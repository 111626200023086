
import Vue from 'vue';
import Backbone from 'backbone';
import { parse as parse_qs } from '../util/query-string.js';

import courses from '../modules/courses.js';
import ControllerFactory from '../modules/controller.factory.js';

import RegisterMainView from '../view/register/main.vue';
import i18nUtils from '../util/i18n.js';
import Client from '../util/client.js';

const RegisterViewComponent = Vue.extend(RegisterMainView);

export class Register2Controller {

    constructor () {
        this._registerView = null;
    }

    async go (appView, router, step = null, query_string = null) {
        let query = parse_qs(query_string);
        // Remove query parameters to only process them once
        Backbone.history.navigate(Backbone.history.fragment.split('?')[0], {replace: true, trigger: false});

        if (this._registerView !== null) {
            this._registerView.navigate(step);
            await this._registerView.apply_query_parameters(query);
        } else {

            if (step !== null) {
                // Should only land on register home page, reset the path for further navigation to work properly
                Backbone.history.navigate(`register`, { trigger: false, replace: true });
            }

            let _client = new Client(null);

            this._registerView = new RegisterViewComponent({
                data: {},
                propsData: {
                    courses: courses.getCourses(),
                    interface_language: i18nUtils.currentInterfaceLanguage,
                    _client,
                    query: query
                }
            });

            this._registerView.$on('remove', () => {
                this._registerView = null;
            });

            this._registerView.$on('navigate', step => {
                Backbone.history.navigate(`register/${step}`, { trigger: true });
            });

            this._registerView.$on('navigate_back', () => {
                window.history.back();
            });

            appView.setMainView(this._registerView);
        }
    }
}

export const register2_controller_factory = new ControllerFactory(Register2Controller);
