var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass:
            "status-incomplete-known_svg__status-incomplete-known_svg__ic-cw-status-dot-incomplete-learned-paper-24",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c(
            "g",
            {
              staticClass:
                "status-incomplete-known_svg__status-incomplete-known_svg__ic-cw-status-dot-incomplete-learned-paper-24__icon",
            },
            [
              _c("path", {
                staticClass:
                  "status-incomplete-known_svg__status-incomplete-known_svg__ic-cw-status-dot-incomplete-learned-paper-24__icon__bg",
                attrs: { d: "M0 0h24v24H0z" },
              }),
              _c("path", {
                staticClass:
                  "status-incomplete-known_svg__status-incomplete-known_svg__ic-cw-status-dot-incomplete-learned-paper-24__icon__path",
                attrs: {
                  fill: "#FAAA5A",
                  "fill-rule": "nonzero",
                  d: "M12 6a6.003 6.003 0 015.684 4.074l-1.686 1.684A4.01 4.01 0 0012 8a4 4 0 00-.252 7.992l1.806 1.805A6 6 0 1112 6z",
                },
              }),
              _c("path", {
                staticClass:
                  "status-incomplete-known_svg__status-incomplete-known_svg__ic-cw-status-dot-incomplete-learned-paper-24__icon__path",
                attrs: {
                  fill: "#43D281",
                  d: "M11.293 11.293a1 1 0 011.327-.078l.087.078L16 14.586l5.794-5.793a1 1 0 011.327-.078l.087.078a1 1 0 01.078 1.327l-.078.087-6.5 6.5a1 1 0 01-1.327.078l-.087-.078-4-4a1 1 0 010-1.414z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }