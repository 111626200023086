<template>
    <div class="lesson-cards">
        <h3 v-html="this.$i18n('lessons_creator_editor_title')" />
        <div class="box">
            <lesson-cards-control
                v-bind:lesson="lesson"
                v-bind:cards="cards"
                v-bind:isPortrait="isPortrait"
                v-bind:interaction-disabled="interactionDisabled"
                v-bind:expanded="expanded"
                v-bind:allChecked="allChecked"
                v-bind:checkedCards="checkedCards"
                v-bind:active-filter="activeCardsFilter"
                v-on:add-cards="addCardsToLesson"
                v-on:expand-lesson="expandLesson" />
            <div class="lesson-cards-container" v-if="cards.length > 0">
                <table :class="{ 'disabled': this.interactionDisabled }">
                    <thead>
                    <tr>
                        <th class="index">&nbsp;</th>
                        <th class="target-lang" :colspan="(isPortrait && !!selected_card) ? 1 : 2" v-html="this.$i18n(targetLang, { collection: 'language_name' })" />
                        <th class="source-lang" :colspan="(isPortrait && !!selected_card) ? 1 : 2">
                            <span v-html="this.$i18n(sourceLang, { collection: 'language_name' })" />
                        </th>
                        <th class="status">&nbsp;</th>
                    </tr>
                    </thead>
                    <transition-group name="list" mode="out-in" tag="tbody" :class="{ expanded }">
                        <tr v-for="(card, index) in filteredCards"
                            :key="card.id"
                            :data-checked="checkedCards.includes(card.id)"
                            :class="{ 'selected': (selected_card && card.id === selected_card.id),
                             'disabled': isCardRowDisabled(card) }">
                            <td class="index">
                                <span class="nr">{{ index + 1 }}</span>
                                <input type="checkbox" class="custom" :value="card.id" v-model="checkedCards"/>
                            </td>
                            <td class="target target-word" v-on:click="$emit('edit-card', card)">
                                <edit-in-sidebar-icon />
                                {{ card.form }}
                            </td>
                            <td class="target target-sentence" v-on:click="$emit('edit-card', card)">
                                <edit-in-sidebar-icon />
                                {{ card.context }}
                            </td>
                            <td class="source-word" v-on:click="$emit('edit-card', card)">
                                <edit-in-sidebar-icon />
                                {{ card.form_translation }}
                            </td>
                            <td class="source-sentence" v-on:click="$emit('edit-card', card)">
                                <edit-in-sidebar-icon />
                                {{ card.context_translation }}
                            </td>
                            <td class="status" v-on:click="$emit('edit-card', card)">
                                <card-status-icon :key="card.id + '_status'" v-bind:status="card.completeness" v-bind:known="card.known" :class="{ hidden: card.completeness === 'complete' && !card.known }" :showTooltip="true" />
                            </td>
                        </tr>
                    </transition-group>
                </table>
            </div>
            <lesson-intro v-else
                          v-bind:lesson_uuid="this.lesson_uuid"
                          v-bind:interaction-disabled="interactionDisabled"
                          v-on:add-cards="addCardsToLesson" />
        </div>
    </div>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus";
    import ExpandButton from './expand-button.vue';
    import LessonCardsControl from './cards-control.vue';
    import LessonIntro from "../components/intro.vue";
    import CardStatusIcon from "./card-status-icon.vue";
    import EditInSidebarIcon from './edit-in-sidebar-icon.vue';

    export default {
        name: 'lesson-cards',
        components: { CardStatusIcon, ExpandButton, LessonCardsControl, LessonIntro, EditInSidebarIcon },
        props: {
            lesson_uuid: {
                type: String
            },
            cards: {
                type: Array
            },
            lesson: {
                type: Object,
            },
            course_info: {
                type: Object,
            },
            selected_card: {
                type: Object,
                default: null
            },
            interactionDisabled: {
                type: Boolean
            },
            isPortrait: {
                type: Boolean
            }
        },
        data() {
            return {
                allChecked: false,
                checkedCards: [],
                expanded: false,
                activeCardsFilter: 'all'
            }
        },
        computed: {
            targetLang() {
                return this.course_info.target_language;
            },
            sourceLang() {
                return this.course_info.source_language;
            },
            filteredCards() {
                if (this.activeCardsFilter === 'all') {
                    return this.cards;
                } else if (this.activeCardsFilter === 'known') {
                    return this.cards.filter(card => card.known);
                } else {
                    return this.cards.filter(card => card.completeness === this.activeCardsFilter);
                }
            }
        },
        methods: {
            checkAll(event) {
                if (event.target.checked) {
                    let _checkedCards = []
                    this.filteredCards.forEach(card => {
                        _checkedCards.push(card.id);
                    });
                    this.checkedCards = _checkedCards;
                } else {
                    this.checkedCards = [];
                }
            },
            resetCheckedCards() {
                this.checkedCards = [];
            },
            listenEventBus() {
                EventBus.$on('cw:reset-checked-cards', this.resetCheckedCards);
                EventBus.$on('cw:expand-toggle', this.toggleExpandCompact);
                EventBus.$on('cw:check-all-toggle', this.checkAll);
                EventBus.$on('cw:filter-cards', this.changeCardsFilter);
            },
            addCardsToLesson({ seed_text, seed_file, intent, word_count }) {
                this.$emit('add-cards', { seed_text, seed_file, intent, word_count });
            },
            toggleExpandCompact() {
                this.expanded = !this.expanded;
            },
            expandLesson() {
                this.$emit('expand-lesson');
            },
            changeCardsFilter(filter) {
                const validFilters = ['all', 'complete', 'incomplete', 'invalid', 'known'];

                if (validFilters.includes(filter)) {
                    this.activeCardsFilter = filter;
                } else {
                    this.activeCardsFilter = 'all';
                }
            },
            isCardRowDisabled(card) {
                const incomplete = (!this.lesson.include_incomplete && (card.completeness === 'invalid' || card.completeness === 'incomplete'));
                const known = (!this.lesson.include_known && card.known)
                return incomplete || known;
            }
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            EventBus.$off('cw:reset-checked-cards', this.resetCheckedCards);
            EventBus.$off('cw:expand-toggle', this.toggleExpandCompact);
            EventBus.$off('cw:check-all-toggle', this.checkAll);
            EventBus.$off('cw:filter-cards', this.changeCardsFilter);
        },
        watch: {
            checkedCards() {
                this.allChecked = this.checkedCards.length === this.filteredCards.length;
                this.$emit('checked-cards-changed', this.checkedCards);
            },
            filteredCards(newValue) {
                if (newValue.length <= 0) {
                    this.activeCardsFilter = 'all';
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.lesson-cards {
        h3 {
            font-size: fontSize(18);
            margin-bottom: .75rem;
            margin-right: 2rem;
            margin-left: 1rem;
            user-select: none;
        }

        > div.box {
            background-color: #fff;
            border-radius: .5rem;
            padding: .5rem 0;
            > div.lesson-cards-container {
                margin-top: .5rem;
                overflow-y: auto;
                overflow-x: hidden;
                @include minimalistScrollbarWithSpacing(12px, #fff);
                max-height: 69vh;
                scroll-padding: 20px;

                > table {
                    width: 100%;
                    border-collapse: collapse;
                    user-select: none;
                    &.disabled {
                        pointer-events: none;
                        opacity: 0.6;
                        filter: grayscale(100%);
                    }
                    > thead {
                        > tr > th {
                            border-bottom: pxToRem(1) solid $colorBackgroundGray;
                            padding: .5rem;
                            text-align: start;
                            font-weight: $font-bold;
                            line-height: pxToRem(24);
                            color: $colorSourceSecondary;
                            &.index {
                                text-align: center;
                                vertical-align: middle;
                                width: pxToRem(50);
                                line-height: unset;
                                input[type=checkbox] {
                                    margin-top: .2rem;
                                }
                            }
                            div.expand-button {
                                float: right;
                            }
                        }
                    }
                    > tbody {
                        > tr {
                            > td {
                                position: relative;
                                cursor: pointer;
                                padding: .35rem;
                                border-bottom: pxToRem(1) solid $colorBackgroundGray;
                                max-width: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                vertical-align: middle;
                                height: 2.5rem;
                                &.index {
                                    text-align: center;
                                    width: pxToRem(50);
                                    .nr {
                                        display: inline-block;
                                    }
                                    input[type=checkbox] {
                                        display: none;
                                        margin-top: .3rem;
                                    }
                                }
                                &.target-word,
                                &.source-word {
                                    width: 15%;
                                }
                                &.status {
                                    width: pxToRem(60);
                                    min-width: pxToRem(60);
                                    max-width: pxToRem(60);
                                    text-align: center;
                                    overflow: visible;
                                    > div.card-status-icon {
                                        &.hidden {
                                            display: none;
                                        }
                                    }
                                }
                                > div.edit-in-sidebar-icon {
                                    display: none;
                                }
                            }
                            &:hover,
                            &[data-checked=true],
                            &.selected {
                                > td {
                                    background-color: $colorBackgroundGraySecondary;
                                    &.index {
                                        .nr {
                                            display: none;
                                        }
                                        input[type=checkbox] {
                                            display: inline-block;
                                        }
                                        box-shadow: inset pxToRem(4) 0 0 0 $colorNeutral;
                                    }
                                    &.target-word,
                                    &.source-word {
                                        > div.editable {
                                            > div.editable-content {
                                                &:hover {
                                                    border-bottom: 2px solid $colorBackgroundGrayDarker;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.disabled {
                                > td {
                                    pointer-events: none;
                                    background-color: rgba($colorBackgroundLighter, 0.3);
                                    color: $colorSourceTertiary;
                                    &.index,
                                    &.status {
                                        pointer-events: auto;
                                        opacity: 1;
                                    }
                                }
                            }
                            &:hover:not(.selected):not(.disabled) {
                                > td {
                                    background-color: $colorBackgroundGrayDarker;
                                    &:hover {
                                        > div.edit-in-sidebar-icon {
                                            position: absolute;
                                            display: inline-flex;
                                            right: 0.25rem;
                                            top: 50%;
                                            margin-top: - pxToRem(12);
                                        }
                                    }
                                    &.target-word,
                                    &.source-word {
                                        > div.editable {
                                            > div.editable-content {
                                                &:hover {
                                                    border-bottom: 2px solid $colorBackgroundGraySecondary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                td {
                                    border-bottom: unset;
                                }
                            }
                        }
                        &.expanded {
                            > tr {
                                > td {
                                    white-space: unset;
                                    overflow: visible;
                                    text-overflow: unset;
                                }
                            }
                        }
                    }
                }
            }
        }

        .list-enter-active, .list-leave-active {
            transition: all 250ms;
            > td {
                border-color: transparent;
            }
        }
        .list-enter, .list-leave-to {
            opacity: 0.4;
            transform: translateY(1.5rem);
            > td {
                border-color: transparent;
            }
        }
    }
</style>
