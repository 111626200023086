var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "tick-correct",
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "10", cy: "10", r: "10", fill: _vm.fillColor },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M4.29985 10.8416L8.57941 14.4718C8.8796 14.7264 9.32923 14.6902 9.58478 14.3908L15.7814 7.1304C16.0375 6.83034 16.0019 6.37948 15.7018 6.12338C15.4017 5.86729 14.9509 5.90293 14.6948 6.20299L8.49816 13.4634L9.50353 13.3824L5.22396 9.75219C4.92313 9.497 4.47239 9.534 4.2172 9.83484C3.96201 10.1357 3.99902 10.5864 4.29985 10.8416Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }