var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "56",
          height: "56",
          viewBox: "0 0 56 56",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M29.5 36.5H10.498a2.999 2.999 0 01-2.998-3V14a3 3 0 013-3h32a3 3 0 013 3v10.5M49 46.5v-10H35v2m0 4v4h9.5",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.5 32.5A3.5 3.5 0 0142 29v0a3.5 3.5 0 013.5 3.5v4h-7v-4z",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("rect", {
        attrs: {
          x: "11",
          y: "15",
          width: "21",
          height: "18",
          rx: "2",
          fill: "#AC6ED1",
        },
      }),
      _c("rect", {
        attrs: {
          x: "14",
          y: "29",
          width: "10",
          height: "2",
          rx: "1",
          fill: "#9DF4F4",
        },
      }),
      _c("circle", {
        attrs: { cx: "45.5", cy: "24", r: "2", fill: "#2C3143" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }