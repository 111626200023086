var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "variation-settings" }, [
    _c("div", { staticClass: "action" }, [
      _c("div", { staticClass: "action-flex" }, [
        _c(
          "div",
          { staticClass: "status" },
          [
            _c("status-dot", {
              key: "status_dot_variation_enabled",
              attrs: { disabled: !_vm.variationEnabled },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          class: { label: true, disabled: _vm.variationToggleDisabled },
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("variation_page_enable_deck_toggle_label")
            ),
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "toggle" },
          [
            _c("toggle-button", {
              key: "variation_enabled_toggle",
              attrs: {
                disabled: _vm.variationToggleDisabled,
                color: _vm.colors,
                width: 40,
                height: 24,
                labels: false,
              },
              on: { change: _vm.toggleVariationEnabled },
              model: {
                value: _vm.variationEnabled,
                callback: function ($$v) {
                  _vm.variationEnabled = $$v
                },
                expression: "variationEnabled",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.variationEnabled
        ? _c("div", {
            staticClass: "action-footnote",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("variation_page_manage_settings_disable_course_text")
              ),
            },
          })
        : _c("div", {
            staticClass: "action-footnote",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("hub_variation_settings_footnote_variation_disabled")
              ),
            },
          }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }