<template>
    <section class="app-header" v-if="isVisible">
        <header-logo @click.native="goLogin"/>
        <div class="actions">
            <v-button v-if="signInButtonText && isItRegisterFlow" @click.native="goLogin" :text="signInButtonText" :filled="true" />
            <language-selection />
        </div>
    </section>
</template>

<script>
    import HeaderLogo from "./header-logo.vue";
    import LanguageSelection from "./language-selection.vue";
    import { EventBus } from "Util/vue-event-bus.js";
    import UserModel from "Model/user.model.js";
    import Backbone from "backbone";
    import VButton from "ViewComponent/v-button.vue";
    import i18nUtils from "Util/i18n.js";

    export default {
        name: 'app-header',
        components: { VButton, LanguageSelection, HeaderLogo },
        data() {
            return {
                isVisible: {
                    type: Boolean,
                    default: true,
                },
                currentLocationHash: {
                    type: String,
                    default: null
                },
                isItRegisterFlow: {
                    type: Boolean,
                    default: false
                },
                signInButtonText: '',
            }
        },
        methods: {
            setSignInButtonText() {
                this.signInButtonText = i18nUtils.prop('home_sign_in');
            },
            interfaceLanguageChanged(a) {
                console.log('interfaceLanguageChanged: ', a);
                this.setSignInButtonText();
            },
            setVisibility(state) {
                this.isVisible = state;
            },
            setIsItRegisterFlow() {
                this.isItRegisterFlow = !!(window && window.location && window.location.hash && window.location.hash.includes('register'));
            },
            urlChange() {
                this.setIsItRegisterFlow();
            },
            checkLoginStatus() {
                const userIsSignedIn = UserModel.isSignedIn();
                if (userIsSignedIn) {
                    this.setVisibility(false);
                }
            },
            onUserLogin(user) {
                if (user) {
                    this.setVisibility(false);
                }
            },
            onUserLogout() {
                this.setVisibility(true);
            },
            goLogin() {
                Backbone.history.navigate('signin', { trigger: true });
            },
            listenEventBus() {
                EventBus.$on('user:signed-in', this.onUserLogin);
                EventBus.$on('user:signed-out', this.onUserLogout);
                EventBus.$on('interfaceLanguageChanged', this.interfaceLanguageChanged);
            },
            unListenEventBus() {
                EventBus.$off('user:signed-in', this.onUserLogin);
                EventBus.$off('user:signed-out', this.onUserLogout);
                EventBus.$off('interfaceLanguageChanged', this.interfaceLanguageChanged);
            }
        },
        beforeMount() {
            this.checkLoginStatus();
        },
        mounted() {
            this.listenEventBus();
            this.setIsItRegisterFlow();
            this.setSignInButtonText();
            window.addEventListener('popstate', this.urlChange);
        },
        beforeDestroy() {
            this.unListenEventBus();
            window.removeEventListener('popstate', this.urlChange);
        }
    }
</script>

<style lang="scss" scoped>
    section.app-header {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        gap: .5rem;
        justify-content: center;
        transition: opacity 200ms linear;
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        > svg.header-logo {
            margin: .5rem;
        }
        > div.actions {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            gap: .5rem;
            body[data-interface-language="ar"] & {
                margin-left: unset;
                margin-right: auto;
            }
        }
    }
</style>
