<template>
    <div class="burger" @click="openHomeDrawer" :class="{ homeDrawerOpen: open }">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.04 12.96H12.96V15.84H35.04V12.96Z" fill="#1D1724"/>
            <path d="M35.04 22.56H12.96V25.44H35.04V22.56Z" fill="#1D1724"/>
            <path d="M35.04 32.16H12.96V35.04H35.04V32.16Z" fill="#1D1724"/>
        </svg>
    </div>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'burger',
        props: {
            open: {
                type: Boolean
            },
        },
        methods: {
            openHomeDrawer() {
                EventBus.$emit('home-drawer:open');
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.burger {
        display: none;
        position: fixed;
        left: .5rem;
        top: .5rem;
        width: pxToRem(64);
        height: pxToRem(64);
        background-color: #fff;
        transition: all 200ms;
        z-index: 20000;
        border-radius: 1rem;
        body[data-interface-language="ar"] & {
            left: unset;
            right: .5rem;
        }
        @include elevated;
        @include respond-to('small') {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover {
            opacity: 1;
            transform: scale(1.2);
        }
        &.homeDrawerOpen {
            display: none;
        }


        animation: fadeOut 1s 3s forwards;
        @keyframes fadeOut {
            to {
                opacity: .5;
                transform: scale(.5);
                left: -.5rem;
                top: -.5rem;
            }
        }
    }
</style>
