var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formSpellingsVisible
    ? _c("div", { staticClass: "card-form-spelling" }, [
        _c("div", { staticClass: "card-form-spelling-container" }, [
          _vm.formSpellingSeenCount <= 3
            ? _c("div", {
                staticClass: "heading",
                domProps: {
                  innerHTML: _vm._s(this.$i18n("form_comment_text")),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-spelling-container" }, [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M7.5 16L9.5 13L7 10H3V4H1C0.447715 4 0 4.44772 0 5V12C0 12.5523 0.447715 13 1 13H5.5L7.5 16Z",
                    fill: "#8EA3EE",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M13 2H14C14.5523 2 15 2.44772 15 3V9C15 9.55228 14.5523 10 14 10H11L9 13L7 10H4C3.44772 10 3 9.55228 3 9V3C3 2.44772 3.44772 2 4 2H9",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "spellings",
                attrs: { id: "card-form-spellings" },
              },
              [
                _vm._l(_vm.formSpellings, function (item, index) {
                  return [
                    _c("span", { staticClass: "spelling" }, [
                      _vm._v(_vm._s(item.spelling)),
                    ]),
                    _vm._v(" "),
                    index + 1 < _vm.formSpellings.length
                      ? _c("span", { staticClass: "comma" }, [_vm._v("; ")])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "close",
            attrs: {
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
            on: { click: _vm.close },
          },
          [
            _c("path", {
              attrs: {
                d: "M4 4L11.6534 11.6534L12 12",
                stroke: "#B2B8CB",
                "stroke-width": "2",
                "stroke-linecap": "round",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M12 4L4 12",
                stroke: "#B2B8CB",
                "stroke-width": "2",
                "stroke-linecap": "round",
              },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }