import moment from "moment";
import { ENTRY_EVENT_TYPE } from "Controller/guess";

export const ANSWER_EVALUATION = {
    CORRECT: 'correct-answer',
    EQUIVALENT_ANSWER: 'equivalent-answer',
    SYNONYM: 'synonym',
    EMPTY: 'empty-answer',
    COMMON_MISTAKE: 'common-mistake',
    TYPO: 'typo',
    WRONG: 'wrong-answer'
};

const ANSWER_ENTRY_LIMIT = 50;
const ANSWER_ANSWER_LIMIT = 100;

export class Answer {
    constructor () {
        this.all_entries = [];
        this.showed = moment().valueOf();
        this.opened = null;
        this.confirmed = null;
        this.answer = null;
        this.guess_value = null;
        this._entry_events = [];
        this.mistake = null;
        this.answer_type = null;
    }

    addEntryEvent (type, result=null, args={}) {
        // If there are more than 50 entry events then attempt to prune
        if (this._entry_events.length >= ANSWER_ENTRY_LIMIT) {
            console.log(`Guess Answer - triggered entry_events pruning`);
            this._entry_events = this._entry_events
                .filter(event => [
                        ENTRY_EVENT_TYPE.REVEAL,
                        ENTRY_EVENT_TYPE.SUBMIT,
                        ENTRY_EVENT_TYPE.SPEECH
                    ].includes(event.type)
                );
        }

        // No more than 50 entry events
        // https://lingvist.atlassian.net/browse/FE-2252
        if (this._entry_events.length < ANSWER_ENTRY_LIMIT) {
            if ([
                ENTRY_EVENT_TYPE.MISTAKE_TABLE_OPENED,
                ENTRY_EVENT_TYPE.MISTAKE_UNIT_EXPANDED,
                ENTRY_EVENT_TYPE.MISTAKE_UNIT_CONTRACTED].includes(type)) {
                this._entry_events.push(Object.assign({}, args, {
                    type: type
                }));
            } else {
                this._entry_events.push(Object.assign({}, args, {
                    type: type,
                    result: result && result.slice(0, ANSWER_ANSWER_LIMIT) // Limit maximum size of answer
                }));
            }
        }

    }

    get entry_events () {
        return this._entry_events;
    }

    addEntry (entry) {
        // No more than 50 entries
        // https://lingvist.atlassian.net/browse/FE-2252
        if (this.all_entries.length < 50) {
            this.all_entries.push(entry && entry.slice(0, ANSWER_ANSWER_LIMIT)); // Limit maximum size of answer
        }
    }

    setAnswer (answer) {
        this.answer = answer && answer.slice(0, ANSWER_ANSWER_LIMIT);  // Limit maximum size of answer
    }
}
