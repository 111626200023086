var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "25",
          height: "24",
          viewBox: "0 0 25 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M4.5 12a1 1 0 011-1h14a1 1 0 110 2h-14a1 1 0 01-1-1zM4.5 20a1 1 0 011-1h14a1 1 0 110 2h-14a1 1 0 01-1-1zM4.5 5a1 1 0 011-1h14a1 1 0 011 1v3a1 1 0 01-1 1h-14a1 1 0 01-1-1V5zM4.5 16a1 1 0 011-1h14a1 1 0 110 2h-14a1 1 0 01-1-1z",
          fill: "#6CD5E5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }