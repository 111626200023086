<template>
    <div class="card-form-spelling" v-if="formSpellingsVisible">
        <div class="card-form-spelling-container">
            <div class="heading" v-html="this.$i18n('form_comment_text')" v-if="formSpellingSeenCount <= 3" />
            <div class="form-spelling-container">
                <svg class="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 16L9.5 13L7 10H3V4H1C0.447715 4 0 4.44772 0 5V12C0 12.5523 0.447715 13 1 13H5.5L7.5 16Z" fill="#8EA3EE"/>
                    <path d="M13 2H14C14.5523 2 15 2.44772 15 3V9C15 9.55228 14.5523 10 14 10H11L9 13L7 10H4C3.44772 10 3 9.55228 3 9V3C3 2.44772 3.44772 2 4 2H9" stroke="#2C3143" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="spellings" id="card-form-spellings">
                    <template v-for="(item, index) in formSpellings">
                        <span class="spelling">{{ item.spelling }}</span>
                        <span v-if="index + 1 < formSpellings.length" class="comma">; </span>
                    </template>
                </div>
            </div>
        </div>
        <svg class="close" @click="close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4L11.6534 11.6534L12 12" stroke="#B2B8CB" stroke-width="2" stroke-linecap="round"/>
            <path d="M12 4L4 12" stroke="#B2B8CB" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script>
    import UserManager from "Modules/usermanager";
    import { NAME, TYPE } from "Modules/user.parameters";
    import { EventBus } from "Util/vue-event-bus.js";
    import { ENTRY_EVENT_SUBMIT_TREATMENT } from "Controller/guess.js";

    export default {
        name: 'card-form-spelling',
        props: {
            question: {
                type: Object,
                required: true
            },
            answer: {
                type: Object
            },
            on_previous: {
                type: Boolean,
                default: false
            },
            autoAdvance: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                formSpellingSeenCount: 0,
                hasBeenClosedBefore: false
            }
        },
        computed: {
            formSpellings() {
                if (this.question && this.question.homograph && this.question.homograph.hasOwnProperty('form_spellings') && this.question.homograph.form_spellings.length > 0) {
                    return this.question.homograph.form_spellings;
                }
            },
            answerState() {
                if (this.answer && this.answer.confirmed && this.answer.all_entries.length === 1) {
                    return 'final-remembered';
                } else if (this.answer && this.answer.opened && !this.answer.confirmed) {
                    return 'wrong';
                } else if (this.answer && this.answer.opened && this.answer.confirmed) {
                    return 'final-wrong';
                } else {
                    return null;
                }
            },
            formSpellingsVisible() {
                // return !!(!this.hasBeenClosedBefore && this.formSpellings && (this.answerState === 'wrong' || this.answerState === 'final-wrong'));
                const { _entry_events } = this.answer;
                let hadEquivalentAnswer = false;
                _entry_events.map(entry => {
                    if (entry.treatment === ENTRY_EVENT_SUBMIT_TREATMENT.EQUIVALENT) {
                        hadEquivalentAnswer = true;
                    }
                });

                return !!(!this.hasBeenClosedBefore && this.formSpellings && (this.answerState === 'wrong' || this.answerState === 'final-wrong' || hadEquivalentAnswer));
            }
        },
        methods: {
            open() {
                this.setClosedParameter(false);
            },
            close() {
                this.setClosedParameter(true);
                EventBus.$emit('guess:form-spelling-button-notify');
            },
            getClosedParameter() {
                const userParameters = UserManager.instance.getUser().getParameters();
                return userParameters.getParameter(NAME.FORM_SPELLINGS_CLOSED);
            },
            setClosedParameter(setting) {
                this.hasBeenClosedBefore = setting;
                const userParameters = UserManager.instance.getUser().getParameters();
                return userParameters.setParameter(NAME.FORM_SPELLINGS_CLOSED, setting, TYPE.BOOLEAN);
            },
            async getFormSpellingSeenCount () {
                return await UserManager.instance.getUser().getStorage().getItem('form_spelling_seen_count') || 0;
            },
            listenEventBus() {
                EventBus.$on('guess:form-spellings-open', this.open);
            }
        },
        async created() {
            this.listenEventBus();
            this.hasBeenClosedBefore = this.getClosedParameter();
            this.formSpellingSeenCount = await this.getFormSpellingSeenCount();
        },
        watch: {
            formSpellingsVisible(newValue) {
                if (newValue === true) {
                    this.formSpellingSeenCount = this.formSpellingSeenCount + 1;
                    UserManager.instance.getUser().getStorage().setItem('form_spelling_seen_count', this.formSpellingSeenCount);
                }
                this.$emit('form-spellings-visibility', newValue);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~Styles/mixins';
    @import "~Styles/colors";
    div.card-form-spelling {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1.5rem;
        > div.card-form-spelling-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: .25rem;
            > div.heading {
                color: $colorSourceSecondary;
                font-size: fontSize(12);
            }
            > div.form-spelling-container {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                align-items: center;
                > svg.icon {

                }
                > div.spellings {
                    font-size: fontSize(18);
                    > span.spelling {
                        color: $colorPurplePrimary;
                    }
                }
            }
        }
        > svg.close {
            cursor: pointer;
            transition: 100ms ease-in-out;
            margin: 0 .5rem;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
</style>
