var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lu-debug" }, [
    _c(
      "div",
      { staticClass: "lus" },
      [
        _c("v-textbox", {
          attrs: {
            "full-width": true,
            label: "Load LU's by UUID, separate with space",
            mono: true,
          },
          on: { "state-change": _vm.setLUs },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: {
            size: "medium",
            text: "Load LU's above",
            disabled: this.loadLUArray.length <= 0,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.loadLUs.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("h2", [_vm._v("Easy load some Lexical Units for debugging")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lu" },
      [
        _c("v-button", {
          attrs: { text: "Bella, Bonita (Spanish)", size: "small" },
          nativeOn: {
            click: function ($event) {
              return _vm.loadLu("29bf6436-c7dc-4458-acc5-89678314201a")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Duro, Severo, Difícil (Spanish)", size: "small" },
          nativeOn: {
            click: function ($event) {
              return _vm.loadLu("6e678a7e-7dd8-4fb7-a0b2-011783baa883")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Fondation, Institution (French)", size: "small" },
          nativeOn: {
            click: function ($event) {
              return _vm.loadLu("c9806758-9425-4f54-9d27-7ec36b7af8a1")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Los - double gap? (Spanish)", size: "small" },
          nativeOn: {
            click: function ($event) {
              return _vm.loadLu("d13df36e-33a7-463b-a573-597523bf0b87")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Los - double gap? (Spanish)", size: "small" },
          nativeOn: {
            click: function ($event) {
              return _vm.loadLu("6dfe377b-15d6-4226-9058-dc18ac623166")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }