var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("circle", { attrs: { cx: "20", cy: "20", r: "18", fill: "#000" } }),
      _c("g", { attrs: { "clip-path": "url(#nyt_svg__clip0_2252_21448)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M29.543 23.044c-.832 2.196-2.362 3.892-4.558 4.79v-4.79l2.629-2.362-2.629-2.328V15.06c2.395-.166 4.059-1.93 4.059-4.091 0-2.828-2.695-3.826-4.225-3.826-.333 0-.699 0-1.231.133v.133c.2 0 .499-.033.599-.033 1.064 0 1.863.499 1.863 1.464 0 .731-.599 1.463-1.664 1.463-2.628 0-5.722-2.129-9.082-2.129-2.995 0-5.058 2.229-5.058 4.49 0 2.23 1.298 2.962 2.662 3.46l.033-.132c-.432-.267-.731-.732-.731-1.464 0-.998.931-1.83 2.096-1.83 2.828 0 7.386 2.362 10.214 2.362h.265v3.327l-2.628 2.295 2.628 2.362V27.9c-1.097.399-2.229.565-3.393.565-4.392 0-7.186-2.662-7.186-7.085 0-1.065.133-2.096.432-3.094l2.196-.965v9.78l4.458-1.962V15.126l-6.554 2.928c.665-1.93 2.03-3.326 3.66-4.125l-.033-.1c-4.392.965-8.65 4.291-8.65 9.281 0 5.755 4.857 9.747 10.513 9.747 5.988 0 9.382-3.992 9.415-9.813h-.1z",
            fill: "#fff",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "nyt_svg__clip0_2252_21448" } }, [
          _c("path", {
            attrs: {
              fill: "#fff",
              transform: "translate(9.714 7.143)",
              d: "M0 0h20.7v26.1H0z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }