var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0 7c0-1.7 1.3-3 3-3h17c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V7zm3-1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h17c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H3z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3.2 8.9c.3-.4 1-.5 1.4-.2l6.9 5.1 6.9-5.1c.4-.3 1.1-.2 1.4.2.3.4.2 1.1-.2 1.4l-8.1 5.9-8.1-5.9c-.4-.3-.5-.9-.2-1.4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }