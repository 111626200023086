var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M2 10v2.66C2 14.107 2.75 16 5 16s3-1.875 3-3.34V10M5 16v14M5 9.5v3M38 30V10c-1.228 0-3 1.2-3 4v4.5l.5 1M24 19.75c0 1.8-1.446.16-1.967.45-1.784.991-4.243 1.8-6.077 1.8C13.22 22 11 19.737 11 17.5s2.22-4.5 4.957-4.5c1.774 0 4.366.757 6.076 1.705.573.317 1.467-.505 1.467 1.295h4.022l1.843-1.238a.5.5 0 01.76.553L29.5 17.5l.624 2.185a.5.5 0 01-.76.553L27.523 19H27M28 29s2.8.1 4-1.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28 30.76c0 .381-.215.735-.58.842-1.849.539-5.272.374-8.226.374-.218 0-.47.005-.713.006a.482.482 0 01-.481-.487V30.6a1 1 0 00-2 0v.345c0 .297-.259.529-.554.492-3.34-.41-5.446-1.184-5.446-2.43 0-1.64 4.116-2.97 9.194-2.97.374 0 .856.003 1.3.003.278 0 .506.224.506.503v.958a1 1 0 102 0v-1.003c0-.275.223-.498.498-.498 1.651.003 2.97.076 3.942.395.354.116.56.466.56.84v3.526z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }