<template>
    <div class="repeat-progress" :class="this.question.repeatProgress" @click="openModal">
        <div class="stripes">
            <div class="stripe one"></div>
            <div class="stripe two"></div>
            <div class="stripe three"></div>
            <div class="stripe four"></div>
            <div class="stripe five"></div>
        </div>
        <div class="repeat-description">
            <span :class="{ alert: this.question.repeatProgress === 'new' }">{{ repeatDesc }}</span>
            <span class="more" v-html="this.$i18n('learn_repeat_progress_find_out_more')" />
        </div>
    </div>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus";
    import i18nUtils from 'Util/i18n';

    export default {
        name: 'repeat-progress',
        props: {
            question: {
                type: Object,
                required: true
            },
            answer: {
                type: Object
            }
        },
        computed: {
            repeatDesc() {
                switch (this.question.repeatProgress) {
                    case 'new':
                        return i18nUtils.prop('guess_meta_new_word');
                    case 'low':
                        return i18nUtils.prop('learn_repeat_progress_low');
                    case 'medium':
                        return i18nUtils.prop('learn_repeat_progress_medium');
                    case 'medium-high':
                        return i18nUtils.prop('learn_repeat_progress_medium_high');
                    case 'high':
                        return i18nUtils.prop('learn_repeat_progress_high');
                }
            }
        },
        methods: {
            openModal() {
                EventBus.$emit('guess:repeat-progress-modal-open');
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";
    div.repeat-progress {
        padding: pxToRem(8) pxToRem(4) pxToRem(8) 0;
        align-items: center;
        background: transparent;
        border-radius: pxToRem(4);
        cursor: pointer;
        display: flex;
        transition: background 200ms, box-shadow 200ms;

        &:hover {
            background: rgba($colorBackgroundGray, 0.8);
            box-shadow: 0 0 0 8px rgba($colorBackgroundGray, 0.8);
            > div.repeat-description {
                opacity: 1;
            }
        }

        > div.stripes {

            display: flex;
            padding-right: pxToRem(4);

            > div.stripe {
                width: pxToRem(16);
                height: pxToRem(4);
                background: $colorBackgroundGraySecondary;
                border-radius: pxToRem(2);
                margin: 0 pxToRem(2);
                transition: background 500ms;

                &.active {
                    background: $colorCorrect;
                }
            }
        }

        > div.repeat-description {

            opacity: 0;
            align-items: center;
            font-size: fontSize(12);
            margin-left: .5rem;

            > span.alert {
                color: $colorAttention;
            }

            > span > em {
                color: $colorSourceSecondary;
                padding: 0 pxToRem(4);
            }
        }

        &.new {
            &:hover {
                > div.repeat-description {
                    > span.more {
                        opacity: 1;
                    }
                }
            }

            > div.stripes {
                > div.stripe.one {
                    background: $colorAttention;
                }
            }

            > div.repeat-description {
                opacity: 1;
                > span.more {
                    opacity: 0;
                }
            }
        }

        &.low {
            > div.stripes {
                > div.stripe.one,
                > div.stripe.two {
                    background: #a7cbcb;
                }
            }
        }

        &.medium {
            > div.stripes {
                > div.stripe.one,
                > div.stripe.two,
                > div.stripe.three {
                    background: $colorTargetSecondary;
                }
            }
        }

        &.medium-high {
            > div.stripes {
                > div.stripe.one,
                > div.stripe.two,
                > div.stripe.three,
                > div.stripe.four {
                    background: $colorTargetPrimary;
                }
            }
        }

        &.high {
            > div.stripes {
                > div.stripe.one,
                > div.stripe.two,
                > div.stripe.three,
                > div.stripe.four,
                > div.stripe.five {
                    background: $colorCorrect;
                }
            }
        }

        &.max {
            > div.stripes {
                > div.stripe.one,
                > div.stripe.two,
                > div.stripe.three,
                > div.stripe.four,
                > div.stripe.five {
                    background: $colorCorrect;
                }
            }

            > div.repeat-description {
                display: none;
            }
        }
    }
</style>
