<template>
    <div class="relevant-words-chart-container" :class="{tracking: tracking}">
        <svg class="gradient-def">
            <defs>
                <linearGradient id="gradient"  x1="0" x2="0" y1="0" y2="1">
                    <stop offset="9.2%" stop-color="#E2B4FF" stop-opacity="1" />
                    <stop offset="100%" stop-color="white" stop-opacity="1" />
                </linearGradient>
            </defs>
        </svg>
        <div ref="chart" class="ct-chart"></div>
    </div>
</template>

<script>
    import Chartist from 'chartist';

    export default {
        name: 'relevant-words-chart',
        props: {
            position: {
                type: Number,
                required: true
            },
            max_words: {
                type: Number,
                required: true
            },
            series: {
                type: Array,
                required: true
            },
            tracking: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                chart: null,
                chartRect: null
            };
        },
        watch: {
            position: function (new_value) {
                this.setPosition(new_value);
            }
        },
        components: {

        },
        methods: {
            setPosition (position) {
                if (this.$refs.chart) {
                    const words_max_percentage = position / this.max_words * 100;

                    const chartWidth = this.$refs.chart.offsetWidth;
                    const widthRatio = (chartWidth - 46) / chartWidth;

                    this.chart.svg.querySelector('rect').attr( {width: words_max_percentage * widthRatio + '%'}, null );
                }
            }
        },
        computed: {
            options: function () {
                return {
                    width: 400,
                    height: 140,
                    chartPadding: {
                        top: 10,
                        left: 0,
                        right: 10
                    },
                    axisX: {
                        type: Chartist.FixedScaleAxis,
                        position: 'end',
                        showLabel: false,
                        labelOffset: {
                            x: -30,
                            y: 0
                        }
                    },
                    axisY: {
                        type: Chartist.FixedScaleAxis,
                        low: 0,
                        high: 100,
                        ticks: [0, 50, 100],
                        labelInterpolationFnc: function (value, index) {
                            return value + '%';
                        },
                        labelOffset: {
                            y: 5
                        }
                    },
                    series: {
                        'series-1': {
                            showPoint: false,
                            showArea: true
                        },
                        'series-2': {
                            showPoint: false,
                            showArea: true
                        }
                    }
                };
            },

            series_: function () {
                return this.series.filter(point => point.x <= this.max_words);
            },

            data: function () {
                const series = this.series_;
                return {
                    series: [{
                        name: 'series-1',
                        data: series
                    }, {
                        name: 'series-2',
                        data: series
                    }]
                };
            }
        },

        mounted () {
            this.chart = new Chartist.Line(this.$refs.chart, this.data, this.options, [
                ['screen and (min-width: 904px)', {
                    width: 400,
                    height: 266,
                }],
                ['screen and (max-width: 904px)', {
                    width: 600,
                    height: 200,
                }],
                ['screen and (max-width: 680px)', {
                    width: '100%',
                    height: 200,
                }],
                ['screen and (max-width: 520px)', {
                    width: '100%',
                    height: 160,
                }]
            ]);

            this.chart.on('created', context => {
                this.chartRect = context.chartRect;

                context.svg
                    .elem('defs')
                    .elem('clipPath', {id: 'mask'})
                    .elem('rect', {x: 38, y: 0, width: 0, height: '100%'});

                context.svg.querySelector('.ct-series-b').attr( {'clip-path': 'url(#mask)'}, null );

                this.setPosition(this.position);
            });
        },

        beforeUnmount () {
            if (this.chart) {
                this.chart.detach();
            }
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.relevant-words-chart-container {

        > svg.gradient-def {
            height: 0;
            position: absolute;
            width: 0;
        }

        &.tracking {
            > .ct-chart {
                circle {
                    transition: none;
                }

                rect {
                    transition: none;
                }
            }
        }

        > .ct-chart {
            .ct-grid {
                stroke-dasharray: none;
            }

            .ct-grid.ct-vertical {
                stroke: $colorBackgroundPurple;

                &:nth-child(3) {
                    display: none;
                }
            }

            .ct-grid.ct-horizontal {
                display: none;
            }

            g.ct-series-a > path.ct-line, g.ct-series-b > path.ct-line {
                stroke: $colorPurplePrimary;
                stroke-width: 2px;
            }

            g.ct-series-a > path.ct-area {
                fill: url(#gradient) !important;
                fill-opacity: 0.5;
            }

            g.ct-series-b > path.ct-area {
                fill: $colorBackgroundPurple;
                fill-opacity: 1;
            }

            circle {
                stroke: $colorSourcePrimary;
                fill: $colorSourcePrimary;
                stroke-width: 1;
                transition: all 0.25s;
            }

            rect {
                transition: width 0.25s;
            }

            .ct-labels foreignObject:first-of-type .ct-label {
                transform: translateY(-6px);
            }

            .ct-labels foreignObject:last-of-type .ct-label {
                transform: translateY(8px);
            }

            .ct-labels .ct-label {
                color: $colorSourcePrimary;
            }
        }
    }
</style>
