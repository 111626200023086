var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "20",
          height: "20",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      _vm.$listeners
    ),
    [
      _c("defs", [
        _c("path", {
          attrs: { id: "status-unlimited_svg__a", d: "M0 0h20v20H0z" },
        }),
      ]),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: "status-unlimited_svg__b", fill: "#fff" } }, [
          _c("use", { attrs: { "xlink:href": "#status-unlimited_svg__a" } }),
        ]),
        _c("path", {
          attrs: {
            d: "M9.293 10c-.946-1.469-2-2.727-3.386-2.727-1.387 0-2.56 1.189-2.56 2.727 0 1.538 1.173 2.755 2.56 2.755.746 0 1.626-.447 2.533-1.552a.58.58 0 01.96.084.7.7 0 01-.067.797c-.96 1.147-2.133 1.902-3.52 1.902C3.707 13.986 2 12.252 2 10c0-2.322 1.76-4 3.813-4 1.414 0 2.84.741 4.187 2.825L10.707 10c1.226 1.986 2.413 2.755 3.386 2.755 1.387 0 2.56-1.217 2.56-2.755 0-1.538-1.173-2.727-2.56-2.727-.893 0-1.653.517-2.333 1.286a.619.619 0 01-.947-.014.643.643 0 01.014-.825C11.933 6.476 13.053 6 14.187 6 16.24 6 18 7.678 18 10c0 2.252-1.707 4-3.813 4-1.734-.014-3.12-1.189-4.187-2.825L9.293 10z",
            fill: "#FAAA5A",
            mask: "url(#status-unlimited_svg__b)",
            transform: "matrix(-1 0 0 1 20 0)",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }