import Vue from 'vue';
import ControllerFactory from '../modules/controller.factory.js';
import MainView from '../view/course_wizard/main.vue';

export class CourseWizardController {

    constructor () {}

    async go (appView, router, action = null, uuid = null) {
        const CourseWizardViewComponent = Vue.extend(MainView);
        appView.setMainView(new CourseWizardViewComponent({ action, uuid }));
    }
}

export const course_wizard_controller_factory = new ControllerFactory(CourseWizardController);
