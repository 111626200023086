<template>
    <div class="course-icons-debug">
        <div class="heading">
            <h2>List of available course-icons</h2>
            <div class="select">
                <select v-model="size">
                    <option selected value="">default size</option>
                    <option value="small">small size</option>
                    <option value="medium">medium size</option>
                    <option value="large">large size</option>
                </select>
            </div>
        </div>
        <div class="icon-list">
            <course-icon v-for="i in icons" :value="i" :key="`course-icon-${i}`" :title="i" :size="size" />
        </div>
    </div>
</template>

<script>
    import courseIcon from "ViewComponent/course-icon.vue";
    import { existingIllustrations } from "ViewComponent/course-icon.vue";

    export default {
        components: {
            courseIcon
        },
        data() {
            return {
                size: '',
                icons: existingIllustrations
            }
        },
    }
</script>

<style lang="scss" scoped>
    div.course-icons-debug {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        > div.heading {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            > div.select {
                margin-left: auto;
            }
        }
        > div.icon-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
</style>
