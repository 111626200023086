var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-more-dropdown",
      on: { mouseleave: _vm.closeDropdownDelayed },
    },
    [
      _c("more-circle", {
        staticClass: "transparent",
        on: { click: _vm.openDropdown },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.open
          ? _c(
              "div",
              {
                staticClass: "dropdown",
                on: {
                  mouseenter: _vm.openDropdown,
                  mouseleave: _vm.closeDropdown,
                },
              },
              [
                !_vm.hideMuteButton
                  ? _c(
                      "div",
                      { staticClass: "item", on: { click: _vm.muteWord } },
                      [
                        _c("mute-icon"),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "text",
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n("mute_word_button_label")
                            ),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "item",
                    on: { click: _vm.toggleFavouriteWord },
                  },
                  [
                    _vm.wordIsFavourite
                      ? [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d: "M7.6066 4C2.8016 4 0.395245 9.80946 3.7929 13.2071L11.2929 20.7071C11.4804 20.8946 11.7348 21 12 21C12.2652 21 12.5196 20.8946 12.7071 20.7071L20.2071 13.2071C23.6048 9.80945 21.1984 4 16.3934 4C14.5813 4 12.978 4.89365 12 6.26428C11.022 4.89365 9.41868 4 7.6066 4Z",
                                  fill: "#AC6ED1",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "text",
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n("remove_favorite_word_button_label")
                              ),
                            },
                          }),
                        ]
                      : [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M11.9999 20L11.2928 20.7071C11.4804 20.8946 11.7347 21 11.9999 21C12.2652 21 12.5195 20.8946 12.7071 20.7071L11.9999 20ZM4.49995 12.5L5.20706 11.7929L4.49995 12.5ZM10.9999 9.3934C10.9999 9.94568 11.4477 10.3934 11.9999 10.3934C12.5522 10.3934 12.9999 9.94568 12.9999 9.3934H10.9999ZM19.5 12.5L18.7928 11.7929V11.7929L19.5 12.5ZM10.8321 17.4179C10.4415 17.0274 9.80837 17.0274 9.41784 17.4179C9.02732 17.8084 9.02732 18.4416 9.41784 18.8321L10.8321 17.4179ZM5.66784 15.0821C6.05837 15.4726 6.69153 15.4726 7.08206 15.0821C7.47258 14.6916 7.47258 14.0584 7.08206 13.6679L5.66784 15.0821ZM7.60655 6C9.48067 6 10.9999 7.51928 10.9999 9.3934H12.9999C12.9999 6.41471 10.5852 4 7.60655 4V6ZM7.60655 4C2.80154 4 0.395193 9.80946 3.79284 13.2071L5.20706 11.7929C3.06933 9.65517 4.58336 6 7.60655 6V4ZM18.7928 11.7929L11.2928 19.2929L12.7071 20.7071L20.2071 13.2071L18.7928 11.7929ZM16.3933 6C19.4165 6 20.9306 9.65517 18.7928 11.7929L20.2071 13.2071C23.6047 9.80945 21.1984 4 16.3933 4V6ZM16.3933 4C13.4147 4 10.9999 6.41471 10.9999 9.3934H12.9999C12.9999 7.51928 14.5192 6 16.3933 6V4ZM12.7071 19.2929L10.8321 17.4179L9.41784 18.8321L11.2928 20.7071L12.7071 19.2929ZM7.08206 13.6679L5.20706 11.7929L3.79284 13.2071L5.66784 15.0821L7.08206 13.6679Z",
                                  fill: "#2C3143",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "text",
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n("favorite_word_button_label")
                              ),
                            },
                          }),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item", on: { click: _vm.openReportModal } },
                  [
                    _c("report-icon"),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "text",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("card_feedback_report_problem_title")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item", on: { click: _vm.openHotkeysModal } },
                  [
                    _c("help-icon"),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "text",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("guess_game_overflow_menu_hotkeys_btn")
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }