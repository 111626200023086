import _ from 'lodash';
import moment from 'moment';
import Raven from 'raven-js';

import getConfigValue from '../util/configuration.js';

export class GrammarTableNotAvailableException extends Error {
    constructor (message) {
        super(message);
        this.name = 'GrammarTableNotAvailableException';
    }
}

export const REPEAT_PROGRESS = {
    NEW: 'new',
    LOW: 'low',
    MEDIUM: 'medium',
    MEDIUM_HIGH: 'medium-high',
    HIGH: 'high'
};

/*
// Question data example
{
        "predicted_ts": null,
        "word": {
          "paths": {
            "v1": "v1/0d0bd906-33de-4096-a0c2-7b19380dc99c/lexical_unit/v1/ba024da3-5a7d-4089-ab74-84eb48c91724/42cd3b9d2e400434f1f276bf9a857180.json"
          },
          "homograph_uuid": "9293f020-9b8a-4013-baaf-f9a100c67c7d",
          "sense_uuid": "690768c6-e6cc-4746-94a5-ac0b606f9c21",
          "context_uuid": "03669924-d537-4815-a9d3-f3efb5089f13"
        },
        "predicted_interval": null,
        "type": "word",
        "guess_params": {
          "prediction_environment": null,
          "status_flags": [
            "evaluation_test"
          ],
          "schema": "urn:lingvist:schemas:events:guess_params:1.0"
        },
        "new_unit_sn": 100,
        "simple_algorithm_state": {
          "version": "SimpeAlgoConfig:1.0",
          "predicted_interval": null,
          "guess_value": null,
          "required_precision": null,
          "step": null,
          "first_wrong_answer_interval": 50.0,
          "error_count": 0,
          "first_correct_answer_interval": 29163567,
          "answer_count": 0,
          "wrong_answer_interval_multiplier": 1.0,
          "event_ts": null,
          "memory_calibration": null,
          "correct_answer_interval_multiplier": 5.0,
          "guess_interval": 0,
          "best_guess_interval": 0,
          "schema": "urn:lingvist:schemas:algo:simple:state:0.2"
        },
        "placement_test": null,
        "lexical_unit_uuid": "ba024da3-5a7d-4089-ab74-84eb48c91724",
        "evaluation_criteria": {
          "required_precision": 0.799,
          "schema": "urn:lingvist:schemas:algo:outbound:evaluation_criteria:0.1"
        }
      }
 */

class UnrecognizedQuestionTypeException extends Error {
    constructor (message) {
        super(message);
        this.name = 'UnrecognizedQuestionTypeException';
    }
}

export default class Question {
    /**
     * Container for question data.
     *
     * Contains question algorithm data and also loads language data, audios, etc,
     * Populates the evaluation_criteria
     */

    constructor (course, data, assetLoader) {
        if (!_.isObject(data)) {
            throw Error('data is a required argument');
        }

        if (!_.isObject(assetLoader)) {
            throw Error('assetLoader is a required argument');
        }

        if (!_.isString(data.lexical_unit_uuid) || data.lexical_unit_uuid.length !== 36) {
            throw Error(`Malformed data. lexical_unit_uuid missing or malformed: '${data.lexical_unit_uuid}'`);
        }

        this._course = course;
        this.courseUUID = this._course.UUID;
        this.UUID = data.lexical_unit_uuid;

        this.type = null;
        this._assetLoader = assetLoader;
        this._lexicalUnitData = null;
        this._grammarTableData = null;

        this._loadableAssets = [];

        this.update(data);
    }

    destroy () {
        var self = this;

        return Promise.resolve().then(function () {
            return Promise.all(self._loadableAssets.map(
                url => self._assetLoader.removeAssetFromLoading(url)
            ));
        }).then(function () {
            delete self._assetLoader;
            delete self._course;
        });
    }

    update (data) {
        if (data.lexical_unit_uuid !== this.UUID) {
            throw Error('Question updated with incorrect data.');
        }
        this.type = data.type;

        if (this.type !== 'word') {
            throw new UnrecognizedQuestionTypeException(`Unsupported question type="${this.type}`);
        }
        this._data = _.clone(data);
        this._data.predicted_ts = data.predicted_ts ? moment.utc(data.predicted_ts) : null;
    }

    serialize () {
        return this._data;
    }

    getInfo () {
        return {
            uuid: this.UUID,
            type: this.type,
            debug: this._data.debug,
            predicted_interval: this.getParameter('predicted_interval'),
            new_unit_sn: this._data.new_unit_sn,
            predicted_ts: this._data.predicted_ts,
            placement_test: this._data.placement_test,
            path: this.getParameter('path'),
            visual: this._data.visual,
            guess_params: this.getParameter('guess_params')
        };
    }

    getParameter (parameterName) {
        switch (parameterName) {
            case 'predicted_ts':
                return this._data.predicted_ts;
            case 'predicted_interval':
                return this._data.predicted_interval;
            case 'new_unit_sn':
                return this._data.new_unit_sn;
            case 'guess_params':
                return this._data.guess_params;
            case 'path':
                return this._data[this.type].paths.v1;
            case 'variation_uuid':
                return this._data.variation_uuid;
            case 'visual':
                return this._data.visual;
            default:
                throw Error(`Unknown parameter requested: '${parameterName}'`);
        }
    }

    getSimpleAlgorithmState () {
        return this._data.simple_algorithm_state;
    }

    getEvaluationCriteria () {
        var self = this;

        return Promise.resolve().then(function () {
            return Promise.all([
                self.getHomograph(),
                self.getContext()
            ]);
        }).then(function (data) {
            var homograph = data[0],
                context = data[1];

            return Promise.resolve({
                schema: 'urn:lingvist:schemas:algo:evaluation_criteria:0.1',
                required_precision: self._data.evaluation_criteria.required_precision,
                interpreted_criteria: {
                    correct_answer: homograph.form,
                    acceptable_answers: _.isArray(context.equivalent_answers) ? context.equivalent_answers : []
                }
            });
        });
    }

    getLexicalUnitDataURL () {
        return `${getConfigValue('assets-base-url')}/${this.getParameter('path')}`;
    }

    loadData (priority) {
        let self = this;

        return Promise.resolve().then(function () {
            if (self._lexicalUnitData === null) {
                return Promise.resolve().then(function () {
                    let lexicalUnitDatURL = self.getLexicalUnitDataURL();
                    self._loadableAssets.push(lexicalUnitDatURL);
                    return self._assetLoader.loadAsset(lexicalUnitDatURL, priority);
                }).then(function (lexicalUnitDataResponse) {
                    self._lexicalUnitData = JSON.parse(lexicalUnitDataResponse);
                    self._course.getLexicalUnitDataCache().setLexicalUnitData(self.UUID, self._lexicalUnitData);
                    return Promise.resolve();
                });
            } else {
                return Promise.resolve();
            }
        }).then(function () {
            return Promise.resolve({
                lexicalUnitData: self._lexicalUnitData
            });
        });
    }

    loadGrammarTableData (priority) {
        let self = this;

        return Promise.resolve().then( () => {
            if (this._grammarTableData === null) {
                return Promise.resolve().then(function () {
                    return self.getHomograph();
                }).then(homograph => {
                    let tablePaths = homograph.grammar_table_paths;

                    if (tablePaths != null) {
                        let tableUrl = `${getConfigValue('assets-base-url')}/${tablePaths.v1}`;
                        self._loadableAssets.push(tableUrl);
                        return this._assetLoader.loadAsset(tableUrl, priority);
                    } else {
                        return Promise.reject(new GrammarTableNotAvailableException());
                    }
                }).then(grammarTableResponse => {
                    this._grammarTableData = JSON.parse(grammarTableResponse);
                });
            } else {
                return Promise.resolve();
            }
        }).then( () => {
            return Promise.resolve({
                grammarTableData: this._grammarTableData
            });
        });
    }

    getLexicalUnitData () {
        var self = this;

        return Promise.resolve().then(function () {
            return self.loadData(0);
        }).then(function (data) {
            return Promise.resolve(data.lexicalUnitData);
        });
    }

    getFullData () {
        return this._data;
    }

    getHomograph () {
        var self = this;

        return Promise.resolve().then(function () {
            return self.getLexicalUnitData();
        }).then(function (lexicalUnitData) {
            var homograph = _.find(lexicalUnitData.homographs, {uuid: self._data[self.type].homograph_uuid});

            if (homograph === undefined) {
                Raven.captureMessage(
                    `Can't find the correct homograph in lexical unit data. Falling back to first homograph.`,
                    {
                        fingerprint: ['guess', 'question', 'cant_find_homograph'],
                        level: 'warning',
                        logger: 'manual',
                        extra: {
                            lexicalUnitData: lexicalUnitData,
                            homograph_uuid: self._data[self.type].homograph_uuid,
                            lexical_unit_uuid: lexicalUnitData.uuid,
                            lexical_unit_file_path: self.getLexicalUnitDataURL()
                        }
                    });
                homograph = lexicalUnitData.homographs[0];
            }

            return Promise.resolve(homograph);
        });
    }

    getSense () {
        var self = this;
        return Promise.resolve().then(function () {
            return self.getHomograph();
        }).then(function (homograph) {
            var sense = _.find(homograph.senses, {uuid: self._data[self.type].sense_uuid});

            if (sense === undefined) {
                Raven.captureMessage(
                    `Can't find the correct sense in homograph. Falling back to first sense.`,
                    {
                        fingerprint: ['guess', 'question', 'cant_find_sense'],
                        level: 'warning',
                        logger: 'manual',
                        extra: {
                            lexical_unit_uuid: self.UUID,
                            homograph: homograph,
                            homograph_uuid: homograph.uuid,
                            sense_uuid: self._data[self.type].sense_uuid,
                            lexical_unit_file_path: self.getLexicalUnitDataURL()
                        }
                    });
                sense = homograph.senses[0];
            }

            return Promise.resolve(sense);
        });
    }

    getAdditionalSenses () {
        var self = this;

        return Promise.resolve().then(() => {
            return self.getHomograph();
        }).then(homograph => {
            let senses = _.filter(homograph.senses, sense => {
                return (sense.uuid !== self._data[self.type].sense_uuid);
            });

           return Promise.resolve(senses);
        });
    }

    getContext () {
        var self = this;

        return Promise.resolve().then(function () {
            return self.getSense();
        }).then(function (sense) {
            var context = _.find(sense.contexts, {uuid: self._data[self.type].context_uuid});

            if (context === undefined) {
                Raven.captureMessage(
                    `Can't find the correct context in sense. Falling back to first context.`,
                    {
                        fingerprint: ['guess', 'question', 'cant_find_context'],
                        level: 'warning',
                        logger: 'manual',
                        extra: {
                            lexical_unit_uuid: self.UUID,
                            sense: sense,
                            sense_uuid: sense.uuid,
                            context_uuid: self._data[self.type].context_uuid,
                            lexical_unit_file_path: self.getLexicalUnitDataURL()
                        }
                    });
                context = sense.contexts[0];
            }

            return Promise.resolve(context);
        });
    }

    getGrammarTable () {
        return Promise.resolve().then( () => {
            return this.loadGrammarTableData(0);
        }).then(data => {
            return Promise.resolve(data.grammarTableData);
        }).catch( error => {
            if (error.name === 'GrammarTableNotAvailableException') {
                return Promise.resolve(null);
            } else {
                Raven.captureException(error, {level: 'error'});
            }
        });
    }

    getRepeatProgress () {

        const interval = this._data.predicted_interval;

        const durationHour = moment.duration(1, 'hours').asSeconds();
        const durationWeek = moment.duration(1, 'weeks').asSeconds();

        if (interval === null) {
            return REPEAT_PROGRESS.NEW;
        } else if (interval < durationHour) {
            return REPEAT_PROGRESS.LOW;
        } else if (interval < durationWeek) {
            return REPEAT_PROGRESS.MEDIUM;
        } else {
            return REPEAT_PROGRESS.MEDIUM_HIGH;
        }
    }

    needsRepeat (time) {
        /**
         * Returns true if the time that the question has to be repeated by has passed
         */

        return moment.utc(this._data.predicted_ts) <= (time || moment.utc());
    }

    isNew () {
        return Question.isNew(this._data);
    }

    isFastTracking () {
        return Question.isFastTracking(this._data);
    }

    isDebug () {
        return Question.isDebug(this._data);
    }

    static isNew (data) {
        /**
         * Evaluates the question data and returns true if the questions is a new question, false otherwise.
         */

        return data.predicted_ts == null && data.new_unit_sn !== null;
    }

    static isFastTracking (data) {
        return data.placement_test;
    }

    static isDebug (data) {
        return data.debug === true;
    }
}
