var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "add-text_svg__add_text_svg__ic-add-some-text-paper",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass:
              "add-text_svg__add_text_svg__ic-add-some-text-paper__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass:
                "add-text_svg__add_text_svg__ic-add-some-text-paper__icon",
              attrs: { fill: "#2C3143", "fill-rule": "nonzero" },
            },
            [
              _c(
                "g",
                {
                  staticClass:
                    "add-text_svg__add_text_svg__ic-add-some-text-paper__icon__group-2",
                },
                [
                  _c("path", {
                    staticClass:
                      "add-text_svg__add_text_svg__ic-add-some-text-paper__icon__group-2__path-19",
                    attrs: {
                      d: "M9 12a1 1 0 01-1.993.117L7 12V4a1 1 0 011.993-.117L9 4v8z",
                    },
                  }),
                  _c("path", {
                    staticClass:
                      "add-text_svg__add_text_svg__ic-add-some-text-paper__icon__group-2__path-19",
                    attrs: {
                      d: "M12 7a1 1 0 01.117 1.993L12 9H4a1 1 0 01-.117-1.993L4 7h8z",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  staticClass:
                    "add-text_svg__add_text_svg__ic-add-some-text-paper__icon__group",
                },
                [
                  _c("path", {
                    staticClass:
                      "add-text_svg__add_text_svg__ic-add-some-text-paper__icon__group__path-19",
                    attrs: {
                      d: "M20 15a1 1 0 01.117 1.993L20 17H4a1 1 0 01-.117-1.993L4 15h16zm0 4a1 1 0 01.117 1.993L20 21H4a1 1 0 01-.117-1.993L4 19h16zm0-8a1 1 0 01.117 1.993L20 13h-6a1 1 0 01-.117-1.993L14 11h6z",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }