<template>
    <section class="message">
        <p v-html="message"></p>
    </section>
</template>

<script>

    export default {
        name: 'relevant-words-message',
        props: {
            message: {
                type: String,
                required: true
            },
        },
        data() {
            return {};
        },
        watch: {},
        components: {},
        methods: {

        },
        computed: {},

        created () {}
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.message {
        font-size: fontSize(16);
        min-height: 3rem;

        @media all and (max-width: em(830,16)) {
            min-height: 4rem;
        }

        @media all and (max-width: em(580,16)) {
            min-height: 5rem;
        }

        ::v-deep em {
            font-weight: bold !important;
        }

        > p {
            text-align: center;
            line-height: 1.25rem;
        }
    }
</style>
