<template>
    <div class="diacritics">
        <div class="diacritic" v-for="item in diacritics" @click="typeDiacritic(item.diacritic)">
            <a class="letter" >{{ item.diacritic }}</a>
            <span class="shortcut">{{ item.shortcut }}</span>
        </div>
        <div class="arrow" />
    </div>
</template>

<script>
    import DiacriticUtils from "Util/diacritic";
    import { EventBus } from "Util/vue-event-bus";

    export default {
        name: 'diacritics',
        props: {
            diacritics: {
                type: Array
            },
            target_language: {
                type: String
            }
        },
        methods: {
            typeDiacritic(character) {
                EventBus.$emit('guess:type-diacritic', character);
            },
            keyPress(event) {
                const character = DiacriticUtils.getDiacriticByShortcut(this.target_language, String.fromCharCode(event.charCode));
                if (character !== undefined) {
                    event.preventDefault();
                    this.typeDiacritic(character);
                }
            },
            keyDown(event) {
                if (event && event.code === 'Escape') { // close on ESC key
                    this.$emit('close');
                }
            }
        },
        mounted() {
            document.addEventListener('keypress', this.keyPress);
            document.addEventListener('keydown', this.keyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keypress', this.keyPress);
            document.removeEventListener('keydown', this.keyDown);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.diacritics {
        bottom: pxToRem(90);
        position: absolute;
        display: flex;
        flex-flow: row wrap;
        z-index: 15;
        width: 100%;
        padding: 0.5rem 1rem;
        justify-content: center;
        min-height: initial;
        background-color: $colorSourcePrimary;
        border-radius: pxToRem(8);
        margin-top: 1.5rem;
        user-select: none;

        > div.arrow {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-top: 18px solid $colorSourcePrimary;
            bottom: -2rem;
            right: 9.2rem;
        }

        &.touch {
            > div.diacritic > span.shortcut {
                display: none;
            }
        }

        > div.diacritic {
            display: flex;
            flex-direction: column;
            padding: 0.5em 0.75em;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background: $colorSourceSecondary;
            }

            > a.letter {
                font-weight: $font-bold;
                color: white;
                width: 0.75em;
                font-size: fontSize(20);
            }

            > span.shortcut {
                padding-top: 0.25em;
                color: #BDDBDF;
                font-size: fontSize(14);
            }
        }
    }
</style>
