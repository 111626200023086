var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M13.283 30.204L19.999 37l13.435-13.593c3.71-3.753 3.71-9.838 0-13.592C29.724 6.062 23.71 6.247 20 10c-3.71-3.753-9.724-3.938-13.434-.185-3.71 3.754-3.71 9.84 0 13.592l1.68 1.7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M22 19h5v4h-5v5h-4v-5h-5v-4h5v-5h4v5z",
          fill: "#AC6ED1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }