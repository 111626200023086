var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9.846 22H7.333C6.045 22 5 20.925 5 19.6v-8.4C5 7.224 8.134 4 12 4s7 3.224 7 7.2v8.4c0 1.325-1.044 2.4-2.333 2.4h-2.513",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 10c-.187-.833-1.125-2-3-2s-2.812 1.167-3 2M6 7L3.707 4.707a1 1 0 010-1.414l1.086-1.086a1 1 0 011.414 0L8.5 4.5M15.5 4.5l2.293-2.293a1 1 0 011.414 0l1.086 1.086a1 1 0 010 1.414L18 7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 15a1 1 0 011-1h6a1 1 0 011 1v3a1 1 0 01-1 1H9a1 1 0 01-1-1v-3z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19 12h2a1 1 0 011 1v6a1 1 0 01-1 1h-2M5 12H3a1 1 0 00-1 1v6a1 1 0 001 1h2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }