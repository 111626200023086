var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M18 10h-4l4 9H9l-2-4c1.974-5.234 7.006-9 13-9 7.443 0 13.605 5.758 14 13h-9l-7-9z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6 25h12M30 25h5a3 3 0 003-3v0a3 3 0 00-3-3H9l-2-4H2l2.5 6.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25 19.01L18 10h-4l4 9.01M25 25l-7 9h-5l5-9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }