var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M19.11 3.745a1 1 0 011.78 0l1.277 2.501a1 1 0 00.734.533l2.772.441a1 1 0 01.55 1.694l-1.983 1.987a1 1 0 00-.28.862l.437 2.774a1 1 0 01-1.441 1.047l-2.502-1.273a1 1 0 00-.907 0l-2.503 1.273a1 1 0 01-1.44-1.047l.437-2.774a1 1 0 00-.28-.862l-1.985-1.987a1 1 0 01.55-1.694l2.774-.44a1 1 0 00.733-.534l1.276-2.5z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 25H7a2 2 0 00-2 2v3m9-5v-4a2 2 0 012-2h8a2 2 0 012 2v9m-12-5v11m12 0v-6m0 6h9v-4a2 2 0 00-2-2h-1m-6 6H14m0 0H5m21-6h2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }