var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M7.4 21.9c-.5.2-1.1 0-1.3-.5L3 14.2V9c0-3.9 3.1-7 7-7h3c3.9 0 7 3.1 7 7v.8l2 5V17h-2v5h-3c-.6 0-1-.4-1-1s.4-1 1-1h1v-5h1.9L18 10.2V9c0-2.8-2.2-5-5-5h-3C7.2 4 5 6.2 5 9v4.8l2.9 6.8c.2.5 0 1.1-.5 1.3z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#63E1E7",
          d: "M6 9c0-2.2 1.8-4 4-4h2.5c2.2 0 4 1.8 4 4v2c0 .6-.4 1-1 1h-4c-.6 0-1 .4-1 1v.5c0 .7-.7 1.2-1.4.9l-2.5-1.1c-.4-.2-.6-.6-.6-.9V9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }