<template>
    <div class="card-container" :class="{ disabled: this.disabled }">
        <locked-icon v-if="disabled" class="locked-icon" />
        <section class="card" :class="{ locked: this.locked, centered: this.isCentered }" v-if="this.question_computed">
            <div class="card-header">
                <fast-tracking v-if="!this.on_previous && this.question_computed.isPlacementTestQuestion" />
                <repeat-progress v-else :question="this.question_computed" :answer="this.answer_computed" />
                <card-more-dropdown v-on="$listeners" :on_previous="this.on_previous" :question="this.question_computed" :word-is-favourite="this.wordIsFavourite" />
            </div>
            <div class="card-main">
                <card-form-spelling :question="this.question_computed" :answer="this.answer_computed" :on-prevous="this.on_previous" :auto-advance="this.autoAdvance" v-on="$listeners" />
                <div class="learn-content">
                    <card-context :question="this.question_computed" :answer="this.answer_computed" :on_previous="this.on_previous" :disabled="this.disabled" :autoPlay="this.autoPlay" :day-insights-shown="this.dayInsightsShown" :onlySynonymAnswers="this.onlySynonymAnswers" v-on="$listeners" />
                    <card-visual v-if="this.cardHasVisuals" :question="this.on_previous ? this.previous_question : this.question" :visuals="this.visuals" v-on="$listeners" />
                </div>
                <transition name="fade">
                    <card-synonym />
                </transition>
                <div class="extra">
                    <card-grammar v-if="this.question_computed.parsed_grammar && this.question_computed.parsed_grammar.hasOwnProperty('fragments') && this.question_computed.parsed_grammar.fragments.length > 0" :parsedGrammar="this.question_computed.parsed_grammar" :full="false" @click.native="toggleGrammar" />
                </div>
            </div>
            <div class="card-footer">
                <card-context-translations :question="question_computed" />
            </div>
        </section>
    </div>
</template>

<script>
    import CardContext from "./card-context.vue"
    import CardContextTranslations from "./card-context-translations.vue"
    import CardVisual from "./card-visual.vue";
    import RepeatProgress from "./repeat-progress.vue";
    import CardGrammar from "./card-grammar.vue";
    import CardMoreDropdown from "./card-more-dropdown.vue";
    import FastTracking from "./fast-tracking.vue";
    import LockedIcon from "Images/locked-40x40.svg";
    import CardSynonym from "./card-synonym.vue";
    import CardFormSpelling from "./card-form-spelling.vue";

    export default {
        name: 'card',
        components: { CardFormSpelling, CardSynonym, CardGrammar, RepeatProgress, CardContext, CardContextTranslations, FastTracking, LockedIcon, CardMoreDropdown, CardVisual },
        props: {
            question: {
                type: Object,
                required: true
            },
            answer: {
                type: Object
            },
            locked: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            on_previous: {
                type: Boolean,
                default: false
            },
            previous_question: {
                type: Object
            },
            previous_answer: {
                type: Object
            },
            autoPlay: {
                type: Boolean,
                default: false
            },
            cardHasVisuals: {
                type: Boolean
            },
            visuals: {
                type: Boolean
            },
            dayInsightsShown: {
                type: Boolean
            },
            onlySynonymAnswers: {
                type: Boolean
            },
            wordIsFavourite: {
                type: Boolean,
                default: false
            },
            autoAdvance: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            question_computed() {
                return this.on_previous ? this.previous_question : this.question;
            },
            answer_computed() {
                return this.on_previous ? this.previous_answer : this.answer;
            },
            isCentered() {
                return !!(this.question_computed && this.question_computed.variationInfo && this.question_computed.variationInfo.features && this.question_computed.variationInfo.features.includes('centered'));
            }
        },
        methods: {
            toggleGrammar() {
                this.$emit('toggle-grammar');
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    $guessCardBorderRadius: pxToRem(12);
    $guessCardPadding: pxToRem(24);

    div.card-container {
        position: relative;
        display: flex;
        justify-content: center;
        flex: none;
        &.disabled {
            pointer-events: none;
            filter: grayscale(100%);
            opacity: 0.8;
            > svg.locked-icon {
                z-index: 100;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        section.card {
            width: 100%;
            flex: 1 1 auto;
            flex-direction: column;
            z-index: 1;
            margin-bottom: 0;
            min-height: pxToRem(318);
            display: flex;
            &.locked {
                pointer-events: none;
            }
            > div.card-header {
                display: flex;
                height: auto;
                padding: pxToRem(16);
                justify-content: space-between;
                align-items: stretch;
                background-color: white;
                border-top-left-radius: $guessCardBorderRadius;
                border-top-right-radius: $guessCardBorderRadius;
                > div.card-more-dropdown {
                    align-self: center;
                }
                @include respond-to('small') {
                    height: pxToRem(60);
                }
            }
            > div.card-main {
                background-color: white;
                display: flex;
                flex: 2;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                > div.learn-content {
                    padding: 0 $guessCardPadding;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    body[data-interface-language="ar"] & {
                        direction: ltr;
                    }
                    > div.card-visual {
                        margin-left: 1rem;
                    }
                    @include respond-to('small') {
                        flex-direction: column;
                        > div.card-visual {
                            margin-left: 0;
                        }
                    }
                }

                > div.card-synonym {
                    padding: .5rem $guessCardPadding;
                }

                > div.extra {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 1rem 1.5rem;
                    > div.card-grammar {
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                    > div.variation-icon {
                        width: pxToRem(24);
                        height: pxToRem(24);
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .fade-enter-active, .fade-leave-active {
                    transition: opacity 250ms;
                }
                .fade-enter, .fade-leave, .fade-leave-to, .fade-leave-active {
                    opacity: 0;
                }
            }
            &.centered {
                > div.card-main {
                    > div.learn-content {
                        justify-content: center;
                    }
                }
                > div.card-footer {
                    > div.card-context-translations {
                        > h3 {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: fontSize(20);
                        }
                    }
                }
            }
        }
    }
</style>
