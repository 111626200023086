
'use strict';

import SingletonFactory from './singleton.factory.js';

export default class ControllerFactory extends SingletonFactory {

    constructor (ControllerClass) {
        super(ControllerClass);
    }

    getController () {
        return this._getInstance.call(this, arguments);
    }
}
