
import ExportUtils from './export.js';

const ResponsivenessUtils = {

    devicePixelRatio: 1,

    initialize: function () {
        if (window.devicePixelRatio != null) {
            this.devicePixelRatio = window.devicePixelRatio;
        }
        var agent = navigator.userAgent.toLowerCase();
        this.isChromeDesktop = (agent.indexOf('chrome') > -1 && ((agent.indexOf('windows') > -1) || (agent.indexOf('macintosh') > -1) || (agent.indexOf('linux') > -1)) && agent.indexOf('mobile') < 0 && agent.indexOf('android') < 0);
        this.isMac = /Mac/.test(navigator.platform);
        this.isIE = agent.indexOf('MSIE ') > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    },

    getWidth: function () {
        if (typeof window.matchMedia !== "function") {
            if (typeof document.body.clientWidth === "number") {
                return document.body.clientWidth;
            }
            else if(typeof window.innerWidth === "number") {
                return window.innerWidth;
            }
            else if(document.documentElement && document.documentElement.clientWidth) {
                return document.documentElement.clientWidth;
            } else {
                return undefined;
            }
        } else if(window.matchMedia('(width:'+window.innerWidth+'px)').matches){
            return window.innerWidth;
        } else if(window.matchMedia('(width:'+document.body.clientWidth+'px)').matches){
            return document.body.clientWidth;
        } else if(window.matchMedia('(width:'+window.outerWidth+'px)').matches){
            return window.outerWidth;
        } else {
            return undefined;
        }
    },

    getHeight: function () {
        if (typeof window.matchMedia !== "function") {
            if (typeof document.body.clientHeight === "number") {
                return document.body.clientHeight;
            }
            else if(typeof window.innerHeight === "number") {
                return window.innerHeight;
            }
            else if(document.documentElement && document.documentElement.clientHeight) {
                return document.documentElement.clientHeight;
            } else {
                return undefined;
            }
        } else if(window.matchMedia('(height:'+window.innerHeight+'px)').matches){
            return window.innerHeight;
        } else if(window.matchMedia('(height:'+document.body.clientHeight+'px)').matches){
            return document.body.clientHeight;
        } else if(window.matchMedia('(height:'+window.outerHeight+'px)').matches){
            return window.outerHeight;
        } else {
            return undefined;
        }
    },

    adjustToDevicePixelRatio: function (distance) {
        return distance * this.devicePixelRatio;
    },

    mediaMaxWidthMatches: function (width) {
        if (typeof window.matchMedia === "function") {
            return window.matchMedia('(max-width:'+this._toEm(width)+'em)').matches;
        } else {
            return this.getWidth() <= width;
        }
    },

    mediaMinWidthMatches: function (width) {
        if (typeof window.matchMedia === "function") {
            return window.matchMedia('(min-width:'+this._toEm(width)+'em)').matches;
        } else {
            return this.getWidth() >= width;
        }
    },

    mediaMaxHeightMatches: function (height) {
        if (typeof window.matchMedia === "function") {
            return window.matchMedia('(max-height:'+this._toEm(height)+'em)').matches;
        } else {
            return this.getHeight() <= height;
        }
    },

    mediaMinHeightMatches: function (height) {
        if (typeof window.matchMedia === "function") {
            return window.matchMedia('(min-height:'+this._toEm(height)+'em)').matches;
        } else {
            return this.getHeight() >= height;
        }
    },

    _toEm: function (x) {
        return x / 16;
    },

    hasTouch: function () {
        if (this.isChromeDesktop && 'ontouchstart' in window && navigator.maxTouchPoints === 0) {
            return false;
        }
        return 'ontouchstart' in window;
    },

    guessInMobileView: function () {
        return (this.mediaMaxWidthMatches(600) || this.mediaMaxHeightMatches(600));
    }
};

ResponsivenessUtils.initialize();

ExportUtils.export('app.util.responsiveness', ResponsivenessUtils);

export default ResponsivenessUtils;
