var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("circle", { attrs: { cx: "12", cy: "12", r: "11", fill: "#000" } }),
      _c("g", { attrs: { "clip-path": "url(#nyt_svg__clip0_2252_21514)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M17.832 13.86c-.509 1.342-1.444 2.379-2.786 2.928V13.86l1.607-1.443-1.607-1.423V8.98c1.464-.101 2.48-1.179 2.48-2.5 0-1.728-1.646-2.338-2.582-2.338-.203 0-.426 0-.752.081v.081c.122 0 .305-.02.366-.02.65 0 1.139.305 1.139.895 0 .447-.366.894-1.017.894-1.606 0-3.497-1.3-5.55-1.3-1.83 0-3.09 1.361-3.09 2.743 0 1.363.792 1.81 1.626 2.115l.02-.082c-.264-.162-.447-.447-.447-.894 0-.61.57-1.118 1.28-1.118 1.73 0 4.515 1.443 6.243 1.443h.162v2.033l-1.606 1.403 1.606 1.443v2.968a6.004 6.004 0 01-2.073.346c-2.684 0-4.392-1.627-4.392-4.33 0-.65.081-1.281.264-1.89l1.342-.59v5.976l2.724-1.2V9.023L8.784 10.81a4.374 4.374 0 012.237-2.521L11 8.229c-2.685.59-5.287 2.622-5.287 5.672 0 3.517 2.968 5.956 6.425 5.956 3.66 0 5.733-2.44 5.754-5.997h-.061z",
            fill: "#fff",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "nyt_svg__clip0_2252_21514" } }, [
          _c("path", {
            attrs: {
              fill: "#fff",
              transform: "translate(5.714 4.143)",
              d: "M0 0h12.222v15.889H0z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }