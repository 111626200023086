var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-drawer" },
    [
      _vm.userCourse ? _c("burger", { attrs: { open: this.open } }) : _vm._e(),
      _vm._v(" "),
      _vm.userCourse && _vm.isRouteAllowed
        ? _c(
            "aside",
            {
              class: { open: _vm.open },
              on: { mouseenter: _vm.openDrawer, mouseleave: _vm.closeDrawer },
            },
            [
              _c("navigation", {
                attrs: {
                  route: _vm.route,
                  open: _vm.open,
                  pinned: _vm.pinned,
                  "user-course": _vm.userCourse,
                  "game-over": _vm.gameOver,
                  "subscription-info": _vm.subscriptionInfo,
                  "interface-language": _vm.interfaceLanguage,
                  "is-mobile": _vm.isMobile,
                },
                on: { togglePinned: _vm.togglePinned },
              }),
              _vm._v(" "),
              _vm.learningProgressVisible
                ? _c("learning-progress", {
                    attrs: { "user-course": _vm.userCourse, open: _vm.open },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("controls", {
                attrs: {
                  open: _vm.open,
                  "game-over": _vm.gameOver,
                  "user-course": _vm.userCourse,
                  "interface-language": _vm.interfaceLanguage,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }