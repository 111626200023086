var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M11 21a8 8 0 11-5.657 2.343M14 7h-1a4 4 0 00-4 4v6M31 7h2a4 4 0 014 4v14a4 4 0 01-4 4H23M22 7h1M18 5v3M27 5v3M11 29l-3-3M11 29v3.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 14a2 2 0 012-2h13a2 2 0 110 4H16a2 2 0 01-2-2z",
          fill: "#8EA3EE",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }