<template>
    <div class="calendar-page">
        <div class="calendar-flex">
            <insights-calendars :user-info="this.userInfo" :course="this.course" v-on:day-selected="calendarDaySelected" />
            <div class="additional-information">
                <collapsible-box :i18n_title="{ id: 'calendar_total_days_learnt_title' }" :title-meta="totalDaysLearned" :i18n_text="{ id: 'calendar_total_days_learnt_description' }">
                    <template v-slot:icon>
                        <total-days-icon />
                    </template>
                </collapsible-box>
                <collapsible-box :i18n_title="{ id: 'calendar_days_streak_title' }" :title-meta="consecutiveDaysLearned" :i18n_text="{ id: 'calendar_days_streak_description' }">
                    <template v-slot:icon>
                        <correct-rate-icon />
                    </template>
                </collapsible-box>
                <collapsible-box :no-background="true" :i18n_title="{ id: 'daily_performance_stats_metric_cards' }" :title-meta="totalCardsAnswered">
                    <template v-slot:icon>
                        <cards-answered-icon />
                    </template>
                </collapsible-box>
                <div class="separator" />
                <collapsible-box :no-background="true" :i18n_title="{ id: 'elastic_goals_calendar_metric_total_time' }" :title-meta="totalTimeSpent">
                    <template v-slot:icon>
                        <time-spent-icon />
                    </template>
                </collapsible-box>
            </div>
        </div>
        <transition name="slide-fade">
            <day-insights v-if="this.selectedCalendarDay" :day="this.selectedCalendarDay.moment" v-on:close="closeDayInsights" />
        </transition>
    </div>
</template>

<script>
    import Backbone from "backbone";
    import UserManager from "Modules/usermanager.js";
    import i18nUtils from "Util/i18n.js";
    import CollapsibleBox from "ViewComponent/collapsible-box/main.vue";
    import InsightsCalendars from "./calendars.vue";
    import TotalDaysIcon from "Images/insights/ic_stats_total_days_learnt.svg";
    import CardsAnsweredIcon from "Images/insights/ic_stats_cards_answered.svg";
    import TimeSpentIcon from "Images/insights/ic_stats_time_spent_light.svg";
    import CorrectRateIcon from "Images/insights/ic_stats_correct_rate.svg";
    import DayInsights from "../components/day-insights.vue";

    export default {
        name: 'calendar-page',
        components: {
            DayInsights,
            InsightsCalendars,
            CollapsibleBox,
            TotalDaysIcon,
            CardsAnsweredIcon,
            TimeSpentIcon,
            CorrectRateIcon
        },
        data() {
            return {
                course: null,
                userInfo: null,
                statisticsData: null,
                selectedCalendarDay: null
            }
        },
        computed: {
            totalDaysLearned() {
                return (this.statisticsData) ? this.statisticsData.total_days_learned : 0;
            },
            consecutiveDaysLearned() {
                let current_streak_days = (this.statisticsData) ? this.statisticsData.consecutive_days_learned : 0;
                return i18nUtils.prop('calendar_days_streak_value', { current_streak_days });
            },
            totalCardsAnswered() {
                return (this.statisticsData && this.statisticsData.all_units) ? this.statisticsData.all_units.total : 0;
            },
            totalTimeSpent() {
                let studyTimeInSeconds = (this.statisticsData) ? this.statisticsData.study_time : 0;

                return i18nUtils.getHumanizedDuration(studyTimeInSeconds);
            }
        },
        methods: {
            async setCourse() {
                if (UserManager.instance.getUser().hasCourse()) {
                    this.course = UserManager.instance.getUser().getCourse();
                } else {
                    // navigate to HUB when course is not set
                    Backbone.history.navigate('hub', { trigger: true });
                }
            },
            setUserInfo() {
                this.userInfo = UserManager.instance.getUser().getInfo();
            },
            getAndSetStatisticsData() {
                this.statisticsData = this.course.getStatistics().getData();
            },
            calendarDaySelected(day) {
                this.selectedCalendarDay = day;
            },
            closeDayInsights() {
                this.selectedCalendarDay = null;
            }
        },
        created() {
            this.setCourse();
            this.setUserInfo();
            this.getAndSetStatisticsData();
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.calendar-page {
        > div.calendar-flex {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            > div.additional-information {
                grid-area: additional-information;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                padding: 1rem;
                border-radius: pxToRem(8);
                background-color: #fff;
                > div.separator {
                    background-color: $colorBackgroundLighter;
                    height: 1px;
                    width: 100%;
                }
            }
        }
        > div.day-insights {
            &.slide-fade-enter-active {
                transition: transform 250ms ease, opacity 350ms linear;
            }
            &.slide-fade-leave-active {
                transition: transform 200ms ease, opacity 100ms linear;
            }
            &.slide-fade-enter, &.slide-fade-leave-to {
                transform: translateX(200px);
                opacity: 0;
            }
        }
    }
</style>
