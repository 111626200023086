<template>
    <div class="fast-tracking">
        <div class="dot-container">
            <span class="pulse">
                <span class="pulse-blob" />
            </span>
        </div>
        <div class="message" v-html="this.$i18n('fast_tracking_message')" />
    </div>
</template>

<script>
    export default {
        name: 'fast-tracking'
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    div.fast-tracking {
        display: flex;
        align-items: center;

        > div.dot-container {
            width: fontSize(40);
            display: flex;
            justify-content: center;
        }

        span.pulse {
            display: flex;
            flex-direction: row;
            align-items: center;
            user-select: none;
            opacity: 0.6;
            span.pulse-blob {
                display: inline-block;
                border-radius: 50%;
                margin-right: .8rem;
                height: .6rem;
                width: .6rem;
                transform: scale(1);
                background: rgba(108, 213, 229, 1);
                box-shadow: 0 0 0 0 rgba(108, 213, 229, 1);
                animation: pulse-blue 1100ms infinite;
            }
        }

        @keyframes pulse-blue {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(108, 213, 229, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(108, 213, 229, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(108, 213, 229, 0);
            }
        }
    }
</style>
