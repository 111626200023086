
'use strict';

import _ from 'lodash';
import Raven from 'raven-js';

export class QuestionList {

    constructor (course, sortingParameters, assetLoader, Modules) {
        this.__name = 'QuestionList';
        // Question map is the main storage for questions
        this._course = course;
        this._courseUUID = this._course.UUID;
        this._questionMap = {};
        this.length = 0;
        this._Modules = Modules;
        this._assetLoader = assetLoader;

        this._sortingParameters = sortingParameters;
        this._Sorter = this._Modules.Sorter;
    }

    destroy () {
        var self = this;

        return Promise.resolve().then(function () {
            return Promise.all(_.transform(self._questionMap,
                (result, question, key) => {
                    result.push(question.destroy());
                    delete self._questionMap[key];
                }, []));
        }).then(function () {
            delete self._questions;
            delete self._questionMap;
            delete self._assetLoader;
            delete self._guessQueue; // This is present in the FastTrackingList subclass!
            delete self._course;
        });
    }

    load (state) {
        /**
         * Loads the QuestionList state from a simple object (produced by dump)
         */

        this.updateQuestions(state.questions, {}); // Empty filter parameters
    }

    /**
     * Dumps the QuestionList state into a simple array of question data
     * @return {[]}
     */
    serialize () {
        return _.values(this._questionMap).map(question => question.serialize());
    }

    _sort () {
        this._questions = new this._Sorter(this._sortingParameters).sort(_.values(this._questionMap));
    }

    _load () {
        var self = this;

        this._questions.forEach(function (question, i) {
            Promise.resolve().then(function () {
                return question.loadData(self.ASSET_LOADING_PRIORITY);
            }).then(function () {
                return Promise.resolve().then( function () {
                    return question.loadGrammarTableData(self.ASSET_LOADING_PRIORITY);
                }).catch( function (error) {
                    if (error.name !== 'GrammarTableNotAvailableException') {
                        Raven.captureException(error, {level: 'error'});
                    }
                });
            }).catch(function (error) {
                if (self._questionMap !== undefined && self._questionMap[question.UUID] !== undefined) {
                    delete self._questionMap[question.UUID];
                    question.destroy();
                    self.length = self._questions.length;

                    if (error.name !== 'RequestAbortedException') {
                        Raven.captureException(error, {level: 'error',
                            fingerprint: error.name === 'AssetLoadingFailedException' ? ['AssetLoadingFailedException', error.status] : undefined});
                    }
                }
            });
        });
    }

    updateQuestions (questions, filterParameters) {
        /**
         * :returns: questions that weren't consumed
         * */
        var self = this;

        var questionsConsumed = [];

        questions.forEach(function (questionData) {
            // Only add new words to this list
            if (self._questionFilter(questionData, filterParameters)) {
                questionsConsumed.push(questionData);
                if (self._questionMap[questionData.lexical_unit_uuid] === undefined) {
                    self._questionMap[questionData.lexical_unit_uuid] = new self._Modules.Question(self._course, questionData, self._assetLoader);
                } else {
                    self._questionMap[questionData.lexical_unit_uuid].update(questionData);
                }
            } else {
                // If there is not new_unit_sn the word is not new anymore so make sure it is not in this list
                delete self._questionMap[questionData.lexical_unit_uuid];
            }
        });

        console.debug(`${this.__name} updateQuestions with ${questions.length} questions. ${questionsConsumed.length} passed filter`);

        this._sort();
        this._load();
        this.length = this._questions.length;

        return _.difference(questions, questionsConsumed);
    }

    updateWithExistingQuestion (question) {
        // No filtering is done here, the question must belong to this list !
        this._questionMap[question.UUID] = question;
        this._sort();
        this.length = this._questions.length;
    }

    popFirst () {
        if (this.length === 0) {
            throw Error(`${this.constructor.name} is empty`);
        }

        var firstQuestion = this._questions.splice(0, 1)[0];
        delete this._questionMap[firstQuestion.UUID];

        this.length = this._questions.length;

        return firstQuestion;
    }

    peekFirst () {
        if (this.length === 0) {
            throw Error(`${this.constructor.name} is empty`);
        }

        return this._questions[0];
    }

    hasQuestion (uuid) {
        return this._questionMap[uuid] !== undefined;
    }

    getQuestionsInfo () {
        return this._questions.map(question => question.getInfo());
    }

    /**
     * Updates the Sorter Class used
     * @param Sorter - A QuestionList Sorter Class to use when sorting the list
     */
    setSorter (Sorter) {
        this._Sorter = Sorter;
    }
}
