<template>
    <div class="controls" :class="{ open }">
        <a class="settings"
           role="button"
           @click="openSettings"
           v-if="userCourse"
           :class="{ disabled: this.gameOver }"
           tabindex="0">
            <div class="icon">
                <settings-icon />
            </div>
            <transition name="fade">
                <div class="label" v-show="open" v-html="this.$i18n('learn_settings_title')" />
            </transition>
        </a>
    </div>
</template>

<script>
    import ArrowIcon from 'Images/arrow-3.svg';
    import SettingsIcon from 'Images/menu/settings.svg';
    import { EventBus } from 'Util/vue-event-bus';

    export default {
        name: 'home-drawer.controls',
        components: {
            ArrowIcon,
            SettingsIcon
        },
        props: {
            userCourse: {
                type: Object,
                required: true
            },
            interfaceLanguage: {
                type: String
            },
            open: {
                type: Boolean,
                default: false
            },
            gameOver: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            openSettings() {
                if (!this.gameOver) {
                    EventBus.$emit('settings:open');
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.controls {
        > a.settings {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.25s;
            border-radius: .5rem;
            padding: .5rem;

            > div.icon {
                width: pxToRem(24);
                height: pxToRem(24);
                display: flex;
                justify-content: center;
                align-items: center;
                > svg {
                    > g, > path {
                        stroke: $colorSourceSecondary;
                    }
                }
            }

            > div.label {
                flex: 1;
                color: $colorSourceSecondary;
            }

            &:hover {
                background-color: rgba($colorBackgroundLighter, 0.75);
            }

            &:focus {
                outline: none;
                background-color: $colorBackgroundLighter;
            }

            &.disabled {
                > div.label {
                    color: $colorSourceTertiary;
                }

                > div.icon {
                    > svg {
                        > g, > path {
                            stroke: $colorSourceTertiary;
                        }
                    }
                }

                &:hover {
                    cursor: not-allowed;
                }
            }

            .fade-enter-active {
                transition: opacity 500ms;
            }
            .fade-enter, .fade-leave, .fade-leave-to {
                opacity: 0;
            }
        }
    }
</style>
