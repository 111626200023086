<template>
    <main class="account">
        <confirmation-modal v-if="this.confirmationModalContent" :content="this.confirmationModalContent" v-on:close="closeConfirmationModal" v-on:action="triggerAction" />
        <support-modal v-if="supportModalVisible" v-on:close="closeSupportModal" v-on:send="sendFeedback" />
        <div class="profile">
            <h1 v-html="this.$i18n('account_title')" />
            <section-name :name="this.fullName" v-on:save="saveName" />
            <section-email :email="this.email" :emailVerified="this.emailVerified" v-on:save="saveEmail" />
            <section-password v-on:save="savePassword" v-if="this.passwordSet" />
            <template v-else>
                <section-password-create v-on:save="createPassword" />
                <hr />
            </template>

            <section-discount :opt-in="this.marketing_opt_in" v-on:save="saveOptIn" />
            <hr />
            <section-action :title="this.$i18n('account_additional_settings_data_download_lbl')" :description="this.$i18n('account_additional_settings_data_download_txt')" action="open-download-modal" v-on:action="triggerAction" />
            <hr />
            <section-action :title="this.$i18n('account_profile_delete_your_account_title')" :description="this.$i18n('account_profile_delete_your_account_info')" action="open-delete-modal" v-on:action="triggerAction" />
            <hr />
            <section-action :title="this.$i18n('account_additional_settings_customer_support_lbl')" action="support" v-on:action="triggerAction" />
            <hr />
            <section-action :title="this.$i18n('account_profile_trustpilot_title')" action="trustpilot" v-on:action="triggerAction" />
            <hr />
            <v-button class="signOutButton" :i18n="{ id: 'menu_sign_out' }" @click.native="signOut" :filled="true" color="primary" />
            <terms message_key="account_legal_link" />
        </div>
        <div class="subscription">
            <subs-main v-on:cancel-subscription-modal="showCancelSubscriptionConfirm" />
        </div>
    </main>
</template>

<script>

    import Backbone from "backbone";
    import googleAnalyticsCommand from "Util/google-analytics.js";
    import UserModel from "Model/user.model.js";
    import UserManager from "Modules/usermanager";
    import VButton from "ViewComponent/v-button.vue";
    import getConfigValue from "Util/configuration.js";
    import _ from "lodash";
    import i18nUtils from "Util/i18n.js";
    import { TYPE as TOAST_TYPE } from "../toaster/constants.js";
    import { EventBus } from "Util/vue-event-bus.js";
    import SectionName from "./components/section-name.vue";
    import SectionEmail from "./components/section-email.vue";
    import SectionDiscount from "./components/section-discount.vue";
    import SectionAction from "./components/section-action.vue";
    import ConfirmationModal from "./components/confirmation-modal.vue";
    import SupportModal from "./components/support-modal.vue";
    import AppState from "Modules/app.state.js";
    import SectionPassword from "./components/section-password.vue";
    import SubsMain from "./components/subs-main.vue";
    import SectionPasswordCreate from "./components/section-create-password.vue";
    import Terms from "ViewComponent/terms.vue";

    const SIGNOUT_DELAY = 1000;

    export default {
        name: 'account',
        components: {
            Terms,
            SectionPasswordCreate,
            SubsMain,
            SectionPassword, SupportModal, ConfirmationModal, SectionAction, SectionDiscount, VButton, SectionName, SectionEmail },
        data() {
            return {
                fullName: null,
                email: null,
                subscription: null,
                emailVerified: null,
                passwordSet: null,
                marketing_opt_in: null,
                subscriptionStatus: null,
                isLimitFreeInput: null,
                confirmationModalContent: null,
                supportModalVisible: false,
                cancel_subscription_uuid: null,
                syncNeeded: false
            }
        },
        methods: {
            saveName(name) {
                this.submit({ name });
            },
            saveEmail({ email, password }) {
                this.submit({ email, changeEmailPassword: password });
            },
            saveOptIn(state) {
                this.marketing_opt_in = state;
                this.submit({ marketing_opt_in: state })
            },
            savePassword({ current_password, new_password }) {
                this.submit({ newPassword: new_password, password: current_password });
            },
            createPassword({ new_password }) {
                this.submit({ initializePassword: new_password });
            },
            sendFeedback(message) {
                let appState = new AppState();
                UserManager.instance.getUser().getEventSender().sendFeedbackEvent({
                    content: message,
                    mood: 'okay',
                    category: 'account_and_settings'
                }, appState, UserManager.instance.getUser().getSubscription().getStatus());
                this.showSuccessToast('feedback');
                this.closeSupportModal();
            },
            openTrustPilot() {
                window.open("https://www.trustpilot.com/review/lingvist.com", "_blank");
            },
            async triggerAction(action) {
                switch(action) {
                    case 'cancel':
                        this.closeConfirmationModal();
                        this.closeSupportModal();
                        break;
                    case 'open-download-modal':
                        this.confirmationModalContent = {
                            title: i18nUtils.prop('account_profile_data_download_confirmation_title'),
                            description: i18nUtils.prop('account_profile_data_download_confirmation_description'),
                            actions: [
                                {
                                    text: i18nUtils.prop('account_profile_cancel_btn'),
                                    action: 'cancel'
                                },
                                {
                                    text: i18nUtils.prop('account_profile_data_download_confirm_btn'),
                                    action: 'download-confirm',
                                    primary: true
                                }
                            ]
                        }
                        break;
                    case 'open-delete-modal':
                        this.confirmationModalContent = {
                            title: i18nUtils.prop('account_profile_delete_your_account_confirmation_title'),
                            description: i18nUtils.prop('account_profile_delete_your_account_confirmation_text'),
                            actions: [
                                {
                                    text: i18nUtils.prop('account_profile_delete_your_account_confirmation_cancel_button'),
                                    action: 'cancel'
                                },
                                {
                                    text: i18nUtils.prop('account_profile_delete_your_account_confirmation_send_email'),
                                    action: 'delete-confirm',
                                    primary: true
                                }
                            ]
                        }
                        break;
                    case 'open-delete-apple-modal':
                        this.confirmationModalContent = {
                            title: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_title'),
                            description: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_text'),
                            actions: [
                                {
                                    text: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_cancel_btn'),
                                    action: 'cancel'
                                },
                                {
                                    text: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_apple_guide_btn'),
                                    action: 'delete-apple-confirm',
                                    primary: true
                                }
                            ]
                        }
                        break;
                    case 'download-confirm':
                        await UserManager.instance.getUser().getEventSender().sendDataDownloadRequestEvent();
                        this.showSuccessToast('download');
                        this.closeConfirmationModal();
                        break;
                    case 'delete-confirm':
                        const subscription = UserManager.instance.getUser().getSubscription();
                        const paySubscriptionInfo = await subscription.getPaySubscriptionInfo();
                        this.closeConfirmationModal();
                        if (paySubscriptionInfo && paySubscriptionInfo.subscription && paySubscriptionInfo.subscription.is_recurring && paySubscriptionInfo.payment_provider === 'apple-in-app') {
                            await this.triggerAction('open-delete-apple-modal')
                        } else {
                            // await new URI('lingvist:delete-account').navigateTo();
                            await UserManager.instance.getUser().getEventSender().sendDeletionRequestEvent();
                            this.showSuccessToast('delete');
                        }
                        break;
                    case 'delete-apple-confirm':
                        this.closeConfirmationModal();
                        window.open('https://support.apple.com/kb/ht4098', '_blank');
                        break;
                    case 'support':
                        this.openSupportModal();
                        break;
                    case 'trustpilot':
                        this.openTrustPilot();
                        break;
                    case 'cancel-subscription-confirm':
                        this.cancelSubscription();
                        break;
                }
            },
            showCancelSubscriptionConfirm(subscription_uuid) {
                this.cancel_subscription_uuid = subscription_uuid;
                this.confirmationModalContent = {
                    title: i18nUtils.prop('account_subscription_cancel_title'),
                    description: i18nUtils.prop('account_subscription_cancel_text'),
                    actions: [
                        {
                            text: i18nUtils.prop('account_subscription_cancel_dismiss'),
                            action: 'cancel'
                        },
                        {
                            text: i18nUtils.prop('account_subscription_cancel_confirm'),
                            action: 'cancel-subscription-confirm',
                            primary: true
                        }
                    ]
                }
            },
            async cancelSubscription() {
                this.closeConfirmationModal();
                if (this.cancel_subscription_uuid) {
                    Backbone.trigger('showLoader', 'account/subscriptions');
                    try {
                        await UserManager.instance.getUser().getSubscription().cancelSubscription(this.cancel_subscription_uuid);
                    } catch (error) {
                        console.error('Error cancelling subscription', error);
                    } finally {
                        Backbone.trigger('hideLoader');
                    }
                    this.cancel_subscription_uuid = null;
                    EventBus.$emit('account:refresh-services', 4000);
                }
            },
            closeConfirmationModal() {
                this.confirmationModalContent = null;
            },
            openSupportModal() {
                this.supportModalVisible = true;
            },
            closeSupportModal() {
                this.supportModalVisible = false;
            },
            signOut() {
                return Promise.resolve().then( () => {
                    return new Promise( resolve => {
                        Backbone.trigger('signoutStart', 'account/signout');
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('application', 'signout');
                        googleAnalyticsCommand('send', 'event', 'Activity', 'SignOut');
                        setTimeout( () => { resolve(); }, SIGNOUT_DELAY);
                    });
                }).then( () => {
                    return UserModel.signout(false);
                }).then( () => {
                    Backbone.trigger('signoutStop', 'account/signout');
                });
            },
            showSuccessToast(type) {
                let toastText = null;
                switch (type) {
                    case 'name':
                        toastText = i18nUtils.prop('settings_information_name_change_success');
                        break;
                    case 'email':
                        toastText = i18nUtils.prop('settings_information_email_change_success');
                        break;
                    case 'download':
                        toastText = i18nUtils.prop('account_additional_settings_data_download_confirmation_txt');
                        break;
                    case 'delete':
                        toastText = i18nUtils.prop('account_profile_delete_your_account_confirmation_text');
                        break;
                    case 'feedback':
                        toastText = i18nUtils.prop('text_feedback_successful');
                        break;
                    case 'password':
                        toastText = i18nUtils.prop('settings_password_change_success');
                        break;
                    case 'password-initialized':
                        toastText = i18nUtils.prop('settings_password_initialized');
                        break;
                }
                if (toastText) {
                    const toast = {
                        text: toastText,
                        type: TOAST_TYPE.SUCCESS,
                        time: 6000
                    };
                    EventBus.$emit('toaster-add', toast);
                }
            },
            showErrorToast(type) {
                let toastText = null;
                switch (type) {
                    case 'password':
                        toastText = i18nUtils.prop('settings_error_incorrect_password');
                        break;
                    case 'server':
                        toastText = i18nUtils.prop('settings_error_server');
                        break;
                    case 'settings_error_email_changed_no_password':
                        toastText = i18nUtils.prop('settings_error_email_changed_no_password');
                        break;
                    case 'settings_error_invalid_email':
                        toastText = i18nUtils.prop('settings_error_invalid_email');
                        break;
                    case 'settings_password_error_empty_password':
                        toastText = i18nUtils.prop('settings_password_error_empty_password');
                        break;
                    case 'error-authentication':
                        toastText = i18nUtils.prop('settings_error_incorrect_password');
                        break;
                }
                if (toastText) {
                    const toast = {
                        text: toastText,
                        type: TOAST_TYPE.ERROR,
                    };
                    EventBus.$emit('toaster-add', toast);
                }
            },
            async getProfileData () {
                const user = await UserManager.instance.getUser();
                const subscription = user.getSubscription();
                this.subscriptionStatus = subscription.getStatus().status;

                if (user && user.hasOwnProperty('profile')) {
                    this.fullName = user.profile.name;
                    this.email = user.profile.email;
                    this.marketing_opt_in = user.profile.marketing_opt_in;
                }
                this.emailVerified = user.getAuthentication().email_verified;
                this.passwordSet =  user.getAuthentication().is_password_set();
                this.isLimitFreeInput = getConfigValue('limit-user-free-input');
            },
            saveSettingsRejected(errors) {
                Backbone.trigger('saveSettingsProcessFinished');
                console.log('account: errors', errors);
                errors.forEach(error => {
                    switch (error.name) {
                        case 'name':
                            switch (error.status) {
                                case 'error':
                                    this.showErrorToast('server');
                                    break;
                            }
                            break;

                        case 'email':
                            switch (error.status) {
                                case 'error':
                                    this.showErrorToast('server');
                                    break;
                                case 'error-email-changed-no-password':
                                    this.showErrorToast('settings_error_email_changed_no_password');
                                    break;
                                case 'error-invalid-email':
                                    this.showErrorToast('settings_error_invalid_email');
                                    break;
                                case 'error-authentication':
                                    this.showErrorToast('settings_error_incorrect_password');
                                    break;
                            }
                            break;

                        case 'password':
                            switch (error.status) {
                                case 'error':
                                    this.showErrorToast('server');
                                    break;
                                case 'error-empty-password':
                                    this.showErrorToast('settings_password_error_empty_password');
                                    break;
                            }
                            break;
                    }
                });
            },
            saveSettingsResolved(status) {
                Backbone.trigger('saveSettingsProcessFinished', status);
                this.syncNeeded = true;
                if (status && status.length > 0 && status[0].hasOwnProperty('object')) {
                    let _status = status[0].status;
                    let _object = status[0].object;
                    let _value = (status[0].hasOwnProperty('value')) ? status[0].value : null;

                    switch(_status) {
                        case 'changed-success':
                        case 'success':
                            this.showSuccessToast(_object);
                            if (_object && _value) {
                                this.updateLocals(_object, _value);
                            }
                            break;
                        case 'initialized':
                            this.showSuccessToast('password-initialized');
                            this.passwordSet = true;
                            break;
                        case 'error-authentication':
                            this.showErrorToast(_status);
                    }
                }
            },
            updateLocals(field, value) {
                switch(field) {
                    case 'email':
                        this.email = value;
                        break;
                    case 'name':
                        this.fullName = value;
                        break;
                }
            },
            async submit (inputData) {
                if (!_.isEmpty(inputData)) {
                    Backbone.trigger('settingsSaveInProgress', 'settings');

                    await UserModel.saveSettings(inputData).then(
                        status => {
                            this.saveSettingsResolved(status);
                        },
                        errors => {
                            this.saveSettingsRejected(errors);
                        }
                    );
                }
            },
        },

        beforeMount() {
            this.getProfileData();
        },

        beforeDestroy() {
            if (this.syncNeeded) {
                UserManager.instance.getUser().sync();
            }
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    main.account {
        display: flex;
        flex: auto;
        flex-direction: row;
        max-width: pxToRem(1100);
        min-width: pxToRem(1100);
        //max-height: pxToRem(900);
        margin: 0 auto;
        padding: 1.5rem;
        gap: 1.5rem;
        @include respond-to('medium') {
            max-width: unset;
            min-width: unset;
            max-height: unset;
        }
        @include respond-to('small') {
            flex-direction: column;

            width: 100%;
        }
        > div.profile,
        > div.subscription {
            background-color: #fff;
            padding: 1rem;
            border-radius: .5rem;
            h1 {
                font-size: fontSize(22);
                line-height: fontSize(26);
                text-transform: capitalize;
                margin-bottom: 2rem;
                @include respond-to('small') {
                    margin-bottom: 1rem;
                }
            }
            hr {
                margin: .5rem .5rem;
                border: none;
                border-bottom: pxToRem(1) solid $colorBackgroundDarker;
            }
        }

        > div.profile {
            flex: 1;
            display: flex;
            flex-direction: column;
            > section.name,
            > section.email,
            > section.password {
                margin-bottom: 1rem;
            }
            > section.discount {
                margin-bottom: .5rem;
            }
            > button.signOutButton {
                margin-top: 2rem;
                justify-self: flex-end;
            }
            > span.terms-and-conditions {
                display: block;
                text-align: center;
                font-size: fontSize(12);
            }
        }

        > div.subscription {
            width: pxToRem(400);
            @include respond-to('medium') {
                width: unset;
            }
            @include respond-to('small') {
                width: 100%;
            }
        }
    }
</style>
