var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7 14.667v.933l5 2.4 5-2.4V12",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 11.577l1 .485L12 14l10-4.846L12 5 2 9.154l2 .969M20 11v4.2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", { attrs: { cx: "20", cy: "18", r: "1", fill: "#AC6ED1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }