<template>
    <div class="wordlist-item" @mouseover="itemIsHovered = true" @mouseleave="itemIsHovered = false" v-if="word && course" :class="{ isSelected, isPlaying: playlistIsPlayingThis }">
        <div class="tags">
            <wordlist-tag-icon icon="mute" :semi-hover="itemIsHovered" :active="word.muted" @click.native="muteIconClicked" />
            <wordlist-tag-icon icon="favourite" :semi-hover="itemIsHovered" :active="word.favourite" @click.native="favIconClicked" />
            <wordlist-tag-icon icon="playlist" :semi-hover="itemIsHovered" :active="word.in_playlist" @click.native="playlistIconClicked" />
        </div>
        <div class="content-container">
            <template v-if="!inverted">
                <div class="homograph" v-if="word.homograph" @click="playAudio('form')" :class="{ audioIsPlaying: formAudioIsPlaying }">
                    <span class="target">
                        <span class="begin" v-if="word.homograph && word.homograph.begin_form">{{ word.homograph.begin_form }}</span>
                        {{ word.homograph.form }}
                        <span class="end" v-if="word.homograph && word.homograph.end_form">{{ word.homograph.end_form }}</span>
                    </span>
                    <speaker-button :in-progress="audioIsPlaying" />
                </div>
                <div class="context target" v-if="word.context && word.context.context" @click="playAudio('context')" :class="{ audioIsPlaying: contextAudioIsPlaying }">
                    {{ word.context.context }}
                </div>
            </template>
            <template v-else>
                <div class="sense-translation" v-if="word.sense && word.sense.translations" @click="playAudio('form')" :class="{ audioIsPlaying: formAudioIsPlaying }">
                    <span class="translations-wrapper">
                        <template v-for="(item, index) in word.sense.translations">
                            <span class="translation">{{ item.translation }}</span>
                            <span v-if="index+1 < word.sense.translations.length" class="comma">, </span>
                        </template>
                    </span>
                    <speaker-button :in-progress="audioIsPlaying" />
                </div>
                <div class="context-translations" v-if="word.context && word.context.translations" @click="playAudio('context')" :class="{ audioIsPlaying: contextAudioIsPlaying }">
                    <div v-for="(item) in word.context.translations">
                        {{ item.translation }}
                    </div>
                </div>
            </template>
            <div class="meta">
                <span class="data-point">{{ wordLastSeen }}</span> | <span class="data-point" v-html="this.$i18n('learned_words_word_guess_count', { args: { guess_count: word.guess_count }})" />
            </div>
        </div>
        <div class="open-sidebar" @click="selectWord">
            <open-sidebar-icon />
        </div>
    </div>
</template>

<script>
    import WordlistTagIcon from "./tag-icon.vue";
    import i18nUtils from "Util/i18n.js";
    import SpeakerButton from "./speaker-button.vue";
    import getConfigValue from "Util/configuration.js";
    import AudioPlayer from "Util/audioplayer.js";
    import UserManager from "Modules/usermanager.js";
    import OpenSidebarIcon from 'Images/ic_utility_right_light.svg';

    export default {
        name: 'wordlist-item',
        components: { SpeakerButton, WordlistTagIcon, OpenSidebarIcon },
        props: {
            word: {
                type: Object,
                required: true
            },
            course: {
                type: Object,
                required: true
            },
            inverted: {
                type: Boolean,
                default: false
            },
            isSelected: {
                type: Boolean,
                default: false
            },
            currentlyPlayingWordUUID: {
                type: String
            },
            currentlyPlayingWordAudioType: {
                type: String
            }
        },
        data() {
            return {
                itemIsHovered: false,
                audioIsPlaying: false,
                audioTypePlaying: null
            }
        },
        computed: {
            wordLastSeen() {
                if (this.word && this.word.guess_ts) {
                    const last_seen = i18nUtils.getTimeAgo(this.word.guess_ts);
                    return i18nUtils.prop('learned_words_word_last_seen', { last_seen });
                }
            },
            playlistIsPlayingThis() {
                return (this.word && this.word.lexical_unit_uuid === this.currentlyPlayingWordUUID);
            },
            formAudioIsPlaying() {
                if (this.audioIsPlaying && this.audioTypePlaying === 'form') {
                    return true;
                } else return !!(this.playlistIsPlayingThis && this.currentlyPlayingWordAudioType === 'form');
            },
            contextAudioIsPlaying() {
                if (this.audioIsPlaying && this.audioTypePlaying === 'context') {
                    return true;
                } else return !!(this.playlistIsPlayingThis && this.currentlyPlayingWordAudioType === 'context');
            }
        },
        methods: {
            muteIconClicked() {
                if (this.word.muted) {
                    this.$emit('un-mute', this.word);
                } else {
                    this.$emit('mute', this.word);
                }
            },
            favIconClicked() {
                if (this.word.favourite) {
                    this.$emit('un-favourite', this.word);
                } else {
                    this.$emit('favourite', this.word);
                }
            },
            playlistIconClicked() {
                if (this.word.in_playlist) {
                    this.$emit('un-playlist', this.word);
                } else {
                    this.$emit('playlist', this.word);
                }
            },
            selectWord() {
                this.$emit('select', this.word);
                console.log('select word', this.word);
            },
            playAudio: async function (type) {
                if (!this.audioIsPlaying) {
                    const mediaUrl = getConfigValue('media-base-url');
                    const voiceUuid = await UserManager.instance.getUser().getCourse().getVoiceUUID();
                    let audioUrl;

                    if (type === 'form' && this.word.sense.audio_hash) {
                        audioUrl = `${mediaUrl}/v1/${voiceUuid}/word/${this.word.sense.audio_hash}.mp3`;
                        this.audioTypePlaying = 'form';
                    } else if (type === 'context' && this.word.context.audio_hash) {
                        audioUrl = `${mediaUrl}/v1/${voiceUuid}/context/${this.word.context.audio_hash}.mp3`;
                        this.audioTypePlaying = 'context';
                    }

                    if (audioUrl) {
                        this.audioIsPlaying = true;

                        AudioPlayer.playLearnedWordAudio(audioUrl).then( () => {
                            this.audioIsPlaying = false;
                            this.audioTypePlaying = null;
                        });
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.wordlist-item {
        padding: .5rem;
        display: flex;
        flex-direction: row;
        gap: .5rem;
        border-top: 1px solid $colorBackgroundLighter;
        &.isSelected {
            background-color: rgba($colorBackgroundLighter, 0.4);
        }
        &.isPlaying {
            background-color: rgba($colorBackgroundLighter, 0.4);
        }
        &:first-child {
            border-top: none;
        }
        > div.tags {
            display: flex;
            flex-direction: column;
            gap: .25rem;
        }
        > div.content-container {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            > div.homograph {
                font-size: fontSize(26);
                color: $colorTargetPrimary;
                display: flex;
                flex-direction: row;
                gap: .5rem;
                align-items: center;
                > span.target {
                    color: $colorTargetPrimary;
                    padding: 0.1rem 0.3rem;
                    border-radius: 0.2rem;
                    > span.begin, > span.end {
                        color: $colorTargetSecondary;
                    }
                    > span.speaker-button {
                        margin-left: .5rem;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.audioIsPlaying {
                    > span.target {
                        background: rgba(108, 213, 229, 0.5);
                    }
                }
            }
            > div.sense-translation {
                font-size: fontSize(26);
                display: flex;
                flex-direction: row;
                align-items: center;
                > span.translations-wrapper {
                    padding: 0.1rem 0.3rem;
                    border-radius: 0.2rem;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.audioIsPlaying {
                    > span.translations-wrapper {
                        background: rgba(108, 213, 229, 0.5);
                    }
                }
                > span.speaker-button {
                    margin-left: .5rem;
                }
            }
            > div.context {
                color: $colorTargetPrimary;
                padding: 0.1rem 0.3rem;
                border-radius: 0.2rem;
                &:hover {
                    cursor: pointer;
                }
                &.audioIsPlaying {
                    background: rgba(108, 213, 229, 0.5);
                }
            }
            > div.context-translations {
                padding: 0.1rem 0.3rem;
                border-radius: 0.2rem;
                &:hover {
                    cursor: pointer;
                }
                &.audioIsPlaying {
                    background: rgba(108, 213, 229, 0.5);
                }
            }
            > div.meta {
                margin-top: auto;
                font-size: fontSize(12);
                color: $colorSourceSecondary;
                display: flex;
                flex-direction: row;
                gap: .5rem;
                padding: 0.1rem 0.3rem;
            }
            *.target {
                body[data-interface-language="ar"] & {
                    text-align: right;
                }
            }
        }

        > div.open-sidebar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            margin-left: auto;
            body[data-interface-language="ar"] & {
                margin-left: unset;
                margin-right: auto;
                > svg {
                    transform: rotate(180deg);
                }
            }
            &:hover {
                opacity: 0.8;
                cursor: pointer;
                > svg {
                    transform: translateX(-0.5rem);
                    transition: all 200ms;
                }
            }
        }
    }
</style>
