<template>
    <div class="cards-filter-dropdown" :class="{ disabled: this.disabled }">
        <div class="filter-button" @click="openDropdown">
            <card-status-icon v-bind:status="activeFilter" :known="(activeFilter === 'known')" />
            <span class="counter">{{ activeFilterCardsCount }}</span>
            <span>{{ activeFilterName }}</span>
        </div>
        <div class="filter-dropdown" v-if="dropdownOpen" @mouseleave="closeDropdownDelayed" @mouseenter="clearDropdownClose">
            <div class="filter-dropdown-item" @click="filterClicked('none')" :class="{ disabled: allCardsCount <= 0 }">
                <card-status-icon status="all" :known="false" />
                <span class="counter">{{ allCardsCount }}</span>
                <span class="text" v-html="this.$i18n('lessons_creator_editor_dropdown_all_cards')"/>
            </div>
            <div class="filter-dropdown-item" @click="filterClicked('complete')" :class="{ disabled: completeCardsCount <= 0 }">
                <card-status-icon status="complete" :known="false" />
                <span class="counter">{{ completeCardsCount }}</span>
                <span class="text" v-html="this.$i18n('lessons_creator_editor_dropdown_complete_cards')"/>
            </div>
            <div class="filter-dropdown-item" @click="filterClicked('incomplete')" :class="{ disabled: inCompleteCardsCount <= 0 }">
                <card-status-icon status="incomplete" :known="false" />
                <span class="counter">{{ inCompleteCardsCount }}</span>
                <span class="text" v-html="this.$i18n('lessons_creator_editor_dropdown_incomplete_cards')"/>
            </div>
            <div class="filter-dropdown-item" @click="filterClicked('known')" :class="{ disabled: knownCardsCount <= 0 }">
                <card-status-icon status="known" :known="true" />
                <span class="counter">{{ knownCardsCount }}</span>
                <span class="text" v-html="this.$i18n('lessons_creator_editor_dropdown_already_learned_words')"/>
            </div>
            <div class="filter-dropdown-item" @click="filterClicked('invalid')" :class="{ disabled: invalidCardsCount <= 0 }">
                <card-status-icon status="invalid" :known="false" />
                <span class="counter">{{ invalidCardsCount }}</span>
                <span class="text" v-html="this.$i18n('lessons_creator_editor_dropdown_missing_translation')" />
            </div>
        </div>
    </div>
</template>

<script>

import { EventBus } from "Util/vue-event-bus";
import CardStatusIcon from "./card-status-icon.vue";

export default {
    name: 'cards-filter-dropdown',
    components: {
        CardStatusIcon
    },
    props: {
        activeFilter: {
            type: String,
            default: 'all'
        },
        cards: {
            type: Array
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dropdownOpen: false,
            dropdownCloseTimeout: null
        }
    },
    computed: {
        allCardsCount() {
            return (this.cards && this.cards.length > 0) ? this.cards.length : 0;
        },
        completeCardsCount() {
            return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.completeness === 'complete').length : 0;
        },
        inCompleteCardsCount() {
            return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.completeness === 'incomplete').length : 0;
        },
        knownCardsCount() {
            return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.known).length : 0;
        },
        invalidCardsCount() {
            return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.completeness === 'invalid').length : 0;
        },
        activeFilterName() {
            switch (this.activeFilter) {
                case 'all':
                    return this.$i18n('lessons_creator_editor_dropdown_all_cards');
                case 'complete':
                    return this.$i18n('lessons_creator_editor_dropdown_complete_cards');
                case 'incomplete':
                    return this.$i18n('lessons_creator_editor_dropdown_incomplete_cards');
                case 'known':
                    return this.$i18n('lessons_creator_editor_dropdown_already_learned_words');
                case 'invalid':
                    return this.$i18n('lessons_creator_editor_dropdown_missing_translation');
            }
        },
        activeFilterCardsCount() {
            switch (this.activeFilter) {
                case 'all':
                    return this.allCardsCount;
                case 'complete':
                    return this.completeCardsCount;
                case 'incomplete':
                    return this.inCompleteCardsCount;
                case 'known':
                    return this.knownCardsCount;
                case 'invalid':
                    return this.invalidCardsCount;
            }
        }
    },
    methods: {
        openDropdown() {
            this.clearDropdownClose();
            this.dropdownOpen = true;
        },
        closeDropdown() {
            this.clearDropdownClose();
            this.dropdownOpen = false;
        },
        clearDropdownClose() {
            clearTimeout(this.dropdownCloseTimeout);
        },
        closeDropdownDelayed() {
            this.dropdownCloseTimeout = setTimeout(() => {
                this.closeDropdown();
            }, 500);
        },
        filterClicked(filter) {
            EventBus.$emit('cw:filter-cards', filter);
            this.closeDropdown();
        },
    },
}
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.cards-filter-dropdown {
        &.disabled {
            pointer-events: none;
            opacity: 0.6;
            filter: grayscale(100%);
        }
        span.counter {
            background-color: $colorBackgroundGray;
            border-radius: 25%;
            color: $colorSourceSecondary;
            font-size: fontSize(12);
            line-height: normal;
            margin-right: .5rem;
            padding: .25rem;
        }
        > div.filter-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: background-color 250ms;
            padding: .5rem .5rem .5rem .25rem;
            border-radius: pxToRem(8);
            user-select: none;
            svg {
                margin-right: .5rem;
            }
            &:hover {
                cursor: pointer;
                background-color: $colorBackgroundGraySecondary;
            }
            &:active {
                background-color: $colorBackgroundGrayDarker;
            }
        }
        > div.filter-dropdown {
            @include elevated;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            position: absolute;
            padding: .5rem 0;
            top: 2rem;
            left: 2rem;
            z-index: 100;
            min-width: pxToRem(220);
            > div.filter-dropdown-item {
                padding: .5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                user-select: none;
                &.disabled {
                    pointer-events: none;
                    opacity: 0.6;
                    > div.text {
                        filter: grayscale(100%);
                    }
                }
                &.active,
                &:hover {
                    background-color: $colorBackgroundGray;
                }
                svg {
                    margin-right: .5rem;
                }
            }
        }
    }
</style>
