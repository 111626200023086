<template>
    <main class="guess" :class="{ 'game-over': this.gameOver, 'tutor-open': this.tutorOpen, 'grammar-open': this.grammarOpen }">
        <loader v-if="isLoading" :centered="true" />
        <template v-else>
            <transition name="slide-fade-insights">
                <day-insights v-if="dayInsightsShown" :show-continue-button="true" :debug="dayInsightsDebug" v-on:close="closeDayInsights" />
            </transition>
            <modal-story v-if="modalStoryVisible" v-on:close="closeModalStory" :content-id="modalStoryContentId" />
            <repeat-progress-modal />
            <hotkeys-modal />
            <ft-modal :course="this.course" />
            <feedback-modal
                :question="this.presentedQuestion"
                :answer="this.on_previous ? this.previous_answer : this.answer"
                :card-has-visuals="this.cardHasVisuals"
                :card-visual-data="this.cardVisualData"
            />
            <card-visual-modal v-on:visual-chosen="cardVisualChosen" :question="this.presentedQuestion" />
            <hint-mark v-if="hintMarkElement" :target-element="hintMarkElement" v-on:close="this.clearHintMark" />
            <locked-message v-if="gameOver" :closable="false" :title="lockedMessageTitle" :text="lockedMessageText" />
            <transition name="slide-fade">
                <tutor v-if="tutorOpen" :question="this.presentedQuestion" v-on:close="closeTutor" />
                <grammar-tag v-if="grammarOpen" :question="this.presentedQuestion" v-on:close="closeGrammar" />
            </transition>
            <div class="card-grid">
                <card :question="this.question_computed"
                      :answer="this.answer"
                      :locked="this.locked"
                      :on_previous="this.on_previous"
                      :previous_question="this.previous_question"
                      :previous_answer="this.previous_answer"
                      :disabled="this.gameOver"
                      :autoPlay="this.autoPlay"
                      :visuals="this.visuals"
                      :card-has-visuals="this.cardHasVisuals"
                      :day-insights-shown="this.dayInsightsShown"
                      :onlySynonymAnswers="this.onlySynonymAnswers"
                      :word-is-favourite="this.wordIsFavourite"
                      :auto_advance="this.auto_advance"
                      v-on:form-spellings-visibility="updateSpellingsVisibility"
                      v-on:user-answer-changed="updateUserAnswer"
                      v-on:toggle-grammar="toggleGrammar"
                      v-on:mute-word="muteWord"
                      v-on:favourite-word="favouriteWord(true)"
                      v-on:un-favourite-word="favouriteWord(false)"
                />
                <card-debug v-if="this.presentedQuestion && this.debugMode" :course="this.course" :question="this.presentedQuestion" :synonyms="this.synonyms" />
            </div>
            <footer>
                <transition name="slide-down-fade">
                    <message v-if="message" :message="message" v-on:close="closeMessage" v-on:trigger-action="this.triggerMessageAction" v-on:trigger-dismiss="this.triggerMessageDismiss" />
                </transition>
                <utility-belt :answer="this.on_previous ? this.previous_answer : this.answer"
                              :userAnswer="this.userAnswer"
                              :question="this.question"
                              :previous_question="this.previous_question"
                              :audioIsPlaying="this.audioIsPlaying"
                              :locked="this.locked"
                              :on_previous="this.on_previous"
                              :tutorOpen="this.tutorOpen"
                              :disabled="this.gameOver"
                              :grammarTable="this.on_previous ? this.previousGrammarTable : this.grammarTable"
                              :has_previous="!!this.previous_question"
                              :visuals="this.visuals"
                              :card-has-visuals="this.cardHasVisuals"
                              :card-visual-data="this.cardVisualData"
                              :course="this.course"
                              :auto_advance="this.auto_advance"
                              :strict_diacritics="this.strict_diacritics"
                              :form-spellings-visible="this.formSpellingsVisible"
                />
            </footer>
            <survey survey-source="guess" type-initial="impact" :course_uuid="this.question_computed.courseUUID" />
        </template>
    </main>
</template>

<script>
    import _ from "lodash";
    import { LetterConversionMap, PinyinLetterConversionMap } from "Util/letter-conversion";
    import UserManager from "Modules/usermanager";
    import DiacriticUtils from "Util/diacritic";
    import AudioPlayer from "Util/audioplayer";
    import { EventBus } from "Util/vue-event-bus";
    import Card from "./components/card.vue";
    import Loader from "ViewComponent/loader.vue";
    import moment from "moment";
    import longestCommonSubstring from "node-lcs";
    import { COURSE_FEATURE } from "Modules/course/course";
    import { Answer, ANSWER_EVALUATION } from "./components/answer";
    import { REPEAT_PROGRESS } from "Modules/question";
    import EvaluationUtils from "Util/evaluation";
    import { NAME as PARAMETER_NAME, NAME, TYPE as PARAMETER_TYPE, TYPE } from "Modules/user.parameters";
    import UtilityBelt from "./components/utility-belt.vue";
    import RepeatProgressModal from "./components/repeat-progress-modal.vue";
    import HotkeysModal from "./components/hotkeys-modal.vue";
    import FeedbackModal from "./components/feedback-modal.vue";
    import LockedMessage from "ViewComponent/locked-message.vue";
    import Tutor from './components/tutor.vue';
    import { SUBSCRIPTION_STATUS } from "Modules/subscription";
    import i18nUtils from "Util/i18n";
    import GrammarTag from "./components/grammar-tag.vue";
    import Message from "./components/message.vue";
    import HintMark from "./components/hint-mark.vue";
    import CardVisualModal from "./components/card-visual-modal.vue";
    import ControllerManager from "Modules/controller.manager";
    import { ONBOARDING_STEP } from "Controller/onboarding";
    import KeyCodes from "Util/keycodes";
    import { ENTRY_EVENT_TYPE, ENTRY_EVENT_SUBMIT_TREATMENT } from "Controller/guess";
    import { TYPE as TOAST_TYPE } from "../toaster/constants";
    import Survey from "ViewComponent/survey.vue";
    import dayInsights from "../insights/components/day-insights.vue";
    import { Awards } from "Modules/awards.js";
    import ModalStory from "ViewComponent/modal-story/modal-story.vue";
    import { GuessMessaging } from "./components/messaging.js";
    import FtModal from "./components/ft-modal.vue";
    import CardDebug from "./components/card-debug.vue";
    import VoiceInput from "Util/voice-input.js";

    export default {
        name: 'guess',
        components: {
            CardDebug,
            Loader,
            ModalStory,
            GrammarTag,
            LockedMessage,
            RepeatProgressModal,
            HotkeysModal,
            UtilityBelt,
            Card,
            Tutor,
            Message,
            HintMark,
            FeedbackModal,
            Survey,
            CardVisualModal,
            dayInsights,
            FtModal
        },
        props: {
            raw_question: {
                type: Object,
            }
        },
        data() {
            return {
                isLoading: false,
                gameOver: this.isGameOver(),
                locked: false,
                course: UserManager.instance.getUser().getCourse(),
                question: this.raw_question,
                question_computed: null,
                answer: new Answer(),
                userAnswer: '',
                auto_advance: false,
                strict_diacritics: false,
                audioIsPlaying: false,
                correctAnswerTimeout: null,
                on_previous: false,
                previous_question: null,
                previous_answer: null,
                tutorOpen: false,
                grammarOpen: false,
                grammarTable: null,
                previousGrammarTable: null,
                message: null,
                hintMarkElement: null,
                thingsBeforeNextCard: [],
                autoPlay: false,
                autoPlayDelay: 2000,
                autoPlayTimeout: null,
                autoPlayProbability: 0,
                visuals: true, // visuals enabled or disabled
                cardVisualData: null,
                dayInsightsShown: false,
                dayInsightsDebug: false,
                onlySynonymAnswers: false,
                learnedWordsFavourites: null,
                debugMode: false,
                modalStoryVisible: false,
                modalStoryContentId: null,
                activeVariations: null,
                formSpellingsVisible: false,
                messaging: new GuessMessaging(),
            }
        },
        computed: {
            presentedQuestion() {
                if (this.on_previous) {
                    return this.previous_question;
                } else {
                    return this.question;
                }
            },
            lockedMessageTitle() {
                const subStatus = UserManager.instance.getUser().getSubscription().getStatus().status;

                if (subStatus === SUBSCRIPTION_STATUS.PRE_TRIAL && UserManager.instance.getUser().getCourse().getLimits().isPreTrialCardLimitExceeded()) {
                    return i18nUtils.prop('pre_trial_float_message_text')
                }
            },
            lockedMessageText() {
                const subStatus = UserManager.instance.getUser().getSubscription().getStatus().status;

                if (subStatus === SUBSCRIPTION_STATUS.PRE_TRIAL && UserManager.instance.getUser().getCourse().getLimits().isPreTrialCardLimitExceeded()) {
                    return i18nUtils.prop('onboarding_pretrial_ended_message')
                }
            },
            cardHasVisuals() {
                if (this.presentedQuestion.visual && this.presentedQuestion.visual.hasOwnProperty('enabled') && this.presentedQuestion.visual.enabled === false) {
                    return false;
                } else {
                    return !!(this.presentedQuestion && this.presentedQuestion.sense && _.isArray(this.presentedQuestion.sense.visuals) && this.presentedQuestion.sense.visuals.length > 0);
                }
            },
            wordIsFavourite() {
                const wordFoundInFavourites = _.find(this.learnedWordsFavourites, { lexical_unit_uuid: this.question.uuid });
                return !!(this.question_computed.favourite || !!wordFoundInFavourites);
            },
            synonyms() {
                let synonyms = []
                if (this.presentedQuestion.senseContexts && this.presentedQuestion.senseContexts.length > 0 && this.presentedQuestion.senseContexts[0].similar_answers) {
                    this.presentedQuestion.senseContexts[0].similar_answers.forEach(item => {
                        if (item.answer) {
                            synonyms.push(item.answer);
                        }
                    });
                }
                return synonyms;
            }
        },
        methods: {
            setQuestion(question) {
                this.question = question;
                this.unLock();
                this.previous_answer = _.cloneDeep(this.answer);
                this.resetAnswer();
            },
            goPrevious() {
                if (this.previous_question && this.previous_answer) {
                    this.on_previous = true;
                    this.closeTutor();
                    this.closeGrammar();
                    this.closeMessage();
                    this.clearHintMark();
                    AudioPlayer.stopContextAudioPlayback();
                    this.loadQuestionAudios(this.previous_question);
                    this.openTutorIfNeeded();
                }
            },
            goCurrent() {
                this.closeTutor();
                this.closeGrammar();
                this.closeMessage();
                this.clearHintMark();
                AudioPlayer.stopContextAudioPlayback();
                this.loadQuestionAudios(this.question);
                this.on_previous = false;
            },
            lock() {
                this.locked = true;
            },
            unLock() {
                this.locked = false;
            },
            toggleTutor() {
                this.closeGrammar();
                this.tutorOpen = !this.tutorOpen;
            },
            closeTutor() {
                this.tutorOpen = false;
            },
            openTutorIfNeeded() {
                if (!this.auto_advance || this.on_previous) {
                    const userParameters = UserManager.instance.getUser().getParameters();
                    if (userParameters.getParameter(NAME.SETTING_SHOW_GRAMMAR_TABLES) || this.on_previous) {
                        const isRepeatWord = this.question_computed && this.question_computed.repeatProgress && this.question_computed.repeatProgress !== 'new';
                        if (this.on_previous && this.previousGrammarTable || (!this.on_previous && this.grammarTable && (isRepeatWord && ((this.answer && this.answer.confirmed) || (this.answer && this.answer.all_entries && this.answer.all_entries.length > 0))))) {
                            this.closeGrammar();
                            this.tutorOpen = true;
                        }
                    }
                }
            },
            toggleGrammar() {
                this.closeTutor();
                this.grammarOpen = !this.grammarOpen;
            },
            closeGrammar() {
                this.grammarOpen = false;
            },
            updateUserAnswer(answer) {
                this.userAnswer = answer;
            },
            showMessage(message) {
                this.message = message;
                EventBus.$emit('guess:close-diacritics');
            },
            closeMessage() {
                this.message = null;
            },
            muteWord() {
                if (this.on_previous) {
                    EventBus.$emit('guess:mute-word', { question: _.cloneDeep(this.previous_question), on_previous: true });
                    this.previous_question.muted = true;
                    this.goCurrent();
                } else {
                    EventBus.$emit('guess:mute-word', { question: _.cloneDeep(this.question), on_previous: false });
                    this.question.muted = true;
                }

                const toast = {
                    text: i18nUtils.prop('mute_word_modal_text'),
                    type: TOAST_TYPE.SUCCESS
                };

                EventBus.$emit('toaster-add', toast);
            },
            async favouriteWord(state) {
                this.question_computed.favourite = state; // update question local state

                let statistics = UserManager.instance.getUser().getCourse().getStatistics();
                let learnedWords = statistics.getLearnedWords();
                let showToast = true;
                let _wordFoundFromLearnedWords = null;

                if (state && !ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.GUESS_WORDLIST)) {
                    this.triggerOnboardingStep('wordlist-favourite-message');
                    showToast = false;
                }

                if (this.on_previous) {
                    const { courseUUID, variation_uuid, uuid: lexical_unit_uuid, homograph, sense, repeatProgress, question } = this.previous_question;
                    const _context = await question.getContext();
                    await UserManager.instance.getUser().getEventSender().sendFavouriteWordEvent(courseUUID, variation_uuid, lexical_unit_uuid, homograph.uuid, sense.uuid, _context.uuid, state);
                    _wordFoundFromLearnedWords = learnedWords.words.find(w => w.lexical_unit_uuid === lexical_unit_uuid);

                    if (showToast) {
                        let toastText = (repeatProgress === 'new') ? i18nUtils.prop('favorite_new_word_modal_text') : i18nUtils.prop('favorite_word_modal_text');
                        if (!state) {
                            toastText = i18nUtils.prop('snackbar_remove_favorite_word_text')
                        }
                        const toast = {
                            text: toastText,
                            type: TOAST_TYPE.SUCCESS
                        };

                        EventBus.$emit('toaster-add', toast);
                    }
                    if (_wordFoundFromLearnedWords) {
                        await learnedWords.updateLexicalUnitFavouriteState(lexical_unit_uuid, state);
                    }
                } else {
                    const { courseUUID, variation_uuid, uuid: lexical_unit_uuid, homograph, sense, repeatProgress, question } = this.question;
                    const _context = await question.getContext();
                    await UserManager.instance.getUser().getEventSender().sendFavouriteWordEvent(courseUUID, variation_uuid, lexical_unit_uuid, homograph.uuid, sense.uuid, _context.uuid, state);
                    _wordFoundFromLearnedWords = learnedWords.words.find(w => w.lexical_unit_uuid === lexical_unit_uuid);

                    if (showToast) {
                        let toastText = (repeatProgress === 'new') ? i18nUtils.prop('favorite_new_word_modal_text') : i18nUtils.prop('favorite_word_modal_text')
                        if (!state) {
                            toastText = i18nUtils.prop('snackbar_remove_favorite_word_text')
                        }
                        const toast = {
                            text: toastText,
                            type: TOAST_TYPE.SUCCESS
                        };

                        EventBus.$emit('toaster-add', toast);
                    }
                    if (_wordFoundFromLearnedWords) {
                        await learnedWords.updateLexicalUnitFavouriteState(lexical_unit_uuid, state);
                    }
                }

                await this.setLearnedWordsFavourites();

            },
            parseContext (parsedContext) {
                const wordRegExp = /'$/;
                const afterRegExp = /[.?!]/;
                // If the word proceeding the current word has "'" add nowrap instructions
                if (parsedContext) {
                    for (let i = 1; i < parsedContext.length; i++) {
                        const word = parsedContext[i];
                        const previousWord = parsedContext[i - 1];
                        if (wordRegExp.test(previousWord.word)) {
                            previousWord.noWrapStart = true;
                            word.noWrapEnd = true;

                            if (word.current) {
                                previousWord.integrateStart = true;
                                word.integrateEnd = true;
                            }
                        }
                        if (afterRegExp.test(word.after)) {
                            previousWord.noWrapStart = true;
                            word.noWrapEnd = true;
                        }
                    }
                }

                return parsedContext;
            },
            letterConversion (word, forceDiacriticsReplace = false) {
                const replaceDiacritics = (!this.strict_diacritics || forceDiacriticsReplace);
                if (replaceDiacritics) {
                    _.each(LetterConversionMap, _.bind(function (value, key) {
                        word = this.replaceAll(word, key, value);
                    }, this));
                    // Extra letter conversions for en:zh-Latn-pinyin course
                    if (UserManager.instance.getUser().getCourse().getInfo().target_language === 'zh-Latn-pinyin') {
                        _.each(PinyinLetterConversionMap, _.bind(function (value, key) {
                            word = this.replaceAll(word, key, value);
                        }, this));
                    }
                }

                return word.toLowerCase().trim();
            },
            getSize (context) {
                let contextSizePx = -0.17265948632973854 * context.length + 37.39270919635441;

                if (contextSizePx > 50) {
                    contextSizePx = 50
                } else if (contextSizePx < 20) {
                    contextSizePx = 20;
                }

                return contextSizePx / 16; // Divided be 16 to get rems.
            },
            contextHasSwt (parsedContext) {
                let hasSwt = false;
                for (let context of parsedContext) {
                    if (context && context.usage && context.usage.translations && context.usage.translations.length > 0) {
                        hasSwt = true;
                        break;
                    }
                }
                return hasSwt;
            },
            getSmallTranslations (question) {
                let maxTranslationLength = 0;

                let translations = question.translations;

                for (let i = 0; i < translations.length; i++) {
                    const translation = translations[i];
                    let translationLength = translation.translation.length;
                    if (translation.begin_translation) {
                        translationLength += translation.begin_translation.length + 1;
                    }
                    if (translation.end_translation) {
                        translationLength += translation.end_translation.length + 1;
                    }
                    if (translationLength > maxTranslationLength) {
                        maxTranslationLength = translationLength;
                    }
                }

                if (translations.length === 1 && maxTranslationLength > 40) {
                    return true;
                } else if (translations.length === 2 && (maxTranslationLength > 20 || question.translations_comments.length > 0)) {
                    return true;
                }

                return false;
            },
            async getGrammarTableIfAvailable () {
                return Promise.all([
                    this.question.grammarTablePromise
                ]).then(([grammarTable]) => {
                    return grammarTable;
                });
            },
            replaceAll (str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            },
            increaseRepeatProgress (question) {
                const repeatValues = _.values(REPEAT_PROGRESS);
                const currentLevel = repeatValues.indexOf(this.question.repeatProgress);

                if (currentLevel === 0) {
                    question.repeatProgress = repeatValues[repeatValues.length - 1];
                } else if (currentLevel < (repeatValues.length - 1)) {
                    const nextLevel = currentLevel + 1;
                    question.repeatProgress = repeatValues[nextLevel];
                }
            },
            checkForCommonMistake (question, answer, answer_text) {

                let commonMistake = null;

                if (question.sense.mistakes) {
                    commonMistake = question.sense.mistakes.find(mistake => {
                        return mistake.inputs.indexOf(answer_text) !== -1;
                    });
                }

                if (answer.mistake === null && commonMistake) {
                    answer.mistake = {
                        id: commonMistake.id,
                        correctness: commonMistake.correctness,
                        input: answer_text
                    };
                }

                return commonMistake;
            },
            isCorrect (question, answer_text) {
                return answer_text === this.letterConversion(question.word);
            },
            checkDiacriticsErrors (word, answer) {
                answer = answer.toLowerCase().trim();
                word = word.toLowerCase().trim();
                let wordHasDiacritics = false;
                let wordAsArray = word.split('');
                let answerAsArray = answer.split('');
                let diacriticsArray = [];
                let isCorrectWithoutStrictDiacriticsCheck = answer === this.letterConversion(word, true);

                if (isCorrectWithoutStrictDiacriticsCheck && this.question && this.question.hasOwnProperty('diacritics') && _.isArray(this.question.diacritics)) {
                    this.question.diacritics.forEach(item => {
                        if (item && item.hasOwnProperty('diacritic')) {
                            return diacriticsArray.push(item.diacritic);
                        }
                    })

                    wordAsArray.forEach(letter => {
                        if (diacriticsArray.includes(letter)) {
                            wordHasDiacritics = true;
                        }
                    });

                    if (wordHasDiacritics) {
                        let diffResult = _.difference(wordAsArray, answerAsArray);
                        let differenceIsDiacritic = false;
                        diffResult.forEach(item => {
                            item = item.toString();
                            if (!answerAsArray.includes(item) && diacriticsArray.includes(item)) {
                                differenceIsDiacritic = true;
                            }
                        })
                        return differenceIsDiacritic;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            getDifference (question, answer) {
                return EvaluationUtils.similarText(
                    this.letterConversion(question.word),
                    answer,
                    1) / 100; // = 2*(matching letters)/(right word nr of letter + written word nr of letters)
            },
            isSynonym (question, answer_text) {
                let synonyms = [];

                if (question.senseContexts && question.senseContexts.length > 0 && question.senseContexts[0].similar_answers) {
                    question.senseContexts[0].similar_answers.forEach(item => {
                        if (item.answer) {
                            synonyms.push(item.answer);
                        }
                    });
                }

                for (let i = 0; i < synonyms.length; i++) {
                    if (answer_text === this.letterConversion(synonyms[i])) {
                        return { 'found': true, 'synonym': synonyms[i]};
                    }
                }
                return { 'found': false };
            },
            isEquivalentAnswer(question, answer_text) {
                const equivalent_words = question.equivalent_words;
                if (equivalent_words && equivalent_words.length > 0) {
                    for (let i = 0; i < equivalent_words.length; i++) {
                        if (answer_text === this.letterConversion(equivalent_words[i])) {
                            return { 'found': true, 'equivalent_answer': equivalent_words[i]};
                        }
                    }
                }
                return { 'found': false };
            },
            getVariationInfo (variation_uuid) {
                const cardVariation = this.course.getVariation(variation_uuid);
                let _variationInfo = { icon: null, name: null };

                _variationInfo = { ...cardVariation };

                // if (cardVariation && cardVariation.type !== VARIATION_TYPE.GENERAL) {
                //     _variationInfo.icon = cardVariation.icon;
                //     _variationInfo.name = cardVariation.name;
                // }

                return _variationInfo;
            },
            async evaluateAnswer (answer = { answer_text: '', answer_type: 'written' }) {
                const { answer_text, answer_type } = answer;
                this.answer.addEntry(answer_text);

                // Answering the first time
                if (_.isNull(this.answer.opened)) {
                    this.answer.opened = moment().valueOf();
                }

                if (this.answer.answer === null) {
                    this.answer.setAnswer(answer_text);
                }

                const hasDiacriticErrors = this.checkDiacriticsErrors(this.question.word, answer_text);

                let normalizedAnswer = this.letterConversion(answer_text),
                    longest_common_substring = longestCommonSubstring(answer_text.toLowerCase(), this.question.word.toLowerCase());

                let isEquivalentAnswer = this.isEquivalentAnswer(this.question, normalizedAnswer);

                if (this.isCorrect(this.question, normalizedAnswer)) {
                    this.answer.addEntryEvent(ENTRY_EVENT_TYPE.SUBMIT, answer_text, {
                        guess_value: 1,
                        longest_common_substring: longest_common_substring.sequence || null,
                        treatment: ENTRY_EVENT_SUBMIT_TREATMENT.CORRECT
                    });

                    this.answer.confirmed = moment().valueOf();
                    this.answer.answer_type = answer_type;

                    if (this.answer.guess_value === null) {
                        this.answer.guess_value = 1;
                    }

                    if (this.answer.all_entries.length === 1 || this.onlySynonymAnswers) {
                        this.answer.guess_value = 1;
                        await this.increaseRepeatProgress(this.question);
                    }

                    return {
                        evaluation: ANSWER_EVALUATION.CORRECT,
                        options: { answer_text: answer_text, diacritic_errors: hasDiacriticErrors }
                    };
                } if (isEquivalentAnswer.found) {
                    this.answer.addEntryEvent(ENTRY_EVENT_TYPE.SUBMIT, answer_text, {
                        guess_value: 1,
                        longest_common_substring: longest_common_substring.sequence || null,
                        treatment: ENTRY_EVENT_SUBMIT_TREATMENT.EQUIVALENT,
                        equivalent_answer: isEquivalentAnswer.equivalent_answer
                    });

                    this.answer.confirmed = moment().valueOf();

                    if (this.answer.guess_value === null) {
                        this.answer.guess_value = 1;
                    }

                    if (this.answer.all_entries.length === 1 || this.onlySynonymAnswers) {
                        await this.increaseRepeatProgress(this.question);
                    }

                    return {
                        evaluation: ANSWER_EVALUATION.EQUIVALENT_ANSWER,
                        options: { answer_text: answer_text, diacritic_errors: hasDiacriticErrors }
                    };
                } else if (normalizedAnswer.length === 0) {
                    this.answer.addEntryEvent(ENTRY_EVENT_TYPE.REVEAL);
                    if (this.answer.guess_value === null) {
                        this.answer.guess_value = 0;
                    }

                    this.onlySynonymAnswers = false;

                    return {
                        evaluation: ANSWER_EVALUATION.EMPTY,
                        options: { answer_text: answer_text, diacritic_errors: hasDiacriticErrors }
                    };
                } else {
                    let answerSynonym = this.isSynonym(this.question, normalizedAnswer);

                    if (answerSynonym.found) {
                        // this.answer.confirmed = moment().valueOf();
                        let answersArray = this.answer.all_entries

                        this.onlySynonymAnswers = answersArray.every((val) => this.isSynonym(this.question, this.letterConversion(val).found)) && !answersArray.includes("");

                        let guessValue = this.getDifference(this.question, normalizedAnswer);

                        if (this.answer.guess_value === null) {
                            this.answer.guess_value = guessValue;
                        }

                        this.answer.addEntryEvent(ENTRY_EVENT_TYPE.SUBMIT, answer_text, {
                            guess_value: 1,
                            longest_common_substring: longest_common_substring.sequence || null,
                            treatment: ENTRY_EVENT_SUBMIT_TREATMENT.SYNONYM
                        });

                        return {
                            evaluation: ANSWER_EVALUATION.SYNONYM,
                            options: { answer_text: answer_text, diacritic_errors: hasDiacriticErrors }
                        };

                        // No synonym answer
                    } else {

                        this.onlySynonymAnswers = false;

                        let guessValue = this.getDifference(this.question, normalizedAnswer);

                        if (this.answer.guess_value === null) {
                            this.answer.guess_value = guessValue;
                        }

                        const mistake = this.checkForCommonMistake(this.question, this.answer, normalizedAnswer);

                        // Common mistake detected (and experiment enabled)
                        if (await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.MISTAKES) && mistake) {
                            this.answer.addEntryEvent(ENTRY_EVENT_TYPE.SUBMIT, answer_text, {
                                guess_value: mistake.correctness || guessValue,
                                longest_common_substring: longest_common_substring.sequence || null,
                                treatment: ENTRY_EVENT_SUBMIT_TREATMENT.MISTAKE
                            });

                            return {
                                evaluation: ANSWER_EVALUATION.COMMON_MISTAKE,
                                options: {
                                    guessValue: mistake.correctness || guessValue,
                                    answer_text: answer_text,
                                    question: this.question,
                                    mistake,
                                    diacritic_errors: hasDiacriticErrors
                                }
                            };

                            // Typo detected
                        } else if (guessValue > 0.5 || longest_common_substring.length > 2) {
                            this.answer.addEntryEvent(ENTRY_EVENT_TYPE.SUBMIT, answer_text, {
                                guess_value: guessValue,
                                longest_common_substring: longest_common_substring.sequence || null,
                                treatment: ENTRY_EVENT_SUBMIT_TREATMENT.TYPO
                            });

                            return {
                                evaluation: ANSWER_EVALUATION.TYPO,
                                options: {
                                    guessValue: guessValue,
                                    answer_text: answer_text,
                                    diacritic_errors: hasDiacriticErrors
                                }
                            };
                            // Unclassified mistake
                        } else {
                            this.answer.addEntryEvent(ENTRY_EVENT_TYPE.SUBMIT, answer_text, {
                                guess_value: guessValue,
                                longest_common_substring: longest_common_substring.sequence || null,
                                treatment: ENTRY_EVENT_SUBMIT_TREATMENT.INCORRECT
                            });

                            return {
                                evaluation: ANSWER_EVALUATION.WRONG,
                                options: {
                                    guess_value: guessValue,
                                    answer_text: answer_text,
                                    diacritic_errors: hasDiacriticErrors
                                }
                            };
                        }
                    }
                }
            },
            checkEmptyAnswerOnboarding() {
                if (!ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.GUESS_REVEAL)) {
                    let course = UserManager.instance.getUser().getCourse(),
                        statistics_data = course.getStatistics().getData();
                    if (statistics_data.all_units.total === 0 && this.userAnswer.length === 0) {   // Only show on the first card that the user ever does and only when revealing
                        setTimeout(() => {
                            this.showHintMark('card-gap');
                            ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_REVEAL);
                        }, 1000);
                    }
                }
            },
            checkFeatureBasedOnboarding(onboarding_controller) {
                if (this.activeVariations && this.activeVariations.length > 0) {
                    const isJapaneseCourse = (this.course && this.course.info) ? this.course.info.target_language.startsWith('ja') : false;
                    const isKoreanCourse = (this.course && this.course.info) ? this.course.info.target_language.startsWith('ko') : false;
                    const isRussianCourse = (this.course && this.course.info) ? this.course.info.target_language.startsWith('ru') : false;

                    if (isJapaneseCourse) {
                        if (!this.modalStoryVisible) {
                            const hasKatakanaFeature = this.activeVariations.some(variation => variation.features.includes('katakana'));
                            const hasHiraganaFeature = this.activeVariations.some(variation => variation.features.includes('hiragana'));

                            if (!onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_JA_GENERAL)) {
                                this.openModalStory('japanese-general');
                            } else if (hasHiraganaFeature && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_JA_HIRAGANA)) {
                                this.openModalStory('japanese-hiragana');
                            } else if (hasKatakanaFeature && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_JA_KATAKANA)) {
                                this.openModalStory('japanese-katakana');
                            }
                        }
                    } else if (isKoreanCourse) {
                        if (!this.modalStoryVisible) {
                            const hasHangeulFeature = this.activeVariations.some(variation => variation.features.includes('hangeul'));

                            if (!onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_KO_GENERAL)) {
                                this.openModalStory('korean-general');
                            } else if (hasHangeulFeature && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_KO_HANGEUL)) {
                                this.openModalStory('korean-hangeul');
                            }
                        }
                    } else if (isRussianCourse) {
                        if (!this.modalStoryVisible) {
                            const hasCyrillicFeature = this.activeVariations.some(variation => variation.features.includes('cyrillic'));

                            if (!onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_RU_GENERAL)) {
                                this.openModalStory('russian-general');
                            } else if (hasCyrillicFeature && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_RU_CYRILLIC)) {
                                this.openModalStory('russian-cyrillic');
                            }
                        }
                    }
                }
            },
            async submit(answer) {
                if (!answer) {
                    answer = { answer_text: this.userAnswer, answer_type: 'written' };
                }

                if (!this.on_previous && !this.isGameOver() && !this.dayInsightsShown) {
                    const { evaluation, options } = await this.evaluateAnswer(answer);

                    switch (evaluation) {
                        case ANSWER_EVALUATION.CORRECT:
                        case ANSWER_EVALUATION.EQUIVALENT_ANSWER:
                            this.locked = !!(this.auto_advance);
                            this.closeGrammar();
                            let _delay = 500;

                            if (AudioPlayer.isAudioEnabled() && AudioPlayer.hasContextAudio()) {
                                _delay = 250;
                                await AudioPlayer.playContext(); // play context audio, if audio is enabled
                            }

                            // show strict-diacritics message about settings, if there are diacritics errors in the answer and the message haven't been seen yet
                            if (options && options.diacritic_errors && !this.strict_diacritics && !ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.SETTINGS_STRICT_DIACRITICS)) {
                                this.thingsBeforeNextCard.push('strict-diacritics-message');
                            }

                            // Don't overdo message check
                            if (Awards.shouldShowDontOverdoNotification(this.course)) {
                                this.thingsBeforeNextCard.push('dont-overdo-message');
                            }

                            const dont_auto_advance = await this.doThingsBeforeNextCard();
                            let _question = this.question.question;

                            // add visual data to guess event
                            if (this.visuals && this.cardHasVisuals && this.cardVisualData && this.cardVisualData.enabled && this.cardVisualData.urls && this.cardVisualData.attribution) {
                                _question.visual = {
                                    url: this.cardVisualData.urls.small,
                                    attribution: this.cardVisualData.attribution
                                };
                            } else {
                                _question.visual = null;
                            }

                            // add favourite information to guess event
                            _question.favourite = this.wordIsFavourite;

                            this.openTutorIfNeeded();

                            this.correctAnswerTimeout = setTimeout(() => {
                                EventBus.$emit('guess:final-answer-submit', { question: _question, answer: this.answer, dont_auto_advance });
                            }, _delay);

                            break;
                        case ANSWER_EVALUATION.SYNONYM:
                            EventBus.$emit('guess:synonym-answer', options);
                            if (!ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.GUESS_SYNONYM)) {
                                this.lock();
                                this.triggerOnboardingStep('synonym-message');
                            }
                            break;
                        case ANSWER_EVALUATION.WRONG:
                        case ANSWER_EVALUATION.COMMON_MISTAKE:
                        case ANSWER_EVALUATION.TYPO:
                            EventBus.$emit('guess:wrong-answer', evaluation);
                            if (!ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.GUESS_FIRST_MISTAKE)) {
                                this.thingsBeforeNextCard.push('first-mistake-message');
                            }
                            this.playWordAudio();
                            this.openTutorIfNeeded();
                            break;
                        case ANSWER_EVALUATION.EMPTY:
                            EventBus.$emit('guess:reveal');
                            this.playWordAudio();
                            this.openTutorIfNeeded();
                            break;
                    }
                } else if (this.isGameOver()) {
                    // looks like gameOver state changed after question change
                    this.gameOver = true;
                }
            },
            isGameOver() {
                return UserManager.instance.getUser().getCourse().getLimits().isGameOver();
            },
            resetAnswer() {
                this.answer = new Answer();
            },
            playWordAudio() {
                if (AudioPlayer.isAudioEnabled()) {
                    AudioPlayer.playWord();
                }
            },
            setAudioPlayerListeners() {
                AudioPlayer.onPlayStart( () => {
                    this.audioIsPlaying = true;
                });

                AudioPlayer.onPlayEnd( () => {
                    this.audioIsPlaying = false;
                });

                AudioPlayer.onPaused( () => {
                    this.audioIsPlaying = false;
                });
            },
            createQuestion () {
                let _question = this.question;
                let _variationInfo = this.getVariationInfo(this.question.variation_uuid);
                _question.wordLength = this.letterConversion(this.question.word).length;
                _question.parsedContext = this.parseContext(this.question.parsedContext);
                _question.fontSize = this.getSize(this.question.context);
                _question.smallTranslations = this.getSmallTranslations(this.question);
                _question.variationIcon = _variationInfo.icon;
                _question.variationName = _variationInfo.name;
                _question.variationInfo = _variationInfo;
                _question.diacritics = DiacriticUtils.getDiacritics(this.question.target_language);
                _question.courseUUID = UserManager.instance.getUser().getCourse().UUID;
                _question.hasSwt = this.contextHasSwt(this.question.parsedContext);

                this.question_computed = _question;
                EventBus.$emit('guess:question-ready');
            },
            setAutoAdvanceLocal(value = null) {
                if (value === null) {
                    const userParameters = UserManager.instance.getUser().getParameters();
                    const auto_advance_from_params = userParameters.getParameter(NAME.SETTING_AUTO_ADVANCE);
                    this.auto_advance = (auto_advance_from_params === null) ? true : auto_advance_from_params;
                } else {
                    this.auto_advance = value;
                }
            },
            setVisuals(fromParams = false) {
                const userParameters = UserManager.instance.getUser().getParameters();
                if (fromParams) {
                    const visuals_from_params = userParameters.getParameter(NAME.SETTING_VISUALS);
                    this.visuals = (visuals_from_params === null) ? true : visuals_from_params;
                } else {
                    this.visuals = !this.visuals;
                    userParameters.setParameter(NAME.SETTING_VISUALS, this.visuals, TYPE.BOOLEAN);
                }
            },
            setStrictDiacritics(value = null) {
                if (value === null) {
                    const userParameters = UserManager.instance.getUser().getParameters();
                    const setting_from_params = userParameters.getParameter(NAME.SETTING_STRICT_DIACRITICS);
                    this.strict_diacritics = (setting_from_params === null) ? false : setting_from_params;
                } else {
                    this.strict_diacritics = value;
                }
            },
            loadQuestionAudios(question) {
                AudioPlayer.loadQuestionAudios(
                    question.uuid,
                    question.audio,
                    question.context_audio,
                    question.parsedContext.map(word => ({ hash: word.audio_hash, urls: word.audio_urls }))
                );
            },
            showHintMark(elementId) {
                let element = document.getElementById(elementId);
                if (element) {
                    this.hintMarkElement = element;
                }
            },
            clearHintMark() {
                this.hintMarkElement = null;
            },
            async checkOnboarding() {
                // Might make sense to move to separate file at one point
                let onboarding_controller = ControllerManager.instance.getController('Onboarding'),
                    course = UserManager.instance.getUser().getCourse(),
                    statistics_data = course.getStatistics().getData();
                const isJapaneseCourse = (this.course && this.course.info) ? this.course.info.target_language.startsWith('ja') : false;
                const isKoreanCourse = (this.course && this.course.info) ? this.course.info.target_language.startsWith('ko') : false;
                const isRussianCourse = (this.course && this.course.info) ? this.course.info.target_language.startsWith('ru') : false;
                const hasFastTracking = await this.course.hasFeature(COURSE_FEATURE.FAST_TRACKING);

                if (!isJapaneseCourse && !isKoreanCourse && !isRussianCourse) {
                    // Only show on the first card that the user ever does
                    if (statistics_data.all_units.total === 0 && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_TRANSLATION)) {
                        this.triggerOnboardingStep('translation-message');
                    } else if (statistics_data.all_units.total === 1 && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_2ND_CARD)) {
                        this.triggerOnboardingStep('2nd-card-message');
                    } else if (statistics_data.all_units.total === 2 && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_3RD_CARD)) {
                        this.triggerOnboardingStep('3rd-card-message');
                    } else if (statistics_data.all_units.total === 75 && !onboarding_controller.getStepDone(ONBOARDING_STEP.GUESS_VOICE_INPUT)) {
                        if (VoiceInput.isSupportedBrowser()) {
                            this.triggerOnboardingStep('voice-input-message');
                        }
                    }
                }

                // Show FastTracking Modal on 4th card
                if (hasFastTracking && this.activeVariations && statistics_data.all_units.total === 3) {
                    const ftStatus = this.course.getFastTrackingState().getStatus();
                    const isGeneralVariationActive = this.activeVariations.some(variation => variation.type === "general");
                    if ((ftStatus === 'initial' || ftStatus === null) && isGeneralVariationActive) {
                        EventBus.$emit('guess:ft-modal-open');
                    }
                }

                // check for variation feature related onboarding (like japanese stories etc.)
                this.checkFeatureBasedOnboarding(onboarding_controller);
            },
            triggerOnboardingStep(step) {
                const message = this.messaging.getMessageForStep(step, this.course, this.question, this.answer);
                this.showMessage(message);
            },
            async triggerMessageAction(action) {
                this.messaging.triggerMessageAction(action, this.question, this.answer, this.auto_advance);
            },
            triggerMessageDismiss(name) {
                switch (name) {
                    case 'translation-message':
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'dismiss', 'translation');
                        ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_TRANSLATION);
                        this.clearHintMark();
                        break;
                    case '2nd-card-message':
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'dismiss', '2nd-card');
                        ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_2ND_CARD);
                        break;
                    case '3rd-card-message':
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'dismiss', '3rd-card');
                        ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_3RD_CARD);
                        break;
                    case 'gap-message':
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'dismiss', 'answer-overlay');
                        ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_ANSWER);
                        this.clearHintMark();
                        break;
                    case 'form-spellings-message':
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'dismiss', 'form-spellings-message');
                        ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_FORM_SPELLING);
                        this.clearHintMark();
                        break;
                    case 'voice-input-message':
                        UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'dismiss', 'voice-input-message');
                        ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_VOICE_INPUT);
                        break;
                }
            },
            async doThingsBeforeNextCard() {
                let dont_auto_advance = false;
                if (this.thingsBeforeNextCard.length > 0) {
                  for (let thing of this.thingsBeforeNextCard) {
                      switch (thing) {
                          case 'first-mistake-message':
                              this.lock();
                              dont_auto_advance = true;
                              this.triggerOnboardingStep('first-mistake-message');
                              break;
                          case 'strict-diacritics-message':
                              this.lock();
                              dont_auto_advance = true;
                              this.triggerOnboardingStep('strict-diacritics-message');
                              break;
                          case 'dont-overdo-message':
                              this.lock();
                              dont_auto_advance = true;
                              this.triggerOnboardingStep('dont-overdo-message');
                              break;
                      }
                  }
                }
                return dont_auto_advance;
            },
            debugAutoPlay() {
                const knowsThisWord = Math.random();
                if (knowsThisWord > this.autoPlayProbability) {
                    this.submit({ answer_text: this.question.word, answer_type: 'written' });
                } else {
                    this.submit({ answer_text: '', answer_type: 'written' });
                    setTimeout(() => {
                        this.submit({ answer_text: this.question.word, answer_type: 'written' });
                    }, 1000);
                }
            },
            debugAutoPlayStart(probability){
                this.autoPlay = true;
                this.autoPlayProbability = probability;
                this.debugAutoPlay();
            },
            debugAutoPlayStop() {
                this.autoPlay = false;
                clearTimeout(this.autoPlayTimeout);
            },
            onDocumentKeyDown(event) {
                const appElement = document.getElementById('app-root');
                const isDoorSlammedOrModalOpen = appElement.classList.contains('doorslam-open') || appElement.classList.contains('onboarding-overlay-open') || document.body.classList.contains('modal-open');

                if (!this.locked && !this.disabled && !isDoorSlammedOrModalOpen && !this.dayInsightsShown) {
                    const gapInFocus = event.target.classList.contains('answer-input');

                    switch (event.keyCode) {
                        case i18nUtils.getKeyCode('next'):
                            // [right arrow] submit when cursor is at the end of the input or input is empty
                            if (this.on_previous) {
                                this.goCurrent();
                            } else if (gapInFocus && (event.target.selectionStart === event.target.value.length || event.target.value.length === 0)) {
                                this.submit({ answer_text: event.target.value, answer_type: 'written' });
                            } else if (!this.auto_advance && this.answer.confirmed && !this.locked && !this.audioIsPlaying) {
                                EventBus.$emit('guess:next');
                            } else if (!gapInFocus && !this.answer.confirmed && !this.locked && !this.audioIsPlaying) {
                                EventBus.$emit('guess:submit');
                            }
                            break;
                        case KeyCodes.ENTER:
                            // [shift+enter] to navigate to next card
                            if (event.shiftKey) {
                                if (this.on_previous && !isDoorSlammedOrModalOpen) {
                                    this.goCurrent();
                                } else if (!this.auto_advance && this.answer.confirmed && !this.locked && !this.audioIsPlaying && !isDoorSlammedOrModalOpen) {
                                    EventBus.$emit('guess:next');
                                }
                            }
                            break;
                        case i18nUtils.getKeyCode('previous'):
                            // [left arrow] move to previous card if possible
                            if (!!this.previous_question && !this.previous_question.muted && !this.audioIsPlaying && ((gapInFocus && event.target.selectionStart === 0) || !gapInFocus)) {
                                this.goPrevious();
                            }
                            break;
                        case KeyCodes.UP_ARROW:
                            event.preventDefault();
                            EventBus.$emit('guess:open-diacritics');
                            break;
                        case KeyCodes.DOWN_ARROW:
                            EventBus.$emit('guess:close-diacritics');
                            break;
                        case KeyCodes.SPACE:
                            if (!this.audioIsPlaying && (this.on_previous || (!this.auto_advance && this.answer.confirmed))) {
                                AudioPlayer.playContext(true);
                            }
                            break;
                    }
                }
            },
            clearThingsBeforeNextCard() {
                this.thingsBeforeNextCard = []
            },
            shouldOpenSurvey () {
                let course = UserManager.instance.getUser().getCourse(),
                    courseStatisticsData = course.getStatistics().getData();
                let over400 = courseStatisticsData && courseStatisticsData.all_units && courseStatisticsData.all_units.total > 400;

                if (over400) {
                    let feedback_survey_ts = UserManager.instance.getUser().getParameters().getParameter(PARAMETER_NAME.GUESS_FEEDBACK_SURVEY_SHOWN_TS);
                    let feedback_survey_moment = feedback_survey_ts && moment(feedback_survey_ts);
                    let now_moment = moment();
                    let datesBetween = now_moment.diff(feedback_survey_moment, 'days');

                    if (isNaN(datesBetween) || datesBetween >= 180) {
                        EventBus.$emit('survey:open', 'impact');
                        let now = moment.utc();
                        UserManager.instance.getUser().getParameters().setParameter(PARAMETER_NAME.GUESS_FEEDBACK_SURVEY_SHOWN_TS, now, PARAMETER_TYPE.DATETIME);
                    }
                }
            },
            async cardVisualChosen (visual) {
                if (visual) {
                    const lexical_unit_uuid = this.question.uuid;
                    const course_uuid = this.question_computed.courseUUID;
                    const variation_uuid = this.question_computed.variation_uuid;
                    let visualClean = _.omit(visual, ['selected']);

                    if (!visualClean.hasOwnProperty('enabled')) {
                        visualClean.enabled = true;
                    }

                    // update question visual in question state as well
                    this.question.visual = visual;

                    EventBus.$emit('guess:card-visual-chosen', visualClean);
                    this.setCardVisualData(visualClean);

                    await UserManager.instance.getUser().getEventSender().sendVisualChoiceEvent(
                        course_uuid, variation_uuid, lexical_unit_uuid, visualClean);
                }
            },
            setCardVisualData(visualData = null) {
                if (visualData) {
                    this.cardVisualData = visualData;
                } else if (this.cardHasVisuals) {
                    this.cardVisualData = this.question.sense.visuals[0];
                    if (!this.cardVisualData.hasOwnProperty('enabled')) {
                        this.cardVisualData.enabled = true;
                    }
                } else {
                    this.cardVisualData = null;
                }
            },
            openDayInsights(debug = false) {
                this.dayInsightsShown = true;
                this.dayInsightsDebug = debug;
            },
            closeDayInsights() {
                this.dayInsightsShown = false;
                this.dayInsightsDebug = false;
            },
            async setLearnedWordsFavourites() {
                let statistics = UserManager.instance.getUser().getCourse().getStatistics();
                const learnedWords = statistics.getLearnedWords();
                const learnedWordsData = await learnedWords.getData();
                if (learnedWordsData.hasOwnProperty('words')) {
                    this.learnedWordsFavourites = learnedWordsData.words.filter(word => (word.favourite === true));
                }
            },
            debugModeOn() {
                this.debugMode = true;
            },
            openModalStory(storyId) {
                this.modalStoryContentId = storyId;
                this.modalStoryVisible = true;
            },
            closeModalStory() {
                this.modalStoryContentId = null;
                this.modalStoryVisible = false;
            },
            setActiveVariations() {
                if (this.course) {
                    const variationCategories = this.course.getVariationsInfo();
                    let activeVariations = [];
                    variationCategories.forEach(category => {
                        const { variations } = category;
                        if (variations && variations.length > 0) {
                            activeVariations.push(...variations.filter(variation => variation.active));
                        }
                    });
                    this.activeVariations = activeVariations;
                }
            },
            async enableVariationOnlyThis(variation_uuid) {
                if (this.course && variation_uuid) {
                    this.isLoading = true;
                    this.lock();
                    await this.course.setVariation({ variation_uuid, enabled: true, deactivate_other: true });

                    setTimeout(async () => {
                        this.setActiveVariations();
                        await this.course.getGuessQueue().removeCurrentQuestion();
                        EventBus.$emit('guess:next');
                    }, 500);

                    setTimeout(() => {
                        this.unLock();
                        this.isLoading = false;
                    }, 500);
                }
            },
            enableVariationByFeatureFlag(flagName) {
                if (this.course && flagName) {
                    const variationCategories = this.course.getVariationsInfo();
                    variationCategories.forEach(category => {
                        const { variations } = category;
                        if (variations && variations.length > 0) {
                            let filteredVariations = variations.filter(v => v.features.includes(flagName));
                            if (filteredVariations.length > 0) {
                                let variationWithFeature = _.first(filteredVariations);
                                this.enableVariationOnlyThis(variationWithFeature.uuid);
                            }
                        }
                    });
                }
            },
            updateSpellingsVisibility(state) {
                if (state) {
                    // check if form-spelling message onboarding has been shown to the user already
                    if (!ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.GUESS_FORM_SPELLING)) {
                        this.triggerOnboardingStep('form-spellings-message');
                    }
                }
                this.formSpellingsVisible = state;
            },
            shouldStartVoiceInput() {
                if (this.previous_answer && this.previous_answer.answer_type === 'voice' && this.auto_advance && !this.message && !this.gameOver && !this.modalStoryVisible && !this.dayInsightsShown) {
                    EventBus.$emit('voice-input:start-listening');
                }
            },
            listenEventBus() {
                EventBus.$on('guess:submit', this.submit);
                EventBus.$on('guess:previous', this.goPrevious);
                EventBus.$on('guess:current', this.goCurrent);
                EventBus.$on('guess:toggle-tutor', this.toggleTutor);
                EventBus.$on('guess:onboarding-step', this.triggerOnboardingStep);
                EventBus.$on(`settings:${ NAME.SETTING_AUTO_ADVANCE }`, this.setAutoAdvanceLocal);
                EventBus.$on(`settings:${ NAME.SETTING_STRICT_DIACRITICS }`, this.setStrictDiacritics);
                EventBus.$on('guess:toggle-visuals', this.setVisuals);
                EventBus.$on('guess:debug-autoplay-start', this.debugAutoPlayStart);
                EventBus.$on('guess:debug-autoplay-stop', this.debugAutoPlayStop);
                EventBus.$on('guess:reveal', this.checkEmptyAnswerOnboarding);
                EventBus.$on("guess:show-day-insights", this.openDayInsights);
                EventBus.$on("guess:show-message", this.showMessage);
                EventBus.$on("guess:clear-hint-mark", this.clearHintMark);
                EventBus.$on("guess:show-hint-mark", this.showHintMark);
                EventBus.$on("guess:enable-variation-by-feature-flag", this.enableVariationByFeatureFlag);
                EventBus.$on("debug:guess-debug", this.debugModeOn);
                EventBus.$on("guess:lock", this.lock);
                EventBus.$on("guess:un-lock", this.unLock);
                EventBus.$on('story:open', this.openModalStory);
                EventBus.$on('story:close', this.closeModalStory);
            },
            unListenEventBus() {
                EventBus.$off('guess:submit', this.submit);
                EventBus.$off('guess:previous', this.goPrevious);
                EventBus.$off('guess:current', this.goCurrent);
                EventBus.$off('guess:toggle-tutor', this.toggleTutor);
                EventBus.$off('guess:onboarding-step', this.triggerOnboardingStep);
                EventBus.$off(`settings:${ NAME.SETTING_AUTO_ADVANCE }`, this.setAutoAdvanceLocal);
                EventBus.$off(`settings:${ NAME.SETTING_STRICT_DIACRITICS }`, this.setStrictDiacritics);
                EventBus.$off('guess:toggle-visuals', this.setVisuals);
                EventBus.$off('guess:debug-autoplay-start', this.debugAutoPlayStart);
                EventBus.$off('guess:debug-autoplay-stop', this.debugAutoPlayStop);
                EventBus.$off('guess:reveal', this.checkEmptyAnswerOnboarding);
                EventBus.$off("guess:show-day-insights", this.openDayInsights);
                EventBus.$off("guess:show-message", this.showMessage);
                EventBus.$off("guess:clear-hint-mark", this.clearHintMark);
                EventBus.$off("guess:show-hint-mark", this.showHintMark);
                EventBus.$off("guess:enable-variation-by-feature-flag", this.enableVariationByFeatureFlag);
                EventBus.$off("guess:lock", this.lock);
                EventBus.$off("guess:un-lock", this.unLock);
                EventBus.$off("debug:guess-debug", this.debugModeOn);
            }
        },
        watch: {
            async question(newQ, oldQ) {
                if (newQ !== oldQ) {
                    this.closeTutor();
                    this.clearHintMark();
                    this.clearThingsBeforeNextCard();
                    this.closeMessage();
                    this.closeGrammar();
                    this.createQuestion();
                    this.loadQuestionAudios(newQ);
                    this.setCardVisualData();
                    if (this.previous_answer && this.previous_answer.confirmed) {
                        this.previous_question = _.cloneDeep(oldQ);
                        this.previousGrammarTable = _.cloneDeep(this.grammarTable);
                    } else {
                        this.previous_question = null;
                        this.previousGrammarTable = null;
                    }
                    this.grammarTable = await this.getGrammarTableIfAvailable();
                    this.gameOver = this.isGameOver();
                    await this.checkOnboarding();
                    this.shouldOpenSurvey();
                    await this.setLearnedWordsFavourites();
                    if (this.autoPlay) {
                        this.autoPlayTimeout = setTimeout(() => {
                            this.debugAutoPlay();
                        }, this.autoPlayDelay)
                    }
                    this.shouldStartVoiceInput();
                }
            }
        },
        created() {
            this.listenEventBus();
            this.setAutoAdvanceLocal();
            this.setVisuals(true);
            this.setStrictDiacritics();
            this.createQuestion();
            this.setLearnedWordsFavourites();
        },
        async mounted() {
            EventBus.$emit('guess:rendered');
            this.setAudioPlayerListeners();
            this.setActiveVariations();
            if (this.question) {
                this.loadQuestionAudios(this.question);
                this.grammarTable = await this.getGrammarTableIfAvailable();
                this.setCardVisualData();
            }
            await this.checkOnboarding();
            this.shouldOpenSurvey();
            document.addEventListener('keydown', this.onDocumentKeyDown);
        },
        beforeDestroy() {
            this.unListenEventBus();
            this.$emit('before-destroy');
            document.removeEventListener('keydown', this.onDocumentKeyDown);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    main.guess {
        position: unset;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        grid-gap: 1rem;
        grid-template-areas:
            "card card"
            "footer footer";
        max-width: pxToRem(680);
        margin: 3rem auto;
        @include respond-to('small') {
            position: static;
        }
        @include respond-to('medium') {
            margin: 1.5rem auto 0;
        }
        > div.locked-message {
            grid-area: locked;
            margin: 0 1rem;
        }
        > div.card-grid {
            grid-area: card;
            margin: 0 1rem;
        }
        > div.tutor {
            grid-area: tutor;
            margin-bottom: 1rem;
        }
        > div.grammar-tag {
            grid-area: grammar;
        }
        > footer {
            grid-area: footer;
            margin: auto 0;
            > div.guess-message {
                margin: 1rem 1rem pxToRem(24);
            }
        }
        &.tutor-open {
            max-width: pxToRem(1080);
            grid-template-areas:
                "card tutor"
                "footer tutor";
            @include respond-to('small') {
                grid-template-columns: 1fr;
                grid-template-areas:
                        "card"
                        "tutor"
                        "footer";
            }
        }
        > div.grammar-tag {
            margin: 0 1rem 1rem 0;
        }
        &.grammar-open {
            grid-template-columns: 2fr 1fr;
            max-width: pxToRem(1080);
            grid-template-areas:
                "card grammar"
                "footer grammar";
            @include respond-to('small') {
                grid-template-columns: 1fr;
                    grid-template-areas:
                        "card"
                        "grammar"
                        "footer";
                > div.grammar-tag {
                    margin: 1rem;
                }
            }
        }
        &.game-over {
            grid-template-rows: auto 1fr auto;
            grid-template-areas:
                "locked locked"
                "card card"
                "footer footer";
        }

        > div.survey {
            bottom: 1rem;
            right: 1rem;
            body[data-interface-language="ar"] & {
                right: unset;
                left: 1rem;
            }
            @include respond-to('medium') { // TODO: make this responsive
                display: none;
            }
        }

        .slide-fade-enter-active {
            transition: all .3s ease;
        }
        .slide-fade-enter {
            transform: translateX(100px);
            opacity: 0;
        }

        .slide-down-fade-enter-active {
            transition: all .3s ease;
        }
        .slide-down-fade-enter {
            transform: translateY(-100px);
            opacity: 0;
        }
        .slide-fade-insights-enter-active {
            transition: transform 250ms ease, opacity 350ms linear;
        }
        .slide-fade-insights-leave-active {
            transition: transform 200ms ease, opacity 100ms linear;
        }
        .slide-fade-insights-enter, .slide-fade-insights-leave-to {
            transform: translateX(200px);
            opacity: 0;
        }
    }
</style>
