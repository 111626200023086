var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M35 9v26H8l-2-3.714",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10 27v2a2 2 0 01-2 2H6V5h2a2 2 0 012 2v1M10 27v2a2 2 0 002 2h19V5H12a2 2 0 00-2 2v2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M10 33h5v6l-2.5-2-2.5 2v-6z", fill: "#63E1E7" },
      }),
      _c("rect", {
        attrs: {
          x: "14",
          y: "10",
          width: "13",
          height: "7",
          rx: "1",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 21a1 1 0 011-1h11a1 1 0 110 2H15a1 1 0 01-1-1z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }