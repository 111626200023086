var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-context target",
      class: { hasSwt: this.question.hasSwt },
      style: { fontSize: _vm.question.fontSize + "rem" },
    },
    [
      _vm.swtInfo
        ? _c("card-swt", { attrs: { swtInfo: _vm.swtInfo } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "context-container",
          style: { fontSize: _vm.question.fontSize + "rem" },
        },
        [
          _vm.question.parsedContext
            ? _c(
                "div",
                { staticClass: "context" },
                [
                  _vm._l(_vm.question.parsedContext, function (word, index) {
                    return [
                      word.current
                        ? [
                            word.noWrapEnd
                              ? [
                                  _vm.showSpacer(word.before)
                                    ? _c("span", { staticClass: "before" }, [
                                        _vm._v(_vm._s(word.before)),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    { staticClass: "no-wrap" },
                                    [
                                      _c(
                                        "card-gap",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "is-first-instance":
                                                _vm.isFirstInstance(index),
                                              "is-last-focused":
                                                word === _vm.lastFocusedWord,
                                              onlySynonymAnswers:
                                                _vm.onlySynonymAnswers,
                                              word: word,
                                              answer: _vm.answer,
                                              on_previous: _vm.on_previous,
                                              disabled: _vm.disabled,
                                              autoPlay: _vm.autoPlay,
                                            },
                                            on: {
                                              "gap-focus": () =>
                                                (_vm.$data.lastFocusedWord =
                                                  word),
                                              submit: _vm.submit,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.handleWordClick(
                                                  word,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          _vm.$listeners
                                        )
                                      ),
                                      _vm.showSpacer(word.after)
                                        ? _c("span", { staticClass: "after" }, [
                                            _vm._v(_vm._s(word.after)),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _vm.showSpacer(word.before)
                                    ? _c("span", { staticClass: "before" }, [
                                        _vm._v(_vm._s(word.before)),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "card-gap",
                                    _vm._g(
                                      {
                                        attrs: {
                                          "is-first-instance":
                                            _vm.isFirstInstance(index),
                                          "is-last-focused":
                                            word === _vm.lastFocusedWord,
                                          onlySynonymAnswers:
                                            _vm.onlySynonymAnswers,
                                          word: word,
                                          answer: _vm.answer,
                                          on_previous: _vm.on_previous,
                                          disabled: _vm.disabled,
                                          autoPlay: _vm.autoPlay,
                                          "day-insights-shown":
                                            _vm.dayInsightsShown,
                                        },
                                        on: {
                                          "gap-focus": () =>
                                            (_vm.$data.lastFocusedWord = word),
                                          submit: _vm.submit,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleWordClick(
                                              word,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      _vm.$listeners
                                    )
                                  ),
                                  _vm.showSpacer(word.after)
                                    ? _c("span", { staticClass: "after" }, [
                                        _vm._v(_vm._s(word.after)),
                                      ])
                                    : _vm._e(),
                                ],
                          ]
                        : [
                            word.noWrapEnd
                              ? [
                                  _vm.showSpacer(word.before)
                                    ? _c("span", { staticClass: "before" }, [
                                        _vm._v(_vm._s(word.before)),
                                      ])
                                    : _vm._e(),
                                  _c("span", { staticClass: "no-wrap" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "word",
                                        class: { hasUsage: !!word.usage },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleWordClick(
                                              word,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(word.word))]
                                    ),
                                    _vm.showSpacer(word.after)
                                      ? _c("span", { staticClass: "after" }, [
                                          _vm._v(_vm._s(word.after)),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              : [
                                  _vm.showSpacer(word.before)
                                    ? _c("span", { staticClass: "before" }, [
                                        _vm._v(_vm._s(word.before)),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "word",
                                      class: { hasUsage: !!word.usage },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordClick(
                                            word,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(word.word))]
                                  ),
                                  _vm.showSpacer(word.after)
                                    ? _c("span", { staticClass: "after" }, [
                                        _vm._v(_vm._s(word.after)),
                                      ])
                                    : _vm._e(),
                                ],
                          ],
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }