var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "repeat-progress",
      class: this.question.repeatProgress,
      on: { click: _vm.openModal },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "repeat-description" }, [
        _c(
          "span",
          { class: { alert: this.question.repeatProgress === "new" } },
          [_vm._v(_vm._s(_vm.repeatDesc))]
        ),
        _vm._v(" "),
        _c("span", {
          staticClass: "more",
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("learn_repeat_progress_find_out_more")
            ),
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("div", { staticClass: "stripe one" }),
      _vm._v(" "),
      _c("div", { staticClass: "stripe two" }),
      _vm._v(" "),
      _c("div", { staticClass: "stripe three" }),
      _vm._v(" "),
      _c("div", { staticClass: "stripe four" }),
      _vm._v(" "),
      _c("div", { staticClass: "stripe five" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }