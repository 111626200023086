var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid variations-grid" },
    [
      _vm.create
        ? _c(
            "div",
            {
              staticClass: "grid-item create",
              on: { click: _vm.navigateToCourseWizard },
            },
            [
              !_vm.isSubscriptionActive
                ? _c("status-icon", { attrs: { type: "locked" } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "variation-icon" },
                [
                  _c("course-wizard-variation-icon-large", {
                    attrs: { width: "80", height: "80", viewBox: "0 0 40 40" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(this.$i18n("hub_course_wizard_create")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.variations, function (variation) {
        return [
          _c("variation-grid-item", {
            key: `variation_item_${variation.uuid}`,
            attrs: {
              variation: variation,
              "is-subscription-active": _vm.isSubscriptionActive,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }