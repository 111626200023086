var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "debug-modal",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "debug-modal-window" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c(
                "tabs",
                { on: { close: _vm.close } },
                [
                  _c(
                    "tab",
                    { attrs: { title: "Guess helpers" } },
                    [_c("tab-guess")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "Lexical units" } },
                    [_c("tab-lu")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "Course Icons" } },
                    [_c("tab-course-icons")],
                    1
                  ),
                  _vm._v(" "),
                  _c("tab", { attrs: { title: "Misc" } }, [_c("tab-misc")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm.autoPlayOpen
    ? _c(
        "div",
        { staticClass: "debug-auto-play-stop" },
        [
          _c("v-button", {
            attrs: {
              text: "Stop autoplay",
              color: "alert",
              filled: true,
              nowrap: true,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.stopAutoPlay.apply(null, arguments)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }