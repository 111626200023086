
'use strict';

import _ from 'lodash';

import AsyncDestroyable from './async.destroyable.js';

import ControllerManager from '../modules/controller.manager.js';
import { ONBOARDING_STEP } from "Controller/onboarding.js";

export const STATUS = {
    INITIAL: 'initial',
    DISABLED: 'disabled',
    EVALUATING: 'evaluating',
    OFFERED: 'offered',
    NOT_OFFERED: 'not_offered',
    IN_PROGRESS: 'in_progress',
    ALL_DONE: 'all_done',
    COMPLETED: 'completed'
};
export const STATUS_VALUES = _.values(STATUS);


class FastTrackingStateError extends Error {
    constructor (message) {
        super(message);
        this.name = 'FastTrackingStateError';
    }
}

export class FastTrackingState extends AsyncDestroyable {

    constructor (user, course) {
        super(['initializeState', '_updateStatus', 'setStatus']);

        this._user = user;
        this._course = course;
        this._status = null;
    }

    destroy () {
        let self = this;

        return Promise.resolve().then(function () {
            delete self._course;
            delete self._user;
        });
    }

    initializeState () {
        console.debug(`Initializing FastTrackingState course="${this._course.UUID}"`);
        // this._status = null;
        return Promise.resolve();
    }

    _updateStatus (status) {
        let oldStatus = this._status;
        this._status = status;

        if (oldStatus === status) {
            return Promise.resolve();
        } else if (oldStatus === STATUS.EVALUATING && status === STATUS.IN_PROGRESS) {
            ControllerManager.instance.getController('Onboarding').queueOnboardingDoorslam('fast-tracking');
        } else if (oldStatus === STATUS.IN_PROGRESS && status === STATUS.ALL_DONE && ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.FAST_TRACKING_DONE, this._course.UUID) === null) {
            ControllerManager.instance.getController('Onboarding').showFastTrackingComplete(1000);
        } else if (oldStatus === STATUS.EVALUATING && status === STATUS.ALL_DONE && ControllerManager.instance.getController('Onboarding').getStepDone(ONBOARDING_STEP.FAST_TRACKING_DONE, this._course.UUID) === null) {
            ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.FAST_TRACKING_DONE, this._course.UUID);
        }

        if (this.isAssessing()) {
            this._user.getEventSender().setSyncImmediate();
        }

        return Promise.resolve();
    }

    getStatus () {
        return this._status;
    }

    setStatus (status) {
        if (STATUS_VALUES.find(value => value === status) === undefined) {
            return Promise.reject(new FastTrackingStateError(`Unknown fast tracking status="${status}"`));
        } else {
            return this._updateStatus(status);
        }
    }

    isAssessing () {
        return this._status === STATUS.EVALUATING ||
               this._status === STATUS.IN_PROGRESS;
    }

    isInProgress () {
        return this._status === STATUS.IN_PROGRESS;
    }
}
