var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bulk-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "bulk-modal-window",
          class: { disabled: this.interactionDisabled },
        },
        [
          !this.interactionDisabled
            ? _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h2", {
            staticClass: "title",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("lessons_creator_editor_add_text_title")
              ),
            },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "sub-title",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("lessons_creator_editor_add_text_microcopy")
              ),
            },
          }),
          _vm._v(" "),
          !_vm.uploadLoading
            ? _c("div", { staticClass: "content" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.seed_text,
                      expression: "seed_text",
                    },
                  ],
                  ref: "seed_text",
                  attrs: {
                    rows: "10",
                    maxlength: "4950",
                    minlength: "1",
                    placeholder: _vm.textareaPlaceholder,
                    ondragstart: "return false",
                    ondrop: "return false",
                  },
                  domProps: { value: _vm.seed_text },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.seed_text = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("upload-file", {
            attrs: { lesson_uuid: this.lesson_uuid },
            on: {
              "text-from-file": _vm.textFromFileArrived,
              "ocr-done": _vm.ocrDone,
              loading: _vm.uploadFileLoading,
            },
          }),
          _vm._v(" "),
          !_vm.uploadLoading
            ? _c(
                "div",
                { staticClass: "action-buttons" },
                [
                  _c("v-button", {
                    attrs: {
                      filled: true,
                      color: "primary",
                      disabled: this.seed_text.length <= 0,
                      i18n: {
                        id: "lessons_creator_editor_add_text_add_words_button",
                        params: { words: 2 },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.addCardsPressed.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      i18n: {
                        id: "lessons_creator_editor_add_text_cancel_button",
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }