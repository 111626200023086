var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "180",
          height: "180",
          viewBox: "0 0 180 180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M134.655 65v-1.545h1.953v-5.327h-.309l-1.656 1.125v-1.768l1.656-1.137h2.089v7.107h1.78V65h-5.513zm7.209-7.7c.626-.75 1.446-1.125 2.459-1.125 1.014 0 1.83.375 2.448 1.125.626.741.939 1.866.939 3.374 0 1.5-.313 2.624-.939 3.374-.618.742-1.434 1.113-2.448 1.113-1.013 0-1.833-.371-2.459-1.113-.618-.75-.927-1.874-.927-3.374 0-1.508.309-2.633.927-3.374zm2.459 6.279c1.072 0 1.607-.969 1.607-2.905 0-1.945-.535-2.917-1.607-2.917-1.063 0-1.594.972-1.594 2.917 0 1.936.531 2.905 1.594 2.905zm5.284-6.28c.626-.75 1.446-1.124 2.46-1.124 1.013 0 1.829.375 2.447 1.125.626.741.939 1.866.939 3.374 0 1.5-.313 2.624-.939 3.374-.618.742-1.434 1.113-2.447 1.113-1.014 0-1.834-.371-2.46-1.113-.618-.75-.927-1.874-.927-3.374 0-1.508.309-2.633.927-3.374zm2.46 6.28c1.071 0 1.607-.969 1.607-2.905 0-1.945-.536-2.917-1.607-2.917-1.063 0-1.595.972-1.595 2.917 0 1.936.532 2.905 1.595 2.905zm4.74-6.749c.346-.437.852-.655 1.52-.655.667 0 1.178.218 1.533.655.354.428.531.989.531 1.681 0 .692-.177 1.257-.531 1.693-.355.429-.866.643-1.533.643-.668 0-1.174-.214-1.52-.643-.346-.436-.52-1-.52-1.693 0-.692.174-1.253.52-1.681zm1.52 3.004c.61 0 .915-.441.915-1.323 0-.396-.079-.713-.235-.952-.149-.247-.375-.37-.68-.37-.305 0-.532.123-.68.37-.148.24-.222.556-.222.952 0 .882.3 1.322.902 1.322zm5.846.655c.659 0 1.166.218 1.52.655.355.428.532.989.532 1.68 0 .693-.177 1.257-.532 1.694-.354.428-.861.643-1.52.643-.667 0-1.178-.215-1.532-.643-.355-.437-.532-1.001-.532-1.693 0-.693.177-1.253.532-1.681.354-.437.865-.655 1.532-.655zm0 3.658c.305 0 .532-.12.68-.358.157-.24.235-.56.235-.964 0-.396-.078-.713-.235-.952-.148-.247-.375-.37-.68-.37a.768.768 0 00-.692.37c-.148.239-.222.556-.222.952 0 .881.304 1.322.914 1.322zm-4.894.853h-1.261l5.241-8.652h1.248L159.279 65z",
          fill: "#fff",
        },
      }),
      _c("path", {
        attrs: {
          d: "M96.7 53.18v67.11H20.75c0-33.11 24.75-60.99 57.63-64.93l18.32-2.18z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: { d: "M18 135a4 4 0 100-8 4 4 0 000 8z", fill: "#fff" },
      }),
      _c("path", {
        attrs: {
          d: "M58.68 81.83h14.76v14.76M55.77 99.5l17.67-17.67",
          stroke: "#9DF4F4",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M161.41 45l-52.14 6.24M109.27 130.92h52.14V73.68M17.69 130.92H91.6M96.7 135.27a4.4 4.4 0 100-8.8 4.4 4.4 0 000 8.8z",
          stroke: "#fff",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }