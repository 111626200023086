<template>
    <div class="icon-button" :class="{ active: this.active || this.pulse }">
        <span v-if="notify && !active" class="notify-blob" />
        <component :is="icon" :data-name="iconName" :class="{ pulse: this.pulse }" />
    </div>
</template>

<script>
    import navArrow from "Images/arrow-nav.svg";
    import diacriticsDe from "Images/diacritics/de.svg";
    import diacriticsEs from "Images/diacritics/es.svg";
    import diacriticsEt from "Images/diacritics/et.svg";
    import diacriticsFr from "Images/diacritics/fr.svg";
    import diacriticsPt from "Images/diacritics/pt.svg";
    import diacriticsIt from "Images/diacritics/it.svg";
    import diacriticsPl from "Images/diacritics/pl.svg";
    import diacriticsNl from "Images/diacritics/nl.svg";
    import diacriticsDa from "Images/diacritics/da.svg";
    import diacriticsNo from "Images/diacritics/no.svg";
    import diacriticsSv from "Images/diacritics/sv.svg";
    import diacriticsTr from "Images/diacritics/tr.svg";
    import tutor from "Images/tutor-24x24.svg";
    import speaker from "Images/speaker-24x24.svg";
    import visuals from "Images/visuals-24x24.svg";
    import tip from "Images/tip-24x24.svg";
    import mic from "Images/mic-24x24.svg";
    import micActive from "Images/mic-24x24-active.svg";

    export default {
        name: 'icon-button',
        props: {
            iconName: {
                type: String,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            notify: {
                type: Boolean,
                default: false
            },
            pulse: {
                type: Boolean,
                default: false
            }
        },
        components: {
            navArrow,
            diacriticsDe,
            diacriticsEs,
            diacriticsEt,
            diacriticsFr,
            diacriticsPt,
            diacriticsIt,
            diacriticsPl,
            diacriticsNl,
            diacriticsDa,
            diacriticsNo,
            diacriticsSv,
            diacriticsTr,
            tutor,
            speaker,
            visuals,
            tip,
            mic,
            micActive
        },
        computed: {
            icon() {
                switch (this.iconName) {
                    case 'back':
                    case 'forward':
                        return navArrow;
                    case 'diacritics-de':
                        return diacriticsDe;
                    case 'diacritics-es':
                        return diacriticsEs;
                    case 'diacritics-et':
                        return diacriticsEt;
                    case 'diacritics-fr':
                        return diacriticsFr;
                    case 'diacritics-pt':
                        return diacriticsPt;
                    case 'diacritics-pl':
                        return diacriticsPl;
                    case 'diacritics-nl':
                        return diacriticsNl;
                    case 'diacritics-it':
                        return diacriticsIt;
                    case 'diacritics-da':
                        return diacriticsDa;
                    case 'diacritics-no':
                        return diacriticsNo;
                    case 'diacritics-sv':
                    case 'diacritics-fi':
                        return diacriticsSv;
                    case 'diacritics-tr':
                        return diacriticsTr;
                    case 'tutor':
                        return tutor;
                    case 'speaker':
                        return speaker;
                    case 'visuals':
                        return visuals;
                    case 'tip':
                        return tip;
                    case 'mic':
                        return mic;
                    case 'mic-active':
                        return micActive;
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.icon-button {
        position: relative;
        border-radius: pxToRem(4);
        width: pxToRem(32);
        height: pxToRem(32);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active,
        &:hover {
            background-color: $colorBackgroundLighter;
        }
        > svg {
            &[data-name="forward"] {
                transform: rotate(180deg);
                body[data-interface-language="ar"] & {
                    transform: none;
                }
            }
            &[data-name="back"] {
                body[data-interface-language="ar"] & {
                    transform: rotate(180deg);
                }
            }
            &.pulse {
                animation: pulse 1s infinite;
            }
        }

        span.notify-blob {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            border-radius: 50%;
            height: .5rem;
            width: .5rem;
            transform: scale(1);
            background: rgba(#FF934D, 1);
            box-shadow: 0 0 0 0 rgba(#FF934D, 1);
            animation: pulse-red 1s infinite;
        }

        @keyframes pulse-red {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(#FF934D, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(#FF934D, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(#FF934D, 0);
            }
        }

        @keyframes pulse {
            0% {
                opacity: 0.2;
            }

            25% {
                opacity: 0.5;
            }

            50% {
                opacity: 1;
            }

            75% {
                opacity: 0.5;
            }

            100% {
                opacity: 0.2;
            }
        }
    }
</style>
