const CCY_MAP = {
    JPY: "¥",
    USD: "$",
    EUR: "€",
    TWD: "NT$",
    NTD: "NTD",
    CAD: "CA$",
    GBP: "£",
    AUD: "A$",
    CNY: "CN¥",
    MXN: "MX$",
    RUB: "RUB",
    UAH: "UAH",
};

const formatWithCurrency = (amount, ccy, locale) => {
    let value = amount;
    if ("string" === typeof amount) {
        value = parseFloat(amount);
    }

    if (window.Intl && window.Intl.NumberFormat) {
        return new window.Intl.NumberFormat(locale, {
            style: "currency",
            currency: ccy,
        }).format(value);
    }

    if ((value * 100) % 100 === 0) {
        // Nice full amount, then no decimals
        return `${CCY_MAP[ccy] || ccy}${(value).toFixed(0)}`;
    }
    // With decimals
    return `${CCY_MAP[ccy] || ccy}${(value).toFixed(2)}`;
};

const noTags = (str) => str.replace(/<[^>]+>/g, "");

export {
    formatWithCurrency,
    noTags
};
