<template>
    <section class="course">
        <course-icon class="course-icon" :value="course.info.source_icon_id" size="large" />
        <course-arrow class="arrow" />
        <course-icon class="course-icon" :value="course.info.target_icon_id" size="large" />
    </section>
</template>

<script>

import CourseIcon from '../../component/course-icon.vue';
import CourseArrow from '../../../../img/register/course_arrow.svg';

export default {
    name: 'register.course',
    components: {
        CourseIcon,
        CourseArrow,
    },
    props: {
        course: {
            type: Object,
            required: true
        },
    },
};
</script>

<style scoped lang="scss">
@import '~Styles/colors';
@import '~Styles/mixins';

section.course {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 13rem;
    padding: 1.5rem 0;

    &::v-deep .course-icon {
        transform: scale(1.4);
    }

    &::v-deep .arrow {
        width: 1.5rem;
        align-self: center;

        > path {
            fill: #2C3143;
        }
    }
}
</style>
