var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-debug" }, [
    _c("div", { staticClass: "uuid", on: { click: _vm.copyUUID } }, [
      _vm._v(_vm._s(this.question.uuid)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "answer" }, [
      _c("h3", [_vm._v("Answer")]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(this.question.word))]),
    ]),
    _vm._v(" "),
    this.question.equivalent_words && this.question.equivalent_words.length > 0
      ? _c("div", { staticClass: "eq-answers" }, [
          _c("h3", [_vm._v("Equivalent Answers")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(this.question.equivalent_words.join(", "))),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.synonyms && this.synonyms.length > 0
      ? _c("div", { staticClass: "synonyms" }, [
          _c("h3", [_vm._v("Synonyms")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(this.synonyms.join(", ")))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.fastTrackingStatus
      ? _c("div", { staticClass: "ft-status" }, [
          _c("h3", [_vm._v("Fast-tracking status")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(this.fastTrackingStatus))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }