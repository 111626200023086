<template>
    <aside class="wordlist-sidebar" v-if="this.selectedWord">
        <header>
            <h2 v-html="this.$i18n('dashboard_word_list_word_details_title')" />
            <close-icon @click="close" class="close" />
        </header>
        <div class="content-container">
            <div class="homograph" v-if="selectedWord.homograph" @click="playAudio('form')" :class="{ audioIsPlaying: audioIsPlaying && audioTypePlaying === 'form' }">
                    <span class="target">
                        <span class="begin" v-if="selectedWord.homograph && selectedWord.homograph.begin_form">{{ selectedWord.homograph.begin_form }}</span>
                        {{ selectedWord.homograph.form }}
                        <span class="end" v-if="selectedWord.homograph && selectedWord.homograph.end_form">{{ selectedWord.homograph.end_form }}</span>
                    </span>
                <speaker-button :in-progress="audioIsPlaying" />
            </div>
            <grammar :parsed-grammar="selectedWord.homograph.parsed_grammar" />
            <div class="sense-translation" v-if="selectedWord.sense && selectedWord.sense.translations" @click="playAudio('form')" :class="{ audioIsPlaying: audioIsPlaying && audioTypePlaying === 'form' }">
                    <span class="translations-wrapper">
                        <template v-for="(item, index) in selectedWord.sense.translations">
                            <span class="translation">{{ item.translation }}</span>
                            <span v-if="index+1 < selectedWord.sense.translations.length" class="comma">, </span>
                        </template>
                    </span>
            </div>
            <div class="separator" />
            <div class="meta">
                <span class="data-point">{{ wordLastSeen }}</span> | <span class="data-point" v-html="this.$i18n('learned_words_word_guess_count', { args: { guess_count: selectedWord.guess_count }})" />
            </div>
            <div class="separator" />
            <div class="contexts" v-if="selectedWord.sense && selectedWord.sense.contexts && selectedWord.sense.contexts.length > 0">
                <div v-for="(context_item) in selectedWord.sense.contexts" class="context-item">
                    <div class="context target" @click="playAudio('context', context_item.audio_hash)" :class="{ audioIsPlaying: audioHashPlaying === context_item.audio_hash }">{{ context_item.context }}</div>
                    <div class="context-translations" v-if="context_item.translations && context_item.translations.length > 0">
                        <div v-for="(translation_item) in context_item.translations">
                            {{ translation_item.translation }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="note">
                <textarea rows="6" maxlength="1000" v-model.trim="note" @input="debouncedOnNoteChange" :placeholder="this.$i18n('dashboard_word_list_word_details_notes_unfocused')" />
                <div class="note-counter">{{ noteCounter }}</div>
            </div>
        </div>
    </aside>
</template>

<script>
    import CloseIcon from "Images/close-24x24.svg";
    import SpeakerButton from "./speaker-button.vue";
    import Grammar from "./grammar.vue";
    import getConfigValue from "Util/configuration.js";
    import AudioPlayer from "Util/audioplayer.js";
    import UserManager from "Modules/usermanager.js";
    import i18nUtils from "Util/i18n.js";
    import { debounce } from "lodash";

    export default {
        name: 'wordlist-sidebar',
        components: {
            CloseIcon,
            SpeakerButton,
            Grammar
        },
        props: {
            selectedWord: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                audioIsPlaying: false,
                audioTypePlaying: null,
                audioHashPlaying: null,
                note: this.selectedWord.note
            }
        },
        methods: {
            close () {
                this.$emit('close');
            },
            playAudio: async function (type, hash = null) {
                if (!this.audioIsPlaying && this.selectedWord) {
                    const mediaUrl = getConfigValue('media-base-url');
                    const voiceUuid = await UserManager.instance.getUser().getCourse().getVoiceUUID();
                    let audioUrl;

                    if (type === 'form') {
                        const audioHash = (hash) ? hash : this.selectedWord.sense.audio_hash;
                        audioUrl = `${mediaUrl}/v1/${voiceUuid}/word/${audioHash}.mp3`;
                        this.audioTypePlaying = 'form';
                        this.audioHashPlaying = audioHash;
                    } else if (type === 'context') {
                        const audioHash = (hash) ? hash : this.selectedWord.context.audio_hash;
                        audioUrl = `${mediaUrl}/v1/${voiceUuid}/context/${audioHash}.mp3`;
                        this.audioTypePlaying = 'context';
                        this.audioHashPlaying = audioHash;
                    }

                    if (audioUrl) {
                        this.audioIsPlaying = true;

                        AudioPlayer.playLearnedWordAudio(audioUrl).then( () => {
                            this.audioIsPlaying = false;
                            this.audioTypePlaying = null;
                            this.audioHashPlaying = null;
                        });
                    }
                }
            },
            updateNote() {
                this.$emit('update-note', { word: this.selectedWord, note: this.note });
            }
        },
        computed: {
            wordLastSeen() {
                if (this.selectedWord && this.selectedWord.guess_ts) {
                    const last_seen = i18nUtils.getTimeAgo(this.selectedWord.guess_ts);
                    return i18nUtils.prop('learned_words_word_last_seen', { last_seen });
                }
            },
            noteCounter() {
                const maxChars = 1000;
                return `${ (this.note) ? this.note.length : 0 }/${ maxChars }`;
            },
            debouncedOnNoteChange () { // waits for 500ms after user has finished typing before triggering the note change
                return debounce(this.updateNote, 2000);
            }
        },
        watch: {
            selectedWord (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.audioIsPlaying = false;
                    this.audioTypePlaying = null;
                    this.note = newValue.note;
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    aside.wordlist-sidebar {
        border-radius: .5rem;
        padding: 1rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-width: pxToRem(360);
        max-width: pxToRem(360);
        min-height: pxToRem(400);
        > header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            > h2 {
                font-size: fontSize(26);
                font-weight: $font-bold;
            }
            > svg.close {
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-left: unset;
                    margin-right: auto;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        > div.content-container {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            height: 0;
            flex-grow: 1;
            > div.separator {
                background-color: $colorBackgroundLighter;
                height: 1px;
                width: 100%;
            }
            > div.homograph {
                font-size: fontSize(18);
                color: $colorTargetPrimary;
                display: flex;
                flex-direction: row;
                gap: .5rem;
                align-items: center;
                > span.target {
                    color: $colorTargetPrimary;
                    padding: 0.1rem 0.3rem;
                    border-radius: 0.2rem;
                    > span.begin, > span.end {
                        color: $colorTargetSecondary;
                    }
                    > span.speaker-button {
                        margin-left: .5rem;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.audioIsPlaying {
                    > span.target {
                        background: rgba(108, 213, 229, 0.5);
                    }
                }
            }
            > div.sense-translation {
                font-size: fontSize(18);
                display: flex;
                flex-direction: row;
                align-items: center;
                > span.translations-wrapper {
                    padding: 0.1rem 0.3rem;
                    border-radius: 0.2rem;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.audioIsPlaying {
                    > span.translations-wrapper {
                        background: rgba(108, 213, 229, 0.5);
                    }
                }
                > span.speaker-button {
                    margin-left: .5rem;
                }
            }
            > div.contexts {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                @include minimalistScrollbar;
                overflow-y: auto;
                flex: 1 1 auto;
                min-height: 0;
                > div.context-item {
                    > div.context {
                        color: $colorTargetPrimary;
                        padding: 0.1rem 0.3rem;
                        border-radius: 0.2rem;
                        &:hover {
                            cursor: pointer;
                        }
                        &.audioIsPlaying {
                            background: rgba(108, 213, 229, 0.5);
                        }
                    }
                    > div.context-translations {
                        padding: 0.1rem 0.3rem;
                        border-radius: 0.2rem;
                        &:hover {
                            cursor: pointer;
                        }
                        &.audioIsPlaying {
                            background: rgba(108, 213, 229, 0.5);
                        }
                    }
                }
            }
            > div.meta {
                //margin-top: auto;
                font-size: fontSize(12);
                color: $colorSourceSecondary;
                display: flex;
                flex-direction: row;
                gap: .5rem;
                padding: 0.1rem 0.3rem;
            }

            > div.note {
                background-color: $colorBackgroundLighter;
                border-radius: .25rem;
                padding: .5rem;
                textarea {
                    @include minimalistScrollbar(8);
                    width: 100%;
                    margin-bottom: .5rem;
                }
                > div.note-counter {
                    color: $colorSourceSecondary;
                    font-size: fontSize(10);
                }
            }
            *.target {
                body[data-interface-language="ar"] & {
                    text-align: right;
                }
            }
        }
    }
</style>
