var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "misc-debug" }, [
    _c("h2", [_vm._v("Misc helpers")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c("v-button", {
          attrs: { text: "Relevant Words Graph", size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.goToRelevantWords.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Open New course dialog", size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.openNewCourseDialog.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Show FR dialog", size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.openFTDialog.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Show NO FT dialog", size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.openNoFTDialog.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Show FT complete dialog", size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.openFTEndDialog.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Open Cookie Consent", size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.openCookieConsent.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }