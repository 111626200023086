var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { opacity: ".6", fill: "#2C3143" } }, [
        _c("path", {
          attrs: {
            d: "M3.3 7.375l4.919-6.15c.59-.737 1.78-.32 1.78.626V8a1 1 0 01-1 1H4.08a1 1 0 01-.78-1.625z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M12.7 8.625l-4.92 6.149c-.59.738-1.78.32-1.78-.625v-6.15a1 1 0 011-1h4.92a1 1 0 01.78 1.626z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }