export default {
    "ar": "messages_ar.681ca2ce88c6cf06cd2608d0c4b257fb.json",
    "de": "messages_de.7dcc4c32af30e49c3d5e61b6f84b3f7a.json",
    "en-GB": "messages_en-GB.203223f6ebc6c972af3f77aed5908ab7.json",
    "en-US": "messages_en-US.3f2e7a2e02c695cf495abd054de309f0.json",
    "en": "messages_en.2340215e0c7d1a0c25f69dbb3f0b4da4.json",
    "es": "messages_es.4df007e86a319b9e574ee403e75c95db.json",
    "et": "messages_et.d762cdda2da7b62f989bf85e0b140780.json",
    "fr-CA": "messages_fr-CA.103c50937f0376c4311e086907460346.json",
    "fr": "messages_fr.7915a2b8adcdc5d3c9fc726207c0cf85.json",
    "hi": "messages_hi.3777feab22e94b3e161498cb29657d89.json",
    "it": "messages_it.8163cb0fe7ffd76b1a975191bdd4f189.json",
    "ja": "messages_ja.e581798886698f479de2232cdce0b42c.json",
    "nl": "messages_nl.d735200c6b6bb9e4406b19e0dfe8770d.json",
    "pl": "messages_pl.e1cf5a88d6cf7c0492512b9ba2ead359.json",
    "pt-BR": "messages_pt-BR.b63fc8602c0a2732e18746bce7d7a164.json",
    "pt": "messages_pt.3dd04df68956cc27f6da1bfd7c24c5d1.json",
    "ru": "messages_ru.949a738233776612d3bd977fef532574.json",
    "th": "messages_th.008c69e49c7daceb026ea500db2936c4.json",
    "tr": "messages_tr.637c6c9f26018d9001ca5133bf35a8dd.json",
    "uk": "messages_uk.6f12d708af26357720ced778d457809e.json",
    "vi": "messages_vi.04ef85a6cbc20eef74be6933f0fc25d4.json",
    "zh-Hans": "messages_zh-Hans.f94db90a2e2a9ef1293aec4ad6447df4.json",
    "zh-Hant": "messages_zh-Hant.32d9a95f33ba7b5a6b46eb194a625682.json"
};