var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "variation-modal-share" },
    [
      !_vm.copied
        ? _c("v-button", {
            attrs: {
              i18n: { id: "variation_page_continue_share_button" },
              filled: true,
              color: "paper",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.copyShareUrl.apply(null, arguments)
              },
            },
          })
        : _c("div", { staticClass: "copied-message" }, [
            _vm._v("\n        📋   "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("variation_page_link_copied_notification")
                ),
              },
            }),
          ]),
      _vm._v(" "),
      _c("input", {
        ref: "shareUrlInput",
        staticClass: "shareUrlInput",
        attrs: { type: "hidden" },
        domProps: { value: _vm.variation.share_url },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }