var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.storyComponent
    ? _c("div", { staticClass: "modal-story" }, [
        _c(
          "div",
          {
            staticClass: "modal-story-window",
            attrs: { "data-story": _vm.contentId },
          },
          [
            _vm.current !== 0
              ? _c(
                  "svg",
                  {
                    staticClass: "prev",
                    attrs: {
                      width: "40",
                      height: "40",
                      viewBox: "0 0 40 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                    on: { click: _vm.previousPage },
                  },
                  [
                    _c("circle", {
                      attrs: { cx: "20", cy: "20", r: "20", fill: "white" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M23 13L16 20L23 27",
                        stroke: "#2C3143",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current !== _vm.storyPagesCount - 1
              ? _c(
                  "svg",
                  {
                    staticClass: "next",
                    attrs: {
                      width: "40",
                      height: "40",
                      viewBox: "0 0 40 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                    on: { click: _vm.nextPage },
                  },
                  [
                    _c("circle", {
                      attrs: { cx: "20", cy: "20", r: "20", fill: "white" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M17 27L24 20L17 13",
                        stroke: "#2C3143",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "nav",
              { ref: "story_nav" },
              _vm._l(_vm.storyPagesCount, function (i) {
                return _c("div", [_c("div", { key: i })])
              }),
              0
            ),
            _vm._v(" "),
            _c("header", [
              _vm.storyTitle
                ? _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.storyTitle)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "header-actions" }, [
                this.isPaused
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          width: "38",
                          height: "38",
                          viewBox: "0 0 38 38",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                        on: { click: _vm.continueStory },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            opacity: "0.5",
                            cx: "18.9999",
                            cy: "18.9995",
                            r: "18",
                            transform: "rotate(-1 18.9999 18.9995)",
                            fill: "white",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M14 15V12.7896C14 12.0072 14.8578 11.5279 15.5241 11.9379L25.6161 18.1483C26.2506 18.5388 26.2506 19.4612 25.6161 19.8517L15.5241 26.0621C14.8578 26.4721 14 25.9928 14 25.2104V20",
                            stroke: "#2C3143",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        staticClass: "pause",
                        attrs: {
                          width: "38",
                          height: "38",
                          viewBox: "0 0 38 38",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                        on: { click: _vm.pauseStory },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            opacity: "0.5",
                            cx: "18.9999",
                            cy: "18.9995",
                            r: "18",
                            transform: "rotate(-1 18.9999 18.9995)",
                            fill: "white",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M13 10C11.3431 10 10 11.3431 10 13V25C10 26.6569 11.3431 28 13 28H14C15.6569 28 17 26.6569 17 25V13C17 11.3431 15.6569 10 14 10H13ZM12 13C12 12.4477 12.4477 12 13 12H14C14.5523 12 15 12.4477 15 13V25C15 25.5523 14.5523 26 14 26H13C12.4477 26 12 25.5523 12 25V13ZM24 10C22.3431 10 21 11.3431 21 13V19C21 19.5523 21.4477 20 22 20C22.5523 20 23 19.5523 23 19V13C23 12.4477 23.4477 12 24 12H25C25.5523 12 26 12.4477 26 13V25C26 25.5523 25.5523 26 25 26H24C23.4477 26 23 25.5523 23 25V23C23 22.4477 22.5523 22 22 22C21.4477 22 21 22.4477 21 23V25C21 26.6569 22.3431 28 24 28H25C26.6569 28 28 26.6569 28 25V13C28 11.3431 26.6569 10 25 10H24Z",
                            fill: "#2C3143",
                          },
                        }),
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "close",
                    attrs: {
                      width: "38",
                      height: "38",
                      viewBox: "0 0 38 38",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                    on: { click: _vm.close },
                  },
                  [
                    _c("circle", {
                      attrs: {
                        opacity: "0.5",
                        cx: "18.9999",
                        cy: "18.9995",
                        r: "18",
                        transform: "rotate(-1 18.9999 18.9995)",
                        fill: "white",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M13 13L25 25",
                        stroke: "#2C3143",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M25 13L13 25",
                        stroke: "#2C3143",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "story-content", on: { touchstart: _vm.onTouch } },
              [
                _c(_vm.storyComponent, {
                  tag: "component",
                  attrs: { "active-index": _vm.current },
                  on: { "story-data": _vm.setStoryData, close: _vm.close },
                }),
              ],
              1
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }