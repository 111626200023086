var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button-component",
      class: {
        filled: _vm.filled,
        disabled: _vm.disabled,
        rounded: _vm.rounded,
        borderless: _vm.borderless,
        nowrap: _vm.nowrap,
      },
      attrs: { "data-size": _vm.size, "data-color": _vm.buttonColor },
      on: { click: _vm.handleClick },
    },
    [
      _vm.text
        ? _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
        : _vm.html
        ? _c("span", {
            staticClass: "text",
            domProps: { innerHTML: _vm._s(_vm.html) },
          })
        : _vm.i18n
        ? _c("span", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(_vm.i18n.id, {
                  collection: _vm.i18n.collection,
                  args: _vm.i18n.params,
                })
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.icon === "course-wizard"
        ? _c(
            "span",
            { staticClass: "icon" },
            [_c("course-wizard-variation-icon-small")],
            1
          )
        : _vm.icon === "continue"
        ? _c("span", { staticClass: "icon" }, [_c("continue-icon")], 1)
        : _vm.icon === "tick"
        ? _c("span", { staticClass: "icon" }, [_c("tick-icon")], 1)
        : _vm.icon === "trash"
        ? _c("span", { staticClass: "icon" }, [_c("trash-icon")], 1)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }