<template>
    <div class="manage">
        <h1 v-html="this.$i18n('pay_manage_title')" />
        <div class="columns">
            <div class="column braintree">
                <braintree :token="token"
                           :user="user"
                           :enableThreeDSecure="enableThreeDSecure"
                           :amount="this.activeProduct ? this.activeProduct.amount : null"
                           v-on:set-braintree-instance="setBraintreeInstance"
                           v-on:payment-option-selected="setPaymentOptionSelected"
                           v-on:payment-method-requestable="setPaymentMethodRequestable"
                />
            </div>
            <div class="column">
                <div class="summary">
                    <h4 v-html="this.$i18n('account_subscription_heading')" />
                    <div class="active-product">
                        <span>{{ this.activeProduct.title }}</span>
                    </div>
                    <div class="info">
                        <p v-html="this.$i18n('account_subscription_paid_billing', { args: { 0: this.activeProduct.formattedPrice, 1: this.activeProduct.formattedBillingInterval }})" />
                        <p v-html="this.$i18n('account_subscription_paid_renew', { args: { 0: this.activeProduct.formattedBillingDate }})" />
                    </div>
                </div>
                <div class="footnote">
                    <p v-html="this.$i18n(this.brandingId, { collection: 'pay_product_recurring_text_cancel' })" />
                </div>
                <div class="actions">
                    <v-button @click.native="update" :i18n="{ id: 'pay_manage_btn' }" :filled="true" color="primary" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Braintree from "./braintree.vue";
    import VButton from "ViewComponent/v-button.vue";
    import Loader from "ViewComponent//loader.vue";
    import Terms from "ViewComponent//terms.vue";
    import i18nUtils from "Util/i18n.js";
    import { EventBus } from "Util/vue-event-bus.js";
    import { TYPE as TOAST_TYPE } from "../../toaster/constants.js";

    export default {
        name: 'manage',
        components: { Braintree, VButton, Loader, Terms },
        props: {
            user: {
                type: Object,
                required: true
            },
            activeProduct: {
                type: Object
            },
            enableThreeDSecure: {
                type: Boolean
            },
            brandingId: {
                type: String
            },
            token: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                paymentOptionSelected: false,
                paymentMethodRequestable: false,
                braintreeInstance: null
            }
        },
        methods: {
            setBraintreeInstance(instance) {
                this.braintreeInstance = instance;
            },
            setPaymentOptionSelected() {
                this.paymentOptionSelected = true;
            },
            setPaymentMethodRequestable() {
                this.paymentMethodRequestable = true;
            },
            showErrorToast(text) {
                const toast = {
                    text: text,
                    type: TOAST_TYPE.ERROR
                };
                EventBus.$emit('toaster-add', toast);
            },
            showSuccessToast(text) {
                const toast = {
                    text: text,
                    type: TOAST_TYPE.SUCCESS
                };
                EventBus.$emit('toaster-add', toast);
            },
            update() {
                if (this.braintreeInstance) {
                    const self = this;
                    const amount = this.activeProduct.amount;
                    const email = (this.user && this.user.profile && this.user.profile.email) ? this.user.profile.email : '';
                    const enabled3DSecure = this.enableThreeDSecure && (amount !== null) && (amount > 0);
                    const options =  {
                        dataCollector: {
                            kount: true // Required if Kount fraud data collection is enabled
                        }
                    };

                    if (enabled3DSecure) {
                        options.threeDSecure = {
                            amount: amount.toString(),

                            // Request a cardholder challenge to establish SCA when the card is first authorized,
                            // establishing a mandate between you and your customer.
                            // challengeRequested: true,

                            ... (email && { email })
                        };
                    }

                    this.braintreeInstance.requestPaymentMethod(options, function (requestPaymentMethodErr, payload) {
                        if (!requestPaymentMethodErr) {
                            self.braintreePaymentMethodDefault(payload);
                        } else {
                            self.showErrorToast(i18nUtils.prop('failed', null, null, 'pay_manage_message'));
                        }
                    });
                }
            },
            braintreePaymentMethodDefault (payload) {
                const self = this;
                const params = {
                    payment_method_nonce: payload.nonce,
                    device_data: payload.deviceData
                };

                EventBus.$emit('pay:loading', true);

                return Promise.resolve()
                    .then(() => self.user.getPayApi().braintreePaymentMethodDefault(params))
                    .then(() => {
                        EventBus.$emit('pay:loading', false);
                        self.showSuccessToast(i18nUtils.prop('success', null, null, 'pay_manage_message'));
                    }).catch(result => {
                        EventBus.$emit('pay:loading', false);
                        if (result && result.error) {
                            self.showErrorToast(i18nUtils.prop('failed', null, null, 'pay_manage_message'));
                        }
                    });
            },
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.manage {
        > div.columns {
            > div.column {
                > div.summary {
                    h4 {
                        font-weight: $font-bold;
                    }
                    div.active-product {
                        color: $colorPurplePrimary;
                        border: 1px solid $colorBackgroundPurple;
                        border-radius: pxToRem(4);
                        padding: .5rem;
                        margin: 1rem 0;
                    }
                    > div.info {
                        > p {
                            margin-bottom: .5rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
