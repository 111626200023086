var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.lesson ? _vm.lesson.uuid : null,
      staticClass: "course-wizard",
      class: { disabled: !_vm.isSubscriptionActive },
    },
    [
      this.bulkModalVisible
        ? _c("bulk-modal", {
            attrs: {
              lesson_uuid: this.lesson_uuid,
              "interaction-disabled": _vm.interactionDisabled,
            },
            on: { "add-cards": _vm.addCardsToLesson },
          })
        : _vm._e(),
      _vm._v(" "),
      this.tutorialModalVisible
        ? _c("tutorial-modal", { on: { close: _vm.closeTutorialModal } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("survey", {
            attrs: {
              lesson_uuid: this.lesson_uuid,
              course_uuid: _vm.course_info.uuid,
              "survey-source": "course-wizard",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.lesson && !_vm.loading
        ? [
            _c("locked-message", {
              attrs: { "is-subscription-active": this.isSubscriptionActive },
            }),
            _vm._v(" "),
            _c("course-wizard-header", {
              attrs: {
                page: _vm.page,
                course_info: _vm.course_info,
                lesson: _vm.lesson,
                cards: _vm.cards,
                checkedCards: _vm.checkedCards,
                userHasClassroomsFeature: _vm.userHasClassroomsFeature,
                "interaction-disabled": _vm.interactionDisabled,
              },
              on: {
                "name-change": _vm.updateLessonName,
                publish: _vm.publish,
                discard: _vm.discard,
                "navigate-to-lessons": _vm.navigateToLessons,
              },
            }),
            _vm._v(" "),
            _c(
              "main",
              { class: { "card-view-opened": !!this.selected_card } },
              [
                _vm.page === "cards"
                  ? _c("lesson-cards", {
                      attrs: {
                        lesson_uuid: this.lesson_uuid,
                        cards: _vm.cards,
                        selected_card: _vm.selected_card,
                        isPortrait: _vm.isPortrait,
                        lesson: _vm.lesson,
                        course_info: _vm.course_info,
                        "interaction-disabled": _vm.interactionDisabled,
                      },
                      on: {
                        "checked-cards-changed": _vm.checkCardsChanged,
                        "edit-card": _vm.openCardView,
                        "add-cards": _vm.addCardsToLesson,
                        "expand-lesson": _vm.expandLesson,
                      },
                    })
                  : _vm.page === "meta"
                  ? _c("lesson-meta", {
                      attrs: {
                        course_info: _vm.course_info,
                        lesson: _vm.lesson,
                        cards: _vm.cards,
                      },
                      on: { "update-meta": _vm.updateMeta },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selected_card && _vm.page === "cards"
                  ? _c("lesson-card", {
                      attrs: {
                        selected_card: _vm.selected_card,
                        course_info: _vm.course_info,
                        selected_card_contexts: _vm.selected_card_contexts,
                        userHasClassroomsFeature: _vm.userHasClassroomsFeature,
                      },
                      on: {
                        "update-card": _vm.updateCard,
                        "update-card-comment": _vm.updateCardComment,
                        close: _vm.resetSelectedCard,
                        "select-card-context": _vm.updateCardContext,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _c("loader"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }