<template>
    <div class="card-grammar" :class="typeClass" v-if="parsedGrammar">
        <grammar-icon v-if="parsedGrammar.icon" :value="parsedGrammar.icon.icon" />
        <div class="txt type-string" v-if="typesString">
            {{ typesString }}
        </div>
        <div class="txt desc" v-else-if="parsedGrammar.icon && parsedGrammar.icon.description">
            {{ parsedGrammar.icon.description }}
        </div>
        <div class="arrow" v-if="arrow">
            <arrow-icon />
        </div>
    </div>
</template>

<script>
    import GrammarIcon from "ViewComponent/grammar-icon.vue";
    import ArrowIcon from 'Images/arrow-3.svg';
    import { isEmpty } from "lodash";

    export default {
        name: 'card-grammar',
        components: { GrammarIcon, ArrowIcon },
        props: {
            parsedGrammar: {
                type: Object,
                required: true
            },
            full: {
                type: Boolean,
                default: false
            },
            arrow: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            typeClass() {
                if (this.parsedGrammar.icon && this.parsedGrammar.icon.icon) {
                    const typeMap = [{ name: 'masculine' }, { name: 'neutral' }, { name: 'feminine' }];
                    const iconName = this.parsedGrammar.icon.icon;
                    let _typeClass;
                    typeMap.forEach(item => {
                        if (iconName.includes(item.name)) {
                            _typeClass = item.name;
                        }
                    });

                    return _typeClass;
                }
            },
            typesString() {
                let _types = [];

                this.parsedGrammar.fragments.map(fragment => {
                    if (this.full && !isEmpty(fragment.full)) {
                        _types.push(fragment.full);
                    } else if (!isEmpty(fragment.short)) {
                        _types.push(fragment.short);
                    }
                });

                return _types.join(', ');
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.card-grammar {
        background-color: $colorBackgroundGray;
        padding: .5rem;
        border-radius: pxToRem(4);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        > div.grammar-icon {
            margin: 0 pxToRem(4);
        }
        > div.txt {
            margin-left: .5rem;
            margin-right: .5rem;
        }
        > div.arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: pxToRem(24);
            height: pxToRem(24);
            body[data-interface-language="ar"] & {
                > svg {
                    transform: rotate(180deg);
                }
            }
        }
        &.masculine {
            background-color: #F2F8F9;
            > div.arrow {
                > svg {
                    > path {
                        fill: #BDDBDF;
                    }
                }
            }
        }
        &.neutral {
            background-color: #FFF7E2;
            > div.arrow {
                > svg {
                    > path {
                        fill: #FFD86F;
                    }
                }
            }
        }
        &.feminine {
            background-color: #FFF0F0;
            > div.arrow {
                > svg {
                    > path {
                        fill: #FFBAB0;
                    }
                }
            }
        }
    }
</style>
