<template>
    <div class="intro">
        <div v-scroll-to="'#illustrations'" class="anchor">
            <h2 v-html="this.$i18n('intro_offer_see_more_content')" />
            <arrow-icon class="arrow-down" />
        </div>
        <div class="illustrations with-gradient" id="illustrations">
            <div class="items">
                <div class="row">
                    <div class="item">
                        <div class="illustration"><illustration-level /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_2')" />
                            <p v-html="this.$i18n('product_highlights_text_2')" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="illustration"><illustration-learn-faster /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_3')" />
                            <p v-html="this.$i18n('product_highlights_text_3')" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="item">
                        <div class="illustration"><illustration-build-vocabulary /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_4')" />
                            <p v-html="this.$i18n('product_highlights_text_4')" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="illustration"><illustration-study-what /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_5')" />
                            <p v-html="this.$i18n('product_highlights_text_5')" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="illustration"><illustration-vocabulary4you /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_6')" />
                            <p v-html="this.$i18n('product_highlights_text_6')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="track-progress">
            <div class="content">
                <div class="illustration"><illustration-progress /></div>
                <div class="info">
                    <h3 v-html="this.$i18n('product_highlights_title_7')" />
                    <p v-html="this.$i18n('product_highlights_text_7')" />
                </div>
            </div>
        </div>
        <div class="illustrations">
            <div class="items">
                <div class="row">
                    <div class="item">
                        <div class="illustration"><illustration-learn-everywhere /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_8')" />
                            <p v-html="this.$i18n('product_highlights_text_8')" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="illustration"><illustration-science /></div>
                        <div class="info">
                            <h3 v-html="this.$i18n('product_highlights_title_9')" />
                            <p v-html="this.$i18n('product_highlights_text_9')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueScrollTo from 'vue-scrollto';
    import arrowIcon from "Images/arrow-down-purple.svg";
    import illustrationLevel from "Images/pay/intro/start-at-your-level.svg";
    import illustrationLearnFaster from "Images/pay/intro/learn-faster.svg";
    import illustrationBuildVocabulary from "Images/pay/intro/build-vocabulary.svg";
    import illustrationStudyWhat from "Images/pay/intro/study-what-you-want.svg";
    import illustrationVocabulary4you from "Images/pay/intro/vocabularies-for-you.svg";
    import illustrationProgress from "Images/pay/intro/progress.svg";
    import illustrationScience from "Images/pay/intro/science.svg";
    import illustrationLearnEverywhere from "Images/pay/intro/learn-everywhere.svg";

    Vue.use(VueScrollTo);

    export default {
        name: 'intro',
        components: {
            arrowIcon,
            illustrationLevel,
            illustrationLearnFaster,
            illustrationBuildVocabulary,
            illustrationStudyWhat,
            illustrationVocabulary4you,
            illustrationProgress,
            illustrationScience,
            illustrationLearnEverywhere
        }
    }
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';
    div.intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.anchor {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            > svg.arrow-down {
                margin: 2rem 0;
                transition: 0.15s ease-in;
            }
            &:hover {
                > svg.arrow-down {
                    transform: translateY(4px);
                }
            }
        }
        > div.illustrations {
            padding: 2rem 0;
            &.with-gradient {
                background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.6) 60%,rgba(255,255,255,0) 100%);
            }
            > div.items {
                > div.row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 1rem 0;
                    @include respond-to('large') {
                        flex-direction: column;
                        justify-content: center;
                    }
                    > div.item {
                        margin: 0 3rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        > div.illustration {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-height: pxToRem(180);
                            max-height: pxToRem(300);
                            width: 100%;
                            > svg {
                                max-height: pxToRem(180);
                                @include respond-to('small') {
                                    max-width: pxToRem(200);
                                }
                            }
                        }
                        @include respond-to('medium') {
                            margin: 0 2rem;
                        }
                        > div.info {
                            text-align: center;
                            > h3 {
                                font-weight: $font-bold;
                                margin-bottom: .5rem;
                            }
                        }
                    }
                }
            }
        }
        > div.track-progress {
            background: linear-gradient(147.72deg, $colorSourcePrimary 27.78%, $colorPurplePrimary 129.65%);
            padding: 6rem 2rem;
            border-radius: pxToRem(12);
            display: flex;
            justify-content: center;
            max-width: pxToRem(736);
            min-width: pxToRem(736);
            @include respond-to('medium') {
                width: 100%;
                min-width: unset;
                border-radius: unset;
                padding: 2rem;
            }
            > div.content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                max-width: pxToRem(616);
                > div.illustration {
                    width: pxToRem(180);
                    height: pxToRem(180);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 2rem;
                }
                > div.info {
                    color: #fff;
                    > h3 {
                        font-weight: $font-bold;
                        margin-bottom: .5rem;
                    }
                }
                @include respond-to('medium') {
                    flex-direction: column;
                    > div.illustration {
                        margin: 0;
                        > svg {
                            transform: scale(1.3);
                        }
                    }
                }
            }
        }
    }
</style>
