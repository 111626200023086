<template>
    <div class="grid variations-grid">
        <div class="grid-item create" v-if="create" @click="navigateToCourseWizard">
            <status-icon type="locked" v-if="!isSubscriptionActive" />
            <div class="variation-icon">
                <course-wizard-variation-icon-large width="80" height="80" viewBox="0 0 40 40" />
            </div>
            <div class="title" v-html="this.$i18n('hub_course_wizard_create')" />
        </div>
        <template v-for="variation in variations">
            <variation-grid-item v-bind:key="`variation_item_${variation.uuid}`" :variation="variation" :is-subscription-active="isSubscriptionActive" />
        </template>
    </div>
</template>

<script>
    import Backbone from 'backbone';
    import VariationGridItem from './variation-grid-item.vue';
    import CourseWizardVariationIconLarge from 'Images/variations/40x40/lesson.svg';
    import StatusIcon from "./status-icon.vue";
    import UserManager from "../../../modules/usermanager.js";

    export default {
        name: 'variations-grid',
        props: {
            variations: Array,
            create: Boolean,
            isSubscriptionActive: Boolean
        },
        components: {
            VariationGridItem,
            StatusIcon,
            CourseWizardVariationIconLarge
        },
        methods: {
            openVariationModal(variation) {
                this.$emit('open-variation-modal', variation);
            },
            navigateToCourseWizard() {
                Backbone.history.navigate('course-wizard/create', { trigger: true });
            }
        },
        created() {

        }
    };
</script>

<style lang="scss">
    @import '~Styles/mixins';

    div.variations-grid {
        > div.grid-item {
            &.create {
                position: relative;
                cursor: pointer;
                user-select: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: pxToRem(48) pxToRem(24);
                transition: opacity 250ms;
                > div.variation-icon {
                    display: block;
                    position: relative;
                    width: pxToRem(80);
                    height: pxToRem(80);
                }
                div.title {
                    margin-top: pxToRem(16);
                    font-size: fontSize(18);
                    font-weight: $font-bold;
                }
                &:hover {
                    opacity: .8;
                }
                div.status-icon {
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                }
            }
        }
    }
</style>
