import $ from 'jquery';
import Backbone from 'backbone';
import renderTemplate from '../../util/template.renderer.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';
import UserManager from '../../modules/usermanager.js';

import { AssignLessonDialogView } from './lesson-list/assign.lesson.dialog.view.js';
import { ASSIGN_LESSON, DELETE_LESSON, CLOSE_ASSIGN_LESSON_DIALOG } from './actions.js';
import { CONFIRM_ACTION, ConfirmationView } from '../util/confirmation.view.js';
import i18nUtils from '../../util/i18n.js';
import UserModel from '../../model/user.model.js';
import Vue from "vue";
import LockedMessageView from "../component/locked-message.vue";

const NUM_ROWS = 10;

const LessonListModel = Backbone.Model.extend({
    defaults: {
        loading: true,
        lessons: [],
        emptyRows: new Array(10),
        classrooms: [],
        lessonToAssign: null,
        assignError: null,
        courseWizardDisabled: true
    }
});

export const LessonListView = Backbone.View.extend({

    name: 'lessons-list',

    tagName: 'main',
    className: 'lessons-list-view',

    events: {
        'click a.edit': 'editLesson',
        'click a.assign': 'assign',
        'click a.delete': 'delete',
        'click a.learn': 'learn',
        'click a.create-lesson': 'navigateToCourseWizard'
    },

    initialize: function () {

        this.model = new LessonListModel();
        this.listenTo(this.model, 'change', this.modelUpdated);

        this.assignLessonDialogView = new AssignLessonDialogView();

        this.assignLessonDialogView.on(ASSIGN_LESSON, classrooms => {

            const lesson = this.model.get('lessonToAssign');
            const { variation_uuid } = lesson.publishing_status;

            if (variation_uuid) {
                this.trigger(ASSIGN_LESSON, variation_uuid, classrooms);
            }

            googleAnalyticsCommand('send', 'event', 'ManageLessons', 'AssignModalAssignLesson');
            UserManager.instance.getUser().getEventSender()
                .sendNavigationEvent('ManageLessons', 'AssignModalAssignLesson', null);

        });

        this.assignLessonDialogView.on(CLOSE_ASSIGN_LESSON_DIALOG, () => {
            this.model.set('lessonToAssign', null);
            googleAnalyticsCommand('send', 'event', 'ManageLessons', 'AssignModalClosed');
            UserManager.instance.getUser().getEventSender()
                .sendNavigationEvent('ManageLessons', 'AssignModalClosed', null);
        });
    },

    modelUpdated: function ({ changed }) {
        if (changed.lessons) {
            let numEmptyRows = 0;
            if (changed.lessons.length < NUM_ROWS) {
                numEmptyRows = NUM_ROWS - changed.lessons.length;
            }
            this.model.set('emptyRows', new Array(numEmptyRows));
            this.render();
        }

        if (changed.lessonToAssign || changed.lessonToAssign == null) {
            this.render();
        }

        if (changed.assignError || changed.assignError == null) {
            this.render();
        }
    },

    render: function () {
        renderTemplate(
            'lessons/lessons-list',
            this.model.attributes,
            this.$el
        ).then( () => {
            this._postRender();
        });
    },

    _postRender: function () {

        this.$lockedMessageContainer = this.$('div.locked-message-container');
        const _isDisabled = this.model.get('isDisabled');

        if (_isDisabled) {
            this.$lockedMessageContainer.removeClass('hidden');
            this.setLockedMessageView();
        } else {
            this.$lockedMessageContainer.addClass('hidden');
        }

        const lessonToAssign = this.model.get('lessonToAssign');

        if (lessonToAssign) {

            const allClassrooms = this.model.get('classrooms');
            const assignableClassrooms = allClassrooms
                .filter( classroom => classroom.course.uuid === lessonToAssign.course.uuid);

            this.assignLessonDialogView.model.set('classrooms', assignableClassrooms);
            this.assignLessonDialogView.model.set('assignError', this.model.get('assignError'));
            this.assignLessonDialogView
                .setElement('div.dialog-container')
                .render();

            googleAnalyticsCommand('send', 'event', 'ManageLessons', 'AssignModalOpen');
            UserManager.instance.getUser().getEventSender()
                .sendNavigationEvent('ManageLessons', 'AssignModalOpen', null);
        }

        Backbone.trigger('rendered', this);
    },

    setLockedMessageView () {
        if (this.$lockedMessageContainer) {
            this.$lockedMessageContainer.empty();
            const vueLockedMessageView = document.createElement( "div" );
            vueLockedMessageView.classList.add('vue-locked-message-view');
            const LockedMessageViewComponent = Vue.extend(LockedMessageView);
            const LockedMessageViewInstance = new LockedMessageViewComponent({
                propsData: {
                    closable: false
                }
            });

            this.$lockedMessageContainer.append(vueLockedMessageView);
            LockedMessageViewInstance.$mount(vueLockedMessageView);
        }
    },

    editLesson: function (event) {
        const { uuid } = event.currentTarget.dataset;
        Backbone.history.navigate(`course-wizard/edit/${uuid}`, { trigger: true });
    },

    assign: function ({ currentTarget }) {

        const index = $(currentTarget).parent().parent().data('index');
        const lessonToAssign = this.model.get('lessons')[index];

        this.model.set({ lessonToAssign });
    },

    delete: function ({ currentTarget }) {
        const index = $(currentTarget).parent().parent().data('index');
        const variation = this.model.get('lessons')[index];

        const confirmationView = this._showVariationDeleteConfirmation(variation);

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                console.log('trigger delete lesson', variation.uuid);
                this.trigger(DELETE_LESSON, variation.uuid);
            }

            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });


    },

    learn: function ({ currentTarget }) {

        const index = $(currentTarget).parent().parent().data('index');
        const variation = this.model.get('lessons')[index];

        const confirmationView = this._showVariationSwitchingConfirmation(variation);

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                googleAnalyticsCommand('send', 'event', 'ManageLessons', 'Learn');
                UserManager.instance.getUser().getEventSender()
                    .sendNavigationEvent('ManageLessons', 'Learn', null);

                Backbone.trigger('showLoader', 'variations');
                Promise.resolve()
                    .then(() => {
                        const { uuid: active_course_uuid } = UserManager.instance.getUser().getCourse().getInfo();
                        if (active_course_uuid !== variation.course.uuid) {
                            return Promise.resolve()
                                .then(() => UserManager.instance.getUser().enrolToCourse(variation.course.uuid))
                                .then(() => UserModel.setCourse(variation.course.uuid));

                        }
                    })
                    .then(() => UserManager.instance.getUser().getCourse().setVariation({ variation }))
                    .then(() => {
                        Backbone.history.navigate('guess', {trigger: true});
                        Backbone.trigger('hideLoader');
                        Backbone.trigger('variationChanged');
                    });
            }

            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });

    },

    _showVariationDeleteConfirmation (variation) {

        const confirmationView = new ConfirmationView({
            title: i18nUtils.prop('lessons_list_table_delete_confirm_dialog_title'),
            message: i18nUtils.prop('lessons_list_table_delete_confirm_dialog_message', { variation_name: variation.name }),
            actions: [
                {
                    title: i18nUtils.prop('lessons_list_table_delete_confirm_dialog_cancel'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('lessons_list_table_delete_confirm_dialog_confirm'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        });

        confirmationView.show();

        return confirmationView;
    },

    _showVariationSwitchingConfirmation (variation) {

        const confirmationView = new ConfirmationView({
            title: i18nUtils.prop('variations_switching_theme_dialog_title'),
            message: i18nUtils.prop('variations_switching_theme_dialog_message', { variation_name: variation.name }),
            actions: [
                {
                    title: i18nUtils.prop('variations_switching_theme_dialog_button_cancel'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('variations_switching_theme_dialog_button_ok'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        });

        confirmationView.show();

        return confirmationView;
    },

    navigateToCourseWizard () {
        Backbone.history.navigate('course-wizard/create', {trigger: true});
    }
});
