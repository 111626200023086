
'use strict';

import _ from 'lodash';
import moment from 'moment';
import Backbone from 'backbone';

import AsyncDestroyable from './async.destroyable.js';

import Question from './question.js';
import { RepeatList } from './repeat.list.js';
import { NewWordList } from './new.word.list.js';
import { FastTrackingList } from './fast.tracking.list.js';
import { DebugQuestionList } from './debug.question.list.js';
import { QuestionListMinimalPredictedIntervalSorter, NewUnitSNSorter,
    FastTrackingListSorter, DebugQuestionListSorter } from './question.list.sorters.js';

export const SUPPORTED_QUESTION_TYPES = ['word'];

export const STATUS = {
    OK: 'ok',
    OUT_OF_QUESTIONS: 'out-of-questions',
};

class GuessQueueNotInitializedException extends Error {
    constructor (message) {
        super(message);
        this.name = 'GuessQueueNotInitializedException';
    }
}

export class UnknownGuessQueueStatusError extends Error {
    constructor (status) {
        super(`Unknown for returned by GuessQueue: status="${status}""`);
        this.name = 'UnknownGuessQueueStatusError';
    }
}

export class GuessQueue extends AsyncDestroyable {

    constructor (user, course, assetLoader) {
        super(['initialize', 'getCurrentQuestion', 'removeCurrentQuestion']);
        this.name = 'GuessQueue';

        if (!_.isObject(user)) {
            throw Error('user is a required parameter');
        }

        if (!_.isObject(course)) {
            throw Error('course is a required parameter');
        }
        this._course = course;

        this._assetLoader = assetLoader;
        this._user = user;
        this._initialized = false;

        this.currentQuestion = null;
        this._lists = {
            repeatList: null,
            newWordList: null,
            fastTrackingList: null,
            debugQuestionList: null
        };
    }

    destroy () {
        console.debug(`GuessQueue.destroy(${this._course.UUID})`);
        this._assertInitialized();

        return Promise.resolve()
            .then(() => this._destroyQueues())
            .then(() => this.currentQuestion !== null ? this.currentQuestion.destroy() : Promise.resolve())
            .then(() => {
                delete this._user;
                delete this._course;
                delete this._assetLoader;
                return Promise.resolve();
            });
    }

    /**
     * Destroys the question queue objects to clean up the resource used by them
     * @private
     * @return {Promise}
     */
    _destroyQueues () {
        return Promise.all([
            this._lists.repeatList && this._lists.repeatList.destroy(),
            this._lists.newWordList && this._lists.newWordList.destroy(),
            this._lists.fastTrackingList && this._lists.fastTrackingList.destroy(),
            this._lists.debugQuestionList && this._lists.debugQuestionList.destroy()
        ]).then(() => {
            this._lists.repeatList = null;
            this._lists.newWordList = null;
            this._lists.fastTrackingList = null;
            this._lists.debugQuestionList = null;
            return Promise.resolve();
        });
    }

    _assertInitialized () {
        if (!this._initialized) {
            return Promise.reject(new GuessQueueNotInitializedException());
        }
    }
    /**
     * Initializes the GuessQueue
     */
    initializeState () {
        console.log(`GuessQueue.initializeState()`);
        return Promise.resolve()
            .then(() => this._destroyQueues())
            .then(() => {
                this._lists.repeatList = new RepeatList(this._course, this._assetLoader, {
                    Question: Question,
                    Sorter: QuestionListMinimalPredictedIntervalSorter
                });
                this._lists.newWordList = new NewWordList(this._course, this._assetLoader, {
                    Question: Question,
                    Sorter: NewUnitSNSorter
                });
                this._lists.fastTrackingList = new FastTrackingList(this._course, this._assetLoader, {
                    Question: Question,
                    Sorter: FastTrackingListSorter
                }, this);
                this._lists.debugQuestionList = new DebugQuestionList(this._course, this._assetLoader, {
                    Question: Question,
                    Sorter: DebugQuestionListSorter
                });
                this._initialized = true;
                return Promise.resolve();
            });
    }

    /**
     *
     * @param {object[]} questions
     * @param {moment} question_horizon
     */
    updateQuestions (questions, question_horizon) {
        console.debug(`GuessQueue.updateQuestions()\n${questions.map(question => {
            return `\t${question.lexical_unit_uuid} ${question.type} pt=${question.placement_test} n_sn=${question.new_unit_sn} pts=${question.predicted_ts}\n`;
        }).join('')}`);
        this._lists.debugQuestionList.updateQuestions(questions);
        this._lists.fastTrackingList.updateQuestions(questions);
        this._lists.repeatList.updateQuestions(questions, {maxPredictedTs: question_horizon});
        this._lists.newWordList.updateQuestions(questions);
        Backbone.trigger('guess-queue-updated');
    }

    serializeQuestions () {
        let questions =_.concat(
            this._lists.debugQuestionList.serialize(),
            this._lists.fastTrackingList.serialize(),
            this._lists.repeatList.serialize(),
            this._lists.newWordList.serialize()
        );
        if (this.currentQuestion !== null) {
            questions.push(this.currentQuestion.serialize());
        }
        return questions;
    }

    getCurrentQuestionSync () {
        /*
        * Used to get some kind of an answer synchronously, when even the
        * knowledge that there is no current question at the moment is enough to continue.
        * */
        return this.currentQuestion;
    }

    getCurrentQuestion (iteration) {
        iteration = iteration === undefined ? 0 : iteration + 1;

        this._assertInitialized();

        // Current
        if (this.currentQuestion !== null) {
            return Promise.resolve({status: STATUS.OK, question: this.currentQuestion});
        }

        // Debug
        if (this._lists.debugQuestionList.length > 0){
            this.currentQuestion = this._lists.debugQuestionList.popFirst();
            return Promise.resolve({status: STATUS.OK, question: this.currentQuestion});
        }

        // Fast-tracking
        if (this._course.getFastTrackingState().isInProgress() && this._lists.fastTrackingList.length > 0) {
            this.currentQuestion = this._lists.fastTrackingList.popFirst();
            return Promise.resolve({status: STATUS.OK, question: this.currentQuestion});
        }

        // Repeat
        let predicted_ts_override = this._course.getPredictedTsOverride();
        let predicted_ts_override_set = moment.isMoment(predicted_ts_override) && predicted_ts_override > moment();
        if (this._lists.repeatList.length > 0 &&
            (this._lists.repeatList.peekFirst().needsRepeat() ||
             (this._lists.newWordList.length === 0 && predicted_ts_override_set && this._lists.repeatList.peekFirst().needsRepeat(predicted_ts_override)))
        ) {
            this.currentQuestion = this._lists.repeatList.popFirst();
            return Promise.resolve({status: STATUS.OK, question: this.currentQuestion});
        }

        // new words
        if (this._lists.newWordList.length > 0) {
            this.currentQuestion = this._lists.newWordList.popFirst();
            return Promise.resolve({status: STATUS.OK, question: this.currentQuestion});
        }

        if (iteration > 0) { // No question can still be returned after updating
            return Promise.resolve({status: STATUS.OUT_OF_QUESTIONS, question: null});
        }

        return Promise.resolve()
            .then(() => this._course.syncCourse())
            .then(() => this.getCurrentQuestion(iteration));
    }

    getQuestionCounts () {
        return {
            questionsToShow: this._lists.repeatList.length + this._lists.newWordList.length
        };
    }

    /**
     * Puts the current question back to its respective list
     */
    replaceCurrentQuestion () {
        if (this.currentQuestion !== null) {
            let currentQuestion = this.currentQuestion;

            if (currentQuestion.isDebug()) {
                this._lists.debugQuestionList.updateWithExistingQuestion(currentQuestion);
            } else if (currentQuestion.isFastTracking()) {
                this._lists.fastTrackingList.updateWithExistingQuestion(currentQuestion);
            } else if (currentQuestion.isNew()) {
                this._lists.newWordList.updateWithExistingQuestion(currentQuestion);
            } else { // is repeat question
                this._lists.repeatList.updateWithExistingQuestion(currentQuestion);
            }

            this.currentQuestion = null;
        }
    }

    switchToDebugIfPossible () {
        this._assertInitialized();

        if (this.currentQuestion !== null && this.currentQuestion.isDebug()) {
            return true;
        }

        if (this._lists.debugQuestionList.length > 0) {
            this.replaceCurrentQuestion();
            this.currentQuestion = this._lists.debugQuestionList.popFirst();
            return true;
        } else {
            return false;
        }
    }

    switchToRepeatIfPossible () {
        this._assertInitialized();

        if (this.currentQuestion !== null && this.currentQuestion.needsRepeat()) {
            return true;
        }

        if (this._lists.repeatList.length > 0) {
            this.replaceCurrentQuestion();
            this.currentQuestion = this._lists.repeatList.popFirst();
            return true;
        } else {
            return false;
        }
    }

    removeCurrentQuestion () {
        this._assertInitialized();

        if (this.currentQuestion === null) {
            return Promise.reject(Error('There is no current question'));
        }

        return Promise.resolve()
            .then(() => {
                let question = this.currentQuestion;
                this.currentQuestion = null;
                this.emit('state-changed');
                return Promise.resolve(question);
            });
    }

    /**
     * Returns the current length of the RepeatList
     * @return {number}
     */
    getRepeatsBelowHorizonCount () {
        this._assertInitialized();
        // repeatList.length is okay here because we only keep the below-horizon questions in the client queue
        return this._lists.repeatList.length;
    }

    /**
     * Returns the current length of the RepeatList
     * @return {number}
     */
    getRepeatsWaitingCount () {
        this._assertInitialized();
        return this._lists.repeatList.getRepeatsWaitingCount();
    }

    /**
     * Returns the current length of the NewWordList
     * @return {number}
     */
    getNewQuestionCount () {
        this._assertInitialized();
        return this._lists.newWordList.length;
    }

    getDebugQuestionsInfo () {
        return this._lists.debugQuestionList.getQuestionsInfo();
    }

    /**
     * Set the Sorter Class for the RepeatList
     * @param Sorter - QuestionList sorter Class
     */
    setRepeatListSorter (Sorter) {
        this._assertInitialized();
        this._lists.repeatList.setSorter(Sorter);
    }

    peekFirstRepeatQuestion () {
        if (this._initialized && this._lists.repeatList.length > 0) {
            return this._lists.repeatList.peekFirst();
        } else {
            return null;
        }
    }
}
