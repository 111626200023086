var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "credentials-email" },
    [
      _c("h1", {
        domProps: {
          innerHTML: _vm._s(
            this.$i18n("register_credentials_selected_course_heading")
          ),
        },
      }),
      _vm._v(" "),
      _c("course", { attrs: { course: _vm.selected_course } }),
      _vm._v(" "),
      _c("h2", {
        domProps: { innerHTML: _vm._s(this.$i18n("register_email_header")) },
      }),
      _vm._v(" "),
      this.oauth_input
        ? _c("p", {
            staticClass: "social",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("signin_social_profile_label", {
                  args: { provider: this.oauth_input.provider },
                })
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            this.$i18n("signin_social_profile_email_requirement_text")
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "register",
          on: {
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit_credentials.apply(null, arguments)
            },
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "input-container" },
            [
              _c("account-email-icon"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email_,
                    expression: "email_",
                  },
                ],
                ref: "email_input",
                staticClass: "email",
                class: {
                  error: _vm.email_validation_error,
                  suggestion: _vm.email_suggestion,
                },
                attrs: {
                  type: "email",
                  placeholder: this.$i18n("register_email_email_placeholder"),
                  autocomplete: "email",
                },
                domProps: { value: _vm.email_ },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.email_ = $event.target.value
                    },
                    _vm.check_email,
                  ],
                  blur: _vm.check_email,
                },
              }),
              _vm._v(" "),
              _vm.email_suggestion
                ? _c("p", {
                    staticClass: "email-suggestion",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("register_email_suggestion", {
                          args: {
                            email: `<a>${decodeURIComponent(
                              _vm.email_suggestion.full
                            )}</a>`,
                          },
                        })
                      ),
                    },
                    on: { click: _vm.use_email_suggestion },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.email_in_use
            ? _c("div", { staticClass: "email-in-use" }, [
                _c("p", {
                  staticClass: "error",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("settings_error_email_in_use")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass: "error",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("register_error_duplicate_email_account_ask")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("a", {
                  staticClass: "login-link",
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("register_error_log_in_link")),
                  },
                  on: { click: _vm.navigate_to_signin },
                }),
              ])
            : [
                _vm.email_validation_error === "error-empty-email"
                  ? _c("p", {
                      staticClass: "error",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("register_error_empty_email")
                        ),
                      },
                    })
                  : _vm.email_validation_error === "error-invalid-email"
                  ? _c("p", {
                      staticClass: "error",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("register_error_invalid_email")
                        ),
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "footer",
        [
          _c("button", {
            attrs: { disabled: !_vm.submit_enabled },
            domProps: { innerHTML: _vm._s(this.$i18n("register_submit")) },
            on: { click: _vm.submit_credentials },
          }),
          _vm._v(" "),
          _c("terms"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }