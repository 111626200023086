var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "collapsible-box",
      class: {
        open: this.open,
        hasContent: this.hasContent,
        noBackground: this.noBackground,
        isStacked: this.stacked,
        hasContent: this.hasContent,
      },
      on: { click: _vm.toggleOpen },
    },
    [
      _c("div", { staticClass: "icon-wrapper" }, [_vm._t("icon")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("h2", { staticClass: "heading" }, [
            _vm.title
              ? _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm.i18n_title
              ? _c("span", {
                  staticClass: "title",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n(_vm.i18n_title.id, {
                        collection: _vm.i18n_title.collection,
                        args: _vm.i18n_title.params,
                      })
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              staticClass: "title-meta",
              domProps: { innerHTML: _vm._s(_vm.titleMeta) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: { name: "expand" },
              on: {
                enter: _vm.enter,
                "after-enter": _vm.afterEnter,
                leave: _vm.leave,
              },
            },
            [
              this.hasContent
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: this.open,
                          expression: "this.open",
                        },
                      ],
                      staticClass: "content",
                    },
                    [
                      _vm.text || _vm.i18n_text
                        ? [
                            _vm.text
                              ? _c("span", { staticClass: "text" }, [
                                  _vm._v(_vm._s(_vm.text)),
                                ])
                              : _vm.i18n_text && _vm.i18n_text.id
                              ? _c("span", {
                                  staticClass: "text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.$i18n(_vm.i18n_text.id, {
                                        collection: _vm.i18n_text.collection,
                                        args: _vm.i18n_text.params,
                                      })
                                    ),
                                  },
                                })
                              : _vm.i18n_text && _vm.i18n_text.ids
                              ? _vm._l(_vm.i18n_text.ids, function (id) {
                                  return _c("p", {
                                    staticClass: "text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$i18n(id, {
                                          collection: _vm.i18n_text.collection,
                                          args: _vm.i18n_text.params,
                                        })
                                      ),
                                    },
                                  })
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      this.$slots.content ? [_vm._t("content")] : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasContent
        ? _c("div", { staticClass: "toggle-icon" }, [_c("arrow-icon")], 1)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }