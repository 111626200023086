var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "20",
          height: "20",
          viewBox: "0 0 20 20",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#43D281",
          "fill-rule": "evenodd",
          d: "M8.438 15.223c.36.306.9.262 1.207-.097l7.436-8.712A.857.857 0 0015.777 5.3l-6.834 8.156L4.412 9.56a.857.857 0 10-1.11 1.307l5.136 4.356z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }