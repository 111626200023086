<template>
    <div class="status-dot">
        <svg width="16" height="16"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path id="status_dot__a" d="M0 0h16v16H0z"/>
                <path d="M3 13L13 3" id="status_dot__c"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="status_dot__b" fill="#fff">
                    <use xlink:href="#status_dot__a"/>
                </mask>
                <g mask="url(#status_dot__b)" :fill="dotColor">
                    <rect width="12" height="12" rx="6" transform="translate(2 2)"/>
                </g>
                <g v-if="disabled" mask="url(#status_dot__b)" stroke-linecap="square">
                    <use stroke="#FFF" stroke-width="4" xlink:href="#status_dot__c"/>
                    <use stroke="#2C3143" stroke-width="2" xlink:href="#status_dot__c"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'status-dot',
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'teal'
            },
        },
        data() {
            return {
                colors: { teal: '#037889', orange: '#FAAA5A' },
                dotColor: null
            };
        },
        methods: {
            setDotColor() {
                this.dotColor = (this.color in this.colors) ? this.colors[this.color] : this.colors['teal']
            }
        },
        created() {
            this.setDotColor();
        }
    };
</script>

<style lang="scss">
    div.status-dot {
        width: 16px;
        height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
</style>
