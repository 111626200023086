<template>
    <main class="pay" id="pay" :class="{ loading }">
        <support-modal-pay v-if="this.supportModalVisible" v-on:close="closeSupportModal"/>
        <div class="loading-overlay" v-if="loading" >
            <loader />
        </div>
        <div class="container">
            <success v-if="this.page === 'success'" :show-receipt-info="receiptSent" />
            <failure v-else-if="this.page === 'failure'" />
            <template v-else>
                <catalog v-if="this.page === 'catalog' && !selectedProduct"
                         :products="this.products"
                         :userHasRecurringService="this.userHasRecurringService"
                         :userCanManageSubscription="this.userCanManageSubscription"
                         :userOnPreTrial="this.userOnPreTrial"
                         :introTrialAvailable="this.introTrialAvailable"
                         :brandingId="this.brandingId"
                         :paymentProvider="this.paymentProvider"
                         :showMessages="true"
                         v-on:product-selected="productSelected"
                         v-on:navigate="onNavigate" />
                <checkout v-else-if="this.page === 'checkout'"
                          :user="user"
                          :enableThreeDSecure="enableThreeDSecure"
                          :userHasRecurringService="this.userHasRecurringService"
                          :userCanManageSubscription="this.userCanManageSubscription"
                          :product="selectedProduct"
                          :token="token"
                          v-on:clear-selected-product="clearSelectedProduct" />
                <manage v-else-if="this.page === 'manage'"
                        :user="user"
                        :activeProduct="activeProduct"
                        :enableThreeDSecure="enableThreeDSecure"
                        :brandingId="brandingId"
                        :token="token" />
                <voucher v-else-if="this.page === 'voucher'"
                         :user="user"
                         :products="this.products"
                         :userHasRecurringService="this.userHasRecurringService"
                         :userCanManageSubscription="this.userCanManageSubscription"
                         :userOnPreTrial="this.userOnPreTrial"
                         :introTrialAvailable="this.introTrialAvailable"
                         :brandingId="this.brandingId"
                         :paymentProvider="this.paymentProvider"
                         :voucherCodeInitial="this.voucherCode"
                         v-on:product-selected="productSelected"
                         v-on:navigate="onNavigate"
                />
                <have-a-voucher v-if="this.page === 'catalog'" @navigate="onNavigate" />
                <div class="footnotes" v-if="this.page === 'catalog'">
                    <p v-if="userOnPreTrial || introTrialAvailable" v-html="this.$i18n(this.brandingId, { collection: 'pay_product_recurring_text_cancel' })" />
                    <p v-html="this.$i18n('pay_product_recurring_text_billed')" />
                </div>
                <intro v-if="this.page === 'catalog'" />
                <secure v-if="this.page === 'checkout' || this.page === 'manage'" />
                <featured-in v-if="this.page === 'catalog'" />
                <faq v-if="showFaq" />
                <div class="anchor-container" v-if="this.page === 'catalog'">
                    <div v-scroll-to="'#pay'" class="anchor">
                        <arrow-icon class="arrow-up" />
                        <h2 v-html="this.$i18n('navigation_anchor_see_plans_label')" />
                    </div>
                </div>
                <div class="floater" @click="openSupportModal">
                    <div class="floater-content">
                        <help-icon />
                        <span v-html="this.$i18n('support_float_button_label')" />
                    </div>
                </div>
            </template>
        </div>
    </main>
</template>

<script>
    import Vue from 'vue';
    import VueScrollTo from 'vue-scrollto';
    import { EventBus } from 'Util/vue-event-bus';
    import catalog from "./components/catalog.vue";
    import intro from "./components/intro.vue";
    import checkout from "./components/checkout.vue";
    import manage from "./components/manage.vue";
    import success from "./components/success.vue";
    import failure from "./components/failure.vue";
    import faq from "./components/faq.vue";
    import loader from "ViewComponent/loader.vue";
    import featuredIn from "./components/featured-in.vue";
    import secure from "./components/secure.vue";
    import HaveAVoucher from "./components/have-a-voucher.vue";
    import voucher from "./components/voucher.vue";
    import SupportModalPay from "./components/support-modal.vue";
    import HelpIcon from "ViewComponent/help-icon.vue";
    import ArrowIcon from "Images/arrow-down-purple.svg";

    const validPages = ['catalog', 'checkout', 'manage', 'success', 'failure', 'voucher', 'lingvist:account?profile'];

    Vue.use(VueScrollTo);

    export default {
        name: 'pay',
        components: {
            HelpIcon,
            ArrowIcon,
            SupportModalPay, HaveAVoucher, intro, secure, featuredIn, loader, catalog, checkout, manage, success, failure, faq, voucher },
        props: {
            user: {
                type: Object
            },
            products: {
                type: Array
            },
            product: {
                type: Object
            },
            subscription: {
                type: Object,
                default: null
            },
            activeProduct: {
                type: Object,
                default: null
            },
            enableThreeDSecure: {
                type: Boolean
            },
            userHasRecurringService: {
                type: Boolean
            },
            userCanManageSubscription: {
                type: Boolean
            },
            userOnPreTrial: {
                type: Boolean
            },
            introTrialAvailable: {
                type: Boolean
            },
            brandingId: {
                type: String
            },
            token: {
                type: String
            },
            pageName: {
                type: String
            },
            paymentProvider: {
                type: String
            }
        },
        data() {
            return {
                loading: false,
                selectedProduct: this.product || null,
                page: this.pageName || 'catalog',
                paymentSuccessful: false,
                receiptSent: false,
                voucherCode: null,
                supportModalVisible: false
            }
        },
        computed: {
            showFaq() {
                const showFaqOnPages = ['catalog', 'checkout', 'manage', 'success', 'failure'];
                return showFaqOnPages.includes(this.page);
            },
        },
        methods: {
            onNavigate(to) {
                if (validPages.includes(to)) {
                    this.$emit('navigate', to);
                    this.page = to;
                } else {
                    console.warn('PAY: trying to navigate to page what doesnt exist', to);
                }
            },
            listenEventBus() {
                EventBus.$on('pay:loading', this.setLoading);
                EventBus.$on('pay:checkout-successful', this.showSuccess);
            },
            unListenEventBus() {
                EventBus.$off('pay:loading', this.setLoading);
                EventBus.$off('pay:checkout-successful', this.showSuccess);
            },
            setLoading(state){
                this.loading = state;
            },
            productSelected(product) {
                this.selectedProduct = product;
                this.page = 'checkout'
                this.$emit('checkout', product);
            },
            clearSelectedProduct(product) {
                this.selectedProduct = null;
                if (product && product.voucherInfo) {
                    this.voucherCode = (product.voucherInfo.voucher && product.voucherInfo.voucher.code) ? product.voucherInfo.voucher.code : null;
                    this.$emit('navigate', 'voucher');
                    this.page = 'voucher';
                } else {
                    this.$emit('navigate', 'catalog');
                    this.page = 'catalog';
                }
            },
            showSuccess(params) {
                this.receiptSent = !!(params && params.receipt);
                this.paymentSuccessful = true;
                this.setLoading(false);
                this.$emit('navigate', 'success');
                this.page = 'success'
            },
            openSupportModal() {
                this.supportModalVisible = true;
            },
            closeSupportModal() {
                this.supportModalVisible = false;
            }
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            this.unListenEventBus();
        }
    }
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';

    $pay-radius: pxToRem(12);
    $pay-max-width: pxToRem(1336);

    main.pay {
        position: relative;
        &.loading {
            overflow: hidden;
            height: 100vh;
        }
        > div.loading-overlay {
            background-color: $colorBackgroundLighter;
            position: absolute;
            z-index: 1000;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            > div.loader {
                position: absolute;
                top: pxToRem(400);
                left: 50%;
                margin-left: -2rem;
            }
        }
        > div.container {
            position: static;
            margin: 0;

            h1 {
                text-align: center;
                margin-bottom: 2rem;
                font-size: fontSize(20);
                font-weight: $font-bold;
            }

            h2 {
                font-size: fontSize(20);
                font-weight: $font-bold;
            }

            > h2 {
                margin: 2rem 1rem 1.5rem;
            }

            > div.success {
                max-height: 82vh;
            }

            > div.intro {
                margin: 3rem 0;
                width: 100%;
                > div.illustrations {
                    width: 100%;
                    > div.items {
                        max-width: $pay-max-width;
                        margin: 0 auto;
                    }
                }
                > div.track-progress {
                    max-width: $pay-max-width;
                    margin: 0 auto;
                }
                @include respond-to('small') {
                    width: unset;
                    > div.illustrations {
                        width: unset;
                    }
                }
            }

            > div.faq,
            > div.featured-in {
                max-width: $pay-max-width;
                margin: 3rem auto;
                padding: 0 2rem;
            }

            > div.checkout,
            > div.manage {
                margin: 2rem;
                @include respond-to('small') {
                    margin: 1rem;
                }
                > div.columns {
                    max-width: $pay-max-width;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: row;
                    padding: 0 2rem;
                    @include respond-to('small') {
                        padding: 0;
                    }
                    > div.column {
                        flex: 1;
                        @include elevated;
                        padding: pxToRem(24);
                        background-color: #fff;
                        border-radius: pxToRem(12);
                        &:first-child {
                            margin-right: pxToRem(24);
                        }
                        > div.summary {
                            border: 4px solid $colorBackgroundDarker;
                            border-radius: pxToRem(8);
                            padding: 1rem;
                        }
                        > div.footnote {
                            margin-top: pxToRem(24);
                            border-radius: pxToRem(8);
                            background-color: $colorBackgroundLighter;
                            padding: 1rem;
                            > h5 {
                                color: $colorSourcePrimary;
                                font-size: fontSize(12);
                            }
                            > h5 + p {
                                margin-top: pxToRem(8)
                            }
                            > p {
                                color: $colorSourceSecondary;
                                font-size: fontSize(12);
                            }
                        }
                        > div.error {
                            margin-top: pxToRem(24);
                            border-radius: pxToRem(8);
                            color: $colorIncorrect;
                            background-color: lighten($colorIncorrect, 30%);
                            padding: 1rem;
                        }
                        > div.actions {
                            margin-top: pxToRem(24);
                            display: flex;
                            flex-direction: column;

                            > button {
                                flex: 1;
                            }
                        }
                    }
                    @include respond-to('medium') {
                        flex-direction: column;
                        margin: 1rem;
                        > div.column {
                            margin: 1rem;
                            flex: 1;
                            &:first-child {
                                margin-right: 1rem;
                                margin-bottom: 0;
                            }
                        }
                    }
                    @include respond-to('small') {
                        margin: 0;
                    }
                }
            }

            > div.anchor-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 2rem 0 0;
                background-color: $colorBackgroundDarker;
                > div.anchor {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 2rem;
                    user-select: none;
                    > svg.arrow-up {
                        margin: 0 0 2rem;
                        transition: 0.15s ease-in;
                        transform: rotate(180deg);
                    }
                    &:hover {
                        > svg.arrow-up {
                            transform: translateY(-4px) rotate(180deg);
                        }
                    }
                }
            }

            > div.footnotes {
                margin-top: 1.5rem;
                text-align: center;
            }

            > div.floater {
                position: absolute;
                bottom: 1rem;
                right: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: pxToRem(40);
                background-color: $colorSourcePrimary;
                border-radius: pxToRem(32);
                padding: 1rem;
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 1rem;
                }
                &:hover {
                    cursor: pointer;
                    transform: scale(1.2);
                }
                > div.floater-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;
                    color: white;
                }
            }
        }
    }
</style>
