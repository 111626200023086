var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 21c.045-1.694-1.63-3-3-3H5v6h4c1.308 0 2.957-1.382 3-3z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 9l.82-.573A1 1 0 0014 9h1zm7 10l-.82.573A1 1 0 0022 20v-1zM5 26H4h1zm1-16a1 1 0 10-2 0h2zm26 18v-1 1zM7 28v1-1zm2-16a1 1 0 100 2v-2zm7-2V9h-2v1h2zm-1.82-.427l7 10 1.64-1.146-7-10-1.64 1.146zM22 20h9v-2h-9v2zM6 26V10H4v16h2zm28-3v2h2v-2h-2zm-2 4H7v2h25v-2zM9 14h3v-2H9v2zm25 11a2 2 0 01-2 2v2a4 4 0 004-4h-2zm-3-5a3 3 0 013 3h2a5 5 0 00-5-5v2zM4 26a3 3 0 003 3v-2a1 1 0 01-1-1H4zm10-16a2 2 0 01-2 2v2a4 4 0 004-4h-2z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18 13.5l-2.5 2M20 16.5l-2.5 2M22.5 19l-3 2.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3 34h31a4 4 0 004-4v-.5M11 28v6M28 28v6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }