var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "course-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _vm.courses
        ? _c(
            "div",
            { staticClass: "course-modal-window" },
            [
              _c(
                "header",
                [
                  _c("BackButton", {
                    staticClass: "back",
                    class: {
                      visible:
                        _vm.chosen_target_language ||
                        _vm.languageNotListedVisible,
                    },
                    on: { click: _vm.backAction },
                  }),
                  _vm._v(" "),
                  _c("h2", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _vm._v(" "),
                  !_vm.courseNeedsToBeSet
                    ? _c("CloseButton", {
                        staticClass: "close",
                        on: { click: _vm.close },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.languageNotListedVisible
                ? [
                    _c("language-not-listed", {
                      attrs: {
                        selected_target_language: {
                          code: this.chosen_target_language,
                        },
                        "show-title": false,
                      },
                      on: { back: _vm.hideLangNotListed },
                    }),
                  ]
                : [
                    _c(
                      "section",
                      { ref: "list", staticClass: "courses-list" },
                      [
                        this.chosen_target_language
                          ? [
                              _c("course-list-item", {
                                attrs: {
                                  "show-subtitle": false,
                                  "show-meta": false,
                                  course: _vm.chosen_course,
                                },
                              }),
                              _vm._v(" "),
                              _vm.source_languages_filtered &&
                              _vm.source_languages_filtered.length > 0
                                ? [
                                    _c("h3", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.$i18n(
                                            "hub_language_selection_source_language_title"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.source_languages_filtered,
                                      function (course) {
                                        return [
                                          _c("course-list-item", {
                                            attrs: {
                                              "show-subtitle": false,
                                              "show-source": true,
                                              course: course,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.addCourse(course)
                                              },
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("course-list-item", {
                                      attrs: { "show-not-listed": true },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showLangNotListed.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          : [
                              _vm.courses &&
                              _vm.courses.coursesEnrolled &&
                              _vm.courses.coursesEnrolled.length > 0
                                ? [
                                    _c("h3", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.$i18n(
                                            "hub_language_selection_currently_active_title"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.enrolledCourses,
                                      function (course) {
                                        return [
                                          _c("course-list-item", {
                                            attrs: {
                                              course: course,
                                              "show-more-dropdown": true,
                                            },
                                            on: {
                                              "select-course": _vm.addCourse,
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.target_languages &&
                              _vm.target_languages.length > 0
                                ? [
                                    _c("h3", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.$i18n(
                                            "hub_language_selection_available_languages_title"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.target_languages,
                                      function (language) {
                                        return [
                                          _c("course-list-item", {
                                            attrs: {
                                              "show-subtitle": false,
                                              course: language,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.languageChosen(
                                                  language
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("course-list-item", {
                                      attrs: { "show-not-listed": true },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showLangNotListed.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }