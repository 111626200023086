import $ from 'jquery';
import Backbone from 'backbone';

import UserModel from '../../model/user.model.js';
import i18nUtils from '../../util/i18n.js';
import renderTemplate from '../../util/template.renderer.js';

const ResetPasswordView = Backbone.View.extend({

    name: 'reset-password',

    tagName: 'main',
    className: 'reset-password-view',

    events: {
        'click .reset-password-submit': 'submit',
        'keypress': 'submitOnEnter',
        'click div.input-hints > div.hint.password': 'togglePasswordReveal',
    },

    initialize: function (params) {
        var self = this;
        this.queryParameters = params.queryParameters;

        let promise = Promise.resolve();

        if (this.queryParameters.language !== undefined) {
            promise = promise.then(function () {
                return i18nUtils.setInterfaceLanguage(self.queryParameters.language);
            });
        }

        return promise.then(function () {
            self.capsLockEnabled = false;

            // Note: Caps Lock works very differently on Win vs Mac browsers.
            // On windows, it fires both keydown and keyup each time,
            // on Mac keydown when enabled, keyup when disabled
            $(window).on('keydown.resetpassword', function (event) {
                if (event.originalEvent.getModifierState !== undefined) {
                    self.capsLockEnabled = event.originalEvent.getModifierState('CapsLock');
                    if (self.capsLockEnabled) {
                        self.$('div.hint.caps-lock').removeClass('hidden');
                    } else {
                        self.$('div.hint.caps-lock').addClass('hidden');
                    }
                }
            });

            $(window).on('keyup.resetpassword', function (event) {
                if (event.originalEvent.getModifierState !== undefined) {
                    self.capsLockEnabled = event.originalEvent.getModifierState('CapsLock');
                    if (self.capsLockEnabled) {
                        self.$('div.hint.caps-lock').removeClass('hidden');
                    } else {
                        self.$('div.hint.caps-lock').addClass('hidden');
                    }
                }
            });

            console.log("Reset password view init");
        });
    },

    remove: function () {
        $(window).off('keyup.resetpassword');
        $(window).off('keypress.resetpassword');
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('reset-password', {}, this.$el).then( () => {
            this._postRender();
        });

        return this;
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
    },

    resetPasswordSuccess: function (status) {
        this.$el.find('.message').removeClass('error');
        this.$el.find('input.error').removeClass('error');
        Backbone.trigger('passwordResetProcessFinished', 'forgot-password');
        switch (status) {
            case 'success':
                this.$el.find('section.help > p.success-message').text(i18nUtils.prop('reset_password_success')).show();
                break;
        }
    },

    resetPasswordError: function (error) {
        this.$el.find('.form input, .form a, .reset-password-submit').prop('disabled', false);
        Backbone.trigger('passwordResetProcessFinished', 'forgot-password');
        switch (error) {
            case 'error-empty-password':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('reset_password_error_empty_password')).show();
                this.$el.find('input.password').addClass('error');
                break;
            case 'error-empty-repeat-password':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('reset_password_error_empty_repeat_password')).show();
                this.$el.find('.repeat-password').addClass('error');
                break;
            case 'error-password-mismatch':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('reset_password_error_password_mismatch')).show();
                this.$el.find('input.password').addClass('error');
                this.$el.find('.repeat-password').addClass('error');
                break;
            case 'error-incorrect-request-url':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('reset_password_error_incorrect_request_url')).show();
                break;
            case 'error-outdated-token':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('reset_password_error_outdated_token')).show();
                break;
            case 'error-server':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('reset_password_error_server')).show();
                break;
        }
    },

    submit: function (event) {
        var self = this;
        this.$el.find('.message').hide();
        this.$el.find('section.help > p.error-message').hide();
        this.$el.find('section.help > p.success-message').hide();
        this.$el.find('input.error').removeClass('error');
        this.$el.find('.form input, .form a, .reset-password-submit').prop('disabled', true);
        Backbone.trigger('passwordResetInProgress', 'forgot-password');

        var data = {
            queryParameters: this.queryParameters,
            newPassword: this.$el.find('input.password').val(),
            repeatNewPassword: this.$el.find('.repeat-password').val()
        };
        UserModel.resetPassword(data,
            function (error) {
                self.resetPasswordError(error);
            },
            function (status) {
                self.resetPasswordSuccess(status);
            }
        );
    },

    submitOnEnter: function (event) {
        if (event.keyCode === 13) {
            this.submit();
        }
    },

    togglePasswordReveal: function (event) {
        const $target = $(event.currentTarget);

        if ($target.hasClass('revealed')) {
            $target.parent().siblings('input').attr('type', 'password');
            $target.removeClass('revealed');
            $target.parent().siblings('input').focus();
        } else {
            $target.parent().siblings('input').attr('type', 'text');
            $target.addClass('revealed');
            $target.parent().siblings('input').focus();
        }
    }
});

export default ResetPasswordView;
