var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M6 18.3V5.7c0-1.3 1.4-2.1 2.5-1.4l10.7 6.3c1.1.7 1.1 2.2 0 2.9L8.5 19.8c-1.1.6-2.5-.2-2.5-1.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }