var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M20 33.053a13.826 13.826 0 01-5.049.947C7.245 34 1 27.733 1 20S7.245 6 14.951 6c1.782 0 3.483.334 5.049.947A14.006 14.006 0 0011.092 20 14.006 14.006 0 0020 33.053z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.994 27.079A13.92 13.92 0 0124.959 34C17.25 34 11 27.735 11 20.003 11 12.265 17.255 6 24.96 6 30.09 6 34.581 8.777 37 12.915M18 20h7M29 20h3M25 13v14",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }