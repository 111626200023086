var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M3 8V7H2v1h1zm0 7a1 1 0 100 2v-2zm9 6l-.707.707A1 1 0 0013 21h-1zm0-18h1a1 1 0 00-1.707-.707L12 3zM7 8v1a1 1 0 00.707-.293L7 8zm0 8l.707-.707A1 1 0 007 15v1zm-5-5a1 1 0 102 0H2zm9.293-8.707l-5 5 1.414 1.414 5-5-1.414-1.414zM7 7H3v2h4V7zm6 0V3h-2v4h2zM3 17h1v-2H3v2zm10 4v-8.5h-2V21h2zM11 7v5.5h2V7h-2zm-4.707 9.707l5 5 1.414-1.414-5-5-1.414 1.414zM4 17h1.5v-2H4v2zm1.5 0H7v-2H5.5v2zM2 8v3h2V8H2z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 9c.63.85 1 1.885 1 3s-.37 2.15-1 3M19 6c1.25 1.632 2 3.722 2 6s-.75 4.368-2 6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }