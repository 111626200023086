var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "japanese-general-story" }, [
    _vm.activeIndex === 0
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_general_language_1_title")
                ),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_general_language_1_text")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M163 125C154.163 125 147 132.163 147 141V165C147 173.837 154.163 181 163 181H217L233.172 197.172C235.691 199.691 240 197.907 240 194.343V181C248.837 181 256 173.837 256 165V141C256 132.163 248.837 125 240 125H163Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M164 149H177C177 152 176.7 158.2 175.5 159C174.3 159.8 172.667 159.167 172 159M170 145C170.333 148 169.7 155.1 164.5 159.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M204 149H217C217 152 216.7 158.2 215.5 159C214.3 159.8 212.667 159.167 212 159M210 145C210.333 148 209.7 155.1 204.5 159.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M184 153C185.333 152.333 188.5 149.8 190.5 145M189 148H197.5C196 151.5 193 156.5 185 161M189.5 152.5C192 153.5 192.6 154 195 156",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M223 150H238.5M231 145.5V151.5C231 154 229.5 158 226 160",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M32 48C23.1634 48 16 55.1634 16 64V88C16 96.5007 22.6293 103.453 31 103.969V117.343C31 120.907 35.3086 122.691 37.8284 120.172L54 104H109C117.837 104 125 96.8366 125 88V64C125 55.1634 117.837 48 109 48H32Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M33 71C34.1667 71 37.5 70.8 39.5 70C34.5 73.5 31.3224 82.5806 37 84C43.1123 85.5281 45.5 78 44 69.5C44.5 72 45.5 75 48 78",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M55.5 72.5C55.5 73.5 55.3 76.3 54.5 79.5C55.3333 78.3333 58 76 62 76C67 76 67.2227 80.4664 63 83C60.5 84.5 57 84 57 84M57 69C58.1667 69.5 61.2 70.5 64 70.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M77.5 69.5C77.3333 71.5 76.2 77.1 73 83.5M72 73.5C73.8333 73.3333 77.8 73 79 73C80.5 73 82.5 73.5 82.5 76C82.5 78.5 82 82.5 80.5 83.5C79.3 84.3 77.6667 83.8333 77 83.5M85 71.5C85.6667 72.3333 87.2 74.7 88 77.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M86 68C86.5 68.4 87 69.2 87 70M88 67C88.5 67.4 89 68.2 89 69",
                  stroke: "#2C3143",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M98 69C97.3333 71.3333 95.4 76.8 93 80",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M92.5 72C94 72.1667 97.8 72.3 101 71.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M104 72C105 72.5 106.3 73.2 107.5 74",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M103 75C103 76.8333 103 80.8 103 82C103 83.5 102 84.5 100 84.5C98 84.5 97 83.5 97 82C97 80 101 77.5 107 82.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M85.5 202C108.42 202 127 188.121 127 171C127 153.879 108.42 140 85.5 140C62.5802 140 44 153.879 44 171C44 181.276 50.6929 190.384 61 196.024V207.343C61 210.907 65.3086 212.691 67.8284 210.172L76.6986 201.301C79.5353 201.759 82.48 202 85.5 202Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M70 178H84M71 175H83M76.5 176.5C77.3333 178.167 79.9 181.7 83.5 182.5M77 168V172C77.3333 174.833 76.4 180.9 70 182.5M68 175.5C67.5 176.667 66.1 179.6 64.5 182M64 169C64.5 169.167 65.8 169.8 67 171M74 162.5V165.5M79.5 162.5V165.5M68 164.5C67.6667 164.167 66.6 163.3 65 162.5M70.5 164H84M71 168H83V172H71V168Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M93 169H103.5C102.667 170.167 100.4 172.6 98 173V180C98 181.5 97.5 182.5 96 182.5H94M89 176H107M89 169V165H107V169M98 165V162.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M197.5 105C174.58 105 156 91.1208 156 74C156 56.8792 174.58 43 197.5 43C220.42 43 239 56.8792 239 74C239 84.2756 232.307 93.3835 222 99.0239V110.343C222 113.907 217.691 115.691 215.172 113.172L206.301 104.301C203.465 104.759 200.52 105 197.5 105Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M222 70V80H220C218.343 80 217 78.6569 217 77V76",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M208 70L208.928 69.6286C208.777 69.249 208.409 69 208 69C207.591 69 207.223 69.249 207.072 69.6286L208 70ZM203.072 79.6286C202.866 80.1414 203.116 80.7234 203.629 80.9285C204.141 81.1336 204.723 80.8842 204.928 80.3714L203.072 79.6286ZM210.8 77L211.728 76.6286L210.8 77ZM211.072 80.3714C211.277 80.8842 211.859 81.1336 212.371 80.9285C212.884 80.7234 213.134 80.1414 212.928 79.6286L211.072 80.3714ZM208.5 76C207.948 76 207.5 76.4477 207.5 77C207.5 77.5523 207.948 78 208.5 78V76ZM207.072 69.6286L203.072 79.6286L204.928 80.3714L208.928 70.3714L207.072 69.6286ZM207.072 70.3714L209.872 77.3714L211.728 76.6286L208.928 69.6286L207.072 70.3714ZM209.872 77.3714L211.072 80.3714L212.928 79.6286L211.728 76.6286L209.872 77.3714ZM210.8 76H208.5V78H210.8V76Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M228 70V80",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M190 80V70L191.5 71.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M194.5 74.5L199 70V80",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M185 80V72C185 70.8954 184.105 70 183 70H179V78C179 79.1046 179.895 80 181 80H185Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M168 80V75M168 75V70H171.5C172.881 70 174 71.1193 174 72.5C174 73.8807 172.881 75 171.5 75H168ZM172 78L174 80",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 1
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_general_language_2_title")
                ),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_general_language_2_text")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  x: "60",
                  y: "14",
                  width: "152",
                  height: "228",
                  rx: "16",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M60 75V43H79C83.4183 43 87 46.5817 87 51C87 55.4183 83.4183 59 79 59H98C102.418 59 106 62.5817 106 67C106 71.4183 102.418 75 98 75H60Z",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M212 187V219H180C175.582 219 172 215.418 172 211C172 206.582 175.582 203 180 203H192C187.582 203 184 199.418 184 195C184 190.582 187.582 187 192 187H212Z",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M212 129V145H201C196.582 145 193 141.418 193 137C193 132.582 196.582 129 201 129H212Z",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M119.672 242H123.026L97.0322 190.013C95.6493 187.247 92.8225 185.5 89.7304 185.5H84.105C81.1793 185.5 78.4772 187.066 77.0223 189.604L74.3273 194.306C73.9153 195.025 74.164 195.941 74.8827 196.353C75.6015 196.765 76.5181 196.517 76.93 195.798L79.6251 191.096C80.5453 189.49 82.2544 188.5 84.105 188.5H89.7304C91.6862 188.5 93.4742 189.605 94.3489 191.354L105.814 214.284L99.1241 220.974L94.9094 216.759C94.3236 216.173 93.3739 216.173 92.7881 216.759L88.5734 220.974L77.695 210.095C77.1092 209.509 76.1594 209.509 75.5736 210.095L71.2056 214.463L68.3078 210.841L72.936 202.766C73.3479 202.047 73.0992 201.131 72.3805 200.719C71.6618 200.307 70.7452 200.556 70.3332 201.274L60 219.303V225.335L66.7042 213.639L69.91 217.646C70.1765 217.979 70.5723 218.183 70.9983 218.207C71.4243 218.23 71.8402 218.071 72.1419 217.77L76.6343 213.277L87.5127 224.156C88.0985 224.741 89.0482 224.741 89.634 224.156L93.8488 219.941L98.0635 224.156C98.3448 224.437 98.7263 224.595 99.1241 224.595C99.522 224.595 99.9035 224.437 100.185 224.156L107.228 217.112L119.672 242Z",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M136 121V135.5M136 135.5L131 130.5M136 135.5L141 130.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M128.5 105.5V108M128.5 105.5H110.5M128.5 105.5V95.5683M110.5 108V105.5M110.5 105.5V95.5683M128.5 95.5683V85.5H110.5V95.5683M128.5 95.5683H110.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M137.5 89.5H161.412M149.5 83.5C149.92 87.6951 152.854 97.4698 161.412 103.007M149.5 83.5C149.08 87.6951 146.058 97.4698 137.5 103.007M149.5 83.5V108.5M144.5 103.5H154.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M96.5 149.5C95 154.5 93.9898 161.439 95.5 170.5C95.7098 168.822 97.4864 164.52 99.5 161.5M103.5 163.5C103.5 166.017 104.028 167.528 105 168.5C106.259 169.759 110 170 115.5 168.5M105 151.5C106.678 151.92 110.973 152.507 115 151.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M126.5 149.5C125.661 152.856 123.99 161.439 125.5 170.5C125.71 168.822 127.486 164.52 129.5 161.5M140.5 150.879L140.863 163.35C140.863 163.594 140.863 163.836 140.862 164.074M140.862 164.074C140.848 167.54 140.437 170.5 137 170.5C131.133 170.5 131.831 164.133 136.5 163.5C138.075 163.286 139.448 163.556 140.862 164.074ZM140.862 164.074C142.767 164.772 144.904 166.446 147.5 168.5M133.5 150.5C135.306 150.699 140.21 151.256 145.5 150.5M133.212 157.405C135.035 157.604 140.272 157.794 146.636 156.958",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M164.5 148.5C161.864 153.084 156.483 164.173 154.5 170.5C156.074 166.329 159.108 159.018 164.5 159.5C170.248 160.014 164.304 167.948 169.5 170.5C172.864 172.153 176.91 165.979 177.5 163.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 2
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_japanese_keyboard_generic_1_title")
                ),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_japanese_keyboard_generic_1_text")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M45.6804 131.924V190C45.6804 194.418 49.2622 198 53.6804 198H189V199.999C189 204.418 185.418 207.999 181 207.999H43C38.5817 207.999 35 204.418 35 199.999V139.924C35 135.506 38.5817 131.924 43 131.924H45.6804Z",
                  fill: "#AC6ED1",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M225 175V123C225 118.052 220.94 114 216 114H55C50.0603 114 46 118.052 46 123V189C46 193.948 50.0603 198 55 198H199.5C201.157 198 202.5 196.657 202.5 195C202.5 193.343 201.157 192 199.5 192H56C53.7909 192 52 190.209 52 188V124C52 121.791 53.7909 120 56 120H215.024C217.224 120 219.008 121.767 219.013 123.966V167",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M83 138C83 140.209 81.2091 142 79 142H68C65.7909 142 64 140.209 64 138V136C64 133.791 65.7909 132 68 132H79C81.2091 132 83 133.791 83 136V138Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M83 157C83 159.209 81.2091 161 79 161H68C65.7909 161 64 159.209 64 157V156C64 153.791 65.7909 152 68 152H79C81.2091 152 83 153.791 83 156V157Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M83 176C83 178.209 81.2091 180 79 180H68C65.7909 180 64 178.209 64 176V175C64 172.791 65.7909 171 68 171H79C81.2091 171 83 172.791 83 175V176Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M115 138C115 140.209 113.209 142 111 142H99C96.7909 142 95 140.209 95 138V136C95 133.791 96.7909 132 99 132H111C113.209 132 115 133.791 115 136V138Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M115 157C115 159.209 113.209 161 111 161H99C96.7909 161 95 159.209 95 157V156C95 153.791 96.7909 152 99 152H111C113.209 152 115 153.791 115 156V157Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M155.75 171H99C96.7909 171 95 172.791 95 175V176C95 178.209 96.7909 180 99 180H172C174.209 180 176 178.209 176 176V175C176 172.791 174.209 171 172 171H165.875",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M145 138C145 140.209 143.209 142 141 142H131C128.791 142 127 140.209 127 138V136C127 133.791 128.791 132 131 132H141C143.209 132 145 133.791 145 136V138Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M145 157C145 159.209 143.209 161 141 161H131C128.791 161 127 159.209 127 157V156C127 153.791 128.791 152 131 152H141C143.209 152 145 153.791 145 156V157Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M176 138C176 140.209 174.209 142 172 142H161C158.791 142 157 140.209 157 138V136C157 133.791 158.791 132 161 132H172C174.209 132 176 133.791 176 136V138Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M176 157C176 159.209 174.209 161 172 161H161C158.791 161 157 159.209 157 157V156C157 153.791 158.791 152 161 152H172C174.209 152 176 153.791 176 156V157Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M207 138C207 140.209 205.209 142 203 142H192C189.791 142 188 140.209 188 138V136C188 133.791 189.791 132 192 132H203C205.209 132 207 133.791 207 136V138Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M207 157C207 159.209 205.209 161 203 161H192C189.791 161 188 159.209 188 157V156C188 153.791 189.791 152 192 152H203C205.209 152 207 153.791 207 156V157Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M207 176C207 178.209 205.209 180 203 180H192C189.791 180 188 178.209 188 176V175C188 172.791 189.791 171 192 171H203C205.209 171 207 172.791 207 175V176Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M112 58C112 53.5817 115.582 50 120 50H152C156.418 50 160 53.5817 160 58V90C160 94.4183 156.418 98 152 98H120C115.582 98 112 94.4183 112 90V58Z",
                  fill: "#AC6ED1",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M123.159 63.5455C126.999 64.4546 136.981 65.3636 146.195 61.7273M133.717 59C130.838 65.3636 129.878 74.0909 133.717 87.1818M139.476 89C141.716 89 146.771 87.7273 149.074 82.6364C151.544 77.1788 149.072 72.3899 141.658 71.1368M141.658 71.1368C140.427 70.9288 139.06 70.8182 137.557 70.8182C126.999 70.8182 120.784 79.0898 122.2 84.4545C123.804 90.5326 136.353 85.8294 141.658 71.1368ZM141.658 71.1368C141.907 70.4466 142.14 69.7343 142.356 69",
                  stroke: "white",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M229 175C229 177.209 227.209 179 225 179C222.791 179 221 177.209 221 175C221 172.791 222.791 171 225 171C227.209 171 229 172.791 229 175Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M230.381 200.372L216 189.5L216.037 207.5L220.494 205.194L225.022 214.477L230.415 211.847L225.887 202.563L230.381 200.372Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M230.037 193L230.037 187",
                  stroke: "#AC6ED1",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M238.037 196L248.037 187",
                  stroke: "#AC6ED1",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M239.037 204H246.037",
                  stroke: "#AC6ED1",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 3
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_japanese_keyboard_3_title")
                ),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("stories_japanese_keyboard_3_text")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { "clip-path": "url(#clip0_5276_242334)" } }, [
                _c("path", {
                  attrs: {
                    d: "M16 96H248C252.418 96 256 99.5817 256 104V256H16V96Z",
                    fill: "#AC6ED1",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M24 88H256C260.418 88 264 91.5817 264 96V248H24V88Z",
                    fill: "white",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "120",
                    y: "93",
                    width: "48",
                    height: "48",
                    rx: "8",
                    fill: "#E2E6ED",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M141.5 109C141.333 111 140.2 116.6 137 123M136 113C137.833 112.833 141.8 112.5 143 112.5C144.5 112.5 146.5 113 146.5 115.5C146.5 118 146 122 144.5 123C143.3 123.8 141.667 123.333 141 123M149 111C149.667 111.833 151.2 114.2 152 117",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M141.5 164C140.833 166.333 138.9 171.8 136.5 175M136 167C137.5 167.167 141.3 167.3 144.5 166.5M147.5 167C148.5 167.5 149.8 168.2 151 169M146.5 170V175.065M146.5 175.065V177C146.5 178.5 145.5 179.5 143.5 179.5C141.5 179.5 140.5 178.5 140.5 177C140.5 175.487 142.788 173.688 146.5 175.065ZM146.5 175.065C147.695 175.509 149.038 176.282 150.5 177.5",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M73.809 110.773C75.66 111.227 80.4728 111.682 84.9154 109.864M78.8994 108.5C77.5111 111.682 77.0483 116.045 78.8994 122.591M81.6761 123.5C82.7559 123.5 85.1931 122.864 86.3038 120.318C87.4944 117.589 86.3025 115.195 82.728 114.568M82.728 114.568C82.1345 114.464 81.4754 114.409 80.7506 114.409C75.6601 114.409 72.6636 118.545 73.3463 121.227C74.1197 124.266 80.1704 121.915 82.728 114.568ZM82.728 114.568C82.8481 114.223 82.9606 113.867 83.0644 113.5",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M77 164.5C77.1667 166.167 76.6 171.4 73 179M72 168C73.6667 168.333 77.7 168.6 80.5 167M80 170.5C81 170.333 83.6 170.1 86 170.5M79.5 175C79.1667 176.167 79.1 178.5 81.5 178.5C84.5 178.5 85.8333 178.167 86.5 178",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M203.5 165C202.833 167.5 201.8 173.9 203 179.5C203.167 178.333 203.8 175.6 205 174M213 165V174.5C213 174.53 213 174.56 213 174.59M213 174.59C212.978 176.573 212.25 178.958 210.003 178.958C208.126 178.958 207.5 178.161 207.5 176.642C207.5 174.659 209.97 173.418 213 174.59ZM213 174.59C214.281 175.086 215.663 176.014 217 177.5M207.5 169C209.836 169.156 215.076 169.102 217 168",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M80 221V230.5C80 230.87 79.9912 231.248 79.9686 231.626M79.9686 231.626C79.8383 233.799 79.2491 235.919 77.2414 235.919C74.8854 235.919 74 234.938 74 233.586C74 231.552 76.5004 230.631 79.9686 231.626ZM79.9686 231.626C81.6441 232.106 83.5455 233.034 85.5 234.5M74 223C75.6667 223.333 80.2 223.8 85 223M74 227.5C75.8333 227.667 80.6 227.8 85 227",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M139 222.5C140.167 224.833 142.8 230.8 144 236M137 228C139 226.667 144 224 148 224C153.151 224 150.953 229.754 148.5 230C146.047 230.246 145.076 229.144 145.076 229.144M143 220C143.508 220.545 143.675 220.736 144 221.5",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M206 220C208 221 209.5 221.5 213 222M205 224C205.167 225 205.2 227.8 204 231C204.667 230 207 228 211 228C216 228 215.349 233.172 213 234.5C210.656 235.825 208.646 235.471 206 235",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M210 108C210.333 110 211.6 114.6 214 117C213.333 116.833 211.6 116.6 210 117M215 110C214.5 110.667 211.5 112 204 111M204.5 117C203.833 118.167 202.8 121.3 206 122.5C209.2 123.7 213 122.5 214 122",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M128 162C128 153.163 135.163 146 144 146C152.837 146 160 153.163 160 162V242C160 250.837 152.837 258 144 258C135.163 258 128 250.837 128 242V162Z",
                    fill: "url(#paint0_linear_5276_242334)",
                    stroke: "url(#paint1_linear_5276_242334)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "116",
                    y: "8",
                    width: "56",
                    height: "32",
                    rx: "8",
                    fill: "#AC6ED1",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M136 19.2414H153C153 23.1724 152.608 31.2966 151.038 32.3448C149.469 33.3931 147.333 32.5632 146.462 32.3448M143.846 14C144.282 17.931 143.454 27.2345 136.654 33",
                    stroke: "white",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "24",
                    y: "50",
                    width: "37",
                    height: "26",
                    rx: "8",
                    fill: "#F7F8FA",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M36 59.1379H49C49 62.2414 48.7 68.6552 47.5 69.4828C46.3 70.3103 44.6667 69.6552 44 69.4828M42 55C42.3333 58.1034 41.7 65.4483 36.5 70",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "70",
                    y: "50",
                    width: "99",
                    height: "26",
                    rx: "8",
                    fill: "#F7F8FA",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M82 59H95C95 62 94.7 68.2 93.5 69C92.3 69.8 90.6667 69.1667 90 69M88 55C88.3333 58 87.7 65.1 82.5 69.5",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M122 59H135C135 62 134.7 68.2 133.5 69C132.3 69.8 130.667 69.1667 130 69M128 55C128.333 58 127.7 65.1 122.5 69.5",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M102 63C103.333 62.3333 106.5 59.8 108.5 55M107 58H115.5C114 61.5 111 66.5 103 71M107.5 62.5C110 63.5 110.6 64 113 66",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M141 60H156.5M149 55.5V61.5C149 64 147.5 68 144 70",
                    stroke: "#2C3143",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "65",
                    y: "54",
                    width: "1",
                    height: "18",
                    fill: "#F7F8FA",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "defs",
                [
                  _c(
                    "linearGradient",
                    {
                      attrs: {
                        id: "paint0_linear_5276_242334",
                        x1: "144",
                        y1: "146",
                        x2: "144",
                        y2: "214.5",
                        gradientUnits: "userSpaceOnUse",
                      },
                    },
                    [
                      _c("stop", { attrs: { "stop-color": "#E2B4FF" } }),
                      _vm._v(" "),
                      _c("stop", {
                        attrs: {
                          offset: "1",
                          "stop-color": "#E2B4FF",
                          "stop-opacity": "0",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "linearGradient",
                    {
                      attrs: {
                        id: "paint1_linear_5276_242334",
                        x1: "144",
                        y1: "146",
                        x2: "144",
                        y2: "234.5",
                        gradientUnits: "userSpaceOnUse",
                      },
                    },
                    [
                      _c("stop", { attrs: { "stop-color": "#AC6ED1" } }),
                      _vm._v(" "),
                      _c("stop", {
                        attrs: {
                          offset: "1",
                          "stop-color": "#AC6ED1",
                          "stop-opacity": "0",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("clipPath", { attrs: { id: "clip0_5276_242334" } }, [
                    _c("rect", {
                      attrs: { width: "272", height: "256", fill: "white" },
                    }),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("v-button", {
                attrs: {
                  i18n: { id: "onboarding_experience_start_btn" },
                  filled: true,
                  color: "primary",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.startLearning.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }