var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "98",
          height: "105",
          viewBox: "0 0 98 105",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d: "M86.575 32.191L80.01 43.258v57.068a3 3 0 01-3 3H14.075a3 3 0 01-3-3V59.258l-6.5-11.067",
            stroke: "#2C3143",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c(
          "g",
          {
            attrs: {
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-width": "1.5",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M26.608 52.032L21.21 39.905M38.574 47.185L34.45 31.8M51.327 44.832V27.133M64.081 44.53l5.04-18.805",
              },
            }),
          ]
        ),
        _c(
          "g",
          { attrs: { transform: "translate(23.009 52.212)", fill: "#2C3143" } },
          [
            _c("circle", { attrs: { cx: "39.823", cy: "36.283", r: "1" } }),
            _c("circle", { attrs: { cx: "38.938", cy: "10.619", r: "1" } }),
            _c("circle", { attrs: { cx: "29.204", cy: "28.319", r: "1" } }),
            _c("circle", { attrs: { cx: "44.248", cy: "21.239", r: "1" } }),
            _c("circle", { attrs: { cx: "1.77", cy: "38.938", r: "1" } }),
            _c("circle", { attrs: { cx: "6.195", cy: "30.973", r: "1" } }),
            _c("circle", { attrs: { cx: "46.903", cy: "1.77", r: "1" } }),
            _c("circle", { attrs: { cx: "18.584", cy: "37.168", r: "1" } }),
            _c("circle", { attrs: { cx: "5.31", cy: "7.08", r: "1" } }),
            _c("circle", { attrs: { cx: "18.584", cy: "8.85", r: "1" } }),
            _c("circle", { attrs: { cx: "32.743", cy: ".885", r: "1" } }),
            _c("circle", { attrs: { cx: ".885", cy: "19.469", r: "1" } }),
          ]
        ),
        _c("g", { attrs: { fill: "#2C3143" } }, [
          _c("circle", { attrs: { cx: "46.018", cy: "35.398", r: "1" } }),
          _c("circle", { attrs: { cx: "39.823", cy: "19.469", r: "1" } }),
          _c("circle", { attrs: { cx: "76.106", cy: "27.434", r: "1" } }),
          _c("circle", { attrs: { cx: "96.46", cy: "21.239", r: "1" } }),
          _c("circle", { attrs: { cx: "63.717", cy: "9.735", r: "1" } }),
          _c("circle", { attrs: { cx: "61.947", cy: "38.053", r: "1" } }),
          _c("circle", { attrs: { cx: "20.354", cy: "11.504", r: "1" } }),
          _c("circle", { attrs: { cx: "18.584", cy: "35.398", r: "1" } }),
          _c("circle", { attrs: { cx: "47.788", cy: ".885", r: "1" } }),
          _c("circle", { attrs: { cx: "30.088", cy: "43.363", r: "1" } }),
          _c("circle", { attrs: { cx: ".885", cy: "34.513", r: "1" } }),
        ]),
        _c(
          "g",
          {
            attrs: {
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.5",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M38.938 68.142v9.57l7.64-4.41M47.196 77.712l8.288-4.785-8.288-4.785",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }