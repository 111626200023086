<template>
    <div class="questionnaire">
        <nav>
            <div class="back-button">
                <arrow-icon
                    class="navigate-back"
                    @click.native="navigate_back" />
            </div>
            <div class="skip-button">
                <div class="skip" v-html="this.$i18n('demographic_registration_question_why_learn_language_skip')"
                     @click="skipQuestionnaire" />
            </div>
        </nav>

        <question class="questions"
                  :question="questionsAnswers[questionIndex]"
                  v-on:answer-given="answerReceived"
        />
    </div>
</template>

<script>

import AnswerIcon from 'ViewComponent/course-icon.vue';
import Question from './demographic-question.vue';
import ArrowIcon from 'Images/arrow-3.svg';
import {Language} from "./languages.vue";
import EventSender from "Modules/eventsender.js";

export default {
    name: "demographic-questionnaire",
    async created () {
        this.eventSender = new EventSender(null);
    },
    beforeDestroy() {
        this.eventSender.destroy();
    },

    data() {
        return {
            eventSender: null,
            questionIndex: 0,
            answersGiven: [],
            questionsAnswers: [
                {
                    questionId: "demographic_registration_question_why_learn_language",
                    questionLanguage: this.selected_target_language.code,
                    answerId: "demographic_registration_question_why_learn_language_answer",
                    answers: [
                        {
                            name: "integrate_country",
                            iconId: 'integrateCountry'
                        },
                        {
                            name: "set_goal",
                            iconId: "setGoal",
                            skipQuestions: 1
                        },
                        {
                            name: "brain_training",
                            iconId: "brainTraining",
                            skipQuestions: 1
                        },
                        {
                            name: "enjoy_learning_languages",
                            iconId: "enjoyLearning",
                            skipQuestions: 1
                        },
                        {
                            name: "connect_friends_family",
                            iconId: "friendsFamily",
                            skipQuestions: 1
                        },
                        {
                            name: "love_country",
                            iconId: "loveCountry",
                            skipQuestions: 1
                        },
                        {
                            name: "career",
                            iconId: 'career',
                        },
                        {
                            name: "pass_exam",
                            iconId: "passExam",
                            skipQuestions: 1
                        },
                        {
                            name: "upcoming_trip",
                            iconId: "upcomingTrip",
                            skipQuestions: 1
                        },
                        {
                            name: "other",
                            iconId: "other",
                            skipQuestions: 1
                        },
                    ]
                },
                {
                    questionId: "demographic_registration_question_what_profession",
                    answerId: "demographic_registration_question_what_profession_answer",
                    answers: [
                        {
                            name: "architecture",
                            iconId: "architecture",
                        },
                        {
                            name: "arts",
                            iconId: "enjoyLearning",
                        },
                        {
                            name: "business",
                            iconId: "career",
                        },
                        {
                            name: "education",
                            iconId: "passExam",
                        },
                        {
                            name: "government",
                            iconId: "blogPost",
                        },
                        {
                            name: "health",
                            iconId: "health",
                        },
                        {
                            name: "law",
                            iconId: "law",
                        },
                        {
                            name: "science",
                            iconId: "science",
                        },
                        {
                            name: "other",
                            iconId: "other",
                        },
                    ]
                },
                {
                    questionId: "demographic_registration_question_what_your_age",
                    answerId: "demographic_registration_question_what_your_age_answer",
                    answers: [
                        {
                            name: "18",
                            iconId: '18'
                        },
                        {
                            name: "18_24",
                            iconId: "18_24"
                        },
                        {
                            name: "25_34",
                            iconId: "25_34"
                        },
                        {
                            name: "35_44",
                            iconId: "35_44"
                        },
                        {
                            name: "45_54",
                            iconId: "45_54"
                        },
                        {
                            name: "55_64",
                            iconId: "55_64"
                        },
                        {
                            name: "65",
                            iconId: "65"
                        },

                    ]
                },
                {
                    questionId: "demographic_registration_question_how_hear_about_us",
                    answerId: "demographic_registration_question_how_hear_about_us_answer",
                    answers: [
                        {
                            name: "web_search",
                            iconId: "webSearch",
                        },
                        {
                            name: "friends_family",
                            iconId: "friendsFamily",
                        },
                        {
                            name: "online_ads",
                            iconId: "onlineAds",
                        },
                        {
                            name: "social_media",
                            iconId: "socialMedia",
                        },
                        {
                            name: "app_store",
                            iconId: "appStore",
                        },
                        {
                            name: "blog_post",
                            iconId: "blogPost",
                        },
                        {
                            name: "you_tube",
                            iconId: "youTube",
                        },
                        {
                            name: "podcast",
                            iconId: "podcast",
                        },
                        {
                            name: "other",
                            iconId: "other",
                        }

                    ]
                }
            ]
        }
    },

    components: {
        AnswerIcon,
        Question,
        ArrowIcon
    },

    props: {
        selected_target_language: {
            type: Language,
            required: true
        }
    },

    methods: {

        async answerReceived(question_id, question_raw, answer_id, answer_raw, answer) {
            let skipped = false;
            await this.eventSender.sendAuxQuestionAnswerEvent(question_id, question_raw, answer_id, answer_raw, skipped);

            const numberSkipped = answer.skipQuestions !== undefined ? answer.skipQuestions + 1 : 1;    //Determines if and how many questions are to be skipped.
            const nextQuestionIndex = this.questionIndex + numberSkipped;
             if (nextQuestionIndex >= this.questionsAnswers.length) {
                 this.$emit('final_answer_given');
             } else {
                 this.questionIndex = nextQuestionIndex;
             }
        },
        async skipQuestionnaire() {
            let question_raw;
            if (this.questionsAnswers[this.questionIndex].questionLanguage !== undefined) {
                question_raw = this.$i18n(this.questionsAnswers[this.questionIndex].questionId, {args: {language_name: {collection: this.questionsAnswers[this.questionIndex].questionLanguage}, target_language: this.questionsAnswers[this.questionIndex].questionLanguage}});
            } else {
                question_raw = this.$i18n(this.questionsAnswers[this.questionIndex].questionId);
            }
            let question_id = this.questionsAnswers[this.questionIndex].questionId;
            let answer_id = null, answer_raw = null, skipped = true;

            await this.eventSender.sendAuxQuestionAnswerEvent(question_id, question_raw, answer_id, answer_raw, skipped);

            this.$emit('final_answer_given');
        },
        navigate_back() {
            window.history.back();
        }
    }
}
</script>

<style scoped lang="scss">

div.questionnaire {

    display: flex;
    flex-direction: column;
    width: 22.5rem;
    max-width: calc(100% - 1rem);

    > nav {
        display: flex;
        flex-direction: row;
        height: 4rem;

        div.back-button {
            flex-grow: 1;
            display: flex;
            justify-content: left;
            align-items: center;
            margin: 0 1rem;


            > svg {
                display: block;
                height: 1rem;
                width: 1rem;
                transform: rotate(180deg);
                transition: transform 0.2s;

                cursor: pointer;

                &:hover {
                    transform: rotate(180deg) translateX(3px);
                }

                &:active {
                    transform: rotate(180deg) translateX(5px);
                }

                body[data-interface-language="ar"] & {
                    transform: rotate(0);
                }
            }
        }

        div.skip-button {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: right;

            div.skip {
                cursor: pointer;
            }
        }
    }
}

</style>
