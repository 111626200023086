import { render, staticRenderFns } from "./collapsible-box-content.vue?vue&type=template&id=f5f72040&"
import script from "./collapsible-box-content.vue?vue&type=script&lang=js&"
export * from "./collapsible-box-content.vue?vue&type=script&lang=js&"
import style0 from "./collapsible-box-content.vue?vue&type=style&index=0&id=f5f72040&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports