var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M4 38h21a1 1 0 001-1v-4H8V9H4a1 1 0 00-1 1v27a1 1 0 001 1z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29 29v4H8V5h21v6.5M13 10h11M13 14h11M13 18h9M13 22h6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M32.5 13.5l3-3 3 3-3 3-3-3z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M20.043 26L32.5 13.5l3 3L23 29h-2.957v-3z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }