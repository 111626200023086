<template>
    <section class="create-password" :class="{ open: this.newPasswordFieldVisible }">
        <div class="toggle" @click="toggleNewPassword">
            <div class="text">
                <h3 v-html="this.$i18n('account_profile_create_password_button_title')" />
                <p v-html="this.$i18n('account_profile_create_password_button_subtitle')" />
            </div>
            <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 4L10 8L7 12" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <transition name="slide-down-fade">
            <div v-if="newPasswordFieldVisible" class="fieldWrapper">
                <div class="field password" :class="{ focused: this.newPasswordInputFocused }">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 9.5L19.0001 9.50006C20.1046 9.50006 21.0001 10.3955 21.0001 11.5001L21 19.5001C21 20.6046 20.1046 21.5001 19 21.5001H5C3.89543 21.5001 3 20.6046 3 19.5001L3.00002 11.5001C3.00002 10.3955 3.89545 9.50006 5.00002 9.50006H7V7.5C7 4.73858 9.23858 2.5 12 2.5C14.0503 2.5 15.8124 3.7341 16.584 5.5" stroke="#FAAA5A" stroke-width="2" stroke-linecap="round"/>
                        <path d="M14 17.5V15.5C14 14.3954 13.1046 13.5 12 13.5V13.5C10.8954 13.5 10 14.3954 10 15.5V17.5" stroke="#FAAA5A" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    <input type="password" name="new_password" ref="new_password"
                           @focus="newPasswordInputFocused = true"
                           @blur="newPasswordInputFocused = false"
                           v-model="newPasswordLocal"
                           :placeholder="newPasswordPlaceholder" v-on:keyup.enter="save" />
                    <reveal-icon @mousedown.native="revealNewPassword(true)" @mouseup.native="revealNewPassword(false)" @mouseleave.native="revealNewPassword(false)" />
                </div>
                <div class="actions">
                    <v-button :i18n="{ id: 'account_profile_cancel_btn' }" @click.native="clear" :filled="false" size="medium" color="primary" />
                    <v-button :i18n="{ id: 'account_profile_input_action_save' }" @click.native="save" :filled="true" :disabled="!contentIsValid" size="medium" color="primary" />
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
import i18nUtils from "Util/i18n.js";
import EditIcon from "./edit-icon.vue";
import VButton from "ViewComponent/v-button.vue";
import RevealIcon from "./reveal-icon.vue";

export default {
    name: 'section-password-create',
    components: { RevealIcon, VButton, EditIcon },
    props: {
        email: {
            type: String,
            default: ''
        },
        passwordSet: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentPasswordLocal: '',
            newPasswordLocal: '',
            newPasswordInputFocused: false,
            newPasswordFieldVisible: false
        }
    },
    computed: {
        newPasswordPlaceholder() {
            return i18nUtils.prop('account_profile_input_placeholder_new_password')
        },
        contentIsValid() {
            return this.newPasswordLocal.length >= 4;
        }
    },
    methods: {
        toggleNewPassword() {
            this.newPasswordFieldVisible = !this.newPasswordFieldVisible;
        },
        clear() {
            this.newPasswordFieldVisible = false;
            this.newPasswordLocal = '';
            this.$refs.new_password.blur();
        },
        save() {
            this.$emit('save', { new_password: this.newPasswordLocal });
            this.clear();
        },
        focusInput() {
            this.newPasswordInputFocused = true;
            this.$refs.new_password.focus();
        },
        revealNewPassword(state) {
            this.$refs.new_password.type = (state) ? 'text' : 'password';
        }
    },
}
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.create-password {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 .5rem;
        > div.toggle {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            > div.text {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: .25rem;
                user-select: none;
                > h3 {
                    font-size: fontSize(16);
                    line-height: fontSize(20);
                }
                > p {
                    font-size: fontSize(12);
                    line-height: fontSize(16);
                    color: $colorSourceSecondary;
                }
            }
            > svg {
                margin: .5rem 1rem .5rem auto;
                transform: rotate(90deg);
            }
            &:hover {
                cursor: pointer;
            }
        }

        &.open {
            > div.toggle {
                > svg {
                    transform: rotate(-90deg);
                }
            }
        }

        > div.fieldWrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        div.field {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $colorBackgroundDarker;
            &.focused {
                border-bottom: 1px solid $colorBackgroundDark;
            }
            > input {
                flex: 1;
            }
            > svg.edit-icon {
                margin-left: auto;
                cursor: pointer;
                transition: all 150ms;
                &:hover {
                    transform: scale(1.2);
                }
            }
            &.password {
                &.focused {
                    border-bottom: 1px solid $colorAttention;
                }
            }
        }
        div.actions {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 1rem;
        }
        .slide-down-fade-enter-active {
            animation: fade-slide-in 0.3s;
        }
        .slide-down-fade-leave-active {
            animation: fade-slide-in 0.3s reverse;
        }
        @keyframes fade-slide-in {
            0% {
                opacity: 0;
                transform: translateY(-100px);
            }
            25% {
                opacity: 0;
            }
            50% {
                opacity: 0;
            }
            75% {
                opacity: 0.4;
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
</style>
