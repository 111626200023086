
'use strict';

import WebappStrings from '@lingvist/webapp-strings';

export default {
    getDiacritics: function (language) {
        return WebappStrings.Diacritics[language];
    },

    getDiacriticByShortcut: function (language, shortcut) {
        if (WebappStrings.Diacritics[language] !== undefined) {
            let item = WebappStrings.Diacritics[language].find(item => item.shortcut === shortcut);
            if (item !== undefined) {
                return item.diacritic;
            } else {
                return undefined;
            }
        }
        return undefined;
    }
};
