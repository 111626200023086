<template>
    <div class="checkout">
        <h1 v-html="this.$i18n('pay_checkout_title')" />
        <div class="columns">
            <transition name="slide-fade">
                <div class="column braintree">
                    <braintree :token="token"
                               :user="user"
                               :enableThreeDSecure="enableThreeDSecure"
                               :amount="amountToCheckout"
                               v-on:set-braintree-instance="setBraintreeInstance"
                               v-on:payment-option-selected="setPaymentOptionSelected"
                               v-on:payment-method-requestable="setPaymentMethodRequestable"
                    />
                </div>
            </transition>
            <div class="column">
                <div class="summary">
                    <h3 v-html="this.$i18n('pay_order_summary_title')" />
                    <div class="subtitle">
                        <p>{{ subTitle }}</p>
                        <v-button @click.native="clearProduct" :i18n="{ id: 'payment_button_change' }" size="small" />
                    </div>
                    <div class="info" :class="{ dots: trialLengthInDays > 0 || voucherIsForInitialPaymentOnly && fullPriceStartsFrom && !isSinglePurchase }">
                        <div class="row" v-if="trialLengthInDays > 0">
                            <div class="dot" />
                            <div class="label" v-html="this.$i18n('payment_trial_start_label')" />
                            <div class="right" v-html="this.$i18n('payment_trial_period_value', { args: { 'product_trial_days': trialLengthInDays }})" />
                        </div>
                        <div class="row price" :class="{ middle: voucherIsForInitialPaymentOnly }">
                            <div class="dot" />
                            <div class="label" v-if="trialEnds" v-html="this.$i18n('payment_plan_start_date_label', { args: { next_payment_ts: trialEnds }})" />
                            <div class="label" v-else v-html="this.$i18n('pay_order_summary_total')" />
                            <div class="right">
                                <div class="priceWithPeriod">
                                    <span class="totalPrice" v-if="product.voucherInfoFormatted && !product.voucherInfoFormatted.is_forced_free_trial">{{ product.voucherInfoFormatted.totalWithCurrency}}</span>
                                    <span class="totalPrice" v-else>{{ product.textTotalPrice }}</span>
                                    <span class="textPeriod">/ {{ product.textPerPeriod }}</span>
                                </div>
                                <p v-if="product.voucherInfoFormatted && !product.voucherInfoFormatted.is_forced_free_trial && product.voucherInfoFormatted.discount" class="crossed">{{ product.textTotalPrice }}</p>
                                <span class="vatText" v-html="this.$i18n('payment_vat_text')" />
                            </div>
                        </div>
                        <div class="row price secondary" v-if="voucherIsForInitialPaymentOnly && fullPriceStartsFrom && !isSinglePurchase">
                            <div class="dot" />
                            <div class="label" v-html="this.$i18n('payment_plan_start_date_label', { args: { next_payment_ts: fullPriceStartsFrom }})" />
                            <div class="right">
                                <div class="priceWithPeriod">
                                    <span class="totalPrice">{{ product.textTotalPrice }}</span>
                                    <span class="textPeriod">/ {{ product.textPerPeriod }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voucherInfo" v-if="this.product && this.product.voucherInfoFormatted">
                    <voucher-icon-small />
                    <div class="text">
                        <span class="prefix" v-html="this.$i18n('payment_order_voucher_title')" />
                        <span v-html="this.$i18n('payment_order_voucher_discount_text', { args: { amount: product.voucherInfoFormatted.discount }})" />
                    </div>
                </div>
                <div class="footnote" v-if="footnoteTitle || footnoteText">
                    <h5 v-if="footnoteTitle">{{ footnoteTitle }}</h5>
                    <p v-if="footnoteText">{{ footnoteText }}</p>
                </div>
                <div class="error" v-if="errorMessage">
                    <p>{{ errorMessage }}</p>
                </div>
                <div class="actions">
                    <div class="tos">
                        <input type="checkbox" class="custom" v-model="tosChecked" />
                        <label>
                            <terms message_key="payment_terms_and_conditions" ref="tos" />
                        </label>
                    </div>
                    <v-button :text="checkoutButtonText" @click.native="checkout" :filled="true" color="primary" :disabled="!isValidToCheckout" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from 'Util/vue-event-bus';
    import Backbone from "backbone";
    import _ from "lodash";
    import moment from "moment";
    import i18nUtils from "Util/i18n";
    import UserManager from "Modules/usermanager.js";
    import { tagManagerCommand } from "Util/google-analytics.js";
    import VButton from "ViewComponent/v-button.vue";
    import Loader from "ViewComponent/loader.vue";
    import Terms from "ViewComponent/terms.vue";
    import Braintree from "./braintree.vue";
    import VoucherIconSmall from 'Images/pay/ic_voucher_24x24.svg';

    export default {
        name: 'checkout',
        components: { Braintree, VButton, Loader, Terms, VoucherIconSmall },
        props: {
            user: {
                type: Object,
                required: true
            },
            product: {
                type: Object,
                required: true
            },
            enableThreeDSecure: {
                type: Boolean
            },
            userHasRecurringService: {
                type: Boolean
            },
            userCanManageSubscription: {
                type: Boolean
            },
            token: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                tosChecked: false,
                checkoutInProcess: false,
                paymentOptionSelected: false,
                paymentMethodRequestable: false,
                braintreeInstance: null,
                errorMessage: null
            }
        },
        computed: {
            amountToCheckout() {
                return (this.product.voucherInfo) ? this.product.voucherInfo.price_info.total_amount : this.product.amountTotal;
            },
            subscription () {
                return (this.user) ? this.user.getSubscription() : null;
            },
            service () {
                if (this.product && this.product.services.length > 0) {
                    return this.product.services[0];
                }
            },
            isSinglePurchase() {
              const _singlePurchaseProducts = ['unlimited-20-years', 'unlimited-10-years'];
              return this.product && this.product.name &&  _singlePurchaseProducts.includes((this.product.name));
            },
            trialEnds() {
                if (this.product.voucherInfoFormatted && this.product.voucherInfoFormatted.duration) {
                    const trialDays = moment.duration(this.product.voucherInfoFormatted.duration).asDays();
                    return moment().add(trialDays, 'days').format('LL');
                } else if (this.product.free_trial_duration) {
                    const trialDays = moment.duration(this.product.free_trial_duration).asDays();
                    return moment().add(trialDays, 'days').format('LL');
                } else {
                    return moment().format('LL');
                }
            },
            trialLengthInDays() {
                if (this.product.voucherInfoFormatted && this.product.voucherInfoFormatted.duration) {
                    return moment.duration(this.product.voucherInfoFormatted.duration).asDays();
                } else if (this.product.free_trial_duration) {
                    return moment.duration(this.product.free_trial_duration).asDays();
                } else {
                    return 0;
                }
            },
            planEnds() {
                if (this.service && this.service.duration_end) {
                    return moment(this.service.duration_end).format('LL');
                }
            },
            planStart() {
                if (this.service && this.service.duration_start) {
                    return moment(this.service.duration_start).format('LL');
                }
            },
            checkoutButtonText() {
                if (this.product && this.product.name.includes('lifetime')) {
                    return i18nUtils.prop('pay_product_recurring_purchase');
                } else {
                    return i18nUtils.prop('pay_product_recurring_subscribe');
                }
            },
            subTitle() {
                let subscription_name = i18nUtils.prop(this.product.name, '', '', 'subscription_name');
                let trial_period = '';
                let trial_days = 0;
                if (this.product.voucherInfoFormatted && this.product.voucherInfoFormatted.hasOwnProperty('duration') && this.product.voucherInfoFormatted.duration) {
                    trial_days = moment.duration(this.product.voucherInfoFormatted.duration).asDays();
                } else if (this.product.free_trial_duration) {
                    trial_days = moment.duration(this.product.free_trial_duration).asDays();
                }

                if (trial_days > 0) {
                    trial_period = i18nUtils.prop("trial_offer_product_button_trial_length", { product_trial_days: trial_days, hours: 0, months: 0 }).replace(/<[^>]+>/g, "");
                }

                if (this.product.isSinglePurchase) {
                    return subscription_name;
                } else {
                    return i18nUtils.prop('payment_subscription_name', { subscription_name, trial_period });
                }
            },
            footnoteTitle() {
                if (this.planStart && !this.product.isSinglePurchase) {
                    if (this.trialEnds) {
                        return i18nUtils.prop('payment_plan_charge_date', { next_payment_ts: this.trialEnds });
                    } else {
                        return i18nUtils.prop('payment_plan_start_date_disclaimer_title', { subscription_activation_date: this.planStart });
                    }
                }
            },
            footnoteText() {
                if (!this.product.name.includes('lifetime') && !this.product.isSinglePurchase) {
                    if (this.product.free_trial_duration || this.product.voucherInfoFormatted && this.product.voucherInfoFormatted.duration) {
                        return i18nUtils.prop('payment_plan_description_1_trial');
                    } else {
                        return i18nUtils.prop('payment_plan_description_2_without_trial');
                    }
                }
            },
            isValidToCheckout() {
                if (!this.checkoutInProcess && (this.tosChecked && this.paymentMethodRequestable)) {
                    return true;
                }
            },
            voucherIsForInitialPaymentOnly() {
                return this.product && this.product.voucherInfo && this.product.voucherInfo.voucher && this.product.voucherInfo.voucher.discount && this.product.voucherInfo.voucher.discount.is_for_initial_payment_only && !this.product.voucherInfo.voucher.discount.is_forced_free_trial
            },
            fullPriceStartsFrom() {
                if (this.voucherIsForInitialPaymentOnly && this.service && this.service.duration_end) {
                    let trialDays = 0;
                    if (this.product.voucherInfoFormatted && this.product.voucherInfoFormatted.duration) {
                        trialDays = moment.duration(this.product.voucherInfoFormatted.duration).asDays();
                    } else if (this.product.free_trial_duration) {
                        trialDays = moment.duration(this.product.free_trial_duration).asDays();
                    }
                    return moment(this.service.duration_end).add(trialDays, 'days').format('LL');
                }
            }
        },
        mounted() {
            console.log('Component mounted');
            setTimeout(() => {
                if (typeof grecaptcha !== 'undefined' && grecaptcha.execute) {
                    console.log('reCAPTCHA is available');
                } else {
                    console.error('reCAPTCHA is not available');
                }
            }, 1000);  // Wait a second to ensure reCAPTCHA has time to load
        },
        methods: {
            clearProduct() {
                this.$emit('clear-selected-product', this.product);
            },
            setBraintreeInstance(instance) {
                this.braintreeInstance = instance;
            },
            setPaymentOptionSelected() {
                this.paymentOptionSelected = true;
            },
            setPaymentMethodRequestable() {
                this.paymentMethodRequestable = true;
            },
            checkout() {
                this.resetError();
                this.checkoutInProcess = true;

                if (this.braintreeInstance && this.product) {
                    const amount = this.amountToCheckout;
                    const email = (this.user && this.user.profile && this.user.profile.email) ? this.user.profile.email : '';
                    const options =  {
                        dataCollector: {
                            kount: true // Required if Kount fraud data collection is enabled
                        }
                    };
                    const self = this;

                    if (this.enableThreeDSecure && amount) {
                        options.threeDSecure = {
                            amount: amount.toString(),

                            // Request a cardholder challenge to establish SCA when the card is first authorized,
                            // establishing a mandate between you and your customer.
                            // challengeRequested: true,

                            // If set to true, a card-add challenge will be requested from the issuer. If set to false,
                            // a card-add challenge will not be requested. If the param is missing, a card-add challenge
                            // will only be requested for $0 amount. An authentication created using this flag should
                            // only be used for vaulting operations (creation of customers' credit cards or payment
                            // methods) and not for creating transactions.
                            // cardAddChallengeRequested: true,

                            ... (email && { email })
                        };
                    }

                    this.braintreeInstance.requestPaymentMethod(options, (requestPaymentMethodErr, payload) => {
                        if (requestPaymentMethodErr) {
                            // TODO: show proper error to user
                            console.warn('Braintree request payment method error', requestPaymentMethodErr);
                            this.checkoutInProcess = false;
                            self.showGenericError();
                            return;
                        }

                        // Check 3D Secure validation result if any.
                        // When 3D Secure check is required for this customer and paying with credit card, allow only if
                        // the liability shift is not possible, or if it is possible and successfully shifted.
                        if (this.enableThreeDSecure && (payload.type === 'CreditCard') && (payload.liabilityShiftPossible && !payload.liabilityShifted)) {
                            // TODO: show proper error to user
                            console.warn('3D Secure liability shift possible but not done',
                                payload.liabilityShiftPossible,
                                payload.liabilityShifted);
                            this.checkoutInProcess = false;
                            self.showGenericError();
                            return;
                        }

                        this.checkoutInProcess = true;
                        self.braintreeCheckout(payload);
                    });
                }
            },
            braintreeCheckout(payload) {
                console.log('Entering braintreeCheckout method');
                const price_uuid = _.get(this.product, ["prices", 0, "uuid"], null);
                console.log('Price UUID:', price_uuid);

                return this.verifyRecaptcha()
                    .then((recaptchaToken) => {
                        console.log('Received reCAPTCHA token in braintreeCheckout:', recaptchaToken);
                        const params = {
                            full_name: null,
                            products: [price_uuid],
                            device_data: payload.deviceData,
                            payment_method_nonce: payload.nonce,
                            locale: i18nUtils.currentInterfaceLanguage,
                            recaptcha_token: recaptchaToken
                        };

                        if (this.product.voucherInfo && this.product.voucherInfo.voucher) {
                            params.voucher_code = this.product.voucherInfo.voucher.code;
                        }

                        EventBus.$emit('pay:loading', true);
                        this.resetError();

                        return this.user.getPayApi().braintreeCheckout(params);
                    })
                    .then(() => {
                        console.log('braintreeCheckout API call successful');
                        return this.user.getPayApi().invalidateCache();
                    })
                    .then(() => {
                        console.log('Cache invalidated');
                        return UserManager.instance.getUser().sync();
                    })
                    .then(() => {
                        console.log('User synced');
                        return UserManager.instance.getUser().getCourse().syncCourse();
                    })
                    .then(() => {
                        console.log('Course synced');
                        tagManagerCommand({ 'event': 'Purchase' });
                        this.resetError();
                        EventBus.$emit('pay:checkout-successful', { receipt: true });
                        this.checkoutInProcess = false;
                        Backbone.trigger('userSubChanged');
                    })
                    .catch((error) => {
                        console.error('Braintree checkout error:', error);
                        if (error.error) {
                            this.errorMessage = i18nUtils.prop(this.errorNameByError(error.error), null, null, "pay_errors");
                        } else if (error.message === 'reCAPTCHA verification failed') {
                            this.errorMessage = i18nUtils.prop('recaptcha_verification_failed', null, null, "pay_errors");
                        } else {
                            this.errorMessage = i18nUtils.prop('order_failed', null, null, "pay_errors");
                        }
                        this.checkoutInProcess = false;
                        EventBus.$emit('pay:loading', false);
                    });
            },

            verifyRecaptcha() {
                console.log('Entering verifyRecaptcha method');
                return new Promise((resolve, reject) => {
                    if (typeof grecaptcha === 'undefined') {
                        console.error('grecaptcha is undefined');
                        reject(new Error('reCAPTCHA not loaded'));
                        return;
                    }
                    console.log('grecaptcha is defined, calling ready()');
                    grecaptcha.ready(() => {
                        console.log('grecaptcha is ready, executing with site key');
                        grecaptcha.execute('6Ld0HBwqAAAAACKUu-BD9Z_n8EgeFLkQCmw_AJek', { action: 'braintree_checkout' })
                            .then(token => {
                                console.log('Received reCAPTCHA token:', token);
                                resolve(token);
                            })
                            .catch(error => {
                                console.error('Error executing reCAPTCHA:', error);
                                reject(error);
                            });
                    });
                });
            },
            showGenericError() {
                this.errorMessage = i18nUtils.prop('integration_failure', null, null, "pay_errors");
            },
            errorNameByError: function (err) {
                if (!err) {
                    return "order_failed";
                }

                if (_.isEqual("transaction_fail_soft", err)) {
                    return "order_failed_soft";
                }

                if (_.isEqual("already_subscribed", err)) {
                    return "order_failed_already_subscribed";
                }

                if (_.isEqual("transaction_fail_soft_insufficient_funds", err)) {
                    return "order_failed_soft_insufficient_funds";
                }

                if (_.isEqual("voucher_not_found", err)) {
                    return "voucher_invalid";
                }

                if (_.isEqual("invalid_product", err)) {
                    return "voucher_invalid";
                }

                return "order_failed";
            },
            resetError () {
                this.errorMessage = null;
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';
    div.checkout {
        > div.columns {
            > div.column {
                > div.summary {
                    > h3 {
                        margin-bottom: 1rem;
                        font-size: fontSize(18);
                        font-weight: $font-bold;
                    }
                    > div.subtitle {
                        display: flex;
                        flex-direction: row;
                        gap: .5rem;
                        > p {
                            color: $colorPurplePrimary;
                        }
                        > button {
                            margin-left: auto
                        }
                        @include respond-to('small') {
                            flex-direction: column;
                            > button {
                                margin-left: unset;
                            }
                        }
                    }
                    > button {
                        margin: 1rem 0 .5rem;
                    }
                    > div.info {
                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                        > div.row {
                            display: flex;
                            flex-direction: row;
                            > div.dot {
                                display: none;
                            }
                            > div.label {
                                font-weight: $font-bold;
                            }
                            > div.right {
                                margin-left: auto;
                                text-align: right;
                                > div.priceWithPeriod {
                                    > span.totalPrice {
                                        font-size: 2em;
                                        color: $colorSourcePrimary;
                                        @include respond-to('small') {
                                            font-size: 1.5em;
                                        }
                                    }
                                    > span.textPeriod {
                                        margin-left: 0.25em;
                                        font-size: fontSize(16);
                                    }
                                }
                                > span.vatText {
                                    font-size: fontSize(12);
                                }
                                > p.crossed {
                                    text-align: right;
                                    font-weight: $font-bold;
                                    color: $colorSourcePrimary;
                                    text-decoration: line-through;
                                }
                            }
                            &.price {
                                > div.label {
                                    padding-top: .5rem;
                                }
                            }
                        }
                        &.dots {
                            > div.row {
                                > div.dot {
                                    display: block;
                                    max-width: 2rem;
                                    min-width: 2rem;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        z-index: 1;
                                        left: 0.45rem;
                                        height: 1.5rem;
                                        border-left: 0.15rem $colorSourcePrimary solid;
                                    }
                                    &::after {
                                        display: inline-block;
                                        content: "";
                                        position: absolute;
                                        z-index: 2;
                                        left: -0.5rem;
                                        top: 0.70rem;
                                        transform: translateX(50%);
                                        border-radius: 50%;
                                        background-color: #fff;
                                        border: 0.15rem $colorSourcePrimary solid;
                                        width: 1rem;
                                        height: 1rem;
                                    }
                                }
                                &:first-child {
                                    > div.dot {
                                        margin-top: 0.25rem;
                                        &::before {
                                            height: 3rem;
                                        }
                                        &::after {
                                            top: 0;
                                            background-color: $colorSourcePrimary;
                                        }
                                    }
                                }
                                &:last-child:not(.secondary) {
                                    padding-top: 1rem;
                                    > div.dot {
                                        margin-top: 0.05rem;
                                    }
                                }
                                &.secondary {
                                    color: $colorSourceSecondary;
                                    > div.dot {
                                        &::before {
                                            border-color: $colorSourceTertiary;
                                        }
                                        &::after {
                                            border-color: $colorSourceTertiary;
                                        }
                                    }
                                    > div.right {
                                        padding-top: 0.5rem;
                                        > div.priceWithPeriod {
                                            > span.totalPrice {
                                                font-size: inherit;
                                                color: $colorSourceSecondary;
                                            }
                                            > span.textPeriod {
                                                font-size: inherit;
                                            }
                                        }
                                    }
                                }
                                &.middle {
                                    padding-top: 1rem;
                                    > div.dot {
                                        &::before {
                                            margin-top: 1rem;
                                            height: 100%;
                                            border-color: $colorSourceTertiary;
                                        }
                                    }
                                    > div.right {
                                        padding-top: 0;
                                    }
                                    &:first-child {
                                        padding-top: unset;
                                        > div.label {
                                            padding-top: 0.15rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                > div.voucherInfo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;
                    margin-top: pxToRem(24);
                    border-radius: pxToRem(8);
                    background-color: $colorNeutral;
                    padding: 1rem;
                    > div.text {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: .5rem;
                        span.prefix {
                            font-weight: $font-bold;
                        }
                    }
                }
                > div.actions {
                    > div.tos {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        input[type='checkbox'] {
                            margin-right: pxToRem(12)
                        }
                        label {
                            span.terms-and-conditions {
                                font-size: fontSize(16);
                                text-align: unset;
                                margin: 0;
                            }
                        }
                    }
                    > div.voucher {
                        margin-top: pxToRem(24);
                    }
                    > button {
                        margin-top: pxToRem(24);
                    }
                    > div.voucher-toggle {
                        margin-top: 1rem;
                        text-align: center;
                        opacity: 0.6;
                        user-select: none;
                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .slide-fade-enter-active {
            transition: all .3s ease;
        }
        .slide-fade-leave-active {
            transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        }
        .slide-fade-enter, .slide-fade-leave-to {
            transform: translateX(-30px);
            opacity: 0;
        }
    }
</style>
