var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-status-icon" },
    [
      this.showTooltip
        ? [
            _vm.tooltip
              ? _c("span", { staticClass: "tooltip" }, [
                  _vm._v(_vm._s(_vm.tooltip)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(_vm.icon, { tag: "component" }),
          ]
        : _c(_vm.icon, { tag: "component", attrs: { title: this.status } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }