import $ from 'jquery';
import _ from 'lodash';
import Backbone from 'backbone';

import ControllerManager from '../../modules/controller.manager.js';
import UserManager from '../../modules/usermanager.js';
import RecorderView from './recorder.view.js';

import AudioPlayer from '../../util/audioplayer.js';
import renderTemplate from '../../util/template.renderer.js';
import EventUtils from '../../util/event.js';
import EvaluationUtils from '../../util/evaluation.js';
import getConfigValue from '../../util/configuration.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';

import { ENTRY_TYPE as EXERCISE_ENTRY_TYPE } from '../../modules/exercises/answer/speaking.js';

const normalizeLine = line => {
    return line.toLowerCase().replace(/[.,!?]/g, '');
};

const ConversationView = Backbone.View.extend({

    name: 'practice.speaking',
    tagName: 'main',
    className: 'conversation-view',

    events: {
        'click a.close': 'closeButtonClicked',
        'click a.back': 'backButtonClicked',
        'click button.start': 'startExercise',
        'click a.notification-button': 'notificationButtonClicked'
    },

    initialize: function () {

        this.props = {};

        this.state = {
            step: 'initial',
            dialogCursor: 0,
            recorderActive: false,
            retryCount: 0
        };

        this.VOICE_SPEED = 'medium';

        this.targetLanguage = UserManager.instance.getUser().getCourse().getInfo().target_language;

        this.recorderView = new RecorderView({ targetLanguage: this.targetLanguage });

        // Listen for evaluated-correct event from RecorderView,
        // that sends this event when user's input is evaluated as correct
        this.recorderView.on('evaluated-correct', transcript => {

            const expectedLineNormalized = normalizeLine(this.props.dialog[this.state.dialogCursor].prompt);
            const transcriptNormalized = normalizeLine(transcript);

            const similarityScore = EvaluationUtils.similarText(expectedLineNormalized, transcriptNormalized, 1) / 100;

            this.trigger('exercise-entry', EXERCISE_ENTRY_TYPE.SPEECH, {
                phraseIndex: this.state.dialogCursor,
                transcript: transcript,
                similarityScore: similarityScore
            });

            this.updateState({
                step: 'evaluated-correct'
            });
        });

        // Listen for evaluated-incorrect event from RecorderView
        // that sends this event when user's input is evaluated as incorrect
        this.recorderView.on('evaluated-incorrect', transcript => {

            const newRetryCount = this.state.retryCount + 1;

            const expectedLineNormalized = normalizeLine(this.props.dialog[this.state.dialogCursor].prompt);
            const transcriptNormalized = normalizeLine(transcript);

            const similarityScore = EvaluationUtils.similarText(expectedLineNormalized, transcriptNormalized, 1) / 100;

            this.trigger('exercise-entry', EXERCISE_ENTRY_TYPE.SPEECH, {
                phraseIndex: this.state.dialogCursor,
                transcript: transcript,
                similarityScore: similarityScore
            });

            if (newRetryCount === 3) {
                this.updateState({
                    step: 'skip-notification',
                    retryCount: 0
                });
            } else {
                this.updateState({
                    step: 'evaluated-incorrect',
                    retryCount: newRetryCount
                });
            }
        });

        // Listen for skipped event that is sent when user
        // clicks the 'Skip' button in the recorder area
        this.recorderView.on('skipped', () => {
            this.trigger('exercise-entry', EXERCISE_ENTRY_TYPE.SKIP, {
                phraseIndex: this.state.dialogCursor,
                suggested: false
            });

            this.skipLine();
        });
    },

    setProps: function (props) {
        this.props = props;

        const mediaUrl = getConfigValue('media-base-url');
        const { uuid } = props;
        // Create a map of voices for sorting
        const voices_map =
            UserManager.instance.getUser().getCourse().getVoices().reduce((result, voice, index) => {
                result[voice.uuid] = index;
                return result;
            }, {});

        const audioPaths = this.props.dialog.map(line =>
            `${mediaUrl}/v1/${_.sortBy(line.speaker.voices, voice => voices_map[voice.uuid])[0].uuid}/exercise/${uuid}/${line.audio_hash}.mp3`
        );

        AudioPlayer.loadConversationAudios(audioPaths);
    },

    remove: function () {
        if (this.state.step !== 'done') {
            this.trigger('exercise-end', { finished: false });
        }
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {

        const renderData = {
            props: this.props,
            state: this.state
        };

        renderTemplate('challenges/conversation', renderData, this.$el).then( () => {
            this.updateState();
            this._postRender();
        });
        return this;
    },

    updateState: function (updates) {

        this.state = Object.assign({}, this.state, updates);

        const { step } = this.state;

        switch (step) {

            case 'initial': {

                this.recorderView.setElement(this.$('main.recorder-view'));
                this.recorderView.render();

                this.delay(1000).then( () => {
                    this.updateState({ step: 'reading-intro' });
                });

                ControllerManager.instance.getController('Onboarding').showConversationIntro();
                break;
            }

            case 'reading-intro': {
                break;
            }

            case 'answer-question': {
                break;
            }

            case 'evaluated-correct': {
                this.delay(1000).then( () => {
                    this.updateState({
                        step: 'append-users-line',
                        duration: this.getLineDuration(),
                        dialogCursor: this.state.dialogCursor + 1
                    });
                });
                break;
            }

            case 'evaluated-incorrect': {
                break;
            }

            case 'append-users-line': {
                this.delay(this.state.duration).then( () => {
                    if (this.state.dialogCursor === this.props.dialog.length) {
                        this.updateState({
                            step: 'done'
                        });
                    } else {
                        AudioPlayer.playConversationLine(this.state.dialogCursor);
                        this.updateState({
                            step: 'append-computers-line',
                            dialogCursor: this.state.dialogCursor + 1
                        });
                    }
                });
                break;
            }

            case 'append-computers-line': {
                this.recorderView.updateState({ status: 'computer-speaking' });
                this.delay(this.state.duration).then( () => {
                    if (this.state.dialogCursor === this.props.dialog.length) {
                        this.updateState({
                            step: 'done'
                        });
                    } else {
                        this.updateState({
                            step: 'answer-question',
                            retryCount: 0
                        });
                    }
                });
                break;
            }

            case 'done': {
                this.trigger('exercise-end', { finished: true });
            }
        }

        this.updateView();
    },

    updateView: function () {

        const $intro = this.$('div.intro');
        const $footer = this.$('footer');
        const $skipNotification = this.$('div.skip-notification');
        const $doneNotification = this.$('div.done-notification');

        this.$dialog = this.$('div.dialog-scroll-container');

        const { step } = this.state;

        switch (step) {

            case 'reading-intro': {
                $footer.addClass('display');

                this.delay(25).then( () => {
                    $footer.addClass('visible');
                });
                break;
            }

            case 'skip-notification': {

                this.recorderView.updateState({ status: 'initial' });
                $skipNotification.addClass('display');

                this.delay(25).then( () => {
                    $skipNotification.addClass('visible');
                });

                googleAnalyticsCommand('send', 'event', 'ChallengesArea', 'SillyRobotSeen');
                break;
            }

            case 'answer-question': {

                $intro.removeClass('visible');

                this.delay(500).then( () => {
                    $intro.removeClass('display');
                });

                this.hideNotifications().then( () => {
                    this.loadNewLineToRecorder();
                });

                break;
            }

            case 'append-users-line': {
                this.hideNotifications().then( () => {
                    const $line = this.$('div.line-container.user:not(.visible)').first();
                    $line.addClass('visible');
                    this.animateScroll();
                });
                break;
            }

            case 'append-computers-line': {
                const $line = this.$('div.line-container.auto:not(.visible)').first();
                this.animateScroll();
                $line.addClass('visible');
                break;
            }

            case 'done': {
                this.recorderView.updateState({ status: 'initial' });
                $doneNotification.addClass('display');

                this.delay(25).then( () => {
                    $doneNotification.addClass('visible');
                });

                googleAnalyticsCommand('send', 'event', 'ChallengesArea', 'TaskCompleted');
                break;
            }
        }
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
    },

    loadNewLineToRecorder: function () {
        this.recorderView.setProps({
            targetLanguage: this.targetLanguage,
            expectedText: this.props.dialog[this.state.dialogCursor].prompt,
            lineWords: this.props.dialog[this.state.dialogCursor].parsed,
            lineIndex: this.state.dialogCursor
        });

        this.recorderView.updateState({
            status: 'ready',
            evaluatedWords: null,
            expectedTextVisible: true
        });
    },

    startExercise: function () {
        this.updateState({ step: 'answer-question' });
    },

    skipLine: function () {
        this.delay(1000).then( () => {
            AudioPlayer.playConversationLine(this.state.dialogCursor);
            this.updateState({
                step: 'append-users-line',
                duration: this.getLineDuration(),
                dialogCursor: this.state.dialogCursor + 1
            });
        });
    },

    notificationButtonClicked: function (event) {

        const action = $(event.currentTarget).data('action');

        switch (action) {
            case 'dismiss': {
                this.updateState({
                    step: 'answer-question'
                });
                break;
            }

            case 'close': {
                Backbone.history.navigate('challenges', { trigger: true });
                break;
            }

            case 'skip': {
                this.trigger('exercise-entry', EXERCISE_ENTRY_TYPE.SKIP, {
                    phraseIndex: this.state.dialogCursor,
                    suggested: true
                });
                this.skipLine();
                break;
            }
        }
    },

    getLineDuration: function () {
        const line = this.props.dialog[this.state.dialogCursor];
        const voice = line.speaker.voices.find( voice => {
            return (voice.speed === this.VOICE_SPEED);
        });
        return voice.duration * 1000 + 1000; // Extra 1 second pause between lines
    },

    closeButtonClicked: function () {
        this.hideNotifications();
    },

    backButtonClicked: function () {
        Backbone.history.navigate('challenges', { trigger: true });
    },

    animateScroll: function () {
        let stepIndex = 1;
        const scrollSteps = 50;
        const scroller = setInterval( () => {
            stepIndex = stepIndex + 1;
            if (stepIndex === scrollSteps) {
                clearInterval(scroller);
            }
            this.$dialog.scrollTop(this.$dialog.prop('scrollHeight'));
        }, 10);
    },

    hideNotifications: function () {

        const $footer = this.$('footer');
        const $skipNotification = this.$('div.skip-notification');
        const $doneNotification = this.$('div.done-notification');

        return new Promise(resolve => {

            if ($footer.hasClass('visible')) {
                $footer.removeClass('visible');
                EventUtils.on('transitionend', $footer, event => {
                    if (event.originalEvent.propertyName === 'transform') {
                        EventUtils.off('transitionend', $footer);
                        $footer.removeClass('display');
                        resolve();
                    }
                });
            } else if ($skipNotification.hasClass('visible')) {
                $skipNotification.removeClass('visible');
                EventUtils.on('transitionend', $skipNotification, event => {
                    if (event.originalEvent.propertyName === 'transform') {
                        EventUtils.off('transitionend', $skipNotification);
                        $skipNotification.removeClass('display');
                        resolve();
                    }
                });
            } else if ($doneNotification.hasClass('visible')) {
                $doneNotification.removeClass('visible');
                EventUtils.on('transitionend', $doneNotification, event => {
                    if (event.originalEvent.propertyName === 'transform') {
                        EventUtils.off('transitionend', $doneNotification);
                        $doneNotification.removeClass('display');
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    },

    delay: function (duration) {
        return new Promise(resolve => setTimeout( () => { resolve(); }, duration));
    },
});

export default ConversationView;
