var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M18 16.333C18 20.015 14.966 23 11.224 23a6.784 6.784 0 01-6.174-3.914c-.217-.47.318-.794.626-.47.572.6 1.311 1.05 2.16 1.05 1.87 0 3.388-1.492 3.388-3.333 0-1.84 1.517-3.333 3.388-3.333S18 14.492 18 16.333z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5 6h8M9 6V3M7 17v3h9M17 4v11M20 9h-3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "9",
          cy: "11",
          rx: "4",
          ry: "2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }