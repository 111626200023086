
import Vue from 'vue';
import Backbone from 'backbone';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';

import ResultsModel from '../model/results.model.js';
import RelevantWordsView from '../view/relevant_words/main.vue';
import moment from 'moment';
import UserModel from "Model/user.model";

const RelevantWordsViewComponent = Vue.extend(RelevantWordsView);

export class RelevantWordsController {

    constructor () {}

    _unpack_learned_curve (raw_data) {
        return raw_data.reduce((result, part) => {
            return result.concat(
                part.series.map((dataPoint, i) => ({x: part.start + i * part.step, y: dataPoint}))
            );
        }, []);
    }

    _add_interaction (interactions, event_name, position) {
        interactions.push({
            type: event_name,
            position: Math.round(position),
            ts: moment()
        });
        return interactions.slice(interactions.length - 100, interactions.length);
    }

    async go (appView) {
        const course_info = UserManager.instance.getUser().getCourse().getInfo();
        const target_language = course_info.target_language;
        const learned_text_raw = await ResultsModel.getLearnedTextRaw(target_language);
        const chart_series = this._unpack_learned_curve(await ResultsModel.getLearnedCurve(target_language));
        const opened = moment();
        let slider_interactions = [];

        const relevant_words_view = new RelevantWordsViewComponent({
            chart_series,
            learned_text_raw,
            target_language
        });

        relevant_words_view.$on('continue-pressed', async () => {
            const navigateToGuess = UserModel.shouldDirectUserStraightToGuess();
            if (navigateToGuess) {
                Backbone.history.navigate('guess', { trigger: true });
            } else {
                Backbone.history.navigate('subscriptions', { trigger: true });
            }

            return await UserManager.instance.getUser().getEventSender().sendRelevantWordsEvent(
                course_info.uuid, opened, moment(), slider_interactions);
        });

        relevant_words_view.$on('start-tracking', async position => {
            slider_interactions = this._add_interaction(slider_interactions, 'start-tracking', position);
        });

        relevant_words_view.$on('end-tracking', async position => {
            slider_interactions = this._add_interaction(slider_interactions, 'end-tracking', position);
        });

        await appView.setMainView(relevant_words_view);


    }
}

export const relevant_words_controller_factory = new ControllerFactory(RelevantWordsController);
