var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M17.5 38C8.387 38 1 30.613 1 21.5S8.387 5 17.5 5C.5 8.5 5 27 9 30.5c6.122 5.356 14.5 6 22.462 0C30.492 32 27.494 38 17.5 38z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20 35c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15zm0 0c-2.42-3.97-2.903-10.588 0-15m0 0c-2.37-4.72-8.342-7.61-12.99-7.5M20 20c5.272.308 10.761-3.42 12.99-7.5M18 27.5c9.18 1.868 16.959-3.994 16.5-11m-19.995-1.982c-6.208 7.015-5.02 16.684 1.276 19.79m11.714-16.326C24.523 9.1 15.557 5.292 9.72 9.192",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }