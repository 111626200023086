
'use strict';

import { QuestionList } from './question.list.js';

export class FastTrackingList extends QuestionList {

    constructor (course, assetLoader, Modules, guessQueue) {
        super(course, null, assetLoader, Modules);
        this.__name = 'FastTrackingWordList';
        this._guessQueue = guessQueue;

        this.ASSET_LOADING_PRIORITY = 5;
    }

    _questionFilter (questionData) {
        var currentQuestion = this._guessQueue.getCurrentQuestionSync(),
            notCurrentQuestion = currentQuestion === null || currentQuestion.UUID !== questionData.lexical_unit_uuid;

        return notCurrentQuestion && this._Modules.Question.isFastTracking(questionData);
    }
}
