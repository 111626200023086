
import _ from 'lodash';
import moment from 'moment';

import { StatisticsProcessorUtils, EVENT_TYPE } from '../processor.utils.js';

export class StatisticsExerciseCompleteEventProcessor {

    static canProcess (event) {
        return event.schema.startsWith('urn:lingvist:schemas:events:exercise_complete');
    }

    static process (event, courseStatistics) {

        if (event.__statisticsDataV1 === undefined) {
            event.__statisticsDataV1 = {
                type: EVENT_TYPE.EXERCISE_COMPLETE,
                eventTS: moment(event.client_event_ts),
                clientSN: event.client_sn,
                category: event.data.exercise_category
            };
        }

        const eventStatistics = event.__statisticsDataV1;
        const currentStatistics = courseStatistics.getData();
        const timeBetweenEvents = eventStatistics.eventTS.diff(currentStatistics.client_event_ts, 'seconds');

        let updatedStatistics = _.cloneDeep(currentStatistics);

        updatedStatistics.exercises = {
            all:  currentStatistics.exercises.all + 1,
            by_category: currentStatistics.exercises.by_category  // Initialize with old, increment in next step
        };

        updatedStatistics.exercises.by_category[eventStatistics.category] =
            (currentStatistics.exercises.by_category[eventStatistics.category] || 0) + 1;

        const updatedHistory = StatisticsProcessorUtils.getUpdatedHistory(
            eventStatistics,
            _.cloneDeep(courseStatistics.getHistory()),
            timeBetweenEvents
        );

        updatedStatistics.client_event_ts = eventStatistics.eventTs;

        return [updatedStatistics, updatedHistory];
    }
}
