'use strict';

import { getPersistentStorageProvider } from './persistent.storage.provider.js';
import Backbone from 'backbone';
import _ from 'lodash';
import UserManager from "./usermanager.js";

export class UserAccounts {
    static async getAccountsFromStorage () {
        const accounts = await getPersistentStorageProvider().getItemAnonymous('accounts');
        if (accounts) {
            return new Map(accounts);
        } else {
            return new Map();
        }
    }

    static updateLocalStorage (accounts) {
        getPersistentStorageProvider().setItemAnonymous('accounts', [...accounts]);
    }

    async addUserAccount (account) {
        if (account) {
            const { user: { user_uuid: uuid } } = account;
            if (uuid) {
                let accounts = await UserAccounts.getAccountsFromStorage();
                accounts.set(uuid, account);
                UserAccounts.updateLocalStorage(accounts);
            }
        }
    }

    async deleteUserAccount (uuid) {
        if (uuid) {
            let accounts = await UserAccounts.getAccountsFromStorage();
            accounts.delete(uuid);
            UserAccounts.updateLocalStorage(accounts);
        }
    }

    async getUserAccounts () {
        const accounts = await UserAccounts.getAccountsFromStorage();
        return [...accounts.values()];
    }

    async getUserAccountInfo (user) {
        if (user.hasCourse()) {
            const auth_providers = user.getAuthentication().providers;
            const { source_language, target_language, source_icon_id, target_icon_id, uuid: course_uuid } = user.getCourse().getInfo();
            const { name, email, uuid: user_uuid } = user.getInfo();
            const { expiration_ts, on_hold, status, trial_duration } = user.getSubscription();
            const paySubscriptionInfo = await user.getSubscription().getPaySubscriptionInfo();

            return {
                auth_provider: _.isArray(auth_providers) ? auth_providers[0] : null,
                course: { source_language, target_language, source_icon_id, target_icon_id, uuid: course_uuid },
                subscription: { expiration_ts, on_hold, status, trial_duration, product_name: (paySubscriptionInfo && paySubscriptionInfo.hasOwnProperty('product_name')) ? paySubscriptionInfo.product_name : null },
                user: { name, email, user_uuid }
            };
        } else {
            return false;
        }
    }

    async addOrUpdateCurrentUser() {
        const user = await UserManager.instance.getUser();
        if (user) {
            let accountInfo = await this.getUserAccountInfo(user);
            await this.addUserAccount(accountInfo);
        }
    }

    async startListeners () {
        Backbone.on('userSignedIn', this.addOrUpdateCurrentUser, this);
        Backbone.on('userCourseChanged', this.addOrUpdateCurrentUser, this);
        Backbone.on('userSubChanged', this.addOrUpdateCurrentUser, this);
    }
}
