var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { "fill-rule": "nonzero", fill: "none" } }, [
        _c("circle", {
          attrs: { cx: "16", cy: "16", r: "16", fill: "#FFCC4D" },
        }),
        _c("path", {
          attrs: {
            fill: "#664500",
            d: "M22.653 24.337c-.04-.16-1.038-3.893-6.653-3.893-5.616 0-6.613 3.734-6.653 3.893a.443.443 0 00.21.492.45.45 0 00.534-.068c.017-.017 1.737-1.65 5.909-1.65s5.893 1.633 5.91 1.649a.446.446 0 00.53.072.445.445 0 00.213-.495z",
          },
        }),
        _c("ellipse", {
          attrs: {
            cx: "10.667",
            cy: "12",
            fill: "#664500",
            rx: "2.222",
            ry: "3.111",
          },
        }),
        _c("ellipse", {
          attrs: {
            cx: "21.333",
            cy: "12",
            fill: "#664500",
            rx: "2.222",
            ry: "3.111",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }