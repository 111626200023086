var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "22",
          height: "20",
          viewBox: "0 0 22 20",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8 7L10.246.637c.25-.711 1.257-.711 1.508 0L14 7h6.687c.76 0 1.092.96.494 1.429L16 12.499l2.239 6.344c.252.714-.537 1.346-1.179.945L11 16l-6.06 3.788c-.642.4-1.43-.231-1.179-.945L6 12.5.819 8.429c-.598-.47-.266-1.43.494-1.43H8z",
          fill: "#68AEB8",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }