var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M15 11.959h-1a5 5 0 00-5 5v5.593a1.5 1.5 0 103 0V18a2 2 0 012-2h1v18.5a1.5 1.5 0 003 0v-8a1.5 1.5 0 011.5-1.5M24 12h1a5 5 0 015 5v5.5a1.5 1.5 0 01-3 0V18a2 2 0 00-2-2h-1v18.5a1.5 1.5 0 01-3 0v-8a1.5 1.5 0 00-1.5-1.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: { cx: "19.5", cy: "6.5", r: "4.5", fill: "#63E1E7" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }