<template>
    <div :class="{ 'editable': true, 'with-counter': !!maxLength }">
        <span class="length-counter" v-if="maxLength">{{ lengthCounter }}</span>
        <span class="edit-icon" v-if="!isInFocus" @click="$refs.editable.focus()">
            <edit-icon />
        </span>
        <div
            :class="{ 'editable-content': true, 'nowrap': this.nowrap, 'no-padding': this.noPadding }"
            ref="editable"
            contenteditable
            v-on:keydown.enter="onEnter"
            v-on:keydown.esc="onEsc"
            v-on:keydown="onInput"
            v-on:input="onInput"
            v-on:paste="onInput"
            v-on:blur="onBlur"
            v-on:focus="onFocus"
        />
    </div>
</template>

<script>
import EditIcon from 'Images/status-unfinished.svg'

export default {
    name: 'editable',
    props: {
        value: {
            type: String,
            default: '',
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        focusOnMount: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number
        }
    },
    components: {
        EditIcon
    },
    data() {
        return {
            valueOnFocus: null,
            isInFocus: false,
            contentLength: 0
        }
    },
    mounted() {
        this.$refs.editable.innerText = this.value;
        this.contentLength = this.value.length;
        if (this.focusOnMount) {
            this.$refs.editable.focus();
        }
    },
    methods: {
        onInput(e) {
            const allowedKeyCodes = [8, 46, 37, 39 ,38, 40]
            const _content = e.target.innerText.trim();
            const _length = _content.length;
            if (this.maxLength && _length === this.maxLength && !allowedKeyCodes.includes(e.keyCode)) {
                e.preventDefault();
            } else {
                this.$emit('input', _content);
            }
            this.contentLength = _length;

            if (e.inputType === 'historyUndo') {
                this.$emit('change', _content);
            }
        },
        onFocus(e) {
            e.stopPropagation();
            this.isInFocus = true;
            this.valueOnFocus = e.target.innerText;
        },
        onBlur(e) {
            this.$emit('blur');
            const valueOnBlur = e.target.innerText;
            if (this.valueOnFocus !== valueOnBlur) {
                this.$emit('change', valueOnBlur);
            }
            this.isInFocus = false;
            this.valueOnFocus = null;
        },
        onEnter(e) {
            e.preventDefault();
            e.target.blur();
        },
        onEsc(e) {
            e.preventDefault();
            e.target.blur();
        },
    },
    computed: {
        lengthCounter() {
            return `${this.contentLength}/${this.maxLength}`;
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$refs.editable.innerText = newValue;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    @import '~Styles/colors';
    @import '~Styles/mixins';

    div.editable {
        position: relative;
        box-sizing: content-box;

        > div.editable-content {
            position: relative;
            padding: .25rem;
            outline: 0 solid transparent;
            transition: border-bottom 250ms;
            border-bottom: 2px solid transparent;
            z-index: 5;

            &.no-padding {
                padding: 0 0 .25rem 0;
            }

            &:hover {
                border-bottom: 2px solid $colorBackgroundDarker;
            }

            &:focus {
                border-bottom-color: $colorSourcePrimary;
            }

            &.nowrap {
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &.with-counter {
            padding-bottom: pxToRem(14);
            > div.editable-content {
                &:hover,
                &:focus {
                    border-bottom: none;
                    background: rgba(224, 235, 239, 0.4);
                    box-shadow: 0 0 0 pxToRem(4) rgba(224, 235, 239, 0.4);
                    border-radius: pxToRem(2);
                    padding: pxToRem(4);
                }
            }
        }

        > span.length-counter {
            display: inline-block;
            position: absolute;
            bottom: 0;
            right: pxToRem(6);
            font-size: fontSize(12);
            line-height: fontSize(14);
            font-family: $defaultFonts;
            z-index: 10;
            background-color: #fff;
            border-radius: pxToRem(2);
            padding: pxToRem(2) pxToRem(4);
            border: 1px solid rgba(224, 235, 239, 0.4);
        }

        > span.edit-icon {
            display: none;
            position: absolute;
            z-index: 100;
            bottom: .2rem;
            right: .2rem;
            background-color: #fff;
            border-radius: pxToRem(4);
            padding: .2rem;
            box-shadow: 0 pxToRem(1) pxToRem(2) 0 rgba(0, 0, 0, 0.3);
        }

        &:hover {
            > span.edit-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>
