<template>
    <section class="vocabulary-text" v-if="text">
        <h2>{{ knowPercentageText }}</h2>
        <div class="text">
            <span
                v-for="word in text"
                class="word"
                v-bind:class="{ blocked: word.p > position }"
            >{{ word.w }}</span>
        </div>
    </section>
</template>

<script>

    import i18nUtils from "Util/i18n.js";

    export default {
        name: 'vocabulary-text',
        props: {
            position: {
                type: Number,
                required: true
            },
            max_words: {
                type: Number,
                required: true
            },
            learned_text_raw: {
                type: Array,
                required: true
            },
            knowPercentage: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                text: null
            };
        },

        methods: {
            setText(_textArray) {
                this.text = _textArray.reduce((result, word) => {
                    if (word.w.endsWith(' ')) {
                        result.push({
                            w: word.w.slice(0, word.w.length - 1),
                            p: word.p
                        });
                        result.push({
                            w: ' ',
                            p: 0
                        });
                    } else {
                        result.push(word);
                    }
                    return result;
                }, []);
            }
        },

        computed: {
            knowPercentageText() {
                return i18nUtils.prop('my_progress_language_learned_tooltip', [this.knowPercentage])
            },
        },

        watch: {
            learned_text_raw(newValue) {
                this.setText(newValue);
            }
        },

        mounted() {
            if (this.learned_text_raw) {
                this.setText(this.learned_text_raw);
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.vocabulary-text {
        > h2 {
            margin-bottom: 1rem;
        }
        > div.text {
            color: $colorPurplePrimary;
            font-family: $targetLanguageFonts;
            line-height: 1.6;
            user-select: none;
            word-spacing: 1px;
            > span.word {
                padding: 1px 0;
                transition: all 0.25s;

                &.blocked:not(&:hover) {
                    border-radius: 4px;
                    color: $colorBackgroundGray;
                    background-color: $colorBackgroundGray;
                }
                &:hover {
                    opacity: .6;
                }
            }
        }
    }
</style>
