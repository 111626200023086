var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    {
      staticClass: "guess",
      class: {
        "game-over": this.gameOver,
        "tutor-open": this.tutorOpen,
        "grammar-open": this.grammarOpen,
      },
    },
    [
      _vm.isLoading
        ? _c("loader", { attrs: { centered: true } })
        : [
            _c(
              "transition",
              { attrs: { name: "slide-fade-insights" } },
              [
                _vm.dayInsightsShown
                  ? _c("day-insights", {
                      attrs: {
                        "show-continue-button": true,
                        debug: _vm.dayInsightsDebug,
                      },
                      on: { close: _vm.closeDayInsights },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.modalStoryVisible
              ? _c("modal-story", {
                  attrs: { "content-id": _vm.modalStoryContentId },
                  on: { close: _vm.closeModalStory },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("repeat-progress-modal"),
            _vm._v(" "),
            _c("hotkeys-modal"),
            _vm._v(" "),
            _c("ft-modal", { attrs: { course: this.course } }),
            _vm._v(" "),
            _c("feedback-modal", {
              attrs: {
                question: this.presentedQuestion,
                answer: this.on_previous ? this.previous_answer : this.answer,
                "card-has-visuals": this.cardHasVisuals,
                "card-visual-data": this.cardVisualData,
              },
            }),
            _vm._v(" "),
            _c("card-visual-modal", {
              attrs: { question: this.presentedQuestion },
              on: { "visual-chosen": _vm.cardVisualChosen },
            }),
            _vm._v(" "),
            _vm.hintMarkElement
              ? _c("hint-mark", {
                  attrs: { "target-element": _vm.hintMarkElement },
                  on: { close: this.clearHintMark },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.gameOver
              ? _c("locked-message", {
                  attrs: {
                    closable: false,
                    title: _vm.lockedMessageTitle,
                    text: _vm.lockedMessageText,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "slide-fade" } },
              [
                _vm.tutorOpen
                  ? _c("tutor", {
                      attrs: { question: this.presentedQuestion },
                      on: { close: _vm.closeTutor },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.grammarOpen
                  ? _c("grammar-tag", {
                      attrs: { question: this.presentedQuestion },
                      on: { close: _vm.closeGrammar },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-grid" },
              [
                _c("card", {
                  attrs: {
                    question: this.question_computed,
                    answer: this.answer,
                    locked: this.locked,
                    on_previous: this.on_previous,
                    previous_question: this.previous_question,
                    previous_answer: this.previous_answer,
                    disabled: this.gameOver,
                    autoPlay: this.autoPlay,
                    visuals: this.visuals,
                    "card-has-visuals": this.cardHasVisuals,
                    "day-insights-shown": this.dayInsightsShown,
                    onlySynonymAnswers: this.onlySynonymAnswers,
                    "word-is-favourite": this.wordIsFavourite,
                    auto_advance: this.auto_advance,
                  },
                  on: {
                    "form-spellings-visibility": _vm.updateSpellingsVisibility,
                    "user-answer-changed": _vm.updateUserAnswer,
                    "toggle-grammar": _vm.toggleGrammar,
                    "mute-word": _vm.muteWord,
                    "favourite-word": function ($event) {
                      return _vm.favouriteWord(true)
                    },
                    "un-favourite-word": function ($event) {
                      return _vm.favouriteWord(false)
                    },
                  },
                }),
                _vm._v(" "),
                this.presentedQuestion && this.debugMode
                  ? _c("card-debug", {
                      attrs: {
                        course: this.course,
                        question: this.presentedQuestion,
                        synonyms: this.synonyms,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "footer",
              [
                _c(
                  "transition",
                  { attrs: { name: "slide-down-fade" } },
                  [
                    _vm.message
                      ? _c("message", {
                          attrs: { message: _vm.message },
                          on: {
                            close: _vm.closeMessage,
                            "trigger-action": this.triggerMessageAction,
                            "trigger-dismiss": this.triggerMessageDismiss,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("utility-belt", {
                  attrs: {
                    answer: this.on_previous
                      ? this.previous_answer
                      : this.answer,
                    userAnswer: this.userAnswer,
                    question: this.question,
                    previous_question: this.previous_question,
                    audioIsPlaying: this.audioIsPlaying,
                    locked: this.locked,
                    on_previous: this.on_previous,
                    tutorOpen: this.tutorOpen,
                    disabled: this.gameOver,
                    grammarTable: this.on_previous
                      ? this.previousGrammarTable
                      : this.grammarTable,
                    has_previous: !!this.previous_question,
                    visuals: this.visuals,
                    "card-has-visuals": this.cardHasVisuals,
                    "card-visual-data": this.cardVisualData,
                    course: this.course,
                    auto_advance: this.auto_advance,
                    strict_diacritics: this.strict_diacritics,
                    "form-spellings-visible": this.formSpellingsVisible,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("survey", {
              attrs: {
                "survey-source": "guess",
                "type-initial": "impact",
                course_uuid: this.question_computed.courseUUID,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }