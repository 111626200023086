var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wordlist" },
    [
      _c("section", { staticClass: "main" }, [
        _c(
          "header",
          [
            _c(
              "div",
              { staticClass: "top" },
              [
                _c("wordlist-search", {
                  on: { "search-for": _vm.searchForString },
                }),
                _vm._v(" "),
                _c("wordlist-play-button", {
                  attrs: { "is-playing": this.playlistIsPlaying },
                  on: { play: _vm.playPlaylist, stop: _vm.stopPlaylist },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("wordlist-filters", {
              attrs: {
                inverted: _vm.inverted,
                "show-muted": _vm.showMuted,
                "show-in-playlist": _vm.showInPlaylist,
                "show-favourites": _vm.showFavourites,
                course: _vm.course,
                "sort-by": _vm.sortBy,
                "filter-counters": _vm.filterCounters,
              },
              on: {
                "toggle-language": _vm.toggleLanguage,
                "toggle-in-playlist": _vm.togglePlaylist,
                "toggle-favourites": _vm.toggleFavourites,
                "toggle-muted": _vm.toggleMuted,
                "sort-by": _vm.sort,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.filteredWords && _vm.filteredWords.length > 0
          ? _c(
              "div",
              { ref: "wordlist-wrapper", staticClass: "list" },
              [
                _vm._l(_vm.filteredWords, function (word) {
                  return word && word.hasOwnProperty("lexical_unit_uuid")
                    ? _c("wordlist-item", {
                        key: word.lexical_unit_uuid,
                        attrs: {
                          word: word,
                          inverted: _vm.inverted,
                          course: _vm.course,
                          "is-selected": !!(
                            _vm.selectedWord &&
                            word.lexical_unit_uuid ===
                              _vm.selectedWord.lexical_unit_uuid
                          ),
                          currentlyPlayingWordUUID:
                            _vm.currentlyPlayingWordUUID,
                          currentlyPlayingWordAudioType:
                            _vm.currentlyPlayingWordAudioType,
                        },
                        on: {
                          mute: _vm.muteWord,
                          "un-mute": _vm.unMuteWord,
                          favourite: _vm.favouriteWord,
                          "un-favourite": _vm.unFavouriteWord,
                          playlist: _vm.playlistWord,
                          "un-playlist": _vm.unPlaylistWord,
                          select: _vm.selectWord,
                        },
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.showMoreButtonVisible
                  ? _c("v-button", {
                      staticClass: "more-button",
                      attrs: {
                        i18n: { id: "course_wizard_sentence_show_more" },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showMore.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            )
          : this.data && this.data.words_total === 0
          ? _c(
              "div",
              { staticClass: "no-words-learned" },
              [
                _c("h2", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("dashboard_word_list_empty_state_title")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("dashboard_word_list_empty_state_text")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("v-button", {
                  attrs: {
                    i18n: { id: "dashboard_word_list_empty_state_btn" },
                    filled: true,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goGuess.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            )
          : this.showMuted || this.showFavourites || this.showInPlaylist
          ? _c("div", { staticClass: "no-result" }, [
              _c("h2", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("dashboard_word_list_search_no_result_title")
                  ),
                },
              }),
              _vm._v(" "),
              this.showMuted
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("dashboard_word_list_sorting_no_muted_words")
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              this.showFavourites
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("dashboard_word_list_sorting_no_favourites")
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              this.showInPlaylist
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("dashboard_word_list_sorting_empty_playlist")
                      ),
                    },
                  })
                : _vm._e(),
            ])
          : _c("div", { staticClass: "no-result" }, [
              _c("h2", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("dashboard_word_list_search_no_result_title")
                  ),
                },
              }),
              _vm._v(" "),
              this.searchFor && this.searchFor.length > 0
                ? _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "dashboard_word_list_search_no_result_text",
                          { args: { search_input: this.searchFor } }
                        )
                      ),
                    },
                  })
                : _vm._e(),
            ]),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-in-fade" } },
        [
          _vm.selectedWord
            ? _c("wordlist-sidebar", {
                attrs: { "selected-word": _vm.selectedWord },
                on: {
                  close: _vm.clearSelectedWord,
                  "update-note": _vm.updateWordNote,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }