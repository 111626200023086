import $ from 'jquery';
import Backbone from 'backbone';
import renderTemplate from '../../util/template.renderer.js';

const ErrorView = Backbone.View.extend({

    events: {
        'click button.reload': 'reload'
    },

    isReloadable: true,

    initialize: function () {
        if ($('body > div.error-overlay').length === 0) {
            $('body').append('<div class="error-overlay hidden"></div>');
        }
        this.setElement($('body > div.error-overlay'));
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    /**
     * @param {APIError|null} error - Error object to display if available
     * @return {ErrorView}
     */
    render: function (error=null) {
        renderTemplate('error-overlay', {error}, this.$el).then( () => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {
        //this.$el.removeClass('hidden');
    },

    show: function () {
        this.$el.removeClass('hidden');
    },

    hide: function () {
        this.$el.addClass('hidden');
    },

    reload: function () {
        if (this.isReloadable) {
            window.location.reload();
        } else {
            this.hide();
        }
    }
});

export default ErrorView;
