<template>
    <div class="course-list-item" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: course && course.action === 'learning' }">
        <template v-if="course">
            <course-icon :value="this.showSource ? course.source_icon_id : course.target_icon_id" @click="selectCourse" size="large" />
            <div class="info" @click="selectCourse">
                <div class="title">{{ course.name }}</div>
                <div class="subtitle" v-if="showSubtitle">{{ course.name_subtitle }}</div>
            </div>
            <div class="right" v-if="showMeta || showMoreDropdown">
                <div class="meta">
                    <course-list-item-more-dropdown v-if="showMoreDropdownLocal" :course="this.course" />
                    <template v-else>
                        <span v-if="course.action !== 'learning' && course.action !== 'unselected' && isNewCourse" class="new" v-html="this.$i18n('course_selection_beta')"  @click="selectCourse" />
                        <selected-icon v-if="course.action === 'learning'" :class="{ hover: this.hover }" @click="selectCourse" />
                        <tick-icon v-else-if="course.action === 'unselected'" @click="selectCourse" />
                    </template>
                </div>
            </div>
        </template>
        <template v-else-if="showNotListed">
            <course-icon value="unknown" size="large" />
            <div class="info" @click="$emit('language-not-listed')">
                <div class="title" v-html="this.$i18n('register_select_course_language_name_unknown')" />
            </div>
        </template>
    </div>
</template>

<script>
import CourseIcon from 'ViewComponent/course-icon.vue';
import SelectedIcon from 'Images/account/selected.svg';
import TickIcon from 'Images/tick-24x24.svg';
import CourseListItemMoreDropdown from "./course-list-item-more.vue";

export default {
    name: 'course-list-item',
    components: {
        CourseListItemMoreDropdown,
        CourseIcon,
        SelectedIcon,
        TickIcon
    },
    props: {
        course: {
            type: Object,
        },
        showSubtitle: {
            type: Boolean,
            default: true
        },
        showMeta: {
            type: Boolean,
            default: true
        },
        showSource: {
            type: Boolean,
            default: false
        },
        showNotListed: {
            type: Boolean,
            default: false
        },
        showMoreDropdown: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hover: false
        }
    },
    computed: {
        isNewCourse() {
            return this.course.features.includes('new');
        },
        showMoreDropdownLocal() {
            return !!(this.showMoreDropdown && this.course.action !== 'learning');
        }
    },
    methods: {
        selectCourse() {
            this.$emit('select-course', this.course);
        }
    },
}
</script>

<style lang="scss">
    @import "~Styles/_colors.scss";
    @import "~Styles/_mixins.scss";

    div.course-list-item {
        display: flex;
        flex-direction: row;
        user-select: none;
        cursor: pointer;
        align-items: center;
        gap: .5rem;
        transition: background-color 250ms;
        border-radius: pxToRem(8);
        padding: .5rem;

        &:hover:not(.active) {
            background-color: $colorBackgroundLighter;
            cursor: pointer;
        }

        &.active {
            pointer-events: none;
        }

        > div.info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div.title {
                font-size: fontSize(18);
                line-height: 1.2rem;
                font-weight: $font-bold;
            }
            > div.subtitle {
                color: $colorSourceSecondary;
                line-height: 1rem;
                font-size: fontSize(12);
            }
        }
        > div.right {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 .55rem;
            > div.meta {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: .5rem;
                > span.learning,
                > span.new {
                    font-size: fontSize(12);
                    line-height: pxToRem(20);
                    color: $colorSourceSecondary;
                }
                > span.new {
                    font-weight: $font-bold;
                    color: $colorSourcePrimary;
                    text-transform: uppercase;
                    background-color: #fff;
                    border-radius: pxToRem(4);
                    padding: pxToRem(2) pxToRem(4);
                    margin: 0 .25rem;
                }
                > svg.hover {
                    filter: grayscale(100%);
                }
            }
            > div.manage {
                display: flex;
                flex-direction: row;
                justify-content: center;
                color: $colorIncorrect;
                font-size: fontSize(12);
                line-height: pxToRem(20);
                gap: .5rem;
            }
        }
    }
</style>
