var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "terms-and-conditions" },
    [
      _vm.display_message
        ? _c("span", {
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(_vm.message_key, {
                  link_templates: _vm.terms_links_templates,
                })
              ),
            },
            on: { click: _vm.terms_click },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.document_open && _vm.document_open.content
        ? _c(
            "modal-popup",
            {
              attrs: { title: _vm.document_open.title, base_width: "40rem" },
              on: {
                close: function ($event) {
                  _vm.document_open = null
                },
              },
            },
            [
              _c("main", {
                staticClass: "document html",
                domProps: { innerHTML: _vm._s(_vm.document_open.content) },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }