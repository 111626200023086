<template>
    <section class="language-not-listed">
        <div class="thanks" v-if="eventSent">
            <thank-you-illustration />
            <p v-html="this.$i18n('feedback_doorslam_confirmation')" />
        </div>
        <template v-else>
            <h1 v-if="showTitle" v-html="this.$i18n('register_language_not_listed_title')" />
            <p v-html="this.$i18n('register_language_not_listed_description')" />
            <input-search-dropdown
                v-if="allLanguages"
                :options="allLanguages"
                :pre-selected-id="selectedTargetLanguageCode"
                v-on:selected="targetLangSelected"
                name="target_language"
                :maxItem="50"
                :placeholder="targetLangPlaceholder">
                <template v-slot:icon>
                    <language-unknown-icon />
                </template>
            </input-search-dropdown>
            <input-search-dropdown
                v-if="allLanguages"
                :options="allLanguages"
                v-on:selected="sourceLangSelected"
                name="source_language"
                :maxItem="50"
                :placeholder="sourceLangPlaceholder">
                <template v-slot:icon>
                    <language-unknown-icon />
                </template>
            </input-search-dropdown>
            <div class="input-container">
                <account-email-icon />
                <input
                    class="email"
                    :class="{ error: !this.emailIsValid }"
                    ref="email_input"
                    type="email"
                    v-model="email"
                    :placeholder="this.$i18n('register_language_not_listed_email_textbox')"
                    autocomplete="email" />
            </div>
            <p class="error" v-if="!this.emailIsValid" v-html="this.$i18n('register_error_invalid_email')" />
            <p class="footnote" v-html="this.$i18n('register_language_not_listed_email_description')" />
            <v-button :i18n="{ id: 'register_language_not_listed_send_btn' }" @click.native="sendRequest" :filled="true" :disabled="!fieldsAreFilled" color="primary" />
        </template>
    </section>
</template>

<script>
import AccountEmailIcon from 'Images/account/email.svg';
import LanguageUnknownIcon from 'Images/language-unknown-24x24.svg';
import ThankYouIllustration from 'Images/illustration_feedback_graditude_light.svg';
import i18nUtils from 'Util/i18n.js';
import inputSearchDropdown from 'ViewComponent/input-search-dropdown.vue';
import {EventBus} from "Util/vue-event-bus.js";
import LanguageList from "Util/language-list.js";
import VButton from "ViewComponent/v-button.vue";
import EvaluationUtils from "Util/evaluation.js";
import EventSender from "Modules/eventsender.js";
import { isEmpty } from "lodash";

export default {
        name: 'language-not-listed',
        components: {
            VButton,
            inputSearchDropdown,
            AccountEmailIcon,
            LanguageUnknownIcon,
            ThankYouIllustration
        },
        props: {
            selected_target_language: {
                type: [Object, null]
            },
            showTitle: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                eventSender: null,
                email: '',
                allLanguages: null,
                target_language: null,
                source_language: null,
                eventSent: false
            }
        },
        methods: {
            targetLangSelected(lang) {
                this.target_language = lang;
            },
            sourceLangSelected(lang) {
                this.source_language = lang;
            },
            setAllLanguages() {
                let lang = i18nUtils.currentInterfaceLanguage.toLowerCase();
                let languageNames = LanguageList.getLanguages(lang);
                let allLanguagesArray = [];
                for (const [key, value] of Object.entries(languageNames)) {
                    allLanguagesArray.push({ id: key, name: value });
                }
                this.allLanguages = allLanguagesArray;
            },
            async sendRequest() {
                const _email = (this.email) ? this.email : null;
                try {
                    await this.eventSender.sendLanguageRequestEvent(_email, this.target_language.name, this.target_language.id, this.source_language.name, this.source_language.id);
                    this.eventSent = true;
                    setTimeout( () => { this.$emit('back'); }, 1500);
                } catch (e) {
                    console.log('Error with sending language request', e);
                }
            }
        },
        computed: {
            selectedTargetLanguageCode() {
                if (this.selected_target_language && this.selected_target_language.code) {
                    return this.selected_target_language.code.replace(/-/g, "_");
                } else {
                    return null;
                }
            },
            sourceLangPlaceholder() {
                return i18nUtils.prop('register_language_not_listed_source_language_textbox')
            },
            targetLangPlaceholder() {
                return i18nUtils.prop('register_language_not_listed_target_language_textbox')
            },
            fieldsAreFilled() {
                return !isEmpty(this.target_language) && !isEmpty(this.source_language) && this.emailIsValid;
            },
            emailIsValid() {
                if (!this.email) {
                    return true;
                }

                try {
                    return EvaluationUtils.validateEmail(this.email);
                } catch (validation_error) {
                    return false;
                }
            },
        },
        async created () {
            this.eventSender = new EventSender(null);
        },
        mounted() {
            this.setAllLanguages();
            this.eventSent = false;
            EventBus.$on('interfaceLanguageChanged', this.setAllLanguages);
        },
        beforeDestroy() {
            EventBus.$off('interfaceLanguageChanged', this.setAllLanguages);
            this.eventSender.destroy();
        }
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/_mixins.scss";
    @import "~Styles/_colors.scss";
    @import "~Styles/font-weights.scss";

    section.language-not-listed {
        > h1 {
            font-size: 1.625rem;
            font-weight: 500;
            margin-bottom: 1rem;
            text-align: center;
        }

        > div.thanks {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            > svg {
                margin-bottom: .5rem;
            }
            > p {
                font-size: fontSize(18);
                font-weight: $font-bold;
            }
        }

        flex: 1 0 auto;

        > div.input-container {
            margin: 1rem 0;

            > svg {
                position: absolute;
                left: 0.125rem;
                top: calc(50% - 0.75rem);
                width: 1.5rem;
                body[data-interface-language="ar"] & {
                    left: unset;
                    right: 0.125rem;
                }
            }

            > input {
                width: 100%;
                font-size: 1rem;
                font-weight: $font-normal;
                height: 3rem;
                padding-left: 2rem;
                color: $colorSourcePrimary;

                body[data-interface-language="ar"] & {
                    padding-left: unset;
                    padding-right: 2rem;
                }

                border-bottom: 2px solid $colorSourceTertiary;

                &:focus {
                    border-bottom: 2px solid $colorSourcePrimary;
                }

                &.error {
                    border-bottom: 2px solid $colorIncorrect;
                }

                &.suggestion {
                    border-bottom: 2px solid $colorAttention;
                }

                &::placeholder {
                    font-size: 1rem;
                }
            }
        }

        > p {
            &.footnote,
            &.error {
                font-size: fontSize(12);
            }
            &.error {
                color: $colorIncorrect;
            }
            &.footnote {
                margin-top: 1rem;
                color: $colorSourceSecondary;
            }
        }

        > button {
            margin-top: 2rem;
            width: 100%;
        }

        .bounce-enter-active {
            animation: bounce-in .5s;
        }
        .bounce-leave-active {
            animation: bounce-in .5s reverse;
        }
        @keyframes bounce-in {
            0% {
                transform: scale(0);
            }
            50% {
                transform: scale(1.5);
            }
            100% {
                transform: scale(1);
            }
        }
    }
</style>
