var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "28",
          height: "28",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      _vm.$listeners
    ),
    [
      _c("defs", [
        _c("path", { attrs: { id: "more-circle_svg__a", d: "M0 0h20v20H0z" } }),
      ]),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("circle", {
          attrs: { fill: "#F5F5F5", cx: "14", cy: "14", r: "14" },
        }),
        _c("g", { attrs: { opacity: ".3", transform: "rotate(90 10 14)" } }, [
          _c("mask", { attrs: { id: "more-circle_svg__b", fill: "#fff" } }, [
            _c("use", { attrs: { "xlink:href": "#more-circle_svg__a" } }),
          ]),
          _c("path", {
            attrs: {
              d: "M1 10.077a2.077 2.077 0 114.154 0 2.077 2.077 0 01-4.154 0zm6.923 0a2.077 2.077 0 114.154 0 2.077 2.077 0 01-4.154 0zm6.923 0a2.077 2.077 0 114.154 0 2.077 2.077 0 01-4.154 0z",
              fill: "#2C3143",
              mask: "url(#more-circle_svg__b)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }