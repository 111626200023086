var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "confirmation-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "confirmation-modal-window" }, [
        _c(
          "header",
          [
            _c("h2", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _vm.closable
              ? _c("CloseButton", {
                  staticClass: "close",
                  on: { click: _vm.close },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("main", [
          _c("div", {
            staticClass: "text",
            domProps: { innerHTML: _vm._s(_vm.description) },
          }),
          _vm._v(" "),
          _vm.actions
            ? _c(
                "div",
                { staticClass: "actions" },
                _vm._l(this.actions, function (action) {
                  return _c("v-button", {
                    key: action.action,
                    attrs: {
                      text: action.text,
                      filled: !!action.primary,
                      size: "medium",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.emitAction(action.action)
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }