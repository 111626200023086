
import dust from "dustjs-linkedin";

import "template/grammar-tips/no-favorites.js";
import "template/grammar-tips/hint.js";
import "template/grammar-tips/hint.js";
import "template/grammar-tips/no-results.js";
import "template/grammar-tips/hint.js";
import "template/grammar-tips/hint.js";

(function(){dust.register("grammar-tips/content",body_0);function body_0(chk,ctx){return chk.exists(ctx.get(["inFavorites"], false),ctx,{"else":body_1,"block":body_10},{});}function body_1(chk,ctx){return chk.helper("eq",ctx,{"else":body_2,"block":body_7},{"key":0,"value":ctx.getPath(false, ["grammarTips","length"])});}function body_2(chk,ctx){return chk.section(ctx.get(["grammarTips"], false),ctx,{"block":body_3},{});}function body_3(chk,ctx){return chk.exists(ctx.get(["inFavorites"], false),ctx,{"else":body_4,"block":body_5},{});}function body_4(chk,ctx){return chk.partial("grammar-tips/hint",ctx,{});}function body_5(chk,ctx){return chk.exists(ctx.get(["favorited"], false),ctx,{"block":body_6},{});}function body_6(chk,ctx){return chk.partial("grammar-tips/hint",ctx,{});}function body_7(chk,ctx){return chk.exists(ctx.get(["searchQuery"], false),ctx,{"else":body_8,"block":body_9},{});}function body_8(chk,ctx){return chk;}function body_9(chk,ctx){return chk.partial("grammar-tips/no-results",ctx,{});}function body_10(chk,ctx){return chk.helper("eq",ctx,{"else":body_11,"block":body_16},{"key":0,"value":ctx.get(["favorites"], false)});}function body_11(chk,ctx){return chk.section(ctx.get(["grammarTips"], false),ctx,{"block":body_12},{});}function body_12(chk,ctx){return chk.exists(ctx.get(["inFavorites"], false),ctx,{"else":body_13,"block":body_14},{});}function body_13(chk,ctx){return chk.partial("grammar-tips/hint",ctx,{});}function body_14(chk,ctx){return chk.exists(ctx.get(["favorited"], false),ctx,{"block":body_15},{});}function body_15(chk,ctx){return chk.partial("grammar-tips/hint",ctx,{});}function body_16(chk,ctx){return chk.partial("grammar-tips/no-favorites",ctx,{});}return body_0;})();

export default 0;
