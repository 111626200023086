var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "have-a-voucher" },
    [
      _c("voucher-illustration"),
      _vm._v(" "),
      _c("div", {
        staticClass: "text",
        domProps: {
          innerHTML: _vm._s(this.$i18n("subscription_page_voucher_title")),
        },
      }),
      _vm._v(" "),
      _c("v-button", {
        attrs: {
          i18n: { id: "subscription_page_voucher_btn" },
          filled: true,
          color: "primary",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.navigateToVoucherPage.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }