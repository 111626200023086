<template>
    <main class="insights">
        <calendar v-if="page === 'calendar'" />
        <wordlist v-else-if="page === 'wordlist'" />
        <vocabulary v-else-if="page === 'vocabulary'" />
    </main>
</template>

<script>
    import Calendar from "./calendar/main.vue";
    import Wordlist from "./wordlist/main.vue";
    import Vocabulary from "./vocabulary/main.vue";
    import VButton from "ViewComponent/v-button.vue";
    import Backbone from "backbone";
    import UserManager from "Modules/usermanager.js";

    export default {
        name: 'insights',
        components: {
            Vocabulary,
            Wordlist,
            VButton,
            Calendar,
        },
        props: {
            pageName: {
                type: String,
                default: 'wordlist'
            },
        },
        data() {
            return {
                pages: ['calendar', 'wordlist', 'vocabulary'],
                page: null
            }
        },
        methods: {
            setPage(name) {
                if (this.pages.includes(name)) {
                    this.page = name;
                    Backbone.history.navigate(`insights/${name}`, { trigger: false });
                    UserManager.instance.getUser().getEventSender().sendNavigationEvent('insights', 'navigate', name);
                }
            }
        },
        created() {
            if (this.pageName) {
                this.setPage(this.pageName);
            } else if (this.pages && this.pages.length > 0) {
                this.setPage(this.pages[0]);
            }
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';
    $insights-max-width: pxToRem(1200);
    main.insights {
        position: unset;
        margin: 2rem auto;
        max-width: $insights-max-width;
        width: 100%;
        padding: 0 3rem;

        @include respond-to('medium') {
            padding: 0 1.5rem;
            margin: 1.5rem 0;
            width: auto;
        }
        > header {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin-bottom: 1rem;
        }
    }
</style>
