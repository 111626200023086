<template>
    <main :key="this.$i18nLanguageChanged">
        <nav>
            <arrow-card
                class="navigate-back"
                v-if="step !== consts.STEP.TARGET_LANGUAGE && step !== consts.STEP.OAUTH_EMAIL && step !== consts.STEP.QUESTIONNAIRE"
                @click.native="navigate_back" />
        </nav>

        <select-target-language
            :courses="courses"
            :interface_language="interface_language"

            class="main-content"
            v-if="step === consts.STEP.TARGET_LANGUAGE"
            @language_selected="select_target_language"
            @language_not_listed="language_not_listed"
        />

        <select-course
            :courses="courses"
            :selected_target_language="selected_target_language"

            class="main-content"
            v-if="step === consts.STEP.SOURCE_LANGUAGE"
            @selected="select_course"
            @language_not_listed="language_not_listed"
        />
        <demographic-questionnaire
            :selected_target_language="selected_target_language"
            v-if="step === consts.STEP.QUESTIONNAIRE"
            @final_answer_given="navigate_conditionally"
        />

        <account-creation-options
            :selected_course="selected_course"
            :single_available_target_language_course="is_single_available_target_language_course"
            :marketing_opt_in="marketing_opt_in"

            class="main-content"
            v-if="step === consts.STEP.ACCOUNT_CREATION_OPTIONS"
            @create_account="select_account_creation_option"
            @marketing_opt_in_toggled="marketing_opt_in = arguments[0];"
        />

        <credentials-email
            :selected_course="selected_course"
            :single_available_target_language_course="is_single_available_target_language_course"
            :name="name"
            :email="email"

            class="main-content"
            v-if="step === consts.STEP.CREDENTIALS_EMAIL"
            @name_updated="name = arguments[0]"
            @email_updated="email_updated"
            @verified_submit="submit_email_credentials"
        />

        <oauth-email
            :selected_course="selected_course"
            :single_available_target_language_course="is_single_available_target_language_course"
            :oauth_input="this.oauth_input"
            :email_in_use="email_in_use"

            class="main-content"
            v-if="step === consts.STEP.OAUTH_EMAIL"
            @email_updated="email_updated"
            @verified_submit="complete_oauth_with_email"
        />

        <has-account-ask
            v-if="step === consts.STEP.HAS_ACCOUNT_ASK"
            :email="this.email"
            :oauth_input="this.oauth_input"
            @register-new-account="set_has_account_ask_result(false)"
        />

        <language-not-listed
            class="main-content"
            v-if="step === consts.STEP.LANGUAGE_NOT_LISTED"
            :selected_target_language="selected_target_language"
            @back="navigate_back"
        />

        <modal-popup
            v-if="referral_cw && !referral_cw_shown"
            :title="this.$i18n('course_wizard_course_share_invitation_title')"
            :closable="true"
            base_width="23rem"
            @close="referral_cw_shown = true">
            <main class="referral-cw">
                <register-referral-illustration />
                <p v-html="this.$i18n('course_wizard_course_share_invitation_message_1')"></p>
                <p v-html="this.$i18n('course_wizard_course_share_invitation_message_2')"></p>

                <footer class="buttons">
                    <button
                        v-html="this.$i18n('course_wizard_course_share_invitation_sign_up')"
                        @click="referral_cw_shown = true"
                    />
                </footer>
            </main>
        </modal-popup>

        <course-loading
            v-if="step === consts.STEP.LOADING"
            :source_icon_id="selected_course.info.source_icon_id"
            :target_icon_id="selected_course.info.target_icon_id"
        />
    </main>
</template>

<script>

import Backbone from 'backbone';
import Raven from 'raven-js';

import { oauth_authorize } from 'Modules/third_party_signin/index.js';

import UserModel from 'Model/user.model.js';
import { EventBus } from 'Util/vue-event-bus.js';
import i18nUtils from 'Util/i18n.js';
import { TYPE as TOAST_TYPE } from '../toaster/constants.js';
import { sleep } from 'Util/delay.js';

import ModalPopup from '../component/modal-popup.vue';
import CourseLoading from '../component/course-loading.vue';

import SelectTargetLanguage from './components/select-target-language.vue';
import SelectCourse from './components/select-course.vue';
import DemographicQuestionnaire from './components/demographic-questionnaire.vue';
import AccountCreationOptions from './components/account-creation-options.vue';
import CredentialsEmail from './components/credentials-email.vue';
import OauthEmail from './components/oauth-email.vue';
import HasAccountAsk from './components/has-account-ask.vue';
import Terms from '../component/terms.vue';
import EventSender from "Modules/eventsender.js";

import ArrowCard from 'Images/arrow-card.svg'
import RegisterReferralIllustration from 'Images/register/referral.svg';
import LanguageNotListed from "ViewComponent/language-not-listed.vue";

const UTM_PARAMETERS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export default {
    name: 'register.main',
    components: {
        LanguageNotListed,
        ModalPopup,
        CourseLoading,
        ArrowCard,
        SelectTargetLanguage,
        SelectCourse,
        DemographicQuestionnaire,
        AccountCreationOptions,
        CredentialsEmail,
        OauthEmail,
        Terms,
        RegisterReferralIllustration,
        HasAccountAsk
    },
    props: {
        courses: {
            type: Array,  // Array<Course>
            required: true
        },
        interface_language: {
            type: String,
            required: true
        },
        query: {
            type: Object,
            required: true
        },
        _client: {
            required: true
        }
    },
    beforeDestroy() {
        this.$emit('remove');
        this.eventSender.destroy();
    },
    async created () {
        await this.apply_query_parameters(this.query);
        this.eventSender = new EventSender(null);
    },
    data () {
        const STEP = Object.freeze({
            TARGET_LANGUAGE: 'target',
            SOURCE_LANGUAGE: 'source',
            QUESTIONNAIRE: 'questionnaire',
            ACCOUNT_CREATION_OPTIONS: 'creation_options',
            CREDENTIALS_EMAIL: 'email_credentials',
            OAUTH_EMAIL: 'oauth_email',
            LOADING: 'loading',
            HAS_ACCOUNT_ASK: 'has_account_ask',
            LANGUAGE_NOT_LISTED: 'language_not_listed',
        });
        return {
            consts: {
                STEP,
                MAX_STEP: 3
            },
            step: STEP.TARGET_LANGUAGE,
            selected_target_language: null,
            selected_course: null,
            marketing_opt_in: false,
            registration_data: null,
            oauth_input: {
                provider: null,
                token: null,
                email_required: false,
                name: null,
            },
            name: null,
            email: null,
            has_account_ask_result: null,

            // Query pass-through parameters
            role: null,
            experiment_overrides: null,
            tracking_parameters: null,
            referral_cw: false,
            referral_cw_shown: false,
            email_in_use: false,
            eventSender: null
        };
    },
    computed: {
        is_single_available_target_language_course () {
            if (this.selected_target_language) {
                return this.selected_target_language &&
                       this.courses
                           .filter(c => {
                               let target_language = c.getInfo().target_language;
                               // Special mapping for unified en language
                               if (this.selected_target_language.code === 'en') {
                                   return target_language === 'en-US' || target_language === 'en-GB';
                               } else {
                                   return target_language === this.selected_target_language.code;
                               }
                           })
                           .length === 1;
            } else {
                return false;
            }
        }
    },
    methods: {
        async apply_query_parameters (query) {
            if (Object.keys(query).length > 0) {
                this.email = query.email || this.email;
                this.role = query.role || this.role;

                this.oauth_input.provider = query.oauth_provider || this.oauth_input.provider;
                this.oauth_input.token = query.oauth_token || this.oauth_input.token;
                this.oauth_input.email_required = query.oauth_email_required || this.oauth_input.email_required;
                this.oauth_input.name = query.oauth_name || this.oauth_input.name;

                if (query.experiment_overrides) {
                    this.experiment_overrides = JSON.parse(query.experiment_overrides);
                }

                if (query.course_uuid) {
                    this.selected_course = this.courses.find(c => c.UUID === query.course_uuid) || this.selected_course;
                }

                this.tracking_parameters = Object.keys(query)
                    .filter(p => UTM_PARAMETERS.includes(p))
                    .reduce((result, p) => {result[p] = query[p]; return result;}, {});

                this.referral_cw = query.referral_cw !== undefined ? true : this.referral_cw;

                await this.navigate_conditionally();
            }
        },

        async navigate_conditionally () {
            if (this.selected_course) {
                if (this.oauth_input.token && this.oauth_input.provider) {
                    if (this.oauth_input.email_required) {
                        this.navigate(this.consts.STEP.OAUTH_EMAIL);
                    } else {
                        this.complete_oauth_with_email();  // no await; return here on purpose
                    }
                } else {
                    this.navigate(this.consts.STEP.ACCOUNT_CREATION_OPTIONS);
                }
            } else {
                if (this.oauth_input.token && this.oauth_input.provider && this.oauth_input.email_required && this.has_account_ask_result === null) {
                    this.navigate(this.consts.STEP.HAS_ACCOUNT_ASK);
                } else {
                    this.navigate(this.consts.STEP.TARGET_LANGUAGE);
                }
            }
        },

        navigate(step) {
            if (step === this.consts.STEP.ACCOUNT_CREATION_OPTIONS && !this.selected_course) {
                step = this.consts.STEP.TARGET_LANGUAGE;
            }
            this.step = !Object.values(this.consts.STEP).includes(step) ? this.consts.STEP.TARGET_LANGUAGE : step;
            Backbone.trigger('hideLoader');
        },

        navigate_back () {
            this.$emit('navigate_back');
        },

        set_has_account_ask_result (result) {
            this.has_account_ask_result = result;
            this.navigate_conditionally();
        },

        /**
         * @param language<Language>
         */
        async select_target_language (language) {
            this.selected_target_language = language;
            this.$emit('navigate', this.consts.STEP.SOURCE_LANGUAGE);
        },

        async select_course (uuid) {
            this.selected_course = this.courses.find(c => c.UUID === uuid);
            await i18nUtils.setInterfaceLanguage(this.selected_course.getInfo().source_language);
            this.$emit('navigate', this.consts.STEP.QUESTIONNAIRE);
        },

        _toast_error (message_key, toast_type = TOAST_TYPE.ERROR) {
            EventBus.$emit('toaster-add', {
                text: i18nUtils.prop(message_key),
                type: toast_type
            });
        },

        display_error (error) {
            switch(error) {
                case 'duplicate-email': {
                    if (this.email) {
                        this.email_in_use = true;
                    }
                    return this._toast_error('register_error_duplicate_email');
                }

                case 'deletion-in-progress': {
                    return this._toast_error('register_error_deletion_in_progress');
                }

                case 'error-network': {
                    return this._toast_error('register_error_network');
                }

                case 'authorization-failed': {
                    return this._toast_error('register_error_authorization_failed');
                }

                case 'closed': {
                    return this._toast_error('register_error_oauth_popup_closed', TOAST_TYPE.ALERT);
                }

                default: {
                    Raven.captureException(error, {level: 'error'});
                    return this._toast_error('register_error_server');
                }
            }
        },

        async select_account_creation_option (option) {
            await this.eventSender.sendNavigationEvent('signup', 'click', option);
            if (option === 'email') {
                this.$emit('navigate', this.consts.STEP.CREDENTIALS_EMAIL);
            } else { // If not "email" then option must be an OAuth provider
                Backbone.trigger('showLoader');

                try {
                    const { provider, code, user, redirect_uri, full_redirect } =
                        await oauth_authorize(option, null);
                    let unauthenticatedUserUUID = await this._client.getUnauthenticatedUserUUID();
                    // Giving up control of flow to UserModel
                    if (!full_redirect) {
                        await UserModel.register_oauth(
                            provider,
                            code,
                            redirect_uri,
                            unauthenticatedUserUUID,
                            this.selected_course.UUID,
                            this.marketing_opt_in,
                            this.role,
                            this.experiment_overrides,
                            this.tracking_parameters,
                        );
                    }
                } catch (error) {
                    console.error(`Register.select_account_creation_option error:`, error);
                    this.display_error(error);
                    Backbone.trigger('hideLoader');
                } finally {
                    Backbone.trigger('hideLoader');
                }
            }
        },

        async submit_email_credentials (password) {
            try {
                Backbone.trigger('showLoader');
                let unauthenticatedUserUUID = await this._client.getUnauthenticatedUserUUID();

                const response = await UserModel.register(
                    'password',
                    unauthenticatedUserUUID,
                    this.name,
                    this.email,
                    password,
                    this.marketing_opt_in,
                    this.role,
                    this.experiment_overrides,
                    this.tracking_parameters,
                );
                await this._client.removeUnauthenticatedUserUUID(); // clean up the user uuid from localstorage to prevent other users trying to register with the same uuid

                this.step = this.consts.STEP.LOADING;
                Backbone.trigger('hideLoader');

                const [ delayed_registration ] = await Promise.all([UserModel.complete_registration(response, this.selected_course.UUID, null), sleep(4000)]);
                delayed_registration();

            } catch (error) {
                console.error('register.submit_email_credentials error=', error);
                Backbone.trigger('hideLoader');
                this.display_error(error);
            }
        },

        async complete_oauth_with_email () {
            try {
                Backbone.trigger('showLoader');
                let unauthenticatedUserUUID = await this._client.getUnauthenticatedUserUUID();
                const response = await UserModel.register(
                    'token',
                    unauthenticatedUserUUID,
                    this.oauth_input.name,
                    this.email,
                    this.oauth_input.token,
                    this.marketing_opt_in,
                    this.role,
                    this.experiment_overrides,
                    this.tracking_parameters,
                );

                this.step = this.consts.STEP.LOADING;
                Backbone.trigger('hideLoader');
                const [ delayed_registration ] = await Promise.all([UserModel.complete_registration(response, this.selected_course.UUID, this.oauth_input.provider), sleep(4000)]);
                delayed_registration();
                await this._client.removeUnauthenticatedUserUUID(); // clean up the user uuid from localstorage to prevent other users trying to register with the same uuid

            } catch (error) {
                console.error('register.complete_oauth_with_email error=', error);
                Backbone.trigger('hideLoader');
                this.display_error(error);
            }
        },
        email_updated (email) {
            this.email_in_use = false;
            this.email = email;
        },
        language_not_listed () {
            this.$emit('navigate', this.consts.STEP.LANGUAGE_NOT_LISTED);
        }
    },
    watch: {
        step(newStep, previousStep) {
            if (newStep === this.consts.STEP.TARGET_LANGUAGE) {
                this.selected_target_language = null;
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import '~Styles/colors';

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
    padding-top: 0.5rem;

    > nav, header, .main-content {
        width: 22.5rem;
        max-width: calc(100% - 1rem);
    }

    > nav {
        > svg {
            position: absolute;
            top: 20rem;
            left: -3.5rem;

            height: 3rem;
            width: 2rem;
            transform: rotate(180deg) translateY(-50%);
            transition: transform 0.2s;

            cursor: pointer;

            body[data-interface-language="ar"] & {
                left: unset;
                right: -3.5rem;
                transform: rotate(0) translateY(-50%);
            }

            &:hover {
                transform: rotate(180deg) translateY(-50%) translateX(3px);
            }

            &:active {
                transform: rotate(180deg) translateY(-50%) translateX(5px);
            }
        }
    }

    main.cookie-consent {
        padding: 0;

        > span.terms-and-conditions {
            font-size: 1rem;
            margin: 0;
            padding: 0 1.5rem;
            text-align: left;
        }

        > footer {
            width: 100%;
            padding: 1.5rem 1.5rem 0;

            > button {
                width: 100%;
            }
        }
    }

    main.referral-cw {
        padding: 0;

        > svg {
            margin-bottom: 1rem;
        }

        > p {
            padding: 0 1.5rem;
            margin-top: 1em;
        }

        > footer {
            width: 100%;
            padding: 1.5rem 1.5rem 0;
            margin-top: 1rem;

            > button {
                width: 100%;
            }
        }
    }
}
</style>
