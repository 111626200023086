<template>
    <div class="misc-debug">
        <h2>Misc helpers</h2>
        <div class="actions">
            <v-button text="Relevant Words Graph" @click.native="goToRelevantWords" size="medium" />
            <v-button text="Open New course dialog" @click.native="openNewCourseDialog" size="medium" />
            <v-button text="Show FR dialog" @click.native="openFTDialog" size="medium" />
            <v-button text="Show NO FT dialog" @click.native="openNoFTDialog" size="medium" />
            <v-button text="Show FT complete dialog" @click.native="openFTEndDialog" size="medium" />
            <v-button text="Open Cookie Consent" @click.native="openCookieConsent" size="medium" />
        </div>
    </div>
</template>

<script>
    import VButton from "ViewComponent/v-button.vue";
    import Backbone from "backbone";
    import { EventBus } from "Util/vue-event-bus.js";
    import ControllerManager from "Modules/controller.manager";

    export default {
        name: 'tab-misc',
        components: { VButton },
        methods: {
            goToRelevantWords() {
                Backbone.history.navigate('relevant-words', { trigger: true });
                EventBus.$emit('debug:close');
            },
            openNewCourseDialog() {
                EventBus.$emit('debug:close');
                ControllerManager.instance.getController('ModalMessages').showShortCourseHub("XYZ", 1234);
            },
            openFTDialog() {
                EventBus.$emit('debug:close');
                ControllerManager.instance.getController('Onboarding').showFastTrackingIntro();
            },
            openFTEndDialog() {
                EventBus.$emit('debug:close');
                ControllerManager.instance.getController('Onboarding').showFastTrackingComplete();
            },
            openNoFTDialog() {
                EventBus.$emit('debug:close');
                ControllerManager.instance.getController('Onboarding').showNoFastTracking();
            },
            openCookieConsent() {
                EventBus.$emit('cookie:show-consent');
                EventBus.$emit('debug:close');
            }
        },
    }
</script>

<style lang="scss" scoped>
    div.misc-debug {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        div.actions {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
</style>
