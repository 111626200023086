var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.course && _vm.dateContext && _vm.daysInMonthWithData
    ? _c("div", { staticClass: "insights-calendar" }, [
        _c("div", { staticClass: "month" }, [
          _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "date-grid" },
            _vm._l(_vm.daysInMonthWithData, function (day, index) {
              return _c(
                "div",
                {
                  staticClass: "day",
                  class: { today: day.isToday, disabled: day.isFuture },
                  style: {
                    gridColumn: index === 0 ? _vm.firstDayOfMonth : "auto",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dayClicked(day)
                    },
                  },
                },
                [
                  _c("goal-status-icon", {
                    attrs: {
                      size: 24,
                      status: day.goalStatus,
                      "on-gray": true,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(day.number)),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }