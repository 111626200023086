<template>
    <span class="speaker-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="inProgress">
            <path d="M3 16V8H7L12 3V7V12.5V21L7 16H4H3Z" fill="#2C3143"/>
            <path d="M3 8V7H2V8H3ZM3 16H2V17H3V16ZM12 3H13C13 2.59554 12.7564 2.2309 12.3827 2.07612C12.009 1.92134 11.5789 2.0069 11.2929 2.29289L12 3ZM7 8V9C7.26522 9 7.51957 8.89464 7.70711 8.70711L7 8ZM12 21L11.2929 21.7071C11.5789 21.9931 12.009 22.0787 12.3827 21.9239C12.7564 21.7691 13 21.4045 13 21H12ZM7 16L7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15V16ZM2 8V16H4V8H2ZM11.2929 2.29289L6.29289 7.29289L7.70711 8.70711L12.7071 3.70711L11.2929 2.29289ZM7 7H3V9H7V7ZM13 7V3H11V7H13ZM3 17H4V15H3V17ZM13 21V12.5H11V21H13ZM11 7V12.5H13V7H11ZM4 17H7V15H4V17ZM6.29289 16.7071L11.2929 21.7071L12.7071 20.2929L7.70711 15.2929L6.29289 16.7071Z" fill="#2C3143"/>
            <path d="M16 9C16.6303 9.85037 17 10.8846 17 12C17 13.1154 16.6303 14.1496 16 15" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            <path d="M19 6C20.2497 7.63205 21 9.72191 21 12C21 14.2781 20.2497 16.3679 19 18" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
            <path d="M3 8V7H2V8H3ZM3 15C2.44772 15 2 15.4477 2 16C2 16.5523 2.44772 17 3 17V15ZM12 21L11.2929 21.7071C11.5789 21.9931 12.009 22.0787 12.3827 21.9239C12.7564 21.7691 13 21.4045 13 21H12ZM12 3H13C13 2.59554 12.7564 2.2309 12.3827 2.07612C12.009 1.92134 11.5789 2.0069 11.2929 2.29289L12 3ZM7 8V9C7.26522 9 7.51957 8.89464 7.70711 8.70711L7 8ZM7 16L7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15V16ZM2 11C2 11.5523 2.44772 12 3 12C3.55228 12 4 11.5523 4 11H2ZM11.2929 2.29289L6.29289 7.29289L7.70711 8.70711L12.7071 3.70711L11.2929 2.29289ZM7 7H3V9H7V7ZM13 7V3H11V7H13ZM3 17H4V15H3V17ZM13 21V12.5H11V21H13ZM11 7V12.5H13V7H11ZM6.29289 16.7071L11.2929 21.7071L12.7071 20.2929L7.70711 15.2929L6.29289 16.7071ZM4 17H5.5V15H4V17ZM5.5 17H7V15H5.5V17ZM2 8V11H4V8H2Z" fill="#2C3143"/>
            <path d="M16 9C16.6303 9.85037 17 10.8846 17 12C17 13.1154 16.6303 14.1496 16 15" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            <path d="M19 6C20.2497 7.63205 21 9.72191 21 12C21 14.2781 20.2497 16.3679 19 18" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </span>
</template>

<script>
    export default {
        name: 'speaker-button',
        props: {
            inProgress: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style lang="scss">
    span.speaker-button {
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            opacity: .8;
            cursor: pointer;
        }
    }
</style>
