var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "utility-belt",
      class: { disabled: this.disabled, locked: this.locked },
    },
    [
      _c("set-progress"),
      _vm._v(" "),
      _vm.question.diacritics && _vm.diacriticsOpen
        ? _c("diacritics", {
            attrs: {
              diacritics: _vm.question.diacritics,
              target_language: _vm.question.target_language,
            },
            on: { close: _vm.closeDiacritics },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _vm.answerState
            ? _c("tick-correct", {
                staticClass: "tick-correct",
                attrs: { "answer-state": _vm.answerState },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.previousCardButtonVisible
            ? _c("icon-button", {
                attrs: { disabled: this.audioIsPlaying, "icon-name": "back" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.previousCard.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.on_previous
            ? _c("icon-button", {
                attrs: { "icon-name": "forward" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.currentCard.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.cardHasVisuals && _vm.cardVisualData && _vm.cardVisualData.enabled
            ? _c("icon-button", {
                attrs: { "icon-name": "visuals", active: _vm.visuals },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleVisuals.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.audioButtonVisible
            ? _c("icon-button", {
                attrs: { "icon-name": "speaker", active: this.audioIsPlaying },
                nativeOn: {
                  click: function ($event) {
                    return _vm.playAudio.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.grammarButtonVisible
            ? _c("icon-button", {
                attrs: {
                  "icon-name": "tutor",
                  active: _vm.tutorOpen,
                  notify: this.notifyGrammarButton,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleTutor.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.on_previous && _vm.question.diacritics
            ? _c("icon-button", {
                attrs: {
                  "icon-name": _vm.diacriticsIconName,
                  id: "diacriticsButton",
                  active: _vm.diacriticsOpen,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleDiacritics.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tipButtonVisible
            ? _c("icon-button", {
                attrs: { "icon-name": "tip", notify: this.notifyTipButton },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openFormSpellings.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.on_previous
            ? _c("voice-input", {
                attrs: {
                  question: this.question,
                  course: this.course,
                  id: "utility-belt-voice-button",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.nextButtonVisible
            ? _c("v-button", {
                attrs: {
                  disabled: this.audioIsPlaying,
                  i18n: { id: "learn_btn_next" },
                  size: "medium",
                  filled: true,
                  color: "primary",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.nextCard.apply(null, arguments)
                  },
                },
              })
            : !this.on_previous
            ? _c("v-button", {
                attrs: {
                  disabled: this.audioIsPlaying,
                  text: this.submitButtonText,
                  size: "medium",
                  filled: true,
                  color: this.submitButtonColor,
                  id: "utility-belt-submit-button",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.submit.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }