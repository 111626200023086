var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "68",
          height: "68",
          viewBox: "0 0 68 68",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          attrs: {
            filter: "url(#arrow-down-purple_svg__filter0_d_4314_172242)",
          },
        },
        [
          _c("circle", {
            attrs: { cx: "34", cy: "29.898", r: "19.5", fill: "#E2B4FF" },
          }),
        ]
      ),
      _c("path", {
        attrs: {
          d: "M40.005 27a1.1 1.1 0 111.555 1.556L40.005 27zM34 34.56l.778.778-.778.778-.778-.778.778-.778zm-7.56-6.004A1.1 1.1 0 0127.994 27l-1.556 1.556zm15.12 0l-6.782 6.782-1.556-1.555L40.005 27l1.555 1.556zm-8.338 6.782l-6.783-6.782L27.995 27l6.783 6.783-1.556 1.555z",
          fill: "#2C3143",
        },
      }),
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              id: "arrow-down-purple_svg__filter0_d_4314_172242",
              x: ".5",
              y: ".398",
              width: "67",
              height: "67",
              filterUnits: "userSpaceOnUse",
              "color-interpolation-filters": "sRGB",
            },
          },
          [
            _c("feFlood", {
              attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
            }),
            _c("feColorMatrix", {
              attrs: {
                in: "SourceAlpha",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                result: "hardAlpha",
              },
            }),
            _c("feOffset", { attrs: { dy: "4" } }),
            _c("feGaussianBlur", { attrs: { stdDeviation: "7" } }),
            _c("feColorMatrix", {
              attrs: { values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" },
            }),
            _c("feBlend", {
              attrs: {
                in2: "BackgroundImageFix",
                result: "effect1_dropShadow_4314_172242",
              },
            }),
            _c("feBlend", {
              attrs: {
                in: "SourceGraphic",
                in2: "effect1_dropShadow_4314_172242",
                result: "shape",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }