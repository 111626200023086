<template>
    <div class="grid-item" :data-type="variation.type" :data-status="variation.status" :data-icon="variation.icon">
        <div class="header">
            <variation-icon @click.native="openVariationModal(variation)" :variation="variation" />
            <more-dropdown v-if="isSubscriptionActive" :variation="variation" :variation-toggle-disabled="variation.status === 'unfinished'" />
        </div>
        <div class="info" @click="openVariationModal(variation)">
            <div class="name">{{ variation.name }}</div>
            <div class="desc" v-if="variation.description">{{ variation.description }}</div>
        </div>
        <div class="footer">
            <div class="statuses">
                <template v-if="!isSubscriptionActive && !variation.active && variation.paid">
                    <status-icon type="locked" tooltip="disabled" />
                </template>
                <template v-if="variation.status === 'unfinished'">
                    <status-icon type="unfinished" tooltip="unfinished" />
                </template>
                <template v-else>
                    <status-icon v-if="!variation.active && variation.status !== 'initial' && variation.status !== 'subscription_limited' " type="disabled" tooltip="disabled" />
                </template>
                <status-icon type="complete" v-if="variation.status === 'complete'" tooltip="complete" />
                <difficulty v-if="variation.difficulty" :difficulty="variation.difficulty" />
            </div>
            <div class="stats" v-if="variation.stats">
                <progress-bar :primary="(variation.stats.user_remembered_words / variation.units) * 100"
                             :secondary="(variation.stats.user_total_words / variation.units) * 100" />
            </div>
        </div>
    </div>
</template>
<script>
    import { EventBus } from 'Util/vue-event-bus';
    import MoreDropdown from './more-dropdown.vue';
    import ProgressBar from './progress-bar.vue';
    import StatusIcon from './status-icon.vue';
    import VariationIcon from 'ViewComponent/variation-icon.vue';
    import Difficulty from "./difficulty.vue";

    export default {
        name: 'variation-grid-item',
        components: {
            Difficulty,
            MoreDropdown,
            ProgressBar,
            StatusIcon,
            VariationIcon
        },
        props: {
            variation: {
                type: Object,
                required: true
            },
            isSubscriptionActive: {
                type: Boolean
            },
        },
        methods: {
            openVariationModal(variation) {
                if (variation.type === 'lesson' && variation.status === 'unfinished') {
                    return;
                }
                EventBus.$emit('open-variation-modal', variation);
            }
        },
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.variations-grid {
        > div.grid-item {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            user-select: none;
            > div.header {
                flex: 1;
                min-height: pxToRem(76);
                max-height: pxToRem(76);
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top-left-radius: pxToRem(12);
                border-top-right-radius: pxToRem(12);
                background-color: $colorBackgroundDefault;
                padding: pxToRem(16);
                > div.variation-icon {
                    cursor: pointer;
                }
            }
            &[data-status='unfinished'] {
                > div.header {
                    background-color: $colorBackgroundDefault;
                    > div.variation-icon {
                        cursor: default;
                    }
                }
                > div.info {
                    cursor: default;
                }
            }

            > div.info {
                flex: 2;
                padding: pxToRem(16);
                cursor: pointer;
                @include respond-to('small') {
                    padding: pxToRem(8) pxToRem(16);
                }
                > div.name {
                    font-weight: $font-bold;
                    font-size: fontSize(18);
                    margin-bottom: pxToRem(8);
                }
                > div.desc {
                    font-size: fontSize(14);
                }
            }

            > div.footer {
                display: flex;
                flex-direction: column;
                margin: pxToRem(16);
                > div.statuses {
                    display: flex;
                    > div.status-icon {
                        margin-left: pxToRem(8);
                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    > div.difficulty {
                        margin-left: auto;
                    }
                }
                > div.stats {
                    margin-top: pxToRem(16);
                }
            }
        }
    }
</style>
