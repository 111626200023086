<template>
    <div class="setting-toggle">
        <div class="setting">
            <div class="text">
                <div class="label" v-html="this.$i18n(this.title)" />
                <div class="footnote" v-if="this.footnote" v-html="this.$i18n(this.footnote)" />
            </div>
            <toggle-button v-model="settingValue"
                           :key="`${settingParameter}:toggle`"
                           :sync="true"
                           :color="{ checked: '#2C3143' }"
                           :width="70"
                           :height="24" />
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import ToggleButton from "vue-js-toggle-button";
    import UserManager from "Modules/usermanager.js";
    import { TYPE } from "Modules/user.parameters.js";
    import { EventBus } from 'Util/vue-event-bus';

    Vue.use(ToggleButton);

    export default {
        name: 'setting-toggle',
        props: {
            settingParameter: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            footnote: {
                type: String
            },
            notSetDefaultValue: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                settingValue: null
            }
        },
        methods: {
            getAndSetSetting() {
                const userParameters = UserManager.instance.getUser().getParameters();
                let p = userParameters.getParameter(this.settingParameter);
                this.settingValue = (p === null) ? this.notSetDefaultValue : p;
            },
            setSetting(value) {
                const userParameters = UserManager.instance.getUser().getParameters();
                userParameters.setParameter(this.settingParameter, value, TYPE.BOOLEAN);
                EventBus.$emit(`settings:${ this.settingParameter }`, value);
            }
        },
        mounted() {
            this.getAndSetSetting();
        },
        watch: {
            settingValue(value) {
                this.setSetting(value);
            }
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.setting-toggle {
        border-top: 1px solid $colorBackgroundGrayDarker;
        display: flex;
        flex-direction: column;
        padding: .5rem 1rem;
        > div.setting {
            display: flex;
            flex-direction: row;
            margin: .25rem 0;
            gap: 1rem;
            &.disabled {
                pointer-events: none;
                filter: grayscale(100%);
                opacity: 0.5;
            }
            > div.text {
                > div.footnote {
                    font-size: fontSize(12);
                    color: $colorSourceSecondary;
                }
            }
            > label {
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-right: auto;
                    margin-left: unset;
                }
            }
        }
    }
</style>
