
'use strict';

import _ from 'lodash';

import AsyncDestroyable from '../async.destroyable.js';
import { CourseStateConverter } from './course.state.converter.js';

export const COURSE_INFO_KEYS = [
    'uuid',
    'name',
    'name_subtitle',
    'hidden',
    'features',
    'can_cancel_ts',
    'expiration_ts',
    'payment_status',
    'payment_uuid',
    'registered_ts',
    'source_icon_id',
    'source_language',
    'target_icon_id',
    'target_language',
    'interface_languages',
    'disclaimer',
    'words'
];

/**
 * @typedef {object} CourseInfo
 * @property uuid {string}
 * @property name {string}
 * @property name_subtitle {string}
 * @property hidden {string}
 * @property features {string[]}
 * @property can_cancel_ts {string}
 * @property expiration_ts {moment}
 * @property payment_status {string}
 * @property payment_uuid {string}
 * @property registered_ts {moment}
 * @property source_icon_id {string}
 * @property source_language {string}
 * @property target_icon_id {string}
 * @property target_language {string}
 * @property interface_languages {string[]}
 * @property disclaimer {string|null}
 * @property words {string}
 */

// https://github.com/lingvist/webapp/wiki/Courses-API#course-feature-list
export const COURSE_FEATURE = {
    ADDITIONAL_MEANINGS: 'additional_meanings',
    GRAMMAR_TABLES: 'grammar_tables',
    GRAMMAR_HINTS: 'grammar_hints',
    GRAMMAR_HINTS_IN_GUESS: 'grammar_hints_in_guess',
    VOCABULARY_CURVE: 'vocabulary_curve',
    WORD_TRANSLATIONS: 'word_translations',
    FAST_TRACKING: 'fast_tracking',
    EXERCISES: 'exercises',
    VARIATIONS: 'variations',
    COURSE_WIZARD: 'course_wizard',
    MISTAKES: 'mistakes',
    NEW: 'new',
    SHORT: 'short'
};

export default class Course extends AsyncDestroyable {
    /**
     * @param {object} course_info - UserCourseInfo_v11. Keys=[
        uuid, source_language, target_language, source_icon_id, target_icon_id, hidden, registered_ts,
        expiration_ts, paid, payment_uuid, payment_status]
     * @param {string[]} asyncMethods - list of methods that have to monitored during destruction by an AsyncDestroyable
     */
    constructor (course_info, asyncMethods=[]) {
        super(asyncMethods);
        this.UUID = course_info.uuid;
        this.info = {};
        this.update_info(course_info);
    }

    update_info (user_course_info) {
        _(user_course_info)
            .pick(COURSE_INFO_KEYS)
            .forEach((value, key) => {
                this.info[key] = CourseStateConverter.convert(key, value);
            });
        this.info.target_in_source = (user_course_info && user_course_info.target_language && user_course_info.source_language) ? `${user_course_info.target_language}_${user_course_info.source_language}` : null;
    }

    destroy () {
        return Promise.resolve();
    }

    /**
     * Returns basic course information
     * @return {CourseInfo}
     */
    getInfo () {
        return _.clone(this.info);
    }


    hasInterfaceLanguage (interface_language) {
        return this.info.interface_languages.indexOf(interface_language) !== -1;
    }

    isHidden () {
        return this.info.hidden;
    }

    isNew () {
        const info = this.getInfo();
        return info.hasOwnProperty('features') && info.features.includes('new');
    }

    isShort () {
        const info = this.getInfo();
        return info.hasOwnProperty('features') && info.features.includes('short');
    }
}
