var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7 8a1 1 0 00-2 0v4.586l-1.707 1.707a1 1 0 101.414 1.414L5 15.414V17a2 2 0 002 2h4a1 1 0 100-2H7v-3.586l1.707-1.707a1 1 0 10-1.414-1.414L7 10.586V8zm12.894 3.553A1 1 0 0019 11h-4.5a1 1 0 100 2H17l-3.3 4.4a1 1 0 00.8 1.6H19a1 1 0 100-2h-2.5l3.3-4.4a1 1 0 00.094-1.047zM17 10a1 1 0 100-2 1 1 0 000 2z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }