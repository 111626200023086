var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "14",
          height: "35",
          viewBox: "0 0 14 35",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M13.35 19.47c.867-1.106.867-2.833 0-3.94L1.43.303A.818.818 0 00.318.152a.739.739 0 00-.16 1.064l11.92 15.225c.445.567.445 1.551 0 2.118L.159 33.784a.739.739 0 00.16 1.064.818.818 0 001.112-.152L13.35 19.47z",
          "fill-rule": "nonzero",
          fill: "#626C91",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }