var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 456 180",
          width: "456",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          opacity: ".5",
          d: "M106.312 40.762v87.902h249.282v-52.29c-11.179-1.443-62.207-21.187-86.55-34.71 0 16.678 0 53.642-1.352 53.642-1.803 3.606-63.56-31.554-79.788-54.544 0 19.383-.451 55.446-1.803 55.446-15.327-8.114-41.472-33.809-53.192-55.446v55.446c-9.918-13.524-24.793-37.866-26.597-55.446z",
          fill: "url(#learn-faster_svg__paint0_linear)",
        },
      }),
      _c("path", {
        attrs: {
          opacity: ".4",
          d: "M106.02 40.31c2.855 24.56 36.839 81.677 80.819 105.38",
          stroke: "#E2B4FF",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M106.02 40.31c1.49 12.82 11.462 34.51 26.91 55.689",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M132.98 95.999V40.31M187.408 96.57V40.884M268.514 96.57V40.884",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-dasharray": "10 10",
        },
      }),
      _c("path", {
        attrs: {
          opacity: ".4",
          d: "M134.006 40.31c15.421 39.982 89.883 86.414 154.995 106.69",
          stroke: "#E2B4FF",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M134.006 40.31c7.199 18.666 27.344 38.762 53.403 56.948",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          opacity: ".4",
          d: "M187.982 40.883c26.274 35.126 82.533 59.4 140.22 77.392",
          stroke: "#E2B4FF",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M187.982 40.883c16.983 22.704 46.493 40.875 80.82 55.746M268.801 40.837C283.651 53.687 327.156 68.504 356 76.5",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M111.5 40.5a5 5 0 11-10 0 5 5 0 0110 0zM138.5 40.5a5 5 0 11-10 0 5 5 0 0110 0zM192.5 40.5a5 5 0 11-10 0 5 5 0 0110 0zM273.5 40.5a5 5 0 11-10 0 5 5 0 0110 0z",
          fill: "#fff",
          stroke: "#2C3143",
          "stroke-width": "3",
        },
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "learn-faster_svg__paint0_linear",
                x1: "200.976",
                y1: "74.57",
                x2: "207.072",
                y2: "114.303",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#E2B4FF" } }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#E2B4FF",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }