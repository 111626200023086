<template>
    <section class="select-target-language">
        <h1 v-html="this.$i18n('register_select_target_languages_heading')"></h1>
        <languages
            :languages="languages"
            :show_language_not_listed="true"
            v-on="$listeners" />
    </section>
</template>

<script>

import _ from 'lodash';
import Raven from 'raven-js';

import Languages, { Language } from './languages.vue'


export default {
    name: 'register.select-target-language',
    components: {
        Languages
    },
    props: {
        courses: Array,
        interface_language: String
    },
    computed: {
        languages () {
            let languages = _(this.courses)
                .chain()
                .reduce((result, c, i) => {
                    const { target_icon_id, target_language } = c.getInfo();
                    const short = c.isShort();
                    const _new = c.isNew();

                    if (target_language === 'en-US' || target_language === 'en-GB') {
                        result['en'] = new Language('en', 'us-gb', false, {
                            short, new: _new
                        });
                    } else if (!result[target_language]) {
                        result[target_language] = new Language(target_language, target_icon_id, false, {
                            short, new: _new
                        });
                    } else {
                        result[target_language].attrs.short = short && result[target_language].attrs.short === short;
                        result[target_language].attrs.new = _new && result[target_language].attrs.new === _new;
                        if (result[target_language].icon_id !== target_icon_id) {
                            Raven.captureException('non-matching-icon-id-for-same-source-language', {level: 'error'});
                        }
                    }
                    return result;
                }, {})
                .value();

            return Object.values(languages);
        }
    }
};
</script>

<style scoped lang="scss">
    section.select-target-language {

        > h1 {
            font-size: 1.625rem;
            font-weight: 500;
            margin-bottom: 1rem;
            text-align: center;
        }

        flex: 1 0 auto;
    }
</style>
