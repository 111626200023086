var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "file-text-32_svg__ic-file-image-text-32",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c(
            "g",
            {
              staticClass: "file-text-32_svg__ic-file-image-text-32__file-icon",
            },
            [
              _c("path", {
                staticClass:
                  "file-text-32_svg__ic-file-image-text-32__file-icon__shadow",
                attrs: {
                  fill: "#2C3143",
                  "fill-opacity": ".1",
                  d: "M9.6 31h16.8a1.6 1.6 0 001.6-1.6V10l-6-6H9.6A1.6 1.6 0 008 5.6v23.8A1.6 1.6 0 009.6 31z",
                },
              }),
              _c("path", {
                staticClass:
                  "file-text-32_svg__ic-file-image-text-32__file-icon__rectangle",
                attrs: {
                  fill: "#FFF",
                  stroke: "#2C3143",
                  "stroke-linejoin": "round",
                  d: "M7.1 28.5h16.8a1.6 1.6 0 001.6-1.6V7.5h0l-6-6H7.1a1.6 1.6 0 00-1.6 1.6v23.8a1.6 1.6 0 001.6 1.6z",
                },
              }),
              _c("path", {
                staticClass:
                  "file-text-32_svg__ic-file-image-text-32__file-icon__rectangle",
                attrs: {
                  fill: "#E4E9F2",
                  stroke: "#2C3143",
                  "stroke-linejoin": "round",
                  d: "M19.5 7.5h6l-6-6z",
                },
              }),
            ]
          ),
          _c("path", {
            staticClass:
              "file-text-32_svg__ic-file-image-text-32__file-icon__rectangle",
            attrs: {
              fill: "#2C3143",
              "fill-rule": "nonzero",
              d: "M8 18h10v1H8zm0-3h15v1H8zm0-3h15v1H8z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }