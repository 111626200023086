<template>
    <section class="action" @click="triggerAction" :class="{ topBorder: this.topBorder, bottomBorder: this.bottomBorder }">
        <div class="text">
            <h3>{{ title }}</h3>
            <p v-if="this.description">{{ description }}</p>
        </div>
        <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 4L10 8L7 12" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </section>
</template>

<script>
    export default {
        name: 'section-action',
        props: {
            title: {
                type: String
            },
            description: {
                type: String
            },
            action: {
                type: String,
                default: 'save'
            },
            topBorder: {
                type: Boolean,
                default: false
            },
            bottomBorder: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            triggerAction() {
                this.$emit('action', this.action);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.action {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        padding: 0 .5rem;
        &.topBorder {
            border-top: 1px solid $colorBackgroundDarker;
            padding-top: .5rem;
        }
        &.bottomBorder {
            border-bottom: 1px solid $colorBackgroundDarker;
            padding-bottom: .5rem;
        }
        > div.text {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: .25rem;
            user-select: none;
            > h3 {
                font-size: fontSize(16);
                line-height: fontSize(20);
            }
            > p {
                font-size: fontSize(12);
                line-height: fontSize(16);
                color: $colorSourceSecondary;
            }
        }
        > svg.arrow {
            margin: .5rem 1rem .5rem auto;
            body[data-interface-language="ar"] & {
                transform: rotate(180deg);
            }
        }
        &:hover {
            cursor: pointer;
            > svg.arrow {
                transition: all 150ms;
                transform: scale(1.4);
                body[data-interface-language="ar"] & {
                    transform: rotate(180deg) scale(1.4);
                }
            }
        }
    }
</style>
