var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.text
    ? _c("section", { staticClass: "vocabulary-text" }, [
        _c("h2", [_vm._v(_vm._s(_vm.knowPercentageText))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text" },
          _vm._l(_vm.text, function (word) {
            return _c(
              "span",
              {
                staticClass: "word",
                class: { blocked: word.p > _vm.position },
              },
              [_vm._v(_vm._s(word.w))]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }