<template>
    <div class="course-loading">
        <div class="course-loading-container">
            <svg width="480" height="260" viewBox="0 0 240 130">
                <path class="anim cw" d="M232,65v20c0,6.6-5.4,12-12,12h-54c-9.5,0-13.7-7.5-16.5-11.5
	c-10.5-15-29.5-38.6-48.2-43.8c-17.1-4.7-4.6,14.4,13.6,30.6c2.9,2.6,7.3,2.6,10.3,0c18.2-16.2,30.7-35.3,13.6-30.6
	C120,46.9,103,69.5,91,85c-2.5,3.2-8,12-17,12H20c-6.6,0-12-5.4-12-12V65"/>
                <path class="anim cw" d="M8,65V45c0-6.6,5.4-12,12-12h54c9.5,0,13.7,7.5,16.5,11.5 c10.5,15,29.5,38.6,
                48.2,43.8c17.1,4.7,4.6-14.4-13.6-30.6c-2.9-2.6-7.3-2.6-10.3,0C96.6,73.9,84.1,93,101.2,88.3
	C120,83.1,137,60.5,149,45c2.5-3.2,8-12,17-12h54c6.6,0,12,5.4,12,12v20"/>
                <circle cx="120" cy="65" r="4"/>
            </svg>
            <course-icon class="source-icon" :value="source_icon_id" size="large" />
            <course-icon class="target-icon" :value="target_icon_id" size="large" />
        </div>
    </div>
</template>
<script>

import CourseIcon from './course-icon.vue';

export default {
    name: 'course-loading',
    props: {
        source_icon_id: {
            type: String,
            required: true
        },
        target_icon_id: {
            type: String,
            required: true
        },
    },
    components: {
        CourseIcon
    }
}
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';

    div.course-loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;


        > div.course-loading-container {
            > div.course-icon {
                width: 124px;
                height: 78px;
                position: absolute;

                &.source-icon {
                    top: 93px;
                    left: 45px;
                }

                &.target-icon {
                    top: 93px;
                    left: 312px;
                }
            }

            .anim{
                position: relative;
                stroke:#152E75;
                stroke-width: 2;
                fill:none;
                stroke-dasharray: 20,440;
            }

            .cw {
                animation-name: dash-start, dash-loop;
                animation-duration: 2800ms, 2200ms;
                animation-delay: 0ms, 2800ms;
                animation-timing-function: ease-in, linear;
                animation-iteration-count: 1, infinite;
            }
        }
    }

    @keyframes dash-start{
        from{
            opacity: 0;
            stroke-dashoffset: 0;
        }
        to{
            opacity: 1;
            stroke-dashoffset: 460;
        }
    }
    @keyframes dash-loop{
        from{
            stroke-dashoffset: 0;
        }
        to{
            stroke-dashoffset: 460;
        }
    }

</style>
