<template>
    <div class="collapsible-box" @click="toggleOpen" :class="{ open: this.open, hasContent: this.hasContent, noBackground: this.noBackground, isStacked: this.stacked, hasContent: this.hasContent }">
        <div class="icon-wrapper">
            <slot name="icon"></slot>
        </div>
        <div class="main">
            <h2 class="heading">
                <span class="title" v-if="title" >{{ title }}</span>
                <span class="title" v-else-if="i18n_title" v-html="this.$i18n(i18n_title.id, { collection: i18n_title.collection, args: i18n_title.params })" />
                <span class="title-meta" v-html="titleMeta" />
            </h2>
            <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <div class="content" v-show="this.open" v-if="this.hasContent">
                    <template v-if="text || i18n_text">
                        <span v-if="text" class="text">{{ text }}</span>
                        <span v-else-if="i18n_text && i18n_text.id" class="text" v-html="this.$i18n(i18n_text.id, { collection: i18n_text.collection, args: i18n_text.params })" />
                        <template v-else-if="i18n_text && i18n_text.ids">
                            <p class="text" v-for="id in i18n_text.ids" v-html="$i18n(id, { collection: i18n_text.collection, args: i18n_text.params })" />
                        </template>
                    </template>
                    <template v-if="this.$slots.content">
                        <slot name="content" />
                    </template>
                </div>
            </transition>
        </div>
        <div class="toggle-icon" v-if="hasContent">
            <arrow-icon />
        </div>
    </div>
</template>

<script>
    import ArrowIcon from 'Images/arrow-3.svg';
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'collapsible-box',
        components: {
            ArrowIcon,
        },
        props: {
            stacked: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
            },
            titleMeta: {
                type: [String, Number],
            },
            text: {
                type: String
            },
            i18n_text: {
                type: Object
            },
            i18n_title: {
                type: Object
            },
            noBackground: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            hasContent() {
                return !!(this.text || this.i18n_text || this.$slots.content);
            },
        },
        methods: {
            toggleOpen() {
                this.open = !this.open;
                if (this.open) {
                    EventBus.$emit('collapsible-box:opened', this._uid);
                }
            },
            closeNotInFocus(id) {
                if (this._uid !== id) {
                    this.open = false;
                }
            },
            enter(el) {
                el.style.height = 'auto';
                const height = getComputedStyle(el).height;
                el.style.height = 0;
                getComputedStyle(el);
                setTimeout(() => {
                    el.style.height = height;
                });
            },
            afterEnter(el) {
                el.style.height = 'auto';
            },
            leave(el) {
                el.style.height = getComputedStyle(el).height;
                getComputedStyle(el);
                setTimeout(() => {
                    el.style.height = 0;
                });
            }
        },
        mounted() {
            EventBus.$on('collapsible-box:opened', this.closeNotInFocus);
        },
        beforeDestroy() {
            EventBus.$off('collapsible-box:opened', this.closeNotInFocus);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.collapsible-box {
        display: flex;
        background-color: $colorBackgroundLighter;
        border-radius: pxToRem(8);
        padding: 1rem;
        flex-direction: row;
        gap: .5rem;
        user-select: none;
        &.hasContent {
            cursor: pointer;
        }
        &.isStacked {
            border-radius: unset;
        }
        &.noBackground {
            background-color: unset;
        }
        > div.icon-wrapper {
            min-width: pxToRem(24);
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: pxToRem(24);
        }
        > div.main {
            flex: 100%;
            > h2.heading {
                display: flex;
                flex-direction: row;
                font-size: pxToRem(16);
                font-weight: $font-bold;
                min-height: pxToRem(24);
                line-height: pxToRem(24);
                margin: 0;
                gap: .5rem;
                > span.title-meta {
                    margin-left: auto;
                    color: $colorSourceSecondary;
                    body[data-interface-language="ar"] & {
                        margin-left: unset;
                        margin-right: auto;
                    }
                }
            }
            > div.content {
                margin-top: .5rem;
                > p.text,
                > span.text {
                    color: $colorSourceSecondary;
                    display: inline-block;
                    font-size: fontSize(14);
                    line-height: 1.35em;
                }
                > p.text {
                    margin-top: .5rem;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                > div.separator {
                    background-color: $colorBackgroundDarker;
                    height: 1px;
                    width: 100%;
                }
                > div.collapsible-box-content {
                    margin: 1rem 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            .expand-enter, .expand-leave-to {
                opacity: 0;
            }
            .expand-enter-active, .expand-leave-active {
                transition: height 250ms ease-in-out, opacity 100ms ease-in-out;
                overflow: hidden;
            }
        }
        > div.toggle-icon {
            width: pxToRem(24);
            height: pxToRem(24);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            flex: pxToRem(24);
            > svg {
                transform: rotate(90deg);
            }
        }
        &.open {
            > div.toggle-icon {
                > svg {
                    transform: rotate(270deg);
                }
            }
        }
        &.hasContent {
            > div.content {
                > h2.heading {
                    cursor: pointer;
                }
            }
        }
    }
</style>
