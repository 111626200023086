
import moment from 'moment';
import Backbone from 'backbone';

import AsyncDestroyable from './async.destroyable.js';
import ControllerManager from './controller.manager.js';
import { TYPE as PARAMETER_TYPE, NAME as PARAMETER_NAME } from './user.parameters.js';
import Raven from 'raven-js';

export const SUBSCRIPTION_STATUS = {
    DISABLED: 'disabled',
    FREE: 'free',
    PAID: 'paid',
    PAID_INTRO: 'paid-intro',
    PRE_TRIAL: 'pre-trial',
    INTRO_TRIAL: 'intro-trial',
    INITIAL_TRIAL: 'initial-trial',
    ADDITIONAL_TRIAL: 'additional-trial'
};

export class Subscription extends AsyncDestroyable {
    /**
     * @typedef {object} SubscriptionData
     * @property {string} status
     * @property {string|null} expiration_ts
     * @property {string|null} trial_duration
     * @property {boolean} on_hold
     * @property {boolean} trial_available
     */

    constructor(user) {
        super(['updateSubscription', 'checkTrialEnd', 'getHasExpiredSubscription']);
        this._user = user;

        // Instance fields
        this.status = null;
        this.expiration_ts = null;
        this.trial_available = null;
        this.on_hold = null;
        this.trial_duration = null;

        this._subscription_end_promise = null;
        this._has_expired_subscription = null;

        Backbone.on('rendered', () => {
            this.checkTrialOrSubscriptionEnd();
            this.getHasExpiredSubscription();
        }, this);
    }

    /**
     * @param {SubscriptionData} data
     */
    async updateSubscription(data) {

        this.status = data.status;
        this.expiration_ts = data.expiration_ts && moment(data.expiration_ts);
        this.trial_available = !!data.trial_available;
        this.on_hold = data.on_hold;
        this.trial_duration = (data.trial_duration) ? Math.round(moment.duration(data.trial_duration).asDays() * 100) / 100 : null; // Rounding - so it can be 0.02 days as well :)

        console.info(`updateSubscription ${ JSON.stringify(data, null, 4) }`);

        switch (this.status) {
            case SUBSCRIPTION_STATUS.INITIAL_TRIAL:
            case SUBSCRIPTION_STATUS.PAID:
                if (this.expiration_ts) {
                    await this._user.getParameters().setParameter(PARAMETER_NAME.SUBSCRIPTION_NOTIFICATION_TS, this.expiration_ts, PARAMETER_TYPE.DATETIME);
                }

                let subscription_notified_ts = this._user.getParameters().getParameter(PARAMETER_NAME.SUBSCRIPTION_NOTIFIED_TS);
                // Cache local copies. As long as nothing else touches these it's okay to cache them!
                this._subscription_notification_ts = this.expiration_ts;
                this._subscription_notified_ts = subscription_notified_ts && moment(subscription_notified_ts);

                return;
        }

        // Start pre-loading subscriptions data
        if (this.status !== SUBSCRIPTION_STATUS.DISABLED) {
            // Load trial/subscription parameters from storage if the course wasn't updated with status==trial - just in case
            this._subscription_notification_ts = this._user.getParameters().getParameter(PARAMETER_NAME.SUBSCRIPTION_NOTIFICATION_TS);
            this._subscription_notified_ts = this._user.getParameters().getParameter(PARAMETER_NAME.SUBSCRIPTION_NOTIFIED_TS);
        }

        if (this.trial_available) {
            const popupView = ControllerManager.instance.getController('ModalMessages').showTrialAvailable(this.trial_duration);
            if (popupView !== null) {
                popupView.on('close', callback => {
                    callback();
                    Backbone.trigger('showLoader', 'start-trial');
                    Promise.resolve()
                        .then(() => this._user.startTrial())
                        .then(() => {
                            Backbone.trigger('hideLoader');
                            Backbone.trigger('reRenderAllViews');
                            if (!this._user.hasCourse()) {
                                Backbone.history.navigate('account?tab=subscription', { trigger: true });
                            }
                        })
                        .catch(error => {
                            Raven.captureException(error, { level: 'error' });
                            Backbone.trigger('hideLoader');
                        });
                });
            }
        }
    }

    destroy() {
        delete this._user;

        Backbone.off(null, null, this);

        return Promise.resolve();
    }

    checkTrialOrSubscriptionEnd() {
        if (!this._user.hasCourse()) {
            return Promise.resolve();
        }

        if (this._subscription_end_promise !== null) {
            return this._subscription_end_promise;
        } else {
            let now = moment.utc();
            if (this._subscription_notification_ts !== null && now > this._subscription_notification_ts && this._subscription_notified_ts < this._subscription_notification_ts) {
                // null < moment() === true
                let promise = Promise.resolve();
                if (this.status !== SUBSCRIPTION_STATUS.PAID) {
                    let sync_promise = this._user.sync();
                    promise = promise
                        .then(() => {
                            return Promise.resolve().then(() => this.hasExpiredSubscription())
                                .then(hasExpiredSubscription => {
                                    if (hasExpiredSubscription) {
                                        return ControllerManager.instance.getController('ModalMessages').showSubscriptionEnd();
                                    } else {
                                        return ControllerManager.instance.getController('ModalMessages').showTrialEnd();
                                    }
                                });
                        })
                        .then(view => new Promise((resolve, reject) => {
                                view.on('close', () => {
                                    resolve();
                                });
                            })
                        )
                        .then(() => sync_promise);
                }

                promise.then(() => {
                    let now = moment.utc();
                    this._subscription_notified_ts = now;
                    this._subscription_end_promise = null;
                    return this._user.getParameters().setParameter(PARAMETER_NAME.SUBSCRIPTION_NOTIFIED_TS, now, PARAMETER_TYPE.DATETIME);
                });

                this._subscription_end_promise = promise;
                return this._subscription_end_promise;
            } else {
                return Promise.resolve();
            }
        }
    }

    getHasExpiredSubscription() {
        if (!this._user.hasCourse()) {
            return Promise.resolve(false);
        }

        return Promise.resolve().then(() => {
            return this._user.getPayApi().getUserServices();
        }).then(response => {
            this._has_expired_subscription = !!('has_historical_services' in response && response.has_historical_services);
            return this._has_expired_subscription;
        }).catch(() => Promise.resolve(false));
    }

    async getPaySubscriptionInfo() {
        const response = await this._user.getPayApi().getUserServices();
        let subscriptionInfo;
        if ('services' in response && response.services.length > 0) {
            subscriptionInfo = response.services[0];
        }

        return Promise.resolve(subscriptionInfo);
    }

    /**
     *
     * @return {{status: string, expiration_ts: moment|null}}
     */
    getStatus() {
        return {
            status: this.status,
            expiration_ts: this.expiration_ts
        };
    }

    /**
     *
     * @param subscription_uuid
     * @return {Promise}
     */
    cancelSubscription(subscription_uuid) {
        return Promise.resolve()
            .then(() => this._user.getPayApi().cancelUserSubscription(subscription_uuid))
            .then(() => this._user.getPayApi().invalidateCache());
    }

    getTrialDurationAsDays() {
        return this.trial_duration;
    }

    hasExpiredSubscription() {
        if (this._has_expired_subscription === null) {
            return Promise.resolve()
                .then(() => this.getHasExpiredSubscription());
        } else {
            return this._has_expired_subscription;
        }
    }

    isSubscriptionActive(status = this.status) {
        if (status) {
            switch (status) {
                case SUBSCRIPTION_STATUS.PAID:
                case SUBSCRIPTION_STATUS.ADDITIONAL_TRIAL:
                case SUBSCRIPTION_STATUS.INTRO_TRIAL:
                case SUBSCRIPTION_STATUS.PAID_INTRO:
                case SUBSCRIPTION_STATUS.INITIAL_TRIAL:
                    return true;
                default:
                    return false;
            }
        } else {
            return false;
        }
    }
}
