import $ from 'jquery';
import Backbone from 'backbone';

import UserModel from '../../model/user.model.js';
import i18nUtils from '../../util/i18n.js';
import ResponsivenessUtils from '../../util/responsiveness.js';
import KeyCodes from '../../util/keycodes.js';

import renderTemplate from '../../util/template.renderer.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';
import Raven from 'raven-js';

const SigninCredentialsView = Backbone.View.extend({

    name: 'signin',

    tagName: 'main',
    className: 'signin-credentials-view',

    events: {
        'click .signin-submit': 'submit',
        'keyup input': 'enableSubmit',
        'keypress': 'submitOnEnter',
        'click a[data-action="navigate"]': 'navigate',
        'click div.input-hints > div.hint.password': 'togglePasswordReveal',
        'click div.back-arrow': 'goBack'
    },

    initialize: function (options) {
        const self = this;
        const queryParameters = options.queryParameters;
        let promise = Promise.resolve();

        this.capsLockEnabled = false;
        this.checkInputsInterval = null;

        if (queryParameters) {
            if (queryParameters.email) {
                this._email = queryParameters.email;
            }
            if (queryParameters.language) {
                promise = promise.then(function () {
                    return i18nUtils.setInterfaceLanguage(queryParameters.language);
                });
            }
        }

        return promise.then(function () {

            $(window).on('keydown.signin', event => {
                if (event.originalEvent.getModifierState !== undefined) {
                    self.capsLockEnabled = event.originalEvent.getModifierState('CapsLock');
                    if (self.capsLockEnabled) {
                        self.$el.find('div.hint.caps-lock').removeClass('hidden');
                    } else {
                        self.$el.find('div.hint.caps-lock').addClass('hidden');
                    }
                }
            });

            $(window).on('keyup.signin', function (event) {
                if (event.originalEvent.getModifierState !== undefined) {
                    self.capsLockEnabled = event.originalEvent.getModifierState('CapsLock');
                    if (self.capsLockEnabled) {
                        self.$el.find('div.hint.caps-lock').removeClass('hidden');
                    } else {
                        self.$el.find('div.hint.caps-lock').addClass('hidden');
                    }
                }
            });

            if (options.router) {
                self._router = options.router;
            }

            console.log('Signin Credentials view init');
            self.checkInputsInterval = setInterval(self.enableSubmit.bind(self), 2000); // check for input values what might be filled by password managers
        });
    },

    remove: function () {
        $(window).off('keyup.signin');
        $(window).off('keypress.signin');

        Backbone.View.prototype.remove.call(this);
        clearInterval(this.checkInputsInterval);
    },

    render: function () {
        renderTemplate('signin/credentials', { email: this._email }, this.$el).then( () => {
           this._postRender();
        });

        return this;
    },

    _postRender: function () {
        if ( ! ResponsivenessUtils.guessInMobileView()) {
            if (this._email) { // if email is pre-filled focus password instead
                this.$('input.password').focus();
            } else {
                this.$('input.email').focus();
            }
        }

        Backbone.trigger('rendered', this);
    },

    show_login_error: function (status) {
        this.$('.form input, .form a, .signin-submit').prop('disabled', false);
        this.$('input').removeClass('error');

        Backbone.trigger('signinProcessFinished');

        switch (status) {

            case 'error-empty-email':
                this.$('input.email').addClass('error');
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_empty_email')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Empty Email');
                break;

            case 'error-empty-password':
                this.$('input.password').addClass('error');
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_empty_password')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Empty Password');
                break;

            case 'error-authentication':
                this.$('input.email').addClass('error');
                this.$('input.password').addClass('error');
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_authentication')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Authentication Failed');
                break;

            case 'authorization-failed':
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_server')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Server Error');
                break;

            case 'error-server':
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_server')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Server Error');
                break;

            case 'deletion-in-progress':
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_deletion_in_progress')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Server Error');
                break;

            case 'error-network':
                this.$('section.help > p.error-message')
                    .text(i18nUtils.prop('signin_error_network')).show();
                googleAnalyticsCommand('send', 'event', 'Login', 'Server Error');
                break;

            default:
                this.$('section.help > p.error-message').hide();
        }
    },

    submit: async function () {
        Backbone.trigger('signinInProgress', 'signin');

        const email = this.$el.find('input.email').val();
        const password = this.$el.find('input.password').val();

        if (email.length === 0) {
            this.show_login_error('error-empty-email');
        } else if (password.length === 0) {
            this.show_login_error('error-empty-password');
        } else {
            try {
                await UserModel.login(email, password);
            } catch (error) {
                if (error instanceof Error) {
                    Raven.captureException(error, { level: 'error' });
                    this.show_login_error('error-server');
                } else {
                    this.show_login_error(error);
                }
            }

        }
    },

    submitOnEnter: function (event) {
        if (event.keyCode === 13) {
            this.submit();
        }
    },

    enableSubmit: function (event = null) {
        if (event) {
            if (event.keyCode !== KeyCodes.ENTER) {
                this.$('input.email').removeClass('error');
                this.$('input.password').removeClass('error');
                this.$('section.help > p.error-message').hide();
            }
        }
        // change submit button appearance based on username / password entry
        // note that button is enabled all the time
        if (this.$el.find('input.email').val() === '' || this.$el.find('input.password').val() === ''){
            this.$el.find('button.signin-submit').addClass('disabled');
        } else  {
            this.$el.find('button.signin-submit').removeClass('disabled');
        }
    },

    navigate: function (event) {
        var target = $(event.currentTarget).data('target');
        Backbone.history.navigate(target, {trigger: true});
    },

    togglePasswordReveal: function (event) {
        var $target = $(event.currentTarget);

        if ($target.hasClass('revealed')) {
            $target.parent().siblings('input').attr('type', 'password').focus();
            $target.removeClass('revealed');
        } else {
            $target.parent().siblings('input').attr('type', 'text').focus();
            $target.addClass('revealed');
        }
    },

    goBack: function () {
        window.history.back();
    }
});

export default SigninCredentialsView;
