var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "difficulty" },
    [
      _vm.showTooltip
        ? _c("div", {
            staticClass: "tooltip",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(this.difficultyName, {
                  collection: "difficulty_indicator_label",
                })
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("difficulty-icon", {
        staticClass: "difficultyIcon",
        class: { full: this.difficulty > 0 },
      }),
      _vm._v(" "),
      _c("difficulty-icon", {
        staticClass: "difficultyIcon",
        class: { full: this.difficulty > 1 },
      }),
      _vm._v(" "),
      _c("difficulty-icon", {
        staticClass: "difficultyIcon",
        class: { full: this.difficulty > 2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }