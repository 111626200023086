
'use strict';

import _ from 'lodash';
import moment from 'moment';
import Raven from 'raven-js';
import {StatisticsProcessorUtils} from './processor.utils.js';

import {LearnedWords, STATUS as LEARNED_WORDS_STATUS} from '../learned.words.js';

import AsyncDestroyable from '../async.destroyable.js';

import {StatisticsAwardEventV1Processor} from './event.processor/award.1.1.js';
import {StatisticsExerciseCompleteEventProcessor} from './event.processor/exercise_complete.0.1.js';
import {StatisticsGuessEventProcessor} from './event.processor/guess.0.js';
import {NavigationV0EventProcessor} from './event.processor/navigation.0.js';
import { EventBus } from '../../util/vue-event-bus.js';

const eventProcessors = [
    StatisticsAwardEventV1Processor,
    StatisticsExerciseCompleteEventProcessor,
    StatisticsGuessEventProcessor,
    NavigationV0EventProcessor
];

export class CourseStatistics extends AsyncDestroyable {
    constructor (user, course) {
        super(['initializeState', 'updateTotals']);
        this._user = user;
        this._course = course;
    }

    /**
     * Initializes the CourseStatistics
     */
    initializeState () {
        console.log(`CourseStatistics.initializeState()`);
        this._events = _.isArray(this._events) ? this._events : [];
        this._totals = StatisticsProcessorUtils.getFreshStatistics();
        this._history = StatisticsProcessorUtils.getFreshHistory();
        this._server_history_client_sn = -1;
        this._learned_words = new LearnedWords(this._user, this._course);

        return Promise.resolve();
    }

    destroy () {
        return Promise.all([
            this._learned_words && this._learned_words.destroy()
        ]).then(() => {
            delete this._user;
            delete this._course;
        });
    }

    /**
     * Merges state to existing state
     * @param totals
     * @private
     */
    _mergeTotals (totals) {
        console.debug(`CourseStatistics._mergeTotals(${totals.client_sn})`);
        _.forEach(totals, (value, key) => {
            switch (key) {
                case 'client_event_ts':
                    this._totals.client_event_ts = value && moment(value);
                    break;
                case 'sets':
                    this._totals.sets = value;
                    break;
                case '__events':
                    this._events = value
                        // Only add events that are not already present in the events list
                        .filter(event => this._events.find(ev => ev.event.client_sn === event.client_sn) === undefined)
                        // Only add events that have processors available
                        .filter(event => this._getProcessor(event) !== undefined)
                        // Transform to internal format with added processor data
                        .map(event => ({
                            event: event,
                            processor: this._getProcessor(event)
                        }));
                    break;
                default:
                    this._totals[key] = value;
            }
        });
    }

    _mergeHistory (history) {
        console.debug(`CourseStatistics._mergeHistory(${history.length})`);

        history.forEach(point => {
            const history_point = StatisticsProcessorUtils.getDataPointForTS(this._history, moment(point.ts)).point;
            if (!history_point.client_sn || history_point.client_sn < point.client_sn) {
                _.merge(history_point, point);
            }
        });
    }

    _isEventRelevant (event_client_sn) {
        return event_client_sn > this._totals.client_sn || event_client_sn > this._server_history_client_sn;
    }

    _pruneEvents () {
        this._events = _.filter(this._events, event => this._isEventRelevant(event.event.client_sn));
    }

    /**
     * Overwrite the local statistics with the incoming saved or server state data
     * @param state {object}
     */
    async updateTotals (state) {
        console.debug(`CourseStatistics.updateTotals(${state.client_sn})`);
        this._mergeTotals(state);
        this._processEvents(true, false);
        if (!state.__local) {  // If loading a local _dump don't discard events they might still be needed
            this._pruneEvents();
        }
    }

    /**
     * Overwrite the local history data points with the incoming saved or server state data
     * @param history {Array<object>}
     */
    async updateHistory (history) {
        console.debug(`CourseStatistics.updateHistory(${history.map(d => d.ts + '-' + d.client_sn + ':' + d.all_units.total)})`);
        history.forEach(d => {
            this._server_history_client_sn = Math.max(this._server_history_client_sn, d.client_sn);
        });
        this._mergeHistory(history);
        this._processEvents(false, true);
        this._pruneEvents();
    }

    updateLearnedWords (learned_words_url) {
        if (this._learned_words.status === LEARNED_WORDS_STATUS.INITIAL ||
            this._learned_words.status === LEARNED_WORDS_STATUS.ERROR) {
            Promise.resolve().then(() => {
                return this._learned_words.load(learned_words_url);
            }).catch(error => {
                Raven.captureException(error, {level: 'error'});
            });
        }
    }

    /**
     * Essentially does the opposite of updateTotals
     * @return {Promise}
     */
    serializeTotals () {
        let stateDump = _.cloneDeep(this._totals);
        stateDump.__local = true;
        stateDump.client_event_ts = stateDump.client_event_ts && stateDump.client_event_ts.format();
        // Save only the event portion of the event, and not the processor.
        // Also don't even bother saving the generated data, so that it would be re-calculated and no
        // State weirdness would exist
        stateDump.__events = this._events.map(event => _.omit(event.event, '__statisticsData', '__statisticsDataV1'));

        return stateDump;
    }

    serializeHistory () {
        return _.cloneDeep(this._history);
    }

    getData () {
        return _.clone(this._totals);
    }

    getHistory () {
        return _.clone(this._history);
    }

    getSetsData () {
        return _.clone(this._totals.sets);
    }

    getTodayData () {
        let now = moment().local();
        return StatisticsProcessorUtils.getDataPointForTS(this._history, now).point;
    }

    getDateData (date) {
        return StatisticsProcessorUtils.getDataPointForTS(this._history, date).point;
    }

    getWordsEncountered (totals = null) {
        totals = totals || this.getData();
        return totals.new_units.total + totals.fast_tracked_words;
    }

    getTotalCardsEncountered (totals = null) {
        totals = totals || this.getData();
        return totals.all_units.total;
    }

    /**
     * Returns array of statistics from start of the current week or from given Moment
     * @return {Array}
     */
    getHistoryDataFromTo (fromMoment = null, toMoment = null) {
        if (!moment.isMoment(fromMoment)) {
            fromMoment = this.getStartOfCurrentWeek();
        }
        if (!moment.isMoment(toMoment)) {
            toMoment = moment().local();
        }

        let historyData = [];

        while (toMoment.isSameOrAfter(fromMoment)) {
            historyData.push(StatisticsProcessorUtils.getDataPointForTS(
                this._history,
                fromMoment
            ).point);
            fromMoment.add(1, 'days');
        }

        return historyData;
    }

    /**
     * Returns start of the current ISO week, returns Moment Object
     * @return {Object}
     */
    getStartOfCurrentWeek () {
        return moment().local().startOf('isoWeek');
    }

    getLearnedWords () {
        return this._learned_words;
    }

    getCourse () {
        return this._course;
    }

    _processEvents (update_totals, update_history) {
        console.debug(`CourseStatistics._processEvents(${update_totals}, ${update_history})
        client_sn=${this._totals.client_sn} history_client_sn=${this._server_history_client_sn}`);
        console.debug(`CourseStatistics._processEvents events ${this._events.map(event => event.event.client_sn)}`);
        this._events
            .filter(event => this._isEventRelevant(event.event.client_sn))
            .forEach(event => {
                // TODO: I think that it might be possible to skip some processing here
                //  if we take both history and totals.client_sn into account better
                //  and only process events into either if necessary
                //  .. needs quite a lot of careful refactoring though
                const [updated_totals, updated_history] = event.processor.process(event.event, this);
                if (update_totals && event.event.client_sn > this._totals.client_sn) {
                    this._mergeTotals(updated_totals);
                }
                if (update_history) {
                    this._mergeHistory(updated_history);
                }
        });
        this.logStatisticsData();
    }

    _getProcessor (event) {
        return _.find(eventProcessors, processor => processor.canProcess(event));
    }

    addEvent (event) {
        let eventProcessor = this._getProcessor(event);
        if (eventProcessor !== undefined) {
            this._events.push({
                processor: eventProcessor,
                event: event
            });
            this._processEvents(true, true);

            if (!this._destructionInProgress()) {
                EventBus.$emit('statistics-updated');

                let todayStatistics = this.getTodayData();
                this._course.getAwards().notifyStatisticsUpdated(todayStatistics);
            }
        }
    }

    logStatisticsData () {
        const totals = this.getData();
        const today = this.getTodayData();
        if (totals !== undefined) {
            console.debug(`CourseStatistics logStatisticsData client_sn=${this._totals.client_sn} history_client_sn=${this._server_history_client_sn} ` +
                          `all_units=${JSON.stringify(totals.all_units)} ` +
                          `repeat_units=${JSON.stringify(totals.repeat_units)} new_units=${JSON.stringify(totals.new_units)} ` +
                          `today_all_units=${JSON.stringify(today.all_units)} awards=${today.awards_objects.length} ` +
                          `flips=${JSON.stringify(totals.flips)} kicks=${JSON.stringify(totals.kicks)} ` +
                          `today_flips=${JSON.stringify(today.flips)} today_kicks=${JSON.stringify(today.kicks)}`);
        } else {
            console.debug(`CourseStatistics logStatisticsData client_sn=${this._totals.client_sn} NO DATA`);
        }
    }
}
