<template>
    <div class="feedback-modal" @click.self="close" v-if="isOpen">
        <div class="feedback-modal-window">
            <CloseButton @click="close" class="close" />
            <h2 class="title" v-html="this.$i18n('card_feedback_report_problem_title')" />
            <div class="content">
                <div class="radio-button-list">
                    <radio-button report_value="report_sentence_quality" :selected_report_value="selectedReportValue" @change="changeReport('report_sentence_quality')" :label="this.getFeedbackLabel('report_sentence_quality')" />
                    <radio-button report_value="report_sentence_translation_issue" :selected_report_value="selectedReportValue" @change="changeReport('report_sentence_translation_issue')" :label="this.getFeedbackLabel('report_sentence_translation_issue')" />
                    <radio-button report_value="report_incorrect_grammar" :selected_report_value="selectedReportValue" @change="changeReport('report_incorrect_grammar')" :label="this.getFeedbackLabel('report_incorrect_grammar')" />
                    <radio-button report_value="report_word_translation_issue" :selected_report_value="selectedReportValue" @change="changeReport('report_word_translation_issue')" :label="this.getFeedbackLabel('report_word_translation_issue')" />
                    <radio-button report_value="report_synonym_not_accepted" :selected_report_value="selectedReportValue" @change="changeReport('report_synonym_not_accepted')" :label="this.getFeedbackLabel('report_synonym_not_accepted')" />
                    <radio-button report_value="report_incorrect_swt_tooltip" :selected_report_value="selectedReportValue" @change="changeReport('report_incorrect_swt_tooltip')" :label="this.getFeedbackLabel('report_incorrect_swt_tooltip')" />
                    <radio-button report_value="report_broken_audio" :selected_report_value="selectedReportValue" @change="changeReport('report_broken_audio')" :label="this.getFeedbackLabel('report_broken_audio')" />
                    <radio-button v-if="cardHasVisuals" report_value="report_incorrect_picture" :selected_report_value="selectedReportValue" @change="changeReport('report_incorrect_picture')" :label="this.getFeedbackLabel('report_incorrect_picture')" />
                    <radio-button v-if="!isLimitFreeInput" report_value="report_other" :selected_report_value="selectedReportValue" @change="changeReport('report_other')" :label="this.getFeedbackLabel('report_other')" />
                </div>
                <div class="description" v-if="!isLimitFreeInput">
                    <h3 v-html="this.$i18n('card_feedback_description_label_compulsory')" />
                    <textarea :placeholder="descriptionPlaceholder" v-model="userDescription" maxlength="2000" />
                </div>
            </div>
            <div class="action-buttons">
                <v-button @click.native="sendFeedback"
                          :filled="true" color="primary"
                          :disabled="!this.selectedReportValue"
                          :i18n="{ id: 'card_feedback_send_feedback' }" />
                <v-button @click.native="close"
                          :i18n="{ id: 'feedback_modal_cancel' }" />
            </div>
        </div>
    </div>
</template>

<script>


import CloseButton from "Images/close-24x24.svg";
import { EventBus } from "Util/vue-event-bus";
import RadioButton from 'ViewComponent/radio-button.vue';
import VButton from 'ViewComponent/v-button.vue';
import i18nUtils from "Util/i18n";
import { TYPE as TOAST_TYPE } from "../../toaster/constants";
import UserManager from "Modules/usermanager";
import getConfigValue from "Util/configuration";

export default {
    name: 'feedback-modal',
    components: {
        CloseButton,
        RadioButton,
        VButton
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        answer: {
            type: Object,
            required: true
        },
        cardHasVisuals: {
            type: Boolean,
            default: false
        },
        cardVisualData: {
            type: Object
        },
    },
    data() {
        return {
            isOpen: false,
            selectedReportValue: null,
            selectedReportLabel: null,
            userDescription: '',
            isLimitFreeInput: getConfigValue('limit-user-free-input')
        }
    },
    computed: {
        descriptionPlaceholder() {
            return i18nUtils.prop('card_feedback_description_label_optional');
        }
    },
    methods: {
        open() {
            document.body.classList.add('modal-open');
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            document.body.classList.remove('modal-open');
        },
        keyDown(event) {
            if (event && event.keyCode === 27) { // close on ESC key
                this.close()
            }
        },
        changeReport(value) {
            this.selectedReportValue = value;
            this.selectedReportLabel = this.getFeedbackLabel(value);
        },
        getFeedbackLabel(value) {
            switch(value) {
                case 'report_sentence_quality':
                    return i18nUtils.prop('report_sentence_quality', null, null,'card_feedback_option');
                case 'report_sentence_translation_issue':
                    return i18nUtils.prop('report_sentence_translation_issue', null, null,'card_feedback_option');
                case 'report_incorrect_grammar':
                    return i18nUtils.prop('report_incorrect_grammar', null, null,'card_feedback_option');
                case 'report_word_translation_issue':
                    return i18nUtils.prop('report_word_translation_issue', null, null,'card_feedback_option');
                case 'report_synonym_not_accepted':
                    return i18nUtils.prop('report_synonym_not_accepted', null, null,'card_feedback_option');
                case 'report_incorrect_swt_tooltip':
                    return i18nUtils.prop('report_incorrect_swt_tooltip', null, null,'card_feedback_option');
                case 'report_broken_audio':
                    return i18nUtils.prop('report_broken_audio', null, null,'card_feedback_option');
                case 'report_incorrect_picture':
                    return i18nUtils.prop('report_incorrect_picture', null, null,'card_feedback_option');
                case 'report_other':
                    return i18nUtils.prop('report_other', null, null,'card_feedback_option');
            }
        },
        sendFeedback() {
            let picture_url = null;
            if (this.selectedReportValue === 'report_incorrect_picture' && this.cardHasVisuals && this.cardVisualData && this.cardVisualData.hasOwnProperty('urls')) {
                picture_url = this.cardVisualData.urls.small;
            }

            UserManager.instance.getUser().getEventSender().sendFeedbackGuessEvent(
                this.question.question,
                this.answer.guess_value ? this.answer : null,
                this.selectedReportValue,
                this.selectedReportLabel,
                this.userDescription.trim(),
                picture_url
            );

            const toast = {
                text: i18nUtils.prop('card_feedback_confirmation_text'),
                type: TOAST_TYPE.SUCCESS
            };
            EventBus.$emit('toaster-add', toast);

            this.cleanupFeedback();
            this.close();
        },
        cleanupFeedback() {
            this.selectedReportValue = null;
            this.userDescription = '';
        },
        listenEventBus() {
            EventBus.$on('guess:feedback-modal-open', this.open);
            EventBus.$on('guess:feedback-modal-close', this.close);
        },
        unListenEventBus() {
            EventBus.$off('guess:feedback-modal-open', this.open);
            EventBus.$off('guess:feedback-modal-close', this.close);
        }
    },
    created() {
        this.listenEventBus();
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
    },
    beforeDestroy() {
        this.unListenEventBus();
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
    }
}
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.feedback-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43, 81, 142, 0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        > div.feedback-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 40vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
            > h2.title {
                text-align: left;
                font-size: 1.375rem;
                font-weight: 500;
            }

            > div.content {
                margin-top: pxToRem(24);
                > div.radio-button-list {
                    > div.radio-button {
                        padding: .25rem 0;
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }

                > div.description {
                    margin-top: 1rem;
                    > h3 {
                        margin-bottom: 1rem;
                    }
                    > textarea {
                        width: 100%;
                        background: $colorInputBox;
                        color: $colorSourcePrimary;
                        padding: pxToRem(16);

                        &:focus {
                            background: $colorInputBoxFocus;
                        }
                    }
                }
            }

            > div.action-buttons {
                margin-top: 1rem;
                display: flex;
                flex-direction: row-reverse;
                > button {
                    margin-right: 1rem;
                    &:first-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
