<template>
    <div class="debug-modal" @click.self="close" v-if="isOpen">
        <div class="debug-modal-window">
            <CloseButton @click="close" class="close" />
            <tabs v-on:close="close">
                <tab title="Guess helpers">
                    <tab-guess />
                </tab>
                <tab title="Lexical units">
                    <tab-lu />
                </tab>
                <tab title="Course Icons">
                    <tab-course-icons />
                </tab>
                <tab title="Misc">
                    <tab-misc />
                </tab>
            </tabs>
        </div>
    </div>
    <div class="debug-auto-play-stop" v-else-if="autoPlayOpen">
        <v-button text="Stop autoplay" color="alert" :filled="true" :nowrap="true" @click.native="stopAutoPlay" />
    </div>
</template>

<script>
import CloseButton from 'Images/close-24x24.svg';
import VButton from 'ViewComponent/v-button.vue';
import { EventBus } from "Util/vue-event-bus";
import Tabs from "./components/debug-tabs.vue";
import Tab from "./components/debug-tab.vue";
import TabCourseIcons from "./components/tab-course-icons.vue";
import TabGuess from "./components/tab-guess.vue";
import TabLu from "./components/tab-lu.vue";
import TabMisc from "./components/tab-misc.vue";
import Backbone from "backbone";

export default {
    name: 'debug-modal',
    components: {
        TabGuess,
        TabCourseIcons,
        TabLu,
        TabMisc,
        VButton,
        CloseButton,
        Tabs,
        Tab
    },
    data: function () {
        return {
            isOpen: false,
            autoPlayOpen: false
        }
    },
    methods: {
        open() {
            this.isOpen = true;
            document.body.classList.add('modal-open');
        },
        close() {
            this.isOpen = false;
            document.body.classList.remove('modal-open');
        },
        startAutoPlay() {
            Backbone.history.navigate('guess', { trigger: true });
            this.close();
            setTimeout(() => {
                EventBus.$emit('guess:debug-autoplay-start');
                this.autoPlayOpen = true;
            }, 500);
        },
        stopAutoPlay() {
            EventBus.$emit('guess:debug-autoplay-stop');
            this.autoPlayOpen = false;
        },
        keyDown(event) {
            if (event && event.keyCode === 27) { // close on ESC key
                this.close()
            }
        },
        listenEventBus() {
            EventBus.$on('debug:open', this.open);
            EventBus.$on('debug:close', this.close);
            EventBus.$on('debug:start-autoplay', this.startAutoPlay);
        },
        unListenEventBus() {
            EventBus.$off('debug:open', this.open);
            EventBus.$off('debug:close', this.close);
            EventBus.$off('debug:start-autoplay', this.startAutoPlay);
        },
    },
    mounted() {
        this.listenEventBus();
        document.addEventListener('keydown', this.keyDown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
        this.unListenEventBus();
    }
};
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.debug-modal {
        direction: ltr;
        font-family: $defaultFonts;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(#AC6ED1, 0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        > div.debug-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(700);
            min-height: pxToRem(600);
            display: flex;
            flex-direction: column;
            max-width: 35vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: 100vh;
                border-radius: unset;
                padding: 1rem;
                font-size: fontSize(12);
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                z-index: 1500;
                &:hover {
                    transform: scale(1.2);
                }
                @include respond-to('small') {
                    right: 1rem;
                    top: 1rem;
                }
            }

            > div.debug-tabs {
                > div.debug-tab-content {
                    h2 {
                        font-size: fontSize(20);
                        min-height: pxToRem(40);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    div.select {
                        display: inline-block;
                        max-width: 100%;
                        position: relative;
                        vertical-align: top;
                        > select {
                            border-radius: .5rem;
                            padding: .5rem 2.5em .5rem .5rem;
                            border: 1px solid $colorBackgroundGraySecondary;
                            position: relative;
                            &:active,
                            &:focus {
                                border-color: $colorSourceSecondary;
                                box-shadow: 0 0 0 .125em rgba($colorSourceSecondary,.25);
                            }
                            &:hover {
                                border-color: $colorSourceSecondary;
                            }
                        }
                        &::after {
                            right: 1.125em;
                            z-index: 4;
                            border: 3px solid $colorSourceSecondary;
                            border-radius: 2px;
                            border-right: 0;
                            border-top: 0;
                            content: " ";
                            display: block;
                            height: .625em;
                            margin-top: -.4375em;
                            pointer-events: none;
                            position: absolute;
                            top: 50%;
                            transform: rotate(-45deg);
                            transform-origin: center;
                            width: .625em;
                        }
                    }
                }
            }
        }
    }
    div.debug-auto-play-stop {
        @include elevated;
        direction: ltr;
        position: fixed;
        z-index: 10000;
        bottom: 8rem;
        left: 0;
        padding: 1rem;
        background-color: #fff;
        body[data-interface-language="ar"] & {
            left: unset;
            right: 0;
        }
    }
</style>
