var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9.707 3.293a1 1 0 010 1.414l-2 2a1 1 0 01-1.414-1.414l2-2a1 1 0 011.414 0zM4 8h6a1 1 0 110 2H6v3h3.5a1 1 0 110 2H6v3h4a1 1 0 110 2H4V8zm15.207 2.207l.5-.5a1 1 0 00-1.414-1.414l-.5.5a.414.414 0 01-.586 0 2.414 2.414 0 00-3.414 0l-.5.5a1 1 0 001.414 1.414l.5-.5a.414.414 0 01.586 0 2.414 2.414 0 003.414 0zM13 19v-6a1 1 0 112 0v.58l1.066-.747c1.657-1.16 3.934.025 3.934 2.048V19a1 1 0 11-2 0v-4.119a.5.5 0 00-.787-.41L15 16.021V19a1 1 0 11-2 0z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }