import Backbone from 'backbone';
import renderTemplate from '../../util/template.renderer.js';
import $ from 'jquery';
import googleAnalyticsCommand from '../../util/google-analytics.js';

const CopyLinkModel = Backbone.Model.extend({
    defaults: {
        mounted: false,
        link_url: '',
        variation_uuid: null
    }
});

const CopyLinkView = Backbone.View.extend({

    className: 'copy-link',

    events: {
        'click div.action': 'copyLink',
    },

    initialize: function (data) {
        console.log('Initialize CopyLinkView');
        this.model = new CopyLinkModel();
        this.model.set('link_url', data.copy_link_url);
        this.listenTo(this.model, 'change', this.updateView);
    },

    render: function () {
        renderTemplate('copy-link', this.model.attributes, this.$el).then(() => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
        this.$referralLink = this.$('input.referral-link');
    },

    updateView: function ({ changed }) {
        if (changed.mounted) {
            this.render();
        }

        if (changed.link_url) {
            this.render();
        }
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    copyLink: function (event) {
        const $referralLinkWrapper = $(event.currentTarget).parent();
        $referralLinkWrapper.addClass('copied');
        this.$referralLink.select();
        document.execCommand('copy');

        setTimeout(() => {
            $referralLinkWrapper.removeClass('copied');
            this.$referralLink.blur();
        }, 3000);

        googleAnalyticsCommand('send', 'event', 'CourseWizard', 'CourseWizardCourseLinkCopied');
        this.trigger('copy-link', this.model.get('variation_uuid'));
    }

});

export default CopyLinkView;
