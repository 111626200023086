'use strict';


import URI from './util/uri.js';


import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import { parse as parse_qs } from './util/query-string.js';
import i18nUtils from './util/i18n.js';

import UserModel from './model/user.model.js';

import { CONFIRM_ACTION, MESSAGE_CLASS, ConfirmationView } from './view/util/confirmation.view.js';
import {
    PostSigninUpdateFeaturesAction,
    PostSigninUpdateCourseVariationAction,
    PostSigninOpenURI,
    PostSigninUpdateRoleAction,
    PostSigninNavigateTo
} from './controller/post.signin.actions.js';
import ControllerManager from './modules/controller.manager.js';
import { CredentialsManager } from './util/credentials.js';
import { UserAccounts } from './modules/user.accounts.js';
import jwtDecode from 'jwt-decode';
import UserManager from './modules/usermanager.js';
import { getRedirectURI } from './modules/third_party_signin/apple.js';
import OauthProviderModel from './model/oauth-provider.model.js';

/**
 * https://github.com/lingvist/webapp/wiki/Action-Links
 */

class MissingRequiredParameterException extends Error {
    constructor (parameter_name) {
        super(`Missing parameter "${parameter_name}"`);
        this.name = 'MissingRequiredParameterException';
    }
}

export default class UrlParameters {
    /**
     *
     * @param api_client - An (unauthenticated) instance of the ApiClient
     * @param search -
     * @param fragment -
     */
    constructor (api_client, search, fragment) {
        this._api_client = api_client;
        this._fragment = fragment;
        this._hash = fragment.split('?')[0];
        this._parsedParameters = parse_qs(search, true);

        this.uiLanguage = this._parsedParameters.ui;
        delete this._parsedParameters.ui;
    }

    _setUiLanguageIfNecessary () {
        if (this.uiLanguage !== undefined) {
            return Promise.resolve()
                .then(() => i18nUtils.setInterfaceLanguage(this.uiLanguage));
        } else {
            return Promise.resolve();
        }
    }

    static removeSearchString () {
        window.history.replaceState({}, document.title, '/' + location.hash); // Remove search string
    }

    initializeApp () {
        let promise = this._setUiLanguageIfNecessary();

        return promise.then(() => {
            // Perform an action
            if (this._parsedParameters.action) {

                let action = this._parsedParameters.action;
                delete this._parsedParameters.action;

                switch (action) {
                    case 'add-course':
                        return Promise.resolve().then(() => {
                            return UserModel.assureSignedIn(this._fragment, {noNavigateOnSuccess: true});
                        }).then(result => {
                            if (result.code === 'success') {
                                Backbone.trigger('showLoader', 'add-course');
                                return Promise.resolve()
                                    .then(() => UserManager.instance.getUser().enrolToCourse(this._parsedParameters.course_uuid))
                                    .then(() => UserModel.setCourse(this._parsedParameters.course_uuid));
                            } else {
                                return Promise.resolve();
                            }
                        }).then(() => {
                            Backbone.history.navigate('guess', {trigger: true});
                        });

                    case 'activate-course':
                        return Promise.resolve()
                            .then(() => UserModel.assureSignedIn(this._fragment, {noNavigateOnSuccess: true}))
                            .then(result => {
                                if (result.code === 'success') {
                                    Backbone.trigger('showLoader', 'activate-course');
                                    return Promise.resolve()
                                        .then(() => UserModel.setCourse(this._parsedParameters.course_uuid))
                                        .then(() => Backbone.history.navigate('hub', {trigger: true}));
                                }
                                // else branch is not needed, as assureSignedIn takes care of error navigation
                            });

                    case 'set-experiment-overrides':
                        if (this._parsedParameters.experiment_overrides === undefined) {
                            throw new MissingRequiredParameterException('experiment_overrides');
                        }
                        return Promise.resolve().then(() => {
                            return UserModel.assureSignedIn(this._fragment, {noNavigateOnSuccess: true});
                        }).then(result => {
                            if (result.code === 'success') {
                                Backbone.trigger('showLoader', 'activate-course');
                            } else {
                                return Promise.resolve();
                            }
                        }).then(() => {
                            window.location.href = window.location.origin;
                            Backbone.history.navigate('guess', {trigger: true});
                        });

                    case 'sso':
                        Backbone.history.navigate('sso', { trigger: true });
                        break;

                    case 'signout':
                        return Promise.resolve().then(() => {
                            return UserModel.assureSignedIn(this._fragment, {noNavigateOnSuccess: true});
                        }).then(result => {
                            if (result.code === 'success') {
                                return UserModel.signout(false);
                            } else {
                                Backbone.history.navigate('signin', {trigger: true});
                                return Promise.resolve();
                            }
                        });

                    case 'delete-account':
                        return Promise.resolve()
                            .then(() => {
                                if (this._parsedParameters.token) {
                                    return Promise.resolve()
                                        .then(() => this._api_client.r.user.delete.post({
                                            token: this._parsedParameters.token
                                        }))
                                        .then(() => null)
                                        .catch(error => (error.response && error.response.body && error.response.body.code || null));
                                } else {
                                    return Promise.resolve('malformed-url');
                                }
                            })
                            .then(error => {
                                let message;

                                if (error !== null) {
                                    message = i18nUtils.prop(error, {}, {}, 'modal_confirmation_account_deletion_error_message');
                                } else {
                                    message = i18nUtils.prop('modal_confirmation_account_deletion_message');
                                }

                                let confirmationView = new ConfirmationView({
                                    title: i18nUtils.prop('modal_confirmation_account_deletion_title'),
                                    message: message,
                                    message_class: error === null ? undefined : MESSAGE_CLASS.ERROR,
                                    actions: error === null ? [
                                        {
                                            title: i18nUtils.prop('modal_confirmation_okay'),
                                            action: CONFIRM_ACTION.OK
                                        }
                                    ] : []
                                });

                                confirmationView.on('confirm', () => {

                                    const decodedToken = jwtDecode(this._parsedParameters.token);
                                    if (decodedToken && decodedToken.uuid) {
                                        const _userAccounts = new UserAccounts();
                                        _userAccounts.deleteUserAccount(decodedToken.uuid);
                                    }

                                    UserModel.signout(false);
                                    Promise.resolve()
                                        .then(() => confirmationView.hide())
                                        .then(() => {
                                            confirmationView.remove();
                                        });
                                });

                                confirmationView.show();
                            });
                    case 'open-uri':
                        return Promise.resolve()
                            .then(() => UserModel.assureSignedIn(this._fragment, {
                                noNavigateOnSuccess: true, noNavigateInFailure: true}))
                            .then(result => {
                                if (result.code === 'success' || URI.canNavigateUnauthenticated(this._parsedParameters.uri)) {
                                    return new URI(this._parsedParameters.uri).navigateTo();
                                } else {
                                    ControllerManager.instance.getController('PostSigninActions').add_action(
                                        new PostSigninOpenURI(this._parsedParameters.uri)
                                    );
                                    Backbone.history.navigate('signin', { trigger: true });
                                    return Promise.resolve();
                                }
                            });
                    case 'set-features':
                        return Promise.resolve()
                            .then(this._parsedParameters.features ? () => {
                                ControllerManager.instance.getController('PostSigninActions').add_action(
                                    new PostSigninUpdateFeaturesAction(JSON.parse(this._parsedParameters.features))
                                );
                                return Promise.resolve();
                            } : Promise.resolve())
                            .then(() => UserModel.assureSignedIn(this._fragment))
                            .then(() => {
                                UrlParameters.removeSearchString();
                                return Promise.resolve();
                            });
                    case 'activate-course-and-variation':
                        return Promise.resolve()
                            .then(this._parsedParameters.course_uuid && this._parsedParameters.variation_uuid ? () => {
                                ControllerManager.instance.getController('PostSigninActions').add_action(
                                    new PostSigninUpdateCourseVariationAction(this._parsedParameters.course_uuid, this._parsedParameters.variation_uuid)
                                );
                                return Promise.resolve();
                            } : Promise.resolve())
                            .then(() => UserModel.assureSignedIn(this._fragment))
                            .then(() => {
                                Backbone.history.navigate(`register?${$.param({
                                    course_uuid: this._parsedParameters.course_uuid,
                                })}`, {trigger: true});
                            })
                            .then(() => {
                                UrlParameters.removeSearchString();
                                return Promise.resolve();
                            });
                    case '_oauth': {
                        // Wrapped in async function locally for convenience
                        return (async () => {
                            // Internal oauth redirect for apple sign-in
                            const error = this._parsedParameters.error === "null" ? null : this._parsedParameters.error;
                            const provider = this._parsedParameters.provider;
                            const code = this._parsedParameters.code;
                            const state = this._parsedParameters.state;
                            const user = this._parsedParameters.user;
                            const sso_flow = this._parsedParameters.sso_flow;
                            const redirect_uri = getRedirectURI();
                            UrlParameters.removeSearchString();

                            if (sso_flow) {
                                await OauthProviderModel.loadSsoParams();
                            }

                            // TODO: Could we move this over to register2?

                            const signin_controller = ControllerManager.instance.getController('Signin');

                            signin_controller.on('view-initial-rendered', () => {
                                signin_controller.continueOAuth(error, {provider, code, state, user, redirect_uri});
                            });

                            Backbone.history.navigate('signin', {trigger: true});
                        })();
                    }
                }
            } else if (this._parsedParameters.page) { // Navigate to specific page
                let page = this._parsedParameters.page;
                delete this._parsedParameters.page;

                // URI encode components with lodash - jquery.param messes them up sometimes
                let pageParams = _.map(this._parsedParameters, (value, key) => `${key}=${encodeURIComponent(value)}`, []).join('&');

                if ({
                        'register': true,
                        'signin': true,
                        'signin-credentials': true,
                        'forgot-password': true
                    }[page]
                ) {
                    // Check if there are some features to set or not …
                    // Direct user to default location determined by assureSignedIn on success, otherwise navigate
                    let _features = (this._parsedParameters.features) ? JSON.parse(this._parsedParameters.features) : null;
                    return Promise.resolve()
                        .then(_features ? () => {
                            ControllerManager.instance.getController('PostSigninActions').add_action(
                                new PostSigninUpdateFeaturesAction(_features)
                            );
                            return Promise.resolve();
                        } : Promise.resolve())
                        .then(this._parsedParameters.role ? () => {
                            ControllerManager.instance.getController('PostSigninActions').add_action(
                                new PostSigninUpdateRoleAction(this._parsedParameters.role)
                            );
                            return Promise.resolve();
                        } : Promise.resolve())
                        .then(() => UserModel.assureSignedIn(this._fragment, {
                            failureNavigateTarget: `${page}?${pageParams}`
                        }))
                        .then(() => {
                            UrlParameters.removeSearchString();
                            return Promise.resolve();
                        });
                } else {
                    // Even for logged in users navigate to given page
                    switch (page) {
                        case 'verify-email':
                            Backbone.history.navigate('verify-email?' + pageParams, { trigger: true });
                            break;

                        case 'account':
                            return Promise.resolve().then(() => {
                                return UserModel.assureSignedIn(this._fragment, {
                                    noNavigateOnSuccess: true,
                                    interfaceLanguageOverride: this.uiLanguage
                                });
                            }).then(result => {
                                if (result.code === 'success') {
                                    Backbone.history.navigate('account?' + pageParams, { trigger: true });
                                    return Promise.resolve();
                                } else {
                                    Backbone.history.navigate('signin', {trigger: true});
                                    return Promise.resolve();
                                }
                            });
                    }
                }
            } else if (this._hash === 'register' ||
                       this._hash.startsWith('register') ||
                       this._hash === 'sso' ||
                       this._hash === 'forgot-password' ||
                       this._hash === 'home' ||
                       this._hash === 'reset-password' ||
                       this._hash === 'request-language' ||
                       this._hash === 'verify-email') {
                Backbone.history.navigate(this._fragment, {trigger: true});
            } else if (this._hash.includes('subscriptions')) {
                return Promise.resolve().then(async () => {
                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateTo(this._hash)
                    );
                    await UserModel.assureSignedIn(this._fragment);
                });
            } else {
                return Promise.resolve()
                    .then( async () => await UserModel.assureSignedIn(this._fragment, {}))
                    .then( async ({ code }) => {
                        if (code === 'no-token') {

                            let credentials = null;
                            try {
                                credentials = await CredentialsManager.getCredentials();
                            } catch (error) {
                                if (error === 'not-available') {
                                    console.info('No credentials available in SmartLock password manager');
                                } else if (error === 'error-authentication') {
                                    Backbone.trigger('smartLockSignInError');
                                } else {
                                    console.warn('Error getting credentials from SmartLock: ', error);
                                }
                            }

                            if (credentials !== null) {
                                Backbone.trigger('signinInProgress', 'signin');
                                try {
                                    await UserModel.login(credentials.user, credentials.password);
                                    Backbone.trigger('signinProcessFinished');
                                } catch (error) {
                                    throw error;
                                }
                            }
                        }
                    });
            }
        });
    }
}
