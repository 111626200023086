
import _ from 'lodash';
import moment from 'moment';

import { StatisticsProcessorUtils, EVENT_TYPE } from '../processor.utils.js';

export class StatisticsGuessEventProcessor {

    static canProcess (event) {
        return _.startsWith(event.schema, 'urn:lingvist:schemas:events:guess:0.');
    }

    static process (event, courseStatistics) {
        // TODO: Some part of this function could probably be refactored out of here because it is
        // TODO: the same for all events

        if (event.__statisticsDataV1 === undefined) {
            const requiredPrecision = parseFloat(event.data.evaluation_criteria.required_precision),
                answerCount = event.data.simple_algorithm_state.answer_count,
                errorCount = event.data.simple_algorithm_state.error_count,
                previous_guess_value = event.data.simple_algorithm_state.guess_value;

            event.__statisticsDataV1 = {
                type: EVENT_TYPE.GUESS,
                lexical_unit_uuid: event.data.lexical_unit_uuid,
                homograph_uuid: event.data.homograph_uuid,
                sense_uuid: event.data.sense_uuid,
                context_uuid: event.data.context_uuid,
                eventTS: moment(event.client_event_ts),
                clientSN: event.client_sn,
                isCorrect: parseFloat(event.data.guess_value) > requiredPrecision,
                isNew: StatisticsProcessorUtils.is_guess_event_new_word(event),
                answerCount: answerCount || 0,
                errorCount: errorCount || 0,
                previous_is_correct: previous_guess_value !== null && parseFloat(previous_guess_value) > requiredPrecision,
                variation_uuid: event.data.variation_uuid,
                favourite: event.data.favourite
            };
        }

        const currentStatistics = courseStatistics.getData(),
            updatedStatistics = _.cloneDeep(currentStatistics),
            eventStatistics = event.__statisticsDataV1;

        updatedStatistics.all_units.total = currentStatistics.all_units.total + 1;

        if (eventStatistics.isCorrect) {
            updatedStatistics.all_units.correct = currentStatistics.all_units.correct + 1;
        }

        if (eventStatistics.isNew) {
            updatedStatistics.new_units.total = currentStatistics.new_units.total + 1;
            if (eventStatistics.isCorrect) {
                updatedStatistics.new_units.correct = currentStatistics.all_units.correct + 1;
            }
        }

        if (!eventStatistics.isNew) {  // Is repeat
            updatedStatistics.repeat_units.total = currentStatistics.repeat_units.total + 1;

            if (eventStatistics.isCorrect) {
                updatedStatistics.repeat_units.correct = currentStatistics.repeat_units.correct + 1;
            }

            if (eventStatistics.previous_is_correct && !eventStatistics.isCorrect && eventStatistics.errorCount > 0) {
                updatedStatistics.flips.negative++;
            } else if (!eventStatistics.previous_is_correct && eventStatistics.isCorrect) {
                updatedStatistics.flips.positive++;
            }

            if (eventStatistics.previous_is_correct && eventStatistics.isCorrect) {
                updatedStatistics.kicks++;
            }
        }

        const timeBetweenEvents = eventStatistics.eventTS.diff(currentStatistics.client_event_ts, 'seconds');

        const updatedHistory = StatisticsProcessorUtils.getUpdatedHistory(
            eventStatistics, _.cloneDeep(courseStatistics.getHistory()), timeBetweenEvents);

        updatedStatistics.client_event_ts = eventStatistics.eventTS;
        updatedStatistics.study_time = StatisticsProcessorUtils.bumpTime(timeBetweenEvents, currentStatistics.study_time);

        courseStatistics.getLearnedWords().update(
            eventStatistics,
            courseStatistics.getCourse().getLexicalUnitDataCache().getLexicalUnitData(event.data.lexical_unit_uuid)
        );

        updatedStatistics.client_sn = event.client_sn;
        return [updatedStatistics, updatedHistory];
    }
}
