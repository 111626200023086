var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#63E1E7",
          d: "M1 22.6c0 .7.5 1.2 1.2 1.2H19c.7 0 1.2-.5 1.2-1.2v-1.4c0-.7-.5-1.2-1.2-1.2H6.2c-.7 0-1.2-.5-1.2-1.2V7.2C5 6.5 4.5 6 3.8 6H2.2C1.5 6 1 6.5 1 7.2v15.4z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M4 4c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v1c0 .6-.4 1-1 1s-1-.4-1-1V4H6v15h14v-9c0-.6.4-1 1-1s1 .4 1 1v10c0 .6-.4 1-1 1H5c-.3 0-.5-.1-.7-.3S4 20.3 4 20V4z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M17 7c0 1.7-1.3 3-3 3h-2c-1.7 0-3-1.3-3-3V6c0-.6.4-1 1-1s1 .4 1 1v1c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V6c0-.6.4-1 1-1s1 .4 1 1v1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }