var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "section",
        { staticClass: "app-header" },
        [
          _c("header-logo", {
            nativeOn: {
              click: function ($event) {
                return _vm.goLogin.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _vm.signInButtonText && _vm.isItRegisterFlow
                ? _c("v-button", {
                    attrs: { text: _vm.signInButtonText, filled: true },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goLogin.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("language-selection"),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }