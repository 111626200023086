var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#2C3143",
          d: "M11.6 4.1c.2-.1.5-.1.8 0l10 4.2c.3.1.6.4.6.8s-.2.8-.6.9l-1.4.8v4.4c0 .6-.4 1-1 1s-1-.4-1-1v-3.5l-1 .5v3.4c0 .4-.2.7-.6.9l-5 2.4c-.3.1-.6.1-.9 0l-5-2.4c-.3-.2-.5-.5-.5-.9v-.9c0-.6.4-1 1-1s1 .4 1 1v.3l4 1.9 4-1.9v-1.8l-3.6 1.7c-.3.1-.6.1-.9 0l-5-2.4c-.5-.2-.7-.8-.5-1.3.2-.5.8-.7 1.3-.5l4.6 2.2 7.6-3.7L12 6.1 4.4 9.2c.5.2.7.8.5 1.3-.2.5-.8.7-1.3.5l-2-1c-.4-.1-.6-.5-.6-.9s.3-.7.6-.9l10-4.1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#AC6ED1",
          d: "M21 18c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }