import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import ConversationView from '../view/challenges/conversation.view.js';

export class ConversationController {
    async go(appView, router, exerciseUuid) {
        let exercise = UserManager.instance.getUser().getCourse().getExercises().getExercise(exerciseUuid);
        const exerciseAnswer = exercise.getExerciseAnswer();
        const exerciseData = await exercise.getData();

        const conversationView = new ConversationView();
        conversationView.setProps(exerciseData);

        conversationView.on('exercise-entry', (type, data) => {
            exerciseAnswer.addEntry(type, data);
        });

        conversationView.on('exercise-end', result => {
            if (!exerciseAnswer.finished) {
                exerciseAnswer.complete(result.finished);
            }
            if (result.finished) {
                UserManager.instance.getUser().getCourse().getExercises().getExercise(exerciseUuid).setCompleted();
            }
            if (exerciseAnswer.hasEntries()) {
                UserManager.instance.getUser().getEventSender().sendSpeakingExerciseEvent(exerciseAnswer);
            }
        });

        appView.setMainView(conversationView, true);
        conversationView.render();
    }
}

export const conversation_controller_factory = new ControllerFactory(ConversationController);
