var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          opacity: ".5",
          fill: "#FAAA5A",
          d: "M21 9c0 1.1-.9 2-2 2h-6V5c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v4zm0 10c0 1.1-.9 2-2 2h-4c-1.1 0-2-.9-2-2v-6h6c1.1 0 2 .9 2 2v4zm-10 0c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h6v6z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#FAAA5A",
          d: "M11 11H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v8z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }