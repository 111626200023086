<template>
    <div class="message-icon">
        <component :is="icon" :data-name="iconName" />
    </div>
</template>

<script>
    import studentCap from "Images/student-cap-24x24.svg";
    import brainTraining from "Images/icons/demographic-icons/brain-training.svg";

    export default {
        name: 'message-icon',
        props: {
            iconName: {
                type: String,
                required: true
            },
        },
        computed: {
            icon() {
                switch (this.iconName) {
                    case 'student-cap':
                        return studentCap;
                    case 'brain-training':
                        return brainTraining;
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/_mixins";
    div.message-icon {
        border-radius: pxToRem(8);
        min-width: pxToRem(50);
        min-height: pxToRem(50);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: $colorBackgroundLighter;
        > svg {
            width: pxToRem(24);
            height: pxToRem(24);
        }
    }
</style>
