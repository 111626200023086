var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "56",
          height: "56",
          viewBox: "0 0 56 56",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M24.334 38.131l-.812-4.874c-.266-2.17-2.011-3.662-4.022-3.662v14.103c0 2.438 1.079 4.875 2.823 6.513 3.223 2.983 8.338 3.116 11.694.133l4.701-4.062c.813-.68.946-1.891.133-2.85-.666-.812-1.878-.946-2.823-.133l-.666.546 1.878-1.625c.812-.68.945-1.891.266-2.717-.666-.812-2.01-.945-2.823-.266-.946.812-1.878 1.625-1.878 1.625l2.823-2.438c1.292-1.078.932-2.117.267-2.85-.56-.612-1.758-.679-2.558 0l-2.676 2.304 7.258-6.233c.812-.679.946-1.89.133-2.716-.666-.813-2.01-.946-2.823-.267-1.612 1.412-4.315 3.77-6.62 5.78M31.048 25c-.453.733-1.012 1.665-1.651 2.637M25.839 27.637c-.64-.972-1.199-1.904-1.651-2.637",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
        },
      }),
      _c("circle", { attrs: { cx: "28", cy: "12", r: "9", fill: "#AC6ED1" } }),
      _c("path", {
        attrs: {
          d: "M24.5 8.5l7 7M31.5 8.5l-7 7",
          stroke: "#9DF4F4",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }