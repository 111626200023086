var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "course-icons-debug" }, [
    _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("List of available course-icons")]),
      _vm._v(" "),
      _c("div", { staticClass: "select" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.size,
                expression: "size",
              },
            ],
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.size = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { selected: "", value: "" } }, [
              _vm._v("default size"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "small" } }, [_vm._v("small size")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "medium" } }, [
              _vm._v("medium size"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "large" } }, [_vm._v("large size")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "icon-list" },
      _vm._l(_vm.icons, function (i) {
        return _c("course-icon", {
          key: `course-icon-${i}`,
          attrs: { value: i, title: i, size: _vm.size },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }