var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "survey", attrs: { "data-type": this.type } },
    [
      !_vm.visible
        ? _c(
            "div",
            { staticClass: "floater", on: { click: _vm.openBox } },
            [
              _c("span", { staticClass: "tooltip" }, [
                _vm._v(_vm._s(_vm.hoverTooltip)),
              ]),
              _vm._v(" "),
              this.type === "impact"
                ? _c("survey-impact-icon")
                : _c("survey-icon"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visible
        ? _c(
            "div",
            { staticClass: "box" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.closeBox },
              }),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.thanks
                  ? _c(
                      "div",
                      { staticClass: "thank-you" },
                      [
                        _c("happy-emoji"),
                        _vm._v(" "),
                        _c("h3", {
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n(
                                "lessons_creator_survey_happiness_feedback_toast_confirm_text"
                              )
                            ),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              !_vm.thanks
                ? [
                    _c("h3", [_vm._v(_vm._s(_vm.surveyTitle))]),
                    _vm._v(" "),
                    _vm.type === "impact"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "scores",
                              on: { mouseleave: _vm.clearHoverScore },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "score",
                                  class: {
                                    unselected: this.score && _vm.score !== 1,
                                    selected: this.score && _vm.score === 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setScore(1)
                                    },
                                    mouseover: function ($event) {
                                      return _vm.setHoverScore(1)
                                    },
                                  },
                                },
                                [
                                  _c("star-icon", {
                                    attrs: {
                                      filled:
                                        (this.score && _vm.score >= 1) ||
                                        _vm.hoverScore >= 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "score",
                                  class: {
                                    unselected: this.score && _vm.score !== 2,
                                    selected: this.score && _vm.score === 2,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setScore(2)
                                    },
                                    mouseover: function ($event) {
                                      return _vm.setHoverScore(2)
                                    },
                                  },
                                },
                                [
                                  _c("star-icon", {
                                    attrs: {
                                      filled:
                                        (this.score && _vm.score > 1) ||
                                        _vm.hoverScore > 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "score",
                                  class: {
                                    unselected: this.score && _vm.score !== 3,
                                    selected: this.score && _vm.score === 3,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setScore(3)
                                    },
                                    mouseover: function ($event) {
                                      return _vm.setHoverScore(3)
                                    },
                                  },
                                },
                                [
                                  _c("star-icon", {
                                    attrs: {
                                      filled:
                                        (this.score && _vm.score > 2) ||
                                        _vm.hoverScore > 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "score",
                                  class: {
                                    unselected: this.score && _vm.score !== 4,
                                    selected: this.score && _vm.score === 4,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setScore(4)
                                    },
                                    mouseover: function ($event) {
                                      return _vm.setHoverScore(4)
                                    },
                                  },
                                },
                                [
                                  _c("star-icon", {
                                    attrs: {
                                      filled:
                                        (this.score && _vm.score > 3) ||
                                        _vm.hoverScore > 3,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "score",
                                  class: {
                                    unselected: this.score && _vm.score !== 5,
                                    selected: this.score && _vm.score === 5,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setScore(5)
                                    },
                                    mouseover: function ($event) {
                                      return _vm.setHoverScore(5)
                                    },
                                  },
                                },
                                [
                                  _c("star-icon", {
                                    attrs: {
                                      filled:
                                        (this.score && _vm.score === 5) ||
                                        _vm.hoverScore === 5,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "labels" }, [
                            _c("span", {
                              staticClass: "minimum",
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n("survey_impact_rate_minimum_label")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "maximum",
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n("survey_impact_rate_maximum_label")
                                ),
                              },
                            }),
                          ]),
                        ]
                      : _c("div", { staticClass: "scores" }, [
                          _c(
                            "div",
                            {
                              staticClass: "score",
                              class: {
                                unselected: this.score && _vm.score !== 1,
                                selected: this.score && _vm.score === 1,
                              },
                              attrs: { title: _vm.ratingTooltip(1) },
                              on: {
                                click: function ($event) {
                                  return _vm.setScore(1)
                                },
                              },
                            },
                            [_c("mad-emoji")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "score",
                              class: {
                                unselected: this.score && _vm.score !== 2,
                                selected: this.score && _vm.score === 2,
                              },
                              attrs: { title: _vm.ratingTooltip(2) },
                              on: {
                                click: function ($event) {
                                  return _vm.setScore(2)
                                },
                              },
                            },
                            [_c("un-happy-emoji")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "score",
                              class: {
                                unselected: this.score && _vm.score !== 3,
                                selected: this.score && _vm.score === 3,
                              },
                              attrs: { title: _vm.ratingTooltip(3) },
                              on: {
                                click: function ($event) {
                                  return _vm.setScore(3)
                                },
                              },
                            },
                            [_c("neutral-emoji")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "score",
                              class: {
                                unselected: this.score && _vm.score !== 4,
                                selected: this.score && _vm.score === 4,
                              },
                              attrs: { title: _vm.ratingTooltip(4) },
                              on: {
                                click: function ($event) {
                                  return _vm.setScore(4)
                                },
                              },
                            },
                            [_c("happy-emoji")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "score",
                              class: {
                                unselected: this.score && _vm.score !== 5,
                                selected: this.score && _vm.score === 5,
                              },
                              attrs: { title: _vm.ratingTooltip(5) },
                              on: {
                                click: function ($event) {
                                  return _vm.setScore(5)
                                },
                              },
                            },
                            [_c("awesome-emoji")],
                            1
                          ),
                        ]),
                    _vm._v(" "),
                    _vm.score
                      ? _c(
                          "div",
                          { staticClass: "more" },
                          [
                            _c("h4", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n(
                                    "lessons_creator_survey_happiness_feedback_form_title"
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.message,
                                  expression: "message",
                                },
                              ],
                              attrs: {
                                placeholder: this.$i18n(
                                  "lessons_creator_survey_happiness_feedback_form_placeholder"
                                ),
                                maxlength: "500",
                              },
                              domProps: { value: _vm.message },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.message = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("v-button", {
                              attrs: {
                                i18n: {
                                  id: "lessons_creator_survey_happiness_button_send",
                                },
                                filled: true,
                                color: "primary",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendSurvey.apply(null, arguments)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }