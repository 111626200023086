
import $ from 'jquery';
import Backbone from 'backbone';
import moment from 'moment';

import AudioPlayer from '../../util/audioplayer.js';
import renderTemplate from '../../util/template.renderer.js';
import getConfigValue from '../../util/configuration.js';

import UserManager from '../../modules/usermanager.js';
import { TYPE as EXERCISE_TYPE } from '../../modules/exercises/constants.js';

const MultipleChoiceView = Backbone.View.extend({

    name: 'challenge.multiple-choice',
    tagName: 'main',
    className: 'multiple-choice-view',

    events: {
        'click a.close': 'closeButtonClicked',
        'click a.back': 'backButtonClicked',
        'click a.answer': 'answerClicked',
        'click a.dialog-button': 'dialogButtonClicked',
        'click section.dialog-correct-container': 'dialogBackgroundClicked',

        'click a.play-button': 'playAudio',
        'click a.notification-button': 'notificationButtonClicked'
    },

    initialize: function () {

        this.props = {};

        this.state = {
            step: 'initial',
        };

        AudioPlayer.onLoaded( () => {
            this.$playButton.removeClass('loading');
        });

        AudioPlayer.onPlayStart( () => {
            this.$playButton.addClass('playback-in-progress');
        });

        AudioPlayer.onPlayEnd( () => {
            this.$playButton.removeClass('playback-in-progress');
            this.$('div.question-content').removeClass('disabled');
            cancelAnimationFrame(this.animationRequest);
        });

        AudioPlayer.onPaused( () => {
            this.$playButton.removeClass('playback-in-progress');
            cancelAnimationFrame(this.animationRequest);
        });

        this.targetLanguage = UserManager.instance.getUser().getCourse().getInfo().target_language;
    },

    setProps: async function (props) {
        this.props = props;

        const { type, audio_hash } = props;

        if (type === EXERCISE_TYPE.LISTENING) {
            const mediaUrl = getConfigValue('media-base-url');
            const voiceUuid = await UserManager.instance.getUser().getCourse().getVoiceUUID();
            const audioPath = `${mediaUrl}/v1/${voiceUuid}/exercise/${audio_hash}.mp3`;
            await AudioPlayer.loadListenChallengeAudio(audioPath);
        }
    },

    remove: function () {
        if (this.props.type === EXERCISE_TYPE.LISTENING) {
            AudioPlayer.stopChallengeAudioPlayback();
        }
        // If user did not complete exercise,
        // trigger "exercise-ended" to send entry events
        if (this.state.step === 'initial') {
            this.trigger('exercise-ended', false);
        }
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {

        const renderData = {
            props: this.props,
            state: this.state
        };

        renderTemplate('challenges/multiple-choice', renderData, this.$el).then( () => {
            this._postRender();
        });

        return this;
    },

    playAudio: function (event) {

        const updateProgressBar = () => {
            const position = AudioPlayer.getListenChallengePosition();
            const progress = AudioPlayer.getListenChallengeProgress();

            const duration = moment.duration(position, 'seconds');

            const minutes = duration.get('minutes');
            let seconds = duration.get('seconds');
            seconds = (seconds < 10) ? `0${seconds}`: seconds;

            this.$('p.time').html(`${minutes}:${seconds}`);
            this.$('div.progress').css('width', `${progress}%`);
            this.$('div.dot').css('margin-left', `calc(${progress}% - 0.25rem`);

            this.animationRequest = requestAnimationFrame(updateProgressBar);
        };

        if (AudioPlayer.isListenChallengeAudioPlaying()) {
            AudioPlayer.pauseListenChallengeAudio();
        } else {
            AudioPlayer.playListenChallengeAudio();
            this.animationRequest = requestAnimationFrame(updateProgressBar);
        }
    },

    answerClicked: function (event) {
        const answerId = String($(event.currentTarget).data('id'));  // Convert to string because $.data does "it's best" to convert things to ES types.
        const answer = this.props.orderedAnswers.find(answer => {
            return answer.id === answerId;
        });

        this.$('a.answer').removeClass('incorrect');

        if (answer.correct) {

            this.$questionContent.addClass('locked');
            this.$(`a.answer:not([data-id="${answerId}"])`).addClass('discarded');
            this.$(`a.answer[data-id="${answerId}"]`).addClass('correct');

            this.state.step = 'ended';
            this.trigger('exercise-answer-correct', { answerId: answerId });
            this.trigger('exercise-ended', true);

            // Show thumbs up dialog
            this.delay(1000).then( () => {

                this.$correctDialog.addClass('display');
                return this.delay(25);
            }).then( () => {
                this.$correctDialog.addClass('active');
            });
        } else {
            this.$(`a.answer[data-id="${answerId}"]`).addClass('incorrect');
            this.trigger('exercise-answer-incorrect', { answerId: answerId });
        }
    },

    dialogButtonClicked: function (event) {
        const target = $(event.currentTarget).data('target');

        if (target === 'challenges') {
            Backbone.history.navigate('challenges', { trigger: true });
        } else if (target === 'next') {
            Backbone.history.navigate('challenges?action=start-next', { trigger: true });
        }
    },

    dialogBackgroundClicked: function (event) {
        const $element = $(event.target);
        if ($element.is('section.dialog-correct-container')) {
            this.$correctDialog.removeClass('active');
            this.delay(25).then( () => {
                this.$correctDialog.removeClass('display');
            });
        }
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
        this.$correctDialog = this.$('section.dialog-correct-container');
        this.$questionContent = this.$('div.question-content');
        this.$playButton = this.$('a.play-button');
    },

    backButtonClicked: function () {
        Backbone.history.navigate('challenges', { trigger: true });
    },

    delay: function (duration) {
        return new Promise(resolve => setTimeout( () => { resolve(); }, duration));
    },
});

export default MultipleChoiceView;
