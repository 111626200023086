<template>
    <div class="course-progress">
        <words-progress :user="this.user" :course="this.course" v-on="$listeners" />
        <weekly :course="this.course" v-on="$listeners" />
        <day-goals :course="this.course" />
    </div>
</template>

<script>
    import DayGoals from '../../insights/components/day-goals.vue';
    import Weekly from './weekly.vue';
    import moment from 'moment';
    import WordsProgress from "./words-progress.vue";

    export default {
        name: 'course-progress',
        components: {
            WordsProgress,
            DayGoals,
            Weekly
        },
        props: {
            user: Object,
            course: Object
        },
        data() {
            return {
                today: moment().local().startOf('day')
            };
        },
    };
</script>

<style lang="scss">
    @import '~Styles/mixins';

    div.course-progress {
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
        user-select: none;
        grid-template-areas:
            "words-progress weekly"
            "words-progress today";
        @include respond-to('medium') {
            grid-template-columns: 1fr;
            grid-template-areas:
                "words-progress"
                "weekly"
                "today";
        }
        > div.grid-item {
            background-color: white;
            border-radius: pxToRem(12);
            padding: 1rem;
        }
    }
</style>
