
'use strict';

import moment from 'moment';

export class CourseStateConverter {
    static convert (key, value) {
        if (value === null) {
            return value;
        }
        switch (key) {
            case 'registered_ts':
                return moment(value);
            case 'expiration_ts':
                // TODO: Find usages and stop converting for no reason !
                return moment(value);
            case 'predicted_ts_override':
                return moment(value);
            case 'can_cancel_ts':
                return moment(value);
            default:
                return value;
        }
    }
}
