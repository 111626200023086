var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "dropdown-component",
      class: { disabled: _vm.disabled },
      attrs: { "data-size": _vm.size },
      on: { mouseleave: _vm.closeDropdownDelayed },
    },
    [
      _c(
        "span",
        {
          staticClass: "dropdown-button",
          class: [_vm.buttonClass],
          on: { click: _vm.openDropdown },
        },
        [
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm.dropdownOpen
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4 9L8 6L12 9",
                      stroke: "#2C3143",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                    },
                  }),
                ]
              )
            : _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4 7L8 10L12 7",
                      stroke: "#2C3143",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                    },
                  }),
                ]
              ),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.dropdownOpen
          ? _c(
              "span",
              {
                staticClass: "dropdown-content",
                on: {
                  mouseenter: _vm.openDropdown,
                  mouseleave: _vm.closeDropdown,
                },
              },
              _vm._l(_vm.dropdown, function (item) {
                return _c(
                  "span",
                  {
                    staticClass: "dropdown-item",
                    on: {
                      click: function ($event) {
                        return _vm.triggerAction(item.action)
                      },
                    },
                  },
                  [
                    !item.iconName ? _vm._t("icon") : _vm._e(),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$i18n(item.i18n.id, {
                            collection: item.i18n.collection,
                            args: item.i18n.params,
                          })
                        ),
                      },
                    }),
                    _vm._v(" "),
                    item.iconName && item.iconName === "ascending"
                      ? _c("icon-ascending")
                      : item.iconName && item.iconName === "descending"
                      ? _c("icon-descending")
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }