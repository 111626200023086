var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "56",
          height: "56",
          viewBox: "0 0 56 56",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M19 42.5h13.5c1.657 0 3-1.344 3-3.001V34m-23 8.5l-6 5v-23a3 3 0 013-3h8",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("circle", {
        attrs: { cx: "35.5", cy: "34", r: "2", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M12.5 28.5h5M12.5 32.5h17M12.5 36.5h17",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47 8H24a3 3 0 00-3 3v15a3 3 0 003 3h19l7 6V11a3 3 0 00-3-3z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.5 16.3c0-1.546 1.343-2.8 3-2.8s3 1.254 3 2.8c0 .908-.463 1.853-1.18 2.478-.833.726-1.82 1.617-1.82 2.722v.4M35.5 24.5v.5",
          stroke: "#9DF4F4",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }