
'use strict';

import ExportUtils from '../util/export.js';

export class ControllerManager {

    constructor () {
        this._controllers = {};
    }

    addController (name, controller) {
        if (this._controllers[name] !== undefined) {
            throw Error(`Attempted to add duplicate controller with name="${name}"`);
        }

        this._controllers[name] = controller;
    }

    getController (name) {
        if (this._controllers[name] === undefined) {
            throw Error(`Requested a controller that has not been added with name="${name}"`);
        }

        return this._controllers[name];
    }
}

export class ControllerManagerFactory {

    constructor () {}

    _getControllerManager () {
        return new ControllerManager();
    }

    initControllerManager (baseObject) {
        if (baseObject.instance !== null) {
            throw Error('Attempt to initialize ControllerManager multiple times');
        }

        baseObject.instance = this._getControllerManager();
    }
}

const controllerManagerFactory = new ControllerManagerFactory();

const Module = {
    init: function () {
        controllerManagerFactory.initControllerManager(Module);
    },
    instance: null
};

ExportUtils.export('app.modules.ControllerManager', Module);

export default Module;
