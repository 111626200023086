var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "variation-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "variation-modal-window" },
        [
          _c("CloseButton", { staticClass: "close", on: { click: _vm.close } }),
          _vm._v(" "),
          _vm.variation && !_vm.loading
            ? [
                _c("variation-icon", { attrs: { variation: _vm.variation } }),
                _vm._v(" "),
                _c("h2", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.variation.name)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm.variation.difficulty
                      ? _c(
                          "div",
                          { staticClass: "difficulty-info" },
                          [
                            _c("div", {
                              staticClass: "difficulty-label",
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n(this.difficultyName, {
                                    collection: "difficulty_indicator_label",
                                  })
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("difficulty", {
                              attrs: {
                                "show-tooltip": false,
                                difficulty: _vm.variation.difficulty,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isSubscriptionActive && _vm.variation.paid
                      ? [
                          _c("div", { staticClass: "text" }, [
                            _vm.variation.description
                              ? _c("p", [
                                  _vm._v(_vm._s(_vm.variation.description)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n(
                                    "variation_page_upgrade_words_to_learn",
                                    {
                                      args: {
                                        variation_units: _vm.variation.units,
                                      },
                                    }
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "message",
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n(
                                    this.gameOver
                                      ? "variation_page_upgrade_body_1"
                                      : "variation_page_trial_offer_body_1"
                                  )
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "action-buttons" },
                            [
                              _c("v-button", {
                                attrs: {
                                  route: "subscriptions",
                                  icon: "unlimited",
                                  filled: true,
                                  color: "primary",
                                  i18n: {
                                    id: this.showStartTrial
                                      ? "variation_page_trial_offer_button"
                                      : "variation_page_upgrade_button",
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.upgradeClicked.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("variation-modal-share", {
                                attrs: { variation: _vm.variation },
                              }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c("div", { staticClass: "text" }, [
                            _vm.variation.description
                              ? _c("p", [
                                  _vm._v(_vm._s(_vm.variation.description)),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.variation.stats
                            ? _c(
                                "div",
                                { staticClass: "stats" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "progress-bar-legend" },
                                    [
                                      _c("div", { staticClass: "new-words" }, [
                                        _c("div", { staticClass: "dot" }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "text",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              this.$i18n(
                                                "variation_page_count_new_words",
                                                {
                                                  args: {
                                                    variation_words_total:
                                                      _vm.variation.stats
                                                        .user_total_words,
                                                    variation_units:
                                                      _vm.variation.units,
                                                  },
                                                }
                                              )
                                            ),
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "remembered-words" },
                                        [
                                          _c("div", { staticClass: "dot" }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "text",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.$i18n(
                                                  "variation_page_count_words_mastered",
                                                  {
                                                    args: {
                                                      variation_words_remembered:
                                                        _vm.variation.stats
                                                          .user_remembered_words,
                                                    },
                                                  }
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("progress-bar", {
                                    attrs: {
                                      primary:
                                        (_vm.variation.stats
                                          .user_remembered_words /
                                          _vm.variation.units) *
                                        100,
                                      secondary:
                                        (_vm.variation.stats.user_total_words /
                                          _vm.variation.units) *
                                        100,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "action-buttons" },
                            [
                              !_vm.variationToggleDisabled
                                ? _c("v-button", {
                                    attrs: {
                                      i18n: {
                                        id: "variation_page_continue_button_activated",
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.enableOnlyThisVariation.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _c("v-button", {
                                    attrs: {
                                      route: "guess",
                                      i18n: {
                                        id: "variation_page_continue_button",
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.continueClicked.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                              _vm._v(" "),
                              _c("variation-modal-share", {
                                attrs: { variation: _vm.variation },
                              }),
                              _vm._v(" "),
                              _vm.canBeDiscarded
                                ? _c("v-button", {
                                    attrs: {
                                      filled: true,
                                      icon: "trash",
                                      i18n: {
                                        id: "overflow_menu_remove_button",
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.discardLesson.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("variation-settings", {
                            attrs: {
                              variation: this.variation,
                              "variation-toggle-disabled":
                                _vm.variationToggleDisabled,
                            },
                          }),
                        ],
                    _vm._v(" "),
                    _vm.variation.sample
                      ? _c("div", { staticClass: "samples" }, [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(this.$i18n(_vm.samplesTitle)),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "words" },
                            _vm._l(_vm.variation.sample, function (sample) {
                              return _c(
                                "span",
                                {
                                  staticClass: "target",
                                  attrs: { title: sample.word_translation },
                                },
                                [_vm._v(" " + _vm._s(sample.word) + " ")]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _c("Loader"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }