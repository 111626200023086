var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "hub" },
    [
      this.courseModalVisible && this.courses
        ? _c("course-modal", {
            attrs: { courses: this.courses },
            on: { "close-course-modal": this.closeCourseModal },
          })
        : _vm._e(),
      _vm._v(" "),
      this.course && this.subscription
        ? [
            _c(
              "transition",
              { attrs: { name: "slide-fade" } },
              [
                this.selectedWeekDay && this.selectedWeekDay.moment
                  ? _c("day-insights", {
                      attrs: { day: this.selectedWeekDay.moment },
                      on: { close: _vm.closeDayInsights },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "slide-fade" } },
              [
                _vm.dynamicGoalModalVisible
                  ? _c("dynamic-goal-modal", {
                      on: { close: _vm.closeDynamicGoalModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            this.variationModalVisible
              ? _c("variation-modal", {
                  attrs: {
                    variation: this.variationModalData,
                    "is-subscription-active": this.isSubscriptionActive,
                    "disable-general-settings": this.disableGeneralSettings,
                  },
                  on: {
                    "close-variation-modal": this.closeVariationModal,
                    "discard-lesson": this.discardLessonWithConfirmation,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.activeWordsModalVisible
              ? _c("active-words-modal", {
                  attrs: { course: this.course, user: this.user },
                  on: {
                    "close-active-words-modal": this.closeActiveWordsModal,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("locked-message", {
              attrs: { "is-subscription-active": this.isSubscriptionActive },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid-top" },
              [
                _c(
                  "div",
                  { staticClass: "grid-item hero" },
                  [
                    _c("hero", {
                      attrs: { course: this.course, courses: this.courses },
                      on: { "open-course-modal": this.openCourseModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("course-progress", {
                  attrs: { user: this.user, course: this.course },
                  on: {
                    "week-day-selected": _vm.weekDaySelected,
                    "thermometer-clicked": this.openActiveWordsModal,
                  },
                }),
                _vm._v(" "),
                this.activeVariations
                  ? _c(
                      "div",
                      { staticClass: "grid-item active-variations" },
                      [
                        _c("active-variations", {
                          attrs: {
                            variations: this.activeVariations,
                            "is-game-over": this.gameOver,
                            "is-subscription-active": this.isSubscriptionActive,
                            "disable-general-settings":
                              this.disableGeneralSettings,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            this.variationsEnabled && this.focuses && this.focuses.length > 0
              ? [
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(this.$i18n("hub_course_focus_title")),
                    },
                  }),
                  _vm._v(" "),
                  _c("variations-grid", {
                    attrs: {
                      variations: this.focuses,
                      "is-subscription-active": this.isSubscriptionActive,
                    },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            this.courseWizardEnabled && this.lessons
              ? [
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(this.$i18n("hub_course_wizard_title")),
                    },
                  }),
                  _vm._v(" "),
                  _c("variations-grid", {
                    attrs: {
                      variations: this.lessons,
                      create: true,
                      "is-subscription-active": this.isSubscriptionActive,
                    },
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }