var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7 17H5a2 2 0 01-2-2V5a2 2 0 012-2h14a2 2 0 012 2v4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 14l1.5-4 1.5 4h4l-3 2.5 1 4.5-3.5-2.5L13 21l1-4.5-3-2.5h4z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 7h5M7 11h2.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }