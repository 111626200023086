var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "progress-bar",
      style: { maxWidth: _vm.maxWidth ? `${_vm.maxWidth}px` : "auto" },
    },
    [
      _c("div", {
        staticClass: "primary",
        style: { width: `${_vm.primaryWidth}%` },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "secondary",
        style: { width: `${_vm.secondaryWidth}%` },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }