
'use strict';

import _ from 'lodash';
import moment from 'moment';

import AsyncDestroyable from '../async.destroyable.js';
import { getTimezoneOffset, setServerTs } from '../../util/datetime.js';

import { CourseStateConverter } from './course.state.converter.js';
import { COURSE_INFO_KEYS } from './course.js';
import { Experiments, SUPPORTED_EXPERIMENTS } from '../course.experiments.js';
import { Exercises } from '../course.exercises.js';
import { SUPPORTED as SUPPORTED_EXERCISES } from '../exercises/constants.js';
import { FastTrackingState } from '../fast.tracking.state.js';
import { CourseStatistics } from '../statistics/course.statistics.js';
import { CourseLimits } from './limits.js';

import { GuessQueue, SUPPORTED_QUESTION_TYPES } from '../guess.queue.js';
import { EventBus } from '../../util/vue-event-bus.js';

export const COURSE_STATE_STORAGE_PREFIX = 'CourseState';
const CURRENT_STATE_INVALIDATION = 2;

/**
 * @typedef {object} Voice
 * @property {string} gender
 * @property {string} name
 * @property {string} speed
 * @property {string} uuid
 */

/**
 * @typedef {object} UserCourseExtendedInfo
 * @property  {string} uuid
 * @property  {string} source_language
 * @property  {string} target_language
 * @property  {string} source_icon_id
 * @property  {string} target_icon_id
 * @property  {boolean} hidden
 * @property  {moment|null} registered_ts
 * @property  {moment|null} expiration_ts
 * @property  {boolean} paid
 * @property  {string} payment_uuid
 * @property  {string} payment_status
 * @property  {moment|null} can_cancel_ts
 * @property  {Voice[]} voices
 * @property  {string} fast_tracking
 * @property  {string} help_center_url
 * @property  {string[]} features
 * @property  {object} urls
 * @property  {object} asset_paths
 * @property  {string} end_action
 * @property  {string|null} predicted_ts_override
 * @property  {object} surveys
 * @property  {string} variation_uuid
 * @property  {array} variation_categories
 * @property  {string} voice_uuid
 */

class UserCourseStateNotInitializedException extends Error {
    constructor () {
        super();
        this.message = 'UserCourseStateManager not initialized';
        this.name = 'UserCourseStateNotInitializedException';
    }
}

export default class UserCourseStateManager extends AsyncDestroyable {
    /**
     *
     * @param user {User}
     * @param course {UserCourse}
     * @param assetLoader {AssetLoader}
     */
    constructor (user, course, assetLoader) {
        super(['initialize', '_initializeState', 'updateState', '_queueStateUpdate', '_loadState', '_storeState',
               '_cleanStorage', '_getServerState', 'update']);
        this._user = user;
        this._initialized = false;
        this._sync_in_progress = Promise.resolve();
        this._course = course;
        this._assetLoader = assetLoader;

        // Let's keep modules here that work on the course state sent by the server
        // and mostly follow the same lifecycle
        this._guessQueue = new GuessQueue(this._user, this._course, this._assetLoader);
        this._exercises = new Exercises(this._course, this._assetLoader);
        this._statistics = new CourseStatistics(this._user, this._course);
        this._experiments = new Experiments(this);
        this._fastTrackingState = new FastTrackingState(this._user, this._course);
        this._limits = new CourseLimits(this._user, this._course);

        this.bookmark = null;
        this.debugLexicalUnits = null;
        this._state = {};
        this._stateUpdatePromise = null;
        this._queuedStateUpdatePromise = null;
        this._stateSyncedWithServer = false;

        this._one_sync_complete_promise = new Promise((resolve, reject) => {
            this._one_sync_complete_promise_resolve = resolve;
        });
    }

    /**
     * Initializes the UserCourse state
     * @return {Promise}
     */
    async initialize () {
        const wasStateLoaded = await this._loadState();
        if (wasStateLoaded) {
            return await this._queueStateUpdate();
        } else {
            this.bookmark = null;
            this.debugLexicalUnits = null;
            try {
                await this._initializeState();
                await this.updateState();
            } catch (error) {
                throw error;
            }

        }
    }

    destroy () {
        console.debug(`UserCourseStateManager.destroy(${this._course.UUID})`);
        return Promise.all([
            this._guessQueue.destroy(),
            this._exercises.destroy(),
            this._statistics.destroy(),
            this._experiments.destroy(),
            this._fastTrackingState.destroy(),
            this._limits.destroy()
        ]).then(() => {
            delete this._user;
            delete this._course;
            delete this._assetLoader;
        });
    }

    /**
     * Initializes the CourseState
     * @private
     */
    _initializeState () {
        console.debug(`UserCourseStateManager._initializeState(${this._course.UUID})`);
        this.bookmark = null;
        this.debugLexicalUnits = null;
        this._state = {};
        return Promise.resolve()
            .then(() => Promise.all([
                this._exercises.initializeState(),
                this._statistics.initializeState(),
                this._experiments.initializeState(),
                this._fastTrackingState.initializeState(),
                this._limits.initializeState()
            ]))
            .then(() => this._guessQueue.initializeState())
            .then(() => {
                this._initialized = true;
                this._guessQueue.on('state-changed', () => {
                    this._storeState();
                });
                return Promise.resolve();
            });
    }

    reset () {
        return Promise.resolve()
            .then(() => this._queuedStateUpdatePromise || this._stateUpdatePromise || Promise.resolve())
            .then(() => this._initializeState())
            .then(() => this.updateState());
    }

    /**
     * Queue a state update or initiate one immediately if no update currently in progress
     * @return {Promise}
     */

    updateState () {
        console.log(`UserCourseStateManager.updateState`);

        if (this._stateUpdatePromise === null) {
            this._stateUpdatePromise = Promise.resolve()
                .then(() => this._getServerState())
                .then(response => {
                    if (response && response.status === 404) {
                        throw('no-such-course');
                    }
                    let promise;
                    let resolve_sync = () => {/*noop*/};
                    let reject_sync = () => {/*noop*/};
                    if (response.meta.clean_state) {
                        console.debug('UserCourseStateManager Initializing state because clean_state:true');
                        this._sync_in_progress = new Promise((resolve, reject) => {
                            resolve_sync = resolve;
                            reject_sync = reject;
                        });
                        promise = Promise.resolve().then(() => this._initializeState());
                    } else {
                        promise = Promise.resolve();
                    }
                    return promise
                        .then(() => {
                            // Process meta properties
                            this.bookmark = response.meta.bookmark;
                            this.question_horizon = moment(response.meta.question_horizon);
                            setServerTs(moment.utc(response.meta.server_ts));

                            if (response.meta.more_to_sync) {
                                // Ignoring returned Promise on purpose
                                this._queueStateUpdate();
                            } else {
                                this._one_sync_complete_promise_resolve();
                            }
                            return this.update(response.course_state);
                        })
                        .then(() => resolve_sync())
                        .catch(error => {
                            reject_sync(error);
                            return Promise.reject(error);
                        })
                        .then(() => {
                            this._storeState();
                        });
                })
                .then(() => {
                    EventBus.$emit('course-state-updated');
                    this._stateUpdatePromise = null;
                    this._stateSyncedWithServer = true;
                });

            return this._stateUpdatePromise;
        } else {
            return this._stateUpdatePromise;
        }
    }

    /**
     *
     * @return {Promise} - A Promise that resolves when the queued sync is completed
     * @private
     */
    _queueStateUpdate () {
        if (this._queuedStateUpdatePromise === null) {
            if (this._stateUpdatePromise !== null) {
                this._queuedStateUpdatePromise = Promise.resolve()
                    .then(() => this._stateUpdatePromise)
                    .then(() => {
                        this._queuedStateUpdatePromise = null;
                        return this.updateState();
                    });
                return this._queuedStateUpdatePromise;
            } else {
                return this.updateState();
            }
        } else {
            return this._queuedStateUpdatePromise;
        }
    }

    /**
     * Attempt to load state from storage and initialize CourseState with the loaded state
     * @return {Promise.<boolean>} - Returns true if state loading succeeded, otherwise false
     * @private
     */
    _loadState () {
        this._cleanStorage();  // TODO: Remove when the storage is clean for most clients
        return Promise.resolve()
            .then(() => this._user.getStorage().getItem(`${COURSE_STATE_STORAGE_PREFIX}/${this._course.UUID}`))
            .then(courseStateDump => {
                console.debug(`UserCourseStateManager._loadState loaded:`, courseStateDump);
                // Need new invalidation check when number is eventually incremented
                if (courseStateDump === null || courseStateDump.invalidation === undefined ||
                    courseStateDump.invalidation < CURRENT_STATE_INVALIDATION) {
                    return Promise.resolve(false);
                } else {
                    return Promise.resolve()
                        .then(() => this._initializeState())
                        .then(() => {
                            this.bookmark = courseStateDump.bookmark;
                            this.debugLexicalUnits = courseStateDump.debugLexicalUnits;
                            this.question_horizon = moment(courseStateDump.question_horizon);
                            return this.update(courseStateDump.state);
                        })
                        .then(() => Promise.resolve(true));
                }
            });
    }

    serializeState () {
        let stateDump = {
            invalidation: CURRENT_STATE_INVALIDATION,
            bookmark: this.bookmark,
            debugLexicalUnits: this.debugLexicalUnits,
            question_horizon: this.question_horizon.format(),
            state: _.clone(this._state)
        };

        stateDump.state.questions = this._guessQueue.serializeQuestions();
        stateDump.state.exercises = this._exercises.serializeExercises();
        stateDump.state.learning_totals = this._statistics.serializeTotals();
        stateDump.state.history = this._statistics.serializeHistory();
        stateDump.state.fast_tracking = this._fastTrackingState.getStatus();
        stateDump.state.experiments = this._experiments.serializeExperiments();
        stateDump.state.limits = this._limits.serializeLimits();

        return stateDump;
    }

    /**
     * Mirror function for _loadState: Saves the current state to persistent storage.
     * @private
     */
    _storeState () {
        let state = this.serializeState();
        return Promise.resolve().then(() => {
            return this._user.getStorage().setItem(
                `${COURSE_STATE_STORAGE_PREFIX}/${this._course.UUID}`,
                state
            );
        });
    }

    _assertInitialized () {
        if (!this._initialized) {
            throw new UserCourseStateNotInitializedException();
        }
    }

    /**
     * Cleans deprecated GuessQueue data from storage
     * @private
     */
    _cleanStorage () {
        return Promise.resolve().then(() => this._user.getStorage().getItem(`GuessQueueState/${this._course.UUID}`));
    }

    /**
     *
     * @return {Promise}
     * @private
     */
    async _getServerState () {
        const client_uuid = await this._user.getClient().getUUID();
        try {
            return await this._user.getApiClient().r.courses.course_uuid(this._course.UUID).post({
                course_state: this.bookmark === null ? null : {
                    bookmark: this.bookmark,
                    statistics_client_sn: this._statistics.getData().client_sn,
                    queues: {
                        new: this._guessQueue.getNewQuestionCount(),
                        repeats_below_horizon: this._guessQueue.getRepeatsBelowHorizonCount(),
                        repeats_waiting: this._guessQueue.getRepeatsWaitingCount(),
                        exercises: this._exercises.getExercisesCount()
                    }
                },
                client: {
                    uuid: client_uuid,
                    request_ts: moment().locale('en').format(),
                    time_correction: getTimezoneOffset(),
                    supported_experiments: SUPPORTED_EXPERIMENTS,
                    supported_types: {
                        questions: SUPPORTED_QUESTION_TYPES,
                        exercises: SUPPORTED_EXERCISES
                    }
                },
                debug: this.debugLexicalUnits && this.debugLexicalUnits.length > 0 ? {
                    get_lexical_units: this.debugLexicalUnits
                } : undefined
            }, { minorVersion: 32 });
        } catch (error) {
            return error;
        }
    }

    /**
     *
     * @param data {object} - UserCourseInfo or UserCourseState items
     */
    update (data) {
        this._course.update_info(data);
        let promise = Promise.resolve();
        Object.keys(data).forEach(key => {
            let value = data[key];
            switch (key) {
                case 'questions':
                    this._assertInitialized();
                    promise = promise
                        .then(() => this._guessQueue.updateQuestions(value, this.question_horizon))
                        .then(() => {
                            this.debugLexicalUnits = _.difference(this.debugLexicalUnits, this._guessQueue.getDebugQuestionsInfo().map(qi => qi.uuid));
                        });
                    console.debug(`UserCourseStateManager.update: received ${value.length} questions with update`);
                    break;
                case 'exercises':
                    this._assertInitialized();
                    promise = promise.then(() => this._exercises.updateExercises(value));
                    console.debug(`UserCourseStateManager.update:  received ${value.length} exercises with update`);
                    break;
                case 'learning_totals':
                    console.debug(`UserCourseStateManager.update: received learning_totals with update`);
                    this._assertInitialized();
                    promise = promise.then(() => this._statistics.updateTotals(value));
                    break;
                case 'history':
                    console.debug(`UserCourseStateManager.update: received history with update`);
                    this._assertInitialized();
                    if (value.length > 0) {
                        promise = promise.then(() => this._statistics.updateHistory(value));
                    }
                    break;
                case 'learned_words_url':
                    this._assertInitialized();
                    this._statistics.updateLearnedWords(value);
                    break;
                case 'fast_tracking':
                    this._assertInitialized();
                    if (value && value.hasOwnProperty('status')) { // if status comes in as an object (backend)
                        this._fastTrackingState.setStatus(value.status);
                    } else if (value) { // if status is just a simple string (storage)
                        this._fastTrackingState.setStatus(value);
                    }
                    break;
                case 'limits':
                    this._assertInitialized();
                    this._limits.updateLimits(value);
                    break;
                case 'experiments':
                    promise = promise.then(() => this._experiments.updateExperiments(value));
                    break;
                default:
                    this._state[key] = CourseStateConverter.convert(key, value);
            }
        });

        return promise;
    }

    /**
     * Returns `true` if the state has been synced with server during this session, `false` otherwise
     */
    isSynced () {
        return this._stateSyncedWithServer;
    }

    /**
     *
     * @param lexicalUnits {string[]}
     * @return {Promise}
     */
    loadLexicalUnits (lexicalUnits) {
        this.debugLexicalUnits = lexicalUnits;
        return Promise.resolve()
            .then(() => this._queueStateUpdate())
            .then(() => this._guessQueue.switchToDebugIfPossible());
    }

    getGuessQueue () {
        this._assertInitialized();
        return this._guessQueue;
    }

    getStatistics () {
        this._assertInitialized();
        return this._statistics;
    }

    getFastTrackingState () {
        this._assertInitialized();
        return this._fastTrackingState;
    }

    getLimits () {
        this._assertInitialized();
        return this._limits;
    }

    getExperiments () {
        this._assertInitialized();
        return this._experiments;
    }

    getExercises () {
        this._assertInitialized();
        return this._exercises;
    }

    // # Data getters

    /**
     * Returns full course information
     * @return {UserCourseExtendedInfo}
     */
    getUserCourseExtendedInfo () {
        this._assertInitialized();
        return _.clone(this._state);
    }

    /**
     * Returns basic course information
     * @return {CourseInfo}
     */
    getInfo () {
        return _.pick(this._state, COURSE_INFO_KEYS);
    }

    /**
     * @return {[]}
     */
    async getFeatures () {
        this._assertInitialized();
        await this._sync_in_progress;
        return this._state.features || [];
    }

    /**
     * @return {object}
     */
    getAssetPaths () {
        this._assertInitialized();
        return this._state.asset_paths;
    }

    /**
     * @return {object}
     */
    getUrls () {
        this._assertInitialized();
        return this._state.urls;
    }

    /**
     * @return {object}
     */
    getSurveys () {
        this._assertInitialized();
        return this._state.surveys;
    }

    /**
     * @return {object}
     */
    getVoices () {
        this._assertInitialized();
        return this._state.voices;
    }

    /**
     * @return {object}
     */
    getVariationCategories () {
        this._assertInitialized();
        return this._state.variation_categories;
    }

    /**
     * @return {string}
     */
    async getVoiceUUID () {
        await this._sync_in_progress;
        return this._state.voice_uuid;
    }

    /**
     * @return {number}
     */
    getRepeatsWaiting () {
        this._assertInitialized();
        return this._state.repeats_waiting;
    }

    decrementRepeatsWaiting () {
        this._state.repeats_waiting--;
    }

    waitForSynced () {
        return this._one_sync_complete_promise;
    }
}
