var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 296 180",
          width: "296",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M135.507 77h-23.014c-5.245 0-9.493-3.93-9.493-8.78V43.78c0-4.85 4.248-8.78 9.493-8.78h23.014c5.245 0 9.493 3.93 9.493 8.78v24.44c0 4.85-4.248 8.78-9.493 8.78zM196.507 143h-23.014c-5.245 0-9.493-3.929-9.493-8.78v-24.44c0-4.851 4.248-8.78 9.493-8.78h23.014c5.245 0 9.493 3.929 9.493 8.78v24.44c0 4.841-4.248 8.78-9.493 8.78z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M110 145H71M102 132V97.743c0-4.28 3.498-7.743 7.821-7.743H154M154 103v34.257c0 4.28-3.498 7.743-7.821 7.743H102M154 77V42.743c0-4.28 3.498-7.743 7.821-7.743H206M206 48v34.257c0 4.28-3.498 7.743-7.821 7.743H154",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M72 149a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M124.5 61h8.721c.983 0 1.779-.835 1.779-1.866V47.866c0-1.03-.796-1.866-1.779-1.866h-18.442c-.983 0-1.779.836-1.779 1.866V65.91l5.5-4.91",
          stroke: "#9DF4F4",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }