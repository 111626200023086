var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M21 6v15H4.241L3 18",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6 15.692V16a2 2 0 01-2 2H3V3h1a2 2 0 012 2v.5M6 15.5v.5a2 2 0 002 2h10V3H8a2 2 0 00-2 2v.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M6 20h3v3l-1.5-1L6 23v-3z", fill: "#63E1E7" },
      }),
      _c("rect", {
        attrs: {
          x: "9",
          y: "6",
          width: "6",
          height: "4",
          rx: ".5",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 12.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }