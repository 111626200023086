var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user && _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "settings-modal",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "settings-modal-window" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c("h2", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(this.$i18n("learn_settings_title")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.userCourse
                    ? _c("audio-settings", {
                        attrs: { "user-course": _vm.userCourse },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("setting-toggle", {
                    attrs: {
                      title: "settings_auto_advance_label",
                      footnote: "settings_auto_advance_text",
                      "setting-parameter": _vm.autoAdvanceParameter,
                    },
                  }),
                  _vm._v(" "),
                  _c("setting-toggle", {
                    attrs: {
                      title: "settings_grammar_tables_label",
                      footnote: "settings_grammar_tables_description",
                      "setting-parameter": _vm.showGrammarParameter,
                      "not-set-default-value": false,
                    },
                  }),
                  _vm._v(" "),
                  _c("setting-toggle", {
                    attrs: {
                      title: "learn_settings_diacritics_toggle_lbl",
                      footnote: "learn_settings_diacritics_toggle_txt",
                      "setting-parameter": _vm.strictDiacriticsParameter,
                      "not-set-default-value": false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }