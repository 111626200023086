var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8 15a7 7 0 117-7c-3.865 0-7 3.135-7 7zM4.2 3.4a1 1 0 011.4-.2l2 1.5a1 1 0 01.114 1.5A1.252 1.252 0 016.5 7.75a1.25 1.25 0 01-.936-2.078L4.4 4.8a1 1 0 01-.2-1.4zm8.007-.107a1 1 0 010 1.414l-.867.867a1.249 1.249 0 11-2.09.926c0-.103.012-.204.036-.3a1 1 0 01.007-1.407l1.5-1.5a1 1 0 011.414 0zM5.5 10a1 1 0 10-2 0v.2A2.8 2.8 0 006.3 13H7a1 1 0 100-2h-.7a.8.8 0 01-.8-.8V10z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 15a7 7 0 111.346 4.127M13 17c.455.622 1.17 1 2 1 .83 0 1.545-.378 2-1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M14 13a.999.999 0 11-1.998.002A.999.999 0 0114 13zM18 13a.999.999 0 11-1.998.002A.999.999 0 0118 13z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }