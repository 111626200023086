import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';

import { LessonListView } from '../view/lessons/lesson.list.view.js';

import {
    ASSIGN_LESSON,
    DELETE_LESSON
} from '../view/lessons/actions.js';
import { COURSE_FEATURE } from "../modules/course/course.js";
import Courses from "../modules/courses.js";


export class LessonsController {

    constructor () {
        this._lessons = [];
        this._appView = null;
    }

    go (appView, router, action = null, uuid = null) {
        this._lessonUuid = uuid;
        this._appView = appView;

        this.renderLessonListView();
    }

    async renderLessonListView () {
        const _subscription = await UserManager.instance.getUser().getSubscription();
        const isDisabled = !_subscription.isSubscriptionActive();
        const lessonListView = new LessonListView();

        lessonListView.model.set({
            isDisabled,
            loading: true,
            courseWizardDisabled: !(await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.COURSE_WIZARD))
        });

        lessonListView.on(ASSIGN_LESSON, (variationUuid, classrooms) => {

            this.assignLesson(variationUuid, classrooms)
                .then( () => {
                    lessonListView.model.set('lessonToAssign', null);
                })
                .catch( error => {
                    lessonListView.model.set('assignError', error);
                });
        });

        lessonListView.on(DELETE_LESSON, lessonUuid => {

            Promise.resolve()
                .then( () => this.deleteLesson(lessonUuid))
                .then( () => this.getLessons())
                .then( lessons => {
                    this._lessons = lessons;
                    lessonListView.model.set({ lessons });
                });
        });

        this._appView.setMainView(lessonListView, true);
        lessonListView.render();

        if (this._lessons && this._classrooms) {

            // Start updating cached lessons list on background
            Promise.resolve()
                .then( () => this.getLessons())
                .then( lessons => {
                    this._lessons = lessons;
                    lessonListView.model.set({ loading: false, lessons });
                    lessonListView.render();
                    return this.getClassrooms();
                })
                .then( classrooms => {
                    this._classrooms = classrooms;
                    lessonListView.model.set({ classrooms });
                });


            lessonListView.model.set({
                loading: false,
                lessons: this._lessons,
                classrooms: this._classrooms
            });
        } else {
            const lessons = await this.getLessons();
            this._lessons = lessons;
            lessonListView.model.set({ loading: false, lessons });

            const classrooms = await this.getClassrooms();
            this._classrooms = classrooms;
            lessonListView.model.set({ classrooms });
            lessonListView.render();
        }
    }

    /**
     * Get the list of created lessons
     */

    getLessons () {
        return Promise.resolve()
            .then( () => UserManager.instance.getUser().getLessons().getLessons())
            .then( lessons => {
                lessons.map(lesson => {
                    const course = Courses.getCourse(lesson.course_uuid);
                    if (course && course.info) {
                        lesson.course = course.info;
                    }
                });
                return Promise.resolve(lessons);
            });
    }

    deleteLesson (lessonUuid) {
        return Promise.resolve()
            .then( () => UserManager.instance.getUser().getLessons().delete(lessonUuid))
            .catch( () => {
                console.log('Failed to delete lesson');
            });
    }

    getClassrooms () {
        return Promise.resolve()
            .then( () => UserManager.instance.getUser().getClassrooms().getClassroomList())
            .then( classroomList => {
                return Promise.resolve(classroomList);
            });
    }

    assignLesson (lessonUuid, classrooms) {
        return Promise.resolve()
            .then( () => UserManager.instance.getUser().getClassrooms().assignLesson(lessonUuid, classrooms))
            .then( response => {
                return Promise.resolve(response);
            })
            .catch( error => {
                return Promise.reject(error);
            });
    }
}

export const lessons_controller_factory = new ControllerFactory(LessonsController);
