var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          stroke: "#2C3143",
          d: "M6 4l4 4-4 4",
          fill: "none",
          "fill-rule": "evenodd",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }