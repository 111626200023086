<template>
    <div class="braintree">
        <div id="drop-in-container"></div>
    </div>
</template>

<script>
    import dropin from 'braintree-web-drop-in';
    import getConfigValue from 'Util/configuration.js';
    import i18nUtils from 'Util/i18n.js';
    export default {
        name: 'braintree',
        props: {
            user: {
                type: Object,
                required: true
            },
            amount: {
                type: [ String, Number ],
                required: true
            },
            enableThreeDSecure: {
                type: Boolean
            },
            token: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                enablePaypal: getConfigValue('pay-braintree-enable-paypal'),
            }
        },
        methods: {
            createDropIn: function () {
                let options = {
                    authorization: this.token,
                    container: '#drop-in-container',
                    locale: i18nUtils.currentInterfaceLanguage,
                    card: {
                        cardholderName: {
                            required: true
                        },
                        overrides: {
                            styles: {
                                input: {
                                    color: '#2C3143',
                                    'border-radius': '4px'
                                },
                                '.invalid': {
                                    color: '#FE5C55'
                                }
                            }
                        }
                    },
                    dataCollector: {
                        kount: true // Required if Kount fraud data collection is enabled
                    }
                };

                const email = (this.user && this.user.profile && this.user.profile.email) ? this.user.profile.email : '';

                if (this.enablePaypal) {
                    options.paypal = {
                        flow: 'vault',
                        buttonStyle: {
                            shape: 'rect',
                            size: 'medium'
                        }
                    };
                }

                options.threeDSecure = this.enableThreeDSecure;

                dropin.create(options, (createErr, instance) => {
                    if (createErr) {
                        console.log('braintree callback createErr', createErr);
                    } else {
                        this.$emit('set-braintree-instance', instance);
                        if (instance.isPaymentMethodRequestable()) {
                            this.$emit('payment-method-requestable');
                        }
                        instance.on('paymentOptionSelected', (event) => {
                            this.$emit('payment-option-selected', event);
                        });
                        instance.on('noPaymentMethodRequestable', (event) => {
                            this.$emit('no-payment-method-requestable', event);
                        });
                        instance.on('paymentMethodRequestable', (event) => {
                            this.$emit('payment-method-requestable', event);
                        });
                    }
                });
            },
        },
        mounted () {
            this.createDropIn();
        }
    }
</script>

<style lang="scss">

</style>
