var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M20 35c-8.283 0-15-6.715-15-14.999S11.717 5 20 5",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17 17a2 2 0 11-4 0 2 2 0 014 0z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.921 23.61a1 1 0 10-1.842.78l1.842-.78zm-1.842.78C13.32 27.323 16.659 29 20 29v-2c-2.79 0-5.239-1.404-6.079-3.39l-1.842.78z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M20 36c8.835 0 16-7.163 16-15.999S28.835 4 20 4v23c3.31 0 6.317 1.413 7.802 3.402a1 1 0 01-1.604 1.196C25.17 30.218 22.822 29 20 29v7zm5-17a2 2 0 100-4 2 2 0 000 4z",
          fill: "#8EA3EE",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }