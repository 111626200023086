var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "46",
          height: "43",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          attrs: {
            "clip-path":
              "url(#venturebeat-logo_svg__venturebeat-logo_svg__clip0)",
            fill: "#2C3143",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M35.888 8.8c2.515.07 5.564.18 7.398 2.09 1.065 1.134 1.665 2.974 1.665 4.603 0 2.195-1 3.435-1.56 3.97-.71.704-1.63 1.164-2.514 1.589 1.025.325 2.09.64 3.04 1.56 1.029 1.024 1.734 2.794 1.734 4.673a6.043 6.043 0 01-1.735 4.39c-1.98 2.014-4.603 2.054-7.433 2.124H24.986v-5.104l2.23.035V13.864l-2.23.035V8.8h10.902zM33.73 18.853c2.02.04 2.3.04 2.8-.07a2.42 2.42 0 001.919-2.49c0-1.31-.75-2.05-1.275-2.3-.52-.249-.925-.284-3.439-.319l-.005 5.179zm0 9.842h1.95c1.38 0 3.324-.03 3.324-2.404 0-1.25-.675-2.09-1.525-2.41-.6-.25-.88-.25-3.749-.25v5.064z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M1.238 8.8h10.727v5.1l-2.194-.035 3.644 14.301 3.934-14.301-2.194.035V8.8h9.837v5.1l-1.665-.035-6.193 19.93H9.061l-6.263-19.93-1.56.035V8.8z",
            },
          }),
        ]
      ),
      _c("defs", [
        _c(
          "clipPath",
          {
            attrs: { id: "venturebeat-logo_svg__venturebeat-logo_svg__clip0" },
          },
          [
            _c("path", {
              attrs: {
                fill: "#fff",
                transform: "translate(.896 .47)",
                d: "M0 0h44.854v41.65H0z",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }