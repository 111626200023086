import $ from 'jquery';
import Backbone from 'backbone';
import moment from "moment";
import Json2CSV from "json2csv";
import _ from 'lodash';

import i18nUtils from '../../util/i18n.js';

import renderTemplate from '../../util/template.renderer.js';
import UserManager from '../../modules/usermanager.js';
import { ClassroomNameDialogView } from './classroom.name.dialog.view.js';


const ClassroomModel = Backbone.Model.extend({
    defaults: {
        classroomNameDialogVisible: false
    }
});

const ClassroomView = Backbone.View.extend({

    name: 'classroom',
    tagName: 'main',
    className: 'classroom-view',

    events: {
        'click a.back': 'goBack',
        'click a.manage': 'manage',
        'click a.remove': 'manage',
        'click a.download': 'downloadCsv',
        'click a.copy': 'copyLink',
        'click div.remove': 'removeStudent',
        'click div.name-container': 'openClassroomNameDialog'
    },

    initialize: function () {
        console.log('Classroom view init');
        this.model = new ClassroomModel();

        this.listenTo(this.model, 'change', this.modelChanged);
    },

    setProps: function (props) {
        this.props = props;
        this.model.set(props);
    },

    render: function () {
        return Promise.resolve()
            .then(() => renderTemplate('classrooms/classroom', this.model.attributes, this.$el))
            .then( () => {
                this._postRender();
                return Promise.resolve();
            });
    },

    _postRender: function () {

        this.$header = this.$('header');
        this.$students = this.$('div.student');
        this.$classroomLink = this.$('div.link');
        this.$classroomInput = this.$('div.link > input');
        this.$message = this.$('div.message');

        Backbone.trigger('rendered', this);
    },

    goBack: function () {
        Backbone.history.navigate('classrooms', { trigger: true });
    },

    manage: function () {
        this.$header.toggleClass('manage');
        this.$students.toggleClass('manage');

        UserManager.instance.getUser().getEventSender()
            .sendNavigationEvent(`classrooms/${this.props.uuid}`, 'click', 'manage');
    },

    copyLink: function () {
        this.$classroomInput.select();
        document.execCommand('copy');
        this.$classroomInput.blur();

        this.$classroomLink.addClass('copied');

        UserManager.instance.getUser().getEventSender()
            .sendNavigationEvent(`classrooms/${this.props.uuid}`, 'click', null);
    },

    removeStudent: function (event) {
        const student_uuid = $(event.currentTarget).data('uuid');
        $(event.currentTarget).parent().addClass('removed');
        this.trigger('remove-student', { student_uuid });
    },

    showMessage: function (message) {

        this.$message.addClass('active');
        this.$message.text(message);

        setTimeout( () => {
            this.$message.removeClass('active');
        }, 5000);
    },

    openClassroomNameDialog: function () {
        this.model.set({ classRoomNameDialogVisible: true });
    },

    modelChanged: function ({ changed }) {
        if (changed.classRoomNameDialogVisible === false) {
            this.classroomNameDialogView.model.set({
                mounted: false
            });
        }

        if (changed.classRoomNameDialogVisible === true) {
            this.classroomNameDialogView = new ClassroomNameDialogView();

            this.classroomNameDialogView.on('save-classroom-name', name => {
                this.trigger('save-classroom-name', { name });
                this.model.set({ classRoomNameDialogVisible: false });
            });

            this.classroomNameDialogView.on('close-classroom-name-dialog', () => {
                this.model.set({ classRoomNameDialogVisible: false });
            });

            this.$el.append(this.classroomNameDialogView.el);
            const name = this.model.get('name');

            this.classroomNameDialogView.model.set({
                name,
                mounted: true
            });
        }

        if (changed.name) {
            this.render();
        }
    },

    downloadCsv: function () {
        const studentsData = this.model.get('students');
        if (studentsData) {
            const fields = [
                {
                    label: i18nUtils.prop('classroom_student_list_name'),
                    value: 'name'
                },
                {
                    label: i18nUtils.prop('classroom_student_list_email'),
                    value: 'email'
                },
                {
                    label: i18nUtils.prop('classroom_student_list_total_words'),
                    value: 'total_words'
                },
                {
                    label: i18nUtils.prop('classroom_student_list_last_days'),
                    value: 'new_words_last_week'
                },
                {
                    label: i18nUtils.prop('classroom_student_list_repeats'),
                    value: 'cards_last_week'
                },
                {
                    label: i18nUtils.prop('classroom_student_list_active'),
                    value: 'active_learning_time_last_week'
                }
            ];

            let dataForCsv = [];
            const json2csvParser = new Json2CSV.Parser({ fields, quote: '' });

            studentsData.forEach(studentData => {
                let student = {
                    name: studentData.name,
                    email: studentData.email,
                    total_words: studentData.statistics.new_units.total,
                    new_words_last_week: studentData.new_units_last_week,
                    cards_last_week: studentData.all_units_last_week,
                    active_learning_time_last_week: moment.duration(studentData.study_time_last_week, 'seconds').humanize()
                };

                dataForCsv.push(student);
            });

            const csv = json2csvParser.parse(dataForCsv);
            const filename = _.kebabCase(this.model.get('name')) + '.csv';

            let link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
});

export default ClassroomView;
