<template>
    <div class="card-debug">
        <div class="uuid" @click="copyUUID">{{ this.question.uuid }}</div>
        <div class="answer">
            <h3>Answer</h3>
            <span>{{ this.question.word }}</span>
        </div>
        <div class="eq-answers" v-if="this.question.equivalent_words && this.question.equivalent_words.length > 0">
            <h3>Equivalent Answers</h3>
            <span>{{ this.question.equivalent_words.join(', ') }}</span>
        </div>
        <div class="synonyms" v-if="this.synonyms && this.synonyms.length > 0">
            <h3>Synonyms</h3>
            <span>{{ this.synonyms.join(', ') }}</span>
        </div>
        <div class="ft-status" v-if="this.fastTrackingStatus">
            <h3>Fast-tracking status</h3>
            <span>{{ this.fastTrackingStatus }}</span>
        </div>
    </div>
</template>

<script>
    import { TYPE as TOAST_TYPE } from "../../toaster/constants.js";
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'card-debug',
        props: {
            course: {
                type: Object,
                required: true
            },
            question: {
                type: Object,
                required: true
            },
            synonyms: {
                type: Array
            },
        },
        computed: {
            fastTrackingStatus() {
                const ftStatus = this.course.getFastTrackingState().getStatus();
                return (ftStatus) ? ftStatus : null;
            }
        },
        methods: {
            async copyUUID() {
                if (this.question?.uuid) {
                    try {
                        await navigator.clipboard.writeText(this.question.uuid);
                        const toast = {
                            text: 'UUID copied to clipboard!',
                            type: TOAST_TYPE.SUCCESS
                        };
                        EventBus.$emit('toaster-add', toast);
                    } catch (err) {
                        console.error('Async: Could not copy text: ', err);
                    }
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.card-debug {
        position: relative;
        display: flex;
        direction: ltr;
        gap: 1rem;
        flex-direction: column;
        margin-top: 1rem;
        background-color: #fff;
        border-radius: pxToRem(12);
        border: 2px solid $colorAttention;
        padding: 1rem;
        h3 {
            font-weight: $font-bold;
            margin-bottom: .25rem;
        }
        > div.uuid {
            position: absolute;
            z-index: 100;
            right: .5rem;
            top: .5rem;
            background-color: #f2f2f2;
            border-radius: .25rem;
            padding: .35rem;
            font-size: fontSize(12);
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
</style>
