var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9 8s.5-2 3-2c2 0 3 1.751 3 3 0 3-3 3-3 3v2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("circle", { attrs: { cx: "12", cy: "18", r: "1", fill: "#2C3143" } }),
      _c("path", {
        attrs: {
          d: "M7.636 3c1.319-.64 2.8-1 4.364-1 5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12c0-2.251.744-4.329 2-6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }