
'use strict';

import _ from 'lodash';


export class RequestAbortedException extends Error {
    constructor (message) {
        super(message);
        this.name = 'RequestAbortedException';
    }
}

export class AssetLoadingFailedException extends Error {
    constructor (asset, response) {
        super(`Failed loading asset with url="${asset.url}" retries="${asset.retryCount}" status="${response && response.status}" messages="${asset.errors.map(e => e.message)}"`);
        this.name = 'AssetLoadingFailedException';
        this.status = response && response.status;
    }
}

export class AssetLoader {
    constructor (configuration, superagent) {

        if (!_.isObject(configuration)) {
            throw Error('configuration is a required argument');
        }

        if (!_.isObject(superagent)) {
            throw Error('superagent is a required argument');
        }

        this._configuration = configuration;
        this._request = superagent;

        this._assets = {};
        this._order = 0;
    }

    destroy () {
        var self = this;
        return Promise.resolve().then(function () {
            _(self._assets)
                .transform((result, job) => result.push(job), [])
                .forEach(asset => {
                    if (asset.request !== undefined) {
                        asset.request.abort();
                    }
                    asset.promise.reject(new RequestAbortedException('Request aborted due to AssetLoader destruction.'));
                    delete self._assets[asset.url];
                });
            delete self._assets;

            return Promise.resolve();
        });
    }

    _isRetryable (asset, response) {
        return (response === undefined || response.statusType === 5) && asset.retryCount <= this._configuration.MAX_RETRIES;
    }

    _load (asset) {
        let self = this;

        asset.loading = true;

        return Promise.resolve().then(function () {
            return new Promise(function(resolve, reject) {
                asset.request = self._request.get(asset.url).end(function (error, response) {
                    if (error == null) {
                        // All ok
                        delete self._assets[asset.url];
                        asset.promise.resolve(response.text);
                        self._loadPool();
                        resolve();
                    } else {
                        // Problem, see if we can retry this
                        asset.errors.push(error);
                        if (self._isRetryable(asset, response)) {
                            // Connection error, server error, etc. Retry.
                            asset.retryCount++;
                            asset.retryTimeout = self._calculateTimeout(asset);
                            // FIXME: I'm not exactly sure that this retry mechanic ALWAYS works correctly, since the loading of this asset is triggered immediately by doing _loadPool sometimes
                            setTimeout(function () {
                                self._load(asset).then(resolve, reject);
                            }, asset.retryTimeout);
                        } else {
                            // HTTP Response error 404 or out of retry attempts. No point in retrying
                            delete self._assets[asset.url];

                            // Don't throw errors from dev assets repo
                            // TODO: might be smart to refactor this to environment name in future
                            if (!asset.url.includes('dev.keel24.eu')) {
                                let ex = new AssetLoadingFailedException(asset, response);
                                asset.promise.reject(ex);
                                self._loadPool();
                                reject(ex);
                            }
                        }
                    }
                });
            });
        });
    }

    _calculateTimeout (asset) {
        return this._configuration.TIMEOUT_BASE * asset.retryCount * this._configuration.TIMEOUT_MULTIPLIER *
            (this._configuration.RANDOM_COMPONENT ? Math.random() * 2 : 1);
    }

    _loadPool () {
        var self = this;

        _(this._assets)
            .transform((result, job) => result.push(job), [])
            .orderBy(['loading', 'priority', 'order'], ['desc', 'asc', 'asc'])
            .forEach((asset, i) => {
                if (i >= self._configuration.POOL_SIZE && asset.priority > 0) {
                    return false;
                } else if (asset.loading === false) {
                    // Do an empty catch for errors so they don't get logged. Load end is unhandled for now.
                    self._load(asset).catch(() => {});
                }
            });
    }

    loadAsset (assetURL, priority) {
        /**
         * @param priority - sets the primary ordering for loading. 0 is a special
         *  value which triggers an immediate load
         */

        var self = this;

        if (this._assets[assetURL] === undefined) {
            this._assets[assetURL] = {
                url: assetURL,
                order: ++self._order,
                promise: {},
                loading: false,
                errors: [],
                retryCount: 0
            };

            this._assets[assetURL].promise.promise = new Promise(function(resolve, reject) {
                self._assets[assetURL].promise.resolve = resolve;
                self._assets[assetURL].promise.reject = reject;
            });
        }

        this._assets[assetURL].priority = priority;

        this._loadPool();

        return this._assets[assetURL].promise.promise;
    }

    removeAssetFromLoading (assetURL) {
        var asset = this._assets[assetURL];
        if (asset !== undefined) {
            if (asset.request !== undefined) {
                asset.request.abort();
            }
            delete this._assets[asset.url];
        }
    }
}
