var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "v-textbox",
        { active: _vm.isActive, full: _vm.fullWidth, mono: _vm.mono },
      ],
    },
    [
      _vm.label
        ? _c("label", { attrs: { for: _vm.textboxId } }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "textbox-wrapper" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          attrs: { type: "text", id: _vm.textboxId },
          domProps: { value: _vm.value },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.value = $event.target.value
              },
              function ($event) {
                return _vm.updateValue($event.target.value)
              },
            ],
            focus: function ($event) {
              _vm.isActive = true
            },
            blur: function ($event) {
              _vm.isActive = false
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }