var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lesson-cards" }, [
    _c("h3", {
      domProps: {
        innerHTML: _vm._s(this.$i18n("lessons_creator_editor_title")),
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("lesson-cards-control", {
          attrs: {
            lesson: _vm.lesson,
            cards: _vm.cards,
            isPortrait: _vm.isPortrait,
            "interaction-disabled": _vm.interactionDisabled,
            expanded: _vm.expanded,
            allChecked: _vm.allChecked,
            checkedCards: _vm.checkedCards,
            "active-filter": _vm.activeCardsFilter,
          },
          on: {
            "add-cards": _vm.addCardsToLesson,
            "expand-lesson": _vm.expandLesson,
          },
        }),
        _vm._v(" "),
        _vm.cards.length > 0
          ? _c("div", { staticClass: "lesson-cards-container" }, [
              _c(
                "table",
                { class: { disabled: this.interactionDisabled } },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "index" }, [_vm._v(" ")]),
                      _vm._v(" "),
                      _c("th", {
                        staticClass: "target-lang",
                        attrs: {
                          colspan:
                            _vm.isPortrait && !!_vm.selected_card ? 1 : 2,
                        },
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(_vm.targetLang, {
                              collection: "language_name",
                            })
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "source-lang",
                          attrs: {
                            colspan:
                              _vm.isPortrait && !!_vm.selected_card ? 1 : 2,
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n(_vm.sourceLang, {
                                  collection: "language_name",
                                })
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", { staticClass: "status" }, [_vm._v(" ")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    {
                      class: { expanded: _vm.expanded },
                      attrs: { name: "list", mode: "out-in", tag: "tbody" },
                    },
                    _vm._l(_vm.filteredCards, function (card, index) {
                      return _c(
                        "tr",
                        {
                          key: card.id,
                          class: {
                            selected:
                              _vm.selected_card &&
                              card.id === _vm.selected_card.id,
                            disabled: _vm.isCardRowDisabled(card),
                          },
                          attrs: {
                            "data-checked": _vm.checkedCards.includes(card.id),
                          },
                        },
                        [
                          _c("td", { staticClass: "index" }, [
                            _c("span", { staticClass: "nr" }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkedCards,
                                  expression: "checkedCards",
                                },
                              ],
                              staticClass: "custom",
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: card.id,
                                checked: Array.isArray(_vm.checkedCards)
                                  ? _vm._i(_vm.checkedCards, card.id) > -1
                                  : _vm.checkedCards,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.checkedCards,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = card.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.checkedCards = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.checkedCards = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.checkedCards = $$c
                                  }
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "target target-word",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("edit-card", card)
                                },
                              },
                            },
                            [
                              _c("edit-in-sidebar-icon"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(card.form) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "target target-sentence",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("edit-card", card)
                                },
                              },
                            },
                            [
                              _c("edit-in-sidebar-icon"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(card.context) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "source-word",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("edit-card", card)
                                },
                              },
                            },
                            [
                              _c("edit-in-sidebar-icon"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(card.form_translation) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "source-sentence",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("edit-card", card)
                                },
                              },
                            },
                            [
                              _c("edit-in-sidebar-icon"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(card.context_translation) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "status",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("edit-card", card)
                                },
                              },
                            },
                            [
                              _c("card-status-icon", {
                                key: card.id + "_status",
                                class: {
                                  hidden:
                                    card.completeness === "complete" &&
                                    !card.known,
                                },
                                attrs: {
                                  status: card.completeness,
                                  known: card.known,
                                  showTooltip: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          : _c("lesson-intro", {
              attrs: {
                lesson_uuid: this.lesson_uuid,
                "interaction-disabled": _vm.interactionDisabled,
              },
              on: { "add-cards": _vm.addCardsToLesson },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }