var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { key: this.$i18nLanguageChanged },
    [
      _c(
        "nav",
        [
          _vm.step !== _vm.consts.STEP.TARGET_LANGUAGE &&
          _vm.step !== _vm.consts.STEP.OAUTH_EMAIL &&
          _vm.step !== _vm.consts.STEP.QUESTIONNAIRE
            ? _c("arrow-card", {
                staticClass: "navigate-back",
                nativeOn: {
                  click: function ($event) {
                    return _vm.navigate_back.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.TARGET_LANGUAGE
        ? _c("select-target-language", {
            staticClass: "main-content",
            attrs: {
              courses: _vm.courses,
              interface_language: _vm.interface_language,
            },
            on: {
              language_selected: _vm.select_target_language,
              language_not_listed: _vm.language_not_listed,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.SOURCE_LANGUAGE
        ? _c("select-course", {
            staticClass: "main-content",
            attrs: {
              courses: _vm.courses,
              selected_target_language: _vm.selected_target_language,
            },
            on: {
              selected: _vm.select_course,
              language_not_listed: _vm.language_not_listed,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.QUESTIONNAIRE
        ? _c("demographic-questionnaire", {
            attrs: { selected_target_language: _vm.selected_target_language },
            on: { final_answer_given: _vm.navigate_conditionally },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.ACCOUNT_CREATION_OPTIONS
        ? _c("account-creation-options", {
            staticClass: "main-content",
            attrs: {
              selected_course: _vm.selected_course,
              single_available_target_language_course:
                _vm.is_single_available_target_language_course,
              marketing_opt_in: _vm.marketing_opt_in,
            },
            on: {
              create_account: _vm.select_account_creation_option,
              marketing_opt_in_toggled: function ($event) {
                _vm.marketing_opt_in = arguments[0]
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.CREDENTIALS_EMAIL
        ? _c("credentials-email", {
            staticClass: "main-content",
            attrs: {
              selected_course: _vm.selected_course,
              single_available_target_language_course:
                _vm.is_single_available_target_language_course,
              name: _vm.name,
              email: _vm.email,
            },
            on: {
              name_updated: function ($event) {
                _vm.name = arguments[0]
              },
              email_updated: _vm.email_updated,
              verified_submit: _vm.submit_email_credentials,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.OAUTH_EMAIL
        ? _c("oauth-email", {
            staticClass: "main-content",
            attrs: {
              selected_course: _vm.selected_course,
              single_available_target_language_course:
                _vm.is_single_available_target_language_course,
              oauth_input: this.oauth_input,
              email_in_use: _vm.email_in_use,
            },
            on: {
              email_updated: _vm.email_updated,
              verified_submit: _vm.complete_oauth_with_email,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.HAS_ACCOUNT_ASK
        ? _c("has-account-ask", {
            attrs: { email: this.email, oauth_input: this.oauth_input },
            on: {
              "register-new-account": function ($event) {
                return _vm.set_has_account_ask_result(false)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.LANGUAGE_NOT_LISTED
        ? _c("language-not-listed", {
            staticClass: "main-content",
            attrs: { selected_target_language: _vm.selected_target_language },
            on: { back: _vm.navigate_back },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.referral_cw && !_vm.referral_cw_shown
        ? _c(
            "modal-popup",
            {
              attrs: {
                title: this.$i18n(
                  "course_wizard_course_share_invitation_title"
                ),
                closable: true,
                base_width: "23rem",
              },
              on: {
                close: function ($event) {
                  _vm.referral_cw_shown = true
                },
              },
            },
            [
              _c(
                "main",
                { staticClass: "referral-cw" },
                [
                  _c("register-referral-illustration"),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "course_wizard_course_share_invitation_message_1"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "course_wizard_course_share_invitation_message_2"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("footer", { staticClass: "buttons" }, [
                    _c("button", {
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n(
                            "course_wizard_course_share_invitation_sign_up"
                          )
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.referral_cw_shown = true
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.consts.STEP.LOADING
        ? _c("course-loading", {
            attrs: {
              source_icon_id: _vm.selected_course.info.source_icon_id,
              target_icon_id: _vm.selected_course.info.target_icon_id,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }