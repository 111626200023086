var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#8EA3EE",
          d: "M0 20.6c0 .7.5 1.2 1.2 1.2H18c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2H3.6c-.7 0-1.2-.5-1.2-1.2v-7c0-.7-.5-1.2-1.2-1.2S0 10.5 0 11.2v9.4z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M7 4.6C7 3.7 7.8 3 8.7 3h6.6c.9 0 1.7.7 1.7 1.6V6h3.4c.7 0 1.6.5 1.6 1.5V8c0 .6-.4 1-1 1s-1-.4-1-1H4v5h2c0-.6.4-1 1-1s1 .4 1 1h8c0-.6.4-1 1-1s1 .4 1 1h2c0-.6.4-1 1-1s1 .4 1 1v5.3c0 .8-.6 1.6-1.6 1.6h-17c-.5 0-.9-.2-1.2-.6-.2-.3-.3-.7-.3-1V7.4C2 6.5 2.9 6 3.6 6H7V4.6zM6 15c0 .6.4 1 1 1s1-.4 1-1h8c0 .6.4 1 1 1s1-.4 1-1h2v3H4v-3h2zm3-9h6V5H9v1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }