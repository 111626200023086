var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M10 20c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10a9.985 9.985 0 01-8-4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M24 19.444C24 24.66 22.21 30 20 30s-4-5.34-4-10.556C16 14.23 17.79 10 20 10s4 4.229 4 9.444z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 14c1.639 1.198 4.64 2 8.07 2 3.335 0 6.264-.759 7.93-1.902M12 26c1.639-1.198 4.64-2 8.07-2 3.335 0 6.264.759 7.93 1.902",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35 20.01a14.94 14.94 0 01-4.5 10.705M33 21.08l2-1.07 2 1.606M5 21c0-4.476 1.723-8.523 4.5-11.426M5 21l2-1.5M5 21l-1.873-2",
          stroke: "#AC6ED1",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }