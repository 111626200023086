var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "36",
          height: "13",
          viewBox: "0 0 36 13",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M34.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M35.116 5.91a1 1 0 00-.912-1.41h-3.657a1 1 0 00-.912 1.41l1.828 4.064c.354.786 1.47.786 1.824 0l1.829-4.064z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.5.5l-4.627 11.069",
          stroke: "#BDDBDF",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M22.116 5.91a1 1 0 00-.912-1.41h-3.657a1 1 0 00-.912 1.41l1.828 4.064c.354.786 1.47.786 1.824 0l1.829-4.064z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M14.116 5.91a1 1 0 00-.912-1.41H9.547a1 1 0 00-.912 1.41l1.829 4.064c.353.786 1.47.786 1.823 0l1.829-4.064z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.116 5.91a1 1 0 00-.912-1.41H1.547a1 1 0 00-.912 1.41l1.829 4.064c.353.786 1.47.786 1.823 0L6.116 5.91z",
          fill: "#BDDBDF",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }