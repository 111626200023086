var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M4 33V10a1 1 0 011-1h2a1 1 0 011 1v19a1 1 0 001 1h9a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1z",
          fill: "#3888E0",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 29V7a1 1 0 011-1h2a1 1 0 011 1v18a1 1 0 001 1h8a1 1 0 011 1v2a1 1 0 01-1 1H9a1 1 0 01-1-1z",
          fill: "#FFD86F",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22 20v10H8V6h14v2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22 15h-3.5a1.5 1.5 0 01-1.5-1.5v0a1.5 1.5 0 011.5-1.5h4.843a4 4 0 012.829 1.172l.656.656A4 4 0 0029.657 15H31M31 22h-1.343a4 4 0 00-2.829 1.172l-.656.656A4 4 0 0123.343 25H22M31 13h4v11h-4zM12 24l2 2 4-4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }