var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "hotkeys-modal",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "hotkeys-modal-window" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c("h2", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("guess_game_hotkeys_modal_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", {
                    staticClass: "key",
                    class: { flash: _vm.activeKeyCode === 39 },
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("guess_game_hotkeys_modal_right_key")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "label",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "guess_game_hotkeys_modal_right_key_description"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", {
                    staticClass: "key",
                    class: { flash: _vm.activeKeyCode === 37 },
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("guess_game_hotkeys_modal_left_key")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "label",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "guess_game_hotkeys_modal_left_key_description"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", {
                    staticClass: "key",
                    class: { flash: _vm.activeKeyCode === 38 },
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("guess_game_hotkeys_modal_up_key")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "label",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "guess_game_hotkeys_modal_up_key_description"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", {
                    staticClass: "key",
                    class: { flash: _vm.activeKeyCode === 40 },
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("guess_game_hotkeys_modal_down_key")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "label",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "guess_game_hotkeys_modal_down_key_description"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", {
                    staticClass: "key",
                    class: { flash: _vm.activeKeyCode === 32 },
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("guess_game_hotkeys_modal_spacebar_key")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "label",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "guess_game_hotkeys_modal_spacebar_key_description"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", {
                    staticClass: "key",
                    class: { flash: _vm.activeKeyCode === 13 },
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("guess_game_hotkeys_modal_enter_key")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "label",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "guess_game_hotkeys_modal_enter_key_description"
                        )
                      ),
                    },
                  }),
                ]),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }