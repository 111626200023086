var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "support-modal-pay" }, [
    _c("div", { staticClass: "support-modal-pay-window" }, [
      _c(
        "header",
        [
          _c("h2", {
            domProps: { innerHTML: _vm._s(this.$i18n("support_float_title")) },
          }),
          _vm._v(" "),
          _vm.closable
            ? _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("main", [
        _c(
          "div",
          {
            staticClass: "field",
            class: { focused: this.messageInputFocused },
          },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userMessage,
                  expression: "userMessage",
                },
              ],
              ref: "message",
              attrs: {
                name: "message",
                rows: "4",
                placeholder: _vm.messagePlaceholder,
              },
              domProps: { value: _vm.userMessage },
              on: {
                focus: function ($event) {
                  _vm.messageInputFocused = true
                },
                blur: function ($event) {
                  _vm.messageInputFocused = false
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.userMessage = $event.target.value
                },
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c("v-button", {
              attrs: { i18n: { id: "dialog_button_cancel" }, size: "medium" },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("v-button", {
              attrs: {
                i18n: { id: "support_float_button_send_message" },
                disabled: !_vm.messageIsValid,
                filled: true,
                size: "medium",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.sendMessage.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }