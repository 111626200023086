var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M5 12c0-1.7 1.3-3 3-3h1c.6 0 1 .4 1 1s-.4 1-1 1H8c-.6 0-1 .4-1 1v5c0 .6-.4 1-1 1s-1-.4-1-1v-5zm4 6c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1zM18 2c.6 0 1 .4 1 1v5c0 1.7-1.3 3-3 3h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1-.4 1-1V3c0-.6.4-1 1-1zm-4 12.5c.6 0 1 .4 1 1V22c0 .6-.4 1-1 1s-1-.4-1-1v-6.5c0-.6.4-1 1-1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#AC6ED1",
          d: "M15 4.5C15 6.4 13.4 8 11.5 8S8 6.4 8 4.5 9.6 1 11.5 1 15 2.6 15 4.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }