var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cards-filter-dropdown",
      class: { disabled: this.disabled },
    },
    [
      _c(
        "div",
        { staticClass: "filter-button", on: { click: _vm.openDropdown } },
        [
          _c("card-status-icon", {
            attrs: {
              status: _vm.activeFilter,
              known: _vm.activeFilter === "known",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "counter" }, [
            _vm._v(_vm._s(_vm.activeFilterCardsCount)),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.activeFilterName))]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dropdownOpen
        ? _c(
            "div",
            {
              staticClass: "filter-dropdown",
              on: {
                mouseleave: _vm.closeDropdownDelayed,
                mouseenter: _vm.clearDropdownClose,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "filter-dropdown-item",
                  class: { disabled: _vm.allCardsCount <= 0 },
                  on: {
                    click: function ($event) {
                      return _vm.filterClicked("none")
                    },
                  },
                },
                [
                  _c("card-status-icon", {
                    attrs: { status: "all", known: false },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "counter" }, [
                    _vm._v(_vm._s(_vm.allCardsCount)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("lessons_creator_editor_dropdown_all_cards")
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filter-dropdown-item",
                  class: { disabled: _vm.completeCardsCount <= 0 },
                  on: {
                    click: function ($event) {
                      return _vm.filterClicked("complete")
                    },
                  },
                },
                [
                  _c("card-status-icon", {
                    attrs: { status: "complete", known: false },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "counter" }, [
                    _vm._v(_vm._s(_vm.completeCardsCount)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_dropdown_complete_cards"
                        )
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filter-dropdown-item",
                  class: { disabled: _vm.inCompleteCardsCount <= 0 },
                  on: {
                    click: function ($event) {
                      return _vm.filterClicked("incomplete")
                    },
                  },
                },
                [
                  _c("card-status-icon", {
                    attrs: { status: "incomplete", known: false },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "counter" }, [
                    _vm._v(_vm._s(_vm.inCompleteCardsCount)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_dropdown_incomplete_cards"
                        )
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filter-dropdown-item",
                  class: { disabled: _vm.knownCardsCount <= 0 },
                  on: {
                    click: function ($event) {
                      return _vm.filterClicked("known")
                    },
                  },
                },
                [
                  _c("card-status-icon", {
                    attrs: { status: "known", known: true },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "counter" }, [
                    _vm._v(_vm._s(_vm.knownCardsCount)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_dropdown_already_learned_words"
                        )
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filter-dropdown-item",
                  class: { disabled: _vm.invalidCardsCount <= 0 },
                  on: {
                    click: function ($event) {
                      return _vm.filterClicked("invalid")
                    },
                  },
                },
                [
                  _c("card-status-icon", {
                    attrs: { status: "invalid", known: false },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "counter" }, [
                    _vm._v(_vm._s(_vm.invalidCardsCount)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_dropdown_missing_translation"
                        )
                      ),
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }