var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.visuals &&
    this.question &&
    _vm.image_data &&
    _vm.image_data.enabled
    ? _c(
        "div",
        {
          staticClass: "card-visual",
          style: { backgroundImage: "url(" + _vm.image_url + ")" },
        },
        [
          _c(
            "div",
            {
              staticClass: "change-picture-button",
              on: { click: _vm.showVisualsModal },
            },
            [_c("change-picture-icon")],
            1
          ),
          _vm._v(" "),
          _vm.image_data && _vm.image_data.attribution
            ? _c(
                "div",
                { staticClass: "info", on: { click: _vm.openUnsplash } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.image_data.attribution.title)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(_vm._s(_vm.image_data.attribution.description)),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }