
'use strict';

import MD5 from 'md5';
import Raven from 'raven-js';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

import jqueryAjax from '../util/jquery.ajax.js';
import { getPersistentStorageProvider } from '../modules/persistent.storage.provider.js';
import Cookie from '../util/cookie.js';
import ExportUtils from '../util/export.js';

const AuthUtils = {
    //Auth configuration
    realm: 'KEEL24-API-REALM',

    // during active session token is stored here to support case where storage and cookies are blocked
    savedToken: undefined,
    saved_authentication: null,

    getPasswordHash: function (email, password) {
        return MD5(email + ':' + AuthUtils.realm + ':' + password);
    },

    setValidTokenCookie: function (token) {
        console.log('AUTH setValidTokenCookie token', token);
        const decodedToken = jwtDecode(token),
            parentDomain = window.location.host.match(/learn\.(.*)/)[1];

        if (parentDomain === undefined) {
            Raven.captureException(Error(`Unable to process domain="${window.location.host}"`), {level: 'error'});
        } else {
            Cookie.setCookie('user_learn_token_valid_until', JSON.stringify(decodedToken.exp), {
                expires: moment.unix(decodedToken.exp).utc().add(1, 'year'),
                domain: parentDomain
            });
        }
    },

    // TODO: Review the necessity of this after removing all $.ajax calls!
    setAuth: async function () {
        try {
            const {token} = this.getStoredAuthentication();
            jqueryAjax.setHeader('Authorization', `Bearer ${token}`);
        } catch (error) {
            Raven.captureException(error, {level: 'error'});
            throw error;
        }
    },

    clearAuth: function () {
        return Promise.resolve().then(function () {
            jqueryAjax.removeHeader('Authorization');
        });
    },

    getStoredAuthentication: async function () {

        if (this.saved_authentication) {
            return this.saved_authentication;
        }

        const authentication = await getPersistentStorageProvider().getItemAnonymous('authentication');

        if (authentication === null) {
            // TODO: Remove this branch: migration logic from pure token to authentication object after some time
            const token = await getPersistentStorageProvider().getItemAnonymous('token');

            if (!!token) {
                try {
                    const decoded_token = jwtDecode(token);

                    if (decoded_token.uuid) {
                        const authentication = {
                            uuid: decoded_token.uuid,
                            token: token
                        };
                        await getPersistentStorageProvider().setItemAnonymous('authentication', authentication);
                        await getPersistentStorageProvider().removeItemAnonymous('token');
                        return authentication;
                    } else {
                        return null;
                    }

                } catch (error) {
                    Raven.captureException(error, {level: 'error'});
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return authentication;
        }
    },

    setAuthentication: async function (user_uuid, token) {
        await this.clearAuthentication();

        const authentication = {
            uuid: user_uuid,
            token: token
        };

        this.saved_authentication = authentication;
        this.setValidTokenCookie(authentication.token);
        return await getPersistentStorageProvider().setItemAnonymous('authentication', authentication);
    },

    async isAuthenticationSet () {
        return (await this.getStoredAuthentication()) !== null;
    },

    clearAuthentication: async function () {
        AuthUtils.saved_authentication = null;

        const parentDomain = window.location.host.match(/learn\.(.*)/)[1];
        if (parentDomain === undefined) {
            Raven.captureException(Error(`Unable to process domain="${window.location.host}"`), {level: 'error'});
        } else {
            Cookie.removeCookie('user_learn_token_valid_until', {
                domain: parentDomain
            });
        }
        await getPersistentStorageProvider().removeItemAnonymous('authentication');
        return await this.clearAuth();
    }
};

export default AuthUtils;

ExportUtils.export('app.util.auth', AuthUtils);
