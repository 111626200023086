var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "weekly-goal" }, [
    _c(
      "div",
      { staticClass: "bars" },
      _vm._l(_vm.cells, function (cell) {
        return _c(
          "div",
          { key: cell.uniqueKey, staticClass: "day" },
          [
            cell.type === "empty"
              ? _c("empty-cell")
              : _c("lottie-animation", {
                  attrs: { animationData: cell.animation, loop: cell.loop },
                  on: {
                    complete: function ($event) {
                      return _vm.cellAnimationComplete(cell.type)
                    },
                  },
                }),
            _vm._v(" "),
            cell.weekdayName
              ? _c("span", { staticClass: "weekday-name" }, [
                  _vm._v(_vm._s(cell.weekdayName)),
                ])
              : _c("span", { staticClass: "weekday-name" }, [_vm._v(" ")]),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-content" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _vm.streak_day === -1
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("dynamic_goals_weekly_doorslam_no_streak_text")
                ),
              },
            })
          : _vm.streak_day === 1
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n(
                    "dynamic_goals_weekly_doorslam_streak_day_1_text",
                    { args: { set_size: _vm.dynamic_goal_size } }
                  )
                ),
              },
            })
          : _vm.streak_day === 2
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("dynamic_goals_weekly_doorslam_streak_day_2_text")
                ),
              },
            })
          : _vm.streak_day === 3
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("dynamic_goals_weekly_doorslam_streak_day_3_text")
                ),
              },
            })
          : _vm.streak_day === 4
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("dynamic_goals_weekly_doorslam_streak_day_4_text")
                ),
              },
            })
          : _vm.streak_day === 5
          ? [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("1", {
                      collection:
                        "dynamic_goals_weekly_doorslam_streak_day_5_text",
                      args: { tl: this.target_language },
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("2", {
                      collection:
                        "dynamic_goals_weekly_doorslam_streak_day_5_text",
                    })
                  ),
                },
              }),
            ]
          : _vm.streak_day === 6
          ? [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("1", {
                      collection:
                        "dynamic_goals_weekly_doorslam_streak_day_6_text",
                      args: { tl: this.target_language },
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("2", {
                      collection:
                        "dynamic_goals_weekly_doorslam_streak_day_6_text",
                    })
                  ),
                },
              }),
            ]
          : _vm.streak_day === 7
          ? [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("1", {
                      collection:
                        "dynamic_goals_weekly_doorslam_streak_day_7_text",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("2", {
                      collection:
                        "dynamic_goals_weekly_doorslam_streak_day_7_text",
                    })
                  ),
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c("v-button", {
          attrs: { i18n: { id: "daily_performance_stats_home_button" } },
          nativeOn: {
            click: function ($event) {
              return _vm.navigateToHub.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: {
            filled: true,
            color: "primary",
            i18n: { id: "sets_aha_moment_goal_achieved_doorslam_btn" },
          },
          nativeOn: {
            click: function ($event) {
              return _vm.close.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }