<template>
    <div class="difficulty">
        <div v-if="showTooltip" class="tooltip" v-html="this.$i18n(this.difficultyName, { collection: 'difficulty_indicator_label' })" />
        <difficulty-icon class="difficultyIcon" :class="{ full: this.difficulty > 0 }"/>
        <difficulty-icon class="difficultyIcon" :class="{ full: this.difficulty > 1 }" />
        <difficulty-icon class="difficultyIcon" :class="{ full: this.difficulty > 2 }" />
    </div>
</template>

<script>
    import difficultyIcon from "Images/difficulty-16x16.svg";
    export default {
        name: 'difficulty',
        components: {
            difficultyIcon,
        },
        props: {
            difficulty: {
                type: Number,
                default: 0
            },
            showTooltip: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            difficultyName() {
                switch(this.difficulty) {
                    case 1:
                        return 'beginner'
                    case 2:
                        return 'intermediate'
                    case 3:
                        return 'advanced'
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.difficulty {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > svg.difficultyIcon {
            opacity: 0.2;
            transition: opacity 250ms, transform 250ms;
            &.full {
                opacity: 1;
            }
            &:hover {
                transform: scale(1.2);
            }
        }
        > div.tooltip {
            display: none;
            min-width: pxToRem(100);
            max-width: pxToRem(100);
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            bottom: pxToRem(30);
            border-radius: pxToRem(8);
            background-color: $colorBackgroundDarkLighter;
            padding: pxToRem(8);
            text-align: center;
            color: #fff;
            font-size: fontSize(12);
            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 100%;
                right: calc(50% - .25rem);
                border: .25rem solid transparent;
                border-bottom: none;
                border-top-color: $colorBackgroundDarkLighter;
            }
        }
        &:hover {
            > div.tooltip {
                display: block;
            }
        }
    }
</style>
