var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navigation-item",
      class: { active: _vm.active, dropdownOpen: _vm.dropdownOpen },
    },
    [
      _c(
        "div",
        {
          staticClass: "navigation-item-button",
          attrs: { role: "button" },
          on: { click: _vm.buttonClick },
        },
        [
          _vm.notify && !_vm.active
            ? _c("span", { staticClass: "badge red" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "icon" }, [_vm._t("icon")], 2),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fadeLabel" } }, [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open,
                  expression: "open",
                },
              ],
              staticClass: "label",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n(_vm.i18n.id, {
                    collection: _vm.i18n.collection,
                    args: _vm.i18n.params,
                  })
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _vm.hasSubRoutes && _vm.open
            ? _c(
                "svg",
                {
                  staticClass: "dropdown-arrow",
                  class: { isOpen: this.dropdownOpen },
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4 7L8 10L12 7",
                      stroke: "#626C91",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.open && _vm.hasSubRoutes && _vm.dropdownOpen
        ? _c(
            "div",
            { staticClass: "sub-routes" },
            _vm._l(_vm.subRoutes, function (subRoute) {
              return !subRoute.hidden
                ? _c(
                    "div",
                    {
                      staticClass: "sub-route",
                      class: { active: subRoute.active },
                      on: {
                        click: function ($event) {
                          return _vm.navigateSubRoute(subRoute.route)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "label",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$i18n(subRoute.i18n.id, {
                              collection: subRoute.i18n.collection,
                              args: subRoute.i18n.params,
                            })
                          ),
                        },
                      }),
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }