<template>
    <section class="account-creation-options">
        <h1 v-html="this.$i18n('register_credentials_selected_course_heading')"></h1>
        <course :course="selected_course"/>
        <p v-html="this.$i18n('register_save_progress')"></p>

        <checkbox class="marketing-opt-in" v-model="marketing_opt_in_" :label="this.$i18n('register_marketing_optin_title')" />

        <footer>
            <authentication-options
                :options="account_creation_options"
                :more_options_label="this.$i18n('register_more_options')"
                @option_selected="create_account"
            />
            <terms />
        </footer>
    </section>
</template>

<script>

import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';

import i18nUtils from '../../../util/i18n.js';


import Course from './course.vue';
import Terms from '../../component/terms.vue';
import Checkbox from '../../component/checkbox.vue';
import AuthenticationOptions from '../../component/authentication-options.vue';

Vue.use(ToggleButton);

export default {
    name: 'register.select-course',
    components: {
        AuthenticationOptions,
        Course,
        Terms,
        Checkbox,
    },
    props: {
        selected_course: {
            type: Object,
            required: true
        },
        single_available_target_language_course: {
            type: Boolean,
            required: true
        },
        marketing_opt_in: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            marketing_opt_in_: this.marketing_opt_in,
            secondary_options_open: false,
            account_creation_options: this.get_account_creation_options(),
        };
    },
    watch: {
        marketing_opt_in_ (value) {
            this.$emit('marketing_opt_in_toggled', value);
        },
    },
    methods: {
        create_account (option) {
            this.$emit('create_account', option)
        },
        get_account_creation_options () {
            const account_creation_options = i18nUtils.getAccountCreationOptions();
            return {
                primary: account_creation_options.primary.map(o => ({
                    option: o,
                    label: i18nUtils.prop('primary', null, null, `register_option_${o}`)
                })),
                secondary: account_creation_options.secondary.map(o => ({
                    option: o,
                    label: i18nUtils.prop('secondary', null, null, `register_option_${o}`)
                }))
            }
        },
    }
};
</script>

<style scoped lang="scss">
@import '~Styles/colors';
@import '~Styles/mixins';

    section.account-creation-options {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        > h1 {
            font-weight: $font-bold;
            font-size: 1.625rem;
            margin-bottom: 2rem;
        }

        > div.marketing-opt-in {
            align-self: flex-start;
            margin-top: 6rem;
            width: 100%;
            transition: background-color 0.2s;
            border-radius: 0.5rem;

            &:hover, &:active {
                background-color: $colorBackgroundLighter;
            }
        }

        > footer {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
</style>
