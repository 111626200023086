<template>
    <div class="insights-calendar" v-if="course && dateContext && daysInMonthWithData">
        <div class="month">
            <h2 v-html="title" />
            <div class="date-grid">
                <div v-for="(day, index) in daysInMonthWithData" class="day" :class="{ 'today': day.isToday, 'disabled': day.isFuture }" :style="{ gridColumn: index === 0 ? firstDayOfMonth : 'auto' }" @click="dayClicked(day)">
                    <goal-status-icon :size="24" :status="day.goalStatus" :on-gray="true" />
                    <span class="number">{{ day.number }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import i18nUtils from "Util/i18n.js";
    import GoalStatusIcon from "ViewComponent/goal-status-icon.vue";
    import { Awards } from "Modules/awards.js";

    export default {
        name: 'calendar',
        components: { GoalStatusIcon },
        props: {
            dateContext: {
                type: Object,
                required: true
            },
            course: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                today: moment().local().startOf('day'),
                daysInMonthWithData: null,
                dynamicGoalSize: Awards.getDynamicGoalSize()
            }
        },
        computed: {
            year() {
                return this.dateContext.format('YYYY');
            },
            month() {
                return this.dateContext.format('MMMM');
            },
            title() {
                if (this.today.year() === this.dateContext.year()) {
                    return i18nUtils.prop(this.dateContext.month() + 1, null, null, 'calendar_month');
                } else {
                    return `${ i18nUtils.prop(this.dateContext.month() + 1, null, null, 'calendar_month') } <em>${ this.dateContext.year() }</em>`;
                }
            },
            currentDate() {
                return this.dateContext.get('date');
            },
            firstDayOfMonth() {
                let firstDay = moment(this.dateContext).subtract((this.currentDate - 1), 'days');
                return firstDay.weekday();
            },
            initialMonth() {
                return this.today.format('MMMM');
            },
        },
        methods: {
            setDaysInMonthWithData() {
                const firstDayOfMonth = this.dateContext.clone().startOf('month');
                const lastDayOfMonth = this.dateContext.clone().endOf('month');
                const statistics = (this.course) ? this.course.getStatistics() : null;

                if (!statistics) {
                    return;
                }

                const monthData = statistics.getHistoryDataFromTo(firstDayOfMonth, lastDayOfMonth);
                let month = [];

                monthData.forEach(day => {
                    let _day = {};
                    _day.moment = moment(day.ts);
                    _day.isToday = this.today.isSame(_day.moment.startOf('day'));
                    _day.number = _day.moment.date();
                    _day.isFuture = _day.moment.isAfter();

                    if (day.awards_objects.length > 0) {
                        let _daySetAwards = Awards.getSetAwardsInfo(day.awards_objects);
                        if (_daySetAwards.setsCompleted > 0) {
                            _day.goalStatus = 'done';
                        }
                    } else if (day.all_units.total > 0 && day.all_units.total < this.dynamicGoalSize) {
                        _day.goalStatus = 'progress';
                    } else {
                        _day.goalStatus = 'none';
                    }

                    month.push(_day);
                });

                this.daysInMonthWithData = month;
            },
            dayClicked(day) {
                this.$emit('day-selected', day);
            }
        },
        created() {
            this.setDaysInMonthWithData();
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.insights-calendar {
        max-width: pxToRem(420);
        > div.month {
            > h2 {
                font-size: fontSize(18);
                margin-bottom: 1rem;
                font-weight: $font-bold;
                > em {
                    opacity: 0.4;
                }
            }
            > div.date-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: .25rem;
                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: pxToRem(20);
                }
            }

            > div.date-grid {
                > div.day {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: pxToRem(50);
                    min-width: pxToRem(32);
                    user-select: none;
                    padding: 0.25rem;
                    &:hover {
                        cursor: pointer;
                        background-color: white;
                        border-radius: pxToRem(4);
                    }
                    &.disabled {
                        pointer-events: none;
                        opacity: 0.6;
                    }
                    > span.number {
                        width: 100%;
                        margin-top: .25rem;
                        display: block;
                        font-size: fontSize(12);
                        text-align: center;
                        border-bottom: 2px solid transparent;
                    }
                    &.today {
                        > span.number {
                            color: $colorPurplePrimary;
                            border-bottom: 2px solid $colorPurplePrimary;
                        }
                    }
                }
            }
        }
    }
</style>
