var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.3 12.9c.3.4.2 1.1-.2 1.4l-3.5 2.5c-1.1.8-1.7 2-1.6 3.2h14c.1-1.2-.5-2.4-1.6-3.2l-3.5-2.5c-1.1-.8.1-2.4 1.2-1.6l3.5 2.5c1.3.9 2.4 2.5 2.4 4.3V22H3v-2.5c0-1.8 1.1-3.4 2.4-4.3l3.5-2.5c.5-.3 1.1-.2 1.4.2zM12 3c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2s2-.9 2-2V5c0-1.1-.9-2-2-2zM8 5c.2-5.3 7.8-5.3 8 0v2c0 2.2-1.8 4-4 4S8 9.2 8 7V5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }