var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "status-dot" }, [
    _c(
      "svg",
      {
        attrs: {
          width: "16",
          height: "16",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      [
        _c("defs", [
          _c("path", { attrs: { id: "status_dot__a", d: "M0 0h16v16H0z" } }),
          _vm._v(" "),
          _c("path", { attrs: { d: "M3 13L13 3", id: "status_dot__c" } }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
          _c("mask", { attrs: { id: "status_dot__b", fill: "#fff" } }, [
            _c("use", { attrs: { "xlink:href": "#status_dot__a" } }),
          ]),
          _vm._v(" "),
          _c(
            "g",
            { attrs: { mask: "url(#status_dot__b)", fill: _vm.dotColor } },
            [
              _c("rect", {
                attrs: {
                  width: "12",
                  height: "12",
                  rx: "6",
                  transform: "translate(2 2)",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.disabled
            ? _c(
                "g",
                {
                  attrs: {
                    mask: "url(#status_dot__b)",
                    "stroke-linecap": "square",
                  },
                },
                [
                  _c("use", {
                    attrs: {
                      stroke: "#FFF",
                      "stroke-width": "4",
                      "xlink:href": "#status_dot__c",
                    },
                  }),
                  _vm._v(" "),
                  _c("use", {
                    attrs: {
                      stroke: "#2C3143",
                      "stroke-width": "2",
                      "xlink:href": "#status_dot__c",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }