<template>
    <div class="card-preview">
        <div class="block">
            <course-icon size="medium" :value="lessonTargetLanguageIconId" />
            <div class="row form">
                <div v-if="!form_in_edit" class="form target" :class="{ empty: !form || form.length <= 0 }" @click="form_in_edit = true">
                <span class="edit-icon">
                    <edit-icon />
                </span>
                    <span class="content" v-html="form" />
                </div>
                <editable v-else :value="form"
                          class="target context-editable"
                          key="form_editable_1"
                          v-on:click.stop
                          v-on:change="updateCardField($event, 'form')"
                          v-on:blur="form_in_edit = false"
                          :max-length="80"
                          :nowrap="false" :no-padding="true" :focus-on-mount="true" />
            </div>
            <div class="row context">
                <div v-if="!context_in_edit" class="context target" :class="{ empty: !context || context.length <= 0 }" @click="context_in_edit = true">
                <span class="edit-icon">
                    <edit-icon />
                </span>
                    <span class="content" v-html="context_highlighted" />
                </div>
                <editable v-else :value="context"
                          class="target context-editable"
                          key="context_editable_1"
                          v-on:click.stop
                          v-on:change="updateCardField($event, 'context')"
                          v-on:blur="context_in_edit = false"
                          :max-length="110"
                          :nowrap="false" :no-padding="true" :focus-on-mount="true" />
            </div>
        </div>
        <hr />
        <div class="block">
            <course-icon size="medium" :value="lessonSourceLanguageIconId" />
            <div class="row form-translation">
                <div v-if="!form_translation_in_edit" class="form-translation" :class="{ empty: !form_translation || form_translation.length <= 0 }"  @click="form_translation_in_edit = true">
                <span class="edit-icon">
                    <edit-icon />
                </span>
                    <span class="content" v-html="form_translation_placeholder ? form_translation_placeholder : form_translation" />
                </div>
                <editable v-else :value="form_translation"
                          class="form-translation-editable"
                          key="form_translation_editable_1"
                          v-on:click.stop
                          v-on:change="updateCardField($event, 'form_translation')"
                          v-on:blur="form_translation_in_edit = false"
                          :max-length="80"
                          :nowrap="false" :no-padding="true" :focus-on-mount="true" />
            </div>
            <div class="row context-translation">
                <div v-if="!context_translation_in_edit" class="context-translation" :class="{ empty: !context_translation || context_translation.length <= 0 }"  @click="context_translation_in_edit = true">
                <span class="edit-icon">
                    <edit-icon />
                </span>
                    <span class="content" v-html="context_translation_placeholder ? context_translation_placeholder : context_translation_highlighted" />
                </div>
                <editable v-else :value="context_translation"
                          class="context-translation-editable"
                          key="context_translation_editable_1"
                          v-on:click.stop
                          v-on:change="updateCardField($event, 'context_translation')"
                          v-on:blur="context_translation_in_edit = false"
                          :max-length="110"
                          :nowrap="false" :no-padding="true" :focus-on-mount="true" />
            </div>
        </div>
        <transition name="fade">
            <div class="error" v-if="error" v-html="error" />
        </transition>
        <div class="actions" v-if="cardHasChanges">
            <v-button @click.native="save"
                      :filled="true" color="primary" size="medium"
                      :i18n="{ id: 'lessons_creator_editor_card_preview_save_btn' }" />
            <v-button @click.native="cancel"
                      size="medium"
                      :i18n="{ id: 'lessons_creator_editor_card_preview_cancel_btn' }" />
        </div>
    </div>
</template>

<script>
    import VButton from 'ViewComponent/v-button.vue';
    import CourseIcon from 'ViewComponent/course-icon.vue';
    import Editable from "ViewComponent/editable.vue";
    import { EventBus } from 'Util/vue-event-bus';
    import EditIcon from 'Images/status-unfinished.svg'
    import i18nUtils from "Util/i18n";
    import format from 'format-util';

    export default {
        name: 'card-preview-edit',
        props: {
            selected_card: {
                type: Object,
                required: true
            },
            course_info: {
                type: Object,
            }
        },
        data() {
            return {
                form: this.selected_card.form,
                form_translation: this.selected_card.form_translation,
                context: this.selected_card.context,
                context_translation: this.selected_card.context_translation,
                error: null,
                errorTimeout: null,
                form_in_edit: false,
                form_translation_in_edit: false,
                context_in_edit: false,
                context_translation_in_edit: false
            }
        },
        components: {
            VButton,
            CourseIcon,
            Editable,
            EditIcon
        },
        methods: {
            highlight(string, query) {
                if (string.length > 0) {
                    return string.replace(new RegExp(query, "gi"), match => {
                        return '<span class="highlight">' + match + '</span>';
                    });
                }
            },
            clearErrorTimeout() {
                this.errorTimeout = setTimeout(() => {
                    this.error = null;
                }, 4000);
            },
            updateCardField(value, field) {
                let valid = false;
                let error = null;
                value = value.trim();

                switch (field) {
                    case 'form':
                        valid = value.length > 0;
                        if (valid) {
                            this.form = value;
                        }
                        break;
                    case 'form_translation':
                        valid = true;
                        this.form_translation = value;
                        break;
                    case 'context':
                        valid = this.validateContext(this.selected_card.form, value);
                        if (valid) {
                            this.context = value;
                        } else {
                            error = this.highlight(i18nUtils.prop('context', { form: this.selected_card.form }, null, 'lessons_creator_editor_card_preview_error'), this.selected_card.form);
                        }
                        break;
                    case 'context_translation':
                        valid = true;
                        this.context_translation = value;
                        break;
                }
                if (valid) {
                    this.error = null;
                } else if (error) {
                    this.error = error;
                    this.clearErrorTimeout();
                }
            },
            validateContext(form, context) {
                const validate_form = form.toLowerCase().replace(/\s/g,'_');
                const validate_context = context.toLowerCase().replace(new RegExp(form.toLowerCase(), 'g'), validate_form);
                const punctuation =  '.,?¿!¡:;"\'\\-…';
                const expression = format('(%s)|(%s)|(%s)|(%s)',
                    format('^[%s]*%s[%s]*$', punctuation, validate_form, punctuation),
                    format('^[%s]*%s[\'-]\\S*[%s]*$', punctuation, validate_form.replace(/'$/,''), punctuation),
                    format('^[%s]*\\S*[\'-]%s[%s]*$', punctuation, validate_form.replace(/^'/,''), punctuation),
                    format('^\\S*[%s]%s[%s]\\S*$', punctuation, validate_form, punctuation)
                );
                const tokens = _(validate_context).split(/\s+/);
                const match = _(tokens).some(function (token) {
                    const re = new RegExp(expression, 'i');
                    return re.exec(token);
                });
                return Boolean(match);
            },
            save() {
                const cardFields = {
                    form: this.form,
                    form_translation: this.form_translation,
                    context: this.context,
                    context_translation: this.context_translation,
                };
                const updatedCard = { ...this.selected_card, ...cardFields };
                EventBus.$emit('cw:update-card', updatedCard);
            },
            cancel() {

                this.$emit('close');
            }
        },
        computed: {
            cardHasChanges() {
                return (this.selected_card.form !== this.form || this.selected_card.form_translation !== this.form_translation || this.selected_card.context !== this.context || this.selected_card.context_translation !== this.context_translation)
            },
            context_highlighted() {
                if (!this.context || this.context.length <= 0) {
                    return this.highlight(this.form, this.form);
                } else {
                    return this.highlight(this.context, this.form);
                }
            },
            context_translation_highlighted() {
                return this.highlight(this.context_translation, this.form_translation);
            },
            context_translation_placeholder() {
                if (!this.context_translation || this.context_translation.length <= 0) {
                    return i18nUtils.getRawMessage('lessons_creator_editor_card_preview_sentence_translation_placeholder');
                } else {
                    return '';
                }
            },
            form_translation_placeholder() {
                if (!this.form_translation || this.form_translation.length <= 0) {
                    return i18nUtils.getRawMessage('lessons_creator_editor_card_preview_sentence_translation_placeholder');
                } else {
                    return '';
                }
            },
            lessonTargetLanguageIconId() {
                return this.course_info.target_icon_id;
            },
            lessonSourceLanguageIconId() {
                return this.course_info.source_icon_id;
            },
        },
        watch: {
            selected_card(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.form = this.selected_card.form;
                    this.form_translation = this.selected_card.form_translation;
                    this.context =  this.selected_card.context;
                    this.context_translation = this.selected_card.context_translation;
                }
            }
        },
        beforeDestroy() {
            clearTimeout(this.errorTimeout);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.card-preview {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: .5rem;
        @include elevated;
        padding: 2rem 1.25rem 1.5rem 1rem;

        > div.block {
            position: relative;
            padding-left: 2.5rem;
            > div.course-icon {
                position: absolute;
                left: 0;
                top: 0;
            }

            > div.row {
                &.form,
                &.form-translation {
                    margin-bottom: 1rem;
                }
                > div.context {
                    line-height: 1.4rem;
                    > span.content {
                        &:empty::after {
                            content: ' ';
                        }
                        > span.highlight {
                            background-color: #e0ebef;
                            box-shadow: 0 0 0 pxToRem(2) #e0ebef;
                            border-radius: pxToRem(2);
                            color: $colorTargetPrimary;
                            padding-left: pxToRem(2);
                            padding-right: pxToRem(2);
                        }
                    }
                }
                > div.context-translation {
                    > span.content {
                        &:empty::after {
                            content: ' ';
                        }
                        > span.highlight {
                            font-weight: $font-bold;
                            text-decoration: underline;
                        }
                    }
                }
                > div.form,
                > div.form-translation,
                > div.context,
                > div.context-translation {
                    padding: .2rem;
                    background: rgba(224, 235, 239, 0.4);
                    box-shadow: 0 0 0 pxToRem(4) rgba(224, 235, 239, 0.4);
                    border-radius: pxToRem(2);
                    &:empty::after {
                        content: ' ';
                    }
                    > span.edit-icon {
                        display: none;
                        position: absolute;
                        bottom: .2rem;
                        right: .2rem;
                        background-color: #fff;
                        border-radius: pxToRem(4);
                        padding: .2rem;
                        box-shadow: 0 pxToRem(1) pxToRem(2) 0 rgba(0, 0, 0, 0.3);
                    }
                    &.empty,
                    &:hover {
                        cursor: text;
                        > span.edit-icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    &.empty {
                        color: lighten($colorSourceSecondary, 20);
                    }
                }

                > div.context-editable {
                    line-height: 1.4rem;
                }
            }
        }

        > hr {
            border: none;
            border-bottom: pxToRem(1) solid $colorBackgroundDarker;
            margin: 1rem 0;
        }

        > div.error {
            color: $colorIncorrect;
            margin-top: 1rem;
            font-size: .8rem;
            > span.highlight {
                font-weight: $font-bold;
                text-decoration: underline;
            }
        }

        > div.actions {
            margin-top: 2rem;
            display: flex;
            flex-direction: row-reverse;
            > button {
                margin-right: 1rem;
                &:first-child {
                    margin-right: 0;
                }
            }
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity .5s;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
    }
</style>
