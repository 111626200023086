var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M30 17v16c-5.436 0-10-4.513-10-10s4.564-10 10-10c2.615 0 5 1 6 2v2h-6z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10 34h20V17h6l1-7-11-4s-1.526 4-6 4-6-4-6-4L3 10l1 7h6v9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }