import getConfigValue from '../../util/configuration.js';
import {SigninPopup} from '../../modules/third_party_signin/popup.js';

const Weibo = {

    showWeiboLogin: async function () {
        let redirect_uri = `${window.location.protocol}//${window.location.host}/oauth.html?provider=weibo`;
        let url = `https://api.weibo.com/oauth2/authorize` +
                  `?client_id=${encodeURIComponent(getConfigValue('weibo-app-id'))}` +
                  `&scope=email` +
                  `&redirect_uri=${encodeURIComponent(redirect_uri)}`;

        return await (new SigninPopup(redirect_uri).open(url));
    }
};

export default Weibo;

