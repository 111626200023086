var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("circle", { attrs: { cx: "20", cy: "14", r: "10", fill: "#63E1E7" } }),
      _c("path", {
        attrs: {
          d: "M20 7.083V35.25M7 16h26M20 16c4.063 7.105 7.8 11.21 13 15M20 16c-4.063 7.105-7.8 11.21-13 15",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }