<template>
    <div class="card-context-translations"  :class="{ open: this.open }">
        <div class="toggle-icon" @click="toggleOpen" v-if="hasContentTranslations">
            <arrow-icon />
        </div>
        <h3 @click="toggleOpen" id="card-context-translation-title" :class="{ isClickable: hasContentTranslations}">
            <template v-for="(item, index) in translations">
                <span class="translation-start" v-if="item.translationStart">{{ item.translationStart }}</span>
                <span class="translation">{{ item.translation }}</span>
                <span class="translation-end" v-if="item.translationEnd">{{ item.translationEnd }}</span>
                <span v-if="index+1 < translations.length" class="comma">, </span>
            </template>
        </h3>
        <div class="comments" v-if="contextTranslationComments">
            <div class="comment" v-for="comment in contextTranslationComments">
                {{ comment }}
            </div>
        </div>
        <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave" v-if="hasContentTranslations">
            <div class="card-context-translations-content" v-show="open">
                <div v-for="(context_translation) in question.context_translation" class="translation-row" :data-type="context_translation.type">
                    <div class="translation" v-html="context_translation_highlighted(context_translation.translation)" />
                    <div class="context_type" v-if="context_translation.type==='literal'">({{ context_translation.type }})</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ArrowIcon from 'Images/arrow-3.svg';

export default {
        name: "card-context-translations",
        components: {
            ArrowIcon
        },
        props: {
            question: {
                type: Object,
                required: true
            }
        },
        computed: {
            translations() {
                if (this.question && this.question.translations && this.question.translations.length > 0) {
                    return this.question.translations.map(item => {
                        return {
                            translation: item.translation,
                            translationStart: item.begin_translation,
                            translationEnd: item.end_translation,
                        }
                    });
                }
            },
            hasContentTranslations() {
                return this.question.context_translation && this.question.context_translation.length > 0;
            },
            contextTranslationComments() {
                if (this.question && this.question.translations_comments && this.question.translations_comments.length > 0) {
                    return this.question.translations_comments[0].comments.map(item => {
                        return item.comment;
                    });
                }
            }
        },
        data() {
            return {
                open: true
            }
        },
        methods: {
            highlight(string, query) {
                if (string.length > 0) {
                    let content = { match_found: false, string };
                    content.string = string.replace(new RegExp(` ${ this.escapeRegExp(query) } `, "gi"), match => {
                        content.match_found = true;
                        return ' <span class="highlight">'+ match.trim() +'</span> ';
                    });

                    return content;
                }
            },
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            },
            context_translation_highlighted(context_translation) {
                if (this.question.translations && this.question.translations.length > 0) {
                    context_translation = context_translation.replace(/(<([^>]+)>)/gi, ""); // remove all tags from the string
                    let form_translation = this.question.translations[0].translation;
                    let form_translations = form_translation.split(',');
                    let hct;

                    if (form_translations.length >= 1) {
                        for (let ft of form_translations) {
                            hct = this.highlight(context_translation, ft);
                            if (hct.match_found) {
                                break;
                            }
                        }
                    } else {
                        hct = this.highlight(context_translation, form_translation);
                    }

                    return hct.string;
                }
            },
            toggleOpen() {
                this.open = !this.open;
            },
            enter(el) {
                el.style.height = 'auto';
                const height = getComputedStyle(el).height;
                el.style.height = 0;
                getComputedStyle(el);
                setTimeout(() => {
                    el.style.height = height;
                });
            },
            afterEnter(el) {
                el.style.height = 'auto';
            },
            leave(el) {
                el.style.height = getComputedStyle(el).height;
                getComputedStyle(el);
                setTimeout(() => {
                    el.style.height = 0;
                });
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    $guessCardBorderRadius: pxToRem(12);
    $guessCardPadding: pxToRem(24);

    div.card-context-translations {
        position: relative;
        padding: $guessCardPadding;
        background-color: rgba(255, 255, 255, 0.5);
        border-bottom-left-radius: $guessCardBorderRadius;
        border-bottom-right-radius: $guessCardBorderRadius;
        body[data-course-uuid="2645fad7-0272-4aaa-848a-583b844967b0"] & {
            font-family: $hindiDefaultFonts;
        }
        > div.toggle-icon {
            cursor: pointer;
            position: absolute;
            top: pxToRem(30);
            right: pxToRem(25);
            width: pxToRem(24);
            height: pxToRem(24);
            display: flex;
            justify-content: center;
            align-items: center;
            body[data-interface-language="ar"] & {
                right: unset;
                left: pxToRem(25);
            }
            > svg {
                transform: rotate(90deg);
            }
        }
        &.open {
            > div.toggle-icon {
                > svg {
                    transform: rotate(270deg);
                }
            }
        }
        > h3 {
            font-weight: $font-bold;
            display: inline-block;
            user-select: none;
            &.isClickable {
                &:hover {
                    cursor: pointer;
                }
            }
            > span.comma,
            > span.translation-start,
            > span.translation-end {
                color: $colorSourceSecondary;
            }
        }
        > div.comments {
            color: $colorSourceSecondary;
            font-size: fontSize(14);
        }
        > div.card-context-translations-content {
            margin-top: 1rem;
            div.translation-row {
                > div.translation {
                    > span.highlight {
                        font-weight: $font-bold;
                        text-decoration: underline;
                    }
                }
                &[data-type="literal"] {
                    color: $colorSourceSecondary;
                    display: flex;
                    flex-direction: row;
                    gap: .5rem;
                    > div.context_type {
                        opacity: 0.8;
                        font-weight: $font-bold;
                    }
                }
            }
        }
        .expand-enter, .expand-leave-to {
            opacity: 0;
        }
        .expand-enter-active, .expand-leave-active {
            transition: height 250ms ease-in-out, opacity 100ms ease-in-out;
            overflow: hidden;
        }
    }
</style>
