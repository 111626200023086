import Backbone from 'backbone';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import ChallengesView from '../view/challenges/challenges.view.js';
import { Device } from '../util/device.js';
import getConfigValue from '../util/configuration.js';
import { TYPE, CATEGORY } from '../modules/exercises/constants.js';
import { parse as parse_qs } from '../util/query-string.js';

export class ChallengesController {

    constructor () {
        this.state = {
            filter: 'all',
            currentChallengeUuid: null,
            nextChallenge: null
        };
    }

    go (appView, router, queryString=null) {

        let {action, filter, open_challenge, uuid : challenge_uuid, type, order} = parse_qs(queryString);

        return Promise.resolve()
            .then(() => UserManager.instance.getUser().getCourse().waitForSynced())
            .then(async () => {

                this.conversationChallengesSupported = Device.isSpeechRecognitionSupported() &&
                    getConfigValue('geo-location') !== 'CN';

                const _subscription = await UserManager.instance.getUser().getSubscription();
                this.isDisabled = !_subscription.isSubscriptionActive();

                this.updateChallengesList();

                let category, uuid;

                if (action === 'start-next' && this.state.nextChallenge !== null) {
                    category = this.state.nextChallenge.category;
                    uuid = this.state.nextChallenge.uuid;
                } else if (open_challenge !== undefined) {
                    let exercise_info;

                    if (challenge_uuid !== undefined) {
                        let exercise = UserManager.instance.getUser().getCourse().getExercises().getExercise(challenge_uuid);
                        exercise_info = exercise && exercise.getInfo();

                        if (!exercise_info) {
                            console.warn(`Unable to find challenge for provided uuid="${challenge_uuid}!"`);
                        }
                    } else if (type !== undefined && order !== undefined) {
                        let exercises_info = this.state.newChallenges.filter(challenge => {
                            return challenge.type === type;
                        });

                        exercise_info = exercises_info[order === 'latest' ? (exercises_info.length - 1) : 0];
                    }

                    if (exercise_info !== undefined) {
                        category = exercise_info.category;
                        uuid = exercise_info.uuid;
                    }
                }

                if (category !== undefined && uuid !== undefined) {
                    switch (category) {
                        case CATEGORY.SPEAKING: {
                            Backbone.history.navigate(`challenges/speaking?uuid=${uuid}`, {trigger: true});
                            break;
                        }
                        case CATEGORY.GRAMMAR: {
                            Backbone.history.navigate(`challenges/grammar?uuid=${uuid}`, {trigger: true});
                            break;
                        }
                        case CATEGORY.LISTENING: {
                            Backbone.history.navigate(`challenges/listening?uuid=${uuid}`, {trigger: true});
                            break;
                        }
                        case CATEGORY.READING: {
                            Backbone.history.navigate(`challenges/reading?uuid=${uuid}`, {trigger: true});
                            break;
                        }
                    }

                    this.setCurrentChallenge(uuid);
                    return;
                }

                const challengesView = new ChallengesView();

                const challengesProps = {
                    new: this.state.newChallenges,
                    completed: this.state.completedChallenges,
                    filter: this.state.filter,
                    conversationChallengesSupported: this.conversationChallengesSupported,
                    isDisabled: this.isDisabled
                };

                appView.setMainView(challengesView, true);
                challengesView.setProps(challengesProps);
                challengesView.render();

                if (filter) {
                    challengesView.filterChallenges(filter);
                }

                challengesView.on('activate-filter', filter => {
                    this.state.filter = filter;
                });

                // When a challenge is started, reference to current challenge
                // and also next challenge (if available) is added to the state
                challengesView.on('start-challenge', payload => {
                    this.setCurrentChallenge(payload.challengeUuid);
                });
            });
    }

    setCurrentChallenge (challengeUuid) {

        this.state.currentChallengeUuid = challengeUuid;

        let filteredChallenges = this.state.newChallenges;

        // Get subset of challenges if a filter is activated
        if (this.state.filter !== 'all') {
            filteredChallenges = this.state.newChallenges.filter(challenge => challenge.category === this.state.filter);
        }

        const currentChallengeIndex = filteredChallenges.findIndex(
            challenge => challenge.uuid === this.state.currentChallengeUuid
        );

        if (currentChallengeIndex < (filteredChallenges.length - 1)) {
            const nextChallengeIndex = currentChallengeIndex + 1;

            const nextChallenge = filteredChallenges[nextChallengeIndex];

            this.state.nextChallenge = {
                category: nextChallenge.category,
                uuid: nextChallenge.uuid
            };
        } else {
            this.state.nextChallenge = null;
        }
    }

    updateChallengesList () {
        const allChallenges = UserManager.instance.getUser().getCourse().getExercises();

        this.state.newChallenges = allChallenges.getExercisesInfo({ completed: false });
        this.state.completedChallenges = allChallenges.getExercisesInfo({ completed: true });

        if ( ! this.conversationChallengesSupported) {
            this.state.newChallenges = this.state.newChallenges.filter(challenge => {
                return challenge.type !== TYPE.SPEAKING;
            });

            this.state.completedChallenges = this.state.completedChallenges.filter(challenge => {
                return challenge.type !== TYPE.SPEAKING;
            });
        }
    }
}

export const challenges_controller_factory = new ControllerFactory(ChallengesController);
