<template>
    <section class="has-account-ask main-content">
        <template v-if="this.email">
            <p v-if="this.oauth_input" v-html="this.$i18n('signin_social_profile_label', { args: { provider: this.oauth_input.provider }})" />
            <p v-html="this.$i18n('signin_social_profile_error_no_account')" class="error" />
            <div class="box">
                <h3 v-html="this.$i18n('signin_social_profile_no_account_guide_title')" />
                <p v-html="this.$i18n('1', { collection: 'signin_social_profile_guide_text' })" />
                <p v-html="this.$i18n('2', { collection: 'signin_social_profile_guide_text' })" />
                <div class="actions">
                    <v-button @click.native="navigateToLogin" :filled="true" color="primary"
                              :i18n="{ id: 'register_error_log_in_link' }" />
                    <v-button @click.native="createNewAccount" color="secondary"
                              :i18n="{ id: 'credentials_ask_create_new_account_btn' }" />
                </div>
            </div>
        </template>
        <template v-else>
            <p v-if="this.oauth_input" v-html="this.$i18n('signin_social_profile_label', { args: { provider: this.oauth_input.provider }})" />
            <p v-html="this.$i18n('signin_social_profile_error_no_email')" class="error" />
            <p>{{ email }}</p>
            <div class="box">
                <h3 v-html="this.$i18n('signin_social_profile_no_email_guide_title')" />
                <p v-html="this.$i18n('1', { collection: 'signin_social_profile_guide_text' })" />
                <p v-html="this.$i18n('2', { collection: 'signin_social_profile_guide_text' })" />
                <div class="actions">
                    <v-button @click.native="navigateToLogin" :filled="true" color="primary"
                              :i18n="{ id: 'register_error_log_in_link' }" />
                    <v-button @click.native="createNewAccount" :borderless="true"
                              :i18n="{ id: 'credentials_ask_create_new_account_btn' }" />
                </div>
            </div>
        </template>
    </section>
</template>

<script>
    import VButton from "ViewComponent/v-button.vue";
    import Backbone from "backbone";

    export default {
        name: 'register.has-account-ask',
        components: { VButton },
        props: {
            email: {
                type: String
            },
            oauth_input: {
                type: Object
            }
        },
        methods: {
            navigateToLogin() {
                Backbone.history.navigate('signin', { trigger: true });
            },
            createNewAccount () {
                this.$emit('register-new-account');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    section.has-account-ask {
        > p {
            margin: .5rem 0;
            color: $colorSourceSecondary;
            &.error {
                color: $colorIncorrect;
            }
        }
        > div.box {
            margin-top: 3rem;
            padding: 1.5rem;
            border-radius: pxToRem(12);
            background-color: $colorBackgroundLighter;
            > h3 {
                font-weight: $font-bold;
            }
            > p {
                margin-top: 1rem;
            }

            > div.actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                margin-top: 2rem;
                > button {
                    margin-top: .5rem;
                }
            }
        }
    }
</style>
