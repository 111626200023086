<template>
    <div class="course-select" @click="openCourseModal">
        <course-icon v-if="activeCourse && activeCourse.target_icon_id" :value="activeCourse.target_icon_id" size="large" />
        <span class="text" v-html="this.$i18n('hub_section_language_selection_btn')" />
        <svg class="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 14.998L12 9.998L8 5" stroke="#2C3143" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</template>

<script>
    import CourseIcon from 'ViewComponent/course-icon.vue';
    export default {
        name: 'course-select',
        components: {
            CourseIcon,
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            courses: {
                type: Object,
                required: true
            },
        },
        computed: {
            activeCourse() {
                return (this.courses && this.courses.activeCourse) ? this.courses.activeCourse : null;
            }
        },
        methods: {
            openCourseModal() {
                this.$emit('open-course-modal');
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.course-select {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        background-color: $colorBackgroundLighter;
        border-radius: pxToRem(8);
        user-select: none;
        align-items: center;
        padding: 1rem;
        transition: background-color 250ms;
        &:hover {
            cursor: pointer;
            background-color: rgba($colorBackgroundLighter, 0.6);
        }
        svg.arrow {
            body[data-interface-language="ar"] & {
                transform: rotate(180deg);
            }
        }
    }
</style>
