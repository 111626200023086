import Vue from 'vue';
import ControllerFactory from '../modules/controller.factory.js';
import MainView from '../view/account/main.vue';

export class AccountController {

    constructor () {}

    async go (appView, router) {
        const AccountViewComponent = Vue.extend(MainView);
        appView.setMainView(new AccountViewComponent());
    }
}

export const account_controller_factory = new ControllerFactory(AccountController);
