var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "autosize-input", class: { hidden: this.hidden } },
    [
      _c(
        "input",
        _vm._b(
          {
            ref: "inputField",
            staticClass: "autosize-input-box",
            class: _vm.className,
            style: _vm.styles,
            attrs: {
              width: `${_vm.inputWidth}px`,
              disabled: this.disabled,
              type: "text",
              autocomplete: "off",
              autocorrect: "off",
              autocapitalize: "off",
              spellcheck: "false",
            },
            domProps: { value: _vm.value },
            on: {
              input: _vm.updateValue,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                return _vm.submit.apply(null, arguments)
              },
              blur: _vm.blurIt,
              focus: () => _vm.$emit("focus"),
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c("span", { ref: "sizer", style: _vm.sizerStyle }, [
        _vm._v(_vm._s(_vm.value)),
      ]),
      _vm._v(" "),
      _vm.placeholder
        ? _c("span", { ref: "placeHolderSizer", style: _vm.sizerStyle }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }