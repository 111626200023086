var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M29 13l-3 1 4-7 3.5 6.488L32 16l-3-3z", fill: "#AC6ED1" },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17 8.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36 25l.764-3.436a3 3 0 00-.266-2.032L30 7l-5.5 8",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M22 20l-2-2 3-5 2.5 4-3.5 3z", fill: "#AC6ED1" },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M23 13l-7.5 12h15L23 13z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.6 21.8a.5.5 0 01.4-.8h.898a.5.5 0 00.377-.83l-2.062-2.356a.491.491 0 01.37-.814.491.491 0 00.408-.764l-3.075-4.612a.5.5 0 00-.832 0l-3.075 4.612a.491.491 0 00.409.764c.422 0 .647.497.37.814l-2.062 2.357a.5.5 0 00.376.829H4a.5.5 0 01.4.8l-1.8 2.4a.5.5 0 00.4.8h9a.5.5 0 00.4-.8l-1.8-2.4z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34 28c-2.283 0-2.386 2-4.667 2-2.28 0-2.386-2-4.666-2-2.282 0-2.386 2-4.667 2-2.283 0-2.384-2-4.667-2-2.282 0-2.384 2-4.666 2C8.384 30 8.282 28 6 28M36 25H2M11 34c2.208 0 2.292-2 4.5-2 2.207 0 2.292 2 4.5 2 2.207 0 2.293-2 4.5-2 2.206 0 2.294 2 4.5 2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }