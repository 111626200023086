<template>
    <div class="campaign-10-20">
        <svg width="181" height="180" viewBox="0 0 181 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="24" width="180" height="132" rx="12" fill="#63E1E7"/>
            <path d="M181 136.184V144C181 150.627 175.627 156 169 156H140.56L164.428 138.655C169.401 135.041 175.597 134.323 181 136.184Z" fill="url(#paint0_linear_4462_2948)" fill-opacity="0.8"/>
            <path opacity="0.49" d="M159.935 24L162.878 25.3571C166.319 26.944 167.823 31.0205 166.236 34.4622L160.511 46.8777C158.924 50.3193 154.848 51.8228 151.406 50.2359L138.99 44.5111C135.549 42.9241 134.045 38.8476 135.632 35.4059L140.892 24H159.935Z" fill="url(#paint1_linear_4462_2948)"/>
            <path d="M76.69 24H13.0004C10.2036 24 7.63024 24.9568 5.58984 26.5609L8.67523 33.2019C13.402 43.3757 25.4814 47.7915 35.6553 43.0647L76.69 24Z" fill="url(#paint2_linear_4462_2948)" fill-opacity="0.8"/>
            <path opacity="0.69" d="M45.436 156C38.3985 144.033 25.3873 136 10.5 136C7.22822 136 4.04706 136.388 1 137.12V139.181C4.03867 138.41 7.22148 138 10.5 138C24.2281 138 36.278 145.185 43.0943 156H45.436Z" fill="url(#paint3_linear_4462_2948)"/>
            <path d="M110.153 119.304C110.153 108.639 117.841 99.7851 127.971 97.9422C128.505 95.4015 128.8 92.7735 128.8 90.0692C128.8 69.2308 111.909 52.3398 91.0704 52.3398C70.232 52.3398 53.3301 69.2199 53.3301 90.0692C53.3301 110.919 70.2211 127.799 91.0595 127.799C98.1364 127.799 104.745 125.847 110.404 122.466C110.251 121.43 110.153 120.384 110.153 119.304Z" fill="#414B72"/>
            <path d="M91.6807 77.2588V81.5224L105.518 90.0606L91.6807 98.5988V102.862L111.908 90.0606L91.6807 77.2588Z" fill="white"/>
            <path d="M76.6868 96.4724V79.3961L72.4014 77.2588V102.862L91.6804 92.1979V87.9342L76.6868 96.4724Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M133 104.249C124.3 104.249 117.248 111.301 117.248 120C117.248 128.699 124.3 135.751 133 135.751C141.699 135.751 148.751 128.699 148.751 120C148.751 111.301 141.699 104.249 133 104.249ZM114.751 120C114.751 109.922 122.921 101.751 133 101.751C143.078 101.751 151.248 109.922 151.248 120C151.248 130.078 143.078 138.249 133 138.249C122.921 138.249 114.751 130.078 114.751 120Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M133 106.751C133.689 106.751 134.248 107.31 134.248 108V115C134.248 115.69 133.689 116.249 133 116.249C132.31 116.249 131.751 115.69 131.751 115V108C131.751 107.31 132.31 106.751 133 106.751Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M141.882 113.117C142.37 113.605 142.37 114.395 141.882 114.883L138.882 117.883C138.395 118.37 137.604 118.37 137.117 117.883C136.629 117.395 136.629 116.605 137.117 116.117L140.117 113.117C140.604 112.63 141.395 112.63 141.882 113.117Z" fill="white"/>
            <path d="M133 122C134.105 122 135 121.105 135 120C135 118.895 134.105 118 133 118C131.895 118 131 118.895 131 120C131 121.105 131.895 122 133 122Z" fill="white"/>
            <path d="M91.0695 39.751C63.2826 39.751 40.751 62.2713 40.751 90.0695C40.751 117.868 63.2826 140.388 91.0695 140.388C97.2439 140.388 103.355 139.235 109.124 137.046C109.591 137.397 110.172 137.604 110.802 137.604C112.35 137.604 113.604 136.35 113.604 134.801C113.604 133.253 112.35 131.999 110.802 131.999C109.254 131.999 108 133.253 108 134.801C102.611 136.854 96.8343 137.891 91.0695 137.891C64.6614 137.891 43.2481 116.488 43.2481 90.0695C43.2481 63.6508 64.6614 42.2481 91.0695 42.2481C117.478 42.2481 138.891 63.6515 138.88 90.0586C138.88 91.2309 138.835 92.3934 138.75 93.5442L135.017 89.7892C134.53 89.3002 133.74 89.2979 133.251 89.784C132.762 90.2702 132.76 91.0607 133.246 91.5497L139.572 97.9133L146.983 92.845C147.553 92.4558 147.699 91.6788 147.309 91.1096C146.92 90.5404 146.143 90.3946 145.574 90.7838L141.239 93.7485C141.33 92.5318 141.377 91.3013 141.377 90.0591C141.388 62.2712 118.856 39.751 91.0695 39.751Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_4462_2948" x1="201.184" y1="142.065" x2="45.0429" y2="257.352" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0697141" stop-color="#414B72"/>
                    <stop offset="0.54662" stop-color="#414B72" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint1_linear_4462_2948" x1="156.67" y1="22.4937" x2="125.754" y2="84.9128" gradientUnits="userSpaceOnUse">
                    <stop offset="0.144591" stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_4462_2948" x1="8.56265" y1="32.6832" x2="116.026" y2="-15.7788" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#414D72"/>
                    <stop offset="0.788306" stop-color="#414B72" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear_4462_2948" x1="10.5" y1="136" x2="24.4383" y2="286.166" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
            </defs>
        </svg>
        <div class="campaign-text">
            <h4 v-html="this.$i18n('product_campaign_20_10_years_highlights_title')"/>
            <p v-html="this.$i18n('product_campaign_20_10_years_highlights_text')"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'campaign-10-20-heading',
}
</script>

<style lang="scss" scoped>
    @import '~Styles/colors';
    @import '~Styles/mixins';
    div.campaign-10-20 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;
        gap: 1rem;
        max-width: pxToRem(616);
        margin: 0 auto;
        div.campaign-text {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            > h4 {
                font-size: 1.25rem;
                font-weight: $font-bold;
            }
        }
    }
</style>
