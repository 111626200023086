var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#8EA3EE",
          d: "M12 12.5c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5h-5c-.3 0-.5-.2-.5-.5zM12 16.5c0-.3.2-.5.5-.5h4c.3 0 .5.2.5.5s-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5zM12 14.5c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5h-3c-.3 0-.5-.2-.5-.5zM6 12c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1v-5zM6 9c0-.6.4-1 1-1h10c.6 0 1 .4 1 1s-.4 1-1 1H7c-.6 0-1-.4-1-1z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M2 5c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v17H11c-.6 0-1-.4-1-1s.4-1 1-1h9V5H4v16c0 .6-.4 1-1 1s-1-.4-1-1V5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }