<template>
    <section class="name">
        <h2 v-html="this.$i18n('account_profile_account_name_title')" />
        <div class="field" :class="{ focused: this.inputFocused }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.2 14L18.2377 15.9789C19.3391 16.6167 20 17.6622 20 18.7796V21H4V18.7796C4 17.6622 4.49275 16.5045 5.6 15.8667L8.8 14" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
                <path d="M12 3C10.3417 3 9 4.32763 9 5.96866V8.03134C9 9.67236 10.3417 11 12 11C13.6583 11 15 9.67236 15 8.03134V5.96296C15 4.32764 13.6526 3 12 3Z" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <input type="text" name="name" ref="name"
                   @focus="inputFocused = true"
                   v-model="nameLocal"
                   :placeholder="namePlaceholder" v-on:keyup.enter="save" />
            <edit-icon v-if="!inputFocused" @click.native="focusInput" />
        </div>
        <transition name="slide-down-fade">
            <div class="actions" v-if="inputFocused && !nameNotChanged">
                <v-button :i18n="{ id: 'account_profile_cancel_btn' }" @click.native="cancel" :filled="false" size="medium" color="primary" />
                <v-button :i18n="{ id: 'account_profile_input_action_save' }" @click.native="save" :filled="true" :disabled="!contentIsValid" size="medium" color="primary" />
            </div>
        </transition>
    </section>
</template>

<script>
    import i18nUtils from "Util/i18n.js";
    import EditIcon from "./edit-icon.vue";
    import VButton from "ViewComponent/v-button.vue";

    export default {
        name: 'section-name',
        components: { VButton, EditIcon },
        props: {
            name: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                nameLocal: '',
                inputFocused: false
            }
        },
        computed: {
            namePlaceholder() {
                return i18nUtils.prop('account_profile_name_placeholder')
            },
            contentIsValid() {
                return this.nameLocal && this.nameLocal.length >= 2;
            },
            nameNotChanged() {
                return this.nameLocal === this.name;
            }
        },
        methods: {
            save() {
                this.$emit('save', this.nameLocal);
            },
            cancel() {
                this.inputFocused = false;
                this.nameLocal = this.name;
                this.$refs.name.blur();
            },
            focusInput() {
                this.inputFocused = true;
                this.$refs.name.focus();
            }
        },
        watch: {
            name(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.nameLocal = newValue;
                }
            }
        },
        beforeMount() {
            this.nameLocal = this.name;
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.name {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 .5rem;
        > h2 {
            font-size: fontSize(16);
            line-height: fontSize(20);
        }
        > div.field {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $colorBackgroundDarker;
            &.focused {
                border-bottom: 1px solid $colorBackgroundDark;
            }
            > input {
                flex: 1;
            }
            > svg.edit-icon {
                margin-left: auto;
                cursor: pointer;
                transition: all 150ms;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
        > div.actions {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: flex-end;
            align-items: center;
        }
        .slide-down-fade-enter-active {
            animation: fade-slide-in 0.3s;
        }
        .slide-down-fade-leave-active {
            animation: fade-slide-in 0.3s reverse;
        }
        @keyframes fade-slide-in {
            0% {
                opacity: 0;
                transform: translateY(-100px);
            }
            25% {
                opacity: 0;
            }
            50% {
                opacity: 0;
            }
            75% {
                opacity: 0.4;
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
</style>
