var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("rect", {
        attrs: {
          x: "19",
          y: "35",
          width: "20",
          height: "3",
          rx: "1",
          fill: "#63E1E7",
        },
      }),
      _c("rect", {
        attrs: {
          x: "22",
          y: "32",
          width: "14",
          height: "2",
          rx: "1",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24 7l8 13m-15-9l2.48 4.132a1 1 0 01-.355 1.378L2.857 26a1 1 0 00-.364 1.36l1.012 1.772a1 1 0 001.364.372L18 22m7.5 2.5l-3-5m3.32 9.001l9.79-5.96a1 1 0 00.312-1.408l-.89-1.335a1 1 0 00-1.352-.3l-9.79 5.96a1 1 0 00-.312 1.409l.89 1.334a1 1 0 001.352.3zM15.809 11.543l9.754-5.513a1 1 0 00.34-1.426l-.888-1.331a1 1 0 00-1.324-.316L13.937 8.47a1 1 0 00-.34 1.426l.888 1.33a1 1 0 001.324.317z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }