
import $ from 'jquery';
import {} from 'timeago';  // just import jquery.timeago to attach it to $
import _ from 'lodash';
import Backbone from 'backbone';
import moment from 'moment';
import humanizeDuration from "humanize-duration";
import momentDurationFormatSetup from 'moment-duration-format';
import Raven from 'raven-js';
import WebappStrings from '@lingvist/webapp-strings';

import { getPersistentStorageProvider } from'../modules/persistent.storage.provider.js';
import googleAnalyticsCommand from '../util/google-analytics.js';
import getConfigValue from '../util/configuration.js';
import getLocaleDefinition from '../util/duration.locale.js';

momentDurationFormatSetup(moment);

import ExportUtils from "../util/export.js";
import KeyCodes from '../util/keycodes.js';
import { assetLoader } from '../modules/global.asset.loader.js';
import UserManager from '../modules/usermanager.js';

import messages from '../available-messages-languages.js';
import grammarMessages from '../available-grammar-messages-languages.js';
import { EventBus } from "./vue-event-bus.js";

const I18N_CONFIG = {
    tgt_replacement: {
        element: 'span',
        class: 'target',
        highlight_attribute_name: 'data-highlight'
    }
};

class MessageGetter {
    constructor (messages, fallback_messages) {
        this._messages = messages;
        this._fallback_messages = fallback_messages;
    }

    setMessage (key, message) {
        if (key.collection !== undefined) {
            this._messages[key.collection][key.id] = message;
        } else {
            this._messages[key.id] = message;
        }
    }

    _get(messages, key) {
        const id = String(key.id).toLowerCase();
        if (key.collection !== undefined) {
            const collection = String(key.collection).toLowerCase();

            return messages[collection] && messages[collection][id];
        } else {
            return messages[id];
        }
    }

    get (key) {
        let message = this._get(this._messages, key);
        if (message !== undefined) {
            return message;
        } else {
            return this._get(this._fallback_messages, key);
        }
    }
}

const YEAR_MS = 365 * 24 * 60 * 60 * 1000,
    DAY_MS = 24 * 60 * 60 * 1000,
    HOUR_MS = 60 * 60 * 1000,
    MINUTE_MS = 60 * 1000;

const UNITS_ENUM = {
    year: 0,
    day: 1,
    hour: 2,
    minute: 3
};

const LOCALE_MAPPER = {
    'zh-Hans': 'zh-cn',
    'zh-Hant': 'zh-tw'
};

export function getTimeDict (interval, unit) {
    /**
     * :param interval: interval to be converted to a dict in ms
     */

    unit = unit || 'minute';

    let years = Math.floor(interval / YEAR_MS),
        days = Math.floor((interval - years * YEAR_MS) / DAY_MS),
        hours = Math.floor((interval - years * YEAR_MS * days * DAY_MS) / HOUR_MS),
        minutes = Math.floor((interval - years * YEAR_MS * days * DAY_MS - hours * HOUR_MS) / MINUTE_MS);

    return {
        years: UNITS_ENUM[unit] >= UNITS_ENUM.year && years || 0,
        days: UNITS_ENUM[unit] >= UNITS_ENUM.day && days || 0,
        hours: UNITS_ENUM[unit] >= UNITS_ENUM.hour && hours || 0,
        minutes: UNITS_ENUM[unit] >= UNITS_ENUM.minute && minutes || 0
    };
}

export const getDurationSecondsFromTs = timestamp => {
    return timestamp.diff(moment(), 'seconds');
};

export const getDurationFormattedFromTs = timestamp => {
    let seconds = getDurationSecondsFromTs(timestamp);
    try {
        if (seconds > 86400) { // longer than 1 day, return as days + hours
            return moment.duration(seconds, 'seconds')
                .format('d __ h __');
        } else { // return as hours and minutes
            return moment.duration(seconds, 'seconds')
                .format('h __ m __');
        }

    } catch (error) {
        if (error instanceof RangeError) {
            return `${seconds}s`;
        } else {
            throw error;
        }
    }

};


export class I18nMessageNotFoundException extends Error {
    constructor (message) {
        super(message);
        this.name = 'I18nMessageNotFoundException';
    }
}


const i18nUtils = {

    availableInterfaceLanguages: getConfigValue('interface-languages'),

    currentInterfaceLanguage: undefined,
    currentLocale: undefined,

    _messageResolver: null,
    _messageGetter: null,
    _grammarMessageResolvers: {},

    setAppView: function (appView) {
        this._appView = appView;
    },

    _getMessages: function (language) {
        if (_.isString(messages[language])) { // Language exists but messages have not been loaded
            return Promise.resolve().then(function () {
                return assetLoader.loadAsset(`messages/${messages[language]}`, 0);
            }).then(function (messagesResponse) {
                messages[language] = JSON.parse(messagesResponse);
                return Promise.resolve(messages[language]);
            });
        } else if (messages[language] === undefined && messages[language.split('-')[0]] !== undefined) {
            return this._getMessages(language.split('-')[0]);
        } else if (messages[language] === undefined) { // Language doesn't exist
            return Promise.reject(Error(`Attempted to get messages for a non-existent language="${language}"`));
        } else { // Language exists and messages have already been loaded
            return Promise.resolve(messages[language]);
        }
    },

    _getGrammarMessages: function (sourceLanguage, targetLanguage) {
        if (_.isString(grammarMessages[targetLanguage][sourceLanguage])) { // Language exists but messages have not been loaded
            return Promise.resolve().then(function () {
                return assetLoader.loadAsset(`messages/grammar/${targetLanguage}/${grammarMessages[targetLanguage][sourceLanguage]}`, 0);
            }).then(function (messagesResponse) {
                grammarMessages[targetLanguage][sourceLanguage] = JSON.parse(messagesResponse);
                return Promise.resolve(grammarMessages[targetLanguage][sourceLanguage]);
            });
        } else if (grammarMessages[targetLanguage][sourceLanguage] === undefined) { // Language doesn't exist
            return Promise.reject(Error(`Attempted to get grammar messages for a non-existent pair ${sourceLanguage}->${targetLanguage}"`));
        } else { // Language exists and messages have already been loaded
            return Promise.resolve(grammarMessages[targetLanguage][sourceLanguage]);
        }
    },

    _setMessageResolver: function (_messages, messages_fallback, language) {
        this._messageGetter = new MessageGetter(_messages, messages_fallback);
        this._messageResolver = new WebappStrings.MessageResolver(
            this._messageGetter,
            new WebappStrings.PluralityChooser(language),
            {
                blank: {
                    begin: '<span class="blank">',
                    char: '_',
                    end: '</span>',
                    default_length: 3
                }
            }
        );
    },

    setInterfaceLanguage: function (language, reRender) {
        const self = this;

        console.info(`Setting interface language to: ${language}`);

        return Promise.resolve()
            .then(() => Promise.all([this._getMessages(language), this._getMessages('en')]))
            .then(([messages, messages_en]) => this._setMessageResolver(messages, messages_en, language))
            .then(() => {
                if (self.currentInterfaceLanguage !== language) {
                    self.currentInterfaceLanguage = language;
                    self.currentLocale = LOCALE_MAPPER[self.currentInterfaceLanguage] || self.currentInterfaceLanguage;

                    const localeDefinition = getLocaleDefinition(self.currentInterfaceLanguage);
                    moment.updateLocale(self.currentInterfaceLanguage, localeDefinition);

                    Promise.resolve()
                        .then(() => import(  // jshint ignore: line
                            /* webpackMode: "eager" */
                            `../lang/timeago_${self.currentInterfaceLanguage}.js`))
                        .then(module  => {
                            $.timeago.settings.strings = module.default;
                            googleAnalyticsCommand('set', 'dimension1', language);
                            $('body').attr('data-interface-language', language);
                            Backbone.trigger('interfaceLanguageChanged', language);
                            EventBus.$emit('interfaceLanguageChanged', language);
                            if (self._appView && reRender !== false) {
                                self._appView.reRenderAll();
                            }
                        })
                        .then(() => getPersistentStorageProvider().setItemAnonymous('interfaceLanguage', language))
                        .catch(error => {
                            Raven.captureException(error, {level: 'error'});
                            console.error('Error setting i18n language', error);
                        });
                } else {
                    return Promise.resolve();
                }
            }).catch( error => {
                console.warn('Error changing UI language: ', language, error);
            });
    },

    getInterfaceLanguages: function () {
        const languages = {
            current: this.currentInterfaceLanguage || 'en',
            available: []
        };
        for (let i = 0; i < this.availableInterfaceLanguages.length; i++) {
            const language = this.availableInterfaceLanguages[i];
            if (language !== this.currentInterfaceLanguage) {
                languages.available.push(language);
            }
        }
        return languages;
    },

    getRawMessage: function (id, collection=undefined) {
        let message = this._messageGetter.get({collection, id});

        if (message === undefined) {
            let error = new I18nMessageNotFoundException(`i18n message not found for collection=${collection} id=${id}`);
            Raven.captureException(error, {level: 'error', extra: {
                    id: id, collection: collection
                }});
            return `[${collection ? collection + '.': ''}${id}]`;
        } else {
            return message;
        }
    },

    _addDefaultParams (params) {
        const course = UserManager.instance.getUser().getCourse(),
            courseInfo = course.getInfo(),
            course_statistics = course.getStatistics(),
            statisticsData = course_statistics.getData(),
            todayData = course_statistics.getTodayData();

        return _.merge({}, {
            sl: courseInfo.source_language,
            tl: courseInfo.target_language,
            qw: null,  // TODO: Add when and if needed
            ea: null,  // TODO: Add when and if needed
            cu: courseInfo.uuid,
            study_time: getTimeDict(statisticsData.study_time * 1000),
            course_expiry: courseInfo.expiration_ts && getTimeDict(courseInfo.expiration_ts.diff(moment()), 'hour'),
            study_time_today: getTimeDict(todayData.study_time * 1000),
            cards: statisticsData.all_units.total,
            words: course_statistics.getWordsEncountered(statisticsData),
            course_words: courseInfo.words,
            user_name: UserManager.instance.getUser().profile.name
        }, params);
    },

    _add_links_default: function (links) {
        return {
            _default: [
                {role: 'text', value: '<a href="javascript:void(0);"'},
                {role: 'id', before: ' data-id="', after: '"', optional: true},
                {role: 'uri', before: ' data-uri="', after: '"', optional: true},
                {role: 'text', value: '>'},
                {role: 'linktext'},
                {role: 'text', value: '</a>'}
            ],
            ...links
        };
    },

    prop: function(id, params = undefined, images = undefined, collection = undefined, links = undefined, options={ addDefaultParams: false }) {
        const key = {
            id: id,
            collection: collection
        };

        if (options.addDefaultParams) {
            params = this._addDefaultParams(params);
        }

        links = this._add_links_default(links);

        try {
            return this._messageResolver.resolve(
                key,
                params,
                images,
                I18N_CONFIG.tgt_replacement,
                links,
                {
                    tooltip_replacer: (node_name, node_text, tooltip_text, attributes) => {
                        return `<${node_name} data-tooltip="${tooltip_text}">${node_text}</${node_name}>`;
                    }
                });
        } catch (error) {
            const e = Error(`A problem occurred with key key.id="${key.id}" key.collection="${key.collection}" - ${error.name}: "${error.message}"`);
            e.name = 'i18nError';
            Raven.captureException(e, {level: 'error', extra: {
                key: key,
                params: params,
                images: images
            }});

            if (collection !== undefined) {
                return `[${collection}.${id}]`;
            } else {
                return `[${id}]`;
            }
        }
    },

    resolveMessage: function (message, params, images, links, options={addDefaultParams: true}) {
        this._messageGetter.setMessage({id: '__default__'}, message);

        if (options.addDefaultParams) {
            params = this._addDefaultParams(params);
        }

        links = this._add_links_default(links);

        try {
            return this._messageResolver.resolve({id: '__default__'}, params, images, I18N_CONFIG.tgt_replacement, links);
        } catch (error) {
            const e = Error(`A problem occurred with message="${message}" - ${error.name}: "${error.message}"`);
            e.name = 'i18nError';
            Raven.captureException(e, {level: 'error', extra: {
                message: message,
                params: params,
                images: images
            }});

            return message;
        }
    },

    getGrammarProp: function (targetLanguage, id, params, images, collection) {
        const self = this;

        const key = {
                id: id,
                collection: collection
            },
            language = this.currentInterfaceLanguage;

        return Promise.resolve().then(function () {
            if (self._grammarMessageResolvers[targetLanguage] === undefined) {
                self._grammarMessageResolvers[targetLanguage] = {};
            }

            let promise = Promise.resolve();

            if (self._grammarMessageResolvers[targetLanguage][language] === undefined) {
                promise = Promise.resolve().then(function () {
                    return self._getGrammarMessages(language, targetLanguage);
                }).then(function (messages) {
                    self._grammarMessageResolvers[targetLanguage][language] = new WebappStrings.MessageResolver(
                        new MessageGetter(messages),
                        null, // PluralityChooser
                        {
                            blank: {
                                begin: '<span class="blank">',
                                char: '_',
                                end: '</span>'
                            }
                        }
                    );
                });
            }

            return promise.then(function () {
                const messageResolver = self._grammarMessageResolvers[targetLanguage][language];
                try {
                    return Promise.resolve(messageResolver.resolve(key, params, images, I18N_CONFIG.tgt_replacement));
                } catch (error) {
                    console.error(`i18n error for grammar key.id="${key.id}" key.collection="${key.collection}":`, error);
                    Raven.captureException(error, {level: 'error'});
                    return Promise.resolve(`[${id}]`);
                }
            });
        });
    },

    getLocaleDateString: function (date, options) {
        return date.toLocaleDateString(this.currentInterfaceLanguage, options);
    },

    toLocaleStringSupportsOptions: function () {
        return !!(typeof window.Intl === 'object' && window.Intl && typeof window.Intl.NumberFormat === 'function');
    },

    getLocaleFormattedNumber: function (number, options) {
        if (this.toLocaleStringSupportsOptions()) {
            try {
                return number.toLocaleString(this.currentInterfaceLanguage, options);
            } catch (e) {
                Raven.captureMessage('Error formatting number according to locale.', {level: "error", logger: 'manual'});
                return number ? number.toString() : number;
            }
        } else {
            if (options !== undefined) {
                if (options.style === 'percent') {
                    number = number * 100;
                }
                if (options.maximumFractionDigits !== undefined) {
                    number = Math.round(number * options.maximumFractionDigits * 10) / (options.maximumFractionDigits * 10);
                }
            }

            number = number.toString();

            if (options !== undefined) {
                if (options.style === 'percent') {
                    number += '%';
                }
            }

            return number;
        }
    },

    getChartLocale: function () {
        return this.currentInterfaceLanguage === 'ar' ? 'en' : this.currentInterfaceLanguage;
    },

    getChartDateFormat: function () {
        return this.currentInterfaceLanguage === 'ar' ? 'd/M/yyyy' : null;
    },

    getKeyCode: function (action) {
        switch (action) {
            case 'next':
                return KeyCodes.RIGHT_ARROW;
            case 'previous':
                return KeyCodes.LEFT_ARROW;
            default:
                return null;
        }
    },

    getLoginOptions: function () {
        let options = getConfigValue('login-options');

        if (options[this.currentInterfaceLanguage] !== undefined) {
            return options[this.currentInterfaceLanguage];
        } else {
            return options.default;
        }
    },

    getAccountCreationOptions: function () {
        let options = getConfigValue('account-creation-options');

        if (options[this.currentInterfaceLanguage] !== undefined) {
            return {
                primary: options[this.currentInterfaceLanguage].primary,
                secondary: options[this.currentInterfaceLanguage].secondary
            };
        } else {
            return {
                primary: options.default.primary,
                secondary: options.default.secondary
            };
        }
    },

    getPreferredUiLanguage: function () {


        const preferredLanguages = window.navigator.languages;

        if (_.isArray(preferredLanguages)) {
            for (let i = 0; i < preferredLanguages.length; i++) {
                const shortPreferredCode = preferredLanguages[i].slice(0, 2);

                for (let j = 0; j < this.availableInterfaceLanguages.length; j++) {
                    const shortAvailCode = this.availableInterfaceLanguages[j].slice(0, 2);

                    if (shortPreferredCode === shortAvailCode) {

                        if (preferredLanguages[i] === 'zh-CN') {
                            return 'zh-Hans';
                        } else if (preferredLanguages[i] === 'zh-TW') {
                            return 'zh-Hant';
                        } else if (preferredLanguages[i] === 'zh') {
                            return 'zh-Hans';
                        } else {
                            return this.availableInterfaceLanguages[j];
                        }
                    }
                }
            }
        }

        return  'en';
    },

    getFileContent: function (name) {
        if (_.has(WebappStrings.Files, name)) {
            return WebappStrings.Files[name];
        } else {
            return `Content for file ${name} is missing!`;
        }

    },

    getHumanizedDuration: function(durationInSeconds) {
        const langMap = { 'zh-Hans': 'zh_CN', 'zh-Hant': 'zh_TW' };
        let lang = i18nUtils.currentInterfaceLanguage;
        if (lang in langMap) {
            lang = langMap[lang];
        }

        return humanizeDuration(durationInSeconds * 1000, { largest: 2, language: lang, fallbacks: ["en"] });
    },

    removeExtraBreaks(string) {
        return string.replace(/(?:<br ?\/?>){2,}/g, '<br />');
    },

    removeHTMLTags(string) {
        return string.replace(/<[^>]*>/g, '');
    },

    getTimeAgo(ts) {
        return $.timeago(ts.toDate());
    },

    getLocaleFromLanguageCode(code) {
        console.log('lang: getLocaleFromLanguageCode', this.availableInterfaceLanguages);
        return this.availableInterfaceLanguages;
    }
};

ExportUtils.export('app.util.i18n', i18nUtils);
ExportUtils.export('app.lib.WebappStrings', WebappStrings);

export default i18nUtils;
