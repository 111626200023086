var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          version: "1",
          xmlns: "http://www.w3.org/2000/svg",
          width: "30",
          height: "24",
          viewBox: "0 0 30 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#43D281",
          d: "M26.033 0l-15.8 15.967-6.25-6.315L0 13.658 10.212 24 30 4.003",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }