<template>
    <div class="home-drawer">
        <burger :open="this.open" v-if="userCourse" />
        <aside v-if="userCourse && isRouteAllowed" :class="{ open }" @mouseenter="openDrawer" @mouseleave="closeDrawer">
            <navigation :route="route"
                        :open="open"
                        :pinned="pinned"
                        :user-course="userCourse"
                        :game-over="gameOver"
                        :subscription-info="subscriptionInfo"
                        :interface-language="interfaceLanguage"
                        :is-mobile="isMobile"
                        v-on:togglePinned="togglePinned"
            />
            <learning-progress :user-course="userCourse" :open="open" v-if="learningProgressVisible" />
            <controls :open="open" :game-over="gameOver" :user-course="userCourse"
                      :interface-language="interfaceLanguage"/>
        </aside>
    </div>
</template>

<script>
import {EventBus} from 'Util/vue-event-bus';
import Backbone from 'backbone';
import UserManager from 'Modules/usermanager.js';
import Navigation from './components/navigation.vue'
import Controls from './components/controls.vue'
import URI from 'Util/uri.js';
import i18nUtils from 'Util/i18n.js';
import HomeDrawerStats from "./components/learning-progress.vue";
import LearningProgress from "./components/learning-progress.vue";
import Burger from "./components/burger.vue";

export default {
    name: 'home-drawer',
    components: {
        Burger,
        LearningProgress,
        Navigation,
        Controls,
        HomeDrawerStats
    },
    data() {
        return {
            user: null,
            route: Backbone.history.getFragment(),
            interfaceLanguage: i18nUtils.currentInterfaceLanguage,
            open: false,
            pinned: false,
            closeTimeout: null,
            learningProgressVisible: true,
            screenWidth: window.innerWidth
        }
    },
    computed: {
        userHasCourse() {
            return this.user && this.user.hasCourse() && this.user.getCourse().isInitialized();
        },
        userCourse() {
            return (this.user && this.userHasCourse) ? this.user.getCourse() : null;
        },
        isRouteAllowed() {
            return this.route && !this.route.startsWith("signin") && !this.route.startsWith("register");
        },
        subscription() {
            return (this.user) ? this.user.getSubscription() : null;
        },
        subscriptionInfo() {
            return (this.subscription) ? this.subscription.getStatus() : null;
        },
        gameOver() {
            if (UserManager.instance.hasUser()) {
                return UserManager.instance.getUser().getCourse().getLimits().isGameOver();
            } else {
                return true;
            }
        },
        isMobile() {
            const _isMobile = this.screenWidth <= 414;
            if (_isMobile) {
                this.pinned = false;
                this.open = false;
            }
            return _isMobile;
        }
    },
    methods: {
        clearCloseTimeout() {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        },
        openDrawer() {
            if (this.closeTimeout) {
                this.clearCloseTimeout();
            }
            document.body.scrollTop = 0;
            if (!this.open) {
                this.open = true;
                EventBus.$emit('home-drawer-opened');
            }
        },
        closeDrawer() {
            if (this.user && this.open && !this.pinned) {
                this.clearCloseTimeout();
                this.closeTimeout = setTimeout(() => {
                    this.open = false;
                    EventBus.$emit('home-drawer-closed');
                    this.clearCloseTimeout();
                }, 250);

            }
        },
        togglePinned(state) {
            this.pinned = state;
            if (UserManager.instance.hasUser()) {
                UserManager.instance.getUser().getStorage().setItem('home_drawer_pinned', state);
            }
        },
        updateRoute(r) {
            this.route = r;
        },
        navigate(route) {
            if (route) {
                this.updateRoute(route);
                Backbone.history.navigate(route, {trigger: true});
                if (this.isMobile) {
                    this.closeDrawer();
                }
            }
        },
        openUri(uri) {
            if (uri) {
                new URI(uri).navigateTo();
                this.closeDrawer();
            }
        },
        setUser(user) {
            this.user = user;
        },
        unsetUser() {
            this.user = null;
        },
        updateInterfaceLanguage(lang) {
            this.interfaceLanguage = lang;
        },
        onResize() {
            this.screenWidth = window.innerWidth;
            this.setProgressVisibility();
        },
        async setInitialPinned() {
            if (!this.isMobile) {
                if (UserManager.instance.hasUser()) {
                    this.pinned = await UserManager.instance.getUser().getStorage().getItem('home_drawer_pinned') || false;
                }
                // if pinned, open the drawer
                if (this.pinned) {
                    this.open = true;
                }
            }
        },
        listenEventBus() {
            EventBus.$on('home-drawer:open', this.openDrawer);
            EventBus.$on('home-drawer:close', this.closeDrawer);
            EventBus.$on('home-drawer:navigate', this.navigate);
            EventBus.$on('home-drawer:uri', this.openUri);
            EventBus.$on('interfaceLanguageChanged', this.updateInterfaceLanguage);
        },
        listenBackboneEvents() {
            Backbone.on('preRoute', this.updateRoute, this);
            Backbone.on('progressOpened', this.closeDrawer, this);
            Backbone.on('closeDrawers', this.closeDrawer, this);
            Backbone.on('openHomeDrawer', this.openDrawer, this);
            Backbone.on('userSignedIn', this.setUser, this);
            Backbone.on('userSignedOut', this.unsetUser, this);
        },
        setProgressVisibility() {
            this.learningProgressVisible = window.innerHeight >= 660;
        }
    },
    created() {
        this.listenEventBus();
        this.listenBackboneEvents();
        this.setInitialPinned();
        if (UserManager.instance.hasUser()) {
            this.user = UserManager.instance.getUser();
        }
        window.addEventListener('resize', this.onResize);
        this.setProgressVisibility();
    },
    beforeDestroy() {
        EventBus.$off('home-drawer:navigate', this.navigate);
        EventBus.$off('home-drawer:uri', this.openUri);
        EventBus.$off('interfaceLanguageChanged', this.updateInterfaceLanguage);
        Backbone.off('preRoute', this.updateRoute, this);
        Backbone.off('progressOpened', this.closeDrawer, this);
        Backbone.off('closeDrawers', this.closeDrawer, this);
        Backbone.off('openHomeDrawer', this.openDrawer, this);
        Backbone.off('userSignedIn', this.setUser, this);
        Backbone.off('userSignedOut', this.unsetUser, this);
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.home-drawer {
        display: flex;
        flex-direction: column;
        height: 100vh;
        aside {
            display: flex;
            flex-direction: column;
            flex: 1;
            width: pxToRem(72);
            user-select: none;
            transition: width 250ms, margin-left 250ms;
            will-change: width;
            background: white;
            @include minimalistScrollbar(8px);

            @include respond-to('small') {
                &:not(.open) {
                    display: none;
                }
            }

            &.open {
                width: pxToRem(240);
                overflow-x: hidden;
                overflow-y: auto;
                @include respond-to('small') {
                    @include elevated;
                    position: fixed;
                    height: -webkit-fill-available;
                }
            }

            > div.learning-progress {
                border-top: 1px solid $colorBackgroundLighter;
                margin-top: 1rem;
                padding: .5rem 0;
            }

            > div.controls {
                margin-top: auto;
                border-top: 1px solid $colorBackgroundLighter;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                padding: 1rem;
            }
        }
    }

</style>
