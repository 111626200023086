
'use strict';

import EventEmitter from 'events';
import Raven from 'raven-js';

import ControllerFactory from '../modules/controller.factory.js';
import GuessView from '../view/guess/main.vue';
import UserManager from '../modules/usermanager.js';
import GuessAdapter from '../modules/guess.adapter.js';

import {STATUS as GUESS_QUEUE_STATUS, UnknownGuessQueueStatusError} from '../modules/guess.queue.js';
import {VARIATION_STATUS, VARIATION_TYPE} from '../modules/course/user.course.js';
import Vue from 'vue';
import ControllerManager from '../modules/controller.manager.js';
import { EventBus } from '../util/vue-event-bus.js';
import { NAME } from "../modules/user.parameters.js";
import { SUBSCRIPTION_STATUS } from "Modules/subscription.js";

const GuessViewComponent = Vue.extend(GuessView);

export const ENTRY_EVENT_TYPE = {
    REVEAL: 'reveal',
    SUBMIT: 'submit',
    SPEECH: 'speech', // Guess word speech input not implemented
    ERASE: 'erase',
    TIMEOUT: 'timeout',
    MISTAKE_TABLE_OPENED: 'mistake_table_opened',  // TODO!
    MISTAKE_UNIT_EXPANDED: 'mistake_unit_expanded',
    MISTAKE_UNIT_CONTRACTED: 'mistake_unit_contracted'
};

export const ENTRY_EVENT_SUBMIT_TREATMENT = {
    MISTAKE: 'mistake',
    TYPO: 'typo',
    INCORRECT: 'incorrect',
    CORRECT: 'correct',
    EQUIVALENT: 'equivalent',
    SYNONYM: 'similar_answer',
};

export const STATE = {
    NORMAL: 'normal',
    OUT_OF_QUESTIONS: 'out-of-questions',
    HOPELESS: 'hopeless'
};

export class GuessController extends EventEmitter.EventEmitter {
    constructor () {
        super();

        this._onboarding_controller = null;
        this._guess_view = null;
        this._guess_adapter = new GuessAdapter();
        this._guess_view_events_attached = false;

        this._appView = null;
        this.auto_advance = null;
        this.final_answer_submitted = false;
    }

    initialize (onboarding_controller) {
        this._onboarding_controller = onboarding_controller;
        this._onboarding_controller.on('step-done', step => {
            this.emit(`onboarding-step-done:${step}`);
        });

        EventBus.$on('audio-speed-changed', async () => {
            if (this._guess_view) {
                await this.reSyncGuess();
            }
        });

        EventBus.$on('guess:rendered', () => {
            this.setAutoAdvanceLocal();
        });

        EventBus.$on(`settings:${ NAME.SETTING_AUTO_ADVANCE }`, async (value) => {
            await this.autoAdvanceChanged(value);
        });

        EventBus.$on('guess:next', async () => {
            await this._showNextGuess();
        });
    }

    setAutoAdvanceLocal(value = null) {
        if (value === null) {
            const userParameters = UserManager.instance.getUser().getParameters();
            const auto_advance_from_params = userParameters.getParameter(NAME.SETTING_AUTO_ADVANCE);
            this.auto_advance = (auto_advance_from_params === null) ? true : auto_advance_from_params;
        } else {
            this.auto_advance = value;
        }
    }

    async autoAdvanceChanged(value) {
        this.setAutoAdvanceLocal(value);
        if (value === true && this.final_answer_submitted) {
            await this._showNextGuess();
        }
    }

    attachGuessViewEvents () {
        if (!this._guess_view_events_attached) {
            EventBus.$on('guess:final-answer-submit', async ({ answer, question, dont_auto_advance = false }) => {
                try {
                    await this.submitGuessCardAnswer(answer, question);
                    this.final_answer_submitted = true;
                    if (this.auto_advance && !dont_auto_advance) {
                        await this._showNextGuess();
                    }
                } catch (error) {
                    return this._captureGuessSyncError(error);
                }
            });

            EventBus.$on('guess:mute-word', async ({ question, on_previous }) => {
                try {
                    await this.submitMuteWordEvent(question.question, on_previous);
                    if (!on_previous) {
                        await this._showNextGuess();
                    }
                } catch (error) {
                    return this._captureGuessSyncError(error);
                }
            });

            this._guess_view_events_attached = true;
        }
    }

    async submitMuteWordEvent (question, on_previous) {
        return Promise.resolve().then(async function () {
            let muteWordEventResult = await UserManager.instance.getUser().getEventSender().sendMuteWordEventForQuestion('guess', question);
            if (muteWordEventResult && muteWordEventResult.hasOwnProperty('data') && muteWordEventResult.data.hasOwnProperty('lexical_unit_uuid')) {
                if (!on_previous) {
                    const queueQuestion = await UserManager.instance.getUser().getCourse().getGuessQueue().removeCurrentQuestion();
                    if (queueQuestion.UUID !== question.UUID) {
                        throw Error(`Question mismatch. Question from GuessQueue doesn't match question from guess.`);
                    }
                }

                let statistics = UserManager.instance.getUser().getCourse().getStatistics(),
                    learnedWords = statistics.getLearnedWords();

                await learnedWords.updateLexicalUnitMuteState(muteWordEventResult.data.lexical_unit_uuid, true);
            }

        }).catch(function (error) {
            Raven.captureException(error, { level: 'error' });
        });
    }

    async _showGuess (data) {
        let { question } = data;

        if (this._guess_view) {
            this._guess_view.setQuestion(question);
        } else {
            let propsData = {
                raw_question: question
            };
            this._guess_view = new GuessViewComponent({ propsData });
            this._appView.setMainView(this._guess_view, true);

            this._guess_view.$on('before-destroy', () => {
                this._guess_view = null;
            });

            this.attachGuessViewEvents(); // only once
        }
    }

    async reactToOutOfQuestions () {

        await UserManager.instance.getUser().getCourse().syncCourse();

        const currentVariations = UserManager.instance.getUser().getCourse().getCurrentVariations();
        const onboardingController = ControllerManager.instance.getController('Onboarding');
        const _currentVariationsCompleteVariations = currentVariations.filter(v => v.status === VARIATION_STATUS.COMPLETE);

        if (_currentVariationsCompleteVariations.length > 0) {
            // TODO: not so smart.. there might be many completed variations in the pool
            const _completedVariation = _currentVariationsCompleteVariations[0];
            let categories = UserManager.instance.getUser().getCourse().getVariationsInfo(),
                available_variations = categories
                    .filter(category => category.variations.find(variation =>
                        variation.type !== VARIATION_TYPE.GENERAL &&
                        variation.status !== VARIATION_STATUS.COMPLETE) !== undefined);

            if (available_variations.length > 0) {
                await onboardingController.showVariationEndUnified(_completedVariation);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('variation-end', 'show', _completedVariation.uuid);
            } else {
                await onboardingController.showCourseEnd(true);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('out-of-words', 'show');
            }
        } else {
            const userSub = UserManager.instance.getUser().getSubscription();
            if (userSub.status === SUBSCRIPTION_STATUS.PRE_TRIAL) {
                await onboardingController.showPaywall();
            } else {
                await onboardingController.showCourseEnd(false);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('out-of-words', 'show');
            }
        }
    }

    async _loadGuessCardIfPossibleOrReactToFailure () {
        let { status, question } = await this._guess_adapter.getQuestion();

        if (status === GUESS_QUEUE_STATUS.OK) {
            await this._showGuess({ question } );
        } else if (status === GUESS_QUEUE_STATUS.OUT_OF_QUESTIONS) {
            return await this.reactToOutOfQuestions();
        } else {
            throw new UnknownGuessQueueStatusError(status);
        }
    }

    async submitGuessCardAnswer (answer, question) {
        const queueQuestion = await UserManager.instance.getUser().getCourse().getGuessQueue().removeCurrentQuestion();
        if (queueQuestion.UUID !== question.UUID) {
            // throw Error(`Question mismatch. Question from GuessQueue doesn't match question from guess.`);
            console.error(`Question mismatch. Question from GuessQueue doesn't match question from guess.`);
        }

        const guessEvent = await UserManager.instance.getUser().getEventSender().sendGuessEvent(question, answer);
        await ControllerManager.instance.getController('Onboarding').notifyGuessSubmitted(guessEvent);
    }


    async reSyncGuess () {
        await this._loadGuessCardIfPossibleOrReactToFailure();
    }

    async _showNextGuess () {
        // Main flow control!
        let course = UserManager.instance.getUser().getCourse();
        this.final_answer_submitted = false;

        let set_complete_award = null;
        if (course.getAwards().shouldGrantSetAward()) {
            set_complete_award = await course.getAwards().grantSetComplete();
        }

        if (set_complete_award !== null) {
            EventBus.$emit('guess:show-day-insights');
        }
        await this._loadGuessCardIfPossibleOrReactToFailure();
    }

    async go (appView, router) {
        this._appView = appView;
        try {
            return await this._showNextGuess();
        } catch (error) {
            return this._captureGuessSyncError(error);
        }
    }

    _captureGuessSyncError(error) {
        Raven.captureException(error, {level: 'error', extra: {message: 'Guess sync failed'}});
        return Promise.resolve({
            state: STATE.HOPELESS
        });
    }
}

export const guess_controller_factory = new ControllerFactory(GuessController);
