<template>
    <div class="insights-calendars">
        <div class="calendars-grid" ref="calendars-wrapper">
            <template v-for="calendar in calendars">
                <calendar :date-context="calendar.dateContext" :course="course" v-on="$listeners" />
            </template>
            <mugen-scroll :handler="generateCalendars" :should-handle="!loading" :handle-on-mount="false" scroll-container="calendars-wrapper">
                ...
            </mugen-scroll>
        </div>
    </div>
</template>

<script>
    import Calendar from "./calendar.vue";
    import MugenScroll from 'vue-mugen-scroll';
    import moment from "moment";

    export default {
        name: 'insights-calendars',
        components: {
            Calendar,
            MugenScroll
        },
        props: {
            userInfo: {
                type: Object,
                required: true
            },
            course: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                initialMonth: moment().local(), // do not mutate this
                currentMonth: null,
                calendars: [],
                loading: false,
            }
        },
        methods: {
            generateCalendars() {
                let dateContext = this.initialMonth.clone().subtract(this.calendars.length, 'month');
                let monthsDiff = dateContext.diff(this.userInfo.registered, 'months');

                // only generate calendars until user registered date
                if (monthsDiff >= 0) {
                    this.calendars.push({ dateContext });
                }
            }
        },
        created() {
            this.calendars.push({ dateContext: this.initialMonth });
            this.calendars.push({ dateContext: this.initialMonth.clone().subtract(1, 'month') });
            this.calendars.push({ dateContext: this.initialMonth.clone().subtract(2, 'month') });
            this.calendars.push({ dateContext: this.initialMonth.clone().subtract(3, 'month') });
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.insights-calendars {
        margin-left: 1rem;
        position: relative;
        > div.calendars-grid {
            @include minimalistScrollbar;
            --scrollbar-width: 12px;
            --mask-height: 32px;
            --mask-image-content: linear-gradient( to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent );
            --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
            --mask-image-scrollbar: linear-gradient(black, black);
            --mask-size-scrollbar: var(--scrollbar-width) 100%;
            -webkit-mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
            -webkit-mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
            -webkit-mask-position: 0 0, 100% 0;
            -webkit-mask-repeat: no-repeat, no-repeat;

            padding-bottom: var(--mask-height);
            padding-top: var(--mask-height);
            position: relative;
            max-height: pxToRem(470);
            overflow-y: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
            justify-content: center;
            @include respond-to('medium') {
                grid-template-columns: 1fr;
                max-height: pxToRem(420);
                max-width: pxToRem(400);
                left: 50%;
                transform: translateX(-50%);
            }
            @include respond-to('small') {
                left: unset;
                transform: unset;
                max-width: unset;
            }
        }
    }
</style>
