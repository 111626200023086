<template>
    <div class="set-progress">
        <div class="numbers-mobile">{{ cardsDoneInSet }} / {{ setSize }}</div>
        <div class="number">
            <div class="tooltip" v-html="this.$i18n('sets_introduction_tooltip')" />
            {{ cardsDoneInSet }}
        </div>
        <div class="progress-background">
            <div class="progress-bar" v-bind:style="setProgressPercentageStyle"></div>
        </div>
        <div class="number">{{ setSize }}</div>
    </div>
</template>

<script>
    import { Awards } from "Modules/awards.js";
    import { EventBus } from "Util/vue-event-bus";

    export default {
        name: 'set-progress',
        data() {
            return {
                setSize: Awards.getDynamicGoalSize(),
                cardsDoneInSet: 0,
                setProgressPercentage: 0
            }
        },
        computed: {
            setProgressPercentageStyle() {
                return  {
                    width: this.setProgressPercentage + '%'
                }
            }
        },
        methods: {
            setSizeChanged(size) {
                this.setSize = size;
            },
            getAndSetData() {
                this.cardsDoneInSet = Awards.cardsDoneInActiveSet();
                this.setProgressPercentage = (this.cardsDoneInSet / this.setSize) * 100;
            },
            listenEventBus() {
                EventBus.$on('statistics-updated', this.getAndSetData);
                EventBus.$on('dynamic-goal:size-changed', this.setSizeChanged);
            }
        },
        created() {
            this.getAndSetData();
            this.listenEventBus();
        },
        beforeDestroy() {
            EventBus.$off('statistics-updated', this.getAndSetData);
            EventBus.$off('dynamic-goal:size-changed', this.setSizeChanged);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.set-progress {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        align-items: center;

        > div.number {
            position: relative;
            font-size: fontSize(16);
            font-weight: $font-bold;
            color: $colorSourcePrimary;
            padding: 0 0.5rem;
            opacity: 1;
            transition: opacity 0.25s;
            width: 2.5rem;
            text-align: end;
            line-height: 0.8;
            font-feature-settings: "lnum" on;
            user-select: none;

            @import '~Styles/components/tooltip';

            > div.tooltip {
                display: none;
                bottom: pxToRem(30);
                left: - pxToRem(6);
                max-width: pxToRem(350);
                min-width: pxToRem(350);
                &.visible {
                    display: block;
                }
            }

            &.hidden {
                opacity: 0;
            }
        }

        > div.numbers-mobile {
            display: none;
            width: calc(100% - 1rem);
            text-align: left;
            font-size: fontSize(14);
            color: $colorSourcePrimary;
            margin-bottom: 0.2rem;
        }

        > div.progress-background {
            width: calc(100% - 1rem);
            height: 0.625rem;
            max-width: pxToRem(550);
            background: $colorBackgroundLighter;
            border-radius: 4px;

            > div.progress-bar {
                height: 0.625rem;
                background: $colorCorrect;
                border-radius: 4px;
                transition: width 0.5s;
                min-width: 6px;
            }
        }

        @media all and (max-width: em(600,16)) {

            flex-direction: column;
            margin: 0 0.5rem;

            > div.number {
                display: none;
            }

            > div.numbers-mobile {
                display: flex;
            }

            > div.progress-background {
                margin: 0.25rem 0 0.5rem;
            }
        }
    }
</style>
