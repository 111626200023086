<template>
    <div class="settings-modal" @click.self="close" v-if="user && isOpen">
        <div class="settings-modal-window">
            <CloseButton @click="close" class="close" />
            <h2 class="title" v-html="this.$i18n('learn_settings_title')" />
            <div class="content">
                <audio-settings v-if="userCourse" :user-course="userCourse" />
                <setting-toggle title="settings_auto_advance_label" footnote="settings_auto_advance_text" :setting-parameter="autoAdvanceParameter" />
                <setting-toggle title="settings_grammar_tables_label" footnote="settings_grammar_tables_description" :setting-parameter="showGrammarParameter" :not-set-default-value="false" />
                <setting-toggle title="learn_settings_diacritics_toggle_lbl" footnote="learn_settings_diacritics_toggle_txt" :setting-parameter="strictDiacriticsParameter" :not-set-default-value="false" />
            </div>
        </div>
    </div>
</template>

<script>
    import CloseButton from 'Images/close-24x24.svg';
    import { EventBus } from "Util/vue-event-bus.js";
    import { NAME } from "Modules/user.parameters.js";
    import UserManager from "Modules/usermanager.js";
    import AudioSettings from "./components/audio.vue";
    import SettingToggle from "./components/setting-toggle.vue";

    export default {
        name: 'settings',
        components: {
            SettingToggle,
            AudioSettings,
            CloseButton
        },
        data() {
            return {
                user: null,
                isOpen: false
            }
        },
        methods: {
            open() {
                this.isOpen = true;
            },
            close() {
                this.isOpen = false;
            },
            keyDown(event) {
                if (event && event.keyCode === 27) { // close on ESC key
                    this.close()
                }
            },
            setUser(user) {
                this.user = user;
            },
            listenEventBus() {
                EventBus.$on('user:signed-in', this.setUser);
                EventBus.$on('settings:open', this.open);
                EventBus.$on('settings:close', this.close);
            },
        },
        computed: {
            userHasCourse() {
                return this.user && this.user.hasCourse() && this.user.getCourse().isInitialized();
            },
            userCourse() {
                return (this.user && this.userHasCourse) ? this.user.getCourse() : null;
            },
            autoAdvanceParameter() {
                return NAME.SETTING_AUTO_ADVANCE;
            },
            showGrammarParameter() {
                return NAME.SETTING_SHOW_GRAMMAR_TABLES;
            },
            strictDiacriticsParameter() {
                return NAME.SETTING_STRICT_DIACRITICS;
            }
        },
        created() {
            this.listenEventBus();
            if (UserManager.instance.hasUser()) {
                this.user = UserManager.instance.getUser();
            }
        },
        beforeDestroy() {
            EventBus.$off('settings:open', this.open);
            EventBus.$off('settings:close', this.close);
            EventBus.$off('user:signed-in', this.setUser);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.settings-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43, 81, 142, 0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        > div.settings-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 40vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
            > h2.title {
                text-align: center;
            }

            > div.content {
                margin-top: pxToRem(24);
                > div.audio-settings {
                    margin-bottom: 1rem;
                }
            }
        }
    }
</style>
