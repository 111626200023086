<template>
    <header class="course-wizard-header" v-if="this.course_info">
        <div class="side">
            <breadcrumb v-bind:page="page" v-bind:complete-cards-count="completeCardsCount" />
            <div class="info">
                <course-icon :value="lessonTargetLanguageIconId" size="medium" />
                <input type="text" :class="{ 'lesson-name': true, 'error': lessonNameError }" maxlength="40"
                       :placeholder="this.$i18n('lessons_creator_editor_name_placeholder')" v-model.lazy="lesson_name_local" />
                <div class="cards-counter" v-html="this.$i18n('lessons_creator_editor_header_cards_added', { args: { words_count: lesson.card_count } })" />
            </div>
        </div>
        <div class="actions">
            <saving-indicator v-if="page !== 'intro'"/>
            <template v-if="page === 'cards'">
                <v-button-dropdown v-if="!this.userHasClassroomsFeature" v-on:action="buttonAction"
                            :disabled="this.interactionDisabled"
                            :primary="{ action: 'next', disabled: (completeCardsCount === 0), i18n: { id: 'lessons_creator_editor_nav_next' } }"
                            :dropdown="[{ action: 'discard', i18n: { id: 'course_wizard_save_for_later_discard' }, icon: 'discard' }]" />
                <v-button-dropdown v-else v-on:action="buttonAction"
                                   :disabled="this.interactionDisabled"
                                   :primary="{ action: 'next', disabled: (completeCardsCount === 0), i18n: { id: 'lessons_creator_editor_nav_next' } }"
                                   :dropdown="[
                                       { action: 'discard', i18n: { id: 'course_wizard_save_for_later_discard' }, icon: 'discard' },
                                       { action: 'back-to-lessons', i18n: { id: 'lessons_creator_editor_nav_options_save_open_lessons' }, icon: 'lessons' }
                                       ]" />
            </template>
            <template v-else-if="page === 'meta'">
                <v-button @click.native="navigate('cards')" :disabled="this.interactionDisabled"
                          :i18n="{ id: 'lessons_creator_editor_nav_previous' }" />
                <v-button @click.native="publish" :filled="true" color="primary"
                          :disabled="this.interactionDisabled || completeCardsCount === 0"
                          :i18n="{ id: 'lessons_creator_editor_button_publish' }" />
            </template>
        </div>
    </header>
</template>

<script>
    import CourseIcon from 'ViewComponent/course-icon.vue';
    import VButton from 'ViewComponent/v-button.vue';
    import VButtonDropdown from 'ViewComponent/v-button-dropdown.vue';
    import SavingIndicator from "./saving-indicator.vue";
    import { EventBus } from 'Util/vue-event-bus';
    import { CONFIRM_ACTION, ConfirmationView } from "../../util/confirmation.view.js";
    import i18nUtils from "Util/i18n";
    import Breadcrumb from "./breadcrumb.vue";

    export default {
        name: 'course-wizard-header',
        components: {
            Breadcrumb,
            SavingIndicator,
            CourseIcon,
            VButton,
            VButtonDropdown
        },
        props: {
            page: {
                type: String
            },
            course_info: {
                type: Object,
                required: true
            },
            lesson: {
                type: Object,
                required: true
            },
            cards: {
                type: Array,
                default: []
            },
            interactionDisabled: {
                type: Boolean
            },
            userHasClassroomsFeature: {
                type: Boolean
            }
        },
        data() {
            return {
                lesson_name_local: null,
                lessonNameError: false
            }
        },
        computed: {
            lessonTargetLanguageIconId() {
                return this.course_info.target_icon_id;
            },
            completeCardsCount() {
                return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.completeness === 'complete').length : 0;
            },
        },
        methods: {
            navigate(page) {
                EventBus.$emit('cw:navigate', page);
            },
            publish() {
                if (this.completeCardsCount > 0) {
                    EventBus.$emit('cw:log-event', { action: 'publish' });
                    this.$emit('publish');
                }
            },
            discard() {
                const confirmationView = this._showLessonDiscardConfirmation();

                confirmationView.on('confirm', action => {
                    if (action === CONFIRM_ACTION.OK) {
                        this.$emit('discard');
                    }

                    Promise.resolve()
                        .then(() => confirmationView.hide())
                        .then(() => {
                            confirmationView.remove();
                        });
                });
            },
            buttonAction(action) {
                switch (action) {
                    case 'next':
                        this.navigate('meta');
                        break;
                    case 'discard':
                        this.discard();
                        break;
                    case 'back-to-lessons':
                        this.$emit('navigate-to-lessons');
                        break;
                }
            },
            validateLessonName(name) {
                return !(name.length <= 0 || name.length >= 40);
            },
            _showLessonDiscardConfirmation () {

                const confirmationView = new ConfirmationView({
                    title: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_title'),
                    message: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_message'),
                    actions: [
                        {
                            title: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_cancel'),
                            action: CONFIRM_ACTION.CANCEL
                        },
                        {
                            title: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_confirm'),
                            action: CONFIRM_ACTION.OK,
                            primary: true
                        }
                    ]
                });

                confirmationView.show();

                return confirmationView;
            }
        },
        mounted() {
            this.lesson_name_local = (this.lesson) ? this.lesson.name : '';
        },
        watch: {
            lesson_name_local(newValue, oldValue) {
                if (newValue !== oldValue && oldValue !== null && newValue !== this.lesson.name) {
                    if (this.validateLessonName(newValue)) {
                        this.$emit('name-change', newValue)
                        this.lessonNameError = false;
                    } else {
                        this.lessonNameError = true;
                    }
                }
            },
            lesson(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue && newValue.name) {
                        this.lesson_name_local = newValue.name;
                    }
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    header.course-wizard-header {
        background-color: $colorBackgroundDefault;
        border-radius: .5rem;
        min-height: pxToRem(64);
        display: flex;
        flex-direction: row;
        padding: pxToRem(16);

        > div.side {
            > div.breadcrumb {
                margin-bottom: pxToRem(8);
            }
            > div.info {
                display: flex;
                flex-direction: row;
                > div {
                    &.course-icon {
                        margin-right: 1rem;
                        margin-top: .4rem;
                    }
                }
                input.lesson-name {
                    padding: .25rem;
                    outline: 0 solid transparent;
                    border-bottom: 2px solid $colorBackgroundDarker;
                    transition: border-bottom 250ms;
                    &:focus {
                        border-bottom-color: $colorSourcePrimary;
                    }
                    &.error {
                        border-bottom-color: $colorIncorrect;
                    }
                }
                > div.cards-counter {
                    margin-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        > div.actions {
            margin-left: auto;
            display: flex;
            align-items: flex-end;
        }

        > div.actions {
            button,
            span.saving-indicator {
                margin-left: 1rem;
            }
            span.saving-indicator {
                min-height: pxToRem(40);
            }
        }
    }
</style>
