import $ from  'jquery';
import Backbone from 'backbone';

import OverlayView from '../overlay/overlay.view.js';
import EventUtils from '../../util/event.js';
import ControllerManager from '../../modules/controller.manager.js';
import renderTemplate from '../../util/template.renderer.js';
import { EventBus } from "Util/vue-event-bus";
import UserModel from "Model/user.model.js";

const DebugView = Backbone.View.extend({

    name: 'debug',

    events: {
        'click button.cancel, button.close': 'close',
        'click div.close': 'close',
        'click section.debug-lexical-units > button.load-lexical-units': 'loadLexicalUnits',
        'click section.auto-play > div.container > button.auto-play': 'startAutoPlay',
        'click section.reset-guess-queue > button.reset-guess-queue': 'resetGuessQueue',
        'click section.add-course > div.container > button.add-course': 'addCourse',
        'click section.different-actions > div.actions > button.show-opening-widget': 'showOpeningWidget',
        'click section.different-actions > div.actions > button.sign-out': 'signOut',
        'click section.doorslams > button.test-dayInsightsDebug': 'showDayInsights',
        'click div[data-content]': 'showContent',
        'change section.guess-notifications input[data-role]': 'configureGuessNotifications',
        'click section.guess-onboarding > fieldset > div.wrapper > button.show-tooltip': 'guessOnboardingTooltip',
        'click section.guess-onboarding > fieldset > div.wrapper > button.show-survey': 'showSurvey',
    },

    initialize: function () {
        console.log("Debug view init");
    },

    remove: function () {
        if (this._overlayView !== undefined) {
            this._overlayView.off();
            this._overlayView.remove();
        }
        this.trigger('remove');
        this.unbind();
        Backbone.View.prototype.remove.call(this);
    },

    render: function (data) {
        renderTemplate('debug/main', data).then(output => {
            const $element = $(output);
            this.setElement($element);
            $('body').append($element);
            this._postRender();
        });
        return this;
    },

    _postRender: function () {},

    renderAutoPlayStop: function (stopCallback) {
        renderTemplate('debug/auto-play-stop').then(output => {
            const $element = $(output);
            $('body').append($element);

            const $button = $element.find('button.stop-auto-play');
            $button.click(function () {
                stopCallback();
                $button.off();
                $element.remove();
            });
        });
    },

    show: function () {
        var self = this;

        setTimeout(function () {
            Backbone.trigger('modalOpened');
            self.$el.find('div.modal.debug').addClass('show');
            self._overlayView = new OverlayView();
            self._overlayView.on("dismissed", self.close, self);
            self._overlayView.render();
        }, 10);
    },

    close: function () {
        var self = this,
            $modal = this.$el.find('div.modal.show');

        this._overlayView.off();
        EventUtils.oneTransitionEnd($modal, function () {
            self.remove();
        }, 500);

        this._overlayView.remove();
        delete this._overlayView;

        $modal.removeClass('show', this.name);

        Backbone.trigger('modalClosed');
    },
    showDayInsights: function () {
        this.close();
        EventBus.$emit('guess:show-day-insights', true);
    },
    loadLexicalUnits: function () {
        var self = this;

        var lexicalUnitUUIDs = this.$el.find('textarea.lexical-units').val()
            .trim()
            .split('\n')
            .map(luuid => luuid.trim())
            .filter(luuid => luuid !== '');

        Promise.resolve().then(function () {
            self.$el.find('section.debug-lexical-units > button.load-lexical-units')
                .text('Loading...')
                .addClass('loading')
                .prop('disabled', 'disabled');

            return ControllerManager.instance.getController('Debug').loadDebugLexicalUnits(lexicalUnitUUIDs);
        }).then(function () {
            self.$el.find('section.debug-lexical-units > button.load-lexical-units')
                .text('Loaded!')
                .addClass('loaded')
                .removeClass('loading');

            setTimeout(function () {
                self.$el.find('section.debug-lexical-units > button.load-lexical-units')
                    .text('Load lexical units')
                    .removeClass('loaded')
                    .prop('disabled', false);
            }, 1000);
        }).catch(function (error) {
            console.error('Error loading debug LUs:', error);
        });
    },

    startAutoPlay: function () {
        console.log("StartAutoPlay");
        const probability = parseFloat(this.$('input[name="probability"]').val()) || 0;

        Promise.resolve().then( () => {

            this.close();

            return ControllerManager
                .instance
                .getController('Debug')
                .startAutoPlay(
                    probability,
                    (stopCallback) => this.renderAutoPlayStop(stopCallback)
                );
        });
    },

    signOut: function () {
        return Promise.resolve().then( () => {
            return new Promise( resolve => {
                Backbone.trigger('signoutStart', 'debug');
                setTimeout( () => { resolve(); }, 1000);
            });
        }).then( () => {
            return UserModel.signout(false);
        }).then( () => {
            Backbone.trigger('signoutStop', 'debug');
        });
    },

    resetGuessQueue: function () {
        var self = this;

        Promise.resolve().then(function () {
            self.$el.find('section.reset-guess-queue > button.reset-guess-queue')
                .text('Resetting...')
                .addClass('resetting')
                .prop('disabled', 'disabled');

            return ControllerManager.instance.getController('Debug').resetGuessQueue();
        }).then(function () {
            self.$el.find('section.reset-guess-queue > button.reset-guess-queue')
                .text('Reset Done!')
                .addClass('reset-done')
                .removeClass('resetting');

            setTimeout(function () {
                self.$el.find('section.reset-guess-queue> button.reset-guess-queue')
                    .text('Reset State')
                    .removeClass('reset-done')
                    .prop('disabled', false);
            }, 1000);
        }).catch(function (error) {
            console.error('Error resetting guess queue:', error);
        });
    },

    addCourse: function () {
        var self = this;
        var course_uuid = this.$el.find('section.add-course input.course-uuid').val();

        console.log(`course_uuid`, course_uuid);

        Promise.resolve().then(function () {
            self.$el.find('section.add-course > div.container > button.add-course')
                .text('Adding course')
                .addClass('adding')
                .prop('disabled', 'disabled');
            return ControllerManager.instance.getController('Debug').addCourse(course_uuid);
        }).then(function () {
            self.$el.find('section.add-course > div.container > button.add-course')
                .text('Course added')
                .addClass('adding-done')
                .removeClass('adding');

            setTimeout(function () {
                self.$el.find('section.add-course > div.container > button.add-course')
                    .text('Add Course')
                    .removeClass('adding-done')
                    .prop('disabled', false);
            }, 1000);
        }).catch(function (error) {
            console.error('Error adding course:', error);
        });
    },

    showOpeningWidget: function () {
        ControllerManager.instance.getController('Debug').setDebugOpeningViewOpen(true);
        ControllerManager.instance.getController('Debug').showDebugViewOpeningView();
    },

    showContent: function (event) {
        const content = $(event.currentTarget).data('content');
        this.$('div.content').removeClass('active');
        this.$(`div.content.${content}`).addClass('active');
    },

    configureGuessNotifications: function (event) {
        let $target = $(event.currentTarget),
            role = $target.data('role'),
            enabled = $target.is(':checked');

        console.debug(`DebugView.configureGuessNotifications: Toggling role=${role} enabled=${enabled}`);

        switch (role) {
            case 'show-ticker': {
                ControllerManager.instance.getController('Debug').toggleTicker(enabled);
                break;
            }
            case 'show-notifications': {
                ControllerManager.instance.getController('Debug').toggleNotifications(enabled);
                break;
            }
            case 'auto-switch-lu': {
                ControllerManager.instance.getController('Debug').toggleAutoSwitch(enabled);
                break;
            }
        }
    },

    guessOnboardingTooltip: function (event) {

        if (Backbone.history.fragment !== 'guess') {
            Backbone.history.navigate('guess', { trigger: true });
            setTimeout(() => {
                this.guessOnboardingTooltip(event);
            }, 500);
        } else {
            let $target = $(event.currentTarget),
                tooltip = $target.data('tooltip');

            EventBus.$emit('guess:onboarding-step', tooltip);
            this.close();
        }
    },

    showSurvey: function (event) {
        if (Backbone.history.fragment !== 'guess') {
            Backbone.history.navigate('guess', { trigger: true });
            setTimeout(() => {
                this.showSurvey(event);
            }, 500);
        } else {
            let $target = $(event.currentTarget),
                surveyType = $target.data('survey');

            EventBus.$emit('survey:open', surveyType);
            this.close();
        }
    }
});

export default DebugView;
