var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "diacritics" },
    [
      _vm._l(_vm.diacritics, function (item) {
        return _c(
          "div",
          {
            staticClass: "diacritic",
            on: {
              click: function ($event) {
                return _vm.typeDiacritic(item.diacritic)
              },
            },
          },
          [
            _c("a", { staticClass: "letter" }, [
              _vm._v(_vm._s(item.diacritic)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "shortcut" }, [
              _vm._v(_vm._s(item.shortcut)),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "arrow" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }