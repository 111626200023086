/* global window */

import $ from 'jquery';
import _ from 'lodash';
import Backbone from 'backbone';

import { parse as parse_qs } from './query-string.js';
import i18nUtils from './i18n.js';
import Classrooms from '../modules/classrooms/classrooms.js';
import ControllerManager from '../modules/controller.manager.js';
import UserModel from '../model/user.model.js';
import UserManager from '../modules/usermanager.js';
import ExportUtils from '../util/export.js';
import {
    PostSigninNavigateTo, PostSigninUpdateCourseVariationAction, PostSigninNavigateToPayAction
} from '../controller/post.signin.actions.js';
import googleAnalyticsCommand from './google-analytics.js';
import { VARIATION_STATUS } from '../modules/course/user.course.js';
import getConfigValue from '../util/configuration.js';
import {CONFIRM_ACTION, ConfirmationView} from '../view/util/confirmation.view.js';
import { USER_FEATURE } from '../modules/user.js';
import Courses from '../modules/courses.js';

const SKIP_AUTH_FOR_URIS = ['lingvist:bolt', 'lingvist:join-classroom', 'lingvist:activate-variation'];

class UnknownURIException extends Error {
    constructor (uri) {
        super(`Unable to process uri="${uri}", unknown base`);
        this.name = 'UnknownURIException';
    }
}

export default class URI {

    constructor (uri, options={}) {
        let uriParts = uri.split('?');

        this.uri = uri;
        this.base = uriParts[0];
        this.parameters = uriParts[1] !== undefined ? parse_qs(uriParts[1], true) : {};
        this.options = options;
    }

    static canNavigateUnauthenticated (uri) {
        return SKIP_AUTH_FOR_URIS.some(u => uri.startsWith(u));
    }

    static removeSearchString () {
        window.history.replaceState({}, document.title, '/' + location.hash); // Remove search string
    }

    navigateTo (options = {}) {

        let self = this;

        switch (this.base) {

            case 'lingvist:store': {
                if (this.parameters.auth === 'required' && !UserModel.isSignedIn()) {
                    // User is not signed in but authentication is required. Trigger auth flow.

                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateTo('subscriptions')
                    );

                    return UserModel.assureSignedIn('signin');
                } else {
                    Backbone.history.navigate('subscriptions', { trigger: true });
                }
            }

            break;

            case 'lingvist:store-checkout': {
                let _path = 'subscriptions';

                if (this.parameters.product) {
                    _path = 'subscriptions/checkout/' + this.parameters.product.trim();
                }

                if (this.parameters.auth === 'required' && !UserModel.isSignedIn()) {
                    // User is not signed in but authentication is required. Trigger auth flow.

                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateTo(_path)
                    );

                    return UserModel.assureSignedIn('signin');
                } else {
                    Backbone.history.navigate(_path, { trigger: true });
                }
            }

            break;

            case 'lingvist:store-manage': {
                let _path = 'subscriptions/manage';

                if (this.parameters.auth === 'required' && !UserModel.isSignedIn()) {
                    // User is not signed in but authentication is required. Trigger auth flow.

                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateTo(_path)
                    );

                    return UserModel.assureSignedIn('signin');
                } else {
                    Backbone.history.navigate(_path, { trigger: true });
                }
            }

            break;

            case 'lingvist:voucher': {
                let _path = 'subscriptions/voucher';

                if (this.parameters && this.parameters.code) {
                    _path = `${_path}?code=${this.parameters.code}`;
                }

                if (this.parameters.auth === 'required' && !UserModel.isSignedIn()) {
                    // User is not signed in but authentication is required. Trigger auth flow.

                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateTo(_path)
                    );

                    return UserModel.assureSignedIn('signin');
                } else {
                    Backbone.history.navigate(_path, { trigger: true });
                }
            }

            break;

            case 'lingvist:pay': {
                let additionalParameters = {locale: i18nUtils.currentInterfaceLanguage};

                if (this.parameters.auth === 'required' && !UserModel.isSignedIn()) {
                    // User is not signed in but authentication is required. Trigger auth flow.

                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateToPayAction(_.assign({}, this.parameters, additionalParameters))
                    );

                    return UserModel.assureSignedIn('signin');
                } else {
                    return Promise.resolve().then(function () {
                        return UserModel.isSignedIn() ? Promise.resolve().then(() => {
                            return UserManager.instance.getUser().getAuthCode();
                        }).then(authCode => {
                            additionalParameters.code = authCode;
                            return Promise.resolve({internal: false});
                        }) : Promise.resolve({internal: false});
                    }).then(() => {
                        let parameters = _.assign({}, this.parameters, additionalParameters);
                        Backbone.trigger('showLoader', 'uri:store');
                        window.location.href = `${getConfigValue('pay-link')}?${$.param(parameters)}`;
                    });
                }
            }

            break;

            case 'lingvist:progress': {
                Backbone.history.navigate('insights/vocabulary', { trigger: true });
                return Promise.resolve({ internal: true });
            }

            case 'lingvist:menu':
                $('button.menu').click();
                return Promise.resolve({internal: true});

            case 'lingvist:guess':
                if (this.parameters.request_questions !== undefined) {
                    return Promise.resolve()
                        .then(function () {
                            Backbone.trigger('showLoader', 'uri:guess:request_questions');
                            return UserManager.instance.getUser().getCourse().requestQuestions(parseInt(self.parameters.count));
                        })
                        .then(() => ControllerManager.instance.getController('Guess').reSyncGuess())
                        .then(function () {
                            Backbone.history.navigate('guess', {trigger: true});
                            return Promise.resolve({internal: true});
                        })
                        .catch(function () {
                            Backbone.trigger('hideLoader');
                        });
                } else {
                    Backbone.history.navigate('guess', {trigger: true});
                    return Promise.resolve({internal: true});
                }
                break;

            case 'lingvist:activate-course':
                Backbone.trigger('showLoader', 'activate-course');
                return Promise.resolve()
                    .then(() => UserModel.setCourse(this.parameters.course_uuid))
                    .then(() => Promise.resolve({internal: true}));
                // break;

            case 'lingvist:register-course': {
                let handler = this.options.handlers && this.options.handlers['lingvist:register-course'];
                if (handler !== undefined) {
                    return Promise.resolve()
                        .then(() => handler(this.parameters))
                        .then(() => Promise.resolve({internal: true}));
                } else if (this.parameters && this.parameters.hasOwnProperty('course_uuid')) {
                    return Promise.resolve()
                        .then( () => {
                            Backbone.trigger('showLoader', 'register-course');
                            return UserManager.instance.getUser().enrolToCourse(this.parameters.course_uuid);
                        })
                        .then(() => UserModel.setCourse(this.parameters.course_uuid))
                        .then(() => {
                            Backbone.trigger('hideLoader');
                            Backbone.history.navigate('guess', { trigger: true });
                        })
                        .then(() => Promise.resolve({internal: true}))
                        .catch( error => {
                            Backbone.trigger('hideLoader');
                            if (error.status === 'error-server') {
                                console.log('Unable to activate course', error);
                            }
                        });
                } else {
                    console.log('Unable to activate course: course_uuid is missing, navigating to HUB');
                    URI.removeSearchString();
                    Backbone.history.navigate('hub', { trigger: true });
                }
                break;
            }

            case 'lingvist:activate-variation':
                // TODO: repeats, new_words and deactivate_others parameters are not processed
                return Promise.resolve()
                    .then(this.parameters.course_uuid && this.parameters.uuid ? () => {
                        ControllerManager.instance.getController('PostSigninActions').add_action(
                            new PostSigninUpdateCourseVariationAction(this.parameters.course_uuid, this.parameters.uuid)
                        );
                        return Promise.resolve();
                    } : Promise.resolve())
                    .then(() => {
                        if (!UserModel.isSignedIn()) {
                            Backbone.history.navigate(`register?${$.param({
                                referral_cw: 1,
                                course_uuid: this.parameters.course_uuid
                            })}`, { trigger: true });
                        } else {
                            return Promise.resolve()
                                .then(() => UserManager.instance.getUser().getVariationInfo(this.parameters.course_uuid, this.parameters.uuid))
                                .then((response) => {
                                    if (response.error) {
                                        Backbone.history.navigate('guess', { trigger: true });
                                        return;
                                    }
                                    const { variation } = response;

                                    const _userSubscription = UserManager.instance.getUser().getSubscription();
                                    const subscriptionStatus = _userSubscription.getStatus().status;
                                    const trialSpent = subscriptionStatus === 'free';

                                    if (variation.status === VARIATION_STATUS.SUBSCRIPTION_LIMITED && trialSpent) {
                                        Backbone.history.navigate('guess', { trigger: true });
                                        return;
                                    }

                                    const _course = Courses.getCourse(this.parameters.course_uuid);
                                    let _course_info = _course.getInfo();

                                    const confirmationView = new ConfirmationView({
                                        title: i18nUtils.prop('variation_accept_title'),
                                        message: i18nUtils.prop('variation_accept_text', { variation_name: variation.name, course_name: _course_info.name, variation_units: variation.units }),
                                        actions: [
                                            {
                                                title: i18nUtils.prop('variation_accept_decline_button'),
                                                action: CONFIRM_ACTION.CANCEL
                                            },
                                            {
                                                title: i18nUtils.prop('variation_accept_confirm_button'),
                                                action: CONFIRM_ACTION.OK,
                                                primary: true
                                            }
                                        ]
                                    });

                                    confirmationView.on('confirm', action => {
                                        if (action === CONFIRM_ACTION.OK) {
                                            googleAnalyticsCommand('send', 'event', 'Variations', 'VariationSwitchConfirmation', 'Yes');

                                            const _activateVariation = () => {
                                                const onboardingController = ControllerManager.instance.getController('Onboarding');
                                                Backbone.history.navigate('guess', { trigger: true });
                                                onboardingController.activateVariation(this.parameters.uuid);
                                            };

                                            if (UserManager.instance.getUser().hasCourse() &&
                                                UserManager.instance.getUser().getCourse().getInfo().uuid === this.parameters.course_uuid) {
                                                _activateVariation();
                                            } else {
                                                Backbone.trigger('showLoader', 'uri:activate-variation');
                                                // Do this asynchronously: Don't wait for it to close the dialog
                                                Promise.resolve()
                                                    .then(() => UserManager.instance.getUser().enrolToCourse(this.parameters.course_uuid))
                                                    .then(() => UserModel.setCourse(this.parameters.course_uuid))
                                                    .then(() => {
                                                        Backbone.trigger('hideLoader');
                                                        _activateVariation();
                                                    });
                                            }
                                        }

                                        if (action === CONFIRM_ACTION.CANCEL) {
                                            googleAnalyticsCommand('send', 'event', 'Variations', 'VariationSwitchConfirmation', 'No');
                                            Backbone.history.navigate('guess', { trigger: true });
                                        }

                                        Promise.resolve()
                                            .then(() => confirmationView.hide())
                                            .then(() => {
                                                confirmationView.remove();
                                            });
                                    });

                                    confirmationView.show();
                                });
                        }
                    })
                    .then(() => {
                        URI.removeSearchString();
                        return Promise.resolve();
                    });

            case 'lingvist:join-classroom': {

                const classroomCode = this.parameters.code;

                if (UserModel.isSignedIn()) {

                    return Promise.resolve()
                        .then( () => ControllerManager.instance.getController('Classrooms').joinClassroom(classroomCode))
                        .then( () => {
                            URI.removeSearchString();
                            Backbone.history.navigate('guess', { trigger: true });
                        });
                } else {
                    return Promise.resolve()
                        .then( () => Classrooms.getCourseUuidByClassroomCode(classroomCode))
                        .then( courseUuid => {
                            UserModel.setClassroomToJoin(classroomCode);
                            Backbone.history.navigate(`register?${$.param({
                                course_uuid: courseUuid,
                            })}`, {trigger: true});
                        });
                }
                break;
            }

            case 'lingvist:show-file': {
                if (this.parameters && this.parameters.name) {
                    Backbone.history.navigate(`show-file?name=${this.parameters.name}`, {trigger: true});
                }
                break;
            }

            case 'lingvist:delete-account': {
                const onboardingController = ControllerManager.instance.getController('Onboarding');
                onboardingController.showAccountDeleteConfirmation();
                break;
            }

            case 'lingvist:custom-decks':
            case 'lingvist:course-wizard': {
                Backbone.history.navigate('course-wizard/create', { trigger: true });

                return Promise.resolve({internal: true});
            }

            case 'lingvist:lessons': {
                if (UserManager.instance.getUser().hasFeature(USER_FEATURE.CLASSROOMS)) {
                    Backbone.history.navigate('lessons', { trigger: true });
                } else {
                    Backbone.history.navigate('classrooms', { trigger: true });
                }

                return Promise.resolve({internal: true});
            }

            case 'lingvist:account': {
                Backbone.history.navigate(`account?${this.parameters.tab !== undefined ? $.param({tab: this.parameters.tab}) : ''}`, {trigger: true});
                return Promise.resolve({internal: true});
            }

            case 'lingvist:home': {
                Backbone.history.navigate(`signin?${this.parameters.error_unauthorized !== undefined ? $.param({error_unauthorized: 1}) : ''}`, { trigger: true });
                return Promise.resolve({internal: true});
            }

            case 'lingvist:help': {

                const user = UserManager.instance.getUser();

                if (user.hasCourse()) {
                    const faqUrl = user.getCourse().getUrl('faq');
                    if (faqUrl !== null) {
                        window.open(faqUrl, '_blank');
                    }
                }
                return Promise.resolve({ internal: false });
            }

            case 'lingvist:hub': {
                Backbone.trigger('openHomeDrawer');
                return Promise.resolve({internal: true});
            }

            case 'lingvist:bolt': {
                // redirect user back to lingvist.com public/static web bolt campaign page
                const _staticWebBaseUrl = getConfigValue('static-web-url');
                const _downloadSection = '#download';
                if (this.parameters && this.parameters.sl) {
                    window.location.href = `${_staticWebBaseUrl}/${this.parameters.sl}/bolt/${_downloadSection}`;
                } else {
                    window.location.href = `${_staticWebBaseUrl}/bolt/${_downloadSection}`;
                }
                break;
            }

            case 'lingvist:learn': {
                let _path = 'guess';

                if (this.parameters.auth === 'required' && !UserModel.isSignedIn()) {
                    // User is not signed in but authentication is required. Trigger auth flow.

                    ControllerManager.instance.getController('PostSigninActions').add_action(
                        new PostSigninNavigateTo(_path)
                    );

                    return UserModel.assureSignedIn('signin');
                } else {
                    Backbone.history.navigate(_path, { trigger: true });
                }
            }

            break;

            case 'lingvist:challenges': {
                Backbone.trigger('showLoader', 'challenges');
                URI.removeSearchString();
                if (this.parameters.open_challenge !== undefined) {
                    if (this.parameters.uuid !== undefined) {
                        Backbone.history.navigate(`challenges?open_challenge=1&uuid=${this.parameters.uuid}`, {trigger: true});
                    } else if (this.parameters.type !== undefined && this.parameters.order !== undefined) {
                        Backbone.history.navigate(`challenges?open_challenge=1&type=${this.parameters.type}&order=${this.parameters.order}`, {trigger: true});
                    } else {
                        Backbone.history.navigate(`challenges`, {trigger: true});
                    }
                } else if (this.parameters.category !== undefined) {
                    Backbone.history.navigate(`challenges?filter=${this.parameters.category}`, {trigger: true});
                } else {
                    Backbone.history.navigate(`challenges`, {trigger: true});
                }
                return Promise.resolve({internal: true});
            }

            default: {
                if (this.uri.startsWith('https://') || this.uri.startsWith('http://')) {
                    window.location.href = this.uri;
                    return Promise.resolve();
                } else {
                    throw new UnknownURIException(this.uri);
                }
            }
        }
    }
}

ExportUtils.export('app.util.URI', URI);
