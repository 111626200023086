var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "credentials-email" },
    [
      _c("h1", {
        domProps: {
          innerHTML: _vm._s(
            this.$i18n("register_credentials_selected_course_heading")
          ),
        },
      }),
      _vm._v(" "),
      _c("course", { attrs: { course: _vm.selected_course } }),
      _vm._v(" "),
      _c("p", {
        domProps: { innerHTML: _vm._s(this.$i18n("register_save_progress")) },
      }),
      _vm._v(" "),
      _c("h2", {
        domProps: { innerHTML: _vm._s(this.$i18n("register_email_header")) },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "register",
          on: {
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.form_keypress.apply(null, arguments)
            },
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "input-container" },
            [
              _c("account-icon"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name_,
                    expression: "name_",
                  },
                ],
                ref: "name_input",
                staticClass: "name",
                class: { error: _vm.name_validation_error },
                attrs: {
                  type: "text",
                  placeholder: this.$i18n("register_name_placeholder"),
                  autocomplete: "name",
                },
                domProps: { value: _vm.name_ },
                on: {
                  change: _vm.check_name,
                  blur: _vm.check_name,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.name_ = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.name_validation_error === "error-empty-name"
                ? _c("p", {
                    staticClass: "error",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("register_error_empty_name")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-container" },
            [
              _c("account-email-icon"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email_,
                    expression: "email_",
                  },
                ],
                staticClass: "email",
                class: {
                  error: _vm.email_validation_error,
                  suggestion: _vm.email_suggestion,
                },
                attrs: {
                  type: "email",
                  placeholder: this.$i18n("register_email_email_placeholder"),
                  autocomplete: "email",
                },
                domProps: { value: _vm.email_ },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.email_ = $event.target.value
                    },
                    _vm.check_email,
                  ],
                  blur: _vm.check_email,
                },
              }),
              _vm._v(" "),
              _vm.email_suggestion
                ? _c("p", {
                    staticClass: "email-suggestion",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("register_email_suggestion", {
                          args: {
                            email: `<a>${decodeURIComponent(
                              _vm.email_suggestion.full
                            )}</a>`,
                          },
                        })
                      ),
                    },
                    on: { click: _vm.use_email_suggestion },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.email_validation_error === "error-empty-email"
                ? _c("p", {
                    staticClass: "error",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("register_error_empty_email")
                      ),
                    },
                  })
                : _vm.email_validation_error === "error-invalid-email"
                ? _c("p", {
                    staticClass: "error",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("register_error_invalid_email")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-container" },
            [
              _c("account-lock-icon"),
              _vm._v(" "),
              (_vm.password_visible ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password_,
                        expression: "password_",
                      },
                    ],
                    staticClass: "password",
                    attrs: {
                      placeholder: this.$i18n("register_password_placeholder"),
                      autocomplete: "new-password",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.password_)
                        ? _vm._i(_vm.password_, null) > -1
                        : _vm.password_,
                    },
                    on: {
                      input: _vm.check_password_on_input,
                      blur: _vm.check_password_on_blur,
                      change: function ($event) {
                        var $$a = _vm.password_,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.password_ = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.password_ = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.password_ = $$c
                        }
                      },
                    },
                  })
                : (_vm.password_visible ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password_,
                        expression: "password_",
                      },
                    ],
                    staticClass: "password",
                    attrs: {
                      placeholder: this.$i18n("register_password_placeholder"),
                      autocomplete: "new-password",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.password_, null) },
                    on: {
                      input: _vm.check_password_on_input,
                      blur: _vm.check_password_on_blur,
                      change: function ($event) {
                        _vm.password_ = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password_,
                        expression: "password_",
                      },
                    ],
                    staticClass: "password",
                    attrs: {
                      placeholder: this.$i18n("register_password_placeholder"),
                      autocomplete: "new-password",
                      type: _vm.password_visible ? "text" : "password",
                    },
                    domProps: { value: _vm.password_ },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.password_ = $event.target.value
                        },
                        _vm.check_password_on_input,
                      ],
                      blur: _vm.check_password_on_blur,
                    },
                  }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "password-reveal",
                  on: {
                    click: function ($event) {
                      _vm.password_visible = !_vm.password_visible
                    },
                  },
                },
                [
                  _c("account-eye", {
                    class: { invisible: !_vm.password_visible },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.password_validation_error === null
                ? _c("p", {
                    staticClass: "password-length-reminder",
                    domProps: {
                      innerHTML: _vm._s(this.$i18n("register_password_length")),
                    },
                  })
                : _vm.password_validation_error === "error-password-too-short"
                ? _c("p", {
                    staticClass: "error",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("register_error_password_too_short")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "footer",
        [
          _c("button", {
            attrs: { disabled: !_vm.submit_enabled },
            domProps: { innerHTML: _vm._s(this.$i18n("register_submit")) },
            on: { click: _vm.submit_credentials },
          }),
          _vm._v(" "),
          _c("terms"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }