
import moment from 'moment';

import { BaseExerciseAnswer } from './base.js';

export const ENTRY_TYPE = {
    SPEECH: 'speech',
    SKIP: 'skip'
};

export class SpeakingExerciseAnswer extends BaseExerciseAnswer {
    constructor (...args) {
        super(...args);
    }

    /**
     *
     * @param type
     * @param {{phraseIndex: int, suggested: boolean, transcript: string, similarityScore: number}} data: Data about the entry
     */
    addEntry (type, data) {
        super.addEntry();

        switch (type) {
            case ENTRY_TYPE.SPEECH:
                this.entry_events.push({
                    type: type,
                    ts: moment(),
                    phrase: data.phraseIndex,
                    result: data.transcript,
                    score: data.similarityScore
                });
                break;
            case ENTRY_TYPE.SKIP:
                this.entry_events.push({
                    type: type,
                    ts: moment(),
                    phrase: data.phraseIndex,
                    suggested: data.suggested
                });
                break;
            default:
                throw Error(`Unrecognized exercise answer entry type="${type}"`);
        }
    }

    hasEntries () {
        return this.entry_events.length > 0;
    }

}
