var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wordlist-play-button",
      class: { isPlaying: _vm.isPlaying, isStopping: _vm.isStopping },
      on: {
        click: function ($event) {
          !_vm.isPlaying ? _vm.play() : _vm.stop()
        },
      },
    },
    [
      this.isPlaying
        ? _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M3 12V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V17",
                  stroke: _vm.stopIconColor,
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          )
        : _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M4.5 15H4C2.89543 15 2 15.8954 2 17V18C2 19.1046 2.89543 20 4 20H6C7.10457 20 8 19.1046 8 18V7C8 5.89543 8.89543 5 10 5H19C20.1046 5 21 5.89543 21 7V15C21 16.1046 20.1046 17 19 17H17C15.8954 17 15 16.1046 15 15V14C15 12.8954 15.8954 12 17 12H18",
                  stroke: "white",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }