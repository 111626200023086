<template>
    <div class="card-visual" v-if="this.visuals && this.question && image_data && image_data.enabled" v-bind:style="{ backgroundImage: 'url(' + image_url + ')' }">
        <div class="change-picture-button" @click="showVisualsModal">
            <change-picture-icon />
        </div>
        <div class="info" v-if="image_data && image_data.attribution" @click="openUnsplash">
            <div class="title">{{ image_data.attribution.title }}</div>
            <div class="description">{{ image_data.attribution.description }}</div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus";
    import { isArray } from 'lodash';
    import ChangePictureIcon from "Images/learn/change_picture_24x24.svg";

    export default {
        name: 'card-visual',
        components: {
            ChangePictureIcon,
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            visuals: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                image_chosen_data: null
            }
        },
        computed: {
            images_data() {
                if (this.question && this.question.sense && isArray(this.question.sense.visuals) && this.question.sense.visuals.length > 0) {
                    return this.question.sense.visuals;
                } else {
                    return [];
                }
            },
            image_data() {
                if (this.image_chosen_data) {
                    return this.image_chosen_data;
                } else if (this.question && this.question.visual && this.question.visual.hasOwnProperty('attribution') && this.question.visual.hasOwnProperty('urls')) {
                    return this.question.visual;
                } else {
                    if (this.images_data.length > 0) {
                        return this.images_data[0];
                    } else {
                        return null;
                    }
                }
            },
            image_url() {
                return (this.image_data) ? this.image_data.urls.small : null;
            }
        },
        methods: {
            reset() {
                this.image_chosen_data = null;
            },
            openUnsplash() {
                if (this.image_data && this.image_data.hasOwnProperty('attribution') && this.image_data.attribution.url) {
                    window.open(this.image_data.attribution.url, '_blank').focus();
                }
            },
            async showVisualsModal() {
                if (this.image_data && this.images_data) {
                    let _imagesData = this.images_data.map(image => {
                        image.selected = image.attribution.original_id === this.image_data.attribution.original_id;
                        return image;
                    });
                    EventBus.$emit('guess:card-visual-modal-open', _imagesData);
                }
            },
            cardVisualChosen(data) {
                this.image_chosen_data = data;
            },
            listenEventBus() {
                EventBus.$on('guess:card-visual-chosen', this.cardVisualChosen);
            },
            unListenEventBus() {
                EventBus.$off('guess:card-visual-chosen', this.cardVisualChosen);
            },
        },
        watch: {
            async question(newQ, oldQ) {
                if (newQ.uuid !== oldQ.uuid) {
                    this.reset();
                }
            }
        },
        async mounted() {
            this.listenEventBus();
        },
        beforeDestroy() {
            this.unListenEventBus();
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";
    div.card-visual {
        position: relative;
        border-radius: pxToRem(12);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: pxToRem(184);
        min-width: pxToRem(184);
        min-height: pxToRem(184);
        background-color: $colorBackgroundGray;
        overflow: hidden;
        > div.change-picture-button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: .5rem;
            top: .5rem;
            background-color: rgba(#EEF0F4, 0.8);
            width: pxToRem(32);
            height: pxToRem(32);
            border-radius: 50%;
            visibility: hidden;
            opacity: 0;
            transition: all 250ms;
            &:hover {
                transform: rotate(180deg);
            }
        }
        &:hover {
            > div.change-picture-button {
                visibility: visible;
                cursor: pointer;
                opacity: 1;
            }
        }
        > div.info {
            font-size: fontSize(14);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: .5rem;
            color: #fff;
            background-color: rgba($colorSourcePrimary, 0.5);
            border-bottom-left-radius: pxToRem(12);
            border-bottom-right-radius: pxToRem(12);
            cursor: pointer;
            width: 100%;
            transform: translateY(100%);
            transition: 200ms ease-in-out;
            opacity: 0;
            z-index: 2;
            > div.description {
                z-index: 1;
                opacity: 0.5;
            }
        }
        &:hover {
            > div.info {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
</style>
