<template>
    <div class="repeat-progress-modal" @click.self="close" v-if="isOpen">
        <div class="repeat-progress-modal-window">
            <CloseButton @click="close" class="close" />
            <h2 class="title" v-html="this.$i18n('learn_repeat_progress_modal_title')" />
            <div class="content">
                <div class="instructions">
                    <p v-html="this.$i18n('learn_repeat_progress_modal_instruction_1')" />
                    <p v-html="this.$i18n('learn_repeat_progress_modal_instruction_2')" />
                </div>
                <div class="levels">
                    <div class="level five">
                        <div class="stripes">
                            <span class="stripe"></span>
                            <span class="stripe"></span>
                            <span class="stripe"></span>
                            <span class="stripe"></span>
                            <span class="stripe"></span>
                        </div>
                        <div class="description" v-html="this.$i18n('learn_repeat_progress_high')" />
                    </div>
                    <div class="level four">
                        <div class="stripes">
                            <span class="stripe one"></span>
                            <span class="stripe two"></span>
                            <span class="stripe three"></span>
                            <span class="stripe four"></span>
                            <span class="stripe five"></span>
                        </div>
                        <div class="description" v-html="this.$i18n('learn_repeat_progress_medium_high')" />
                    </div>
                    <div class="level three">
                        <div class="stripes">
                            <span class="stripe one"></span>
                            <span class="stripe two"></span>
                            <span class="stripe three"></span>
                            <span class="stripe four"></span>
                            <span class="stripe five"></span>
                        </div>
                        <div class="description" v-html="this.$i18n('learn_repeat_progress_medium')" />
                    </div>
                    <div class="level two">
                        <div class="stripes">
                            <span class="stripe one"></span>
                            <span class="stripe two"></span>
                            <span class="stripe three"></span>
                            <span class="stripe four"></span>
                            <span class="stripe five"></span>
                        </div>
                        <div class="description" v-html="this.$i18n('learn_repeat_progress_low')" />
                    </div>
                    <div class="level one">
                        <div class="stripes">
                            <span class="stripe one"></span>
                            <span class="stripe two"></span>
                            <span class="stripe three"></span>
                            <span class="stripe four"></span>
                            <span class="stripe five"></span>
                        </div>
                        <div class="description" v-html="this.$i18n('learn_repeat_progress_new')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import CloseButton from "Images/close-24x24.svg";
    import { EventBus } from "Util/vue-event-bus";

    export default {
        name: 'repeat-progress-modal',
        components: {
            CloseButton
        },
        data() {
            return {
                isOpen: false
            }
        },
        methods: {
            open() {
                document.body.classList.add('modal-open');
                this.isOpen = true;
            },
            close() {
                this.isOpen = false;
                document.body.classList.remove('modal-open');
            },
            keyDown(event) {
                if (event && event.keyCode === 27) { // close on ESC key
                    this.close()
                }
            },
            listenEventBus() {
                EventBus.$on('guess:repeat-progress-modal-open', this.open);
                EventBus.$on('guess:repeat-progress-modal-close', this.close);
            },
            unListenEventBus() {
                EventBus.$off('guess:repeat-progress-modal-open', this.open);
                EventBus.$off('guess:repeat-progress-modal-close', this.close);
            }
        },
        created() {
            this.listenEventBus();
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
        },
        beforeDestroy() {
            this.unListenEventBus();
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.repeat-progress-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43, 81, 142, 0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        > div.repeat-progress-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 40vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
            > h2.title {
                text-align: center;
            }

            > div.content {
                margin-top: pxToRem(24);

                > div.instructions {

                    padding: 0 2rem 1rem;

                    > p {
                        padding: .5rem 0;
                        text-align: center;
                    }
                }

                > div.levels {

                    margin: 0 pxToRem(32);
                    padding: pxToRem(16) pxToRem(32);
                    border-top: 1px solid $colorBackgroundLighter;
                    @include respond-to('small') {
                        margin: 0 .5rem;
                        padding: .5rem;
                    }

                    > div.level {

                        display: flex;
                        padding: pxToRem(4) 0;

                        > div.stripes {

                            display: flex;
                            align-items: center;
                            padding: 0 1rem;

                            > span.stripe {
                                display: block;
                                width: pxToRem(16);
                                height: pxToRem(4);
                                background: $colorBackgroundGraySecondary;
                                border-radius: pxToRem(2);
                                margin: 0 pxToRem(2);
                            }
                        }

                        &.five {
                            > div.stripes {
                                > span.stripe {
                                    background: $colorCorrect;
                                }
                            }
                        }

                        &.four {
                            > div.stripes {
                                > span.stripe.one,
                                > span.stripe.two,
                                > span.stripe.three,
                                > span.stripe.four {
                                    background: $colorTargetPrimary;
                                }
                            }
                        }

                        &.three {
                            > div.stripes {
                                > span.stripe.one,
                                > span.stripe.two,
                                > span.stripe.three {
                                    background: $colorTargetSecondary;
                                }
                            }
                        }

                        &.two {
                            > div.stripes {
                                > span.stripe.one,
                                > span.stripe.two {
                                    background: #BED7D7;
                                }
                            }
                        }

                        &.one {
                            > div.stripes {
                                > span.stripe.one {
                                    background: $colorAttention;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
