var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "weekly grid-item" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [
        _c("h2", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("hub_section_sets_goal_title")),
          },
        }),
        _vm._v(" "),
        _c("h4", [_vm._v(_vm._s(_vm.cardsOutOfCards))]),
      ]),
      _vm._v(" "),
      _c("a", {
        staticClass: "change-goal",
        domProps: {
          innerHTML: _vm._s(this.$i18n("hub_section_daily_goal_button")),
        },
        on: { click: _vm.changeGoal },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "weeks" },
      [
        this.previousWeekGoalsData
          ? [
              _c(
                "week-goals",
                _vm._g(
                  {
                    staticClass: "previousWeek",
                    attrs: { "goals-data": this.previousWeekGoalsData },
                  },
                  _vm.$listeners
                )
              ),
              _vm._v(" "),
              _c("div", { staticClass: "separator" }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "week-goals",
          _vm._g(
            {
              staticClass: "currentWeek",
              attrs: { "goals-data": this.thisWeekGoalsData },
            },
            _vm.$listeners
          )
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }