var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "action",
      class: { topBorder: this.topBorder, bottomBorder: this.bottomBorder },
      on: { click: _vm.triggerAction },
    },
    [
      _c("div", { staticClass: "text" }, [
        _c("h3", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        this.description
          ? _c("p", [_vm._v(_vm._s(_vm.description))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "arrow",
          attrs: {
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M7 4L10 8L7 12",
              stroke: "#2C3143",
              "stroke-width": "2",
              "stroke-linecap": "round",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }