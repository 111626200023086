var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7 25h5M15 7v5M33 15h-5M25 33v-5M5 20h7M20 5v7M35 20h-7M20 35v-7M7 15h5M25 7v5M33 25h-5M15 33v-5",
          stroke: "#63E1E7",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "6",
          cy: "25",
          r: "2",
          transform: "rotate(90 6 25)",
          fill: "#63E1E7",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "15",
          cy: "6",
          transform: "rotate(-180 15 6)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "34",
          cy: "15",
          transform: "rotate(-90 34 15)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", { attrs: { cx: "25", cy: "34", fill: "#63E1E7", r: "2" } }),
      _c("circle", { attrs: { cx: "4", cy: "20", r: "2", fill: "#63E1E7" } }),
      _c("circle", {
        attrs: {
          cx: "20",
          cy: "4",
          transform: "rotate(90 20 4)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "36",
          cy: "20",
          transform: "rotate(-180 36 20)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "20",
          cy: "36",
          transform: "rotate(-90 20 36)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", { attrs: { cx: "6", cy: "15", r: "2", fill: "#63E1E7" } }),
      _c("circle", {
        attrs: {
          cx: "25",
          cy: "6",
          transform: "rotate(90 25 6)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "34",
          cy: "25",
          transform: "rotate(-180 34 25)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "15",
          cy: "34",
          transform: "rotate(-90 15 34)",
          fill: "#63E1E7",
          r: "2",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 16a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 01-1 1h-8a1 1 0 01-1-1v-8z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 14a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H14a2 2 0 01-2-2V14z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }