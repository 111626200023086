
import moment from 'moment';
import AsyncDestroyable from './async.destroyable.js';
import Raven from 'raven-js';

export const TYPE = {
    STRING: 'string',
    INTEGER: 'integer',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    DATETIME: 'datetime'
};

export const NAME = {
    // Global parameters
    BLOCKED_FEATURE_PHASE: 'global.subscriptions.blocked_feature.phase',
    WORD_TAGGED_COUNT: 'global.word_tagging.guess_word_tagged_count',
    VARIATIONS_LAST_ASSIGNED: 'global.variations.last_assigned_ts',
    LANDING_PAGE_DEFAULT: 'global.landing_page.default',
    COURSE_WIZARD_LAST_LESSON_UUID: 'global.course_wizard.last_lesson_uuid',
    SUBSCRIPTION_NOTIFICATION_TS: 'global.subscriptions.notification_ts',
    SUBSCRIPTION_NOTIFIED_TS: 'global.subscriptions.notified_ts',
    CURRENT_COURSE_UUID: 'global.current_course_uuid',
    SETTING_AUTO_ADVANCE: 'global.guess_auto_advance',
    SETTING_SHOW_GRAMMAR_TABLES: 'global.settings.auto_show_grammar_tables',
    SETTING_STRICT_DIACRITICS: 'global.settings.strict_diacritics',
    SETTING_VISUALS: 'global.guess_visuals',
    GUESS_FEEDBACK_SURVEY_SHOWN_TS: 'global.guess.feedback_survey_shown_ts',
    SETTING_THERMOMETER_ZOOMED: 'global.settings.thermometer_zoomed',
    DYNAMIC_GOALS_CARDS_GOAL: 'global.dynamic_goals.daily_cards_goal',

    // Web-only parameters
    AUDIO_ENABLED: 'web.audio_enabled',
    DEBUG_OPENING_VIEW_OPEN: 'web.debug_opening_view_open',
    DEBUG_OPENING_VIEW_COORDINATE_X: 'web.debug_opening_view_coordinates_x',
    DEBUG_OPENING_VIEW_COORDINATE_Y: 'web.debug_opening_view_coordinates_y',
    GUESS_KEYBOARD_OPEN: 'web.guess_keyboard_open',
    LOCKED_MESSAGE_CLOSED: 'web.locked_message_closed',
    FORM_SPELLINGS_CLOSED: 'web.form_comment_closed',
    GRAMMAR_TIP_FAVOURITE: key => `web.grammar_tip_favourite.${key}`,
    ONBOARDING_PROGRESS_STEP_DONE: tooltip => `web.onboarding_progress_step_done.${tooltip}`,
    ONBOARDING_PROGRESS_TIMESTAMP: name => `web.onboarding_progress_timestamp.${name}`
};

function dump_parameter (type, value) {
    switch (type) {
        case TYPE.STRING:
            return value;
        case TYPE.DATETIME:
            // Assume moment objects!
            return value.locale('en').format();
        default:
            return JSON.stringify(value);
    }
}

class UserParameterInvalidTypeException extends Error {
    constructor (type) {
        super(`UserParameter is of unsupported type="${type}"`);
        this.name = 'UserParameterInvalidTypeException';
    }
}

export class UserParameters extends AsyncDestroyable {
    constructor (api_client) {
        super(['sync', '_sync', 'update']);
        this._api_client = api_client;

        this._sync_promise = null;
        this._sync_timeout = null;
        this._parameters = {};
        this._updated = moment.utc();
    }

    destroy () {
        delete this._api_client;
        return Promise.resolve();
    }

    _decodeParameter (type, value) {
        switch (type) {
            case TYPE.STRING:
                return value;
            case TYPE.INTEGER:
                return JSON.parse(value);
            case TYPE.NUMBER:
                return JSON.parse(value);
            case TYPE.BOOLEAN:
                return JSON.parse(value);
            case TYPE.DATETIME:
                return moment(value);
            default:
                throw new UserParameterInvalidTypeException(type);
        }
    }

    updateParameters (parameters) {
        parameters.forEach(parameter => {
            try {
                this._parameters[parameter.key] = {
                    type: parameter.type,
                    value: this._decodeParameter(parameter.type, parameter.value),
                    updated: moment.utc()
                };
                this._updated = moment.utc();
            } catch (error) {
                Raven.captureException(error, {level: 'error'});
            }

        });
    }

    _sync () {
        if (this._sync_promise) {
            // If a sync is already in progress start the process again after this finishes
            return Promise.resolve()
                .then(() => this._sync_promise)
                .then(() => this.sync());
        } else {
            let parameters = Object.entries(this._parameters)
                .filter(([key, data]) => data.updated.isAfter(this._updated))
                .map(([key, data]) => ({
                    key: key,
                    type: data.type,
                    value: dump_parameter(data.type, data.value)
                }));
            if (parameters.length > 0) {
                this._sync_promise =
                    Promise.resolve()
                        .then(() => {
                            return this._api_client.r.user.parameters.post({parameters});
                        })
                        .then(() => {
                            this._sync_promise = null;
                            parameters.forEach(({key}) => {
                                this._parameters[key].updated = moment.utc();
                            });
                            this._updated = moment.utc();
                        })
                        .catch(error => {
                            this._sync_promise = null;
                            return Promise.reject(error);
                        });
                return this._sync_promise;
            } else {
                return Promise.resolve();
            }
        }
    }

    sync () {
        clearTimeout(this._sync_timeout);
        return new Promise(resolve => {
            this._sync_timeout = setTimeout(() => resolve(), 5000);
        }).then(() => this._sync());
    }

    getParameter (key) {
        return (this._parameters[key] === undefined ? null : this._parameters[key].value);
    }

    getParameterByPrefix (key_prefix) {
        return Object.entries(this._parameters)
            .filter(([key, value]) => key.startsWith(key_prefix))
            .reduce((result, [key, value]) => {result[key] = value; return result;}, {});
    }

    setParameter (key, value, type=null) {
        let parameter_type = type !== null ? type : (this._parameters[key] && this._parameters[key].type || TYPE.STRING);

        // Test parameter
        dump_parameter(parameter_type, value);

        this._parameters[key] = {
            // If type is not provided try to infer type from existing parameter, otherwise default to string
            type: parameter_type,
            value: value,
            updated: moment.utc()
        };

        this.sync(); // Ignoring Promise on purpose
        return Promise.resolve();
    }
}

