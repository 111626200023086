<template>
    <div class="lu-debug">
        <div class="lus">
            <v-textbox :full-width="true" label="Load LU's by UUID, separate with space" :mono="true" @state-change="setLUs" />
            <v-button size="medium" text="Load LU's above" @click.native="loadLUs" :disabled="this.loadLUArray.length <= 0" />
        </div>
        <h2>Easy load some Lexical Units for debugging</h2>
        <div class="lu">
            <v-button text="Bella, Bonita (Spanish)" @click.native="loadLu('29bf6436-c7dc-4458-acc5-89678314201a')" size="small" />
            <v-button text="Duro, Severo, Difícil (Spanish)" @click.native="loadLu('6e678a7e-7dd8-4fb7-a0b2-011783baa883')" size="small" />
            <v-button text="Fondation, Institution (French)" @click.native="loadLu('c9806758-9425-4f54-9d27-7ec36b7af8a1')" size="small" />
            <v-button text="Los - double gap? (Spanish)" @click.native="loadLu('d13df36e-33a7-463b-a573-597523bf0b87')" size="small" />
            <v-button text="Los - double gap? (Spanish)" @click.native="loadLu('6dfe377b-15d6-4226-9058-dc18ac623166')" size="small" />
        </div>
    </div>
</template>

<script>
    import ControllerManager from "Modules/controller.manager.js";
    import VButton from "ViewComponent/v-button.vue";
    import { EventBus } from "Util/vue-event-bus.js";
    import VTextbox from "ViewComponent/v-textbox.vue";

    export default {
        name: 'tab-lu',
        components: { VTextbox, VButton },
        data() {
            return {
                loadLUArray: []
            }
        },
        methods: {
            guessDebugModeOn() {
                EventBus.$emit('debug:guess-debug');
                EventBus.$emit('debug:close');
            },
            loadLu(uuid) {
                ControllerManager.instance.getController('Debug').loadDebugLexicalUnits([uuid]);
                EventBus.$emit('debug:close');
            },
            loadLUs() {
                if (this.loadLUArray && this.loadLUArray.length > 0) {
                    ControllerManager.instance.getController('Debug').loadDebugLexicalUnits(this.loadLUArray)
                    this.guessDebugModeOn();
                }
            },
            setLUs(string) {
                if (string && string.length > 0) {
                    this.loadLUArray = string.split(" ");
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    div.lu-debug {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        div.lu,
        div.lus {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
</style>
