var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M5 12a7 7 0 111.06 3.704",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M15 11.611C15 15.262 13.657 19 12 19s-3-3.738-3-7.389C9 7.96 10.343 5 12 5s3 2.96 3 6.611z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18 8.5c-1.215.667-3.517 1.61-5.95 1.61-.838 0-1.64-.053-2.383-.15M6 15.5c1.17-.599 3.314-1.5 5.764-1.5.784 0 1.537.041 2.236.117",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3 7l2-1M3 7L2 5m1 2c.4-1.496 1-3.5 3.5-4.5M21 17l-2 1m2-1l1 2m-1-2c-.4 1.496-1 3.5-3.5 4.5",
          stroke: "#AC6ED1",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }