var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#63E1E7",
          d: "M15 5c0 2.1-1.2 3.8-3 4.6V5s0-2-2-2-2 2-2 2v4.6C6.2 8.8 5 7.1 5 5c0-2.8 2.2-5 5-5s5 2.2 5 5z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#2C3143",
          d: "M10 2C8.3 2 7 3.3 7 5v4.6L4.3 13c-1 1.2-.9 2.9.2 4L7 19.4V23h3c.6 0 1-.4 1-1s-.4-1-1-1H9v-2.4l-3-3c-.4-.4-.4-.9-.1-1.3L7 12.9V14c0 .6.4 1 1 1s1-.4 1-1V5c0-.6.4-1 1-1s1 .4 1 1v8c0 .6.4 1 1 1s1-.4 1-1V9h1c.6 0 1 .4 1 1v3.5c0 .6.4 1 1 1s1-.4 1-1V11h1c.6 0 1 .4 1 1v4.5c0 .2 0 .3-.1.4l-.9 1.9V21h-3c-.6 0-1 .4-1 1s.4 1 1 1h5v-3.8l.7-1.4c.2-.4.3-.9.3-1.3V12c0-1.7-1.3-3-3-3h-1.2c-.4-1.2-1.5-2-2.8-2h-1V5c0-1.7-1.3-3-3-3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }