var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "20",
          height: "13",
          viewBox: "0 0 20 13",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17.276 6.368a1 1 0 00-1.801 0l-1.783 3.698a1 1 0 00.901 1.434h3.565a1 1 0 00.901-1.434l-1.783-3.698z",
          fill: "#FFBAB0",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#FFBAB0",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.5.5L7.874 11.569",
          stroke: "#BDDBDF",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#BDDBDF",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.116 5.91a1 1 0 00-.912-1.41H1.547a1 1 0 00-.912 1.41l1.829 4.064c.353.786 1.47.786 1.823 0L6.116 5.91z",
          fill: "#BDDBDF",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }