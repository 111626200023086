
export class NotImplementedException extends Error {
    /**
     * @param object_ - the calling object
     * @param function_name - Name of the non-implemented function
     */
    constructor(object_, function_name) {
        super();
        this.name = 'NotImplementedException';
        this.message = `"${function_name}" is not implemented`;
    }
}
