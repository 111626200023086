<template>
    <div class="subs-item-single" v-if="this.service && this.service.hasOwnProperty('subscription') && !this.service.is_voucher">
        <template v-if="singlePeriodDiscount && this.service.isRecurringAndActive">
            <div class="section" v-for="item in singlePeriodDiscount">
                <h3 class="title">{{ item.startingTitle }}</h3>
                <div class="details">
                    <p class="name" v-if="item.name === 'single-period-discount'">{{ service.title }}</p>
                    <p class="renewal" v-else v-html="$i18n('my_account_subscription_renewal_text')" />
                    <p class="voucher" v-if="item.is_voucher">voucher</p>
                    <p class="price-period" v-html="getPricePeriod(item.amount, item.duration)" />
                    <p class="footnote" v-if="appStoresChangeLabel">{{ appStoresChangeLabel }}</p>
                    <v-button v-else-if="item.name !== 'single-period-discount'" size="medium" :i18n="{ id: 'account_subscription_cancel' }" @click.native="cancelSubscriptionPrompt"/>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="isRecurring && activeSinceIsInTheFuture">
                <div class="section" v-if="service.is_on_free_trial">
                    <h3 class="title">{{ this.startingTitle(this.service.active_since_ts) }}</h3>
                    <div class="details">
                        <p class="name"  v-html="this.$i18n('my_account_subscription_free_trial_text')" />
                    </div>
                </div>
                <div class="section" v-else>
                    <h3 class="title">{{ this.startingTitle(this.service.active_since_ts) }}</h3>
                    <div class="details">
                        <template>
                            <p class="name">{{ subscriptionName }}</p>
                            <p class="voucher" v-if="service.is_voucher">voucher</p>
                            <p class="price-period" v-if="pricePeriod" v-html="pricePeriod" />
                        </template>
                    </div>
                </div>
            </template>
            <div class="section">
                <h3 class="title">{{ upcomingTitle }}</h3>
                <div class="details">
                    <p v-if="service.cancelled" v-html="this.$i18n('my_account_subscription_cancelled_text')" />
                    <template v-else>
                        <template>
                            <p class="renewal" v-if="isRecurring" v-html="this.$i18n('my_account_subscription_renewal_text')" />
                            <p class="name" v-else>{{ subscriptionName }}</p>
                            <p class="price-period" v-if="pricePeriod" v-html="pricePeriod" />
                        </template>
                        <p class="footnote" v-if="appStoresChangeLabel">{{ appStoresChangeLabel }}</p>
                        <v-button v-else-if="service.enableCancellation" size="medium" :i18n="{ id: 'account_subscription_cancel' }" @click.native="cancelSubscriptionPrompt"/>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import i18nUtils from "Util/i18n.js";
    import VButton from "ViewComponent/v-button.vue";
    import Datetime from "Util/datetime.js";
    import { formatWithCurrency } from "Util/currency.js";
    import { clone, isArray } from 'lodash';
    import moment from "moment";

    export default {
        name: 'subs-item-single',
        components: { VButton },
        props: {
            service: {
                type: Object,
                required: true
            },
        },
        computed: {
            pricePeriod() {
                if (this.service && this.service.duration && this.service.subscription.price && this.service.subscription.price.amount) {
                    return this.getPricePeriod(this.service.subscription.price.amount, this.service.duration);
                }
            },
            upcomingTitle() {
                if (this.isRecurring) {
                    if (moment(this.service.active_until_ts).isAfter(moment(this.service.subscription.next_billing_ts)) && !this.service.is_on_free_trial) {
                        return this.service.expires_at_text;
                    } else if (this.service.formattedBillingDate) {
                        return i18nUtils.prop('my_account_date_future_title', { date: this.service.formattedBillingDate });
                    } else {
                        return this.service.expires_at_text;
                    }
                } else {
                    return (this.service.expires_at_text) ? this.service.expires_at_text : this.service.starting_from_text;
                }
            },
            activeSinceIsInTheFuture() {
                return moment(this.service.active_since_ts).isAfter(moment());
            },
            isRecurring() {
                return !!(this.service && this.service.subscription && this.service.subscription.is_recurring);
            },
            singlePeriodDiscount() {
                if (this.service && this.service.subscription && this.service.subscription.price && isArray(this.service.subscription.price.schedule)) {
                    let singlePeriodDiscountArray = [];
                    let hasSinglePeriodDiscount = false;
                    this.service.subscription.price.schedule.forEach(item => {
                        let _item = clone(item);
                        if (item.name && item.name === 'single-period-discount' || item.name === 'permanent-full') {
                            _item.price = formatWithCurrency(_item.amount, this.service.subscription.price.currency);
                            _item.startingTitle = this.startingTitle((item.starting_from_ts) ? item.starting_from_ts : this.service.active_since_ts);
                            singlePeriodDiscountArray.push(_item);
                        }
                        if (item.name && item.name === 'single-period-discount') {
                            hasSinglePeriodDiscount = true;
                        }
                    });
                    if (hasSinglePeriodDiscount && singlePeriodDiscountArray.length > 0) {
                        return singlePeriodDiscountArray;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
            subscriptionName() {
                if (this.service && this.service.duration) {
                    switch (this.service.duration) {
                        case 'P1M':
                            return i18nUtils.prop('my_account_subscription_monthly_subscription_text');
                        case 'P1Y':
                            return i18nUtils.prop('my_account_subscription_annual_subscription_text');
                        default:
                            return this.service.product_name
                    }
                } else {
                    return this.service.product_name;
                }
            },
            appStoresChangeLabel() {
                switch(this.service.payment_provider) {
                    case 'google-in-app':
                        return i18nUtils.prop('google-in-app', null, null, 'account_subscription_footer');
                    case 'apple-in-app':
                        return i18nUtils.prop('apple-in-app', null, null, 'account_subscription_footer');
                    default:
                        return null;
                }
            },
        },
        methods: {
            cancelSubscriptionPrompt() {
                if (this.service && this.service.subscription && this.service.subscription.uuid) {
                    this.$emit('cancel-subscription-prompt', this.service.subscription.uuid);
                }
            },
            startingTitle(ts) {
                const currentLocale = i18nUtils.currentLocale;
                const _date = moment(ts).locale(currentLocale).format('ll');
                return i18nUtils.prop('my_account_date_future_title', { date: _date });
            },
            getPricePeriod(amount, duration) {
                if (amount && duration && this.service.subscription && this.service.subscription.price) {
                    const durationVars = Datetime.getDurationVars(duration);
                    const priceWithCurrency = formatWithCurrency(amount, this.service.subscription.price.currency);

                    return i18nUtils.prop('my_account_subscription_future_payment_text', { amount: priceWithCurrency, ...durationVars });
                }
            },
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.subs-item-single {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        > div.section {
            > div.details {
                p {
                    &.footnote {
                        color: $colorSourceSecondary;
                    }
                    &.voucher {
                        display: inline-block;
                        padding: .25rem .5rem;
                        border-radius: pxToRem(4);
                        background-color: $colorBackgroundGray;
                        color: $colorSourceSecondary;
                        font-size: fontSize(12);
                    }
                }
            }
        }
    }
</style>
