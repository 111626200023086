var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3 6.5A3.5 3.5 0 016.5 3h4.111a1 1 0 110 2H6.5a1.5 1.5 0 100 3h2a3.5 3.5 0 110 7H4.389a1 1 0 110-2H8.5a1.5 1.5 0 000-3h-2A3.5 3.5 0 013 6.5zM17.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm1.68-1.572a3.5 3.5 0 10-.18 6.235v.837a1.5 1.5 0 01-2.7.9 1 1 0 10-1.6 1.2 3.5 3.5 0 006.3-2.1V9a1 1 0 00-1.82-.572zM6 17a1 1 0 011-1h.5a2.5 2.5 0 010 5H6a1 1 0 110-2h1.5a.5.5 0 000-1H7a1 1 0 01-1-1zM19.975 5.975A1 1 0 0018.56 4.56a1.5 1.5 0 01-2.122 0 1 1 0 10-1.414 1.414 3.5 3.5 0 004.95 0z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }