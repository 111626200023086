import $ from 'jquery';
import Backbone  from 'backbone';

import ControllerManager from '../../modules/controller.manager.js';

import { ConfirmationView, CONFIRM_ACTION } from './confirmation.view.js';

import URI from '../../util/uri.js';
import EventUtils from '../../util/event.js';
import renderTemplate from '../../util/template.renderer.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';
import i18nUtils from '../../util/i18n.js';


const ModalMessagesView = Backbone.View.extend({

    events: {
        'click button.close': 'close',
        'click button.payment': 'payment',
        'click button.decline': 'decline',
        'click div.close': 'close',
        'click a[data-action="navigate"]': 'navigate',
        'click a.close': 'close',

        'click a[data-action]': 'takeAction',
        'click a[data-uri]': 'navigateUri',
    },

    initialize: function (options) {
        console.log(`ModalMessages view init. Message="${options.data.message}"`);

        this._data = options.data;
        // NB! addDefaultParams only applies for the modal-upsell template
        this._data.addDefaultParams = this._data.addDefaultParams === undefined ? true : this._data.addDefaultParams;
        this._template = options.template;
        this._rendered = false;
    },

    remove: function () {
        this.unbind();
        Backbone.View.prototype.remove.call(this);
    },

    // Resolve when rendered
    render: function () {
        if (this._rendered) {
            return Promise.resolve(this);
        } else if (this._render_promise !== undefined) {
            return this._render_promise;
        } else {
            const template_name = this._template === 'modal-upsell' ? 'modal-upsell' : 'modal-messages';

            this._render_promise = Promise.resolve()
                .then(() => renderTemplate(template_name, this._data))
                .then(output => {
                    const $modal = $(output);
                    this.setElement($modal);
                    $('body').append($modal);
                })
                .then(() => this._postRender())
                .then(() => {
                    this._rendered = true;

                    delete this._render_promise;
                    return Promise.resolve(this);
                });

            return this._render_promise;
        }
    },

    // Cache references
    _postRender: function () {
        if (this._template === 'modal-upsell') {
            this.$modal = this.$('div.modal-upsell');
        } else {
            this.$modal = this.$('div.modal.modal-messages');
        }
        return Promise.resolve();
    },

    // Render and then show with fade
    show: function () {
        return Promise.resolve()
            .then(() => this.render())
            .then( () => {
                this.$modal.addClass('display');
                return new Promise(resolve => setTimeout(resolve, 25));
            })
            .then( () => {
                this.$modal.addClass('show');
            });
    },

    hide: function () {
        this.$modal.removeClass('show');
        return new Promise(resolve => {
            EventUtils.oneTransitionEnd(this.$modal, () => {
                this.$modal.removeClass('display');
                resolve();
            }, 500);
        });
    },

    close: function () {
        this.trigger('close', () => {
            EventUtils.oneTransitionEnd(this.$el, () => {
                this.remove();
            }, 500);

            this.$el.addClass('close');
        });
    },

    payment: function (event) {
        const $element = $(event.currentTarget);
        const paymentUuid = $element.data('payment-uuid');

        new URI(`lingvist:store?page=payment-extend&payment_uuid=${paymentUuid}`).navigateTo();
    },

    navigate: function (event) {
        var $target = $(event.currentTarget),
            navigateTarget = $target.data('target');

        if (navigateTarget === 'open-feedback') {
            ControllerManager.instance.getController('Feedback').show('feedback', 'account_and_settings');
        }
    },

    takeAction: function (event) {

        const $target = $(event.currentTarget);

        const id = $target.data('id');
        const action = $target.data('action');
        const uri = $target.data('uri');

        switch (action) {

            case 'open': {
                this.trigger('close', () => {
                    if (uri !== undefined) {
                        googleAnalyticsCommand('ec:setAction', 'promo_click');
                        googleAnalyticsCommand('send', 'event', this._data.type, 'click', 'popup');

                        new URI(uri).navigateTo();
                        this.close();
                    }
                });
                break;
            }

            case 'close': {

                const action = this._data.popup.actions[id];

                if (action.confirm) {
                    return Promise.resolve().then(() => this.hide())
                        .then(() => {
                            let confirmationView = new ConfirmationView({
                                title: i18nUtils.resolveMessage(action.confirm.title),
                                message: i18nUtils.resolveMessage(action.confirm.message),
                                actions: [
                                    {
                                        title: i18nUtils.prop('modal_confirmation_yes'),
                                        action: CONFIRM_ACTION.OK
                                    },
                                    {
                                        title: i18nUtils.prop('modal_confirmation_no'),
                                        action: CONFIRM_ACTION.CANCEL
                                    }
                                ]
                            }, {background: false});

                            confirmationView.on('confirm', action => {
                                if (action === CONFIRM_ACTION.OK) {
                                    this.close();
                                } else if (action === CONFIRM_ACTION.CANCEL) {
                                    this.show();
                                }
                                Promise.resolve()
                                    .then(() => confirmationView.hide())
                                    .then(() => {
                                        confirmationView.remove();
                                    });
                            });

                            confirmationView.show();
                        });
                } else {
                    this.close();
                }
                break;
            }

            case '_custom': {
                this.trigger('_custom');
                this.close();
                break;
            }
        }
    },

    navigateUri: function (event) {
        let uri = $(event.target).data('uri');
        if (uri) {
            new URI(uri).navigateTo();
        }
    },

    decline: function (event) {
        this.close();
    }
});

export default ModalMessagesView;
