var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-container", class: { disabled: this.disabled } },
    [
      _vm.disabled
        ? _c("locked-icon", { staticClass: "locked-icon" })
        : _vm._e(),
      _vm._v(" "),
      this.question_computed
        ? _c(
            "section",
            {
              staticClass: "card",
              class: { locked: this.locked, centered: this.isCentered },
            },
            [
              _c(
                "div",
                { staticClass: "card-header" },
                [
                  !this.on_previous &&
                  this.question_computed.isPlacementTestQuestion
                    ? _c("fast-tracking")
                    : _c("repeat-progress", {
                        attrs: {
                          question: this.question_computed,
                          answer: this.answer_computed,
                        },
                      }),
                  _vm._v(" "),
                  _c(
                    "card-more-dropdown",
                    _vm._g(
                      {
                        attrs: {
                          on_previous: this.on_previous,
                          question: this.question_computed,
                          "word-is-favourite": this.wordIsFavourite,
                        },
                      },
                      _vm.$listeners
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-main" },
                [
                  _c(
                    "card-form-spelling",
                    _vm._g(
                      {
                        attrs: {
                          question: this.question_computed,
                          answer: this.answer_computed,
                          "on-prevous": this.on_previous,
                          "auto-advance": this.autoAdvance,
                        },
                      },
                      _vm.$listeners
                    )
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "learn-content" },
                    [
                      _c(
                        "card-context",
                        _vm._g(
                          {
                            attrs: {
                              question: this.question_computed,
                              answer: this.answer_computed,
                              on_previous: this.on_previous,
                              disabled: this.disabled,
                              autoPlay: this.autoPlay,
                              "day-insights-shown": this.dayInsightsShown,
                              onlySynonymAnswers: this.onlySynonymAnswers,
                            },
                          },
                          _vm.$listeners
                        )
                      ),
                      _vm._v(" "),
                      this.cardHasVisuals
                        ? _c(
                            "card-visual",
                            _vm._g(
                              {
                                attrs: {
                                  question: this.on_previous
                                    ? this.previous_question
                                    : this.question,
                                  visuals: this.visuals,
                                },
                              },
                              _vm.$listeners
                            )
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [_c("card-synonym")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "extra" },
                    [
                      this.question_computed.parsed_grammar &&
                      this.question_computed.parsed_grammar.hasOwnProperty(
                        "fragments"
                      ) &&
                      this.question_computed.parsed_grammar.fragments.length > 0
                        ? _c("card-grammar", {
                            attrs: {
                              parsedGrammar:
                                this.question_computed.parsed_grammar,
                              full: false,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.toggleGrammar.apply(null, arguments)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-footer" },
                [
                  _c("card-context-translations", {
                    attrs: { question: _vm.question_computed },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }