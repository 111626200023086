
'use strict';

// Queues events on client side and syncs to backend

import _ from 'lodash';
import Raven from 'raven-js';
import moment from 'moment';
import AsyncDestroyable from './async.destroyable.js';
import { unauthenticated_api_client } from '../util/unauthenticated_api_client.js';
import { getPersistentStorageProvider } from './persistent.storage.provider.js';
import * as DatetimeUtils from "Util/datetime.js";

export default class EventQueue extends AsyncDestroyable {

    constructor (options, client, user = null) {
        super(['postEvent', 'sendEvent', 'sync']);
        this._client = client;
        this._user = user;

        if (!_.isObject(options)) {
            throw Error('An options object must be provided');
        }

        if (!_.isInteger(options.syncTimeout)) {
            throw Error('options.syncTimeout must be an integer');
        }

        if (!_.isInteger(options.maxQueueLength)) {
            throw Error('options.maxQueueLength must be an integer');
        }

        this._options = options;

        // Set up sync flow control variables
        this._syncing = false;
        this._queuedSync = null;
    }

    destroy () {
        let self = this;

        return Promise.resolve().then(function () {
            clearTimeout(self._syncTimer);
            return self._sync();
        }).then(function () {
            delete self._user;
            return Promise.resolve();
        });
    }

    sync () {
        // TODO: Add any necessary handling here
        return this._sync();
    }

    setOptions (options) {
        console.debug(`EventQueue:setOptions options=${JSON.stringify(options)}`);
        this._options = _.assign({}, this._options, options);
    }

    _syncPublic () {

    }

    _syncUser () {
        /*
               let self = this;

        if (this._syncing === true) {
            if (this._queuedSync !== null) {
                return this._queuedSync.promise;
            }

            let res, rej, syncPromise = new Promise(function(resolve, reject) {
                res = resolve;
                rej = reject;
            });

            this._queuedSync = {
                promise: syncPromise,
                resolve: res,
                reject: rej
            };

            return syncPromise;
        } else {
            this._syncing = true;

            return Promise.resolve().then(function () {
                return self._getEvents();
            }).then(function (events) {
                if (events.length > 0) {
                    console.debug(`Sending ${events.length} events.`);
                    let _apiClient = (self._user) ? self._user.getApiClient() : unauthenticated_api_client;
                    return _apiClient.r.events.post({
                        type: 'events/1.0', // TODO: define events API schema (somewhat defined in Entwurf/api-spec)
                        client_request_ts: moment().local().locale('en').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                        events: events
                    }).then(function () {
                        return Promise.resolve(events);
                    }).catch(function (error) { // TODO: Recovery from error scenarios?
                        Raven.captureException(error, {level: 'error',
                            extra: {message: "Error sending events (/api/events)"}}
                        );
                        return Promise.reject(error);
                    });
                } else {
                    return Promise.resolve([]);
                }
            }).then(function (syncedEvents) {
                return Promise.resolve().then(function () {
                    if (syncedEvents.length > 0) {
                        return self._removeEvents(syncedEvents);
                    } else {
                        return Promise.resolve();
                    }
                }).then(function () {
                    return Promise.resolve(syncedEvents);
                });
            }).then(function (syncedEvents) {
                if (self._user && self._user.hasActiveCourse()) {
                    if (syncedEvents.length > 0) {
                        self._user.getCourse().syncCourse();
                    }
                }
                return Promise.resolve();
            }).then(function () {
                self._syncing = false;

                if (self._queuedSync !== null) {
                    let queuedSync = self._queuedSync;
                    self._queuedSync = null;
                    self._sync().then(queuedSync.resolve, queuedSync.reject);
                }
            }).catch(function (error) {
                self._syncing = false;
                return Promise.reject(error);
            });
        }
         */
    }

    async _postEvents (events) {
        const req_ts = DatetimeUtils.getCurrentFormattedTs();
        const client_info = await this._client.getClientInfo(req_ts);
        if (this._user) {
            return this._user.getApiClient().r.events.post({
                type: 'events/1.0', // TODO: define events API schema (somewhat defined in Entwurf/api-spec)
                client_request_ts: req_ts,
                events: events,
                client: client_info,
            });
        } else {
            let unauthenticatedUserUUID = await this._client.getUnauthenticatedUserUUID();
            return unauthenticated_api_client.r.events.user.user_uuid(unauthenticatedUserUUID).post({
                type: 'events/1.0',
                client_request_ts: moment().local().locale('en').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                events: events,
                client: client_info,
            });
        }
    }

    _sync () {
        /**
         * Gets events form the storage provider attached to the user or not and posts them using the
         * api client (also attached to the user or not) returning a Promise which resolves when the sync
         * is complete.
         *
         * This function also synchronises itself - only one sync at a time is allowed to happen,
         * all the other calls are deferred and get the same Promise object as a response. The deferred
         * sync calls are resolved immediately after the success of the previous sync
         *
         * TODO: Error handling and retrying should be implemented in the API layer
         */

        let self = this;

        if (this._syncing === true) {
            if (this._queuedSync !== null) {
                return this._queuedSync.promise;
            }

            let res, rej, syncPromise = new Promise(function(resolve, reject) {
                res = resolve;
                rej = reject;
            });

            this._queuedSync = {
                promise: syncPromise,
                resolve: res,
                reject: rej
            };

            return syncPromise;
        } else {
            this._syncing = true;

            return Promise.resolve().then(function () {
                return self._getEvents();
            }).then(function (events) {
                if (events.length > 0) {
                    console.debug(`Sending ${events.length} events.`);
                    return self._postEvents(events).then(function () {
                        return Promise.resolve(events);
                    }).catch(function (error) { // TODO: Recovery from error scenarios?
                        Raven.captureException(error, {level: 'error',
                            extra: {message: "Error sending events (/api/events)"}}
                        );
                        return Promise.reject(error);
                    });
                } else {
                    return Promise.resolve([]);
                }
            }).then(function (syncedEvents) {
                return Promise.resolve().then(function () {
                    if (syncedEvents.length > 0) {
                        return self._removeEvents(syncedEvents);
                    } else {
                        return Promise.resolve();
                    }
                }).then(function () {
                    return Promise.resolve(syncedEvents);
                });
            }).then(function (syncedEvents) {
                if (self._user && self._user.hasActiveCourse()) {
                    if (syncedEvents.length > 0) {
                        self._user.getCourse().syncCourse();
                    }
                }
                return Promise.resolve();
            }).then(function () {
                self._syncing = false;

                if (self._queuedSync !== null) {
                    let queuedSync = self._queuedSync;
                    self._queuedSync = null;
                    self._sync().then(queuedSync.resolve, queuedSync.reject);
                }
            }).catch(function (error) {
                self._syncing = false;
                return Promise.reject(error);
            });
        }
    }

    _onTimer() {
        clearTimeout(this._syncTimer);
        this._sync();
    }

    _addEvent (event) {
        let self = this;

        // TODO: A QuotaExceededError is raised here if the LS is full. It should somehow be dealt with either here on one level higher..
        // THIS IS THE ERROR QuotaExceededError Failed to execute 'setItem' on 'Storage': Setting the value of 'PSP_STORAGE/d9414043-df50-4ab7-8283-ffaa7ce9806c/events/16' exceeded the quota.
        return Promise.resolve().then(function () {
            return self._setStorageItem(`events/${event.client_sn}`, event);
        });
    }

    _getEvents () {
        let self = this;

        return Promise.resolve().then(function () {
            return self._getStorageItemsByPrefix('events/');
        }).then(function (events) {
            return Promise.resolve(
                _(events)
                    .transform((result, value, key) => result.push(value), [])
                    .sortBy('client_sn').value()
            );

        });
    }

    _removeEvents (events) {
        let self = this;
        let eventRemovePromises = [];

        events.forEach(function (event) {
            eventRemovePromises.push(self._removeStorageItem(`events/${event.client_sn}`));
        });

        return Promise.all(eventRemovePromises);
    }

    _getStorage () {
        if (this._user) {
            return this._user.getStorage();
        } else {
            return getPersistentStorageProvider(null);
        }
    }

    _setStorageItem(key, value) {
        if (this._user) {
            return this._user.getStorage().setItem(key, value);
        } else {
            return getPersistentStorageProvider(null).setItemAnonymous(key, value);
        }
    }

    _removeStorageItem(key) {
        if (this._user) {
            return this._user.getStorage().removeItem(key);
        } else {
            return getPersistentStorageProvider(null).removeItemAnonymous(key);
        }
    }

    _getStorageItemsByPrefix(prefix) {
        if (this._user) {
            return this._user.getStorage().getItemsByPrefix('events/');
        } else {
            return getPersistentStorageProvider(null).getItemsAnonymousByPrefix('events/');
        }
    }

    // Queue and sync later
    postEvent (event) {
        let self = this;

        return Promise.resolve().then(function () {
            return self._addEvent(event);
        }).then(function () {
            return self._getEvents();
        }).then(function (events) {
            clearTimeout(self._syncTimer);

            // Synchronize immediately if there are too many events or the storage is not persistent
            if (!self._getStorage().isStoragePersistent() || events.length > self._options.maxQueueLength) {
                self._sync();
            } else {
                self._syncTimer = setTimeout(function () {
                    self._onTimer();
                }, self._options.syncTimeout);
            }

            // This function is async by design so even if the sync happens here this function returns before that
            return Promise.resolve(event);
        });
    }

    // Immediate send & sync
    sendEvent (event) {
        let self = this;

        return Promise.resolve().then(function () {
            return self._addEvent(event);
        }).then(function () {
            clearTimeout(self._syncTimer);
            return self._sync();
        });
    }
}
