// Google Analytics tracking script loader
'use strict';

import getConfigValue from './configuration.js';
import version from '../version.js';

// Load analytics.js asyncronously
(function(i,s,o,g,r,a,m) {
    i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();
    // set delay, so it would not compete other loading elements
    setTimeout(function () {
        a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];
        a.async=1;a.src=g;
        m.parentNode.insertBefore(a,m);
    }, 500);
})(window,document,'script','//www.google-analytics.com/analytics.js','__gaTracker');

// Load Google Tag Manager
// https://developers.google.com/tag-manager/quickstart
(function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),
        dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;
    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', getConfigValue('gtm') || 'GTM-PNSWKWK');  // 'GTM-PNSWKWK' DEV container

// Set up Property
var __gaTracker = window['__gaTracker'];

__gaTracker('create', getConfigValue('ga') || 'UA-47110788-1', 'auto', { 'allowLinker': true }); // 'UA-47110788-1' production property
__gaTracker('require', 'displayfeatures');
__gaTracker('require', 'linker');
__gaTracker('require', 'ec');  // Enhanced Ecommerce
__gaTracker('set', 'dataSource', 'app');
__gaTracker('set', 'appId', 'com.lingvist.learn');
__gaTracker('set', 'appName', 'Lingvist');
__gaTracker('set', 'appVersion', 'webapp ' + version.version);

export default function googleAnalyticsCommand () {
    window['__gaTracker'] && window['__gaTracker'].apply(window, arguments);
}

export let googleAnalyticsLoaded = false;

googleAnalyticsCommand(function (tracker) {
    // GA resolved with tracker - not blocked
    googleAnalyticsLoaded = true;
});

export function tagManagerCommand(command) {
    const dataLayer = window.dataLayer;

    if (dataLayer !== undefined) {
        dataLayer.push(command);
    }
}

