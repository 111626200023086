
'use strict';

import ExportUtils from './export.js';

export default class Cookie {
    static setCookie (name, value, options) {

        /**
         * options:
         *   {
         *     expires: moment().add(1, 'year'),
         *     domain: 'lingvist.com',
         *     secure: true
         *   }
         * */

        var expires = options && options.expires ? `;expires=${options.expires.toDate().toUTCString()}` : '',
            domain = options && options.domain ? `;domain=${options.domain}` : '',
            secure = options && options.secure ? `;secure` : '',
            c = `${name}=${encodeURIComponent(value)}${expires}${domain}${secure}`;

        console.debug(`Setting cookie="${c}"`);

        document.cookie = c;
    }

    static getCookie (name) {
        return document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1");
    }

    static removeCookie (name, options) {
        /**
         * options:
         *   {
         *     domain: 'lingvist.com'
         *   }
         * */

        var domain = options && options.domain ? `;domain=${options.domain}` : '',
            c = `${name}=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;

        console.debug(`Removing cookie="${c}"`);
        document.cookie = c;
    }
}

ExportUtils.export('app.util.Cookie', Cookie);
