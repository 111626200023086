<template>
    <div class="hint-mark" v-if="this.targetRect" :style="{ left: this.positionLeft, top: this.positionTop, width: `${this.size}px`, height: `${this.size}px` }" @click="close" @mouseover="closeWithDelay"/>
</template>

<script>
    export default {
        name: 'hint-mark',
        props: {
            targetElement: {
                required: true
            },
        },
        data() {
            return {
                targetRect: null,
                setTargetRectTimeout: null
            }
        },
        computed: {
            size() {
                return Math.round(this.targetRect.width * 2);
            },
            positionLeft() {
                return `${ this.targetRect.left + (this.targetRect.width / 2) - (this.size / 2)}px`;
            },
            positionTop() {
                return `${ this.targetRect.top  + (this.targetRect.height / 2)  - (this.size / 2)}px`;
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            closeWithDelay() {
                setTimeout(() => {
                    this.$emit('close');
                }, 300);
            },
            setTargetRect() {
                this.targetRect = this.targetElement.getBoundingClientRect();
            }
        },
        created() {
            window.addEventListener('resize', this.setTargetRect);
            this.setTargetRectTimeout = setTimeout(() => {
                this.setTargetRect();
            }, 250);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.setTargetRect);
            clearTimeout(this.setTargetRectTimeout);
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.hint-mark {
        position: fixed;
        border-radius: 50%;
        width: pxToRem(100);
        height: pxToRem(100);
        z-index: 10000;
        background-color: rgba($colorPurplePrimary, 0.20);
        box-shadow: 0 0 0 3px $colorPurplePrimary;
        animation: pulse-purple 1100ms infinite;

        @keyframes pulse-purple {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba($colorPurplePrimary, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba($colorPurplePrimary, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba($colorPurplePrimary, 0);
            }
        }
    }
</style>
