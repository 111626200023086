
import ControllerFactory from '../modules/controller.factory.js';
import Vue from 'vue';
import HubView from '../view/hub/hub.vue';

export class HubController {

    constructor () {
        this.state = {

        };
    }

    go (appView) {
        const HubViewComponent = Vue.extend(HubView);
        appView.setMainView(new HubViewComponent());
    }
}

export const hub_controller_factory = new ControllerFactory(HubController);
