<template>
    <span class="card-gap" :data-word="word.word" id="card-gap" :data-audio-hash="word.audio_hash" :style="{ minWidth: !on_previous ? gapMinWidth : 'unset' }" :class="{ wrong: this.wrongAnswer, correct: this.isCorrectAnswer, remembered: this.isRemembered, synonym: this.synonymAnswer, onPrevious: this.on_previous }">
      <autosize-input
        name="answer-input"
        className="answer-input"
        :hidden="answerVisible || isCorrectAnswer || isEquivalentAnswer"
        :disabled="inputDisabled"
        :maxWidth="gapMaxWidth"
        :minWidth="(gapMinWidth) ? gapMinWidth : '112px'"
        :extra-width="16"
        v-model="userAnswer"
        v-on:submit="submit"
        v-on:blur="blurCursorPosition"
        v-on:focus="() => $emit('gap-focus')"
        ref="autosizeInput"
       />
        <span class="word-container" v-show="isEquivalentAnswer || answerVisible || isCorrectAnswer || hintVisible || (hintVisible && synonymAnswer)" @click="hideAnswer(true)" :class="{ hintIsVisible: hintVisible && !answerVisible && !isCorrectAnswer }">
            <span v-for="(letterObj, index) in hintAsLetters" :index="index" :class="[ letterObj.correctness, isSpaceOrLetter(letterObj.letter) ]">{{ letterObj.letter }}</span>
        </span>
        <span class="hidden-word-container" ref="hiddenWordContainer">
            <span v-for="(letter) in wordAsLetters" :class="isSpaceOrLetter(letter)">{{ letter }}</span>
        </span>
    </span>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus";
    import AutosizeInput from 'ViewComponent/autosize-input.vue'
    import i18nUtils from "Util/i18n";
    import KeyCodes from "Util/keycodes";
    import { last } from "lodash";

    export default {
        name: 'card-gap',
        components: {
            AutosizeInput,
        },
        props: {
            word: {
                type: Object,
                required: true
            },
            answer: {
                type: Object
            },
            on_previous: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            autoPlay: {
                type: Boolean,
                default: false
            },
            dayInsightsShown: {
                type: Boolean
            },
            onlySynonymAnswers: {
                type: Boolean
            },
            isLastFocused: {
                type: Boolean,
                default: false
            },
            isFirstInstance: {
                type: Boolean
            },
        },
        data() {
            return {
                inputIsFocused: false,
                userAnswer: '',
                wrongAnswer: false,
                synonymAnswer: false,
                answerVisible: false,
                hintVisible: false,
                showAnswerTimeout: null,
                gapMinWidth: null,
                awaitingUserAnswerUpdate: false,
                awaitingUserAnswerUpdateTimeout: null,
                typingIdleTimeout: null,
                cursorPosition: null,
                voiceInputTimeout: null
            }
        },
        computed: {
            lastAnswer() {
                if (this.answer && this.answer.all_entries && this.answer.all_entries.length > 0) {
                    return last(this.answer.all_entries);
                }
            },
            lastAnswerEntry() {
                if (this.answer && this.answer._entry_events && this.answer._entry_events.length > 0) {
                    return last(this.answer._entry_events);
                }
            },
            isEquivalentAnswer() {
                let _isEquivalentAnswer = false;
                if (!this.on_previous && this.answer && this.answer.confirmed && this.answer.answer !== this.word.word) {
                    _isEquivalentAnswer = true;
                }
                return _isEquivalentAnswer;
            },
            correctEquivalentAnswer() {
                if (this.isEquivalentAnswer && this.lastAnswerEntry && this.lastAnswerEntry.hasOwnProperty('equivalent_answer')) {
                    return this.lastAnswerEntry.equivalent_answer;
                }
            },
            isCorrectAnswer() {
                let _isCorrectAnswer = false;
                if (this.answer && this.answer.confirmed) {
                    _isCorrectAnswer = true;
                }
                return _isCorrectAnswer;
            },
            isRemembered() {
                let _isRemembered = false;
                if (this.answer && this.answer.confirmed && (this.answer.all_entries.length === 1 || this.onlySynonymAnswers)) {
                    _isRemembered = true;
                }
                return _isRemembered;
            },
            gapMaxWidth() {
                let windowWidth = window.innerWidth;
                if (windowWidth < 1000) {
                    return `${ Math.round(windowWidth * 0.7) }px`;
                } else {
                    return '400px';
                }
            },
            wordAsLetters() {
                return (this.isEquivalentAnswer && this.correctEquivalentAnswer) ? this.correctEquivalentAnswer.split('') : this.word.word.split('');
            },
            hintAsLetters() {
                let _word = this.wordAsLetters;
                let _previousLetter = null;
                return _word.map((letter, index) => {
                    if (this.synonymAnswer && index === 0) { // first letter should be correct/visible on synonym hints, always
                        let letterObject = { letter: letter, correctness: 'correct' }
                        _previousLetter = letterObject;
                        return letterObject;
                    } else if (!this.synonymAnswer || _previousLetter === null || (_previousLetter && _previousLetter.correctness === 'correct')) {
                        let letterObject = { letter: letter, correctness: this.letterCorrectness(letter, index) }
                        _previousLetter = letterObject;
                        return letterObject;
                    }
                    return { letter: letter, correctness: 'unknown' };
                });
            },
            inputDisabled() {
                return this.answerVisible || this.disabled;
            }
        },
        methods: {
            updateOtherCardGap(event) {
                if (event.uid !== this._uid) {
                    this.userAnswer = event.changedAnswer;
                }
            },
            updateFromVoiceInput({ text, autoSubmit }) {
                if (text && text.length > 0) {
                    this.userAnswer = text;
                    if (autoSubmit) {
                        this.voiceInputTimeout = setTimeout(() => {
                            this.$emit('submit', { answer_text: this.userAnswer, answer_type: 'voice' });
                        }, 1000);
                    }
                }
            },
            blurCursorPosition(e) {
                this.cursorPosition = e.selectionStart;
                this.lastAutosizeInputInFocus = this.$refs.autosizeInput;
            },
            submit(e) {
                e.target.blur(); // remove focus from the gap input field (mandatory mainly for firefox to fix issues while hiding the input while it's in focus)
                this.$emit('submit', { answer_text: this.userAnswer, answer_type: 'written' });
            },
            resetUserAnswer() {
                this.userAnswer = '';
                this.$emit('user-answer-changed', this.userAnswer);
            },
            resetGapMinWidth() {
                this.gapMinWidth = null;
            },
            isSpaceOrLetter(letter) {
                if (letter === '' || letter === ' ') {
                    return 'is-space'
                } else {
                    return 'is-letter'
                }
            },
            hideAnswer(reset = false) {
                if (reset && this.wrongAnswer) {
                    this.resetUserAnswer();
                }
                this.answerVisible = false;
                this.clearShowAnswerTimeout();
            },
            revealAnswer(wrong) {
                if (!this.showAnswerTimeout) {
                    this.setGapMinWidth();
                    this.answerVisible = true;
                    if (wrong === true) {
                        this.wrongAnswer = true;
                    }
                    this.clearShowAnswerTimeout();
                    this.showAnswerTimeout = setTimeout(() => {
                        this.hideAnswer();
                        if (wrong === true) {
                            this.wrongAnswer = false;
                            this.resetUserAnswer();
                        }
                    }, 3000);
                }
            },
            clearShowAnswerTimeout() {
                clearTimeout(this.showAnswerTimeout);
                this.showAnswerTimeout = null;
            },
            reactToWrongAnswer() {
                this.revealAnswer(true);
            },
            reactToSynonymAnswer() {
                this.synonymAnswer = true;
                this.hintVisible = true;
                this.resetUserAnswer();
                setTimeout(() => {
                    this.synonymAnswer = false;
                    this.hintVisible = false;
                    this.focusGap();
                }, 3000);
            },
            reactToCorrectAnswer() {
                this.wrongAnswer = false;
                this.revealAnswer(false);
            },
            letterCorrectness(letter, index) {
                let _answer = ((this.synonymAnswer || this.isEquivalentAnswer) && this.answer && this.answer.all_entries.length > 0) ? this.lastAnswer : this.userAnswer;

                if (_answer && _answer.length > 0) {
                    const userAnswerLetters = _answer.trim().toLowerCase().split('');
                    return userAnswerLetters[index] === this.wordAsLetters[index].toLowerCase() ? 'correct' : 'wrong';
                } else {
                    return 'unknown';
                }
            },
            typeDiacritic(character) {
                this.cursorPosition = this.$refs.autosizeInput.getSelectionStart();
                if (this.isLastFocused) {
                    this.userAnswer = this.userAnswer.slice(0, this.cursorPosition) + character + this.userAnswer.slice(this.cursorPosition);
                    this.focusGap();
                }

            },
            focusGap() {
                if (this.isLastFocused && this.lastAutosizeInputInFocus) {
                    this.lastAutosizeInputInFocus.focusInput();
                }
                else if (this.$refs.autosizeInput && !this.dayInsightsShown && this.isFirstInstance) {
                    this.$refs.autosizeInput.focusInput();
                }
            },
            unFocusGap() {
                if (this.$refs.autosizeInput) {
                    this.$refs.autosizeInput.blurInput();
                }
            },
            actForQuestionReady() {
                this.hideAnswer();
                this.resetUserAnswer();
                this.resetGapMinWidth();
                clearTimeout(this.typingIdleTimeout);
                if (this.autoPlay || this.answer.confirmed) {
                    this.setGapMinWidth();
                }
                if (!this.dayInsightsShown) {
                    setTimeout(() => {
                        this.focusGap();
                    }, 200)
                }
            },
            setGapMinWidth() {
                const padding = 14;
                let $hiddenWordContainer = this.$refs.hiddenWordContainer;
                if ($hiddenWordContainer) {
                    this.gapMinWidth = $hiddenWordContainer.offsetWidth + padding + 'px';
                }
            },
            globalKeyDown(e) {
                if (e && e.keyCode !== KeyCodes.ENTER && this.answerVisible) {
                    if (e.key.length === 1) { // not the smartest way to detect if pressed key is a letter
                        e.preventDefault();
                        this.userAnswer = e.key;
                    } else if (e.keyCode !== i18nUtils.getKeyCode('next')) {
                        this.resetUserAnswer();
                        this.hideAnswer();
                    }
                }
                this.hintVisible = false;
            },
            setHintVisible(_userAnswer) {
                if (!this.onlySynonymAnswers && this.answer && this.answer.opened && (_userAnswer.length > 0 && _userAnswer.length !== this.word.word.length && this.word.word.startsWith(_userAnswer))) {
                    clearTimeout(this.typingIdleTimeout);
                    this.typingIdleTimeout = setTimeout(() => {
                        this.hintVisible = true;
                    }, 3000);
                } else {
                    clearTimeout(this.typingIdleTimeout);
                    this.hintVisible = false;
                }
            },
            listenEventBus() {
                EventBus.$on('guess:reveal', this.revealAnswer);
                EventBus.$on('guess:final-answer-submit', this.reactToCorrectAnswer);
                EventBus.$on('guess:wrong-answer', this.reactToWrongAnswer);
                EventBus.$on('guess:synonym-answer', this.reactToSynonymAnswer);
                EventBus.$on('guess:type-diacritic', this.typeDiacritic);
                EventBus.$on('guess:question-ready', this.actForQuestionReady);
                EventBus.$on('guess:focus-gap', this.focusGap);
                EventBus.$on('guess:show-day-insights', this.unFocusGap);
                EventBus.$on('guess:user-answer-changed', this.updateOtherCardGap);
                EventBus.$on('guess:voice-input', this.updateFromVoiceInput);
            },
            unListenEventBus() {
                EventBus.$off('guess:reveal', this.revealAnswer);
                EventBus.$off('guess:final-answer-submit', this.reactToCorrectAnswer);
                EventBus.$off('guess:wrong-answer', this.reactToWrongAnswer);
                EventBus.$off('guess:synonym-answer', this.reactToSynonymAnswer);
                EventBus.$off('guess:type-diacritic', this.typeDiacritic);
                EventBus.$off('guess:question-ready', this.actForQuestionReady);
                EventBus.$off('guess:focus-gap', this.focusGap);
                EventBus.$off('guess:show-day-insights', this.unFocusGap);
                EventBus.$off('guess:user-answer-changed', this.updateOtherCardGap);
                EventBus.$off('guess:voice-input', this.updateFromVoiceInput);
            },
        },
        watch: {
            userAnswer(newValue) {
                EventBus.$emit('guess:user-answer-changed', { uid: this._uid, changedAnswer: this.userAnswer });
                if (!this.awaitingUserAnswerUpdate) {
                    this.hideAnswer();
                    this.awaitingUserAnswerUpdateTimeout = setTimeout(() => {
                        this.$emit('user-answer-changed', this.userAnswer);
                        this.awaitingUserAnswerUpdate = false;
                    }, 1000);
                }
                this.awaitingUserAnswerUpdate = true;

                // show hint when user has been idle for 2 seconds and has reveled the answer before
                if (!this.synonymAnswer) {
                    this.setHintVisible(newValue);
                }
            },
            answerVisible(newValue) {
                if (!newValue && this.$refs.autosizeInput) {
                    setTimeout(() => {
                        this.focusGap();
                    }, 20);
                }
            },
            on_previous(newValue) {
                if (!newValue) {
                    setTimeout(() => {
                        this.focusGap();
                    }, 20);
                }
            }
        },
        mounted() {
            this.focusGap();
            if (this.autoPlay) {
                this.userAnswer = this.word.word;
            }
            document.addEventListener('keydown', this.globalKeyDown);
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            this.unListenEventBus();
            this.clearShowAnswerTimeout();
            document.removeEventListener('keydown', this.globalKeyDown);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    span.card-gap {
        display: inline-block;
        position: relative;
        padding: 0.25rem 0;
        background-color: $colorBackgroundGray;
        border-radius: 4px;
        line-height: 1em;
        body[data-interface-language="ar"] & {
            font-family: $targetLanguageFonts;
        }

        > * {
            margin: 0 0.25em;
        }
        > span.word-container,
        > span.hidden-word-container {
            box-sizing: content-box;
            position: absolute;
            white-space: nowrap;
            display: inline-flex;
            flex-direction: row;
            left: 0;
            > span {
                &.is-space {
                    content: '\a0';
                    min-width: .2em;
                }
            }
        }

        > span.word-container {
            top: 50%;
            transform: translateY(-50%);
            user-select: none;
            opacity: 0.5;
            cursor: text;
            &.hintIsVisible {
                > span {
                    &.correct {
                        color: transparent;
                    }
                }
            }
        }

        &.synonym {
            > span.word-container {
                &.hintIsVisible {
                    > span {
                        color: transparent;
                        &.correct {
                            color: $colorAttention;
                        }
                    }
                }
            }
        }

        > span.hidden-word-container {
            visibility: hidden;
            pointer-events: none;
        }

        &.wrong {
            > span.word-container:not(.hintIsVisible) {
                animation-name: incorrectAnswer;
                animation-duration: 900ms;
                > span {
                    &.correct,
                    &.unknown {
                        color: $colorTargetPrimary;
                    }
                    &.wrong {
                        color: $colorIncorrect;
                    }
                }
            }
        }

        &.correct {
            color: $colorTargetPrimary;
            background-color: #E0EBEF;

            > span.word-container {
                cursor: pointer;
                color: $colorTargetPrimary;
                > span {
                    &.wrong {
                        color: $colorIncorrect;
                    }
                }
            }
        }

        &.remembered {
            background-color: lighten($colorCorrect, 42%);

            > span.word-container {
                cursor: pointer;
                color: $colorCorrect;
            }
        }

        &.synonym {
            background-color: rgba($colorAttention, 0.2);

            > span.word-container {
                cursor: pointer;
                color: $colorAttention;
            }
        }

        > span.autosize-input {
            &.hidden {
                visibility: hidden;
                margin: 0;
            }
            > input.answer-input {
                display: inline;
                color: $colorTargetPrimary;
                margin: 0;
                //padding: 0 0.25em;
                padding: 0;

                &:disabled {
                    visibility: hidden;
                }

                &::-ms-clear {
                    display: none;
                }
            }
        }

        &.onPrevious {
            background-color: transparent;
            > span.word-container {
                margin: 0;
                transform: none;
                position: relative;
                top: unset;
                left: unset;
                bottom: unset;
                right: unset;
            }
            > span.autosize-input {
                > input.answer-input {
                    display: none;
                }
            }
        }

        @keyframes incorrectAnswer {
            0% {
                opacity: 0.6;
                color: $colorTargetPrimary;
            }

            50% {
                opacity: 1;
                color: $colorIncorrect;
            }

            100% {
                opacity: 0.6;
                color: $colorTargetPrimary;
            }
        }
    }
</style>
