var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M18 6L6 18M13.553 5.239c-1.38-.432-2.845-.275-4.134.442-1.289.716-2.316 1.945-2.898 3.466a8.086 8.086 0 00-.276 4.832c.404 1.598 1.283 2.976 2.479 3.885 1.196.91 2.63 1.292 4.046 1.078 1.416-.214 2.72-1.01 3.679-2.245.958-1.235 1.507-2.828 1.548-4.493.042-1.665-.427-3.293-1.321-4.59",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }