
'use strict';

import _ from 'lodash';

export class LexicalUnitDataCache {
    // TODO: Persist LU data cache and use also for the GuessQueue questions
    constructor () {
        this._data = {};
    }

    getLexicalUnitData (lexical_unit_uuid) {
        return this._data[lexical_unit_uuid] || null;
    }

    setLexicalUnitData (lexical_unit_uuid, data) {
        this._data[lexical_unit_uuid] = _.clone(data);
    }

    destroy () {
        delete this._data;
        return Promise.resolve();
    }
}
