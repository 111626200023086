var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { editable: true, "with-counter": !!_vm.maxLength } },
    [
      _vm.maxLength
        ? _c("span", { staticClass: "length-counter" }, [
            _vm._v(_vm._s(_vm.lengthCounter)),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isInFocus
        ? _c(
            "span",
            {
              staticClass: "edit-icon",
              on: {
                click: function ($event) {
                  return _vm.$refs.editable.focus()
                },
              },
            },
            [_c("edit-icon")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        ref: "editable",
        class: {
          "editable-content": true,
          nowrap: this.nowrap,
          "no-padding": this.noPadding,
        },
        attrs: { contenteditable: "" },
        on: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.onEnter.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.onEsc.apply(null, arguments)
            },
            _vm.onInput,
          ],
          input: _vm.onInput,
          paste: _vm.onInput,
          blur: _vm.onBlur,
          focus: _vm.onFocus,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }