var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "23",
          height: "18",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0 3a3 3 0 013-3h17a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v12a1 1 0 001 1h17a1 1 0 001-1V3a1 1 0 00-1-1H3z",
          fill: "#000",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3.194 4.909a1 1 0 011.397-.215L11.5 9.76l6.909-5.066a1 1 0 011.182 1.612L11.5 12.24 3.409 6.306a1 1 0 01-.215-1.397z",
          fill: "#000",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }