import moment from 'moment';
import Raven from 'raven-js';
import Backbone from 'backbone';
import iso_duration from 'iso8601-duration';
import googleAnalyticsCommand from '../util/google-analytics.js';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';

import AccountView from '../view/account/account.view.js';
import ProfileTabView from '../view/account/profile.tab.view.js';
import SubscriptionTabView from '../view/account/subscription.tab.view.js';

import i18nUtils, { getDurationSecondsFromTs, getDurationFormattedFromTs } from '../util/i18n.js';

import { SUBSCRIPTION_STATUS } from '../modules/subscription.js';
import getConfigValue from '../util/configuration.js';

export const ERROR_CODE = {
    LIMITED: 'limited',
    UNKNOWN: 'unknown'
};

export const TAB_NAME = {
    PROFILE: 'profile',
    SUBSCRIPTION: 'subscription'
};

export class AccountController {

    constructor () {
        this._activeTabName = null;
    }

    _activateProfileTab (accountView) {
        const profileView = new ProfileTabView();
        profileView.setProps(this.getProfileTabData());
        profileView.render();

        profileView.on('download-data', () => {
            UserManager.instance.getUser().getEventSender().sendDataDownloadRequestEvent();
        });

        accountView.setContent(TAB_NAME.PROFILE, profileView.el);
    }

    _activateSubscriptionTab (accountView) {
        const subscriptionTabView = new SubscriptionTabView();

        subscriptionTabView.on('cancel-subscription', subscription_uuid => {
            Backbone.trigger('showLoader', 'account/subscriptions');
            Promise.resolve()
                .then(() => UserManager.instance.getUser().getSubscription().cancelSubscription(subscription_uuid))
                .then(() => this.getSubscriptionTabData())
                .then(subscriptionTabData => {
                    subscriptionTabView.setProps(subscriptionTabData);
                    return subscriptionTabView.render();
                })
                .then(() => Backbone.trigger('hideLoader'))
                .catch(() => Backbone.trigger('hideLoader'));
        });

        Promise.resolve()
            .then(() => this.getSubscriptionTabData())
            .then(subscriptionTabData => {
                subscriptionTabView.setProps(subscriptionTabData);
                subscriptionTabView.render();
                accountView.setContent(TAB_NAME.SUBSCRIPTION, subscriptionTabView.el);
            }).catch(error => {
                Raven.captureException(error, {level: 'error'});
            });
    }

    _activateTabByName (accountView, tabName) {
        switch (tabName) {
            case TAB_NAME.PROFILE: {
                this._activateProfileTab(accountView);
                break;
            }
            case TAB_NAME.SUBSCRIPTION: {
                this._activateSubscriptionTab(accountView);
                break;
            }
            default: {
                console.error(`Unknown tab: ${tabName}`);
            }
        }
    }

    go (appView, router, tab = null) {
        return Promise.resolve().then(() => {
            this._activeTabName = tab || TAB_NAME.PROFILE;

            const accountView = new AccountView();
            appView.setMainView(accountView, true);

            accountView.setProps(this.getAccountViewData());

            accountView.on('activate-tab', tabName => {
                this._activeTabName = tabName;

                this._activateTabByName(accountView, this._activeTabName);

                UserManager.instance.getUser().getEventSender().sendNavigationEvent('account', 'open', tabName);
                googleAnalyticsCommand('send', 'pageview', `/account/${tabName}`);
                googleAnalyticsCommand('send', 'screenview', { 'screenName': `account/${tabName}` });
            });

            accountView.on('rendered', () => {
                this._activateTabByName(accountView, this._activeTabName);
            });

            accountView.render();
        });
    }

    getAccountViewData () {

        const user = UserManager.instance.getUser();
        const subscription = user.getSubscription();
        const subscriptionStatus = subscription.getStatus();
        const coursesDisabled = !subscription.isSubscriptionActive();

        let data = {
            subscriptionEnabled: subscriptionStatus.status !== SUBSCRIPTION_STATUS.DISABLED,
            coursesDisabled
        };

        if (user.hasCourse()) {
            const PRODUCT_DISCLAIMER_LINK_TEMPLATE = [
                {role: 'text', value: '<a target="_blank" href="'},
                {role: 'uri'},
                {role: 'text', value: '">'},
                {role: 'linktext'},
                {role: 'text', value: '</a>'}
            ];

            data.productDisclaimer = UserManager.instance.getUser().getCourse().getInfo().disclaimer;
            data.productDisclaimerLinkTemplate = PRODUCT_DISCLAIMER_LINK_TEMPLATE;
        }

        return data;
    }

    getProfileTabData () {
        const user = UserManager.instance.getUser();
        const subscription = user.getSubscription();
        const subscriptionStatus = subscription.getStatus().status;

        return {
            name: user.profile.name,
            email: user.profile.email,
            emailVerified: user.getAuthentication().email_verified,
            passwordSet: user.getAuthentication().is_password_set(),
            marketing_opt_in: user.profile.marketing_opt_in,
            subscriptionStatus,
            isLimitFreeInput: getConfigValue('limit-user-free-input')
        };
    }

    getSubscriptionTabData () {

        const user = UserManager.instance.getUser();
        const subscription = user.getSubscription();
        const subscriptionStatus = subscription.getStatus();
        const trialDays = subscription.getTrialDurationAsDays();
        const trialSpent = subscriptionStatus && subscriptionStatus.status === 'free';
        let trialInfo = {
            seconds: 'XX',
            formatted: 'XX'
        };

        return Promise.resolve().then( () => {
            if (subscriptionStatus.status === SUBSCRIPTION_STATUS.INITIAL_TRIAL) {
                if (subscriptionStatus.expiration_ts) {
                    trialInfo = {
                        seconds: getDurationSecondsFromTs(subscriptionStatus.expiration_ts),
                        formatted: getDurationFormattedFromTs(subscriptionStatus.expiration_ts)
                    };
                }

                return Promise.resolve({ trialInfo });

            } else if (subscription.isSubscriptionActive()) {

                return Promise.resolve().then( () => {
                    return user.getPayApi().getUserServices();
                }).then(response => {

                    let userHasRecurringService = false;
                    let userCanManageSubscription = false;
                    let userHasLifetimeService = false;
                    let paymentProvider = null;
                    let services = [];

                    if ('services' in response) {
                        services = response.services.map(service => {

                            service.isLifeTimeService = service.product_name === 'unlimited-lifetime';
                            userHasLifetimeService = !!(service.isLifeTimeService);
                            const currentLocale = i18nUtils.currentLocale;

                            if (service.subscription.is_recurring) {

                                // For active subscription's service, show message about periodic payments
                                if (service.subscription.status === 'active') {

                                    const duration = iso_duration.parse(service.subscription.period);
                                    const months = duration.years * 12 + duration.months;

                                    service.intervalMonths = months;
                                    service.formattedPrice = `${service.subscription.price.currency} ${parseFloat(service.subscription.price.amount).toFixed(2)}`;
                                    service.formattedBillingInterval =  i18nUtils.prop('months', { months: months }, null, 'time' , null);
                                    service.formattedBillingDate = moment(service.subscription.next_billing_ts).locale(currentLocale).format('ll');

                                    service.isRecurringAndActive = true;
                                    userHasRecurringService = true;

                                    paymentProvider = service.payment_provider;
                                    userCanManageSubscription = (paymentProvider === 'braintree');

                                    // For non-active subscription's service, show when the service expires.
                                } else {
                                    service.formattedExpireDate = moment(service.active_until_ts).locale(currentLocale).format('ll');
                                }

                                // Show cancel button for Braintree provided subscriptions that are active or past due
                                if (['active', 'past due', 'trial'].includes(service.subscription.status)) {
                                    if (['braintree'].includes(service.payment_provider)) {
                                        service.enableCancellation = true;
                                    }
                                }

                            } else {
                                if (service.isLifeTimeService) {
                                    service.formattedExpireDate = null;
                                } else {
                                    service.formattedExpireDate = moment(service.active_until_ts).locale(currentLocale).format('ll');
                                }
                            }

                            return service;
                        });
                    }

                    return Promise.resolve({
                        services,
                        userHasRecurringService,
                        userCanManageSubscription,
                        userHasLifetimeService,
                        paymentProvider
                    });
                });
            } else {
                return Promise.resolve()
                    .then(() => subscription.hasExpiredSubscription())
                    .then(hasExpiredSubscription => {
                        if (hasExpiredSubscription) {
                            return Promise.resolve({ hasExpiredSubscription });
                        } else {
                            Backbone.history.navigate('subscriptions', { trigger: true });
                            return false;
                        }
                    });
            }

        }).then( info => {
            let data = {
                subscriptionStatus,
                trialSpent,
                trial_days: trialDays,
                trialInfo: (info && info.hasOwnProperty('trialInfo')) ? info.trialInfo : null,
                services: info.services,
                hasExpiredSubscription: (info.hasExpiredSubscription) ? info.hasExpiredSubscription : false,
                hasActiveSubscription: subscription.isSubscriptionActive(),
                userHasRecurringService: info.userHasRecurringService,
                userCanManageSubscription: info.userCanManageSubscription,
                userHasLifetimeService: info.userHasLifetimeService,
                paymentProvider: info.paymentProvider
            };

            return Promise.resolve(data);
        });
    }
}

export const account_controller_factory = new ControllerFactory(AccountController);
