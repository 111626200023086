var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "expand-button",
      attrs: {
        title: this.expanded
          ? this.$i18n("home_drawer_minimise")
          : this.$i18n("home_drawer_expand"),
      },
    },
    [this.expanded ? _c("collapse-icon") : _c("expand-icon")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }