import $ from 'jquery';
import Backbone from 'backbone';
import EventUtils from '../../util/event.js';

const OverlayView = Backbone.View.extend({

    _options: {},

    events: {
        'click': 'dismiss',
        'dblclick': 'dismiss'
    },

    initialize: function (options) {
        this._options = options;
        console.log("OverlayView view init");
        this.setElement($('<div class="overlay"></div>'));
    },

    remove: function () {
        var self = this;

        EventUtils.oneTransitionEnd(this.$el, function () {
            $('main#app-root').removeClass('overlay-open');
            self.unbind();
            Backbone.View.prototype.remove.call(self);
        }, 500);

        this.$el.removeClass('active');
    },

    dismiss: function () {
        this.trigger('dismiss');

        if (typeof this._options === 'object' && !this._options.noDismiss) {
            console.log("OverlayView: dismissed");
            this.remove();
            this.trigger('dismissed');
        }
    },

    render: function (options) {
        var self = this;

        if (options) {
            $.extend(this._options, options);
        }

        $('body').prepend(this.$el);
        $('main#app-root').addClass('overlay-open');

        setTimeout( function () {
            self.$el.addClass('active');
        }, 16);

        return this;
    }
});

export default OverlayView;
