var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wordlist-filters" },
    [
      this.inverted
        ? _c("v-button", {
            attrs: {
              i18n: {
                id: "dashboard_word_list_sorting_menu_target_language",
                params: { tl: _vm.course.info.target_language },
              },
              filled: true,
              color: "purple-primary",
              size: "medium",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleLanguage.apply(null, arguments)
              },
            },
          })
        : _c("v-button", {
            attrs: {
              i18n: {
                id: "dashboard_word_list_sorting_menu_source_language",
                params: { sl: _vm.course.info.source_language },
              },
              filled: true,
              color: "gray",
              size: "medium",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleLanguage.apply(null, arguments)
              },
            },
          }),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      _c("v-button", {
        attrs: {
          html: _vm.mutedButtonLabel,
          filled: true,
          color: this.showMuted ? "purple-primary" : "gray",
          size: "medium",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.toggleMuted.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("v-button", {
        attrs: {
          html: _vm.favouriteButtonLabel,
          filled: true,
          color: this.showFavourites ? "purple-primary" : "gray",
          size: "medium",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.toggleFavourites.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("v-button", {
        attrs: {
          html: _vm.playlistButtonLabel,
          filled: true,
          color: this.showInPlaylist ? "purple-primary" : "gray",
          size: "medium",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.togglePlaylist.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      _c("v-dropdown", {
        attrs: {
          title: _vm.sortByDropdownTitle,
          activeItem: _vm.sortBy,
          "button-class": "gray",
          size: "medium",
          dropdown: _vm.sortDropdownItems,
        },
        on: { action: _vm.sortByAction },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }