var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { "fill-rule": "nonzero", fill: "none" } }, [
        _c("path", {
          attrs: {
            fill: "#FFCC4D",
            d: "M32 16c0 8.836-7.164 16-16 16S0 24.836 0 16 7.164 0 16 0s16 7.164 16 16",
          },
        }),
        _c("ellipse", {
          attrs: {
            cx: "10.222",
            cy: "14.667",
            fill: "#664500",
            rx: "2.222",
            ry: "3.111",
          },
        }),
        _c("ellipse", {
          attrs: {
            cx: "21.778",
            cy: "14.667",
            fill: "#664500",
            rx: "2.222",
            ry: "3.111",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#664500",
            d: "M22.222 23.111H9.778a.889.889 0 110-1.778h12.444a.888.888 0 110 1.778z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }