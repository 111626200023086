var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c("div", { staticClass: "ft-modal" }, [
        _c("div", { staticClass: "ft-modal-window" }, [
          _c(
            "div",
            { staticClass: "ft-animation" },
            [
              _c("lottie-animation", {
                attrs: { animationData: this.ftAnim, loop: true },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.page === "intro"
                  ? _c("section", [
                      _c("h2", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "guess_game_onboarding_fasttracking_ask_grn3160_title",
                              { args: { tl: this.target_language } }
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "text",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "guess_game_onboarding_fasttracking_ask_grn3160_text"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _c("v-button", {
                            attrs: {
                              i18n: {
                                id: "guess_game_onboarding_fasttracking_ask_grn3160_btn_no",
                              },
                              filled: false,
                              color: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.navigateToPage("beginning")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("v-button", {
                            class: { brain: this.thinkingBrain },
                            attrs: {
                              i18n: {
                                id: "guess_game_onboarding_fasttracking_ask_grn3160_btn_yes",
                              },
                              filled: true,
                              color: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.navigateToPage("placement-test")
                              },
                              mousedown: function ($event) {
                                return _vm.handleMouseDown.apply(
                                  null,
                                  arguments
                                )
                              },
                              mouseup: function ($event) {
                                return _vm.handleMouseUpLeave.apply(
                                  null,
                                  arguments
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.handleMouseUpLeave.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.page === "placement-test"
                  ? _c("section", [
                      _c("h2", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "guess_game_onboarding_fasttracking_test_grn3160_title"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "text",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "guess_game_onboarding_fasttracking_test_grn3160_text"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _c("v-button", {
                            attrs: {
                              i18n: {
                                id: "guess_game_onboarding_fasttracking_details_grn3160_btn_back",
                              },
                              filled: false,
                              color: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.navigateToPage("intro")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("v-button", {
                            class: { brain: this.thinkingBrain },
                            attrs: {
                              i18n: {
                                id: "guess_game_onboarding_fasttracking_details_grn3160_btn_continue",
                              },
                              filled: true,
                              color: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.startFastTracking.apply(
                                  null,
                                  arguments
                                )
                              },
                              mousedown: function ($event) {
                                return _vm.handleMouseDown.apply(
                                  null,
                                  arguments
                                )
                              },
                              mouseup: function ($event) {
                                return _vm.handleMouseUpLeave.apply(
                                  null,
                                  arguments
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.handleMouseUpLeave.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.page === "beginning"
                  ? _c("section", [
                      _c("h2", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "guess_game_onboarding_fasttracking_beginner_grn3160_title"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "text",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "guess_game_onboarding_fasttracking_beginner_grn3160_text",
                              {
                                args: {
                                  general_variation_units: this.words_count,
                                },
                              }
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _c("v-button", {
                            attrs: {
                              i18n: {
                                id: "guess_game_onboarding_fasttracking_details_grn3160_btn_back",
                              },
                              filled: false,
                              color: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.navigateToPage("intro")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("v-button", {
                            attrs: {
                              i18n: {
                                id: "guess_game_onboarding_fasttracking_details_grn3160_btn_continue",
                              },
                              filled: true,
                              color: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.startBeginning.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }