
'use strict';

// import $ from 'jquery';
import Backbone from 'backbone';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import { UserCourse } from '../modules/course/user.course.js';

import UserModel from '../model/user.model.js';

import DebugView from '../view/debug/debug.view.js';
import TickerView from '../view/debug/ticker.view.js';
import DebugViewOpeningView from '../view/debug/debug.view.opening.view.js';

import ControllerManager from '../modules/controller.manager.js';
import {NAME as USER_PARAMETER, TYPE as PARAMETER_TYPE} from '../modules/user.parameters.js';
import { EventBus } from "Util/vue-event-bus";


export class DebugController {

    constructor () {
        var self = this;

        Backbone.on('userSignedIn',
            /**
             * @param {User} user - initialized User object
             */
            user => {
                self.showDebugViewOpeningView(user.getInfo().email);

                return Promise.resolve()
                    .then(() => UserManager.instance.getUser().getStorage().getItem('debug/guess_notifications/ticker_enabled'))
                    .then(ticker_enabled => {
                        this._toggleTicker(ticker_enabled);
                    });
            });

        Backbone.on('signoutStart', () => {
            self.closeDebugViewOpeningView();

            return Promise.resolve().then(() => this._toggleTicker(false));
        });

        Backbone.on('rendered guess-queue-updated', () => {
            this._updateTicker();
        });
    }

    loadDebugLexicalUnits (lexicalUnitsUUIDs) {
        return Promise.resolve().then(function () {
            if (lexicalUnitsUUIDs.length !== 0) {
                return UserManager.instance.getUser().getCourse().loadLexicalUnits(lexicalUnitsUUIDs);
            } else {
                return Promise.resolve();
            }
        }).then(function () {
            if(UserManager.instance.getUser().getCourse().getGuessQueue().switchToDebugIfPossible()) {
                return ControllerManager.instance.getController('Guess').reSyncGuess();
            }
            return Promise.resolve();
        });
    }

    resetGuessQueue () {
        return Promise.resolve().then(function () {
            return UserManager.instance.getUser().getCourse().reset();
        })
            .then(() => ControllerManager.instance.getController('Guess').reSyncGuess());
    }

    startAutoPlay (probability, renderAutoPlayStop) {
        console.info(`DebugController: Starting auto play`);


        EventBus.$emit('guess:debug-autoplay-start', probability);

        renderAutoPlayStop(function () {
            EventBus.$emit('guess:debug-autoplay-stop');
            console.info('DebugController: Stopping auto play.');
        });
    }

    // startAutoPlay (delay, probability, renderAutoPlayStop) {
    //     console.info(`DebugController: Starting auto play with delay="${delay}"`);
    //
    //     // TODO: Refactor auto-play to a separate module
    //     var playing = true;
    //     $('input.answer-input').val(String(window.app.lib.$('.input-container').data('word')));
    //     $('div.navigate.next').click();
    //
    //     Backbone.on('rendered', function (view) {
    //
    //         if (view.name === 'guess' && playing) {
    //             setTimeout(function () {
    //
    //                 const knowsThisWord = Math.random();
    //
    //                 const correctAnswer = String($('.input-container').data('word'));
    //                 const $inputBox = $('input.answer-input');
    //                 const $nextButton = $('div.navigate.next');
    //
    //                 if (knowsThisWord > probability) {
    //                     $inputBox.val(correctAnswer);
    //                     $nextButton.click();
    //                 } else {
    //                     $inputBox.val('');
    //                     $nextButton.click();
    //
    //                     setTimeout( () => {
    //                         $inputBox.val(correctAnswer);
    //                         $nextButton.click();
    //                     }, 1000);
    //                 }
    //             }, delay);
    //         }
    //     });
    //
    //     renderAutoPlayStop(function () {
    //         playing = false;
    //         console.info('DebugController: Stopping auto play.');
    //     });
    // }

    showDebugView () {
        let debugView = new DebugView(),
            storage = UserManager.instance.getUser().getStorage();

        return Promise.resolve().then(() => Promise.all([
            storage.getItem('debug/guess_notifications/ticker_enabled'),
            storage.getItem('debug/guess_notifications/notifications_enabled'),
            storage.getItem('debug/guess_notifications/auto_switch_lu_enabled')
        ])).then(([guessQueueClassName, ticker_enabled, notifications_enabled, auto_switch_lu_enabled]) => {
            debugView.render({
                guess_notifications: {
                    ticker_enabled,
                    notifications_enabled,
                    auto_switch_lu_enabled
                }
            });
            debugView.show();
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('debug', 'open');
        });
    }

    async showDebugViewOpeningView (email) {

        let openingViewOpen = UserManager.instance.getUser().getParameters().getParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_OPEN);
        if (openingViewOpen === null && (email && email.endsWith('@lingvist.io'))) {
            await UserManager.instance.getUser().getParameters().setParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_OPEN, true, PARAMETER_TYPE.BOOLEAN);
            openingViewOpen = true;
        }

        if (openingViewOpen) {
            if (this.debugViewOpeningView === undefined) {
                this.debugViewOpeningView = new DebugViewOpeningView();
            }

            const x = UserManager.instance.getUser().getParameters().getParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_COORDINATE_X);
            const y = UserManager.instance.getUser().getParameters().getParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_COORDINATE_Y);
            this.debugViewOpeningView.render({
                x: x == null ? 5: x,
                y: y == null ? 150: y}
            );
        }
    }

    async setDebugOpeningViewPosition (x, y) {
        await UserManager.instance.getUser().getParameters().setParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_COORDINATE_X, x, PARAMETER_TYPE.NUMBER);
        await UserManager.instance.getUser().getParameters().setParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_COORDINATE_Y, y, PARAMETER_TYPE.NUMBER);
    }

    async setDebugOpeningViewOpen (isOpen) {
        await UserManager.instance.getUser().getParameters().setParameter(USER_PARAMETER.DEBUG_OPENING_VIEW_OPEN, isOpen, PARAMETER_TYPE.BOOLEAN);
    }

    closeDebugViewOpeningView () {
        if (this.debugViewOpeningView !== undefined) {
            this.debugViewOpeningView.remove();
            delete this.debugViewOpeningView;
        }
    }

    addCourse (course_uuid) {
        return Promise.resolve()
            .then(() => UserManager.instance.getUser().getCourses())
            .then(courses =>  {
                var course = courses.find(c => c.UUID === course_uuid);

                if (course === undefined) {
                    let user = UserManager.instance.getUser();
                    course = new UserCourse(user, {uuid: course_uuid});
                    user._courses.push(course);
                }

                return Promise.resolve(course);
            })
            .then(course => Promise.resolve()
                .then(() => course.enrolTo())
                .then(() => UserModel.setCourse(course.UUID))
            );
    }

    toggleNotifications (enabled) {
        return Promise.resolve()
            .then(() => {
                if (enabled) {
                    return Promise.resolve()
                        .then(() => Notification.requestPermission())
                        .then((permission) => {
                            if (permission === 'granted') {
                                return Promise.resolve(true);
                            } else {
                                console.error(`Unable to show notification: permission=${permission}`);
                                return Promise.resolve(false);
                            }
                        })
                        .catch(function (error) {
                            console.error('Error when requesting notification!');
                            throw error;
                        });
                } else {
                    return Promise.resolve(false);
                }
            }).then(enabled => {
                return Promise.resolve()
                    .then(() => UserManager.instance.getUser().getStorage().setItem('debug/guess_notifications/notifications_enabled', enabled))
                    .then(() => {
                        if (this._tickerView) {
                            this._tickerView.setNotificationsEnabled(enabled);
                        }
                    });
            });
    }

    toggleTicker (enabled) {
        return Promise.resolve().then(() => {
            return UserManager.instance.getUser().getStorage().setItem('debug/guess_notifications/ticker_enabled', enabled);
        }).then(() => {
            this._toggleTicker(enabled);
        });
    }

    _toggleTicker (enabled) {
        if (enabled) {
            if (this._tickerView === undefined) {
                return Promise.resolve()
                    .then(() => UserManager.instance.getUser().getStorage().getItem('debug/guess_notifications/notifications_enabled'))
                    .then(notifications_enabled => {
                        let firstRepeatQuestion = UserManager.instance.getUser().getCourse().getGuessQueue().peekFirstRepeatQuestion();
                        this._tickerView = new TickerView(firstRepeatQuestion && firstRepeatQuestion.getParameter('predicted_ts'), notifications_enabled);
                        if (firstRepeatQuestion) {
                            this._tickerView.updateTargetTime(firstRepeatQuestion.getParameter('predicted_ts'));
                        }
                        this._tickerView.on('switch-to-repeat', () => {
                            if(UserManager.instance.getUser().getCourse().getGuessQueue().switchToRepeatIfPossible()) {
                                return ControllerManager.instance.getController('Guess').reSyncGuess();
                            }
                        });
                        return this._tickerView.render();
                    });
            }
        } else if (this._tickerView !== undefined) {
            this._tickerView.off();
            this._tickerView.remove();
            delete this._tickerView;
        }
    }

    _updateTicker () {
        if (this._tickerView !== undefined) {
            let currentQuestion = UserManager.instance.getUser().getCourse().getGuessQueue().getCurrentQuestionSync();
            if (currentQuestion && currentQuestion.needsRepeat()) {
                this._tickerView.setHidden();
            } else {
                let firstRepeatQuestion = UserManager.instance.getUser().getCourse().getGuessQueue().peekFirstRepeatQuestion();
                this._tickerView.updateTargetTime(firstRepeatQuestion && firstRepeatQuestion.getParameter('predicted_ts'));
                this._tickerView.setVisible();
            }

        }
    }

    toggleAutoSwitch (enabled) {
        return Promise.resolve().then(() => {
            return UserManager.instance.getUser().getStorage().setItem('debug/guess_notifications/auto_switch_lu_enabled', enabled);
        }).then(() => {
            // TODO
            console.warn('Debug.autoSwitch is not ready yet!');
        });
    }

    showDayInsights () {
        EventBus.$emit('guess:show-day-insights', true);
    }

    showNewDebugView() {
        EventBus.$emit('debug:open');
    }
}

export const debug_controller_factory = new ControllerFactory(DebugController);
