var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "featured-in" }, [
    _c("h2", {
      domProps: {
        innerHTML: _vm._s(this.$i18n("product_highlights_featured_title")),
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "featured-in-logos" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.huffingtonpost.com/entry/how-technology-is-helping-us-learn-new-languages_us_5a0b2a6ae4b06d8966cf32fe",
            target: "_blank",
          },
        },
        [_c("huffpost-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.dw.com/en/7-estonian-startups-to-be-reckoned-with-entrepreneurship-innovation-robotics-starship-estonianmafia/g-41028331",
            target: "_blank",
          },
        },
        [_c("dw-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "http://time.com/4032036/best-websites-learn-something-new/",
            target: "_blank",
          },
        },
        [_c("time-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.aljazeera.com/news/2017/09/estonians-eye-digital-future-170929065350530.html",
            target: "_blank",
          },
        },
        [_c("aljazeera-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://venturebeat.com/2020/06/17/amazon-and-techstars-announce-inaugural-alexa-next-stage-cohort/",
            target: "_blank",
          },
        },
        [_c("venture-beat-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.lifehacker.jp/2017/07/34745.html",
            target: "_blank",
          },
        },
        [_c("lifehacker-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://blog.aboutamazon.com/devices/alexa-next-stage-startups-pitch-their-businesses",
            target: "_blank",
          },
        },
        [_c("alexa-logo")],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.zdnet.com/article/quickly-learn-a-new-language-with-ai-powered-lingvist/",
            target: "_blank",
          },
        },
        [_c("zdnet-logo")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }