var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M16 3v6M5 28v6M32 33v6M19 6h-6M8 31H2M35 36h-6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.756 26.148a1 1 0 01.554.57l2.558 6.788a1 1 0 01.002.7l-.836 2.26c-.322.87-1.553.87-1.875 0l-2.71-7.324a1 1 0 00-.591-.59l-7.323-2.71c-.871-.323-.871-1.554 0-1.876l2.242-.83a1 1 0 01.73.014l7.249 2.998z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.219 18.219l-7.684 2.843c-.871.322-.871 1.554 0 1.876l7.253 2.684a1 1 0 01.59.59l2.684 7.253c.322.871 1.554.871 1.876 0l2.684-7.253a1 1 0 01.59-.59l7.253-2.684c.871-.322.871-1.554 0-1.876l-7.252-2.684a1 1 0 01-.591-.59l-2.684-7.254c-.322-.87-1.554-.87-1.876 0l-.953 2.575",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.031 9.267a.5.5 0 01.938 0l1.207 3.262a.5.5 0 00.295.295l3.262 1.207a.5.5 0 010 .938L8.47 16.176a.5.5 0 00-.295.295l-1.207 3.262a.5.5 0 01-.938 0L4.824 16.47a.5.5 0 00-.295-.295l-3.262-1.207a.5.5 0 010-.938l3.262-1.207a.5.5 0 00.295-.295l1.207-3.262z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }