<template>
    <section ref="slider" class="slider">
        <section class="labels">
            <span v-for="tick in this.ticks" :style="{ left: `${tick.proportion * 100}%` }" class="label-container" :class="{ recommended: tick.recommended }" @click="clickTick(tick)"><span class="label">{{ tick.label }}</span></span>
        </section>
        <div ref="slider_body"
             class="slider-body-container"
             @click="clickBody"
             v-on:mousedown="slideStart">
            <section :class="{ tracking: tracking }" class="slider-body" :style="{ background: slider_background }">
                <div v-for="tick in this.ticks"
                     :style="{
                     left: `${tick.proportion * 100}%`,
                     visibility: (tick.first || tick.last) ? 'hidden' : 'inherit'
                 }"
                     class="slider-tick"
                     @click.stop="clickTick(tick)"/>

                <knob
                    :style="{ left: `${slider_position * 100}%` }"
                    class="slider-knob">
                </knob>
            </section>
        </div>
    </section>
</template>

<script>
    import Knob from 'Images/slider-knob-32x32.svg';
    import { clone } from 'lodash';

    export default {
        name: 'slider',
        components: {
            Knob,
        },
        props: {
            position: {
                type: Number,
                default: 0
            },
            max_amount: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                tracking: false,
                slider_position_: 0,
                ticks: [
                    { proportion: 0, label: 10, value: 0, first: true },
                    { proportion: 0.21, label: 50, value: 4, recommended: true },
                    { proportion: 1, label: 200, value: 19, last: true },
                ],
            };
        },

        computed: {
            slider_position () {
                if (this.tracking) {
                    return this.slider_position_;
                } else {
                    return this.position / this.max_amount;
                }
            },
            slider_background () {
                const _progress = this.slider_position * 100;
                const _progressWithColor = `#AC6ED1 ${_progress}%`;
                return `linear-gradient(90deg, #E2B4FF, ${_progressWithColor}, #EEF0F4 0)`;
            }
        },

        methods: {
            clickBody () {
                if (!this.tracking) {
                    this.$emit('update-position', this.position);
                }
            },

            clickTick (tick) {
                this.$emit('update-position', tick.value);
            },

            _getTrackingPosition (event) {
                const slider_x = this.$refs.slider_body.getBoundingClientRect().left;
                const slider_width = this.$refs.slider_body.offsetWidth;
                return Math.min(Math.max((event.clientX - slider_x) / slider_width, 0), 1);
            },

            _setTrackingPosition: function (event) {
                const tracking_position = this._getTrackingPosition(event);

                this.slider_position_ = tracking_position;
                this.$emit('update-position', Math.round(tracking_position * this.max_amount));
            },

            slideStart (event) {
                this.tracking = true;
                this.$emit('start-tracking');
                this._setTrackingPosition(event);
            },

            slideMove (event)  {
                if (this.tracking) {
                    this._setTrackingPosition(event);
                }
            },

            slideEnd (event) {
                if (this.tracking) {
                    this.tracking = false;
                    this.$emit('end-tracking');

                    this.$emit('update-position', Math.round(this._getTrackingPosition(event) * this.max_amount));
                }
            },

            setTrackingWithKeyboard(increase) {
                let pos = clone(this.position);
                if (increase) {
                    pos = (pos + 1 < this.max_amount) ? pos + 1 : this.max_amount;
                } else {
                    pos = Math.max(pos - 1, 0);
                }
                this.$emit('update-position', pos);
            },

            keyDown (event) {
                if (event) {
                    switch (event.code) {
                        case 'ArrowRight':
                            event.preventDefault();
                            event.stopImmediatePropagation();
                            event.stopPropagation();
                            this.setTrackingWithKeyboard(true);
                            return;
                        case 'ArrowLeft':
                            event.preventDefault();
                            event.stopImmediatePropagation();
                            event.stopPropagation();
                            this.setTrackingWithKeyboard(false);
                            return;
                    }
                }
            }
        },

        created () {
            this.slider_position_ = this.position / this.max_amount;

            this._document_mouseup = event => {
                this.slideEnd(event);
            };

            this._document_mousemove = event => {
                this.slideMove(event);
            };

            // Using global listeners because it's easy for user to move mouse from
            // the slider are during slider, as it's common UX practive to be able to
            // do this
            document.addEventListener('mouseup', this._document_mouseup);
            document.addEventListener('mousemove', this._document_mousemove);
            document.addEventListener('keydown', this.keyDown);
        },

        beforeDestroy () {
            document.removeEventListener('mouseup', this._document_mouseup);
            document.removeEventListener('mousemove', this._document_mousemove);
            document.removeEventListener('keydown', this.keyDown);
        }
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.slider {
        margin: 1rem 0 2rem;
        user-select: none;
        width: 100%;
        max-width: 32rem;
        padding: 0 0.5rem;
        direction: ltr;


        > div.knob {
            height: 1.75rem;
            width: 1.75rem;
            background-color: $colorBackgroundPurple;
            border-radius: 1.75rem;
            position: absolute;
            cursor: pointer;
            z-index: 1;

            display: flex;
            justify-content: center;
            align-items: center;

            &.start::v-deep > svg, &.end::v-deep > svg {
                height: 0.875rem;
                width: 0.875rem;
                position: relative;
            }

            &.start {
                left: -0.3rem;
                transform: translateY(0.15rem);

                ::v-deep > svg {
                    transform: rotate(180deg);
                    left: -2px;
                }
            }

            &.end {
                right: -0.45rem;
                transform: translateY(-1.9rem);

                ::v-deep > svg {
                    right: -1px;
                }
            }
        }

        > div.slider-body-container {
            padding: 1rem 0;
            cursor: pointer;

            > section.slider-body {
                height: 8px;
                border-radius: 0.5rem;
                background-color: $colorBackgroundLighter;

                &.tracking {
                    > svg.slider-knob {
                        transition: left 0s;
                    }
                }

                > svg.slider-knob {
                    transition: left 0ms;
                    bottom: 0;
                    position: absolute;
                    top: -0.8rem;
                    z-index: 2;
                    transform: translate(-50%);
                }
            }
        }

        > section.labels {
            margin-bottom: 1rem;

            > span.label-container {
                width: 0;
                display: flex;
                cursor: pointer;
                position: absolute;

                > span.label {
                    color: $colorSourceSecondary;
                    font-size: fontSize(12);
                    transform: translate(-50%);
                }
                &.recommended {
                    > span.label {
                        font-weight: $font-bolder;
                    }
                }
            }
        }
    }
</style>
