import Backbone from 'backbone';
import moment from 'moment';
import _ from 'lodash';

import ControllerFactory from '../modules/controller.factory.js';
import ClassroomListView from '../view/classrooms/classroom.list.view.js';
import ClassroomsSignupView from '../view/classrooms/classrooms.signup.view.js';
import ClassroomView from '../view/classrooms/classroom.view.js';
import UserModel from '../model/user.model.js';
import UserManager from '../modules/usermanager.js';
import { ConfirmationView, CONFIRM_ACTION } from '../view/util/confirmation.view.js';
import i18nUtils from '../util/i18n.js';
import { trackLeanplumEvent } from '../util/leanplum.js';
import googleAnalyticsCommand from '../util/google-analytics.js';
import { USER_FEATURE } from '../modules/user.js';

const MIN_ROW_COUNT = 10;

export class ClassroomsController {

    constructor () {
        this._classroomListData = null;
        this._appView = null;
        this._confirmationViewVisible = false;
    }

    async go (appView, router, uuid = null) {

        const user = UserManager.instance.getUser();
        this._appView = appView;

        if (uuid !== null) {

            const classroomView = new ClassroomView();

            classroomView.on('remove-student', ({ student_uuid }) => {

                user
                    .getClassrooms()
                    .removeStudent(uuid, student_uuid)
                    .then( () => this.getClassroomData(uuid))
                    .then( classroomData => {
                        classroomView.setProps(classroomData);
                        classroomView.render().then( () => {
                            classroomView.showMessage(i18nUtils.prop('classroom_student_list_remove_message'));
                        });
                    })
                    .catch(error => {
                        classroomView.showMessage(error.message);
                    });
            });

            classroomView.on('save-classroom-name', ({ name }) => {

                user
                    .getClassrooms()
                    .updateClassroomName(uuid, name)
                    .then( classroomInfo => {
                        const { name } = classroomInfo;
                        classroomView.model.set({ name });
                    })
                    .catch(error => {
                        classroomView.showMessage(error.message);
                    });
            });

            appView.setMainView(classroomView, true);

            return Promise.resolve()
                .then( () => this.getClassroomData(uuid))
                .then( classroomData => {
                    classroomView.setProps(classroomData);
                    classroomView.render();
                });
        } else {
            if (user.hasFeature(USER_FEATURE.CLASSROOMS)) {
                this.renderClassroomListView();
            } else {
                this.renderClassroomsSignupView();
            }
            googleAnalyticsCommand('send', 'pageview', `/classrooms`);
        }
    }

    renderClassroomListView () {
        const classroomListView = new ClassroomListView();

        classroomListView.on('create-classroom', ({ name, course_uuid }) => {

            return Promise.resolve()
                .then( () => {
                    Backbone.trigger('showLoader', 'classrooms');
                    return Promise.resolve();
                })
                .then( () => UserManager.instance.getUser().getClassrooms().createClassroom(name, course_uuid))
                .then( () => this.fetchClassroomListData())
                .then( classroomListData => {
                    classroomListView.setProps(classroomListData);
                    classroomListView.render();
                    Backbone.trigger('hideLoader', 'classrooms');
                });
        });

        classroomListView.on('delete-classroom', ({ classroom_uuid }) => {

            return Promise.resolve()
                .then( () => {
                    Backbone.trigger('showLoader', 'classrooms');
                    return Promise.resolve();
                })
                .then( () => UserManager.instance.getUser().getClassrooms().deleteClassroom(classroom_uuid))
                .then( () => this.fetchClassroomListData())
                .then( classroomListData => {
                    classroomListView.setProps(classroomListData);
                    classroomListView.render();
                    Backbone.trigger('hideLoader', 'classrooms');
                });
        });

        this._appView.setMainView(classroomListView, true);

        this.getClassroomListData()
            .then(classroomListData => {
                classroomListView.setProps(classroomListData);
                classroomListView.render();
            })
            .then( () => this.fetchClassroomListData())
            .then( classroomListData => {
                classroomListView.setProps(classroomListData);
                classroomListView.render();
            });
    }

    renderClassroomsSignupView () {
        const classroomsSignupView = new ClassroomsSignupView();
        classroomsSignupView.on('classrooms-feature-added', () => {
            Backbone.trigger('showLoader', 'classrooms');
            this.renderClassroomListView();
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('classrooms', 'request-access', null);
            trackLeanplumEvent('Classrooms', 'ImAnEducator');
        });

        classroomsSignupView.setProps({
            joinUrl: this.getClassroomFeatureJoinUrl()
        });

        classroomsSignupView.render();

        this._appView.setMainView(classroomsSignupView, true);
    }

    /**
     * Get cached classroom data for quick renderding. Load from backend when not available.
     */

    getClassroomListData () {
        if (this._classroomListData !== null) {
            return Promise.resolve(this._classroomListData);
        } else {
            return Promise.resolve()
                .then( () => this.fetchClassroomListData());
        }
    }

    /**
     * Fetch and prepare classrooms data from backend
     */

    async fetchClassroomListData () {


        const courses = await UserModel.getCourses();
        const classroomList = await UserManager.instance.getUser().getClassrooms().getClassroomList();
        const lessonsUserFeatureEnabled = UserManager.instance.getUser().hasFeature(USER_FEATURE.LESSONS);
        const _subscription = await UserManager.instance.getUser().getSubscription();
        const isDisabled = !_subscription.isSubscriptionActive();

        // Set action flag to toggle for the multi-purpose Course UI component
        const activeSourceCourses = _.isArray(courses.coursesAvailableActiveSource) && courses.coursesAvailableActiveSource.map(course => {
            return Object.assign({}, course, {
                action: 'toggle'
            });
        });

        // Set action flag to toggle for the multi-purpose Course UI component
        const otherSourceCourses = _.isArray(courses.coursesAvailableOtherSource) && courses.coursesAvailableOtherSource.map(course => {
            return Object.assign({}, course, {
                action: 'toggle'
            });
        });

        // Set action flag to toggle for the multi-purpose Course UI component
        const allAvailableCourses = _.isArray(courses.coursesAvailableAll) && courses.coursesAvailableAll.map(course => {
            return Object.assign({}, course, {
                action: 'toggle'
            });
        });

        let emptyRows = null;

        if (classroomList.length < MIN_ROW_COUNT) {
            emptyRows = new Array(MIN_ROW_COUNT - classroomList.length);
            emptyRows.fill('');
        }

        let orderedClassroomList = _.orderBy(classroomList, [classroom => classroom.name.toLowerCase()], ['asc']);

        this._classroomListData = {
            classroomList: orderedClassroomList,
            emptyRows,
            activeSourceCourses,
            otherSourceCourses,
            allAvailableCourses,
            lessonsEnabled: lessonsUserFeatureEnabled,
            isDisabled
        };

        return this._classroomListData;
    }

    /**
     * Get metadata and list of students of specific classroom
     * @param classroomUuid - the UUID of the classroom
     */

    async getClassroomData (classroomUuid) {

        const user = UserManager.instance.getUser();
        let classroomData = await user.getClassrooms().getClassroom(classroomUuid);

            classroomData.students.forEach( student => {

                const NUM_DAYS = 7;
                const statsUpdatedDate = moment(student.statistics.series.updated).startOf('date');
                const statsUpdatedDaysAgo = moment().diff(statsUpdatedDate, 'days');

                if (statsUpdatedDaysAgo < NUM_DAYS) {

                    let { study_time, all_units, new_units } = student.statistics.series.series;

                    // Fill idle days with zeros
                    _.range(statsUpdatedDaysAgo).forEach( () => {
                        study_time.unshift(0);
                        all_units.unshift(0);
                        new_units.unshift(0);
                    });

                    // Last MAX_DAYS days
                    study_time = study_time.slice(0, NUM_DAYS);
                    all_units = all_units.slice(0, NUM_DAYS);
                    new_units = new_units.slice(0, NUM_DAYS);

                    // Sum up card counts and minutes
                    student.study_time_last_week = study_time.reduce( (total, current) => total + current, 0);
                    student.all_units_last_week = all_units.reduce( (total, current) => total + current, 0);
                    student.new_units_last_week = new_units.reduce( (total, current) => total + current, 0);

                } else {
                    student.study_time_last_week = 0;
                    student.all_units_last_week = 0;
                    student.new_units_last_week = 0;
                }
            });

            if (classroomData.students.length < MIN_ROW_COUNT) {
                classroomData.emptyRows = new Array(MIN_ROW_COUNT - classroomData.students.length);
            }

            const _subscription = await UserManager.instance.getUser().getSubscription();
            classroomData.isDisabled = !_subscription.isSubscriptionActive();
            classroomData.lessonsEnabled = user.hasFeature(USER_FEATURE.LESSONS);

            classroomData.updated = moment().format('LTS');

            return classroomData;
    }

    /**
     * Join an existing classroom using the join code
     * @param classroomCode - the join code of the classroom
     */

    joinClassroom (classroomCode) {

        return Promise.resolve()
            .then( () => UserManager.instance.getUser().getClassrooms().joinClassroom(classroomCode))
            .then(response => {
                let _title = null,
                    _message = null;

                if (this._confirmationViewVisible) {
                    return Promise.resolve(); // there's a confirmationView already, fail silently
                }

                if (_.isString(response)) {
                    _title = i18nUtils.prop('classroom_joined_modal_title');
                    _message = i18nUtils.prop('classroom_joined_modal_text', { classroom_name: response });
                } else if (_.isObject(response) && response.code) { // handle error
                    _title = i18nUtils.prop('title',null,null,'classroom_joined_modal_error');
                    _message = i18nUtils.prop(response.code, null, null, 'classroom_joined_modal_error');
                } else {
                    return Promise.resolve(); // fail silently
                }

                this._confirmationViewVisible = true;

                const confirmationView = new ConfirmationView({
                    title: _title,
                    message: _message,
                    actions: [
                        {
                            title: i18nUtils.prop('classroom_joined_modal_confirm'),
                            action: CONFIRM_ACTION.OK,
                            primary: true
                        }
                    ]
                }, { background: true });

                confirmationView.on('confirm', () => {
                    Promise.resolve()
                        .then(() => confirmationView.hide())
                        .then(() => {
                            confirmationView.remove();
                            this._confirmationViewVisible = false;
                        });
                });

                confirmationView.show();

                return Promise.resolve();
            })
            .then(() => UserManager.instance.getUser().sync())
            // User limits are synced with course, this is necessary to avoid running into limits
            .then(() => UserManager.instance.getUser().hasCourse() && UserManager.instance.getUser().getCourse().syncCourse());
    }

    notifyUserSignedIn () {
        this.fetchClassroomListData();
    }

    getClassroomFeatureJoinUrl () {

        const typeformUrls = [
            { lang: 'zh-Hant', url: 'https://lingvist.typeform.com/to/D7DUCi' },
            { lang: 'zh-Hans', url:  'https://lingvist.typeform.com/to/BbP7h3' },
            { lang: 'ru', url: 'https://lingvist.typeform.com/to/UYH9ZP' },
            { lang: 'pt', url: 'https://lingvist.typeform.com/to/kUvPzA' },
            { lang: 'ja', url: 'https://lingvist.typeform.com/to/YjtQ6e' },
            { lang: 'fr', url: 'https://lingvist.typeform.com/to/BIEsYP' },
            { lang: 'et', url: 'https://lingvist.typeform.com/to/mYODKX' },
            { lang: 'es', url: 'https://lingvist.typeform.com/to/AK8grg' },
            { lang: 'de', url: 'https://lingvist.typeform.com/to/pqvbDq' },
            { lang: 'en', url: 'https://lingvist.typeform.com/to/PTKVh9' }
        ];

        const defaultUrl = typeformUrls.find(typeformUrl => typeformUrl.lang === 'en');
        const langSpecificUrl = typeformUrls.find(typeformUrl => typeformUrl.lang === i18nUtils.currentInterfaceLanguage);

        const user = UserManager.instance.getUser();
        const hiddenParameters = `?name=${encodeURIComponent(user.profile.name)}&email=${encodeURIComponent(user.profile.email)}&user_uuid=${encodeURIComponent(user.UUID)}`;

        if (langSpecificUrl) {
            return `${langSpecificUrl.url}${hiddenParameters}`;
        } else {
            return `${defaultUrl.url}${hiddenParameters}`;
        }
    }
}

export const classrooms_controller_factory = new ControllerFactory(ClassroomsController);
