var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collapsible-box-content" },
    [
      _c("div", { staticClass: "icon-wrapper" }, [_vm._t("icon")], 2),
      _vm._v(" "),
      _vm.text || _vm.i18n_text
        ? [
            _vm.text
              ? _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
              : _vm.i18n_text
              ? _c("div", {
                  staticClass: "text",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n(_vm.i18n_text.id, {
                        collection: _vm.i18n_text.collection,
                        args: _vm.i18n_text.params,
                      })
                    ),
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "text-meta",
        domProps: { innerHTML: _vm._s(_vm.textMeta) },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }