var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#8EA3EE",
          d: "M11 6H8l3 5H6.5L5 8h-.8C5.4 4.9 8.4 2.6 12 2.6c4.5 0 8.2 3.5 8.4 7.8v.6H15l-4-5z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M8 5c-.4 0-.7.2-.9.5-.1.3-.1.7 0 1L9.2 10H7.5L5.8 7.4C5.6 7.2 5.3 7 5 7H2c-.3 0-.6.1-.8.4-.2.2-.2.5-.2.8l1 4c.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2L3.3 9h1.2l1.7 2.6c.2.2.5.4.8.4h13c.6 0 1 .4 1 1s-.4 1-1 1h-1c-.6 0-1 .4-1 1s.4 1 1 1h1c1.7 0 3-1.3 3-3s-1.3-3-3-3h-4.5l-3.7-4.6c-.2-.3-.5-.4-.8-.4H8zm4.9 5l-2.4-3h-.7l1.8 3h1.3z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M11.2 14c.1 0 .3.1.4.2l.3.3c.1.1.1.3.1.4v.4c0 .1-.1.2-.1.3L9.8 19h.8l3.7-4.6c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4l-4 5c-.3.3-.6.4-.9.4H8c-.4 0-.7-.2-.9-.5-.2-.3-.2-.7 0-1L9.2 16H4c-.6 0-1-.4-1-1s.4-1 1-1h7.2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }