var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "burger",
      class: { homeDrawerOpen: _vm.open },
      on: { click: _vm.openHomeDrawer },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "48",
            height: "48",
            viewBox: "0 0 48 48",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M35.04 12.96H12.96V15.84H35.04V12.96Z",
              fill: "#1D1724",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M35.04 22.56H12.96V25.44H35.04V22.56Z",
              fill: "#1D1724",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M35.04 32.16H12.96V35.04H35.04V32.16Z",
              fill: "#1D1724",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }