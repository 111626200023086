var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M16.308 36H12a4 4 0 01-4-4V18c0-6.627 5.373-12 12-12s12 5.373 12 12v14a4 4 0 01-4 4h-4.308",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28 16c-.5-2.5-3-6-8-6s-7.5 3.5-8 6M9 11.5L6.139 7.566a2 2 0 01.39-2.755l1.31-1.02a2 2 0 012.867.432L13 7.5M31 11.5l2.861-3.934a2 2 0 00-.39-2.755l-1.31-1.02a2 2 0 00-2.867.432L27 7.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 23a1 1 0 011-1h14a1 1 0 011 1v8a1 1 0 01-1 1H13a1 1 0 01-1-1v-8z",
          fill: "#AC6ED1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32 19h3a1 1 0 011 1v13a1 1 0 01-1 1h-3M8 19H5a1 1 0 00-1 1v13a1 1 0 001 1h3M12 26h7M25 26h3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }