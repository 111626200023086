var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "success" },
    [
      _c("order-failure-icon"),
      _vm._v(" "),
      _c("div", { staticClass: "message" }, [
        _c("h2", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("pay_order_summary_title")),
          },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("order_failed", { collection: "pay_errors" })
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _c("v-button", {
        attrs: {
          i18n: { id: "pay_failure_page_continue_btn" },
          route: "subscriptions",
          filled: true,
          color: "primary",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }