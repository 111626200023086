
import _ from 'lodash';
import moment from 'moment';

import { StatisticsProcessorUtils, EVENT_TYPE } from '../processor.utils.js';
import { AWARD_NAME } from '../../awards.js';

export class StatisticsAwardEventV1Processor {

    static canProcess (event) {
        return event.schema === 'urn:lingvist:schemas:events:award:1.1';
    }

    static process (event, courseStatistics) {
        if (event.__statisticsDataV1 === undefined) {
            event.__statisticsDataV1 = {
                type: EVENT_TYPE.AWARD,
                eventTS: moment(event.client_event_ts),
                clientSN: event.client_sn
            };
        }

        const eventStatistics = event.__statisticsDataV1;

        const currentStatistics = courseStatistics.getData();
        const updatedStatistics = _.cloneDeep(currentStatistics);

        const history_day = StatisticsProcessorUtils.getDataPointForTS(
            _.cloneDeep(courseStatistics.getHistory()), eventStatistics.eventTS).point;

        history_day.awards_objects.push({
            award_name: event.data.award_name,
            cards_completed: event.data.cards_completed,
            new_cards: event.data.new_cards,
            correct_repeated_cards: event.data.correct_repeated_cards,
            wrong_repeated_cards: event.data.wrong_repeated_cards,
            time_elapsed: event.data.time_elapsed,
            max_correct_streak: event.data.max_correct_streak
        });
        history_day.client_sn = eventStatistics.clientSN;

        const updatedHistory = [history_day];

        updatedStatistics.client_event_ts = eventStatistics.eventTS;

        if (event.data.award_name === AWARD_NAME.SET_COMPLETE) {
            let correct_rate = 0;
            if (event.data.correct_repeated_cards + event.data.wrong_repeated_cards > 0) {
                correct_rate = event.data.correct_repeated_cards / (event.data.correct_repeated_cards + event.data.wrong_repeated_cards);
            }

            updatedStatistics.sets = {
                max_correct_streak: Math.max(currentStatistics.sets.max_correct_streak, event.data.max_correct_streak),
                max_correct_rate: Math.max(correct_rate, event.data.max_correct_rate),
                count: currentStatistics.sets.count + 1
            };
        }

        updatedStatistics.client_sn = event.client_sn;
        return [updatedStatistics, updatedHistory];
    }
}
