var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          opacity: ".5",
          fill: "#B2B8CB",
          d: "M3 15c0-1.1.9-2 2-2h6v6c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-4zM3 5c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v6H5c-1.1 0-2-.9-2-2V5zm10 0c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2h-6V5z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#B2B8CB",
          d: "M13 13h8c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2h-6c-1.1 0-2-.9-2-2v-8z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }