import ControllerManager from '../controller.manager.js';

import { Limit } from './limit.js';
import { SUBSCRIPTION_STATUS } from '../subscription.js';

export const LIMIT_ACTION = {
    HARD_PAYWALL: 'hard-paywall'
};


export const ACTION_GOAL = {
    HARD_PAYWALL: 'hard-paywall',
    UPSELL: 'upsell'
};

export const PRE_TRIAL_CARD_LIMIT = 50;

export class CourseLimits {
    constructor (user, course) {
        this._user = user;
        this._course = course;

        this._limits = null;
    }

    destroy () {
        delete this._user;
        delete this._course;
        delete this._limits;
        return Promise.resolve();
    }

    initializeState () {
        this._limits = {};
        return Promise.resolve();
    }

    updateLimits (limits) {
        this._limits = {};
        Object.keys(limits).forEach(type => {
            let scopes = limits[type];
            this._limits[type] = new Limit(type, scopes);
        });
    }

    serializeLimits () {
        return Object.keys(this._limits).reduce((result, type) => {
            result[type] = this._limits[type].serialize();
            return result;
        }, {});
    }

    async showDisabledPopup() {
        const modalMessagesController = ControllerManager.instance.getController('ModalMessages');
        await modalMessagesController.showPopup(LIMIT_ACTION.HARD_PAYWALL, null, null);
    }

    isPreTrialCardLimitExceeded() {
        let statistics = this._course.getStatistics();
        let cardsDone = statistics.getTotalCardsEncountered();

        return cardsDone >= PRE_TRIAL_CARD_LIMIT;
    }

    isFastTrackingInProgress() {
        return this._course.getFastTrackingState().isAssessing();
    }

    /**
     * GameOver - used for locking out user from using the app main functionality
     * @return {boolean}
     */
    isGameOver() {
        const status = this._user.getSubscription().getStatus().status;
        if (status === SUBSCRIPTION_STATUS.FREE) {
            return true;
        } else if (status === SUBSCRIPTION_STATUS.PRE_TRIAL) {
            if (this.isPreTrialCardLimitExceeded()) {
                return !this.isFastTrackingInProgress();
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
