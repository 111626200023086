var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M16.8 8.5c-.4-.9-1.3-1.5-2.3-1.5-.8 0-1.5.7-1.5 1.5V22c0 .6.4 1 1 1s1-.4 1-1V9.3l2.1 4.6c.2.5.8.7 1.3.5.5-.2.7-.8.5-1.3l-2.1-4.6zM10 8c0-.6-.4-1-1-1h-.5c-1.1 0-2 .8-2.1 1.9l-.4 5c0 .6.4 1 .9 1.1.6 0 1-.4 1.1-.9l.4-5s0-.1.1-.1H9c.6 0 1-.4 1-1zm-.4 7c-.6 0-1 .4-1.1.9l-.5 6c0 .6.4 1 .9 1.1.6 0 1-.4 1.1-.9l.5-6c0-.6-.4-1.1-.9-1.1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#AC6ED1",
          d: "M9 3c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3-3 1.3-3 3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }