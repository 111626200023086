var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M24 13v-2h-8v8.789a4 4 0 01-.672 2.219l-5.182 7.773C8.374 32.44 10.279 36 13.474 36h13.052c3.195 0 5.1-3.56 3.328-6.219l-5.182-7.773a4 4 0 01-.672-2.22V18",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.943 32H25a1.03 1.03 0 00.837-1.628L22 25h-4l-3.87 5.419A1 1 0 0014.942 32z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 15h4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24 9.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18 5.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM14 11h12",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }