var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.5 7a1 1 0 01.912.59l4.5 10a1 1 0 11-1.824.82L9.003 16H3.997l-1.085 2.41a1 1 0 11-1.824-.82l4.5-10A1 1 0 016.5 7zm-1.603 7h3.206L6.5 10.437 4.897 14zM17.65 7.27A1.5 1.5 0 0016 8.762V18a1 1 0 01-2 0v-5h-1a1 1 0 010-2h1V8.762a3.5 3.5 0 017 0v.412a3.95 3.95 0 01-.66 2.187 3.891 3.891 0 01-.59 7.528l-.586.097a1 1 0 01-.328-1.972l.584-.098a1.89 1.89 0 00-.076-3.74l-1.468-.184a1 1 0 01-.323-1.887l.366-.183A1.955 1.955 0 0019 9.174v-.412a1.5 1.5 0 00-1.35-1.492z",
          fill: "#2C3143",
        },
      }),
      _c("circle", { attrs: { cx: "4", cy: "5", r: "1", fill: "#2C3143" } }),
      _c("circle", { attrs: { cx: "9", cy: "5", r: "1", fill: "#2C3143" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }