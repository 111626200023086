<template>
    <div class="words-progress grid-item">
        <h2 v-html="this.$i18n('hub_section_progress_title')"></h2>
        <thermometer :course="this.course" :user="this.user" :clickable="true" v-on="$listeners" />
        <collapsible-box :i18n_title="{ id: 'widget_stats_total_words_label' }"
                         :title-meta="totalWords"
                         :i18n_text="{ id:'knowledge_lab_total_words_description'}">
            <template v-slot:icon>
                <IconHubTotalWords />
            </template>
        </collapsible-box>
    </div>
</template>

<script>
import CollapsibleBox from "ViewComponent/collapsible-box/main.vue";
import IconHubTotalWords from 'Images/icons/icon-hub-totalwords.svg';
import { EventBus } from "Util/vue-event-bus";
import Thermometer from "./thermometer.vue";

export default {
    name: 'words-progress',
    components: {
        Thermometer,
        CollapsibleBox,
        IconHubTotalWords,
    },
    props: ['user', 'course'],
    data() {
        return {
            totalWords: 0
        };
    },
    methods: {
        setData() {
            const _statistics = (this.course) ? this.course.getStatistics() : null;
            if (!_statistics) {
                return;
            }
            this.totalWords = _statistics.getWordsEncountered();
        },
        listenEventBus() {
            EventBus.$on('interfaceLanguageChanged', this.reRender);
        },
        reRender() {
            this.$forceUpdate();
        }
    },
    created() {
        this.setData()
        this.listenEventBus();
    },
    watch: {
        course(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setData();
            }
        }
    },
    beforeDestroy() {
        EventBus.$off('interfaceLanguageChanged', this.reRender);
    }
};
</script>

<style lang="scss" scoped>
@import '~Styles/mixins';
@import '~Styles/colors';

div.words-progress {
    grid-area: words-progress;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > h2 {
        margin: 0;
    }
}
</style>
