var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tutor" },
    [
      !_vm.grammarTableInfo
        ? _c("loader", { attrs: { centered: true } })
        : [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("div", {
                  staticClass: "title",
                  domProps: { innerHTML: _vm._s(_vm.grammarTableInfo.title) },
                }),
                _vm._v(" "),
                _c("CloseButton", {
                  staticClass: "close",
                  on: { click: _vm.close },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "sidebar" },
                _vm._l(_vm.grammarTableInfo.sections, function (section) {
                  return _c(
                    "div",
                    {
                      staticClass: "item",
                      class: {
                        active:
                          _vm.activeSection &&
                          section.id === _vm.activeSection.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openSection(section)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(section.title) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "body" },
                [
                  _vm.activeSection
                    ? [
                        _c(
                          "div",
                          { staticClass: "items" },
                          _vm._l(_vm.activeSection.items, function (item) {
                            return _c(
                              "div",
                              {
                                staticClass: "item target",
                                class: {
                                  heading: item.type === "heading",
                                  item: item.type === "item",
                                },
                              },
                              [
                                item.type === "heading"
                                  ? _c("p", { staticClass: "heading" }, [
                                      _vm._v(_vm._s(item.heading)),
                                    ])
                                  : [
                                      _c("div", { staticClass: "item-row" }, [
                                        item.prefixes
                                          ? _c(
                                              "span",
                                              { staticClass: "prefix" },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      item.prefixes.join(", ")
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "form" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.forms.join(", ")) +
                                              "\n                                "
                                          ),
                                        ]),
                                      ]),
                                    ],
                              ],
                              2
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.activeSection.comment
                          ? _c("div", {
                              staticClass: "comment",
                              domProps: {
                                innerHTML: _vm._s(_vm.activeSection.comment),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSection.description
                          ? _c("div", { staticClass: "description" }, [
                              _c("h3", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.activeSection.description.title
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.activeSection.description.text
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }