var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "course" },
    [
      _c("course-icon", {
        staticClass: "course-icon",
        attrs: { value: _vm.course.info.source_icon_id, size: "large" },
      }),
      _vm._v(" "),
      _c("course-arrow", { staticClass: "arrow" }),
      _vm._v(" "),
      _c("course-icon", {
        staticClass: "course-icon",
        attrs: { value: _vm.course.info.target_icon_id, size: "large" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }