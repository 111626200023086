<template>
    <section class="credentials-email">
        <h1 v-html="this.$i18n('register_credentials_selected_course_heading')"></h1>
        <course :course="selected_course"/>
        <p v-html="this.$i18n('register_save_progress')"></p>

        <h2 v-html="this.$i18n('register_email_header')"></h2>

        <form class="register" v-on:keypress.enter="form_keypress" v-on:submit.prevent>
            <div class="input-container">
                <account-icon />
                <input
                    class="name"
                    ref="name_input"
                    :class="{'error': name_validation_error}"
                    type="text"
                    v-model="name_"
                    @change="check_name"
                    @blur="check_name"
                    :placeholder="this.$i18n('register_name_placeholder')"
                    autocomplete="name" />
                <p class="error" v-if="name_validation_error === 'error-empty-name'"
                   v-html="this.$i18n('register_error_empty_name')"></p>
            </div>

            <div class="input-container">
                <account-email-icon />
                <input
                    class="email"
                    :class="{'error': email_validation_error, 'suggestion': email_suggestion}"
                    type="email"
                    v-model="email_"
                    @input="check_email"
                    @blur="check_email"
                    :placeholder="this.$i18n('register_email_email_placeholder')"
                    autocomplete="email" />
                <p
                    v-if="email_suggestion"
                    class="email-suggestion"
                    @click="use_email_suggestion"
                    v-html="this.$i18n('register_email_suggestion', {args: {email: `<a>${decodeURIComponent(email_suggestion.full)}</a>`}})"
                ></p>
                <p class="error" v-if="email_validation_error === 'error-empty-email'"
                   v-html="this.$i18n('register_error_empty_email')"></p>
                <p class="error" v-else-if="email_validation_error === 'error-invalid-email'"
                   v-html="this.$i18n('register_error_invalid_email')"></p>
            </div>


            <div class="input-container">
                <account-lock-icon />
                <input
                    class="password"
                    :type="password_visible ? 'text': 'password'"
                    v-model="password_"
                    @input="check_password_on_input"
                    @blur="check_password_on_blur"
                    :placeholder="this.$i18n('register_password_placeholder')"
                    autocomplete="new-password" />
                <a class="password-reveal" @click="password_visible = !password_visible">
                    <account-eye :class="{invisible: !password_visible}" />
                </a>
                <p class="password-length-reminder" v-if="password_validation_error === null"
                   v-html="this.$i18n('register_password_length')"></p>
                <p class="error" v-else-if="password_validation_error === 'error-password-too-short'"
                   v-html="this.$i18n('register_error_password_too_short')"></p>
            </div>
        </form>

        <footer>
            <button @click="submit_credentials" :disabled="!submit_enabled" v-html="this.$i18n('register_submit')"></button>
            <terms />
        </footer>
    </section>
</template>

<script>

import EvaluationUtils from '../../../util/evaluation.js';

import Course from '../components/course.vue';
import Terms from '../../component/terms.vue';

import AccountIcon from '../../../../img/account/person.svg';
import AccountEmailIcon from '../../../../img/account/email.svg';
import AccountLockIcon from '../../../../img/account/lock.svg';
import AccountEye from '../../../../img/account/eye.svg';

export default {
    name: 'register.credentials-email',
    props: {
        name: {
            type: String,
            required: false
        },
        email: {
            type: String,
            required: false
        },
        selected_course: {
            type: Object,
            required: true,
        },
        single_available_target_language_course: {
            type: Boolean,
            required: true
        },
    },
    components: {
        Course,
        Terms,
        AccountIcon,
        AccountEmailIcon,
        AccountLockIcon,
        AccountEye,
    },
    data () {
        return {
            email_: this.email || '',
            name_: this.name ||'',
            password_: '',

            name_validation_error: null,
            email_validation_error: null,
            password_validation_error: null,

            email_suggestion: null,
            password_visible: false,
        };
    },
    mounted () {
        this.$refs.name_input.focus();
    },
    computed: {
        submit_enabled () {
            return this.password_.length >= EvaluationUtils.passwordMinLength
                   && this.email_.length > 0
                   && !this.email_validation_error
                   && !this.name_validation_error;
        }
    },
    methods: {
        check_name () {
            if (this.name_.length === 0) {
                this.name_validation_error = 'error-empty-name';
            } else {
                this.name_validation_error = null;
                this.$emit('name_updated', this.name_);
            }
        },
        async check_email () {
            this.email_suggestion = null;
            this.email_validation_error = null;

            try {
                this.email_suggestion = await EvaluationUtils.validateAndSuggestEmail(this.email_);
                this.$emit('email_updated', this.email_);
            } catch (validation_error) {
                this.email_validation_error = validation_error;
            }
        },
        check_password_on_blur () {
            if (this.password_.length < EvaluationUtils.passwordMinLength) {
                this.password_validation_error = 'error-password-too-short';
            } else {
                this.password_validation_error = null;
            }
        },
        check_password_on_input () {
            if (this.password_.length >= EvaluationUtils.passwordMinLength) {
                this.password_validation_error = null;
            }
        },
        use_email_suggestion (event) {
            if (event.target.nodeName === 'A') {
                this.email_ = event.target.innerText;
                this.email_suggestion = null;
                this.email_validation_error = null;
            }
        },
        form_keypress () {
            this.check_name();
            this.check_email();
            this.check_password_on_blur();
            this.submit_credentials();
        },
        submit_credentials () {
            if (this.submit_enabled) {
                this.$emit('verified_submit', this.password_)
            }
        },
    }
};
</script>

<style scoped lang="scss">
@import "~Styles/colors";
@import "~Styles/font-weights";
@import "~Styles/email-suggestion.scss";

section.credentials-email {
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
        font-weight: $font-bold;
        font-size: 1.625rem;
        margin-bottom: 2rem;
    }

    > h2 {
        font-weight: $font-bold;
        font-size: 1.625rem;
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    }

    > form {
        width: 100%;

        > div.input-container {
            margin: 1rem 0;

            > svg {
                position: absolute;
                left: 0.125rem;
                top: calc(50% - 0.75rem);
                width: 1.5rem;
                body[data-interface-language="ar"] & {
                    left: unset;
                    right: 0.125rem;
                }
            }

            > input {
                width: 100%;
                font-size: 1rem;
                font-weight: $font-normal;
                height: 3rem;
                padding-left: 2.25rem;
                color: $colorSourcePrimary;
                body[data-interface-language="ar"] & {
                    padding-left: unset;
                    padding-right: 2.25rem;
                }


                border-bottom: 1px solid $colorSourceTertiary;

                &:focus {
                    border-bottom: 1px solid $colorSourcePrimary;
                }

                &.error {
                    border-bottom: 1px solid $colorIncorrect;
                }

                &.suggestion {
                    border-bottom: 1px solid #FAAA5A;
                }

                &::placeholder {
                    font-size: 1rem;
                }
            }

            > a.password-reveal {
                cursor: pointer;
                font-size: 0.75rem;
                position: absolute;
                right: 0;
                top: calc(50% - 0.75rem);
                line-height: 1em;
                user-select: none;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 0;
                }

                &::v-deep > svg {
                    width: 1.5rem;

                    &.invisible {
                        > path {
                            fill: $colorSourceTertiary;
                        }
                    }
                }
            }

            > p.password-length-reminder {
                color: $colorSourceSecondary;
                width: 100%;
                position: absolute;
                font-size: 0.875rem;
                text-align: left;
                body[data-interface-language="ar"] & {
                    font-size: 0.7rem;
                    text-align: right;
                }
            }

            &::v-deep > p.email-suggestion {
                @include email-suggestion;
            }

            > p.error {
                position: absolute;
                font-size: 0.875rem;
                color: $colorIncorrect;
                text-align: left;
            }
        }
    }

    > footer {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        flex: 1 1 8rem;
        justify-content: flex-end;

        text-align: center;
    }
}

</style>
