var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "hub-hero": true } },
    [
      this.courses
        ? _c("course-select", {
            attrs: { courses: this.courses },
            on: { "open-course-modal": _vm.openCourseModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cta" },
        [
          _c("v-button", {
            attrs: {
              i18n: {
                id: "hub_hero_learn_btn",
                params: {
                  today_current_set_cards: _vm.todayCurrentSetCards,
                  today_sets_completed: _vm.todaySetsCompleted,
                },
              },
              filled: true,
              color: "primary",
              icon: "continue",
              route: "guess",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }