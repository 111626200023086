<template>
    <div class="week-goals" v-if="this.goalsData && this.goalsData.length > 0">
        <div class="week">
            <template v-for="day in goalsData">
                <div v-bind:class="{ goal: true, today: day.isToday, missing: day.missing }"
                     :title="day.dayWord" @click="dayClicked(day)">
                    <goal-status-icon :size="24" :status="day.goalStatus" />
                    <div class="day">
                        <span>{{ day.dayShort }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import goalStatusIcon from "ViewComponent/goal-status-icon.vue";

    export default {
        name: 'week-goals',
        components: {
            goalStatusIcon
        },
        props: {
            goalsData: {
                type: Array
            },
        },
        methods: {
            dayClicked(day) {
                if (!day.missing) {
                    this.$emit('week-day-selected', day);
                }
            }
        },
    };
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.week-goals {
        display: flex;
        flex-direction: column;
        > div.week {
            display: flex;
            justify-content: space-between;
            > div.goal {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-content: center;
                margin: 0 .25rem;
                &:hover:not(.missing) {
                    cursor: pointer;
                    opacity: 0.8;
                }
                > div.day {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 0.1rem .25rem;
                    border-radius: pxToRem(4);
                    font-size: fontSize(12);
                    margin-top: pxToRem(8);
                    min-width: pxToRem(32);
                    > svg {
                        visibility: hidden;
                        &.visible {
                            visibility: visible;
                        }
                    }
                }
                &.today {
                    > div.day {
                        background-color: $colorBackgroundLighter;
                    }
                }
            }

            @include respond-to('small') {
                justify-content: space-between;
                > div.set {
                    margin: 0;
                }
            }
        }
    }
</style>
