var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "add-word_svg__add_word_svg__ic-add-word-paper",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass: "add-word_svg__add_word_svg__ic-add-word-paper__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass:
                "add-word_svg__add_word_svg__ic-add-word-paper__icon",
              attrs: { fill: "#2C3143", "fill-rule": "nonzero" },
            },
            [
              _c("path", {
                staticClass:
                  "add-word_svg__add_word_svg__ic-add-word-paper__icon__path-19",
                attrs: {
                  d: "M13 19.5a1 1 0 01-1.993.117L11 19.5v-15a1 1 0 011.993-.117L13 4.5v15z",
                },
              }),
              _c("path", {
                staticClass:
                  "add-word_svg__add_word_svg__ic-add-word-paper__icon__path-19",
                attrs: {
                  d: "M19.5 11a1 1 0 01.117 1.993L19.5 13h-15a1 1 0 01-.117-1.993L4.5 11h15z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }