var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { ref: "slider", staticClass: "slider" }, [
    _c(
      "section",
      { staticClass: "labels" },
      _vm._l(this.ticks, function (tick) {
        return _c(
          "span",
          {
            staticClass: "label-container",
            class: { recommended: tick.recommended },
            style: { left: `${tick.proportion * 100}%` },
            on: {
              click: function ($event) {
                return _vm.clickTick(tick)
              },
            },
          },
          [_c("span", { staticClass: "label" }, [_vm._v(_vm._s(tick.label))])]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "slider_body",
        staticClass: "slider-body-container",
        on: { click: _vm.clickBody, mousedown: _vm.slideStart },
      },
      [
        _c(
          "section",
          {
            staticClass: "slider-body",
            class: { tracking: _vm.tracking },
            style: { background: _vm.slider_background },
          },
          [
            _vm._l(this.ticks, function (tick) {
              return _c("div", {
                staticClass: "slider-tick",
                style: {
                  left: `${tick.proportion * 100}%`,
                  visibility: tick.first || tick.last ? "hidden" : "inherit",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clickTick(tick)
                  },
                },
              })
            }),
            _vm._v(" "),
            _c("knob", {
              staticClass: "slider-knob",
              style: { left: `${_vm.slider_position * 100}%` },
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }