var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "name" },
    [
      _c("h2", {
        domProps: {
          innerHTML: _vm._s(this.$i18n("account_profile_account_name_title")),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "field", class: { focused: this.inputFocused } },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M15.2 14L18.2377 15.9789C19.3391 16.6167 20 17.6622 20 18.7796V21H4V18.7796C4 17.6622 4.49275 16.5045 5.6 15.8667L8.8 14",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M12 3C10.3417 3 9 4.32763 9 5.96866V8.03134C9 9.67236 10.3417 11 12 11C13.6583 11 15 9.67236 15 8.03134V5.96296C15 4.32764 13.6526 3 12 3Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nameLocal,
                expression: "nameLocal",
              },
            ],
            ref: "name",
            attrs: {
              type: "text",
              name: "name",
              placeholder: _vm.namePlaceholder,
            },
            domProps: { value: _vm.nameLocal },
            on: {
              focus: function ($event) {
                _vm.inputFocused = true
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.save.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.nameLocal = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          !_vm.inputFocused
            ? _c("edit-icon", {
                nativeOn: {
                  click: function ($event) {
                    return _vm.focusInput.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-down-fade" } }, [
        _vm.inputFocused && !_vm.nameNotChanged
          ? _c(
              "div",
              { staticClass: "actions" },
              [
                _c("v-button", {
                  attrs: {
                    i18n: { id: "account_profile_cancel_btn" },
                    filled: false,
                    size: "medium",
                    color: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.cancel.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-button", {
                  attrs: {
                    i18n: { id: "account_profile_input_action_save" },
                    filled: true,
                    disabled: !_vm.contentIsValid,
                    size: "medium",
                    color: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.save.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }