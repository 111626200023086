var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "goal-status-icon" },
    [
      _vm.size === 24
        ? [
            _vm.status === "none"
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        x: "1",
                        y: "1",
                        width: "22",
                        height: "22",
                        rx: "2",
                        stroke: _vm.onGray ? "#D5DBE5" : "#EEF0F4",
                        "stroke-width": "2",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "progress"
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", { attrs: { opacity: "0.2" } }, [
                      _c("rect", {
                        attrs: {
                          width: "24",
                          height: "24",
                          rx: "3",
                          fill: "#AC6ED1",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M9 15L15 9",
                        stroke: "#AC6ED1",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "done"
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", { attrs: { opacity: "0.1" } }, [
                      _c("rect", {
                        attrs: {
                          width: "24",
                          height: "24",
                          rx: "4",
                          fill: "#43D281",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M8 12L11 15L17 9",
                        stroke: "#43D281",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.size === 40
        ? [
            _vm.status === "none"
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "40",
                      height: "40",
                      viewBox: "0 0 40 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        width: "40",
                        height: "40",
                        rx: "5",
                        fill: "#B2B8CB",
                        "fill-opacity": "0.2",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M15 18V23.2C15 25.851 17.2386 28 20 28C22.7614 28 25 25.851 25 23.2V16.8C25 14.149 22.7614 12 20 12C18.8744 12 17.8357 12.3719 17 12.9996C16.6212 13.2842 16.2842 13.6212 15.9996 14",
                        stroke: "#B2B8CB",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "progress"
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "40",
                      height: "40",
                      viewBox: "0 0 40 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", { attrs: { opacity: "0.2" } }, [
                      _c("rect", {
                        attrs: {
                          width: "40",
                          height: "40",
                          rx: "5",
                          fill: "#AC6ED1",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M15 25L25 15",
                        stroke: "#AC6ED1",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "done"
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "40",
                      height: "40",
                      viewBox: "0 0 40 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", { attrs: { opacity: "0.2" } }, [
                      _c("rect", {
                        attrs: {
                          width: "40",
                          height: "40",
                          rx: "5",
                          fill: "#43D281",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M13 20L18 25L27 16",
                        stroke: "#43D281",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }