var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M1 6v1.773C1 8.738 1.5 10 3 10s2-1.25 2-2.227V6M3 10v9M23 19V6c-1.228 0-3 .72-3 2.4V12M11.375 13C9.527 13 8 11.842 8 10.5S9.527 8 11.375 8c2.25 0 3.125 1 3.125 1h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2M17 18s1.4-.434 2-1.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 19.23c0 .22-.12.428-.33.497-1.124.369-3.233.257-5.051.257-1.396 0-5.619-.183-5.619-1.98 0-2.435 6.152-1.969 7.944-2.003 1.168-.007 2.085.03 2.739.271.203.075.317.28.317.496v2.462z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }