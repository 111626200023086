<template>
    <div class="weekly grid-item">
        <div class="header">
            <div class="title">
                <h2 v-html="this.$i18n('hub_section_sets_goal_title')"/>
                <h4>{{ cardsOutOfCards }}</h4>
            </div>
            <a class="change-goal" v-html="this.$i18n('hub_section_daily_goal_button')" @click="changeGoal" />
        </div>
        <div class="weeks">
            <template v-if="this.previousWeekGoalsData">
                <week-goals v-bind:goals-data="this.previousWeekGoalsData" class="previousWeek" v-on="$listeners" />
                <div class="separator"/>
            </template>
            <week-goals v-bind:goals-data="this.thisWeekGoalsData" class="currentWeek" v-on="$listeners" />
        </div>
    </div>
</template>

<script>
    import WeekGoals from './week-goals.vue';
    import moment from "moment";
    import i18nUtils from "Util/i18n.js";
    import { Awards } from "Modules/awards.js";
    import { EventBus } from "Util/vue-event-bus.js";
    import UserManager from "Modules/usermanager.js";

    export default {
        name: 'weekly',
        components: {
            WeekGoals,
        },
        props: {
            course: Object
        },
        data() {
            return {
                today: moment().local().startOf('day'),
                previousWeekGoalsData: null,
                thisWeekGoalsData: null,
                dynamicGoalSize: Awards.getDynamicGoalSize()
            };
        },
        computed: {
            cardsOutOfCards () {
                const daily_cards_count = UserManager.instance.getUser().getCourse().getStatistics().getTodayData().all_units.total;
                return i18nUtils.prop('hub_section_sets_goal_subtitle', { daily_cards_count, daily_cards_goal: this.dynamicGoalSize });
            }
        },
        methods: {
            dynamicGoalSizeChanged(size) {
                this.dynamicGoalSize = size;
            },
            changeGoal() {
                EventBus.$emit('change-dynamic-goal');
            },
            combineAndGetGoalsData(fromMoment = null, toMoment = null) {
                const statistics = (this.course) ? this.course.getStatistics() : null;

                if (!statistics) {
                    return;
                }

                const weekData = statistics.getHistoryDataFromTo(fromMoment, toMoment);
                let week = [];

                weekData.forEach(day => {
                    let _day = {};
                    let correct_ratio = day.repeat_units.total !== 0 ? day.repeat_units.correct / day.repeat_units.total : 0;
                    _day.dayNumber = moment(day.ts).isoWeekday();
                    _day.dayLetter = i18nUtils.prop(_day.dayNumber, null, null, 'weekday_letter');
                    _day.dayShort = i18nUtils.prop(_day.dayNumber, null, null, 'weekday_short');
                    _day.dayWord = i18nUtils.prop(_day.dayNumber, null, null, 'weekday');
                    _day.isToday = this.today.isSame(moment(day.ts).startOf('day'));
                    _day.moment = moment(day.ts);
                    _day.statistics = {
                        correct_ratio: correct_ratio,
                        words: day.new_units.total,
                        cards: day.all_units.total,
                        all_units: day.all_units,
                        study_time: day.study_time,
                    };

                    if (day.awards_objects.length > 0) {
                        let _daySetAwards = Awards.getSetAwardsInfo(day.awards_objects);
                        if (_daySetAwards.setsCompleted > 0) {
                            _day.goalStatus = 'done';
                        }
                    } else if (day.all_units.total > 0 && day.all_units.total < this.dynamicGoalSize) {
                        _day.goalStatus = 'progress';
                    } else {
                        _day.goalStatus = 'none';
                    }

                    week.push(_day);
                });

                // add missing days to the end of week, when needed
                if (week.length < 7) {
                    const lastWeekDay = week.slice(-1)[0];
                    const daysMissing = 7 - week.length;

                    for (let i = 0; i < daysMissing; i++) {
                        let missingDay = {};
                        missingDay.dayNumber = (lastWeekDay.dayNumber + i) % 7 + 1;
                        missingDay.dayLetter = i18nUtils.prop(missingDay.dayNumber, null, null, 'weekday_letter');
                        missingDay.dayShort = i18nUtils.prop(missingDay.dayNumber, null, null, 'weekday_short');
                        missingDay.dayWord = i18nUtils.prop(missingDay.dayNumber, null, null, 'weekday');
                        missingDay.setIcon = 'set-0';
                        missingDay.setsCompleted = 0;
                        missingDay.missing = true;

                        week.push(missingDay);
                    }
                }

                return week;
            },
            setThisWeekGoalsData() {
                this.thisWeekGoalsData = this.combineAndGetGoalsData();
            },
            setPreviousWeekGoalsData() {
                let today = moment().local();
                let todayWeekAgo = today.clone().subtract(1, 'week');
                let previousWeekStartMoment = todayWeekAgo.clone().startOf('isoWeek');
                let previousWeekEndMoment = todayWeekAgo.clone().endOf('isoWeek');
                this.previousWeekGoalsData = this.combineAndGetGoalsData(previousWeekStartMoment, previousWeekEndMoment);
            },
            listenEventBus() {
                EventBus.$on('dynamic-goal:size-changed', this.dynamicGoalSizeChanged);
            }
        },
        created: function () {
            this.setThisWeekGoalsData();
            this.setPreviousWeekGoalsData();
            this.listenEventBus();
        },
        watch: {
            course(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.setThisWeekGoalsData();
                    this.setPreviousWeekGoalsData();
                }
            }
        },
        beforeDestroy() {
            EventBus.$off('dynamic-goal:size-changed', this.dynamicGoalSizeChanged);
        }
    };
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.weekly {
        grid-area: weekly;

        > div.header {
            display: flex;
            flex-direction: row;
            > div.title {
                > h2 {
                    margin-bottom: .5rem;
                }
                > h4 {
                    font-size: fontSize(14);
                    color: $colorSourceSecondary;
                }
                margin-bottom: 1rem;
            }
            > a.change-goal {
                margin-left: auto;
                cursor: pointer;
                color: $colorPurplePrimary;
                &:hover {
                    opacity: 0.6;
                }
            }
        }

        > div.weeks {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-template-areas: "previousWeek separator currentWeek";

            @include respond-to('large') {
                grid-template-columns: 1fr;
                grid-template-areas: "currentWeek";
            }

            @include respond-to('medium') {
                grid-template-columns: 1fr auto 1fr;
                grid-template-areas: "previousWeek separator currentWeek";
            }

            @include respond-to('small-medium') {
                grid-template-columns: 1fr;
                grid-template-areas: "currentWeek";
            }


            > div.week-goals {
                &.previousWeek,
                &.currentWeek {
                    justify-content: end;
                }

                &.previousWeek {
                    grid-area: previousWeek;
                    @include respond-to('large') {
                        display: none;
                    }
                    @include respond-to('medium') {
                        display: flex;
                    }
                    @include respond-to('small-medium') {
                        display: none;
                    }

                    opacity: 0.4;
                }

                &.currentWeek {
                    grid-area: currentWeek;
                }
            }

            > div.separator {
                grid-area: separator;
                width: pxToRem(1);
                background-color: $colorSourceTertiary;
                margin: 1rem;
                @include respond-to('large') {
                    display: none;
                }
                @include respond-to('medium') {
                    display: block;
                }
                @include respond-to('small-medium') {
                    display: none;
                }
            }
        }
    }
</style>
