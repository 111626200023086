var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "languages",
      class: { "single-selected": _vm.single_selected },
    },
    [
      _vm.heading !== null ? _c("h2", [_vm._v(_vm._s(_vm.heading))]) : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.languagesOrdered, function (language) {
        return _c(
          "div",
          {
            staticClass: "language",
            class: { selected: language.selected },
            on: {
              click: function ($event) {
                return _vm.$emit("language_selected", language)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "highlight" },
              [
                _c("course-icon", { attrs: { value: language.icon_id } }),
                _vm._v(" "),
                _c("div", { staticClass: "name-container" }, [
                  _c("span", {
                    staticClass: "name",
                    domProps: { innerHTML: _vm._s(language.title) },
                  }),
                  _vm._v(" "),
                  language.subtitle
                    ? _c("span", { staticClass: "name-subtitle" }, [
                        _vm._v(_vm._s(language.subtitle)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                language.attrs.new
                  ? _c("span", {
                      staticClass: "new",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$i18n("register_languages_language_new")
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm.show_language_not_listed
        ? _c(
            "div",
            {
              staticClass: "language not-listed",
              on: {
                click: function ($event) {
                  return _vm.$emit("language_not_listed")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "highlight" },
                [
                  _c("course-icon", { attrs: { value: "unknown" } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "name-container" }, [
                    _c("span", {
                      staticClass: "name",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$i18n(
                            "register_select_course_language_name_unknown"
                          )
                        ),
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }