import $ from 'jquery';
import Backbone from 'backbone';

import OverlayView from '../overlay/overlay.view.js';
import EventUtils from '../../util/event.js';
import i18nUtils from '../../util/i18n.js';
import ControllerManager from '../../modules/controller.manager.js';
import renderTemplate from '../../util/template.renderer.js';
import _ from 'lodash';


const FeedbackView = Backbone.View.extend({

    events: {
        'click button.select-category': 'selectCategory',
        'click button.send': 'sendFeedback',
        'click button.back': 'goBack',
        'click button.cancel, button.close': 'close',
        'click div.close': 'close',
        'input textarea.feedback-content': 'processInput',
        'focus textarea.feedback-content': 'focusTextarea'
    },

    initialize: function () {
        console.log("Feedback view init");
    },

    remove: function () {
        if (this._overlayView !== undefined) {
            this._overlayView.off();
            this._overlayView.remove();
        }
        this.trigger('remove');
        this.unbind();
        Backbone.View.prototype.remove.call(this);
    },

    render: function (data) {
        renderTemplate('feedback/main', data).then(output => {
            const $element = $(output);
            this.setElement($element);
            $('body').append($element);
            this._postRender();
        });
        return this;
    },

    _postRender: function () {},

    showPostFeedback: async function (data) {
        const output = await renderTemplate('feedback/post-feedback', data);

        this.$el.find('div.modal.post-feedback').html(output);
        this.$el.find('div.modal.post-feedback').addClass('show');
        this.$el.find('div.modal.feedback').removeClass('show');
    },

    _selectCategory: function ($element) {
        $element.addClass('selected');
        $element.siblings().removeClass('selected');
        this.$el.find('section.select-category').removeClass('active');
        this.$el.find('section.input').addClass('active');
        this.$el.find('section.input > footer button.cancel').prop('disabled', false);
        this.$el.find('section.input > header input[value="okay"]').prop('checked', true);
        this.$el.find('section.input > header > h2.feedback-title').text(i18nUtils.prop($element.data('category'), null, null, 'feedback_category_title'));
        setTimeout(() => {
            this.$el.find('textarea.feedback-content').focus();
        }, 25);
    },

    selectCategory: function (event) {
        this._selectCategory($(event.currentTarget));
    },

    sendFeedback: function () {
        var feedbackContent = this.$el.find('textarea.feedback-content').val(),
            mood = this.$el.find('input[name="mood-evaluation"]:checked').val(),
            category = this.$el.find('button.select-category.selected').data('category');

        ControllerManager.instance.getController('Feedback')
            .submitFeedback(feedbackContent, mood, category, this);
    },

    goBack: function () {
        this.$el.find('button.select-category').removeClass('selected');
        this.$el.find('section.input').removeClass('active');
        this.$el.find('section.select-category').addClass('active');
        this.$el.find('section.input > footer button.cancel').prop('disabled', true);
        this.$el.find('section.input > header input[value="okay"]').prop('checked', false);
    },

    show: function (category) {
        var self = this;

        setTimeout(function () {
            Backbone.trigger('modalOpened');
            self.$el.find('div.modal.feedback').addClass('show');
            self._overlayView = new OverlayView({ noDismiss: true });
            self._overlayView.on("dismiss", self.tryClosing, self);
            self._overlayView.render();

            if (category !== undefined) {
                self._selectCategory(self.$el.find(`button.select-category[data-category="${category}"]`));
            }
        }, 10);
    },

    close: function () {
        var self = this,
            $modal = this.$el.find('div.modal.show');

        this._overlayView.off();
        EventUtils.oneTransitionEnd($modal, function () {
            self.remove();
        }, 500);

        this._overlayView.remove();
        delete this._overlayView;

        $modal.removeClass('show');

        Backbone.trigger('modalClosed');
    },

    tryClosing: function() {
        const feedbackContent = this.$el.find('textarea.feedback-content').val();
        if (_.isEmpty(feedbackContent)) {
            this.close();
        }
    },

    processInput: function (event) {
        var $target = $(event.currentTarget);
        if ($target.val().length > 0) {
            this.$el.find('button.send').prop('disabled', false);
        } else {
            this.$el.find('button.send').prop('disabled', true);
        }
    },

    focusTextarea: function () {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 100);
    }
});

export default FeedbackView;
