var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "7",
          height: "12",
          viewBox: "0 0 7 12",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.276 6.368a1 1 0 00-1.802 0L.691 10.066a1 1 0 00.901 1.434h3.565a1 1 0 00.901-1.434L4.276 6.368z",
          fill: "#FFBAB0",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#FFBAB0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }