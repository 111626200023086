var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.service &&
    this.service.hasOwnProperty("subscription") &&
    !this.service.is_voucher
    ? _c(
        "div",
        { staticClass: "subs-item-single" },
        [
          _vm.singlePeriodDiscount && this.service.isRecurringAndActive
            ? _vm._l(_vm.singlePeriodDiscount, function (item) {
                return _c("div", { staticClass: "section" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.startingTitle)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "details" },
                    [
                      item.name === "single-period-discount"
                        ? _c("p", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.service.title)),
                          ])
                        : _c("p", {
                            staticClass: "renewal",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$i18n(
                                  "my_account_subscription_renewal_text"
                                )
                              ),
                            },
                          }),
                      _vm._v(" "),
                      item.is_voucher
                        ? _c("p", { staticClass: "voucher" }, [
                            _vm._v("voucher"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "price-period",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getPricePeriod(item.amount, item.duration)
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.appStoresChangeLabel
                        ? _c("p", { staticClass: "footnote" }, [
                            _vm._v(_vm._s(_vm.appStoresChangeLabel)),
                          ])
                        : item.name !== "single-period-discount"
                        ? _c("v-button", {
                            attrs: {
                              size: "medium",
                              i18n: { id: "account_subscription_cancel" },
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.cancelSubscriptionPrompt.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              })
            : [
                _vm.isRecurring && _vm.activeSinceIsInTheFuture
                  ? [
                      _vm.service.is_on_free_trial
                        ? _c("div", { staticClass: "section" }, [
                            _c("h3", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  this.startingTitle(
                                    this.service.active_since_ts
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "details" }, [
                              _c("p", {
                                staticClass: "name",
                                domProps: {
                                  innerHTML: _vm._s(
                                    this.$i18n(
                                      "my_account_subscription_free_trial_text"
                                    )
                                  ),
                                },
                              }),
                            ]),
                          ])
                        : _c("div", { staticClass: "section" }, [
                            _c("h3", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  this.startingTitle(
                                    this.service.active_since_ts
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "details" },
                              [
                                [
                                  _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.subscriptionName)),
                                  ]),
                                  _vm._v(" "),
                                  _vm.service.is_voucher
                                    ? _c("p", { staticClass: "voucher" }, [
                                        _vm._v("voucher"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.pricePeriod
                                    ? _c("p", {
                                        staticClass: "price-period",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.pricePeriod),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                              ],
                              2
                            ),
                          ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "section" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.upcomingTitle)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "details" },
                    [
                      _vm.service.cancelled
                        ? _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n(
                                  "my_account_subscription_cancelled_text"
                                )
                              ),
                            },
                          })
                        : [
                            [
                              _vm.isRecurring
                                ? _c("p", {
                                    staticClass: "renewal",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        this.$i18n(
                                          "my_account_subscription_renewal_text"
                                        )
                                      ),
                                    },
                                  })
                                : _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.subscriptionName)),
                                  ]),
                              _vm._v(" "),
                              _vm.pricePeriod
                                ? _c("p", {
                                    staticClass: "price-period",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.pricePeriod),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            _vm._v(" "),
                            _vm.appStoresChangeLabel
                              ? _c("p", { staticClass: "footnote" }, [
                                  _vm._v(_vm._s(_vm.appStoresChangeLabel)),
                                ])
                              : _vm.service.enableCancellation
                              ? _c("v-button", {
                                  attrs: {
                                    size: "medium",
                                    i18n: { id: "account_subscription_cancel" },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.cancelSubscriptionPrompt.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ]),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }