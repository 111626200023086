<template>
    <div class="hotkeys-modal" @click.self="close" v-if="isOpen">
        <div class="hotkeys-modal-window">
            <CloseButton @click="close" class="close" />
            <h2 class="title" v-html="this.$i18n('guess_game_hotkeys_modal_title')" />
            <div class="content">
                <div class="item">
                    <div class="key" v-html="this.$i18n('guess_game_hotkeys_modal_right_key')" :class="{ flash: activeKeyCode === 39 }" />
                    <div class="label" v-html="this.$i18n('guess_game_hotkeys_modal_right_key_description')" />
                </div>
                <div class="item">
                    <div class="key" v-html="this.$i18n('guess_game_hotkeys_modal_left_key')" :class="{ flash: activeKeyCode === 37 }" />
                    <div class="label" v-html="this.$i18n('guess_game_hotkeys_modal_left_key_description')" />
                </div>
                <div class="item">
                    <div class="key" v-html="this.$i18n('guess_game_hotkeys_modal_up_key')" :class="{ flash: activeKeyCode === 38 }" />
                    <div class="label" v-html="this.$i18n('guess_game_hotkeys_modal_up_key_description')" />
                </div>
                <div class="item">
                    <div class="key" v-html="this.$i18n('guess_game_hotkeys_modal_down_key')" :class="{ flash: activeKeyCode === 40 }" />
                    <div class="label" v-html="this.$i18n('guess_game_hotkeys_modal_down_key_description')" />
                </div>
                <div class="item">
                    <div class="key" v-html="this.$i18n('guess_game_hotkeys_modal_spacebar_key')" :class="{ flash: activeKeyCode === 32 }" />
                    <div class="label" v-html="this.$i18n('guess_game_hotkeys_modal_spacebar_key_description')" />
                </div>
                <div class="item">
                    <div class="key" v-html="this.$i18n('guess_game_hotkeys_modal_enter_key')" :class="{ flash: activeKeyCode === 13 }" />
                    <div class="label" v-html="this.$i18n('guess_game_hotkeys_modal_enter_key_description')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import CloseButton from "Images/close-24x24.svg";
    import { EventBus } from "Util/vue-event-bus";

    export default {
        name: 'hotkeys-modal',
        components: {
            CloseButton
        },
        data() {
            return {
                isOpen: false,
                activeKeyCode: null,
                keyDownTimeout: null
            }
        },
        methods: {
            open() {
                document.body.classList.add('modal-open');
                this.isOpen = true;
            },
            close() {
                this.isOpen = false;
                document.body.classList.remove('modal-open');
            },
            clearActiveKeyCode(newKeyCode) {
                if (newKeyCode !== this.activeKeyCode) {
                    this.activeKeyCode = null;
                    clearTimeout(this.keyDownTimeout);
                }
            },
            keyDown(event) {
                if (event && event.keyCode) { // close on ESC key
                    switch (event.keyCode) {
                        case 27: // ESC Key
                            this.close();
                            break;
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 32:
                        case 13:
                            // flash key
                            this.clearActiveKeyCode(event.keyCode);
                            this.activeKeyCode = event.keyCode;
                            break;
                    }
                }
                this.keyDownTimeout = setTimeout(() => {
                    this.clearActiveKeyCode();
                }, 500);
            },
            listenEventBus() {
                EventBus.$on('guess:hotkeys-modal-open', this.open);
                EventBus.$on('guess:hotkeys-modal-close', this.close);
            },
            unListenEventBus() {
                EventBus.$off('guess:hotkeys-modal-open', this.open);
                EventBus.$off('guess:hotkeys-modal-close', this.close);
            }
        },
        created() {
            this.listenEventBus();
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
        },
        beforeDestroy() {
            this.unListenEventBus();
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.hotkeys-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43, 81, 142, 0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        > div.hotkeys-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 40vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
            > h2.title {

            }

            > div.content {
                margin-top: pxToRem(24);
                display: flex;
                flex-direction: column;
                gap: .5rem;
                > div.item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;
                    border-top: 1px solid $colorBackgroundLighter;
                    padding: .5rem 0 0;
                    &:first-child {
                        border-top: none;
                    }
                    > div.key {
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: pxToRem(4) pxToRem(12);
                        background: $colorBackgroundDarker;
                        border-radius: pxToRem(4);
                        &.flash {
                            animation-duration: 500ms;
                            animation-name: flash;
                        }
                    }
                }
            }
        }

        @keyframes flash {
            0% {
                transform: scale(0.85);
                box-shadow: 0 0 0 0 rgba(213, 219, 229, 0.7);
            }

            70% {
                transform: scale(1.1);
                box-shadow: 0 0 0 10px rgba(213, 219, 229, 0);
            }

            100% {
                transform: scale(1);
                box-shadow: 0 0 0 0 rgba(213, 219, 229, 0);
            }
        }
    }
</style>
