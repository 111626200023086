var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M6.9 8.8C7 7.8 7.9 7 9 7h.5c.6 0 1 .4 1 1s-.4 1-1 1H9c-.1 0-.1.1-.2.1l-.6 4.4 1.5-.5c.3-.1.6-.1.9.1.3.2.4.5.4.8v8c0 .6-.4 1-1 1s-1-.4-1-1v-6.6l-1.7.6c-.3.1-.7 0-1-.2-.2-.2-.3-.5-.3-.8l.9-6.1zM13 8c0-.6.4-1 1-1h.5c1 0 2 .7 2.2 1.7l1.3 5c.1.5-.2 1.1-.7 1.2-.5.1-1.1-.2-1.2-.7l-1.3-5c0-.1-.1-.2-.3-.2H14c-.6 0-1-.4-1-1zm.8 7c.5-.1 1.1.3 1.2.8l1 6c.1.5-.3 1.1-.8 1.2-.5.1-1.1-.3-1.2-.8l-1-6c-.1-.6.3-1.1.8-1.2z",
        },
      }),
      _c("circle", {
        attrs: { fill: "#8EA3EE", cx: "11.5", cy: "3.5", r: "2.5" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }