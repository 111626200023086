var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "20",
          height: "20",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M5.228 9.498C3.331 8.98 2 7.858 2 6.476V3.143h3V2h10v1.143h3v3.333c0 1.383-1.33 2.504-3.228 3.022A5.008 5.008 0 0111 12.9v2.226A4.007 4.007 0 0113.874 18H6.126A4.007 4.007 0 019 15.126V12.9a5.008 5.008 0 01-3.772-3.402zm-.21-1.062A5.065 5.065 0 015 8V4.095H2.941v2.381c0 .777.817 1.517 2.078 1.96zM15 4.096V8c0 .147-.006.292-.019.436 1.26-.443 2.078-1.183 2.078-1.96v-2.38H15z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }