var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "25",
          height: "24",
          viewBox: "0 0 25 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("circle", {
        attrs: { cx: "12.5", cy: "13", r: "9", fill: "#424960" },
      }),
      _c("path", {
        attrs: {
          d: "M12.5 8v6l3 1",
          stroke: "#fff",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.5 1h4M12.5 2V1M19.5 4l2 2M5.5 4l-2 2",
          stroke: "#424960",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }