
'use strict';
import ControllerFactory from '../modules/controller.factory.js';

import ModalMessagesView from '../view/util/modal.messages.view.js';

import UserManager from '../modules/usermanager.js';
import {ACTION_GOAL, LIMIT_ACTION} from '../modules/course/limits.js';
import ExportUtils from '../util/export.js';
import Backbone from 'backbone';

import {
    BlockedFeaturePopupUpsell,
    HardPaywallBlockedFeaturePopupUpsell,
    HardPaywallTrialEndPopup,
    HardPaywallSubscriptionEndPopup,
    TrialAvailablePopup,
    ShortCoursePopup,
    ShortCoursePopupHub
} from '../data/popups.js';

export class ModalMessagesController {

    constructor () {
        ExportUtils.export('app.controller.modalMessages', this);
        this._popupAction = null;
    }

    show (message, options) {

        switch (message) {

            case 'updated_terms': {
                let updatedTermsView = new ModalMessagesView({
                    data: {
                        illustration: true,
                        message: message,
                        link: `<a href="${options.changes_url}" target="_blank">[[body]]</a>`
                    },
                    overlayNoDismiss: true
                });

                updatedTermsView.render();
                updatedTermsView.show();
                return updatedTermsView;
            }

            case 'lesson_assigned': {
                const lessonAssignedView = new ModalMessagesView({
                    data: {
                        message,
                        variation_name: options.name,
                        variation_units: options.units
                    }
                });

                lessonAssignedView.render();
                lessonAssignedView.show();
                return lessonAssignedView;
            }

            default: {
                console.error(`Unknown message type="${message}"`);
                break;
            }
        }
    }

    showPopup (action, upsell_context, actionGoal) {
        // avoid duplicate popups to pop
        if (this._popupAction && this._popupAction === action) {
            return;
        }

        this._popupAction = action;
        const _user = UserManager.instance.getUser();
        const trialDays = _user.getSubscription().getTrialDurationAsDays();

        let popupData = null;
        const popupVariables = {
            store_uri: 'lingvist:store?catalog=unlimited'
        };

        let modifiedUpsellContext = Object.assign({}, upsell_context, { trial_days: trialDays });
        let dismissible = false;
        let navigateToHubOnClose = false;

        if (action === LIMIT_ACTION.HARD_PAYWALL) {
            const navigateOnDismissPages = ['classrooms'];
            const page = Backbone.history.fragment;
            navigateToHubOnClose = navigateOnDismissPages.includes(page);

            popupVariables.hasExpiredSubscription = UserManager.instance.getUser().getSubscription().hasExpiredSubscription();
            popupData = HardPaywallBlockedFeaturePopupUpsell.data(popupVariables);
            dismissible = true;
        } else if (actionGoal === ACTION_GOAL.UPSELL) {
            popupData = BlockedFeaturePopupUpsell.data(popupVariables);
        }

        const popupView = new ModalMessagesView({
            data: {
                popup: popupData,
                addDefaultParams: false,
                context: modifiedUpsellContext,
                goal: actionGoal,
                dismissible: dismissible
            },
            template: 'modal-upsell'
        });

        popupView.on('close', callback => {
            if (navigateToHubOnClose) {
                Backbone.history.navigate('hub', { trigger: true });
            }
            callback();
            this._popupAction = null;
        });

        popupView.show();

        return Promise.resolve(popupView);
    }

    showTrialEnd () {
        // avoid duplicate popups to pop
        if (this._popupAction && this._popupAction === 'trial-end') {
            return;
        }
        this._popupAction = 'trial-end';
        let popupData = HardPaywallTrialEndPopup.data({});

        const popupView = new ModalMessagesView({
            data: {
                popup: popupData,
                addDefaultParams: false,
                // context: modifiedUpsellContext,
                // goal: actionGoal,
                dismissible: true
            },
            template: 'modal-upsell'
        });

        popupView.on('close', callback => {
            callback();
            this._popupAction = null;
        });

        popupView.show();

        return Promise.resolve(popupView);
    }

    showSubscriptionEnd () {
        // avoid duplicate popups to pop
        if (this._popupAction && this._popupAction === 'subscription-end') {
            return;
        }
        this._popupAction = 'subscription-end';

        let popupData = HardPaywallSubscriptionEndPopup.data({});

        const popupView = new ModalMessagesView({
            data: {
                popup: popupData,
                addDefaultParams: false,
                dismissible: true
            },
            template: 'modal-upsell'
        });

        popupView.on('close', callback => {
            callback();
            this._popupAction = null;
        });

        popupView.show();

        return Promise.resolve(popupView);
    }

    showTrialAvailable (trial_days) {
        if (this._popupAction && this._popupAction === 'trial-available') {
            return null;
        } else {
            this._popupAction = 'trial-available';

            const popupView = new ModalMessagesView({
                data: {
                    popup: TrialAvailablePopup.data(),
                    context: {trial_days},
                    addDefaultParams: false,
                    dismissible: false
                },
                template: 'modal-upsell'
            });

            popupView.on('close', () => {
                this._popupAction = null;
                popupView.off();
            });

            popupView.show();  // Deliberately ignoring promise

            return popupView;
        }
    }

    showShortCourse (name, course_words_rounded, cb = null) {
        if (this._popupAction && this._popupAction === 'short-course') {
            return;
        }
        this._popupAction = 'short-course';

        const popupView = new ModalMessagesView({
            data: {
                popup: ShortCoursePopup.data({ name, course_words_rounded }),
                addDefaultParams: false,
                dismissible: true
            },
            template: 'modal-upsell'
        });

        popupView.on('close', callback => {
            callback();
            this._popupAction = null;
        });

        popupView.on('_custom', () => {
            if (cb !== null) {
                cb();
            }
        });

        popupView.show();

        return Promise.resolve(popupView);
    }

    showShortCourseHub (name, course_words_rounded) {
        if (this._popupAction && this._popupAction === 'short-course-hub') {
            return;
        }
        this._popupAction = 'short-course-hub';

        const popupView = new ModalMessagesView({
            data: {
                popup: ShortCoursePopupHub.data({ name, course_words_rounded }),
                addDefaultParams: false,
                dismissible: true
            },
            template: 'modal-upsell'
        });

        popupView.on('close', callback => {
            callback();
            this._popupAction = null;
        });

        popupView.show();

        return Promise.resolve(popupView);
    }
}

export const modal_messages_controller_factory = new ControllerFactory(ModalMessagesController);

