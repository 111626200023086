
import getConfigValue from '../../util/configuration.js';

const STATE_STRING_LENGTH = 30;

function getRandomStateString () {
    return new Array(STATE_STRING_LENGTH+1).join((Math.random().toString(36)+'00000000000000000').slice(2, 18)).slice(0, STATE_STRING_LENGTH);
}

export function getRedirectURI (sso_flow) {
    return `${window.location.protocol}//${window.location.host}/oauth?provider=apple${sso_flow ? '&sso_flow=1' : ''}`;
}

// https://learn.com.dev.keel24.eu/oauth?provider=apple&sso_flow=1

export function showAppleLogin (sso_flow) {
    let check_state = getRandomStateString();
    // https://developer.apple.com/documentation/signinwithapplejs/incorporating_sign_in_with_apple_into_other_platforms
    window.location = `https://appleid.apple.com/auth/authorize` +
                      `?client_id=${encodeURIComponent(getConfigValue('apple-app-id'))}` +
                      `&response_type=code` +
                      `&state=${encodeURIComponent(check_state)}` +
                      `&scope=${encodeURIComponent('email name')}` +
                      `&response_mode=form_post` +  // Only supported mode if we want email or name scopes:(
                      `&redirect_uri=${encodeURIComponent(getRedirectURI(sso_flow))}`;
}
