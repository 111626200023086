var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#E32529",
          d: "M21 14.5c0 3.8-5.2 6.8-10.9 6.8C4.5 21.3 0 18.8 0 14.9 0 10.4 5.8 6 7.9 5.2c4.5-1.8 4.4 1.4 3.9 3 1.1-.1 2.6-.7 4-.8 1.6 0 2.8.7 1.9 3.5 2.5 1.3 3.3 2.1 3.3 3.6z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d: "M16.9 14.9c0 2.6-3.5 5.1-7.5 5.1s-6.9-1.6-6.9-4.5c0-2.7 3.5-5 7.8-5 3.9 0 6.6 1.8 6.6 4.4z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13 15.6c0 2-2.2 3.5-4.3 3.5-2.1 0-3.5-1.2-3.5-3 0-1.9 1.9-3.7 4.2-3.7 2.2 0 3.6 1.3 3.6 3.2z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#FFF",
          d: "M9.1 16.2c0 .8-.6 1.3-1.3 1.3-.7 0-1.1-.4-1.1-1 0-.7.6-1.3 1.5-1.3.5.1.9.4.9 1zm1.2-1.2c0 .3-.3.5-.5.5s-.4-.2-.4-.4.2-.4.5-.4.4.1.4.3z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#F29600",
          d: "M22.1 9.7c1.1-3.3-1.5-6.6-5.4-6-.5.1-.9-.2-1-.7-.1-.5.2-.9.7-1 5.1-.9 8.7 3.6 7.3 8.1-.1.4-.6.7-1 .5s-.7-.4-.6-.9z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#F29600",
          d: "M19.1 7.3c-.2-.4-.7-.8-1.8-.7-.4 0-.7-.2-.7-.6s.2-.7.6-.7c1.5-.2 2.6.4 3.1 1.3.5.8.5 1.9.3 2.6-.1.4-.5.5-.9.4s-.5-.5-.4-.9c.1-.3.1-.9-.2-1.4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }