var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-guess" }, [
    _c(
      "section",
      [
        _c("v-button", {
          attrs: {
            text: "Guess Debug Mode ON",
            filled: true,
            color: "attention",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.guessDebugModeOn.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Start autoplay" },
          nativeOn: {
            click: function ($event) {
              return _vm.startAutoPlay.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { text: "Day insights" },
          nativeOn: {
            click: function ($event) {
              return _vm.showDayInsights.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("h3", [_vm._v("Guess Stories")]),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese General" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("japanese-general")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese Hiragana" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("japanese-hiragana")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese Katakana" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("japanese-katakana")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Korean General" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("korean-general")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Korean Hangeul" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("korean-hangeul")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Russian General" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("russian-general")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Russian Cyrillic" },
          nativeOn: {
            click: function ($event) {
              return _vm.openStory("russian-cyrillic")
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("h3", [_vm._v("Guess Messages & Overlays")]),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("v-button", {
          attrs: { size: "medium", text: "Fast Tracking Modal" },
          nativeOn: {
            click: function ($event) {
              return _vm.openFTmodal.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Can you say word - overlay?" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("translation-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "2nd card message" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("2nd-card-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "3rd card message" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("3rd-card-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "First mistake - exit message" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("first-mistake-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: {
            size: "medium",
            text: "Strict Diacritics - setting message",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("strict-diacritics-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Synonym - exit message" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("synonym-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Do not overdo" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("dont-overdo-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese Scripts" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("japanese-scripts-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese Hiragana & Katakana" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("japanese-hiragana-katakana-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese Hiragana" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("japanese-hiragana-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Japanese Hiragana Learning" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("japanese-hiragana-learning-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Russian Cyrillic" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("russian-cyrillic-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Form spelling" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("form-spellings-message")
            },
          },
        }),
        _vm._v(" "),
        _c("v-button", {
          attrs: { size: "medium", text: "Voice Input" },
          nativeOn: {
            click: function ($event) {
              return _vm.openMessage("voice-input-message")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }