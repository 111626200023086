import Backbone from 'backbone';
import renderTemplate from '../../../util/template.renderer.js';
import googleAnalyticsCommand from '../../../util/google-analytics.js';
import UserManager from '../../../modules/usermanager.js';

import {
    ASSIGN_LESSON,
    CLOSE_ASSIGN_LESSON_DIALOG
} from '../actions.js';

const AssignLessonDialogModel = Backbone.Model.extend({
    defaults: {
        classrooms: [],
        selected: []
    }
});

export const AssignLessonDialogView = Backbone.View.extend({

    events: {
        'click div.classroom': 'selectClassroom',
        'click a.close': 'close',
        'click a.send': 'assignLesson',
    },

    initialize: function () {
        this.model = new AssignLessonDialogModel();
    },

    render: function () {

        renderTemplate(
            'lessons/lessons-list/assign-lesson-dialog',
            this.model.attributes,
            this.$el
        ).then( () => {
            this._postRender();
        });
    },

    _postRender: function () {

    },

    selectClassroom: function ({ currentTarget }) {

        const { uuid } = currentTarget.dataset;
        const classrooms = this.model.get('classrooms');
        const classroom = classrooms.find(classroom => classroom.uuid === uuid);

        classroom.selected = ! classroom.selected;

        this.render();

        googleAnalyticsCommand('send', 'event', 'ManageLessons', 'AssignModalClassroomSelected');
        UserManager.instance.getUser().getEventSender()
            .sendNavigationEvent('CourseWizard', 'AssignModalClassroomSelected', null);
    },

    assignLesson: function () {

        const selectedClassrooms = this.model.get('classrooms')
            .filter( classroom => classroom.selected === true);

        if (selectedClassrooms.length > 0) {
            this.trigger(ASSIGN_LESSON, selectedClassrooms);
        }
    },

    close: function () {
        this.trigger(CLOSE_ASSIGN_LESSON_DIALOG);
    }
});
