<template>
    <div class="cards-toggle-menu">
        <div class="action">
            <div class="action-flex">
                <div class="status">
                    <card-status-icon status="incomplete" :known="false" />
                </div>
                <div class="label">
                    <span class="text" v-html="this.$i18n('lessons_creator_editor_toggle_label_incomplete_cards')" />
                    <span class="counter">{{ incompleteCardsCount }}</span>
                </div>
                <div class="toggle">
                    <toggle-button v-model="include_incomplete"
                                   key="include_incomplete_toggle"
                                   :color="colors"
                                   :width="40"
                                   :height="24"
                                   :labels="false" />
                </div>
            </div>
            <div class="action-footnote" v-html="this.$i18n('lessons_creator_editor_toggle_description_incomplete_cards')" />
        </div>
        <div class="action" v-if="known_toggle_visible">
            <div class="action-flex">
                <div class="status">
                    <card-status-icon status="known" :known="true" />
                </div>
                <div class="label">
                    <span class="text" v-html="this.$i18n('lessons_creator_editor_toggle_label_cards_already_learned')" />
                    <span class="counter">{{ knownCardsCount }}</span>
                </div>
                <div class="toggle">
                    <toggle-button v-model="include_known"
                                   key="include_known_toggle"
                                   :color="colors"
                                   :width="40"
                                   :height="24"
                                   :labels="false" />
                </div>
            </div>
            <div class="action-footnote" v-html="this.$i18n('lessons_creator_editor_toast_cards_already_learned_toggle_on')" />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { EventBus } from 'Util/vue-event-bus';
    import ToggleButton from 'vue-js-toggle-button';
    import CardStatusIcon from "./card-status-icon.vue";
    Vue.use(ToggleButton);

    export default {
        name: 'cards-toggle-menu',
        components: {
            CardStatusIcon
        },
        props: {
            lesson: {
                type: Object,
                required: true
            },
            cards: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                colors: {
                    checked: '#2C3143',
                    disabled: '#D8DBE0'
                },
                include_incomplete: this.lesson.include_incomplete,
                include_known: this.lesson.include_known,
                known_toggle_visible: false
            };
        },
        computed: {
            incompleteCardsCount() {
                return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.completeness === 'incomplete').length : 0;
            },
            knownCardsCount() {
                return (this.cards && this.cards.length > 0) ? this.cards.filter(card => card.known).length : 0;
            },
        },
        watch: {
            include_incomplete(value) {
                EventBus.$emit('cw:update-meta', { field: 'include_incomplete', value: value })
            },
            include_known(value) {
                EventBus.$emit('cw:update-meta', { field: 'include_known', value: value })
            },
        },
    }
</script>

<style lang="scss">
    @import "~Styles/mixins";
    @import "~Styles/colors";

    div.cards-toggle-menu {
        display: flex;
        flex-direction: column;
        > div.action {
            margin: pxToRem(8);
            user-select: none;
            > div.action-flex {
                display: flex;
                align-content: center;
                > div.status {
                    flex: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                > div.label {
                    flex: 1;
                    margin: 0 1rem;
                    white-space: nowrap;
                    > span.text {
                        font-size: fontSize(18);
                        padding-top: pxToRem(3);
                        font-weight: $font-bold;
                    }
                    > span.counter {
                        flex: 0;
                        background-color: $colorBackgroundGray;
                        border-radius: 25%;
                        color: $colorSourceSecondary;
                        font-size: fontSize(12);
                        line-height: normal;
                        padding: .25rem;
                        margin: 0 .5rem;
                    }
                }
                > div.toggle {
                    flex: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            > div.action-footnote {
                margin-top: pxToRem(8);
                font-size: fontSize(12);
                opacity: .6;
            }
        }
    }
</style>
