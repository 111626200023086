var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "relevant-words" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("h1", {
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("linking_purpose_relevant_words_title")
            ),
          },
        }),
        _vm._v(" "),
        _vm.relevantWordsMessage
          ? _c("relevant-words-message", {
              attrs: {
                message: this.relevantWordsMessage,
                tracking: _vm.slider_tracking,
                position: _vm.position,
                max_words: _vm.max_words,
              },
            })
          : _c("div", { staticClass: "message-area-placeholder" }),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "data-visualization" },
          [
            _c("relevant-words-chart", {
              staticClass: "chart",
              attrs: {
                series: this.chart_series,
                position: _vm.position,
                max_words: _vm.max_words,
                tracking: _vm.slider_tracking,
              },
            }),
            _vm._v(" "),
            _c("relevant-words-text", {
              staticClass: "text",
              attrs: {
                learned_text_raw: this.learned_text_raw,
                position: _vm.position,
                max_words: _vm.max_words,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("relevant-words-slider", {
          attrs: { position: _vm.position, max_words: _vm.max_words },
          on: {
            "update-position": _vm.sliderUpdate,
            "start-tracking": _vm.sliderStartTracking,
            "end-tracking": _vm.sliderEndTracking,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("button", {
      domProps: {
        innerHTML: _vm._s(
          this.$i18n("linking_purpose_relevant_words_button_continue")
        ),
      },
      on: { click: _vm.continue_ },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }