var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 10a4 4 0 100 8 4 4 0 000-8zm-6 4a6 6 0 1112 0 6 6 0 01-12 0zm9.707-9.707a1 1 0 010 1.414l-.34.34a3 3 0 01-3.786.376l-.271-.181a1 1 0 00-1.262.124l-.34.341a1 1 0 01-1.415-1.414l.34-.34a3 3 0 013.786-.376l.271.181a1 1 0 001.262-.124l.34-.341a1 1 0 011.415 0z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }