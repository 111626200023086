import GrammarTipsView from '../view/grammar/grammar.tips.view.js';
import ControllerFactory from '../modules/controller.factory.js';
import GrammarTipsModel from '../model/grammar.tips.model.js';

export class GrammarTipsController {
    constructor () {}

    go (appView, router) {
        var self = this;

        this._grammarTipsView = new GrammarTipsView({model: GrammarTipsModel});
        appView.setMainView(this._grammarTipsView, true);

        return Promise.resolve().then(function () {
            return self._grammarTipsView.render();
        });
    }
}

export const grammar_tips_controller_factory = new ControllerFactory(GrammarTipsController);

