
'use strict';

import AsyncDestroyable from './async.destroyable.js';

import Course from './course/course.js';

export class Courses extends AsyncDestroyable {
    constructor () {
        super(['initialize']);
        this._courses = null;
    }

    async initialize (apiClient) {
        const response = await apiClient.r.courses.public.get({
            preferred_languages: (window.navigator.languages || [window.navigator.language] || []).join(',') || ''
        });

        this._courses = response.courses.map(courseInfo => new Course(courseInfo));
    }

    getCourses () {
        if (this._courses === null) {
            throw Error('Courses are not initialized yet!');
        }

        return this._courses.filter(c => !c.isHidden());
    }

    getCourse (uuid) {
        return this.getCourses().find(c => c.UUID === uuid) || null;
    }

    getCoursesByInterfaceLanguage (interfaceLanguage) {
        return this.getCourses().filter(course => course.hasInterfaceLanguage(interfaceLanguage));
    }
}

export default new Courses();
