var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-button", on: { click: _vm.toggle } }, [
    _vm.isSelected
      ? _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("circle", {
              attrs: {
                cx: "12",
                cy: "12",
                r: "9",
                stroke: "#B2B8CB",
                "stroke-width": "2",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              attrs: { cx: "12", cy: "12", r: "5", fill: "#2C3143" },
            }),
          ]
        )
      : _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("circle", {
              attrs: {
                cx: "12",
                cy: "12",
                r: "9",
                stroke: "#B2B8CB",
                "stroke-width": "2",
              },
            }),
          ]
        ),
    _vm._v(" "),
    _vm.label !== null
      ? _c("p", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }