
'use strict';

import EventEmitter from 'events';

import _ from 'lodash';

export default class AsyncDestroyable extends EventEmitter.EventEmitter {
    /**
     *
     * @param asyncMethods {string[]}
     */
    constructor (asyncMethods) {
        super(); // EventEmitter

        if (!_.isArray(asyncMethods)) {
            throw Error('asyncMethods is required');
        }

        // Synchronization variables
        this.__destructionSynchronizationParameters = {
            destructionInProgress: false,
            openAsyncMethods: 0,
            asyncMethodsDoneResolve: null
        };

        this.__destructionSynchronizationParameters.asyncMethodsDonePromise = new Promise(resolve => {
            this.removeAllListeners();  // EventEmitter
            this.__destructionSynchronizationParameters.asyncMethodsDoneResolve = resolve;
        });

        // Wrap the functions
        asyncMethods.forEach(functionName => {
            this[functionName] = this._wrapEventSendingFunction(this[functionName], functionName);
        });

        let destroyFunction = _.isFunction(this.destroy) ? this.destroy : () => {/*no-op*/};
        this.destroy = () => {
            return this._synchronizeDestruction()
                .then(() =>  destroyFunction.call(this));
        };
    }

    _synchronizeDestruction () {
        /**
         * Make sure that all the asynchronous functions are done before destroying the instance
         *
         * This function should be called in synchronous mode because it sets up the flag for the
         * pending destruction which allows us to capture (potentially erroneous) late calls to
         * these functions.
         *
         * Correct:
         *  this._synchronizeDestruction().then(...
         *
         * Incorrect:
         *  var self = this;
         *  Promise.resolve().then(function () {
         *      return self._synchronizeDestruction();
         *  }).then(...
         *
         *  NB! This way of calling the function is okay because this function itself guarantees that it
         *  does in fact always return a promise so Promise.resolve wrapping is not necessary in this case.
         */

        this.__destructionSynchronizationParameters.destructionInProgress = true;

        return Promise.resolve().then(() => {
            if (this.__destructionSynchronizationParameters.openAsyncMethods > 0) {
                return this.__destructionSynchronizationParameters.asyncMethodsDonePromise;
            } else {
                this.removeAllListeners(); // EventEmitter
                return Promise.resolve();
            }
        });
    }

    _destructionInProgress () {
        return this.__destructionSynchronizationParameters.destructionInProgress;
    }

    _wrapEventSendingFunction (eventSendingFunction, functionName) {
        var self = this;

        return function () {
            var args = Array.prototype.slice.call(arguments); // Arguments object must be parsed into a normal array for apply to accept it
            if (self.__destructionSynchronizationParameters.destructionInProgress) {
                throw Error(`Attempted call to ${functionName} but ${self.constructor.name} is being or has been already destroyed!`);
            }

            this.__destructionSynchronizationParameters.openAsyncMethods++;

            return Promise.resolve().then(function () {
                return eventSendingFunction.apply(self, args);
            }).then(function (result) {
                self.__destructionSynchronizationParameters.openAsyncMethods--;
                self.__eventSendingFunctionDone();
                return Promise.resolve(result);
            }).catch(function (error) {
                self.__destructionSynchronizationParameters.openAsyncMethods--;
                self.__eventSendingFunctionDone();
                return Promise.reject(error);
            });
        };
    }

    __eventSendingFunctionDone () {
        /**
         * Common actions for both wrapped function resolve and reject paths
         */
        var self = this;
        if (this.__destructionSynchronizationParameters.openAsyncMethods === 0) {
            this.__destructionSynchronizationParameters.asyncMethodsDoneResolve();

            this.__destructionSynchronizationParameters.asyncMethodsDonePromise = new Promise(function(resolve) {
                self.__destructionSynchronizationParameters.asyncMethodsDoneResolve = resolve;
            });
        }
    }
}
