<template>
    <div class="course-wizard" :key="lesson ? lesson.uuid : null" :class="{ disabled: !isSubscriptionActive }">
        <bulk-modal v-if="this.bulkModalVisible"
                    v-bind:lesson_uuid="this.lesson_uuid"
                    v-bind:interaction-disabled="interactionDisabled"
                    v-on:add-cards="addCardsToLesson" />
        <tutorial-modal v-if="this.tutorialModalVisible" v-on:close="closeTutorialModal" />
        <survey v-if="!loading" v-bind:lesson_uuid="this.lesson_uuid"  v-bind:course_uuid="course_info.uuid" survey-source="course-wizard" />
        <template v-if="lesson && !loading">
            <locked-message :is-subscription-active="this.isSubscriptionActive" />
            <course-wizard-header
                v-bind:page="page"
                v-bind:course_info="course_info"
                v-bind:lesson="lesson"
                v-bind:cards="cards"
                v-bind:checkedCards="checkedCards"
                v-bind:userHasClassroomsFeature="userHasClassroomsFeature"
                v-on:name-change="updateLessonName"
                v-on:publish="publish"
                v-on:discard="discard"
                v-on:navigate-to-lessons="navigateToLessons"
                v-bind:interaction-disabled="interactionDisabled" />
            <main :class="{ 'card-view-opened': !!this.selected_card }">
                <lesson-cards
                    v-if="page === 'cards'"
                    v-bind:lesson_uuid="this.lesson_uuid"
                    v-bind:cards="cards"
                    v-bind:selected_card="selected_card"
                    v-bind:isPortrait="isPortrait"
                    v-on:checked-cards-changed="checkCardsChanged"
                    v-on:edit-card="openCardView"
                    v-on:add-cards="addCardsToLesson"
                    v-on:expand-lesson="expandLesson"
                    v-bind:lesson="lesson"
                    v-bind:course_info="course_info"
                    v-bind:interaction-disabled="interactionDisabled" />
                <lesson-meta v-else-if="page === 'meta'"
                             v-bind:course_info="course_info"
                             v-bind:lesson="lesson"
                             v-bind:cards="cards"
                             v-on:update-meta="updateMeta" />
                <lesson-card v-if="selected_card && page === 'cards'"
                             v-bind:selected_card="selected_card"
                             v-bind:course_info="course_info"
                             v-bind:selected_card_contexts="selected_card_contexts"
                             v-bind:userHasClassroomsFeature="userHasClassroomsFeature"
                             v-on:update-card="updateCard"
                             v-on:update-card-comment="updateCardComment"
                             v-on:close="resetSelectedCard"
                             v-on:select-card-context="updateCardContext" />
            </main>
        </template>
        <loader v-else />
    </div>
</template>

<script>
    import { omitBy, isNil } from 'lodash';
    import UserManager from 'Modules/usermanager';
    import { NAME, TYPE } from 'Modules/user.parameters';
    import CourseWizardHeader from './components/header.vue';
    import LessonCards from './components/cards.vue';
    import LessonCard from './components/card.vue';
    import LessonMeta from "./components/meta.vue";
    import Loader from 'ViewComponent/loader.vue';
    import i18nUtils from 'Util/i18n';
    import { EventBus } from 'Util/vue-event-bus';
    import Backbone from 'backbone';
    import ControllerManager from "Modules/controller.manager";
    import { COURSE_FEATURE } from "Modules/course/course";
    import Courses from "Modules/courses";
    import BulkModal from "./components/bulk-modal.vue";
    import { USER_FEATURE } from 'Modules/user';
    import { TYPE as TOAST_TYPE } from '../toaster/constants.js';
    import Survey from "ViewComponent/survey.vue";
    import LockedMessage from "../component/locked-message.vue";
    import TutorialModal from "./components/tutorial-modal.vue";

    export default {
        data: function() {
            return {
                page: null,
                loading: true,
                course_info: null,
                lesson_uuid: null,
                lesson: null,
                cards: [],
                selected_card: null,
                selected_card_contexts: null,
                userHasClassroomsFeature: false,
                checkedCards: [],
                interactionDisabled: false,
                publishingInterval: null,
                bulkModalVisible: false,
                tutorialModalVisible: false,
                isPortrait: window.innerWidth < window.innerHeight,
                subscription: null,
                isSubscriptionActive: false,
            };
        },
        components: {
            TutorialModal,
            LockedMessage,
            Survey,
            BulkModal,
            LessonMeta,
            Loader,
            CourseWizardHeader,
            LessonCards,
            LessonCard,
        },
        methods: {
            checkCardsChanged (checkedCards) {
                this.checkedCards = checkedCards;
            },
            raiseError (error) {
                const error_toast = {
                    text: error,
                    type: TOAST_TYPE.ERROR
                };
                EventBus.$emit('toaster-add', error_toast);
            },
            updateCardCount (added_cards) {
                let { card_count } = this.lesson;
                if (this.lesson.include_known) {
                    const knownCardsCount = added_cards.filter(card => card.known).length;
                    card_count = card_count + knownCardsCount;
                } else {
                    const notKnownCardsCount = added_cards.filter(card => !card.known).length;
                    card_count = card_count + notKnownCardsCount;
                }

                if (this.lesson.include_incomplete) {
                    const incompleteCardsCount = added_cards.filter(card => card.completeness === 'incomplete').length;
                    card_count = card_count + incompleteCardsCount;
                }

                this.lesson.card_count = card_count
            },
            async setLesson () {
                if (!this.lesson_uuid) {
                    return;
                }

                try {
                    this.lesson = await UserManager.instance.getUser().getLessons().getLessonByUuid(this.lesson_uuid);
                    let lesson_course_is_different = false;

                    if (this.lesson) {
                        const _course = Courses.getCourse(this.lesson.course_uuid);
                        if (_course) {
                            const _courseInfo = _course.getInfo();
                            if (this.course_info && this.course_info.uuid !== _courseInfo.uuid) {
                                // lesson course is different than user active course
                                lesson_course_is_different = true;
                            }
                            this.course_info = _courseInfo;
                        }
                    }

                    await this.setLessonWords();
                    if (lesson_course_is_different && this.lesson.card_count === 0) {
                        // if it's empty lesson from another course (not active) discard it and start fresh
                        await this.discard();
                    } else {
                        this.navigate('cards');
                        this.setLoading(false);
                    }
                } catch (error) {
                    console.log('CW: setLesson error', error);

                    // fallback and create a new lesson instead
                    this.lesson_uuid = null;
                    await this.createLesson();
                }
            },
            async setLessonWords () {
                if (!this.lesson_uuid) {
                    return;
                }

                try {
                    this.cards = await UserManager.instance.getUser().getLessons().getLessonCardsByUuid(this.lesson_uuid);
                } catch (error) {
                    console.log('CW: setLessonWords error', error);
                }
            },
            async addCardsToLesson ({ seed_text, seed_file, intent, word_count }) {
                if (!this.lesson_uuid) {
                    return;
                }

                intent = (intent) ? intent : 'automatic';

                this.disableInteraction();
                EventBus.$emit('cw:saving', true);
                this.logEvent({ action: 'addCards' });

                try {
                    let response = await UserManager.instance.getUser().getLessons().addCardsToLesson(this.lesson_uuid, seed_text, seed_file, intent, word_count);
                    if (response && response.cards && response.cards.length > 0) {
                        this.cards = [ ...this.cards, ...response.cards ];
                        this.updateCardCount(response.cards);
                        const _toastText = (response.cards.length === 1) ? i18nUtils.prop('word-added', null, null, 'lesson_creator_editor_notification') : i18nUtils.prop('words-added', null, null, 'lesson_creator_editor_notification');
                        const toast = {
                            text: _toastText,
                            type: TOAST_TYPE.SUCCESS
                        };
                        EventBus.$emit('toaster-add', toast);
                    } else if (response && response.cards && response.cards.length === 0) {
                        const _toastText = i18nUtils.prop('course_wizard_error_no_words');
                        const toast = {
                            text: _toastText,
                            type: TOAST_TYPE.INCOMPLETE
                        };
                        EventBus.$emit('toaster-add', toast);
                    }
                } catch (error) {
                    this.raiseError(error);
                }
                EventBus.$emit('cw:saving', false);

                this.enableInteraction();
            },
            async createLesson() {
                const courseWizardEnabled = await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.COURSE_WIZARD);
                if (!courseWizardEnabled) {
                    if (this.userHasClassroomsFeature) {
                        await this.navigateToLessons();
                    } else {
                        Backbone.history.navigate('hub', { trigger: true });
                    }
                }

                this.setLastLessonUuidToParams(); // empty
                this.navigate('cards');

                const _lesson = await UserManager.instance.getUser().getLessons().createLesson({
                    name: '',
                    course_uuid: this.course_info.uuid,
                    temporary: true
                });

                this.setEditPath(_lesson.uuid, false);
                this.setLastLessonUuidToParams(_lesson.uuid);
                this.lesson = _lesson;
                this.lesson_uuid = _lesson.uuid;
            },
            async deleteCheckedCards () {
                this.disableInteraction();
                this.resetSelectedCard();
                this.logEvent({ action: 'deleteCards' });
                try {
                    await UserManager.instance.getUser().getLessons().deleteWords(this.lesson_uuid, this.checkedCards);
                    const toast = {
                        text: i18nUtils.prop('words-deleted', null, null, 'lesson_creator_editor_notification'),
                        type: TOAST_TYPE.SUCCESS
                    };
                    EventBus.$emit('toaster-add', toast);
                    EventBus.$emit('cw:reset-checked-cards');

                    if (this.lesson) {
                        this.lesson.card_count = this.lesson.card_count - this.checkedCards.length;
                    }

                    await this.setLessonWords();
                } catch (error) {
                    console.log('CW: deleteCheckedCards failed with error', error);
                }
                this.enableInteraction();
            },
            async updateLesson (toast = null) {
                try {
                    const _cleanedLesson = omitBy(this.lesson, isNil); // removes all null/empty key:values from lesson object
                    this.lesson = await UserManager.instance.getUser().getLessons().updateLessonByUuid(this.lesson_uuid, _cleanedLesson);
                    if (!toast) {
                        toast = {
                            text: i18nUtils.prop('lesson-updated', null, null, 'lesson_creator_editor_notification'),
                            type: TOAST_TYPE.SUCCESS
                        };
                    }
                    EventBus.$emit('toaster-add', toast);
                } catch (error) {
                    console.log('CW: updateLesson failed with error', error);
                }
            },
            async updateLessonName (name) {
                this.lesson.name = name;
                EventBus.$emit('cw:saving', true);
                await this.updateLesson();
                EventBus.$emit('cw:saving', false);
            },
            async updateCardComment ({ card, value }) {
                card.form_translation_comment = value;
                await this.updateCard(card);

                let notification = i18nUtils.prop('course_wizard_comment_success');
                const toast = {
                    text: notification,
                    type: TOAST_TYPE.SUCCESS
                };
                EventBus.$emit('toaster-add', toast);
            },
            async updateCard (card) {

                // Set EDITED flags for different fields if they have been changed
                if (this.selected_card.context !== card.context) {
                    card.context_edited = true;
                    this.logEvent({ action: 'updateCardField', context: 'context' });
                }

                if (this.selected_card.context_translation !== card.context_translation) {
                    card.context_translation_edited = true;
                    this.logEvent({ action: 'updateCardField', context: 'context_translation' });
                }

                if (this.selected_card.form_translation !== card.form_translation) {
                    card.form_translation_edited = true;
                    this.logEvent({ action: 'updateCardField', context: 'form_translation' });
                }

                EventBus.$emit('cw:saving', true);
                const updatedCard = await this.pushCard(card.id, card);
                EventBus.$emit('cw:saving', false);

                // update existing data with the latest one
                this.selected_card = updatedCard;
                this.cards = this.cards.map(card => {
                    if (card.id === updatedCard.id) {
                        return { ...card, ...updatedCard };
                    } else {
                        return card;
                    }
                });

            },
            async updateCardContext (card) {
                const _cleanCard = omitBy(card, isNil);
                EventBus.$emit('cw:saving', true);
                const updatedCard = await this.pushCard(card.id, _cleanCard);

                this.selected_card = updatedCard;
                this.cards.map(card => {
                    if (card.id === updatedCard.id) {
                        card.completeness = updatedCard.completeness;
                        card.context = updatedCard.context;
                        card.context_translation = updatedCard.context_translation;
                        card.form = updatedCard.form;
                        card.form_translation = updatedCard.form_translation;
                        card.grammar = updatedCard.grammar;
                        card.lemma = updatedCard.lemma;
                        card.context_edited = false;
                        card.context_translation_edited = false;
                        return card;
                    }
                });

                EventBus.$emit('cw:saving', false);
            },
            async pushCard (cardId, changedCard) {
                try {
                    return await UserManager.instance.getUser().getLessons().pushCard(this.lesson_uuid, cardId, changedCard);
                } catch (error) {
                    console.log('CW: pushCard failed with error', error);
                }
            },
            async updateMeta ({ field, value }) {
                this.logEvent({ action: 'updateMetaField', context: field });
                let toast = null;
                if (field === 'include_incomplete') {
                    if (value === true) {
                        toast = {
                            text: i18nUtils.prop('lessons_creator_editor_toast_incomplete_cards_toggle_on'),
                            type: TOAST_TYPE.INCOMPLETE
                        };
                    } else {
                        toast = {
                            text: i18nUtils.prop('lessons_creator_editor_toast_incomplete_cards_toggle_off'),
                            type: TOAST_TYPE.INCOMPLETE
                        };
                    }
                } else if (field === 'include_known') {
                    if (value === true) {
                        toast = {
                            text: i18nUtils.prop('lessons_creator_editor_toast_cards_already_learned_toggle_on'),
                            type: TOAST_TYPE.SUCCESS
                        };
                    } else {
                        toast = {
                            text: i18nUtils.prop('lessons_creator_editor_toast_cards_already_learned_toggle_off'),
                            type: TOAST_TYPE.SUCCESS
                        };
                    }
                }
                this.lesson[field] = value;
                EventBus.$emit('cw:saving', true);
                await this.updateLesson(toast);
                EventBus.$emit('cw:saving', false);
            },
            async getLessonPublishingStatus (waitForInSec = 5) {
                return UserManager.instance.getUser().getLessons().getLessonPublishingStatusByUuid(this.lesson_uuid, waitForInSec);
            },
            async publish () {
                try {
                    this.setLoading(true);
                    this.logEvent({ action: 'publish' });
                    const publishResponse = await UserManager.instance.getUser().getLessons().publish(this.lesson_uuid);

                    if (publishResponse) {
                        this.lesson = publishResponse;
                        const lesson_name = publishResponse.name;

                        if (this.userHasClassroomsFeature) {
                            Backbone.history.navigate('lessons', { trigger: true });
                        } else {
                            Backbone.history.navigate('guess', { trigger: true });
                        }

                        this.publishingInterval = setInterval(async () => {
                            const response = await this.getLessonPublishingStatus();

                            if (response.status === "published") {
                                clearInterval(this.publishingInterval);
                                this.setLastLessonUuidToParams(); // empty
                                this.setLoading(false);

                                if (this.userHasClassroomsFeature) {
                                    const isStillLessons = Backbone.history.fragment.includes('lessons');
                                    if (isStillLessons) {
                                        const lessonsController = ControllerManager.instance.getController('Lessons');
                                        await lessonsController.renderLessonListView();
                                    } else {
                                        Backbone.history.navigate('lessons', { trigger: true });
                                    }
                                } else {
                                    const { variation_uuid } = response;
                                    ControllerManager.instance.getController('Onboarding').showLessonIsPublished(lesson_name, variation_uuid);
                                }

                                this.logEvent({ action: 'CoursePublished' });
                            }
                        }, 5000);
                    } else {
                        this.raiseError(i18nUtils.prop('course_wizard_error_general'));
                    }
                } catch (error) {
                    console.log('CW: publish failed with error', error);
                }
            },
            async persist () {
                try {
                    await UserManager.instance.getUser().getLessons().persist(this.lesson_uuid);
                } catch (error) {
                    console.log('CW: persist failed', error);
                }
            },
            async discard () {
                this.logEvent({ action: 'discard' });
                this.setLoading(true);
                this.setLastLessonUuidToParams(); // empty
                await UserManager.instance.getUser().getLessons().delete(this.lesson_uuid);

                // check if this deck is part of course variations as well, if yes.. delete it from there also
                const variation = UserManager.instance.getUser().getCourse().getVariation(this.lesson_uuid);
                if (variation) {
                    await UserManager.instance.getUser().deleteVariation(this.course_info.uuid, this.lesson_uuid);
                }

                this.resetLessonState();
                await this.createLesson();
                this.setLoading(false);
            },
            async showExample () {
                const exampleSeedText = i18nUtils.prop('course_wizard_onboarding_input_pasted_text');
                await this.addCardsToLesson({ seed_text: exampleSeedText, intent: 'vocabulary' });
            },
            async expandLesson (expandBy = 20) {
                if (!this.lesson_uuid || !this.lesson) {
                    return;
                }

                this.logEvent({ action: 'expand' });

                try {
                    EventBus.$emit('cw:saving', true);
                    let newSize = this.lesson.card_count + expandBy;
                    if (newSize > 999) {
                        newSize = 999
                    }

                    this.disableInteraction();
                    let response = await UserManager.instance.getUser().getLessons().resizeLesson(this.lesson_uuid, newSize);
                    if (response && response.added_cards) {
                        this.cards = [ ...this.cards, ...response.added_cards ];
                        if (this.lesson) {
                            this.lesson.card_count = response.size;
                        }
                    }
                    this.enableInteraction();
                } catch (error) {
                    console.log('CW: expand lesson failed with error', error);
                }

                EventBus.$emit('cw:saving', false);
            },
            async getContexts(wordId) {
                return UserManager.instance.getUser().getLessons().getContexts(this.lesson_uuid, wordId);
            },
            async openCardView (word) {
                this.logEvent({ action: 'navigate', context: 'cardView' });
                const contexts = await this.getContexts(word.id);
                this.selected_card = word;
                this.selected_card_contexts = contexts;
            },
            setLoading (state = false) {
                this.loading = state;
                this.interactionDisabled = state;
            },
            setLastLessonUuidToParams (uuid = '') {
                const userParameters = UserManager.instance.getUser().getParameters();
                userParameters.setParameter(NAME.COURSE_WIZARD_LAST_LESSON_UUID, uuid, TYPE.STRING);
            },
            getLastLessonUuidFromParams () {
                const userParameters = UserManager.instance.getUser().getParameters();
                return userParameters.getParameter(NAME.COURSE_WIZARD_LAST_LESSON_UUID);
            },
            resetSelectedCard () {
                this.selected_card = null;
                this.selected_card_contexts = null;
            },
            navigate (page) {
                this.logEvent({ action: 'navigate', context: page });
                if (page === 'meta') {
                    EventBus.$emit('survey:open');
                }
                this.page = page;
            },
            async navigateToLessons () {
                if (this.userHasClassroomsFeature) {
                    this.logEvent({ action: 'navigate', context: 'lessons' });
                    Backbone.history.navigate('lessons', { trigger: true });
                    setTimeout(async () => {
                        const lessonsController = ControllerManager.instance.getController('Lessons');
                        await lessonsController.renderLessonListView();
                    }, 2000)
                }
            },
            resetLessonState () {
                const user = UserManager.instance.getUser();
                this.course_info = user.getCourse().getInfo();
                this.lesson_uuid = null;
                this.lesson = null;
                this.selected_card = null;
                this.selected_card_contexts = null;
                this.cards = [];
                this.checkedCards = [];
            },
            setEditPath (uuid, trigger) {
                Backbone.history.navigate(`course-wizard/edit/${uuid}`, { trigger });
            },
            async persistIfNeeded () {
                if (this.cards.length > 0 && this.lesson.publishing_status.status === 'temporary') {
                    await this.persist();
                }
            },
            openBulkModal () {
                this.logEvent({ action: 'openBulkModal' });
                this.bulkModalVisible = true;
            },
            closeBulkModal() {
                this.bulkModalVisible = false;
            },
            openTutorialModal () {
                this.logEvent({ action: 'openTutorialModal' });
                this.tutorialModalVisible = true;
            },
            closeTutorialModal() {
                this.tutorialModalVisible = false;
            },
            logEvent({ screen = 'CourseWizard', action, context = null }) {
                if (action) {
                    UserManager.instance.getUser().getEventSender().sendNavigationEvent(screen, action, context);
                }
            },
            setPortrait() {
                this.isPortrait = window.innerWidth < window.innerHeight;
            },
            disableInteraction() {
                this.interactionDisabled = true;
            },
            enableInteraction() {
                this.interactionDisabled = false;
            },
            async getAndSetSubscription() {
                this.subscription = await UserManager.instance.getUser().getSubscription();
                this.isSubscriptionActive = this.subscription.isSubscriptionActive();
            },
            listenEventBus() {
                EventBus.$on('cw:navigate', this.navigate);
                EventBus.$on('cw:open-bulk-modal', this.openBulkModal);
                EventBus.$on('cw:close-bulk-modal', this.closeBulkModal);
                EventBus.$on('cw:open-tutorial-modal', this.openTutorialModal);
                EventBus.$on('cw:close-tutorial-modal', this.closeTutorialModal);
                EventBus.$on('cw:log-event', this.logEvent);
                EventBus.$on('cw:update-card', this.updateCard);
                EventBus.$on('cw:delete-checked-words', this.deleteCheckedCards);
                EventBus.$on('cw:show-example', this.showExample);
                EventBus.$on('cw:update-meta', this.updateMeta);
                EventBus.$on('cw:disable-interaction', this.disableInteraction);
                EventBus.$on('cw:enable-interaction', this.enableInteraction);
            }
        },
        created () {
            const user = UserManager.instance.getUser();
            this.userHasClassroomsFeature = user.hasFeature(USER_FEATURE.CLASSROOMS);
            const actionCreateLesson = (this.$options.action && this.$options.action === 'create');
            this.course_info = user.getCourse().getInfo(); // gets overwritten by setLesson()

            if (this.$options.uuid) {
                this.lesson_uuid = this.$options.uuid;
                this.setLastLessonUuidToParams(this.$options.uuid);
            } else if (!actionCreateLesson) {
                this.lesson_uuid = this.getLastLessonUuidFromParams();
            }

            if (!this.lesson_uuid || actionCreateLesson) {
                this.createLesson();
            } else {
                this.setEditPath(this.lesson_uuid, false);
            }

            window.addEventListener('resize', this.setPortrait);
            this.getAndSetSubscription();

            this.listenEventBus();
        },
        watch: {
            async lesson_uuid (newValue, oldValue) {
                if (newValue !== oldValue)  {
                    await this.setLesson();
                }
            }
        },
        beforeDestroy() {
            this.persistIfNeeded();
            EventBus.$off('cw:navigate', this.navigate);
            EventBus.$off('cw:open-bulk-modal', this.openBulkModal);
            EventBus.$off('cw:close-bulk-modal', this.closeBulkModal);
            EventBus.$off('cw:log-event', this.logEvent);
            EventBus.$off('cw:update-card', this.updateCard);
            EventBus.$off('cw:delete-checked-words', this.deleteCheckedCards);
            EventBus.$off('cw:show-example', this.showExample);
            EventBus.$off('cw:update-meta', this.updateMeta);
            window.removeEventListener('resize', this.setPortrait);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.course-wizard {
        margin: 3rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include respond-to('medium') {
            margin: 1.5rem;
        }

        > main {
            display: flex;
            flex-direction: row;
            margin: 1.5rem 0 4rem;
            flex: 1;
            max-height: 82vh;
            > div.lesson-cards {
                flex: 2;
            }
            > div.lesson-card {
                flex: 1;
                max-width: pxToRem(400);
                margin-left: 2rem;
            }

            @include respond-to('medium') {
                &.card-view-opened {
                    > div.lesson-cards {
                        flex: 1;
                        min-width: pxToRem(200);
                        > div.box {
                            > div.lesson-cards-control {
                                > div.actions {
                                    flex-direction: column;
                                    > div.manual > div.action > input {
                                        min-width: unset;
                                    }
                                    > div.text,
                                    > div.auto {
                                        margin-left: unset;
                                        margin-right: unset;
                                    }
                                }
                            }
                            > div.lesson-cards-container {
                                > table {
                                    > thead > tr {
                                        div.expand-button {
                                            display: none;
                                        }
                                    }
                                    > tbody > tr {
                                        td.target-word,
                                        td.source-word {
                                            width: auto;
                                        }
                                        td.target-sentence,
                                        td.source-sentence {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    > div.lesson-card {
                        flex: 2;
                    }
                }
            }
        }

        > div.loader {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -2rem;
            margin-top: -1rem;
        }

        > div.locked-message {
            margin-bottom: 1rem;
        }

        > div.bulk-modal,
        > div.tutorial-modal {
            z-index: 1000;
        }

        &.disabled {
            > header.course-wizard-header,
            > div.survey,
            > main {
                opacity: 0.6;
                filter: grayscale(100%);
                pointer-events: none;
            }
        }
    }
</style>
