<template>
    <div class="grammar-icon" :class="[value]">
        <component v-bind:is="icon"/>
    </div>
</template>

<script>
    import feminine_invariable_plurality from "Images/grammar-icon/feminine_invariable_plurality.svg";
    import feminine_neutral_plurality from "Images/grammar-icon/feminine_neutral_plurality.svg";
    import feminine_plural from "Images/grammar-icon/feminine_plural.svg";
    import feminine_singular from "Images/grammar-icon/feminine_singular.svg";
    import masculine_plural from "Images/grammar-icon/masculine_plural.svg";
    import masculine_singular from "Images/grammar-icon/masculine_singular.svg";
    import masculine_feminine_plural from "Images/grammar-icon/masculine_feminine_plural.svg";
    import masculine_feminine_singular from "Images/grammar-icon/masculine_feminine_singular.svg";
    import masculine_invariable_plurality from "Images/grammar-icon/masculine_invariable_plurality.svg";
    import neutral_invariable_plurality from "Images/grammar-icon/neutral_invariable_plurality.svg";
    import neutral_plural from "Images/grammar-icon/neutral_plural.svg";
    import neutral_singular from "Images/grammar-icon/neutral_singular.svg";

    export default {
        name: 'grammar-icon',
        components: {
            feminine_invariable_plurality,
            feminine_neutral_plurality,
            feminine_plural,
            feminine_singular,
            masculine_plural,
            masculine_singular,
            masculine_feminine_plural,
            masculine_feminine_singular,
            masculine_invariable_plurality,
            neutral_invariable_plurality,
            neutral_plural,
            neutral_singular
        },
        props: {
            value: {
                type: String,
                required: true
            }
        },
        computed: {
            icon: function () {
                switch (this.value) {
                    case 'feminine_invariable_plurality':
                        return feminine_invariable_plurality;
                    case 'feminine_plural':
                        return feminine_plural;
                    case 'feminine_singular':
                        return feminine_singular;
                    case 'feminine_neutral_plurality':
                        return feminine_neutral_plurality;
                    case 'masculine_plural':
                        return masculine_plural;
                    case 'masculine_singular':
                        return masculine_singular;
                    case 'masculine_feminine_plural':
                        return masculine_feminine_plural;
                    case 'masculine_feminine_singular':
                        return masculine_feminine_singular;
                    case 'masculine_invariable_plurality':
                        return masculine_invariable_plurality;
                    case 'neutral_invariable_plurality':
                        return neutral_invariable_plurality;
                    case 'neutral_plural':
                        return neutral_plural;
                    case 'neutral_singular':
                        return neutral_singular;
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "~Styles/mixins";

    div.grammar-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: pxToRem(24);
        min-height: pxToRem(24);
        max-height: pxToRem(24);

        > svg {
            transform: scale(1.4);
        }

        &.masculine_invariable_plurality,
        &.neutral_invariable_plurality,
        &.feminine_invariable_plurality {
            min-width: pxToRem(40);
            > svg {
                transform: scale(1);
            }

        }
        &.feminine_neutral_plurality,
        &.masculine_feminine_plural {
            min-width: pxToRem(60);
            > svg {
                transform: scale(1);
            }
        }
    }
</style>
