var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M35 27l-3-11c-.534-1.648-1.814-3-3.5-3h-16c-1.686 0-2.966 1.352-3.5 3l-1.5 5M2 27h36",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13 17h3v3h-3v-3zM16 20h3v3h-3v-3zM19 17h3v3h-3v-3zM22 20h3v3h-3v-3zM25 17h3v3h-3v-3z",
          fill: "#AC6ED1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }