var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass:
            "status-all_svg__status-all_svg__ic-cw-status-dot-all-cards-paper-24",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass:
              "status-all_svg__status-all_svg__ic-cw-status-dot-all-cards-paper-24__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass:
                "status-all_svg__status-all_svg__ic-cw-status-dot-all-cards-paper-24__icon",
              attrs: { transform: "translate(3 3)" },
            },
            [
              _c("circle", {
                staticClass:
                  "status-all_svg__status-all_svg__ic-cw-status-dot-all-cards-paper-24__icon__oval",
                attrs: { cx: "6", cy: "6", r: "6", fill: "#2C3143" },
              }),
              _c("circle", {
                staticClass:
                  "status-all_svg__status-all_svg__ic-cw-status-dot-all-cards-paper-24__icon__oval",
                attrs: {
                  cx: "12",
                  cy: "12",
                  r: "5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }