<template>
    <div :class="{'hub-hero': true}">
        <course-select v-on:open-course-modal="openCourseModal"
                       v-bind:courses="this.courses" v-if="this.courses" />
        <div class="cta">
            <v-button :i18n="{ id: 'hub_hero_learn_btn', params: { 'today_current_set_cards': todayCurrentSetCards, 'today_sets_completed': todaySetsCompleted } }" :filled="true" color="primary" icon="continue" route="guess" />
        </div>
    </div>
</template>

<script>
    import VButton from 'ViewComponent/v-button.vue';
    import CourseSelect from "./course-select.vue";
    import { Awards } from 'Modules/awards';
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'hero',
        props: {
            course: {
                type: Object,
                required: true
            },
            courses: {
                type: Object
            },
        },
        data() {
            return {
                todayCurrentSetCards: null,
                todaySetsCompleted: null
            };
        },
        components: {
            CourseSelect,
            VButton,
        },
        methods: {
            openCourseModal() {
                this.$emit('open-course-modal');
            },
            setSetsData: function () {
                if (this.course) {
                    const statistics = this.course.getStatistics();
                    const todayData = statistics.getTodayData();
                    const todaySetAwards = Awards.getSetAwardsInfo(todayData.awards_objects);

                    this.todayCurrentSetCards = Awards.cardsDoneInActiveSet();
                    this.todaySetsCompleted = todaySetAwards.setsCompleted;
                }
            },
            listenEventBus() {
                EventBus.$on('dynamic-goal:size-changed', this.setSetsData);
                EventBus.$on('dynamic-goal:set-completed', this.setSetsData);
            }
        },
        created() {
            this.listenEventBus();
            this.setSetsData();
        },
        watch: {
            course(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.setSetsData();
                }
            }
        },
        beforeDestroy() {
            EventBus.$off('dynamic-goal:size-changed', this.setSetsData);
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.hub-hero {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        @include respond-to('small') {
            flex-direction: column;
        }

        div.cta {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            @include respond-to('small') {
                justify-content: center;
            }
            body[data-interface-language="ar"] & {
                margin-left: unset;
                margin-right: auto;
            }
            > button.button-component {
                padding-left: pxToRem(24);
                padding-right: pxToRem(24);
                @include respond-to('small') {
                    width: 100%;
                }
                body[data-interface-language="ar"] & {
                    > span.icon > svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
</style>
