
import Vue from 'vue';
import $ from 'jquery';
import Backbone from 'backbone';

import UserModel from '../../model/user.model.js';

import renderTemplate from '../../util/template.renderer.js';
import i18nUtils from '../../util/i18n.js';
import KeyCodes from '../../util/keycodes.js';
import _ from 'lodash';

import { CONFIRM_ACTION, ConfirmationView } from '../util/confirmation.view.js';

import ControllerManager from '../../modules/controller.manager.js';
import URI from '../../util/uri.js';
import UserManager from '../../modules/usermanager.js';
import TermsComponent from '../component/terms.vue';

const TermsViewComponent = Vue.extend(TermsComponent);

const ProfileTabView = Backbone.View.extend({

    name: 'account',

    tagName: 'section',
    className: 'tab-profile',

    events: {
        'click span.save': 'submit',
        'click button.save': 'submit',
        'click button.submit-changes': 'submit',
        'focus div.input': 'focusInput',
        'blur input.email': 'blurEmail',
        'focus form.change-password input.password': 'focusPassword',
        'click button.resend': 'resendVerificationEmail',
        'click a.create-password': 'createPassword',
        'click a.change-password': 'changePassword',
        'click span.reveal': 'revealPassword',

        'input input': 'saveInput',
        'keypress': 'submitOnEnter',

        'click a.download-data': 'downloadData',
        'click a.delete-account': 'deleteAccount',
        'click a.contact-support': 'contactSupport',
        'click a.report-bug': 'reportBug',

        'click a.tos-link': 'openTos',
        'click a.pp-link': 'openPp',
        'click a[data-uri="lingvist:show-file?name=legal"]': 'openLegal'
    },

    initialize: function () {
        console.log('Profile Tab view init');
        this.inputData = {};
    },

    setProps: function (props) {
        this.props = props;
    },

    remove: function () {
        if (this._terms_view) {
            this._terms_view.$destroy();
            if (this._terms_view.$el) {
                this._terms_view.$el.remove();
            }
            delete this._terms_view;
        }
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('account/tab-profile', this.props, this.$el).then( () => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {

        this.$mainForm = this.$('main.form');
        this.$inputName = this.$('div.input.name');
        this.$inputEmail = this.$('input.email');
        this.$allInputs = this.$('div.input');

        this.$verificationContainer = this.$('div.verification-container');
        this.$resendButton = this.$('button.resend');

        this.$changeEmailPasswordContainer = this.$('form.change-email-password');
        this.$changePasswordContainer = this.$('form.change-password');
        this.$createPasswordContainer = this.$('form.create-password');

        this.$changePasswordButton = this.$('a.change-password');
        this.$passwordChanger = this.$('div.password-changer');

        this.$createPasswordButton = this.$('a.create-password');
        this.$passwordCreator = this.$('div.password-creator');

        this.$passwordNotificationSuccess = this.$('div.password-notification > p.success');
        this.$passwordNotificationError = this.$('div.password-notification > p.error');

        this.$downloadData = this.$('a.download-data');

        this.$successMessage = this.$('section.help > p.success-message');
        this.$errorMessage = this.$('section.help > p.error-message');

        this._terms_view = new TermsViewComponent({
            propsData: {
                message_key: 'account_legal_link'
            }
        });

        this._terms_view.$mount(this.$el.find('div.terms')[0]);
    },

    focusInput: function (event) {
        this.$allInputs.removeClass('focus');
        $(event.currentTarget).addClass('focus');
    },

    blurEmail: function (event) {
        const $relatedTarget = $(event.relatedTarget);
        if (( ! $relatedTarget.is('input.password') ||
                $relatedTarget.parents('div.change-email-password').length === 0) &&
            (this.inputData.email === undefined || this.inputData.email === UserManager.instance.getUser().profile.email)) {
            this.hidePasswordSection();
            this.$changePasswordContainer.addClass('enabled');
        }
    },

    focusPassword: function () {
        if (this.inputData.email === undefined || this.inputData.email === UserManager.instance.getUser().profile.email) {
            this.hidePasswordSection();
        }
    },

    emailInputChanged: function () {
        const user = UserManager.instance.getUser();
        if (user.getAuthentication().is_password_set()) {
            if (this.inputData.email === user.profile.email) {
                this.hidePasswordSection();
                this.$changePasswordContainer.addClass('enabled');
                return;
            }
            this.$changeEmailPasswordContainer.removeClass('hidden');
            this.$changePasswordContainer.removeClass('enabled');
        }
        this.$verificationContainer.addClass('hidden');
        this.$inputEmail.removeClass('verification-pending');
    },

    submit: function () {
        if (!_.isEmpty(this.inputData)) {
            this.$('input.error').removeClass('error');
            this.$('main.form').addClass('disabled');
            Backbone.trigger('settingsSaveInProgress', 'settings');

            UserModel.saveSettings(this.inputData).then(
                status => {
                    this.saveSettingsResolved(status);
                },
                errors => {
                    this.saveSettingsRejected(errors);
                }
            );
        }
    },

    submitOnEnter: function (event) {
        const $target = $(event.target);

        if (event.keyCode === KeyCodes.ENTER) {
            event.preventDefault();
            if ($target.is('input.name')) {
                this.submit();
            } else if ($target.is('input.email') && UserManager.instance.getUser().getAuthentication().is_password_set()) {
                this.$el.find('section.change-email-password:not(.hidden) input.password').focus();
            } else if ($target.is('input.password') && $target.parents('div.input-container.password').length > 0 &&
                       $target.parents('section.change-password').length > 0) {
                this.$el.find('section.change-password > div.input-container.new-password > input.password').focus();
            } else {
                this.submit();
            }
        }
    },

    createPassword: function () {
        this.$passwordNotificationSuccess.removeClass('active');
        this.$passwordNotificationError.removeClass('active');
        this.$createPasswordButton.toggleClass('active');
        this.$passwordCreator.toggleClass('active');
        this.$passwordCreator.find('input.password').focus();
    },

    changePassword: function () {
        this.$passwordNotificationSuccess.removeClass('active');
        this.$passwordNotificationError.removeClass('active');
        this.$changePasswordButton.toggleClass('active');
        this.$passwordChanger.toggleClass('active');
        this.$passwordChanger.find('input.password.current').focus();
    },

    revealPassword: function (event) {

        const $container = $(event.currentTarget).parent();
        const $input = $(event.currentTarget).parent().find('input');

        $container.toggleClass('reveal');

        if ($input.attr('type') === 'password') {
            $input.attr('type', 'text');
        } else {
            $input.attr('type', 'password');
        }
    },

    resendVerificationEmail: function () {

        this.$resendButton.prop('disabled', true);

        UserModel.resendVerificationEmail().then(
            () => {
                this.$passwordNotificationSuccess
                    .text(i18nUtils.prop('settings_information_verification_resend_success'))
                    .addClass('active');
                this.$resendButton.prop('disabled', false);
            },
            () => {
                this.$passwordNotificationError
                    .text(i18nUtils.prop('settings_information_verification_resend_error_server'))
                    .addClass('active');
                this.$resendButton.prop('disabled', false);
            }
        );
    },

    saveInput: function (event) {

        const $target = $(event.currentTarget);
        const content = $target.data('content');

        if (content === 'marketing_opt_in') {
            $target.blur();

            Promise.resolve()
                .then(() => UserModel.saveSettings({marketing_opt_in: $target.is(':checked')}))
                .then(status => {
                    this.saveSettingsResolved(status);
                })
                .catch(errors => {
                    this.saveSettingsRejected(errors);
                });
        } else {
            this.inputData[content] = $target.val().trim();

            if (content === 'name') {
                this.$inputName.addClass('changed');
            }

            if (content === 'changeEmailPassword') {
                this.$('button.save.email').attr('disabled', false);
            }

            if (content === 'email') {
                this.emailInputChanged();
            }

            this.$('div.password.current').removeClass('error');
            this.$passwordNotificationSuccess.removeClass('active');
            this.$passwordNotificationError.removeClass('active');

            if (UserManager.instance.getUser().getAuthentication().is_password_set()) {
                const currentPasswordEntered = this.$('input.password.current').val().length > 0;
                const newPasswordEntered = this.$('input.password.new').val().length > 0;

                if (currentPasswordEntered && newPasswordEntered) {
                    this.$('button.submit-changes.change-password').attr('disabled', false);
                } else {
                    this.$('button.submit-changes.change-password').attr('disabled', true);
                }
            } else {
                const firstPasswordEntered = this.$('input.password.create').val().length > 0;

                if (firstPasswordEntered) {
                    this.$('button.submit-changes').attr('disabled', false);
                }
            }
        }
    },

    saveSettingsResolved: function (changes) {

        this.$mainForm.removeClass('disabled');
        Backbone.trigger('saveSettingsProcessFinished');

        changes.forEach(change => {

            switch (change.object) {
                case 'name':
                    switch (change.status) {
                        case 'success':
                            this.$inputName.removeClass('changed');
                            this.$passwordNotificationSuccess
                                .text(i18nUtils.prop('settings_information_name_change_success'))
                                .addClass('active');
                            break;
                    }
                    break;
                case 'email':
                    switch (change.status) {
                        case 'success':
                            this.$passwordNotificationSuccess
                                .text(i18nUtils.prop('settings_information_email_change_success'))
                                .addClass('active');
                            this.hidePasswordSection();
                            break;
                        case 'error-authentication':
                            this.$passwordNotificationError
                                .text(i18nUtils.prop('settings_error_incorrect_password')).addClass('active');
                            this.$el.find('section.change-email-password input.password').addClass('error');
                            break;
                        case 'error-email-in-use':
                            this.$passwordNotificationError
                                .text(i18nUtils.prop('settings_information_error_email_in_use'))
                                .addClass('active');
                            this.$el.find('input.email').addClass('error');
                            break;
                    }
                    break;

                case 'password':

                    switch (change.status) {
                        case 'changed-success':
                            this.$passwordNotificationSuccess
                                .text(i18nUtils.prop('settings_password_change_success'))
                                .addClass('active');
                            this.$('a.change-password').removeClass('active');
                            this.$passwordChanger.removeClass('active');
                            this.$passwordChanger.find('input.password').val('');
                            this.inputData.password = undefined;
                            this.inputData.newPassword = undefined;
                            break;

                        case 'initialized':
                            this.inputData.initializePassword = undefined;
                            this.$passwordCreator.removeClass('active');
                            this.$createPasswordContainer.removeClass('enabled');
                            this.$changePasswordContainer.addClass('enabled');
                            this.$passwordNotificationSuccess
                                .text(i18nUtils.prop('settings_password_initialized'))
                                .addClass('active');
                            break;

                        case 'error-authentication':
                            this.$passwordNotificationError
                                .text(i18nUtils.prop('settings_error_incorrect_password'))
                                .addClass('active');
                            this.$passwordChanger
                                .find('div.input.password.current')
                                .addClass('error');
                            break;
                    }
                    break;

                case 'marketing_opt_in':
                    // TODO: Give proper feedback
                    console.log(`marketing_opt_in updated`);
                    break;
            }
        });
    },

    saveSettingsRejected: function (errors) {

        this.$mainForm.removeClass('disabled');
        Backbone.trigger('saveSettingsProcessFinished');

        errors.forEach(error => {

            switch (error.name) {

                case 'name':
                    switch (error.status) {
                        case 'error':
                            this.$errorMessage.text(i18nUtils.prop('settings_error_server')).show();
                            break;
                    }
                    break;

                case 'email':
                    switch (error.status) {
                        case 'error':
                            this.$errorMessage.text(i18nUtils.prop('settings_error_server')).show();
                            break;
                        case 'error-email-changed-no-password':
                            this.$errorMessage.
                                text(i18nUtils.prop('settings_error_email_changed_no_password')).show();
                            this.$el.find('section.change-email-password input.password').addClass('error');
                            break;
                        case 'error-invalid-email':
                            this.$errorMessage.
                                text(i18nUtils.prop('settings_error_invalid_email')).show();
                            this.$el.find('input.email').addClass('error');
                            break;
                    }
                    break;

                case 'password':

                    switch (error.status) {
                        case 'error':
                            this.$passwordNotificationError
                                .text(i18nUtils.prop('settings_error_server')).addClass('active');
                            break;
                        case 'error-empty-password':
                            this.$passwordNotificationError
                                .text(i18nUtils.prop('settings_password_error_empty_password'))
                                .addClass('active');
                            this.$el.find('div.change-password > div.input-container.password > input.password').addClass('error');
                            break;
                    }
                    break;
            }
        });
    },

    hidePasswordSection: function () {

        this.$changeEmailPasswordContainer.addClass('hidden');
        this.$changeEmailPasswordContainer.find('input').val('');
        this.inputData.changeEmailPassword = undefined;

        if (!UserManager.instance.getUser().getAuthentication().email_verified) {
            this.$verificationContainer.removeClass('hidden');
            this.$inputEmail.addClass('verification-pending');
        }
    },

    downloadData: function () {

        const confirmationView = new ConfirmationView({
            title: i18nUtils.prop('account_additional_settings_data_download_confirmation_title'),
            message: i18nUtils.prop('account_additional_settings_data_download_confirmation_txt'),
            actions: [
                {
                    title: i18nUtils.prop('account_additional_settings_data_download_confirmation_negative_button'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('account_additional_settings_data_download_confirmation_positive_button'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        }, { background: true });

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                this.$downloadData.addClass('requested');
                this.trigger('download-data');
            }
            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });

        confirmationView.show();
    },

    deleteAccount: async function () {
        const subscription = UserManager.instance.getUser().getSubscription();
        const paySubscriptionInfo = await subscription.getPaySubscriptionInfo();
        if (paySubscriptionInfo && paySubscriptionInfo.subscription && paySubscriptionInfo.subscription.is_recurring && paySubscriptionInfo.payment_provider === 'apple-in-app') {
            this.showAccountDeleteInfoForActiveAppleSub();
        } else {
            await new URI('lingvist:delete-account').navigateTo();
        }
    },

    showAccountDeleteInfoForActiveAppleSub: function() {
        const confirmationView = new ConfirmationView({
            title: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_title'),
            message: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_text'),
            actions: [
                {
                    title: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_cancel_btn'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('account_profile_delete_your_account_active_ios_subscription_apple_guide_btn'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        });

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                window.open('https://support.apple.com/kb/ht4098', '_blank');
            }
            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });

        confirmationView.show();
    },

    contactSupport: function (event) {
        if (!this.isActionLocked(event)) {
            ControllerManager.instance.getController('Feedback').show('feedback');
        }
    },

    reportBug: function (event) {
        if (!this.isActionLocked(event)) {
            ControllerManager.instance.getController('Feedback').show('bug');
        }
    },

    openLegal: function () {
        new URI('lingvist:show-file?name=legal').navigateTo();
    },

    isActionLocked: function (event) {
        return !!(event && event.currentTarget && event.currentTarget.classList.contains('locked'));
    }
});

export default ProfileTabView;
