var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M34 11a7 7 0 11-14 0 7 7 0 0114 0z", fill: "#63E1E7" },
      }),
      _c("path", {
        attrs: {
          d: "M5 11.894c2.4 0 7.8-.734 10.2-3.67-4.6 5.913-12 20.799-1.8 25.081 8.578 3.602 18.358-7.075 13.391-25.614m0 0c-.061-.229-.125-.46-.191-.691.053.216.117.447.191.691zm0 0c1.048 3.442 4.175 9.406 8.209 10.32",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }