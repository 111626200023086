
'use strict';

/**
 * This module should be used for all the access to the User object through the UserManager class.
 * PS. this.module.init should only be called once during the initialization of the application,
 * afterwards the UserManager can (and must) be accessed through this.module.instance.
 *
 * The this.module.class and this.module.factory should not be used in the application code, they
 * are provided as exports of this module only for testing purposes.
 * */

import _ from 'lodash';
import Raven from 'raven-js';

import User from './user.js';
import ExportUtils from '../util/export.js';


export class UserNotInitializedException extends Error {
    constructor () {
        super();
        this.message = 'User not initialized';
        this.name = 'UserNotInitializedException';
    }
}

export class UserAlreadyInitializedException extends Error {
    constructor () {
        super();
        this.message = 'User already initialized.';
        this.name = 'UserAlreadyInitializedException';
    }
}

export class MissingAuthenticationError extends Error {
    constructor () {
        super('Unable to initialize user without authentication data!');
        this.name = 'MissingAuthenticationError';
    }
}

export class UserManager {
    /**
     * UserManager is the main entry point class for the application, which is used
     * initialize and destroy as the User object as well as all the access to the User object.
     * */

    constructor (UserClass) {

        if (!_.isFunction(UserClass)) {
            throw Error('Constructor for the User class is required');
        }

        this._UserClass = UserClass;
        this._user = null;
    }

    /**
     * @returns User
     */
    getUser () {
        if (this._user == null) {
            throw new UserNotInitializedException();
        }
        return this._user;
    }

    hasUser () {
        return this._user !== null;
    }

    constructUser (authentication) {
        if (this._user !== null) {
            throw new UserAlreadyInitializedException();
        }

        return Promise.resolve()
            .then(() => {
                if (authentication === null) {
                    throw new MissingAuthenticationError();
                } else {
                    this._user = new this._UserClass(authentication.uuid, authentication.token);
                }
            })
            .then(() => Promise.resolve(this._user));
    }

    destroyUser () {
        const user = this._user;
        this._user = null;

        //TODO: Not sure if silently failing is a good idea..
        if (user === null) {
            return Promise.resolve();
        }

        return Promise.resolve().then(function () {
            return user.destroy();
        }).catch(function (error) {
            Raven.captureException(error, {level: "warning", extra: {
                message: 'An error occurred while destroying the User object:'
            }});
            return Promise.reject(error);
        });
    }
}

export class UserManagerFactory {
    /**
     *  UserManagerFactory is used to initialize the UserManager object once per run (per base object) .
     * */

    constructor (UserClass) {

        if (!_.isFunction(UserClass)) {
            throw Error('Constructor for the User class is required');
        }

        this._UserClass = UserClass;
    }

    _getUserManager () {
        return new UserManager(this._UserClass);
    }

    initUserManager (baseObject) {
        if (baseObject.instance !== null) {
            throw Error('Attempt to initialize UserManager multiple times');
        }

        baseObject.instance = this._getUserManager();
    }
}

const userManagerFactory = new UserManagerFactory(User);


const Module = {
    instance: null,
    init: function () {
        userManagerFactory.initUserManager(Module);
    }
};

ExportUtils.export('app.modules.UserManager', Module);

export default Module;

