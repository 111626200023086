var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "course-loading" }, [
    _c(
      "div",
      { staticClass: "course-loading-container" },
      [
        _c(
          "svg",
          { attrs: { width: "480", height: "260", viewBox: "0 0 240 130" } },
          [
            _c("path", {
              staticClass: "anim cw",
              attrs: {
                d: "M232,65v20c0,6.6-5.4,12-12,12h-54c-9.5,0-13.7-7.5-16.5-11.5\n\tc-10.5-15-29.5-38.6-48.2-43.8c-17.1-4.7-4.6,14.4,13.6,30.6c2.9,2.6,7.3,2.6,10.3,0c18.2-16.2,30.7-35.3,13.6-30.6\n\tC120,46.9,103,69.5,91,85c-2.5,3.2-8,12-17,12H20c-6.6,0-12-5.4-12-12V65",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "anim cw",
              attrs: {
                d: "M8,65V45c0-6.6,5.4-12,12-12h54c9.5,0,13.7,7.5,16.5,11.5 c10.5,15,29.5,38.6,\n                48.2,43.8c17.1,4.7,4.6-14.4-13.6-30.6c-2.9-2.6-7.3-2.6-10.3,0C96.6,73.9,84.1,93,101.2,88.3\n\tC120,83.1,137,60.5,149,45c2.5-3.2,8-12,17-12h54c6.6,0,12,5.4,12,12v20",
              },
            }),
            _vm._v(" "),
            _c("circle", { attrs: { cx: "120", cy: "65", r: "4" } }),
          ]
        ),
        _vm._v(" "),
        _c("course-icon", {
          staticClass: "source-icon",
          attrs: { value: _vm.source_icon_id, size: "large" },
        }),
        _vm._v(" "),
        _c("course-icon", {
          staticClass: "target-icon",
          attrs: { value: _vm.target_icon_id, size: "large" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }