var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.course_info
    ? _c("header", { staticClass: "course-wizard-header" }, [
        _c(
          "div",
          { staticClass: "side" },
          [
            _c("breadcrumb", {
              attrs: {
                page: _vm.page,
                "complete-cards-count": _vm.completeCardsCount,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "info" },
              [
                _c("course-icon", {
                  attrs: {
                    value: _vm.lessonTargetLanguageIconId,
                    size: "medium",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy",
                      value: _vm.lesson_name_local,
                      expression: "lesson_name_local",
                      modifiers: { lazy: true },
                    },
                  ],
                  class: { "lesson-name": true, error: _vm.lessonNameError },
                  attrs: {
                    type: "text",
                    maxlength: "40",
                    placeholder: this.$i18n(
                      "lessons_creator_editor_name_placeholder"
                    ),
                  },
                  domProps: { value: _vm.lesson_name_local },
                  on: {
                    change: function ($event) {
                      _vm.lesson_name_local = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "cards-counter",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("lessons_creator_editor_header_cards_added", {
                        args: { words_count: _vm.lesson.card_count },
                      })
                    ),
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _vm.page !== "intro" ? _c("saving-indicator") : _vm._e(),
            _vm._v(" "),
            _vm.page === "cards"
              ? [
                  !this.userHasClassroomsFeature
                    ? _c("v-button-dropdown", {
                        attrs: {
                          disabled: this.interactionDisabled,
                          primary: {
                            action: "next",
                            disabled: _vm.completeCardsCount === 0,
                            i18n: { id: "lessons_creator_editor_nav_next" },
                          },
                          dropdown: [
                            {
                              action: "discard",
                              i18n: {
                                id: "course_wizard_save_for_later_discard",
                              },
                              icon: "discard",
                            },
                          ],
                        },
                        on: { action: _vm.buttonAction },
                      })
                    : _c("v-button-dropdown", {
                        attrs: {
                          disabled: this.interactionDisabled,
                          primary: {
                            action: "next",
                            disabled: _vm.completeCardsCount === 0,
                            i18n: { id: "lessons_creator_editor_nav_next" },
                          },
                          dropdown: [
                            {
                              action: "discard",
                              i18n: {
                                id: "course_wizard_save_for_later_discard",
                              },
                              icon: "discard",
                            },
                            {
                              action: "back-to-lessons",
                              i18n: {
                                id: "lessons_creator_editor_nav_options_save_open_lessons",
                              },
                              icon: "lessons",
                            },
                          ],
                        },
                        on: { action: _vm.buttonAction },
                      }),
                ]
              : _vm.page === "meta"
              ? [
                  _c("v-button", {
                    attrs: {
                      disabled: this.interactionDisabled,
                      i18n: { id: "lessons_creator_editor_nav_previous" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.navigate("cards")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      filled: true,
                      color: "primary",
                      disabled:
                        this.interactionDisabled ||
                        _vm.completeCardsCount === 0,
                      i18n: { id: "lessons_creator_editor_button_publish" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.publish.apply(null, arguments)
                      },
                    },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }