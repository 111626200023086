var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M20.987 12.487a9 9 0 11-9.474-9.474C11.343 3.004 11.172 3 11 3 5.477 3 1 7.477 1 13s4.477 10 10 10 10-4.477 10-10c0-.172-.005-.343-.013-.513z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 21a9 9 0 100-18 9 9 0 000 18zm0 0c-1.452-2.382-1.742-6.353 0-9m0 0c-1.422-2.832-5.005-4.566-7.794-4.5M12 12c3.163.185 6.457-2.052 7.794-4.5m-8.994 9c5.508 1.12 10.175-2.396 9.9-6.6M8.703 8.71c-3.725 4.21-3.013 10.011.766 11.874m7.028-9.795C14.714 5.46 9.334 3.175 5.831 5.516",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }