<template>
    <button class="dropdown-component" :class="{ disabled }" @mouseleave="closeDropdownDelayed" :data-size="size">
        <span class="dropdown-button" @click="openDropdown" :class="[buttonClass]">
            <span class="title">{{ title }}</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="dropdownOpen">
                <path d="M4 9L8 6L12 9" stroke="#2C3143" stroke-width="2" stroke-linecap="round" />
            </svg>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                <path d="M4 7L8 10L12 7" stroke="#2C3143" stroke-width="2" stroke-linecap="round" />
            </svg>
        </span>
        <transition name="fade">
            <span class="dropdown-content" v-if="dropdownOpen" @mouseenter="openDropdown" @mouseleave="closeDropdown">
                <span class="dropdown-item" v-for="item in dropdown"
                      @click="triggerAction(item.action)">
                    <slot name="icon" v-if="!item.iconName"></slot>
                    <span class="text" v-html="$i18n(item.i18n.id, { collection: item.i18n.collection, args: item.i18n.params })" />
                    <icon-ascending v-if="item.iconName && item.iconName === 'ascending'" />
                    <icon-descending v-else-if="item.iconName && item.iconName === 'descending'" />
                </span>
            </span>

        </transition>
    </button>
</template>

<script>

import iconDescending from "Images/ic_descending.svg";
import iconAscending from "Images/ic_ascending.svg";

export default {
    name: 'v-dropdown',
    components: {
        iconDescending,
        iconAscending
    },
    props: {
        title: {
            type: String,
            required: true
        },
        activeItem: {
            type: String,
        },
        dropdown: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: 'transparent'
        },
        size: {
            type: String,
            default: 'large'
        }
    },
    data() {
        return {
            dropdownOpen: false,
            dropdownCloseTimeout: null
        }
    },
    methods: {
        triggerAction(action) {
            this.$emit('action', action);
        },
        openDropdown() {
            clearTimeout(this.dropdownCloseTimeout);
            this.dropdownOpen = true;
        },
        closeDropdown() {
            this.dropdownOpen = false;
        },
        closeDropdownDelayed() {
            this.dropdownCloseTimeout = setTimeout(() => {
                this.closeDropdown();
            }, 250);
        }
    }
};
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    button.dropdown-component {
        display: flex;
        height: unset;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        font-size: fontSize(18);
        padding: 0;
        user-select: none;
        border-radius: unset;
        background-color: transparent;
        border: 0;
        &:hover {
            cursor: default;
        }
        > span.dropdown-button {
            background-color: $colorSourcePrimary;
            padding: pxToRem(8) pxToRem(16);
            color: #fff;
            border-radius: pxToRem(8);
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;
            &.transparent {
                background-color: transparent;
                color: $colorSourcePrimary;
            }
            &.gray {
                background-color: $colorBackgroundLighter;
                color: $colorSourcePrimary;
            }
            &:hover {
                cursor: pointer;
                background-color: $colorBackgroundLighter;
            }
        }
        > span.dropdown-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            min-width: pxToRem(220);
            right: 0;
            top: pxToRem(35);
            @include elevated;
            gap: .5rem;
            font-size: fontSize(16);
            background-color: #fff;
            z-index: 100;
            color: $colorSourcePrimary;
            border-radius: pxToRem(8);
            text-align: left;
            padding: .5rem;
            > span.dropdown-item {
                padding: pxToRem(8) pxToRem(16);
                display: flex;
                align-items: center;
                border-radius: pxToRem(4);
                gap: .5rem;
                > svg {
                    &:last-child {
                        margin-left: auto;
                        body[data-interface-language="ar"] & {
                            margin-right: auto;
                            margin-left: unset;
                        }
                    }
                }
                &:first-child {
                    border-top: none;
                }
                &.active,
                &:hover {
                    opacity: .8;
                    cursor: pointer;
                    background-color: $colorBackgroundLighter;
                }
            }
        }
        &.disabled {
            color: $colorSourceTertiary;
            border-color: $colorSourceTertiary;
            background-color: transparent;
            pointer-events: none;
            cursor: default;
        }
        &.fade-enter-active, &.fade-leave-active {
            transition: opacity 250ms;
        }
        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }
        &[data-size='medium'] {
            border-radius: pxToRem(8);
            font-size: fontSize(16);
            > span.dropdown-button {
                padding: pxToRem(8);
            }
        }
    }
</style>
