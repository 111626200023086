var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#2C3143",
          d: "M7.5 3c0-.6-.4-1-1-1s-1 .4-1 1v1H2c-.6 0-1 .4-1 1s.4 1 1 1h6.4c-.3 1.4-1.3 2.7-2.5 3.7-.4-.4-.7-.8-1-1.3-.4-.4-1-.5-1.5-.2-.4.3-.5.9-.2 1.4.3.5.7.9 1.1 1.3-.4.3-.8.5-1.1.7-.3.2-.6.3-.7.4-.1.1-.2.1-.3.1-.5.2-.7.8-.5 1.3.2.5.8.7 1.3.5l.3-.2c.2-.1.5-.2.8-.4.5-.3 1.1-.6 1.7-1.1.6.4 1.1.7 1.7 1 .7.3 1.2.5 1.7.6.2.1.4.1.5.1h.2c.5.1 1.1-.3 1.1-.9.1-.5-.3-1-.8-1.1-.2 0-.3-.1-.5-.1-.3-.1-.8-.2-1.3-.5-.3-.1-.6-.3-.9-.5 1.3-1.2 2.6-2.9 3-4.9h1.1c.6 0 1-.4 1-1s-.4-1-1-1h-4V3z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#AC6ED1",
          d: "M19 15.5V17h-2v-1.5c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#AC6ED1",
          d: "M13 11c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V12c0-.6-.4-1-1-1H13zm2 4.5c0-1.4 1.1-2.5 2.5-2.5h1c1.4 0 2.5 1.1 2.5 2.5V20c0 .6-.4 1-1 1s-1-.4-1-1v-1h-2v1c0 .6-.4 1-1 1s-1-.4-1-1v-4.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }