<template>
    <button class="button-component" @click="handleClick" :class="{ filled, disabled, rounded, borderless, nowrap }" :data-size="size" :data-color="buttonColor" >
        <span class="text" v-if="text">{{ text }}</span>
        <span class="text" v-else-if="html" v-html="html" />
        <span class="text" v-else-if="i18n" v-html="this.$i18n(i18n.id, { collection: i18n.collection, args: i18n.params })" />
        <span class="icon" v-if="icon === 'course-wizard'">
            <course-wizard-variation-icon-small />
        </span>
        <span class="icon" v-else-if="icon === 'continue'">
            <continue-icon />
        </span>
        <span class="icon" v-else-if="icon === 'tick'">
            <tick-icon />
        </span>
        <span class="icon" v-else-if="icon === 'trash'">
            <trash-icon />
        </span>
    </button>
</template>

<script>
    import CourseWizardVariationIconSmall from 'Images/variations/24x24/lesson.svg';
    import ContinueIcon from 'Images/continue-20x20.svg';
    import TrashIcon from 'Images/trash-24x24.svg';
    import TickIcon from 'Images/account/tick.svg';
    import Backbone from 'backbone';
    import URI from 'Util/uri'

    export default {
        name: 'v-button',
        props: {
            route: {
                type: String
            },
            text: {
                type: String
            },
            html: {
                type: String
            },
            i18n: {
                type: Object
            },
            icon: {
                type: String,
                default: null
            },
            color: {
                type: String
            },
            filled: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            rounded: {
                type: Boolean,
                default: false
            },
            borderless: {
                type: Boolean,
                default: false
            },
            nowrap: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: 'large'
            }
        },
        computed: {
            buttonColor() {
                return this.filled && !this.color ? 'primary' : this.color;
            }
        },
        components: {
            CourseWizardVariationIconSmall,
            ContinueIcon,
            TickIcon,
            TrashIcon
        },
        methods: {
            handleClick() {
                if (this.route && !this.disabled) {
                    if (this.route.startsWith('lingvist:')) {
                        new URI(this.route).navigateTo();
                    } else {
                        Backbone.history.navigate(this.route, { trigger: true });
                    }
                }
            }
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    button.button-component {
        display: flex;
        height: unset;
        padding: pxToRem(8) pxToRem(16);
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $colorSourcePrimary;
        border: 1px solid $colorSourcePrimary;
        border-radius: pxToRem(10);
        background-color: transparent;
        user-select: none;
        gap: .5rem;
        span.text {
            display: inline-flex;
            flex-direction: row;
            gap: .5rem;
            justify-content: center;
            align-items: center;
            > em {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: .5rem;
                padding: .20rem;
                max-height: 1rem;
                min-width: 1rem;
                min-height: 1rem;
                font-size: fontSize(12);
                background-color: rgba(255,255,255, 1);
                color: $colorSourcePrimary;
            }
        }
        span.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &:hover {
            opacity: .8;
        }
        &.filled[data-color='white'] {
            border-color: #fff;
            background-color: #fff;
        }
        &.filled[data-color='gray'],
        &.filled[data-color='paper'] {
            border-color: $colorBackgroundLighter;
            background-color: $colorBackgroundLighter;
        }
        &.filled[data-color='primary'] {
            color: #fff;
            border-color: $colorSourcePrimary;
            background-color: $colorSourcePrimary;
        }
        &.filled[data-color='secondary'] {
            color: #fff;
            border-color: $colorSourceSecondary;
            background-color: $colorSourceSecondary;
        }
        &.filled[data-color='purple'] {
            color: $colorSourcePrimary;
            border-color: $colorBackgroundPurple;
            background-color: $colorBackgroundPurple;
        }
        &.filled[data-color='purple-primary'] {
            color: #fff;
            border-color: $colorPurplePrimary;
            background-color: $colorPurplePrimary;
        }
        &.filled[data-color='light'] {
            color: $colorSourcePrimary;
            border-color: $colorBackgroundDarker;
            background-color: $colorBackgroundDarker;
        }
        &[data-color='secondary'] {
            border-color: $colorSourceSecondary;
            color: $colorSourceSecondary;
        }
        &.filled[data-color='alert'] {
            color: #fff;
            border-color: $colorIncorrect;
            background-color: $colorIncorrect;
        }
        &.filled[data-color='attention'] {
            color: #fff;
            border-color: $colorAttention;
            background-color: $colorAttention;
        }
        &.disabled {
            color: $colorSourceTertiary;
            border-color: $colorSourceTertiary;
            background-color: transparent;
            pointer-events: none;
            cursor: default;
        }
        &.rounded {
            border-radius: pxToRem(32);
        }
        &.borderless {
            border-color: transparent;
            background-color: transparent;
        }
        &.nowrap {
            white-space: nowrap;
        }
        &[data-size='medium'] {
            padding: pxToRem(6) pxToRem(12);
            border-radius: pxToRem(8);
            font-size: fontSize(14);
        }
        &[data-size='small'] {
            padding: pxToRem(4) pxToRem(6);
            border-radius: pxToRem(8);
            font-size: fontSize(12);
        }
    }
</style>
