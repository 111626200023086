var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#AC6ED1",
          d: "M1 8c0 3.9 3.1 7 7 7 0-1.3.4-2.6 1-3.6-.6.4-1.3.6-2 .6-1.1 0-2.2-.5-2.8-1.4-.3-.5-.2-1.1.2-1.4.5-.3 1.1-.2 1.4.2.3.4.7.6 1.2.6s.9-.2 1.2-.6c.3-.4 1-.5 1.4-.2.3.2.4.6.4.9C11.3 8.8 13 8 15 8c0-3.9-3.1-7-7-7S1 4.1 1 8zm5-2c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm3 1c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M15 9c-3.3 0-6 2.7-6 6 0 .6-.4 1-1 1s-1-.4-1-1c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8c-2.7 0-5-1.3-6.5-3.3-.3-.4-.2-1.1.2-1.4.4-.3 1.1-.2 1.4.2C11.2 20 13 21 15 21c3.3 0 6-2.7 6-6s-2.7-6-6-6z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M18.6 16.2c.4.3.5 1 .2 1.4-.6.9-1.7 1.4-2.8 1.4-1.1 0-2.2-.5-2.8-1.4-.3-.4-.2-1.1.2-1.4.4-.3 1.1-.2 1.4.2.3.4.7.6 1.2.6s.9-.2 1.2-.6c.3-.4.9-.5 1.4-.2zM15 13c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1M19 13c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }