var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "variation" },
    [
      _c("variation-icon", {
        attrs: { variation: _vm.variation },
        nativeOn: {
          click: function ($event) {
            return _vm.openVariationModal(_vm.variation)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "name",
          on: {
            click: function ($event) {
              return _vm.openVariationModal(_vm.variation)
            },
          },
        },
        [_vm._v(_vm._s(_vm.variation.name))]
      ),
      _vm._v(" "),
      _vm.variation.stats
        ? _c(
            "div",
            { staticClass: "stats" },
            [
              _c("div", {
                staticClass: "text",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("variation_page_words_count", {
                      args: {
                        variation_words_total:
                          _vm.variation.stats.user_total_words,
                        variation_units: _vm.variation.units,
                      },
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("progress-bar", {
                attrs: {
                  maxWidth: 120,
                  primary:
                    (_vm.variation.stats.user_remembered_words /
                      _vm.variation.units) *
                    100,
                  secondary:
                    (_vm.variation.stats.user_total_words /
                      _vm.variation.units) *
                    100,
                },
              }),
            ],
            1
          )
        : _vm.variation.status !== "initial"
        ? _c("div", { staticClass: "stats loading" }, [_c("loader")], 1)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _vm.isGameOver ||
          (_vm.variation.status === "subscription_limited" &&
            _vm.variation.type !== "general")
            ? _c("status-icon", {
                attrs: { type: "locked", tooltip: "disabled" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.variation.status === "complete"
            ? _c("status-icon", {
                attrs: { type: "complete", tooltip: "complete" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("more-dropdown", {
            attrs: {
              variation: _vm.variation,
              "variation-toggle-disabled": _vm.variationToggleDisabled,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }