export default {
    "es": {
        "en-GB": "messages_en-GB.ea3d0c430eece5b14a4d5db06bfcbd3a.json",
        "en-US": "messages_en-US.e2108555e1173aeebceaad4592cf886b.json",
        "en": "messages_en.e2108555e1173aeebceaad4592cf886b.json"
    },
    "fr": {
        "en-GB": "messages_en-GB.274d555241ebf721f30c2d821861ad58.json",
        "en-US": "messages_en-US.274d555241ebf721f30c2d821861ad58.json",
        "en": "messages_en.274d555241ebf721f30c2d821861ad58.json",
        "et": "messages_et.ff83443e955f182308f60e3e1f079f45.json",
        "fr": "messages_fr.bd3edf865aa436a2efc370194e4cac3b.json",
        "ru": "messages_ru.2db098359f247f8081cbac42598175c6.json"
    },
    "ru": {
        "en-GB": "messages_en-GB.9146cbf14f7d46f3ea4aa4d20a476727.json",
        "en-US": "messages_en-US.9146cbf14f7d46f3ea4aa4d20a476727.json",
        "en": "messages_en.9146cbf14f7d46f3ea4aa4d20a476727.json"
    }
};