<template>
    <div class="success">
        <order-failure-icon />
        <div class="message">
            <h2 v-html="this.$i18n('pay_order_summary_title')" />
            <p v-html="this.$i18n('order_failed', { collection: 'pay_errors' })" />
        </div>
        <v-button :i18n="{ id: 'pay_failure_page_continue_btn' }" route="subscriptions" :filled="true" color="primary" />
    </div>
</template>

<script>
    import OrderFailureIcon from "Images/pay/illustrations/payment_failed.svg";
    import VButton from "ViewComponent/v-button.vue";
    export default {
        name: 'failure',
        components: {
            VButton,
            OrderFailureIcon
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.success {
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.message {
            text-align: center;
            margin: 2rem 1rem;
            font-size: fontSize(18);
            > h2 {
                margin-bottom: .5rem;
                font-weight: $font-bold;
            }
        }
    }
</style>
