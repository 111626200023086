var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c("div", { staticClass: "guess-message" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _vm.message.icon
              ? _c("message-icon", { attrs: { "icon-name": _vm.message.icon } })
              : _vm._e(),
            _vm._v(" "),
            _vm.message.title
              ? _c("h3", {
                  staticClass: "title",
                  class: { light: !_vm.message.text },
                  domProps: { innerHTML: _vm._s(_vm.message.title) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isClosable
              ? _c("CloseButton", {
                  staticClass: "close",
                  on: { click: _vm.close },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "message-content-wrapper",
            class: {
              horizontal:
                _vm.message.hasOwnProperty("illustrationPosition") &&
                _vm.message.illustrationPosition === "left",
            },
          },
          [
            _vm.message.illustrationId
              ? _c("message-illustration", {
                  attrs: { "illustration-id": _vm.message.illustrationId },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _vm.message.texts
                ? _c(
                    "div",
                    { staticClass: "body" },
                    _vm._l(_vm.message.texts, function (text) {
                      return _c("p", { domProps: { innerHTML: _vm._s(text) } })
                    }),
                    0
                  )
                : _vm.message.text
                ? _c("div", { staticClass: "body" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.message.text) },
                    }),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm.message.actions
          ? _c(
              "div",
              { staticClass: "footer" },
              [
                _vm._l(_vm.message.actions, function (action) {
                  return [
                    _c("v-button", {
                      attrs: { text: action.text, filled: action.filled },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.emitAction(action.eventName)
                        },
                      },
                    }),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }