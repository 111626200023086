var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          version: "1",
          xmlns: "http://www.w3.org/2000/svg",
          width: "30",
          height: "30",
          viewBox: "0 0 30 30",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#1CD5A5",
          d: "M15 0C6.717 0 0 6.715 0 15s6.717 15 15 15c8.284 0 15-6.715 15-15S23.284 0 15 0zm-2.53 21.274l-5.396-5.407 2.105-2.093 3.3 3.3 8.35-8.35 2.096 2.096L12.47 21.274z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }