var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "12",
          height: "6",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M6.029 2.21L1.645 5.764c-.277.234-.66.3-.999.172a.992.992 0 11-.253-1.72L5.386.219a1.003 1.003 0 011.252 0l4.994 3.997a.99.99 0 01.147 1.394c-.345.426-.97.495-1.4.154L6.028 2.21z",
          fill: "#2C3143",
          "fill-rule": "evenodd",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }