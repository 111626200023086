<template>
    <div class="day-insights" :class="{ isOverlay: this.isOverlay }">
        <div class="dialog-window" :class="{ weeklyGoalView: showWeeklyGoal }">
            <close-button @click="close" class="close" />
            <weekly-goal v-if="showWeeklyGoal" v-on="$listeners"/>
            <template v-else>
                <div class="dateOfDay">
                    <div class="dayName">{{ this.day.format('dddd') }}</div>
                    <div class="dayMonth">{{ this.day.format('Do MMMM') }}</div>
                </div>
                <day-goals :course="this.course" :day="this.day" />
                <div class="collapsibles">
                    <collapsible-box-stack>
                        <!--Words to study-->
                                                <collapsible-box :i18n_title="{ id: 'performance_metrics_mistaken_word_count_btn' }"
                                                                 :title-meta="wordsToStudy"
                                                                 :i18n_text="{ id: 'performance_metrics_mistaken_word_count_description' }"
                                                                 :stacked="true">
                                                    <template v-slot:icon>
                                                        <words-to-learn-icon/>
                                                    </template>
                                                </collapsible-box>
                        <!--Learned Words-->
                        <collapsible-box :i18n_title="{ id: 'performance_metrics_learned_words_title' }"
                                         :title-meta="learnedWords" :stacked="true">
                            <template v-slot:icon>
                                <learned-words-icon/>
                            </template>
                            <template v-slot:content>
                                <collapsible-box-content
                                    :i18n_text="{ id: 'performance_metrics_learned_words_flips_up' }"
                                    :text-meta="learnedWordsPositive">
                                    <template v-slot:icon>
                                        <trending-up-icon/>
                                    </template>
                                </collapsible-box-content>
                                <div class="separator"/>
                                <collapsible-box-content
                                    :i18n_text="{ id: 'performance_metrics_learned_words_flips_down' }"
                                    :text-meta="learnedWordsNegative">
                                    <template v-slot:icon>
                                        <trending-down-icon/>
                                    </template>
                                </collapsible-box-content>
                            </template>
                        </collapsible-box>
                        <!--Words Reinforced-->
                        <collapsible-box :i18n_title="{ id: 'performance_metrics_kicks_title' }"
                                         :title-meta="wordsReinforced"
                                         :i18n_text="{ collection:'performance_metrics_kicks_description', id:'1'}"
                                         :stacked="true">
                            <template v-slot:icon>
                                <words-reinforced-icon/>
                            </template>
                        </collapsible-box>
                    </collapsible-box-stack>
                    <!--Cards Answered -->
                    <collapsible-box :no-background="true" :i18n_title="{ id: 'daily_performance_stats_metric_cards' }"
                                     :title-meta="totalCardsAnswered">
                        <template v-slot:icon>
                            <cards-answered-icon/>
                        </template>
                    </collapsible-box>
                    <div class="separator"/>
                    <!--Unique Words-->
<!--                                        <collapsible-box :no-background="true"
                                                         :i18n_title="{ id: 'daily_performance_stats_metric_unique_words' }"
                                                         :title-meta="uniqueWords">
                                            <template v-slot:icon>
                                                <unique-words-icon/>
                                            </template>
                                        </collapsible-box>-->
                    <!--Time Spent-->
                    <div class="separator"/>
                    <collapsible-box :no-background="true"
                                     :i18n_title="{ id: 'elastic_goals_calendar_metric_total_time' }"
                                     :title-meta="totalTimeSpent">
                        <template v-slot:icon>
                            <time-spent-icon/>
                        </template>
                    </collapsible-box>
                    <!--New Words-->
                    <collapsible-box-stack>
                        <collapsible-box :i18n_title="{ id: 'performance_metrics_new_words_title' }"
                                         :title-meta="newWords"
                                         :i18n_text="{ id: 'performance_metrics_new_words_copy' }"
                                         :stacked="true">
                            <template v-slot:icon>
                                <new-words-icon/>
                            </template>
                            <template v-slot:content>
                                <collapsible-box-content
                                    :i18n_text="{ id: 'performance_metrics_new_words_metric_answered_correct' }"
                                    :text-meta="newWordsCorrect">
                                    <template v-slot:icon>
                                        <correct-first-try-icon/>
                                    </template>
                                </collapsible-box-content>
                                <div class="separator"/>
                                <collapsible-box-content
                                    :i18n_text="{ id: 'performance_metrics_new_words_metric_answered_incorrect' }"
                                    :text-meta="newWordsIncorrect">
                                    <template v-slot:icon>
                                        <new-words-icon/>
                                    </template>
                                </collapsible-box-content>
                            </template>
                        </collapsible-box>
                        <!--Correct Rate-->
                        <collapsible-box :i18n_title="{ id: 'performance_metrics_correct_rate_title' }"
                                         :i18n_text="{ id: 'performance_metrics_correct_rate_copy' }"
                                         :title-meta="correctRate" :stacked="true">
                            <template v-slot:icon>
                                <correct-rate-icon/>
                            </template>
                            <template v-slot:content>
                                <collapsible-box-content
                                    :i18n_text="{ id: 'performance_metrics_correct_rate_metric_new_words' }"
                                    :text-meta="correctRateNew">
                                    <template v-slot:icon>
                                        <new-words-icon/>
                                    </template>
                                </collapsible-box-content>
                                <div class="separator"/>
                                <collapsible-box-content
                                    :i18n_text="{ id: 'performance_metrics_correct_rate_metric_repeat_words' }"
                                    :text-meta="correctRatePractice">
                                    <template v-slot:icon>
                                        <correct-rate-icon/>
                                    </template>
                                </collapsible-box-content>
                            </template>
                        </collapsible-box>
                    </collapsible-box-stack>
                </div>
                <v-button class="continue-button" @click.native="continuePressed" v-if="this.showContinueButton"
                          :i18n="{ id: 'sets_end_of_set_stats_screen_btn'}" :filled="true" color="primary"/>
            </template>
    </div>
    </div>
</template>

<script>
import Backbone from "backbone";
import UserManager from "Modules/usermanager.js";
import i18nUtils from "Util/i18n";
import CollapsibleBox from "ViewComponent/collapsible-box/main.vue";
import CollapsibleBoxContent from "ViewComponent/collapsible-box/collapsible-box-content.vue";
import CollapsibleBoxStack from "ViewComponent/collapsible-box/collapsible-box-stack.vue";
import TotalDaysIcon from "Images/insights/ic_stats_total_days_learnt.svg";
import CardsAnsweredIcon from "Images/insights/ic_stats_cards_answered.svg";
import TimeSpentIcon from "Images/insights/ic_stats_time_spent_light.svg";
import CorrectRateIcon from "Images/insights/ic_stats_correct_rate.svg";
import WordsToLearnIcon from "Images/insights/ic_stats_words_to_learn.svg";
import LearnedWordsIcon from "Images/insights/ic_stats_learned_words.svg";
import TrendingUpIcon from "Images/insights/ic_stats_learned_trending_up.svg";
import TrendingDownIcon from "Images/insights/ic_stats_learned_trending_down.svg";
import WordsReinforcedIcon from "Images/insights/ic_stats_words_reinforced.svg";
import UniqueWordsIcon from "Images/insights/ic_stats_unique_words.svg";
import NewWordsIcon from "Images/icons/icon-home-drawer-newwords.svg";
import CorrectFirstTryIcon from "Images/insights/ic_stats_first_try_correct.svg";
import DayGoals from "./day-goals.vue";
import moment from "moment";
import VButton from 'ViewComponent/v-button.vue';
import CloseButton from 'Images/close-24x24-whitespace.svg';
import WeeklyGoal from "./weekly-goal.vue";
import { AWARD_NAME } from "Modules/awards.js";

export default {
    name: 'DayInsights',
    components: {
        DayGoals,
        CollapsibleBox,
        CollapsibleBoxStack,
        CollapsibleBoxContent,
        TotalDaysIcon,
        CardsAnsweredIcon,
        TimeSpentIcon,
        CorrectRateIcon,
        WordsToLearnIcon,
        LearnedWordsIcon,
        TrendingUpIcon,
        TrendingDownIcon,
        WordsReinforcedIcon,
        UniqueWordsIcon,
        NewWordsIcon,
        CorrectFirstTryIcon,
        UserManager,
        VButton,
        CloseButton,
        WeeklyGoal
    },
    data() {
        return {
            course: null,
            dayData: null,
            showWeeklyGoal: false,
        }
    },
    props: {
        day: {
            type: Object,
            default() {
                return moment().local()
            }
        },
        showContinueButton: {
            type: Boolean,
            default: false
        },
        isOverlay: {
            type: Boolean,
            default: true
        },
        debug: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isToday() {
            return this.day.startOf('day').isSame(moment().startOf('day'));
        },
        wordsToStudy() {
            return (this.dayData && this.dayData.hasOwnProperty('new_units')) ? (this.dayData.new_units.total - this.dayData.new_units.correct) : 0;
        },
        learnedWords() {
            return (this.dayData && this.dayData.hasOwnProperty('flips')) ? (this.dayData.flips.positive - this.dayData.flips.negative) : 0;
        },
        learnedWordsPositive() {
            return (this.dayData && this.dayData.hasOwnProperty('flips')) ? this.dayData.flips.positive : 0;
        },
        learnedWordsNegative() {
            return (this.dayData && this.dayData.hasOwnProperty('flips')) ? this.dayData.flips.negative : 0;
        },
        wordsReinforced() {
            return (this.dayData && this.dayData.hasOwnProperty('kicks')) ? this.dayData.kicks : 0;
        },
        totalCardsAnswered() {
            return (this.dayData && this.dayData.hasOwnProperty('all_units')) ? this.dayData.all_units.total : 0;
        },
        uniqueWords() {
            return (this.dayData && this.dayData.hasOwnProperty('unique_word_count')) ? this.dayData.unique_word_count : 0;
        },
        totalTimeSpent() {
            let studyTimeInSeconds = (this.dayData && this.dayData.hasOwnProperty('study_time')) ? this.dayData.study_time : 0;
            return i18nUtils.getHumanizedDuration(studyTimeInSeconds);
        },
        newWords() {
            return (this.dayData && this.dayData.hasOwnProperty('new_units')) ? this.dayData.new_units.total : 0;
        },
        newWordsCorrect() {
            return (this.dayData && this.dayData.hasOwnProperty('new_units')) ? this.dayData.new_units.correct : 0;
        },
        newWordsIncorrect() {
            return (this.dayData && this.dayData.hasOwnProperty('new_units')) ? (this.dayData.new_units.total - this.dayData.new_units.correct) : 0;
        },
        correctRate() {
            let repeatCorrect = this.dayData.repeat_units.correct;
            let newCorrect = this.dayData.new_units.correct;
            let totalCards = this.dayData.all_units.total;
            let labels = ["repeat_units", "new_units", "all_units"];
            return (!(totalCards === 0) && labels.every(labels => this.dayData.hasOwnProperty(labels))) ? Math.round(((repeatCorrect + newCorrect) * 100 / totalCards)) + '%' : 0;
        },
        correctRateNew() {
            let newTotal = this.dayData.new_units.total;
            let newCorrect = this.dayData.new_units.correct;
            return (!(newTotal === 0) && this.dayData.hasOwnProperty('new_units')) ? Math.round((100 * newCorrect / newTotal)) + '%' : 0;
        },
        correctRatePractice() {
            let repeatTotal = this.dayData.repeat_units.total;
            let repeatCorrect = this.dayData.repeat_units.correct;
            return (!(repeatTotal === 0) && this.dayData.hasOwnProperty('repeat_units')) ? Math.round((100 * repeatCorrect / repeatTotal)) + '%' : 0;
        },
        daySetAwardsCount () {
            if (this.dayData && this.dayData.awards_objects) {
                return this.dayData.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE).length;
            } else {
                return 0;
            }
        }
    },
    methods: {
        async setCourse() {
            if (UserManager.instance.getUser().hasCourse()) {
                this.course = UserManager.instance.getUser().getCourse();
            } else {
                // navigate to HUB when course is not set
                Backbone.history.navigate('hub', {trigger: true});
            }
        },
        getDayData() {
            this.dayData = this.course.getStatistics().getDateData(this.day);
        },
        close() {
            this.showWeeklyGoal = false;
            this.$emit('close');
        },
        continuePressed() {
            if (this.daySetAwardsCount === 1 || this.debug) {
                this.showWeeklyGoal = true;
            } else {
                this.close();
            }
        },
        keyDown(event) {
            if (event && event.code) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
                switch (event.code.toLowerCase()) {
                    case 'escape':
                        this.close();
                        break;
                    case 'enter':
                        if (this.showWeeklyGoal) {
                            this.close();
                        } else if (this.showContinueButton) {
                            this.continuePressed();
                        }
                        break;
                }
            }
        }
    },
    created() {
        this.setCourse();
        this.getDayData();
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
    }
}
</script>


<style lang="scss">
@import '~Styles/_mixins.scss';
@import '~Styles/_colors.scss';

div.day-insights {
    color: $colorSourcePrimary;

    &.isOverlay {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 100vh;
        z-index: 99;
        overflow-y: auto;
        @include minimalistScrollbar;
        background-color: rgba(255,255,255,0.8);
        @include respond-to('small') {
            align-items: unset;
            justify-content: unset;
            background-color: white;
        }
    }

    > div.dialog-window {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        z-index: 100;
        width: 33rem;
        min-height: 38rem;
        gap: 1rem;
        margin: 1rem;
        background-color: white;
        border-radius: pxToRem(8);
        height: max-content;
        &.weeklyGoalView {
            justify-content: center;
        }
        @include elevated;
        @include respond-to('small') {
            margin: 0;
            box-shadow: unset;
            width: auto;
            height: max-content;
            min-height: 100vh;
        }

        > svg.close {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
            transition: 100ms ease-in-out;
            z-index: 100;
            body[data-interface-language="ar"] & {
                right: unset;
                left: 1rem;
            }
            &:hover {
                transform: scale(1.2);
            }
        }

        > div.today {
            > h2 {
                margin-bottom: 1rem;
                font-size: 1.38rem;
                font-weight: $font-bold;
            }

            > div.today-goals {
                > div.info {
                    p {
                        body[data-interface-language="ar"] & {
                            font-size: fontSize(14);
                            line-height: 1.35em;
                        }
                    }
                    p.text {
                        font-weight: $font-bold;
                    }
                }
            }
        }

        > div.dateOfDay {
            display: flex;
            align-items: baseline;

            div.dayName {
                font-size: 1.4rem;
                line-height: 1.63rem;
            }

            div.dayMonth {
                margin-left: 0.4rem;
                font-size: 1rem;
                line-height: 1.25rem;
            }
        }

        > div.collapsibles {
            > div.separator {
                background-color: $colorBackgroundLighter;
                height: 1px;
                width: 100%;
            }
        }

        button.continue-button {
            margin-top: auto;
        }
    }
}

</style>
