<template>
    <div class="audio-settings" v-if="this.userCourse && courseHasAudio">
        <div class="setting audio-toggle">
            <div class="label" v-if="audio_on" v-html="this.$i18n('menu_audio_settings_on')"/>
            <div class="label" v-else-if="!audio_on" v-html="this.$i18n('menu_audio_settings_off')"/>
            <toggle-button v-model="audio_on"
                           key="audio_toggle"
                           :sync="true"
                           :color="{ checked: '#2C3143' }"
                           :width="70"
                           :height="24"
                           :labels="false" />
        </div>
        <div class="setting audio-speed" :class="{ disabled: !audio_on }">
            <div class="label" v-html="this.$i18n('learn_settings_audio_speed_lbl')"/>
            <toggle-button v-model="audio_slow"
                           @change="toggleAudioSlow"
                           key="audio_speed"
                           :sync="true"
                           :color="{ checked: '#2C3143', unchecked: '#2C3143' }"
                           :width="70"
                           :height="24"
                           :labels="{ checked: audio_speed_slow_string, unchecked: audio_speed_medium_string }" />
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import ToggleButton from "vue-js-toggle-button";
    import AsyncComputed from "vue-async-computed";
    import i18nUtils from "Util/i18n.js";
    import { AUDIO_SPEED } from "Modules/course/user.course.js";
    import { EventBus } from "Util/vue-event-bus.js";
    import UserModel from "Model/user.model.js";

    Vue.use(ToggleButton);
    Vue.use(AsyncComputed);

    export default {
        name: 'audio-settings',
        props: {
            userCourse: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                audio_on: false,
                audio_speed_slow_string: '',
                audio_speed_medium_string: '',
                audio_slow: false,
            }
        },
        asyncComputed: {
            async courseHasAudio() {
                return this.userCourse && await this.userCourse.getVoiceUUID() !== null;
            }
        },
        methods: {
            setAudioToggleStrings() {
                this.audio_speed_slow_string = i18nUtils.prop('slow', null, null,'learn_settings_audio_speed_choice');
                this.audio_speed_medium_string = i18nUtils.prop('medium', null, null,'learn_settings_audio_speed_choice');
            },
            async getCourseVoiceSpeed() {
                const courseVoice = await this.userCourse.getVoice();
                if (courseVoice) {
                    const { speed } = courseVoice;
                    return speed;
                } else {
                    return null;
                }
            },
            async toggleAudioSlow({ value }) {
                const speed = value ? AUDIO_SPEED.SLOW : AUDIO_SPEED.MEDIUM;
                console.log('audio: change to', speed);
                await this.userCourse.setVoiceSpeed(speed);
                console.log('audio: change done');
                EventBus.$emit('audio-speed-changed', speed);
            }
        },
        async mounted() {
            this.setAudioToggleStrings();
            this.audio_slow = await this.getCourseVoiceSpeed() === AUDIO_SPEED.SLOW;
            let audio_on = UserModel.isAudioEnabled();

            if (audio_on === null) {
                await UserModel.setAudioEnabled(true);
                this.audio_on = true;
            } else {
                this.audio_on = audio_on;
            }
        },
        watch: {
            audio_on(value) {
                UserModel.setAudioEnabled(value);
            },
            async userCourse(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.audio_slow = await this.getCourseVoiceSpeed() === AUDIO_SPEED.SLOW
                }
            }
        },
    }
</script>

<style lang="scss">
    div.audio-settings {
        display: flex;
        flex-direction: column;
        padding: .5rem 1rem;

        > div.setting {
            display: flex;
            flex-direction: row;
            margin: .25rem 0;
            &.disabled {
                pointer-events: none;
                filter: grayscale(100%);
                opacity: 0.5;
            }
            > label {
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-right: auto;
                    margin-left: unset;
                }
            }
        }
    }
</style>
