var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "grid-item",
      attrs: {
        "data-type": _vm.variation.type,
        "data-status": _vm.variation.status,
        "data-icon": _vm.variation.icon,
      },
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("variation-icon", {
            attrs: { variation: _vm.variation },
            nativeOn: {
              click: function ($event) {
                return _vm.openVariationModal(_vm.variation)
              },
            },
          }),
          _vm._v(" "),
          _vm.isSubscriptionActive
            ? _c("more-dropdown", {
                attrs: {
                  variation: _vm.variation,
                  "variation-toggle-disabled":
                    _vm.variation.status === "unfinished",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "info",
          on: {
            click: function ($event) {
              return _vm.openVariationModal(_vm.variation)
            },
          },
        },
        [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.variation.name)),
          ]),
          _vm._v(" "),
          _vm.variation.description
            ? _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.variation.description)),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "statuses" },
          [
            !_vm.isSubscriptionActive &&
            !_vm.variation.active &&
            _vm.variation.paid
              ? [
                  _c("status-icon", {
                    attrs: { type: "locked", tooltip: "disabled" },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.variation.status === "unfinished"
              ? [
                  _c("status-icon", {
                    attrs: { type: "unfinished", tooltip: "unfinished" },
                  }),
                ]
              : [
                  !_vm.variation.active &&
                  _vm.variation.status !== "initial" &&
                  _vm.variation.status !== "subscription_limited"
                    ? _c("status-icon", {
                        attrs: { type: "disabled", tooltip: "disabled" },
                      })
                    : _vm._e(),
                ],
            _vm._v(" "),
            _vm.variation.status === "complete"
              ? _c("status-icon", {
                  attrs: { type: "complete", tooltip: "complete" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.variation.difficulty
              ? _c("difficulty", {
                  attrs: { difficulty: _vm.variation.difficulty },
                })
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.variation.stats
          ? _c(
              "div",
              { staticClass: "stats" },
              [
                _c("progress-bar", {
                  attrs: {
                    primary:
                      (_vm.variation.stats.user_remembered_words /
                        _vm.variation.units) *
                      100,
                    secondary:
                      (_vm.variation.stats.user_total_words /
                        _vm.variation.units) *
                      100,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }