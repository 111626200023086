var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "collapse_svg__ic-compact-paper-24",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass: "collapse_svg__ic-compact-paper-24__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass: "collapse_svg__ic-compact-paper-24__icon",
              attrs: {
                stroke: "#2C3143",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
              },
            },
            [
              _c("path", {
                staticClass: "collapse_svg__ic-compact-paper-24__icon__path-22",
                attrs: { d: "M15 21l-3-3-3 3m6-18l-3 3-3-3" },
              }),
              _c("path", {
                staticClass: "collapse_svg__ic-compact-paper-24__icon__path",
                attrs: { d: "M4.5 12h15" },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }