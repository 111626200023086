
'use strict';

import $ from 'jquery';
import _ from 'lodash';
import Backbone from 'backbone';
import Raven from 'raven-js';

import version from '../version.js';
import AuthUtils from '../util/auth.js';
import getConfigValue from '../util/configuration.js';
import EvaluationUtils from '../util/evaluation.js';
import ExportUtils from '../util/export.js';
import { unauthenticated_api_client } from '../util/unauthenticated_api_client.js';

import UserManager from '../modules/usermanager.js';
import ControllerManager from '../modules/controller.manager.js';
import { NAME, NAME as USER_PARAMETER, TYPE as PARAMETER_TYPE } from '../modules/user.parameters.js';

import googleAnalyticsCommand, { tagManagerCommand } from '../util/google-analytics.js';
import AudioPlayer from '../util/audioplayer.js';
import URI from '../util/uri.js';
import { setLeanplumUserId } from '../util/leanplum.js';

import { USER_FEATURE } from '../modules/user.js';
import i18nUtils from '../util/i18n.js';
import { TYPE as TOAST_TYPE } from '../view/toaster/constants.js';
import { EventBus } from '../util/vue-event-bus.js';
import OauthProviderModel from './oauth-provider.model.js';
import { COURSE_FEATURE } from '../modules/course/course.js';


export class UserOauthException extends Error {
    constructor (message) {
        super(message);
        this.name = 'UserOauthException';
    }
}

export class RegisterCredentialValidationError extends Error {
    constructor (error_status, location) {
        super(`Error validating credentials: ${error_status}, called from ${location}`);
        this.name = 'RegisterCredentialValidationError';
    }
}

export const AUTHENTICATION_USER_STATUS = {
    EXISTING: 'existing',
    NEW: 'new'
};

const UserModel = {

    user: {},
    frozen: {},

    setAppView: function (appView) {
        this._appView = appView;
    },

    getCourses: function () {

        const user = UserManager.instance.getUser();

        return Promise.resolve().then( () => {
            return user.getCoursesInfo();
        }).then(function (courseList) {

            let activeCourse = null;
            let activeCourseUuid = null;

            let coursesEnrolled = null;
            let coursesAvailableActiveSource = null;
            let coursesAvailableOtherSource = null;

            courseList = courseList.map(course => {
                return course;
            });

            if (user.hasActiveCourse()) {

                activeCourse = user.getCourse().getInfo();
                activeCourseUuid = user.getCourse().UUID;

                // courses that user has registered to

                coursesEnrolled = courseList
                    .filter(({registered_ts}) => registered_ts !== null)
                    .map(course => {
                        return Object.assign({}, course, {
                            action: course.uuid === activeCourseUuid ? 'learning' : 'unselected'
                        });
                    });

                // available public courses in user's current UI language
                coursesAvailableActiveSource = courseList
                    .filter( ({interface_languages, hidden}) =>
                        (interface_languages.indexOf(activeCourse.source_language) !== -1 && ! hidden))
                    .map(course => {
                        return Object.assign({}, course, {
                            action: course.registered_ts !== null ? 'learning' : 'add'
                        });
                    });

                // available public courses in other source languages
                coursesAvailableOtherSource = courseList
                    .filter( ({interface_languages, hidden}) =>
                        (interface_languages.indexOf(activeCourse.source_language) === -1 && ! hidden))
                    .map(course => {
                        return Object.assign({}, course, {
                            action: course.registered_ts !== null ? 'learning' : 'add'
                        });
                    });
            }

            // all public courses available for register
            const coursesAvailableAll = courseList
                .filter(({ registered_ts, hidden }) =>
                    (registered_ts === null && ! hidden))
                .map(course => {
                    return Object.assign({}, course, {
                        action: 'add'
                    });
                });

            return Promise.resolve({
                activeCourse,
                allCourses: courseList,
                coursesEnrolled,
                coursesAvailableAll,
                coursesAvailableActiveSource,
                coursesAvailableOtherSource: _.sortBy(coursesAvailableOtherSource, 'name')
            });
        });
    },

    setCourse: async function (uuid) {
        const course = await UserManager.instance.getUser().setCourse(uuid);

        const courseInfo = course.getInfo();
        console.info(`Setting course: ${courseInfo.source_language}->${courseInfo.target_language} uuid="${uuid}"`);
        googleAnalyticsCommand('set' , 'dimension2', uuid);

        $('body').attr('data-course-uuid', uuid);
        Backbone.trigger('closeDrawers');
        Backbone.trigger('userCourseChanged', course);
        if (this._appView !== undefined) {
            this._appView.reRenderAll();
        }
    },

    isAudioEnabled: function () {
        return UserManager.instance.getUser().getParameters().getParameter(USER_PARAMETER.AUDIO_ENABLED);
    },

    setAudioEnabled: async function (enabled) {
        AudioPlayer.toggleAudioEnabled(enabled);
        return UserManager.instance.getUser().getParameters()
            .setParameter(USER_PARAMETER.AUDIO_ENABLED, enabled, PARAMETER_TYPE.BOOLEAN);

    },

    navigate_after_user_initialization: async function (user, navigate) {
        if (OauthProviderModel.isSsoFlow()) {
            await user.sso_authorize();
        } else {
            googleAnalyticsCommand('send', 'event', 'Login', 'Successful');
            const hasPostSigninNavigateToAction = ControllerManager.instance.getController('PostSigninActions').has_navigation_action();

            if (navigate) {
                if (!user.hasCourse()) {
                    Backbone.history.navigate('hub', { trigger: true });
                } else if (hasPostSigninNavigateToAction) {
                    await ControllerManager.instance.getController('PostSigninActions').execute();
                } else if (user) {
                    const _userParameters = user.getParameters();
                    let _userDefaultLandingPage = _userParameters.getParameter(USER_PARAMETER.LANDING_PAGE_DEFAULT);
                    const _course = user.getCourse();
                    const isGameOver = _course.getLimits().isGameOver();

                    if (!isGameOver) {
                        Backbone.history.navigate(_userDefaultLandingPage || 'guess', { trigger: true });
                    } else {
                        Backbone.history.navigate('subscriptions', { trigger: true });
                    }
                }
            }
        }
    },

    async login_request (email, password) {
        let response = null;
        try {
            response = await unauthenticated_api_client.r.user.login.post({
                email: email,
                password_hash: AuthUtils.getPasswordHash(email, password)
            }, { minorVersion: 0 });
        } catch (error) {
            if (error instanceof Response) {
                if (error.status === 401) {
                    throw 'error-authentication';
                } else if (error.status === 403) {
                    throw 'deletion-in-progress';
                } else {
                    throw 'error-server';
                }
            } else {
                throw 'error-network';
            }
        }

        return response;
    },

    async login (email, password) {
        email = email.trim().toLowerCase();

        const response = await this.login_request(email, password);

        if (response !== null) {
            const {
                user: {uuid: user_uuid},
                authentication: {token}
            } = response;

            await AuthUtils.setAuthentication(user_uuid, token);
            await AuthUtils.setAuth();

            const user = await this.initializeUserModel({uuid: user_uuid, token});
            await this.navigate_after_user_initialization(user, true);
        }
    },

    async oauth_request (provider, code, redirect_uri) {
        let response = null;
        if (!_.isString(provider)) {
            throw Error('UserModel:OAuthRequest "provider" must be a string!');
        }
        if (!_.isString(code)) {
            throw Error('UserModel:OAuthRequest "code" must be a string!');
        }
        try {
            response = await unauthenticated_api_client.r.user.oauth.post({
                provider: provider,
                code: code,
                access_token: null,
                redirect_uri: redirect_uri
            }, { minorVersion: 0 });
        } catch (error) {
            if (error instanceof Response) {
                if (error.status === 401) {
                    throw 'authorization-failed';
                } else if (error.status === 403) {
                    throw 'deletion-in-progress';
                } else {
                    throw 'error-server';
                }
            } else {
                throw 'error-network';
            }
        }

        return response;
    },

    async _oauth_existing_user (oauth_response, show_existing_user_toast = false) {
        const {
            user: {uuid: user_uuid},
            authentication: {token}
        } = oauth_response;

        await AuthUtils.setAuthentication(user_uuid, token);
        await AuthUtils.setAuth();

        const user = await this.initializeUserModel({uuid: user_uuid, token});
        await this.navigate_after_user_initialization(user, true);

        if (show_existing_user_toast) {
            const _user = UserManager.instance.getUser();
            EventBus.$emit('toaster-add', {
                text: i18nUtils.prop('register_existing_account_found', {email: _user.profile.email}),
                type: TOAST_TYPE.SUCCESS
            });
        }
    },

    processAppleUserData: function (user) {
        if (user !== null && _.isString(user)) {
            try {
                let apple_user_data = JSON.parse(user);
                const name = apple_user_data && apple_user_data.name ?
                    `${apple_user_data.name.firstName} ${apple_user_data.name.lastName}` : null;

                return { name };
            } catch (error) {
                Raven.captureException(error, { level: 'error' });
                return { name: null };
            }
        }
    },

    oauth_signin: async function (provider, code, user, redirect_uri) {
        ControllerManager.instance.getController('LoaderController').showPriorityLoader('oauth');

        let apple_user_data = provider === 'apple' ? this.processAppleUserData(user) : { name: null };

        try {
            const oauth_response = await this.oauth_request(provider, code, redirect_uri);

            if (oauth_response !== null) {
                const { user_status } = oauth_response;

                if (user_status === AUTHENTICATION_USER_STATUS.NEW) {
                    const { email_required, token } = oauth_response;

                    Backbone.history.navigate(`register?${$.param({
                        oauth_token: token,
                        oauth_provider: provider,
                        oauth_email_required: email_required ? 1 : undefined,
                        oauth_name: apple_user_data.name
                    })}`, {trigger: true});

                } else if (user_status === AUTHENTICATION_USER_STATUS.EXISTING) {
                    await this._oauth_existing_user(oauth_response, false);
                } else {
                    throw new UserOauthException(`Unknown user_status returned by oauth request: "${user_status}"`);
                }
            }

            ControllerManager.instance.getController('LoaderController').hidePriorityLoader('oauth');
        } catch (error) {
            ControllerManager.instance.getController('LoaderController').hidePriorityLoader('oauth');
            if (error instanceof Error) {
                Raven.captureException(error, {level: 'error'});
                throw 'error-server';
            }
            throw error;
        }
    },

    register_oauth: async function (provider, code, redirect_uri, user_uuid, course_uuid, marketing_opt_in, role,
                                    experiment_overrides, tracking_parameters) {
        /**
         * Specific OAuth flow to be used with register2. Apple OAuth doesn't take this path due-to the page redirect
         */
        try {
            const oauth_response = await this.oauth_request(provider, code, redirect_uri);

            if (oauth_response !== null) {
                const { user_status } = oauth_response;

                if (user_status === AUTHENTICATION_USER_STATUS.NEW) {
                    const { email_required, token } = oauth_response;

                     if (email_required) {
                         const parameters = {
                             oauth_provider: provider,
                             oauth_token: token
                         };
                         Backbone.history.navigate(`register/oauth_email?${$.param(parameters)}`, {trigger: true});
                     } else {
                         try {
                             const response = await UserModel.register(
                                 'token',
                                 user_uuid,
                                 null,
                                 null,
                                 token,
                                 marketing_opt_in,
                                 role,
                                 experiment_overrides,
                                 tracking_parameters,
                             );

                             Backbone.history.navigate('register/loading', {trigger: true});
                             const delayed_registration = await UserModel.complete_registration(response, course_uuid, provider);
                             delayed_registration();
                         } catch (error) {
                             Raven.captureException(new RegisterCredentialValidationError(error, 'UserModel.oauth'), {level: 'error'});
                         }
                     }
                } else if (user_status === AUTHENTICATION_USER_STATUS.EXISTING) {
                    await this._oauth_existing_user(oauth_response, true);
                } else {
                    throw new UserOauthException(`Unknown user_status returned by oauth request: "${user_status}"`);
                }
            }

        } catch (error) {
            if (error instanceof Error) {
                Raven.captureException(error, {level: 'error'});
                throw 'error-server';
            }
            throw error;
        }
    },

    async register (mode, user_uuid, name, email, password_or_token, marketing_opt_in, role, experiment_overrides,
                    tracking_parameters) {
        let response = null;
        try {
            const payload = {
                user_uuid: user_uuid,
                name: name,
                email: email,
                role: role,
                interface_language: i18nUtils.currentInterfaceLanguage,
                marketing_opt_in: marketing_opt_in,
                tracking_parameters: tracking_parameters,
                experiment_overrides: experiment_overrides,
                accepted_tos_version: getConfigValue('tos-version'),
                accepted_pp_version: getConfigValue('pp-version'),
            };
            if (mode === 'password') {
                payload.mode = 'password';
                payload.password_hash = AuthUtils.getPasswordHash(email, password_or_token);
                payload.token = null;
            } else if (mode === 'token') {
                payload.mode = 'token';
                payload.password_hash = null;
                payload.token = password_or_token;
            } else {
                throw new Error(`Unknown registration mode="${mode}"`);
            }
            response = await unauthenticated_api_client.r.user.register.post(payload, { minorVersion: 2 });
        } catch (error) {
            if (error instanceof Response) {
                if (error.status === 401) {
                    throw 'error-oauth';
                } else if (error.status === 403 && !error.bodyUsed) {
                    throw (await error.json()).code; // 'deletion-in-progress', 'duplicate-email'
                } else {
                    throw 'error-server';
                }
            } else {
                throw 'error-network';
            }
        }

        if (response === null) {
            throw 'error-network';
        } else {
            return response;
        }
    },

    async complete_registration (registration_request_response, course_uuid, provider = null) {
        const {
            user_status,
            user: {uuid: user_uuid},
            authentication: {token}
        } = registration_request_response;

        if (user_status === AUTHENTICATION_USER_STATUS.NEW || user_status === AUTHENTICATION_USER_STATUS.EXISTING) {
            await AuthUtils.setAuthentication(user_uuid, token);
            await AuthUtils.setAuth();

            this.trackSuccessfulRegistration(provider);

            const user = await this.initializeUserModel({uuid: user_uuid, token});

            if (course_uuid) {
                await user.enrolToCourse(course_uuid);
                await UserModel.setCourse(course_uuid);
            }

            if (OauthProviderModel.isSsoFlow()) {
                await user.sso_authorize();
            } else {
                const _userParameters = user.getParameters();
                let _userDefaultLandingPage = _userParameters.getParameter(NAME.LANDING_PAGE_DEFAULT);

                if (user_status === AUTHENTICATION_USER_STATUS.EXISTING) {
                    const toast = {
                        text: i18nUtils.prop('register_existing_account_found', {email: user.profile.email}),
                        type: TOAST_TYPE.SUCCESS
                    };
                    EventBus.$emit('toaster-add', toast);
                }

                if (user.hasActiveCourse()) {
                    let _shouldNavigate = true;
                    let navigation_target = 'guess';
                    let postRegistrationSurvey = user.getCourse().getSurvey('post-registration');
                    if (postRegistrationSurvey !== null) {
                        ControllerManager.instance.getController('Survey').show('post-registration', postRegistrationSurvey);
                    }

                    const hasPostSigninActions = ControllerManager.instance.getController('PostSigninActions').has_actions();
                    const hasPostSigninNavigateToAction = ControllerManager.instance.getController('PostSigninActions').has_navigation_action();

                    if (hasPostSigninNavigateToAction || hasPostSigninActions) {
                        await ControllerManager.instance.getController('PostSigninActions').execute();
                        _shouldNavigate = !hasPostSigninNavigateToAction;
                    } else if (await user.getCourse().hasFeature(COURSE_FEATURE.VOCABULARY_CURVE) && user_status === AUTHENTICATION_USER_STATUS.NEW) {
                        navigation_target = 'relevant-words';
                    } else if (user_status === AUTHENTICATION_USER_STATUS.EXISTING) {
                        const _userSubscription = user.getSubscription();
                        navigation_target = (_userSubscription.isSubscriptionActive()) ? 'hub' : 'subscriptions';
                    }
                    if (_shouldNavigate) {
                        return () => Backbone.history.navigate(_userDefaultLandingPage || navigation_target, { trigger: true, replace: true });
                    } else {
                        // Return a no-op function if there is no need to navigate
                        return () => {};
                    }
                } else {
                    return () => Backbone.history.navigate(_userDefaultLandingPage || 'subscriptions', { trigger: true });
                }
            }
        } else {
            await UserModel.signout(true);
            throw new UserOauthException(`Unknown user_status returned by register request: "${user_status}"`);
        }
    },

    signout: async function (authentication_error) {
        console.info('UserModel.signout()');
        const user = this.isSignedIn() && UserManager.instance.getUser();

        // clean up user course-state from localstorage on logout
        try {
            if (user) {
                let CourseStateItems = await user.getStorage().getItemsByPrefix('CourseState/');
                if (CourseStateItems && typeof CourseStateItems === 'object') {
                    let CourseStateKeys = Object.keys(CourseStateItems);
                    CourseStateKeys.forEach(key => {
                        user.getStorage().removeItem(key);
                    });
                }
                user.getClient().removeUnauthenticatedUserUUID(); // do a failsafe cleanup (it shouldn't be there)
            }
        } catch (error) {
            console.error(error);
        }

        let redirectURI = 'lingvist:home';
        if (authentication_error) {
            redirectURI = 'lingvist:home?error_unauthorized=1';
        } else {
            if (this.isSignedIn() && user.hasCourse()) {
                const blogUrl = user.getCourse().getUrl('blog');
                if (blogUrl !== null) {
                    redirectURI = blogUrl;
                }
            }
        }

        // Sign out from Google
        if (window.gapi && window.gapi.auth2) {

            const googleAuth = window.gapi.auth2.getAuthInstance();

            if (googleAuth !== null) {
                const userIsSignedIn = googleAuth.isSignedIn.get();
                if (userIsSignedIn) {
                    googleAuth.signOut().then( () => {
                        console.info('User signed out from Google.');
                    });
                }
            }
        }


        try {
            await AuthUtils.clearAuthentication();
            $('body').attr('data-signed-in', false);

            this.user = {};
            UserManager.instance.destroyUser();


            Backbone.trigger('userSignedOut'); // User data is wiped
            EventBus.$emit('user:signed-out');

            new URI(redirectURI).navigateTo();
        } catch (error) {
            console.error(error);
            await new URI('lingvist:home').navigateTo();
        }
    },

    assureSignedIn: async function (fragment, options = {}) {
        console.log('Started assureSignedIn with arguments', arguments);

        function navigateWithParams(target) {
            if (fragment.indexOf(target) === 0) {
                // remove sensitive cross-site auth token
                target = fragment.replace(/[&?]cs-token=[^&?]*/, '');
            }
            Backbone.history.navigate(target, {trigger: true});
        }

        if (await AuthUtils.isAuthenticationSet()) {
            await AuthUtils.setAuth();

            const user = await this.initializeUserModel(await AuthUtils.getStoredAuthentication());
            await this.navigate_after_user_initialization(user, !options.noNavigateOnSuccess);

            return {code: 'success'};
        } else {
            console.info('assureSignedIn : token not found');
            if (options.noNavigateInFailure) {
                return { code: 'no-token' };
            } else if (options.paymentFlow) {
                navigateWithParams('signin?flow=pay');
            } else if (options.failureNavigateTarget) {
                navigateWithParams(options.failureNavigateTarget);
            } else {
                navigateWithParams('signin');
                return { code: 'no-token' };
            }
        }
    },

    initializeUserModel: async function (authentication) {
        try {
            const user = await UserManager.instance.constructUser(authentication);

            user.on('error-unauthorized', () => {
                this.signout(true);
                Backbone.history.navigate('signin', {trigger: true});
            });

            // TODO: Also attach a listener to user to trigger on authentication token changes and remove all
            //  token saving related things to outside of User. See UserAuthentication.update

            await user.initialize();

            if (UserManager.instance.getUser().hasActiveCourse()) {
                $('body').attr('data-course-uuid', UserManager.instance.getUser().getCourse().UUID);
            }

            googleAnalyticsCommand('set', 'userId', user.UUID);
            setLeanplumUserId(user.UUID);

            // Report User's Payment Status as 'dimension3' to Google Analytics
            googleAnalyticsCommand('set', 'dimension3', UserManager.instance.getUser().getSubscription().status);

            if (await user.hasFeature(USER_FEATURE.CLASSROOMS)) {
                ControllerManager.instance.getController('Classrooms').notifyUserSignedIn();
            }

            let audio_enabled = user.getParameters().getParameter(USER_PARAMETER.AUDIO_ENABLED);
            audio_enabled = !!(audio_enabled === null || audio_enabled); // null === true
            AudioPlayer.toggleAudioEnabled(audio_enabled);

            // TODO: Would be better to use PostSigninAction
            if (this.user.classroomCode) {
                ControllerManager.instance.getController('Classrooms').joinClassroom(this.user.classroomCode);
                this.user.classroomCode = null;
            }

            $('body').attr('data-signed-in', true);

            Backbone.trigger('userSignedIn', user);
            EventBus.$emit('user:signed-in', user);

            return Promise.resolve(user);
        } catch (error) {
            Raven.captureException(error, {level: 'error'});
            throw error;
        }
    },

    resendVerificationEmail: function () {
        return new Promise(function(resolve, reject) {
            return Promise.resolve()
                .then(() => {
                    const url = `${getConfigValue('api-url')}/verifications`;
                    fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${UserManager.instance.getUser().getAuthentication().token}`,
                            'X-Client-Type': 'web',
                            'X-Client-Version': version.version,
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            let result = parseInt(data);
                            if (result === 0) {
                                resolve();
                            } else {
                                reject();
                            }
                        })
                        .catch((error) => {
                            console.error('resendVerificationEmail error:', error);
                            reject();
                        });
                });
        });
    },

    saveSettings: function (settingsData) {
        let data = {},
            settings = {},
            potentialChanges = [];
        data.settings = settings;

        const user_profile = UserManager.instance.getUser().profile;

        return new Promise((resolve, reject) => {
            if (settingsData.name !== undefined && settingsData.name !== user_profile.name) {
                settings.name = settingsData.name;
            }

            if (settingsData.email !== undefined && settingsData.email !== user_profile.email) {
                const is_password_set = UserManager.instance.getUser().getAuthentication().is_password_set();
                if (EvaluationUtils.validateEmail(settingsData.email)) {
                    if (!is_password_set || settingsData.changeEmailPassword !== undefined && settingsData.changeEmailPassword.length !== 0) {
                        data.settings.email = settingsData.email;
                        if (is_password_set) {
                            data.settings.oldpwhash = AuthUtils.getPasswordHash(user_profile.email, settingsData.changeEmailPassword);
                            data.settings.newpwhash = AuthUtils.getPasswordHash(data.settings.email, settingsData.changeEmailPassword);
                        }
                    } else {
                        reject([{name: 'email', status: 'error-email-changed-no-password'}]);
                        return;
                    }
                } else {
                    reject([{name: 'email', status: 'error-invalid-email'}]);
                    return;
                }
            }

            if (settingsData.initializePassword !== undefined && settingsData.initializePassword.length !== 0) {
                if (data.settings.email !== undefined) {
                    data.settings.newpwhash = AuthUtils.getPasswordHash(data.settings.email, settingsData.initializePassword);
                } else {
                    data.settings.newpwhash = AuthUtils.getPasswordHash(user_profile.email, settingsData.initializePassword);
                }
                data.settings.initializingPassword = true;
            }

            if (settingsData.newPassword !== undefined && settingsData.newPassword.length !== 0) {
                if (settingsData.password !== undefined && settingsData.password.length !== 0) {
                    if (data.settings.oldpwhash === undefined) {
                        data.settings.oldpwhash = AuthUtils.getPasswordHash(user_profile.email, settingsData.password);
                    }
                    if (data.settings.email !== undefined) {
                        data.settings.newpwhash = AuthUtils.getPasswordHash(data.settings.email, settingsData.newPassword);
                    } else {
                        data.settings.newpwhash = AuthUtils.getPasswordHash(user_profile.email, settingsData.newPassword);
                    }
                    data.settings.changingPassword = true;
                } else {
                    reject([{name: 'password', status: 'error-empty-password'}]);
                    return;
                }
            }

            if (settingsData.marketing_opt_in !== undefined) {
                data.settings.marketing_opt_in = settingsData.marketing_opt_in;
            }

            let settingsKeys = Object.keys(data.settings);
            if (settingsKeys.length === 0) {
                resolve([]);
                return;
            } else {
                for (let i = 0; i < settingsKeys.length; i++) {
                    let object = settingsKeys[i];
                    if (object === 'newpwhash' || object === 'oldpwhash') {
                        potentialChanges.push({'name': 'password', 'status': 'error'});
                    } else {
                        potentialChanges.push({'name': object, 'status': 'error'});
                    }
                }
            }

            return Promise.resolve()
                .then(() => AuthUtils.getStoredAuthentication())
                .then(authentication => {
                    if (authentication === null) {
                        Raven.captureException(Error('Unable to save-settings, no authentication information is available'), {level: 'error'});
                    } else {
                        const {token} = authentication;
                        const url = `${getConfigValue('api-url')}/user/save-settings`;
                        Promise.resolve()
                            .then(() => fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                    'X-Client-Type': 'web',
                                    'X-Client-Version': version.version,
                                },
                                body: JSON.stringify(data)
                            }))
                            .then((response) => response.json())
                            .then(async result => {
                                if (result.changes !== undefined) {
                                    const user = UserManager.instance.getUser();
                                    const user_authentication = user.getAuthentication();

                                    if (result.token !== undefined) {
                                        // TODO: Remove this hack, password change should be handled for user internally
                                        await user_authentication.update({
                                            token: result.token,
                                            providers: user_authentication.providers,
                                            email_verified: user_authentication.email_verified
                                        });
                                        await AuthUtils.setAuth();  // TODO: Remove, legacy for jQuery.ajax
                                        await user.sync();
                                    }
                                    resolve(result.changes);
                                } else {
                                    reject(potentialChanges);
                                }
                            })
                            .catch(error => {
                                Raven.captureException(error, {level: 'error'});
                                console.error('user save-settings error:', error);
                                reject([]);
                            });
                    }
                });
        });
    },

    async sendForgotPasswordEmail (emailAddress, errorCallback, successCallback) {
        let response = null;
        try {
            response = await unauthenticated_api_client.r.user.recover_password.post({
                email: emailAddress,
            }, { minorVersion: 0 });
            if (response !== null) {
                successCallback('email-sent');
            }
        } catch (error) {
            switch (error.status) {
                case 400:
                    errorCallback('error-no-user-with-email');
                    break;
                default:
                    errorCallback('error-server');
                    break;
            }
        }
    },

    async resetPassword(data, errorCallback, successCallback) {
        let response = null;

        if (data.newPassword.length === 0) {
            errorCallback('error-empty-password');
            return;
        }
        if (data.repeatNewPassword.length === 0) {
            errorCallback('error-empty-repeat-password');
            return;
        }
        if (data.repeatNewPassword !== data.newPassword) {
            errorCallback('error-password-mismatch');
            return;
        }
        if (data.queryParameters && data.queryParameters.user) {
            data.queryParameters.user = data.queryParameters.user.trim().toLowerCase();
        }
        if (!data.queryParameters || !data.queryParameters.user || !data.queryParameters.hash) {
            errorCallback('error-incorrect-request-url');
            return;
        }

        try {
            response = await unauthenticated_api_client.r.user.reset_password.post({
                email: data.queryParameters.user,
                reset_token: data.queryParameters.hash,
                new_password: AuthUtils.getPasswordHash(data.queryParameters.user, data.newPassword)
            }, { minorVersion: 0 });
            if (response) {
                switch (response.status) {
                    case 'OK':
                        successCallback('success');
                        setTimeout(function () {
                            Backbone.history.navigate('signin', {trigger: true});
                        }, 2000);
                        break;
                }
            }
        } catch (error) {
            switch (error.status) {
                case 400:
                    if (error.headers.get('content-type').includes('application/json')) {
                        let errorRes = await error.json();
                        switch(errorRes.code) {
                            case 'outdated-token':
                            case 'invalid-token':
                                errorCallback('error-outdated-token');
                                break;
                            case 'invalid-api-usage':
                            case 'no-user':
                            case 'email-mismatch':
                                errorCallback('error-server');
                                break;
                            default:
                                errorCallback('error-server');
                                break;
                        }
                    } else {
                        errorCallback('error-server');
                    }
                    break;
                default:
                    errorCallback('error-server');
                    break;
            }
        }
    },

    verifyEmail: function (code) {
        return new Promise(function(resolve, reject) {
            return Promise.resolve()
                .then(() => {
                    const url = `${getConfigValue('api-url')}/verifications/${code}`;
                    fetch(url, {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Client-Type': 'web',
                            'X-Client-Version': version.version,
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then(resolve, reject)
                        .catch((error) => {
                            console.error('verifyEmail error:', error);
                            reject();
                        });
                });
        });
    },

    isSignedIn: function () {
        return UserManager.instance.hasUser();
    },

    setClassroomToJoin (classroomCode) {
        this.user.classroomCode = classroomCode;
    },

    trackSuccessfulRegistration: function (provider) {
        googleAnalyticsCommand('set', 'page', '/register-complete');
        googleAnalyticsCommand('send', 'pageview');
        googleAnalyticsCommand('send', 'screenview', { 'screenName': 'register-complete' });
        tagManagerCommand({ 'event': 'AccountRegisterComplete' });
        googleAnalyticsCommand('send', 'event', 'Activity', 'Register', provider || 'e-mail');
    },

    shouldDirectUserStraightToGuess () {
        const user = this.isSignedIn() && UserManager.instance.getUser();
        const _course = user.getCourse();
        const isGameOver = _course.getLimits().isGameOver();

        return !isGameOver;
    }
};

ExportUtils.export('app.model.UserModel', UserModel);

export default UserModel;
