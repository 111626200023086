var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M23 13.562C23 16.566 20.586 19 17.608 19a5.37 5.37 0 01-1.16-.126l-.527-.163A7.055 7.055 0 0017 14.949a7.057 7.057 0 00-2.977-5.77L14 9.083s.155-.43.21-.657C14.664 6.565 15.732 5 17.608 5c1.878 0 2.927 1.652 3.399 3.427.2.75.586 1.496.967 2.228.519.999 1.026 1.974 1.026 2.907zM2 3.196c2.864-.908 6.173 1.466 7 4.608-2.864.909-6.173-1.466-7-4.608z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.5 5.5s.5-2-.5-3.5M11.195 8c.298.052.59.122.875.21A7.048 7.048 0 0117 14.95C17 18.843 13.866 22 10 22s-7-3.157-7-7.051a7.057 7.057 0 012.741-5.597",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 11c-.333.31-.9.5-1.5.5S8.333 11.31 8 11",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }