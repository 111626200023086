
import $ from 'jquery';
import Backbone from 'backbone';
import renderTemplate from '../../util/template.renderer.js';
import UserManager from 'Modules/usermanager.js';

const ClassroomsSignupView = Backbone.View.extend({

    name: 'classrooms-signup',

    tagName: 'main',
    className: 'classrooms-signup-view',

    events: {
        'click button': 'buttonClicked'
    },

    initialize: function () {},

    setProps: function (props) {
        this.props = props;
    },

    render: function () {
        renderTemplate('classrooms/classrooms-signup-view', this.props, this.$el).then( () => {
            this._postRender();
        });
    },

    _postRender: function ()  {
        Backbone.trigger('rendered', this);
    },

    buttonClicked: function (event) {

        const action = $(event.currentTarget).data('action');

        if (action === 'signup') {
            UserManager.instance.getUser().addFeature('classrooms');
            UserManager.instance.getUser().addFeature('course_wizard');
            this.trigger('classrooms-feature-added');
        } else if (action === 'dismiss') {
            Backbone.history.navigate('guess', { trigger: true });
        }
    }
});

export default ClassroomsSignupView;
