var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M15.2 14l3.038 1.979c1.101.638 1.762 1.683 1.762 2.8V21H4v-2.22c0-1.118.493-2.276 1.6-2.913L8.8 14M12 3c-1.658 0-3 1.328-3 2.969V8.03C9 9.672 10.342 11 12 11s3-1.328 3-2.969V5.963C15 4.328 13.653 3 12 3z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }