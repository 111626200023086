var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "20",
          height: "20",
          viewBox: "0 0 20 20",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("circle", { attrs: { cx: "10", cy: "10", r: "10", fill: "#037889" } }),
      _c("path", {
        attrs: {
          d: "M9.33 4.257A.98.98 0 0110 4a.98.98 0 01.707.293l.002.002 3.998 3.998a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414-1.414c1.34-1.34 2.632-2.766 4.037-4.036z",
          fill: "#fff",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }