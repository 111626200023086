
'use strict';

import _ from 'lodash';
import Raven from 'raven-js';

import UserManager from '../modules/usermanager.js';
import {NAME as USER_PARAMETER, TYPE as PARAMETER_TYPE} from '../modules/user.parameters.js';

export default {
    getMenuTips: function (searchQuery) {
        var self = this;

        return Promise.resolve().then(function () {
            return UserManager.instance.getUser().getCourse().getGrammarTips().getMenuTips();
        }).then(function (tips) {
            return Promise.resolve(self.processData(tips, searchQuery));
        }).catch(function (error) {
            Raven.captureException(error, {level: 'error'});
            return Promise.resolve(self.processData([], searchQuery));
        });
    },

    processData: function (grammarTipsData, searchQuery) {

        var self = this;

        for (var i = 0; i < grammarTipsData.length; i++) {
            var grammarHint = grammarTipsData[i];
            grammarHint.favorited = this.getHintFavorited(grammarHint.key);
        }
        searchQuery = searchQuery !== undefined ? searchQuery.trim().toLowerCase(): undefined;

        if (searchQuery) {
            grammarTipsData = _.chain(grammarTipsData).transform(function (result, hint) {
                hint.searchMatch = self.calculateSearchMatch(hint, searchQuery);

                if (hint.searchMatch > 0) {
                    result.push(hint);
                }
            }, [], this).sortBy(function (hint) {
                return -hint.searchMatch;
            }).value();
        }

        if (this.inFavorites) {
            grammarTipsData = _.sortBy(grammarTipsData, function(grammarHint) {return grammarHint.favorited ? 0: 1;});
        }

        return {
            inFavorites: this.inFavorites,
            favorites: this.getNumberOfFavorites(),
            grammarTips: grammarTipsData,
            searchQuery: searchQuery
        };
    },

    getHintFavorited: function (hintKey) {
        return UserManager.instance.getUser().getParameters().getParameter(USER_PARAMETER.GRAMMAR_TIP_FAVOURITE(hintKey));
    },

    setHintFavorited: async function (hintKey, favorited) {
        await UserManager.instance.getUser().getParameters().setParameter(USER_PARAMETER.GRAMMAR_TIP_FAVOURITE(hintKey),favorited, PARAMETER_TYPE.BOOLEAN);
    },

    getNumberOfFavorites: function () {

        const favourites = UserManager.instance.getUser().getParameters().getParameterByPrefix(USER_PARAMETER.GRAMMAR_TIP_FAVOURITE(''));
        const courseUuid = UserManager.instance.getUser().getCourse().getInfo().uuid;
        let count = 0;

        _.each(favourites, (fav, key) => {
            if (key.includes(courseUuid) && fav.value) {
                count++;
            }
        });

        return count;
    },

    calculateSearchMatch: function (hint, query) {
        var value = 0,
            searchDataKeys = Object.keys(hint.search_data);

        for (var i = 0; i < searchDataKeys.length; i++) {
            var searchKey = searchDataKeys[i],
                index = searchKey.indexOf(query);
            if (query === searchKey) {
                value += hint.search_data[searchKey];
            } else if (index > -1) {
                value += ((hint.search_data[searchKey] - index) / 2) * (query.length / searchKey.length);
            }
        }

        return value;
    }
};
