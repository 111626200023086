import i18nUtils from "Util/i18n.js";
import UserManager from "Modules/usermanager.js";
import ControllerManager from "Modules/controller.manager.js";
import { ONBOARDING_STEP } from "Controller/onboarding.js";
import { EventBus } from "Util/vue-event-bus.js";
import Backbone from "backbone";

export class GuessMessaging {
    getMessageForStep(step, course = null, question = null, answer = null) {
        switch (step) {
            case 'translation-message':
                const _form_translation = question.translations[0].translation;
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'open', 'translation');
                if (course && course.UUID === 'da261776-e0b9-463f-aa9d-3351b06af950') {
                    return {
                        name: step,
                        title: i18nUtils.prop('intro_set_message_ask_given_word', { form_translation: _form_translation, target_language: question.target_language }),
                        actions: [
                            { name: 'translation-message', text: i18nUtils.prop('intro_set_btn_ok'), filled: true, eventName: 'onboarding-translation-ok', triggerDismiss: true }
                        ]
                    };
                } else {
                    return {
                        name: step,
                        title:  i18nUtils.removeHTMLTags(i18nUtils.prop('guess_game_onboarding_first_message_ask_grn3160_title')),
                        text: i18nUtils.prop('guess_game_onboarding_first_message_ask_grn3160_text', { form_translation: _form_translation, tl: question.target_language }),
                        actions: [
                            { name: 'translation-message', text: i18nUtils.prop('guess_game_onboarding_first_message_ask_no_btn'), filled: true, eventName: 'onboarding-translation-no', triggerDismiss: true },
                            { name: 'translation-message', text: i18nUtils.prop('guess_game_onboarding_first_message_ask_yes_btn'), filled: true, eventName: 'onboarding-translation-yes', triggerDismiss: true }
                        ]
                    };
                }
                break;

            case '2nd-card-message':
                return {
                    name: step,
                    icon: 'student-cap',
                    title: i18nUtils.removeHTMLTags(i18nUtils.prop('guess_game_onboarding_second_message_grn3160_title')),
                    text: i18nUtils.prop('guess_game_onboarding_second_message_grn3160_text', { tl: question.target_language }),
                    closable: true,
                    actions: [
                        { name: '2nd-card-message', text: i18nUtils.prop('onboarding_btn_continue'), filled: true, eventName: 'onboarding-2nd-card-continue' },
                    ]
                };

            case '3rd-card-message':
                return {
                    name: step,
                    icon: 'student-cap',
                    title: i18nUtils.removeHTMLTags(i18nUtils.prop('guess_game_onboarding_third_message_grn3160_title')),
                    text: i18nUtils.prop('guess_game_onboarding_third_message_grn3160_text'),
                    closable: true,
                    actions: [
                        { name: '3rd-card-message', text: i18nUtils.prop('onboarding_btn_continue'), filled: true, eventName: 'onboarding-3rd-card-continue' },
                    ]
                };

            case 'first-mistake-message':
                return {
                    name: step,
                    icon: 'student-cap',
                    title: i18nUtils.prop('onboarding_message_first_mistake'),
                    closable: false,
                    actions: [
                        { name: 'first-mistake-message', text: i18nUtils.prop('onboarding_btn_ok'), filled: true, eventName: 'onboarding-mistake-got-it' },
                    ]
                };

            case 'strict-diacritics-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_strict_diacritics_title_correct'),
                    text: i18nUtils.prop('onboarding_strict_diacritics_text_exit_generic'),
                    closable: false,
                    actions: [
                        { name: 'strict-diacritics-message', text: i18nUtils.prop('onboarding_auto_advance_intro_button_settings'), eventName: 'onboarding-diacritics-settings' },
                        { name: 'strict-diacritics-message', text: i18nUtils.prop('onboarding_btn_continue'), filled: true, eventName: 'onboarding-diacritics-continue', triggerDismiss: true }
                    ]
                };

            case 'synonym-message':
                return {
                    name: step,
                    icon: 'student-cap',
                    title: i18nUtils.prop('synonyms_tutor_first_message_txt'),
                    closable: false,
                    actions: [
                        { name: 'synonym-message', text: i18nUtils.prop('synonyms_tutor_first_message_btn'), filled: true, eventName: 'onboarding-synonym-got-it' },
                    ]
                };

            case 'dont-overdo-message':
                return {
                    name: step,
                    icon: 'brain-training',
                    title: i18nUtils.prop('sets_overdo_warning_doorslam_title'),
                    text: i18nUtils.prop('dynamic_goals_overdo_warning_doorslam_text'),
                    closable: false,
                    actions: [
                        { name: 'dont-overdo-message', text: i18nUtils.prop('sets_overdo_warning_doorslam_btn_ok'), filled: true, eventName: 'dont-overdo-ok' },
                        { name: 'dont-overdo-message', text: i18nUtils.prop('sets_overdo_warning_doorslam_btn_cancel'), filled: false, eventName: 'dont-overdo-cancel' },
                    ]
                };

            case 'wordlist-favourite-message':
                return {
                    name: step,
                    title: i18nUtils.prop('favorite_word_modal_title'),
                    text: i18nUtils.prop('favorite_word_modal_text'),
                    closable: false,
                    actions: [
                        { name: 'wordlist-favourite-message', text: i18nUtils.prop('mute_word_modal_button_dismiss'), eventName: 'onboarding-wordlist-got-it', triggerDismiss: true },
                        { name: 'wordlist-favourite-message', text: i18nUtils.prop('mute_word_modal_button_show_list'), filled: true, eventName: 'onboarding-wordlist-open', triggerDismiss: true }
                    ]
                };

            case 'japanese-scripts-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_japanese_scripts_title'),
                    text: i18nUtils.prop('onboarding_japanese_scripts_text'),
                    illustrationId: 'japanese-scripts',
                    closable: true,
                    actions: [
                        { name: 'japanese-scripts-message', text: i18nUtils.prop('onboarding_btn_no'), filled: true, eventName: 'japanese-scripts-message-no' },
                        { name: 'japanese-scripts-message', text: i18nUtils.prop('onboarding_btn_yes'), filled: true, eventName: 'japanese-scripts-message-yes' }
                    ]
                };

            case 'japanese-hiragana-katakana-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_kana_title'),
                    text: i18nUtils.prop('onboarding_kana_text_web'),
                    illustrationId: 'hiragana-katakana',
                    closable: true,
                    actions: [
                        { name: 'japanese-hiragana-katakana-message', text: i18nUtils.prop('onboarding_btn_ok'), eventName: 'japanese-hiragana-katakana-ok' },
                    ]
                };

            case 'japanese-hiragana-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_hiragana_title'),
                    texts: [
                        i18nUtils.prop(1, null, null, 'onboarding_hiragana_recommended_text'),
                        i18nUtils.prop(2, null, null, 'onboarding_hiragana_recommended_text')
                    ],
                    illustrationId: 'hiragana',
                    closable: true,
                    actions: [
                        { name: 'japanese-hiragana-message', text: i18nUtils.prop('btn_later'), eventName: 'japanese-hiragana-message-later' },
                        { name: 'japanese-hiragana-message', text: i18nUtils.prop('btn_ja_learn_hiragana'), filled: true, eventName: 'japanese-hiragana-message-learn' }
                    ]
                };

            case 'japanese-hiragana-learning-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_hiragana_title'),
                    text: i18nUtils.prop('onboarding_hiragana_now_learning_text'),
                    illustrationId: 'hiragana-learning',
                    closable: true,
                    actions: [
                        { name: 'japanese-hiragana-learning-message', text: i18nUtils.prop('btn_continue'), eventName: 'japanese-hiragana-learning-message-continue' },
                    ]
                };

            case 'form-spellings-message':
                setTimeout(() => {
                    EventBus.$emit('guess:show-hint-mark', 'card-form-spellings');
                }, 500);

                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_form_comment_title'),
                    text: i18nUtils.prop('onboarding_form_comment_text'),
                    illustrationId: 'form-spelling',
                    closable: true,
                    triggerDismiss: true,
                    actions: [
                        { name: 'form-spellings-message', text: i18nUtils.prop('onboarding_btn_ok'), eventName: 'form-spellings-message-got-it', triggerDismiss: true },
                    ]
                };

            case 'korean-scripts-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_hangeul_script_title'),
                    text: i18nUtils.prop('onboarding_hangeul_script_text'),
                    illustrationId: 'korean-scripts',
                    closable: true,
                    actions: [
                        { name: 'korean-scripts-message', text: i18nUtils.prop('onboarding_btn_no'), filled: true, eventName: 'korean-scripts-message-no' },
                        { name: 'korean-scripts-message', text: i18nUtils.prop('onboarding_btn_yes'), filled: true, eventName: 'korean-scripts-message-yes' }
                    ]
                };

            case 'korean-hangeul-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_hangeul_variation_title'),
                    text: i18nUtils.prop('onboarding_hangeul_variation_text_web'),
                    illustrationId: 'korean-hangeul',
                    closable: true,
                    actions: [
                        { name: 'korean-hangeul-message', text: i18nUtils.prop('guess_game_onboarding_first_message_ask_given_word_understood_btn'), filled: true, eventName: 'korean-hangeul-message-gotit' }
                    ]
                };

            case 'korean-hangeul-scripts-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_hangeul_script_title'),
                    text: i18nUtils.prop('onboarding_hangeul_recommended_text'),
                    illustrationId: 'korean-hangeul-scripts',
                    closable: true,
                    actions: [
                        { name: 'korean-hangeul-scripts-message', text: i18nUtils.prop('btn_later'), eventName: 'korean-hangeul-scripts-message-later' },
                        { name: 'korean-hangeul-scripts-message', text: i18nUtils.prop('btn_learn_now'), filled: true, eventName: 'korean-hangeul-scripts-message-learn' }
                    ]
                };

            case 'korean-hangeul-learning-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_hangeul_variation_simple_title'),
                    text: i18nUtils.prop('onboarding_hangeul_now_learning_text'),
                    illustrationId: 'hangeul-learning',
                    closable: true,
                    actions: [
                        { name: 'korean-hangeul-learning-message', text: i18nUtils.prop('btn_continue'), eventName: 'korean-hangeul-learning-message-continue' },
                    ]
                };

            case 'voice-input-message':
                return {
                    name: step,
                    title: i18nUtils.prop('speaking_doorslam_title'),
                    text: i18nUtils.prop('speaking_doorslam_text_web'),
                    illustrationId: 'voice-input',
                    illustrationPosition: 'left',
                    closable: true,
                    actions: [
                        { name: 'voice-input-message', text: i18nUtils.prop('speech_input_doorslam_btn2'), eventName: 'voice-input-message-later', triggerDismiss: true },
                        { name: 'voice-input-message', text: i18nUtils.prop('speech_input_doorslam_btn1'), eventName: 'voice-input-message-ok', filled: true },
                    ]
                };

            case 'russian-cyrillic-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_ru_cyrillic_script_title'),
                    text: i18nUtils.prop('onboarding_ru_cyrillic_script_text'),
                    illustrationId: 'russian-cyrillic',
                    closable: true,
                    actions: [
                        { name: 'russian-cyrillic-message', text: i18nUtils.prop('onboarding_btn_no'), filled: true, eventName: 'russian-cyrillic-message-no' },
                        { name: 'russian-cyrillic-message', text: i18nUtils.prop('onboarding_btn_yes'), filled: true, eventName: 'russian-cyrillic-message-yes' }
                    ]
                };

            case 'russian-cyrillic-script-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_ru_cyrillic_script_title'),
                    text: i18nUtils.prop('onboarding_ru_cyrillic_recommended_text', { tl: question.target_language }),
                    illustrationId: 'russian-cyrillic-script',
                    closable: true,
                    actions: [
                        { name: 'russian-cyrillic-message', text: i18nUtils.prop('btn_later'), filled: false, eventName: 'russian-cyrillic-message-later' },
                        { name: 'russian-cyrillic-message', text: i18nUtils.prop('btn_learn_now'), filled: true, eventName: 'russian-cyrillic-message-learn' }
                    ]
                };

            case 'russian-cyrillic-deck-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_ru_cyrillic_variation_title'),
                    text: i18nUtils.prop('onboarding_ru_cyrillic_variation_text_web'),
                    illustrationId: 'russian-cyrillic-deck',
                    closable: true,
                    actions: [
                        { name: 'russian-cyrillic-deck-message', text: i18nUtils.prop('onboarding_btn_ok'), filled: true, eventName: 'russian-cyrillic-message-gotit' }
                    ]
                };

            case 'russian-cyrillic-learn-message':
                return {
                    name: step,
                    title: i18nUtils.prop('onboarding_ru_cyrillic_variation_simple_title'),
                    text: i18nUtils.prop('onboarding_ru_cyrillic_now_learning_text'),
                    illustrationId: 'russian-cyrillic-script-small',
                    closable: true,
                    actions: [
                        { name: 'russian-cyrillic-deck-message', text: i18nUtils.prop('onboarding_btn_continue'), filled: true, eventName: 'russian-cyrillic-message-continue' }
                    ]
                };
        }
    }

    triggerMessageAction(action, question, answer, auto_advance) {
        switch (action) {
            case 'onboarding-translation-no':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'no-know', 'translation');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_TRANSLATION);
                EventBus.$emit('guess:clear-hint-mark');

                setTimeout(() => {
                    EventBus.$emit('guess:show-message', {
                        icon: 'student-cap',
                        title: i18nUtils.prop('intro_set_tooltip_learn_word'),
                        text: i18nUtils.prop('onboarding_tooltip_entry_box')
                    });
                    EventBus.$emit('guess:show-hint-mark', 'utility-belt-submit-button');
                }, 1000);
                break;
            case 'onboarding-translation-yes':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'know', 'translation');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_TRANSLATION);
                EventBus.$emit('guess:clear-hint-mark');

                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:show-hint-mark', 'card-gap');
                    EventBus.$emit('guess:show-message', {
                        title: i18nUtils.prop('onboarding_tooltip_entry_box'),
                        actions: [
                            { name: 'gap-message', text: i18nUtils.prop('onboarding_btn_ok'), filled: true, eventName: 'onboarding-gap-got-it', triggerDismiss: true }
                        ]
                    });
                }, 1000);
                break;
            case 'onboarding-translation-ok':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'ok', 'translation');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_TRANSLATION);
                EventBus.$emit('guess:clear-hint-mark');
                break;
            case 'onboarding-gap-got-it':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'ok', 'answer-overlay');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_ANSWER);
                EventBus.$emit('guess:clear-hint-mark');
                break;
            case 'onboarding-mistake-got-it':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'got-it', 'first-mistake');
                if (answer.confirmed) {
                    ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_FIRST_MISTAKE);
                    EventBus.$emit('guess:next');
                }
                break;
            case 'onboarding-diacritics-settings':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'settings', 'strict-diacritics');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.SETTINGS_STRICT_DIACRITICS);
                EventBus.$emit('settings:open');
                break;
            case 'onboarding-diacritics-continue':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'continue', 'strict-diacritics');
                if (answer.confirmed) {
                    ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.SETTINGS_STRICT_DIACRITICS);
                    EventBus.$emit('guess:next');
                }
                break;
            case 'onboarding-synonym-got-it':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'got-it', 'synonym');
                EventBus.$emit('guess:un-lock');
                EventBus.$emit('guess:focus-gap');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_SYNONYM);
                break;
            case 'dont-overdo-cancel':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'cancel', 'dont-overdo');
                EventBus.$emit('guess:un-lock');
                if (answer.confirmed && auto_advance) {
                    EventBus.$emit('guess:next');
                }
                break;
            case 'dont-overdo-ok':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'ok', 'dont-overdo');
                EventBus.$emit('guess:un-lock');
                Backbone.history.navigate('hub', { trigger: true });
                break;
            case 'onboarding-wordlist-got-it':
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_WORDLIST);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'got-it', 'wordlist-favourite-message');
                break;
            case 'onboarding-wordlist-open':
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_WORDLIST);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'open', 'wordlist-favourite-message');
                Backbone.history.navigate('insights/wordlist', { trigger: true });
                break;
            case 'japanese-scripts-message-yes':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'yes', 'japanese-scripts-message');
                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:onboarding-step', 'japanese-hiragana-katakana-message');
                }, 1000);
                break;
            case 'japanese-scripts-message-no':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'no', 'japanese-scripts-message');
                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:onboarding-step', 'japanese-hiragana-message');
                }, 1000);
                break;
            case 'japanese-hiragana-message-learn':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'learn', 'japanese-hiragana-message');
                EventBus.$emit('guess:enable-variation-by-feature-flag', 'hiragana'); // Japanese HIRAGANA
                break;
            case 'korean-scripts-message-no':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'no', 'korean-scripts-message');
                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:onboarding-step', 'korean-hangeul-scripts-message');
                }, 1000);
                break;
            case 'korean-scripts-message-yes':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'yes', 'korean-scripts-message');
                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:onboarding-step', 'korean-hangeul-message');
                }, 1000);
                break;
            case 'korean-hangeul-scripts-message-learn':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'learn', 'korean-hangeul-scripts-message');
                EventBus.$emit('guess:enable-variation-by-feature-flag', 'hangeul'); // Korean HANGEUL
                break;
            case 'onboarding-2nd-card-continue':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'continue', '2nd-card-message');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_2ND_CARD);
                break;
            case 'onboarding-3rd-card-continue':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'continue', '3rd-card-message');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_3RD_CARD);
                break;
            case 'voice-input-message-ok':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'ok', 'voice-input-message');
                ControllerManager.instance.getController('Onboarding').setStepDone(ONBOARDING_STEP.GUESS_VOICE_INPUT);
                setTimeout(() => {
                    EventBus.$emit('guess:show-hint-mark', 'utility-belt-voice-button');
                }, 500);
                break;
            case 'russian-cyrillic-message-no':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'no', 'russian-cyrillic-message');
                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:onboarding-step', 'russian-cyrillic-script-message');
                }, 1000);
                break;
            case 'russian-cyrillic-message-yes':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'yes', 'russian-cyrillic-message');
                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:onboarding-step', 'russian-cyrillic-deck-message');
                }, 1000);
                break;
            case 'russian-cyrillic-message-learn':
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-onboarding', 'learn', 'russian-cyrillic-message');
                EventBus.$emit('guess:enable-variation-by-feature-flag', 'cyrillic'); // Russian CYRILLIC
                break;
            case 'japanese-hiragana-message-later':
            case 'japanese-hiragana-learning-message-continue':
            case 'japanese-hiragana-katakana-ok':
            case 'korean-hangeul-message-gotit':
            case 'korean-hangeul-scripts-message-later':
            case 'korean-hangeul-learning-message-continue':
            case 'russian-cyrillic-message-later':
            case 'russian-cyrillic-message-gotit':
            case 'russian-cyrillic-message-continue':
                EventBus.$emit('guess:clear-hint-mark');

                // it's a chain of messages, continue with it
                setTimeout(() => {
                    EventBus.$emit('guess:show-hint-mark', 'card-gap');
                    EventBus.$emit('guess:show-message', {
                        title: i18nUtils.prop('onboarding_tooltip_entry_box'),
                        actions: [
                            { name: 'gap-message', text: i18nUtils.prop('onboarding_btn_ok'), filled: true, eventName: 'onboarding-gap-got-it', triggerDismiss: true }
                        ]
                    });
                }, 1000);
                break;
        }
    }
}
