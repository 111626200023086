var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M11.5 4c-1.4 0-2.7.3-3.8.9-.5.3-1.1.1-1.4-.4-.2-.5 0-1.1.5-1.4 1.4-.7 3-1.1 4.7-1.1C17.3 2 22 6.7 22 12.5S17.3 23 11.5 23 1 18.3 1 12.5c0-1.7.4-3.3 1.1-4.7.3-.5.9-.7 1.4-.5.5.3.7.9.4 1.4-.6 1.1-.9 2.4-.9 3.8 0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5S16.2 4 11.5 4z",
        },
      }),
      _c("circle", {
        attrs: { fill: "#AC6ED1", cx: "6.5", cy: "12.5", r: "1.5" },
      }),
      _c("circle", {
        attrs: { fill: "#AC6ED1", cx: "11.5", cy: "12.5", r: "1.5" },
      }),
      _c("circle", {
        attrs: { fill: "#AC6ED1", cx: "16.5", cy: "12.5", r: "1.5" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }