import $ from 'jquery';

const EventUtils = {

    events: {
        'animationend': 'animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd',
        'transitionend': 'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd'
    },

    parseElement: function (element) {
        if (typeof(element.on) === 'undefined') {
            return $(element);
        } else {
            return element;
        }
    },

    oneAnimationEnd: function ($element, callback) {
        this.one('animationend', $element, callback);
    },

    // TODO: Make possible to use with promises
    oneTransitionEnd: function ($element, callback, safetyTimeout) {
        this.one('transitionend', $element, callback, safetyTimeout);
    },

    one: function (event, $element, callback, safety) {
        var eventFired = false;
        $element = this.parseElement($element);
        $element.one(this.events[event], function (event) {
            if (!eventFired) {
                eventFired = true;
                callback(event);
            }
        });

        // Transition end event is not foolproof http://www.cuppadev.co.uk/2009/10/the-trouble-with-css-transitions/
        // Therefore a backup in case it fails in the form of a timeout is in place
        if (!isNaN(safety)) {
            setTimeout(function () {
                if (!eventFired) {
                    callback({type: 'timeout'});
                }
            }, parseInt(safety));
        }
    },

    on: function (event, $element, callback) {
        $element = this.parseElement($element);
        $element.on(this.events[event], callback);
    },

    off: function (event, $element) {
        $element = this.parseElement($element);
        if (event !== undefined) {
            $element.off(this.events[event]);
        } else {
            $element.off();
        }

    }
};

export default EventUtils;
