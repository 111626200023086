<template>
    <div class="status-icon">
        <template v-if="this.tooltip">
            <div class="tooltip" v-html="this.$i18n(this.tooltip, { collection: 'hub_variation_status_tooltip' })" />
        </template>
        <template v-if="this.type === 'dot'">
            <StatusDot v-bind="$attrs" />
        </template>
        <template v-else-if="this.type === 'unlimited'">
            <StatusUnlimited />
        </template>
        <template v-else-if="this.type === 'locked'">
            <StatusLocked />
        </template>
        <template v-else-if="this.type === 'complete'">
            <StatusComplete />
        </template>
        <template v-else-if="this.type === 'disabled'">
            <StatusDisabled />
        </template>
        <template v-else-if="this.type === 'unfinished'">
            <StatusUnfinished />
        </template>
        <template v-else>
            🤔
        </template>
    </div>
</template>

<script>
    import StatusDot from './status-dot.vue'
    import StatusLocked from 'Images/status-locked.svg'
    import StatusUnlimited from 'Images/status-unlimited.svg'
    import StatusComplete from 'Images/status-complete.svg'
    import StatusDisabled from 'Images/status-disabled.svg'
    import StatusUnfinished from 'Images/status-unfinished.svg'

    export default {
        name: 'status-icon',
        props: {
            type: String,
            tooltip: String
        },
        components: {
            StatusDot,
            StatusLocked,
            StatusUnlimited,
            StatusComplete,
            StatusDisabled,
            StatusUnfinished
        },
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.status-icon {
        position: relative;
        width: pxToRem(24);
        height: pxToRem(24);
        border-radius: pxToRem(8);
        background-color: $colorBackgroundGray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: fontSize(12);
        > div.tooltip {
            display: none;
            min-width: pxToRem(130);
            max-width: pxToRem(130);
            position: absolute;
            bottom: pxToRem(34);
            border-radius: pxToRem(8);
            background-color: $colorBackgroundDarkLighter;
            padding: pxToRem(8);
            text-align: center;
            color: #fff;
            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 100%;
                right: calc(50% - .25rem);
                border: .25rem solid transparent;
                border-bottom: none;
                border-top-color: $colorBackgroundDarkLighter;
            }
        }
        &:hover {
            > div.tooltip {
                display: block;
            }
        }
    }
</style>
