<template>
    <div class="wordlist-play-button" @click="(!isPlaying) ? play() : stop()" :class="{ isPlaying, isStopping }">
        <svg v-if="this.isPlaying" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V17" :stroke="stopIconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 15H4C2.89543 15 2 15.8954 2 17V18C2 19.1046 2.89543 20 4 20H6C7.10457 20 8 19.1046 8 18V7C8 5.89543 8.89543 5 10 5H19C20.1046 5 21 5.89543 21 7V15C21 16.1046 20.1046 17 19 17H17C15.8954 17 15 16.1046 15 15V14C15 12.8954 15.8954 12 17 12H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'wordlist-play-button',
    props: {
        isPlaying: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isStopping: false,
            stopIconColor: '#2C3143'
        }
    },
    methods: {
        play() {
            this.$emit('play');
        },
        stop() {
            this.$emit('stop');
            this.isStopping = true;
            this.stopIconColor = 'white';
        }
    },
    watch: {
        isPlaying(newValue, oldValue) {
            if (newValue === false) {
                this.isStopping = false;
                this.stopIconColor = '#2C3143';
            }
        }
    },
}
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';
    div.wordlist-play-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: pxToRem(12);
        background-color: $colorSourcePrimary;
        border-radius: .5rem;
        transition: background-color 200ms, transform 200ms;
        cursor: pointer;
        &.isPlaying {
            background-color: $colorKnowledgeMapping;
        }
        &.isPlaying.isStopping {
            background-color: $colorSourcePrimary;
        }
        &:hover:not(.isPlaying) {
            background-color: #000;
        }
        &:active {
            transform: scale(1.1);
        }
    }
</style>
