var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkbox", on: { click: _vm.toggle } }, [
    this.value_
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 16 16",
              "xml:space": "preserve",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M2 0C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h12c1.1 0\n        2-.9 2-2V2c0-1.1-.9-2-2-2H2zm10.7 6.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L7 9.6 4.7 7.3c-.4-.4-1-.4-1.4\n        0-.4.4-.4 1 0 1.4l3 3c.2.2.4.3.7.3s.5-.1.7-.3l5-5z",
              },
            }),
          ]
        )
      : _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 16 16",
              "xml:space": "preserve",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M14 2H2v12h12V2zM2 0C.9 0 0 .9 0 2v12c0 1.1.9 2 2\n        2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2z",
              },
            }),
          ]
        ),
    _vm._v(" "),
    _vm.label !== null
      ? _c("p", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }