
'use strict';

import _ from 'lodash';

import request from 'superagent';

export default class FeedbackSidecarSender {
    constructor () {}

    _getFormData (fields, data) {
        var formData = new FormData();

        _(fields).forEach(function (value, name) {
            formData.append(name, value);
        });

        formData.append('file', new Blob([JSON.stringify(data)], {type: 'application/json'}), fields.key);

        return formData;
    }

    _makePreSignedRequest (url, formData) {
        return Promise.resolve().then(function () {
            return request.post(url).send(formData);
        }).then(function (response) {
            if (response.statusType === 2) {
                return Promise.resolve(response);
            } else {
                var error = Error(`[${response.statusCode}] ${response.statusText}: ${response.text}`);
                error.name = 'UnexpectedPreSignedPostResponse';
                return Promise.reject(error);
            }
        });
    }

    send (apiClient, appState, feedbackEvent) {
        var self = this;

        return Promise.resolve().then(function () {
            return Promise.all([
                apiClient.r.feedback.sidecar_presigned_post.get({
                    client_uuid: feedbackEvent.client_uuid,
                    client_event_sn: feedbackEvent.client_sn
                }),
                appState.getState()
            ]);
        }).then(function (result) {
            var postData = result[0],
                appState = result[1];

            var sidecar = {
                course_state: appState.courseState,
                guess_model_state: appState.guessModelState,
                statistics_state: appState.statisticsState,
                full_logs: appState.fullLogs,
                storage_info: appState.storageInfo
            };

            return self._makePreSignedRequest(postData.url, self._getFormData(postData.fields, sidecar));
        });
    }
}
