var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.synonym
    ? _c("div", { staticClass: "card-synonym" }, [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("synonyms_card_feedback_message", {
                args: { form_synonym: _vm.synonymHtml },
              })
            ),
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }