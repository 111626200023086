<template>
    <main class="hub">
        <course-modal v-if="this.courseModalVisible && this.courses"
                      v-bind:courses="this.courses"
                      v-on:close-course-modal="this.closeCourseModal"/>
        <template v-if="this.course && this.subscription">
            <transition name="slide-fade">
                <day-insights v-if="this.selectedWeekDay && this.selectedWeekDay.moment" :day="this.selectedWeekDay.moment" v-on:close="closeDayInsights" />
            </transition>
            <transition name="slide-fade">
                <dynamic-goal-modal v-if="dynamicGoalModalVisible" v-on:close="closeDynamicGoalModal" />
            </transition>
            <variation-modal v-if="this.variationModalVisible"
                             :variation="this.variationModalData"
                             :is-subscription-active="this.isSubscriptionActive"
                             :disable-general-settings="this.disableGeneralSettings"
                             v-on:close-variation-modal="this.closeVariationModal"
                             v-on:discard-lesson="this.discardLessonWithConfirmation" />
            <active-words-modal v-if="this.activeWordsModalVisible"
                                :course="this.course"
                                :user="this.user"
                                v-on:close-active-words-modal="this.closeActiveWordsModal" />
            <locked-message :is-subscription-active="this.isSubscriptionActive" />
            <div class="grid-top">
                <div class="grid-item hero">
                    <hero v-bind:course="this.course"
                          v-bind:courses="this.courses"
                          v-on:open-course-modal="this.openCourseModal" />
                </div>
                <course-progress v-bind:user="this.user"
                                 v-bind:course="this.course"
                                 v-on:week-day-selected="weekDaySelected"
                                 v-on:thermometer-clicked="this.openActiveWordsModal" />
                <div class="grid-item active-variations" v-if="this.activeVariations">
                    <active-variations v-bind:variations="this.activeVariations"
                                       v-bind:is-game-over="this.gameOver"
                                       v-bind:is-subscription-active="this.isSubscriptionActive"
                                       :disable-general-settings="this.disableGeneralSettings" />
                </div>
            </div>

            <template v-if="this.variationsEnabled && this.focuses && this.focuses.length > 0">
                <h2 v-html="this.$i18n('hub_course_focus_title')"/>
                <variations-grid v-bind:variations="this.focuses" v-bind:is-subscription-active="this.isSubscriptionActive" />
            </template>

            <template v-if="this.courseWizardEnabled && this.lessons">
                <h2 v-html="this.$i18n('hub_course_wizard_title')" />
                <variations-grid v-bind:variations="this.lessons" :create="true" v-bind:is-subscription-active="this.isSubscriptionActive" />
            </template>
        </template>

    </main>
</template>

<script>
    import Backbone from 'backbone';
    import { EventBus } from 'Util/vue-event-bus';
    import i18nUtils from 'Util/i18n';
    import UserManager from 'Modules/usermanager'
    import VButton from 'ViewComponent/v-button.vue';
    import Hero from './components/hero.vue';
    import CourseProgress from './components/course-progress.vue';
    import ActiveVariations from './components/active-variations.vue';
    import VariationsGrid from './components/variations-grid.vue';
    import VariationModal from './components/variation-modal.vue';
    import CourseModal from './components/course-modal/course-modal.vue';
    import { COURSE_FEATURE } from 'Modules/course/course';
    import { find, filter } from 'lodash';
    import moment from 'moment';
    import UserModel from "../../model/user.model.js";
    import { TYPE as TOAST_TYPE } from '../toaster/constants.js';
    import ControllerManager from "Modules/controller.manager";
    import LockedMessage from "../component/locked-message.vue";
    import { CONFIRM_ACTION, ConfirmationView } from "../util/confirmation.view.js";
    import DayInsights from "../insights/components/day-insights.vue";
    import ActiveWordsModal from "./components/active-words-modal.vue";
    import DynamicGoalModal from "./components/dynamic-goal-modal/main.vue";

    export default {
        name: 'hub',
        components: {
            DynamicGoalModal,
            ActiveWordsModal,
            LockedMessage,
            Hero,
            CourseProgress,
            VButton,
            ActiveVariations,
            VariationModal,
            VariationsGrid,
            CourseModal,
            DayInsights
        },
        data() {
            return {
                user: UserManager.instance.getUser(),
                course: null,
                courses: null,
                subscription: null,
                isSubscriptionActive: false,
                variationsEnabled: null,
                courseWizardEnabled: null,
                variationModalVisible: false,
                variationModalData: null,
                variations: null,
                variationStats: null,
                variationStatsFetchedAt: null,
                variationStatsFetchTimeout: null,
                userLessons: null,
                courseModalVisible: false,
                selectedWeekDay: null,
                activeWordsModalVisible: false,
                dynamicGoalModalVisible: false
            }
        },
        computed: {
            activeVariations: function () {
                return this.variations && this.variations.filter(variation => variation.active).map(this.setEditable);
            },
            focuses: function () {
                return this.variations && this.variations.filter(variation => !variation.active && variation.type === 'focus'
                    || !variation.active && variation.type === 'general')
            },
            lessons: function () {
                const _fromVariations = this.variations && this.variations.filter(variation => variation.type === 'lesson').map(this.setEditable);
                if (_fromVariations) {
                    const _activeCourseInfo = this.course.getInfo();
                    let _userUnfinishedLessons = [];

                    if (this.userLessons) {
                        _userUnfinishedLessons = this.userLessons.filter(lesson => (lesson.course_uuid === _activeCourseInfo.uuid && !find(_fromVariations, { uuid: lesson.uuid }))).map(lesson => {
                            lesson.active = false;
                            lesson.icon = 'lesson';
                            lesson.type = 'lesson';
                            lesson.status = 'unfinished';
                            lesson.is_editable = true;
                            return lesson;
                        });
                    }

                    return [ ..._fromVariations.filter(variation => !variation.active), ..._userUnfinishedLessons ];
                } else {
                    return [];
                }
            },
            disableGeneralSettings: function () {
                return !!(this.activeVariations && this.activeVariations.length === 1 && this.activeVariations[0].type === 'general');
            },
            gameOver() {
                return UserManager.instance.getUser().getCourse().getLimits().isGameOver();
            }
        },
        methods: {
            setVariations() {
                if (this.course) {
                    const variationCategories = this.course.getVariationsInfo();
                    let allVariations = [];

                    variationCategories.forEach(category => {
                        const { variations } = category;
                        allVariations.push(...variations);
                    });

                    const variationsRest = allVariations.filter(variation => variation.status !== 'initial');
                    const variationsInitial = allVariations.filter(variation => variation.status === 'initial');

                    this.variations = [ ...variationsRest, ...variationsInitial ];

                    this.fetchVariationStats();
                }
            },
            addStatsToVariation(variation) {
                const _thisVariationStats = find(this.variationStats, { 'uuid': variation.uuid });
                let stats = null;

                if (_thisVariationStats) {
                    const { user_remembered_words, user_total_words } = _thisVariationStats;

                    stats = {
                        user_remembered_words,
                        user_total_words,
                        user_words_to_practice: Math.max(0, user_total_words - user_remembered_words)
                    };
                }

                if (stats) {
                    return { stats, ...variation };
                } else {
                    return variation;
                }
            },
            updateVariationsWithStats() {
                if (!this.variationStats) {
                    return;
                }

                if (this.variations && this.variations.length > 0) {
                    this.variations = this.variations.map(variation => {
                        return this.addStatsToVariation(variation);
                    });
                }
            },
            async fetchVariationStats() {
                const now = moment().local();
                const fetchedMoreThan20SecondsAgo = !!(this.variationStatsFetchedAt && moment.duration(now.diff(this.variationStatsFetchedAt)).asSeconds() > 20);
                let variationIsMissingStats = false;

                if (this.variationStats) {
                    this.updateVariationsWithStats();
                    // check if any of variations is missing stats after update
                    variationIsMissingStats = this.isAnyVariationMissingStats();
                }

                if (!this.variationStats || variationIsMissingStats || fetchedMoreThan20SecondsAgo) {
                    this.variationStatsFetchedAt = now;
                    const { variations } = await this.course.getCourseVariationStats().getAll();
                    this.variationStats = variations;
                    this.updateVariationsWithStats();

                    // check if there's still something missing (might happen with freshly created cw lessons)
                    if (this.isAnyVariationMissingStats()) {
                        clearTimeout(this.variationStatsFetchTimeout); // clear old timeout before setting a new one
                        this.variationStatsFetchTimeout = setTimeout(() => {
                            this.fetchVariationStats();
                        }, 3000);
                    } else {
                        clearTimeout(this.variationStatsFetchTimeout);
                    }
                }
            },
            isAnyVariationMissingStats() {
                return this.variations.some(variation => {
                    return !variation.hasOwnProperty('stats') && variation.status !== 'initial';
                });
            },
            openVariationModal(variation) {
                this.variationModalData = { ...variation };
                this.variationModalVisible = true;
            },
            closeVariationModal() {
                this.variationModalData = null;
                this.variationModalVisible = false;
            },
            openActiveWordsModal() {
                this.activeWordsModalVisible = true;
            },
            closeActiveWordsModal() {
                this.activeWordsModalVisible = false;
            },
            openCourseModal() {
                this.courseModalVisible = true;
            },
            closeCourseModal() {
                this.courseModalVisible = false;
            },
            openDynamicGoalModal() {
                this.dynamicGoalModalVisible = true;
            },
            closeDynamicGoalModal() {
                this.dynamicGoalModalVisible = false;
            },
            enableVariation(variation) {
                if (this.course && !this.gameOver) {
                    this.course.setVariation({ variation, enabled: true });
                    setTimeout(() => {
                        this.closeVariationModal();
                    }, 1000);
                }
            },
            enableVariationOnlyThis(variation) {
                if (this.course && !this.gameOver) {
                    this.course.setVariation({ variation, enabled: true, deactivate_other: true });
                }
            },
            disableVariation(variation) {
                if (this.course && !this.gameOver) {
                    this.course.setVariation({ variation, enabled: false });
                    setTimeout(() => {
                        this.closeVariationModal();
                    }, 1000);
                }
            },
            setEditable (variation) {
                if (variation && variation.type === 'lesson') {
                    variation.is_editable = !!find(this.userLessons, { uuid: variation.uuid });
                }
                return variation;
            },
            async discardLesson(uuid) {
                // delete from lessons-api
                if (!!find(this.userLessons, { uuid: uuid })) {
                    try {
                        await UserManager.instance.getUser().getLessons().delete(uuid);
                        this.userLessons = filter(this.userLessons, lesson => lesson.uuid !== uuid);
                    } catch (e) {
                        console.log('HUB: issue with discardLesson from lessons-api', e);
                    }
                }

                // delete from learn-api
                if (!!find(this.variations, { uuid: uuid })) {
                    try {
                        await UserManager.instance.getUser().deleteVariation(this.course.UUID, uuid);
                        this.variations = filter(this.variations, variation => variation.uuid !== uuid);
                    } catch (e) {
                        console.log('HUB: issue with discardLesson from learn-api', e);
                    }
                }
            },
            async getAndSetUserLessons() {
                this.userLessons = await UserManager.instance.getUser().getLessons().getLessons();
            },
            async getAndSetSubscription() {
                this.subscription = await UserManager.instance.getUser().getSubscription();
                this.isSubscriptionActive = this.subscription.isSubscriptionActive();
            },
            async getAndSetCourses() {
                this.courses = await UserModel.getCourses();
            },
            async changeCourse(course) {
                try {
                    Backbone.trigger('settingsSaveInProgress', 'hub');
                    return await UserModel.setCourse(course.uuid);
                } finally {
                    await this.setCourse();
                    await this.getAndSetUserLessons();
                    await this.getAndSetCourses();
                    Backbone.trigger('saveSettingsProcessFinished');
                    if (course && course.features && course.features.includes('short') && course.words) {
                        ControllerManager.instance.getController('ModalMessages').showShortCourseHub(course.name, course.words);
                    }
                }
            },
            async removeCourse(course) {
                try {
                    return await UserManager.instance.getUser().removeCourse(course.uuid);
                } catch (e) {
                    console.log('HUB: remove course failed with error', e);
                } finally {
                    const _toastText = i18nUtils.prop('account_courses_help_course_remove_success', { course_name: course.name, sl: course.source_language, tl: course.target_language } );
                    const toast = {
                        text: _toastText,
                        type: TOAST_TYPE.SUCCESS
                    };
                    EventBus.$emit('toaster-add', toast);
                    await this.getAndSetCourses();
                }
            },
            async addCourse(course) {
                try {
                    return await UserManager.instance.getUser().enrolToCourse(course.uuid);
                } catch (e) {
                    console.log('HUB: add-course failed with error', e);
                } finally {
                    await this.getAndSetCourses();
                    await this.changeCourse(course);
                }
            },
            async setCourse() {
                if (UserManager.instance.getUser().hasCourse()) {
                    this.course = UserManager.instance.getUser().getCourse();
                } else {
                    console.log('HUB: course is not set yet, opening course modal!');
                    this.course = null;
                    this.openCourseModal();
                    return false;
                }
            },
            discardLessonWithConfirmation(uuid) {
                const confirmationView = this._showLessonDiscardConfirmation();

                confirmationView.on('confirm', action => {
                    if (action === CONFIRM_ACTION.OK) {
                        this.discardLesson(uuid)
                    }

                    Promise.resolve()
                        .then(() => confirmationView.hide())
                        .then(() => {
                            confirmationView.remove();
                        });
                });
            },
            _showLessonDiscardConfirmation () {

                const confirmationView = new ConfirmationView({
                    title: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_title'),
                    message: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_message'),
                    actions: [
                        {
                            title: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_cancel'),
                            action: CONFIRM_ACTION.CANCEL
                        },
                        {
                            title: i18nUtils.prop('lessons_creator_editor_discard_confirm_dialog_confirm'),
                            action: CONFIRM_ACTION.OK,
                            primary: true
                        }
                    ]
                });

                confirmationView.show();

                return confirmationView;
            },
            weekDaySelected(day) {
                this.selectedWeekDay = day;
            },
            closeDayInsights() {
                this.selectedWeekDay = null;
            },
            listenEventBus() {
                // NOTE: when adding to the list, make sure to add $off to beforeDestroy() method as well.
                EventBus.$on('open-variation-modal', this.openVariationModal);
                EventBus.$on('enable-variation', this.enableVariation);
                EventBus.$on('disable-variation', this.disableVariation);
                EventBus.$on('enable-variation-only-this', this.enableVariationOnlyThis);
                EventBus.$on('course-state-updated', this.setVariations); // triggered in user.course.state.manager.js
                EventBus.$on('discard-lesson', this.discardLessonWithConfirmation);
                EventBus.$on('change-course', this.changeCourse);
                EventBus.$on('add-course', this.addCourse);
                EventBus.$on('remove-course', this.removeCourse);
                EventBus.$on('reRenderAllViews', this.getAndSetSubscription);
                EventBus.$on('reRenderAllViews', this.setVariations);
                EventBus.$on('change-dynamic-goal', this.openDynamicGoalModal);
            },
        },
        watch: {
            userLessons (newValue, oldValue) {
                if (newValue !== oldValue)  {
                    this.setVariations();
                }
            },
            async course (newValue, oldValue) {
                if (newValue !== oldValue && newValue !== null) {
                    this.variationsEnabled = await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.VARIATIONS);
                    this.courseWizardEnabled = await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.COURSE_WIZARD);
                }
            }
        },
        created() {
            this.listenEventBus();
            this.getAndSetSubscription();
            this.setCourse();
            this.setVariations();
            this.getAndSetUserLessons(); // calls setVariations() again when complete
            this.getAndSetCourses();
        },
        mounted() {
            Backbone.trigger('rendered', { name: 'hub', type: 'vue' });
        },
        beforeDestroy() {
            EventBus.$off('open-variation-modal', this.openVariationModal);
            EventBus.$off('enable-variation', this.enableVariation);
            EventBus.$off('disable-variation', this.disableVariation);
            EventBus.$off('enable-variation-only-this', this.enableVariationOnlyThis);
            EventBus.$off('course-state-updated', this.setVariations);
            EventBus.$off('discard-lesson', this.discardLessonWithConfirmation);
            EventBus.$off('change-course', this.changeCourse);
            EventBus.$off('add-course', this.addCourse);
            EventBus.$off('remove-course', this.removeCourse);
            EventBus.$off('reRenderAllViews', this.getAndSetSubscription);
            EventBus.$off('reRenderAllViews', this.setVariations);

            clearTimeout(this.variationStatsFetchTimeout);
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';

    $hub-grid-gap: 1.5rem;
    $hub-grid-gap-mobile: 1rem;
    $hub-grid-item-radius: pxToRem(12);

    main.hub {
        position: static;
        padding: $hub-grid-gap * 2;
        max-width: pxToRem(1336);
        margin: 0 auto;
        @include respond-to('medium') {
            padding: $hub-grid-gap;
        }
        @include respond-to('small') {
            padding: $hub-grid-gap-mobile;
        }
        h2 {
            font-size: fontSize(20);
            font-weight: $font-bold;
        }
        > h2 {
            margin: 2rem 1rem 1.5rem;
        }
        > button.button-component {
            margin: 2rem auto;
        }
        div.locked-message {
            margin-bottom: $hub-grid-gap;
            @include respond-to('small') {
                margin-bottom: $hub-grid-gap-mobile;
            }
        }
        div.grid-top {
            display: grid;
            grid-gap: $hub-grid-gap;
            grid-template-columns: 1fr;
            grid-template-areas:
                "hero"
                "course-progress"
                "active-variations";
            @include respond-to('small') {
                grid-gap: $hub-grid-gap-mobile;
            }
            > div.grid-item {
                background-color: white;
                border-radius: $hub-grid-item-radius;
                padding: 1rem;
                h2 {
                    margin-bottom: 1rem;
                }
                &.hero {
                    grid-area: hero;
                }
                &.course-progress {
                    grid-area: course-progress;
                }
                &.active-variations {
                    grid-area: active-variations;
                }
            }
        }

        div.grid {
            display: grid;
            grid-gap: $hub-grid-gap;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @include respond-to('large') {
                #app-root.progress-opened & {
                    grid-template-columns: 1fr 1fr;
                }
            }

            @include respond-to('medium') {
                grid-template-columns: 1fr 1fr;
            }

            @include respond-to('small') {
                grid-template-columns: 1fr;
            }

            @include respond-to('tiny') {
                grid-template-columns: 1fr;
            }

            > div.grid-item {
                background-color: white;
                border-radius: $hub-grid-item-radius;
                //@include elevated;
            }
        }
        div.variation-modal,
        div.course-modal,
        div.active-words-modal {
            z-index: 1000;
        }
        > div.day-insights {
            &.slide-fade-enter-active {
                transition: transform 250ms ease, opacity 350ms linear;
            }
            &.slide-fade-leave-active {
                transition: transform 200ms ease, opacity 100ms linear;
            }
            &.slide-fade-enter, &.slide-fade-leave-to {
                transform: translateX(200px);
                opacity: 0;
            }
        }
    }
</style>
