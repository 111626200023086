var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "controls", class: { open: _vm.open } }, [
    _vm.userCourse
      ? _c(
          "a",
          {
            staticClass: "settings",
            class: { disabled: this.gameOver },
            attrs: { role: "button", tabindex: "0" },
            on: { click: _vm.openSettings },
          },
          [
            _c("div", { staticClass: "icon" }, [_c("settings-icon")], 1),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade" } }, [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.open,
                    expression: "open",
                  },
                ],
                staticClass: "label",
                domProps: {
                  innerHTML: _vm._s(this.$i18n("learn_settings_title")),
                },
              }),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }