var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "day-insights", class: { isOverlay: this.isOverlay } },
    [
      _c(
        "div",
        {
          staticClass: "dialog-window",
          class: { weeklyGoalView: _vm.showWeeklyGoal },
        },
        [
          _c("close-button", {
            staticClass: "close",
            on: { click: _vm.close },
          }),
          _vm._v(" "),
          _vm.showWeeklyGoal
            ? _c("weekly-goal", _vm._g({}, _vm.$listeners))
            : [
                _c("div", { staticClass: "dateOfDay" }, [
                  _c("div", { staticClass: "dayName" }, [
                    _vm._v(_vm._s(this.day.format("dddd"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dayMonth" }, [
                    _vm._v(_vm._s(this.day.format("Do MMMM"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("day-goals", {
                  attrs: { course: this.course, day: this.day },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "collapsibles" },
                  [
                    _c(
                      "collapsible-box-stack",
                      [
                        _c("collapsible-box", {
                          attrs: {
                            i18n_title: {
                              id: "performance_metrics_mistaken_word_count_btn",
                            },
                            "title-meta": _vm.wordsToStudy,
                            i18n_text: {
                              id: "performance_metrics_mistaken_word_count_description",
                            },
                            stacked: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("words-to-learn-icon")]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("collapsible-box", {
                          attrs: {
                            i18n_title: {
                              id: "performance_metrics_learned_words_title",
                            },
                            "title-meta": _vm.learnedWords,
                            stacked: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("learned-words-icon")]
                              },
                              proxy: true,
                            },
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("collapsible-box-content", {
                                    attrs: {
                                      i18n_text: {
                                        id: "performance_metrics_learned_words_flips_up",
                                      },
                                      "text-meta": _vm.learnedWordsPositive,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [_c("trending-up-icon")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "separator" }),
                                  _vm._v(" "),
                                  _c("collapsible-box-content", {
                                    attrs: {
                                      i18n_text: {
                                        id: "performance_metrics_learned_words_flips_down",
                                      },
                                      "text-meta": _vm.learnedWordsNegative,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [_c("trending-down-icon")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("collapsible-box", {
                          attrs: {
                            i18n_title: {
                              id: "performance_metrics_kicks_title",
                            },
                            "title-meta": _vm.wordsReinforced,
                            i18n_text: {
                              collection:
                                "performance_metrics_kicks_description",
                              id: "1",
                            },
                            stacked: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("words-reinforced-icon")]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("collapsible-box", {
                      attrs: {
                        "no-background": true,
                        i18n_title: {
                          id: "daily_performance_stats_metric_cards",
                        },
                        "title-meta": _vm.totalCardsAnswered,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("cards-answered-icon")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "separator" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "separator" }),
                    _vm._v(" "),
                    _c("collapsible-box", {
                      attrs: {
                        "no-background": true,
                        i18n_title: {
                          id: "elastic_goals_calendar_metric_total_time",
                        },
                        "title-meta": _vm.totalTimeSpent,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("time-spent-icon")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "collapsible-box-stack",
                      [
                        _c("collapsible-box", {
                          attrs: {
                            i18n_title: {
                              id: "performance_metrics_new_words_title",
                            },
                            "title-meta": _vm.newWords,
                            i18n_text: {
                              id: "performance_metrics_new_words_copy",
                            },
                            stacked: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("new-words-icon")]
                              },
                              proxy: true,
                            },
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("collapsible-box-content", {
                                    attrs: {
                                      i18n_text: {
                                        id: "performance_metrics_new_words_metric_answered_correct",
                                      },
                                      "text-meta": _vm.newWordsCorrect,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [_c("correct-first-try-icon")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "separator" }),
                                  _vm._v(" "),
                                  _c("collapsible-box-content", {
                                    attrs: {
                                      i18n_text: {
                                        id: "performance_metrics_new_words_metric_answered_incorrect",
                                      },
                                      "text-meta": _vm.newWordsIncorrect,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [_c("new-words-icon")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("collapsible-box", {
                          attrs: {
                            i18n_title: {
                              id: "performance_metrics_correct_rate_title",
                            },
                            i18n_text: {
                              id: "performance_metrics_correct_rate_copy",
                            },
                            "title-meta": _vm.correctRate,
                            stacked: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("correct-rate-icon")]
                              },
                              proxy: true,
                            },
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("collapsible-box-content", {
                                    attrs: {
                                      i18n_text: {
                                        id: "performance_metrics_correct_rate_metric_new_words",
                                      },
                                      "text-meta": _vm.correctRateNew,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [_c("new-words-icon")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "separator" }),
                                  _vm._v(" "),
                                  _c("collapsible-box-content", {
                                    attrs: {
                                      i18n_text: {
                                        id: "performance_metrics_correct_rate_metric_repeat_words",
                                      },
                                      "text-meta": _vm.correctRatePractice,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [_c("correct-rate-icon")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                this.showContinueButton
                  ? _c("v-button", {
                      staticClass: "continue-button",
                      attrs: {
                        i18n: { id: "sets_end_of_set_stats_screen_btn" },
                        filled: true,
                        color: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.continuePressed.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }