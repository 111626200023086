var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M16.297 14.703A5 5 0 1015 6L20 11l-3.703 3.703z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.5 16.5l-3.25 3.25c-.69.69-1.81.69-2.5 0v0c-.69-.69-.69-1.81 0-2.5L7 13",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 13l-2-2 7.5-7.5L20 11l-7.5 7.5-1-1M9.732 10.408l4.902 4.901",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }