<template>
    <div class="expand-button" :title="this.expanded ? this.$i18n('home_drawer_minimise') : this.$i18n('home_drawer_expand')">
        <collapse-icon v-if="this.expanded" />
        <expand-icon v-else />
    </div>
</template>

<script>
    import ExpandIcon from 'Images/expand.svg';
    import CollapseIcon from 'Images/collapse.svg';

    export default {
        name: 'expand-button',
        props: {
            expanded: {
                type: Boolean,
                default: false
            },
        },
        components: {
            ExpandIcon,
            CollapseIcon
        },
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.expand-button {
        position: relative;
        width: pxToRem(40);
        height: pxToRem(40);
        border-radius: pxToRem(8);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: fontSize(12);
        line-height: normal;
        cursor: pointer;
        pointer-events: auto;
        &:hover {
            cursor: pointer;
            background-color: $colorBackgroundGraySecondary;
        }
        &:active {
            background-color: $colorBackgroundGrayDarker;
        }
    }
</style>
