var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M4.6 14H5V6.2A1.2 1.2 0 016.2 5H19V4a2 2 0 00-2-2H3a2 2 0 00-2 2v13.575L4.6 14z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13 17h8a1 1 0 001-1V6a1 1 0 00-1-1H6a1 1 0 00-1 1v15l4-4M10 9l7 4M10 13l7-4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }