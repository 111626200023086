<template>
    <div class="free-trial-heading">
        <start-trial-illustration />
        <div class="text">
            <h2 v-html="this.$i18n('product_highlights_title_1')" />
            <p v-html="this.$i18n('product_highlights_text_1')" />
        </div>
    </div>
</template>

<script>
    import StartTrialIllustration from 'Images/pay/start-free-trial.svg';
    export default {
        name: 'free-trial-heading',
        components: { StartTrialIllustration }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    div.free-trial-heading {
        max-width: pxToRem(616);
        margin: 2rem auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        > svg {
            min-width: pxToRem(119);
        }
        > div.text {
            padding: 2rem;
            > h2 {
                margin-bottom: 1rem;
            }
        }
        @include respond-to('small') {
            flex-direction: column;
            justify-content: center;
            margin: 1rem auto 0;
            > div.text {
                margin-top: 1rem;
                text-align: center;
                padding: 1rem;
            }
        }
    }
</style>
