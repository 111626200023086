<template>
    <div class="collapsible-box-content">
        <div class="icon-wrapper">
            <slot name="icon"></slot>
        </div>
        <template v-if="text || i18n_text">
            <div class="text" v-if="text">{{ text }}</div>
            <div class="text" v-else-if="i18n_text" v-html="this.$i18n(i18n_text.id, { collection: i18n_text.collection, args: i18n_text.params })" />
        </template>
        <div class="text-meta" v-html="textMeta" />
    </div>
</template>

<script>
    export default {
        name: 'collapsible-box-content',
        props: {
            text: {
                type: String
            },
            i18n_text: {
                type: Object
            },
            textMeta: {
                type: [String, Number],
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.collapsible-box-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: .5rem;
        > div.icon-wrapper {
            min-width: pxToRem(24);
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: pxToRem(24);
        }
        > div.text {
            flex: 100%;
            color: $colorSourceSecondary;
            display: inline-block;
            font-size: fontSize(14);
            line-height: 1.35em;
        }
        > div.text-meta {
            margin-left: auto;
            color: $colorSourceSecondary;
            display: inline-block;
            font-size: fontSize(14);
            line-height: 1.35em;
            body[data-interface-language="ar"] & {
                margin-right: auto;
                margin-left: unset;
            }
        }
    }
</style>
