var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    {
      staticClass: "pay",
      class: { loading: _vm.loading },
      attrs: { id: "pay" },
    },
    [
      this.supportModalVisible
        ? _c("support-modal-pay", { on: { close: _vm.closeSupportModal } })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading-overlay" }, [_c("loader")], 1)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          this.page === "success"
            ? _c("success", { attrs: { "show-receipt-info": _vm.receiptSent } })
            : this.page === "failure"
            ? _c("failure")
            : [
                this.page === "catalog" && !_vm.selectedProduct
                  ? _c("catalog", {
                      attrs: {
                        products: this.products,
                        userHasRecurringService: this.userHasRecurringService,
                        userCanManageSubscription:
                          this.userCanManageSubscription,
                        userOnPreTrial: this.userOnPreTrial,
                        introTrialAvailable: this.introTrialAvailable,
                        brandingId: this.brandingId,
                        paymentProvider: this.paymentProvider,
                        showMessages: true,
                      },
                      on: {
                        "product-selected": _vm.productSelected,
                        navigate: _vm.onNavigate,
                      },
                    })
                  : this.page === "checkout"
                  ? _c("checkout", {
                      attrs: {
                        user: _vm.user,
                        enableThreeDSecure: _vm.enableThreeDSecure,
                        userHasRecurringService: this.userHasRecurringService,
                        userCanManageSubscription:
                          this.userCanManageSubscription,
                        product: _vm.selectedProduct,
                        token: _vm.token,
                      },
                      on: {
                        "clear-selected-product": _vm.clearSelectedProduct,
                      },
                    })
                  : this.page === "manage"
                  ? _c("manage", {
                      attrs: {
                        user: _vm.user,
                        activeProduct: _vm.activeProduct,
                        enableThreeDSecure: _vm.enableThreeDSecure,
                        brandingId: _vm.brandingId,
                        token: _vm.token,
                      },
                    })
                  : this.page === "voucher"
                  ? _c("voucher", {
                      attrs: {
                        user: _vm.user,
                        products: this.products,
                        userHasRecurringService: this.userHasRecurringService,
                        userCanManageSubscription:
                          this.userCanManageSubscription,
                        userOnPreTrial: this.userOnPreTrial,
                        introTrialAvailable: this.introTrialAvailable,
                        brandingId: this.brandingId,
                        paymentProvider: this.paymentProvider,
                        voucherCodeInitial: this.voucherCode,
                      },
                      on: {
                        "product-selected": _vm.productSelected,
                        navigate: _vm.onNavigate,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                this.page === "catalog"
                  ? _c("have-a-voucher", { on: { navigate: _vm.onNavigate } })
                  : _vm._e(),
                _vm._v(" "),
                this.page === "catalog"
                  ? _c("div", { staticClass: "footnotes" }, [
                      _vm.userOnPreTrial || _vm.introTrialAvailable
                        ? _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n(this.brandingId, {
                                  collection:
                                    "pay_product_recurring_text_cancel",
                                })
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("pay_product_recurring_text_billed")
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.page === "catalog" ? _c("intro") : _vm._e(),
                _vm._v(" "),
                this.page === "checkout" || this.page === "manage"
                  ? _c("secure")
                  : _vm._e(),
                _vm._v(" "),
                this.page === "catalog" ? _c("featured-in") : _vm._e(),
                _vm._v(" "),
                _vm.showFaq ? _c("faq") : _vm._e(),
                _vm._v(" "),
                this.page === "catalog"
                  ? _c("div", { staticClass: "anchor-container" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#pay",
                              expression: "'#pay'",
                            },
                          ],
                          staticClass: "anchor",
                        },
                        [
                          _c("arrow-icon", { staticClass: "arrow-up" }),
                          _vm._v(" "),
                          _c("h2", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n("navigation_anchor_see_plans_label")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "floater",
                    on: { click: _vm.openSupportModal },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "floater-content" },
                      [
                        _c("help-icon"),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n("support_float_button_label")
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }