var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M20 11v6c0 2.8-2.24 5-5 5-2.764 0-5-2.203-5-5M7 5v-.5A1.5 1.5 0 005.5 3v0A1.5 1.5 0 004 4.5v3.506A5.998 5.998 0 0010 14c3.313 0 6-2.684 6-5.994V4.5A1.5 1.5 0 0014.5 3v0A1.5 1.5 0 0013 4.5v.565",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M20 12a2 2 0 100-4 2 2 0 000 4z", fill: "#63E1E7" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }