var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M21.62 14.873a1 1 0 01.165 1.405l-3.875 4.914A.995.995 0 0118.5 21H21a1 1 0 110 2h-2.5a1 1 0 01-.923-1.384l-.792 1.003a1 1 0 01-1.57-1.238l5-6.342a1 1 0 011.404-.166zM8 4a1 1 0 011 1v7a1 1 0 11-2 0V5a1 1 0 011-1zM5 15a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1z",
          fill: "#E2B4FF",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5.383 1.076A1 1 0 016 2v7a1 1 0 01-2 0V4.414l-.293.293a1 1 0 01-1.414-1.414l2-2a1 1 0 011.09-.217zM8 12a1 1 0 01-1 1H3a1 1 0 110-2h4a1 1 0 011 1zM13 12.659C13 10.697 14.51 9 16.5 9s3.5 1.697 3.5 3.659a1 1 0 01-.215.619l-3.875 4.914A.996.996 0 0116.5 18H19a1 1 0 110 2h-2.5a1 1 0 01-.923-1.384l-.792 1.003a1 1 0 01-1.57-1.238l4.759-6.036C17.837 11.54 17.186 11 16.5 11c-.771 0-1.5.684-1.5 1.659a1 1 0 01-2 0z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }