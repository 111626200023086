import _ from 'lodash';
import Raven from 'raven-js';

export let Configuration = {};

export default function getConfigValue (key) {

    if (Object.keys(Configuration).length === 0) {
        if (window.__public_configuration == null) {
            throw Error("Configuration loading has failed");
        } else {
            Configuration = _.clone(window.__public_configuration);
            delete window.__public_configuration;
        }
        console.debug('Loaded public configuration:', Configuration);
    }

    if (Configuration[key] === undefined) {
        Raven.captureMessage(`Requested missing configuration key="${key}"`,
            { level: 'warning', logger: 'manual', extra: { Configuration: Configuration }});
    }

    return Configuration[key];
}
