
import _ from 'lodash';
import AsyncDestroyable from './async.destroyable.js';


const STORAGE_PREFIX = 'PSP_STORAGE/';

export class LocalStorageStorageProvider {

    static test () {
        var supportsLocalStorage;
        try {
            supportsLocalStorage = 'localStorage' in window && window.localStorage !== null;

            var testItemKey = 'testItem_' + Date.now();

            localStorage.setItem(testItemKey, "test");

            if (localStorage.getItem(testItemKey) !== 'test') {
                supportsLocalStorage = false;
            }

            localStorage.removeItem(testItemKey);
        } catch (error) {
            // If the browser is in a private browsing mode, or localStorage is otherwise disabled
            // an exception is thrown upon trying to use it
            console.warn('localStorage is not available. Test failed with', error);
            supportsLocalStorage = false;
        }

        return supportsLocalStorage;
    }

    static getInstance () {
        if (LocalStorageStorageProvider._instance === undefined) {
            LocalStorageStorageProvider._instance = new LocalStorageStorageProvider(window.localStorage);
        }

        return LocalStorageStorageProvider._instance;
    }

    constructor (localStorage) {
        this._localStorage = localStorage;
        this.isPersistent = true;
    }

    getItem (key) {
        return Promise.resolve(JSON.parse(this._localStorage.getItem(key)));
    }

    setItem (key, item) {
        try {
            this._localStorage.setItem(key, JSON.stringify(item));
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    removeItem (key) {
        this._localStorage.removeItem(key);
        return Promise.resolve();
    }

    getItemsByPrefix (prefix) {
        var result = {};
        for (var key in this._localStorage) {
            if (this._localStorage.hasOwnProperty(key) && key.indexOf(prefix) === 0) {
                result[key] = JSON.parse(this._localStorage.getItem(key));
            }
        }
        return Promise.resolve(result);
    }
}

export class NonPersistentStorageProvider {

    static test () {
        // This storage provider is always available
        return true;
    }

    static getInstance () {
        if (NonPersistentStorageProvider._instance === undefined) {
            NonPersistentStorageProvider._instance = new NonPersistentStorageProvider();
        }

        return NonPersistentStorageProvider._instance;
    }

    constructor () {
        this.storageMap = {};
        this.isPersistent = false;
    }

    getItem (key) {
        if (this.storageMap[key] === undefined) {
            return Promise.resolve(null);
        }
        return Promise.resolve(this.storageMap[key]);
    }

    setItem (key, item) {
        this.storageMap[key] = item;
        return Promise.resolve();
    }

    removeItem (key) {
        delete this.storageMap[key];
        return Promise.resolve();
    }

    getItemsByPrefix (prefix) {
        var result = {};
        for (var key in this.storageMap) {
            if (this.storageMap.hasOwnProperty(key) && key.indexOf(prefix) === 0) {
                result[key] = this.storageMap[key];
            }
        }
        return Promise.resolve(result);
    }
}

export class AnonymousPersistentStorageProvider extends AsyncDestroyable {

    constructor (storageProvider) {
        super([
            'setItemAnonymous', 'getItemAnonymous', 'removeItemAnonymous', 'getItem', 'setItem', 'getItemsByPrefix', 'getItemsAnonymousByPrefix'
        ]);
        this.anonymousPrefix = STORAGE_PREFIX + 'anonymous/';

        this._storageProvider = storageProvider;
        this._storagePersistent = storageProvider.isPersistent;
    }

    isStoragePersistent () {
        return this._storagePersistent;
    }

    _makeAnonymousStorageKey (key) {
        return this.anonymousPrefix + key;
    }

    setItemAnonymous (key, value) {
        return this._storageProvider.setItem(this._makeAnonymousStorageKey(key), value);
    }

    getItemAnonymous (key) {
        return this._storageProvider.getItem(this._makeAnonymousStorageKey(key));
    }

    removeItemAnonymous (key) {
        return this._storageProvider.removeItem(this._makeAnonymousStorageKey(key));
    }

    async getItemsAnonymousByPrefix (prefix) {
        return await this._storageProvider.getItemsByPrefix(this._makeAnonymousStorageKey(prefix));
    }

    _makeStorageKey () {throw Error('Function `_makeStorageKey` is not implemented');}

    getItem () {throw Error('Function `getItem` is not implemented');}

    setItem () {throw Error('Function `setItem` is not implemented');}

    getItemsByPrefix () {throw Error('Function `getItemsByPrefix` is not implemented');}
}


class PersistentStorageProviderException extends Error {
    constructor (message) {
        super(message);
        this.name = 'PersistentStorageProviderException';
    }
}


export class PersistentStorageProvider extends AnonymousPersistentStorageProvider {

     constructor (storageProvider, user) {
        super(storageProvider);

        this._user = user;
    }

    _getUserPrefix () {
         if (!this._user || !_.isString(this._user.UUID)) {
             throw new PersistentStorageProviderException(
                 `Unable to get user prefix user=${!!this._user} uuid=${this._user && this._user.UUID}`);
         } else {
             return STORAGE_PREFIX + this._user.UUID + '/';
         }
    }

    _makeStorageKey (key) {
        return this._getUserPrefix() + key;
    }

    setItem (key, value) {
        return this._storageProvider.setItem(this._makeStorageKey(key), value);
    }

    getItem (key) {
        return this._storageProvider.getItem(this._makeStorageKey(key));
    }

    removeItem (key) {
        return this._storageProvider.removeItem(this._makeStorageKey(key));
    }

    getItemsByPrefix (prefix) {
        var self = this;
        return Promise.resolve().then(function () {
            return self._storageProvider.getItemsByPrefix(self._makeStorageKey(prefix));
        }).then(function (results) {
            var normalizedResults = {};
            Object.keys(results).forEach(function (key) {
                normalizedResults[key.substr(self._getUserPrefix().length, key.length)] = results[key];
            });
            return Promise.resolve(normalizedResults);
        });

    }
}

export class PersistentStorageProviderFactory {

    constructor (storageProviders) {
        this.storageProviders = storageProviders;
        this._anonymous_instance = null;
    }

    chooseStorageProvider () {
        // Choose the first storage provider which tests positive
        // A default should always be provided, otherwise an error occurs
        for (var i = 0; i < this.storageProviders.length; i++) {
            var StorageProvider = this.storageProviders[i];
            if (StorageProvider.test()) {
                return StorageProvider;
            }
        }

        throw Error('No suitable storage provider found');
    }

    getPersistentStorageProvider (user) {
        if (user == null) {
            if (this._anonymous_instance === null) {
                this._anonymous_instance = new AnonymousPersistentStorageProvider(this.chooseStorageProvider().getInstance());
            }
            return this._anonymous_instance;
        } else {
            return new PersistentStorageProvider(this.chooseStorageProvider().getInstance(), user);
        }
    }

}

const pspFactory = new PersistentStorageProviderFactory([
    LocalStorageStorageProvider,
    NonPersistentStorageProvider
]);

export function getPersistentStorageProvider (user) {
    return pspFactory.getPersistentStorageProvider(user);
}
