import Backbone from 'backbone';

import UserModel from '../../model/user.model.js';
import i18nUtils from '../../util/i18n.js';
import renderTemplate from '../../util/template.renderer.js';

const ForgotPasswordView = Backbone.View.extend({

    name: 'forgot-password',

    tagName: 'main',
    className: 'forgot-password-view',

    events: {
        'click .forgot-password-submit': 'submit',
        'keypress': 'submitOnEnter',
        'click div.back-arrow': 'goBack'
    },

    initialize: function (options) {
        console.log("Forgot password view init");

        var queryParameters = options.queryParameters;
        if (queryParameters) {
            if (queryParameters.email) {
                this._email = queryParameters.email;
            }
            if (queryParameters.language) {
                i18nUtils.setInterfaceLanguage(queryParameters.language);
            }
        }

        if (options.router) {
            this._router = options.router;
        }
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('forgot-password', { 'email': this._email }, this.$el).then( () => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
    },

    forgotPasswordSuccess: function (status) {
        Backbone.trigger('passwordResetProcessFinished', 'forgot-password');
        switch (status) {
            case 'email-sent':
                this.$el.find('section.help > p.success-message').text(i18nUtils.prop('forgot_password_message_email_sent')).show();
                setTimeout(() => {
                    Backbone.history.navigate('signin-credentials', {trigger: true});
                }, 3000);
                break;
        }
    },

    forgotPasswordError: function (error) {
        this.$el.find('.form input, .form a, .forgot-password-submit').prop('disabled', false);
        Backbone.trigger('passwordResetProcessFinished', 'forgot-password');
        switch (error) {
            case 'error-empty-email':
                this.$el.find('input.email').addClass('error');
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('forgot_password_error_empty_email')).show();
                break;
            case 'error-no-user-with-email':
                this.$el.find('input.email').addClass('error');
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('forgot_password_error_no_user_with_email')).show();
                break;
            case 'error-server':
                this.$el.find('section.help > p.error-message').text(i18nUtils.prop('forgot_password_error_server')).show();
                break;
        }
    },

    submit: function (event) {
        this.$el.find('section.help > p.error-message').hide();
        this.$el.find('section.help > p.success-message').hide();
        this.$el.find('input.error').removeClass('error');
        this.$el.find('.form input, .form a, .forgot-password-submit').prop('disabled', true);
        Backbone.trigger('passwordResetInProgress', 'forgot-password');
        var self = this;
        var emailAddress = this.$el.find('input.email').val();
        UserModel.sendForgotPasswordEmail(emailAddress,
            function (error) {
                self.forgotPasswordError(error);
            },
            function (status) {
                self.forgotPasswordSuccess(status);
            }
        );
    },

    submitOnEnter: function (event) {
        if (event.keyCode === 13) {
            this.submit();
        }
    },

    goBack: function () {
        window.history.back();
    }
});

export default ForgotPasswordView;
