var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8 11c-1.842 1.18-3 2.838-3 5.2 0 2.558 1.114 4.85 3 5.8h8c1.886-.95 3-3.242 3-5.8 0-2.362-1.158-4.02-3-5.2h-3.5M8 11V8.4C8 7.075 9.228 6 10.743 6h2.514C14.772 6 16 7.075 16 8.4V11h-4M10 4.2A1.2 1.2 0 0111.2 3h1.6A1.2 1.2 0 0114 4.2V6h-4V4.2z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 16.198C17 17.77 16.5 19 15.5 20h-7c-1-1-1.5-2.23-1.5-3.802 0-1.267.193-.211 2.955-.211 2.761 0 7.045-2.355 7.045.211z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 11v6.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }