<template>
    <div class="navigation-item" :class="{ active: active, dropdownOpen: dropdownOpen }">
        <div class="navigation-item-button" @click="buttonClick" role="button">
            <span class="badge red" v-if="notify && !active" />
            <div class="icon">
                <slot name="icon" />
            </div>
            <transition name="fadeLabel">
                <div class="label" v-show="open" v-html="this.$i18n(i18n.id, { collection: i18n.collection, args: i18n.params })" />
            </transition>
            <svg class="dropdown-arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hasSubRoutes && open" :class="{ isOpen: this.dropdownOpen }">
                <path d="M4 7L8 10L12 7" stroke="#626C91" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <div class="sub-routes" v-if="open && hasSubRoutes && dropdownOpen">
            <div class="sub-route" v-for="subRoute in subRoutes" @click="navigateSubRoute(subRoute.route)" :class="{ active: subRoute.active }" v-if="!subRoute.hidden">
                <span class="label" v-html="$i18n(subRoute.i18n.id, { collection: subRoute.i18n.collection, args: subRoute.i18n.params })" />
            </div>
        </div>
    </div>
</template>

<script>
import { find } from "lodash";
import { EventBus } from "Util/vue-event-bus.js";

export default {
        name: 'navigation-item',
        props: {
            i18n: {
                type: Object,
                required: true
            },
            uri: {
                type: String,
            },
            route: {
                type: String,
            },
            active: {
                type: Boolean,
                default: false
            },
            open: {
                type: Boolean,
                default: false
            },
            notify: {
                type: Boolean,
                default: false
            },
            subRoutes: {
                type: Array
            }
        },
        data() {
            return {
                dropdownOpen: false
            }
        },
        computed: {
            hasSubRoutes() {
                return this.subRoutes && this.subRoutes.length > 0
            }
        },
        methods: {
            toggle() {
                this.dropdownOpen = !this.dropdownOpen;
                if (this.dropdownOpen) {
                    EventBus.$emit('home-drawer:dropdown-opened', this._uid);
                }
            },
            closeNotInFocus(id) {
                if (this._uid !== id) {
                    this.dropdownOpen = false;
                }
            },
            buttonClick() {
                if (this.hasSubRoutes) {
                    this.toggle();
                } else {
                    if (this.uri) {
                        this.$emit('navigate', { type: 'uri', uri: this.uri })
                    } else if (this.route) {
                        this.$emit('navigate', { type: 'route', route: this.route })
                    }
                }
            },
            navigateSubRoute(route) {
                if (route) {
                    this.$emit('navigate', { type: 'route', route })
                }
            },
        },
        watch: {
            open(newValue, oldValue) {
                if (newValue === false) {
                    this.dropdownOpen = false;
                }
            }
        },
        mounted() {
            EventBus.$on('home-drawer:dropdown-opened', this.closeNotInFocus);
        },
        beforeDestroy() {
            EventBus.$off('home-drawer:dropdown-opened', this.closeNotInFocus);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.navigation-item {
        > div.navigation-item-button {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.25s;
            border-radius: .5rem;
            padding: .5rem;

            > div.icon {
                width: pxToRem(24);
                height: pxToRem(24);
                display: flex;
                justify-content: center;
                align-items: center;
                > svg {
                    > g, > path {
                        stroke: $colorSourceSecondary;
                    }
                }
            }

            > div.label {
                flex: 1;
            }

            > span.badge {
                position: absolute;
                top: pxToRem(4);
                left: pxToRem(30);
                display: inline-block;
                width: pxToRem(6);
                height: pxToRem(6);
                background-color: $colorSourcePrimary;
                border-radius: 50%;
                &.red {
                    background-color: $colorIncorrect;
                }
            }

            &:hover {
                background-color: rgba($colorBackgroundLighter, 0.75);
            }

            &:focus {
                outline: none;
                background-color: $colorBackgroundLighter;
            }

            > div.label {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: fontSize(16);
                font-weight: $font-bold;
                transition: opacity 0.1s;
                color: $colorSourceSecondary;
            }

            .fadeLabel-enter-active {
                transition: opacity 100ms;
            }
            .fadeLabel-enter, .fadeLabel-leave, .fadeLabel-leave-to {
                opacity: 0;
            }

            > svg.dropdown-arrow {
                &.isOpen {
                    transform: rotate(180deg);
                }
            }
        }
        > div.sub-routes {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin: .5rem .5rem .5rem 1.25rem;
            padding: .5rem 0 0;
            > div.sub-route {
                padding: .5rem 1rem .5rem 1.5rem;
                color: $colorSourceSecondary;
                box-shadow: inset 1px 0 0 0 $colorBackgroundLighter;
                white-space: nowrap;
                transition: box-shadow 250ms;
                &:hover {
                    cursor: pointer;
                    box-shadow: inset 4px 0 0 0 $colorBackgroundPurple;
                }
                &.active {
                    color: $colorSourcePrimary;
                    box-shadow: inset 1px 0 0 0 $colorBackgroundPurple;
                }
                body[data-interface-language="ar"] & {
                    padding: .5rem 1.5rem .5rem 1rem;
                    box-shadow: inset -1px 0 0 0 $colorBackgroundLighter;
                    &:hover {
                        box-shadow: inset -4px 0 0 0 $colorBackgroundPurple;
                    }
                    &.active {
                        box-shadow: inset -1px 0 0 0 $colorBackgroundPurple;
                    }
                }
            }
        }
        &.disabled {
            > div.navigation-item-button {
                > div.label {
                    color: $colorSourceTertiary;
                }

                > div.icon {
                    > svg {
                        > g, > path {
                            fill: $colorSourceTertiary;
                        }
                    }
                }

                &:hover {
                    cursor: not-allowed;
                }
            }
        }

        &.active {
            > div.navigation-item-button {
                background-color: $colorBackgroundPurple;
                > div.label {
                    color: $colorSourcePrimary;
                }

                > div.icon {
                    > svg {
                        > g, > path {
                            stroke: $colorSourcePrimary;
                        }
                    }
                }
            }
        }
        &.dropdownOpen:not(.active) {
            > div.navigation-item-button {
                background-color: rgba($colorBackgroundLighter, 0.75);
            }
        }
    }
</style>
