import WebappStrings from '@lingvist/webapp-strings';

const getLocaleDefinition = uiLanguage => {

    const localeDefinition = WebappStrings.DurationLocales[uiLanguage];

    localeDefinition.durationPluralKey = (token, integerValue, decimalValue) => {
        if (integerValue === localeDefinition.useSingular.integerValue &&
                decimalValue === localeDefinition.useSingular.decimalValue) {
            return token;
        }
        return `${token}${token}`;
    };

    return localeDefinition;
};

export default getLocaleDefinition;
