var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button-dropdown-component",
      class: { disabled: _vm.disabled },
      on: { mouseleave: _vm.closeDropdownDelayed },
    },
    [
      _c("span", {
        staticClass: "primary",
        class: { disabled: _vm.primary.disabled },
        domProps: {
          innerHTML: _vm._s(
            this.$i18n(_vm.primary.i18n.id, {
              collection: _vm.primary.i18n.collection,
              args: _vm.primary.i18n.params,
            })
          ),
        },
        on: {
          click: function ($event) {
            return _vm.triggerAction(_vm.primary.action)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "dropdown", on: { click: _vm.openDropdown } },
        [_vm.dropdownOpen ? _c("arrow-up-icon") : _c("arrow-down-icon")],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.dropdownOpen
          ? _c(
              "span",
              {
                staticClass: "dropdown-content",
                on: {
                  mouseenter: _vm.openDropdown,
                  mouseleave: _vm.closeDropdown,
                },
              },
              _vm._l(_vm.dropdown, function (item) {
                return _c(
                  "span",
                  {
                    staticClass: "dropdown-item",
                    on: {
                      click: function ($event) {
                        return _vm.triggerAction(item.action)
                      },
                    },
                  },
                  [
                    item.icon
                      ? _c(_vm.icon(item.icon), { tag: "component" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$i18n(item.i18n.id, {
                            collection: item.i18n.collection,
                            args: item.i18n.params,
                          })
                        ),
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }