<template>
    <div class="input-search-dropdown" v-if="options" :class="{ withIcon: hasIcon }">
        <slot name="icon" />
        <transition name="bounce">
            <tick-icon v-if="hasSelected" />
        </transition>

        <!-- Dropdown Input -->
        <input
               :name="name"
               type="text"
               autocomplete="off"
               @focus="showOptions()"
               @blur="exit()"
               @keyup="keyMonitor"
               @keydown.up.prevent="highlightPrevious"
               @keydown.down.prevent="highlightNext"
               v-model="searchFilter"
               :disabled="disabled"
               :placeholder="placeholder" />

        <!-- Dropdown Menu -->
        <div class="dropdown-content"
             v-show="optionsShown" ref="results">
            <div
                class="dropdown-item"
                @mousedown="selectOption(option)"
                v-for="(option, index) in filteredOptions"
                :class="{ 'highlight': index === highlightedIndex }"
                :key="index">
                {{ option.name || option.id || '-' }}
            </div>
        </div>
    </div>
</template>

<script>
    import tickIcon from 'Images/tick-green-24x24.svg';
    import { isEmpty, find } from "lodash";

    export default {
        name: 'input-search-dropdown',
        template: 'Dropdown',
        components: {
            tickIcon,
        },
        props: {
            name: {
                type: String,
                required: false,
                default: 'dropdown',
                note: 'Input name'
            },
            options: {
                type: Array,
                required: true,
                default: [],
                note: 'Options of dropdown. An array of options with id and name',
            },
            placeholder: {
                type: String,
                required: false,
                default: 'Please select an option',
                note: 'Placeholder of dropdown'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
                note: 'Disable the dropdown'
            },
            maxItem: {
                type: Number,
                required: false,
                default: 6,
                note: 'Max items showing'
            },
            preSelectedId: {
                type: String
            }
        },
        data() {
            return {
                selected: {},
                optionsShown: false,
                highlightedIndex: 0,
                searchFilter: ''
            }
        },
        created() {
            this.$emit('selected', this.selected);
        },
        mounted() {
            if (this.preSelectedId) {
                this.setFromPreSelected()
            }
        },
        computed: {
            filteredOptions() {
                const filtered = [];
                const regOption = new RegExp(this.searchFilter, 'ig');
                for (const option of this.options) {
                    if (this.searchFilter.length < 1 || option.name.match(regOption)){
                        if (filtered.length < this.maxItem) filtered.push(option);
                    }
                }
                return filtered;
            },
            hasSelected() {
                return !isEmpty(this.selected);
            },
            hasIcon() {
                return !!(this.$slots.icon);
            },
        },
        methods: {
            selectOption(option) {
                this.selected = option;
                this.optionsShown = false;
                this.searchFilter = this.selected.name;
                this.$emit('selected', this.selected);
            },
            showOptions(){
                if (!this.disabled) {
                    this.optionsShown = true;
                }
            },
            exit() {
                if (!this.selected.id) {
                    this.selected = {};
                    this.searchFilter = '';
                }

                this.optionsShown = false;
            },
            // Selecting when pressing Enter
            keyMonitor: function(event) {
                if (event.key === "Enter") {
                    if (this.highlightedIndex >= 0) {
                        this.selectOption(this.filteredOptions[this.highlightedIndex]);
                    }
                }
            },
            highlightPrevious() {
                if (this.highlightedIndex > 0) {
                    this.highlightedIndex = this.highlightedIndex - 1;
                    this.scrollIntoView();
                }
            },
            highlightNext() {
                if (this.highlightedIndex < this.filteredOptions.length - 1) {
                    this.highlightedIndex = this.highlightedIndex + 1;
                    this.scrollIntoView();
                }
            },
            scrollIntoView() {
                this.$refs.results.children[this.highlightedIndex].scrollIntoView({ block: 'nearest' })
            },
            setFromPreSelected() {
                if (this.preSelectedId && this.options && this.options.length > 0) {
                    const result = find(this.options, { 'id': this.preSelectedId });
                    if (result) {
                        this.selected = result;
                        this.searchFilter = this.selected.name;
                        this.$emit('selected', this.selected);
                    }
                }
            }
        },
        watch: {
            searchFilter(newValue, oldValue) {
                if (this.filteredOptions.length === 0 || isEmpty(newValue)) {
                    this.selected = {};
                    this.$emit('selected', null);
                }
                this.$emit('filter', this.searchFilter);
            }
        }
    };
</script>


<style lang="scss" scoped>
    @import "~Styles/mixins";
    @import "~Styles/colors";
    @import "~Styles/font-weights.scss";

    div.input-search-dropdown {
        margin: 1rem 0;
        position: relative;
        display: block;

        > svg {
            position: absolute;
            left: 0.125rem;
            top: calc(50% - 0.75rem);
            width: 1.5rem;
            body[data-interface-language="ar"] & {
                left: unset;
                right: 0.125rem;
            }
            &.tick-green-24x24_svg__tick {
                left: unset;
                right: 0.125rem;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 0.125rem;
                }
            }
        }

        > input {
            width: 100%;
            font-size: 1rem;
            font-weight: $font-normal;
            height: 3rem;
            color: $colorSourcePrimary;
            border-bottom: 2px solid $colorSourceTertiary;

            &:focus {
                border-bottom: 2px solid $colorSourcePrimary;
            }

            &.error {
                border-bottom: 2px solid $colorIncorrect;
            }

            &.suggestion {
                border-bottom: 2px solid $colorAttention;
            }

            &::placeholder {
                font-size: 1rem;
            }
        }

        &.withIcon > input {
            padding-left: 2rem;
            body[data-interface-language="ar"] & {
                padding-right: 2rem;
                padding-left: unset;
            }
        }

        > div.dropdown-content {
            position: absolute;
            background-color: #fff;
            min-width: 100%;
            max-height: 248px;
            overflow: auto;
            z-index: 1;
            @include elevated;
            > div.dropdown-item {
                font-size: fontSize(14);
                padding: .5rem;
                text-decoration: none;
                display: block;
                cursor: pointer;
                &.highlight,
                &:hover {
                    background-color: $colorBackgroundGray;
                }
            }
        }

        &:hover {
            > div.dropdown-content {
                display: block;
            }
        }

        .bounce-enter-active {
            animation: bounce-in .5s;
        }
        .bounce-leave-active {
            animation: bounce-in .5s reverse;
        }
        @keyframes bounce-in {
            0% {
                transform: scale(0);
            }
            50% {
                transform: scale(1.5);
            }
            100% {
                transform: scale(1);
            }
        }
    }
</style>
