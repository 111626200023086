var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "course-select", on: { click: _vm.openCourseModal } },
    [
      _vm.activeCourse && _vm.activeCourse.target_icon_id
        ? _c("course-icon", {
            attrs: { value: _vm.activeCourse.target_icon_id, size: "large" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        staticClass: "text",
        domProps: {
          innerHTML: _vm._s(this.$i18n("hub_section_language_selection_btn")),
        },
      }),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "arrow",
          attrs: {
            width: "20",
            height: "20",
            viewBox: "0 0 20 20",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M8 14.998L12 9.998L8 5",
              stroke: "#2C3143",
              "stroke-width": "1.75",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }