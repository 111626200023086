var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "16",
          height: "16",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      _vm.$listeners
    ),
    [
      _c("defs", [
        _c("path", {
          attrs: { id: "status-disabled_svg__a", d: "M0 0h16v16H0z" },
        }),
      ]),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: "status-disabled_svg__b", fill: "#fff" } }, [
          _c("use", { attrs: { "xlink:href": "#status-disabled_svg__a" } }),
        ]),
        _c("g", { attrs: { mask: "url(#status-disabled_svg__b)" } }, [
          _c("path", {
            attrs: {
              d: "M0 6.41v3.18c0 2.23.232 3.037.668 3.852a4.543 4.543 0 001.89 1.89c.815.436 1.623.668 3.852.668h3.18c2.23 0 3.037-.232 3.852-.668a4.543 4.543 0 001.89-1.89c.436-.815.668-1.623.668-3.852V6.41c0-2.23-.232-3.037-.668-3.852a4.543 4.543 0 00-1.89-1.89C12.627.232 11.82 0 9.59 0H6.41C4.18 0 3.373.232 2.558.668a4.543 4.543 0 00-1.89 1.89C.232 3.373 0 4.18 0 6.41z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M6 14H2v-.879l2.46-3.036H2.091V9H6v.857l-2.47 3.048H6V14zm8-5H8V7.93l4.373-4.784H8.148V2H14v1.04L9.627 7.853H14V9z",
              fill: "#2C3143",
              "fill-rule": "nonzero",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }