<template>
    <span class="terms-and-conditions">
        <span
            v-if="display_message"
            v-html="this.$i18n(message_key, {link_templates: terms_links_templates})"
            @click="terms_click"
        ></span>
        <modal-popup
            v-if="document_open && document_open.content"
            :title="document_open.title"
            base_width="40rem"
            @close="document_open = null">
            <main class="document html" v-html="document_open.content" />
        </modal-popup>
    </span>
</template>

<script>

import ModalPopup from './modal-popup.vue';
import i18nUtils from '../../util/i18n.js';
import getConfigValue from '../../util/configuration.js';
import Backbone from 'backbone';

export default {
    name: 'Register.terms',
    components: { ModalPopup },
    props: {
        message_key: {
            type: String,
            required: false,
            default: 'register_credentials_terms',
        },
        display_message: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data () {
        return {
            terms_links_templates: {
                tos: [
                    {role: 'text', value: '<a data-link="tos">'},
                    {role: 'linktext'},
                    {role: 'text', value: '</a>'}
                ],
                pp: [
                    {role: 'text', value: '<a data-link="pp">'},
                    {role: 'linktext'},
                    {role: 'text', value: '</a>'}
                ],
            },
            document_open: null,
        };
    },
    methods: {
        async show_document (document) {
            /**
             * document=['tos', 'pp']
             */
            try {
                Backbone.trigger('showLoader');

                let url;
                let title_key;
                let language = i18nUtils.currentInterfaceLanguage;

                if (document === 'tos') {
                    let tosPaths = getConfigValue('tos-paths');
                    url = `${getConfigValue('assets-base-url')}/${tosPaths[language] || tosPaths.default}`;
                    title_key = 'register_modal_terms_of_service';
                } else if (document === 'pp') {
                    let ppPaths = getConfigValue('pp-paths');
                    url = `${getConfigValue('assets-base-url')}/${ppPaths[language] || ppPaths.default}`;
                    title_key = 'register_modal_privacy_policy';
                }

                const response = await fetch(url);
                const document_content = await response.text();

                this.document_open = {
                    type: document,
                    title: this.$i18n(title_key),
                    content: document_content
                };
            } catch (error) {
                console.error('Failed showing document with error:', error)
            } finally {
                Backbone.trigger('hideLoader')
            }
        },
        async show_uri(uri) {
            switch (uri) {
                case 'lingvist:show-file?name=legal':
                    let _content = await i18nUtils.getFileContent('legal');
                    _content = _content.replace('Third party software notices and information.\n\n', '');
                    _content = _content.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    this.document_open = {
                        type: 'third-party',
                        title: 'Third party software notices and information',
                        content: _content
                    };
            }
        },
        terms_click (event) {
            const link = event.target.attributes['data-link'];
            const uri = event.target.attributes['data-uri'];

            if (link) {
                this.show_document(link.value);
            } else if (uri && uri.value) {
                this.show_uri(uri.value);
            }
        }
    }
};
</script>

<style lang="scss">
@import "~Styles/colors";
@import "~Styles/font-weights.scss";

// Need some non-scoped css to to handle special case of v-html i18n link
span.terms-and-conditions {
    text-align: left;
    font-size: 1rem;
    height: auto;
    margin-top: 1rem;

    a {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
