<template>
    <div class="utility-belt" :class="{ disabled: this.disabled, locked: this.locked }">
        <set-progress />
        <diacritics v-if="question.diacritics && diacriticsOpen" :diacritics="question.diacritics" :target_language="question.target_language" v-on:close="closeDiacritics" />
        <div class="actions">
            <tick-correct class="tick-correct" v-if="answerState" :answer-state="answerState" />
            <icon-button v-if="previousCardButtonVisible" :disabled="this.audioIsPlaying" icon-name="back" @click.native="previousCard" />
            <icon-button v-if="on_previous" icon-name="forward" @click.native="currentCard" />
            <icon-button v-if="cardHasVisuals && cardVisualData && cardVisualData.enabled" icon-name="visuals" :active="visuals" @click.native="toggleVisuals" />
            <icon-button v-if="audioButtonVisible" icon-name="speaker" @click.native="playAudio" :active="this.audioIsPlaying" />
            <icon-button v-if="grammarButtonVisible" icon-name="tutor" :active="tutorOpen" :notify="this.notifyGrammarButton" @click.native="toggleTutor" />
            <icon-button v-if="!on_previous && question.diacritics" :icon-name="diacriticsIconName" id="diacriticsButton" :active="diacriticsOpen" @click.native="toggleDiacritics" />
            <icon-button v-if="tipButtonVisible" icon-name="tip" @click.native="openFormSpellings" :notify="this.notifyTipButton" />
            <voice-input  v-if="!on_previous" :question="this.question" :course="this.course" id="utility-belt-voice-button" />
            <v-button v-if="nextButtonVisible" :disabled="this.audioIsPlaying" :i18n="{ id: 'learn_btn_next' }" size="medium" :filled="true" color="primary" @click.native="nextCard" />
            <v-button v-else-if="!this.on_previous" :disabled="this.audioIsPlaying" :text="this.submitButtonText" size="medium" :filled="true" :color="this.submitButtonColor" @click.native="submit" id="utility-belt-submit-button" />
        </div>
    </div>
</template>

<script>
import { EventBus } from 'Util/vue-event-bus';
import SetProgress from "./set-progress.vue";
import TickCorrect from "./tick-correct.vue";
import VButton from 'ViewComponent/v-button.vue';
import UserManager from "Modules/usermanager";
import IconButton from "./icon-button.vue";
import i18nUtils from "Util/i18n";
import Diacritics from "./diacritics.vue";
import AudioPlayer from "Util/audioplayer";
import VoiceInput from "./voice-input.vue";

export default {
        name: 'utility-belt',
        components: { VoiceInput, Diacritics, IconButton, SetProgress, VButton, TickCorrect },
        props: {
            course: {
                type: Object,
                required: true
            },
            on_previous: {
                type: Boolean,
                default: false
            },
            has_previous: {
                type: Boolean,
                default: false
            },
            question: {
                type: Object
            },
            previous_question: {
                type: Object
            },
            answer: {
                type: Object
            },
            userAnswer: {
                type: String,
            },
            audioIsPlaying: {
                type: Boolean
            },
            locked: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            tutorOpen: {
                type: Boolean,
                default: false
            },
            grammarTable: {
                type: Object
            },
            visuals: {
                type: Boolean,
                default: false
            },
            cardHasVisuals: {
                type: Boolean,
                default: false
            },
            cardVisualData: {
                type: Object
            },
            auto_advance: {
                type: Boolean,
                default: true
            },
            strict_diacritics: {
                type: Boolean,
                default: false
            },
            formSpellingsVisible: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                diacriticsOpen: false,
                notifyTipButton: false,
                browserType: 'unknown'
            }
        },
        computed: {
            answerState() {
                if (this.answer && this.answer.confirmed && this.answer.all_entries.length === 1) {
                    return 'final-remembered';
                } else if (this.answer && this.answer.opened && !this.answer.confirmed) {
                    return 'wrong';
                } else if (this.answer && this.answer.opened && this.answer.confirmed) {
                    return 'final-wrong';
                } else {
                    return null;
                }
            },
            audioButtonVisible() {
                return !!(this.answer && this.answer.confirmed && !this.auto_advance || this.on_previous);
            },
            nextButtonVisible() {
                return !this.auto_advance && !this.on_previous && (this.answerState === 'final-remembered' || this.answerState === 'final-wrong');
            },
            grammarButtonVisible() {
                return !!(this.grammarTable && (this.on_previous || (this.answer && this.answer.confirmed) || (this.answer && this.answer.all_entries && this.answer.all_entries.length > 0)));
            },
            notifyGrammarButton() {
                let _question = (this.on_previous) ? this.previous_question : this.question;
                const isRepeatWord = _question && _question.repeatProgress && _question.repeatProgress !== 'new';
                return !!(isRepeatWord && this.grammarTable && (this.on_previous || (this.answer && this.answer.confirmed) || (this.answer && this.answer.all_entries && this.answer.all_entries.length > 0)));
            },
            previousCardButtonVisible() {
                return !this.on_previous && this.has_previous && (this.previous_question && !this.previous_question.muted);
            },
            submitButtonText() {
                if (this.userAnswer && this.userAnswer.length > 0) {
                    return i18nUtils.prop('btn_enter');
                } else {
                    return i18nUtils.prop('btn_reveal_learn_word');
                }
            },
            submitButtonColor() {
                if (this.userAnswer && this.userAnswer.length > 0) {
                    return 'primary';
                } else {
                    return 'light';
                }
            },
            diacriticsIconName() {
                const availableDiacriticIcons = ['de', 'es', 'et', 'fr', 'pt', 'it', 'pl', 'nl', 'sv', 'da', 'no', 'tr', 'fi'];
                if (this.question.target_language && availableDiacriticIcons.includes(this.question.target_language)) {
                    return `diacritics-${ this.question.target_language }`;
                } else {
                    return 'diacritics-es';
                }
            },
            tipButtonVisible() {
                let hasFormSpellings = !!(this.question && this.question.homograph && this.question.homograph.hasOwnProperty('form_spellings') && this.question.homograph.form_spellings.length > 0);
                return !this.formSpellingsVisible && hasFormSpellings && (this.answerState === 'final-wrong' || this.answerState === 'wrong');
            },
        },
        methods: {
            nextCard() {
                EventBus.$emit('guess:next');
            },
            previousCard() {
                EventBus.$emit('guess:previous');
            },
            currentCard() {
                EventBus.$emit('guess:current');
            },
            submit() {
                EventBus.$emit('guess:submit');
            },
            toggleDiacritics() {
                this.diacriticsOpen = !this.diacriticsOpen;
            },
            openDiacritics(){
                this.diacriticsOpen = true;
            },
            closeDiacritics(){
                this.diacriticsOpen = false;
            },
            toggleTutor() {
                EventBus.$emit('guess:toggle-tutor');
            },
            toggleVisuals() {
                EventBus.$emit('guess:toggle-visuals');
                if (this.visuals) {
                    UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-visuals', 'hide', 'utility-belt');
                } else {
                    UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-visuals', 'show', 'utility-belt');
                }
            },
            notifyFormSpelling() {
                this.notifyTipButton = true;
                setTimeout(() => {
                    this.notifyTipButton = false;
                }, 5000)
            },
            playAudio() {
                AudioPlayer.playContext(true);
            },
            openFormSpellings() {
                EventBus.$emit('guess:form-spellings-open');
            },
            listenEventBus() {
                EventBus.$on('guess:open-diacritics', this.openDiacritics);
                EventBus.$on('guess:close-diacritics', this.closeDiacritics);
                EventBus.$on('guess:form-spelling-button-notify', this.notifyFormSpelling);
            },
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            EventBus.$off('guess:open-diacritics', this.openDiacritics);
            EventBus.$off('guess:close-diacritics', this.closeDiacritics);
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.utility-belt {
        border-radius: pxToRem(12);
        background-color: #fff;
        padding: .5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;
        @include respond-to('small') {
            margin: 0;
            border-radius: 0;
        }
        &.locked,
        &.disabled {
            pointer-events: none;
            filter: grayscale(100%);
            opacity: 0.8;
        }
        > div.set-progress {
            margin: .7rem 0;
            max-width: 15rem;
            @include respond-to('medium') {
                margin: 0;
            }
        }
        > div.actions {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: .5rem;
            body[data-interface-language="ar"] & {
                margin-left: unset;
                margin-right: auto;
            }

            > button[data-size="medium"] {
                min-width: pxToRem(80);
                border-radius: pxToRem(4);
                padding: pxToRem(5) pxToRem(7);
            }
        }
    }
</style>
