var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-toggle" }, [
    _c(
      "div",
      { staticClass: "setting" },
      [
        _c("div", { staticClass: "text" }, [
          _c("div", {
            staticClass: "label",
            domProps: { innerHTML: _vm._s(this.$i18n(this.title)) },
          }),
          _vm._v(" "),
          this.footnote
            ? _c("div", {
                staticClass: "footnote",
                domProps: { innerHTML: _vm._s(this.$i18n(this.footnote)) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("toggle-button", {
          key: `${_vm.settingParameter}:toggle`,
          attrs: {
            sync: true,
            color: { checked: "#2C3143" },
            width: 70,
            height: 24,
          },
          model: {
            value: _vm.settingValue,
            callback: function ($$v) {
              _vm.settingValue = $$v
            },
            expression: "settingValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }