var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M6.707 2.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414-1.414l-2-2zm12 4a1 1 0 00-1.414 0l-2 2a1 1 0 001.414 1.414l2-2a1 1 0 000-1.414zM10 7H4v12h6a1 1 0 100-2H6v-3h3.5a1 1 0 100-2H6V9h4a1 1 0 100-2z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M16.5 11a3.5 3.5 0 00-3.5 3.5v1a3.5 3.5 0 107 0v-1a3.5 3.5 0 00-3.5-3.5zM15 14.5a1.5 1.5 0 013 0v1a1.5 1.5 0 01-3 0v-1z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }