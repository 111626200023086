var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-page" },
    [
      _c(
        "div",
        { staticClass: "calendar-flex" },
        [
          _c("insights-calendars", {
            attrs: { "user-info": this.userInfo, course: this.course },
            on: { "day-selected": _vm.calendarDaySelected },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "additional-information" },
            [
              _c("collapsible-box", {
                attrs: {
                  i18n_title: { id: "calendar_total_days_learnt_title" },
                  "title-meta": _vm.totalDaysLearned,
                  i18n_text: { id: "calendar_total_days_learnt_description" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("total-days-icon")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("collapsible-box", {
                attrs: {
                  i18n_title: { id: "calendar_days_streak_title" },
                  "title-meta": _vm.consecutiveDaysLearned,
                  i18n_text: { id: "calendar_days_streak_description" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("correct-rate-icon")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("collapsible-box", {
                attrs: {
                  "no-background": true,
                  i18n_title: { id: "daily_performance_stats_metric_cards" },
                  "title-meta": _vm.totalCardsAnswered,
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("cards-answered-icon")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("div", { staticClass: "separator" }),
              _vm._v(" "),
              _c("collapsible-box", {
                attrs: {
                  "no-background": true,
                  i18n_title: {
                    id: "elastic_goals_calendar_metric_total_time",
                  },
                  "title-meta": _vm.totalTimeSpent,
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("time-spent-icon")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          this.selectedCalendarDay
            ? _c("day-insights", {
                attrs: { day: this.selectedCalendarDay.moment },
                on: { close: _vm.closeDayInsights },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }