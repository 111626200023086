
import _ from 'lodash';
import $ from 'jquery';

import getConfigValue from '../util/configuration.js';
import ExportUtils from '../util/export.js';
import AsyncDestroyable from './async.destroyable.js';


export const ENDPOINT = {
    OAUTH: '1.0/auth/oauth',
    USER_SUBSCRIPTIONS: '1.0/user/subscriptions',
    SERVICES: '1.2/user/services',
    SUBSCRIPTION_CANCEL: '1.0/user/subscription/cancel',
    PRODUCTS: '1.2/catalog/products',
    METHODS: '1.2/methods',
    PCHOME_AUTH_CODE: '1.0/pchome/auth-code',
    BRAINTREE_CLIENT_TOKEN: '1.0/braintree/client_token',
    BRAINTREE_CHECKOUT: '1.1/braintree/checkout',
    BRAINTREE_PAYMENT_METHOD_DEFAULT: '1.0/braintree/payment-method/default',
    VOUCHER_CHECK: '1.0/voucher/check',
    VOUCHER_CHECKOUT: '1.0/voucher/checkout',
};

export const HTTP_METHOD = {
    GET: 'GET',
    POST: 'POST'
};

class UnableToGetPaymentAPITokenException extends Error {
    constructor (message) {
        super(message);
        this.name = 'UnableToGetPaymentAPITokenException';
    }
}


export class PayAPI extends AsyncDestroyable {

    constructor (user) {
        super(['getUserSubscriptions']);
        this._user = user;

        this._token = null;
        this._pay_api_url = getConfigValue('payment-api-url');
    }

    destroy () {
        delete this._user;

        return Promise.resolve();
    }

    _doRequest (method, endpoint, headers, parameters=null, search_parameters=null) {
        let search = search_parameters !== null ? ('?' + $.param(search_parameters)): '';
        return fetch(
            `${this._pay_api_url}/${endpoint}${search}`,
            {
                method,
                headers,
                body: (parameters !== null ? JSON.stringify(parameters) : undefined)
            }
        );
    }

    /**
     * @typedef {object} APIError
     * @property {int} status - HTTP response status
     * @property (string} error - Error (might not be machine readable depending on the code)
     *
     */

    /**
     *
     * @param response
     * @return {Promise<APIError>}
     * @private
     */
    _processErrorResponse (response) {
        return Promise.resolve()
            .then(() => response.json())
            .then(response_json => Promise.reject({
                status: response.status,
                error: response_json.error
            }));
    }

    /**
     *
     * @param request_promise
     * @return {Promise<object|APIError>}
     * @private
     */
    _getJson (request_promise) {
        return Promise.resolve()
            .then(() => request_promise)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return this._processErrorResponse(response);
                }
            });
    }

    _getPayApiToken () {
        return Promise.resolve()
            .then(() => this._user.getAuthCode())
            .then(auth_code => this._doRequest(HTTP_METHOD.POST, ENDPOINT.OAUTH,
                {'content-type': 'application/json'},
                {
                    code: auth_code,
                    redirect_uri: getConfigValue('pay-link')
                }
            ))
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.resolve()
                        .then(() => response.json())
                        .then(({error}) => {
                            throw new UnableToGetPaymentAPITokenException(
                                `Unable to get Payment API token because code=${response.status} error=${error}`);
                        });
                }
            })
            .then(response_json => Promise.resolve([response_json.token_type, response_json.access_token]));
    }

    _doRequestWithAuth (method, endpoint, parameters=null, search_parameters=null, headers = {}, retry=false) {
        let promise = Promise.resolve();

        let tokenUpdated = false;
        if (this._token === null) {
            tokenUpdated = true;
            promise = promise
                .then(() => this._getPayApiToken())
                .then(([token_type, access_token]) => {
                    this._token = {
                        type: token_type,
                        value: access_token
                    };
                });
        }

        promise = promise
            .then(() => this._doRequest(method, endpoint, _.assign({}, {
                'Content-Type': parameters !== null ? 'application/json' : undefined,
                'Authorization': `${this._token.type} ${this._token.value}`
            }, headers), parameters, search_parameters))
            .then(response => {
                // Retry request with new token if it fails at first
                if (response.status === 401 && !retry && !tokenUpdated) {
                    this._token = null;
                    return this._doRequestWithAuth(method, endpoint, parameters, search_parameters, headers, true);
                } else {
                    return response;
                }
            });

        return promise;
    }

    /**
     * @typedef {object} UserSubscription
     *
     * @property uuid {string}
     * @property created_ts {string}
     * @property expiration_ts {string}
     * @property group_name {string}
     * @property next_billing_ts {string}
     * @property period {string}
     * @property plan_name {string}
     * @property price {{amount: string, currency: string}}
     * @property created_ts {string}
     * @property product_name {string}
     * @property status {string}
     */

    /**
     *
     * @return {Promise<{subscriptions: [UserSubscription]}>}
     */
    getUserSubscriptions () {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.GET, ENDPOINT.USER_SUBSCRIPTIONS));
    }

    /**
     * @typedef {object} UserServiceSubscription
     * @property is_recurring {boolean} -
     * @property uuid {string|null} -
     * @property group_name {string|null} -
     * @property period {string|null} -
     * @property status {string|null} -
     * @property next_billing_ts {string|null} -
     * @property expiration_ts {string|null} -
     * @property price {{amount: string, currency: string}} - Purchase price. Including the next billing price for the service
     */

    /**
     * @typedef {object} UserService
     * @property duration {string} - ISO 8601 duration: Original duration of the service derived from the product. May be different for the actual until-since service dates when overridden by the discount, for example.
     * @property service {string} - Name of the service
     * @property active_since_ts {string} - ISO 8601 timestamp: Actual date since when the service starts to be active
     * @property active_until_ts {string} - ISO 8601 timestamp: Actual date when the service expires
     * @property payment_provider {string} - Payment provider the services was provisioned with: (`braintree`|`pchome`|`apple-in-app`|`free-trial`|`voucher`|`google-in-app`)
     * @property product_name
     * @property title
     * @property subscription {UserServiceSubscription}
     * @property unlimited_bundle {object} - Specific service attribute, if any (always the same for the `unlimited`)
     */

    /**
     *
     * @return {Promise<{services: [UserService]}>}
     */
    getUserServices (search_parameters) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.GET, ENDPOINT.SERVICES, null, search_parameters));
    }

    /**
     *
     * @param subscription_uuid {uuid} - UUID of the subscription to cancel
     * @return {Promise<{error:string|undefined,status:string|undefined}>}
     */
    cancelUserSubscription (subscription_uuid) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.POST, ENDPOINT.SUBSCRIPTION_CANCEL, {
            subscription_uuid: subscription_uuid
        }));
    }

    getProductCatalog (search_parameters) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.GET, ENDPOINT.PRODUCTS, null, search_parameters));
    }

    getUserMethods (search_parameters) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.GET, ENDPOINT.METHODS, null, search_parameters));
    }

    getPChomeAuthCode (search_parameters) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.GET, ENDPOINT.PCHOME_AUTH_CODE, null, search_parameters));
    }

    getBraintreeClientToken (recaptchaToken) {
        if (!recaptchaToken) {
            return Promise.reject(new Error('reCAPTCHA token is required'));
        }

        const params = {
            recaptcha_token: recaptchaToken
        };

        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.POST, ENDPOINT.BRAINTREE_CLIENT_TOKEN, params));
    }

    braintreeCheckout (params) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.POST, ENDPOINT.BRAINTREE_CHECKOUT, params));
    }

    braintreePaymentMethodDefault (params) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.POST, ENDPOINT.BRAINTREE_PAYMENT_METHOD_DEFAULT, params));
    }

    voucherCheck (params) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.POST, ENDPOINT.VOUCHER_CHECK, params));
    }

    voucherCheckout (params) {
        return this._getJson(this._doRequestWithAuth(HTTP_METHOD.POST, ENDPOINT.VOUCHER_CHECKOUT, params));
    }
}

ExportUtils.export('app.modules.PayAPI', PayAPI);
