

import { MultipleChoiceExercise } from './multiple.choice.exercise.js';

export class ReadingExercise extends MultipleChoiceExercise {
    update (exercise_data) {
        super.update(exercise_data);
    }

    getData () {
        return super.getData();
    }
}
