'use strict';

import _ from 'lodash';

import getConfigValue from '../util/configuration.js';
import { assetLoader } from '../modules/global.asset.loader.js';

import AsyncDestroyable from './async.destroyable.js';

const BASE_URL = getConfigValue('grammar-hints-url');
const ASSETS_VERSION = 'v1';

export class GrammarTips extends AsyncDestroyable {
    constructor (course, assetLoader) {
        super(['_getCurrentVersion', '_getAll', 'getSingle', 'getMenuTips']);

        this._course = course;
        this._assetLoader = assetLoader;

        this._currentVersion = null;
        this._tips = null;
        this._tipPromises = {};

        this._getCurrentVersion(false); // Pre-load the current version
    }

    _getCurrentVersion (now) {
        var self = this;
        if (this._currentVersion !== null) {
            return Promise.resolve(this._currentVersion);
        } else {
            return Promise.resolve().then(function () {
                return assetLoader.loadAsset(`${BASE_URL}${ASSETS_VERSION}/current.json`, now ? 0 : 100);
            }).then(function (response) {
                self._currentVersion = JSON.parse(response).version;
                return Promise.resolve(self._currentVersion);
            });
        }
    }

    _getAll (now) {
        var self = this;

        if (this._tips !== null) {
            return Promise.resolve(this._tips);
        } else {
            return Promise.resolve().then(function () {
                return self._assetLoader.loadAsset(
                    self._course.getAssetUrl('grammar_hints'), now ? 0 : 100
                );
            }).then(function (response) {
                self._tips = JSON.parse(response).items.reduce((result, item, i) => {
                    item.orderNr = i;
                    result[item.key] = item;
                    return result;
                }, {});
                return Promise.resolve(self._tips);
            });
        }
    }

    getSingle (key) {
        var self = this;

        if (this._tips !== null && this._tips[key] !== undefined) {
            return Promise.resolve(this._tips[key]);
        } else if (this._tipPromises[key]) {
            return this._tipPromises[key];
        } else {
            self._tipPromises[key] = Promise.resolve().then(function () {
                return self._assetLoader.loadAsset(`${BASE_URL}${key}.json`, 0);
            }).then(function (response) {
                return JSON.parse(response);
            });
            return self._tipPromises[key];
        }
    }

    getSingleV0 (key) {
        var self = this;

        if (this._tipPromises[key]) {
            return this._tipPromises[key];
        } else {
            self._tipPromises[key] = Promise.resolve().then(function () {
                return self._getCurrentVersion(true);
            }).then(function (version) {
                return self._assetLoader.loadAsset(`${BASE_URL}${version}/${key}.json`, 0);
            }).then(function (response) {
                return JSON.parse(response);
            });
            return self._tipPromises[key];
        }
    }

    getMenuTips () {
        var self = this;

        return Promise.resolve().then(function () {
            return self._getAll(true);
        }).then(function (tips) {
            return _(tips).values().filter({'menu_hint': true}).sortBy('orderNr').value();
        });
    }

    destroy () {
        var self = this;

        console.log(`grammar tips destroy`, self._course.UUID);

        return Promise.resolve().then(function () {
            delete self._course;
            delete self._assetLoader;
        });
    }
}
