var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "russian-cyrillic-story" }, [
    _vm.activeIndex === 0
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_ru_cyrillic_1_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_ru_cyrillic_1_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M62 107C57.5817 107 54 110.582 54 115V174C54 178.418 57.5817 182 62 182H170",
                  stroke: "#AC6ED1",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M88 95H75C70.5817 95 67 98.5817 67 103V162C67 166.418 70.5817 170 75 170H207.875M96 95H220C224.418 95 228 98.5817 228 103V151.25",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M232 152C232 154.209 230.209 156 228 156C225.791 156 224 154.209 224 152C224 149.791 225.791 148 228 148C230.209 148 232 149.791 232 152Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M100 114C100 116.209 98.2092 118 96 118H85C82.7909 118 81 116.209 81 114V112C81 109.791 82.7909 108 85 108H96C98.2092 108 100 109.791 100 112V114Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M100 133C100 135.209 98.2092 137 96 137H85C82.7909 137 81 135.209 81 133V132C81 129.791 82.7909 128 85 128H96C98.2092 128 100 129.791 100 132V133Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M100 152C100 154.209 98.2092 156 96 156H85C82.7909 156 81 154.209 81 152V151C81 148.791 82.7909 147 85 147H96C98.2092 147 100 148.791 100 151V152Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M129 114C129 116.209 127.209 118 125 118H113C110.791 118 109 116.209 109 114V112C109 109.791 110.791 108 113 108H125C127.209 108 129 109.791 129 112V114Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M129 133C129 135.209 127.209 137 125 137H113C110.791 137 109 135.209 109 133V132C109 129.791 110.791 128 113 128H125C127.209 128 129 129.791 129 132V133Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M166 147H113C110.791 147 109 148.791 109 151V152C109 154.209 110.791 156 113 156H181C183.209 156 185 154.209 185 152V151C185 148.791 183.209 147 181 147H175.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M156 114C156 116.209 154.209 118 152 118H142C139.791 118 138 116.209 138 114V112C138 109.791 139.791 108 142 108H152C154.209 108 156 109.791 156 112V114Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M156 133C156 135.209 154.209 137 152 137H142C139.791 137 138 135.209 138 133V132C138 129.791 139.791 128 142 128H152C154.209 128 156 129.791 156 132V133Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M185 114C185 116.209 183.209 118 181 118H170C167.791 118 166 116.209 166 114V112C166 109.791 167.791 108 170 108H181C183.209 108 185 109.791 185 112V114Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M185 133C185 135.209 183.209 137 181 137H170C167.791 137 166 135.209 166 133V132C166 129.791 167.791 128 170 128H181C183.209 128 185 129.791 185 132V133Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M214 114C214 116.209 212.209 118 210 118H199C196.791 118 195 116.209 195 114V112C195 109.791 196.791 108 199 108H210C212.209 108 214 109.791 214 112V114Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M214 133C214 135.209 212.209 137 210 137H199C196.791 137 195 135.209 195 133V132C195 129.791 196.791 128 199 128H210C212.209 128 214 129.791 214 132V133Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M214 152C214 154.209 212.209 156 210 156H199C196.791 156 195 154.209 195 152V151C195 148.791 196.791 147 199 147H210C212.209 147 214 148.791 214 151V152Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M79 51C72.3726 51 67 56.3829 67 63.0229V87.0688C67 93.7089 72.3726 99.0917 79 99.0917H82.1111L89.2517 111.969C90.0137 113.344 91.9863 113.344 92.7483 111.969L99.8889 99.0917H103C109.627 99.0917 115 93.7089 115 87.0688V63.0229C115 56.3829 109.627 51 103 51H79Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M89.75 65.125C89.75 65.8154 89.1904 66.375 88.5 66.375C87.8097 66.375 87.25 65.8154 87.25 65.125C87.25 64.4346 87.8097 63.875 88.5 63.875C89.1904 63.875 89.75 64.4346 89.75 65.125ZM86.25 67.875H87.25H96V69.875H88.25V75.375H96V77.375H88.25V82.875H96V84.875H87.25H86.25V83.875V68.875V67.875ZM93.5 66.375C94.1904 66.375 94.75 65.8154 94.75 65.125C94.75 64.4346 94.1904 63.875 93.5 63.875C92.8097 63.875 92.25 64.4346 92.25 65.125C92.25 65.8154 92.8097 66.375 93.5 66.375Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M165 38C158.373 38 153 43.3829 153 50.0229V74.0688C153 80.7089 158.373 86.0917 165 86.0917H168.111L175.252 98.9693C176.014 100.344 177.986 100.344 178.748 98.9693L185.889 86.0917H197C203.627 86.0917 209 80.7089 209 74.0688V50.0229C209 43.3829 203.627 38 197 38H165Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M173 70.7143H172V71.7143H173V70.7143ZM174 55.2857V54.2857H172V55.2857H174ZM174 70.7143V63H172V70.7143H174ZM174 63V55.2857H172V63H174ZM173 64H181.091V62H173V64ZM181.091 69.7143H173V71.7143H181.091V69.7143ZM183.769 66.8571C183.769 68.4805 182.526 69.7143 181.091 69.7143V71.7143C183.719 71.7143 185.769 69.4943 185.769 66.8571H183.769ZM181.091 64C182.526 64 183.769 65.2338 183.769 66.8571H185.769C185.769 64.22 183.719 62 181.091 62V64ZM191 72V54H189V72H191Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M136 224.523C142.627 224.523 148 219.14 148 212.5L148 191.931C148 185.291 142.627 179.908 136 179.908H132.889L125.748 167.031C124.986 165.656 123.014 165.656 122.252 167.031L115.111 179.908L104 179.908C97.3726 179.908 92 185.291 92 191.931L92 212.5C92 219.14 97.3726 224.523 104 224.523L136 224.523Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M124 212V204.154M124 204.154V195H119.625C117.209 195 115.25 197.049 115.25 199.577C115.25 202.105 117.209 204.154 119.625 204.154H124ZM119 204.154L114 212",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M26 164C26 170.627 31.3829 176 38.0229 176H62.0688C68.7089 176 74.0917 170.627 74.0917 164V160.889L86.9693 153.748C88.3436 152.986 88.3436 151.014 86.9693 150.252L74.0917 143.111V140C74.0917 133.373 68.7089 128 62.0688 128L38.0229 128C31.3828 128 26 133.373 26 140L26 164Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M43 162V163H45V162H43ZM44 158.2V157.2H43V158.2H44ZM57 158.2H58V157.2H57V158.2ZM56 162V163H58V162H56ZM54.6364 143H55.6364V142H54.6364V143ZM49.9091 143V142H48.9091V143H49.9091ZM45 162V158.2H43V162H45ZM44 159.2H57V157.2H44V159.2ZM56 158.2V162H58V158.2H56ZM55.6364 158.2V143H53.6364V158.2H55.6364ZM54.6364 142H49.9091V144H54.6364V142ZM48.9091 143C48.9091 145.439 48.449 151.787 44.3625 157.627L46.0011 158.773C50.4237 152.453 50.9091 145.628 50.9091 143H48.9091Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M213.889 156.908L206.748 144.031C205.986 142.656 204.014 142.656 203.252 144.031L196.111 156.908H193C186.373 156.908 181 162.291 181 168.931V192.977C181 199.617 186.373 205 193 205H217C223.627 205 229 199.617 229 192.977V168.931C229 162.291 223.627 156.908 217 156.908H213.889Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M206 174L206 188M199 174L204.6 181L199 188M213 174L207.4 181L213 188",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "square",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 1
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_ru_cyrillic_2_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_ru_cyrillic_2_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  x: "59",
                  y: "82",
                  width: "154",
                  height: "96",
                  rx: "16",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M91.663 181.496C94.0366 187.36 99.7852 191.496 106.5 191.496L213 191.496C221.837 191.496 229 184.333 229 175.496L229 112.5C229 105.785 224.864 100.037 219 97.663",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "43",
                  y: "65",
                  width: "154",
                  height: "96",
                  rx: "16",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M174 94C174 96.7614 172.209 99 170 99C167.791 99 166 96.7614 166 94C166 91.2386 167.791 89 170 89C172.209 89 174 91.2386 174 94Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M77 99V92.659C77 90.8977 75.8 87.7979 71 89.4887M77 94H72.5C71.1193 94 70 95.1193 70 96.5C70 97.8807 71.1193 99 72.5 99H74L77 96V94Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "square",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M93 93.6671V93C93 90.7909 94.7909 89 97 89C99.2091 89 101 90.7909 101 93V93.6671H93ZM93 93.6671C93 98.3343 96.2 100.084 100.467 98.3343",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "square",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M121 86C121 86.5523 120.552 87 120 87C119.448 87 119 86.5523 119 86C119 85.4477 119.448 85 120 85C120.552 85 121 85.4477 121 86ZM117 93C117 90.2386 119.239 88 122 88C124.761 88 127 90.2386 127 93V93.6671V94.6671H126H119.059C119.249 96.174 119.874 97.084 120.649 97.5503C121.611 98.129 123.117 98.2172 125.087 97.4091L126.012 97.0296L126.771 98.88L125.846 99.2595C123.55 100.201 121.323 100.29 119.618 99.2641C117.885 98.2214 117 96.2178 117 93.6671V93ZM124.982 92.6671C124.816 91.1669 123.544 90 122 90C120.456 90 119.184 91.1669 119.018 92.6671H124.982ZM124 87C124.552 87 125 86.5523 125 86C125 85.4477 124.552 85 124 85C123.448 85 123 85.4477 123 86C123 86.5523 123.448 87 124 87Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M143 98H142V99H143V98ZM149.3 89.6923V88.6923H148.768L148.471 89.1334L149.3 89.6923ZM149 98V99H151V98H149ZM150 89.6923H151V88.6923H150V89.6923ZM143.7 98V99H144.232L144.529 98.5589L143.7 98ZM142 89V98H144V89H142ZM149.3 90.6923H150V88.6923H149.3V90.6923ZM149 89.6923V98H151V89.6923H149ZM143 99H143.7V97H143V99ZM144.529 98.5589L150.129 90.2513L148.471 89.1334L142.871 97.4411L144.529 98.5589Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M78 121C75.9015 125.712 74.693 128.426 72.6561 133C72 134.473 70.8533 135.753 69 135M70 121L73.9921 130",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M91 133H90V134H91V133ZM92 123V122H90V123H92ZM92 133V128H90V133H92ZM92 128V123H90V128H92ZM91 129H96.5V127H91V129ZM96.5 132H91V134H96.5V132ZM98 130.5C98 131.328 97.3284 132 96.5 132V134C98.433 134 100 132.433 100 130.5H98ZM96.5 129C97.3284 129 98 129.672 98 130.5H100C100 128.567 98.433 127 96.5 127V129ZM104 134V122H102V134H104Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M118 132.33C118.76 132.769 119.622 133 120.5 133C121.378 133 122.24 132.769 123 132.33C123.76 131.891 124.391 131.26 124.83 130.5C125.269 129.74 125.5 128.878 125.5 128M125.5 128C125.5 127.122 125.269 126.26 124.83 125.5C124.391 124.74 123.76 124.109 123 123.67C122.24 123.231 121.378 123 120.5 123C119.622 123 118.76 123.231 118 123.67M125.5 128H118.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M144.438 128C144.438 130.367 146.13 132.286 148.219 132.286C150.307 132.286 152 130.367 152 128C152 125.633 150.307 123.714 148.219 123.714C146.13 123.714 144.438 125.633 144.438 128ZM144.438 128H141M141 128V123M141 128V133",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M173 133V128.846M173 128.846V124L170 124C168.792 124 167.812 125.085 167.812 126.423C167.812 127.761 168.792 128.846 170 128.846H173ZM169.5 128.783L167 133",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 2
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_ru_cyrillic_3_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_ru_cyrillic_3_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M63 109C58.5817 109 55 112.582 55 117V176C55 180.418 58.5817 184 63 184H171",
                  stroke: "#AC6ED1",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M89 97H76C71.5817 97 68 100.582 68 105V164C68 168.418 71.5817 172 76 172H208.875M97 97H221C225.418 97 229 100.582 229 105V153.25",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M233 154C233 156.209 231.209 158 229 158C226.791 158 225 156.209 225 154C225 151.791 226.791 150 229 150C231.209 150 233 151.791 233 154Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M101 116C101 118.209 99.2091 120 97 120H86C83.7909 120 82 118.209 82 116V114C82 111.791 83.7909 110 86 110H97C99.2091 110 101 111.791 101 114V116Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M101 135C101 137.209 99.2091 139 97 139H86C83.7909 139 82 137.209 82 135V134C82 131.791 83.7909 130 86 130H97C99.2091 130 101 131.791 101 134V135Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M101 154C101 156.209 99.2091 158 97 158H86C83.7909 158 82 156.209 82 154V153C82 150.791 83.7909 149 86 149H97C99.2091 149 101 150.791 101 153V154Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M130 116C130 118.209 128.209 120 126 120H114C111.791 120 110 118.209 110 116V114C110 111.791 111.791 110 114 110H126C128.209 110 130 111.791 130 114V116Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M130 135C130 137.209 128.209 139 126 139H114C111.791 139 110 137.209 110 135V134C110 131.791 111.791 130 114 130H126C128.209 130 130 131.791 130 134V135Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M167 149H114C111.791 149 110 150.791 110 153V154C110 156.209 111.791 158 114 158H182C184.209 158 186 156.209 186 154V153C186 150.791 184.209 149 182 149H176.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M157 116C157 118.209 155.209 120 153 120H143C140.791 120 139 118.209 139 116V114C139 111.791 140.791 110 143 110H153C155.209 110 157 111.791 157 114V116Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M157 135C157 137.209 155.209 139 153 139H143C140.791 139 139 137.209 139 135V134C139 131.791 140.791 130 143 130H153C155.209 130 157 131.791 157 134V135Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M186 116C186 118.209 184.209 120 182 120H171C168.791 120 167 118.209 167 116V114C167 111.791 168.791 110 171 110H182C184.209 110 186 111.791 186 114V116Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M186 135C186 137.209 184.209 139 182 139H171C168.791 139 167 137.209 167 135V134C167 131.791 168.791 130 171 130H182C184.209 130 186 131.791 186 134V135Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M215 116C215 118.209 213.209 120 211 120H200C197.791 120 196 118.209 196 116V114C196 111.791 197.791 110 200 110H211C213.209 110 215 111.791 215 114V116Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M215 135C215 137.209 213.209 139 211 139H200C197.791 139 196 137.209 196 135V134C196 131.791 197.791 130 200 130H211C213.209 130 215 131.791 215 134V135Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M215 154C215 156.209 213.209 158 211 158H200C197.791 158 196 156.209 196 154V153C196 150.791 197.791 149 200 149H211C213.209 149 215 150.791 215 153V154Z",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M80 53C73.3726 53 68 58.3829 68 65.0229V89.0688C68 95.7089 73.3726 101.092 80 101.092H83.1111L90.2517 113.969C91.0137 115.344 92.9863 115.344 93.7483 113.969L100.889 101.092H104C110.627 101.092 116 95.7089 116 89.0688V65.0229C116 58.3829 110.627 53 104 53H80Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M90.5 68.25C90.5 68.9404 89.9404 69.5 89.25 69.5C88.5596 69.5 88 68.9404 88 68.25C88 67.5596 88.5596 67 89.25 67C89.9404 67 90.5 67.5596 90.5 68.25ZM87 71H88H96.75V73H89V78.5H96.75V80.5H89V86H96.75V88H88H87V87V72V71ZM94.25 69.5C94.9404 69.5 95.5 68.9404 95.5 68.25C95.5 67.5596 94.9404 67 94.25 67C93.5596 67 93 67.5596 93 68.25C93 68.9404 93.5596 69.5 94.25 69.5Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M214.889 158.908L207.748 146.031C206.986 144.656 205.014 144.656 204.252 146.031L197.111 158.908H194C187.373 158.908 182 164.291 182 170.931V194.977C182 201.617 187.373 207 194 207H218C224.627 207 230 201.617 230 194.977V170.931C230 164.291 224.627 158.908 218 158.908H214.889Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M201 190.861C202.317 191.607 203.812 192 205.333 192C206.855 192 208.349 191.607 209.667 190.861C210.984 190.115 212.078 189.042 212.839 187.75C213.6 186.458 214 184.992 214 183.5M214 183.5C214 182.008 213.6 180.542 212.839 179.25C212.078 177.958 210.984 176.885 209.667 176.139C208.349 175.393 206.855 175 205.333 175C203.812 175 202.317 175.393 201 176.139M214 183.5H202.444",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("v-button", {
                attrs: {
                  i18n: { id: "onboarding_experience_start_btn" },
                  filled: true,
                  color: "primary",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.startLearning.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }