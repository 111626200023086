<template>
    <div class="edit-in-sidebar-icon" :title="this.$i18n('lessons_creator_editor_tooltip_edit_in_sidebar')">
        <open-sidebar-icon />
    </div>
</template>

<script>
    import OpenSidebarIcon from 'Images/open-sidebar.svg';
    export default {
        name: 'edit-in-sidebar-icon',
        components: { OpenSidebarIcon }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.edit-in-sidebar-icon {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: pxToRem(24);
        z-index: 100;
        background-color: #fff;
        border-radius: pxToRem(4);
        padding: .2rem;
        box-shadow: 0 pxToRem(1) pxToRem(2) 0 rgba(0, 0, 0, 0.3);
        &:hover {
            > span.tooltip {
                display: block;
            }
        }
    }
</style>
