<template>
    <div class="wordlist">
        <section class="main">
            <header>
                <div class="top">
                    <wordlist-search v-on:search-for="searchForString" />
                    <wordlist-play-button v-on:play="playPlaylist" v-on:stop="stopPlaylist" :is-playing="this.playlistIsPlaying" />
                </div>
                <wordlist-filters :inverted="inverted" :show-muted="showMuted" :show-in-playlist="showInPlaylist" :show-favourites="showFavourites" :course="course" :sort-by="sortBy" :filter-counters="filterCounters"
                                  v-on:toggle-language="toggleLanguage"
                                  v-on:toggle-in-playlist="togglePlaylist"
                                  v-on:toggle-favourites="toggleFavourites"
                                  v-on:toggle-muted="toggleMuted"
                                  v-on:sort-by="sort"
                />
            </header>
            <div class="list" v-if="filteredWords && filteredWords.length > 0" ref="wordlist-wrapper">
                <wordlist-item v-for="word in filteredWords"
                               v-if="word && word.hasOwnProperty('lexical_unit_uuid')"
                               :word="word"
                               :inverted="inverted"
                               :key="word.lexical_unit_uuid"
                               :course="course"
                               :is-selected="!!(selectedWord && word.lexical_unit_uuid === selectedWord.lexical_unit_uuid)"
                               :currentlyPlayingWordUUID="currentlyPlayingWordUUID"
                               :currentlyPlayingWordAudioType="currentlyPlayingWordAudioType"
                               v-on:mute="muteWord"
                               v-on:un-mute="unMuteWord"
                               v-on:favourite="favouriteWord"
                               v-on:un-favourite="unFavouriteWord"
                               v-on:playlist="playlistWord"
                               v-on:un-playlist="unPlaylistWord"
                               v-on:select="selectWord" />
                <v-button :i18n="{ id: 'course_wizard_sentence_show_more' }" v-if="showMoreButtonVisible" class="more-button" @click.native="showMore" />
            </div>

            <div class="no-words-learned" v-else-if="this.data && this.data.words_total === 0">
                <h2 v-html="this.$i18n('dashboard_word_list_empty_state_title')" />
                <p v-html="this.$i18n('dashboard_word_list_empty_state_text')" />
                <v-button :i18n="{ id: 'dashboard_word_list_empty_state_btn' }" :filled="true" @click.native="goGuess" />
            </div>

            <div class="no-result" v-else-if="this.showMuted || this.showFavourites || this.showInPlaylist">
                <h2 v-html="this.$i18n('dashboard_word_list_search_no_result_title')" />
                <p v-if="this.showMuted" v-html="this.$i18n('dashboard_word_list_sorting_no_muted_words')"  />
                <p v-if="this.showFavourites" v-html="this.$i18n('dashboard_word_list_sorting_no_favourites')" />
                <p v-if="this.showInPlaylist" v-html="this.$i18n('dashboard_word_list_sorting_empty_playlist')" />
            </div>

            <div class="no-result" v-else>
                <h2 v-html="this.$i18n('dashboard_word_list_search_no_result_title')" />
                <div v-if="this.searchFor && this.searchFor.length > 0" v-html="this.$i18n('dashboard_word_list_search_no_result_text', { args: { search_input: this.searchFor }})" />
            </div>
        </section>
        <transition name="slide-in-fade">
            <wordlist-sidebar v-if="selectedWord" :selected-word="selectedWord" v-on:close="clearSelectedWord" v-on:update-note="updateWordNote" />
        </transition>
    </div>
</template>

<script>
    import UserManager from "Modules/usermanager.js";
    import WordlistItem from "./list-item.vue";
    import WordlistFilters from "./filters.vue";
    import WordlistSearch from "./search.vue";
    import WordlistPlayButton from "./play-button.vue";
    import { filter, clone, union } from "lodash";
    import { DEFAULT_SORT_ORDER, LearnedWords } from "Modules/learned.words.js";
    import WordlistSidebar from "./sidebar.vue";
    import getConfigValue from "Util/configuration.js";
    import AudioPlayer from "Util/audioplayer.js";
    import VButton from "ViewComponent/v-button.vue";
    import { EventBus } from "Util/vue-event-bus.js";
    import Backbone from "backbone";

    export default {
        name: 'wordlist',
        components: {
            VButton,
            WordlistSidebar,
            WordlistPlayButton,
            WordlistFilters,
            WordlistSearch,
            WordlistItem
        },
        data() {
            return {
                course: UserManager.instance.getUser().getCourse(),
                LearnedWords: null,
                data: null,
                sorting: null,
                inverted: false,
                searchFor: null,
                showMuted: false,
                showFavourites: false,
                showInPlaylist: false,
                selectedWord: null,
                sortBy: 'form-ascending',
                playlistIsPlaying: false,
                stopPlaylistPlaying: false,
                currentlyPlayingWordUUID: null,
                currentlyPlayingWordAudioType: null,
                mediaBaseUrl: getConfigValue('media-base-url'),
                visibleFilter: { from: 0, to: 100 }
            }
        },
        computed: {
            filteredWords() {
                if (this.data && this.data.words) {
                    let _mutedWords = [], _favouriteWords = [], _playListWords = [], _unionWords;
                    let hasAnyFilterTurnedOn = false;
                    if (this.showMuted) {
                        _mutedWords = this.data.words.filter(word => (word.muted === true));
                        hasAnyFilterTurnedOn = true;
                    }
                    if (this.showFavourites) {
                        _favouriteWords = this.data.words.filter(word => (word.favourite === true));
                        hasAnyFilterTurnedOn = true;
                    }
                    if (this.showInPlaylist) {
                        _playListWords = this.data.words.filter(word => (word.in_playlist === true));
                        hasAnyFilterTurnedOn = true;
                    }

                    if (_mutedWords.length > 0 || _favouriteWords.length > 0 || _playListWords.length > 0) {
                        _unionWords = union(_mutedWords, _favouriteWords, _playListWords);
                    } else {
                        _unionWords = this.data.words;
                    }

                    if (this.searchFor && this.searchFor.length > 0) {
                        if (this.inverted) {
                            _unionWords = filter(_unionWords, (item) => {
                                let translationFound = false;
                                if (item && item.sense && item.sense.translations && item.sense.translations.length > 0) {
                                    item.sense.translations.forEach(i => {
                                        if (i.translation.toLowerCase().includes(this.searchFor.toLowerCase())) {
                                            translationFound = true;
                                        }
                                    });
                                }

                                return translationFound;
                            });
                        } else {
                            _unionWords = filter(_unionWords, (item) => {
                                if (item.homograph.form.toLowerCase().includes(this.searchFor.toLowerCase())){
                                    return item.homograph.form;
                                }
                            });
                        }
                    }

                    if (_unionWords && _unionWords.length > 1 && hasAnyFilterTurnedOn && _mutedWords.length === 0 && _favouriteWords.length === 0 && _playListWords.length === 0) {
                        return [];
                    } else {
                        if (_unionWords && _unionWords.length > 100) {
                            return _unionWords.slice(this.visibleFilter.from, this.visibleFilter.to);
                        } else if (_unionWords.length <= 100) {
                            return _unionWords;
                        } else {
                            return [];
                        }
                    }
                }
            },
            showMoreButtonVisible() {
                return !this.showMuted && !this.showFavourites && !this.showInPlaylist && this.filteredWords.length >= 100;
            },
            filterCounters() {
                if (this.data && this.data.words) {
                    const _mutedWords = this.data.words.filter(word => (word.muted === true));
                    const _favouriteWords = this.data.words.filter(word => (word.favourite === true));
                    const _playListWords = this.data.words.filter(word => (word.in_playlist === true));

                    return { mutedWordsCount: _mutedWords.length, favouriteWordsCount: _favouriteWords.length, playlistWordsCount: _playListWords.length };
                } else {
                    return { mutedWordsCount: 0, favouriteWordsCount: 0, playlistWordsCount: 0 };
                }
            }
        },
        methods: {
            _preProcessData (learnedWords, statistics) {
                return {
                    sorting: learnedWords.sorting,
                    status: learnedWords.status,
                    words: learnedWords.words,
                    words_today: statistics.getTodayData().new_units.total,
                    words_total: statistics.getWordsEncountered()
                };
            },
            async setLearnedWords() {
                let statistics = UserManager.instance.getUser().getCourse().getStatistics();
                this.learnedWords = statistics.getLearnedWords();
                let _learnedWordsData = await this.learnedWords.getData();
                this.data = this._preProcessData(_learnedWordsData, statistics);
            },
            resetVisibleFilter() {
                this.visibleFilter = { from: 0, to: 100 };
            },
            toggleLanguage() {
                this.inverted = !this.inverted;
            },
            toggleMuted() {
                this.resetVisibleFilter();
                this.showMuted = !this.showMuted;
            },
            togglePlaylist() {
                this.resetVisibleFilter();
                this.showInPlaylist = !this.showInPlaylist;
            },
            toggleFavourites() {
                this.resetVisibleFilter();
                this.showFavourites = !this.showFavourites;
            },
            searchForString(string) {
                this.searchFor = string;
            },
            selectWord(word) {
                this.selectedWord = word;
            },
            clearSelectedWord() {
                this.selectedWord = null;
            },
            sort: function (sortBy) {
                this.sortBy = sortBy;
                let field, order;

                switch (sortBy) {
                    case 'form-ascending':
                        field = 'form';
                        order = 'ascending';
                        break;
                    case 'form-descending':
                        field = 'form';
                        order = 'descending';
                        break;
                    case 'last-practiced-ascending':
                        field = 'last-practiced';
                        order = 'ascending';
                        break;
                    case 'last-practiced-descending':
                        field = 'last-practiced';
                        order = 'descending';
                        break;
                    case 'times-practiced-ascending':
                        field = 'times-practiced';
                        order = 'ascending';
                        break;
                    case 'times-practiced-descending':
                        field = 'times-practiced';
                        order = 'descending';
                        break;
                    default:
                        field = sortBy;
                        order = DEFAULT_SORT_ORDER[sortBy]
                }

                let sorting = {
                    field,
                    order
                };

                this.sorting = sorting;

                LearnedWords.sort(sorting, this.data.words);
            },
            updateWordData(wordMutated) {
                if (this.data && this.data.words) {
                    this.data.words = this.data.words.map(w => {
                        if (w && w.hasOwnProperty('lexical_unit_uuid') && wordMutated && wordMutated.hasOwnProperty('lexical_unit_uuid') && w.lexical_unit_uuid === wordMutated.lexical_unit_uuid) {
                            return wordMutated;
                        } else {
                            return w;
                        }
                    });
                }
            },
            async updateWordNote(obj) {
                const { word, note } = obj;
                try {
                    let _wordMutated = clone(word);
                    _wordMutated.note = note;

                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendNoteChangeEvent(this.course.UUID, word.variation_uuid, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, note);
                } catch (e) {
                    console.log('Error with updating word note', e);
                }
            },
            async muteWord(word) {
                if (word) {
                    let _wordMutated = clone(word);
                    _wordMutated.muted = true;
                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendMuteWordEvent(this.course.UUID, word.variation_uuid, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, 'null', 'wordlist');

                    await this.learnedWords.updateLexicalUnitMuteState(word.lexical_unit_uuid, true);
                }
            },
            async unMuteWord(word) {
                if (word) {
                    let _wordMutated = clone(word);
                    _wordMutated.muted = false;
                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendUnMuteWordEvent(this.course.UUID, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, 'null');

                    await this.learnedWords.updateLexicalUnitMuteState(word.lexical_unit_uuid, false);
                }
            },
            async favouriteWord(word) {
                if (word) {
                    let _wordMutated = clone(word);
                    _wordMutated.favourite = true;
                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendFavouriteWordEvent(this.course.UUID, word.variation_uuid, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, true);

                    await this.learnedWords.updateLexicalUnitFavouriteState(word.lexical_unit_uuid, true);
                }
            },
            async unFavouriteWord(word) {
                if (word) {
                    let _wordMutated = clone(word);
                    _wordMutated.favourite = false;
                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendFavouriteWordEvent(this.course.UUID, word.variation_uuid, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, false);

                    await this.learnedWords.updateLexicalUnitFavouriteState(word.lexical_unit_uuid, false);
                }
            },
            async playlistWord(word) {
                if (word) {
                    let _wordMutated = clone(word);
                    _wordMutated.in_playlist = true;
                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendPlaylistWordEvent(this.course.UUID, word.variation_uuid, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, true);

                    await this.learnedWords.updateLexicalUnitPlaylistState(word.lexical_unit_uuid, true);
                }
            },
            async unPlaylistWord(word) {
                if (word) {
                    let _wordMutated = clone(word);
                    _wordMutated.in_playlist = false;
                    this.updateWordData(_wordMutated);
                    await UserManager.instance.getUser().getEventSender().sendPlaylistWordEvent(this.course.UUID, word.variation_uuid, word.lexical_unit_uuid, word.homograph_uuid, word.sense_uuid, word.context_uuid, false);

                    await this.learnedWords.updateLexicalUnitPlaylistState(word.lexical_unit_uuid, false);
                }
            },
            playBothAudios: async function (_word) {
                const voiceUuid = await UserManager.instance.getUser().getCourse().getVoiceUUID();
                let formAudioUrl = `${this.mediaBaseUrl}/v1/${voiceUuid}/word/${_word.sense.audio_hash}.mp3`;
                let contextAudioUrl = `${this.mediaBaseUrl}/v1/${voiceUuid}/context/${_word.context.audio_hash}.mp3`;

                this.currentlyPlayingWordUUID = _word.lexical_unit_uuid;

                // maybe user pressed stop button meanwhile
                if (!this.stopPlaylistPlaying) {
                    this.currentlyPlayingWordAudioType = 'form';
                    await AudioPlayer.playLearnedWordAudio(formAudioUrl);
                }
                // maybe user pressed stop button meanwhile
                if (!this.stopPlaylistPlaying) {
                    this.currentlyPlayingWordAudioType = 'context';
                    await AudioPlayer.playLearnedWordAudio(contextAudioUrl);
                }

                // reset when finished word playing
                this.currentlyPlayingWordAudioType = null;
                this.currentlyPlayingWordUUID = null;

                return true;
            },
            playPlaylist: async function () {
                this.playlistIsPlaying = true;
                let _filteredWords = clone(this.filteredWords);

                await _filteredWords.reduce(async (promise, word) => {
                    await promise;
                    if (!this.stopPlaylistPlaying) {
                        await this.playBothAudios(word);
                    } else {
                        _filteredWords.splice(1);
                    }
                }, Promise.resolve());


                setTimeout(() => {
                    this.stopPlaylistPlaying = false;
                    this.playlistIsPlaying = false;
                }, 500);
            },
            stopPlaylist() {
                this.stopPlaylistPlaying = true;
                switch (this.currentlyPlayingWordAudioType) {
                    case 'word':
                        AudioPlayer.stopWordAudioPlayback();
                        break;
                    case 'context':
                        AudioPlayer.stopContextAudioPlayback();
                        break;
                }
            },
            showMore() {
                this.visibleFilter = { from: this.visibleFilter.from, to: this.visibleFilter.to + 100 };
            },
            goGuess() {
                Backbone.history.navigate('guess', { trigger: true });
            },
            listenEventBus() {
                EventBus.$on('wordlist:update', this.setLearnedWords);
            }
        },
        async created () {
            await this.setLearnedWords();
            this.listenEventBus();
        },
        beforeDestroy() {
            EventBus.$off('wordlist:update', this.setLearnedWords);
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';
    div.wordlist {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        > section.main {
            flex-grow: 1;
            min-height: pxToRem(400);
            > header {
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
                padding: 1rem;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                > div.top {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    > div.wordlist-search {
                        flex-grow: 1;
                    }
                }
            }
            > div.list {
                @include minimalistScrollbar(12px, #fff);
                display: flex;
                flex-direction: column;
                background-color: #fff;
                border-bottom-left-radius: .5rem;
                border-bottom-right-radius: .5rem;
                min-height: 70vh;
                max-height: 70vh;
                overflow-y: auto;
                > button.more-button {
                    margin: 1rem 1rem 2rem 1rem;
                    justify-self: center;
                    align-self: center;
                }
            }

            > div.no-result,
            > div.no-words-learned {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: #fff;
                border-bottom-left-radius: .5rem;
                border-bottom-right-radius: .5rem;
                padding: 2rem 1rem;
                > h2 {
                    margin-bottom: .5rem;
                }
                > p {
                    margin-bottom: .5rem;
                }
            }

            > div.no-words-learned {
                gap: 1rem;
                > h2,
                > p {
                    max-width: pxToRem(400);
                    margin-bottom: 0;
                    text-align: center;
                }
            }
        }
        .slide-in-fade-enter-active {
            transition: all 200ms ease;
        }
        .slide-in-fade-enter {
            transform: translateX(-100px);
            opacity: 0;
        }
    }
</style>
