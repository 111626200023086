import $ from 'jquery';
import Backbone from 'backbone';

import ControllerManager from '../../modules/controller.manager.js';
import renderTemplate from '../../util/template.renderer.js';

const DebugViewOpeningView = Backbone.View.extend({

    name: 'debug-opening',

    events: {
        'click div.close': 'close',
        'click button.open': 'open',
        'mousedown header': 'dragStart'
    },

    initialize: function () {
        this._dragState = {};
        this._dragEndFunc = this.dragEnd.bind(this);
    },

    remove: function () {
        $(document).off('mouseup', null, this._dragEndFunc);
        this.unbind();
        Backbone.View.prototype.remove.call(this);
    },

    render: function (data) {
        renderTemplate('debug/opening', data).then(output => {
            var $element = $(output);
            this.setElement($element);
            $('body').append($element);
            this._postRender(data);
        });
        return this;
    },

    _postRender: function (data) {
        var self = this;

        $(document).on('mouseup', null, this._dragEndFunc);

        let windowWidth = $(window).width(),
            windowHeight = $(window).height();

        if (data.x <= 0 || data.x > (windowWidth + self.$el.width()) ||
            data.y <= 0 || data.y > (windowHeight + self.$el.height())) {

            ControllerManager.instance.getController('Debug').setDebugOpeningViewPosition(5, 100);

            self.$el.css('left', `5px`);
            self.$el.css('top', `100px`);
        } else {
            self.$el.css('left', `${data.x}px`);
            self.$el.css('top', `${data.y}px`);
        }
    },

    close: function () {
        ControllerManager.instance.getController('Debug').setDebugOpeningViewOpen(false);
        this.remove();
    },

    open: function () {
        ControllerManager.instance.getController('Debug').showDebugView();
    },

    dragStart: function (event) {
        var self = this;
        var elementOffset = this.$el.offset();
        this._dragState.dragStartXCompensation = event.clientX - elementOffset.left;
        this._dragState.dragStartYCompensation = event.clientY - elementOffset.top;

        this._dragState.mouseMoveHandler = function (event) {
            self.$el.css('left', `${event.clientX - self._dragState.dragStartXCompensation}px`);
            self.$el.css('top', `${event.clientY - self._dragState.dragStartYCompensation}px`);
        };

        $(document).on('mousemove', null, this._dragState.mouseMoveHandler);
    },

    dragEnd: function (event) {

        ControllerManager.instance.getController('Debug').setDebugOpeningViewPosition(
            event.clientX - (this._dragState.dragStartXCompensation || 0),
            event.clientY - (this._dragState.dragStartYCompensation || 0)
        );

        $(document).off('mousemove', null, this._dragState.mouseMoveHandler);
    }
});

export default DebugViewOpeningView;
