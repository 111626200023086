
import { signInWithGoogle } from './google.js';
import { showAppleLogin } from './apple.js';
import Facebook from './facebook.js';
import Weibo from './weibo.js';
import OauthProviderModel from '../../model/oauth-provider.model.js';


export async function oauth_authorize (provider, email_hint) {
    /**
     * @throws: String error
     * @returns: {provider, code, user, redirect_uri}
     */
    switch (provider) {
        case 'google': {
            let redirect_uri = `${window.location.protocol}//${window.location.host}`;
            try {
                const code = await signInWithGoogle();

                return { provider, code, user: null, redirect_uri };
            } catch (error) {
                if (error === 'popup-closed') {
                    throw 'closed';
                } else {
                    throw 'error-server';
                }
            }
            break;
        }
        case 'apple': {
            // Apple login redirects completely instead of a popup, so no need to return anything
            const sso_flow = OauthProviderModel.isSsoFlow();
            if (sso_flow) {
                OauthProviderModel.storeSsoParams();
            }
            showAppleLogin(sso_flow);
            return { full_redirect: true };
        }
        case 'facebook': {
            return await Facebook.showFacebookLogin(email_hint);
        }
        case 'weibo': {
            return await Weibo.showWeiboLogin();
        }
        default: {
            throw new Error(`Unknow OAuth provider="${provider}"`);
        }
    }
}
