<template>
    <div class="course-list-item-more-dropdown" @mouseleave="closeDropdownDelayed">
        <more-circle @click="openDropdown" class="transparent" />
        <transition name="fade">
            <div class="dropdown" v-if="open" @mouseenter="openDropdown" @mouseleave="closeDropdown">
                <div class="item" @click="hideCourse">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6041_118485)">
                            <path d="M3 21L21 3" stroke="black" stroke-width="2" stroke-linecap="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8997 4.40301C13.9515 4.13344 12.9779 3.98558 11.9985 4.00111C9.78671 3.96603 7.60397 4.76434 5.67799 5.91601C3.74079 7.07438 2.00015 8.62718 0.658392 10.1936L0.658386 10.1936L0.65565 10.1969C0.228858 10.6994 0 11.3417 0 11.9984C0 12.6552 0.228858 13.2975 0.65565 13.8L0.655644 13.8L0.658194 13.803C1.35988 14.6227 2.17891 15.4483 3.08508 16.2177L4.50449 14.7983C3.63749 14.0747 2.8502 13.288 2.17907 12.5042C2.06845 12.3733 2 12.1937 2 11.9984C2 11.8032 2.06842 11.6236 2.17901 11.4928C3.39981 10.0679 4.97812 8.66479 6.70441 7.63254C8.4375 6.59621 10.2571 5.97026 11.9806 6.00109C11.9926 6.00131 12.0045 6.00131 12.0164 6.00109C12.4137 5.99399 12.8161 6.02177 13.2213 6.08144L14.8997 4.40301ZM6.46984 16.227C6.53351 16.2667 6.59741 16.3059 6.66154 16.3446C8.39312 17.3892 10.2255 18.0302 11.9807 17.9989C11.9926 17.9987 12.0045 17.9987 12.0164 17.9989C13.7716 18.0302 15.6042 17.3891 17.3364 16.3445C19.062 15.3038 20.6254 13.8993 21.821 12.5041C21.9316 12.3732 22 12.1936 22 11.9984C22 11.8033 21.9316 11.6237 21.8211 11.4929C20.5987 10.0679 19.0197 8.66481 17.2931 7.63258C16.8105 7.34405 16.3212 7.08732 15.8291 6.86773L17.3287 5.36816C17.6654 5.53929 17.996 5.72265 18.3194 5.91597C20.2569 7.07429 21.9981 8.62698 23.3412 10.1932L23.3444 10.1968C23.7711 10.6994 24 11.3417 24 11.9984C24 12.6552 23.7711 13.2975 23.3443 13.8L23.3416 13.8033C22.0284 15.3361 20.3051 16.8896 18.3692 18.0572C16.4442 19.2182 14.2461 20.0345 11.9985 19.9989C9.75098 20.0345 7.55311 19.2182 5.62845 18.0571C5.42395 17.9337 5.22183 17.8061 5.02231 17.6745L6.46984 16.227Z" fill="black"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2941 7.00866C11.5429 6.9644 10.7882 7.09013 10.0875 7.38022C9.17385 7.75846 8.39287 8.39911 7.8433 9.22118C7.29378 10.0432 7.00032 11.0099 7 11.9987C6.99995 12.0973 7.00283 12.1959 7.0086 12.2941L12.2941 7.00866ZM7.87316 14.8237C8.04717 15.0781 8.24475 15.3164 8.46368 15.5354C8.92805 15.9999 9.4794 16.3683 10.0862 16.6196C10.6931 16.8709 11.3435 17.0002 12.0003 17C12.5526 16.9999 13.0001 16.552 13 15.9997C12.9999 15.4474 12.552 14.9999 11.9997 15C11.6057 15.0001 11.2155 14.9226 10.8515 14.7718C10.4874 14.621 10.1567 14.4 9.87808 14.1213C9.65665 13.8999 9.47163 13.6454 9.32938 13.3675L7.87316 14.8237ZM13.3672 9.32966C13.6443 9.47153 13.8991 9.65643 14.1216 9.87896C14.5411 10.2986 14.8267 10.8331 14.9424 11.415C15.0307 11.8589 15.017 12.3152 14.9047 12.75C14.7667 13.2848 15.0882 13.8302 15.623 13.9682C16.1577 14.1063 16.7032 13.7847 16.8412 13.25C17.0283 12.5254 17.0511 11.7649 16.904 11.025C16.7112 10.0552 16.2351 9.16426 15.536 8.46494C15.3162 8.24512 15.0775 8.04733 14.8234 7.87345L13.3672 9.32966Z" fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_6041_118485">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <span class="text" v-html="this.$i18n('hub_language_selection_hide_btn')" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import MoreCircle from 'Images/more-circle.svg';
import VButton from "ViewComponent/v-button.vue";
import { EventBus } from "Util/vue-event-bus.js";

export default {
    name: 'course-list-item-more-dropdown',
    components: {
        VButton,
        MoreCircle,
    },

    props: {
        course: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            open: false,
            closeTimeout: null
        };
    },
    methods: {
        openDropdown() {
            clearTimeout(this.closeTimeout);
            this.open = true;
        },
        closeDropdown() {
            this.open = false;
            clearTimeout(this.closeTimeout);
        },
        closeDropdownDelayed() {
            this.closeTimeout = setTimeout(() => {
                this.closeDropdown();
            }, 250);
        },
        hideCourse() {
            this.closeDropdown();
            EventBus.$emit('remove-course', this.course);
        },
    }
};
</script>

<style lang="scss">
@import "~Styles/colors";
@import "~Styles/mixins";

div.course-list-item-more-dropdown {
    width: pxToRem(28);
    height: pxToRem(28);
    position: relative;
    user-select: none;

    svg {
        circle {
            transition: fill 200ms ease;
        }
    }
    svg.transparent {
        circle {
            fill: transparent;
        }
    }
    &:hover {
        cursor: pointer;
        svg.transparent {
            circle {
                fill: #fff;
            }
        }
    }

    div.dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: pxToRem(30);
        @include elevated;
        background-color: #fff;
        z-index: 100;
        min-width: pxToRem(250);
        border-radius: pxToRem(8);
        padding: .5rem;
        body[data-interface-language="ar"] & {
            right: unset;
            left: 0;
        }
        > div.item {
            padding: pxToRem(8);
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;
            &:hover {
                background-color: $colorBackgroundLighter;
            }
        }
        &.fade-enter-active, &.fade-leave-active {
            transition: opacity 250ms;
        }
        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }
    }
}
</style>
