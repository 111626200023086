var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M33 18v9.709C33 32.29 29.191 36 24.5 36c-4.698 0-8.5-3.424-8.5-8M10 9.244V7.75C10 6.785 9.162 6 8.133 6h-.266C6.838 6 6 6.785 6 7.75v6.35c0 5.467 4.478 9.9 10 9.9s10-4.433 10-9.9V7.75C26 6.785 25.162 6 24.133 6h-.266C22.838 6 22 6.785 22 7.75v1.494",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M33 19a3 3 0 100-6 3 3 0 000 6z", fill: "#63E1E7" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }