<template>
    <section class="slider" ref="slider">
        <div class="start knob" @click="clickStartArrow()"><arrow-icon /></div>
        <div class="end knob" @click="clickEndArrow()"><arrow-icon /></div>
        <div class="slider-body-container"
             @click="clickBody"
             ref="slider_body"
             v-on:mousedown="slideStart"
        >
            <section class="slider-body" :class="{tracking: tracking}">
                <div class="slider-tick"
                     v-for="tick in this.ticks"
                     :style="{
                     left: `${tick.proportion * 100}%`,
                     visibility: (tick.first || tick.last) ? 'hidden' : 'inherit'
                 }"
                     @click.stop="clickTick(tick)"
                ></div>

                    <div
                        class="slider-knob"
                        :style="{ left: `${slider_position * 100}%` }"
                        :class="{grow: knob_animate}"
                    >
                        <transition appear><div class="inner"><div class="circle"></div></div></transition>
                    </div>
            </section>
        </div>

        <section class="labels">
            <span class="label-container" v-for="tick in this.ticks" @click="clickTick(tick)" :style="{ left: `${tick.proportion * 100}%` }"><span class="label">{{tick.value}}</span></span>
        </section>
    </section>

</template>

<script>
    import Arrow from 'Images/arrow-3.svg';

    export default {
        name: 'relevant-words-slider',
        components: { ArrowIcon: Arrow },
        props: {
            position: {
                type: Number,
                required: true
            },
            max_words: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                knob_animate: false,
                tracking: false,
                slider_position_: 0,
                ticks: [
                    {proportion: 0, value: 0, first: true},
                    {proportion: 0.1, value: 500},
                    {proportion: 0.3, value: 1500},
                    {proportion: 0.6, value: 3000},
                    {proportion: 1, value: 5000, last: true},
                ],
            };
        },

        computed: {
            slider_position () {
                if (this.tracking) {
                    return this.slider_position_;
                } else {
                    return this.position / this.max_words;
                }
            }
        },

        methods: {
            clickBody (event) {
                if (!this.tracking) {
                    this.$emit('update-position', this.position);
                }
            },

            clickTick (tick) {
                this.$emit('update-position', tick.value);
            },

            _getTrackingPosition (event) {
                const slider_x = this.$refs.slider_body.getBoundingClientRect().left;
                const slider_width = this.$refs.slider_body.offsetWidth;
                return Math.min(Math.max((event.clientX - slider_x) / slider_width, 0), 1);
            },

            _setTrackingPosition: function (event) {
                const tracking_position = this._getTrackingPosition(event);

                this.slider_position_ = tracking_position;
                this.$emit('update-position', parseInt(tracking_position * this.max_words));
            },

            slideStart (event) {
                this.tracking = true;
                this.$emit('start-tracking');
                this._setTrackingPosition(event);
            },

            slideMove (event)  {
                if (this.tracking) {
                    this._setTrackingPosition(event);
                }
            },

            slideEnd (event) {
                if (this.tracking) {
                    this.tracking = false;
                    this.$emit('end-tracking');

                    this.$emit('update-position', parseInt(this._getTrackingPosition(event) * this.max_words));
                }
            },
            clickStartArrow () {
                let i = this.ticks.slice().reverse().findIndex(t => t.value < this.position);
                if (i > -1) {
                    this.$emit('update-position', this.ticks[this.ticks.length - i - 1].value);
                }
            },
            clickEndArrow () {
                let i = this.ticks.findIndex(t => t.value > this.position);
                if (i > -1) {
                    this.$emit('update-position', this.ticks[i].value);
                }
            },
        },

        created () {
            this.slider_position_ = this.position / this.max_words;

            this._document_mouseup = event => {
                this.slideEnd(event);
            };

            this._document_mousemove = event => {
                this.slideMove(event);
            };

            // Using global listeners because it's easy for user to move mouse from
            // the slider are during slider, as it's common UX practive to be able to
            // do this
            document.addEventListener('mouseup', this._document_mouseup);
            document.addEventListener('mousemove', this._document_mousemove);
        },

        beforeDestroy () {
            document.removeEventListener('mouseup', this._document_mouseup);
            document.removeEventListener('mousemove', this._document_mousemove);
        }
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.slider {
        margin: 2rem 2rem 0;
        user-select: none;
        width: 100%;
        max-width: 32rem;
        padding: 0 0.5rem;
        position: relative;

        @include respond-to('small-medium') {
            margin: 1rem 2rem 0;
        }

        > div.knob {
            height: 1.75rem;
            width: 1.75rem;
            background-color: $colorBackgroundPurple;
            border-radius: 1.75rem;
            position: absolute;
            cursor: pointer;
            z-index: 1;

            display: flex;
            justify-content: center;
            align-items: center;

            &.start::v-deep > svg, &.end::v-deep > svg {
                height: 0.875rem;
                width: 0.875rem;
                position: relative;
            }

            &.start {
                left: 0;
                top: 0;
                transform: translate(-0.3rem, 0.15rem);

                ::v-deep > svg {
                    transform: rotate(180deg);
                    left: -2px;
                }
            }

            &.end {
                right: 0;
                top: 0;
                transform: translate(0.45rem, 0.15rem);

                ::v-deep > svg {
                    right: -1px;
                }
            }
        }

        > div.slider-body-container {
            padding: 1rem 0;
            cursor: pointer;

            > section.slider-body {
                height: 1px;
                border-radius: 0.5rem;
                background-color: $colorBackgroundDarker;
                direction: ltr;

                &.tracking {
                    > div.slider-knob {
                        transition: left 0s;
                    }
                }

                > div.slider-knob {
                    transition: left 0.25s;
                    bottom: 0;
                    position: absolute;
                    top: 0.25rem;
                    z-index: 2;

                    > div.inner {
                        height: 1.75rem;
                        width: 1.75rem;
                        background-color: $colorBackgroundPurple;
                        border-radius: 1rem;
                        transform: translate(-0.8rem, -1.1rem);
                        transition: transform 1000ms;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.v-enter-to {
                            transform: translate(3rem, -1.1rem);
                        }

                        > div.circle {
                            height: 1.125rem;
                            width: 1.125rem;
                            border-radius: 1rem;
                            border: 2px solid $colorSourcePrimary;
                        }
                    }

                    &.grow > div.inner {
                       transform: translate(3rem, -1.1rem);
                    }
                }

                > div.slider-tick {

                    &:before, &:after {
                        content: "";
                        position: absolute;
                        height: 15px;
                        width: 1px;
                        background-color: $colorBackgroundDarker;
                    }

                    &:before {
                        bottom: 0.5rem;
                    }

                    &:after {
                        top: 0.5rem;
                    }
                }
            }
        }

        > section.labels {
            margin-top: 1rem;

            > span.label-container {
                width: 0;
                display: flex;
                cursor: pointer;
                position: absolute;

                > span.label {
                    color: $colorSourcePrimary;
                    transform: translate(-50%);
                }
            }
        }
    }
</style>
