var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M19.5 1C17.6 1 16 2.6 16 4.5c0 .6.2 1.2.4 1.7l-4.1 4.1 1.4 1.4 4.1-4.1c.5.2 1.1.4 1.7.4C21.4 8 23 6.4 23 4.5S21.4 1 19.5 1zM18 4.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5S20.3 6 19.5 6 18 5.3 18 4.5zM16.4 17.8l-3.1-3.1 1.4-1.4 3.1 3.1c.5-.3 1.1-.4 1.7-.4 1.9 0 3.5 1.6 3.5 3.5S21.4 23 19.5 23 16 21.4 16 19.5c0-.6.2-1.2.4-1.7zm1.6 1.7c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5zM7.6 17.8l3.1-3.1-1.4-1.4-3.1 3.1c-.5-.2-1.1-.4-1.7-.4C2.6 16 1 17.6 1 19.5S2.6 23 4.5 23 8 21.4 8 19.5c0-.6-.2-1.2-.4-1.7zM6 19.5c0 .8-.7 1.5-1.5 1.5S3 20.3 3 19.5 3.7 18 4.5 18s1.5.7 1.5 1.5z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#63E1E7",
          d: "M16 12c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M4.5 1C6.4 1 8 2.6 8 4.5c0 .6-.2 1.2-.4 1.7l1.1 1.1-1.4 1.4-1.1-1.1c-.5.2-1.1.4-1.7.4C2.6 8 1 6.4 1 4.5S2.6 1 4.5 1zM6 4.5C6 3.7 5.3 3 4.5 3S3 3.7 3 4.5 3.7 6 4.5 6 6 5.3 6 4.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }