var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M5 18V4a1 1 0 011-1h1a1 1 0 011 1v14a1 1 0 01-1 1H6a1 1 0 01-1-1z",
          fill: "#FFD86F",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2 20V4a1 1 0 011-1h1a1 1 0 011 1v11a3 3 0 003 3h3a1 1 0 011 1v1a1 1 0 01-1 1H3a1 1 0 01-1-1z",
          fill: "#3888E0",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2 21h10v-4.5M12 5V3H2v10.5M12 11h-2a1.5 1.5 0 01-1.5-1.5v0A1.5 1.5 0 0110 8h2.554c.903 0 1.747.452 2.248 1.203L15 9.5a3.369 3.369 0 002.803 1.5H18M18 16h-.197A3.369 3.369 0 0015 17.5v0a3.369 3.369 0 01-2.803 1.5H12M18 9h4v9h-4zM6 16l1 1 2-2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }