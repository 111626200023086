
'use strict';

import _ from 'lodash';
import moment from 'moment';

import AsyncDestroyable from './async.destroyable.js';
import { EventBus } from 'Util/vue-event-bus.js';


export const STATUS = {
    ERROR: 'error',     // Something went wrong, try again next time
    INITIAL: 'initial',  // Fresh object
    LOADING: 'loading',  // Data loading has been triggered
    PARTIAL: 'partial',  // At least header data loaded - some words can probably be displayed
    READY: 'ready'       // Loading is finished or failed in a handleable way
};

const SORTING_ALPHABET = 'aAáÁàÀâÂãÃæÆbBcCçÇdDeEéÉèÈêÊẽẼëËfFgGhHiIíÍìÌîÎïÏjJkKlLmMnNñÑoOóÓòÒôÔœŒpPqQrRsSšŠžŽtTuUúÚùÙûÛvVwWxXyYýÝỳỲŷŶÿŸzZõÕäÄöÖüÜßẞ';
const FORM_CONVERSION_MAP = _.reduce(SORTING_ALPHABET, (result, letter, i) => {result[letter] = '0'.repeat(3 - i.toString().length) + i; return result;}, {});
const LETTER_CONVERSION_MAP = {
    '`': '\'',
    '´': '\'',
    '’': '\'',
    'à': 'a',
    'À': 'A',
    'á': 'a',
    'Á': 'A',
    'â': 'a',
    'Â': 'A',
    'ç': 'c',
    'Ç': 'C',
    'é': 'e',
    'É': 'E',
    'è': 'e',
    'È': 'E',
    'ê': 'e',
    'Ê': 'E',
    'ë': 'e',
    'Ë': 'E',
    'ï': 'i',
    'Ï': 'I',
    'î': 'i',
    'Î': 'I',
    'ì': 'i',
    'Ì': 'I',
    'í': 'i',
    'Í': 'I',
    'ò': 'o',
    'Ò': 'O',
    'ó': 'o',
    'Ó': 'O',
    'ô': 'o',
    'Ô': 'O',
    'œ': 'oe',
    'Œ': 'OE',
    'ù': 'u',
    'Ù': 'U',
    'ú': 'u',
    'Ú': 'U',
    'û': 'u',
    'Û': 'U',
    'й': 'и',
    'Й': 'И',
};

function _getHomographSortingKey (form) {
    /**
     * Get sorting key for form. Unknown letters are sorted to the end.
     * First the letters are passed through a conversion to eliminate any diacritics that aren't important in sorting
     * Then the letters are mapped to order codes based on the full alphabet
     */
    return {
        normalized: _.map(form.toLowerCase(), letter => FORM_CONVERSION_MAP[LETTER_CONVERSION_MAP[letter] || letter] || '999').join(''),
        hasUppercase: form.toLowerCase() !== form
    };
}

const ORDER = {
    DESC: 'descending',
    ASC: 'ascending'
};

const SORTING = {
    'form': word => word._homograph_sorting,
    'last-practiced': word => -word.guess_ts,
    'times-practiced': word => word.guess_count
};

const SORTING_FUNC = {
    'form': (a, b, mod) => {
        /**
         * First order sort is done on normalized sorting values and then items that are otherwise equal are sorted
         * based on the existence of uppercase letters in the item.
         */
        let result;
        if (a.normalized < b.normalized) {
            result = -1;
        } else if (a.normalized > b.normalized) {
            result = 1;
        } else {
            if (!a.hasUppercase && b.hasUppercase) {
                result = -1;
            } else if (a.hasUppercase && !b.hasUppercase) {
                result = 1;
            } else {
                result = 0;
            }
        }
        return mod * result;
    }
};

const DEFAULT_SORTING = {
    field: 'last-practiced',
    order: ORDER.ASC
};

export const DEFAULT_SORT_ORDER = {
    'last-practiced': ORDER.ASC,
    'times-practiced': ORDER.DESC,
    'form': ORDER.ASC,
};

export class NonMatchingWordCountException extends Error {
    constructor (message) {
        super(message);
        this.name = 'NonMatchingWordCountException';
    }
}

export class XmlHttpRequestError extends Error {
    constructor (request) {
        super(request.responseText || 'No response');
        this.name = `XmlHttpRequestError(${request.statusText || request.status || 'N/A'})`;
    }
}

class LearnedWordsError extends Error {
    constructor (message) {
        super(message);
        this.name = 'LearnedWordsError';
    }
}

export class LearnedWords extends AsyncDestroyable {
    constructor (user, course) {
        super(['load', 'sort', 'getData', 'update']);
        this._user = user;
        this._course = course;
        this._loadingPromise = null;

        // Set up instance fields
        this.status = STATUS.INITIAL;
        this.words = [];

        this.client_sn = null;
        this.last_event_ts = null;
        this.count = null;
        this.sorting = null;

        this._partialLoadPromise = null;
        this._initPartialLoadPromise();
    }

    destroy () {
        delete this._user;
        delete this.words;
        return Promise.resolve();
    }

    _initEmpty () {
        this.last_event_ts = moment.utc(0);
        this.client_sn = -1;
        this.status = STATUS.READY;
        this.max_guess_count = 1;
        this.count = 0;
        this.sorting = DEFAULT_SORTING;
    }

    _loadHeader (clientUUID, clientSn, header) {
        this.last_event_ts = moment(header.last_client_event_ts);

        if (header.client_state[clientUUID] !== undefined) {
            this.client_sn = header.client_state[clientUUID].client_sn;
        } else {
            this.client_sn = clientSn;
        }

        this.max_guess_count = header.max_guess_count;

        this.status = STATUS.PARTIAL;
        this.sorting = DEFAULT_SORTING;

        return header.count;
    }

    _processWord (wordData) {
        let word = _.clone(wordData);
        word.guess_ts = moment(wordData.guess_ts);
        word.homograph = wordData.homographs.find(h => h.uuid === wordData.homograph_uuid) || wordData.homographs[0];
        word.sense = word.homograph.senses.find(s => s.uuid === wordData.sense_uuid) || word.homograph.senses[0];
        word.context = word.sense.contexts && word.sense.contexts.find(c => c.uuid === wordData.context_uuid) || null;
        word._homograph_sorting = _getHomographSortingKey(word.homograph.form);
        return word;
    }

    _initWord (lexicalUnitData, eventStatistics) {
        let homograph = lexicalUnitData.homographs.find(h => h.uuid === eventStatistics.homograph_uuid),
            sense = homograph.senses.find(s => s.uuid === eventStatistics.sense_uuid),
            context = sense.contexts.find(c => c.uuid === eventStatistics.context_uuid);

        return {
            lexical_unit_uuid: eventStatistics.lexical_unit_uuid,
            homograph_uuid: eventStatistics.homograph_uuid,
            sense_uuid: eventStatistics.sense_uuid,
            context_uuid: eventStatistics.context_uuid,
            homographs: lexicalUnitData.homographs,
            guess_ts: eventStatistics.eventTS,
            guess_count: 1,
            homograph: homograph,
            sense: sense,
            context: context,
            correct_rate: eventStatistics.isCorrect ? 1 : 0,
            last_correct: eventStatistics.isCorrect,
            _homograph_sorting: _getHomographSortingKey(homograph.form),
            variation_uuid: eventStatistics.variation_uuid,
            favourite: eventStatistics.favourite
        };
    }

    _initPartialLoadPromise () {
        if (this._partialLoadPromise === null) {
            this._partialLoadPromise = {};
            this._partialLoadPromise.promise = new Promise((resolve, reject) => {
                this._partialLoadPromise.resolve = resolve;
                this._partialLoadPromise.reject = reject;
            });
        }
    }

    _setErrorState (error) {
        this.status = STATUS.ERROR;
        this._error = error;
        this._partialLoadPromise.reject(error);
        this._partialLoadPromise = null;
    }

    _doIncrementalLoad (url, clientUUID, clientSn) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest(),
                position = 0,
                data = '',
                headerCount,
                words = [];

            // (re)set status to loading
            this.status = STATUS.LOADING;
            this._error = null;
            this._initPartialLoadPromise();


            xhr.addEventListener("progress", event => {
                if (event.currentTarget.status === 200) {
                    let newData = event.currentTarget.responseText.substr(position);
                    position += newData.length;
                    data += newData;
                    let dataRows = data.split('\n');
                    let initial_count = this.count;
                    dataRows.forEach((row, i) => {
                        if (i === dataRows.length - 1) {
                            data = row;
                        } else {
                            if (this.status === STATUS.LOADING) {
                                headerCount = this._loadHeader(clientUUID, clientSn, JSON.parse(row));
                                this._partialLoadPromise.resolve();
                            } else {
                                words.push(this._processWord(JSON.parse(row)));
                                this.count = this.words.length;

                            }
                        }
                    });

                    this.words = words;

                    if (this.count > initial_count) {
                        EventBus.$emit('wordlist:update');
                    }
                }
            });

            xhr.addEventListener('load', event => {
                if (event.currentTarget.status === 200 && words.length !== headerCount) {
                    let error = new NonMatchingWordCountException(`Specified: ${headerCount} Present: ${words.length}`);
                    this._setErrorState(error);
                    reject(error);
                } else if (event.currentTarget.status !== 200) {
                    if (event.currentTarget.status === 404) {
                        // Okay, no learned words file yet, no problem start building on empty file
                        this._initEmpty();
                        this._partialLoadPromise.resolve();
                        resolve();
                        EventBus.$emit('wordlist:update');
                    } else {
                        let error = new XmlHttpRequestError(event.currentTarget);
                        this._setErrorState(error);
                        reject(error);
                    }
                } else {
                    this.words = words;
                    this.count = this.words.length;
                    this.status = STATUS.READY;
                    this._partialLoadPromise.resolve();
                    resolve();
                    EventBus.$emit('wordlist:update');
                }
            });

            ['error', 'abort', 'timeout'].forEach(type => {
                xhr.addEventListener(type, event => {
                    let error = new XmlHttpRequestError(event.currentTarget);
                    this._setErrorState(error);
                    reject(error);
                });
            });

            xhr.open('GET', url);
            xhr.timeout = 2 * 60 * 1000;  // Arbitrary timeout...
            xhr.send();
        });
    }

    load (url) {
        console.debug(`LearnedWords.load(${url})`);

        return this._loadingPromise || Promise.resolve()
            .then(() => {
                return Promise.all([
                    this._user.getClient().getUUID(),
                    this._user.getClient().getSn()
                ]);
            })
            .then((result) => {
                let clientUUID = result[0],
                    clientSn = result[1];

                this._loadingPromise = this._doIncrementalLoad(url, clientUUID, clientSn);
                return this._loadingPromise;
            }).catch(error => {
                this._loadingPromise = null;
                return Promise.reject(error);
            });
    }

    static sort (sorting, words) {
        words.sort((a, b) => {
            a = SORTING[sorting.field](a);
            b = SORTING[sorting.field](b);
            let mod = sorting.order === ORDER.DESC ? 1 : -1;
            let sortingFunc = SORTING_FUNC[sorting.field];

            if (sortingFunc) {
                return -1 * sortingFunc(a, b, mod);
            }

            if (a < b) {
                return mod;
            } else if (a > b) {
                return mod * -1;
            } else {
                return 0;
            }
        });
    }

    getData () {
        if (this.status === STATUS.PARTIAL || this.status === STATUS.READY) {
            return Promise.resolve(this);
        } else if (this.status === STATUS.LOADING || this.status === STATUS.INITIAL) {
            return Promise.resolve()
                .then(() => this._partialLoadPromise.promise)
                .then(() => Promise.resolve(this));
        } else {
            return Promise.reject(this.status === STATUS.ERROR && this._error || new Error(`Unable to return data, status=${this.status}`));
        }
    }

    update (eventStatistics, lexicalUnitData) {
        if (eventStatistics.clientSN > this.client_sn) {
            return Promise.resolve()
                .then(() => this.getData())
                .then(learnedWords => {
                    let word = learnedWords.words.find(w => w.lexical_unit_uuid === eventStatistics.lexical_unit_uuid);
                    if (word) {
                        word.guess_ts = eventStatistics.eventTS;
                        word.corect_rate = ((word.guess_count * word.correct_rate) + (eventStatistics.isCorrect ? 1 : 0)) / (word.guess_count + 1);
                        word.guess_count = eventStatistics.answerCount + 1;
                        word.last_correct = eventStatistics.isCorrect;
                        learnedWords.max_guess_count = Math.max(learnedWords.max_guess_count, word.guess_count);
                    } else {
                        if (lexicalUnitData !== null) {
                            learnedWords.words.push(this._initWord(lexicalUnitData, eventStatistics));
                            this.count++;
                        } else {
                            let error = new LearnedWordsError(
                                `LexicalUnitData not provided for
                                lu_uuid=${eventStatistics.lexical_unit_uuid}
                                course_uuid=${this._course.UUID}`);
                            console.error(error);
                        }
                    }

                    this.client_sn = eventStatistics.clientSN;
                    this.last_event_ts = eventStatistics.eventTS;

                    return LearnedWords.sort(this.sorting, this.words);
                }).then(() => {
                    EventBus.$emit('wordlist:update');
                });
        } else {
            return Promise.resolve();
        }
    }

    updateLexicalUnitMuteState (lexical_unit_uuid, state) {
        return Promise.resolve()
            .then(() => this.getData())
            .then(learnedWords => {
                let word = learnedWords.words.find(w => w.lexical_unit_uuid === lexical_unit_uuid);
                if (word) {
                    word.muted = state;
                } else {
                    let error = new LearnedWordsError(`updateLexicalUnitMuteState: lexical_unit_uuid not provided`);
                    console.error(error);
                }

                return LearnedWords.sort(this.sorting, this.words);
            });
    }

    updateLexicalUnitFavouriteState (lexical_unit_uuid, state) {
        return Promise.resolve()
            .then(() => this.getData())
            .then(learnedWords => {
                let word = learnedWords.words.find(w => w.lexical_unit_uuid === lexical_unit_uuid);
                if (word) {
                    word.favourite = state;
                } else {
                    let error = new LearnedWordsError(`updateLexicalUnitFavouriteState: lexical_unit_uuid not provided`);
                    console.error(error);
                }

                return LearnedWords.sort(this.sorting, this.words);
            });
    }

    updateLexicalUnitPlaylistState (lexical_unit_uuid, state) {
        return Promise.resolve()
            .then(() => this.getData())
            .then(learnedWords => {
                let word = learnedWords.words.find(w => w.lexical_unit_uuid === lexical_unit_uuid);
                if (word) {
                    word.in_playlist = state;
                } else {
                    let error = new LearnedWordsError(`updateLexicalUnitPlaylistState: lexical_unit_uuid not provided`);
                    console.error(error);
                }

                return LearnedWords.sort(this.sorting, this.words);
            });
    }
}
