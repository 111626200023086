var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relevant-words-chart-container",
      class: { tracking: _vm.tracking },
    },
    [
      _c("svg", { staticClass: "gradient-def" }, [
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              { attrs: { id: "gradient", x1: "0", x2: "0", y1: "0", y2: "1" } },
              [
                _c("stop", {
                  attrs: {
                    offset: "9.2%",
                    "stop-color": "#E2B4FF",
                    "stop-opacity": "1",
                  },
                }),
                _vm._v(" "),
                _c("stop", {
                  attrs: {
                    offset: "100%",
                    "stop-color": "white",
                    "stop-opacity": "1",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { ref: "chart", staticClass: "ct-chart" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }