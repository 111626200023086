var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#63E1E7",
          d: "M18.5 18.3c-1.5 2.2-4.1 3.7-7 3.7C6.8 22 3 18.2 3 13.5c0-2.8 1.4-5.3 3.5-6.9 0 0-3.5 7.4 1 10.9s10 0 10 0l1 .8z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M12.5 5C8.9 5 6 7.9 6 11.5S8.9 18 12.5 18c1.6 0 3-.6 4.1-1.5l.7-.6 6.4 6.4c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-5.1-5.1c-1.3.9-2.9 1.4-4.7 1.4C7.8 20 4 16.2 4 11.5S7.8 3 12.5 3 21 6.8 21 11.5c0 1-.2 1.9-.5 2.8-.2.5-.8.8-1.3.6-.5-.2-.8-.8-.6-1.3.2-.7.4-1.4.4-2.2C19 7.9 16.1 5 12.5 5z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M11.9 7.9c.2.5 0 1.1-.5 1.3-.8.4-1.4 1.3-1.4 2.3 0 1 .6 1.9 1.4 2.3.5.2.7.8.5 1.3-.2.5-.8.7-1.3.5-1.5-.8-2.6-2.3-2.6-4.1 0-1.8 1.1-3.3 2.6-4.1.5-.2 1.1 0 1.3.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }