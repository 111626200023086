<template>
    <div class="active-variations" v-if="variations">
        <h2><span v-html="this.$i18n('hub_active_courses')" /> <span class="count">{{ variations.length }}</span></h2>
        <div class="list">
            <template v-for="variation in visibleVariations">
                <active-variation-item :variation="variation"
                                       v-bind:key="`active_variation_${variation.uuid}`"
                                       v-bind:is-game-over="isGameOver"
                                       :variation-toggle-disabled="variation.type === 'general' && disableGeneralSettings || !isSubscriptionActive" />
            </template>
        </div>
        <div class="toggleWrapper" v-if="showToggle">
            <div @click="toggleVisible" class="toggleButton hide" v-if="this.allVisible">
                <span v-html="this.$i18n('hub_active_variations_toggle_hide')"></span>
                <toggle-arrow />
            </div>
            <div @click="toggleVisible" class="toggleButton" v-else>
                <span v-html="this.$i18n('hub_active_variations_toggle_show')" />
                <toggle-arrow />
            </div>
        </div>
    </div>
</template>

<script>
    import ActiveVariationItem from './active-variation-item.vue';
    import ToggleArrow from 'Images/arrow-3.svg'

    export default {
        name: 'active-variations',
        props: {
            variations: {
                type: Array,
                required: true
            },
            disableGeneralSettings: {
                type: Boolean,
                default: false
            },
            isSubscriptionActive: {
                type: Boolean
            },
            isGameOver: {
                type: Boolean
            }
        },
        components: {
            ActiveVariationItem,
            ToggleArrow
        },
        data() {
            return {
                showToggle: this.variations && this.variations.length > 3,
                allVisible: false
            };
        },
        computed: {
            visibleVariations: function () {
                if (this.allVisible) {
                    return this.variations;
                } else {
                    return this.variations.filter((i, index) => (index < 3))
                }
            }
        },
        methods: {
            toggleVisible() {
                this.allVisible = !this.allVisible
            }
        },
        watch: {
            variations: function(newVariations, oldVariations) {
                if (newVariations.length !== oldVariations.length) {
                    const _isMoreThanThree = newVariations.length > 3;
                    this.showToggle = _isMoreThanThree;
                    this.allVisible = _isMoreThanThree;
                }
            },
        },
    };
</script>

<style lang="scss">
    @import "~Styles/mixins";
    @import "~Styles/colors";

    div.active-variations {
        > h2 {
            span.count {
                font-weight: $font-normal;
                font-size: fontSize(20);
                margin-left: .25rem;
                opacity: .5;
            }
        }
        > div.list {
            display: flex;
            flex-direction: column;
            > div.variation {
                display: flex;
                align-items: center;
                margin: pxToRem(4) 0;
                background-color: $colorBackgroundLighter;
                padding: pxToRem(8);
                border-radius: pxToRem(8);
                user-select: none;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }

                > div.variation-icon {
                    flex: 0;
                    width: pxToRem(40);
                    height: pxToRem(40);
                    cursor: pointer;
                }

                > div.name {
                    flex: 1;
                    font-weight: $font-bold;
                    margin: 0 2rem;
                    cursor: pointer;
                    @include respond-to('small') {
                        font-size: fontSize(14);
                        flex: 2;
                        margin: 0 1rem;
                    }
                }

                > div.stats {
                    flex: 2;
                    @include respond-to('small') {
                        flex: 0;
                        display: none;
                    }
                    > div.text {
                        color: $colorSourceSecondary;
                        margin-bottom: .5rem;
                        font-size: fontSize(12);
                        em {
                            font-weight: $font-bold;
                            color: $colorSourcePrimary;
                        }
                    }
                    main.progress-opened & {
                        flex: 0;
                        display: none;
                    }
                }

                > div.actions {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    > div {
                        margin-left: pxToRem(8);
                        &:last-child {
                            margin-right: pxToRem(8);
                        }
                        &.status-icon {
                            background-color: #fff;
                        }
                    }
                }

                &:hover {
                    @include elevated;
                    transition: all 100ms linear;
                    background-color: #fff;

                    > div.actions {
                        > div.status-icon {
                            background-color: $colorBackgroundGray;
                        }
                    }
                }
            }
        }
        > div.toggleWrapper {
            margin: 1rem 1rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
            > div.toggleButton {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                span {
                    margin-right: .5rem;
                }
                svg {
                    margin-top: pxToRem(3);
                    transform: rotate(90deg);
                }
                &.hide {
                    svg {
                        margin-top: 0;
                        transform: rotate(270deg);
                    }
                }
                &:hover {
                    opacity: .8;
                }
            }
        }
    }
</style>
