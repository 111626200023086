var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M18.485 12.902C17.108 12.558 16 13.676 16 15c0 1.324 1.108 2.442 2.485 2.098l.273-.068a1 1 0 11.485 1.94l-.273.068C16.348 19.694 14 17.608 14 15c0-2.608 2.348-4.694 4.97-4.038l.273.068a1 1 0 11-.485 1.94l-.273-.068zm.222 7.391a1 1 0 010 1.414l-1 1a1 1 0 01-1.414-1.414l1-1a1 1 0 011.414 0z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 7H5v12h6a1 1 0 100-2H7v-3h4a1 1 0 100-2H7V9h4a1 1 0 100-2z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.707 2.293a1 1 0 010 1.414l-2 2a1 1 0 01-1.414-1.414l2-2a1 1 0 011.414 0z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }