const languageMap = new Map([
    ['af', 'af-ZA'],
    ['am', 'am-ET'],
    ['ar', 'ar-XA'],
    ['as', 'as-IN'],
    ['az', 'az-AZ'],
    ['be', 'be-BY'],
    ['bg', 'bg-BG'],
    ['bn', 'bn-IN'],
    ['bs', 'bs-BA'],
    ['ca', 'ca-ES'],
    ['cs', 'cs-CZ'],
    ['cy', 'cy-GB'],
    ['da', 'da-DK'],
    ['de', 'de-DE'],
    ['el', 'el-GR'],
    ['en', 'en-US'],
    ['en-GB', 'en-GB'],
    ['en-IN', 'en-IN'],
    ['en-AU', 'en-AU'],
    ['en-CA', 'en-CA'],
    ['en-SG', 'en-SG'],
    ['en-ZA', 'en-ZA'],
    ['es', 'es-ES'],
    ['es-419', 'es-419'],
    ['es-US', 'es-US'],
    ['et', 'et-EE'],
    ['eu', 'eu-ES'],
    ['fa', 'fa-IR'],
    ['fi', 'fi-FI'],
    ['fil', 'fil-PH'],
    ['fr', 'fr-FR'],
    ['fr-CA', 'fr-CA'],
    ['gl', 'gl-ES'],
    ['gu', 'gu-IN'],
    ['hi', 'hi-IN'],
    ['hr', 'hr-HR'],
    ['hu', 'hu-HU'],
    ['hy', 'hy-AM'],
    ['id', 'id-ID'],
    ['is', 'is-IS'],
    ['it', 'it-IT'],
    ['iu', 'iu-CA'],
    ['ja', 'ja-JP'],
    ['jv', 'jv-ID'],
    ['ka', 'ka-GE'],
    ['kk', 'kk-KZ'],
    ['km', 'km-KH'],
    ['kn', 'kn-IN'],
    ['ko', 'ko-KR'],
    ['kok', 'kok-IN'],
    ['ky', 'ky-KG'],
    ['lo', 'lo-LA'],
    ['lt', 'lt-LT'],
    ['lv', 'lv-LV'],
    ['mk', 'mk-MK'],
    ['ml', 'ml-IN'],
    ['mn', 'mn-MN'],
    ['mr', 'mr-IN'],
    ['ms', 'ms-MY'],
    ['mt', 'mt-MT'],
    ['nb', 'nb-NO'],
    ['ne', 'ne-NP'],
    ['nl', 'nl-NL'],
    ['or', 'or-IN'],
    ['pa', 'pa-IN'],
    ['pl', 'pl-PL'],
    ['ps', 'ps-AF'],
    ['pt', 'pt-PT'],
    ['pt-BR', 'pt-BR'],
    ['pt-PT', 'pt-PT'],
    ['ro', 'ro-RO'],
    ['ru', 'ru-RU'],
    ['rw', 'rw-RW'],
    ['si', 'si-LK'],
    ['sk', 'sk-SK'],
    ['sl', 'sl-SI'],
    ['sq', 'sq-AL'],
    ['sr', 'sr-RS'],
    ['sv', 'sv-SE'],
    ['sw', 'sw-TZ'],
    ['ta', 'ta-IN'],
    ['te', 'te-IN'],
    ['th', 'th-TH'],
    ['tr', 'tr-TR'],
    ['uk', 'uk-UA'],
    ['ur', 'ur-PK'],
    ['uz', 'uz-UZ'],
    ['vi', 'vi-VN'],
    ['zh', 'zh-CN'],
    ['zh-CN', 'zh-CN'],
    ['zh-TW', 'zh-TW'],
    ['nb', 'nb-NO'],
    ['no', 'nb-NO'],
]);

export default class VoiceInput {
    static isSupportedBrowser() {
        const userAgent = navigator.userAgent.toLowerCase();
        const supportedBrowsers = ['chrome', 'edge'];
        let browserType = null;

        switch (true) {
            case userAgent.includes('chrome'):
                browserType = 'chrome';
                break;
            case userAgent.includes('firefox'):
                browserType = 'firefox';
                break;
            case userAgent.includes('edg'):
                browserType = 'edge';
                break;
            default:
                browserType = 'unknown';
        }

        return supportedBrowsers.includes(browserType);
    }

    static getLanguageMap() {
        return languageMap;
    }
}
