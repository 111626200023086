var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manage" }, [
    _c("h1", {
      domProps: { innerHTML: _vm._s(this.$i18n("pay_manage_title")) },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column braintree" },
        [
          _c("braintree", {
            attrs: {
              token: _vm.token,
              user: _vm.user,
              enableThreeDSecure: _vm.enableThreeDSecure,
              amount: this.activeProduct ? this.activeProduct.amount : null,
            },
            on: {
              "set-braintree-instance": _vm.setBraintreeInstance,
              "payment-option-selected": _vm.setPaymentOptionSelected,
              "payment-method-requestable": _vm.setPaymentMethodRequestable,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "summary" }, [
          _c("h4", {
            domProps: {
              innerHTML: _vm._s(this.$i18n("account_subscription_heading")),
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "active-product" }, [
            _c("span", [_vm._v(_vm._s(this.activeProduct.title))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("account_subscription_paid_billing", {
                    args: {
                      0: this.activeProduct.formattedPrice,
                      1: this.activeProduct.formattedBillingInterval,
                    },
                  })
                ),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("account_subscription_paid_renew", {
                    args: { 0: this.activeProduct.formattedBillingDate },
                  })
                ),
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footnote" }, [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(this.brandingId, {
                  collection: "pay_product_recurring_text_cancel",
                })
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c("v-button", {
              attrs: {
                i18n: { id: "pay_manage_btn" },
                filled: true,
                color: "primary",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.update.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }