var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M5 10c0-1.7 1.3-3 3-3h1c.6 0 1 .4 1 1s-.4 1-1 1H8c-.6 0-1 .4-1 1v5c0 .6-.4 1-1 1s-1-.4-1-1v-5zm4 6c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1s-1-.4-1-1v-5c0-.6.4-1 1-1zM13 8c0-.6.4-1 1-1h1c1.7 0 3 1.3 3 3v4c0 .6-.4 1-1 1s-1-.4-1-1v-4c0-.6-.4-1-1-1h-1c-.6 0-1-.4-1-1zm1 6c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1v-7c0-.6.4-1 1-1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#8EA3EE",
          d: "M14 3.5C14 4.9 12.9 6 11.5 6S9 4.9 9 3.5 10.1 1 11.5 1 14 2.1 14 3.5z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M17 16c.6 0 1 .4 1 1v2.5c0 .6-.4 1-1 1s-1-.4-1-1V17c0-.6.4-1 1-1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }