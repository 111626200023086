<template>
    <div class="confirmation-modal" @click.self="close">
        <div class="confirmation-modal-window">
            <header>
                <h2>{{ title }}</h2>
                <CloseButton @click="close" class="close" v-if="closable" />
            </header>
            <main>
                <div class="text" v-html="description" />
                <div class="actions" v-if="actions">
                    <v-button :text="action.text" v-for="action in this.actions" :key="action.action" @click.native="emitAction(action.action)" :filled="!!action.primary" size="medium" />
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import CloseButton from 'Images/close-24x24.svg';
import VButton from "ViewComponent/v-button.vue";

export default {
    name: 'confirmation-modal',
    props: {
        closable: {
            type: Boolean,
            default: true
        },
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        VButton,
        CloseButton,
    },
    computed: {
        title() {
            return this.content && this.content.title ? this.content.title : null
        },
        description() {
            return this.content && this.content.description ? this.content.description : null
        },
        actions() {
            return this.content && this.content.actions && this.content.actions.length > 0 ? this.content.actions : null
        }
    },
    methods: {
        close() {
            if (this.closable) {
                this.$emit('close');
            }
        },
        keyDown(event) {
            if (this.closable && event && event.code === 'Escape') { // close on ESC key
                this.close()
            }
        },
        emitAction(action) {
            this.$emit('action', action);
        }
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
        document.body.classList.add('modal-open');
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
    }
};
</script>

<style lang="scss">
@import "~Styles/colors";
@import "~Styles/mixins";

div.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(44, 49, 67, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div.confirmation-modal-window {
        position: relative;
        @include elevated;
        background-color: #fff;
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 2rem);
        min-width: pxToRem(400);
        max-width: pxToRem(600);
        box-sizing: border-box;
        padding-bottom: 1.5rem;
        gap: 1rem;

        > header {
            padding: 1.5rem 1.5rem 0 1.5rem;
            display: flex;
            flex-direction: row;
            body[data-interface-language="ar"] & {
                flex-direction: row-reverse;
            }
            > svg.close {
                cursor: pointer;
                transition: 100ms ease-in-out;
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-left: unset;
                    margin-right: auto;
                }
                &:hover {
                    transform: scale(1.2);
                }
            }

            > h2 {
                font-size: fontSize(20);
                line-height: fontSize(24);
                text-align: left;
                margin-right: 1rem;
                body[data-interface-language="ar"] & {
                    text-align: right;
                    margin-left: 1rem;
                    margin-right: unset;
                }
            }
        }

        > main {
            padding: 0 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            > div.text {
                > em {
                    font-weight: $font-bold;
                }
            }
            > div.actions {
                margin-top: auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                gap: 1rem;
            }
        }

    }
}
</style>
