<template>
    <div class="checkbox" @click="toggle">
        <!-- Checked -->
        <svg v-if="this.value_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" xml:space="preserve">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h12c1.1 0
            2-.9 2-2V2c0-1.1-.9-2-2-2H2zm10.7 6.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L7 9.6 4.7 7.3c-.4-.4-1-.4-1.4
            0-.4.4-.4 1 0 1.4l3 3c.2.2.4.3.7.3s.5-.1.7-.3l5-5z"/></svg>
        <!-- Unchecked -->
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" xml:space="preserve">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 2H2v12h12V2zM2 0C.9 0 0 .9 0 2v12c0 1.1.9 2 2
            2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2z"/></svg>

        <p class="label" v-if="label !== null">{{ label }}</p>
    </div>
</template>

<script>

export default {
    name: 'checkbox',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            value_: this.value,
        };
    },
    methods: {
        toggle () {
            this.value_ = !this.value_;
            this.$emit('input', this.value_);
        },
    },
};
</script>

<style lang="scss">
@import "~Styles/mixins";

div.checkbox {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    padding: 0 1rem;
    min-height: 2.5rem;
    gap: .75rem;

    > svg {
        width: 1rem;
        height: 1rem;
        flex: 0 0 1rem;
    }
}
</style>
