var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "icon-button",
      class: { active: this.active || this.pulse },
    },
    [
      _vm.notify && !_vm.active
        ? _c("span", { staticClass: "notify-blob" })
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.icon, {
        tag: "component",
        class: { pulse: this.pulse },
        attrs: { "data-name": _vm.iconName },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }