import $ from 'jquery';
import Backbone from 'backbone';
import Raven from 'raven-js';

import ResponsivenessUtils from '../../util/responsiveness.js';
import UserManager from '../../modules/usermanager.js';

import renderTemplate from '../../util/template.renderer.js';

const ContentDialogView = Backbone.View.extend({

    tagName: 'main',
    className: 'main-view help',

    events: {
        'click .close': 'close',
        'click div.show-all > span.show-all-text': 'clickShowAll',
        'click aside > ul span.list-item-text': 'showItem',
        'click a[data-action="navigate-back"]': 'navigateBack',
        'input input.search': 'search',
        'focus input.search': 'focusSearch',
        'blur input.search': 'blurSearch',
    },

    initialize: function () {
        console.log('Initialize ContentDialog');
    },

    remove: function () {
        this.$el.find('article.content').unbind();
        this.unbind();
        Backbone.View.prototype.remove.call(this);
    },

    render: async function (data) {

        if (this.$el.find('aside > ul')) {
            this.$el.find('aside > ul').off();
        }

        await renderTemplate(this.mainTemplate, data, this.$el);

        this._postRender();

        return this;
    },

    _postRender: function () {
        var self = this;
        this.$el.find('aside > ul').on('scroll', function (event) {
            self.scrollItems(event);
        });
        Backbone.trigger('rendered', this);
    },

    clickShowAll: function (event) {
        var self = this,
            $searchInput = this.$el.find('input.search');

        if (ResponsivenessUtils.mediaMinWidthMatches(720) || $searchInput.val().length > 0) {
            this.showAll(event).then(
                function (result) {
                    $searchInput.val('');
                    if (result.refresh) {
                        self._refresh(result.data);
                    } else {
                        self.removeFocus();
                    }
                },
                function (error) {
                    Raven.captureException(error, {level: 'error'});
                }
            );
        } else {
            this.toggleList(event);
        }
    },

    removeFocus: function () {
        this.$el.find('.current').removeClass('current');
        this.$el.find('aside > div.show-all').addClass('current');
        this.$el.find('article.content').removeClass('content-item-selected');
        this.$el.find('article.content').animate({scrollTop: 0}, 250);
    },

    toggleList: function (event) {
        var $target = $(event.currentTarget).parent(),
            mainHeight = $target.parent().parent().height(),
            asideHeight = $target.parent().outerHeight(),
            height = mainHeight - asideHeight;

        $target.parent().parent().toggleClass('list-open');
        if ($target.parent().parent().hasClass('list-open')) {
            $target.parent().find('ul').height(height);
        } else {
            $target.parent().find('ul').height(0);
        }
    },

    showItem: function (event) {
        var $target = $(event.currentTarget);
        this._showItem($target.parent(), $target.data('key'));
        UserManager.instance.getUser().getEventSender().sendNavigationEvent('grammar-tip', 'open', $target.data('key'));
    },

    _showItem: function ($menuItem, key) {
        var self = this;
        this.focusItem(key).then(
            function (result) {
                if (result.refresh) {
                    self._refresh(result.data);
                } else {
                    self.$el.find('.current').removeClass('current');
                    $menuItem.addClass('current');
                    var $contentItem = self.$el.find('div.content-item[data-key="' + key + '"]'),
                        $articleContent = self.$el.find('article.content'),
                        scrollTopTarget = $articleContent.scrollTop() + $contentItem.offset().top - $articleContent.offset().top - 50;

                    $articleContent.unbind('scroll');
                    $articleContent.animate({scrollTop: scrollTopTarget}, {
                        duration: 250,
                        complete: function () {
                            $articleContent.on('scroll', function () {
                                if(Math.abs(scrollTopTarget - $articleContent.scrollTop()) > ResponsivenessUtils.adjustToDevicePixelRatio(100)) {
                                    $articleContent.removeClass('content-item-selected');
                                    self.$el.find('.current-item').removeClass('current-item');
                                    self.$el.find('li.current').removeClass('current');
                                    $articleContent.unbind('scroll');
                                }
                            });
                        }
                    });
                    $articleContent.addClass('content-item-selected');
                    self.$el.find('.current-item').removeClass('current-item');
                    $contentItem.addClass('current-item');
                    if (ResponsivenessUtils.mediaMaxWidthMatches(720)) {
                        self.$el.find('aside > ul').height(0);
                        self.$el.find('div.content-dialog > main').removeClass('list-open');
                    }
                }
            },
            function (error) {
                Raven.captureException(error, {level: 'error'});

            }
        );
    },

    navigateBack: function () {
        window.history.back();
    },

    scrollItems: function (event) {
        var $target = $(event.currentTarget),
            $lastChild = $target.children().last();

        if ($lastChild.length !== 0) {
            var targetHeight = $lastChild.offset().top - $target.offset().top + $lastChild.outerHeight();
            if (targetHeight - $target.height() < ResponsivenessUtils.adjustToDevicePixelRatio(25)) {
                this.$el.find('div.content-dialog > main').addClass('scrolled-to-bottom');
            } else {
                this.$el.find('div.content-dialog > main').removeClass('scrolled-to-bottom');
            }
        }
    },

    _refresh: async function (data) {
        this.$el.find('article.content').html(await renderTemplate(this.contentTemplate, data));
        this.$el.find('article.content').scrollTop(0);

        this.$el.find('aside > ul').html(await renderTemplate(this.listTemplate, data));
        this.$el.find('div.show-all').addClass('current');
        this.$el.find('div.show-all > span.favorite > input').attr('checked', data.inFavorites);
    },

    focusSearch: function (event) {
        var $target = $(event.currentTarget);
        $target.parent().addClass('focused');
    },

    blurSearch: function (event) {
        var $target = $(event.currentTarget);
        $target.parent().removeClass('focused');
    }
});

export default ContentDialogView;
