<template>
    <div class="thermometer" :class="{ clickable: this.clickable || this.canZoom, compact: this.compact }" v-on:click="clickTriggered">
        <template v-if="!compact">
            <h3 v-html="this.$i18n('hub_thermometer_title')" />
            <div class="sub-title">
                <span class="done" v-html="subTitle" />
                <span class="percentage" v-if="this.zoomed && this.canZoom && this.currentZoomLevel" v-html="this.$i18n('hub_thermometer_zoom_info', { args: { zoom_level: currentZoomLevel } })" />
            </div>
        </template>
        <div class="zoom" v-else-if="this.canZoom">
            <ZoomOutIcon v-if="zoomed" />
            <ZoomInIcon v-else />
            <span class="zoom-txt" v-if="zoomed" v-html="this.$i18n('thermometer_metrics_zoom_out')" />
            <span class="zoom-txt" v-else v-html="this.$i18n('thermometer_metrics_zoom_in')" />
            <span class="percentage">{{ currentZoomLevel }}%</span>
        </div>
        <div class="thermometer-bar" ref="bar">
            <div class="repeats" v-bind:style="{ width: `${repeatsWidth}%` }"></div>
            <div class="progressing" v-bind:style="{ width: `${progressingWidth}%` }"></div>
            <div class="mastered" v-bind:style="{ width: `${masteredWidth}%` }"></div>
        </div>
        <div class="one-liner" v-if="compact">
            <h4 v-html="this.$i18n('hub_thermometer_title')" />
            <div class="sub-title" v-html="subTitle" />
        </div>
    </div>
</template>

<script>
    import i18nUtils from "Util/i18n";
    import UserManager from "Modules/usermanager";
    import ZoomInIcon from "Images/insights/ic_zoom_in.svg";
    import ZoomOutIcon from "Images/insights/ic_zoom_out.svg";
    import { NAME } from "Modules/user.parameters.js";
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'thermometer',
        components: {
            ZoomInIcon,
            ZoomOutIcon
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            course: {
                type: Object,
                required: true
            },
            clickable: {
                type: Boolean,
                default: false
            },
            compact: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                width: 100,
                minWidth: 1,
                statistics: (this.course) ? this.course.getStatistics() : null,
                learningProgress: null,
                zoomed: false,
                getCourseLearningProgressTimeout: null
            }
        },
        computed: {
            totalWords() {
                if (this.learningProgress && this.learningProgress.hasOwnProperty('not_seen') && this.learningProgress.hasOwnProperty('progressing') && this.learningProgress.hasOwnProperty('mastered') && this.learningProgress.hasOwnProperty('repeats_waiting')) {
                    return this.learningProgress.not_seen + this.learningProgress.repeats_waiting + this.learningProgress.progressing + this.learningProgress.mastered;
                } else {
                    return 0;
                }
            },
            totalWordsZoomed() {
                if (this.zoomed && this.canZoom) {
                    return this.totalWords * this.zoomLevel / 100;
                } else {
                    return null;
                }
            },
            repeatsWidth() {
                if (this.learningProgress && this.learningProgress.hasOwnProperty('repeats_waiting')) {
                    let _totalWords = (this.totalWordsZoomed) ? this.totalWordsZoomed : this.totalWords;
                    return Math.max(Math.floor(this.width * this.learningProgress.repeats_waiting / _totalWords), (this.learningProgress.repeats_waiting > 0) ? this.minWidth : 0);
                } else {
                    return 0;
                }
            },
            progressingWidth() {
                if (this.learningProgress && this.learningProgress.hasOwnProperty('progressing')) {
                    let _totalWords = (this.totalWordsZoomed) ? this.totalWordsZoomed : this.totalWords;
                    return Math.max(this.width * this.learningProgress.progressing / _totalWords, (this.learningProgress.progressing > 0) ? this.minWidth : 0);
                } else {
                    return 0;
                }
            },
            masteredWidth() {
                if (this.learningProgress && this.learningProgress.hasOwnProperty('mastered')) {
                    let _totalWords = (this.totalWordsZoomed) ? this.totalWordsZoomed : this.totalWords;
                    return Math.max(this.width * this.learningProgress.mastered / _totalWords, (this.learningProgress.mastered > 0) ? this.minWidth : 0);
                } else {
                    return 0;
                }
            },
            subTitle() {
                if (this.learningProgress && this.learningProgress.hasOwnProperty('progressing') && this.learningProgress.hasOwnProperty('mastered') && this.learningProgress.hasOwnProperty('repeats_waiting')) {
                    const seenWords = this.learningProgress.repeats_waiting + this.learningProgress.progressing + this.learningProgress.mastered;
                    const percentage = Math.floor(seenWords / this.totalWords * 100);
                    return i18nUtils.prop('hub_thermometer_measurement', { learned_words_percent: percentage, learned_words_total: this.totalWords } );
                } else {
                    return '&nbsp;';
                }
            },
            zoomLevel() {
                if (this.learningProgress && this.learningProgress.hasOwnProperty('progressing') && this.learningProgress.hasOwnProperty('mastered') && this.learningProgress.hasOwnProperty('repeats_waiting')) {
                    const seenWords = this.learningProgress.repeats_waiting + this.learningProgress.progressing + this.learningProgress.mastered;
                    const percentage = Math.floor(seenWords / this.totalWords * 100);

                    return (percentage + 10) / 10 * 10;
                }
            },
            canZoom () {
                return this.zoomLevel <= 90;
            },
            currentZoomLevel() {
                return (this.zoomed && this.canZoom) ? this.zoomLevel : 100;
            }
        },
        methods: {
            clickTriggered() {
                if (this.canZoom && this.compact) {
                    this.setZoomed(!this.zoomed);
                    this.setZoomedToParams();
                    EventBus.$emit('thermometer:zoomed', this.zoomed);
                } else {
                    this.$emit('thermometer-clicked');
                }
            },
            async getAndSetLearningProgress() {
                if (this.user && this.course && this.course.UUID) {
                    const fetchFresh = !!this.compact;
                    this.learningProgress = await this.user.getCourseLearningProgress(this.course.UUID, fetchFresh);
                    EventBus.$emit('thermometer:learning-progress-updated', { uid: this._uid, progress: this.learningProgress });
                }
            },
            async getAndSetLearningProgressFreshWithDelay() {
                if (this.user && this.course && this.course.UUID) {
                    this.getCourseLearningProgressTimeout = setTimeout(async () => {
                        this.learningProgress = await this.user.getCourseLearningProgress(this.course.UUID, true);
                        EventBus.$emit('thermometer:learning-progress-updated', { uid: this._uid, progress: this.learningProgress });
                    }, 1500);
                }
            },
            updateLearningProgressEvent(event) {
                // only update learningProgress local variable if it was sent by another thermometer instance, if it's the same, it's already up-to-date
                if (event.uid !== this._uid) {
                    this.learningProgress = event.progress;
                }
            },
            setZoomed(state) {
                this.zoomed = state;
            },
            getAndSetZoomedFromParams() {
                const userParameters = UserManager.instance.getUser().getParameters();
                this.setZoomed(userParameters.getParameter(NAME.SETTING_THERMOMETER_ZOOMED));
            },
            setZoomedToParams() {
                const userParameters = UserManager.instance.getUser().getParameters();
                userParameters.setParameter(NAME.SETTING_THERMOMETER_ZOOMED, this.zoomed);
            },
            listenEventBus() {
                EventBus.$on('thermometer:zoomed', this.setZoomed);
                EventBus.$on('course-state-updated', this.getAndSetLearningProgress);
                EventBus.$on('enable-variation', this.getAndSetLearningProgressFreshWithDelay);
                EventBus.$on('enable-variation-only-this', this.getAndSetLearningProgressFreshWithDelay);
                EventBus.$on('disable-variation', this.getAndSetLearningProgressFreshWithDelay);
                EventBus.$on('thermometer:learning-progress-updated', this.updateLearningProgressEvent);
            },
            unListenEventBus() {
                EventBus.$off('thermometer:zoomed', this.setZoomed);
                EventBus.$off('course-state-updated', this.getAndSetLearningProgress);
                EventBus.$off('enable-variation', this.getAndSetLearningProgressFreshWithDelay);
                EventBus.$off('enable-variation-only-this', this.getAndSetLearningProgressFreshWithDelay);
                EventBus.$off('disable-variation', this.getAndSetLearningProgressFreshWithDelay);
                EventBus.$on('thermometer:learning-progress-updated', this.updateLearningProgressEvent);
            }
        },
        watch: {
            course(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getAndSetLearningProgress();
                }
            }
        },
        mounted() {
            this.listenEventBus();
            this.getAndSetLearningProgress();
            this.getAndSetZoomedFromParams();
        },
        beforeDestroy() {
            this.unListenEventBus();
            clearTimeout(this.getCourseLearningProgressTimeout);
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';
    div.thermometer {
        background-color: $colorBackgroundLighter;
        border-radius: pxToRem(8);
        padding: 1rem;
        user-select: none;
        $thermometer-border-radius: pxToRem(4);
        &.clickable {
            &:hover {
                cursor: pointer;
                background-color: rgba($colorBackgroundLighter, 0.8);
            }
        }
        > h3 {
            margin-bottom: .5rem;
            font-size: fontSize(18);
            font-weight: $font-bold;
        }
        > div.sub-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: .5rem;
            font-size: fontSize(14);
            color: $colorSourceSecondary;
            > span {
                &.done {
                    color: $colorSourceSecondary;
                }
                &.percentage {
                    margin-left: auto;
                    body[data-interface-language="ar"] & {
                        margin-left: unset;
                        margin-right: auto;
                    }
                }
            }
        }
        > div.thermometer-bar {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            background-color: $colorBackgroundDarker;
            border-radius: $thermometer-border-radius;
            height: pxToRem(10);
            min-height: pxToRem(10);
            overflow: hidden;
            > div {
                background-color: #000;
                transition: width 250ms ease-in;
                &.repeats {
                    background-color: $colorAttention;
                    transition-delay: 50ms;
                }
                &.progressing {
                    background-color: $colorPurplePrimary;
                    transition-delay: 100ms;
                }
                &.mastered {
                    background-color: $colorKnowledgeMapping;
                    transition-delay: 150ms;
                }
            }
        }
        > div.zoom {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: $font-bold;
            margin-bottom: .5rem;
            gap: .5rem;
            > span.percentage {
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-right: auto;
                    margin-left: unset;
                }
            }
        }
        > div.one-liner {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            margin-top: .5rem;
            > h4 {
                font-weight: $font-bold;
            }
            > div.sub-title {
                color: $colorSourceSecondary;
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-left: unset;
                    margin-right: auto;
                }
            }
        }
    }
</style>
