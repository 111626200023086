var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "saving-indicator" }, [
    _vm.saving ? _c("span", { staticClass: "saving-blob" }) : _vm._e(),
    _vm._v(" "),
    _vm.saving
      ? _c("span", {
          staticClass: "saving",
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("saving", {
                collection: "lessons_creator_editor_saving_indicator",
              })
            ),
          },
        })
      : _c("span", {
          staticClass: "saved",
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("saved", {
                collection: "lessons_creator_editor_saving_indicator",
              })
            ),
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }