
import Backbone from 'backbone';

import AsyncDestroyable from '../async.destroyable.js';

export class UserTerms extends AsyncDestroyable {
    constructor (user) {
        super([]);

        this._user = user;

        this.tos_needs_update = null;
        this.current_tos_version = null;
        this.pp_needs_update = null;
        this.current_pp_version = null;
        this.changes_url = null;
    }

    async destroy () {
        delete this._user;
    }

    async update (terms_and_conditions) {
        this.tos_needs_update = terms_and_conditions.tos_needs_update;
        this.current_tos_version = terms_and_conditions.current_tos_version;
        this.pp_needs_update = terms_and_conditions.pp_needs_update;
        this.current_pp_version = terms_and_conditions.current_pp_version;
        this.changes_url = terms_and_conditions.changes_url;


        Backbone.trigger('terms_and_conditions_updated', this._user, this);
    }
}
