var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.toast
    ? _c(
        "div",
        { staticClass: "toast", attrs: { "data-type": _vm.toast.type } },
        [
          _vm.toast.type
            ? _c(
                "div",
                { staticClass: "icon" },
                [
                  _vm.toast.type === _vm.TYPE.SUCCESS
                    ? _c("tick-icon", { attrs: { width: "20", height: "20" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.toast.type === _vm.TYPE.ERROR
                    ? _c("error-icon", { attrs: { width: "20", height: "20" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.toast.type === _vm.TYPE.ALERT
                    ? _c("alert-icon", { attrs: { width: "24", height: "24" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.toast.type === _vm.TYPE.INCOMPLETE
                    ? _c("incomplete-icon", {
                        attrs: { width: "24", height: "24" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.toast.type === _vm.TYPE.MICROPHONE
                    ? _c("mic-icon", { attrs: { width: "24", height: "24" } })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.toast.text))]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }