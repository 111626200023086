
import { getPersistentStorageProvider } from '../modules/persistent.storage.provider.js';


const OauthProviderModel = {
    _sso_params: null,

    isSsoFlow: function () {
        return this._sso_params || false;
    },

    enableSsoFlow: function (params) {
        this._sso_params = params;
    },

    disableSsoFlow: async function () {
        delete this._sso_params;
        await this.clearSsoParams();
    },

    getSsoParams () {
        return this._sso_params;
    },

    async storeSsoParams () {
        await getPersistentStorageProvider().setItemAnonymous('sso_parameters', this._sso_params);
    },

    async loadSsoParams () {
        this._sso_params = await getPersistentStorageProvider().getItemAnonymous('sso_parameters');
    },

    async clearSsoParams () {
        await getPersistentStorageProvider().removeItemAnonymous('sso_parameters');
    }
};

export default OauthProviderModel;
