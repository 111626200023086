var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-preview" },
    [
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("course-icon", {
            attrs: { size: "medium", value: _vm.lessonTargetLanguageIconId },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row form" },
            [
              !_vm.form_in_edit
                ? _c(
                    "div",
                    {
                      staticClass: "form target",
                      class: { empty: !_vm.form || _vm.form.length <= 0 },
                      on: {
                        click: function ($event) {
                          _vm.form_in_edit = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "edit-icon" },
                        [_c("edit-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "content",
                        domProps: { innerHTML: _vm._s(_vm.form) },
                      }),
                    ]
                  )
                : _c("editable", {
                    key: "form_editable_1",
                    staticClass: "target context-editable",
                    attrs: {
                      value: _vm.form,
                      "max-length": 80,
                      nowrap: false,
                      "no-padding": true,
                      "focus-on-mount": true,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      change: function ($event) {
                        return _vm.updateCardField($event, "form")
                      },
                      blur: function ($event) {
                        _vm.form_in_edit = false
                      },
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row context" },
            [
              !_vm.context_in_edit
                ? _c(
                    "div",
                    {
                      staticClass: "context target",
                      class: { empty: !_vm.context || _vm.context.length <= 0 },
                      on: {
                        click: function ($event) {
                          _vm.context_in_edit = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "edit-icon" },
                        [_c("edit-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "content",
                        domProps: {
                          innerHTML: _vm._s(_vm.context_highlighted),
                        },
                      }),
                    ]
                  )
                : _c("editable", {
                    key: "context_editable_1",
                    staticClass: "target context-editable",
                    attrs: {
                      value: _vm.context,
                      "max-length": 110,
                      nowrap: false,
                      "no-padding": true,
                      "focus-on-mount": true,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      change: function ($event) {
                        return _vm.updateCardField($event, "context")
                      },
                      blur: function ($event) {
                        _vm.context_in_edit = false
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("course-icon", {
            attrs: { size: "medium", value: _vm.lessonSourceLanguageIconId },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row form-translation" },
            [
              !_vm.form_translation_in_edit
                ? _c(
                    "div",
                    {
                      staticClass: "form-translation",
                      class: {
                        empty:
                          !_vm.form_translation ||
                          _vm.form_translation.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          _vm.form_translation_in_edit = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "edit-icon" },
                        [_c("edit-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "content",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.form_translation_placeholder
                              ? _vm.form_translation_placeholder
                              : _vm.form_translation
                          ),
                        },
                      }),
                    ]
                  )
                : _c("editable", {
                    key: "form_translation_editable_1",
                    staticClass: "form-translation-editable",
                    attrs: {
                      value: _vm.form_translation,
                      "max-length": 80,
                      nowrap: false,
                      "no-padding": true,
                      "focus-on-mount": true,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      change: function ($event) {
                        return _vm.updateCardField($event, "form_translation")
                      },
                      blur: function ($event) {
                        _vm.form_translation_in_edit = false
                      },
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row context-translation" },
            [
              !_vm.context_translation_in_edit
                ? _c(
                    "div",
                    {
                      staticClass: "context-translation",
                      class: {
                        empty:
                          !_vm.context_translation ||
                          _vm.context_translation.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          _vm.context_translation_in_edit = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "edit-icon" },
                        [_c("edit-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "content",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.context_translation_placeholder
                              ? _vm.context_translation_placeholder
                              : _vm.context_translation_highlighted
                          ),
                        },
                      }),
                    ]
                  )
                : _c("editable", {
                    key: "context_translation_editable_1",
                    staticClass: "context-translation-editable",
                    attrs: {
                      value: _vm.context_translation,
                      "max-length": 110,
                      nowrap: false,
                      "no-padding": true,
                      "focus-on-mount": true,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      change: function ($event) {
                        return _vm.updateCardField(
                          $event,
                          "context_translation"
                        )
                      },
                      blur: function ($event) {
                        _vm.context_translation_in_edit = false
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.error
          ? _c("div", {
              staticClass: "error",
              domProps: { innerHTML: _vm._s(_vm.error) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.cardHasChanges
        ? _c(
            "div",
            { staticClass: "actions" },
            [
              _c("v-button", {
                attrs: {
                  filled: true,
                  color: "primary",
                  size: "medium",
                  i18n: { id: "lessons_creator_editor_card_preview_save_btn" },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.save.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("v-button", {
                attrs: {
                  size: "medium",
                  i18n: {
                    id: "lessons_creator_editor_card_preview_cancel_btn",
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancel.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }