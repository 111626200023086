const LetterConversionMap = {
    '`': '\'',
    '´': '\'',
    '’': '\'',
    'à': 'a',
    'À': 'A',
    'á': 'a',
    'Á': 'A',
    'â': 'a',
    'Â': 'A',
    'ä': 'a',
    'Ä': 'A',
    'å': 'aa',
    'Å': 'AA',
    'æ': 'ae',
    'Æ': 'AE',
    'ç': 'c',
    'Ç': 'C',
    'é': 'e',
    'É': 'E',
    'è': 'e',
    'È': 'E',
    'ê': 'e',
    'Ê': 'E',
    'ë': 'e',
    'Ë': 'E',
    'ï': 'i',
    'Ï': 'I',
    'î': 'i',
    'Î': 'I',
    'ì': 'i',
    'Ì': 'I',
    'í': 'i',
    'Í': 'I',
    'ò': 'o',
    'Ò': 'O',
    'ó': 'o',
    'Ó': 'O',
    'ô': 'o',
    'Ô': 'O',
    'ö': 'o',
    'Ö': 'O',
    'õ': 'o',
    'Õ': 'O',
    'ø': 'oe',
    'Ø': 'OE',
    'œ': 'oe',
    'Œ': 'OE',
    'ù': 'u',
    'Ù': 'U',
    'ú': 'u',
    'Ú': 'U',
    'û': 'u',
    'Û': 'U',
    'ü': 'u',
    'Ü': 'U',
    'ß': 'ss',
    'ñ': 'n',
    'Ñ': 'N',
    'š': 's',
    'Š': 'S',
    'ž': 'z',
    'Ž': 'Z',
    'ą': 'a',
    'Ą': 'A',
    'ć': 'c',
    'Ć': 'C',
    'ę': 'e',
    'Ę': 'E',
    'ł': 'l',
    'Ł': 'L',
    'ń': 'n',
    'Ń': 'N',
    'ś': 's',
    'Ś': 'S',
    'ź': 'z',
    'Ź': 'Z',
    'ż': 'z',
    'Ż': 'Z',
    'ā': 'a',
    'Ā': 'A',
    'ē': 'e',
    'Ē': 'E',
    'ī': 'i',
    'Ī': 'I',
    'ō': 'o',
    'Ō': 'O',
    'ū': 'u',
    'Ū': 'U',
    'ı': 'i',
    'I': 'I',
    'İ': 'I',
    'ğ': 'g',
    'Ğ': 'G',
    'ş': 's',
    'Ş': 'S'
};

const PinyinLetterConversionMap = {
    'ā': 'a',
    'Ā': 'A',
    'á': 'a',
    'Á': 'A',
    'ǎ': 'a',
    'Ǎ': 'A',
    'à': 'a',
    'À': 'A',
    'ē': 'e',
    'Ē': 'E',
    'é': 'e',
    'É': 'E',
    'ě': 'e',
    'Ě': 'E',
    'è': 'e',
    'È': 'E',
    'ī': 'i',
    'Ī': 'I',
    'í': 'i',
    'Í': 'I',
    'ǐ': 'i',
    'Ǐ': 'I',
    'ì': 'i',
    'Ì': 'I',
    'ō': 'o',
    'Ō': 'O',
    'ó': 'o',
    'Ó': 'O',
    'ǒ': 'o',
    'Ǒ': 'O',
    'ò': 'o',
    'Ò': 'O',
    'ū': 'u',
    'Ū': 'U',
    'ú': 'u',
    'Ú': 'U',
    'ǔ': 'u',
    'Ǔ': 'U',
    'ù': 'u',
    'Ù': 'U',
    'ǖ': 'u',
    'Ǖ': 'U',
    'ǘ': 'u',
    'Ǘ': 'U',
    'ǚ': 'u',
    'Ǚ': 'U',
    'ǜ': 'u',
    'Ǜ': 'U'
};

export {
    LetterConversionMap,
    PinyinLetterConversionMap
};
