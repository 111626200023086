import AsyncDestroyable from '../async.destroyable.js';

export class UserClassrooms extends AsyncDestroyable {

    constructor (user) {
        super([]);
        this._user = user;
    }

    getClassroomList () {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.classrooms.get())
            .then(response => {
                return Promise.resolve(response.classrooms);
            });
    }

    getClassroom (classroomUuid) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.classrooms.classroom_uuid(classroomUuid).get())
            .then( response => {
                return Promise.resolve(response);
            });
    }

    createClassroom (name, course_uuid) {

        return Promise.resolve()
            .then( () => this._user.getApiClient().r.classrooms.post({ name, course_uuid }))
            .then(response => {
                return Promise.resolve(response.name);
            });
    }

    joinClassroom (classroom_code) {

        return Promise.resolve()
            .then( () => this._user.getApiClient().r.classrooms.join.post({ classroom_code }))
            .then( response => {
                return Promise.resolve(response.name);
            })
            .catch(error => {
                if (error.response && error.response.body && error.response.body.code) {
                    return Promise.resolve(error.response.body);
                }
            });
    }

    removeStudent (classroom_uuid, user_uuid) {

        return Promise.resolve()
            .then( () => this._user.getApiClient().r.classrooms
                .classroom_uuid(classroom_uuid).user_uuid(user_uuid).delete())
            .then(response => {
                if (response.status === 'absent') {
                    return Promise.resolve();
                } else {
                    return Promise.reject({ code: 'unknown-error' });
                }
            })
            .catch(error => {
                return Promise.reject(error.response.body);
            });
    }

    updateClassroomName (classroom_uuid, name) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.classrooms
                .classroom_uuid(classroom_uuid).post({ name }))
            .then( response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                if (error.response && error.response.body && error.response.body.code) {
                    return Promise.resolve(error.response.body);
                }
            });
    }

    deleteClassroom (classroom_uuid) {
        return Promise.resolve()
            .then(() => this._user.getApiClient().r.classrooms
                .classroom_uuid(classroom_uuid).delete())
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error.response.body);
            });
    }

    assignLesson (lesson_uuid, classrooms) {

        const putLessonRequests = classrooms.map( classroom => {
            return this._user.getApiClient().r.classrooms.classroom_uuid(classroom.uuid).lessons.put({
                lesson_uuid,
                variation_uuid: lesson_uuid,
                activate: true
            });
        });

        return Promise.all(putLessonRequests)
            .then( () => {
                return Promise.resolve();
            })
            .catch( error => {
                return Promise.reject(error.response.body.code);
            });

    }
}
