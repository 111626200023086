<template>
    <main class="relevant-words">
        <div class="container">
            <h1 v-html="this.$i18n('linking_purpose_relevant_words_title')"></h1>
            <relevant-words-message v-if="relevantWordsMessage" :message="this.relevantWordsMessage" :tracking="slider_tracking" :position="position" :max_words="max_words" />
            <div class="message-area-placeholder" v-else></div>

            <section class="data-visualization">
                <relevant-words-chart class="chart" :series="this.chart_series" :position="position" :max_words="max_words" :tracking="slider_tracking" />
                <relevant-words-text class="text" :learned_text_raw="this.learned_text_raw" :position="position" :max_words="max_words" />
            </section>

            <relevant-words-slider
                :position="position"
                :max_words="max_words"
                @update-position="sliderUpdate"
                @start-tracking="sliderStartTracking"
                @end-tracking="sliderEndTracking"
            />
        </div>
        <button @click="continue_" v-html="this.$i18n('linking_purpose_relevant_words_button_continue')"></button>
    </main>
</template>

<script>
    import Backbone from 'backbone';

    import RelevantWordsChart from './components/chart.vue';
    import RelevantWordsSlider from './components/slider.vue';
    import RelevantWordsText from './components/text.vue';
    import RelevantWordsMessage from './components/message.vue';
    import i18nUtils from "Util/i18n.js";

    const MAX_WORDS = 5000;

    export default {
        name: 'relevant-words',
        components: {
            RelevantWordsChart,
            RelevantWordsSlider,
            RelevantWordsText,
            RelevantWordsMessage,
        },
        props: [],
        data() {
            return {
                initial: true,
                position: 0,
                max_words: MAX_WORDS,
                slider_tracking: false
            }
        },
        computed: {
            relevantWordsMessage() {
                const collectionId = (this.target_language === 'ja') ? 'linking_purpose_relevant_words_message_ja' : 'linking_purpose_relevant_words_message';

                if (this.position < 100) {
                    return i18nUtils.prop('0', null, null, collectionId);
                } else if (this.position >= 100 && this.position < 1500) {
                    return i18nUtils.prop('501', null, null, collectionId, null, { addDefaultParams: true });
                } else if (this.position >= 1500 && this.position < 3000) {
                    return i18nUtils.prop('1501', null, null, collectionId, null, { addDefaultParams: true });
                } else if (this.position >= 3000 && this.position < 5000) {
                    return i18nUtils.prop('3001', null, null, collectionId, null, { addDefaultParams: true });
                } else if (this.position >= 5000) {
                    return i18nUtils.prop('5000', null, null, collectionId, null, { addDefaultParams: true });
                } else {
                    return null;
                }
            }
        },

        methods: {
            sliderUpdate (words) {
                this.position = words;
                this.initial = false;
            },

            sliderStartTracking () {
                this.slider_tracking = true;
                this.$emit('start-tracking', this.position);
            },

            sliderEndTracking () {
                this.slider_tracking = false;
                this.$emit('end-tracking', this.position);
            },

            continue_ () {
                this.$emit('continue-pressed');
            },
        },

        created () {
            this.chart_series = this.$options.chart_series;
            this.learned_text_raw = this.$options.learned_text_raw;
            this.target_language = this.$options.target_language;
        },

        mounted() {
            Backbone.trigger('rendered', { name: 'relevant-words', type: 'vue' });
        }
    }
</script>

<style lang="scss" scoped>
    @import '~Styles/mixins';
    @import "~Styles/colors";

    main.relevant-words {
        padding: 4rem 4rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media all and (max-height: em(710,16)), all and (max-width: em(660,16)) {
            padding: 1rem;
        }


        @media all and (max-height: em(660,16)), all and (max-width: em(416,16)) {
            padding: 0 1rem 1rem;
        }

        > div.container {
            max-width: 68rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            @media all and (max-height: em(660,16)) {
                padding: 0 1rem;
            }

            > h1 {
                font-size: fontSize(22);
                text-align: center;
            }

            > section.data-visualization {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 1rem;
                width: 100%;
                align-items: flex-start;
                gap: 1rem;

                @media all and (max-width: em(904,16)) {
                    flex-direction: column;
                    align-items: center;
                    gap: 0;
                }
            }

            > div.message-area-placeholder {
                width: calc(100vw - 2rem);
            }
        }

        > button {
            min-width: 20rem;
            margin-bottom: 2rem;
            height: 3rem;
            margin-top: 3rem;

            @media all and (max-height: em(805,16)) {
                margin-top: 4.5rem;
            }

            @media all and (max-height: em(750,16)) {
                margin-top: 2rem;
            }

            @include respond-to('small-medium') {
                margin-top: 2rem;
            }
        }
    }
</style>
