
'use strict';

import { QuestionList } from './question.list.js';

export class DebugQuestionList extends QuestionList {

    constructor (course, assetLoader, Modules) {
        super(course, null, assetLoader, Modules);
        this.__name = 'DebugQuestionList';

        this.ASSET_LOADING_PRIORITY = 2;
    }

    _questionFilter (questionData) {
        return this._Modules.Question.isDebug(questionData);
    }
}
