var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M34 11a7 7 0 11-14 0 7 7 0 0114 0z", fill: "#AC6ED1" },
      }),
      _c("path", {
        attrs: {
          d: "M6 13h22v16.178c0 2.489-1.257 3.733-3.143 4.355-1.886.623-4.4.623-5.657 0",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 6v12.87c0 8.195-1.333 13.239-6 15.13",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }