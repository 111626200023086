var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.selectedWord
    ? _c("aside", { staticClass: "wordlist-sidebar" }, [
        _c(
          "header",
          [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("dashboard_word_list_word_details_title")
                ),
              },
            }),
            _vm._v(" "),
            _c("close-icon", {
              staticClass: "close",
              on: { click: _vm.close },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _vm.selectedWord.homograph
              ? _c(
                  "div",
                  {
                    staticClass: "homograph",
                    class: {
                      audioIsPlaying:
                        _vm.audioIsPlaying && _vm.audioTypePlaying === "form",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.playAudio("form")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "target" }, [
                      _vm.selectedWord.homograph &&
                      _vm.selectedWord.homograph.begin_form
                        ? _c("span", { staticClass: "begin" }, [
                            _vm._v(
                              _vm._s(_vm.selectedWord.homograph.begin_form)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.selectedWord.homograph.form) +
                          "\n                    "
                      ),
                      _vm.selectedWord.homograph &&
                      _vm.selectedWord.homograph.end_form
                        ? _c("span", { staticClass: "end" }, [
                            _vm._v(_vm._s(_vm.selectedWord.homograph.end_form)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("speaker-button", {
                      attrs: { "in-progress": _vm.audioIsPlaying },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("grammar", {
              attrs: {
                "parsed-grammar": _vm.selectedWord.homograph.parsed_grammar,
              },
            }),
            _vm._v(" "),
            _vm.selectedWord.sense && _vm.selectedWord.sense.translations
              ? _c(
                  "div",
                  {
                    staticClass: "sense-translation",
                    class: {
                      audioIsPlaying:
                        _vm.audioIsPlaying && _vm.audioTypePlaying === "form",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.playAudio("form")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "translations-wrapper" },
                      [
                        _vm._l(
                          _vm.selectedWord.sense.translations,
                          function (item, index) {
                            return [
                              _c("span", { staticClass: "translation" }, [
                                _vm._v(_vm._s(item.translation)),
                              ]),
                              _vm._v(" "),
                              index + 1 <
                              _vm.selectedWord.sense.translations.length
                                ? _c("span", { staticClass: "comma" }, [
                                    _vm._v(", "),
                                  ])
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "separator" }),
            _vm._v(" "),
            _c("div", { staticClass: "meta" }, [
              _c("span", { staticClass: "data-point" }, [
                _vm._v(_vm._s(_vm.wordLastSeen)),
              ]),
              _vm._v(" | "),
              _c("span", {
                staticClass: "data-point",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("learned_words_word_guess_count", {
                      args: { guess_count: _vm.selectedWord.guess_count },
                    })
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "separator" }),
            _vm._v(" "),
            _vm.selectedWord.sense &&
            _vm.selectedWord.sense.contexts &&
            _vm.selectedWord.sense.contexts.length > 0
              ? _c(
                  "div",
                  { staticClass: "contexts" },
                  _vm._l(
                    _vm.selectedWord.sense.contexts,
                    function (context_item) {
                      return _c("div", { staticClass: "context-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "context target",
                            class: {
                              audioIsPlaying:
                                _vm.audioHashPlaying ===
                                context_item.audio_hash,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.playAudio(
                                  "context",
                                  context_item.audio_hash
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(context_item.context))]
                        ),
                        _vm._v(" "),
                        context_item.translations &&
                        context_item.translations.length > 0
                          ? _c(
                              "div",
                              { staticClass: "context-translations" },
                              _vm._l(
                                context_item.translations,
                                function (translation_item) {
                                  return _c("div", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(translation_item.translation) +
                                        "\n                    "
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ])
                    }
                  ),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "note" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.note,
                    expression: "note",
                    modifiers: { trim: true },
                  },
                ],
                attrs: {
                  rows: "6",
                  maxlength: "1000",
                  placeholder: this.$i18n(
                    "dashboard_word_list_word_details_notes_unfocused"
                  ),
                },
                domProps: { value: _vm.note },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.note = $event.target.value.trim()
                    },
                    _vm.debouncedOnNoteChange,
                  ],
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "note-counter" }, [
                _vm._v(_vm._s(_vm.noteCounter)),
              ]),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }