
'use strict';

import Raven from 'raven-js';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import AppState from '../modules/app.state.js';
import FeedbackSidecarSender from '../modules/feedback.sidecar.sender.js';

import FeedbackView from '../view/help/feedback.view.js';

const FEEDBACK_SETUPS = {
    bug: {
        mode: 'bug',
        categories: [
            'bug',
            'typos_and_translations'
        ],
        allowAttachment: false
    },
    feedback: {
        mode: 'feedback',
        categories: [
            'typos_and_translations',
            'account_and_settings',
            'ideas_and_thoughts'
        ],
        allowAttachment: false
    }
};

export class FeedbackController {
    constructor () {
        this._feedbackSidecarSender = new FeedbackSidecarSender();
    }

    async submitFeedback (content, mood, category, feedbackView) {
        let self = this;

        await feedbackView.showPostFeedback({category: category});

        let appState = new AppState();
        return Promise.resolve().then(function () {
            return UserManager.instance.getUser().getEventSender().sendFeedbackEvent({
                content: content,
                mood: mood,
                category: category
            }, appState, UserManager.instance.getUser().getSubscription().getStatus());
        }).then(function (event) {
            return self._feedbackSidecarSender.send(UserManager.instance.getUser().getApiClient(), appState, event);
        }).catch(function (error) {
            Raven.captureException(error, {level: 'error'});
        });
    }

    show (mode, category) {
        const _user = UserManager.instance.getUser();
        if (_user.hasCourse()) {
            let feedbackUrl = _user.getCourse().getUrl('feedback');
            if (feedbackUrl !== null) {
                window.open(feedbackUrl, '_blank');
            } else {
                let feedbackView = new FeedbackView();

                feedbackView.render(FEEDBACK_SETUPS[mode]);
                feedbackView.show(category);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('feedback', 'open');

                feedbackView.on('remove', function () {
                    UserManager.instance.getUser().getEventSender().sendNavigationEvent('feedback', 'close');
                });
            }
        } else {
            return Promise.resolve();
        }
    }
}

export const feedback_controller_factory = new ControllerFactory(FeedbackController);
