<template>
    <transition name="fade-message">
        <div class="locked-message" v-if="!hidden">
            <div class="content">
                <div class="icon-container">
                    <unlock-icon />
                </div>
                <div class="message-action">
                    <div class="message">
                        <h4 v-if="title">{{ title }}</h4>
                        <p class="text" v-if="text"> {{ text }}</p>
                        <p v-else v-html="this.$i18n('pre_trial_float_message_text')" />
                    </div>
                    <v-button :i18n="{ id: (this.showStartTrial) ? 'pre_trial_float_message_button' : 'pre_trial_float_message_button_2' }" route="subscriptions" size="medium" color="primary" :filled="true" />
                </div>
            </div>
            <div class="right" v-if="closable">
                <div class="separator" />
                <close-button @click="close" class="close" />
            </div>
        </div>
    </transition>
</template>

<script>
    import VButton from "./v-button.vue";
    import UnlockIcon from 'Images/unlock-purple.svg';
    import CloseButton from 'Images/close-20x20.svg';
    import UserManager from "Modules/usermanager";
    import { NAME, TYPE } from "Modules/user.parameters";
    import { SUBSCRIPTION_STATUS } from "Modules/subscription";
    import { EventBus } from "Util/vue-event-bus";

    export default {
        name: 'locked-message',
        components: { VButton, UnlockIcon, CloseButton },
        props: {
            closable: {
                type: Boolean,
                default: true
            },
            isSubscriptionActive: {
                type: Boolean
            },
            title: {
                type: String
            },
            text: {
                type: String
            }
        },
        data() {
            return {
                hidden: true,
                hasBeenClosedBefore: false
            }
        },
        computed: {
            showStartTrial() {
                return UserManager.instance.getUser().getSubscription().getStatus().status === SUBSCRIPTION_STATUS.PRE_TRIAL;
            }
        },
        methods: {
            close() {
                if (this.closable) {
                    this.setClosedParameter();
                    this.hidden = true;
                }
            },
            getClosedParameter() {
                const userParameters = UserManager.instance.getUser().getParameters();
                return userParameters.getParameter(NAME.LOCKED_MESSAGE_CLOSED);
            },
            setClosedParameter() {
                this.hasBeenClosedBefore = true;
                const userParameters = UserManager.instance.getUser().getParameters();
                return userParameters.setParameter(NAME.LOCKED_MESSAGE_CLOSED, true, TYPE.BOOLEAN);
            },
            listenEventBus() {
                EventBus.$on('interfaceLanguageChanged', this.reRender);
            },
            reRender() {
                this.$forceUpdate();
            }
        },
        created() {
            this.listenEventBus();
            this.hasBeenClosedBefore = this.getClosedParameter();
            this.hidden = this.isSubscriptionActive || this.closable && this.hasBeenClosedBefore;
        },
        beforeDestroy() {
            EventBus.$off('interfaceLanguageChanged', this.reRender);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.locked-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba($colorBackgroundPurple, 0.1);
        padding: 1rem;
        border: 1px solid $colorPurplePrimary;
        border-radius: pxToRem(4);
        > div.content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            flex: 1;
            > div.icon-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: pxToRem(40);
                height: pxToRem(40);
                background-color: rgba($colorBackgroundPurple, 0.2);
                border-radius: pxToRem(4);
                margin-right: 1rem;
                align-self: flex-start;
            }
            > div.message-action {
                display: flex;
                flex-direction: row;
                flex: 1;
                @include respond-to('small') {
                    flex-direction: column;
                }
                > div.message {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    white-space: normal;
                    margin: .5rem .5rem .5rem 0;
                    > h4 {
                        font-weight: bolder;
                    }
                }
                > button {
                    margin-left: auto;
                    @include respond-to('small') {
                        margin-left: unset;
                    }
                }
            }
        }
        > div.right {
            display: flex;
            justify-content: center;
            align-items: center;
            > div.separator {
                position: relative;
                display: flex;
                align-items: stretch;
                min-height: 2rem;
                margin-left: 1.5rem;
                margin-right: 1.2rem;
                &::after {
                    display: block;
                    content: ' ';
                    border-left: 1px solid rgba($colorPurplePrimary, 0.5);
                }
            }
            > svg {
                transition: all 100ms linear;
                &:hover {
                    transform: scale(1.2);
                    cursor: pointer;
                }
            }
        }
    }
    .fade-message-enter-active, .fade-message-leave-active {
        transform: translateY(0);
        transition: all 250ms ease-in-out;
    }
    .fade-message-enter, .fade-message-leave-to {
        opacity: 0;
        transform: translateY(-100%);
    }
</style>
