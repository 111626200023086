<template>
    <svg class="edit-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 12L10.5 17.5L4 20L6.5 13.5L15.5858 4.41421C16.3668 3.63317 17.6332 3.63317 18.4142 4.41421L19.5858 5.58579C20.3668 6.36684 20.3668 7.63317 19.5858 8.41421L19.5 8.5" stroke="#2C3143" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 20H17" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
    export default {
        name: 'edit-icon'
    }
</script>
