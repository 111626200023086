var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.parsedGrammar
    ? _c(
        "div",
        { staticClass: "card-grammar", class: _vm.typeClass },
        [
          _vm.parsedGrammar.icon
            ? _c("grammar-icon", {
                attrs: { value: _vm.parsedGrammar.icon.icon },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.parsedGrammar.icon && _vm.parsedGrammar.icon.description
            ? _c("div", { staticClass: "txt" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.parsedGrammar.icon.description) +
                    "\n    "
                ),
              ])
            : _vm.typesString
            ? _c("div", { staticClass: "txt" }, [
                _vm._v("\n        " + _vm._s(_vm.typesString) + "\n    "),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }