var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cards-control-more-dropdown",
      on: { mouseleave: _vm.closeDropdownDelayed, click: _vm.openDropdown },
    },
    [
      _c("more-dots", { class: { transparent: _vm.transparent } }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.open
          ? _c(
              "div",
              {
                staticClass: "dropdown",
                on: {
                  mouseenter: _vm.openDropdown,
                  mouseleave: _vm.closeDropdown,
                },
              },
              [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("lessons_creator_editor_settings_modal_title")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("cards-toggle-menu", {
                  attrs: { lesson: this.lesson, cards: this.cards },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }