var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status-icon" },
    [
      this.tooltip
        ? [
            _c("div", {
              staticClass: "tooltip",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n(this.tooltip, {
                    collection: "hub_variation_status_tooltip",
                  })
                ),
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      this.type === "dot"
        ? [_c("StatusDot", _vm._b({}, "StatusDot", _vm.$attrs, false))]
        : this.type === "unlimited"
        ? [_c("StatusUnlimited")]
        : this.type === "locked"
        ? [_c("StatusLocked")]
        : this.type === "complete"
        ? [_c("StatusComplete")]
        : this.type === "disabled"
        ? [_c("StatusDisabled")]
        : this.type === "unfinished"
        ? [_c("StatusUnfinished")]
        : [_vm._v("\n        🤔\n    ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }