var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "vocabulary-chart", class: { tracking: _vm.tracking } },
    [
      _vm.words_learned
        ? _c("div", { staticClass: "words-learned" }, [
            _vm._v(_vm._s(_vm.words_learned)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("svg", { staticClass: "gradient-def" }, [
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              { attrs: { id: "gradient", x1: "0", x2: "0", y1: "0", y2: "1" } },
              [
                _c("stop", {
                  attrs: {
                    offset: "9.2%",
                    "stop-color": "#E2B4FF",
                    "stop-opacity": "1",
                  },
                }),
                _vm._v(" "),
                _c("stop", {
                  attrs: {
                    offset: "100%",
                    "stop-color": "white",
                    "stop-opacity": "1",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { ref: "chart", staticClass: "ct-chart" }),
      _vm._v(" "),
      _c("vocabulary-slider", {
        attrs: { max_words: _vm.max_words, position: _vm.position },
        on: {
          "update-position": _vm.sliderUpdate,
          "start-tracking": _vm.sliderStartTracking,
          "end-tracking": _vm.sliderEndTracking,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }