<template>
    <div class="lesson-cards-control" :class="{ disabled: lessonCardsLimitReached }">
        <div class="actions" v-if="!lessonCardsLimitReached">
            <div class="check-all">
                <input type="checkbox" class="check-all custom" v-bind:checked="allChecked" @change="checkAllToggle($event)" v-if="lessonHasCards"/>
            </div>
            <div class="delete" v-if="checkedCards && checkedCards.length > 0">
                <div class="action" v-on:click="deleteCheckedCards" :class="{ disabled: this.interactionDisabled }">
                    <remove-icon />
                    <span v-html="this.$i18n('lessons_creator_editor_word_action_delete_selected')" />
                </div>
                <div class="selected-counter">
                    <span v-html="this.$i18n('lessons_creator_editor_items_selected', { args: { 0: checkedCards.length, items: checkedCards.length } })" />
                </div>
            </div>
            <template v-else>
                <div class="word">
                    <div class="action" v-on:click="openAddWordDialog" :class="{ disabled: this.interactionDisabled }">
                        <add-word-icon />
                        <span v-html="this.$i18n('course_wizard_add_new_word_add_btn')" />
                    </div>
                </div>
                <div class="text">
                    <div class="action" v-on:click="openModal" :class="{ disabled: this.interactionDisabled }">
                        <add-text-icon />
                        <span v-html="this.$i18n('lessons_creator_editor_add_text_button')" />
                    </div>
                </div>
                <div class="example" v-if="!lessonHasCards">
                    <div class="action" v-on:click="exampleClicked" :class="{ disabled: this.interactionDisabled }">
                        <example-icon />
                        <span v-html="this.$i18n('lessons_creator_editor_intro_example_button')" />
                    </div>
                </div>
                <div class="auto" v-if="lessonHasCards">
                    <div class="action" v-on:click="expandLesson" :class="{ disabled: this.interactionDisabled }">
                        <add-more-icon />
                        <span v-html="this.$i18n('lessons_creator_editor_button_expand', { args: { words_count: '~20' } })" />
                    </div>
                </div>
                <cards-filter-dropdown v-bind:active-filter="activeFilter" v-bind:cards="this.cards" v-bind:disabled="this.interactionDisabled" />
                <cards-control-more-dropdown v-bind:lesson="this.lesson" v-bind:cards="this.cards" />
                <expand-button v-if="lessonHasCards" @click.native="expandCollapse" v-bind:expanded="expanded" />
            </template>
        </div>
        <div v-if="lessonCardsLimitReached" class="limit-reached-message" v-html="this.$i18n('lesson-size-exceeded', { collection: 'lessons_creator_editor_error' })" />
        <div v-if="addCardDialogOpen" class="add-card-dialog" @mouseleave="closeAddCardDialogDelayed" @mouseenter="clearAddCardDialogClose">
            <div class="add-card-dialog-container">
                <div class="title" v-html="this.$i18n('lessons_creator_editor_add_words_dialog_title')" />
                <div class="subtitle" v-html="this.$i18n('lessons_creator_editor_add_words_dialog_subtitle')" />
                <input type="text" ref="word_input" v-on:keyup.enter="addWordsToLesson" v-on:keyup.esc="closeAddCardDialog" :placeholder="manualPlaceholder" />
                <v-button @click.native="addWordsToLesson" :filled="true" size="medium" :i18n="{ id: 'lessons_creator_editor_add_words_dialog_action' }" />
            </div>
        </div>
    </div>
</template>

<script>
    import ExpandButton from './expand-button.vue';
    import AddWordIcon from 'Images/add-word.svg';
    import AddTextIcon from 'Images/add-text.svg';
    import AddMoreIcon from 'Images/add-more.svg';
    import ExampleIcon from 'Images/sentence-24x24.svg';
    import RemoveIcon from 'Images/remove.svg';
    import i18nUtils from 'Util/i18n';
    import BulkModal from './bulk-modal.vue'
    import { EventBus } from 'Util/vue-event-bus';
    import VButton from 'ViewComponent/v-button.vue';
    import CardsFilterDropdown from "./cards-filter-dropdown.vue";
    import CardsControlMoreDropdown from "./cards-control-more-dropdown.vue";

    export default {
        name: 'lesson-cards-control',
        components: {
            CardsFilterDropdown,
            CardsControlMoreDropdown,
            AddWordIcon,
            AddMoreIcon,
            AddTextIcon,
            ExampleIcon,
            RemoveIcon,
            BulkModal,
            ExpandButton,
            VButton
        },
        props: {
            cards: {
                type: Array,
                default: []
            },
            interactionDisabled: {
                type: Boolean
            },
            expanded: {
                type: Boolean
            },
            allChecked: {
                type: Boolean
            },
            checkedCards: {
                type: Array
            },
            activeFilter: {
                type: String
            },
            lesson: {
                type: Object,
                required: true
            }
        },
        computed: {
            manualPlaceholder() {
                return i18nUtils.prop('lessons_creator_editor_add_words_dialog_input_placeholder')
            },
            lessonHasCards() {
                return (this.cards.length > 0);
            },
            lessonCardsLimitReached() {
                return (this.cards.length === 999);
            }
        },
        data() {
            return {
                addCardDialogOpen: false,
                addCardDialogCloseTimeout: null
            }
        },
        methods: {
            addWordsToLesson() {
                const seed_text = this.$refs.word_input.value;
                if (seed_text.length > 0) {
                    this.$emit('add-cards', { seed_text, intent: 'vocabulary' });
                    this.$refs.word_input.value = "";
                }
            },
            expandLesson() {
                this.$emit('expand-lesson');
                this.closeAddCardDialog();
            },
            openModal() {
                EventBus.$emit('cw:open-bulk-modal');
                this.closeAddCardDialog();
            },
            expandCollapse() {
                EventBus.$emit('cw:expand-toggle');
            },
            checkAllToggle(event) {
                EventBus.$emit('cw:check-all-toggle', event);
            },
            deleteCheckedCards() {
                EventBus.$emit('cw:delete-checked-words');
            },
            openAddWordDialog() {
                clearTimeout(this.addCardDialogCloseTimeout);
                this.addCardDialogOpen = true;
                this.$nextTick(() => {
                    this.$refs.word_input.focus();
                });
            },
            closeAddCardDialog() {
                this.addCardDialogOpen = false;
            },
            closeAddCardDialogDelayed() {
                this.addCardDialogCloseTimeout = setTimeout(() => {
                    this.closeAddCardDialog();
                }, 500);
            },
            clearAddCardDialogClose() {
                clearTimeout(this.addCardDialogCloseTimeout)
            },
            exampleClicked() {
                EventBus.$emit('cw:log-event', { action: 'try-example' });
                EventBus.$emit('cw:show-example');
            },
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.lesson-cards-control {
        position: relative;
        padding: 0 .5rem .5rem;
        box-shadow: 0 2px 4px rgba(236, 236, 236, 0.8);
        &.disabled {
            pointer-events: none;
            opacity: 0.6;
            filter: grayscale(100%);
        }
        > div.actions {
            display: flex;
            flex-direction: row;
            > div.check-all {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 .5rem;
                margin-right: 1rem;
            }
            > div.auto,
            > div.word,
            > div.text,
            > div.example,
            > div.delete {
                margin-right: 1.2rem;
                > div.action {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    transition: background-color 250ms;
                    padding: .5rem .5rem .5rem .25rem;
                    border-radius: pxToRem(8);
                    user-select: none;
                    svg {
                        margin-right: .5rem;
                    }
                    &:hover {
                        cursor: pointer;
                        background-color: $colorBackgroundGraySecondary;
                    }
                    &:active {
                        background-color: $colorBackgroundGrayDarker;
                    }
                    &.disabled {
                        pointer-events: none;
                        opacity: 0.6;
                        filter: grayscale(100%);
                    }
                }
            }
            > div.auto,
            > div.cards-filter-dropdown {
                margin-right: 1.2rem;
            }

            > div.delete {
                flex: 1;
                margin: unset;
                display: flex;
                flex-direction: row;
                > div.selected-counter {
                    margin-left: auto;
                    margin-right: .5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            > div.expand-button {
                margin-left: auto;
            }
        }
        > div.limit-reached-message {
            user-select: none;
            margin: 1rem auto;
            text-align: center;
        }
        > div.add-card-dialog {
            @include elevated;
            background-color: #fff;
            position: absolute;
            top: 2rem;
            left: 2rem;
            padding: 1rem;
            border-radius: .5rem;
            z-index: 100;
            > div.add-card-dialog-container {
                max-width: pxToRem(400);
                min-width: pxToRem(400);
                display: flex;
                flex-direction: column;
                > div.title {
                    font-size: fontSize(18);
                    margin-bottom: .5rem;
                    font-weight: $font-bold;
                    user-select: none;
                }
                > div.subtitle {
                    margin-bottom: .5rem;
                    user-select: none;
                }
                > input {
                    background-color: $colorInputBox;
                    color: $colorSourcePrimary;
                    padding: pxToRem(16);
                    transition: background-color 250ms;

                    &:focus {
                        background-color: $colorInputBoxFocus;
                    }
                }
                > button {
                    margin-top: 1rem;
                    align-self: flex-end;
                }
                &.lesson-has-words {
                    > div.title {
                        display: none;
                    }
                }
            }
        }
    }
</style>
