
import moment from 'moment';


export class BaseExerciseAnswer {

    constructor (course_uuid, info, display_info, assetURL) {
        this._completed = false;

        // Data fields
        this.course_uuid = course_uuid;
        this.exercise_uuid = info.uuid;
        this.exercise_type = info.type;
        this.exercise_category = info.category;
        this.path = assetURL;
        this.display_info = display_info;

        this.shown_ts = moment();
        this.opened_ts = null;
        this.submitted_ts = null;

        this.entry_events = [];

        this.finished = null;
    }

    _assertNotCompleted () {
        if (this._completed) {
            throw Error('Attempted to modify a completed exercise answer');
        }
    }

    complete (finished) {
        this._assertNotCompleted();
        this._completed = true;

        this.finished = finished;
        this.submitted_ts = moment();
    }

    addEntry () {
        this._assertNotCompleted();

        let now = moment();
        if (this.entry_events.length === 0) {
            this.opened_ts = now;
        }
    }

    hasEntries () {
        return this.entry_events.length > 0;
    }

    getData () {
        return {
            course_uuid: this.course_uuid,
            exercise_uuid: this.exercise_uuid,
            exercise_type: this.exercise_type,
            exercise_category: this.exercise_category,
            path: this.path,
            display_info: this.display_info,
            shown_ts: this.shown_ts,
            opened_ts: this.opened_ts,
            submitted_ts: this.submitted_ts,
            finished: this.finished,
            entry_events: this.entry_events
        };
    }
}
