
'use strict';

import Backbone from 'backbone';
import Raven from 'raven-js';

import ControllerManager from '../modules/controller.manager.js';

export class TermsAndConditionsController {

    constructor (router) {
        this._router = router;
    }

    initialize () {
        this._state = {};

        Backbone.on('terms_and_conditions_updated', this.checkTOSAndPP, this);
    }

    async checkTOSAndPP(user, user_terms) {
        if (user_terms.tos_needs_update || user_terms.pp_needs_update) {
            console.log(`Showing terms and conditions updated message`);

            try {
                let view = ControllerManager.instance.getController('ModalMessages').show('updated_terms', {changes_url: user_terms.changes_url});
                await new Promise(resolve => {
                    view.on('close', async callback => {
                        Backbone.trigger('showLoader', 'terms-and-conditions');
                        await user.getApiClient().r.user.terms.put({
                            accepted_tos_version: user_terms.current_tos_version,
                            accepted_pp_version: user_terms.current_pp_version
                        });
                        callback();
                        resolve();
                    });
                });
            } catch (error) {
                Raven.captureException(error, {level: 'error'});
            } finally {
                Backbone.trigger('hideLoader');
            }
        }
    }
}
