var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "card-visual-modal",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "card-visual-modal-window" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c("h2", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("unsplash_change_picture_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "div",
                    { staticClass: "visuals" },
                    _vm._l(this.images, function (image) {
                      return _c("div", {
                        staticClass: "visual",
                        class: { selected: image.selected, empty: image.empty },
                        style: _vm.getStyle(image),
                        on: {
                          click: function ($event) {
                            return _vm.changeChosenVisual(image)
                          },
                        },
                      })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      i18n: { id: "unsplash_change_picture_feedback_label" },
                      size: "medium",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.noPictureToChoose.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }