var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "subs-main" },
    [
      _c("h1", {
        domProps: {
          innerHTML: _vm._s(
            this.$i18n("my_account_subscription_information_title")
          ),
        },
      }),
      _vm._v(" "),
      !_vm.userServices
        ? _c("loader", { attrs: { centered: true } })
        : [
            _c(
              "section",
              { staticClass: "services" },
              [
                !this.currentActiveService
                  ? [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "my_account_subscription_no_subscription_text"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("v-button", {
                        attrs: {
                          i18n: { id: "dashboard_word_list_paywall_title" },
                          filled: true,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goSubscribe.apply(null, arguments)
                          },
                        },
                      }),
                    ]
                  : _vm.currentActiveService
                  ? _c("div", { staticClass: "section active" }, [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.titleToday)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "details" }, [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.subTitle)),
                        ]),
                        _vm._v(" "),
                        this.currentActiveService &&
                        this.currentActiveService.isLifeTimeService
                          ? _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n(
                                    "account_subscription_paid_lifetime"
                                  )
                                ),
                              },
                            })
                          : this.currentActiveService?.is1020campaignService
                          ? _c("p", [
                              _vm._v(
                                _vm._s(this.currentActiveService?.product_name)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentActiveService &&
                _vm.currentActiveService.is_voucher &&
                _vm.currentActiveService.expires_at_text &&
                !this.currentActiveService.isLifeTimeService
                  ? _c("div", { staticClass: "section active" }, [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(_vm.currentActiveService.expires_at_text)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "details" }, [
                        _c("p", {
                          staticClass: "name",
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n(
                                "my_account_subscription_voucher_expired_text"
                              )
                            ),
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.computedService &&
                this.currentActiveService &&
                !this.currentActiveService.isLifeTimeService
                  ? [
                      _vm._l(_vm.computedServices, function (service) {
                        return _c("subs-item-single", {
                          key: _vm.uniqueId("service_"),
                          attrs: { service: service },
                          on: {
                            "cancel-subscription-prompt":
                              _vm.cancelSubscription,
                          },
                        })
                      }),
                      _vm._v(" "),
                      (!_vm.userHas1020campaignService &&
                        _vm.userIsOnActiveVoucherWithoutRecurring) ||
                      _vm.userHasActiveSubscriptionButNotRecurring
                        ? _c("v-button", {
                            attrs: {
                              i18n: { id: "dashboard_word_list_paywall_title" },
                              filled: true,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.goSubscribe.apply(null, arguments)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            !this.userServices.userHasLifetimeService &&
            !_vm.userHas1020campaignService &&
            this.userServices.userHasRecurringService &&
            this.userServices.userCanManageSubscription
              ? _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c("section-action", {
                      attrs: {
                        title: this.$i18n("account_subscription_change"),
                        description: this.$i18n(
                          "account_subscription_change_subtitle"
                        ),
                        "top-border": true,
                      },
                      on: { action: _vm.manageSubscription },
                    }),
                    _vm._v(" "),
                    _c("hr", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("section-action", {
                      attrs: {
                        title: this.$i18n("account_payment_update"),
                        description: this.$i18n(
                          "account_payment_update_subtitle"
                        ),
                        "bottom-border": true,
                      },
                      on: { action: _vm.managePaymentMethod },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }