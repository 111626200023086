var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M14 15c-3.07 1.966-5 5.563-5 9.5 0 4.264 2.357 7.917 5.5 9.5h11c3.143-1.583 5.5-5.236 5.5-9.5 0-3.937-1.93-7.534-5-9.5h-5.5M14 15v-3a4 4 0 014-4h4a4 4 0 014 4v3h-8M17 6a2 2 0 012-2h2a2 2 0 012 2v2h-6V6z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28 24.157c0 2.828-1.343 5.33-3.4 6.843h-9.2c-2.057-1.514-3.4-4.015-3.4-6.843 0-2.282.309-.38 4.727-.38 4.419 0 11.273-4.24 11.273.38z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20 15v12",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }