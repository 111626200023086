var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M4 6l-.707-.707a1 1 0 000 1.414L4 6zm3.707-2.293a1 1 0 00-1.414-1.414l1.414 1.414zm-1.414 6a1 1 0 001.414-1.414L6.293 9.707zM20 18l.707.707a1 1 0 000-1.414L20 18zm-2.293-3.707a1 1 0 00-1.414 1.414l1.414-1.414zm-1.414 6a1 1 0 001.414 1.414l-1.414-1.414zM17 11a1 1 0 102 0h-2zM7 13a1 1 0 10-2 0h2zM4.707 6.707l3-3-1.414-1.414-3 3 1.414 1.414zm-1.414 0l3 3 1.414-1.414-3-3-1.414 1.414zm17.414 10.586l-3-3-1.414 1.414 3 3 1.414-1.414zm-1.414 0l-3 3 1.414 1.414 3-3-1.414-1.414zM11 19h9v-2h-9v2zm8-8a6 6 0 00-6-6v2a4 4 0 014 4h2zm-8 6a4 4 0 01-4-4H5a6 6 0 006 6v-2zM4 7h9V5H4v2z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }