
'use strict';


import _ from 'lodash';
import Raven from 'raven-js';

import UserManager from './usermanager.js';
import Schema from '../util/schema.js';

import { STATUS as GUESS_QUEUE_STATUS, UnknownGuessQueueStatusError } from './guess.queue.js';

import { COURSE_FEATURE } from './course/course.js';

import getConfigValue from '../util/configuration.js';

const MEDIA_BASE_URL = getConfigValue('media-base-url');

class UnableToLoadGrammarHintDataException extends Error {
    constructor (error) {
        super(`Unable to load grammar hint data: ${error.name} - ${error.message}`);
        this.name = 'UnableToLoadGrammarHintDataException';
    }
}


class UnrecognizedLexicalUnitException extends Error {
    constructor (lexicalUnitData) {
        super(`Unrecognized lexical unit schema="${lexicalUnitData.schema}"`);
        this.name = 'UnrecognizedLexicalUnitException';
    }
}

export default class GuessAdapter {

    getGrammarTipPromise (lexicalUnitData, homograph) {
        return Promise.resolve().then(function () {
            if (Schema.isSchemaMinorGE(lexicalUnitData.schema, 'urn:lingvist:schemas:content:lexical_unit', 1, 1)) {
                // We have new style grammar hint keys
                if (homograph.grammar_post_hint_keys.length > 0) {
                    return UserManager.instance.getUser().getCourse().getGrammarTips().getSingle(homograph.grammar_post_hint_keys[0]);
                } else {
                    return Promise.resolve(null);
                }
            } else if (Schema.isSchemaMinorGE(lexicalUnitData.schema, 'urn:lingvist:schemas:content:lexical_unit', 1, 0)) {
                if (homograph.grammar_hint_key !== null) {
                    return Promise.resolve().then(function () {
                        return UserManager.instance.getUser().getCourse().getGrammarTips().getSingleV0(homograph.grammar_hint_key);
                    }).then(function (hint) {
                        return Promise.resolve({ // Map old format to the expected new keys
                            key: hint.key,
                            name: hint.name,
                            title: hint.title,
                            html: hint.hintHTML
                        });
                    });
                } else {
                    return Promise.resolve(null);
                }
            } else {
                Raven.captureException(new UnrecognizedLexicalUnitException(lexicalUnitData), {level: 'error'});
                return Promise.resolve(null);
            }
        }).catch(function (error) {
            Raven.captureException(new UnableToLoadGrammarHintDataException(error),
                {
                    level: 'error',
                    fingerprint: ['UnableToLoadGrammarHintDataException', error.name],
                    extra: {
                        lexical_unit_data: lexicalUnitData,
                        homograph: homograph
                    }
                });
            return Promise.resolve(null);
        });
    }

    getQuestion () {
        const course = UserManager.instance.getUser().getCourse();

        return Promise.resolve()
            .then(() => course.getGuessQueue().getCurrentQuestion())
            .then(async ({status, question}) => {
                if (status === GUESS_QUEUE_STATUS.OK) {
                    this._logQuestion(question);

                    return Promise.resolve({
                        status: status,
                        question: await this._extractQuestionData(question, course)
                    });
                } else if ([GUESS_QUEUE_STATUS.OUT_OF_QUESTIONS].includes(status)) {
                    // Propagate error to GuessView for putting it in the appropriate state
                    return Promise.resolve({status: status});
                } else {
                    return Promise.reject(new UnknownGuessQueueStatusError(status));
                }
            })
            .catch(error => {
                // If getting the data together for displaying the question fails, report this and take the next question
                Raven.captureException(error, {level: 'error'});
                return Promise.resolve()
                    .then(() => {
                        let guessQueue = UserManager.instance.getUser().getCourse().getGuessQueue();
                        return guessQueue.getCurrentQuestionSync() ? guessQueue.removeCurrentQuestion() : Promise.resolve();
                    })
                    .then(() => this.getQuestion())
                    .catch(() => this.getQuestion());
            });
    }

    _logQuestion(question) {
        let pts = question.getParameter('predicted_ts');
        console.debug(`GuessAdapter.sync got current question uuid=${question.UUID} ` +
                      `isNew=${question.isNew()} isFastTracking=${question.isFastTracking()} ` +
                      `isDebug=${question.isDebug()} predicted_ts=${pts ? pts.format() : pts}`);
    }

    async _extractQuestionData(question, course) {
        const [lexicalUnitData, homograph, sense, context,
            additionalSenses, evaluationCriteria, repeatProgress, simpleAlgorithmState] = await Promise
            .all([
                question.getLexicalUnitData(),
                question.getHomograph(),
                question.getSense(),
                question.getContext(),
                question.getAdditionalSenses(),
                question.getEvaluationCriteria(),
                question.getRepeatProgress(),
                Promise.resolve(question.getSimpleAlgorithmState()),
            ]);


        let schemaGE12 =
                Schema.isSchemaMinorGE(lexicalUnitData.schema, 'urn:lingvist:schemas:content:lexical_unit', 1, 2),
            schemaGE18 = Schema.isSchemaMinorGE(lexicalUnitData.schema, 'urn:lingvist:schemas:content:lexical_unit', 1, 8),
            target_language = schemaGE12 ? lexicalUnitData.course.target_language : lexicalUnitData.course.language,
            source_language = schemaGE12 ? lexicalUnitData.course.source_language : lexicalUnitData.course.language_source,
            context_translations;

        if (schemaGE18) {
            context_translations = context.translations_v2;
        } else {
            const _formatLegacyTranslations = contextTranslation => {
                let type = 'unknown';
                switch (_.head(contextTranslation.tags) || '') {
                    case 'RAW':
                        type = 'literal';
                        break;
                    case '':
                        type = 'usage';
                        break;
                }
                return {
                    translation: contextTranslation.translation,
                    type
                };
            };
            context_translations = context.translations.map(_formatLegacyTranslations);
        }

        const _voiceUUID = await course.getVoiceUUID();

        return {
            question: question,
            uuid: question.UUID,
            homograph: homograph,
            sense: sense,
            target_language: target_language,
            source_language: source_language,
            context_translation: context_translations,
            context_audio: ['mp3'].map(ext =>
                `${MEDIA_BASE_URL}/v1/${_voiceUUID}/context/${context.audio_hash}.${ext}`),
            parsedContext: context.parsed.map(parsedContext => ({
                before: parsedContext.begin,
                word: parsedContext.word,
                after: parsedContext.end,
                current: parsedContext.current,
                audio_hash: parsedContext.audio_hash,
                audio_urls: ['mp3'].map(ext =>
                    `${MEDIA_BASE_URL}/v1/${_voiceUUID}/word/${parsedContext.audio_hash}.${ext}`),
                usage: parsedContext.usage
            })),
            equivalent_words: evaluationCriteria.interpreted_criteria.acceptable_answers,
            times_learned: simpleAlgorithmState.answer_count,
            translations: sense.translations.map(translation => {
                return {
                    begin_translation: translation.begin_translation,
                    translation: translation.translation,
                    end_translation: translation.end_translation,
                    // We only check for the RAW tag currently so extract the RAW tag if exists
                    tag: translation.tags !== null ?
                        (translation.tags.indexOf('RAW') > -1 ? 'RAW' : translation.tags[0]) : null,
                    id: translation.uuid
                };
            }),
            translations_comments: sense.translations.map(translation => ({
                comments: translation.comments !== null ? translation.comments.map(comment => {
                    return {
                        comment: comment.comment,
                        tag: comment.tags !== null ? comment.tags[0] : null
                    };
                }) : []
            })).filter(tc => tc.comments.length > 0),
            grammar: homograph.grammar,
            parsed_grammar: homograph.parsed_grammar !== undefined ? homograph.parsed_grammar : null,
            audio: ['mp3'].map(ext =>
                `${MEDIA_BASE_URL}/v1/${_voiceUUID}/word/${sense.audio_hash}.${ext}`),
            word: homograph.form,
            context_audio_enabled: context.audio_enabled,
            context: context.context,
            isPlacementTestQuestion: question.isFastTracking(),
            isNewWord: question.isNew(),
            grammarTipPromise: (await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.GRAMMAR_HINTS_IN_GUESS)) ? this.getGrammarTipPromise(lexicalUnitData, homograph) : Promise.resolve(null),
            grammarTablePromise: (await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.GRAMMAR_TABLES)) ? question.getGrammarTable() : Promise.resolve(null),
            meanings: (await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.ADDITIONAL_MEANINGS)) ? additionalSenses : Promise.resolve(null),
            senseContexts: sense.contexts,
            repeatProgress,
            variation_uuid: question.getParameter('variation_uuid'),
            visual: question.getParameter('visual'),
            on_previous: false
        };
    }
}
