
import { BaseExercise } from './base.exercise.js';
import { SpeakingExerciseAnswer } from './answer/speaking.js';


export class SpeakingExercise extends BaseExercise {
    update (exercise_data) {
        super.update(exercise_data);
    }

    getData () {
        return Promise.resolve()
            .then(() => super.getData())
            .then(data => {

                data.intro = this._data.content.intro[this._display_info.intro];
                data.summary = {
                    title: this._data.content.summary.title[this._display_info.summary],
                    content: this._data.content.summary.content[this._display_info.summary],
                    illustration: ''
                };
                data.dialog = this._data.content.dialog;

                return Promise.resolve(data);
            });
    }

    getExerciseAnswer () {
        return new SpeakingExerciseAnswer(
            this._course.UUID,
            this.getInfo(),
            this._display_info,
            this._getAssetURL()
        );
    }

}
