<template>
    <div class="wordlist-filters">
        <v-button v-if="this.inverted" :i18n="{ id: 'dashboard_word_list_sorting_menu_target_language', params: { tl: course.info.target_language } }" @click.native="toggleLanguage" :filled="true" color="purple-primary" size="medium" />
        <v-button v-else :i18n="{ id: 'dashboard_word_list_sorting_menu_source_language', params: { sl: course.info.source_language } }" @click.native="toggleLanguage" :filled="true" color="gray" size="medium" />
        <div class="separator" />
        <v-button :html="mutedButtonLabel" @click.native="toggleMuted" :filled="true" :color="(this.showMuted) ? 'purple-primary' : 'gray'" size="medium" />
        <v-button :html="favouriteButtonLabel" @click.native="toggleFavourites" :filled="true" :color="(this.showFavourites) ? 'purple-primary' : 'gray'" size="medium" />
        <v-button :html="playlistButtonLabel" @click.native="togglePlaylist" :filled="true" :color="(this.showInPlaylist) ? 'purple-primary' : 'gray'" size="medium" />
        <div class="separator" />
        <v-dropdown v-on:action="sortByAction" :title="sortByDropdownTitle" :activeItem="sortBy" button-class="gray" size="medium"
                    :dropdown="sortDropdownItems" />
    </div>
</template>

<script>
    import VButton from "ViewComponent/v-button.vue";
    import VDropdown from "ViewComponent/v-dropdown.vue";
    import i18nUtils from "Util/i18n.js";

    export default {
        name: 'wordlist-filters',
        components: { VButton, VDropdown },
        props: {
            sortBy: {
                type: String,
                default: 'form-ascending'
            },
            course: {
                type: Object,
                required: true
            },
            filterCounters: {
                type: Object,
                required: true
            },
            inverted: {
                type: Boolean
            },
            showMuted: {
                type: Boolean
            },
            showInPlaylist: {
                type: Boolean
            },
            showFavourites: {
                type: Boolean
            },
        },
        computed: {
            mutedButtonLabel () {
                if (this.filterCounters.mutedWordsCount > 0) {
                    return `${i18nUtils.prop('dashboard_word_list_sorting_menu_muted')} <em>${this.filterCounters.mutedWordsCount}</em>`;
                } else {
                    return i18nUtils.prop('dashboard_word_list_sorting_menu_muted');
                }
            },
            playlistButtonLabel () {
                if (this.filterCounters.playlistWordsCount > 0) {
                    return `${i18nUtils.prop('dashboard_word_list_sorting_menu_playlist')} <em>${this.filterCounters.playlistWordsCount}</em>`;
                } else {
                    return i18nUtils.prop('dashboard_word_list_sorting_menu_playlist');
                }
            },
            favouriteButtonLabel () {
                if (this.filterCounters.favouriteWordsCount > 0) {
                    return `${i18nUtils.prop('dashboard_word_list_sorting_menu_favourites')} <em>${this.filterCounters.favouriteWordsCount}</em>`;
                } else {
                    return i18nUtils.prop('dashboard_word_list_sorting_menu_favourites');
                }
            },
            sortByDropdownTitle () {
                const _prefix = i18nUtils.prop('dashboard_word_list_sorting_menu_sort_title');
                let _suffix = '';
                switch (this.sortBy) {
                    case 'form-ascending':
                    case 'form-descending':
                        _suffix = i18nUtils.prop('dashboard_word_list_sorting_menu_alphabetic');
                        break;
                    case 'last-practiced-ascending':
                    case 'last-practiced-descending':
                        _suffix = i18nUtils.prop('dashboard_word_list_sorting_menu_last_seen');
                        break;
                    case 'times-practiced-ascending':
                    case 'times-practiced-descending':
                        _suffix = i18nUtils.prop('dashboard_word_list_sorting_menu_times_practiced');
                        break;
                }
                return `${ _prefix }: ${ _suffix }`;
            },
            sortDropdownItems() {
                return [
                    {
                        action: (this.sortBy === 'form-descending') ? 'form-ascending' : 'form-descending',
                        iconName: (this.sortBy === 'form-descending') ? 'ascending' : 'descending',
                        i18n: { id: 'dashboard_word_list_sorting_menu_alphabetic' }
                    },
                    {
                        action: (this.sortBy === 'last-practiced-descending') ? 'last-practiced-ascending' : 'last-practiced-descending',
                        iconName: (this.sortBy === 'last-practiced-descending') ? 'ascending' : 'descending',
                        i18n: { id: 'dashboard_word_list_sorting_menu_last_seen' }
                    },
                    {
                        action: (this.sortBy === 'times-practiced-descending') ? 'times-practiced-ascending' : 'times-practiced-descending',
                        iconName: (this.sortBy === 'times-practiced-descending') ? 'ascending' : 'descending',
                        i18n: { id: 'dashboard_word_list_sorting_menu_times_practiced' }
                    }
                ]
            }
        },
        methods: {
            toggleLanguage() {
                this.$emit('toggle-language');
            },
            toggleMuted() {
                this.$emit('toggle-muted');
            },
            togglePlaylist() {
                this.$emit('toggle-in-playlist');
            },
            toggleFavourites() {
                this.$emit('toggle-favourites');
            },
            sortByAction(_sortBy) {
                this.$emit('sort-by', _sortBy);
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.wordlist-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: .5rem;
        > div.separator {
            border-left: 1px solid $colorBackgroundLighter;
            display: block;
            content: ' ';
        }
        > button.dropdown-component {
            min-width: 12rem;
            > span.dropdown-button {
                justify-content: center;
                min-width: 12rem;
            }
        }
    }
</style>
