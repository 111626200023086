var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9.7 13.524a3.6 3.6 0 11.824-5.024l-.024 1L10 13l-.3.524z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 1.5V2M13.5 5h-3M15.5 12h-7M16.5 16h-9M11.5 5L10 12l-1 4-3 5M12.5 5l1.5 7 1 4 3 5M4 21h5.5M20 21h-4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }