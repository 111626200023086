<template>
    <div class="have-a-voucher">
        <voucher-illustration />
        <div class="text" v-html="this.$i18n('subscription_page_voucher_title')" />
        <v-button :i18n="{ id: 'subscription_page_voucher_btn' }" @click.native="navigateToVoucherPage" :filled="true" color="primary" />
    </div>
</template>

<script>
    import VoucherIllustration from 'Images/pay/voucher-illustration.svg';
    import VButton from "ViewComponent/v-button.vue";

    export default {
        name: 'have-a-voucher',
        components: {
            VButton,
            VoucherIllustration,
        },
        methods: {
            navigateToVoucherPage() {
                this.$emit('navigate', 'voucher');
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';

    div.have-a-voucher {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        max-width: 40rem;
        margin: 2rem auto;
        border-radius: .5rem;
        background-color: #fff;
        padding: 1.5rem;
        > div.text {
            font-weight: $font-bold;
        }
        > button {
            margin-left: auto;
        }
        @include respond-to('medium') {
            max-width: 44rem;
        }
        @include respond-to('small') {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            margin: 0;
            width: 100%;
            > button {
                margin-left: unset;
                width: 100%;
            }
        }
    }
</style>
