<template>
    <div class="language-selection" v-if="this.languageList && this.languageList.available">
        <div class="current" @mouseover="openDropdown">
            <world-icon />
            <span class="label">{{ currentLanguageLabel }}</span>
        </div>
        <transition name="fade">
            <span class="dropdown-content" v-if="dropdownOpen" @mouseenter="openDropdown" @mouseleave="closeDropdownDelayed">
                <span class="dropdown-item" v-for="code in languageList.available" :class="{ active: code === languageList.current }"
                      @click="selectLanguage(code)">
                    <span class="text">{{ getLanguageName(code) }}</span>
                </span>
            </span>
        </transition>
    </div>
</template>

<script>
    import WorldIcon from "Images/world-icon.svg";
    import VDropdown from "ViewComponent/v-dropdown.vue";
    import iconAscending from "Images/ic_ascending.svg";
    import iconDescending from "Images/ic_descending.svg";
    import i18nUtils from "Util/i18n.js";
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'language-selection',
        components: {
            iconDescending, iconAscending,
            VDropdown,
            WorldIcon
        },
        data() {
            return {
                dropdownOpen: false,
                dropdownCloseTimeout: null,
                languageList: null
            }
        },
        computed: {
            currentLanguageLabel() {
                if (this.languageList) {
                    let code = this.languageList.current.toLowerCase();
                    return i18nUtils.prop(code, null, null,'language_name_original');
                } else {
                    return '…';
                }
            }
        },
        methods: {
            selectLanguage(code) {
                i18nUtils.setInterfaceLanguage(code);
                this.closeDropdown();
                this.languageList.current = code.toLowerCase();
            },
            setLanguageList() {
                this.languageList = i18nUtils.getInterfaceLanguages();
            },
            getLanguageName(code) {
                return i18nUtils.prop(code, null, null,'language_name_original')
            },
            openDropdown() {
                clearTimeout(this.dropdownCloseTimeout);
                this.dropdownOpen = true;
            },
            closeDropdown() {
                this.dropdownOpen = false;
            },
            closeDropdownDelayed() {
                this.dropdownCloseTimeout = setTimeout(() => {
                    this.closeDropdown();
                }, 250);
            },
            listenEventBus() {
                EventBus.$on('interfaceLanguageChanged', this.setLanguageList);
            },
            unListenEventBus() {
                EventBus.$off('interfaceLanguageChanged', this.setLanguageList);
            }
        },
        created() {
            this.setLanguageList();
            this.listenEventBus();
        },
        beforeDestroy() {
            this.unListenEventBus();
        }
    }
</script>

<style lang="scss" scoped>
    @import '~Styles/mixins';
    @import "~Styles/colors";
    div.language-selection {
        user-select: none;
        > div.current {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: $colorBackgroundGray;
            gap: .5rem;
            border-radius: .5rem;
            padding: 0.75rem;
            transition: background-color 300ms;
            > span.label {
                font-size: fontSize(14);
                font-weight: $font-bolder;
                color: $colorSourceSecondary;
            }
            &:hover {
                cursor: pointer;
                background-color: rgba($colorBackgroundGray, 0.5)
            }
        }
        > span.dropdown-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            min-width: pxToRem(220);
            right: 0;
            top: pxToRem(35);
            @include elevated;
            gap: .5rem;
            font-size: fontSize(16);
            background-color: #fff;
            z-index: 500;
            color: $colorSourcePrimary;
            border-radius: pxToRem(8);
            text-align: left;
            padding: .5rem;
            body[data-interface-language="ar"] & {
                right: unset;
                left: 0;
            }
            > span.dropdown-item {
                padding: pxToRem(8) pxToRem(16);
                display: flex;
                align-items: center;
                border-radius: pxToRem(4);
                gap: .5rem;
                > svg {
                    &:last-child {
                        margin-left: auto;
                        body[data-interface-language="ar"] & {
                            margin-right: auto;
                            margin-left: unset;
                        }
                    }
                }
                &:first-child {
                    border-top: none;
                }
                &.active,
                &:hover {
                    opacity: .8;
                    cursor: pointer;
                    background-color: $colorBackgroundLighter;
                }
            }
        }
        &.disabled {
            color: $colorSourceTertiary;
            border-color: $colorSourceTertiary;
            background-color: transparent;
            pointer-events: none;
            cursor: default;
        }
        &.fade-enter-active, &.fade-leave-active {
            transition: opacity 250ms;
        }
        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }
        &[data-size='medium'] {
            border-radius: pxToRem(8);
            font-size: fontSize(16);
            > span.dropdown-button {
                padding: pxToRem(8);
            }
        }
    }
</style>
