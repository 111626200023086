var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2v11c2.115 0 4.296 1.06 5.121 3.01a1 1 0 01-1.842.78C14.855 15.788 13.564 15 12 15v7zm3.5-11.25a1.249 1.249 0 100-2.5 1.251 1.251 0 000 2.5z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 21a9 9 0 010-18",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M9.75 9.5a1.249 1.249 0 11-2.5 0 1.251 1.251 0 012.5 0z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.72 14.01a1 1 0 10-1.841.78l1.842-.78zm-1.841.78C7.675 16.672 9.799 18 12 18v-2c-1.478 0-2.827-.92-3.28-1.99l-1.841.78z",
          fill: "#8EA3EE",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }