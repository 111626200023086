<template>
    <section class="select-target-language">
        <languages
            :languages="target_languages"
            :heading="this.$i18n('register_select_course_target_heading')"/>

        <languages
            :languages="source_languages"
            :heading="this.$i18n('register_select_course_source_heading')"
            :show_language_not_listed="true"
            @language_not_listed="language_not_listed"

            @language_selected="language_selected" />
    </section>
</template>

<script>

import Languages, { Language } from './languages.vue'
import ControllerManager from '../../../modules/controller.manager.js';


export default {
    name: 'register.select-course',
    components: {
        Languages
    },
    props: {
        courses: {
            type: Array,
            required: true
        },
        selected_target_language: {
            type: Language,
            required: true
        }
    },
    computed: {
        target_languages () {
            // Basically clone the target language without the attributes
            return [new Language(this.selected_target_language.code, this.selected_target_language.icon_id, true)];
        },
        source_languages () {
            return this.courses
                .filter(c => {
                    // Special mapping for unified en language
                    if (this.selected_target_language.code === 'en') {
                        return c.info.target_language === 'en-US' || c.info.target_language === 'en-GB';
                    } else {
                        return c.info.target_language === this.selected_target_language.code;
                    }
                })
                .map(course => {
                    const {source_language, source_icon_id} = course.getInfo();

                    return new Language(
                        source_language, source_icon_id, false,
                        {
                            course_uuid: course.UUID,
                            short: course.isShort(),
                            new: course.isNew(),
                            course: course
                        }
                    )
                });
        }
    },
    methods: {
        async language_selected (language) {
            if (language.attrs.short && !language.attrs.short_popup_shown) {
                language.attrs.short_popup_shown = true;
                const popupView = await ControllerManager.instance.getController('ModalMessages')
                    .showShortCourse(language.attrs.course.info.name, language.attrs.course.info.words);

                popupView.on('_custom', () => {
                    this.$emit('selected', language.attrs.course_uuid);
                });
            } else {
                this.$emit('selected', language.attrs.course_uuid);
            }
        },
        language_not_listed(language) {
            this.$emit('language_not_listed', language);
        }
    }
};
</script>

<style scoped lang="scss">
    section.select-target-language {
        flex: 1 0 auto;
    }
</style>
