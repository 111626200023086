var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.5 14c-2 0-2 3 0 3s2-3 0-3zM8 15.5c.1-4.6 6.9-4.6 7 0-.1 4.6-6.9 4.6-7 0z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5 7C4.9-.1 15.2-3 17.9 4c.3.6.1 1.2-.5 1.5-.6.2-1.2-.2-1.3-.8C14.1-.2 6.8 2.2 7 7v3H4c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1h15c.6 0 1-.4 1-1v-9c0-.6-.4-1-1-1h-7.5c-.6 0-1-.4-1-1s.4-1 1-1H19c1.7 0 3 1.3 3 3v9c0 1.7-1.3 3-3 3H4c-1.7 0-3-1.3-3-3v-9c0-1.7 1.3-3 3-3h1V7z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }