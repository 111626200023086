var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tutorial-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tutorial-modal-window" },
        [
          _c("CloseButton", { staticClass: "close", on: { click: _vm.close } }),
          _vm._v(" "),
          _vm.video_id
            ? _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.loading
                    ? _c("loader", { attrs: { centered: true } })
                    : _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("lesson_creator_editor_video_title")
                          ),
                        },
                      }),
                  _vm._v(" "),
                  _c("vimeo-player", {
                    ref: "player",
                    attrs: {
                      "video-id": _vm.video_id,
                      options: _vm.options,
                      "player-width": _vm.playerWidth,
                      "player-height": 420,
                      autoplay: true,
                    },
                    on: { ready: _vm.onReady },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }