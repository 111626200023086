var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "japanese-katakana-story" }, [
    _vm.activeIndex === 0
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_1_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_1_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "257",
                viewBox: "0 0 272 257",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("circle", {
                attrs: { cx: "136", cy: "128.5", r: "120", fill: "#8EA3EE" },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M68 114.5C68 122.232 61.732 128.5 54 128.5C46.268 128.5 40 122.232 40 114.5C40 106.768 46.268 100.5 54 100.5C61.732 100.5 68 106.768 68 114.5Z",
                  fill: "#63E1E7",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M10 116.288C14.8 116.288 25.6 114.82 30.4 108.947C21.2 120.774 6.4 150.546 26.8 159.111C43.9561 166.313 63.516 144.961 53.582 107.882M53.582 107.882C53.4592 107.424 53.3319 106.963 53.2 106.5C53.3059 106.932 53.4335 107.395 53.582 107.882ZM53.582 107.882C55.6775 114.767 61.9319 126.695 70 128.523",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: { cx: "232", cy: "120.5", r: "20", fill: "#63E1E7" },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M232 106.666V162.999",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M206 124.5H258",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M232 124.5C240.125 138.711 247.6 146.921 258 154.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M232 124.5C223.875 138.711 216.4 146.921 206 154.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M164 114.5C164 122.232 157.732 128.5 150 128.5C142.268 128.5 136 122.232 136 114.5C136 106.768 142.268 100.5 150 100.5C157.732 100.5 164 106.768 164 114.5Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M108 118.5L152 118.5V150.856C152 155.833 149.486 158.322 145.714 159.567C141.943 160.811 136.914 160.811 134.4 159.567",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M124 104.5V130.239C124 146.63 121.333 156.717 112 160.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 1
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_2_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_2_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  x: "16",
                  y: "16",
                  width: "104",
                  height: "224",
                  rx: "16",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M96 91C96 98.732 89.732 105 82 105C74.268 105 68 98.732 68 91C68 83.268 74.268 77 82 77C89.732 77 96 83.268 96 91Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M38 92.788C42.8 92.788 53.6 91.3198 58.4 85.447C49.2 97.2742 34.4 127.046 54.8 135.611C71.9561 142.813 91.516 121.461 81.582 84.3825M81.582 84.3825C81.4592 83.924 81.3319 83.4632 81.2 83C81.3059 83.4319 81.4335 83.8945 81.582 84.3825ZM81.582 84.3825C83.6775 91.2669 89.9319 103.195 98 105.023",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M31 166C32.1667 166 35.5 165.8 37.5 165C32.5 168.5 29.3224 177.581 35 179C41.1123 180.528 43.5 173 42 164.5C42.5 167 43.5 170 46 173",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M53.5 167.5C53.5 168.5 53.3 171.3 52.5 174.5C53.3333 173.333 56 171 60 171C65 171 65.2227 175.466 61 178C58.5 179.5 55 179 55 179M55 164C56.1667 164.5 59.2 165.5 62 165.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M75.5 164.5C75.3333 166.5 74.2 172.1 71 178.5M70 168.5C71.8333 168.333 75.8 168 77 168C78.5 168 80.5 168.5 80.5 171C80.5 173.5 80 177.5 78.5 178.5C77.3 179.3 75.6667 178.833 75 178.5M83 166.5C83.6667 167.333 85.2 169.7 86 172.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M84 163C84.5 163.4 85 164.2 85 165M86 162C86.5 162.4 87 163.2 87 164",
                  stroke: "#2C3143",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M96 164C95.3333 166.333 93.4 171.8 91 175",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M90.5 167C92 167.167 95.8 167.3 99 166.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M102 167C103 167.5 104.3 168.2 105.5 169",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M101 170C101 171.833 101 175.8 101 177C101 178.5 100 179.5 98 179.5C96 179.5 95 178.5 95 177C95 175 99 172.5 105 177.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "152",
                  y: "16",
                  width: "104",
                  height: "224",
                  rx: "16",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M232.5 91C232.5 98.732 226.232 105 218.5 105C210.768 105 204.5 98.732 204.5 91C204.5 83.268 210.768 77 218.5 77C226.232 77 232.5 83.268 232.5 91Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M176.5 94.5L220.5 94.5V127.356C220.5 132.333 217.986 134.822 214.214 136.067C210.443 137.311 205.414 137.311 202.9 136.067",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M192.5 81V106.739C192.5 123.13 189.833 133.217 180.5 137",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M167 168H180C180 171 179.7 177.2 178.5 178C177.3 178.8 175.667 178.167 175 178M173 164C173.333 167 172.7 174.1 167.5 178.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M207 168H220C220 171 219.7 177.2 218.5 178C217.3 178.8 215.667 178.167 215 178M213 164C213.333 167 212.7 174.1 207.5 178.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M187 172C188.333 171.333 191.5 168.8 193.5 164M192 167H200.5C199 170.5 196 175.5 188 180M192.5 171.5C195 172.5 195.6 173 198 175",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M226 169H241.5M234 164.5V170.5C234 173 232.5 177 229 179",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 2
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_3_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_3_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  x: "16",
                  y: "16",
                  width: "104",
                  height: "198",
                  rx: "16",
                  fill: "white",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M83.3663 160.657C81.6218 161.524 77.3865 163.402 74.5 164M74.5 158V167C74.5 169.5 75.0219 170.121 77.3049 170.3C79.5879 170.478 81.1295 170.454 84.2532 169.96",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M54.5 164H66.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M90.5 164H102.5",
                  stroke: "#2C3143",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "bevel",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M38 159C37.4477 159 37 159.448 37 160C37 160.552 37.4477 161 38 161V159ZM48.5 160H49.5V159H48.5V160ZM47.5 171C47.5 171.552 47.9477 172 48.5 172C49.0523 172 49.5 171.552 49.5 171H47.5ZM48.5 171C49.0523 171 49.5 170.552 49.5 170C49.5 169.448 49.0523 169 48.5 169V171ZM38 169C37.4477 169 37 169.448 37 170C37 170.552 37.4477 171 38 171V169ZM38 161H48.5V159H38V161ZM47.5 160V171H49.5V160H47.5ZM48.5 169H38V171H48.5V169Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M68 79.5H64H47C44.7909 79.5 43 81.2909 43 83.5V90V96.5C43 105.889 50.6112 113.5 60 113.5H68C77.3888 113.5 85 105.889 85 96.5V83.5C85 81.2909 83.2091 79.5 81 79.5H77",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M50.1001 96.5C50.1001 102.023 54.5773 106.5 60.1001 106.5H68.1001",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M85 85.5H92C94.2091 85.5 96 87.2909 96 89.5C96 93.366 92.866 96.5 89 96.5H85V85.5Z",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M52.5 58L53.681 59.9683C54.75 61.7501 54.4692 64.0307 53 65.5C51.5307 66.9693 51.2499 69.2499 52.319 71.0317L53.5 73M60.7 55L61.4561 56.5403C62.0921 57.8357 61.9138 59.3831 61 60.5C60.0861 61.6169 59.9079 63.1643 60.5438 64.4597L61.3 66",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M87.467 110.524C88.5286 109.092 88.2277 107.07 86.7951 106.008C81.3544 101.977 71.6724 106.645 66.9185 113.061C62.1645 119.477 60.5173 130.098 65.958 134.13C67.3906 135.191 69.4125 134.89 70.474 133.458L87.467 110.524Z",
                  fill: "#AC6ED1",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M89.8775 112.311C90.939 110.878 92.9609 110.577 94.3935 111.639C99.8342 115.67 98.1869 126.292 93.433 132.707C88.679 139.123 78.9971 143.791 73.5564 139.76C72.1238 138.699 71.8229 136.677 72.8845 135.244L89.8775 112.311Z",
                  fill: "#E2B4FF",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "152",
                  y: "42",
                  width: "104",
                  height: "198",
                  rx: "16",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M197.5 136.6V129.6C195.537 129.6 194 128.137 194 126.167C194 124.197 195.537 122.6 197.5 122.6H211.5C213.463 122.6 215 124.197 215 126.167C215 128.137 213.463 129.6 211.5 129.6V152.6L226.476 177.793C229.319 182.549 225.906 188.6 220.38 188.6H188.62C183.094 188.6 179.681 182.549 182.524 177.793L197.5 152.6V145.6",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M194.578 166.199L186.596 179.503C185.885 180.687 186.738 182.194 188.12 182.194H219.88C221.261 182.194 222.115 180.687 221.404 179.503L213.422 166.199H194.578Z",
                  fill: "#9DF4F4",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: {
                  cx: "197",
                  cy: "108",
                  r: "5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: {
                  cx: "211.5",
                  cy: "92.5",
                  r: "3.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 3
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_4_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_4_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "256",
                viewBox: "0 0 272 256",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M33.3821 121.027H19V137.285V164.799H40.8859L69.0248 192V163.09L55.268 149.792H33.3821V122.278V121.027Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: { cx: "33.5", cy: "124.5", r: "4.5", fill: "#2C3143" },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M33.5 105.5V104H32V105.5H33.5ZM33.5 148C32.6716 148 32 148.672 32 149.5C32 150.328 32.6716 151 33.5 151V148ZM83.5 176.5L82.4684 177.589C82.9035 178.001 83.5421 178.115 84.0927 177.878C84.6432 177.641 85 177.099 85 176.5H83.5ZM83.5 78.5H85C85 77.8983 84.6404 77.3548 84.0866 77.1195C83.5328 76.8841 82.8919 77.0025 82.4588 77.4202L83.5 78.5ZM55.5 105.5V107C55.8884 107 56.2616 106.849 56.5412 106.58L55.5 105.5ZM55 149.5L56.0316 148.411C55.753 148.147 55.3838 148 55 148V149.5ZM32 122.5C32 123.328 32.6716 124 33.5 124C34.3284 124 35 123.328 35 122.5H32ZM82.4588 77.4202L54.4588 104.42L56.5412 106.58L84.5412 79.5798L82.4588 77.4202ZM55.5 104H33.5V107H55.5V104ZM53.9684 150.589L82.4684 177.589L84.5316 175.411L56.0316 148.411L53.9684 150.589ZM32 105.5V122.5H35V105.5H32ZM33.5 151H55V148H33.5V151ZM85 176.5V78.5H82V176.5H85Z",
                  fill: "#2C3143",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: {
                  cx: "189.084",
                  cy: "138.537",
                  r: "22.5112",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M103.886 111.334C107.335 115.987 109.357 121.645 109.357 127.748C109.357 133.851 107.335 139.51 103.886 144.163",
                  stroke: "#AC6ED1",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M120.3 94.9199C127.138 103.85 131.243 115.284 131.243 127.749C131.243 140.213 127.138 151.648 120.3 160.577",
                  stroke: "#AC6ED1",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M184.436 106.589C189.752 107.953 203.574 109.318 216.332 103.861M199.055 99.7676C195.068 109.318 193.739 122.415 199.055 142.061M207.029 144.79C210.13 144.79 217.129 142.88 220.319 135.24C223.738 127.049 220.315 119.862 210.05 117.982M210.05 117.982C208.346 117.67 206.453 117.504 204.371 117.504C189.752 117.504 181.147 129.917 183.108 137.968C185.329 147.09 202.705 140.032 210.05 117.982ZM210.05 117.982C210.395 116.946 210.718 115.877 211.016 114.775",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M216.598 85.8545L224.201 66",
                  stroke: "#AC6ED1",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M230.115 89.0224L248.093 71.8016",
                  stroke: "#AC6ED1",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M237.272 101.806L252.479 93.5682",
                  stroke: "#AC6ED1",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                },
              }),
            ]
          ),
        ])
      : _vm.activeIndex === 4
      ? _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bubble" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_5_title")),
              },
            }),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("stories_katakana_5_text")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "272",
                height: "257",
                viewBox: "0 0 272 257",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M136 108L90 188H203.099C211.893 188 217.7 178.853 213.959 170.895L194.5 129.5L185 120.5L175.5 129.5L154 109L144 118L136 108Z",
                  fill: "#8EA3EE",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M246.5 188L215.5 126M198.5 92L188.317 71.6334C186.284 67.568 182.129 65 177.584 65H167.453C163.153 65 159.181 67.3015 157.042 71.0327L152.189 79.5M30 188H90L147.03 88.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: { cx: "29.5", cy: "188", r: "3.5", fill: "#2C3143" },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M136 108.5L144 118.5L154 108.5L175.5 130L185 120.5L194.5 130L200.187 124.313",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M123 130.374C122.21 130.131 121.37 130 120.5 130H89.5C84.8056 130 81 133.806 81 138.5C81 143.194 84.8056 147 89.5 147H113.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "181",
                  y: "92",
                  width: "48",
                  height: "17",
                  rx: "8.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "197",
                  y: "109",
                  width: "48",
                  height: "17",
                  rx: "8.5",
                  stroke: "#2C3143",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("v-button", {
                attrs: {
                  i18n: { id: "onboarding_experience_start_btn" },
                  filled: true,
                  color: "primary",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.startLearning.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }