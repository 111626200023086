
import moment from 'moment';

import { BaseExerciseAnswer } from './base.js';

export const ENTRY_TYPE = {
    SELECT: 'select'
};

export class MultipleChoiceExerciseAnswer extends BaseExerciseAnswer {
    constructor (course_uuid, info, display_info, assetURL, answer_order) {
        super(course_uuid, info, display_info, assetURL);
        this.answer_order = answer_order;
    }

    /**
     *
     * @param type
     * @param {{option: string, correct: boolean}} data: Data about the entry
     */
    addEntry (type, data) {
        super.addEntry();

        switch (type) {
            case ENTRY_TYPE.SELECT:
                this.entry_events.push({
                    type: type,
                    ts: moment(),
                    option: data.option,
                    correct: data.correct
                });
                break;
            default:
                throw Error(`Unrecognized exercise answer entry type="${type}"`);
        }
    }

    getData () {
        let data = super.getData();

        data.answer_order = this.answer_order;

        return data;
    }

}
