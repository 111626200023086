var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#2C3143",
          d: "M1.711 2.007l9.407.9c.328.031.632.167.885.374.251-.205.554-.339.88-.37l9.406-.9C23.221 1.922 24 2.686 24 3.706v15.511c0 .962-.709 1.792-1.588 1.876l-9.406.9a1.488 1.488 0 01-1.009-.267 1.49 1.49 0 01-1.003.263l-9.406-.9C.708 21.005 0 20.175 0 19.213V3.703c0-1.021.779-1.785 1.711-1.696zm20.701 1.536l-9.406.9c-.155.015-.3.184-.3.344v15.51c0 .113.064.175.176.164l9.407-.9c.155-.015.3-.184.3-.344V3.707c0-.112-.064-.174-.177-.164zM1.584 3.54c-.112-.01-.175.051-.175.163v15.511c0 .16.144.329.299.344l9.406.9c.113.01.176-.052.176-.163V4.783c0-.16-.144-.33-.3-.344l-9.406-.9zm1.979 11.343l.095.002 5.647.513c.389.035.677.407.645.83-.032.423-.374.738-.762.703l-5.647-.513c-.388-.035-.677-.407-.645-.83.033-.424.374-.738.762-.703zm16.847.002c.388-.035.73.28.762.703.032.423-.256.795-.645.83l-5.646.513c-.389.035-.73-.28-.762-.703-.033-.423.256-.795.644-.83zm-16.847-4.07l.095.002 5.647.512c.389.036.677.407.645.83-.032.424-.374.739-.762.703l-5.647-.512c-.388-.036-.677-.407-.645-.83.033-.424.374-.739.762-.703zm16.847.002c.388-.036.73.279.762.702.032.424-.256.795-.645.83l-5.646.513c-.389.036-.73-.279-.762-.702-.033-.424.256-.795.644-.83zM3.563 6.747l.095.002 5.647.513c.389.035.677.407.645.83-.032.424-.374.738-.762.703l-5.647-.513c-.388-.035-.677-.407-.645-.83.033-.423.374-.738.762-.703zm16.847.002c.388-.035.73.28.762.703.032.423-.256.795-.645.83l-5.646.513c-.389.035-.73-.28-.762-.703-.033-.423.256-.795.644-.83z",
          "fill-rule": "evenodd",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }