<template>
    <div class='course-icon' v-bind:data-size="size">
        <component v-bind:is="icon"/>
    </div>
</template>

<script>
import IntegrateCountry from 'Images/icons/demographic-icons/integrate-country.svg';
import SetGoal from 'Images/icons/demographic-icons/set-goal.svg';
import BrainTraining from 'Images/icons/demographic-icons/brain-training.svg';
import EnjoyLearning from 'Images/icons/demographic-icons/enjoy-learning.svg';
import FriendsFamily from 'Images/icons/demographic-icons/friends-family.svg';
import LoveCountry from 'Images/icons/demographic-icons/love-country.svg';
import Career from 'Images/icons/demographic-icons/career.svg';
import PassExam from 'Images/icons/demographic-icons/pass-exam.svg';
import UpcomingTrip from 'Images/icons/demographic-icons/upcoming-trip.svg';
import DemographicOther from 'Images/icons/demographic-icons/demographic-other.svg';
import Age18 from 'Images/icons/demographic-icons/age-18.svg';
import Age1824 from 'Images/icons/demographic-icons/age-18-24.svg';
import Age2534 from 'Images/icons/demographic-icons/age-25-34.svg';
import Age3544 from 'Images/icons/demographic-icons/age-35-44.svg';
import Age4554 from 'Images/icons/demographic-icons/age-45-54.svg';
import Age5564 from 'Images/icons/demographic-icons/age-55-64.svg';
import Age65 from 'Images/icons/demographic-icons/age-65.svg';
import WebSearch from 'Images/icons/demographic-icons/web-search.svg';
import OnlineAds from 'Images/icons/demographic-icons/online-ads.svg';
import SocialMedia from 'Images/icons/demographic-icons/social-media.svg';
import AppStore from 'Images/icons/demographic-icons/app-store.svg';
import BlogPost from 'Images/icons/demographic-icons/blog-post.svg';
import YouTube from 'Images/icons/demographic-icons/you-tube.svg';
import Podcast from 'Images/icons/demographic-icons/podcast.svg';
import ArchitectureConstruction from 'Images/variations/24x24/house.svg';
import Health from 'Images/variations/24x24/health.svg';
import Law from 'Images/variations/24x24/law.svg';
import Science from 'Images/variations/24x24/technology.svg';


export default {
    name: 'demographic-icons',
    props: {
        value: {
            type: String,
            required: true
        },
        size: {
            type: String,
            required: false
        }
    },
    components: {
        IntegrateCountry,
        SetGoal,
        BrainTraining,
        EnjoyLearning,
        FriendsFamily,
        LoveCountry,
        Career,
        PassExam,
        UpcomingTrip,
        DemographicOther,
        Age18,
        Age1824,
        Age2534,
        Age3544,
        Age4554,
        Age5564,
        Age65,
        WebSearch,
        OnlineAds,
        SocialMedia,
        AppStore,
        BlogPost,
        YouTube,
        Podcast,
        ArchitectureConstruction, Health, Law, Science
    },
    computed: {
        icon: function () {
            switch (this.value) {
                case 'integrateCountry':
                    return IntegrateCountry;
                case 'setGoal':
                    return SetGoal;
                case 'brainTraining':
                    return BrainTraining;
                case 'enjoyLearning':
                    return EnjoyLearning;
                case 'friendsFamily':
                    return FriendsFamily;
                case 'loveCountry':
                    return LoveCountry;
                case 'career':
                    return Career;
                case 'passExam':
                    return PassExam;
                case 'upcomingTrip':
                    return UpcomingTrip;
                case 'other':
                    return DemographicOther;
                case '18':
                    return Age18;
                case '18_24':
                    return Age1824;
                case '25_34':
                    return Age2534;
                case '35_44':
                    return Age3544;
                case '45_54':
                    return Age4554;
                case '55_64':
                    return Age5564;
                case '65':
                    return Age65;
                case 'webSearch':
                    return WebSearch;
                case 'onlineAds':
                    return OnlineAds;
                case 'socialMedia':
                    return SocialMedia;
                case 'appStore':
                    return AppStore;
                case 'blogPost':
                    return BlogPost;
                case 'youTube':
                    return YouTube;
                case 'podcast':
                    return Podcast;
                case 'architecture':
                    return ArchitectureConstruction;
                case 'health':
                    return Health;
                case 'law':
                    return Law;
                case 'science':
                    return Science;
            }
        }
    }
};
</script>

<style lang="scss">
@import "~Styles/mixins";

div.course-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // Very specific pixel sizes by Alexander :/
    width: 42px;
    height: 26px;
    flex: 0 0 auto;

    &[data-size="medium"] {
        width: pxToRem(30);
        height: pxToRem(30);

        > svg {
            width: pxToRem(30);
            height: pxToRem(30);
        }
    }

    &[data-size="small"] {
        width: pxToRem(20);
        height: pxToRem(20);

        > svg {
            width: pxToRem(20);
            height: pxToRem(20);
        }
    }
}
</style>
