var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M2 5h3c.6 0 1 .4 1 1s-.4 1-1 1H4v12c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V7h-1c-.6 0-1-.4-1-1s.4-1 1-1h3v14c0 1.7-1.3 3-3 3H5c-1.7 0-3-1.3-3-3V5z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M8 2c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1s-1-.4-1-1V3c0-.6.4-1 1-1zM13.5 6c0 .6-.4 1-1 1h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1zM16 2c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1s-1-.4-1-1V3c0-.6.4-1 1-1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#8EA3EE",
          d: "M6 10c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1v-2zM6 15c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1v-2zM13 10c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-3c-.6 0-1-.4-1-1v-2zM13 15c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-3c-.6 0-1-.4-1-1v-2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }