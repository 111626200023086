var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "free-trial-heading" },
    [
      _c("start-trial-illustration"),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _c("h2", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("product_highlights_title_1")),
          },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("product_highlights_text_1")),
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }