var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toaster" },
    [
      _c(
        "transition-group",
        { attrs: { name: "list", tag: "div" } },
        _vm._l(_vm.toasts, function (toast) {
          return _c("toast", {
            key: toast.id,
            attrs: { toast: toast },
            nativeOn: {
              click: function ($event) {
                return _vm.removeToast(toast)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }