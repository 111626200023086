var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "insights-calendars" }, [
    _c(
      "div",
      { ref: "calendars-wrapper", staticClass: "calendars-grid" },
      [
        _vm._l(_vm.calendars, function (calendar) {
          return [
            _c(
              "calendar",
              _vm._g(
                {
                  attrs: {
                    "date-context": calendar.dateContext,
                    course: _vm.course,
                  },
                },
                _vm.$listeners
              )
            ),
          ]
        }),
        _vm._v(" "),
        _c(
          "mugen-scroll",
          {
            attrs: {
              handler: _vm.generateCalendars,
              "should-handle": !_vm.loading,
              "handle-on-mount": false,
              "scroll-container": "calendars-wrapper",
            },
          },
          [_vm._v("\n            ...\n        ")]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }