
import _ from 'lodash';
import Raven from 'raven-js';
import Backbone from 'backbone';
import LoaderView from '../view/overlay/loader.view.js';


class LoaderNotInitializedException extends Error {
    constructor (message) {
        super(message);
        this.name = 'LoaderNotInitializedException';
    }
}

const LoaderController = {

    initialize: function () {

        this._priorityLoaders = {};

        this._loaderView = new LoaderView();
        this._loaderViewRendered = this._loaderView.render();

        Backbone.on('rendered', this.hideLoader, this);
        Backbone.on('preRoute', this.showLoader, this);
        Backbone.on('routed', this.hideLoader, this);
        Backbone.on('signinInProgress', this.showLoader, this);
        Backbone.on('signinProcessFinished', this.hideLoader, this);
        Backbone.on('registerInProgress', this.showLoader, this);
        Backbone.on('registerProcessFinished', this.hideLoader, this);
        Backbone.on('passwordResetInProgress', this.showLoader, this);
        Backbone.on('passwordResetProcessFinished', this.hideLoader, this);
        Backbone.on('requestLanguageInProgress', this.showLoader, this);
        Backbone.on('requestLanguageProcessFinished', this.hideLoader, this);
        Backbone.on('settingsSaveInProgress', this.showLoader, this);
        Backbone.on('saveSettingsProcessFinished', this.hideLoader, this);
        Backbone.on('server-error', this.hideLoader, this);
        Backbone.on('signoutStart', this.showLoader, this);
        Backbone.on('signoutStop', this.hideLoader, this);
        Backbone.on('showLoader', this.showLoader, this);
        Backbone.on('hideLoader', this.hideLoader, this);
    },

    showLoader: function (route) {

        return  Promise.resolve()
            .then(() => this._loaderViewRendered || Promise.reject(new LoaderNotInitializedException()))
            .then(() => {
                this._loaderView.show(route);
            }).catch(error => {
                Raven.captureException(error, {level: 'error'});
            });
    },

    hideLoader: function () {
        return  Promise.resolve()
            .then(() => this._loaderViewRendered || Promise.reject(new LoaderNotInitializedException()))
            .then(() => {
                if (!_.some(Object.values(this._priorityLoaders))) {
                    this._loaderView.hide();
                }
            }).catch(error => {
                Raven.captureException(error, {level: 'error'});
            });
    },

    showPriorityLoader: function (tag) {
        this._priorityLoaders[tag] = true;
        return this.showLoader(tag);
    },

    hidePriorityLoader: function (tag) {
        delete this._priorityLoaders[tag];
        return this.hideLoader();
    }
};

export default LoaderController;
