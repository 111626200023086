var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "180",
          height: "180",
          viewBox: "0 0 180 180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M23 95a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M155.78 99.028L89.21 137.79c-6.89 4.05-15.45-.96-15.45-8.8v-19.74h33.66c1.02.18 2.07.28 3.14.28 9.72 0 17.82-8.11 17.82-17.84 0-4.68-1.81-8.98-4.82-12.18-.11-.13-.22-.26-.35-.37a17.75 17.75 0 00-12.92-5.55H73.75V51.23c0-7.84 8.56-12.83 15.45-8.8l66.58 38.76c6.9 4.05 6.9 13.79 0 17.84z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M128.37 91.409c0 9.72-8.1 17.84-17.82 17.84-1.07 0-2.13-.09-3.14-.28-8.45-1.48-14.69-8.89-14.69-17.56 0-10 8.11-17.82 17.84-17.82 4.99 0 9.45 2.03 12.64 5.27.13.11.24.24.35.37 3.01 3.2 4.82 7.51 4.82 12.18zm-65.93-.211c0 4.69-3.9 8.6-8.59 8.6-.52 0-1.03-.04-1.52-.13-4.07-.71-7.09-4.29-7.09-8.47 0-4.82 3.91-8.59 8.6-8.59 2.41 0 4.56.98 6.1 2.54.06.05.12.12.17.18a8.514 8.514 0 012.33 5.87z",
          fill: "#9DF4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.81 91.209h4.8m31.79 0h14.4",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }