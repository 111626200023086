var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M23 8.9c0 1.617-.72 2.862-2 3.548a4.292 4.292 0 01-.1.052c-.667-.867-1.772-1.426-2.9-1.5 0-3-1.938-4.696-1.938-4.696C16.748 5.458 17.95 5 19.1 5 21.301 5 23 6.8 23 8.9z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 19h9.063c1.796 0 3.309-1.225 3.78-2.902.102-.353.157-.727.157-1.114 0-2.133-1.943-3.85-4-3.984 0-3.5-2.5-6-5.534-6C9.5 5 7 7.5 7 10c-2.5 0-5 1.903-5 4.5 0 .533.105 1.036.294 1.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }