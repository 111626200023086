var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "upload-file",
      class: { error: _vm.typesAnimated },
      on: { dragover: _vm.dragover, dragleave: _vm.dragleave, drop: _vm.drop },
    },
    [
      !_vm.loading
        ? [
            _c("input", {
              ref: "file",
              staticClass: "file-input",
              attrs: {
                type: "file",
                multiple: "",
                accept: ".jpg,.jpeg,.png,.txt,.pdf",
              },
              on: { change: _vm.onChange },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-container" },
              [
                _c("v-button", {
                  attrs: {
                    i18n: { id: "lessons_creator_editor_upload_button" },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.openExplorer.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "help-text",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("lessons_creator_editor_upload_text")
                    ),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "accepted-types",
                class: { animated: _vm.typesAnimated },
              },
              [
                _c("FileImageIcon"),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [_vm._v("(JPG, PNG)")]),
                _vm._v(" "),
                _c("FileTextIcon"),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [_vm._v("(TXT, PDF)")]),
              ],
              1
            ),
          ]
        : _c(
            "div",
            { staticClass: "process" },
            [
              _c("loader"),
              _vm._v(" "),
              _vm.status === "uploading"
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_upload_status_1_uploading"
                        )
                      ),
                    },
                  })
                : _vm.status === "upload-complete"
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_upload_status_2_file_uploaded"
                        )
                      ),
                    },
                  })
                : _vm.status === "processing"
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_upload_status_3_file_uploaded"
                        )
                      ),
                    },
                  })
                : _vm.status === "still-processing"
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("lessons_creator_processing_message_text")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }