<template>
    <div class="active-words-modal" @click.self="close">
        <div class="active-words-modal-window">
            <CloseButton @click="close" class="close" />
            <h2 class="title" v-html="this.$i18n('thermometer_metrics_title')" />
            <div class="content">
                <p v-html="this.$i18n('1', { collection: 'thermometer_metrics_description' })" />
                <p v-html="this.$i18n('2', { collection: 'thermometer_metrics_description' })" />
                <div class="blocks" v-if="this.learningProgress">
                    <thermometer :course="this.course" :user="this.user" :compact="true" />
                    <collapsible-box :i18n_title="{ id: 'thermometer_metrics_words_at_risk_title' }"
                                     :title-meta="this.learningProgress.repeats_waiting"
                                     :i18n_text="{ ids: ['1', '2'], collection: 'thermometer_metrics_words_at_risk_description' }">
                        <template v-slot:icon>
                            <IconRepeats />
                        </template>
                    </collapsible-box>
                    <collapsible-box :i18n_title="{ id: 'thermometer_metrics_words_in_transit_title' }"
                                     :title-meta="this.learningProgress.progressing"
                                     :i18n_text="{ ids: ['1', '2', '3'], collection: 'thermometer_metrics_words_in_transit_description' }">
                        <template v-slot:icon>
                            <IconProgressing />
                        </template>
                    </collapsible-box>
                    <collapsible-box :i18n_title="{ id: 'thermometer_metrics_words_conquered_title' }"
                                     :title-meta="this.learningProgress.mastered"
                                     :i18n_text="{ id: '1', collection: 'thermometer_metrics_words_conquered_description' }">
                        <template v-slot:icon>
                            <IconMastered />
                        </template>
                    </collapsible-box>
                    <collapsible-box :i18n_title="{ id: 'thermometer_metrics_not_yet_seen_words_title' }"
                                     :title-meta="this.learningProgress.not_seen"
                                     :i18n_text="{ id: 'thermometer_metrics_not_yet_seen_words_description' }">
                        <template v-slot:icon>
                            <IconNotSeen />
                        </template>
                    </collapsible-box>
                </div>
                <loader v-else :centered="true" />
            </div>
        </div>
    </div>
</template>

<script>
    import CloseButton from 'Images/close-24x24-whitespace.svg';
    import Thermometer from "./thermometer.vue";
    import CollapsibleBox from "ViewComponent/collapsible-box/main.vue";
    import IconMastered from 'Images/insights/ic_stats_mastered_light.svg';
    import IconNotSeen from 'Images/insights/ic_stats_not_seen_light.svg';
    import IconProgressing from 'Images/insights/ic_stats_progressing_light.svg';
    import IconRepeats from 'Images/insights/ic_stats_repeats_light.svg';
    import loader from "ViewComponent/loader.vue";

    export default {
        name: 'active-words-modal',
        props: {
            course: {
                type: Object,
                required: true
            },
            user: {
                type: Object,
                required: true
            },
        },
        components: {
            Thermometer,
            CollapsibleBox,
            loader,
            CloseButton,
            IconMastered,
            IconNotSeen,
            IconProgressing,
            IconRepeats
        },
        data: function () {
            return {
                learningProgress: null
            }
        },
        methods: {
            close() {
                this.$emit('close-active-words-modal');
            },
            keyDown(event) {
                if (event && event.keyCode === 27) { // close on ESC key
                    this.close()
                }
            },
            async setLearningProgress() {
                if (this.user && this.course && this.course.UUID) {
                    this.learningProgress = await this.user.getCourseLearningProgress(this.course.UUID);
                }
            }
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
            document.body.classList.add('modal-open');
            this.setLearningProgress();
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.active-words-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43,81,142,0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.active-words-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 35vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }
                &:hover {
                    transform: scale(1.2);
                }
            }

            > h2.title {
                text-align: center;
                margin-bottom: 1rem;
            }

            > div.content {
                > p {
                    margin-bottom: .5rem;
                }
                > div.blocks {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
                > div.loader.centered {
                    top: unset;
                    margin-top: 2rem;
                }
            }
        }
    }
</style>
