var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.languageList && this.languageList.available
    ? _c(
        "div",
        { staticClass: "language-selection" },
        [
          _c(
            "div",
            { staticClass: "current", on: { mouseover: _vm.openDropdown } },
            [
              _c("world-icon"),
              _vm._v(" "),
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.currentLanguageLabel)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.dropdownOpen
              ? _c(
                  "span",
                  {
                    staticClass: "dropdown-content",
                    on: {
                      mouseenter: _vm.openDropdown,
                      mouseleave: _vm.closeDropdownDelayed,
                    },
                  },
                  _vm._l(_vm.languageList.available, function (code) {
                    return _c(
                      "span",
                      {
                        staticClass: "dropdown-item",
                        class: { active: code === _vm.languageList.current },
                        on: {
                          click: function ($event) {
                            return _vm.selectLanguage(code)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.getLanguageName(code))),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }