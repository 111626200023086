var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "20",
          height: "20",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M0 0h20v20H0z" } }),
        _c("path", {
          attrs: {
            d: "M12.709 10H.833A9.167 9.167 0 102 5.524",
            stroke: "#FFF",
            "stroke-width": "1.25",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M10.14 13.15a.617.617 0 00.1.88.65.65 0 00.9-.099l2.887-3.54a.615.615 0 000-.781L11.14 6.069a.65.65 0 00-.9-.099.617.617 0 00-.1.88L12.709 10l-2.57 3.15z",
            fill: "#FFF",
            "fill-rule": "nonzero",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }