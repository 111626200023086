import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import MultipleChoiceView from '../view/challenges/multiple.choice.view.js';

import {
    ENTRY_TYPE as MULTIPLE_CHOICE_ENTRY_TYPE
} from '../modules/exercises/answer/multiple.choice.js';

export class ListeningController {

    go (appView, router, exerciseUuid) {
        this.exercise = UserManager.instance.getUser().getCourse().getExercises().getExercise(exerciseUuid);
        this.exerciseAnswer = this.exercise.getExerciseAnswer();

        return Promise.resolve()
            .then( () => this.exercise.getData())
            .then(exerciseData => {
                 if (this.exercise.type === 'listening') {
                    const multipleChoiceView = new MultipleChoiceView();
                    multipleChoiceView.setProps(exerciseData);

                    multipleChoiceView.on('exercise-answer-incorrect', answer => {
                        this.exerciseAnswer.addEntry(MULTIPLE_CHOICE_ENTRY_TYPE.SELECT, {
                            option: answer.answerId,
                            correct: false
                        });
                    });

                    multipleChoiceView.on('exercise-answer-correct', answer => {
                        this.exerciseAnswer.addEntry(MULTIPLE_CHOICE_ENTRY_TYPE.SELECT, {
                            option: answer.answerId,
                            correct: true
                        });
                    });

                    multipleChoiceView.on('exercise-ended', isComplete => {
                        if (this.exerciseAnswer.hasEntries()) {
                            this.exerciseAnswer.complete(isComplete);
                            if (isComplete) {
                                this.exercise.setCompleted();
                            }
                            UserManager.instance.getUser().getEventSender().sendListeningExerciseEvent(this.exerciseAnswer);
                        }
                    });

                    appView.setMainView(multipleChoiceView, true);
                    multipleChoiceView.render();
                }
            });
    }
}

export const listening_controller_factory = new ControllerFactory(ListeningController);
