var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M5.6 9.2C6 7.9 7.2 7 8.5 7H10c.6 0 1 .4 1 1s-.4 1-1 1H8.5c-.4 0-.8.3-1 .7L7.2 11c-.1.3-.1.7 0 1l.8 3.3c.1.5-.2 1.1-.7 1.2-.5.1-1.1-.2-1.2-.7l-.8-3.2c-.2-.7-.2-1.4 0-2.1l.3-1.3zM10 15c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1zM13 8c0-.6.4-1 1-1h1.4c1.4 0 2.6.9 2.9 2.3l.4 1.7c.2.7.2 1.4 0 2.1l-.7 2.7c-.2.5-.7.8-1.2.7-.5-.2-.8-.7-.7-1.2l.8-2.7c.1-.3.1-.7 0-1l-.4-1.7c-.1-.4-.5-.8-1-.8H14c-.6-.1-1-.5-1-1.1zm1 5c.6 0 1 .4 1 1v8c0 .6-.4 1-1 1s-1-.4-1-1v-8c0-.6.4-1 1-1z",
        },
      }),
      _c("circle", { attrs: { fill: "#8EA3EE", cx: "12", cy: "3", r: "3" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }