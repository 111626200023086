<template>
    <div class="progress-bar" v-bind:style="{ maxWidth: (maxWidth) ? `${maxWidth}px` : 'auto' }">
        <div class="primary" v-bind:style="{ width: `${primaryWidth}%` }"></div>
        <div class="secondary" v-bind:style="{ width: `${secondaryWidth}%` }"></div>
    </div>
</template>

<script>
    export default {
        name: 'progress-bar',
        props: {
            maxWidth: {
                type: Number
            },
            primary: {
                type: Number,
                default: 0
            },
            secondary: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                primaryWidth: this.primary < 100 ? this.primary : 100,
                secondaryWidth: this.secondary < 100 ? this.secondary : 100
            };
        },
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    $barHeight: .3rem;

    div.progress-bar {
        position: relative;
        height: $barHeight;
        background-color: $colorBackgroundLighter;
        border-radius: $barHeight / 2;
        body[data-interface-language="ar"] & {
            transform: rotate(180deg);
        }
        > div.primary,
        > div.secondary {
            border-radius: $barHeight / 2;
            position: absolute;
            left: 0;
            top: 0;
            height: $barHeight;
            width: 0;
            transition: width 200ms ease;
        }

        > div.primary {
            z-index: 10;
            background-color: $colorCorrect;
        }

        > div.secondary {
            z-index: 5;
            background-color: rgba($colorCorrect, 0.4);
        }
    }
</style>
