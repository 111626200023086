
'use strict';

import dust from 'dustjs-linkedin';
import {} from 'dustjs-helpers';

import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import Raven from 'raven-js';

import ResponsivenessUtils from './util/responsiveness.js';
import i18nUtils from './util/i18n.js';
import UserManager from './modules/usermanager.js';

const IMAGE_KEY_REGEX = /^img_(\w+)$/;
const LINK_TEMPLATE_KEY_REGEX = /^link_template_(\w+)$/;
const LINK_KEY_REGEX = /^link_(\w+)$/;
const CUSTOM_ARG_REGEX = /^arg_(\w+)$/;

dust.helpers.i18n = function (chunk, ctx, bodies, params) {
    /*
     * Params:
     * prefix: prefix to be added to the id
     * id: the id of the i18n property
     * capitalize: if specified make sure the first letter of the result is uppercase
     * context: Additional parameters object that can be passed to the i18n string
     * varargs*: positional arguments for templating (replacing {n} in i18n strings with it's value)
     * */
    var id,
        capitalize,
        collection,
        out;

    var message=null,
        parameters = {},
        images = {},
        links = {},
        link_templates = {},
        paramNr = 0,
        addDefaultParams = false,
        context = {};

    Object.keys(params).forEach(function (key) {
        switch (key) {
            case 'id':
                id = params[key];
                break;
            case 'capitalize':
                capitalize = params[key];
                break;
            case 'context':
                context = params[key];
                break;
            case 'collection':
                collection = params[key];
                break;
            case 'message':
                message = params[key];
                break;
            case 'addDefaultParams':
                addDefaultParams = !!(params[key]);
                break;
            default:
                var matches = key.match(IMAGE_KEY_REGEX),
                    linkTemplateMatches = key.match(LINK_TEMPLATE_KEY_REGEX),
                    linkMatches = key.match(LINK_KEY_REGEX),
                    argMatches = key.match(CUSTOM_ARG_REGEX);

                if (argMatches) {
                    parameters[argMatches[1]] = params[key];
                } else if (matches) {
                    images[matches[1]] = params[key];
                } else if (linkTemplateMatches) {  // Must be before links matching
                    link_templates[linkTemplateMatches[1]] = params[key];
                } else if (linkMatches) {
                    links[linkMatches[1]] = params[key];
                } else {
                    parameters[paramNr] = params[key];
                    paramNr++;
                }
        }
    });

    links = _(links).mapValues(replacement => {
        var replacementParts = replacement.split('[[body]]');
        return [
            {role: 'text', value: replacementParts[0]},
            {role: 'linktext'},
            {role: 'text', value: replacementParts[1]}
        ];
    }).value();

    _.assign(link_templates, links);
    // Add context to parameters, overriding context with direct parameters if they are given
    parameters = _.assign(context, parameters);

    if (message !== null) {
        try {
            out = i18nUtils.resolveMessage(message, parameters, images, link_templates, {addDefaultParams: addDefaultParams});
        } catch (error) {
            Raven.captureException(error,
                {
                    level: 'error',
                    fingerprint: ['DustHelpers', 'i18n', 'resolveMessage'],
                    extra: {params: params}
                });
        }
    } else {
        out = i18nUtils.prop(id, parameters, images, collection, link_templates);
    }

    if (capitalize) {
        out = out.charAt(0).toUpperCase() + out.slice(1);
    }

    return chunk.write(out);
};

dust.helpers.grammar = function (chunk, ctx, bodies, params) {
    var id,
        capitalize,
        collection,
        language,
        parameters = [];

    Object.keys(params).forEach(function (key) {
        switch (key) {
            case 'id':
                id = params[key];
                break;
            case 'capitalize':
                capitalize = params[key];
                break;
            case 'collection':
                collection = params[key];
                break;
            case 'language':
                language = params[key];
                break;
            default:
                parameters.push(params[key]);
        }
    });

    return chunk.map(function (chunk) {
        Promise.resolve().then(function () {
            return i18nUtils.getGrammarProp(language, id, parameters, {}, collection);
        }).then(function (prop) {
            chunk.write(prop).end();
        }).catch(function (error) {
            console.error('Error getting grammar prop', error); // TODO ?!
            chunk.write('[error]').end();
        });
    });
};

dust.helpers.formatTime = function (chunk, ctx, bodies, params) {
    var time = params.time,
        unit,
        unitLength,
        minDisplayUnit,
        maxDisplayUnit,
        padding,
        hourPadding,
        minutePadding,
        secondPadding,
        separator,
        hours = 0,
        minutes = 0,
        seconds = 0;

    var unitsEnum = {
        hour: 2,
        minute: 1,
        second: 0
    };

    if (params.unit !== undefined) {
        unit = params.unit;
    } else {
        unit = 'minutes';
    }

    if (params.unitLength !== undefined) {
        unitLength = params.unitLength;
    } else {
        unitLength = '_letter';
    }

    if (params.minDisplayUnit !== undefined) {
        minDisplayUnit = params.minDisplayUnit;
    } else {
        minDisplayUnit = unit;
    }

    if (params.maxDisplayUnit !== undefined) {
        maxDisplayUnit = params.maxDisplayUnit;
    } else {
        maxDisplayUnit = 'hour';
    }

    if (params.padding !== undefined) {
        padding = params.padding;
    } else {
        padding = 1;
    }

    if (params.hourPadding !== undefined) {
        hourPadding = params.hourPadding;
    } else {
        hourPadding = padding;
    }

    if (params.minutePadding !== undefined) {
        minutePadding = params.minutePadding;
    } else {
        minutePadding = padding;
    }

    if (params.secondPadding !== undefined) {
        secondPadding = params.secondPadding;
    } else {
        secondPadding = padding;
    }

    separator = params.separator;

    switch (unit) {
        case "minutes":
            hours = Math.floor(time / 60);
            minutes = time - (hours * 60);
            break;
        case "seconds":
            hours = Math.floor(time / 3600);
            minutes = Math.floor((time - (hours * 3600)) / 60);
            seconds = time - (hours * 3600) - (minutes * 60);
    }

    switch (separator) {
        case ":":
            if (unitsEnum[maxDisplayUnit] >= unitsEnum.hour && unitsEnum.hour >= unitsEnum[minDisplayUnit]) {
                if (String(hours).length < hourPadding) {
                    hours = new Array(hourPadding - String(hours).length + 1).join('0') + hours;
                }
                chunk.write(hours);
            }
            if (unitsEnum[maxDisplayUnit] >= unitsEnum.minute && unitsEnum.minute >= unitsEnum[minDisplayUnit]) {
                if (String(minutes).length < minutePadding) {
                    minutes = new Array(minutePadding - String(minutes).length + 1).join('0') + minutes;
                }
                chunk.write(':' + minutes);
            }
            if (unitsEnum[maxDisplayUnit] >= unitsEnum.second && unitsEnum.second >= unitsEnum[minDisplayUnit]) {
                if (String(seconds).length < secondPadding) {
                    seconds = new Array(secondPadding - String(seconds).length + 1).join('0') + seconds;
                }
                chunk.write(':' + seconds);
            }
            break;
        default:
            if (hours > 0) {
                if (String(hours).length < hourPadding) {
                    hours = new Array(hourPadding - String(hours).length + 1).join('0') + hours;
                }
                chunk.write(hours + ' ' + i18nUtils.prop('hour' + unitLength) + ' ');
            }
            if (minutes > 0) {
                if (String(minutes).length < minutePadding) {
                    minutes = new Array(minutePadding - String(minutes).length + 1).join('0') + minutes;
                }
                chunk.write(minutes + ' ' + i18nUtils.prop('minute' + unitLength) + ' ');
            }
            if (seconds > 0) {
                if (String(seconds).length < secondPadding) {
                    seconds = new Array(secondPadding - String(seconds).length + 1).join('0') + seconds;
                }
                chunk.write(seconds + ' ' + i18nUtils.prop('second' + unitLength));
            }
            if (hours === 0 && minutes === 0 && seconds === 0) {
                chunk.write(0 + ' ' + i18nUtils.prop('second' + unitLength));
            }
    }
    return chunk;
};

dust.helpers.i18nTimeago = function (chunk, ctx, bodies, params) {
    let i18n_params = {};
    i18n_params[params.arg_name] =  $.timeago(params.date.toDate());
    return chunk.write(i18nUtils.prop(params.message_id, i18n_params));
};

dust.helpers.hasTouch = function (chunk, ctx, bodies, params) {
    if (ResponsivenessUtils.hasTouch()) {
        bodies.block(chunk, ctx);
    } else if (bodies.else) {
        bodies.else(chunk, ctx);
    }
    return chunk.write('');
};

dust.helpers.localeDate = function (chunk, ctx, bodies, params) {
    var date = params.date;
    var options = {};

    if (params.month !== undefined) {
        options.month = params.month;
    }

    if (params.year !== undefined) {
        options.year = params.year;
    }

    return chunk.write(i18nUtils.getLocaleDateString(date, options));
};

dust.helpers.momentDate = function (chunk, ctx, bodies, params) {
    const currentLocale = i18nUtils.currentLocale;
    const date = params.date;
    const format = (params.format !== undefined) ? params.format : 'DD MMM';
    return chunk.write(moment(date).locale(currentLocale).format(format));
};

dust.helpers.experiment = function (chunk, ctx, bodies, params) {
    var experimentParameter = UserManager.instance.getUser().getCourse().getExperiments().getParameter(params.experiment, params.param);
    if (bodies.block !== undefined && experimentParameter) {
        bodies.block(chunk, ctx);
    } else if (bodies.else !== undefined && !experimentParameter) {
        bodies.else(chunk, ctx);
    } else {
        chunk.write(experimentParameter);
    }
    return chunk.write('');
};

dust.helpers.localeDate = function (chunk, ctx, bodies, params) {
    var date = params.date;
    return chunk.write(i18nUtils.getLocaleDateString(date, {month: 'long', year: 'numeric'}));
};

dust.helpers.formatCounterNumber = function (chunk, ctx, bodies, params) {
    var number = params.number;
    if (number >= 1000) {
        return chunk.write(parseInt(number / 1000) + 'K+');
    } else {
        return chunk.write(number);
    }

};

dust.helpers.lettersToSpans = function (chunk, ctx, bodies, params) {
    var input = params.input,
        out = '';

    for (var i = 0; i < input.length; i++) {
        var letter = input[i];
        out += '<span index="' + i + '" class="hidden">' + letter + '</span>';
    }

    return chunk.write(out);
};

dust.helpers.sortByTranslation = function (chunk, ctx, bodies, params) {
    var collection = params.collection,
        key = params.key;

    params.items.sort( function (a, b) {
        var stringA = i18nUtils.prop(a[key], {}, {}, collection);
        var stringB = i18nUtils.prop(b[key], {}, {}, collection);

        return stringA.localeCompare(stringB);
    });

    return chunk;
};

dust.helpers.convertNewlines = function (chunk, ctx, bodies, params) {
    return chunk.write(params.text.split('\n\n').map(paragraph => `<p>${paragraph}</p>`).join(''));
};

dust.helpers.text2html = function (chunk, ctx, bodies, params) {
    let out = params.text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\r\n?|\n/g, "<br>");
    out = out.replace(/<br>\s*<br>/g, "</p><p>");
    out = `<p>${out}</p>`;

    return chunk.write(out);
};

dust.helpers.generatePlaceholders = function (chunk, ctx, bodies, params) {
    let out = '';

    [...Array(params.count)].forEach(() => {
        out += `<div class='placeholder'></div>`;
    });

    return chunk.write(out);
};

dust.helpers.svg = function (chunk, ctx, bodies, params) {
    /** Loads an svg into a dust template from the img/ directory */

    return chunk.map(function (chunk) {
        Promise.resolve()
            .then(() => import( // jshint ignore:line
                /* webpackMode: "eager" */
                `!svg-inline-loader?removeSVGTagAttrs=false!Images/${params.path}.svg`))
            .then(svg_module => {
                chunk.write(svg_module.default).end();
            })
            .catch(error => {
                chunk.write('').end();
                console.error('DustHelpersSVGNotFound', error);
                Raven.captureException(error,
                    {
                        level: 'error',
                        fingerprint: ['DustHelpersSVGNotFound'],
                        extra: {
                            params: params
                        }
                    });
            });
    });
};
