<template>
        <div class="card-synonym" v-if="synonym">
            <span v-html="this.$i18n('synonyms_card_feedback_message', { args: { form_synonym: synonymHtml }})" />
        </div>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'card-synonym',
        data() {
            return {
                synonym: null
            }
        },
        computed: {
            synonymHtml() {
                return (this.synonym) ? `<em>${ this.synonym }</em>` : '';
            }
        },
        methods: {
            setSynonym(options) {
                if (options && options.answer_text) {
                    this.synonym = options.answer_text;
                }
                setTimeout(() => {
                    this.synonym = null;
                }, 3000); // must be same what's defined in card-gap.vue reactToSynonymAnswer() method
            },
            listenEventBus() {
                EventBus.$on('guess:synonym-answer', this.setSynonym);
            },
            unListenEventBus() {
                EventBus.$off('guess:synonym-answer', this.setSynonym);
            }
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            this.unListenEventBus();
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";

    div.card-synonym {
        > span {
            > em {
                color: $colorAttention;
            }
        }
    }
</style>
