var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "28",
          height: "28",
          viewBox: "0 0 28 28",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M14.75 14l-3.082-3.78a.74.74 0 01.12-1.056.78.78 0 011.08.119l3.464 4.248a.739.739 0 010 .938l-3.464 4.248a.78.78 0 01-1.08.119.74.74 0 01-.12-1.056L14.75 14z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }