var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M27 8a2 2 0 100-4 2 2 0 000 4z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M14 13a33.573 33.573 0 00-1.783 1.747c-6.5 6.857-9.086 15.245-5.776 18.735 1.58 1.664 4.217 1.914 7.249.974M25 28a33.204 33.204 0 002.584-2.324c6.679-6.661 9.337-14.807 5.936-18.2-1.561-1.556-4.127-1.84-7.091-1.048",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28 16a33.613 33.613 0 00-2.51-2.662C18.553 6.73 10.07 4.099 6.536 7.465c-1.79 1.704-1.962 4.621-.795 7.945M13 25a34.209 34.209 0 002.06 2.31c6.75 6.925 15.005 9.682 18.443 6.155 1.766-1.812 1.916-4.926.731-8.465",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M21.223 17.045a3.264 3.264 0 014.787 0c1.32 1.393 1.32 3.65 0 5.043L20.398 28l-5.406-5.695c-1.323-1.393-1.323-3.65 0-5.043a3.264 3.264 0 014.787 0l.619.652.825-.869zM17.5 17c-.767 0-1.444.491-1.85.998-.433.538-.77 1.345-.562 2.23a1 1 0 101.947-.456c-.027-.117.003-.31.173-.521.08-.1.169-.173.243-.217a.378.378 0 01.073-.034 1 1 0 00-.024-2z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }