'use strict';

import ExportUtils from './export.js';
import getConfigValue from './configuration.js';

import platform from 'platform';

export class Device {

    // static isBrowserOsIos () {
    //     return platform && platform.os && platform.os.family && platform.os.family.startsWith('iOS') || false;
    // }

    static isBrowserOsIos () {
        const platform_iOS = platform && platform.os && platform.os.family && platform.os.family.startsWith('iOS') || false;
        const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);
        const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const iOS1to12quirk = function() {
            let audio = new Audio(); // temporary Audio object
            audio.volume = 0.5; // has no effect on iOS <= 12
            return audio.volume === 1;
        };

        return !window.MSStream && (platform_iOS || iOS_1to12 || iOS13_iPad || iOS1to12quirk());
    }

    static isBrowserOsAndroid () {
        return platform && platform.os && platform.os.family && platform.os.family.startsWith('Android') || false;
    }

    static isBrowserOsMobile () {
        return this.isBrowserOsIos() || this.isBrowserOsAndroid();
    }

    static getNativeAppMarketplaceUrl () {
        if (this.isBrowserOsIos()) {
            return getConfigValue('app-store-url');
        } else if (this.isBrowserOsAndroid()) {
            return getConfigValue('play-store-url');
        }
        return false;
    }

    static isSpeechRecognitionSupported () {

        const supportedBrowsers = [
            { name: 'Chrome', os: 'Windows' },
            { name: 'Chrome', os: 'OS X' },
            { name: 'Chrome Mobile', os: 'Android' }
        ];

        let speechSupported = false;

        supportedBrowsers.forEach(supported_browser => {
            if (supported_browser.name === platform.name && platform.os.family.indexOf(supported_browser.os) > -1) {
                speechSupported = true;
            }
        });
        return speechSupported;
    }
}

ExportUtils.export('app.util.Device', Device);
