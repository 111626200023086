var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userCourse
    ? _c(
        "div",
        { staticClass: "learning-progress", class: { open: _vm.open } },
        [
          _c("div", { staticClass: "home-drawer-stats newWords" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "icon" }, [_c("icon-new-words")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "words" }, [
                _c("div", { staticClass: "count" }, [
                  _vm._v(" " + _vm._s(_vm.new_units_total) + " "),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("div", { staticClass: "label" }, [
                _vm.open
                  ? _c("div", {
                      staticClass: "title",
                      domProps: {
                        innerHTML: _vm._s(this.$i18n("hub_today_new_words")),
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "home-drawer-stats wordsAtRisk" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "icon" }, [_c("icon-words-at-risk")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "words" }, [
                _c("div", { staticClass: "count" }, [
                  _vm._v(" " + _vm._s(_vm.repeats_waiting) + " "),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                { staticClass: "label" },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.open
                      ? _c("div", {
                          staticClass: "title",
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n("widget_stats_words_at_risk_label")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }