var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M17.83 9.294A5 5 0 008.67 5.5M9.88 7.594a3.5 3.5 0 10-4.286 4.287M14.136 13.035A4 4 0 1018 8M3.5 11.402A3 3 0 005 17M12.5 7a3.5 3.5 0 00-3.38 2.594M11.706 12.83a4.999 4.999 0 003.794-.5M5 17h3M12 17l3.293 3.293c.63.63 1.707.184 1.707-.707V17",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }