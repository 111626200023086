var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M21 18V7c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1s-1-.4-1-1V7c0-1.7 1.3-3 3-3h16c1.7 0 3 1.3 3 3v11c0 1.7-1.3 3-3 3H4c-1.7 0-3-1.3-3-3v-5c0-.6.4-1 1-1s1 .4 1 1v5c0 .6.4 1 1 1h16c.6 0 1-.4 1-1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#AC6ED1",
          d: "M8 9c0-.4.5-.7.9-.5L16 12c.4.2.4.9 0 1.1l-7.1 3.5c-.4.2-.9-.1-.9-.6V9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }