var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "66",
          height: "16",
          viewBox: "0 0 66 16",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("rect", {
        attrs: {
          x: "38",
          y: "8",
          width: "8",
          height: "8",
          rx: "1",
          fill: "#B2B8CB",
        },
      }),
      _c("circle", { attrs: { cx: "42", cy: "3", r: "3", fill: "#B2B8CB" } }),
      _c("rect", {
        attrs: {
          x: "48",
          y: "8",
          width: "8",
          height: "8",
          rx: "1",
          fill: "#B2B8CB",
        },
      }),
      _c("circle", { attrs: { cx: "52", cy: "3", r: "3", fill: "#B2B8CB" } }),
      _c("rect", {
        attrs: {
          x: "58",
          y: "8",
          width: "8",
          height: "8",
          rx: "1",
          fill: "#B2B8CB",
        },
      }),
      _c("circle", { attrs: { cx: "62", cy: "3", r: "3", fill: "#B2B8CB" } }),
      _c("path", {
        attrs: {
          d: "M36 1l-6 14",
          stroke: "#FFBAB0",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5.294 8.791c-.522-1.059-2.066-1.059-2.588 0L.144 13.983c-.46.932.236 2.014 1.295 2.014H6.56c1.06 0 1.755-1.082 1.295-2.014L5.294 8.791z",
          fill: "#FFBAB0",
        },
      }),
      _c("circle", { attrs: { cx: "4", cy: "3", r: "3", fill: "#FFBAB0" } }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M15.294 8.791c-.522-1.059-2.066-1.059-2.588 0l-2.562 5.192c-.46.932.236 2.014 1.295 2.014h5.122c1.06 0 1.755-1.082 1.295-2.014l-2.562-5.192z",
          fill: "#FFBAB0",
        },
      }),
      _c("circle", { attrs: { cx: "14", cy: "3", r: "3", fill: "#FFBAB0" } }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M25.294 8.791c-.522-1.059-2.066-1.059-2.588 0l-2.562 5.192c-.46.932.236 2.014 1.295 2.014h5.122c1.06 0 1.755-1.082 1.295-2.014l-2.562-5.192z",
          fill: "#FFBAB0",
        },
      }),
      _c("circle", { attrs: { cx: "24", cy: "3", r: "3", fill: "#FFBAB0" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }