var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M18 10v10c-3.261 0-6-2.708-6-6 0-3.5 2.739-6.2 6-6.2 1.57 0 2.9.6 3.5 1.2v1H18z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6 20h12V10h3.5l.5-4-6.5-2.5S14.684 6 12 6 8.5 3.5 8.5 3.5L2 6l.5 4H6v6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }