<template>
    <div :class="['v-textbox', { 'active': isActive, 'full': fullWidth, 'mono': mono }]">
        <label v-if="label" :for="textboxId">{{ label }}</label>
        <div class="textbox-wrapper">
            <input
                type="text"
                :id="textboxId"
                v-model="value"
                @input="updateValue($event.target.value)"
                @focus="isActive = true"
                @blur="isActive = false"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-textbox',
    props: {
        initialValue: {
            type: String,
            default: ''
        },
        label: {
            type: String
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        mono: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isActive: false,
            typingTimer: null,
            value: ''
        };
    },
    computed: {
        textboxId() {
            return `textbox-${this._uid}`;
        }
    },
    methods: {
        updateValue(newValue) {
            clearTimeout(this.typingTimer);
            this.$emit('input', newValue);
            this.typingTimer = setTimeout(() => {
                this.$emit('state-change', this.value);
            }, 200);
        }
    },
    mounted() {
        this.value = this.initialValue;
    },
};
</script>

<style lang="scss" scoped>
    @import '~Styles/colors';
    div.v-textbox {
        > div.textbox-wrapper {
            border: 1px solid $colorBackgroundGray;
            border-radius: .2rem;
            padding: .5rem;
        }
        > label {
            margin: 0 .5rem .5rem;
            display: block;
            font-size: .8rem;
            color: $colorSourceSecondary;
            user-select: none;
        }
        &.active {
            border-color: $colorPurplePrimary;
            > div.textbox-wrapper {
                border-color: $colorPurplePrimary;
            }
        }
        &.full {
            width: 100%;
            > div.textbox-wrapper {
                width: 100%;
                > input {
                    width: 100%;
                }
            }
        }
        &.mono {
            > div.textbox-wrapper {
                > input {
                    font-family: monospace;
                }
            }
        }
    }
</style>
