var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M18 8.893L15 10l3-5 1.8 3-.3 2.5L18 8.893z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10 5a2 2 0 11-4 0 2 2 0 014 0z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22 15l.363-1.818a1.8 1.8 0 00-.221-1.279L18 5l-3 5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 10.5L13.566 8 15 10.5 13.26 13 12 10.5z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M13.5 8L9 15h9l-4.5-7z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M5.86 13.117a.294.294 0 01.24-.47h.538c.258 0 .396-.298.226-.488l-1.237-1.386c-.166-.187-.031-.48.222-.48a.289.289 0 00.245-.449L4.25 7.131a.303.303 0 00-.5 0L1.906 9.844c-.13.193.01.45.245.45.253 0 .388.292.222.479l-1.237 1.386c-.17.19-.032.488.226.488H1.9c.248 0 .389.277.24.47L1.06 14.53A.294.294 0 001.3 15h5.4a.294.294 0 00.24-.47l-1.08-1.412z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22 15H2M7 20c1.325 0 1.175-1 2.5-1 1.324 0 1.175 1 2.5 1 1.324 0 1.176-1 2.5-1 1.323 0 1.176 1 2.5 1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }