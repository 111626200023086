var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass:
            "status-known_svg__status-known_svg__ic-cw-status-learned-cards-paper-24",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass:
              "status-known_svg__status-known_svg__ic-cw-status-learned-cards-paper-24__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c("path", {
            staticClass:
              "status-known_svg__status-known_svg__ic-cw-status-learned-cards-paper-24__checkmark-vector",
            attrs: {
              fill: "#43D281",
              d: "M17.293 7.293a1 1 0 011.497 1.32l-.083.094-8 8a1 1 0 01-1.32.083l-.094-.083-4-4a1 1 0 011.32-1.497l.094.083L10 14.585l7.293-7.292z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }