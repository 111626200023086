var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.387 19.837a8.003 8.003 0 010-15.674A8.295 8.295 0 009.37 3.6C4.747 3.6 1 7.36 1 12c0 4.64 3.747 8.4 8.37 8.4 1.065 0 2.08-.199 3.017-.563z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10 12h4M17 12h1M14 8v8M21 8.124a8 8 0 100 7.752",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }