
'use strict';

import languages_de from '../data/language-list/de/language.json';
import languages_en from '../data/language-list/en/language.json';
import languages_es from '../data/language-list/es/language.json';
import languages_et from '../data/language-list/et/language.json';
import languages_fr from '../data/language-list/fr/language.json';
import languages_it from '../data/language-list/it/language.json';
import languages_ja from '../data/language-list/ja/language.json';
import languages_nl from '../data/language-list/nl/language.json';
import languages_pl from '../data/language-list/pl/language.json';
import languages_pt from '../data/language-list/pt/language.json';
import languages_ru from '../data/language-list/ru/language.json';
import languages_zh_hans from '../data/language-list/zh_Hans/language.json';
import languages_zh_hant from '../data/language-list/zh_Hant/language.json';

// If a new i18n needs to be added, language list json files are from https://github.com/umpirsky/language-list

export default {
    getLanguages: function (language) {
        switch (language) {
            case 'de':
                return languages_de;
            case 'en':
            case 'en-GB':
            case 'en-US':
                return languages_en;
            case 'es':
            case 'es-US':
                return languages_es;
            case 'et':
                return languages_et;
            case 'fr':
                return languages_fr;
            case 'it':
                return languages_it;
            case 'ja':
                return languages_ja;
            case 'nl':
                return languages_nl;
            case 'pl':
                return languages_pl;
            case 'pt':
            case 'pt-BR':
                return languages_pt;
            case 'ru':
                return languages_ru;
            case 'zh-Hans':
                return languages_zh_hans;
            case 'zh-Hant':
                return languages_zh_hant;
            default:
                return languages_en;
        }
    },
};
