
import Raven from 'raven-js';
import Leanplum from 'leanplum-sdk';

import getConfigValue from './configuration.js';

const isProductionKey = key => key.startsWith('prod_');

export var leanplum_started = false;

/**
 * Get Leanplum parameters from Public Conf.
 * Initialize the Leanplum SDK with dev or prod key.
 * Subscribe to Web Push Notifications.
 */
export function startLeanplum (uiLanguage) {

    if (localStorage === null) {
        // Leanplum throws errors if attempted to start with null localStorage on Safari
        return;
    }

    const {
        app_id,
        app_key,
        host_name,
        servlet_name
    } = getConfigValue('leanplum');

    if ((app_id !== undefined) && (app_key !== undefined)) {
        if (isProductionKey(app_key)) {
            Leanplum.setAppIdForProductionMode(app_id, app_key);
        } else {
            Leanplum.setAppIdForDevelopmentMode(app_id, app_key);
        }

        if (host_name && servlet_name) {
            Leanplum.setApiPath(`https://${host_name}/${servlet_name}`);
        }

        Leanplum.addStartResponseHandler(function(success) {
            if (success) {
                const env = isProductionKey(app_key) ? 'production' : 'development';
                console.log(`Leanplum started in ${env} mode.`);
                leanplum_started = true;
            } else {
                console.log('Leanplum start failed');
                Raven.captureMessage('Leanplum start failed.',
                    { level: 'error', logger: 'manual' });
                leanplum_started = false;
            }

        });

        let userAttrs = {};
        if (uiLanguage) {
            userAttrs.source_language = uiLanguage;
        }

        // console.log('Leanplum start called with User Attributes:', userAttrs);
        Leanplum.start(userAttrs);
    } else {
        Raven.captureMessage('Leanplum not configured.',
            { level: 'error', logger: 'manual' });
    }
}

/**
 * Check if user's browser supports Push Notifications.
 */
export function isPushNotificationsSupported () {
    return Leanplum.isWebPushSupported();
}

/**
 * Subscribe user to Push Notifications.
 * Resolves when user has already subscribed or when user gets succesfully subscribed.
 */
export function subscribeToPushNotifications () {

    return new Promise( (resolve, reject) => {

        Promise.resolve()
            .then( () => Leanplum.isWebPushSubscribed())
            .then( isSubscribed => {
                if (isSubscribed) {
                    resolve();
                } else {
                    return Promise.resolve();
                }
            }).then( () => {
                Leanplum.registerForWebPush('./sw.min.js', result => {
                    if (result) {
                        resolve();
                    } else {
                        Raven.captureMessage('Failed to subscribe to Push Notifications.',
                            { level: 'error', logger: 'manual' });
                        reject();
                    }
                });
            });
    });
}

/**
 * @param {string} userId - set Lingvist userId for Leanplum
 */
export function setLeanplumUserId (userId) {
    if (leanplum_started) {
        Leanplum.setUserId(userId);
    }
}

/**
 * @param {object} attrs - set Lingvist user attributes for Leanplum
 */
export function setLeanplumUserAttributes (attrs) {
    if (leanplum_started) {
        console.debug('Leanplum: setting user attributes', attrs);
        Leanplum.setUserAttributes(attrs);
    }
}

/**
 * Track an event to Leanplum
 * @param {string} eventName - name of the event
 * @param {object} eventParameters - named parameters for the event
 */
export function trackLeanplumEvent (eventName, eventParameters = null) {
    if (leanplum_started) {
        if (eventParameters) {
            Leanplum.track(eventName, eventParameters);
        } else {
            Leanplum.track(eventName);
        }
    }

}
