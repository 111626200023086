var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cookie-consent-container" },
    [
      _c("transition", { attrs: { name: "slide-in-fade" } }, [
        this.isVisible
          ? _c("div", { staticClass: "cookie-consent" }, [
              _c(
                "svg",
                {
                  staticClass: "cookie-icon",
                  attrs: {
                    width: "32",
                    height: "32",
                    viewBox: "0 0 32 32",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("circle", {
                    staticClass: "circle-stroked",
                    attrs: {
                      cx: "9",
                      cy: "18",
                      r: "2",
                      stroke: "#ffffff",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "circle-filled",
                    attrs: {
                      cx: "15.5",
                      cy: "15.5",
                      r: "1.5",
                      fill: "#ffffff",
                    },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "circle-filled",
                    attrs: {
                      cx: "24.5",
                      cy: "20.5",
                      r: "1.5",
                      fill: "#ffffff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M7.69213 26C4.82516 23.6159 3 20.0223 3 16.0026C3 9.38469 7.94695 3.92202 14.3453 3.10938C14.2139 3.51518 14.1429 3.94818 14.1429 4.39776C14.1429 6.32447 15.4474 7.94655 17.2215 8.42927C17.0325 8.90572 16.9286 9.4252 16.9286 9.96894C16.9286 12.2763 18.7994 14.1467 21.1071 14.1467C21.7698 14.1467 22.3964 13.9925 22.9531 13.718C23.6438 15.0734 25.0527 16.0018 26.6786 16.0018C27.5298 16.0018 28.3215 15.7473 28.9819 15.3104C28.9939 15.5396 29 15.7703 29 16.0026C29 23.1809 23.1797 29 16 29C14.9675 29 13.963 28.8797 13 28.6522",
                      stroke: "#ffffff",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M11.3284 23.6926C12.4167 24.3535 13.6329 24.776 14.8966 24.9321",
                      stroke: "#ffffff",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M10.7435 8.69458C9.82506 9.35543 9.03948 10.1835 8.42791 11.1355",
                      stroke: "#ffffff",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M20.2614 23.9272C20.6069 23.7415 20.9398 23.5334 21.2581 23.3043",
                      stroke: "#ffffff",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _c("p", [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("cookie_acceptance_information")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("a", {
                    staticClass: "find-out-more",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("cookie_acceptance_findoutmore_button")
                      ),
                    },
                    on: { click: _vm.moreInfo },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "action" },
                [
                  _c("v-button", {
                    attrs: {
                      i18n: { id: "cookie_acceptance_accept_button" },
                      filled: true,
                      color: "white",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.accept.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.document_open && _vm.document_open.hasOwnProperty("content")
        ? _c(
            "modal-popup",
            {
              attrs: { title: _vm.document_open.title, base_width: "50rem" },
              on: {
                close: function ($event) {
                  _vm.document_open = null
                },
              },
            },
            [
              _c("main", {
                staticClass: "document",
                domProps: { innerHTML: _vm._s(_vm.document_open.content) },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }