<template>
    <div class="secure">
        <div class="illustrations with-gradient">
            <div class="items">
                <div class="row">
                    <div class="item">
                        <illustration-secure />
                        <div class="info">
                            <h3 v-html="this.$i18n('product_payment_highlights_title_1')" />
                            <p v-html="this.$i18n('product_payment_highlights_text_1')" />
                        </div>
                    </div>
                    <div class="item">
                        <illustration-no-ads />
                        <div class="info">
                            <h3 v-html="this.$i18n('product_payment_highlights_title_2')" />
                            <p v-html="this.$i18n('product_payment_highlights_text_2')" />
                        </div>
                    </div>
                    <div class="item">
                        <illustration-cancel />
                        <div class="info">
                            <h3 v-html="this.$i18n('product_payment_highlights_title_3')" />
                            <p v-html="this.$i18n('product_payment_highlights_text_3')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import illustrationSecure from "Images/pay/secure/secure-payment.svg";
    import illustrationNoAds from "Images/pay/secure/no-ads.svg";
    import illustrationCancel from "Images/pay/secure/cancel-anytime.svg";

    export default {
        name: 'secure',
        components: {
            illustrationSecure,
            illustrationNoAds,
            illustrationCancel
        }
    }
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';
    div.secure {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        > div.illustrations {
            padding: 2rem 0;
            width: 100%;
            &.with-gradient {
                background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(0,0,0,0) 100%);
            }
            > div.items {
                > div.row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    margin: 1rem 0;
                    @include respond-to('medium') {
                        flex-direction: column;
                        justify-content: center;
                    }
                    > div.item {
                        margin: 0 3rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        max-width: pxToRem(296);
                        @include respond-to('medium') {
                            margin: 2rem;
                            > svg {
                                max-width: pxToRem(300);
                            }
                        }
                        > div.info {
                            margin-top: 1.5rem;
                            text-align: center;
                            > h3 {
                                font-weight: $font-bold;
                                margin-bottom: .5rem;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
