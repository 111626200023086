var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", { attrs: { fill: "#AC6ED1", d: "M15 14h10v10H15z" } }),
      _c("path", {
        attrs: {
          d: "M13 24h10a2 2 0 002-2V11a2 2 0 00-2-2H5a2 2 0 00-2 2v18l5-5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27 29H17a2 2 0 01-2-2V16a2 2 0 012-2h18a2 2 0 012 2v18l-5-5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }