var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-creation-options" },
    [
      _c("h1", {
        domProps: {
          innerHTML: _vm._s(
            this.$i18n("register_credentials_selected_course_heading")
          ),
        },
      }),
      _vm._v(" "),
      _c("course", { attrs: { course: _vm.selected_course } }),
      _vm._v(" "),
      _c("p", {
        domProps: { innerHTML: _vm._s(this.$i18n("register_save_progress")) },
      }),
      _vm._v(" "),
      _c("checkbox", {
        staticClass: "marketing-opt-in",
        attrs: { label: this.$i18n("register_marketing_optin_title") },
        model: {
          value: _vm.marketing_opt_in_,
          callback: function ($$v) {
            _vm.marketing_opt_in_ = $$v
          },
          expression: "marketing_opt_in_",
        },
      }),
      _vm._v(" "),
      _c(
        "footer",
        [
          _c("authentication-options", {
            attrs: {
              options: _vm.account_creation_options,
              more_options_label: this.$i18n("register_more_options"),
            },
            on: { option_selected: _vm.create_account },
          }),
          _vm._v(" "),
          _c("terms"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }