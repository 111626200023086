
import $ from  'jquery';
import Backbone from 'backbone';
import moment from 'moment';

import renderTemplate from '../../util/template.renderer.js';


const STATE = {
    TIME_AVAILABLE: 'time-available',
    NEXT_IS_REPEAT: 'next-is-repeat',
    DATA_UNAVAILABLE: 'data-unavailable'
};

const DebugView = Backbone.View.extend({

    name: 'debug.ticker',

    events: {
        'click a.switch-to-repeat': 'switchToRepeat'
    },

    initialize: function (target_time, notifications_enabled=false) {
        console.log("Debug.Ticker view init");
        this._target_time = target_time;
        this._notifications_enabled = notifications_enabled;
        this._updateInterval = setInterval(() => this._updateTime(), 1000);
    },

    remove: function () {
        clearInterval(this._updateInterval);
        this.unbind();
        Backbone.View.prototype.remove.call(this);
    },

    updateTargetTime (target_time) {
        this._target_time = target_time;
        this._updateTime();
    },

    setNotificationsEnabled (notifications_enabled) {
        this._notifications_enabled = notifications_enabled;
    },

    _updateTime () {
        let {state, transition} = this.getState();

        if (state === STATE.TIME_AVAILABLE) {
            this.$el.find('em.ticker-time').text(this.getRemainingTime());
        } else if (transition && STATE.NEXT_IS_REPEAT) {
            return this._notifyOfRepeat();
        }

        this.$el.find('main.content').attr('data-state', state);
    },

    _notifyOfRepeat: function () {
        if (this._notifications_enabled && document.hidden) {
            if (this._notificationSound === undefined) {
                // this._notificationSound = new Howl({
                //     src: ['audio/alert.m4a']
                // });
            }
            // this._notificationSound.play();
            new Notification('New repeat available!');
        }

    },

    getState () {
        let old_state = this._state;

        if (this._target_time && this._target_time.isAfter(moment())) {
            this._state = STATE.TIME_AVAILABLE;
        } else if (this._target_time && this._target_time.isBefore(moment())) {
            this._state = STATE.NEXT_IS_REPEAT;
        } else {
            this._state = STATE.DATA_UNAVAILABLE;
        }

        return {
            state: this._state,
            transition: old_state && old_state !== this._state
        };
    },

    getRemainingTime () {
        return this._target_time && moment.duration(this._target_time.diff(moment(), 'milliseconds')).format('mm:ss') || null;
    },

    render: function () {
        return Promise.resolve()
            .then(() => renderTemplate('debug/ticker', {
                state: this.getState().state,
                time_remaining: this.getRemainingTime()
            }))
            .then(output => {
                const $element = $(output);
                this.setElement($element);
                $('main#app-root > section.app-header').before($element);
                return this._postRender();
            });
    },

    _postRender: function () {},

    setHidden: function () {
        this.$el.addClass('hidden');
    },

    setVisible: function () {
        this.$el.removeClass('hidden');
    },

    switchToRepeat: function () {
        this.trigger('switch-to-repeat');
    }
});

export default DebugView;
