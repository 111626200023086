var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "catalog", attrs: { id: "catalog" } },
    [
      _vm.has1020product
        ? _c("campaign1020-heading")
        : this.userOnPreTrial || this.introTrialAvailable
        ? _c("free-trial-heading")
        : _vm.showTitle
        ? _c("h1", {
            domProps: {
              innerHTML: _vm._s(this.$i18n("pay_product_recurring_title")),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cards" },
        _vm._l(_vm.productsModified, function (product) {
          return _c(
            "div",
            {
              staticClass: "card",
              class: {
                promoted: product.isPromoted,
                "semi-promoted": product.isSemiPromoted,
                subscribed: product.isUserExistingRecurringService,
                voucherApplied: product.voucherInfoFormatted,
              },
            },
            [
              _c("header", [_c("h3", [_vm._v(_vm._s(product.heading))])]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    product.voucherInfoFormatted &&
                    !product.voucherInfoFormatted.is_forced_free_trial
                      ? [
                          _c("div", { staticClass: "usualPriceWithSaving" }, [
                            _c("span", { staticClass: "usualPrice" }, [
                              _vm._v(_vm._s(product.amountPeriodUsual)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "saving" }, [
                              _vm._v(
                                "-" +
                                  _vm._s(product.voucherInfoFormatted.discount)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "wrapper" }, [
                            _c("span", { staticClass: "totalPrice" }, [
                              _vm._v(
                                _vm._s(
                                  product.voucherInfoFormatted.totalWithCurrency
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "textPeriod" }, [
                              _vm._v("/ " + _vm._s(product.textPerPeriod)),
                            ]),
                            _vm._v(" "),
                            product.voucherInfoFormatted &&
                            product.voucherInfoFormatted.duration &&
                            product.voucherInfoFormatted.trialLengthText
                              ? _c("div", { staticClass: "promo-trial" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        product.voucherInfoFormatted
                                          .trialLengthText
                                      )
                                    ),
                                  ]),
                                ])
                              : product.free_trial_duration &&
                                product.trialLengthText
                              ? _c("div", { staticClass: "promo-trial" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(product.trialLengthText)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            product.voucherInfoFormatted.monthlyWithCurrency &&
                            product.voucherInfoFormatted.monthlyWithCurrency !==
                              product.voucherInfoFormatted.totalWithCurrency
                              ? _c("div", { staticClass: "periodPrice" }, [
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          product.voucherInfoFormatted
                                            .textPeriodPrice
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$i18n("per_month", {
                                            collection:
                                              "product_payment_frequency",
                                          })
                                        ),
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      : [
                          product.isDiscounted
                            ? _c("span", { staticClass: "usualPrice" }, [
                                _vm._v(_vm._s(product.textUsualPrice)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "wrapper" }, [
                            _c("span", { staticClass: "totalPrice" }, [
                              _vm._v(_vm._s(product.textTotalPrice)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "textPeriod" }, [
                              _vm._v("/ " + _vm._s(product.textPerPeriod)),
                            ]),
                            _vm._v(" "),
                            product.voucherInfoFormatted &&
                            product.voucherInfoFormatted.duration &&
                            product.voucherInfoFormatted.trialLengthText
                              ? _c("div", { staticClass: "promo-trial" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        product.voucherInfoFormatted
                                          .trialLengthText
                                      )
                                    ),
                                  ]),
                                ])
                              : product.free_trial_duration &&
                                product.trialLengthText
                              ? _c("div", { staticClass: "promo-trial" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(product.trialLengthText)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            product.textPeriodPrice.length > 0 &&
                            product.textPeriodPrice !== product.textTotalPrice
                              ? _c("div", { staticClass: "periodPrice" }, [
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(_vm._s(product.textPeriodPrice)),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$i18n("per_month", {
                                            collection:
                                              "product_payment_frequency",
                                          })
                                        ),
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "footer",
                [
                  _c("v-button", {
                    attrs: {
                      color:
                        product.isPromoted || product.isSemiPromoted
                          ? "primary"
                          : "secondary",
                      filled: !!(product.isPromoted || product.isSemiPromoted),
                      disabled:
                        _vm.userHasRecurringService &&
                        _vm.paymentProvider !== "apple-in-app",
                      icon:
                        product.isUserExistingRecurringService &&
                        !product.voucherInfo
                          ? "tick"
                          : null,
                      text: _vm.selectProductButtonText(product),
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.selectProduct(product)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.showMessages && _vm.alreadySubscribedMessage
        ? _c("div", { staticClass: "messages" }, [
            _c("p", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.alreadySubscribedMessage)),
            ]),
            _vm._v(" "),
            _vm.subscribedOnPlatformMessage
              ? _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.subscribedOnPlatformMessage),
                  },
                })
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }