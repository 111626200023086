var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "64",
          height: "43",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          attrs: {
            "clip-path": "url(#zdnet-logo_svg__zdnet-logo_svg__clip0)",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            fill: "#2C3143",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M36.8 24.006V10.54h2.474l5.144 8.993V10.54h2.358v13.466h-2.547l-5.067-8.78v8.78h-2.361zm17.914-3.105l2.422.43a4.205 4.205 0 01-1.466 2.155 4.054 4.054 0 01-2.5.74c-1.58 0-2.753-.551-3.513-1.662a5.865 5.865 0 01-.902-3.379c0-1.614.395-2.88 1.186-3.797a3.772 3.772 0 012.99-1.375 3.917 3.917 0 013.192 1.456c.781.96 1.156 2.424 1.124 4.392h-6.04a2.703 2.703 0 00.585 1.784 1.766 1.766 0 001.388.638c.349.009.688-.111.953-.338a2.07 2.07 0 00.58-1.067v.023zm.138-2.607a2.591 2.591 0 00-.54-1.69 1.605 1.605 0 00-1.25-.58 1.62 1.62 0 00-1.304.618 2.476 2.476 0 00-.507 1.668l3.601-.016zm8.533-4.041v2.057H61.74v3.933c-.016.464 0 .929.047 1.391a.564.564 0 00.213.338c.118.085.26.13.406.128a3.04 3.04 0 00.972-.246l.206 2c-.6.264-1.25.396-1.905.385a2.854 2.854 0 01-1.175-.233 1.733 1.733 0 01-.767-.608c-.184-.31-.3-.656-.338-1.014a13.456 13.456 0 01-.078-1.817V16.31h-1.104v-2.057h1.104v-1.94l2.419-1.506v3.446h1.645z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M28.358.47L.385 14.145 14.059 42.12l27.973-13.675L28.358.47zm-.767 23.614h-1.939v-3.247h1.203c3.75 0 3.787-2.82 3.787-3.513 0-.757-.038-3.517-3.787-3.517h-1.71v6.419l-2.955 3.858H7.053l6.867-10.277H7.266l2.155-3.246h10.64l-6.418 10.276h7.716V10.561h6.232c5.564 0 6.955 3.716 6.955 6.756 0 3.639-2.108 6.767-6.955 6.767z",
            },
          }),
        ]
      ),
      _c("defs", [
        _c(
          "clipPath",
          { attrs: { id: "zdnet-logo_svg__zdnet-logo_svg__clip0" } },
          [
            _c("path", {
              attrs: {
                fill: "#fff",
                transform: "translate(.385 .47)",
                d: "M0 0h63.199v41.65H0z",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }