var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { ref: "slider", staticClass: "slider" }, [
    _c(
      "div",
      {
        staticClass: "start knob",
        on: {
          click: function ($event) {
            return _vm.clickStartArrow()
          },
        },
      },
      [_c("arrow-icon")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "end knob",
        on: {
          click: function ($event) {
            return _vm.clickEndArrow()
          },
        },
      },
      [_c("arrow-icon")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "slider_body",
        staticClass: "slider-body-container",
        on: { click: _vm.clickBody, mousedown: _vm.slideStart },
      },
      [
        _c(
          "section",
          { staticClass: "slider-body", class: { tracking: _vm.tracking } },
          [
            _vm._l(this.ticks, function (tick) {
              return _c("div", {
                staticClass: "slider-tick",
                style: {
                  left: `${tick.proportion * 100}%`,
                  visibility: tick.first || tick.last ? "hidden" : "inherit",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clickTick(tick)
                  },
                },
              })
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "slider-knob",
                class: { grow: _vm.knob_animate },
                style: { left: `${_vm.slider_position * 100}%` },
              },
              [
                _c("transition", { attrs: { appear: "" } }, [
                  _c("div", { staticClass: "inner" }, [
                    _c("div", { staticClass: "circle" }),
                  ]),
                ]),
              ],
              1
            ),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "labels" },
      _vm._l(this.ticks, function (tick) {
        return _c(
          "span",
          {
            staticClass: "label-container",
            style: { left: `${tick.proportion * 100}%` },
            on: {
              click: function ($event) {
                return _vm.clickTick(tick)
              },
            },
          },
          [_c("span", { staticClass: "label" }, [_vm._v(_vm._s(tick.value))])]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }