var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "thermometer",
      class: {
        clickable: this.clickable || this.canZoom,
        compact: this.compact,
      },
      on: { click: _vm.clickTriggered },
    },
    [
      !_vm.compact
        ? [
            _c("h3", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("hub_thermometer_title")),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "sub-title" }, [
              _c("span", {
                staticClass: "done",
                domProps: { innerHTML: _vm._s(_vm.subTitle) },
              }),
              _vm._v(" "),
              this.zoomed && this.canZoom && this.currentZoomLevel
                ? _c("span", {
                    staticClass: "percentage",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("hub_thermometer_zoom_info", {
                          args: { zoom_level: _vm.currentZoomLevel },
                        })
                      ),
                    },
                  })
                : _vm._e(),
            ]),
          ]
        : this.canZoom
        ? _c(
            "div",
            { staticClass: "zoom" },
            [
              _vm.zoomed ? _c("ZoomOutIcon") : _c("ZoomInIcon"),
              _vm._v(" "),
              _vm.zoomed
                ? _c("span", {
                    staticClass: "zoom-txt",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("thermometer_metrics_zoom_out")
                      ),
                    },
                  })
                : _c("span", {
                    staticClass: "zoom-txt",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("thermometer_metrics_zoom_in")
                      ),
                    },
                  }),
              _vm._v(" "),
              _c("span", { staticClass: "percentage" }, [
                _vm._v(_vm._s(_vm.currentZoomLevel) + "%"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "bar", staticClass: "thermometer-bar" }, [
        _c("div", {
          staticClass: "repeats",
          style: { width: `${_vm.repeatsWidth}%` },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "progressing",
          style: { width: `${_vm.progressingWidth}%` },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "mastered",
          style: { width: `${_vm.masteredWidth}%` },
        }),
      ]),
      _vm._v(" "),
      _vm.compact
        ? _c("div", { staticClass: "one-liner" }, [
            _c("h4", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("hub_thermometer_title")),
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "sub-title",
              domProps: { innerHTML: _vm._s(_vm.subTitle) },
            }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }