
import dust from 'dustjs-linkedin';
import '../dust-custom-helpers.js';

import Raven from 'raven-js';

// Find template using map and return the output
export default function renderTemplate (name, data = {}, element = undefined) {
    return new Promise( (resolve, reject) => {
        Promise.resolve()
            .then(() => import(  // jshint ignore: line
                /* webpackMode: "eager" */
                `../template/${name}.js`))
            .then(() => {
                console.info(`renderTemplate: ${name}`);

                dust.render(name, data, (error, output) => {
                    if (error) {
                        console.error('Template rendering failed', error);
                        Raven.captureException(error, {level: 'error'});
                        reject();
                    }
                    if (element !== undefined) {
                        element.html(output);
                        resolve();
                    } else {
                        resolve(output);
                    }
                });
            }).catch(error => {
                console.error('Error importing template:', error);
                Raven.captureException(error, {level: 'error'});
            });
    });
}
