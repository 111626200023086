var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "273",
          height: "130",
          viewBox: "0 0 273 130",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M93 38a2 2 0 012-2h69a2 2 0 012 2v7a2 2 0 01-2 2H95a2 2 0 01-2-2v-7zM97 78h-2a2 2 0 01-2-2v-2a2 2 0 012-2h32a2 2 0 012 2v2a2 2 0 01-2 2h-23M104 65h43a2 2 0 002-2v-2a2 2 0 00-2-2H95a2 2 0 00-2 2v2a2 2 0 002 2h2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("circle", { attrs: { cx: "179", cy: "52", r: "3", fill: "#2C3143" } }),
      _c("path", {
        attrs: {
          d: "M72 39a6 6 0 016-6h3v56a4 4 0 004 4h81.5l1 .5V95l-1.5 6H78a6 6 0 01-6-6V39z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M179 51.5V28a4 4 0 00-4-4H85a4 4 0 00-4 4v61a4 4 0 004 4h80.633a2 2 0 011.904 2.613l-2.569 7.98c-.446 1.384 1.142 2.529 2.315 1.669l10.534-7.726a2.001 2.001 0 012.366 0l10.534 7.726c1.173.86 2.761-.285 2.315-1.669l-4.041-12.555a2 2 0 01.721-2.225l10.593-7.769c1.169-.857.645-3.044-.805-3.044h-13c-.868 0-1.697-.56-1.963-1.387l-4.109-12.177c-.447-1.387-2.409-1.387-2.856 0L173.5 76.5c-.266.827-1.072 1.5-1.941 1.5H158.5c-1.45 0-2.056 1.852-.887 2.71L166 87.135",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }