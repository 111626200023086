var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "discount" },
    [
      _c("div", { staticClass: "text" }, [
        _c("h3", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("register_marketing_optin_title")),
          },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("register_marketing_optin_subtitle")),
          },
        }),
      ]),
      _vm._v(" "),
      _c("toggle-button", {
        key: `optIn:toggle`,
        attrs: {
          sync: true,
          color: { checked: "#2C3143" },
          width: 60,
          height: 24,
        },
        on: { change: _vm.save },
        model: {
          value: _vm.optInLocal,
          callback: function ($$v) {
            _vm.optInLocal = $$v
          },
          expression: "optInLocal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }