
import ControllerFactory from '../modules/controller.factory.js';
import Vue from 'vue';
import InsightsView from '../view/insights/main.vue';

export class InsightsController {

    constructor () {
        this.state = {

        };
    }

    go (appView, router, page = null) {
        const InsightsViewComponent = Vue.extend(InsightsView);

        appView.setMainView(new InsightsViewComponent({
            data: {},
            propsData: {
                pageName: page
            }
        }));
    }
}

export const insights_controller_factory = new ControllerFactory(InsightsController);
