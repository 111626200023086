<template>
    <section class="languages" :class="{'single-selected': single_selected}">
        <h2 v-if="heading !== null">{{ heading }}</h2>
        <div class="language"
             :class="{selected: language.selected}"
             v-for="language in languagesOrdered"
             @click="$emit('language_selected', language)">
            <div class="highlight">
                <course-icon :value="language.icon_id"/>
                <div class="name-container">
                    <span class="name" v-html="language.title" />
                    <span class="name-subtitle" v-if="language.subtitle">{{ language.subtitle }}</span>
                </div>
                <span class="new" v-if="language.attrs.new" v-html="$i18n('register_languages_language_new')"></span>
            </div>
        </div>
        <div class="language not-listed" @click="$emit('language_not_listed')" v-if="show_language_not_listed">
            <div class="highlight">
                <course-icon value="unknown"/>
                <div class="name-container">
                    <span class="name" v-html="$i18n('register_select_course_language_name_unknown')"></span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import CourseIcon from 'ViewComponent/course-icon.vue';
import i18nUtils from "Util/i18n";
import { cloneDeep, sortBy } from "lodash";

export class Language {
    constructor (code, icon_id, selected=false, attrs=false) {
        this.code = code;
        this.icon_id = icon_id;
        this.selected = selected;
        this.attrs = attrs || {};
    }
}

export default {
    name: 'register.languages',
    components: {
        CourseIcon
    },
    props: {
        languages: {
            type: Array,
            required: true,
        },
        heading: {
            type: String,
            default: null,
        },
        show_language_not_listed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        single_selected () {
            return this.languages.length === 1 && this.languages[0].selected;
        },
        languagesOrdered() {
            let _languages = cloneDeep(this.languages);
            _languages.map(language => {
                language.title = language.attrs.name || i18nUtils.prop(language.code, null, null,'language_name');
                if (language && language.attrs.name_subtitle) {
                    language.subtitle = language.attrs.name_subtitle;
                } else if (language && language.code === 'en' && language.attrs.course_uuid === 'da261776-e0b9-463f-aa9d-3351b06af950') {
                    language.subtitle = i18nUtils.prop(language.attrs.course_uuid, null, null,'course_subtitle')
                } else {
                    language.subtitle = false;
                }

                return language;
            });

            return sortBy(_languages, 'title');
        }
    },
};
</script>

<style scoped lang="scss">
@import '~Styles/colors';
@import '~Styles/mixins';

section.languages {
    background-color: white;
    margin-bottom: 1rem;

    &.single-selected {
        border-bottom: none;

        > div.language:first-of-type {
            border-top: none;
        }
    }

    > h2 {
        font-weight: $font-bold;
        font-size: 1.625rem;
        text-align: center;
        padding-bottom: 1rem;
    }

    > div.language {
        padding: 0.25rem 0;
        cursor: pointer;
        border-bottom: 1px solid $colorBackgroundDarker;

        &:first-of-type {
            border-top: 1px solid $colorBackgroundDarker;
        }

        &.selected {
            border-bottom: none;
            cursor: default !important;

            > div.highlight {
                background-color: $colorBackgroundLighter;
                cursor: default !important;
            }
        }

        > div.highlight {
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: background-color 0.2s, border 0.2s;
            border-radius: 0.5rem;
            min-height: 3rem;
            padding: 0 1rem;

            &:hover {
                cursor: pointer;
                background-color: $colorBackgroundLighter;
            }

            &:active, &.selected {
                background-color: $colorBackgroundDefault;
            }

            > div.name-container {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                padding: 0 1rem;

                > span.name {
                    font-weight: $font-bold;
                    font-size: 1.0625rem;
                }

                > span.name-subtitle {
                    font-size: 0.75rem;
                }
            }

            > span.new {
                color: $colorSourcePrimary;
                padding: 0.25rem 0.5rem;
                border-radius: 0.25rem;
                background-color: white;
                text-transform: uppercase;
            }

            > span.shorter {
                position: absolute;
                right: 1rem;
                top: -.75rem;
                padding: .25rem .5rem;
                border-radius: .35rem;
                font-weight: $font-bold;
                background-color: $colorBackgroundPurple;
            }
        }
    }
}
</style>
