var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 4c4.4 0 8.6 3 11.3 6.2.4.5.7 1.1.7 1.8s-.2 1.3-.7 1.8C20.6 16.9 16.4 20 12 20c-4.4.1-8.6-3.1-11.3-6.2-.5-.5-.7-1.1-.7-1.8s.2-1.3.7-1.8C3.4 7 7.6 4 12 4zm-9.8 7.5c-.2.3-.2.8 0 1 1.2 1.4 2.8 2.8 4.5 3.8 3.5 2.2 7.1 2.2 10.7 0 1.7-1 3.3-2.4 4.5-3.8.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-1.2-1.4-2.8-2.8-4.5-3.9C12 4 5.8 7.1 2.2 11.5z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.1 9.2c-.7-.3-2.1.1-2.1-1.2 0-.6.4-1 1-1 2.7-.1 5.1 2.3 5 5-.1 6-8.7 6.8-9.9 1-.2-1-.3-3.2 1.3-2.9.5.1.9.7.7 1.2-.7 2.3 1.9 4.5 4.1 3.5 2.4-.9 2.4-4.7-.1-5.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }