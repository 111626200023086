<template>
    <section class="vocabulary-slider" ref="slider">
        <div class="slider-body-container" ref="slider_body" v-on:mousedown="slideStart">
            <section class="slider-body" :class="{ tracking: tracking }">
                    <div
                        class="slider-knob"
                        :style="{ left: `${slider_position * 100}%` }"
                        :class="{ grow: knob_animate }">
                        <transition appear><div class="inner"><div class="circle"></div></div></transition>
                    </div>
            </section>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'vocabulary-slider',
        props: {
            position: {
                type: Number,
                required: true
            },
            max_words: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                knob_animate: false,
                tracking: false,
                slider_position_: 0
            };
        },

        computed: {
            slider_position () {
                if (this.tracking) {
                    return this.slider_position_;
                } else {
                    return this.position / this.max_words;
                }
            }
        },

        methods: {
            _getTrackingPosition (event) {
                const slider_x = this.$refs.slider_body.getBoundingClientRect().left;
                const slider_width = this.$refs.slider_body.offsetWidth;
                return Math.min(Math.max((event.clientX - slider_x) / slider_width, 0), 1);
            },

            _setTrackingPosition: function (event) {
                const tracking_position = this._getTrackingPosition(event);

                this.slider_position_ = tracking_position;
                this.$emit('update-position', parseInt(tracking_position * this.max_words));
            },

            slideStart (event) {
                this.tracking = true;
                this.$emit('start-tracking');
                this._setTrackingPosition(event);
            },

            slideMove (event)  {
                if (this.tracking) {
                    this._setTrackingPosition(event);
                }
            },

            slideEnd (event) {
                if (this.tracking) {
                    this.tracking = false;
                    this.$emit('end-tracking');
                    this.$emit('update-position', parseInt(this._getTrackingPosition(event) * this.max_words));
                }
            },
        },

        created () {
            this.slider_position_ = this.position / this.max_words;

            this._document_mouseup = event => {
                this.slideEnd(event);
            };

            this._document_mousemove = event => {
                this.slideMove(event);
            };

            // Using global listeners because it's easy for user to move mouse from
            // the slider are during slider, as it's common UX practive to be able to
            // do this
            document.addEventListener('mouseup', this._document_mouseup);
            document.addEventListener('mousemove', this._document_mousemove);
        },

        beforeDestroy () {
            document.removeEventListener('mouseup', this._document_mouseup);
            document.removeEventListener('mousemove', this._document_mousemove);
        }
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.vocabulary-slider {
        user-select: none;
        width: 100%;
        padding: 0 0.5rem;
        position: relative;

        > div.slider-body-container {
            padding: 1rem 0;
            cursor: pointer;

            > section.slider-body {
                height: 1px;
                border-radius: 0.5rem;
                background-color: $colorBackgroundDarker;
                direction: ltr;

                &.tracking {
                    > div.slider-knob {
                        transition: left 0s;
                    }
                }

                > div.slider-knob {
                    transition: left 0.25s;
                    bottom: 0;
                    position: absolute;
                    top: 0.25rem;
                    z-index: 2;

                    > div.inner {
                        height: 1.75rem;
                        width: 1.75rem;
                        background-color: $colorBackgroundPurple;
                        border-radius: 1rem;
                        transform: translate(-0.8rem, -1.1rem);
                        transition: transform 1000ms;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        > div.circle {
                            height: 1.125rem;
                            width: 1.125rem;
                            border-radius: 1rem;
                            border: 2px solid $colorSourcePrimary;
                        }
                    }

                    &.grow > div.inner {
                       transform: translate(3rem, -1.1rem);
                    }
                }
            }
        }
    }
</style>
