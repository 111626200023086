// French

export default {
    prefixAgo: "",
    prefixFromNow: "今から",
    suffixAgo: "前",
    suffixFromNow: "後",
    seconds: "1 分未満",
    minute: "約 1 分",
    minutes: "%d 分",
    hour: "約 1 時間",
    hours: "約 %d 時間",
    day: "約 1 日",
    days: "約 %d 日",
    month: "約 1 月",
    months: "約 %d 月",
    year: "約 1 年",
    years: "約 %d 年",
    wordSeparator: ""
};
