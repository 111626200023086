<template>
    <div class="cards-control-more-dropdown" @mouseleave="closeDropdownDelayed" @click="openDropdown">
        <more-dots :class="{ transparent }"/>
        <transition name="fade">
            <div class="dropdown" v-if="open" @mouseenter="openDropdown" @mouseleave="closeDropdown">
                <h3 v-html="this.$i18n('lessons_creator_editor_settings_modal_title')" />
                <cards-toggle-menu :lesson="this.lesson" :cards="this.cards" />
            </div>
        </transition>
    </div>
</template>

<script>
import MoreDots from 'Images/more-dots-vertical.svg';
import VButton from "ViewComponent/v-button.vue";
import CardsToggleMenu from "./cards-toggle-menu.vue";

export default {
    name: 'more-dropdown',
    components: {
        VButton,
        MoreDots,
        CardsToggleMenu
    },
    props: {
        transparent: {
            type: Boolean,
            default: true
        },
        lesson: {
            type: Object,
            required: true
        },
        cards: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            open: false,
            closeTimeout: null
        };
    },
    methods: {
        openDropdown() {
            clearTimeout(this.closeTimeout);
            this.open = true;
        },
        closeDropdown() {
            this.open = false;
        },
        closeDropdownDelayed() {
            this.closeTimeout = setTimeout(() => {
                this.closeDropdown();
            }, 250);
        },
    }
};
</script>

<style lang="scss">
@import "~Styles/colors";
@import "~Styles/mixins";

div.cards-control-more-dropdown {
    position: relative;
    width: pxToRem(40);
    height: pxToRem(40);
    border-radius: pxToRem(8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: fontSize(12);
    line-height: normal;
    cursor: pointer;
    pointer-events: auto;
    &:hover {
        cursor: pointer;
        background-color: $colorBackgroundGraySecondary;
    }
    &:active {
        background-color: $colorBackgroundGrayDarker;
    }

    svg {
        circle {
            transition: fill 200ms ease;
        }
    }
    svg.transparent {
        circle {
            fill: transparent;
        }
    }
    &:hover {
        cursor: pointer;
        svg.transparent {
            circle {
                fill: $colorBackgroundGray;
            }
        }
    }

    div.dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: pxToRem(30);
        @include elevated;
        border-radius: pxToRem(12);
        padding: pxToRem(16);
        background-color: #fff;
        z-index: 100;
        min-width: pxToRem(330);
        @include respond-to('small') {
            min-width: pxToRem(260);
            padding: pxToRem(8);
        }
        &.fade-enter-active, &.fade-leave-active {
            transition: opacity 250ms;
        }
        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }
        > h3 {
            margin-top: .5rem;
            font-weight: $font-bold;
        }
        > div.actions {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            > button.editLesson,
            > button.discardLesson {
                flex: 1;
                margin: .5rem;
            }
        }
    }
}
</style>
