<template>
    <div class="vocabulary-page">
        <h1 v-html="this.$i18n('my_progress_vocabulary')"></h1>
        <div v-if="!this.courseHasVocabulary" class="message">
            This feature is not yet available for that course
        </div>
        <template v-else>
            <div v-if="this.vocabularyDisabled" class="message">
                <h2 v-html="this.$i18n('my_progress_vocabulary_disabled_heading')" />
                <p v-html="this.$i18n('my_progress_vocabulary_disabled_message')" />
            </div>
            <div class="vocabulary-content" v-else-if="this.chart_series && this.learned_text_raw">
                <vocabulary-chart
                    :series="this.chart_series"
                    :max_words="this.max_words"
                    :position="this.position"
                    :words_learned="this.position"
                    v-on:position-update="updatePosition" />
                <vocabulary-text
                    :learned_text_raw="this.learned_text_raw"
                    :max_words="this.max_words"
                    :know-percentage="this.knowPercentage"
                    :position="this.position" />
            </div>
            <loader v-else :centered="true" />
            <div v-show="end_of_FT" class="continuation-button">
                <v-button :i18n="{ id: 'hub_hero_learn_btn', params: { 'today_current_set_cards': todayCurrentSetCards } }" :filled="true" color="primary" icon="continue" route="guess" />
            </div>
        </template>
    </div>
</template>

<script>
import ResultsModel from "Model/results.model.js";
import UserManager from "Modules/usermanager.js";
import VocabularyText from "./text.vue";
import VocabularyChart from "./chart.vue";
import Loader from "ViewComponent/loader.vue";
import Raven from "raven-js";
import { COURSE_FEATURE } from "Modules/course/course.js";
import VButton from 'ViewComponent/v-button.vue';
import {Awards} from "Modules/awards";
import { EventBus } from "Util/vue-event-bus.js";

const MAX_WORDS = 5000;
    export default {
        name: 'vocabulary',
        components: { Loader, VocabularyChart, VocabularyText, VButton },
        data() {
            return {
                courseHasVocabulary: false,
                vocabularyDisabled: false,
                initial: true,
                position: 0,
                max_words: MAX_WORDS,
                learned_text_raw: null,
                chart_series: null,
                wordsLearned: null,
                learnedCurve: null,
                learnedText: null,
                sliderValue: null,
                knowPercentage: 0,
                end_of_FT: false
            }
        },
        computed: {
            todayCurrentSetCards: function() {
                return Awards.cardsDoneInActiveSet();
                }
        },
        methods: {
            updatePosition(position) {
                this.convertDataToPercentLearned();
                this.position = position;
            },
            _unpack_learned_curve (raw_data) {
                return raw_data.reduce((result, part) => {
                    return result.concat(
                        part.series.map((dataPoint, i) => ({x: part.start + i * part.step, y: dataPoint}))
                    );
                }, []);
            },
            async setData () {
                const course = UserManager.instance.getUser().getCourse();
                const course_info = course.getInfo();
                const target_language = course_info.target_language;
                this.courseHasVocabulary = await course.hasFeature(COURSE_FEATURE.VOCABULARY_CURVE);
                this.learned_text_raw = await ResultsModel.getLearnedTextRaw(target_language);
                this.chart_series = this._unpack_learned_curve(await ResultsModel.getLearnedCurve(target_language));

                await this.loadVocabularyData();
                this.convertDataToPercentLearned();
            },
            convertDataToPercentLearned () {
                let learnedWordCount = isNaN(this.position) ? 0 : this.position;

                if (this.learnedCurve) {
                    let curvePart = this.learnedCurve
                        .find(part => part.start - part.step <= learnedWordCount && part.start + part.step * (part.series.length - 1) >= learnedWordCount);
                    this.knowPercentage = Math.round(
                        curvePart.series.find((dataPoint, i) => Math.abs(curvePart.start + curvePart.step * i - learnedWordCount) < curvePart.step)
                    );
                } else {
                    this.knowPercentage = 0;
                }
            },
            async loadVocabularyData () {
                try {
                    let course = UserManager.instance.getUser().getCourse()
                    let target_language = course.getInfo().target_language;
                    let course_statistics = course.getStatistics();
                    let statistics = course_statistics.getData();
                    this.vocabularyDisabled = UserManager.instance.getUser().getCourse().getFastTrackingState().isAssessing();
                    this.wordsLearned = this.vocabularyDisabled ? 0 : course_statistics.getWordsEncountered(statistics);

                    const [learnedCurve, learnedText] = await Promise.all([
                        ResultsModel.getLearnedCurve(target_language),
                        ResultsModel.getLearnedText(target_language)
                    ]);

                    this.learnedCurve = learnedCurve;
                    this.learnedText = learnedText;
                    this.position = Math.round(this.wordsLearned / 10) * 10;
                    if (this.wordsLearned >= this.max_words) {
                        this.max_words = 1000 * Math.ceil((this.wordsLearned + 10) / 1000.0);
                    }
                } catch (error) {
                    Raven.captureException(error, {
                        level: 'error',
                        extra: {
                            message: "Error loading data needed to show the learned vocabulary graph and text."
                        }
                    });
                }
            },
            handleResultsFromFT(eventData) {
                this.end_of_FT = eventData.end_of_FT;
            }
        },
        mounted() {
            this.setData();
        },
        created() {
            if (EventBus.navigationData) {
                this.handleResultsFromFT(EventBus.navigationData);
                delete EventBus.navigationData;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.vocabulary-page {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        min-height: 90vh;

        > h1 {
            text-align: center;
        }
        > div.message {
            background-color: $colorBackgroundGray;
            padding: 1rem;
            margin: 2rem auto;
            max-width: pxToRem(500);
            border-radius: 1rem;
        }
        > div.vocabulary-content {
            display: flex;
            gap: 1.5rem;

            @include respond-to('medium') {
                flex-direction: column;
            }

            > section {
                display: block;
                background-color: #fff;
                padding: 1rem;
                border-radius: pxToRem(16);
            }
        }
        > div.continuation-button {
            display: flex;
            justify-content: center;

            > button.button-component {
                padding-left: pxToRem(24);
                padding-right: pxToRem(24);
                @include respond-to('small') {
                    width: 100%;
                }
                body[data-interface-language="ar"] & {
                    > span.icon > svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

    }
</style>
