
'use strict';

import $ from 'jquery';
import Backbone from 'backbone';

import EventUtils from '../util/event.js';
import ControllerManager from '../modules/controller.manager.js';
import OverlayView from './overlay/overlay.view.js';

import renderTemplate from '../util/template.renderer.js';

import URI from '../util/uri.js';

export default Backbone.View.extend({

    events: {
        'click button.close': 'close',
        'click button.payment': 'payment',
        'click button.decline': 'decline',
        'click div.close': 'close',
        'click a[data-action="navigate"]': 'navigate'
    },

    initialize: function (options) {
        this._data = options.data;
        console.log(`Survey view init. Survey="${options.data.url}"`);
    },

    remove: function () {
        if (this._overlayView !== undefined) {
            this._overlayView.off();
            this._overlayView.remove();
        }
        this.trigger('remove');
        this.unbind();
        window.removeEventListener('message', this._formSubmitEventListener);
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('survey', this._data).then(output => {
            let $element = $(output);
            this.setElement($element);
            $('body').append($element);
            this._postRender();
        });

        return this;
    },

    _postRender: function () {
        let self = this;
        if (this._formSubmitEventListener !== undefined) {
            window.removeEventListener('message', this._formSubmitEventListener);
        }
        this._formSubmitEventListener = event => {
            if(event.data === 'form-submit') {
                self.close();
            }
        };
        window.addEventListener('message', this._formSubmitEventListener, false);
        Backbone.trigger('rendered', 'survey');
    },

    show: function () {
        let self = this;

        setTimeout(function () {
            self.$el.find('div.modal.modal').addClass('show');
            self._overlayView = new OverlayView();
            self._overlayView.on("dismissed", self.close, self);
            self._overlayView.render();
        }, 10);

        return new Promise(function(resolve, reject) {
            self._dismissPromiseResolve = resolve;
            self._dismissPromiseReject = reject;
        });
    },

    close: function () {
        let self = this,
            $modal = this.$el.find('div.modal.show');

        this._overlayView.off();
        EventUtils.oneTransitionEnd($modal, function () {
            self.remove();
        }, 500);

        this._overlayView.remove();
        delete this._overlayView;

        $modal.removeClass('show');

        this._dismissPromiseResolve();
    },

    navigate: function (event) {
        let $target = $(event.currentTarget),
            navigateTarget = $target.data('target');

        if (navigateTarget === 'open-feedback') {
            ControllerManager.instance.getController('Feedback').show('feedback', 'account_and_settings');
        }
    },

    payment: function (event) {
        const $element = $(event.currentTarget);
        const paymentUuid = $element.data('payment-uuid');

        new URI(`lingvist:store?page=payment-extend&payment_uuid=${paymentUuid}`).navigateTo();
    },

    decline: function (event) {
        this.close();
    }
});

