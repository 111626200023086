<template>
    <div class="collapsible-box-stack">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'collapsible-box-stack'
    }
</script>

<style lang="scss" >
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.collapsible-box-stack {
        border-radius: pxToRem(8);
        overflow: hidden;
        > div.collapsible-box {
            border-top: 1px solid $colorBackgroundDarker;
            &:first-child {
                border-top: none;
            }
        }
    }
</style>
