var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.targetRect
    ? _c("div", {
        staticClass: "hint-mark",
        style: {
          left: this.positionLeft,
          top: this.positionTop,
          width: `${this.size}px`,
          height: `${this.size}px`,
        },
        on: { click: _vm.close, mouseover: _vm.closeWithDelay },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }