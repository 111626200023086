import $ from 'jquery';
import Backbone from 'backbone';

import renderTemplate from '../../util/template.renderer.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';
import UserManager from '../../modules/usermanager.js';
import { CATEGORY as EXERCISE_CATEGORY } from '../../modules/exercises/constants.js';
import Vue from "vue";
import LockedMessageView from "../component/locked-message.vue";

const ChallengesView = Backbone.View.extend({

    name: 'challenges',

    tagName: 'main',
    className: 'challenges-view',

    events: {
        'click a.tab': 'selectTab',
        'click a.activity': 'startChallenge',
        'click a.filter': 'filterClicked'
    },

    initialize: function () {
        console.log('Challenges view init');

        this.state = {
            activeTab: 'available',
            filter: 'all'
        };
    },

    setProps: function (props) {
        this.props = props;
        this.state.filter = props.filter;
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('challenges/challenges', this.props, this.$el).then( () => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {
        Backbone.trigger('rendered', this); // Hide the loader
        this.$lockedMessageContainer = this.$('div.locked-message-container');

        if (this.props.isDisabled) {
            this.setLockedMessageView();
        }

        this.tabs = {
            available: {
                label: this.$('a.tab[data-content="available"]'),
                content: this.$('section.challenges-tab-content.available')
            },
            completed: {
                label: this.$('a.tab[data-content="completed"]'),
                content: this.$('section.challenges-tab-content.completed')
            }
        };
    },

    setLockedMessageView () {
        if (this.$lockedMessageContainer) {
            this.$lockedMessageContainer.empty();
            const vueLockedMessageView = document.createElement( "div" );
            vueLockedMessageView.classList.add('vue-locked-message-view');
            const LockedMessageViewComponent = Vue.extend(LockedMessageView);
            const LockedMessageViewInstance = new LockedMessageViewComponent({
                propsData: {
                    closable: false,
                }
            });

            this.$lockedMessageContainer.append(vueLockedMessageView);
            LockedMessageViewInstance.$mount(vueLockedMessageView);
        }
    },

    selectTab: function (event) {
        this.tabs[this.state.activeTab].label.removeClass('active');
        this.tabs[this.state.activeTab].content.removeClass('active');

        const selectedTab = $(event.currentTarget).data('content');

        this.tabs[selectedTab].label.addClass('active');
        this.tabs[selectedTab].content.addClass('active');

        this.state.activeTab = selectedTab;

        if (selectedTab === 'completed') {
            googleAnalyticsCommand('send', 'pageview', '/Challenges/Completed');
            googleAnalyticsCommand('send', 'screenview', { 'screenName': 'Challenges/Completed' });
        }
    },

    filterChallenges: function (filter) {
        this.$('a.filter').removeClass('active');
        this.$(`a.filter[data-type="${filter}"]`).addClass('active');

        this.$('div.challenges-list-content').removeClass(this.state.filter);
        this.$('div.challenges-list-content').addClass(filter);
        this.state.filter = filter;
        this.trigger('activate-filter', filter);

        let noChallengesAvailable = false;
        const $activeTabContent = this.$('section.challenges-tab-content.active');
        const $noChallengesMessage = $activeTabContent.find('div.no-challenges');

        if (filter === 'all') {
            noChallengesAvailable = $activeTabContent.find('a.activity').length === 0;
        } else {
            noChallengesAvailable = $activeTabContent.find(`a.activity[data-category="${filter}"]`).length === 0;
        }

        if (noChallengesAvailable) {
            $noChallengesMessage.addClass('active');
        } else {
            $noChallengesMessage.removeClass('active');
        }

        const eventMap = {
            'all': 'challenges/all',
            'speaking': 'challenges/speaking',
            'grammar': 'challenges/grammar',
            'listening': 'challenges/listening',
            'reading': 'challenges/reading'
        };

        googleAnalyticsCommand('send', 'pageview', eventMap[filter]);
        googleAnalyticsCommand('send', 'screenview', { 'screenName': eventMap[filter] });

        UserManager.instance.getUser().getEventSender().sendNavigationEvent(eventMap[filter], 'open', null);
    },

    filterClicked: function (event) {

        const newFilter = $(event.currentTarget).data('type');

        this.filterChallenges(newFilter);
    },

    startChallenge: function (event) {

        if (this.props.isDisabled) {
            // UserManager.instance.getUser().getCourse().getLimits().showDisabledPopup();
            return;
        }

        const challengeCategory = $(event.currentTarget).data('category');
        const challengeUuid = $(event.currentTarget).data('challenge');

        this.trigger('start-challenge', { challengeUuid });

        switch (challengeCategory) {
            case EXERCISE_CATEGORY.SPEAKING: {
                Backbone.history.navigate(`challenges/speaking?uuid=${challengeUuid}`, { trigger: true });
                break;
            }
            case EXERCISE_CATEGORY.GRAMMAR: {
                Backbone.history.navigate(`challenges/grammar?uuid=${challengeUuid}`, { trigger: true });
                break;
            }
            case EXERCISE_CATEGORY.LISTENING: {
                Backbone.history.navigate(`challenges/listening?uuid=${challengeUuid}`, { trigger: true });
                break;
            }
            case EXERCISE_CATEGORY.READING: {
                Backbone.history.navigate(`challenges/reading?uuid=${challengeUuid}`, { trigger: true });
                break;
            }
        }
    }
});

export default ChallengesView;
