var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M0 20.6a1.2 1.2 0 001.2 1.2H18a1.2 1.2 0 000-2.4H3.6a1.2 1.2 0 01-1.2-1.2v-7a1.2 1.2 0 00-2.4 0v9.4z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21 13.643v4.717c0 .354-.255.64-.568.64H3.474C3.16 19 3 18.74 3 18.387v-4.718",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 6V4.632c0-.35-.307-.632-.686-.632H8.686C8.307 4 8 4.283 8 4.632V6",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21 13v1H3V7.467C3 7.209 3.254 7 3.568 7h16.864c.314 0 .568.209.568.467V8M17 15v-2M7 15v-2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }