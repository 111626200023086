var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "active-words-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "active-words-modal-window" },
        [
          _c("CloseButton", { staticClass: "close", on: { click: _vm.close } }),
          _vm._v(" "),
          _c("h2", {
            staticClass: "title",
            domProps: {
              innerHTML: _vm._s(this.$i18n("thermometer_metrics_title")),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("1", {
                      collection: "thermometer_metrics_description",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("2", {
                      collection: "thermometer_metrics_description",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              this.learningProgress
                ? _c(
                    "div",
                    { staticClass: "blocks" },
                    [
                      _c("thermometer", {
                        attrs: {
                          course: this.course,
                          user: this.user,
                          compact: true,
                        },
                      }),
                      _vm._v(" "),
                      _c("collapsible-box", {
                        attrs: {
                          i18n_title: {
                            id: "thermometer_metrics_words_at_risk_title",
                          },
                          "title-meta": this.learningProgress.repeats_waiting,
                          i18n_text: {
                            ids: ["1", "2"],
                            collection:
                              "thermometer_metrics_words_at_risk_description",
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("IconRepeats")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3400614124
                        ),
                      }),
                      _vm._v(" "),
                      _c("collapsible-box", {
                        attrs: {
                          i18n_title: {
                            id: "thermometer_metrics_words_in_transit_title",
                          },
                          "title-meta": this.learningProgress.progressing,
                          i18n_text: {
                            ids: ["1", "2", "3"],
                            collection:
                              "thermometer_metrics_words_in_transit_description",
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("IconProgressing")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4098813269
                        ),
                      }),
                      _vm._v(" "),
                      _c("collapsible-box", {
                        attrs: {
                          i18n_title: {
                            id: "thermometer_metrics_words_conquered_title",
                          },
                          "title-meta": this.learningProgress.mastered,
                          i18n_text: {
                            id: "1",
                            collection:
                              "thermometer_metrics_words_conquered_description",
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("IconMastered")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          392017909
                        ),
                      }),
                      _vm._v(" "),
                      _c("collapsible-box", {
                        attrs: {
                          i18n_title: {
                            id: "thermometer_metrics_not_yet_seen_words_title",
                          },
                          "title-meta": this.learningProgress.not_seen,
                          i18n_text: {
                            id: "thermometer_metrics_not_yet_seen_words_description",
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("IconNotSeen")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3428024608
                        ),
                      }),
                    ],
                    1
                  )
                : _c("loader", { attrs: { centered: true } }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }