<template>
    <div class="wordlist-search">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="focusInput">
            <path d="M17.5732 13C17.8496 12.2181 18 11.3766 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.3117 18 13.9734 17.3576 15.2697 16.2882L21 22" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            <path d="M7.5 13.5C7.5 13.5 6 12.587 6 10.5C6 8.41301 7.5 7.5 7.5 7.5" stroke="#2C3143" stroke-width="2.29545" stroke-linecap="round"/>
        </svg>
        <input type="text" ref="searchInput" :placeholder="searchPlaceholder" v-model="searchString" @input="debouncedOnChange" />
        <close-icon v-if="searchString && searchString.length > 0" @click.native="clearSearch" class="close" />
    </div>
</template>

<script>
    import i18nUtils from "Util/i18n.js";
    import { debounce } from "lodash";
    import CloseIcon from "Images/close-24x24.svg";

    export default {
        name: 'wordlist-search',
        components: {
            CloseIcon,
        },
        data () {
            return {
                searchString: ''
            }
        },
        methods: {
            clearSearch() {
                this.searchString = '';
                this.focusInput();
                this.triggerSearch();
            },
            focusInput () {
                this.$refs.searchInput.focus();
            },
            triggerSearch () {
                this.$emit('search-for', this.searchString.trim());
            }
        },
        computed: {
            searchPlaceholder () {
                return i18nUtils.prop('dashboard_word_list_searchbar_unfocused');
            },
            debouncedOnChange () { // waits for 500ms after user has finished typing before triggering the search
                return debounce(this.triggerSearch, 500);
            }
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import '~Styles/colors';

    div.wordlist-search {
        background-color: $colorBackgroundLighter;
        padding: pxToRem(12);
        display: flex;
        flex-direction: row;
        gap: 1rem;
        border-radius: .5rem;
        transition: background-color 200ms;
        align-items: center;
        &:hover {
            background-color: rgba($colorBackgroundLighter, 0.8);
        }
        > input {
            width: 100%;
            font-size: fontSize(18);
            &::placeholder {
                color: $colorSourcePrimary;
            }
        }
        > svg.close {
            cursor: pointer;
        }
    }
</style>
