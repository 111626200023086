'use strict';

import _ from 'lodash';
import moment from 'moment';

import UserManager from '../modules/usermanager.js';
import DatetimeUtils from '../util/datetime.js';

import version from '../version.js';
import ResponsivenessUtils from '../util/responsiveness.js';

const LEARNING_VIEWS = {
    'guess': 'learn',
    'read': 'read',
    'read-list': 'read',
    'listen': 'listen',
    'listen-list': 'listen'
};

function _try (method, thisObject) {
    return Promise.resolve().then(function () {
        return method.apply(thisObject);
    }).then(function (result) { // Try serialization !
        JSON.stringify(result, null, 4);
        return result;
    }).catch(function (error) {
        console.error(error);
        return Promise.resolve({
            error: {
                name: error.name,
                message: error.message
            }
        });
    }).catch(function (error) { // If reject didn't return a proper error
        console.error('UnableToSerializeErrorError', `${error.name}:${error.message}`);
        return Promise.resolve({
            error: {
                name: 'UnableToSerializeErrorError',
                message: `${error.name}:${error.message}`,
            }
        });
    });
}

export default class AppState {
    /**
     * Used to create a snapshot of the state of the application for feedback purposes.
     *
     * Gathers information about the learning state and the environment.
     * */

    constructor () {
        /**
         * Starts gathering the app state and sets up a promise for it.
         *
         * The gathering sub-promises must themselves make sure that the don't fail, rather they
         * should capture their exceptions and deal with them by providing dummy objects.
         * */
        var self = this;

        this._gatheringPromise = Promise.resolve().then(function () {
            return Promise.all([
                _try(self._gatherLearningState, self),
                _try(self._gatherClientInfo, self),
                _try(self._gatherSimpleLogs, self),
                _try(self._gatherCourseState, self),
                _try(self._gatherStatisticsState, self),
                _try(self._gatherFullLogs, self),
                _try(self._gatherStorageInfo, self)
            ]);
        }).then(function (data) {
            return Promise.resolve({
                learningState: data[0],
                clientInfo: data[1],
                simpleLogs: data[2],
                courseState: data[3],
                statisticsState: data[4],
                fullLogs: data[5],
                storageInfo: data[6]
            });
        });
    }

    getState () {
        return this._gatheringPromise;
    }

    _getCurrentQuestionData () {
        return Promise.resolve().then(function () {
            return UserManager.instance.getUser().getCourse().getGuessQueue().getCurrentQuestion();
        }).then(function ({status, question}) {
            return Promise.all([
                question.getLexicalUnitData(),
                question.getHomograph(),
                question.getSense(),
                question.getContext(),
                Promise.resolve(question.getSimpleAlgorithmState()),
                question.getEvaluationCriteria()
            ]);
        }).then(function (data) {
            var lexicalUnitData = data[0],
                homograph = data[1],
                sense = data[2],
                context = data[3],
                simpleAlgorithmState = data[4],
                evaluationCriteria = data[5];

            return Promise.resolve({
                type: 'question',
                lexical_unit_data: JSON.stringify(lexicalUnitData),
                homograph_uuid: homograph.uuid,
                sense_uuid: sense.uuid,
                context_uuid: context.uuid,
                evaluation_criteria: evaluationCriteria,
                simple_algorithm_state: simpleAlgorithmState
            });
        }).catch(function (error) {
            return Promise.resolve({
                type: 'question-retrieving-error',
                error: error.name,
                error_message: error.message
            });
        });
    }

    _getLatestObject (activity) {
        var self = this;

        return Promise.resolve().then(function () {
            switch (activity) {
                case 'learn':
                    return self._getCurrentQuestionData();
            }
        });
    }

    _findLatestLearningActivity () {
        var route = _(window.app.router.previousRoutes).findLast(route => LEARNING_VIEWS[route] !== undefined);
        return LEARNING_VIEWS[route];
    }

    _gatherLearningState () {
        var self = this;
        var course = UserManager.instance.getUser().getCourse(),
            latestActivity = this._findLatestLearningActivity() || null;

        return Promise.resolve().then(function () {
            return latestActivity === null ? Promise.resolve(null) : self._getLatestObject(latestActivity);
        }).then(function (latestObject) {

            return Promise.resolve({
                course_uuid: course.UUID,
                latest_activity: latestActivity,
                latest_object: latestObject
            });
        });

    }

    _getEnvironment () {
        let viewportWidth = ResponsivenessUtils.getWidth(),
            viewportHeight = ResponsivenessUtils.getHeight();

        return {
            container_type: 'browser',
            user_agent: navigator.userAgent,
            natural_timestamp: (new Date()).toISOString(),
            corrected_timestamp: moment().local().locale('en').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            timezone_offset: DatetimeUtils.getTimezoneOffset(),
            viewport: {
                width: viewportWidth === undefined ? null : viewportWidth,
                height: viewportHeight === undefined ? null : viewportHeight
            }
        };
    }

    _gatherClientInfo () {
        var self = this;

        return Promise.resolve({
            client_type: 'web',
            version: version.version,
            environment: self._getEnvironment()
        });
    }

    _gatherSimpleLogs () {
        return Promise.resolve(window.app.logger.getLogs('info', 50 * 1024));
    }

    _gatherFullLogs () {
        return Promise.resolve(window.app.logger.getRawLogs());
    }

    _gatherCourseState () {
        return Promise.resolve(UserManager.instance.getUser().getCourse()._stateManager.serializeState());
    }

    _gatherStatisticsState () {
        return Promise.resolve(UserManager.instance.getUser().getCourse().getStatistics()._state);
    }

    _gatherStorageInfo () {
        return Promise.resolve({
            persistentStorageProvider: {
                isStoragePersistent: UserManager.instance.getUser().getStorage().isStoragePersistent()
            }
        });
    }
}
