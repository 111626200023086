export default {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: "önce",
    suffixFromNow: "şu andan itibaren",
    seconds: "bir dakikadan az",
    minute: "yaklaşık bir dakika",
    minutes: "%d dakika",
    hour: "yaklaşık bir saat",
    hours: "yaklaşık %d saat",
    day: "bir gün",
    days: "%d gün",
    month: "yaklaşık bir ay",
    months: "%d ay",
    year: "yaklaşık bir yıl",
    years: "%d yıl",
    wordSeparator: " ",
    numbers: []
};
