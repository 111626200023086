var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "swt-container" }, [
    _c("div", { ref: "swt", staticClass: "swt", style: _vm.tooltipStyle }, [
      _vm._v("\n        " + _vm._s(_vm.swtArray.join(", ")) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }