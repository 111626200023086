<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 8C9 8 9.5 6 12 6C14 6 15 7.7511 15 9C15 12 12 12 12 12V14" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <circle cx="12" cy="18" r="1" fill="white"/>
        <path d="M7.63587 3C8.95464 2.35934 10.4354 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 9.74879 2.74389 7.67132 3.99927 6" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
    export default {
        name: 'help-icon'
    }
</script>
