var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10 4c-3.36 0-6 2.63-6 6s2.64 6 6 6c1.576 0 2.969-.37 3.95-1.182l.702-.58 6.055 6.055a1 1 0 01-1.414 1.414l-4.817-4.816C13.138 17.7 11.546 18 10 18c-4.463 0-8-3.523-8-8 0-4.478 3.537-8 8-8s8 3.522 8 8c0 .945-.162 1.855-.46 2.7a1 1 0 01-1.886-.664c.224-.635.346-1.32.346-2.036 0-3.37-2.64-6-6-6z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 7a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }