var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M17.5 21.874a6 6 0 111.374-8.374L17.5 21.874z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20 3v3M22 9h-4M24 20h-8M26 27H14M19 9l-1 11-1.5 7-4.5 8M21 9l1 11 1.5 7 4.5 8M8 35h10m14 0h-7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }