var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8 12a2 2 0 00-2-2H3v4h3a2 2 0 002-2z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3 6v9a1 1 0 001 1h14.5a2.5 2.5 0 002.5-2.5v0a2.5 2.5 0 00-2.5-2.5H14L9.5 5l-.502 1.005A1.8 1.8 0 017.388 7H7M11.5 8l-1 1M14 11l-1 1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3 20h17.5a1.5 1.5 0 001.5-1.5V18M8 16v4M18 16v4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }