var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { ref: "slider", staticClass: "vocabulary-slider" }, [
    _c(
      "div",
      {
        ref: "slider_body",
        staticClass: "slider-body-container",
        on: { mousedown: _vm.slideStart },
      },
      [
        _c(
          "section",
          { staticClass: "slider-body", class: { tracking: _vm.tracking } },
          [
            _c(
              "div",
              {
                staticClass: "slider-knob",
                class: { grow: _vm.knob_animate },
                style: { left: `${_vm.slider_position * 100}%` },
              },
              [
                _c("transition", { attrs: { appear: "" } }, [
                  _c("div", { staticClass: "inner" }, [
                    _c("div", { staticClass: "circle" }),
                  ]),
                ]),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }