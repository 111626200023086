var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lesson-intro",
      class: { disabled: this.interactionDisabled },
    },
    [
      _c(
        "div",
        { staticClass: "lesson-intro-content" },
        [
          _c("intro-illustration"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text" },
            [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("lessons_creator_editor_intro_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("lessons_creator_editor_intro_text")
                  ),
                },
              }),
              _vm._v(" "),
              _c("v-button", {
                attrs: {
                  size: "medium",
                  filled: true,
                  color: "purple",
                  i18n: { id: "lesson_creator_editor_see_tutorial_button" },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openTutorial.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("upload-file", {
            attrs: { lesson_uuid: this.lesson_uuid },
            on: {
              "text-from-file": _vm.textFromFileArrived,
              "ocr-done": _vm.ocrDone,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }