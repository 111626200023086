'use strict';

export function parse (str, decode) {
    // Create an object with no prototype
    // https://github.com/sindresorhus/query-string/issues/47

    let ret = Object.create(null),
        shouldDecode = (decode || false);

    if (typeof str !== 'string') {
        return ret;
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
        return ret;
    }

    str.split('&').forEach(function (param) {
        var parts = param.split('=');
        // Firefox (pre 40) decodes `%3D` to `=`
        // https://github.com/sindresorhus/query-string/pull/37
        var key = parts.shift();
        var val = parts.length > 0 ? parts.join('=') : undefined;

        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : val;

        if (shouldDecode) {
            key = decodeURIComponent(key);
            if ((val !== undefined) && (val !== null)) {
                val = decodeURIComponent(val);
            }
        }

        if (ret[key] === undefined) {
            ret[key] = val;
        } else if (Array.isArray(ret[key])) {
            ret[key].push(val);
        } else {
            ret[key] = [ret[key], val];
        }
    });

    return ret;
}
