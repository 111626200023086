
'use strict';

import _ from 'lodash';
import moment from 'moment';
import Raven from 'raven-js';

import Schema from '../util/schema.js';

import EventQueue from './eventqueue.js';
import AsyncDestroyable from './async.destroyable.js';
import i18nUtils from '../util/i18n.js';
import Client from "Util/client.js";

const DEFAULT_QUEUE_OPTIONS = {
    syncTimeout: 5000,
    maxQueueLength: 10
};

const IMMEDIATE_SYNC_QUEUE_OPTIONS = {
    syncTimeout: 500,
    maxQueueLength: 5
};

export const EVENT_SCHEMA = {
    GUESS: 'urn:lingvist:schemas:events:guess',
    FAST_TRACKING: {
        PLACEMENT_TEST_ANSWER: 'urn:lingvist:schemas:events:fast_tracking:placement_test:answer',
    },
    EXERCISE_COMPLETE: {
        READING: 'urn:lingvist:schemas:events:exercise_complete:reading',
        LISTENING: 'urn:lingvist:schemas:events:exercise_complete:listening',
        SPEAKING: 'urn:lingvist:schemas:events:exercise_complete:speaking',
        ARTICLE: 'urn:lingvist:schemas:events:exercise_complete:article'
    },
    RELEVANT_WORDS: 'urn:lingvist:schemas:events:relevant_words',
};

// Methods to send events
export default class EventSender extends AsyncDestroyable {

    constructor (user = null) {

        // Call to AsyncDestroyable constructor to wrap all the event sending functions
        super(['sendNavigationEvent', 'sendAwardEvent', 'sendGuessEvent', 'sendFeedbackEvent',
               'sendSpeakingExerciseEvent', 'sendReadingExerciseEvent',
               'sendListeningExerciseEvent', 'sendArticleExerciseEvent',
               'sendDataDownloadRequestEvent', 'sendDeletionRequestEvent', 'syncQueue']);

        // if (!_.isObject(user)) {
        //     throw Error('user is required');
        // }

        this._user = user;
        this._client = new Client(user);

        this._queue = new EventQueue(
            _.clone(DEFAULT_QUEUE_OPTIONS),
            this._client,
            user
        );
    }

    destroy () {
        let self = this;

        return Promise.resolve().then(function () {
            return self._queue.destroy();
        }).then(function () {
            delete self._user;
            delete self._client;
            return Promise.resolve();
        });
    }

    syncQueue () {
        this._queue.sync();
    }

    _getNewEvent (eventSchema, eventData) {
        // Theoretically there could be different event formats based on the schema.

        let eventTs = moment();
        this._resolveEventTimeDeltas(eventSchema, eventData, eventTs);

        return Promise.resolve().then(() => {
            return Promise.all([
                this._client.getUUID(),
                this._client.getSn(),
                Promise.resolve(eventData)
            ]);
        }).then(result => {
            return Promise.resolve({
                schema: eventSchema,
                client_uuid: result[0],
                client_sn: result[1],
                client_event_ts: eventTs.local().locale('en').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                data: result[2]
            });
        });
    }

    /**
     * Changes the necessary times and fields in the incoming data from ts to offset format based
     * on the incoming eventTime
     * @param eventSchema {string}
     * @param eventData {object}
     * @param eventTime {moment}
     * @private
     */
    _resolveEventTimeDeltas (eventSchema, eventData, eventTime) {
        if (
            Schema.isSchemaMinorGE(eventSchema, EVENT_SCHEMA.GUESS, 0, 0) ||
            Schema.isSchema(eventSchema, EVENT_SCHEMA.FAST_TRACKING.PLACEMENT_TEST_ANSWER, 0, 1)
        ) {
            eventData.shown_offset = (moment(eventData.shown_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.shown_ts;
            eventData.opened_offset = (moment(eventData.opened_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.opened_ts;
            eventData.confirmed_offset = (moment(eventData.confirmed_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.confirmed_ts;
        }

        if (
            Schema.isSchemaMinorGE(eventSchema, EVENT_SCHEMA.EXERCISE_COMPLETE.READING, 1, 0) ||
            Schema.isSchemaMinorGE(eventSchema, EVENT_SCHEMA.EXERCISE_COMPLETE.LISTENING, 1, 0) ||
            Schema.isSchemaMinorGE(eventSchema, EVENT_SCHEMA.EXERCISE_COMPLETE.SPEAKING, 1, 0) ||
            Schema.isSchemaMinorGE(eventSchema, EVENT_SCHEMA.EXERCISE_COMPLETE.ARTICLE, 1, 0)
        ) {
            eventData.shown_offset = (moment(eventData.shown_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.shown_ts;
            eventData.opened_offset = (moment(eventData.opened_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.opened_ts;
            eventData.submitted_offset = (moment(eventData.submitted_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.submitted_ts;

            eventData.entry_events.forEach(entryEvent => {
                entryEvent.offset = (entryEvent.ts.valueOf() - eventTime.valueOf()) / 1000;
                delete entryEvent.ts;
            });
        }

        if (Schema.isSchemaMinorGE(eventSchema, EVENT_SCHEMA.RELEVANT_WORDS, 1, 0)) {
            eventData.opened_offset = (moment(eventData.opened_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.opened_ts;
            eventData.continued_offset = (moment(eventData.continued_ts).valueOf() - eventTime.valueOf()) / 1000;
            delete eventData.continued_ts;

            eventData.slider_interactions.forEach(interaction => {
                interaction.offset = (interaction.ts.valueOf() - eventTime.valueOf()) / 1000;
                delete interaction.ts;
            });
        }
    }
    /**
     *  Posts event to queue for sending or if specified sends it immediately
     * @param schema {string} - event schema
     * @param data {object} - event data
     * @param immediate {boolean} - if true send the event immediately
     * @return {Promise<void>} - If immediate is set the promise will resolve only after the event is actually synced
     * @private
     */
    _sendEvent (schema, data, immediate=false) {
        let ev;

        return Promise.resolve().then(() => {
            return this._getNewEvent(schema, data);
        }).then(event => {
            ev = event;
            try {
                if (this._user && this._user.hasActiveCourse()) {
                    this._user.getCourse().addEvent(_.cloneDeep(event));
                }
            } catch (error) {
                Raven.captureException(error, {level: "error", extra: {event: ev}});
            }

            if (immediate) {
                return this._queue.sendEvent(event);
            } else {
                return this._queue.postEvent(event);
            }
        }).catch(error => {
            Raven.captureException(error, {level: "error", extra: {message: 'Error posting event', event: ev}});
            return Promise.reject(error);
        });
    }

    setSyncImmediate () {
        this._queue.setOptions(_.clone(IMMEDIATE_SYNC_QUEUE_OPTIONS));
    }

    setSyncNormal () {
        this._queue.setOptions(_.clone(DEFAULT_QUEUE_OPTIONS));
    }

    /**
     * All event sending functions must be documented in the constructor so they could be wrapped
     * in synchronisation wrappers for object destruction!
     */

    sendNavigationEvent (screen, action, context=null) {
        let self = this;

        console.debug(`EventSender.sendNavigationEvent(screen=${screen} action=${action} context=${context})`);

        let eventData = {
            screen: screen,
            action: action,
            context: context !== null ? context.toString() : null,
        };

        if (self._user && self._user.hasActiveCourse()) {
            eventData.course_uuid = self._user.getCourse().UUID;
        }

        return Promise.resolve().then(function () {
            return self._sendEvent('urn:lingvist:schemas:events:navigation:0.1', eventData);
        });
    }

    sendAwardEventV1 (data) {
        let self = this;

        return Promise.resolve().then(function () {
            return self._sendEvent('urn:lingvist:schemas:events:award:1.1', {
                course_uuid: data.course_uuid,
                award_name: data.award_name,
                time_elapsed: data.time_elapsed,
                cards_completed: data.cards_completed,
                new_cards: data.new_cards,
                correct_repeated_cards: data.correct_repeated_cards,
                wrong_repeated_cards: data.wrong_repeated_cards,
                max_correct_streak: data.max_correct_streak
            });
        });
    }

    sendGuessEvent (question, answer) {
        return Promise.resolve()
            .then(() => Promise.all([
                    question.getLexicalUnitData(),
                    question.getHomograph(),
                    question.getSense(),
                    question.getContext(),
                    Promise.resolve(question.getSimpleAlgorithmState()),
                    question.getEvaluationCriteria()
                ]))
            .then(([lexicalUnitData, homograph, sense, context, simpleAlgorithmState, evaluationCriteria]) => {
                return this._sendEvent('urn:lingvist:schemas:events:guess:0.13', {
                    course_uuid: lexicalUnitData.course.uuid,
                    lexical_unit_uuid: lexicalUnitData.uuid,
                    homograph_uuid: homograph.uuid,
                    sense_uuid: sense.uuid,
                    context_uuid: context.uuid,

                    answer: answer.answer === "" ? null : answer.answer,
                    all_entries: answer.all_entries.map(a => a === "" ? null : a),
                    guess_value: answer.guess_value,

                    shown_ts: answer.showed,
                    opened_ts: answer.opened,
                    confirmed_ts: answer.confirmed,

                    simple_algorithm_state: _.clone(simpleAlgorithmState),
                    evaluation_criteria: evaluationCriteria,

                    word_hint: {},
                    try_again: 'no',
                    guess_params: question.getParameter('guess_params'),

                    // 0.5
                    grammar_pre_hint: null,  // Feature not implemented

                    // 0.6
                    entry_events: answer.entry_events,

                    // 0.7
                    variation_uuid: question.getParameter('variation_uuid'),

                    // 0.9/0.10
                    content_path: question.getParameter('path'),
                    mistake: answer.mistake,

                    repeats_waiting: this._user ? this._user.getCourse().repeats_waiting : null,

                    // 0.13
                    visual: (question.visual) ? question.visual : null,

                    favourite: (!question.hasOwnProperty('favourite') || question.favourite === undefined) ? false : question.favourite,
                });
            }).then(result => {
                if (this._user && this._user.getCourse().getGuessQueue().getQuestionCounts().questionsToShow <= 2) {
                    return Promise.resolve()
                        .then(() => this._queue.sync())
                        .then(() => Promise.resolve(result));
                } else {
                    return Promise.resolve(result);
                }
            });
    }

    sendFeedbackEvent (feedbackData, appState, subscription) {
        let self = this;

        return Promise.resolve().then(function () {
            return appState.getState();
        }).then(function (state) {
            return self._sendEvent('urn:lingvist:schemas:events:feedback:1.1', {
                feedback: {
                    mood: feedbackData.mood,
                    category: feedbackData.category,
                    message: feedbackData.content
                },
                learning_state: state.learningState,
                client_info: state.clientInfo,
                logs: state.simpleLogs,
                extra_data: {},
                subscription
            });
        });
    }

    /**
     *
     * @param exercise_answer {SpeakingExerciseAnswer}
     */
    sendSpeakingExerciseEvent (exercise_answer) {
        return this._sendEvent('urn:lingvist:schemas:events:exercise_complete:speaking:1.1', exercise_answer.getData());
    }

    /**
     *
     * @param exercise_answer {MultipleChoiceExerciseAnswer}
     */
    sendReadingExerciseEvent (exercise_answer) {
        return this._sendEvent('urn:lingvist:schemas:events:exercise_complete:reading:1.1', exercise_answer.getData());
    }

    /**
     *
     * @param exercise_answer {MultipleChoiceExerciseAnswer}
     */
    sendListeningExerciseEvent (exercise_answer) {
        return this._sendEvent('urn:lingvist:schemas:events:exercise_complete:listening:1.1', exercise_answer.getData());
    }

    /**
     * @param exercise_answer {ArticleExerciseAnswer}
     */

    sendArticleExerciseEvent (exercise_answer) {
        return this._sendEvent('urn:lingvist:schemas:events:exercise_complete:article:1.1', exercise_answer.getData());
    }

    sendDataDownloadRequestEvent () {
        return Promise.resolve()
            .then(() => this._sendEvent('urn:lingvist:schemas:events:data_download_request:1.0', {
                language: i18nUtils.currentInterfaceLanguage
            }));
    }

    sendDeletionRequestEvent () {
        return Promise.resolve()
            .then(() => this._sendEvent('urn:lingvist:schemas:events:deletion_request:1.0', {
                language: i18nUtils.currentInterfaceLanguage
            }));
    }

    sendCourseWizardSurveyEvent (action, course_uuid, lesson_uuid, score = null, message = null) {
        let data = null;
        if (action === 'submit') {
            data = {
                course_uuid: course_uuid,
                lesson_uuid: lesson_uuid,
                score: score,
                message: message,
                action: 'submit'
            };
        } else if (action === 'skip') {
            data = {
                course_uuid: course_uuid,
                lesson_uuid: lesson_uuid,
                action: 'skip'
            };
        }
        if (data) {
            return Promise.resolve()
                .then(() => this._sendEvent('urn:lingvist:schemas:events:course_wizard_feedback:survey:1.0', data));
        }
    }

    sendGuessSurveyEvent (action, course_uuid, source = 'guess', score = null, message = null) {
        let data = null;
        if (action === 'submit') {
            data = {
                course_uuid: course_uuid,
                score: score,
                message: message,
                action: 'submit',
                source
            };
        } else if (action === 'skip') {
            data = {
                course_uuid: course_uuid,
                action: 'skip',
                source
            };
        }
        if (data) {
            return Promise.resolve()
                .then(() => this._sendEvent('urn:lingvist:schemas:events:guess_game_feedback:survey:1.1', data));
        }
    }

    /**
     *
     * @param question {Question}
     * @param answer {Answer|null}
     * @param code {string}
     * @param code_label {string}
     * @param user_message {string}
     * @param picture_url {string|null}
     */
    sendFeedbackGuessEvent (question, answer, code, code_label, user_message, picture_url = null) {

        return Promise.resolve()
            .then(() => question.getLexicalUnitData())
            .then(lexical_unit_data => this._sendEvent('urn:lingvist:schemas:events:feedback:guess:1.2', {
                course_uuid: question.courseUUID,
                question_variation_uuid: question.getParameter('variation_uuid'),
                question: question.getFullData(),
                lexical_unit_data: JSON.stringify(lexical_unit_data),
                answer: answer ? {
                    answer: answer.answer,
                    all_entries: answer.all_entries,
                    guess_value: answer.guess_value,
                    entry_events: answer.entry_events,
                    mistake: answer.mistake
                } : null,

                code: code,
                message: code_label,
                user_message: user_message,
                picture_url
            }));
    }

    /**
     *
     * @param course_uuid {String}
     * @param word {String}
     * @param variation_uuid {String}
     * @param lexical_unit {String}
     * @param context_uuid {String}
     * @param context {String}
     */
    async sendSwtEvent (course_uuid, word, variation_uuid, lexical_unit, context_uuid, context) {
        return await this._sendEvent('urn:lingvist:schemas:events:word_translation:1.0', {
            course_uuid,
            screen: 'guess',
            word,
            variation_uuid,
            lexical_unit,
            context_uuid,
            context,
        });
    }

    /**
     *
     * @param source {String}
     * @param question {Object}
     */

    async sendMuteWordEventForQuestion (source, question) {
        return Promise.resolve()
            .then(() => Promise.all([
                question.getLexicalUnitData(),
                question.getHomograph(),
                question.getSense(),
                question.getContext(),
            ]))
            .then(([lexicalUnitData, homograph, sense, context]) => {
                return this._sendEvent('urn:lingvist:schemas:events:mute:lexical_unit:2.0', {
                    course_uuid: lexicalUnitData.course.uuid,
                    variation_uuid: question.getParameter('variation_uuid'),
                    lexical_unit_uuid: lexicalUnitData.uuid,
                    homograph_uuid: homograph.uuid,
                    sense_uuid: sense.uuid,
                    context_uuid: context.uuid,
                    content_path: question.getParameter('path'),
                    source
                });
            });
    }

    /**
     *
     * @param course_uuid {String}
     * @param variation_uuid {String}
     * @param lexical_unit_uuid {String}
     * @param homograph_uuid {String}
     * @param sense_uuid {String}
     * @param context_uuid {String}
     * @param content_path {String}
     * @param source {String}
     */

    async sendMuteWordEvent (course_uuid, variation_uuid, lexical_unit_uuid, homograph_uuid, sense_uuid, context_uuid, content_path, source) {
        const data = {
            course_uuid,
            variation_uuid,
            lexical_unit_uuid,
            homograph_uuid,
            sense_uuid,
            context_uuid,
            content_path,
            source
        };

        return await this._sendEvent('urn:lingvist:schemas:events:mute:lexical_unit:2.0', data);
    }


    /**
     *
     * @param course_uuid {String}
     * @param lexical_unit_uuid {String}
     * @param homograph_uuid {String}
     * @param sense_uuid {String}
     * @param context_uuid {String}
     * @param content_path {String}
     */

    async sendUnMuteWordEvent (course_uuid, lexical_unit_uuid, homograph_uuid, sense_uuid, context_uuid, content_path) {
        return await this._sendEvent('urn:lingvist:schemas:events:unmute:lexical_unit:2.0', {
            course_uuid,
            lexical_unit_uuid,
            homograph_uuid,
            sense_uuid,
            context_uuid,
            content_path
        });
    }

    /**
     *
     * @param course_uuid {String}
     * @param opened_ts {moment.Moment}
     * @param continued_ts {moment.Moment}
     * @param slider_interactions {Array<object>}
     */
    async sendRelevantWordsEvent (course_uuid, opened_ts, continued_ts, slider_interactions) {
        return await this._sendEvent('urn:lingvist:schemas:events:relevant_words:1.0', {
            course_uuid,
            opened_ts,
            continued_ts,
            slider_interactions: slider_interactions.map(interaction => ({
                type: interaction.type,
                position: interaction.position,
                ts: interaction.ts
            }))
        });
    }

    /**
     *
     * @param course_uuid {String}
     * @param variation_uuid {String}
     * @param lexical_unit_uuid {String}
     * @param visual {Object}
     */

    async sendVisualChoiceEvent (course_uuid, variation_uuid, lexical_unit_uuid, visual) {
        return await this._sendEvent('urn:lingvist:schemas:events:visual:choice:1.0', {
            course_uuid,
            variation_uuid,
            lexical_unit_uuid,
            visual
        }, true);
    }

    /**
     *
     * @param question_id {String}
     * @param question_raw {String}
     * @param answer_id {String|null}
     * @param answer_raw {String|null}
     * @param skipped {Boolean}
     */

    async sendAuxQuestionAnswerEvent (question_id, question_raw, answer_id, answer_raw, skipped) {
        return await this._sendEvent('urn:lingvist:schemas:events:auxiliary_question_answer:1.0', {
            question_id,
            question_raw,
            answer_id,
            answer_raw,
            skipped
        });
    }

    /**
     *
     * @param email_address {String|null}
     * @param source_language {String}
     * @param source_language_code {String}
     * @param target_language {String}
     * @param target_language_code {String}
     * @param comment {String}
     */

    async sendLanguageRequestEvent (email_address, source_language, source_language_code, target_language, target_language_code, comment = '') {
        return await this._sendEvent('urn:lingvist:schemas:events:language_request:1.0', {
            email_address,
            source_language,
            source_language_code,
            target_language,
            target_language_code,
            comment
        });
    }

    /**
     *
     * @param course_uuid {String}
     * @param variation_uuid {String}
     * @param lexical_unit_uuid {String}
     * @param homograph_uuid {String}
     * @param sense_uuid {String}
     * @param context_uuid {String}
     * @param note {String}
     */

    async sendNoteChangeEvent (course_uuid, variation_uuid, lexical_unit_uuid, homograph_uuid, sense_uuid, context_uuid , note = '') {
        return await this._sendEvent('urn:lingvist:schemas:events:lexical_unit:note:1.0', {
            course_uuid,
            variation_uuid,
            lexical_unit_uuid,
            homograph_uuid,
            sense_uuid,
            context_uuid,
            note
        });
    }

    /**
     *
     * @param course_uuid {String}
     * @param variation_uuid {String}
     * @param lexical_unit_uuid {String}
     * @param homograph_uuid {String}
     * @param sense_uuid {String}
     * @param context_uuid {String}
     * @param favourite {Boolean}
     */

    async sendFavouriteWordEvent (course_uuid, variation_uuid, lexical_unit_uuid, homograph_uuid, sense_uuid, context_uuid , favourite) {
        return await this._sendEvent('urn:lingvist:schemas:events:lexical_unit:favourite:1.0', {
            course_uuid,
            variation_uuid,
            lexical_unit_uuid,
            homograph_uuid,
            sense_uuid,
            context_uuid,
            favourite
        });
    }

    /**
     *
     * @param course_uuid {String}
     * @param variation_uuid {String}
     * @param lexical_unit_uuid {String}
     * @param homograph_uuid {String}
     * @param sense_uuid {String}
     * @param context_uuid {String}
     * @param in_playlist {Boolean}
     */

    async sendPlaylistWordEvent (course_uuid, variation_uuid, lexical_unit_uuid, homograph_uuid, sense_uuid, context_uuid , in_playlist) {
        return await this._sendEvent('urn:lingvist:schemas:events:lexical_unit:playlist:1.0', {
            course_uuid,
            variation_uuid,
            lexical_unit_uuid,
            homograph_uuid,
            sense_uuid,
            context_uuid,
            in_playlist
        });
    }

    /**
     *
     * @param course_uuid {String}
     * @param level_assessment_choice {String}
     */

    async sendUserLevelAssessmentChoice (course_uuid, level_assessment_choice) {
        return await this._sendEvent('urn:lingvist:schemas:events:user_level_assessment_choice:0.1', {
            course_uuid,
            level_assessment_choice
        });
    }
}
