var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M0 0h24v24H0z" } }),
        _c("path", {
          attrs: {
            stroke: "#FFF",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M13.73 4.99l7.532 13.008A2 2 0 0119.532 21H4.468a2 2 0 01-1.731-3.002L10.269 4.99a2 2 0 013.462 0z",
          },
        }),
        _c("circle", { attrs: { cx: "12", cy: "17", r: "1", fill: "#FFF" } }),
        _c("path", {
          attrs: {
            stroke: "#FFF",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M12 14V9",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }