<template>
    <div class="swt-container">
        <div class="swt" ref="swt" :style="tooltipStyle">
            {{ swtArray.join(', ') }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'card-swt',
        props: {
            swtInfo: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                tooltipStyle: null
            }
        },
        computed: {
            swtArray() {
                return this.swtInfo.translations.map((item) => {
                    return item.translation;
                });
            }
        },
        methods: {
            positionTooltip() {
                this.$nextTick(() => {
                    let tooltipWidth = this.$refs.swt.offsetWidth;
                    let _left = (this.swtInfo.position.left + this.swtInfo.rect.width / 2) - tooltipWidth / 2;
                    this.tooltipStyle = { top: this.swtInfo.position.top + 'px', left: _left + 'px' };
                });
            }
        },
        mounted() {
            this.positionTooltip();
        },
        watch: {
            swtInfo(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.positionTooltip();
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";
    div.swt-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        > div.swt {
            position: absolute;
            margin-top: pxToRem(48);
            padding: pxToRem(16);
            border-radius: pxToRem(8);
            background-color: $colorBackgroundLighter;
            border: 1px solid $colorBackgroundDarker;
            font-size: 1rem;
            font-family: $defaultFonts;
            color: $colorSourcePrimary;
            @include elevated;
            z-index: 100;
            &::before {
                content:'';
                position:absolute;
                top: -10px;
                width: 10px;
                height: 10px;
                left: 50%;
                transform: translate(-50%,50%) rotate(45deg);
                background-color: $colorBackgroundLighter;
                border-left: 1px solid $colorBackgroundDarker;
                border-top: 1px solid $colorBackgroundDarker;
                z-index: 90;
            }
        }
    }
</style>
