var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "has-account-ask main-content" },
    [
      this.email
        ? [
            this.oauth_input
              ? _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("signin_social_profile_label", {
                        args: { provider: this.oauth_input.provider },
                      })
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("signin_social_profile_error_no_account")
                ),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("signin_social_profile_no_account_guide_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("1", {
                      collection: "signin_social_profile_guide_text",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("2", {
                      collection: "signin_social_profile_guide_text",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("v-button", {
                    attrs: {
                      filled: true,
                      color: "primary",
                      i18n: { id: "register_error_log_in_link" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.navigateToLogin.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      color: "secondary",
                      i18n: { id: "credentials_ask_create_new_account_btn" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.createNewAccount.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : [
            this.oauth_input
              ? _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("signin_social_profile_label", {
                        args: { provider: this.oauth_input.provider },
                      })
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("signin_social_profile_error_no_email")
                ),
              },
            }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.email))]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("signin_social_profile_no_email_guide_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("1", {
                      collection: "signin_social_profile_guide_text",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("2", {
                      collection: "signin_social_profile_guide_text",
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("v-button", {
                    attrs: {
                      filled: true,
                      color: "primary",
                      i18n: { id: "register_error_log_in_link" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.navigateToLogin.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      borderless: true,
                      i18n: { id: "credentials_ask_create_new_account_btn" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.createNewAccount.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }