var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M20 6a4 4 0 11-8 0 4 4 0 018 0z", fill: "#63E1E7" },
      }),
      _c("path", {
        attrs: {
          d: "M3 7c1.44 0 4.56-.238 6-2-2.76 3.548-7.12 12.43-1 15 5.138 2.157 11.072-4.433 8.13-15.526m0 0A26.944 26.944 0 0016 4c.036.145.08.304.13.474zm0 0C16.77 6.597 18.6 10.456 21 11",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }