var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M6 4c.6 0 1 .4 1 1v3c0 .6.4 1 1 1h2c.6 0 1 .4 1 1v12c0 .6-.4 1-1 1s-1-.4-1-1V11H8c-1.7 0-3-1.3-3-3V5c0-.6.4-1 1-1zM19 4c.6 0 1 .4 1 1v3c0 1.7-1.3 3-3 3h-2c-.6 0-1-.4-1-1s.4-1 1-1h2c.6 0 1-.4 1-1V5c0-.6.4-1 1-1zm-4 10c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1v-7c0-.6.4-1 1-1z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#8EA3EE",
          d: "M16 4.5C16 6.4 14.4 8 12.5 8S9 6.4 9 4.5 10.6 1 12.5 1 16 2.6 16 4.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }