var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7.665 13.153a4.5 4.5 0 11-5.512 3.182M8 4h-.6A2.4 2.4 0 005 6.4V9.91M19 4h.6A2.4 2.4 0 0122 6.4v8.2a2.4 2.4 0 01-2.4 2.4H15m0-13h-3M7 17l-1.5-1.5M7 17v2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("rect", {
        attrs: {
          x: "8",
          y: "7",
          width: "11",
          height: "3",
          rx: "1.2",
          fill: "#8EA3EE",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }