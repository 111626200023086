import $ from 'jquery';
import Backbone from 'backbone';
import EventUtils from '../../util/event.js';
import renderTemplate from '../../util/template.renderer.js';

export const CONFIRM_ACTION = {
    OK: 'ok',
    CANCEL: 'cancel'
};

export const MESSAGE_CLASS = {
    ERROR: 'error'
};

export const ConfirmationView = Backbone.View.extend({

    className: 'confirmation-container',

    events: {
        'click a.confirm-action': 'confirmAction'
    },

    initialize: function (data, options={background:true}) {
        $('body').append(this.$el);
        if (!options.background) {
            this.$el.addClass('no-background');
        }
        this.data = data;
    },

    render: function () {
        return Promise.resolve().then( () => {
            renderTemplate('confirmation', this.data, this.$el).then( () => {
                return Promise.resolve();
            });
        });
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    show: function () {
        return Promise.resolve()
            .then(() => this.render())
            .then(() => {
                this.$el.addClass('displayed');
                return new Promise(resolve => setTimeout(resolve, 25));
            })
            .then( () => this.$el.addClass('show'));
    },

    hide: function () {
        return new Promise( resolve => {
            this.$el.removeClass('show');
            EventUtils.on('transitionend', this.$el, event => {
                if (event.originalEvent.propertyName === 'opacity') {
                    this.$el.removeClass('displayed');
                    EventUtils.off('transitionend', this.$el);
                    resolve();
                }
            });
        });
    },

    confirmAction: function (event) {
        const action = $(event.currentTarget).data('action');
        this.trigger('confirm', action);
    }
});
