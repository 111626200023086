
'use strict';

export default {
    applyPatches: function () {
        /**
         * This applies the patches necessary for backward compatibility of this application
         */


        return Promise.resolve().then(function () {
            // No patches currently
            return Promise.resolve();
        });
    }
};
