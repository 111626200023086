var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "edit-in-sidebar-icon",
      attrs: {
        title: this.$i18n("lessons_creator_editor_tooltip_edit_in_sidebar"),
      },
    },
    [_c("open-sidebar-icon")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }