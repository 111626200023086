var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "274",
          height: "130",
          viewBox: "0 0 274 130",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M176 92V80.5l-.193 4.82a8 8 0 01-7.993 7.68h-35.493a4 4 0 00-3.473 2.015L126 100h42a8 8 0 008-8zM83.5 52v40a8 8 0 008 8h9.5l12 17 5.5-7-8.84-15.028A4 4 0 00106.212 93H100.5a8 8 0 01-8-8V47.331c0-.22.018-.44.055-.658L93 44h-1.5a8 8 0 00-8 8z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M176.5 57v28a8 8 0 01-8 8h-38l-11.562 18L108 93h-7.5a8 8 0 01-8-8V48a8 8 0 018-8H149",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: {
          r: "4",
          transform: "matrix(1 0 0 -1 113.5 68)",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: {
          r: "4",
          transform: "matrix(1 0 0 -1 134.5 68)",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: {
          r: "4",
          transform: "matrix(1 0 0 -1 155.5 68)",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M185.5 38v8a2 2 0 01-2 2h-3.089a2 2 0 00-.788.162l-6.246 2.676a2 2 0 01-.788.162H162m23.5-19v-1a2 2 0 00-2-2h-2.575a2 2 0 01-1.524-.705l-7.425-8.735a2.002 2.002 0 01-.476-1.295V14a2 2 0 00-2-2h-3a2 2 0 00-2 2v4.814c0 .124.011.247.034.369l1.022 5.448A2 2 0 01163.59 27h-.84M161.5 39h4a2 2 0 012 2v2a2 2 0 01-2 2h-2m-6-6h-1a2 2 0 00-2 2v2a2 2 0 002 2h2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M163.5 39h1a2 2 0 002-2v-2a2 2 0 00-2-2H163m-6.5 6h-1a2 2 0 01-2-2v-2a2 2 0 012-2h1M162 33h.5a2 2 0 002-2v-2a2 2 0 00-2-2h-8a2 2 0 00-2 2v2a2 2 0 002 2h2M163.5 45v0a2 2 0 012 2v2a2 2 0 01-2 2h-5a2 2 0 01-2-2v-2a2 2 0 012-2h1.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }