
'use strict';

import $ from 'jquery';
import Backbone from 'backbone';
import Raven from 'raven-js';
import { blockAdBlock } from './util/blockadblock.js';
import moment from 'moment';

import { getPersistentStorageProvider } from'./modules/persistent.storage.provider.js';

import Router from './router.js';
import UserModel from './model/user.model.js';

import UserManager from './modules/usermanager.js';
import ControllerManager from './modules/controller.manager.js';

import { factory as onboarding_controller_factory } from './controller/onboarding.js';
import { insights_controller_factory } from './controller/insights.js';
import LoaderController from './controller/loader.js';
import ErrorController from './controller/error.overlay.js';
import { guess_controller_factory } from './controller/guess.js';
import { feedback_controller_factory } from './controller/feedback.js';
import { modal_messages_controller_factory } from './controller/modal.messages.js';
import { debug_controller_factory } from './controller/debug.js';
import { classrooms_controller_factory } from './controller/classrooms.js';
import { pay_controller_factory } from './controller/pay.js';
import { post_signin_actions_controller_factory } from './controller/post.signin.actions.js';
import { account_controller_factory } from 'Controller/account.js';
import { signin_controller_factory } from './controller/signin.js';
import { analytics_controller_factory } from './controller/analytics.js';
import { lessons_controller_factory } from './controller/lessons.js';
import { TermsAndConditionsController } from './controller/terms_and_conditions.js';
import { survey_controller_factory } from './controller/survey.js';

import UrlParameters from 'url-parameters';
import AuthUtils from './util/auth.js';
import i18nUtils from './util/i18n.js';
import i18nVuePlugin from './util/i18n-vue-plugin.js';
import ExportUtils from './util/export.js';
import jqueryAjax from './util/jquery.ajax.js';
import getConfigValue from './util/configuration.js';
import Courses from './modules/courses.js';
import Classrooms from './modules/classrooms/classrooms.js';
import googleAnalyticsCommand from './util/google-analytics.js';
import { tagManagerCommand } from './util/google-analytics.js';
import { trackLeanplumEvent } from './util/leanplum.js';
import { startLeanplum } from './util/leanplum.js';
import { loadGooglePlatformSdk } from './modules/third_party_signin/google.js';
import Vue from 'vue';
import { unauthenticated_api_client } from './util/unauthenticated_api_client.js';
import { UserAccounts } from './modules/user.accounts.js';


let uiLanguage = null;

loadGooglePlatformSdk();

Promise.resolve().then(function () {
    return Promise.resolve().then(function () {
        return getPersistentStorageProvider().getItemAnonymous('interfaceLanguage');
    }).then(function (interfaceLanguage) {
        uiLanguage = (interfaceLanguage || i18nUtils.getPreferredUiLanguage());
        return i18nUtils.setInterfaceLanguage(uiLanguage);
    }).then(() => {
        Vue.use(i18nVuePlugin, {i18nUtils});
    });
}).then(function () {
    return jqueryAjax.setup();
}).then(function () {
    return AuthUtils.setAuth();
}).then(function () {
    return Courses.initialize(unauthenticated_api_client);
}).then(function () {
    return Classrooms.initialize(unauthenticated_api_client);
}).then(function () {

    const router = new Router();
    ExportUtils.export('app.router', router);

    ErrorController.initialize();
    ControllerManager.instance.addController('ErrorController', ErrorController);

    LoaderController.initialize();
    ControllerManager.instance.addController('LoaderController', LoaderController);

    let onboarding_controller = onboarding_controller_factory.getController();
    ControllerManager.instance.addController('Onboarding', onboarding_controller);

    let insights_controller = insights_controller_factory.getController();
    ControllerManager.instance.addController('Insights', insights_controller);

    let guess_controller = guess_controller_factory.getController();
    ControllerManager.instance.addController('Guess', guess_controller);
    guess_controller.initialize(onboarding_controller);

    const terms_and_conditions_controller = new TermsAndConditionsController(router);
    terms_and_conditions_controller.initialize();
    ControllerManager.instance.addController('TermsAndConditions', terms_and_conditions_controller);

    const userAccounts = new UserAccounts();
    userAccounts.startListeners();

    if (getConfigValue('debug')) {
        ControllerManager.instance.addController('Debug', debug_controller_factory.getController());
    } else {
        if (Vue) {
            Vue.config.devtools = false;
            Vue.config.debug = false;
            Vue.config.silent = true;
        }
    }

    ControllerManager.instance.addController('Feedback', feedback_controller_factory.getController());
    ControllerManager.instance.addController('ModalMessages', modal_messages_controller_factory.getController());
    ControllerManager.instance.addController('Survey', survey_controller_factory.getController());
    ControllerManager.instance.addController('Classrooms', classrooms_controller_factory.getController());
    ControllerManager.instance.addController('Pay', pay_controller_factory.getController());
    ControllerManager.instance.addController('PostSigninActions', post_signin_actions_controller_factory.getController());
    ControllerManager.instance.addController('Account', account_controller_factory.getController());
    ControllerManager.instance.addController('Signin', signin_controller_factory.getController());
    ControllerManager.instance.addController('Analytics', analytics_controller_factory.getController());
    ControllerManager.instance.addController('Lessons', lessons_controller_factory.getController());

    Backbone.on('preRoute', function (route, parameters = null) {

        try {
            console.info(`Routing: ${route}, parameters: ${parameters}`);

            let context = null;

            googleAnalyticsCommand('set', 'page', route);
            googleAnalyticsCommand('send', 'pageview');
            googleAnalyticsCommand('send', 'screenview', { 'screenName': route });

            if (parameters && parameters.indexOf('uuid') !== -1) {
                context = parameters.split('=')[1];
            }
            if(!router.isGuestView(route)) {
                UserManager.instance.getUser().getEventSender().sendNavigationEvent(route, 'open', context);
            }
            router.previousRoutes.push(route);
            if (router.previousRoutes.length > 1000) {
                router.previousRoutes.shift();
            }
        } catch (error) {
            console.error('preRoute error', error);
        }
    });

    router.on('route', function () {
        // Control background blurring for guest views on route (after actually)
        if ((router.isGuestView() && Backbone.history.fragment !== 'home') || Backbone.history.fragment === 'settings') {
            $('section.app-background.blurred').removeClass('hidden');
        } else {
            $('section.app-background.blurred').addClass('hidden');
        }
    });

    // Don't render anything based on the initial fragment, instead navigate to
    // 'empty' to let further processes to navigate to the required view
    Backbone.history.start({silent: true, pushState: false});

    // Global ajax error - redirects to sign-in screen on auth error
    $(document).ajaxError(function(event, jqxhr, settings, thrownError) {
        if (jqxhr.status === 401 &&
            settings.url.indexOf(getConfigValue('api-url') + '/login') !== 0 &&
            settings.url.indexOf(getConfigValue('api-url') + '/signin') !== 0 ) {
            UserModel.signout(true);
            Backbone.history.navigate('signin', {trigger: true});
        // TODO: remove it completely
        // (TH)  I don't think this is longer necessary
        //       rather this breaks user experience on temporary server issues (eg upgrade)
        //       only in specific conditions we should trigger 'server-error'
        // } else if (jqxhr.status === 500) {
        //    console.error('document.ajaxError', event, jqxhr.status, settings);
        //    Backbone.trigger('server-error');
        }
    });

    var search = Backbone.history.location.search;
    var fragment = Backbone.history.fragment;

    // navigate to empty, else router will not navigate to guess
    Backbone.history.navigate('', {trigger: true});

    tagManagerCommand({ 'event': 'StartApp' });

    // adblockDetect(adblockDetected => {
    //     // Ad-blockers also block, so starting it would just cause an error - therefore there is not point to try
    //     if (!adblockDetected) {
    //         try {
    //             startLeanplum(uiLanguage);
    //             trackLeanplumEvent('AppStarted');
    //         } catch (error) {
    //             // If localStorage is blocked a security error is thrown, and there is not much we can do about it
    //             if (error.name !== 'SecurityError') {
    //                 throw error;
    //             }
    //         }
    //     } else {
    //         console.log(`Leanplum not started because adblockDetected=${adblockDetected}`);
    //     }
    // });

    Promise.resolve()
        .then(() => blockAdBlock.onDetected())
        .then(() => {
            console.log(`adblock detected`);
            // Ad-blockers also block Leanplum, so starting it would just cause an error - therefore there is not point to try
            console.log(`Leanplum not started because adblock was detected`);
        });

    Promise.resolve()
        .then(() => blockAdBlock.onNotDetected())
        .then(() => {
            console.log(`adblock not detected`);
            try {
                startLeanplum(uiLanguage);
                trackLeanplumEvent('AppStarted');
            } catch (error) {
                // If localStorage is blocked a security error is thrown, and there is not much we can do about it
                if (error.name !== 'SecurityError') {
                    throw error;
                }
            }
        });

    // Process URL parameters
    Promise.resolve().then(function () {
        return (new UrlParameters(unauthenticated_api_client, search, fragment)).initializeApp();
    }).then(function () {
        // App initialized
    }).catch(function (error) {
        Raven.captureException(error, {level: 'error'});
    });

    ExportUtils.export('app.lib.$', $);
    ExportUtils.export('app.lib.Backbone', Backbone);
    ExportUtils.export('app.lib.moment', moment);

}).catch(function (error) {
    console.error('Unable to initialize application', error);
});

