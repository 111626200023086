var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "84",
          height: "84",
          viewBox: "0 0 84 84",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M68.142 69.142A37.835 37.835 0 0145 77C24.013 77 7 59.987 7 39c0-8.71 2.93-16.734 7.857-23.143C5.823 22.804 0 33.722 0 46c0 20.987 17.013 38 38 38 12.278 0 23.196-5.823 30.142-14.858z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M71.87 12.13a38 38 0 107.193 10.026M70.033 60.502a32.998 32.998 0 00-7.602-49.523",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M32.707 25.293l-1-1a1 1 0 00-1.414 0l-8 8a1 1 0 000 1.414l8.299 8.299-8.305 8.447a1 1 0 00.013 1.415l8 7.846a1 1 0 001.412-.012l3.965-4.018L28 48.154 37 39l-9-9 4.707-4.707zm7.625 26.317l7.955 8.091a1 1 0 001.42.006l4.003-4.002L45 46.846l-3.25 3.327-1.418 1.437zm10.375-26.317L45 31l-1.707-1.707 5-5a1 1 0 011.414 0l1 1z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38 54l-2 2-8-7.846L37 39l-9-9 8-8 9 9 9-9 8 8-9 9 9 9-8 8-9-9.154-3.25 3.327",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }