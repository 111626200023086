var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "25",
          height: "24",
          viewBox: "0 0 25 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7.5 1a1 1 0 011 1v4a1 1 0 01-2 0V2a1 1 0 011-1zM17.5 1a1 1 0 011 1v4a1 1 0 11-2 0V2a1 1 0 011-1z",
          fill: "#6CD5E5",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.5 4a2 2 0 00-2 2v13a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2h-1v2a2 2 0 11-4 0V4h-6v2a2 2 0 11-4 0V4h-1zm12.74 6.673a1 1 0 00-1.48-1.346l-4.356 4.792-2.264-1.887a1 1 0 10-1.28 1.536l3 2.5.736.613.644-.708 5-5.5z",
          fill: "#6CD5E5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }