var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M3.5 14V4.5l8 5m-4 2c3.573 8.768 10.948 15 21 15",
          stroke: "#2C3143",
          "stroke-width": "2",
          fill: "none",
          "fill-rule": "evenodd",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }