

import { MultipleChoiceExercise } from './multiple.choice.exercise.js';

export class ListeningExercise extends MultipleChoiceExercise {
    update (exercise_data) {
        super.update(exercise_data);
    }

    getData () {
        return Promise.resolve()
            .then(() => super.getData())
            .then(data => {
                data.audio_hash = this._data.content.audio_hash;
                return Promise.resolve(data);
            });
    }
}
