
'use strict';

import _ from 'lodash';
import Raven from 'raven-js';

export const EXPERIMENTS = {
    EXTEND_PRETRIAL_TO_50_CARDS_EXPERIMENT: {
        NAME: 'ExtendPretrialTo50CardsExperiment',
        PARAMETER: {
            GROUP: 'group'
        }
    },
    VALUE_OF_CHALLENGES_ON_WEB_EXPERIMENT: {
        NAME: 'ValueOfChallengesOnWebExperiment',
        PARAMETER: {
            GROUP: 'group'
        }
    }
};

export const SUPPORTED_EXPERIMENTS = [
    EXPERIMENTS.EXTEND_PRETRIAL_TO_50_CARDS_EXPERIMENT.NAME,
    EXPERIMENTS.VALUE_OF_CHALLENGES_ON_WEB_EXPERIMENT.NAME,
];

export class Experiments {

    constructor (course_state_manager) {
        this._course_state_manager = course_state_manager;

        this._experiments = null;
    }

    initializeState () {
        this._experiments = {};
        return Promise.resolve();
    }

    updateExperiments (experiments) {
        this._experiments = experiments;
        console.debug(`Experiments:\n${Object.keys(experiments).map(name => `\t${name}: ${Object.keys(experiments[name]).map(p => `${p}="${experiments[name][p]}"`).join(', ')}`).join('\n')}`);
        return Promise.resolve();
    }

    serializeExperiments () {
        return _.clone(this._experiments);
    }

    getParameter (experimentName, parameterName) {
        if (this._experiments[experimentName] !== undefined) {
            if (this._experiments[experimentName][parameterName] === undefined) {
                if (this._course_state_manager.isSynced()) {
                    Raven.captureMessage(`Missing experiment parameter ${parameterName} requested.`,
                        {level: "warning", logger: 'manual'});
                }
            }
            return this._experiments[experimentName][parameterName];
        } else {
            if (this._course_state_manager.isSynced()) {
                Raven.captureMessage(`Parameter from a missing experiment ${experimentName} requested.`,
                    {level: "error", logger: 'manual'});
            }
            return undefined;
        }
    }

    getParameters (experimentName) {
        if (this._experiments[experimentName] !== undefined) {
            return this._experiments[experimentName];
        } else {
            if (this._course_state_manager.isSynced()) {
                Raven.captureMessage(`Parameters from a missing experiment ${experimentName} requested.`,
                    {level: "error", logger: 'manual'});
            }
            return {};
        }
    }

    destroy () {
        delete this._course_state_manager;
        return Promise.resolve();
    }
}

Experiments.SUPPORTED_EXPERIMENTS = SUPPORTED_EXPERIMENTS;
