var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M6.73 27A14.934 14.934 0 015 20c0-8.284 6.716-15 15-15 8.285 0 15 6.716 15 15 0 8.284-6.715 15-15 15-3.213 0-6.191-1.01-8.632-2.731",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20 13V5M27 18l7-2M24.5 26l4.5 6M13 18l-7.5-2M15.5 26l-4.132 6.267",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M20 13l7 5.2-2.625 7.8h-8.75L13 18.2l7-5.2z",
          fill: "#8EA3EE",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }