import $ from 'jquery';
import Backbone from 'backbone';

import renderTemplate from '../../util/template.renderer.js';
import UserManager from '../../modules/usermanager.js';

const CreateClassroomModalView = Backbone.View.extend({

    name: 'create-classroom',
    tagName: 'main',
    className: 'create-classroom-modal-view',

    events: {
        'click a.close': 'close',
        'focus div.input.classroom-name > input': 'focusName',
        'blur div.input.classroom-name > input': 'blurName',
        'input div.input.classroom-name > input': 'inputName',
        'click a.course': 'selectCourse',
        'click button.create-classroom': 'createClassroom'
    },

    initialize: function () {
        console.log('Create Classroom Modal view init');

        this.state = {
            selectedCourseUuid: null,
            classroomName: null
        };
    },

    setProps: function (props) {
        this.props = props;
        this.state.selectedCourseUuid = (props && props.selectedCourseUuid) ? props.selectedCourseUuid : null;
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('classrooms/create-classroom', this.props, this.$el)
            .then( () => {
                this._postRender();
            });
        return this;
    },

    _postRender: function () {
        this.$classroomNameContainer = this.$('div.input.classroom-name');
        this.$classroomNameInput = this.$('div.input.classroom-name > input');
        this.$createButton = this.$('button.create-classroom');
        this.$errorMessage = this.$('p.error');

        this.$classroomNameInput.focus();
        this.domReady = true;
    },

    show: function () {
        this.$el.addClass('display');
        setTimeout( () => {
            this.$el.addClass('visible');
        }, 25);
    },

    close: function () {

        UserManager.instance.getUser().getEventSender()
            .sendNavigationEvent('classrooms', 'close', 'create');

        this.remove();
    },

    focusName: function () {
        this.$classroomNameContainer.addClass('focus');
    },

    blurName: function () {
        this.$classroomNameContainer.removeClass('focus');
    },

    inputName: function (event) {
        if (!this.domReady) {
            return;
        }

        const classroomName = $(event.currentTarget).val();

        if (classroomName.length > 0) {
            this.state.classroomName = classroomName;
        } else {
            this.state.classroomName = null;
        }

        const readyToCreate = (this.state.selectedCourseUuid !== null && classroomName.length > 0);

        this.$createButton.prop('disabled', !readyToCreate);

        if (classroomName.length >= 40) {
            this.$errorMessage.removeClass('hidden');
            this.$classroomNameContainer.addClass('error');
        } else {
            this.$errorMessage.addClass('hidden');
            this.$classroomNameContainer.removeClass('error');
        }
    },

    selectCourse: function (event) {
        const courseUuid = $(event.currentTarget).data('uuid');

        this.$('a.course').removeClass('active');
        this.$(`a.course[data-uuid="${courseUuid}"]`).addClass('active');

        this.state.selectedCourseUuid = courseUuid;

        if (this.state.classroomName !== null) {
            this.$createButton.prop('disabled', false);
        }

        UserManager.instance.getUser().getEventSender()
            .sendNavigationEvent('classrooms', 'choose-course', 'create');
    },

    createClassroom: function () {

        this.trigger('create-classroom', {
            name: this.state.classroomName,
            course_uuid: this.state.selectedCourseUuid
        });

        this.remove();
    }
});

export default CreateClassroomModalView;
