<template>
    <div class="guess-message" v-if="message">
        <div class="header">
            <message-icon v-if="message.icon" :icon-name="message.icon" />
            <h3 class="title" v-if="message.title" :class="{ light: !message.text }" v-html="message.title" />
            <CloseButton @click="close" class="close" v-if="isClosable" />
        </div>
        <div class="message-content-wrapper" :class="{ horizontal: message.hasOwnProperty('illustrationPosition') && message.illustrationPosition === 'left' }">
            <message-illustration v-if="message.illustrationId" :illustration-id="message.illustrationId" />
            <div class="text">
                <div class="body" v-if="message.texts">
                    <p v-for="text in message.texts" v-html="text" />
                </div>
                <div class="body" v-else-if="message.text">
                    <p v-html="message.text" />
                </div>
            </div>
        </div>
        <div class="footer" v-if="message.actions">
            <template v-for="action in message.actions">
                <v-button :text="action.text" :filled="action.filled" @click.native="emitAction(action.eventName)" />
            </template>
        </div>
    </div>
</template>

<script>
    import CloseButton from 'Images/close-24x24.svg';
    import VButton from 'ViewComponent/v-button.vue';
    import MessageIcon from "./message-icon.vue";
    import MessageIllustration from "./message-illustration.vue";
    export default {
        name: 'message',
        components: {
            MessageIllustration,
            MessageIcon,
            CloseButton,
            VButton
        },
        props: {
            message: {
                type: Object,
                required: true
            },
        },
        computed: {
            isClosable() {
                return this.message && (!this.message.hasOwnProperty('closable') || this.message.closable === true)
            },
        },
        methods: {
            close() {
                this.$emit('close');
                if (this.message.triggerDismiss) {
                    this.$emit('trigger-dismiss', this.message.name);
                }
            },
            emitAction(eventName) {
                this.$emit('trigger-action', eventName);
                this.close();
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.guess-message {
        display: flex;
        height: auto;
        background-color: white;
        border-radius: pxToRem(12);
        flex-direction: column;
        > div.header {
            padding: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            > div.message-icon {
                align-self: flex-start;
            }
            > h3.title {
                font-weight: $font-bold;
                &.light {
                    font-weight: normal;
                }
                > em {
                    font-weight: $font-bolder;
                }
            }
            > svg.close {
                align-self: start;
                cursor: pointer;
                transition: 100ms ease-in-out;
                margin-left: auto;
                min-width: pxToRem(24);
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
        > div.message-content-wrapper {
            padding: 0 1rem 1rem;
            > div.message-illustration {
                padding: .5rem 0;
            }
            &.horizontal {
                padding: 1rem;
                display: flex;
                flex-direction: row;
                gap: 1rem;
                > div.message-illustration {
                    width: pxToRem(100);
                    height: pxToRem(100);
                }
                > div.text {
                    flex: 1;
                }
            }
            > div.text {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                > div.body {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    em {
                        font-weight: $font-bold;
                    }
                }
            }
        }

        > div.footer {
            display: flex;
            padding: 1rem;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            gap: 1rem;
            > button {
                min-width: pxToRem(100);
            }
        }
    }
</style>
