var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options
    ? _c(
        "div",
        {
          staticClass: "input-search-dropdown",
          class: { withIcon: _vm.hasIcon },
        },
        [
          _vm._t("icon"),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "bounce" } },
            [_vm.hasSelected ? _c("tick-icon") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchFilter,
                expression: "searchFilter",
              },
            ],
            attrs: {
              name: _vm.name,
              type: "text",
              autocomplete: "off",
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
            },
            domProps: { value: _vm.searchFilter },
            on: {
              focus: function ($event) {
                return _vm.showOptions()
              },
              blur: function ($event) {
                return _vm.exit()
              },
              keyup: _vm.keyMonitor,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _vm.highlightPrevious.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _vm.highlightNext.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchFilter = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.optionsShown,
                  expression: "optionsShown",
                },
              ],
              ref: "results",
              staticClass: "dropdown-content",
            },
            _vm._l(_vm.filteredOptions, function (option, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "dropdown-item",
                  class: { highlight: index === _vm.highlightedIndex },
                  on: {
                    mousedown: function ($event) {
                      return _vm.selectOption(option)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(option.name || option.id || "-") +
                      "\n        "
                  ),
                ]
              )
            }),
            0
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }