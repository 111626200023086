
import _ from 'lodash';

export class NavigationV0EventProcessor {

    static canProcess (event) {
        return event.schema.startsWith('urn:lingvist:schemas:events:navigation:0.');
    }

    static process (event, courseStatistics) {
        const statistics = courseStatistics.getData(),
            updatedStatistics = _.cloneDeep(statistics);

        updatedStatistics.client_sn = event.client_sn;
        return [updatedStatistics, []];
    }
}
