import $ from 'jquery';
import Backbone from 'backbone';

import UserManager from '../../modules/usermanager.js';
import UserModel from '../../model/user.model.js';
import renderTemplate from '../../util/template.renderer.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';

const SIGNOUT_DELAY = 1000;

const AccountView = Backbone.View.extend({

    name: 'account',

    tagName: 'main',
    className: 'account-view',

    events: {
        'click a.tab': 'activateTab',
        'click button.sign-out': 'signOut'
    },

    initialize: function () {
        console.log('Account view init');

        this.state = {
            activeTab: 'available'
        };
    },

    setProps: function (props) {
        this.props = props;
        this.state.filter = props.filter;
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        return Promise.resolve()
            .then(() => renderTemplate('account/account', this.props, this.$el))
            .then(() => this._postRender());
    },

    _postRender: function () {
        this.$('a.tab[data-tab="course"]').addClass('active');

        Backbone.trigger('rendered', this); // Hide the loader
        this.trigger('rendered');

        return Promise.resolve();
    },

    activateTab: function (event) {
        this.trigger('activate-tab', $(event.currentTarget).data('tab'));
    },

    setContent: function (tabName, content) {
        this.$el.find('main.tab-content').html(content);
        this.$('a.tab').removeClass('active');
        this.$(`a.tab[data-tab="${tabName}"]`).addClass('active');
    },

    signOut: function () {
        return Promise.resolve().then( () => {
            return new Promise( resolve => {
                Backbone.trigger('signoutStart', 'account/signout');
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('application', 'signout');
                googleAnalyticsCommand('send', 'event', 'Activity', 'SignOut');
                setTimeout( () => { resolve(); }, SIGNOUT_DELAY);
            });
        }).then( () => {
            return UserModel.signout(false);
        }).then( () => {
            Backbone.trigger('signoutStop', 'account/signout');
        });
    }
});

export default AccountView;
