var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M10.482 6.772a2 2 0 013.037 0L18 12l-4.482 5.228a2 2 0 01-3.037 0L6 12l4.482-5.228z",
          fill: "#037889",
        },
      }),
      _c("path", {
        attrs: {
          opacity: ".5",
          d: "M4.753 15.003C4.145 14.308 3 14.738 3 15.66V21h4.796c.86 0 1.319-1.012.753-1.659l-3.796-4.338zM4.753 8.997C4.145 9.692 3 9.262 3 8.34V3h4.796c.86 0 1.319 1.012.753 1.659L4.753 8.997zM19.247 15.003c.608-.695 1.753-.265 1.753.658V21h-4.796c-.86 0-1.319-1.012-.753-1.659l3.796-4.338zM19.247 8.997c.608.695 1.753.265 1.753-.658V3h-4.796c-.86 0-1.319 1.012-.753 1.659l3.796 4.338z",
          fill: "#037889",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }