var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSupportedBrowser
    ? _c(
        "div",
        { staticClass: "voice-input" },
        [
          !_vm.micAvailable
            ? [
                _c("icon-button", {
                  attrs: { "icon-name": "mic" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.promptPermissions.apply(null, arguments)
                    },
                  },
                }),
              ]
            : [
                _vm.micAvailable && _vm.listening
                  ? [
                      _c("div", {
                        staticClass: "label",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("utility_belt_voice_input_activated")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("icon-button", {
                        attrs: { "icon-name": "mic-active", pulse: true },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.stopListening.apply(null, arguments)
                          },
                        },
                      }),
                    ]
                  : _vm.micAvailable && !_vm.listening
                  ? _c("icon-button", {
                      attrs: { "icon-name": "mic" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.startListening.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }