var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.5 12a1.5 1.5 0 11-1.499-1.5c.827 0 1.499.67 1.499 1.5zM13.5 12a1.5 1.5 0 11-1.499-1.5c.827 0 1.499.67 1.499 1.5zM18.5 12a1.5 1.5 0 11-1.499-1.5c.827 0 1.499.67 1.499 1.5z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4 7.4a.6.6 0 01-.6.6H1.6a.6.6 0 01-.6-.6v-.912c0-.45.126-.89.365-1.272L3 2.6V5h.4a.6.6 0 01.6.6v1.8zM20 16.6a.6.6 0 01.6-.6h1.8a.6.6 0 01.6.6v1.531a2.4 2.4 0 01-.556 1.537L21 21.4V19h-.4a.6.6 0 01-.6-.6v-1.8zM8 7.4a.6.6 0 01-.6.6H5.6a.6.6 0 01-.6-.6v-.912c0-.45.126-.89.365-1.272L7 2.6V5h.4a.6.6 0 01.6.6v1.8zM16 16.6a.6.6 0 01.6-.6h1.8a.6.6 0 01.6.6v1.531a2.4 2.4 0 01-.556 1.537L17 21.4V19h-.4a.6.6 0 01-.6-.6v-1.8z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }