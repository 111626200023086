<template>
    <section class="email" :class="{ notVerified: !emailVerified }">
        <h2 v-html="this.$i18n('account_profile_email_title')" />
        <div class="field" :class="{ focused: this.emailInputFocused }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 13L18 9" :stroke="(this.emailVerified) ? '#2C3143' : '#FF934D'" stroke-width="2" stroke-linecap="round"/>
                <path d="M4.5 19H4C2.89543 19 2 18.1046 2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V11V15.5V17C22 18.1046 21.1046 19 20 19H12H9.5" :stroke="(this.emailVerified) ? '#2C3143' : '#FF934D'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <input type="text" name="email" ref="email"
                   @focus="emailInputFocused = true"
                   v-model="emailLocal"
                   :placeholder="emailPlaceholder" />
            <edit-icon v-if="!emailInputFocused" @click.native="focusInput" />
        </div>
        <transition name="slide-down-fade">
            <div class="error email" v-if="!emailIsValid && passwordFieldVisible" v-html="this.$i18n('settings_error_invalid_email')" />
        </transition>
        <transition name="slide-down-fade">
            <div v-if="passwordFieldVisible" class="fieldWrapper">
                <div class="field password" :class="{ focused: this.passwordInputFocused }">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 9.5L19.0001 9.50006C20.1046 9.50006 21.0001 10.3955 21.0001 11.5001L21 19.5001C21 20.6046 20.1046 21.5001 19 21.5001H5C3.89543 21.5001 3 20.6046 3 19.5001L3.00002 11.5001C3.00002 10.3955 3.89545 9.50006 5.00002 9.50006H7V7.5C7 4.73858 9.23858 2.5 12 2.5C14.0503 2.5 15.8124 3.7341 16.584 5.5" stroke="#FAAA5A" stroke-width="2" stroke-linecap="round"/>
                        <path d="M14 17.5V15.5C14 14.3954 13.1046 13.5 12 13.5V13.5C10.8954 13.5 10 14.3954 10 15.5V17.5" stroke="#FAAA5A" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    <input type="password" name="password" ref="password"
                           @focus="passwordInputFocused = true"
                           @blur="passwordInputFocused = false"
                           v-model="passwordLocal"
                           :placeholder="passwordPlaceholder" v-on:keyup.enter="save" />
                    <reveal-icon @mousedown.native="revealPassword(true)" @mouseup.native="revealPassword(false)" @mouseleave.native="revealPassword(false)" />
                </div>
                <div class="actions">
                    <v-button :i18n="{ id: 'account_profile_cancel_btn' }" @click.native="cancel" :filled="false" size="medium" color="primary" />
                    <v-button :i18n="{ id: 'account_profile_input_action_save' }" @click.native="save" :filled="true" :disabled="!contentIsValid" size="medium" color="primary" />
                </div>
            </div>
        </transition>
        <transition name="slide-down-fade">
            <div class="not-verified" v-if="!emailVerified">
                <p>
                    <span v-html="this.$i18n('account_profile_email_verification_message_1')" />&nbsp;<span v-html="this.$i18n('account_profile_email_verification_message_2')" />
                </p>
                <v-button :i18n="{ id: 'account_profile_email_verification_button_resend' }" @click.native="reSend" :disabled="resendDisabled" :filled="true" size="medium" color="primary" />
            </div>
        </transition>
    </section>
</template>

<script>
    import i18nUtils from "Util/i18n.js";
    import EditIcon from "./edit-icon.vue";
    import VButton from "ViewComponent/v-button.vue";
    import EvaluationUtils from "Util/evaluation.js";
    import RevealIcon from "./reveal-icon.vue";
    import UserModel from "Model/user.model.js";
    import {EventBus} from "Util/vue-event-bus.js";
    import {TYPE as TOAST_TYPE} from "../../toaster/constants.js";

    export default {
        name: 'section-email',
        components: { RevealIcon, VButton, EditIcon },
        props: {
            email: {
                type: String,
                default: ''
            },
            emailVerified: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                emailLocal: '',
                passwordLocal: '',
                emailInputFocused: false,
                passwordInputFocused: false,
                resendDisabled: false
            }
        },
        computed: {
            emailPlaceholder() {
                return i18nUtils.prop('account_profile_email_placeholder')
            },
            passwordPlaceholder() {
                return i18nUtils.prop('account_profile_input_placeholder_change_email_password')
            },
            passwordFieldVisible() {
                return this.emailLocal !== this.email;
            },
            contentIsValid() {
                return this.emailIsValid && this.passwordLocal.length > 2;
            },
            emailIsValid() {
                return EvaluationUtils.validateEmail(this.emailLocal);
            }
        },
        methods: {
            save() {
                this.$emit('save', { email: this.emailLocal, password: this.passwordLocal });
            },
            cancel() {
                this.emailInputFocused = false;
                this.emailLocal = this.email;
                this.$refs.email.blur();
            },
            focusInput() {
                this.emailInputFocused = true;
                this.$refs.email.focus();
            },
            revealPassword(state) {
                this.$refs.password.type = (state) ? 'text' : 'password';
            },
            async reSend() {
                this.resendDisabled = true;
                await UserModel.resendVerificationEmail().then(
                    () => {
                        EventBus.$emit('toaster-add', {
                            text: i18nUtils.prop('settings_information_verification_resend_success'),
                            type: TOAST_TYPE.SUCCESS
                        });
                    },
                    () => {
                        EventBus.$emit('toaster-add', {
                            text: i18nUtils.prop('settings_information_verification_resend_error_server'),
                            type: TOAST_TYPE.ERROR
                        });
                    }
                );
                this.resendDisabled = false;
            }
        },
        watch: {
            email(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.emailLocal = newValue;
                }
            }
        },
        beforeMount() {
            this.emailLocal = this.email;
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.email {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 .5rem;
        > h2 {
            font-size: fontSize(16);
            line-height: fontSize(20);
        }
        > div.fieldWrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        div.field {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $colorBackgroundDarker;
            &.focused {
                border-bottom: 1px solid $colorBackgroundDark;
            }
            > input {
                flex: 1;
            }
            > svg.edit-icon {
                margin-left: auto;
                cursor: pointer;
                transition: all 150ms;
                &:hover {
                    transform: scale(1.2);
                }
            }
            &.password {
                &.focused {
                    border-bottom-color: $colorAttention;
                }
            }
        }
        &.notVerified {
            div.field {
                border-bottom-color: $colorAttention;
            }
        }
        div.error {
            color: $colorIncorrect;
            font-size: fontSize(12);
        }
        div.actions {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: flex-end;
            align-items: center;
        }
        div.not-verified {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            > p {
                font-size: fontSize(14);
            }
            > button {
                margin-left: auto;
            }
        }
        .slide-down-fade-enter-active {
            animation: fade-slide-in 0.3s;
        }
        .slide-down-fade-leave-active {
            animation: fade-slide-in 0.3s reverse;
        }
        @keyframes fade-slide-in {
            0% {
                opacity: 0;
                transform: translateY(-100px);
            }
            25% {
                opacity: 0;
            }
            50% {
                opacity: 0;
            }
            75% {
                opacity: 0.4;
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
</style>
