<template>
    <section class="text">
        <span
            v-for="word in text"
            class="word"
            v-bind:class="{ blocked: word.p > position }"
        >{{word.w}}</span>
    </section>
</template>

<script>

    export default {
        name: 'relevant-words-text',
        props: {
            position: {
                type: Number,
                required: true
            },
            max_words: {
                type: Number,
                required: true
            },
            learned_text_raw: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                text: null
            };
        },
        watch: {},
        components: {},
        methods: {

        },
        computed: {},

        created () {
            this.text = this.learned_text_raw.reduce((result, word) => {
                if (word.w.endsWith(' ')) {
                    result.push({
                        w: word.w.slice(0, word.w.length - 1),
                        p: word.p
                    });
                    result.push({
                        w: ' ',
                        p: 0
                    });
                } else {
                    result.push(word);
                }
                return result;
            }, []);
        }
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.text {
        color: $colorPurplePrimary;
        font-family: $targetLanguageFonts;
        line-height: 1.6;
        user-select: none;
        word-spacing: 1px;
        padding: 1rem;
        background-color: white;
        border-radius: 0.875rem;
        margin-top: 0.5rem;
        min-height: 14rem;

        @media all and (max-width: em(904,16)) {
            min-height: auto;
        }

        > span.word {
            padding: 1px 0;
            transition: all 0.25s;

            &.blocked {
                border-radius: 4px;
                color: $colorBackgroundPurple;
                background-color: $colorBackgroundPurple;
            }
        }
    }
</style>
