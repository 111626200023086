<template>
    <div class="weekly-goal">
        <div class="bars">
            <div class="day" v-for="cell in cells" :key="cell.uniqueKey">
                <empty-cell v-if="cell.type === 'empty'" />
                <lottie-animation
                    v-else
                    :animationData="cell.animation"
                    :loop="cell.loop"
                    @complete="cellAnimationComplete(cell.type)"
                />
                <span class="weekday-name" v-if="cell.weekdayName">{{ cell.weekdayName }}</span>
                <span class="weekday-name" v-else>&nbsp;</span>
            </div>
        </div>
        <div class="text-content">
            <h2>{{ title }}</h2>
            <p v-if="streak_day === -1" v-html="this.$i18n('dynamic_goals_weekly_doorslam_no_streak_text')" />
            <p v-else-if="streak_day === 1" v-html="this.$i18n('dynamic_goals_weekly_doorslam_streak_day_1_text', { args: { set_size: dynamic_goal_size }})" />
            <p v-else-if="streak_day === 2" v-html="this.$i18n('dynamic_goals_weekly_doorslam_streak_day_2_text')" />
            <p v-else-if="streak_day === 3" v-html="this.$i18n('dynamic_goals_weekly_doorslam_streak_day_3_text')" />
            <p v-else-if="streak_day === 4" v-html="this.$i18n('dynamic_goals_weekly_doorslam_streak_day_4_text')" />
            <template v-else-if="streak_day === 5">
                <p v-html="this.$i18n('1', { collection: 'dynamic_goals_weekly_doorslam_streak_day_5_text', args: { tl: this.target_language } })" />
                <p v-html="this.$i18n('2', { collection: 'dynamic_goals_weekly_doorslam_streak_day_5_text' })" />
            </template>
            <template v-else-if="streak_day === 6">
                <p v-html="this.$i18n('1', { collection: 'dynamic_goals_weekly_doorslam_streak_day_6_text', args: { tl: this.target_language } })" />
                <p v-html="this.$i18n('2', { collection: 'dynamic_goals_weekly_doorslam_streak_day_6_text' })" />
            </template>
            <template v-else-if="streak_day === 7">
                <p v-html="this.$i18n('1', { collection: 'dynamic_goals_weekly_doorslam_streak_day_7_text' })" />
                <p v-html="this.$i18n('2', { collection: 'dynamic_goals_weekly_doorslam_streak_day_7_text' })" />
            </template>
        </div>
        <div class="actions">
            <v-button @click.native="navigateToHub"
                      :i18n="{ id: 'daily_performance_stats_home_button' }" />
            <v-button @click.native="close"
                      :filled="true" color="primary"
                      :i18n="{ id: 'sets_aha_moment_goal_achieved_doorslam_btn' }" />
        </div>
    </div>
</template>

<script>
    import i18nUtils from "Util/i18n.js";
    import { uniqueId } from 'lodash';
    import UserManager from "Modules/usermanager";
    import LottieAnimation from 'lottie-web-vue'
    import Vue from 'vue';
    import FillUpAnimationJson from "Styles/animations/weekly-goals/anim_weekly_goals_fillup_light";
    import IdleAnimationJson from "Styles/animations/weekly-goals/anim_weekly_goals_idle_light";
    import EmergingAnimationJson from "Styles/animations/weekly-goals/anim_weekly_goal_emerging_light";
    import CelebrationAnimationJson from "Styles/animations/weekly-goals/anim_weekly_goal_celebration";
    import EmptyCell from "Images/insights/weekly_goal_pill_inactive_light.svg"
    import VButton from "ViewComponent/v-button.vue";
    import Backbone from "backbone";
    import { Awards } from "Modules/awards.js";
    import { find } from "lodash";
    import moment from "moment";

    Vue.use(LottieAnimation);

    export default {
        name: 'weekly-goal',
        components: {
            LottieAnimation,
            VButton,
            EmptyCell
        },
        data() {
            return {
                cell_count: 7,
                course: UserManager.instance.getUser().getCourse(),
                fillUpAnimation: FillUpAnimationJson,
                idleAnimation: IdleAnimationJson,
                emergingAnimation: EmergingAnimationJson,
                celebrationAnimation: CelebrationAnimationJson,
                lastCellFillAnimationComplete: false,
                lastCellSlideInAnimationComplete: false,
                days_from_week_start: Awards.getDaysFromBeginningOfWeek(),
                dynamic_goal_size: Awards.getDynamicGoalSize(),
                weekdays_short: {
                    1: i18nUtils.prop(1, null, null, 'weekday_short'),
                    2: i18nUtils.prop(2, null, null, 'weekday_short'),
                    3: i18nUtils.prop(3, null, null, 'weekday_short'),
                    4: i18nUtils.prop(4, null, null, 'weekday_short'),
                    5: i18nUtils.prop(5, null, null, 'weekday_short'),
                    6: i18nUtils.prop(6, null, null, 'weekday_short'),
                    7: i18nUtils.prop(7, null, null, 'weekday_short')
                },
                cells: []
            }
        },
        computed: {
            target_language() {
                return this.course.getInfo().target_language;
            },

            streak_day () {
                return Awards.getStreakToday();
            },

            title() {
                if (this.streak_day <= 0) {
                    return i18nUtils.prop('dynamic_goals_weekly_doorslam_no_streak_title');
                } else {
                    switch (this.streak_day) {
                        case 7:
                            return i18nUtils.prop('dynamic_goals_weekly_doorslam_streak_complete_title');
                        default:
                            return i18nUtils.prop('dynamic_goals_weekly_doorslam_streak_progress_title', { daily_goals_achieved: this.daily_goals_achieved_count });
                    }
                }
            },

            daily_goals_achieved() {
                if (this.course) {
                    return Awards.getDailyGoalsAchievedThisWeek(this.course);
                    // NOTE: for debugging purpose there's a method available below 👇
                    // return Awards.getDailyGoalsAchievedThisWeekDebug(4);
                }
            },

            daily_goals_achieved_count() {
                return (this.daily_goals_achieved) ? this.daily_goals_achieved.length : 0;
            }
        },
        methods: {
            setCells() {
                let _cells = [];

                for (let i = 1; i <= this.cell_count; i++) {
                    let cell = {};
                    cell.uniqueKey = uniqueId('cell_');
                    cell.weekdayName = this.weekdays_short[i];
                    const weekDayWithGoal = find(this.daily_goals_achieved, { weekday_nr: i });
                    const weekDayWithGoalIsToday = (weekDayWithGoal && weekDayWithGoal.hasOwnProperty('date') && moment(weekDayWithGoal.date).isSame(moment().startOf('day')));

                    if (weekDayWithGoal) {
                        if (i === weekDayWithGoal.weekday_nr && weekDayWithGoalIsToday && !this.lastCellFillAnimationComplete) {
                            cell.type = 'filling';
                            cell.animation = this.fillUpAnimation;
                            cell.loop = false;
                        } else if (this.daily_goals_achieved_count === 7 && this.lastCellFillAnimationComplete) {
                            cell.type = 'celebrate';
                            cell.animation = this.celebrationAnimation;
                            cell.loop = true;
                        } else {
                            cell.type = 'full';
                            cell.animation = this.idleAnimation;
                            cell.loop = true;
                        }
                    } else {
                        cell.type = 'empty';
                    }

                    _cells.push(cell);
                }

                this.cells = _cells;
            },
            cellAnimationComplete(type) {
                if (type === 'filling') {
                    this.lastCellFillAnimationComplete = true;
                    this.setCells();
                } else if (type === 'sliding') {
                    this.lastCellSlideInAnimationComplete = true;
                    this.setCells();
                }
            },
            navigateToHub() {
                this.$emit('close');
                Backbone.history.navigate('hub', { trigger: true });
            },
            close() {
                this.$emit('close');
            }
        },
        mounted() {
            this.setCells();
        }
    }
</script>

<style lang="scss">
    @import '~Styles/_mixins.scss';
    @import '~Styles/_colors.scss';

    div.weekly-goal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        min-height: 36rem;
        @include respond-to('small') {
            min-height: calc(100vh - 2rem);
        }
        > div.bars {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 6rem;
            > div.day {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                > div,
                > svg {
                    min-width: 44px;
                    min-height: 116px;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                > span.weekday-name {
                    text-transform: uppercase;
                    font-size: fontSize(12);
                    font-weight: $font-bold;
                }
            }
        }
        > div.text-content {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            max-width: 27rem;
            > h2 {
                margin-bottom: 1rem;
                font-size: 1.38rem;
                font-weight: $font-bold;
            }
        }

        > div.actions {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin-top: auto;
            width: 100%;
        }
    }
</style>
