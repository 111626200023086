var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "120",
          height: "35",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          attrs: {
            "clip-path": "url(#huffpost-logo_svg__huffpost-logo_svg__clip0)",
            fill: "#2C3143",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M15.75 24.016h4.331l2.322-13.292h-4.331l-.857 4.76H13.46l.856-4.76H9.984L7.662 24.016h4.332l.873-4.974h3.755l-.873 4.974zm9.108-13.309l-1.318 7.396a8.493 8.493 0 00-.165 1.663c0 3.92 3.426 4.513 5.485 4.513 4.315 0 6.176-1.433 6.852-5.303l1.45-8.285h-4.333l-1.235 6.95c-.395 2.175-.675 3.311-2.29 3.311-1.053 0-1.564-.56-1.564-1.713 0-.445.066-.988.198-1.663l1.251-6.885h-4.331v.016zm35.198 13.309h4.331l.61-3.426h2.042c3.772 0 5.864-2.043 5.864-5.765 0-2.619-1.812-4.118-4.99-4.118h-5.535l-2.322 13.309zm6.753-6.704h-1.252l.576-3.13h1.17c.955 0 1.466.478 1.466 1.351 0 1.104-.742 1.779-1.96 1.779zm14.51-6.885c-4.71 0-7.527 3.047-7.527 8.17 0 3.557 2.24 5.699 5.996 5.699 4.71 0 7.527-3.047 7.527-8.17.016-3.574-2.224-5.699-5.996-5.699zm-1.268 10.426c-1.12 0-1.779-.807-1.779-2.157 0-.511.05-.956.148-1.466.33-1.746.89-3.344 2.652-3.344 1.12 0 1.78.807 1.78 2.158 0 .51-.05.955-.15 1.466-.328 1.73-.888 3.343-2.65 3.343zm22.334 3.163h4.332l1.713-9.735h3.261l.626-3.557h-11.084l-.626 3.557h3.459l-1.681 9.735zm-7.18-8.384c-1.368-.428-1.928-.61-1.928-1.252 0-.428.28-.939 1.104-.939.61 0 1.12.346 1.317.857l3.822-1.038c-.445-1.877-2.06-2.833-4.843-2.833-5.238 0-5.65 3.508-5.65 4.58 0 2.173 1.154 3.507 3.657 4.2.659.18 1.417.378 1.417 1.103 0 .576-.428.939-1.17.939-.675 0-1.4-.396-1.647-1.087l-3.772 1.02c.412 1.977 2.273 3.097 5.189 3.097 2.223 0 5.946-.593 5.946-4.612.016-2.01-1.12-3.327-3.443-4.035zM5.242 24.015L7.58 10.724H.416v13.291h4.826zM114.18 10.724l-2.323 13.291h7.165V10.724h-4.842zm-71.846 9.371h3.64l.593-3.343h-3.64l.412-2.47h5.09l.625-3.575h-9.421L37.31 24.015h4.332l.692-3.92zm11.348 0h3.657l.593-3.343h-3.64l.412-2.47h5.09l.625-3.575h-9.421l-2.323 13.308h4.332l.675-3.92z",
            },
          }),
        ]
      ),
      _c("defs", [
        _c(
          "clipPath",
          { attrs: { id: "huffpost-logo_svg__huffpost-logo_svg__clip0" } },
          [
            _c("path", {
              attrs: {
                fill: "#fff",
                transform: "translate(.416 .125)",
                d: "M0 0h118.869v34.34H0z",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }