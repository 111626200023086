var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "more-dropdown",
      on: { mouseleave: _vm.closeDropdownDelayed },
    },
    [
      _c("more-circle", {
        class: { transparent: _vm.transparent },
        on: { click: _vm.openDropdown },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.open
          ? _c(
              "div",
              {
                staticClass: "dropdown",
                on: {
                  mouseenter: _vm.openDropdown,
                  mouseleave: _vm.closeDropdown,
                },
              },
              [
                _c("variation-settings", {
                  attrs: {
                    variation: _vm.variation,
                    "variation-toggle-disabled": _vm.variationToggleDisabled,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _vm.canBeDiscarded
                      ? _c("v-button", {
                          staticClass: "discardLesson",
                          attrs: {
                            i18n: {
                              id: "course_wizard_save_for_later_discard",
                            },
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.discardLesson.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isEditable
                      ? _c("v-button", {
                          staticClass: "editLesson",
                          attrs: {
                            i18n: { id: "lessons_list_table_edit" },
                            filled: true,
                            color: "primary",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.editLesson.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }