var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M18 21.5a1.5 1.5 0 11-1.499-1.5c.827 0 1.499.67 1.499 1.5zM22 21.5a1.5 1.5 0 11-1.499-1.5c.827 0 1.499.67 1.499 1.5zM26 21.5a1.5 1.5 0 11-1.499-1.5c.827 0 1.499.67 1.499 1.5z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 17a1 1 0 01-1 1H3a1 1 0 01-1-1v-2.667a4 4 0 01.8-2.4L5 9v4h1a1 1 0 011 1v3zM34 26a1 1 0 011-1h3a1 1 0 011 1v2.667a4 4 0 01-.8 2.4L36 34v-4h-1a1 1 0 01-1-1v-3zM13 17a1 1 0 01-1 1H9a1 1 0 01-1-1v-2.667a4 4 0 01.8-2.4L11 9v4h1a1 1 0 011 1v3zM28 26a1 1 0 011-1h3a1 1 0 011 1v2.667a4 4 0 01-.8 2.4L30 34v-4h-1a1 1 0 01-1-1v-3z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }