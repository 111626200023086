
'use strict';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import moment from 'moment';
import { tagManagerCommand } from '../util/google-analytics.js';
import { EventBus } from "../util/vue-event-bus.js";

export class AnalyticsController {
    constructor () {
        EventBus.$on('statistics-updated', async () => {
            await this._statisticsUpdated();
        });
    }

    async sendDailyActivityEvents (statistics, history) {
        const user =  UserManager.instance.getUser();
        const user_registered = user.getInfo().registered;
        const today = moment().local().startOf('day');

        const total_days_with_cards = history.filter(day => day.all_units.total > 0).length;
        const total_days = today.diff(user_registered.local().startOf('day'), 'days');

        if (total_days === 1 && total_days_with_cards === 2 && !await user.getStorage().getItem('analytics/active_2_or_3_of_3_sent')) {
            console.log(`AnalyticsController.sendDailyActivityEvents(): sending active_2_or_3_of_3 for total_days==2`);
            tagManagerCommand({ 'event': 'active_2_or_3_of_3' });
            user.getStorage().setItem('analytics/active_2_or_3_of_3_sent', true);
        } else if (total_days === 2 && total_days_with_cards === 2 && !await user.getStorage().getItem('analytics/active_2_or_3_of_3_sent')) {
            console.log(`AnalyticsController.sendDailyActivityEvents(): sending active_2_or_3_of_3 for total_days==3`);
            tagManagerCommand({ 'event': 'active_2_or_3_of_3' });
            user.getStorage().setItem('analytics/active_2_or_3_of_3_sent', true);
        } else if (total_days === 2 && total_days_with_cards === 3 && !await user.getStorage().getItem('analytics/active_3_of_3_sent')) {
            console.log(`AnalyticsController.sendDailyActivityEvents(): sending active_3_of_3 for total_days==3`);
            tagManagerCommand({ 'event': 'active_3_of_3' });
            user.getStorage().setItem('analytics/active_3_of_3_sent', true);
        }
    }

    _statisticsUpdated () {
        const statistics = UserManager.instance.getUser().getCourse().getStatistics();
        Promise.resolve()
            .then(() => this.sendDailyActivityEvents(statistics.getData(), statistics.getHistory()));
    }
}

export const analytics_controller_factory = new ControllerFactory(AnalyticsController);
