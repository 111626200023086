var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M3.5 9.5V3.932a.6.6 0 01.848-.546L9 5.5M20.5 12.5V3.932a.6.6 0 00-.848-.546L15 5.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.2 11.8a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0zM16.2 11.8a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.646 14.646l-.792-.792a.5.5 0 01.353-.854h1.586a.5.5 0 01.353.854l-.792.792a.5.5 0 01-.708 0z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.5 12.367V13.5c0 4.745-3.755 8.5-8.5 8.5s-8.5-3.755-8.5-8.5v-.567M7 6.603A8.553 8.553 0 0112 5c1.865 0 3.591.594 5 1.604",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }