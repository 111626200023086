<template>
    <div class="lesson-card" v-if="this.selected_card && this.selected_card_contexts">
        <close-button @click="close" class="close" />
        <h3 v-html="this.$i18n('lessons_creator_editor_card_preview_title')" />
        <card-preview-edit v-bind:selected_card="selected_card" v-bind:course_info="course_info" v-on:close="close" />
        <div class="comment">
            <textarea rows="2" maxlength="100" class="comment" :placeholder="this.$i18n('course_wizard_comment_title')"
                      :value="comment" v-on:change="updateComment" v-on:keyup="updateCommentCounter" />
            <span class="comment-length-counter">{{commentLengthCounter}}</span>
        </div>
        <h3 v-html="this.$i18n('lessons_creator_editor_choose_sentence_title')" />
        <div class="sentences" ref="sentences">
            <div v-for="item in selected_card_contexts_filtered"
                 :class="{ sentence: true, 'selected': selected_card.context === item.context }"
                 v-on:click="$emit('select-card-context', item)">
                <div class="context target">{{ item.context }}</div>
                <div class="context-translation">{{ item.context_translation }}</div>
            </div>
            <div class="actions" v-if="selected_card_contexts.length > 5">
                <div class="show-more" v-if="!showMore" v-on:click="showMoreSentences">
                    <span v-html="this.$i18n('course_wizard_sentence_show_more')" />
                    <arrow-down-icon />
                </div>
                <div class="show-less" v-if="showMore" v-on:click="showLessSentences">
                    <span v-html="this.$i18n('course_wizard_sentence_show_less')" />
                    <arrow-up-icon />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowDownIcon from 'Images/arrows/arrow-down.svg';
import ArrowUpIcon from 'Images/arrows/arrow-up.svg';
import CloseButton from 'Images/close-24x24.svg';
import CardPreviewEdit from "./card-preview-edit.vue";

export default {
        name: 'lesson-card',
        components: {
            CardPreviewEdit,
            ArrowDownIcon,
            ArrowUpIcon,
            CloseButton
        },
        props: {
            selected_card: {
                type: Object,
                required: true
            },
            selected_card_contexts: {
                type: Array,
                required: true
            },
            userHasClassroomsFeature: {
                type: Boolean
            },
            course_info: {
                type: Object,
            }
        },
        data() {
            return {
                showMore: false,
                comment: this.selected_card.form_translation_comment,
                commentLength: (this.selected_card && this.selected_card.form_translation_comment) ? this.selected_card.form_translation_comment.length : 0
            }
        },
        computed: {
            selected_card_contexts_filtered() {
                if (this.showMore) {
                    return this.selected_card_contexts;
                } else {
                    return this.selected_card_contexts.slice(0, 5);
                }
            },
            commentLengthCounter() {
                return `${this.commentLength}/100`;
            }
        },
        methods: {
            showMoreSentences() {
                this.showMore = true;
            },
            showLessSentences() {
                this.showMore = false;
            },
            showMoreWhenSelectedIsOutOfFirst5() {
                this.selected_card_contexts.forEach((item, index) => {
                    if (this.selected_card.context === item.context) {
                        if (index > 4) {
                            this.showMoreSentences();
                        } else {
                            this.showLessSentences();
                        }
                    }
                });
            },
            updateComment(event) {
                const value = event.target.value;
                this.$emit('update-card-comment', { card: this.selected_card, value });
            },
            updateCommentCounter(event) {
                this.comment = event.target.value;
                this.commentLength = event.target.value.length;
            },
            close() {
                this.$emit('close');
            },
            resetSentencesScroll() {
                this.$refs.sentences.scrollTop = 0;
            }
        },
        created() {
            this.showMoreWhenSelectedIsOutOfFirst5();
        },
        watch: {
            selected_card(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.showMoreWhenSelectedIsOutOfFirst5();
                    this.comment = newValue.form_translation_comment;
                    this.commentLength = (newValue.form_translation_comment) ? newValue.form_translation_comment.length : 0;
                    this.resetSentencesScroll();
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.lesson-card {
        display: flex;
        flex-direction: column;
        h3 {
            font-size: fontSize(18);
            margin-bottom: .75rem;
            margin-right: 2rem;
            margin-left: 1rem;
            user-select: none;
        }
        > div.card-preview {
            margin-bottom: 1rem;
        }
        > svg.close {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: .25rem;
            transition: 100ms ease-in-out;
            body[data-interface-language="ar"] & {
                right: unset;
                left: 0;
            }
            &:hover {
                transform: scale(1.2);
            }
        }
        > div.sentences {
            overflow-y: auto;
            overflow-x: hidden;
            @include minimalistScrollbarWithSpacing(12px);
            > div.sentence {
                padding: .75rem;
                border-top: pxToRem(1) solid $colorBackgroundDarker;
                cursor: pointer;
                user-select: none;
                &:first-child {
                    border-top: none;
                }
                &.selected {
                    background-color: rgba(255,255,255,0.4);
                    box-shadow: inset pxToRem(4) 0 0 0 $colorNeutral;
                }
                &:hover:not(.selected) {
                    background-color: $colorBackgroundDarker;
                }
            }
            > div.actions {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-top: 1px solid $colorBackgroundDarker;
                border-bottom: 1px solid $colorBackgroundDarker;
                > div.show-more,
                > div.show-less {
                    padding: .75rem;
                    user-select: none;
                    position: relative;
                    text-align: right;
                    cursor: pointer;
                    &:hover {
                        opacity: .8;
                    }
                    svg {
                        margin-left: .5rem;
                    }
                }
            }
        }
        > div.comment {
            margin: 0 0 1rem;
            position: relative;

            > textarea {
                width: 100%;
                background: $colorBackgroundDarker;
                color: $colorSourcePrimary;
                padding: pxToRem(16);
                z-index: 5;
                border-radius: pxToRem(4);
                @include minimalistScrollbar(8px, $colorInputBox, $colorInputBox);

                &:focus {
                    background: $colorBackgroundDefault;
                }
            }

            > span.comment-length-counter {
                position: absolute;
                bottom: pxToRem(6);
                right: pxToRem(10);
                font-size: fontSize(12);
                opacity: .6;
                z-index: 10;
            }
        }
    }
</style>
