var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("rect", {
        attrs: {
          x: "17",
          y: "21",
          width: "6",
          height: "2",
          rx: "1",
          fill: "#63E1E7",
        },
      }),
      _c("rect", {
        attrs: {
          x: "11",
          y: "21",
          width: "3",
          height: "2",
          rx: "1",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.857 5.593l5 8.66m-8.464-6.66l2 3.464-10.393 6 1.5 2.598 6.929-4m4.964.598l-1.5-2.598m1.267 6.197l6.929-4-1.5-2.598-6.929 4 1.5 2.598zM8.66 8.593l6.929-4L14.08 2 7.16 5.995l1.5 2.598z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }