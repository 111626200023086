<template>
    <div class="tab-guess">
        <section>
            <v-button text="Guess Debug Mode ON" :filled="true" color="attention" @click.native="guessDebugModeOn" />
            <v-button text="Start autoplay" @click.native="startAutoPlay" />
            <v-button text="Day insights" @click.native="showDayInsights" />
        </section>
        <h3>Guess Stories</h3>
        <section>
            <v-button size="medium" text="Japanese General" @click.native="openStory('japanese-general')" />
            <v-button size="medium" text="Japanese Hiragana" @click.native="openStory('japanese-hiragana')" />
            <v-button size="medium" text="Japanese Katakana" @click.native="openStory('japanese-katakana')" />
            <v-button size="medium" text="Korean General" @click.native="openStory('korean-general')" />
            <v-button size="medium" text="Korean Hangeul" @click.native="openStory('korean-hangeul')" />
            <v-button size="medium" text="Russian General" @click.native="openStory('russian-general')" />
            <v-button size="medium" text="Russian Cyrillic" @click.native="openStory('russian-cyrillic')" />
        </section>
        <h3>Guess Messages & Overlays</h3>
        <section>
            <v-button size="medium" text="Fast Tracking Modal" @click.native="openFTmodal" />
            <v-button size="medium" text="Can you say word - overlay?" @click.native="openMessage('translation-message')" />
            <v-button size="medium" text="2nd card message" @click.native="openMessage('2nd-card-message')" />
            <v-button size="medium" text="3rd card message" @click.native="openMessage('3rd-card-message')" />
            <v-button size="medium" text="First mistake - exit message" @click.native="openMessage('first-mistake-message')" />
            <v-button size="medium" text="Strict Diacritics - setting message" @click.native="openMessage('strict-diacritics-message')" />
            <v-button size="medium" text="Synonym - exit message" @click.native="openMessage('synonym-message')" />
            <v-button size="medium" text="Do not overdo" @click.native="openMessage('dont-overdo-message')" />
            <v-button size="medium" text="Japanese Scripts" @click.native="openMessage('japanese-scripts-message')" />
            <v-button size="medium" text="Japanese Hiragana & Katakana" @click.native="openMessage('japanese-hiragana-katakana-message')" />
            <v-button size="medium" text="Japanese Hiragana" @click.native="openMessage('japanese-hiragana-message')" />
            <v-button size="medium" text="Japanese Hiragana Learning" @click.native="openMessage('japanese-hiragana-learning-message')" />
            <v-button size="medium" text="Russian Cyrillic" @click.native="openMessage('russian-cyrillic-message')" />
            <v-button size="medium" text="Form spelling" @click.native="openMessage('form-spellings-message')" />
            <v-button size="medium" text="Voice Input" @click.native="openMessage('voice-input-message')" />
        </section>
    </div>
</template>

<script>
    import VButton from "ViewComponent/v-button.vue";
    import { EventBus } from "Util/vue-event-bus";
    import Backbone from "backbone";


    export default {
        name: 'tab-autoplay',
        components: { VButton },
        methods: {
            startAutoPlay() {
                EventBus.$emit('debug:start-autoplay');
            },
            openStory(storyId) {
                EventBus.$emit('debug:close');
                EventBus.$emit('story:open', storyId);
            },
            showDayInsights(){
                EventBus.$emit('debug:close');
                EventBus.$emit('guess:show-day-insights', true);
            },
            openFTmodal(){
                EventBus.$emit('debug:close');
                EventBus.$emit('guess:ft-modal-open', true);
            },
            openMessage(messageId) {
                if (Backbone.history.fragment !== 'guess') {
                    Backbone.history.navigate('guess', { trigger: true });
                    setTimeout(() => {
                        this.openMessage(messageId);
                    }, 500);
                } else {
                    EventBus.$emit('guess:onboarding-step', messageId);
                    EventBus.$emit('debug:close');
                }
            },
            guessDebugModeOn() {
                EventBus.$emit('debug:guess-debug');
                EventBus.$emit('debug:close');
            },
        },
    }
</script>

<style lang="scss" scoped>
    div.tab-guess {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        > h3 {
            font-weight: 500;
            margin: 1rem 0 .5rem;
        }
        > section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
</style>
