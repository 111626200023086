var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.word && _vm.course
    ? _c(
        "div",
        {
          staticClass: "wordlist-item",
          class: {
            isSelected: _vm.isSelected,
            isPlaying: _vm.playlistIsPlayingThis,
          },
          on: {
            mouseover: function ($event) {
              _vm.itemIsHovered = true
            },
            mouseleave: function ($event) {
              _vm.itemIsHovered = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "tags" },
            [
              _c("wordlist-tag-icon", {
                attrs: {
                  icon: "mute",
                  "semi-hover": _vm.itemIsHovered,
                  active: _vm.word.muted,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.muteIconClicked.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("wordlist-tag-icon", {
                attrs: {
                  icon: "favourite",
                  "semi-hover": _vm.itemIsHovered,
                  active: _vm.word.favourite,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.favIconClicked.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("wordlist-tag-icon", {
                attrs: {
                  icon: "playlist",
                  "semi-hover": _vm.itemIsHovered,
                  active: _vm.word.in_playlist,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.playlistIconClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-container" },
            [
              !_vm.inverted
                ? [
                    _vm.word.homograph
                      ? _c(
                          "div",
                          {
                            staticClass: "homograph",
                            class: { audioIsPlaying: _vm.formAudioIsPlaying },
                            on: {
                              click: function ($event) {
                                return _vm.playAudio("form")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "target" }, [
                              _vm.word.homograph &&
                              _vm.word.homograph.begin_form
                                ? _c("span", { staticClass: "begin" }, [
                                    _vm._v(
                                      _vm._s(_vm.word.homograph.begin_form)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.word.homograph.form) +
                                  "\n                    "
                              ),
                              _vm.word.homograph && _vm.word.homograph.end_form
                                ? _c("span", { staticClass: "end" }, [
                                    _vm._v(_vm._s(_vm.word.homograph.end_form)),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("speaker-button", {
                              attrs: { "in-progress": _vm.audioIsPlaying },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.word.context && _vm.word.context.context
                      ? _c(
                          "div",
                          {
                            staticClass: "context target",
                            class: {
                              audioIsPlaying: _vm.contextAudioIsPlaying,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.playAudio("context")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.word.context.context) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.word.sense && _vm.word.sense.translations
                      ? _c(
                          "div",
                          {
                            staticClass: "sense-translation",
                            class: { audioIsPlaying: _vm.formAudioIsPlaying },
                            on: {
                              click: function ($event) {
                                return _vm.playAudio("form")
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "translations-wrapper" },
                              [
                                _vm._l(
                                  _vm.word.sense.translations,
                                  function (item, index) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "translation" },
                                        [_vm._v(_vm._s(item.translation))]
                                      ),
                                      _vm._v(" "),
                                      index + 1 <
                                      _vm.word.sense.translations.length
                                        ? _c("span", { staticClass: "comma" }, [
                                            _vm._v(", "),
                                          ])
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("speaker-button", {
                              attrs: { "in-progress": _vm.audioIsPlaying },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.word.context && _vm.word.context.translations
                      ? _c(
                          "div",
                          {
                            staticClass: "context-translations",
                            class: {
                              audioIsPlaying: _vm.contextAudioIsPlaying,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.playAudio("context")
                              },
                            },
                          },
                          _vm._l(
                            _vm.word.context.translations,
                            function (item) {
                              return _c("div", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.translation) +
                                    "\n                "
                                ),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ],
              _vm._v(" "),
              _c("div", { staticClass: "meta" }, [
                _c("span", { staticClass: "data-point" }, [
                  _vm._v(_vm._s(_vm.wordLastSeen)),
                ]),
                _vm._v(" | "),
                _c("span", {
                  staticClass: "data-point",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("learned_words_word_guess_count", {
                        args: { guess_count: _vm.word.guess_count },
                      })
                    ),
                  },
                }),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "open-sidebar", on: { click: _vm.selectWord } },
            [_c("open-sidebar-icon")],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }