var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wordlist-tag-icon",
      class: { semiHover: _vm.semiHover },
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
        click: _vm.clicked,
      },
    },
    [
      _vm.icon === "mute"
        ? _c(
            "svg",
            {
              attrs: {
                width: "32",
                height: "32",
                viewBox: "0 0 32 32",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M18 22H10.5H9C7.89543 22 7 21.1046 7 20V12C7 10.8954 7.89543 10 9 10H22C23.1046 10 24 10.8954 24 12V16",
                  stroke: _vm.hover || _vm.activeLocal ? "#FFBAB0" : "#D5DBE5",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M21 19L27 25",
                  stroke: _vm.hover || _vm.activeLocal ? "#FFBAB0" : "#D5DBE5",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M27 19L21 25",
                  stroke: _vm.hover || _vm.activeLocal ? "#FFBAB0" : "#D5DBE5",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M11 14H17M11 18H14",
                  stroke: _vm.hover || _vm.activeLocal ? "#FFBAB0" : "#D5DBE5",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          )
        : _vm.icon === "favourite"
        ? _c(
            "svg",
            {
              attrs: {
                width: "32",
                height: "32",
                viewBox: "0 0 32 32",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: _vm.hover || _vm.activeLocal ? "#AC6ED1" : "#D5DBE5",
                  d: "M16 24L15.2929 24.7071C15.4804 24.8946 15.7348 25 16 25C16.2652 25 16.5196 24.8946 16.7071 24.7071L16 24ZM8.50001 16.5L9.20712 15.7929L8.50001 16.5ZM15 13.3934C15 13.9457 15.4477 14.3934 16 14.3934C16.5523 14.3934 17 13.9457 17 13.3934H15ZM23.5 16.5L22.7929 15.7929V15.7929L23.5 16.5ZM14.8321 21.4179C14.4416 21.0274 13.8084 21.0274 13.4179 21.4179C13.0274 21.8084 13.0274 22.4416 13.4179 22.8321L14.8321 21.4179ZM9.6679 19.0821C10.0584 19.4726 10.6916 19.4726 11.0821 19.0821C11.4726 18.6916 11.4726 18.0584 11.0821 17.6679L9.6679 19.0821ZM11.6066 10C13.4807 10 15 11.5193 15 13.3934H17C17 10.4147 14.5853 8 11.6066 8V10ZM11.6066 8C6.8016 8 4.39525 13.8095 7.7929 17.2071L9.20712 15.7929C7.0694 13.6552 8.58342 10 11.6066 10V8ZM22.7929 15.7929L15.2929 23.2929L16.7071 24.7071L24.2071 17.2071L22.7929 15.7929ZM20.3934 10C23.4166 10 24.9306 13.6552 22.7929 15.7929L24.2071 17.2071C27.6048 13.8095 25.1984 8 20.3934 8V10ZM20.3934 8C17.4147 8 15 10.4147 15 13.3934H17C17 11.5193 18.5193 10 20.3934 10V8ZM16.7071 23.2929L14.8321 21.4179L13.4179 22.8321L15.2929 24.7071L16.7071 23.2929ZM11.0821 17.6679L9.20712 15.7929L7.7929 17.2071L9.6679 19.0821L11.0821 17.6679Z",
                },
              }),
            ]
          )
        : _vm.icon === "playlist"
        ? _c(
            "svg",
            {
              attrs: {
                width: "32",
                height: "32",
                viewBox: "0 0 32 32",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  stroke: _vm.hover || _vm.activeLocal ? "#6CD5E5" : "#D5DBE5",
                  d: "M8.5 19H8C6.89543 19 6 19.8954 6 21V22C6 23.1046 6.89543 24 8 24H10C11.1046 24 12 23.1046 12 22V11C12 9.89543 12.8954 9 14 9H23C24.1046 9 25 9.89543 25 11V19C25 20.1046 24.1046 21 23 21H21C19.8954 21 19 20.1046 19 19V18C19 16.8954 19.8954 16 21 16H22",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-in-fade" } }, [
        _vm.tooltipVisible
          ? _c("span", { staticClass: "tooltip" }, [
              _vm._v("\n            " + _vm._s(_vm.tooltip) + "\n        "),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }