var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "set-progress" }, [
    _c("div", { staticClass: "numbers-mobile" }, [
      _vm._v(_vm._s(_vm.cardsDoneInSet) + " / " + _vm._s(_vm.setSize)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "number" }, [
      _c("div", {
        staticClass: "tooltip",
        domProps: {
          innerHTML: _vm._s(this.$i18n("sets_introduction_tooltip")),
        },
      }),
      _vm._v("\n        " + _vm._s(_vm.cardsDoneInSet) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "progress-background" }, [
      _c("div", {
        staticClass: "progress-bar",
        style: _vm.setProgressPercentageStyle,
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "number" }, [_vm._v(_vm._s(_vm.setSize))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }