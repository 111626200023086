<template>
    <div class="modal-popup" @click.self="close">
        <div class="modal-popup-window" :style="{width: base_width}">
            <CloseButton @click="close" class="close" v-if="closable" />
            <header>
                <h2 v-html="title" />
                <h3 v-if="subtitle" v-html="subtitle"></h3>
            </header>

            <slot /> <!-- Main content here -->

        </div>
    </div>
</template>

<script>
import CloseButton from 'Images/close-24x24.svg';

export default {
    name: 'modal-popup',
    props: {
        closable: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false,
            default: null
        },
        base_width: {
            type: String,
            required: false,
            default: '32rem'
        },
    },
    components: {
        CloseButton,
    },
    data: function () {
        return {

        };
    },
    computed: {

    },
    methods: {
        close() {
            if (this.closable) {
                this.$emit('close');
            }
        },
        keyDown(event) {
            if (this.closable && event && event.code === 'Escape') { // close on ESC key
                this.close()
            }
        },
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
        document.body.classList.add('modal-open');
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
    }
};
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.modal-popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        background-color: rgba(44, 49, 67, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div.modal-popup-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: 0.75rem;
            display: flex;
            flex-direction: column;
            max-width: calc(100% - 1rem);
            max-height: calc(100% - 2rem);
            box-sizing: border-box;
            padding-bottom: 1rem;

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: 1.5rem;
                top: 1.5rem;
                transition: 100ms ease-in-out;
                z-index: 1100;

                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 1.5rem;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }

            > header {
                padding: 1.5rem;

                > h2 {
                    font-size: 1.375rem;
                    text-align: left;
                    font-weight: $font-bold;
                    body[data-interface-language="ar"] & {
                        text-align: right;
                    }
                }

                > h3 {
                    font-size: 1rem;
                }
            }

            > main.document {
                @include minimalistScrollbar(12px);
                direction: ltr;

                color: $colorSourcePrimary;
                text-align: left;
                overflow: auto;
                padding: 0 1.5rem 1rem;
                font-size: 0.875rem;

                > p, > h3, > h4, > ul, > ol, > ul li {
                    text-align: left;
                    padding: 0;
                }

                p {
                    margin-top: 0;
                    padding-bottom: 0.5em;
                    strong {
                        font-weight: $font-bolder;
                    }
                }

                > h2, > h3 {
                    margin: 0.5em 0;
                }

                > h4 {
                    margin: 0.25em 0;
                    font-weight: $font-bold;
                }

                > ul, > ol {
                    padding-left: 1.5em;
                    margin: 2rem 0;

                    > li {
                        padding-bottom: 0.25em;
                    }

                    ul, ol {
                        > li {
                            margin-left: 1rem;
                        }
                    }
                }

                a {
                    display: inline;
                }
            }
        }
    }
</style>
