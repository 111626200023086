var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M6 13c0-1.7 1.3-3 3-3h1c.6 0 1 .4 1 1s-.4 1-1 1H9c-.6 0-1 .4-1 1v4c0 .6-.4 1-1 1s-1-.4-1-1v-4zm4 5c.6 0 1 .4 1 1v2.5c0 .6-.4 1-1 1s-1-.4-1-1V19c0-.6.4-1 1-1zM13 11c0-.6.4-1 1-1h1c1.7 0 3 1.3 3 3v4c0 .6-.4 1-1 1s-1-.4-1-1v-4c0-.6-.4-1-1-1h-1c-.6 0-1-.4-1-1zm1 3.5c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1z",
        },
      }),
      _c("circle", { attrs: { fill: "#63E1E7", cx: "12", cy: "5", r: "4" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }