<template>
    <div class="subs-main">
        <h1 v-html="this.$i18n('my_account_subscription_information_title')" />
        <loader v-if="!userServices" :centered="true" />
        <template v-else>
            <section class="services">
                <template v-if="!this.currentActiveService">
                    <p v-html="this.$i18n('my_account_subscription_no_subscription_text')" />
                    <v-button :i18n="{ id: 'dashboard_word_list_paywall_title' }" :filled="true" @click.native="goSubscribe"/>
                </template>
                <div class="section active" v-else-if="currentActiveService">
                    <h3 class="title">{{ titleToday }}</h3>
                    <div class="details">
                        <p class="name">{{ subTitle }}</p>
                        <p v-if="this.currentActiveService && this.currentActiveService.isLifeTimeService" v-html="this.$i18n('account_subscription_paid_lifetime')" />
                        <p v-else-if="this.currentActiveService?.is1020campaignService">{{ this.currentActiveService?.product_name }}</p>
                    </div>
                </div>
                <div class="section active" v-if="currentActiveService && currentActiveService.is_voucher && currentActiveService.expires_at_text && !this.currentActiveService.isLifeTimeService">
                    <h3 class="title">{{ currentActiveService.expires_at_text }}</h3>
                    <div class="details">
                        <p class="name" v-html="this.$i18n('my_account_subscription_voucher_expired_text')" />
                    </div>
                </div>
                <template v-if="computedService && this.currentActiveService && !this.currentActiveService.isLifeTimeService">
                    <subs-item-single v-for="service in computedServices" :service="service"
                               :key="uniqueId('service_')"
                               v-on:cancel-subscription-prompt="cancelSubscription" />
                    <v-button :i18n="{ id: 'dashboard_word_list_paywall_title' }" :filled="true" @click.native="goSubscribe" v-if="!userHas1020campaignService && userIsOnActiveVoucherWithoutRecurring || userHasActiveSubscriptionButNotRecurring" />
                </template>
            </section>
            <div class="actions" v-if="!this.userServices.userHasLifetimeService && !userHas1020campaignService && this.userServices.userHasRecurringService && this.userServices.userCanManageSubscription">
                <section-action :title="this.$i18n('account_subscription_change')"
                                :description="this.$i18n('account_subscription_change_subtitle')"
                                :top-border="true"
                                v-on:action="manageSubscription" />
                <hr class="line" />
                <section-action :title="this.$i18n('account_payment_update')"
                                :description="this.$i18n('account_payment_update_subtitle')"
                                :bottom-border="true"
                                v-on:action="managePaymentMethod" />
            </div>
        </template>
    </div>
</template>

<script>
    import UserManager from "Modules/usermanager.js";
    import i18nUtils from "Util/i18n.js";
    import iso_duration from "iso8601-duration";
    import moment from "moment";
    import Loader from "ViewComponent/loader.vue";
    import { clone, find, uniqueId } from "lodash";
    import URI from "Util/uri.js";
    import SectionAction from "./section-action.vue";
    import { EventBus } from "Util/vue-event-bus.js";
    import Backbone from "backbone";
    import VButton from "ViewComponent/v-button.vue";
    import SubsItemSingle from "./subs-item-single.vue";

    export default {
        name: 'subs-main',
        components: { SubsItemSingle, VButton, SectionAction, Loader },
        data() {
            return {
                userServices: null,
                fetchUserServicesTimeout: null
            }
        },
        computed: {
            titleToday() {
                return i18nUtils.prop('my_account_date_today_title');
            },
            subTitle() {
                if (this.currentActiveService) {
                    if (this.currentActiveService && this.currentActiveService.subscription && this.currentActiveService.is_on_free_trial) {
                        return i18nUtils.prop('my_account_subscription_free_trial_text');
                    } else if (this.currentActiveService.is_voucher) {
                        return i18nUtils.prop('my_account_subscription_voucher_text');
                    } else if (this.currentActiveService.hasOwnProperty('duration')) {
                        return this.getSubscriptionNameByDuration(this.currentActiveService.duration);
                    } else if (this.currentActiveService.hasOwnProperty('title')) {
                        return this.currentActiveService.title;
                    }
                } else {
                    return null;
                }
            },
            services() {
                return (this.userServices && this.userServices.services && this.userServices.services.length > 0) ? this.userServices.services : null
            },
            computedService() {
                if (this.services && this.services.length > 0) {
                    let active_until_ts = null;
                    let _computedService = {
                        cancelled: true,
                        is_on_free_trial: false
                    };
                    for (let _service of this.services) {
                        _service.active = moment(_service.active_since_ts).isBefore(moment());
                        if (_service.subscription && _service.subscription.status !== "cancelled") {
                            _computedService.cancelled = false;
                        }
                        if (_service.subscription && (moment(_service.subscription.expiration_ts).isAfter(moment())) && _service.subscription.is_on_free_trial) {
                            _computedService.is_on_free_trial = true;
                        }
                        if (!active_until_ts || (moment(_service.active_until_ts).isAfter(active_until_ts))) {
                            active_until_ts = _service.active_until_ts;
                            _computedService = { ..._computedService, ..._service };
                        }
                    }
                    return _computedService;
                } else {
                    return null;
                }
            },
            computedServices() {
                let computedServices = [];
                if (this.services && this.services.length > 0) {
                    for (let _service of this.services) {
                        let _localService = clone(_service);
                        _localService.active = moment(_service.active_since_ts).isBefore(moment());
                        _localService.product_name = i18nUtils.prop(_service.product_name, null, null, "subscription_name");
                        _localService.cancelled = _service.subscription && _service.subscription.status === "cancelled";
                        _localService.is_on_free_trial = !!(_service.subscription && (moment(_service.subscription.expiration_ts).isAfter(moment())) && _service.subscription.is_on_free_trial);
                        _localService.starting_from_text = i18nUtils.prop('my_account_date_future_title', { date: _service.formattedStartingDate });
                        _localService.expires_at_text = _service.formattedExpireDate ? i18nUtils.prop('my_account_date_future_title', { date: _service.formattedExpireDate }) : null;
                        computedServices.push(_localService);
                    }
                }

                return computedServices.reverse();
            },
            currentActiveService() {
                return find(this.computedServices, { active: true });
            },
            userIsOnActiveVoucherWithoutRecurring() {
                return this.computedService && this.computedService.subscription && !this.computedService.subscription.is_recurring && this.computedService.payment_provider === 'voucher';
            },
            userHasActiveSubscriptionButNotRecurring() {
                return this.userServices && !this.userServices.userHasRecurringService && !this.userServices.userHasLifetimeService && this.computedService.cancelled && moment(this.computedService.active_until_ts).isAfter(moment());
            },
            userHas1020campaignService() {
                return this.userServices && this.userServices.userHas1020campaignService;
            }
        },
        methods: {
            uniqueId,
            async fetchUserServices(delay = 0) {
                this.userServices = null;
                this.fetchUserServicesTimeout = setTimeout(async () => {
                    const user = UserManager.instance.getUser();
                    try {
                        const response = await user.getPayApi().getUserServices();

                        let userHasRecurringService = false;
                        let userCanManageSubscription = false;
                        let userHasLifetimeService = false;
                        let userHas1020campaignService = false;
                        let paymentProvider = null;
                        let services = [];

                        if ('services' in response) {
                            services = response.services.map((service) => {
                                const { product_name, subscription, payment_provider, active_since_ts, active_until_ts } = service;
                                const currentLocale = i18nUtils.currentLocale;

                                service.isLifeTimeService = product_name === 'unlimited-lifetime';
                                service.is1020campaignService = product_name === 'unlimited-10-years' || product_name === 'unlimited-20-years';
                                service.is_voucher = payment_provider === 'voucher';
                                userHasLifetimeService = (userHasLifetimeService) ? true : Boolean(service.isLifeTimeService);
                                userHas1020campaignService = (userHas1020campaignService) ? true : Boolean(service.is1020campaignService);

                                if (subscription?.is_recurring) {
                                    if (subscription.status === 'active') {
                                        const duration = iso_duration.parse(subscription.period);
                                        const months = duration.years * 12 + duration.months;

                                        Object.assign(service, {
                                            intervalMonths: months,
                                            formattedPrice: `${subscription.price.currency} ${parseFloat(subscription.price.amount).toFixed(2)}`,
                                            formattedBillingInterval: i18nUtils.prop('months', { months }, null, 'time', null),
                                            formattedBillingDate: moment(subscription.next_billing_ts).locale(currentLocale).format('ll'),
                                            formattedStartingDate: moment(active_since_ts).locale(currentLocale).format('ll'),
                                            formattedExpireDate: active_until_ts ? moment(active_until_ts).locale(currentLocale).format('ll') : null,
                                            isRecurringAndActive: true,
                                        });

                                        userHasRecurringService = true;
                                        paymentProvider = payment_provider;
                                        userCanManageSubscription = paymentProvider === 'braintree';
                                    } else {
                                        service.formattedStartingDate = moment(active_since_ts).locale(currentLocale).format('ll'),
                                        service.formattedExpireDate = moment(active_until_ts).locale(currentLocale).format('ll');
                                    }

                                    if (['active', 'past due', 'trial'].includes(subscription.status) && payment_provider === 'braintree') {
                                        service.enableCancellation = true;
                                    }
                                } else {
                                    service.formattedStartingDate = moment(active_since_ts).locale(currentLocale).format('ll');
                                    service.formattedExpireDate = service.isLifeTimeService || service.is1020campaignService ? null : moment(active_until_ts).locale(currentLocale).format('ll');
                                }

                                return service;
                            });
                        }

                        this.userServices = {
                            services,
                            userHasRecurringService,
                            userCanManageSubscription,
                            userHasLifetimeService,
                            userHas1020campaignService,
                            paymentProvider,
                        };
                    } catch (error) {
                        // handle error as appropriate, for example:
                        console.error(error);
                        throw error;
                    }
                }, delay)
            },
            manageSubscription () {
                new URI('lingvist:store?page=recurring-products&catalog=unlimited').navigateTo();
            },
            managePaymentMethod () {
                Backbone.history.navigate('subscriptions/manage', { trigger: true });
            },
            goSubscribe() {
                Backbone.history.navigate('subscriptions', { trigger: true });
            },
            cancelSubscription(subscription_uuid) {
                this.$emit('cancel-subscription-modal', subscription_uuid);
            },
            listenEventBus() {
                EventBus.$on('account:refresh-services', this.fetchUserServices);
            },
            unListenEventBus() {
                EventBus.$off('account:refresh-services', this.fetchUserServices);
            },
            getSubscriptionNameByDuration(duration) {
                if (duration) {
                    switch (duration) {
                        case 'P1M':
                            return i18nUtils.prop('my_account_subscription_monthly_subscription_text');
                        case 'P1Y':
                            return i18nUtils.prop('my_account_subscription_annual_subscription_text');
                    }
                } else {
                    return null;
                }
            }
        },
        async mounted() {
            this.listenEventBus();
            await this.fetchUserServices();
        },
        beforeDestroy() {
            this.unListenEventBus();
            clearTimeout(this.fetchUserServicesTimeout);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.subs-main {
        position: relative;
        min-height: 100%;
        > h1 {
            font-size: fontSize(22);
            line-height: fontSize(26);
            margin-bottom: 2rem;
        }

        > section.services {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 2rem;
            div.section {
                padding: 0 1rem;
                > h3.title {
                    font-size: fontSize(16);
                    font-weight: $font-bold;
                    margin-bottom: .5rem;
                }
                > div.details {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    border-left: 1px solid $colorSourceSecondary;
                    margin-left: .5rem;
                    padding-left: 1rem;
                    align-items: flex-start;
                    > p {
                        > em {
                            font-weight: $font-bolder;
                        }
                        &.name {
                            color: $colorSourceSecondary;
                            opacity: 1;
                        }
                        font-size: fontSize(14);
                    }
                }
            }
        }

        > div.actions {
            > hr.line {
                margin: .5rem 0;
            }
        }
    }
</style>
