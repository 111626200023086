var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "12",
          height: "6",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M6.029 3.79L1.645.236C1.368.002.985-.064.646.064a.992.992 0 10-.253 1.72l4.993 3.997c.366.292.887.292 1.252 0l4.994-3.997A.99.99 0 0011.779.39a1.003 1.003 0 00-1.4-.154L6.028 3.79z",
          fill: "#2C3143",
          "fill-rule": "evenodd",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }