import Backbone from 'backbone';

import UserModel from '../../model/user.model.js';
import OauthProviderModel from '../../model/oauth-provider.model.js';
import i18nUtils from '../../util/i18n.js';
import { parse as parse_qs } from '../../util/query-string.js';


const SsoView = Backbone.View.extend({

    name: 'sso',

    events: {},

    initialize: function (options) {

        var hashParams = options.queryParameters,
            defaultParams = parse_qs(window.location.search, true),
            promise = Promise.resolve();

        // hash parameters have higher priority over url parameters
        Object.assign(defaultParams, hashParams);

        if (defaultParams.language) {
            promise = Promise.resolve().then(function () {
                return i18nUtils.setInterfaceLanguage(defaultParams.language);
            });
        }

        var paymentFlowEnabled = (defaultParams.flow && defaultParams.flow === 'pay');

        OauthProviderModel.enableSsoFlow(defaultParams);

        promise.then(function () {
            return UserModel.assureSignedIn('sso', { paymentFlow: paymentFlowEnabled });
        }).catch(function () {
            console.warn('Unable to sign in');
            return OauthProviderModel.disableSsoFlow();
        });
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },
});

export default SsoView;
