
import ApiClient from '@lingvist/api-client-js';
import getConfigValue from './configuration.js';
import { version } from '../version.js';

export const unauthenticated_api_client =
    ApiClient.getClient({}, getConfigValue('api-url'), {
        clientType: 'web',
        clientVersion: version
    });
