'use strict';

let windowObject = null;
try {
    windowObject = window;
} catch (error) {
    console.warn(`Working in a context where the 'window' object is not defined`);
}

function export_ (name, object) {
    if (windowObject === null) {
        return;
    }

    var nameParts = name.split('.'),
        previousObject = windowObject;

    for (var i = 0; i < nameParts.length; i++) {
        var namePart = nameParts[i];
        if (i === nameParts.length - 1) {
            previousObject[namePart] = object;
        } else if (previousObject[namePart] === undefined) {
            previousObject[namePart] = {};
            previousObject = previousObject[namePart];
        } else {
            previousObject = previousObject[namePart];
        }
    }
}

export_('app.config', {});

export default {
    export: export_,
    config: function (key, default_) {
        if (windowObject && windowObject.app && windowObject.app.config && windowObject.app.config[key]) {
            return windowObject.app.config[key];
        } else {
            return default_;
        }
    }
};

