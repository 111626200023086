var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.917 21.11c.86-1.145.58-2.779-.612-3.574L9 16l-3 4 2.535 1.69a2.496 2.496 0 003.382-.58z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 13l.6-.8a1 1 0 00-1.422.23L11 13zm4.14 3.105l.6-.8-.6.8zm3.675-.057l.624.781-.624-.78zm.685-.548l.976-.22a1 1 0 00-1.6-.56l.624.78zM22.32 28l-.976.22a1 1 0 00.976.78v-1zM29 28v1-1zm3 8v1h1v-1h-1zm-16 0l-.581.814A1 1 0 0016 37v-1zM2.581 25.186a1 1 0 10-1.162 1.628l1.162-1.628zm1.597-4.088a1 1 0 001.644 1.138l-1.644-1.139zM10.4 13.8l4.14 3.105 1.2-1.6L11.6 12.2l-1.2 1.6zm9.04 3.029l.685-.548-1.25-1.562-.685.548 1.25 1.562zm-.916-1.109l2.82 12.5 1.95-.44-2.819-12.5-1.95.44zM22.32 29H29v-2h-6.68v2zM32 35H16v2h16v-2zm-1-4v5h2v-5h-2zm-14.419 4.186l-14-10-1.162 1.628 14 10 1.162-1.628zM29 29a2 2 0 012 2h2a4 4 0 00-4-4v2zM14.54 16.905a4 4 0 004.9-.076l-1.25-1.562a2 2 0 01-2.45.039l-1.2 1.6zm-8.718 5.33l6-8.666-1.644-1.138-6 8.667 1.644 1.138z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31 33H18L8 26M20 20h-2M21 24h-2M22 28h-2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M35 11a4 4 0 11-7.999 0A4 4 0 0135 11z",
          fill: "#AC6ED1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31 7V3.5M35 11h3.5M31 15v3.5M27 11h-3.5M33.828 8.172l2.475-2.475M33.828 13.829l2.475 2.474M28.172 13.829l-2.475 2.474M28.172 8.172l-2.475-2.475",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }