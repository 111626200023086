import $ from 'jquery';

import ContentDialogView from '../util/content.dialog.view.js';


const GrammarTipsView = ContentDialogView.extend({

    name: 'grammar-tips',

    tagName: 'main',
    className: 'main-view grammar-tips',

    initialize: function (options) {
        this.mainTemplate = 'grammar-tips/main';
        this.listTemplate = 'grammar-tips/list';
        this.contentTemplate = 'grammar-tips/content';

        this._model = options.model;

        $.extend(this.events, {
            'change #show-favorites': 'toggleFavorites',
            'change aside > ul span.favorite > input': 'toggleFavorite',
            'click span.word': 'showWordTranslation'
        });

        this.delegateEvents();
    },

    render: function () {
        var self = this;

        return Promise.resolve().then(function () {
            return self._model.getMenuTips();
        }).then(function (content) {
            ContentDialogView.prototype.render.call(self, content);
            return Promise.resolve();
        });
    },

    showAll: function (event) {

        var self = this;
        var $searchInput = this.$el.find('input.search');

        return new Promise(function(resolve, reject) {
            if (self._model.inFavorites) {
                self._model.inFavorites = false;
                self._model.getMenuTips().then(function (data) {
                    resolve({refresh: true, data: data});
                }, reject);
            } else if ($searchInput.val().length > 0) {
                self._model.getMenuTips().then(function (data) {
                    resolve({refresh: true, data: data});
                }, reject);
            } else {
                resolve({refresh: false});
            }
        });
    },

    focusItem: function (itemKey) {
        var self = this;

        return new Promise(function (resolve, reject) {
            if (self._model.inFavorites && !self._model.getHintFavorited(itemKey)) {
                self._model.inFavorites = false;
                self._model.getMenuTips().then(function (data) {
                    resolve({refresh: true, data: data});
                }, reject);
            } else {
                resolve({refresh: false});
            }
        });
    },

    search: function (event) {
        var self = this,
            $target = $(event.currentTarget),
            searchQuery = $target.val();

        self._model.getMenuTips(searchQuery).then(function (data) {
            self._refresh(data);
        });
    },

    toggleFavorites: function (event) {
        var self = this,
            $target = $(event.currentTarget);

        self._model.inFavorites = $target.is(':checked');
        this.$el.find('input.search').val('');
        self._model.getMenuTips().then(function (data) {
            self._refresh(data);
        });
    },

    toggleFavorite: function (event) {
        var self = this,
            $target = $(event.currentTarget),
            hintKey = $target.parent().parent().data('key');

        self._model.setHintFavorited(hintKey, $target.is(':checked'));
        if ($target.is(':checked')) {
            this.$el.find('div.show-all > span.favorite').addClass('pop');
            setTimeout(function () {
                self.$el.find('div.show-all > span.favorite').removeClass('pop');
            }, 500);
        }
        var numberOfFavorites = self._model.getNumberOfFavorites();
        if (numberOfFavorites > 0) {
            this.$el.find('div.show-all > span.favorite').addClass('has-favorites');
            this.$el.find('div.show-all > span.number').text(numberOfFavorites);
        } else {
            this.$el.find('div.show-all > span.favorite').removeClass('has-favorites');
            this.$el.find('div.show-all > span.number').text('');
        }
        if (self._model.inFavorites) {
            self._model.getMenuTips().then(function (data) {
                self._refresh(data);
            });
        }
    }
});

export default GrammarTipsView;
