<template>
    <div class="variation-modal-share">
        <v-button @click.native="copyShareUrl" v-if="!copied"
                  :i18n="{ id: 'variation_page_continue_share_button' }" :filled="true" color="paper" />
        <div class="copied-message" v-else>
            📋 &nbsp; <span v-html="this.$i18n('variation_page_link_copied_notification')" />
        </div>
        <input type="hidden" ref="shareUrlInput" :value="variation.share_url" class="shareUrlInput" />
    </div>
</template>

<script>
    import VButton from 'ViewComponent/v-button.vue';
    import UserManager from 'Modules/usermanager';

    export default {
        name: 'variation-modal-share',
        props: {
            variation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                copied: false
            };
        },
        components: {
            VButton,
        },
        methods: {
            copyShareUrl() {
                const shareUrlInput = this.$refs.shareUrlInput;
                shareUrlInput.setAttribute('type', 'text');
                shareUrlInput.select();

                try {
                    document.execCommand('copy');
                } catch (err) {
                    console.log('HUB: copyShareUrl copy failed');
                }

                shareUrlInput.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges();

                this.copied = true;
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('Variations', 'VariationPageShare', this.variation.uuid);

                setTimeout(() => {
                    this.copied = false;
                }, 3000);
            },
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.variation-modal-share {
        > div.copied-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: pxToRem(8) pxToRem(16);
            font-size: fontSize(12);
            background-color: $colorBackgroundDarkLighter;
            color: #fff;
            border-radius: pxToRem(32);
            user-select: none;
        }
    }
</style>
