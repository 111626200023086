<template>
    <div class="modal-story" v-if="storyComponent">
        <div class="modal-story-window" :data-story="contentId">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="prev" @click="previousPage" v-if="current !== 0">
                <circle cx="20" cy="20" r="20" fill="white"/>
                <path d="M23 13L16 20L23 27" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="next" @click="nextPage" v-if="current !== storyPagesCount - 1">
                <circle cx="20" cy="20" r="20" fill="white"/>
                <path d="M17 27L24 20L17 13" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <nav ref="story_nav">
                <div v-for="i in storyPagesCount">
                    <div :key="i"></div>
                </div>
            </nav>
            <header>
                <div class="title" v-if="storyTitle">{{ storyTitle }}</div>
                <div class="header-actions">
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="this.isPaused" @click="continueStory">
                        <circle opacity="0.5" cx="18.9999" cy="18.9995" r="18" transform="rotate(-1 18.9999 18.9995)" fill="white"/>
                        <path d="M14 15V12.7896C14 12.0072 14.8578 11.5279 15.5241 11.9379L25.6161 18.1483C26.2506 18.5388 26.2506 19.4612 25.6161 19.8517L15.5241 26.0621C14.8578 26.4721 14 25.9928 14 25.2104V20" stroke="#2C3143" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="pause" v-else @click="pauseStory">
                        <circle opacity="0.5" cx="18.9999" cy="18.9995" r="18" transform="rotate(-1 18.9999 18.9995)" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 10C11.3431 10 10 11.3431 10 13V25C10 26.6569 11.3431 28 13 28H14C15.6569 28 17 26.6569 17 25V13C17 11.3431 15.6569 10 14 10H13ZM12 13C12 12.4477 12.4477 12 13 12H14C14.5523 12 15 12.4477 15 13V25C15 25.5523 14.5523 26 14 26H13C12.4477 26 12 25.5523 12 25V13ZM24 10C22.3431 10 21 11.3431 21 13V19C21 19.5523 21.4477 20 22 20C22.5523 20 23 19.5523 23 19V13C23 12.4477 23.4477 12 24 12H25C25.5523 12 26 12.4477 26 13V25C26 25.5523 25.5523 26 25 26H24C23.4477 26 23 25.5523 23 25V23C23 22.4477 22.5523 22 22 22C21.4477 22 21 22.4477 21 23V25C21 26.6569 22.3431 28 24 28H25C26.6569 28 28 26.6569 28 25V13C28 11.3431 26.6569 10 25 10H24Z" fill="#2C3143"/>
                    </svg>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" @click="close" class="close">
                        <circle opacity="0.5" cx="18.9999" cy="18.9995" r="18" transform="rotate(-1 18.9999 18.9995)" fill="white"/>
                        <path d="M13 13L25 25" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
                        <path d="M25 13L13 25" stroke="#2C3143" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </div>
            </header>
            <section class="story-content" @touchstart="onTouch">
                <component v-bind:is="storyComponent" v-on:story-data="setStoryData" v-on:close="close" :active-index="current" />
            </section>
        </div>
    </div>
</template>

<script>
import anime from "animejs";
import JapaneseGeneralContent from "./content/japanese-general.vue";
import JapaneseKatakanaContent from "./content/japanese-katakana.vue";
import JapaneseHiraganaContent from "./content/japanese-hiragana.vue";
import KoreanGeneralContent from "./content/korean-general.vue";
import KoreanHangeulContent from "./content/korean-hangeul.vue";
import RussianGeneralContent from "./content/russian-general.vue";
import RussianCyrillicContent from "./content/russian-cyrillic.vue";
import { head } from "lodash";

export default {
    name: 'modal-story',
    props: {
        contentId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            storyPagesCount: 0,
            storyTitle: null,
            current: 0,
            playTimeout: null,
            timeline: null,
            timerInSeconds: 7,
            isPaused: false,
        }
    },
    computed: {
        storyComponent() {
            switch (this.contentId) {
                case 'japanese-general':
                    return JapaneseGeneralContent;
                case 'japanese-katakana':
                    return JapaneseKatakanaContent;
                case 'japanese-hiragana':
                    return JapaneseHiraganaContent;
                case 'korean-general':
                    return KoreanGeneralContent;
                case 'korean-hangeul':
                    return KoreanHangeulContent;
                case 'russian-general':
                    return RussianGeneralContent;
                case 'russian-cyrillic':
                    return RussianCyrillicContent;
                default:
                    // if no matching story-content found, emit close
                    this.$emit('close');
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        keyDown(event) {
            if (event && event.code === 'Escape') { // close on ESC key
                this.close()
            }
        },
        setStoryData(data) {
            if (data) {
                if (data.hasOwnProperty('count')) {
                    this.storyPagesCount = data.count;
                }
                if (data.hasOwnProperty('title')) {
                    this.storyTitle = data.title;
                }
                if (data.hasOwnProperty('timer')) {
                    this.timerInSeconds = data.timer;
                }
            }
        },
        continueStory() {
            this.isPaused = false;
            this.timeline.play();
        },
        pauseStory() {
            this.isPaused = true;
            this.timeline.pause();
        },
        playStory() {
            this.playTimeout = setTimeout(() => {
                this.timeline = anime.timeline({
                    autoplay: true,
                    duration: this.timerInSeconds * 1000,
                    easing: 'linear',
                    loop: false
                })
                let _story_nav_element = this.$refs.story_nav;
                for (let i = 0; i < this.storyPagesCount; i++ ) {
                    let _selector = `div:nth-child(${i + 1}) > div:first-child`;
                    let _target = _story_nav_element.querySelector(_selector);

                    this.timeline.add({
                        targets: _target,
                        width: '100%',
                        changeBegin: (a) => {
                            this.current = i
                        }
                    })
                }
            }, 500)
        },
        nextPage() {
            if (this.current < this.storyPagesCount) {
                this.current += 1;
                this.timeline.pause();
                this.timeline.seek(this.current * (this.timerInSeconds * 1000));
                this.timeline.play();
                this.isPaused = false;
            }
        },
        previousPage() {
            if (this.current > 0) {
                this.current -= 1;
                this.timeline.pause();
                this.timeline.seek(this.current * (this.timerInSeconds * 1000));
                this.timeline.play();
                this.isPaused = false;
            }
        },
        onTouch(e) {
            // TODO: prevent navigation on button touches
            if (e.targetTouches && e.targetTouches.length > 0) {
                const targetFirstTouch = head(e.targetTouches);
                if (targetFirstTouch.clientX > window.innerWidth / 2) {
                    this.nextPage();
                } else {
                    this.previousPage();
                }
            }
        },
        reset() {
            clearTimeout(this.playTimeout);
            this.timeline = null;
            this.current = 0;
            this.storyPagesCount = 0;
            this.storyTitle = null;
        },
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
        document.body.classList.add('modal-open');
        this.playStory();
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
        this.reset();
    }
};
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.modal-story {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        background-color: rgba(#2C3143, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div.modal-story-window {
            position: relative;
            @include elevated;
            background: #fff;
            border-radius: pxToRem(8);
            display: flex;
            flex-direction: column;
            width: pxToRem(360);
            height: pxToRem(640);
            box-sizing: border-box;
            padding: 1rem;
            gap: 1rem;
            &[data-story='japanese-general'],
            &[data-story='japanese-hiragana'],
            &[data-story='korean-general'],
            &[data-story='russian-general'] {
                background: linear-gradient(180deg, #E2B4FF 0%, #EEF0F4 100%);
            }
            &[data-story='japanese-katakana'],
            &[data-story='korean-hangeul'],
            &[data-story='russian-cyrillic'] {
                background: linear-gradient(180deg, #8FA2EC 0%, #EEF0F4 100%);
            }

            svg.next,
            svg.prev {
                position: absolute;
                top: 50%;
                &:hover {
                    cursor: pointer;
                    transform: scale(1.2);
                }
            }
            svg.next {
                right: -60px;
            }
            svg.prev {
                left: -60px;
            }

            @include respond-to('small') {
                width: 100%;
                height: 100%;
                border-radius: unset;
                svg.next,
                svg.prev {
                    display: none;
                }
            }

            > nav {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 1rem;
                min-height: pxToRem(4);
                user-select: none;
                > div {
                    flex: 1;
                    background: rgba($colorSourcePrimary, .25);
                    min-height: pxToRem(4);
                    border-radius: .25rem;
                    > div {
                        background: $colorSourcePrimary;
                        border-radius: .25rem;
                        min-height: pxToRem(4);
                        width: 0;
                    }
                }
            }

            > header {
                display: flex;
                flex-direction: row;
                align-items: center;
                > div.title {
                    font-size: fontSize(18);
                    font-weight: $font-bold;
                    user-select: none;
                }
                > div.header-actions {
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    gap: .5rem;
                    align-items: center;
                    > svg {
                        transition: 100ms ease-in-out;
                        &:hover {
                            cursor: pointer;
                            transform: scale(1.2);
                        }
                    }
                }
            }

            > section.story-content {
                flex: 1;
                user-select: none;
                display: flex;
                > div {
                    flex: 1;
                }
            }
        }
    }
</style>
