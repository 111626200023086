import moment from 'moment';

import { BaseExerciseAnswer } from './base.js';

export const ENTRY_TYPE = {
    TIME: 'time',
    LINK: 'link',
    COMPLETE: 'complete'
};

export class ArticleExerciseAnswer extends BaseExerciseAnswer {

    constructor (...args) {
        super(...args);
    }

    /**
     * Mark Answer as complete.
     */
    complete () {
        super.complete(this.isFinished());
    }

    /**
     * Check if user has clicked the "Complete reading" button.
     */
    isFinished () {
        return this.entry_events.find(
            entryEvent => entryEvent.type === ENTRY_TYPE.COMPLETE
        ) !== undefined;
    }

    /**
     * @param {string} type - Type of the Article Entry event.
     */
    addEntry (type) {

        super.addEntry();

        switch (type) {
            case ENTRY_TYPE.TIME: {
                this.entry_events.push({
                    type,
                    ts: moment(),
                });
                break;
            }
            case ENTRY_TYPE.LINK: {
                this.entry_events.push({
                    type,
                    ts: moment()
                });
                break;
            }
            case ENTRY_TYPE.COMPLETE: {
                this.entry_events.push({
                    type,
                    ts: moment()
                });
                break;
            }
            default: {
                throw Error(`Unrecognized exercise answer entry type="${type}"`);
            }
        }
    }
}
