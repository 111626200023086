var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.75 12.75c.414-.69.19-1.586-.5-2L6 10l-1.5 2.5 1.25.75c.69.414 1.586.19 2-.5z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 8l.555-.832a1 1 0 00-1.403.302L7 8zm3 2l.606-.796a.978.978 0 00-.051-.036L10 10zm2.089-.145l.63.777.024-.02-.654-.757zM12.5 9.5l.98-.196a1 1 0 00-1.634-.56l.654.756zM14 17l-.98.196A1 1 0 0014 18v-1zm6 5v1h1v-1h-1zm-10 0l-.6.8a1 1 0 00.6.2v-1zm-7.4-6.8a1 1 0 10-1.2 1.6l1.2-1.6zm1.052-3.73a1 1 0 001.696 1.06l-1.696-1.06zm2.793-2.638l3 2 1.11-1.664-3-2-1.11 1.664zm6.298 1.78l.411-.355-1.308-1.514-.411.356 1.308 1.513zm-1.223-.916l1.5 7.5 1.96-.392-1.5-7.5-1.96.392zM14 18h4v-2h-4v2zm6 3H10v2h10v-2zm-9.4.2l-8-6-1.2 1.6 8 6 1.2-1.6zM9.395 10.796c1.123.854 2.432.56 3.324-.164l-1.26-1.553c-.199.161-.375.224-.501.235a.477.477 0 01-.352-.11l-1.211 1.592zM5.348 12.53l2.5-4-1.696-1.06-2.5 4 1.696 1.06zM19 19v3h2v-3h-2zm-1-1a1 1 0 011 1h2a3 3 0 00-3-3v2z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13 14h-2M15 17h-3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "18",
          cy: "6",
          r: "3",
          fill: "#AC6ED1",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }