var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M24.903 15c.064-.49.097-.991.097-1.5C25 7.15 19.852 2 13.5 2 7.15 2 2 7.15 2 13.5 2 19.853 7.15 25 13.5 25c.509 0 1.01-.033 1.5-.097C15 19.5 20.5 15 24.903 15zM9.72 6.72a1 1 0 011.414 0l2.573 2.573a1 1 0 01-1.414 1.414L9.72 8.134a1 1 0 010-1.414zM12 12.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm8 0c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5a1.5 1.5 0 013 0zm-10 4a1 1 0 10-2 0 3.5 3.5 0 003.5 3.5H14a1 1 0 100-2h-2.5a1.5 1.5 0 01-1.5-1.5zm9.207-8.293a1 1 0 00-1.414-1.414l-2.5 2.5a1 1 0 001.414 1.414l2.5-2.5z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 26c0-6.074 4.926-11 11-11 6.075 0 11 4.926 11 11 0 6.075-4.925 11-11 11-3.648 0-6.883-1.777-8.885-4.513",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M24 24.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM31 24.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5a1.5 1.5 0 013 0z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21 29.5c.776 1.168 2.714 2.5 5.001 2.5 2.285 0 4.223-1.332 4.999-2.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }