import Backbone from 'backbone';
import ErrorView from '../view/overlay/error.view.js';

/**
 * @typedef {object} APIError
 * @property {string} code - Application internal error classification
 * @property {{code: string, message: string}} data - Server error object
 */

var ErrorController = {

    initialize: function () {
        this._errorView = new ErrorView();
        this._errorView.render();
        Backbone.on('server-error', this.showError, this);
    },

    /**
     *
     * @param {boolean} isReloadable - Should the error screen provide a reload option
     * @param {APIError|null} error - Error object to display if available
     */
    showError: function (isReloadable, error=null) {
        this._errorView.isReloadable = ((isReloadable === undefined) || isReloadable);
        this._errorView.render(error);
        this._errorView.show();
    },

    hideError: function () {
        this._errorView.hide();
    }
};

 export default ErrorController;
