
'use strict';

/**
 * A port of the lingvist_helpers.utils.schema module.
 * Contains helper functions for lingvist schema parsing
 * */

const SCHEMA_PARSING_RE= /(.*):(\d)\.(\d)/;

class ParsedSchema {
    constructor (name, major, minor) {
        this.name = name;
        this.major = parseInt(major);
        this.minor = parseInt(minor);

        if (isNaN(this.major)) {
            throw Error(`Unexpected value for schema major version: "${major}"`);
        }

        if (isNaN(this.minor)) {
            throw Error(`Unexpected value for schema minor version: "${minor}"`);
        }
    }
}

export default class Schema {
    static parseEventSchema (event) {
        return Schema.parseSchema(event.event.schema);
    }

    static parseSchema (schema) {
        var matches = schema.match(SCHEMA_PARSING_RE),
            name, minor, major;

        if (matches !== null && matches.length === 4) {
            name = matches[1];
            major = matches[2];
            minor = matches[3];
        } else {
            name = '';
            major = 0;
            minor = 0;
        }

        return new ParsedSchema(name, major, minor);
    }

    static isSchema (schema, expectedSchema, expectMajor, expectMinor) {
        var parsed = Schema.parseSchema(schema);
        return expectedSchema === parsed.name &&
               (expectMajor === undefined || expectMajor === parsed.major) &&
               (expectMinor === undefined || expectMinor === parsed.minor);
    }

    static isSchemaMinorGE (schema, expectedSchema, expectMajor, expectMinor) {
        var parsed = Schema.parseSchema(schema);
        return expectedSchema === parsed.name &&
               expectMajor === parsed.major &&
               expectMinor <= parsed.minor;
    }

    static isSchemaMinorLE (schema, expectedSchema, expectMajor, expectMinor) {
        var parsed = Schema.parseSchema(schema);
        return expectedSchema === parsed.name &&
               expectMajor === parsed.major &&
               expectMinor >= parsed.minor;
    }
}
