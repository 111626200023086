import $ from 'jquery';
import Backbone from 'backbone';
import EventUtils from '../../util/event.js';
import renderTemplate from '../../util/template.renderer.js';
import i18nUtils from '../../util/i18n.js';

const DIRECTIVE = {
    SHOW: 'show',
    HIDE: 'hide'
};

const LoaderView = Backbone.View.extend({

    events: {
        'click div.switch.permanent-translations': 'togglePermanentTranslations'
    },

    // Track the latest thing that was asked from the view and don't continue
    // certain actions if the directive has changed during timeouts
    latest_directive: null,

    initialize: function () {
        console.log("LoaderView initialize");
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        $('body > div.loader-overlay').remove();

        return Promise.resolve()
            .then(() => renderTemplate('loader'))
            .then(output => {
                $('body').append(output);
                this.setElement($('body > div.loader-overlay'));
                return this._postRender();
            });
    },

    _postRender: function () {},

    show: function (route) {
        this.latest_directive = DIRECTIVE.SHOW;

        // Some loaders require special messaging
        switch (route) {
            case 'account/signout': {
                this.$el.find('p.loader-message').html(i18nUtils.prop('loader_signing_out'));
                break;
            }
            default: {
                this.$el.find('p.loader-message').empty();
                break;
            }
        }

        this.$el.removeClass('not-displayed');
        EventUtils.off('transitionend', this.$el);
        setTimeout(() => {
            if (this.latest_directive === DIRECTIVE.SHOW) {
                this.$el.removeClass('hidden');
            }
        }, 5);
    },

    hide: function () {
        this.latest_directive = DIRECTIVE.HIDE;

        if (!this.$el.hasClass('hidden')) {
            EventUtils.oneTransitionEnd(this.$el, () => {
                EventUtils.off('transitionend', this.$el);
                this.$el.addClass('not-displayed');
            }, 250);
            this.$el.addClass('hidden');
        } else {
            this.$el.addClass('not-displayed');
        }
    }
});

export default LoaderView;
