<template>
    <section class="signin">
        <h1 v-html="this.$i18n('signin_welcome')"></h1>

        <section class="user-accounts">
            <div
                class="account"
                @click="signin_account_selected({user, auth_provider})"
                v-for="{ user, course, subscription, auth_provider } in user_accounts_"
                :key="user.user_uuid"
            >

                <div class="highlight">
                    <course-icon :value="course.target_icon_id" size="large" />
                    <div class="info">
                        <h4 class="email">{{ user.email }}</h4>
                        <p class="course">{{ course.name }} {{ course.name_subtitle }}</p>
                        <p class="subscription">{{ subscription.text }}</p>
                    </div>
                </div>

            </div>
        </section>

        <footer>
            <authentication-options
                :options="login_options"
                :more_options_label="this.$i18n('signin_more_options')"
                @option_selected="signin_option_selected"
            />
            <button
                v-if="register_enabled"
                class="register outline"
                v-html="this.$i18n('signin_new_to_lingvist')"
                @click="$emit('register')"
            ></button>

            <p class="error" v-if="current_error === 'error-authentication'" v-html="this.$i18n('signin_error_authentication')"></p>
            <p class="error" v-else-if="current_error === 'authorization-failed'" v-html="this.$i18n('signin_error_authorization_failed')"></p>
            <p class="error" v-else-if="current_error === 'error-server'" v-html="this.$i18n('signin_error_server')"></p>
            <p class="error" v-else-if="current_error === 'error-network'" v-html="this.$i18n('signin_error_network')"></p>
            <p class="error" v-else-if="current_error === 'duplicate-email'" v-html="this.$i18n('signin_error_duplicate_email')"></p>
            <p class="error" v-else-if="current_error === 'deletion-in-progress'" v-html="this.$i18n('signin_error_deletion_in_progress')"></p>
            <p class="error" v-else-if="current_error === 'error-unauthorized'" v-html="this.$i18n('signin_error_unauthorized')"></p>
            <p class="error" v-else-if="current_error === 'error-unauthorized'" v-html="this.$i18n('signin_error_server')"></p>
        </footer>
    </section>
</template>

<script>

import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';

import AuthenticationOptions from '../component/authentication-options.vue';
import CourseIcon from '../component/course-icon.vue';
import i18nUtils from 'Util/i18n.js';
import {EventBus} from 'Util/vue-event-bus.js';
import EventSender from "Modules/eventsender.js";

Vue.use(ToggleButton);

const ERRORS = [
    'authorization-failed', 'error-server', 'error-network', 'duplicate-email', 'deletion-in-progress',
    'error-unauthorized', 'error-unauthorized'];

export default {
    name: 'register.select-course',
    components: {
        AuthenticationOptions,
        CourseIcon,
    },
    props: {
        user_accounts: {
            type: Array,
            required: true,
        },
        get_user_accounts: {
            type: Function,
            required: true,
        },
        register_enabled: {
            type: Boolean,
            required: true,
        },
        error_unauthorized: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            current_error: null,
            user_accounts_: this.user_accounts,
            login_options: this.get_login_options(),
            eventSender: null
        };
    },
    methods: {
        async signin_option_selected (option) {
            await this.eventSender.sendNavigationEvent('login', 'click', option);
            this.$emit('signin_option_selected', option);
        },
        signin_account_selected ({user, auth_provider}) {
            this.$emit('signin_account_selected', {user, auth_provider});
        },
        set_error (error) {
            this.current_error = ERRORS.includes(error) ? error : 'error-server';
        },
        reset_error () {
            this.current_error = null;
        },
        get_login_options () {
            const login_options = i18nUtils.getLoginOptions();
            return {
                primary: login_options.primary.map(o => ({
                    option: o,
                    label: i18nUtils.prop(o, null, null, `signin_option`)
                })),
                secondary: login_options.secondary.map(o => ({
                    option: o,
                    label: i18nUtils.prop(o, null, null, `signin_option`)
                }))
            }
        },
        async update_i18n_content () {
            // Hacky hack for making things update on language change
            this.login_options = this.get_login_options();
            this.user_accounts_ = await this.get_user_accounts();
        },
    },
    created () {
        if (this.error_unauthorized) {
            this.set_error('error-unauthorized');
        }
        EventBus.$on('interfaceLanguageChanged', this.update_i18n_content);
        this.eventSender = new EventSender(null);
    },
    mounted () {
        this.$emit('mounted');
    },
    beforeDestroy () {
        EventBus.$off('interfaceLanguageChanged', this.update_i18n_content);
        this.eventSender.destroy();
    }

};
</script>

<style scoped lang="scss">
@import '~Styles/colors';
@import '~Styles/mixins';

    section.signin {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5rem;

        > h1 {
            font-weight: $font-bold;
            font-size: pxToRem(22);
            margin-bottom: 2rem;
        }

        > section.user-accounts {
            width: 22.5rem;

            > div.account {
                border-bottom: 1px solid $colorBackgroundDarker;
                padding: 0.25rem 0;
                cursor: pointer;

                &:first-of-type {
                    border-top: 1px solid $colorBackgroundDarker;
                }

                > div.highlight {
                    padding: 0.5rem 1rem;
                    border-radius: 0.5rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    &:hover {
                        background-color: $colorBackgroundLighter;
                    }

                    > div.info {
                        > h4.email {
                            font-size: 1rem;
                            line-height: 1.2rem;
                            color: $colorSourcePrimary;
                            font-weight: $font-bold;
                        }

                        > p.course, > p.subscription {
                            line-height: 1rem;
                            font-size: pxToRem(12);
                            color: $colorSourceSecondary;
                        }
                    }
                }
            }
        }

        > footer {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 3.5rem;
            margin-bottom: 2rem;

            width: 22.5rem;

            > button.register {

            }
        }
    }
</style>
