var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vocabulary-page" },
    [
      _c("h1", {
        domProps: { innerHTML: _vm._s(this.$i18n("my_progress_vocabulary")) },
      }),
      _vm._v(" "),
      !this.courseHasVocabulary
        ? _c("div", { staticClass: "message" }, [
            _vm._v(
              "\n        This feature is not yet available for that course\n    "
            ),
          ])
        : [
            this.vocabularyDisabled
              ? _c("div", { staticClass: "message" }, [
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("my_progress_vocabulary_disabled_heading")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("my_progress_vocabulary_disabled_message")
                      ),
                    },
                  }),
                ])
              : this.chart_series && this.learned_text_raw
              ? _c(
                  "div",
                  { staticClass: "vocabulary-content" },
                  [
                    _c("vocabulary-chart", {
                      attrs: {
                        series: this.chart_series,
                        max_words: this.max_words,
                        position: this.position,
                        words_learned: this.position,
                      },
                      on: { "position-update": _vm.updatePosition },
                    }),
                    _vm._v(" "),
                    _c("vocabulary-text", {
                      attrs: {
                        learned_text_raw: this.learned_text_raw,
                        max_words: this.max_words,
                        "know-percentage": this.knowPercentage,
                        position: this.position,
                      },
                    }),
                  ],
                  1
                )
              : _c("loader", { attrs: { centered: true } }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.end_of_FT,
                    expression: "end_of_FT",
                  },
                ],
                staticClass: "continuation-button",
              },
              [
                _c("v-button", {
                  attrs: {
                    i18n: {
                      id: "hub_hero_learn_btn",
                      params: {
                        today_current_set_cards: _vm.todayCurrentSetCards,
                      },
                    },
                    filled: true,
                    color: "primary",
                    icon: "continue",
                    route: "guess",
                  },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }