var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M5 14V4.539a1 1 0 011.406-.914L14 7M35 19V4.539a1 1 0 00-1.406-.914L26 7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17 18a2 2 0 11-4 0 2 2 0 014 0zM27 18a2 2 0 11-4 0 2 2 0 014 0z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M20.707 23.793a1 1 0 01-1.414 0l-1.086-1.086c-.63-.63-.184-1.707.707-1.707h2.172c.89 0 1.337 1.077.707 1.707l-1.086 1.086z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 26.97c1.216 1.732 3.784 1.194 5-.97 1.216 2.164 3.784 2.701 5 .97",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.833 10.905A15.122 15.122 0 0120 6c4.418 0 8.395 1.89 11.167 4.905M35 19v2c0 8.374-6.626 15-15 15-8.374 0-15-6.626-15-15v-1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }