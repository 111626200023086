var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass:
            "status-invalid_svg__status-invalid_svg__ic-cw-status-invalid-paper-24",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass:
              "status-invalid_svg__status-invalid_svg__ic-cw-status-invalid-paper-24__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass:
                "status-invalid_svg__status-invalid_svg__ic-cw-status-invalid-paper-24__icon",
              attrs: { fill: "#FE5C55", "fill-rule": "nonzero" },
            },
            [
              _c("path", {
                staticClass:
                  "status-invalid_svg__status-invalid_svg__ic-cw-status-invalid-paper-24__icon__oval",
                attrs: {
                  d: "M12 6a6 6 0 110 12 6 6 0 010-12zm0 2a4 4 0 100 8 4 4 0 000-8z",
                },
              }),
              _c("path", {
                staticClass:
                  "status-invalid_svg__status-invalid_svg__ic-cw-status-invalid-paper-24__icon__path",
                attrs: {
                  d: "M17.293 5.293a1 1 0 011.497 1.32l-.083.094-12 12a1 1 0 01-1.497-1.32l.083-.094 12-12z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }