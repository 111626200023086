var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 101.414 1.414L12 13.414l4.293 4.293a1 1 0 001.414-1.414L13.414 12l4.293-4.293a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }