
'use strict';

import Raven from 'raven-js';
import Backbone from 'backbone';

import { assetLoader } from '../modules/global.asset.loader.js';
import UserManager from '../modules/usermanager.js';

import { COURSE_FEATURE } from '../modules/course/course.js';

class VocabularyDataLoadingFailedException extends Error {
    constructor (message) {
        super(message);
        this.name = 'VocabularyDataLoadingFailedException';
    }
}

const ResultsModel = {

    _vocabularyBaseData: {},

    initialize: function () {
        var self = this;

        this.initializeData();
        Backbone.on('userSignedOut', this.onUserSignedOut, this);

        // On language change and user sign in change start pre-loading data for the vocabulary view
        Backbone.on('userCourseChanged userSignedIn', async () => {
            if (UserManager.instance.getUser().hasActiveCourse() &&
                await UserManager.instance.getUser().getCourse().hasFeature(COURSE_FEATURE.VOCABULARY_CURVE)) {
                const target_language = UserManager.instance.getUser().getCourse().getInfo().target_language;
                self.getLearnedCurve(target_language);
                self.getLearnedText(target_language);
            }
        });
    },

    initializeData: function () {
        this.testsPerDayTarget = 150;
    },

    onUserSignedOut: function () {
        console.log("ResultsModel.onUserSignedOut");
        this.initializeData();
    },

    getLearnedText: function(language) {
        let self = this;

        if (self._vocabularyBaseData[language] === undefined) {
            self._vocabularyBaseData[language] = {};
        }

        if (this._vocabularyBaseData[language].text !== undefined) {
            return Promise.resolve(this._vocabularyBaseData[language].text);
        } else if (self._vocabularyBaseData[language].getTextPromise !== undefined) {
            return self._vocabularyBaseData[language].getTextPromise;
        } else {
            let getLearnedTextPromise =  Promise.resolve().then(function () {
                return assetLoader.loadAsset(
                    UserManager.instance.getUser().getCourse().getAssetUrl('vocabulary_text'), 0);
            }).then(function (learnedText) {
                learnedText = JSON.parse(learnedText)
                    .map(word => `<span data-priority="${word.p}">${word.w}</span>`).join('');
                self._vocabularyBaseData[language].text = learnedText;
                delete self._vocabularyBaseData[language].getTextPromise;
                return Promise.resolve(learnedText);
            }).catch(function(error) {
                Raven.captureException(new VocabularyDataLoadingFailedException(
                    `Language learned text data loading failed for language="${language}"`), {
                    level: 'warning',
                    fingerprint: ['{{ default }}', 'text', language],
                    extra: {error: error}
                });
                // If the text does not exist
                self._vocabularyBaseData[language].text = null;
                delete self._vocabularyBaseData[language].getTextPromise;
            });
            self._vocabularyBaseData[language].getTextPromise = getLearnedTextPromise;
            return getLearnedTextPromise;
        }
    },

    getLearnedTextRaw: async function(language) {
        const raw_text =  await assetLoader.loadAsset(
            UserManager.instance.getUser().getCourse().getAssetUrl('vocabulary_text'), 0);
        return JSON.parse(raw_text);
    },

    getLearnedCurve: function(language) {
        let self = this;

        if (self._vocabularyBaseData[language] === undefined) {
            self._vocabularyBaseData[language] = {};
        }

        if (this._vocabularyBaseData[language].curve !== undefined) {
            return Promise.resolve(this._vocabularyBaseData[language].curve);
        } else if (self._vocabularyBaseData[language].getCurvePromise !== undefined) {
            return self._vocabularyBaseData[language].getCurvePromise;
        } else {
            let getLearnedCurvePromise = Promise.resolve().then(function () {
                return assetLoader.loadAsset(
                    UserManager.instance.getUser().getCourse().getAssetUrl('vocabulary_curve'), 0);
            }).then(function (learnedCurve) {
                learnedCurve = JSON.parse(learnedCurve);
                self._vocabularyBaseData[language].curve = learnedCurve;
                delete self._vocabularyBaseData[language].getCurvePromise;
                return Promise.resolve(learnedCurve);
            }).catch(function(error) {
                Raven.captureException(new VocabularyDataLoadingFailedException(
                    `Language learned curve data loading failed for language="${language} error=${error.name}:${error.message}"`), {
                    level: 'warning',
                    fingerprint: ['{{ default }}', 'curve', language],
                    extra: {error: error}
                });
                self._vocabularyBaseData[language].curve = null;
                delete self._vocabularyBaseData[language].getCurvePromise;
            });
            self._vocabularyBaseData[language].getCurvePromise = getLearnedCurvePromise;
            return getLearnedCurvePromise;
        }
    }
};

ResultsModel.initialize();

export default ResultsModel;

