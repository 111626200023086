var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "email", class: { notVerified: !_vm.emailVerified } },
    [
      _c("h2", {
        domProps: {
          innerHTML: _vm._s(this.$i18n("account_profile_email_title")),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "field", class: { focused: this.emailInputFocused } },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M6 9L12 13L18 9",
                  stroke: this.emailVerified ? "#2C3143" : "#FF934D",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M4.5 19H4C2.89543 19 2 18.1046 2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V11V15.5V17C22 18.1046 21.1046 19 20 19H12H9.5",
                  stroke: this.emailVerified ? "#2C3143" : "#FF934D",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.emailLocal,
                expression: "emailLocal",
              },
            ],
            ref: "email",
            attrs: {
              type: "text",
              name: "email",
              placeholder: _vm.emailPlaceholder,
            },
            domProps: { value: _vm.emailLocal },
            on: {
              focus: function ($event) {
                _vm.emailInputFocused = true
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.emailLocal = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          !_vm.emailInputFocused
            ? _c("edit-icon", {
                nativeOn: {
                  click: function ($event) {
                    return _vm.focusInput.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-down-fade" } }, [
        !_vm.emailIsValid && _vm.passwordFieldVisible
          ? _c("div", {
              staticClass: "error email",
              domProps: {
                innerHTML: _vm._s(this.$i18n("settings_error_invalid_email")),
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-down-fade" } }, [
        _vm.passwordFieldVisible
          ? _c("div", { staticClass: "fieldWrapper" }, [
              _c(
                "div",
                {
                  staticClass: "field password",
                  class: { focused: this.passwordInputFocused },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "25",
                        viewBox: "0 0 24 25",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M12 9.5L19.0001 9.50006C20.1046 9.50006 21.0001 10.3955 21.0001 11.5001L21 19.5001C21 20.6046 20.1046 21.5001 19 21.5001H5C3.89543 21.5001 3 20.6046 3 19.5001L3.00002 11.5001C3.00002 10.3955 3.89545 9.50006 5.00002 9.50006H7V7.5C7 4.73858 9.23858 2.5 12 2.5C14.0503 2.5 15.8124 3.7341 16.584 5.5",
                          stroke: "#FAAA5A",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M14 17.5V15.5C14 14.3954 13.1046 13.5 12 13.5V13.5C10.8954 13.5 10 14.3954 10 15.5V17.5",
                          stroke: "#FAAA5A",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.passwordLocal,
                        expression: "passwordLocal",
                      },
                    ],
                    ref: "password",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: _vm.passwordPlaceholder,
                    },
                    domProps: { value: _vm.passwordLocal },
                    on: {
                      focus: function ($event) {
                        _vm.passwordInputFocused = true
                      },
                      blur: function ($event) {
                        _vm.passwordInputFocused = false
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.save.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.passwordLocal = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("reveal-icon", {
                    nativeOn: {
                      mousedown: function ($event) {
                        return _vm.revealPassword(true)
                      },
                      mouseup: function ($event) {
                        return _vm.revealPassword(false)
                      },
                      mouseleave: function ($event) {
                        return _vm.revealPassword(false)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("v-button", {
                    attrs: {
                      i18n: { id: "account_profile_cancel_btn" },
                      filled: false,
                      size: "medium",
                      color: "primary",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.cancel.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      i18n: { id: "account_profile_input_action_save" },
                      filled: true,
                      disabled: !_vm.contentIsValid,
                      size: "medium",
                      color: "primary",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-down-fade" } }, [
        !_vm.emailVerified
          ? _c(
              "div",
              { staticClass: "not-verified" },
              [
                _c("p", [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "account_profile_email_verification_message_1"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "account_profile_email_verification_message_2"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("v-button", {
                  attrs: {
                    i18n: {
                      id: "account_profile_email_verification_button_resend",
                    },
                    disabled: _vm.resendDisabled,
                    filled: true,
                    size: "medium",
                    color: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.reSend.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }