<template>
    <div class="variation-settings">
        <div class="action">
            <div class="action-flex">
                <div class="status">
                    <status-dot :disabled="!variationEnabled" key="status_dot_variation_enabled" />
                </div>
                <div :class="{ label: true, disabled: variationToggleDisabled }" v-html="this.$i18n('variation_page_enable_deck_toggle_label')" />
                <div class="toggle">
                    <toggle-button v-model="variationEnabled"
                                   key="variation_enabled_toggle"
                                   @change="toggleVariationEnabled"
                                   :disabled="variationToggleDisabled"
                                   :color="colors"
                                   :width="40"
                                   :height="24"
                                   :labels="false" />
                </div>
            </div>
            <div class="action-footnote" v-if="variationEnabled" v-html="this.$i18n('variation_page_manage_settings_disable_course_text')" />
            <div class="action-footnote" v-else v-html="this.$i18n('hub_variation_settings_footnote_variation_disabled')" />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { EventBus } from 'Util/vue-event-bus';
    import ToggleButton from 'vue-js-toggle-button';
    import StatusDot from './status-dot.vue';

    Vue.use(ToggleButton);

    export default {
        name: 'variation-settings',
        props: {
            variation: {
                type: Object
            },
            variationToggleDisabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            StatusDot,
        },
        data() {
            return {
                colors: {
                    checked: '#2C3143',
                    disabled: '#D8DBE0'
                },
                variationEnabled: this.variation.active
            };
        },
        methods: {
            toggleVariationEnabled({ value }) {
                if (value) {
                    EventBus.$emit('enable-variation', this.variation);
                } else {
                    EventBus.$emit('disable-variation', this.variation);
                }
            }
        },
        watch: {
            variation: function(variation) {
                this.variationEnabled = variation.active;
            }
        },
    };
</script>

<style lang="scss">
    @import "~Styles/mixins";
    @import "~Styles/colors";

    div.variation-settings {
        display: flex;
        flex-direction: column;
        > div.action {
            margin: pxToRem(8);
            user-select: none;
            > div.action-flex {
                display: flex;
                align-content: center;
                > div.status {
                    flex: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                > div.label {
                    flex: 1;
                    margin: 0 1rem;
                    font-size: fontSize(18);
                    padding-top: pxToRem(3);
                    font-weight: $font-bold;
                    &.disabled {
                        color: $colorSourceTertiary;
                    }
                }
                > div.toggle {
                    flex: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            > div.action-footnote {
                margin-top: pxToRem(8);
                font-size: fontSize(12);
                opacity: .6;
            }
        }
    }
</style>
