
'use strict';

export default class SingletonFactory {
    constructor (Class) {
        this.__Class = Class;
        this.__instance = null;
    }

    _getInstance () {
        if (this.__instance === null) {
            this.__instance = new (Function.prototype.bind.apply(this.__Class, arguments)); // jshint ignore:line
        }

        return this.__instance;
    }
}
