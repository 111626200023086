var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M11.466 1.327a.6.6 0 011.068 0l.766 1.5a.6.6 0 00.44.32l1.664.265a.6.6 0 01.33 1.017l-1.19 1.192a.6.6 0 00-.168.517l.262 1.664a.6.6 0 01-.865.628l-1.501-.764a.6.6 0 00-.544 0l-1.502.764a.6.6 0 01-.864-.628l.262-1.664a.6.6 0 00-.168-.517l-1.19-1.192a.6.6 0 01.33-1.017l1.664-.264a.6.6 0 00.44-.32l.766-1.5z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 15H4a1 1 0 00-1 1v1m6-2v-3a1 1 0 011-1h4a1 1 0 011 1v9m-6-6v6m6 0h6v-3a1 1 0 00-1-1h-1m-4 4H9m0 0H3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }