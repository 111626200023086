<template>
    <div class="survey" :data-type="this.type">
        <div class="floater" @click="openBox" v-if="!visible">
            <span class="tooltip">{{ hoverTooltip }}</span>
            <survey-impact-icon v-if="this.type === 'impact'" />
            <survey-icon v-else />
        </div>
        <div class="box" v-if="visible">
            <CloseButton @click="closeBox" class="close" />
            <transition name="fade">
                <div class="thank-you" v-if="thanks">
                    <happy-emoji />
                    <h3 v-html="this.$i18n('lessons_creator_survey_happiness_feedback_toast_confirm_text')" />
                </div>
            </transition>
            <template v-if="!thanks">
                <h3>{{ surveyTitle }}</h3>
                <template v-if="type === 'impact'">
                    <div class="scores" @mouseleave="clearHoverScore">
                        <div class="score" @click="setScore(1)" @mouseover="setHoverScore(1)" :class="{ unselected: this.score && score !== 1, selected: this.score && score === 1 }"><star-icon :filled="(this.score && score >= 1) || hoverScore >= 1" /></div>
                        <div class="score" @click="setScore(2)" @mouseover="setHoverScore(2)" :class="{ unselected: this.score && score !== 2, selected: this.score && score === 2 }"><star-icon :filled="(this.score && score > 1) || hoverScore > 1" /></div>
                        <div class="score" @click="setScore(3)" @mouseover="setHoverScore(3)" :class="{ unselected: this.score && score !== 3, selected: this.score && score === 3 }"><star-icon :filled="(this.score && score > 2) || hoverScore > 2" /></div>
                        <div class="score" @click="setScore(4)" @mouseover="setHoverScore(4)" :class="{ unselected: this.score && score !== 4, selected: this.score && score === 4 }"><star-icon :filled="(this.score && score > 3) || hoverScore > 3" /></div>
                        <div class="score" @click="setScore(5)" @mouseover="setHoverScore(5)" :class="{ unselected: this.score && score !== 5, selected: this.score && score === 5 }"><star-icon :filled="(this.score && score === 5) || hoverScore === 5" /></div>
                    </div>
                    <div class="labels">
                        <span class="minimum" v-html="this.$i18n('survey_impact_rate_minimum_label')" />
                        <span class="maximum" v-html="this.$i18n('survey_impact_rate_maximum_label')" />
                    </div>
                </template>
                <div class="scores" v-else>
                    <div class="score" @click="setScore(1)" :class="{ unselected: this.score && score !== 1, selected: this.score && score === 1 }" :title="ratingTooltip(1)"><mad-emoji /></div>
                    <div class="score" @click="setScore(2)" :class="{ unselected: this.score && score !== 2, selected: this.score && score === 2 }" :title="ratingTooltip(2)"><un-happy-emoji /></div>
                    <div class="score" @click="setScore(3)" :class="{ unselected: this.score && score !== 3, selected: this.score && score === 3 }" :title="ratingTooltip(3)"><neutral-emoji /></div>
                    <div class="score" @click="setScore(4)" :class="{ unselected: this.score && score !== 4, selected: this.score && score === 4 }" :title="ratingTooltip(4)"><happy-emoji /></div>
                    <div class="score" @click="setScore(5)" :class="{ unselected: this.score && score !== 5, selected: this.score && score === 5 }" :title="ratingTooltip(5)"><awesome-emoji /></div>
                </div>
                <div class="more" v-if="score">
                    <h4 v-html="this.$i18n('lessons_creator_survey_happiness_feedback_form_title')" />
                    <textarea :placeholder="this.$i18n('lessons_creator_survey_happiness_feedback_form_placeholder')" v-model="message" maxlength="500" />
                    <v-button :i18n="{ id: 'lessons_creator_survey_happiness_button_send' }" @click.native="sendSurvey" :filled="true" color="primary" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import VButton from "ViewComponent/v-button.vue";
    import { EventBus } from 'Util/vue-event-bus';
    import StarIcon from 'ViewComponent/star-icon.vue';
    import SurveyIcon from 'Images/survey-24.svg';
    import SurveyImpactIcon from 'Images/survey-impact-24x24.svg';
    import CloseButton from 'Images/close-24x24.svg';
    import MadEmoji from 'Images/emojis/mad-32.svg';
    import UnHappyEmoji from 'Images/emojis/unhappy-32.svg';
    import NeutralEmoji from 'Images/emojis/neutral-32.svg';
    import HappyEmoji from 'Images/emojis/happy-32.svg';
    import AwesomeEmoji from 'Images/emojis/awesome-32.svg';
    import UserManager from "Modules/usermanager";
    import i18nUtils from "Util/i18n";

    const surveyTypes = ['happiness', 'impact'];

    export default {
        name: 'survey',
        components: {
            VButton,
            SurveyIcon,
            SurveyImpactIcon,
            CloseButton,
            MadEmoji,
            UnHappyEmoji,
            NeutralEmoji,
            HappyEmoji,
            AwesomeEmoji,
            StarIcon
        },
        props: {
            surveySource: {
                type: String,
                default: 'guess'
            },
            lesson_uuid: {
                type: String,
            },
            course_uuid: {
                type: String,
                required: true
            },
            typeInitial: {
                type: String,
                default: 'happiness'
            },
        },
        data() {
            return {
                type: this.typeInitial,
                visible: false,
                hoverScore: 0,
                score: null,
                message: null,
                skipped_once: false,
                sent: false,
                thanks: false,
                thanksTimeout: null,
                hoverTimeout: null
            }
        },
        computed: {
            hoverTooltip() {
                if (this.type === 'happiness') {
                    switch (this.surveySource) {
                        case 'course-wizard':
                            return i18nUtils.prop('lessons_creator_survey_happiness_button_tooltip');
                        default:
                            return i18nUtils.prop('survey_feedback_learn_with_lingvist_question');
                    }
                } else if (this.type === 'impact') {
                    return i18nUtils.prop('survey_feedback_learn_button_label');
                } else {
                    return i18nUtils.prop('survey_feedback_learn_with_lingvist_question');
                }
            },
            surveyTitle() {
                if (this.type === 'happiness') {
                    switch (this.surveySource) {
                        case 'course-wizard':
                            return i18nUtils.prop('lessons_creator_survey_happiness_question_title_question');
                        default:
                            return i18nUtils.prop('survey_feedback_learn_with_lingvist_question');
                    }
                } else if (this.type === 'impact') {
                    return i18nUtils.prop('survey_impact_question_text');
                } else {
                    return i18nUtils.prop('survey_feedback_learn_with_lingvist_question');
                }
            }
        },
        methods: {
            setScore(score) {
                this.score = score;
            },
            setHoverScore(score) {
                this.hoverScore = score;
            },
            clearHoverScore() {
                this.hoverTimeout = setTimeout(() =>{
                    this.hoverScore = 0;
                    clearTimeout(this.hoverTimeout);
                }, 300);
            },
            rate() {
                EventBus.$emit('cw:rate', this.score);
            },
            reset() {
                this.score = null;
                this.message = null;
                this.sent = false;
                this.thanks = false;
                this.type = 'happiness'
            },
            openBox() {
                document.body.classList.add('modal-open');
                this.visible = true;
            },
            closeBox() {
                if (!this.sent) {
                    if (!this.skipped_once) {
                        this.sendSkippedSurvey();
                    }
                    this.visible = false;
                } else {
                    if (this.thanks) {
                        this.visible = false;
                    } else {
                        this.thanks = true;
                        this.thanksTimeout = setTimeout(() => {
                            this.visible = false;
                        }, 2000);
                    }
                }
                document.body.classList.remove('modal-open');
            },
            ratingTooltip(rating) {
                switch (rating) {
                    case 1:
                        return i18nUtils.prop('lessons_creator_survey_happiness_tooltip_1_awful');
                    case 2:
                        return i18nUtils.prop('lessons_creator_survey_happiness_tooltip_2_bad');
                    case 3:
                        return i18nUtils.prop('lessons_creator_survey_happiness_tooltip_3_neutral');
                    case 4:
                        return i18nUtils.prop('lessons_creator_survey_happiness_tooltip_4_good');
                    case 5:
                        return i18nUtils.prop('lessons_creator_survey_happiness_tooltip_5_awesome');
                }
            },
            listenEventBus() {
                EventBus.$on('survey:open', (type) => {
                    if (type && surveyTypes.includes(type)) {
                        this.type = type;
                    }
                    if (!this.sent) {
                        this.openBox();
                    }
                });
            },
            sendSurvey() {
                this.sent = true;
                if (this.surveySource === 'course-wizard') {
                    UserManager.instance.getUser().getEventSender().sendCourseWizardSurveyEvent(
                        'submit',
                        this.course_uuid,
                        this.lesson_uuid,
                        this.score,
                        this.message
                    )
                } else if (this.surveySource === 'guess') {
                    const source = (this.type === 'impact') ? 'guess_impact' : 'guess';
                    UserManager.instance.getUser().getEventSender().sendGuessSurveyEvent(
                        'submit',
                        this.course_uuid,
                        source,
                        this.score,
                        this.message
                    )
                }
                this.closeBox();
            },
            sendSkippedSurvey() {
                if (!this.skipped_once && !this.sent && this.visible) {
                    if (this.surveySource === 'course-wizard') {
                        UserManager.instance.getUser().getEventSender().sendCourseWizardSurveyEvent(
                            'skip',
                            this.course_uuid,
                            this.lesson_uuid
                        )
                    } else if (this.surveySource === 'guess') {
                        const source = (this.type === 'impact') ? 'guess_impact' : 'guess';
                        UserManager.instance.getUser().getEventSender().sendGuessSurveyEvent(
                            'skip',
                            this.course_uuid,
                            source
                        )
                    }
                    this.skipped_once = true;
                }
            }
        },
        created () {
            this.listenEventBus();
        },
        beforeDestroy() {
            this.sendSkippedSurvey();
            EventBus.$off('survey:open', this.openBox);
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.survey {
        position: absolute;
        z-index: 200;
        bottom: 0;
        right: 0;
        body[data-interface-language="ar"] & {
            right: unset;
            left: 0;
        }
        > div.floater {
            position: relative;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: pxToRem(40);
            height: pxToRem(40);
            background-color: $colorSourcePrimary;
            border-radius: 50%;
            padding: .5rem;
            transition: 100ms ease-in-out;
            > span.tooltip {
                display: none;
                font-size: fontSize(12);
                min-width: pxToRem(230);
                position: absolute;
                bottom: pxToRem(45);
                right: 0;
                border-radius: pxToRem(8);
                background-color: $colorBackgroundDarkLighter;
                padding: pxToRem(8);
                text-align: center;
                color: #fff;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 0;
                }
            }
            &:hover {
                cursor: pointer;
                transform: scale(1.2);
                > span.tooltip {
                    display: block;
                }
            }
            &:active {
                > span.tooltip {
                    display: none;
                }
            }
        }
        > div.box {
            background-color: #fff;
            padding: 1.5rem;
            @include elevated;
            border-radius: .75rem;
            max-width: pxToRem(340);
            > svg.close {
                cursor: pointer;
                position: absolute;
                right: 1.5rem;
                top: 1.5rem;
                transition: 100ms ease-in-out;
                z-index: 1100;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 1.5rem;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
            > h3 {
                margin-right: 2rem;
                body[data-interface-language="ar"] & {
                    margin-right: unset;
                    margin-left: 2rem;
                }
            }
            > div.scores {
                margin-top: .5rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-content: space-between;
                > div.score {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: pxToRem(52);
                    height: pxToRem(52);
                    padding: pxToRem(10);
                    border-radius: 50%;
                    margin: .5rem 0;
                    transition: background-color 250ms linear;
                    &:hover {
                        cursor: pointer;
                        background-color: $colorBackgroundGraySecondary;
                    }
                    &.selected {
                        background-color: $colorBackgroundGraySecondary;
                    }
                    &.unselected {
                        > svg {
                            filter: grayscale(100%);
                        }
                    }
                }
            }
            > div.labels {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: fontSize(12);
                color: $colorSourceSecondary;
                > span.maximum {
                    text-align: right;
                }
            }
            > div.more {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
                > textarea {
                    margin: .5rem 0 1rem;
                    width: 100%;
                    background: $colorInputBox;
                    color: $colorSourcePrimary;
                    padding: 1rem;

                    &:focus {
                        background: $colorInputBoxFocus;
                    }
                }
                > button {
                    justify-self: end;
                }
            }
            > div.thank-you {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                > svg {
                    transform: scale(2);
                    margin: 2rem;
                }
                > h3 {
                    margin: 0 1rem;
                }
            }
        }

        .fade-enter-active {
            transition: opacity 500ms;
        }
        .fade-enter, .fade-leave, .fade-leave-to {
            opacity: 0;
        }

        .bounce-enter-active {
            animation: bounce-in .5s;
        }

        .bounce-leave {
            opacity: 0;
        }

        @keyframes bounce-in {
            0% {
                transform: scale(0);
            }
            50% {
                transform: scale(1.5);
            }
            100% {
                transform: scale(1);
            }
        }

    }
</style>
