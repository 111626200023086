
'use strict';

import _ from 'lodash';

import moment from 'moment';

import { QuestionList } from './question.list.js';


const TARGET_PROBABILITY = 0.9;
const REPEAT_LIST_COMPARISON_INTERVAL = 1000;
const REPEAT_LIST_SORTING_PARAMETERS = {
    targetProbability: TARGET_PROBABILITY,
    comparisonTimeInterval: REPEAT_LIST_COMPARISON_INTERVAL
};

export class RepeatList extends QuestionList {

    constructor (course, assetLoader, Modules) {
        super(course, REPEAT_LIST_SORTING_PARAMETERS, assetLoader, Modules);
        this.__name = 'RepeatList';

        this.ASSET_LOADING_PRIORITY = 10;
    }

    _questionFilter (questionData, parameters) {
        return !this._Modules.Question.isDebug(questionData) &&
               !this._Modules.Question.isFastTracking(questionData) &&
               !this._Modules.Question.isNew(questionData) &&
               (parameters.maxPredictedTs === undefined ||
                moment.utc(questionData.predicted_ts) < parameters.maxPredictedTs);
    }

    getRepeatsWaitingCount () {
        return _.values(this._questionMap).filter(question => question.needsRepeat()).length;
    }
}
