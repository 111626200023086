<template>
    <div class="ft-modal" v-if="isOpen">
        <div class="ft-modal-window">
            <div class="ft-animation">
                <lottie-animation
                    :animationData="this.ftAnim"
                    :loop="true"
                />
            </div>
            <div class="content">
                <transition name="fade">
                    <section v-if="page === 'intro'">
                        <h2 v-html="this.$i18n('guess_game_onboarding_fasttracking_ask_grn3160_title', { args: { tl: this.target_language } })" />
                        <div class="text" v-html="this.$i18n('guess_game_onboarding_fasttracking_ask_grn3160_text')" />
                        <div class="actions">
                            <v-button :i18n="{ id: 'guess_game_onboarding_fasttracking_ask_grn3160_btn_no' }" @click.native="navigateToPage('beginning')" :filled="false" color="primary" />
                            <v-button :i18n="{ id: 'guess_game_onboarding_fasttracking_ask_grn3160_btn_yes' }" @click.native="navigateToPage('placement-test')" @mousedown.native="handleMouseDown" @mouseup.native="handleMouseUpLeave" @mouseleave.native="handleMouseUpLeave" :filled="true" color="primary" :class="{ brain: this.thinkingBrain }" />
                        </div>
                    </section>
                </transition>
                <transition name="fade">
                    <section v-if="page === 'placement-test'">
                        <h2 v-html="this.$i18n('guess_game_onboarding_fasttracking_test_grn3160_title')" />
                        <div class="text" v-html="this.$i18n('guess_game_onboarding_fasttracking_test_grn3160_text')" />
                        <div class="actions">
                            <v-button :i18n="{ id: 'guess_game_onboarding_fasttracking_details_grn3160_btn_back' }" @click.native="navigateToPage('intro')" :filled="false" color="primary" />
                            <v-button :i18n="{ id: 'guess_game_onboarding_fasttracking_details_grn3160_btn_continue' }" @click.native="startFastTracking" @mousedown.native="handleMouseDown" @mouseup.native="handleMouseUpLeave" @mouseleave.native="handleMouseUpLeave" :filled="true" color="primary" :class="{ brain: this.thinkingBrain }" />
                        </div>
                    </section>
                </transition>
                <transition name="fade">
                    <section v-if="page === 'beginning'">
                        <h2 v-html="this.$i18n('guess_game_onboarding_fasttracking_beginner_grn3160_title')" />
                        <div class="text" v-html="this.$i18n('guess_game_onboarding_fasttracking_beginner_grn3160_text', { args: { general_variation_units: this.words_count } })" />
                        <div class="actions">
                            <v-button :i18n="{ id: 'guess_game_onboarding_fasttracking_details_grn3160_btn_back' }" @click.native="navigateToPage('intro')" :filled="false" color="primary" />
                            <v-button :i18n="{ id: 'guess_game_onboarding_fasttracking_details_grn3160_btn_continue' }" @click.native="startBeginning" :filled="true" color="primary" />
                        </div>
                    </section>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import UserManager from "Modules/usermanager";
    import { EventBus } from "Util/vue-event-bus";
    import LottieAnimation from 'lottie-web-vue'
    import Vue from "vue";
    import FtAnimationJson from "Styles/animations/ft_modal_anim_light";
    import VButton from "ViewComponent/v-button.vue";

    Vue.use(LottieAnimation);

    export default {
        name: 'ft-modal',
        components: {
            VButton,
            LottieAnimation
        },
        props: {
            course: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                ftAnim: FtAnimationJson,
                isOpen: false,
                pages: ['intro', 'placement-test', 'beginning'],
                page: 'intro',
                thinkingBrain: false,
                thinkingBrainTimer: null,
                userChoice: null
            }
        },
        computed: {
            target_language() {
                return (this.course && this.course.info) ? this.course.info.target_language : null;
            },
            words_count() {
                return (this.course && this.course.info) ? this.course.info.words : null;
            }
        },
        methods: {
            async open(debug = false) {
                // only show when user haven't made a choice before
                await this.getAndSetUserChoiceFromStorage();
                if (!this.userChoice || debug) {
                    document.body.classList.add('modal-open');
                    this.isOpen = true;
                }
            },
            close() {
                this.isOpen = false;
                document.body.classList.remove('modal-open');
            },
            navigateToPage(page) {
                if (this.pages.includes(page)) {
                    this.page = page;
                }
            },
            saveUserChoiceToStorage(choice) {
                this.userChoice = choice;
                const _key = `user_level_assessment_choice_${this.course.UUID}`;
                UserManager.instance.getUser().getStorage().setItem(_key, choice);
            },
            async getAndSetUserChoiceFromStorage() {
                const _key = `user_level_assessment_choice_${this.course.UUID}`;
                this.userChoice = await UserManager.instance.getUser().getStorage().getItem(_key);
            },
            async startBeginning() {
                const _choice = 'skip';
                await UserManager.instance.getUser().getEventSender().sendUserLevelAssessmentChoice(this.course.UUID, _choice);
                this.saveUserChoiceToStorage(_choice);
                this.close();
            },
            async startFastTracking() {
                const _choice = 'fast_track';
                await UserManager.instance.getUser().getEventSender().sendUserLevelAssessmentChoice(this.course.UUID, _choice);
                this.saveUserChoiceToStorage(_choice);
                this.close();
            },
            handleMouseDown() {
                this.thinkingBrainTimer = setTimeout(() => {
                    this.thinkingBrain = true;
                }, 2000);
            },
            handleMouseUpLeave() {
                clearTimeout(this.thinkingBrainTimer);
                this.thinkingBrain = false;
            },
            listenEventBus() {
                EventBus.$on('guess:ft-modal-open', this.open);
                EventBus.$on('guess:ft-modal-close', this.close);
            },
            unListenEventBus() {
                EventBus.$off('guess:ft-modal-open', this.open);
                EventBus.$off('guess:ft-modal-close', this.close);
            }
        },
        created() {
            this.listenEventBus();
        },
        mounted() {
            this.getAndSetUserChoiceFromStorage();
            document.addEventListener('keydown', this.keyDown);
        },
        beforeDestroy() {
            this.unListenEventBus();
            clearTimeout(this.thinkingBrainTimer);
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.ft-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255,255,255,0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        > div.ft-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(300);
            display: flex;
            flex-direction: column;
            max-width: pxToRem(560);
            box-sizing: border-box;
            padding: pxToRem(24);
            overflow: hidden;

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > div.ft-animation {
                max-width: pxToRem(273);
                margin: 1rem auto;
            }

            > div.content {
                > section {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    > h2, > div.text {
                        text-align: center;
                        > em {
                            font-weight: $font-bolder;
                        }
                    }
                    > div.actions {
                        padding: 1.5rem 0 .5rem;
                        margin-top: auto;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        gap: 1rem;
                        > button {
                            flex: 1;
                            &.brain {
                                cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🧠</text></svg>") 16 0,auto;
                            }
                        }
                    }
                }
                .fade-enter-active, .fade-leave-active {
                    transition: all 350ms ease;
                }
                .fade-leave-active {
                    position: absolute;
                    top: 0;
                }
                .fade-enter, .fade-leave-to {
                    transform: translateX(100px);
                    opacity: 0;
                }
            }
        }
    }
</style>
