var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M30.735 25.665c0 6.257-5.072 11.33-11.33 11.33-4.588 0-8.54-2.73-10.32-6.652-.364-.8.53-1.35 1.046-.8.955 1.02 2.192 1.787 3.61 1.787a5.665 5.665 0 005.665-5.665 5.665 5.665 0 0111.33 0z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 10h12M15 10V6M13 26v6h15M27 7v18M32 16h-5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "15",
          cy: "17.5",
          rx: "6",
          ry: "3.5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }