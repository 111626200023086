var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cards-toggle-menu" }, [
    _c("div", { staticClass: "action" }, [
      _c("div", { staticClass: "action-flex" }, [
        _c(
          "div",
          { staticClass: "status" },
          [
            _c("card-status-icon", {
              attrs: { status: "incomplete", known: false },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _c("span", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(
                  "lessons_creator_editor_toggle_label_incomplete_cards"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "counter" }, [
            _vm._v(_vm._s(_vm.incompleteCardsCount)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "toggle" },
          [
            _c("toggle-button", {
              key: "include_incomplete_toggle",
              attrs: {
                color: _vm.colors,
                width: 40,
                height: 24,
                labels: false,
              },
              model: {
                value: _vm.include_incomplete,
                callback: function ($$v) {
                  _vm.include_incomplete = $$v
                },
                expression: "include_incomplete",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "action-footnote",
        domProps: {
          innerHTML: _vm._s(
            this.$i18n(
              "lessons_creator_editor_toggle_description_incomplete_cards"
            )
          ),
        },
      }),
    ]),
    _vm._v(" "),
    _vm.known_toggle_visible
      ? _c("div", { staticClass: "action" }, [
          _c("div", { staticClass: "action-flex" }, [
            _c(
              "div",
              { staticClass: "status" },
              [
                _c("card-status-icon", {
                  attrs: { status: "known", known: true },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "label" }, [
              _c("span", {
                staticClass: "text",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n(
                      "lessons_creator_editor_toggle_label_cards_already_learned"
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "counter" }, [
                _vm._v(_vm._s(_vm.knownCardsCount)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "toggle" },
              [
                _c("toggle-button", {
                  key: "include_known_toggle",
                  attrs: {
                    color: _vm.colors,
                    width: 40,
                    height: 24,
                    labels: false,
                  },
                  model: {
                    value: _vm.include_known,
                    callback: function ($$v) {
                      _vm.include_known = $$v
                    },
                    expression: "include_known",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "action-footnote",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(
                  "lessons_creator_editor_toast_cards_already_learned_toggle_on"
                )
              ),
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }