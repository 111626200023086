import AsyncDestroyable from '../async.destroyable.js';
import _ from "lodash";
import { EventBus } from '../../util/vue-event-bus.js';

export class UserLessons extends AsyncDestroyable {

    constructor (user) {
        super([]);
        this._user = user;
    }

    /**
     * Get list of all lessons.
     * @returns array of lesson objects
     */

    getLessons () {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.get())
            .then( response => {
                return Promise.resolve(response.lessons);
            });
    }

    createLesson ({ name, course_uuid, temporary }) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.post({ name, course_uuid, temporary }))
            .then( response => {
                return Promise.resolve(response);
            });
    }

    getLessonByUuid (uuid) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(uuid).get())
            .then( response => {
                return Promise.resolve(response);
            });
    }

    updateLessonByUuid (uuid, lesson) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(uuid).post(lesson))
            .then( updatedLesson => {
                return Promise.resolve(updatedLesson);
            });
    }

    getLessonCardsByUuid (uuid) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(uuid).cards.get())
            .then( response => {
                return Promise.resolve(response.cards);
            });
    }

    addCardsToLesson (uuid, seed_text = null, seed_file = null, intent = 'automatic', word_count = null, include_known = true) {
        if (!seed_text && !seed_file) {
            console.log('addCardsToLesson failed: no seed_text or seed_file defined');
            return false;
        } else {
            return Promise.resolve()
                .then( () => this._user.getApiClient().r.lessons.lesson_uuid(uuid).add_cards.post({
                    manual: (intent === 'vocabulary'),
                    seed_text,
                    seed_file,
                    intent,
                    include_known,
                    ... (word_count && { word_count })
                }).then( response => {
                    return Promise.resolve(response);
                }));
        }
    }

    getContexts (lessonUuid, wordId) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons
                .lesson_uuid(lessonUuid).words.word_id(wordId).fetch_sentences.get())
            .then( response => {
                return Promise.resolve(response.words);
            });
    }

    publish (lessonUuid) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(lessonUuid).publish.post())
            .then( response => {
                return Promise.resolve(response);
            });
    }

    persist (lessonUuid) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(lessonUuid).persist.post())
            .then( response => {
                return Promise.resolve(response);
            });
    }

    delete (lessonUuid) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(lessonUuid).delete())
            .then( response => {
                if (response.code === 'success') {
                    return Promise.resolve();
                } else {
                    return Promise.reject();
                }
            });
    }

    pushWord (lessonUuid, wordId, changedWord) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons
                .lesson_uuid(lessonUuid)
                .words.word_id(wordId)
                .post(changedWord)
            )
            .then( changedWord => {
                return Promise.resolve(changedWord);
            });
    }

    pushCard (lessonUuid, cardId, changedCard) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons
                .lesson_uuid(lessonUuid)
                .cards.card_id(cardId)
                .post(changedCard)
            )
            .then( changedCard => {
                return Promise.resolve(changedCard);
            });
    }

    deleteWord (lessonUuid, wordId) {
        return Promise.resolve()
            .then( () => {
                return this._user.getApiClient().r.lessons
                    .lesson_uuid(lessonUuid)
                    .words.word_id(wordId)
                    .delete();
            })
            .then( ({ code }) => {
                if (code === 'success') {
                    return Promise.resolve();
                } else {
                    return Promise.reject(code);
                }
            })
            .catch( ({ response }) => {
                return Promise.reject(response.body.code);
            });
    }

    deleteWords (lessonUuid, wordIds) {
        return Promise.resolve()
            .then( () => {
                let _payload = { word_ids: Array.from(wordIds) };
                return this._user.getApiClient().r.lessons
                    .lesson_uuid(lessonUuid)
                    .delete_words
                    .post(_payload);
            })
            .then( ({ code }) => {
                if (code === 'success') {
                    return Promise.resolve();
                } else {
                    return Promise.reject(code);
                }
            })
            .catch( ({ response }) => {
                return Promise.reject(response.body.code);
            });
    }

    deleteAllWords (lessonUuid) {
        return Promise.resolve()
            .then( () => {
                return this._user.getApiClient().r.lessons
                    .lesson_uuid(lessonUuid)
                    .words
                    .delete();
            })
            .then( ({ code }) => {
                if (code === 'success') {
                    return Promise.resolve();
                }
            });
    }

    getLessonPublishingStatusByUuid (uuid, waitForInSec) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(uuid).publishing_status.get({ wait: waitForInSec }))
            .then( response => {
                return Promise.resolve(response);
            });
    }

    createVariationExtension (course_uuid, variation_uuid, waitForInSec) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.create_variation_extension.post({
                course_uuid,
                variation_uuid,
                wait: waitForInSec
            }))
            .then( response => {
                return Promise.resolve(response);
            });
    }

    resizeLesson (uuid, size) {
        return Promise.resolve()
            .then( () => this._user.getApiClient().r.lessons.lesson_uuid(uuid).resize.post({
                'new_size': size
            }))
            .then( response => {
                return Promise.resolve(response);
            });
    }

    async createFileGetFileUuid (uuid, file, content_type, file_size, image_width, image_height) {
        EventBus.$emit('cw:ocr-status', 'uploading');
        let filesPostResponse = await this._user.getApiClient().r.lessons.files.post({
            content_type,
            file_size: parseInt(file_size),
            image_width: (image_width) ? parseInt(image_width) : null,
            image_height: (image_height) ? parseInt(image_height) : null
        });

        let { upload_info } = filesPostResponse;

        if (filesPostResponse && upload_info && filesPostResponse.hasOwnProperty('file_uuid') && upload_info.hasOwnProperty('fields') && upload_info.hasOwnProperty('url')) {
            EventBus.$emit('cw:ocr-status', 'upload-complete');
            const { fields, url } = upload_info;
            const { file_uuid } = filesPostResponse;

            let formData = new FormData();

            _(fields).forEach(function (value, name) {
                formData.append(name, value);
            });

            formData.append('file', file);

            let s3Request = await fetch(url, {
                method: 'POST',
                body: formData
            });

            if (s3Request.ok) {
                let ocrStatusAttempts = 0;
                let _completed = false;
                const _getOctStatus = async () => {
                    ocrStatusAttempts++;
                    let getOcrStatus =  await this._user.getApiClient().r.lessons.files.file_uuid(file_uuid).ocr_status.get({ wait: 45 });
                    if (getOcrStatus && getOcrStatus.hasOwnProperty('ocr_status')) {
                        switch (getOcrStatus.ocr_status) {
                            case 'completed':
                                if (!_completed) {
                                    EventBus.$emit('cw:ocr-status', 'completed');
                                    _completed = true;
                                    return { file_uuid };
                                }
                                break;
                            case 'in_progress':
                                // retry
                                EventBus.$emit('cw:ocr-status', 'still-processing');
                                return (ocrStatusAttempts < 2) ? await _getOctStatus() : false;
                            default:
                                return false;
                        }
                    } else {
                        return false;
                    }
                };
                EventBus.$emit('cw:ocr-status', 'processing');
                return await _getOctStatus();
            } else {
                EventBus.$emit('cw:ocr-status', 'failed');
                return false;
            }
        }
    }

    /*
        async createFileGetFileText (uuid, file, content_type, file_size, image_width, image_height) {
        let filesPostResponse = await this._user.getApiClient().r.lessons.lesson_uuid(uuid).files.post({
            content_type,
            file_size: parseInt(file_size),
            image_width: parseInt(image_width),
            image_height: parseInt(image_height)
        });

        if (filesPostResponse && filesPostResponse.hasOwnProperty('file_uuid') && filesPostResponse.hasOwnProperty('fields') && filesPostResponse.hasOwnProperty('url')) {
            const { file_uuid, fields, url } = filesPostResponse;

            let formData = new FormData();

            _(fields).forEach(function (value, name) {
                formData.append(name, value);
            });

            formData.append('file', file);

            let s3Request = await fetch(url, {
                method: 'POST',
                body: formData
            });

            if (s3Request.ok) {
                let getTextResponse = await this._user.getApiClient().r.lessons.lesson_uuid(uuid).files.file_uuid(file_uuid).text.get();
                if (getTextResponse && getTextResponse.text) {
                    return getTextResponse.text;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
     */
}
