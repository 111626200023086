var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "20",
          height: "20",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      _vm.$listeners
    ),
    [
      _c("defs", [
        _c("path", {
          attrs: {
            d: "M5.333 16a5.333 5.333 0 110-10.667 5.333 5.333 0 010 10.667zm.762-4.785a1.51 1.51 0 00.762-1.31c0-.843-.68-1.524-1.524-1.524-.833 0-1.523.68-1.523 1.524 0 .559.304 1.046.761 1.3v1.747a.76.76 0 101.524 0v-1.737z",
            id: "status-locked_svg__a",
          },
        }),
      ]),
      _c(
        "g",
        {
          attrs: {
            transform: "translate(4.5 2)",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2.625 6.814c.89-.512 1.794-.772 2.708-.772.914 0 1.818.26 2.709.772v-3.48a2.708 2.708 0 00-5.417 0v3.48z",
              stroke: "#B2B8CB",
              "stroke-width": "1.25",
            },
          }),
          _c("use", {
            attrs: { fill: "#B2B8CB", "xlink:href": "#status-locked_svg__a" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }