var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "course-list-item",
      class: { active: _vm.course && _vm.course.action === "learning" },
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _vm.course
        ? [
            _c("course-icon", {
              attrs: {
                value: this.showSource
                  ? _vm.course.source_icon_id
                  : _vm.course.target_icon_id,
                size: "large",
              },
              on: { click: _vm.selectCourse },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "info", on: { click: _vm.selectCourse } },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.course.name)),
                ]),
                _vm._v(" "),
                _vm.showSubtitle
                  ? _c("div", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.course.name_subtitle)),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.showMeta || _vm.showMoreDropdown
              ? _c("div", { staticClass: "right" }, [
                  _c(
                    "div",
                    { staticClass: "meta" },
                    [
                      _vm.showMoreDropdownLocal
                        ? _c("course-list-item-more-dropdown", {
                            attrs: { course: this.course },
                          })
                        : [
                            _vm.course.action !== "learning" &&
                            _vm.course.action !== "unselected" &&
                            _vm.isNewCourse
                              ? _c("span", {
                                  staticClass: "new",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      this.$i18n("course_selection_beta")
                                    ),
                                  },
                                  on: { click: _vm.selectCourse },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.course.action === "learning"
                              ? _c("selected-icon", {
                                  class: { hover: this.hover },
                                  on: { click: _vm.selectCourse },
                                })
                              : _vm.course.action === "unselected"
                              ? _c("tick-icon", {
                                  on: { click: _vm.selectCourse },
                                })
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]
        : _vm.showNotListed
        ? [
            _c("course-icon", { attrs: { value: "unknown", size: "large" } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "info",
                on: {
                  click: function ($event) {
                    return _vm.$emit("language-not-listed")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "title",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("register_select_course_language_name_unknown")
                    ),
                  },
                }),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }