<template>
    <div class="loader" :class="{ 'centered': this.centered }">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</template>

<script>
    export default {
        name: 'loader',
        props: {
            centered: {
                type: Boolean,
                default: false
            },
        },
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/components/loader";
    div.loader.centered {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -2rem;
        margin-top: -1rem;
    }
</style>
