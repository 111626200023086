
'use strict';

import moment from 'moment';


export class QuestionListMinimalPredictedIntervalSorter {
    constructor (parameters) {

    }

    sort (questions) {
        var currentTime = moment.utc();

        return questions.sort(function (question1, question2) {
            var q1_in_repeat_stack = question1.getParameter('predicted_ts') < currentTime,
                q2_in_repeat_stack = question2.getParameter('predicted_ts') < currentTime;

            if (q1_in_repeat_stack && q2_in_repeat_stack) {
                // both in repeat stack: sort in predicted_interval order (smaller predicted_interval first)
                return question1.getParameter('predicted_interval') - question2.getParameter('predicted_interval');
            } else if (q1_in_repeat_stack) {
                // only question1 in repeat stack: question1 first
                return -1;
            } else if (q2_in_repeat_stack) {
                // only question2 in repeat stack: question2 first
                return +1;
            }
            else {
                // neither in repeat stack: sort in predicted_ts order (smaller predicted_ts first)
                return question1.getParameter('predicted_ts') - question2.getParameter('predicted_ts');
            }
        });
    }
}

export class NewUnitSNSorter {
    constructor (parameters) {

    }

    sort (questions) {
        return questions.sort(function (question1, question2) {
            return question1.getParameter('new_unit_sn') - question2.getParameter('new_unit_sn');
        });
    }
}

export class FastTrackingListSorter {
    constructor (parameters) {

    }

    sort (questions) {
        return questions.sort(function (question1, question2) {
            return question1.getParameter('new_unit_sn') - question2.getParameter('new_unit_sn');
        });
    }
}

export class DebugQuestionListSorter {
    constructor (parameters) {

    }

    sort (questions) {
        return questions;
    }
}
