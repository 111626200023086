var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M6.246 19.375C6.084 20.225 6 21.103 6 22c0 7.732 6.268 14 14 14s14-6.268 14-14S27.732 8 20 8a13.96 13.96 0 00-10.167 4.375",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "20",
          cy: "3",
          r: "2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.217 19.917A10.041 10.041 0 0010 22c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10a9.987 9.987 0 00-8.123 4.167M20 30v2M12.5 22H11M26.5 27.5l1 1M13.5 27.5l-1 1M20 14v-2M28 22h2M13.5 16.5l-1-1M26.5 16.5l1-1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.585 18.927a3.133 3.133 0 011.645-.872l4.964-.908-.908 4.964a3.132 3.132 0 01-.872 1.645l-4.829-4.829z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.695 23.036a2.754 2.754 0 01-1.277.735l-3.881.692 1.034-3.54c.117-.478.38-.923.734-1.276",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }