<template>
    <div class="card-grammar" :class="typeClass" v-if="parsedGrammar">
        <grammar-icon v-if="parsedGrammar.icon" :value="parsedGrammar.icon.icon" />
        <div class="txt" v-if="parsedGrammar.icon && parsedGrammar.icon.description">
            {{ parsedGrammar.icon.description }}
        </div>
        <div class="txt" v-else-if="typesString">
            {{ typesString }}
        </div>
    </div>
</template>

<script>

import GrammarIcon from "ViewComponent/grammar-icon.vue";

export default {
    name: 'card-grammar',
    components: { GrammarIcon },
    props: {
        parsedGrammar: {
            type: Object,
            required: true
        },
        full: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        typeClass() {
            if (this.parsedGrammar.icon && this.parsedGrammar.icon.icon) {
                const typeMap = [{ name: 'masculine' }, { name: 'neutral' }, { name: 'feminine' }];
                const iconName = this.parsedGrammar.icon.icon;
                let _typeClass;
                typeMap.forEach(item => {
                    if (iconName.includes(item.name)) {
                        _typeClass = item.name;
                    }
                });

                return _typeClass;
            }
        },
        typesString() {
            let _types = [];

            this.parsedGrammar.fragments.map(fragment => {
                _types.push(this.full ? fragment.full : fragment.short);
            });

            return _types.join(', ');
        }
    },
}
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.card-grammar {
        background-color: $colorBackgroundGray;
        padding: .5rem;
        border-radius: pxToRem(4);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        > div.txt {
            margin-left: .25rem;
            margin-right: .25rem;
        }
        > div.grammar-icon {
            min-height: unset;
            margin-right: .25rem;
        }
        &.masculine {
            background-color: #F2F8F9;
            > div.arrow {
                > svg {
                    > path {
                        fill: #BDDBDF;
                    }
                }
            }
        }
        &.neutral {
            background-color: #FFF7E2;
            > div.arrow {
                > svg {
                    > path {
                        fill: #FFD86F;
                    }
                }
            }
        }
        &.feminine {
            background-color: #FFF0F0;
            > div.arrow {
                > svg {
                    > path {
                        fill: #FFBAB0;
                    }
                }
            }
        }
    }
</style>
