var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8 18l4 4 8.06-7.956c2.226-2.252 2.226-5.903 0-8.155S14.226 3.75 12 6c-2.226-2.252-5.835-2.363-8.06-.11-2.226 2.251-2.226 5.903 0 8.154L5 15",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M13 8h-2v3H8v2h3v3h2v-3h3v-2h-3V8z", fill: "#AC6ED1" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }