import Backbone from 'backbone';
import renderTemplate from '../../util/template.renderer.js';

const NameDialogModel = Backbone.Model.extend({
    defaults: {
        name: ''
    }
});

export const ClassroomNameDialogView = Backbone.View.extend({

    className: 'classroom-name-dialog-view',

    events: {
        'input input': 'changeInput',
        'keyup input': 'saveOnEnter',
        'click a.close': 'close',
        'click a.save': 'save'
    },

    initialize: function () {
        this.model = new NameDialogModel();
        this.listenTo(this.model, 'change', this.updateView);
    },

    render: function () {

        renderTemplate(
            'classrooms/classroom-name-dialog',
            this.model.attributes,
            this.$el
        ).then( () => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {
        this.$('input').focus();
        this.$el.addClass('animate');
        this.$errorMessage = this.$('p.error');
    },

    updateView: function ({ changed }) {

        if (changed.mounted !== undefined) {
            if (changed.mounted) {
                this.render();
            } else {
                this.remove();
            }
        }
    },

    changeInput: function (event) {
        const value = event.currentTarget.value;
        this.model.set('name', value);

        if (value.length >= 40) {
            this.$errorMessage.removeClass('hidden');
        } else {
            this.$errorMessage.addClass('hidden');
        }
    },

    save: function () {

        const newName = this.model.get('name');

        if (newName.length > 0) {
            this.trigger('save-classroom-name', newName);
        }
    },

    close: function () {
        this.trigger('close-classroom-name-dialog');
    },

    saveOnEnter: function (event) {

        const newName = this.model.get('name');

        if (event.key === 'Enter' && newName.length > 0) {
            this.trigger('save-classroom-name', newName);
        }
    },

    remove: function () {
        this.$el.removeClass('animate');
        setTimeout( () => Backbone.View.prototype.remove.call(this), 250);
    },
});
