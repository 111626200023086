<template>
    <section class="demographic-question">

        <h2 v-if="question.questionLanguage !== undefined" v-html="this.$i18n(question.questionId, {args: {language_name: {collection: question.questionLanguage}, target_language: question.questionLanguage}})"></h2>
        <h2 v-else> {{$i18n(question.questionId)}}</h2>
        <div class="answer"
             :class="{selected: question.selected}"
             v-for="answer in question.answers"
             :key="answer.name"
             @click="answerGiven(answer)">
            <div class="highlight">
                <answer-icon :value="answer.iconId"/>
                <div class="name-container">
                    <span class="name" v-html="$i18n(answer.name, { collection: question.answerId })" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import AnswerIcon from './demographic-icons.vue';

export default {
    name: "demographic-question",

    props: {
        question: {
            type: Object,
            required: true,
        },
    },

    components: {
        AnswerIcon
    },
    created() {
        this.randomize(this.question.answers);
    },
    beforeUpdate() {
        if (this.question.questionId !== "demographic_registration_question_what_your_age") {
            this.randomize(this.question.answers);
        } else {
            let randomOrder = Math.floor(Math.random() * 10) + 1;
            if (randomOrder % 2 === 0) {
                this.reorderAnswers(this.question.answers);
            }
        }
    },

    methods: {
        answerGiven (answer) {
            let question_id = this.question.questionId, question_raw = this.$i18n(this.question.questionId, {args: {language_name: {collection: this.question.questionLanguage}, target_language: this.question.questionLanguage}}),
                answer_id = answer.name, answer_raw = this.$i18n(answer.name, { collection: this.question.answerId });
            this.$emit('answer-given', question_id, question_raw, answer_id, answer_raw, answer );
        },
        randomize(answers) {
            let currentIndex = answers.length, randomIndex;
            while (currentIndex !== 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [answers[currentIndex], answers[randomIndex]] = [
                    answers[randomIndex], answers[currentIndex]];
            }
            return answers;
        },
        reorderAnswers(answersToReorder) {
            return answersToReorder.reverse();
        }
    }
}
</script>


<style scoped lang="scss">
@import '~Styles/colors';
@import '~Styles/mixins';

section.demographic-question {
    background-color: white;
    margin-bottom: 1rem;

    > h2 {
        font-weight: $font-bold;
        font-size: 1.625rem;
        text-align: center;
        padding-bottom: 1rem;
    }

    > div.answer {
        padding: 0.25rem 0;
        cursor: pointer;
        border-bottom: 1px solid $colorBackgroundDarker;
        user-select: none;

        &.selected {
            border-bottom: none;
            cursor: default !important;

            > div.highlight {
                background-color: $colorBackgroundLighter;
                cursor: default !important;
            }
        }

        > div.highlight {
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: background-color 0.2s, border 0.2s;
            border-radius: 0.5rem;
            min-height: 3rem;
            padding: 0 1rem;

            &:hover {
                cursor: pointer;
                background-color: $colorBackgroundLighter;
            }

            &:active, &.selected {
                background-color: $colorBackgroundDefault;
            }

            > div.name-container {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                padding: 0 1rem;

                > span.name {
                    font-weight: $font-bold;
                    font-size: 1.0625rem;
                }
            }
        }
    }
}
</style>
