<template>
    <div class="voice-input" v-if="isSupportedBrowser">
        <template v-if="!micAvailable">
            <icon-button icon-name="mic" @click.native="promptPermissions" />
        </template>
        <template v-else>
            <template v-if="micAvailable && listening">
                <div class="label" v-html="this.$i18n('utility_belt_voice_input_activated')" />
                <icon-button icon-name="mic-active" :pulse="true" @click.native="stopListening" />
            </template>
            <icon-button v-else-if="micAvailable && !listening" icon-name="mic" @click.native="startListening" />
        </template>
    </div>
</template>

<script>
    import IconButton from "./icon-button.vue";
    import { EventBus } from "Util/vue-event-bus.js";
    import { isEmpty } from "lodash";
    import i18nUtils from "Util/i18n.js";
    import { TYPE as TOAST_TYPE } from "../../toaster/constants.js";
    import VoiceInput from "Util/voice-input.js";

    export default {
        name: 'voice-input',
        components: { IconButton },
        props: {
            course: {
                type: Object,
                required: true
            },
            question: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                permissionStatus: null,
                transcription: [],
                recognition: null,
                micAvailable: false,
                listening: false,
                textListened: null,
                failedCount: 0,
            }
        },
        computed: {
            synonyms() {
                let synonyms = []
                if (this.question.senseContexts && this.question.senseContexts.length > 0 && this.question.senseContexts[0].similar_answers) {
                    this.question.senseContexts[0].similar_answers.forEach(item => {
                        if (item.answer) {
                            synonyms.push(item.answer);
                        }
                    });
                }
                return synonyms;
            },
            speechLang() {
                if (this.course && this.course.info && this.course.info.hasOwnProperty('target_language')) {
                    const languageMap = VoiceInput.getLanguageMap();
                    return languageMap.get(this.course.info.target_language);
                }
            },
            isSupportedBrowser() {
                return VoiceInput.isSupportedBrowser();
            }
        },
        methods: {
            showToast() {
                let _text;
                if (this.failedCount > 1) {
                    _text = i18nUtils.prop('speech_input_no_speech_detected');
                } else {
                    _text = i18nUtils.prop('speech_input_no_speech_detected_first_time');
                }

                if (_text) {
                    const toast = {
                        text: _text,
                        type: TOAST_TYPE.MICROPHONE
                    };
                    EventBus.$emit('toaster-add', toast);
                }
            },
            findCommonElements(arr1, arr2) {
                return arr1.find(item => arr2.some(x => x.toLowerCase() === item.toLowerCase()));
            },
            checkIfCorrectWordFound(transcriptsCollection) {
                if (!isEmpty(transcriptsCollection)) {
                    const validWords = [this.question.word, ...this.question.equivalent_words, ...this.synonyms];
                    // console.log('Speech: transcriptsCollection', transcriptsCollection);
                    transcriptsCollection.map(transcript => {
                        if (transcript && transcript.length > 0) {
                            const transcriptWords = transcript.split(" ");
                            let commonWord = this.findCommonElements(transcriptWords, validWords);
                            if (commonWord) {
                                EventBus.$emit('guess:voice-input', { text: commonWord, autoSubmit: true });
                            } else {
                                EventBus.$emit('guess:voice-input', { text: transcript, autoSubmit: false });
                            }
                        }
                    })
                }
            },
            cleanUpString(str) {
                return str.replace(/[^\p{L}\p{Z}_]|_/gu, "").replace(/\s+/g, " ");
            },
            checkSpeechApi() {
                window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

                if (!SpeechRecognition) {
                    this.micAvailable = false;
                    console.log("Speech: No Speech Recognition");
                    return false;
                }

                this.recognition = new SpeechRecognition();
                // this.recognition.continuous = true;
                this.recognition.lang = this.speechLang;
                this.recognition.interimResults = true;

                this.recognition.onresult = (event) => {
                    // console.log('Speech recognition result:', event);
                    this.textListened = Array.from(event.results)
                        .map((result) => result[0])
                        .map((result) => this.cleanUpString(result.transcript))
                        .join("");
                };

                this.clearTranscription();

                this.recognition.onend = () => {
                    // console.log('Speech recognition ended');
                    if (this.textListened) {
                        this.transcription.push(this.textListened);
                        this.stopListening();
                        this.checkIfCorrectWordFound(this.transcription);
                    } else {
                        this.failedCount = this.failedCount + 1;
                        this.stopListening();
                        this.showToast();
                    }

                    this.textListened = null;
                };
            },
            startListening() {
                this.checkSpeechApi();
                if (this.recognition) {
                    this.recognition.start();
                    this.listening = true;
                }
            },
            clearTranscription() {
                this.transcription = [];
            },
            stopListening() {
                this.listening = false;
                if (this.recognition) {
                    this.recognition.stop();
                }
                this.recognition = null;
            },
            clearVoiceInput() {
                this.transcription = [];
                this.recognition = null;
                this.listening = false;
                this.textListened = null;
            },
            async promptPermissions() {
                try {
                    await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                    this.micAvailable = true;
                } catch (err) {
                    this.micAvailable = false;
                    console.log(`${err.name} : ${err.message}`);
                }
            },
            async checkPermissions() {
                try {
                    this.permissionStatus = await navigator.permissions.query({ name: 'microphone' });
                    this.micAvailable = (this.permissionStatus.state === 'granted');
                    this.permissionStatus.onchange = () => {
                        console.log("Speech: mic permission changed to " + this.state);
                        this.micAvailable = (this.permissionStatus.state === 'granted');
                    };
                } catch (error) {
                    console.error(error);
                }
            },
            listenEventBus() {
                EventBus.$on('voice-input:start-listening', this.startListening);
                EventBus.$on('voice-input:stop-listening', this.stopListening);
            },
            unListenEventBus() {
                EventBus.$off('voice-input:start-listening', this.startListening);
                EventBus.$off('voice-input:stop-listening', this.stopListening);
            }
        },
        watch: {
            question: function(newQ, oldQ) {
                if (newQ.word !== oldQ.word) {
                    this.clearVoiceInput();
                }
            },
        },
        async mounted() {
            await this.checkPermissions();
            this.checkSpeechApi();
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            this.unListenEventBus();
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.voice-input {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;
        > div.label {
            font-size: fontSize(14);
        }
    }
</style>
