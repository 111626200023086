var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M19 14h-.33c-.429 0-.84-.17-1.145-.475v0a1.62 1.62 0 00-2.158-.12l-.187.151c-.69.552-1.67.552-2.36 0l-.11-.088a2 2 0 00-2.45-.038l-.227.17a2 2 0 01-1.2.4H8.5",
          stroke: "#8EA3EE",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 17.5V2M12 8h9c-1.522-2.944-4.978-5-9-5S4.522 5.056 3 8h4.8",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17 22H7l-4-8 3.5-3.5 4 7.5H21l-4 4z",
          fill: "#8EA3EE",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }