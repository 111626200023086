
/*jshint loopfunc: true */

import $ from 'jquery';

import { NotImplementedException } from '../util/modules.js';

import ControllerFactory from '../modules/controller.factory.js';

import UserModel from '../model/user.model.js';

import UserManager from '../modules/usermanager.js';
import { NAME, TYPE } from '../modules/user.parameters.js';
import { VARIATION_STATUS } from '../modules/course/user.course.js';
import ControllerManager from '../modules/controller.manager.js';
import URI from '../util/uri.js';
import Backbone from 'backbone';
import _ from 'lodash';
import getConfigValue from '../util/configuration.js';


class PostSigninAction {
    constructor(is_navigation_action = false) {
        this._is_navigation_action = is_navigation_action;
    }

    execute () {
        /**
         * @returns {Promise}
         */
        throw new NotImplementedException(this, 'execute');
    }

    get is_navigation_action () {
        return !!this._is_navigation_action;
    }
}

export class PostSigninUpdateFeaturesAction extends PostSigninAction {
    constructor (features) {
        super();
        this._features = features;
    }

    execute () {
        const user = UserManager.instance.getUser();
        const userParameters = user.getParameters();

        for (let feature of this._features) {
            user.addFeature(feature);
            if (feature === 'classrooms') {
                console.log("Setting userParameter LANDING_PAGE_DEFAULT", feature);
                userParameters.setParameter(NAME.LANDING_PAGE_DEFAULT, 'classrooms', TYPE.STRING);
            }
        }
    }
}

export class PostSigninUpdateCourseVariationAction extends PostSigninAction {
    constructor (course_uuid, variation_uuid) {
        super();
        this._course_uuid = course_uuid;
        this._variation_uuid = variation_uuid;
    }

    execute () {
        const onboardingController = ControllerManager.instance.getController('Onboarding');
        return Promise.resolve()
            .then(() => UserManager.instance.getUser().enrolToCourse(this._course_uuid))
            .then(() => UserModel.setCourse(this._course_uuid))
            .then(() => {
                let variation = UserManager.instance.getUser().getCourse().getVariation(this._variation_uuid);

                if (variation && variation.status === VARIATION_STATUS.SUBSCRIPTION_LIMITED) {
                    onboardingController.queuePostTrialStart({ type: 'set-variation', args: { variation_uuid: this._variation_uuid }});
                    UserManager.instance.getUser().getCourse().syncCourse();
                } else {
                    onboardingController.activateVariation(this._variation_uuid);
                    UserManager.instance.getUser().getCourse().syncCourse();
                }
            });
    }
}

export class PostSigninOpenURI extends PostSigninAction {
    constructor (uri) {
        super(true);
        this._uri = uri;
    }

    execute () {
        return Promise.resolve()
            .then(() => {
                return new URI(this._uri).navigateTo();
            });
    }
}

export class PostSigninNavigateTo extends PostSigninAction {
    constructor (path) {
        super(true);
        this._path = path;
    }

    async execute () {
        console.log('NAV: trigger PostSigninNavigateTo', this._path);
        return Backbone.history.navigate(this._path, { trigger: true });
    }
}

export class PostSigninUpdateRoleAction extends PostSigninAction {
    constructor (role) {
        super();
        this._role = role;
    }

    execute () {
        return Promise.resolve()
            .then(() => UserManager.instance.getUser().updateRole(this._role));
    }
}

export class PostSigninNavigateToPayAction extends PostSigninAction {
    constructor (parameters) {
        super(true);
        this.parameters = parameters;
    }

    async execute () {
        const code = await UserManager.instance.getUser().getAuthCode();

        const parameters = _.assign({}, this.parameters, {code});
        Backbone.trigger('showLoader', 'uri:pay');
        window.location.href = `${getConfigValue('pay-link')}?${$.param(parameters)}`;
    }
}


export class PostSigninActionsController {

    constructor () {
        this._post_signin_actions = [];
    }

    execute () {
        console.debug(`PostSigninUpdateAction executing ${this._post_signin_actions.length} post register actions`);
        if (this._post_signin_actions.length > 0) {
            let promise = Promise.resolve();
            while (this._post_signin_actions.length > 0) {
                let action = this._post_signin_actions.shift();
                console.debug(`PostSigninUpdateAction executing ${action.constructor.name}`);
                promise = promise.then(() => action.execute());
            }
            return promise;
        } else {
            return Promise.resolve();
        }
    }

    add_action (action) {
        /**
         * @param {PostSigninAction} action
         */

        this._post_signin_actions.push(action);
    }

    get_actions () {
        return this._post_signin_actions;
    }

    has_actions () {
        return this._post_signin_actions.length > 0;
    }

    has_navigation_action () {
        return !!this.get_actions().find(action => action.is_navigation_action);
    }
}

export const post_signin_actions_controller_factory = new ControllerFactory(PostSigninActionsController);
