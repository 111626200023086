var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 180 180",
          width: "180",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M29.59 111.04V47.08c0-7.23 5.86-13.08 13.08-13.08h86.17c7.23 0 13.08 5.86 13.08 13.08v8.62M116.891 96.21V85.57c0-7.23 5.86-13.08 13.08-13.08h23.9c7.23 0 13.08 5.86 13.08 13.08v47.45c0 7.23-5.86 13.08-13.08 13.08h-23.9c-7.23 0-13.08-5.86-13.08-13.08v-19.98M48.09 58.07h36.66M48.09 70.57h22.69",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M151.321 82.06h-18.8a6.97 6.97 0 00-6.97 6.97v40.3a6.97 6.97 0 006.97 6.97h18.8a6.97 6.97 0 006.97-6.97v-40.3a6.97 6.97 0 00-6.97-6.97z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: { d: "M142 61a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M141.92 130.15a3.69 3.69 0 100-7.38 3.69 3.69 0 000 7.38zM134.279 112.5V97.09c0-1.56 1.7-2.55 3.06-1.74l13.2 7.68a2.03 2.03 0 010 3.53l-13.2 7.69c-1.36.8-3.06-.19-3.06-1.75z",
          fill: "#9DF4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.02 111.18c-2.77 0-5.02 2.25-5.02 5.02 0 2.78 2.25 5.02 5.02 5.02h97.87v-10.05H19.02v.01z",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }