<template>
    <div class="learning-progress" :class="{ open }" v-if="userCourse">
        <div class="home-drawer-stats newWords">
            <div class="left">
                <div class="icon">
                    <icon-new-words />
                </div>
                <div class="words">
                    <div class="count"> {{ new_units_total }} </div>
                </div>
            </div>
            <div class="right">
                <div class="label">
                    <div v-if="open" class="title" v-html="this.$i18n('hub_today_new_words')" />
                </div>
            </div>
        </div>

        <div class="home-drawer-stats wordsAtRisk">
            <div class="left">
                <div class="icon">
                    <icon-words-at-risk />
                </div>
                <div class="words">
                    <div class="count"> {{ repeats_waiting }} </div>
                </div>
            </div>
            <div class="right">
                <div class="label">
                    <transition name="fade">
                        <div v-if="open" class="title" v-html="this.$i18n('widget_stats_words_at_risk_label')" />
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from 'Util/vue-event-bus';
import IconWordsAtRisk from 'Images/insights/ic_stats_repeats_light.svg';
import IconNewWords from 'Images/icons/icon-home-drawer-newwords.svg';

export default {
        components: {
        IconNewWords,
        IconWordsAtRisk
    },
    props: {
        userCourse: {
            type: Object,
            required: true
        },
        open: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            new_units_total: 0,
            repeats_waiting: 0
        }
    },
    methods: {
        setData() {
            if (this.userCourse) {
                const statistics = this.userCourse.getStatistics();
                const todayData = statistics.getTodayData();
                this.repeats_waiting = this.userCourse.repeats_waiting;
                this.new_units_total = todayData.new_units.total;
            }
        },
        listenEventBus() {
            EventBus.$on('statistics-updated', this.setData); // triggered in course.statistics.js
            EventBus.$on('course-state-updated', this.setData); // triggered in user.course.state.manager.js
        }
    },
    created() {
        this.setData();
        this.listenEventBus();
    },
    beforeDestroy() {
        EventBus.$off('course-state-updated', this.setData);
    }
}
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.learning-progress {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;

        > div.home-drawer-stats {
            display: grid;
            grid-template-rows: auto auto;
            grid-template-columns: pxToRem(72) auto;
            line-height: 20px;

            > div.left {
                > div.icon,
                > div.words {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    > div.count {
                        padding: 0 .5rem;
                        border-radius: pxToRem(4);
                        background-color: $colorBackgroundLighter;
                        color: $colorSourcePrimary;
                        font-size: fontSize(12);
                        font-family: $defaultFonts;
                    }
                }
                > div.icon {
                    padding-top: 0.5rem;
                    width: pxToRem(72);
                    height: pxToRem(34);
                }
                > div.words {
                    width: pxToRem(72);
                    min-height: pxToRem(34);
                }
            }

            > div.right {
                > div.label {
                    margin-left: -0.5rem;
                    padding: 0.8rem 0.5rem 0.5rem 0.5rem;
                    font-weight: $font-bold;
                    color: $colorSourceSecondary;
                    min-height: pxToRem(34);
                    body[data-interface-language="ar"] & {
                        padding: 0.8rem 0.5rem 0.5rem 0.5rem;
                        margin-right: -0.5rem;
                        margin-left: unset;
                    }
                }
            }
        }

    }
</style>
