var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "select-target-language" },
    [
      _c("h1", {
        domProps: {
          innerHTML: _vm._s(
            this.$i18n("register_select_target_languages_heading")
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "languages",
        _vm._g(
          {
            attrs: { languages: _vm.languages, show_language_not_listed: true },
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }