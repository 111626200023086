var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "36",
          height: "13",
          viewBox: "0 0 36 13",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M29.75 5.5a1 1 0 011-1H34a1 1 0 011 1v5a1 1 0 01-1 1h-3.25a1 1 0 01-1-1v-5zM34.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#FFD86F",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.5.5l-4.628 11.069",
          stroke: "#BDDBDF",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.75 5.5a1 1 0 011-1H21a1 1 0 011 1v5a1 1 0 01-1 1h-3.25a1 1 0 01-1-1v-5zM21.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM8.75 5.5a1 1 0 011-1H13a1 1 0 011 1v5a1 1 0 01-1 1H9.75a1 1 0 01-1-1v-5zM13.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM.75 5.5a1 1 0 011-1H5a1 1 0 011 1v5a1 1 0 01-1 1H1.75a1 1 0 01-1-1v-5zM5.25 1.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z",
          fill: "#FFD86F",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }