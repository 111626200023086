<template>
    <div class="card-context target" :style="{ fontSize: question.fontSize + 'rem'}" :class="{ hasSwt: this.question.hasSwt }">
        <card-swt v-if="swtInfo" :swtInfo="swtInfo" />
        <section class="context-container" :style="{ fontSize: question.fontSize + 'rem' }">
            <div class="context" v-if="question.parsedContext">
                <template v-for="(word, index) in question.parsedContext">
                    <template v-if="word.current">
                        <template v-if="word.noWrapEnd">
                            <span class="before" v-if="showSpacer(word.before)">{{ word.before }}</span><span class="no-wrap"><card-gap :is-first-instance="isFirstInstance(index)" :is-last-focused="word===lastFocusedWord" v-on:gap-focus="() => $data.lastFocusedWord=word" :onlySynonymAnswers="onlySynonymAnswers" :word="word" :answer="answer" :on_previous="on_previous" v-on:submit="submit" :disabled="disabled" :autoPlay="autoPlay" v-on="$listeners" @click.native="handleWordClick(word, $event)" /><span class="after" v-if="showSpacer(word.after)">{{ word.after }}</span></span>
                        </template>
                        <template v-else>
                            <span class="before" v-if="showSpacer(word.before)">{{ word.before }}</span><card-gap :is-first-instance="isFirstInstance(index)" :is-last-focused="word===lastFocusedWord" v-on:gap-focus="() => $data.lastFocusedWord=word" :onlySynonymAnswers="onlySynonymAnswers" :word="word" :answer="answer" :on_previous="on_previous" v-on:submit="submit" :disabled="disabled" :autoPlay="autoPlay" :day-insights-shown="dayInsightsShown"  v-on="$listeners" @click.native="handleWordClick(word, $event)" /><span class="after" v-if="showSpacer(word.after)">{{ word.after }}</span>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="word.noWrapEnd">
                            <span class="before" v-if="showSpacer(word.before)">{{ word.before }}</span><span class="no-wrap"><span class="word" :class="{ hasUsage: !!word.usage }" @click="handleWordClick(word, $event)">{{ word.word }}</span><span class="after" v-if="showSpacer(word.after)">{{ word.after }}</span></span>
                        </template>
                        <template v-else>
                            <span class="before" v-if="showSpacer(word.before)">{{ word.before }}</span><span class="word" :class="{ hasUsage: !!word.usage }" @click="handleWordClick(word, $event)">{{ word.word }}</span><span class="after" v-if="showSpacer(word.after)">{{ word.after }}</span>
                        </template>
                    </template>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
    import { EventBus } from "Util/vue-event-bus";
    import CardGap from "./card-gap.vue";
    import CardSwt from "./card-swt.vue";
    import AudioPlayer from "Util/audioplayer";
    import _ from "lodash";

    export default {
        name: "card-context",
        components: { CardGap, CardSwt },
        props: {
            question: {
                type: Object,
                required: true
            },
            answer: {
                type: Object
            },
            on_previous: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            autoPlay: {
                type: Boolean,
                default: false
            },
            dayInsightsShown: {
                type: Boolean
            },
            onlySynonymAnswers: {
                type: Boolean
            }
        },
        data() {
            return {
                swtInfo: null,
                activeWordElement: null,
                lastFocusedWord: undefined
            }
        },
        computed: {
            wordIndexInQuestion() {
                return this.question.parsedContext.findIndex(word => word.current === true);
            }
        },
        methods: {
            isFirstInstance(index) {
                return index === this.wordIndexInQuestion;
            },
            submit(answer) {
                EventBus.$emit('guess:submit', answer);
            },
            showSpacer(content) {
                return content && content.length > 0;
            },
            showSwt(translations, position, rect) {
                if (translations && translations.length > 0) {
                    this.swtInfo = {
                        translations,
                        position,
                        rect
                    };
                    this.activeWordElement.addEventListener('mouseleave', this.hideSwt, true);
                }
            },
            hideSwt() {
                this.activeWordElement.removeEventListener('mouseleave', this.hideSwt, true);
                this.activeWordElement = null;
                this.swtInfo = null;
            },
            handleWordClick(word, event) {
                if (word.current && !this.on_previous && this.answer && this.answer.confirmed) {
                    AudioPlayer.playSingleWord(word.audio_hash);
                } else if (word.current && !this.on_previous) {
                    return;
                }
                const wordParsedContext = _.find(this.question.parsedContext, { word: word.word });
                if (wordParsedContext && wordParsedContext.usage && wordParsedContext.usage.translations.length > 0 && !word.current) {
                    this.activeWordElement = event.target;
                    let translations = wordParsedContext.usage.translations;
                    let rect = this.activeWordElement.getBoundingClientRect();
                    let position = {
                        top: this.activeWordElement.offsetTop,
                        left: this.activeWordElement.offsetLeft,
                    };
                    this.showSwt(translations, position, rect);
                }
                AudioPlayer.playSingleWord(word.audio_hash);
            }
        },
    }
</script>

<style lang="scss">
    @import '~Styles/mixins';
    @import "~Styles/colors";

    div.card-context {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $targetLanguageFonts;
        font-feature-settings: "lnum" on;
        height: auto;
        margin: 0 0 pxToRem(16);

        > section.context-container {
            text-align: left;

            > div.context {
                color: $colorTargetPrimary;
                display: inline;
                align-items: center;
                line-height: 2.5rem;

                &.previous-card {
                    display: block;

                    span.integrated {
                        span.word {
                            background: none;
                        }
                    }
                }

                span.before {
                    padding: 0.3rem 0;
                }

                span.after {
                    padding: 0.3rem 0;
                }

                span.no-wrap {
                    white-space: nowrap;
                }

                span.word {
                    cursor: pointer;
                    &:hover {
                        opacity: .8;
                    }
                    &.active {
                        box-shadow: inset 0 -2px 0 0 $colorTargetPrimary;
                    }
                }
            }
        }

        &.hasSwt {
            > section.context-container {
                > div.context {
                    span.word.hasUsage:not(.active) {
                        box-shadow: inset 0 -2px 0 0 rgba($colorTargetPrimary, 0.2);
                    }
                }
            }
        }
    }
</style>
