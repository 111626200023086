var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grammar-tag" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("div", {
          staticClass: "title",
          domProps: { innerHTML: _vm._s(this.$i18n("hub_grammar")) },
        }),
        _vm._v(" "),
        _c("CloseButton", { staticClass: "close", on: { click: _vm.close } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("card-grammar", {
          attrs: {
            "parsed-grammar": this.question.parsed_grammar,
            arrow: false,
            full: true,
          },
        }),
        _vm._v(" "),
        _vm.iconDesc
          ? _c("div", { staticClass: "desc" }, [
              _vm._v("\n            " + _vm._s(_vm.iconDesc) + "\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        this.question.parsed_grammar.explanation
          ? _c("div", {
              staticClass: "explanation",
              domProps: {
                innerHTML: _vm._s(this.question.parsed_grammar.explanation),
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }