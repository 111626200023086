var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#8EA3EE",
          d: "M1 4.2C1 3 2 2 3.2 2h12.6C17 2 18 3 18 4.2v2.4c0 .6-.4 1-1 1s-1-.4-1-1V4.2c0-.1-.1-.2-.2-.2H3.2c-.1 0-.2.1-.2.2v9.9l1.4-1c.5-.3 1.1-.2 1.4.3.3.5.2 1.1-.3 1.4l-4.6 3V4.2z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#8EA3EE",
          d: "M5 7c0-.6.4-1 1-1h8c.6 0 1 .4 1 1s-.4 1-1 1H6c-.6 0-1-.4-1-1zM5 10c0-.6.4-1 1-1h2c.6 0 1 .4 1 1s-.4 1-1 1H6c-.6 0-1-.4-1-1z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M7 8c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2v13.9L18.7 19H14c-.6 0-1-.4-1-1s.4-1 1-1h5.3l1.7 1.1V8H9v9h1c.6 0 1 .4 1 1s-.4 1-1 1H9c-1.1 0-2-.9-2-2V8z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M11 11c0 .6.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1h-6c-.6 0-1 .4-1 1zM11 14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1s-.4-1-1-1h-3c-.6 0-1 .4-1 1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }