
import Backbone from 'backbone';
import { parse as parse_qs } from './util/query-string.js';
import Raven from 'raven-js';

import UserModel from './model/user.model.js';

import AppView from './view/app.view.js';

import SigninCredentialsView from 'view/signin/signin.credentials.view.js';
import SsoView from './view/util/sso.view.js';
import ForgotPasswordView from './view/signin/forgot.password.view.js';

import ResetPasswordView from './view/signin/reset.password.view.js';

import { guess_controller_factory } from './controller/guess.js';

import { classrooms_controller_factory } from './controller/classrooms.js';
import { lessons_controller_factory } from './controller/lessons.js';

import { challenges_controller_factory } from './controller/challenges.js';
import { conversation_controller_factory } from './controller/conversation.js';
import { reading_controller_factory } from './controller/reading.js';
import { listening_controller_factory } from './controller/listening.js';
import { pay_controller_factory } from './controller/pay.js';
import { signin_controller_factory } from './controller/signin.js';

import { account_controller_factory } from 'Controller/account.js';
import { grammar_tips_controller_factory } from './controller/grammar.tips.js';
import { verify_email_controller_factory } from './controller/verify.email.js';
import ShowFileView from './view/show-file.view.js';
import { hub_controller_factory } from './controller/hub.js';
import { insights_controller_factory } from './controller/insights.js';
import { relevant_words_controller_factory } from './controller/relevant_words.js';
import { course_wizard_controller_factory } from './controller/course_wizard.js';
import { register2_controller_factory } from './controller/register2.js';

// TODO: Move all handlers to new format:
// * enable fragment inserting into function call in execute
// * enable go function
// * use go function with controllers in routes
// * figure out query string handling


function go (controller_factory) {

    const controller = controller_factory.getController();

    return function () {
        const self = this;

        let args = Array.from(arguments);

        // Extract the fragment from the arguments (inserted by router.execute)
        const fragment = args.splice(0, 1)[0];

        const fragmentSplit = fragment.split('?');
        const route = fragmentSplit[0];
        const parameters = fragmentSplit[1];

        Backbone.trigger('preRoute', route, parameters);

        Promise.resolve()
            .then(() => self.getAppView())
            .then(appView => {
                args.unshift(appView, self);
                return controller.go.apply(controller, args);
            })
            .then(() => {
                Backbone.trigger('routed', fragment);
            })
            .catch(error => {
                console.error(error);
                Raven.captureException(error, {
                    level: 'error', extra: {
                        message: `Failed to go to "${fragment}"!`
                    }
                });
            });
    };
}

// FIXME: This router has a problem: It doesn't take into account the async nature of the path handlers,
//  and consequently when two routes are called in rapid succession a race condition occurs and the right one
//  might not be reached

export default Backbone.Router.extend({
    routes: {
        '': '!!NEVER USED!!',
        'guess': go(guess_controller_factory),
        'hub': go(hub_controller_factory),
        'insights(/:page)': go(insights_controller_factory),

        'challenges(?*queryString)': go(challenges_controller_factory),
        'challenges/speaking?uuid=:uuid': go(conversation_controller_factory),
        'challenges/grammar?uuid=:uuid': go(reading_controller_factory),
        'challenges/reading?uuid=:uuid': go(reading_controller_factory),
        'challenges/listening?uuid=:uuid': go(listening_controller_factory),

        'classrooms(/:uuid)': go(classrooms_controller_factory),
        'lessons(/:action)(/:uuid)': go(lessons_controller_factory),

        'account': go(account_controller_factory),
        'grammar-tips': go(grammar_tips_controller_factory),

        // Send deprecated routes help and faq to Account
        'help': go(account_controller_factory),
        'faq(?*queryString)': go(account_controller_factory),

        'register(/:step)(?*queryString)': go(register2_controller_factory),

        'signin(?*queryString)':                  go(signin_controller_factory),
        'sso(?*queryString)':                     'sso',
        'signin-credentials(?*queryString)':      'signin_credentials',

        'forgot-password(?*queryString)':         'forgot_password',
        'reset-password(?*queryString)':          'reset_password',

        'show-file(?queryString)':                'show_file',

        'verify-email(?*queryString)': go(verify_email_controller_factory),

        'subscriptions(/:page)(/:product)': go(pay_controller_factory),

        'relevant-words': go(relevant_words_controller_factory),

        'course-wizard(/:action)(/:uuid)': go(course_wizard_controller_factory),

        // DEPRECATED: Send Read & Listen users to Learn
        'read': go(guess_controller_factory),
        'listen': go(guess_controller_factory),
        'listen-list': go(guess_controller_factory),
        'read-list': go(guess_controller_factory),
    },

    previousRoutes: [],

    initialize: function () {
        this.on('route', function (name) {
            const _fragment = Backbone.history.fragment;
            console.log(`Router: route="${name}"`);
            console.log(`Router: fragment="${_fragment}"`);
            if (_fragment) {
                document.body.dataset.fragment = _fragment;
            }
        });
    },

    execute: function (callback, args) {
        let previousFragment = this.previousRoutes[this.previousRoutes.length - 1],
            nextFragment = Backbone.history.fragment,
            nextIsGuest = this.isGuestView(nextFragment);

        if (UserModel.isSignedIn() && nextIsGuest) {
            Backbone.history.navigate(previousFragment);
            return false;
        } else if (!UserModel.isSignedIn() && !nextIsGuest) {
            Backbone.history.navigate(previousFragment);
            return false;
        } else if (callback === undefined) {
            return false;
        } else {
            // TODO: following line messes up query parameters
            args.splice(0, 0, nextFragment);
            callback.apply(this, args);
        }
    },

    getAppView: function () {
        if (this._appViewPromise !== undefined) {
            return this._appViewPromise;
        } else if (this._appView !== undefined) {
            return Promise.resolve(this._appView);
        } else {
            this._appViewPromise = new Promise( (resolve, reject) => {

                let appView = new AppView();

                Promise.resolve()
                    .then(() => appView.render())
                    .then(() => {
                        this._appView = appView;
                        delete this._appViewPromise;
                        resolve(this._appView);
                    })
                    .catch(reject);
            });
            return this._appViewPromise;
        }
    },

    signin_credentials: function (fragment, queryString) {
        Backbone.trigger('preRoute', 'signin');
        this.getAppView().then(appView => {
            const signinCredentialsView = new SigninCredentialsView({
                router: this,
                queryParameters: parse_qs(queryString)
            });
            appView.setMainView(signinCredentialsView);
        });
    },

    sso: function (fragment, queryString) {
        Backbone.trigger('preRoute', 'sso');
        this.getAppView().then(appView => {
            const ssoView = new SsoView({
                router: this,
                queryParameters: parse_qs(queryString)
            });
            appView.setMainView(ssoView);
        });
    },

    forgot_password: function (fragment, queryString) {
        Backbone.trigger('preRoute', 'forgot-password');
        this.getAppView().then(appView => {
            const forgotPasswordView = new ForgotPasswordView({
                router: this,
                queryParameters: parse_qs(queryString)
            });
            appView.setMainView(forgotPasswordView);
        });
    },

    reset_password: function (fragment, queryString) {
        Backbone.trigger('preRoute', 'reset-password');
        this.getAppView().then(appView => {
            const resetPasswordView = new ResetPasswordView({
                queryParameters: parse_qs(queryString)
            });
            appView.setMainView(resetPasswordView);
        });
    },

    show_file: function (fragment, queryString) {
        Backbone.trigger('preRoute', 'show-file');
        this.getAppView().then(appView => {
            const showFileView = new ShowFileView({
                router: this,
                queryParameters: parse_qs(queryString)
            });
            appView.setMainView(showFileView);
        });
    },

    isGuestView: function (fragment) {
        if (fragment === undefined) {
            fragment = Backbone.history.fragment;
        }
        const hash = fragment.split('?')[0];

        return hash.startsWith('register') ||
               hash.startsWith('signin') ||
               hash === 'forgot-password' ||
               hash === 'home' ||
               hash === 'sso' ||
               hash === 'reset-password' ||
               hash === 'verify-email' ||
               hash === '';
    }
});
