
import i18nUtils from '../util/i18n.js';

const ACTION = {
    OPEN: 'open',
    CLOSE: 'close',
    NEXT: 'next',
    CUSTOM: '_custom'
};

const ACTION_STYLE = {
    DEFAULT: 'default',
    SECONDARY: 'secondary'
};

export class WelcomeBackDoorslam {
    static data () {
        return {
            cards: [{
                heading: i18nUtils.getRawMessage('progress_doorslam_daily_goals_repeat_title'),
                illustration_id: 'onboarding/welcome-back',
                paragraphs: [
                    i18nUtils.getRawMessage('progress_doorslam_daily_goals_repeat_message')
                ],
                actions: [{
                    title: i18nUtils.getRawMessage('progress_doorslam_got_it_button'),
                    style: ACTION_STYLE.DEFAULT,
                    action: ACTION.CLOSE,
                    uri: undefined
                }]
            }]
        };
    }
}

export class ConversationIntroDoorslam {
    static data () {
        return {
            cards: [
                {
                    heading: i18nUtils.getRawMessage('onboarding_conversation_heading'),
                    illustration_id: 'onboarding/conversation-speaking-face',
                    paragraphs: [
                        i18nUtils.getRawMessage('onboarding_conversation_1_message_1'),
                        i18nUtils.getRawMessage('onboarding_conversation_1_message_2'),
                        i18nUtils.getRawMessage('onboarding_conversation_1_message_3')
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('onboarding_conversation_button_next'),
                            style: ACTION_STYLE.SECONDARY,
                            action: ACTION.NEXT,
                            uri: undefined
                        }
                    ]
                },
                {
                    heading: i18nUtils.getRawMessage('onboarding_conversation_heading'),
                    illustration_id: 'onboarding/conversation-microphone',
                    paragraphs: [
                        i18nUtils.getRawMessage('onboarding_conversation_2_message_1'),
                        i18nUtils.getRawMessage('onboarding_conversation_2_message_2')
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('onboarding_conversation_button_check'),
                            style: ACTION_STYLE.SECONDARY,
                            action: ACTION.CUSTOM,
                            uri: 'check-permissions'
                        }
                    ]
                },
                {
                    heading: i18nUtils.getRawMessage('onboarding_conversation_heading'),
                    illustration_id: 'onboarding/conversation-microphone',
                    paragraphs: [
                        i18nUtils.getRawMessage('onboarding_conversation_2_message_1'),
                        i18nUtils.getRawMessage('onboarding_conversation_2_message_2'),
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('onboarding_conversation_button_continue'),
                            style: ACTION_STYLE.DEFAULT,
                            action: ACTION.CLOSE,
                            uri: undefined
                        }
                    ]
                },
                {
                    heading: i18nUtils.getRawMessage('onboarding_conversation_3_heading'),
                    illustration_id: 'onboarding/conversation-microphone-disabled',
                    paragraphs: [
                        i18nUtils.getRawMessage('onboarding_conversation_3_message_1'),
                        i18nUtils.getRawMessage('onboarding_conversation_3_message_2'),
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('onboarding_conversation_button_blocked'),
                            style: ACTION_STYLE.SECONDARY,
                            action: ACTION.OPEN,
                            uri: 'lingvist:challenges'
                        }
                    ]
                }
            ]
        };
    }
}


export class FastTrackingDoorslam {
    static data () {
        return {
            cards: [{
                heading: i18nUtils.getRawMessage('onboarding_fast_tracking_intro_heading'),
                illustration_id: 'fast_tracking_light',
                paragraphs: [
                    i18nUtils.getRawMessage('onboarding_fast_tracking_intro_message_1'),
                    i18nUtils.getRawMessage('onboarding_fast_tracking_intro_message_2')
                ],
                actions: [{
                    title: i18nUtils.getRawMessage('onboarding_fast_tracking_intro_button'),
                    style: ACTION_STYLE.DEFAULT,
                    action: ACTION.CLOSE,
                    uri: undefined
                }]
            }]
        };
    }
}


export class NoFastTrackingDoorslam {
    static data () {
        return {
            cards: [{
                heading: i18nUtils.getRawMessage('onboarding_no_fast_tracking_heading'),
                illustration_id: 'fast_tracking/no_ft',
                paragraphs: [
                    i18nUtils.getRawMessage('onboarding_no_fast_tracking_message_1'),
                    i18nUtils.getRawMessage('onboarding_no_fast_tracking_message_2')
                ],
                actions: [{
                    title: i18nUtils.getRawMessage('onboarding_no_fast_tracking_button'),
                    style: ACTION_STYLE.DEFAULT,
                    action: ACTION.CLOSE,
                    uri: undefined
                }]
            }]
        };
    }
}


export class FastTrackingCompleteDoorslam {
    static data (courseHasVocabulary) {
        if (courseHasVocabulary) {
            return {
                cards: [{
                    heading: i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_heading'),
                    illustration_id: 'fast_tracking/graph',
                    paragraphs: [
                        i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_message_1'),
                        i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_message_2')
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_button_dismiss'),
                            style: ACTION_STYLE.DEFAULT,
                            action: ACTION.CLOSE,
                            uri: 'lingvist:progress'
                        },
                        {
                            title: i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_button_progress'),
                            style: ACTION_STYLE.SECONDARY,
                            action: ACTION.CLOSE,
                            uri: undefined
                        }
                    ]
                }]
            };
        } else {
            return {
                cards: [{
                    heading: i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_heading'),
                    illustration_id: 'fast_tracking/graph',
                    paragraphs: [
                        i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_message_1'),
                        i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_message_2')
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('onboarding_finish_fast_tracking_1_button_progress'),
                            style: ACTION_STYLE.SECONDARY,
                            action: ACTION.CLOSE,
                            uri: undefined
                        }
                    ]
                }]
            };
        }
    }
}

export class CourseEndDoorslam {
    static data (variables) {
        if (variables && variables.variations_enabled && !variables.variations_completed) {
            return {
                cards: [{
                    heading: i18nUtils.getRawMessage('no_words_course_variations_title'),
                    illustration_id: 'upsell-doorslam/end_of_course',
                    paragraphs: [
                        i18nUtils.getRawMessage('no_words_course_variations_text')
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('no_words_course_variations_btn_home'),
                            style: ACTION_STYLE.DEFAULT,
                            action: ACTION.CUSTOM,
                            uri: 'open_hub'
                        }
                    ]
                }]
            };
        } else {
            return {
                cards: [{
                    heading: i18nUtils.getRawMessage('extend_course_no_variations_title'),
                    illustration_id: 'upsell-doorslam/end_of_course',
                    paragraphs: [
                        i18nUtils.getRawMessage('extend_course_no_variations_text')
                    ],
                    actions: [
                        {
                            title: i18nUtils.getRawMessage('extend_course_no_variations_home_btn'),
                            style: ACTION_STYLE.DEFAULT,
                            action: ACTION.CUSTOM,
                            uri: 'open_hub'
                        }
                    ]
                }]
            };
        }
    }
}

export class VariationEndUnifiedDoorslam {
    static data (variables) {
        let cards = [
            {
                heading: i18nUtils.getRawMessage('extend_end_of_variation_title'),
                illustration_id: 'upsell-doorslam/end_of_course',
                paragraphs: [
                    i18nUtils.getRawMessage('extend_end_of_variation_text'),
                ],
                copy_link_enabled: variables.copy_link_enabled,
                actions: [{
                    title: i18nUtils.getRawMessage('extend_end_of_variation_continue_btn'),
                    style: ACTION_STYLE.DEFAULT,
                    action: ACTION.NEXT,
                    uri: undefined
                }]
            }
        ];

        if (variables && variables.extend_variation_enabled) {
            cards.push({
                heading: i18nUtils.getRawMessage('extend_course_cw_title'),
                illustration_id: 'upsell-doorslam/end_of_course_share',
                paragraphs: [
                    i18nUtils.getRawMessage('extend_course_cw_text'),
                ],
                actions: [
                    {
                        title: i18nUtils.getRawMessage('extend_course_cw_btn_home'),
                        style: ACTION_STYLE.SECONDARY,
                        action: ACTION.CUSTOM,
                        uri: 'open_hub'
                    },
                    {
                        title: i18nUtils.getRawMessage('extend_course_cw_btn_extend'),
                        style: ACTION_STYLE.DEFAULT,
                        action: ACTION.CUSTOM,
                        uri: 'extend_variation'
                    }
                ]
            });
        } else {
            cards.push({
                heading: i18nUtils.getRawMessage('extend_course_variations_title'),
                illustration_id: 'upsell-doorslam/end_of_course',
                paragraphs: [
                    i18nUtils.getRawMessage('extend_course_variations_text'),
                ],
                actions: [
                    {
                        title: i18nUtils.getRawMessage('extend_course_variations_home_btn'),
                        style: ACTION_STYLE.DEFAULT,
                        action: ACTION.CUSTOM,
                        uri: 'open_hub'
                    }
                ]
            });
        }

        return {
            cards
        };
    }
}

export class LessonIsPublishedDoorslam {
    static data () {
        return {
            cards: [{
                heading: i18nUtils.getRawMessage('course_wizard_course_ready_title'),
                illustration_id: 'doorslam/published',
                paragraphs: [
                    i18nUtils.getRawMessage('course_wizard_course_ready_message_1')
                ],
                actions: [
                    {
                        title: i18nUtils.getRawMessage('course_wizard_course_ready_confirm_button'),
                        style: ACTION_STYLE.DEFAULT,
                        action: ACTION.CUSTOM,
                        uri: 'confirm'
                    },
                    {
                        title: i18nUtils.getRawMessage('course_wizard_course_ready_decline_button'),
                        style: ACTION_STYLE.SECONDARY,
                        action: ACTION.OPEN,
                        uri: 'lingvist:guess'
                    }
                ]
            }]
        };
    }
}

export class PaywallDoorslam {
    static data () {
        return {
            cards: [{
                heading: i18nUtils.getRawMessage('onboarding_pretrial_ended_heading'),
                illustration_id: 'upsell-doorslam/free_limit_reached',
                paragraphs: [
                    i18nUtils.getRawMessage('onboarding_pretrial_ended_message')
                ],
                actions: [
                    {
                        title: i18nUtils.getRawMessage('onboarding_pretrial_ended_button'),
                        style: ACTION_STYLE.DEFAULT,
                        action: ACTION.CUSTOM,
                        uri: 'open_pay'
                    },
                    {
                        title: i18nUtils.getRawMessage('onboarding_pretrial_ended_dismiss'),
                        style: ACTION_STYLE.SECONDARY,
                        action: ACTION.CUSTOM,
                        uri: 'open_hub'
                    }
                ]
            }]
        };
    }
}
