var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 44 116" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          opacity: ".2",
          fill: "#B2B8CB",
          d: "M12 56h20c2.2 0 4 1.8 4 4v42c0 2.2-1.8 4-4 4H12c-2.2 0-4-1.8-4-4V60c0-2.2 1.8-4 4-4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }