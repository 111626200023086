var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M13 7v4l6.337-5.432a1 1 0 011.316.012l15.381 13.673c.688.61.255 1.747-.664 1.747H32v14H8V21H4.414c-.89 0-1.337-1.077-.707-1.707L8 15V7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16 25a2 2 0 012-2h4a2 2 0 012 2v9h-8v-9z",
          fill: "#AC6ED1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }