var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.userCourse && _vm.courseHasAudio
    ? _c("div", { staticClass: "audio-settings" }, [
        _c(
          "div",
          { staticClass: "setting audio-toggle" },
          [
            _vm.audio_on
              ? _c("div", {
                  staticClass: "label",
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("menu_audio_settings_on")),
                  },
                })
              : !_vm.audio_on
              ? _c("div", {
                  staticClass: "label",
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("menu_audio_settings_off")),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("toggle-button", {
              key: "audio_toggle",
              attrs: {
                sync: true,
                color: { checked: "#2C3143" },
                width: 70,
                height: 24,
                labels: false,
              },
              model: {
                value: _vm.audio_on,
                callback: function ($$v) {
                  _vm.audio_on = $$v
                },
                expression: "audio_on",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "setting audio-speed",
            class: { disabled: !_vm.audio_on },
          },
          [
            _c("div", {
              staticClass: "label",
              domProps: {
                innerHTML: _vm._s(this.$i18n("learn_settings_audio_speed_lbl")),
              },
            }),
            _vm._v(" "),
            _c("toggle-button", {
              key: "audio_speed",
              attrs: {
                sync: true,
                color: { checked: "#2C3143", unchecked: "#2C3143" },
                width: 70,
                height: 24,
                labels: {
                  checked: _vm.audio_speed_slow_string,
                  unchecked: _vm.audio_speed_medium_string,
                },
              },
              on: { change: _vm.toggleAudioSlow },
              model: {
                value: _vm.audio_slow,
                callback: function ($$v) {
                  _vm.audio_slow = $$v
                },
                expression: "audio_slow",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }