var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M11 6H8l3 5H6.5L5 8h-.8C5.384 4.86 8.404 2.6 12 2.6c4.465 0 8.162 3.455 8.4 7.8v.6H15l-4-5z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4 15h7M19 15h1a2 2 0 002-2v0a2 2 0 00-2-2H7L5 8H2l1 4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 11l-4-5H8l3 5M15 15l-4 5H8l3-5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }