'use strict';

import googleAnalyticsCommand from '../../util/google-analytics.js';
import Raven from 'raven-js';
import Course from './course.js';
import {AssetLoader} from '../asset.loader.js';
import getConfigValue from '../../util/configuration.js';
import UserCourseStateManager from './user.course.state.manager.js';
import {ERROR_CODE as VARIATIONS_ERROR} from 'Controller/account_old.js';
import { StatisticsProcessorUtils } from '../statistics/processor.utils.js';
import Schema from '../../util/schema.js';

import _ from 'lodash';
import superagent from 'superagent';
import { Awards } from '../awards.js';
import { GrammarTips } from '../grammar.tips.js';
import { CourseVariationStats } from '../course.variation.stats.js';
import { LexicalUnitDataCache } from '../lexical.unit.data.cache.js';
import { EVENT_SCHEMA } from '../eventsender.js';

export class VoiceUUIDError extends Error {
    constructor (message) {
        super(message);
        this.name = 'VoiceUUIDError';
    }
}

export const VARIATION_STATUS = {
    INITIAL: 'initial',
    IN_PROGRESS: 'in_progress',
    SUBSCRIPTION_LIMITED: 'subscription_limited',
    COMPLETE: 'complete'
};

export const VARIATION_TYPE = {
    GENERAL: 'general',
    FOCUS: 'focus',
    LESSON: 'lesson'
};

export const AUDIO_SPEED = {
    SLOW: 'slow',
    MEDIUM: 'medium'
};

export class UserCourse extends Course {
    /**
     *
     * @param user {User}
     * @param {object} user_course_info - UserCourseInfo_v11. Keys=[
     uuid, source_language, target_language, source_icon_id, target_icon_id, hidden, registered_ts,
     expiration_ts, paid, payment_uuid, payment_status].
     MUST CONTAIN AT LEAST UUID IF COURSE IS INITIALIZED IMMEDIATELY !
     */
    constructor (user, user_course_info) {
        console.debug(`UserCourse.constructor(${user_course_info.uuid}(${user_course_info.source_language}->${user_course_info.target_language}))`);
        super(user_course_info, ['initialize', 'enrolTo', 'unregisterFrom', 'requestQuestions', 'syncCourse']);
        this._initialized = false;

        this._user = user;

        this._assetLoader = new AssetLoader({
            // Configuration is hardcoded for now
            POOL_SIZE: 5,
            TIMEOUT_MULTIPLIER: 2,
            RANDOM_COMPONENT: true,
            MAX_RETRIES: 5,
            TIMEOUT_BASE: 500
        }, superagent);

        this._stateManager = new UserCourseStateManager(this._user, this, this._assetLoader);

        // These are mostly just helpers and don't contain course state as sent by the server
        this._awards = new Awards(this._user, this);
        this._grammarTips = new GrammarTips(this, this._assetLoader);
        this._courseVariationStats = new CourseVariationStats(this._user, this);
        this._lexical_unit_data_cache = new LexicalUnitDataCache();
    }

    async initialize () {
        console.debug(`UserCourse.initialize() course_uuid=${this.UUID}`);
        if (this._initialized) {
            console.info(`UserCourse course_uuid="${this.UUID}" already initialized`);
            // When coming back to a course that has already been active before
            this.syncCourse(); // not awaiting for this on purpose
            return this;
        } else {
            console.info(`Initializing UserCourse course_uuid="${this.UUID}"`);
            await this._stateManager.initialize();
            googleAnalyticsCommand('set' , 'dimension4', this._stateManager.getUserCourseExtendedInfo().variation_uuid);
            this._initialized = true;
            return this;
        }
    }

    /**
     * Get the basic course info
     * @returns {CourseInfo}
     */
    getInfo () {
        if (this._initialized) {
            return this._stateManager.getInfo();
        } else {
            return super.getInfo();
        }
    }

    destroy () {
        console.debug('UserCourse.destroy()');
        let promise = Promise.resolve();
        if (this._initialized) {
            promise = promise
                .then(() => this._stateManager.destroy())
                .then(() => this._assetLoader.destroy())
                .then(() => this._grammarTips.destroy())
                .then(() => this._courseVariationStats.destroy())
                .then(() => this._lexical_unit_data_cache.destroy())
                .then(() => this._awards.destroy());
        }

        return promise
            .then(() => {
                delete this._user;
            }).then(() => super.destroy());
    }

    enrolTo () {
        let self = this;

        return Promise.resolve().then(function () {
            return self._user.getApiClient().r.courses.course_uuid(self.UUID).register.post({});
        }).then(function (courseInfo) {
            _.assign(self._state, courseInfo);
        });
    }

    unregisterFrom () {
        return Promise.resolve().then(() => {
            return this._user.getApiClient().r.courses.course_uuid(this.UUID).unregister.post();
        }).then(() =>  {
            return this._stateManager.update({registered_ts: null});
        });
    }

    getVoices () {
        return this._stateManager.getVoices();
    }

    async getVoiceUUID () {
        const voiceUUID = await this._stateManager.getVoiceUUID();
        if (voiceUUID) {
            return voiceUUID;
        } else {
            let voices = this._stateManager.getVoices();

            if (!voices || voices.length === 0) {
                if (!this._voicesErrorReported) {
                    this._voicesErrorReported = true; // No point in reporting this on each word
                    Raven.captureException(new VoiceUUIDError(`Course uuid="${this.UUID}" has 0 voices.`), {level: 'error'});
                }
                return null;
            }

            // Currently just return the uuid of the first voice
            return voices[0].uuid;
        }
    }

    // StateManager Proxies

    getGuessQueue () {
        return this._stateManager.getGuessQueue();
    }

    getStatistics () {
        return this._stateManager.getStatistics();
    }

    getFastTrackingState () {
        return this._stateManager.getFastTrackingState();
    }

    getLimits () {
        return this._stateManager.getLimits();
    }

    getExperiments () {
        return this._stateManager.getExperiments();
    }

    getExercises () {
        return this._stateManager.getExercises();
    }

    getGrammarTips () {
        return this._grammarTips;
    }

    getLexicalUnitDataCache () {
        return this._lexical_unit_data_cache;
    }

    getAwards () {
        return this._awards;
    }

    async hasFeature (feature) {
        return (await this._stateManager.getFeatures()).find(f => f === feature) !== undefined;
    }

    getAssetUrl (asset) {
        /**
         * Returns v1 asset urls
         * Supported assets: grammar_hints, vocabulary_curve, vocabulary_text
         * */
        let assetPaths = this._stateManager.getAssetPaths();
        return assetPaths[asset] && `${getConfigValue('assets-base-url')}/${assetPaths[asset].v1}` || null;
    }

    getUrl (item) {
        /**
         * Supported urls: feedback
         * */
        return this._stateManager.getUrls()[item] || null;
    }

    getPredictedTsOverride () {
        return this._stateManager.getUserCourseExtendedInfo().predicted_ts_override;
    }

    requestQuestions (count) {

        return Promise.resolve()
            .then(() => this._user.getEventSender().sendNavigationEvent('request-questions', 'send-request', null))
            .then(() => this._user.getApiClient().r.courses.course_uuid(this.UUID).request_questions.post({
                count: count
            }))
            .then(response => {
                if (response.questions > 0) {
                    return this.syncCourse();
                } else {
                    return Promise.reject(Error('Questions request failed - no questions were given'));
                }
            });
    }

    getSurvey (type) {
        let surveys = this._stateManager.getSurveys();
        return surveys && surveys[type] || null;
    }

    getVariationsInfo () {
        let { variation_categories: categories } = this._stateManager.getUserCourseExtendedInfo();

        return categories.map(category => ({
            title: category.title,
            variations:category.variations.map(variation => ({
                uuid: variation.uuid,
                status: variation.status,
                name: variation.name,
                description: variation.description,
                icon: variation.icon,
                active: variation.enabled,
                units: variation.units,
                type: variation.type,
                sample: variation.sample,
                share_url: variation.share_url,
                assigned_ts: variation.assigned_ts,
                difficulty: variation.difficulty,
                features: variation.features,
                paid: variation.paid,
                can_extend: variation.can_extend
            }))
        }));
    }

    getVariation (variation_uuid) {
        let categories = this._stateManager.getVariationCategories(),
            variation = null;

        categories.forEach(category => {
            variation = category.variations.find(variation => variation.uuid === variation_uuid) || variation;
        });

        return variation;
    }

    getCurrentVariations () {
        const variationCategories = this.getVariationsInfo();
        let currentVariations = [];

        variationCategories.forEach(category => {
            const { variations } = category;
            const activeVariations = variations && variations.filter(variation => variation.active);
            currentVariations.push(...activeVariations);
        });

        return currentVariations;
    }

    setVariation ({ variation, variation_uuid, enabled = true, deactivate_other = false }) {
        const uuid = (variation) ? variation.uuid : variation_uuid;
        return Promise.resolve()
            .then(() => this._user.getApiClient().r.courses.course_uuid(this.UUID).variation.put({
                enabled,
                deactivate_other,
                uuid
            }))
            .then(response => {  // 200 - Success response
                if (response.status === 'OK') {
                    googleAnalyticsCommand('set' , 'dimension4', uuid);
                    return this.syncCourse();
                } else {
                    return Promise.reject({
                        code: VARIATIONS_ERROR.UNKNOWN,
                        data: {
                            code: `ERROR`,
                            message: `Invalid response=${JSON.stringify(response)}`
                        }
                    });
                }
            }).catch(error => {  // 400, 403, 500 - Error responses
                let code = VARIATIONS_ERROR.UNKNOWN,
                    data = {};

                if (error.response && error.response.type === 'application/json') {
                    let body = JSON.parse(error.response.text);
                    code = _.values(VARIATIONS_ERROR).find(error => error === body.reason) ? body.reason : VARIATIONS_ERROR.UNKNOWN;

                    data = body;
                }

                if (code === 'limited') {
                    return Promise.resolve()
                        .then(() => this.syncCourse());
                } else {
                    return Promise.reject({code: code, data});
                }
            });
    }

    getVoiceWithSpeed (speed) {
        let voices = this._stateManager.getVoices();
        if (voices.length > 0) {
            return _.find(voices,{ speed: speed });
        } else {
            return null;
        }
    }

    async getVoice () {
        const uuid = await this.getVoiceUUID();
        let voices = this._stateManager.getVoices();

        if (voices.length > 0) {
            return _.find(voices,{ uuid });
        } else {
            return null;
        }
    }

    async setVoiceSpeed (speed) {
        const { uuid } = this.getVoiceWithSpeed(speed);
        console.log(`Setting new voice with speed ${speed}:`, uuid);
        if (uuid) {
            try {
                await this._user.getApiClient().r.courses.course_uuid(this.UUID).voice.put({
                    uuid
                });
                await this._stateManager.update({ voice_uuid: uuid });
            } catch (error) {
                console.log('setVoiceSpeed failed with error', error);
            }
        }
    }

    getCourseVariationStats () {
        return this._courseVariationStats;
    }

    syncCourse () {
        return this._stateManager.updateState();
    }

    loadLexicalUnits (lexicalUnits) {
        return this._stateManager.loadLexicalUnits(lexicalUnits);
    }

    reset () {
        return this._stateManager.reset();
    }

    waitForSynced () {
        return this._stateManager.waitForSynced();
    }

    get repeats_waiting () {
        return this._stateManager.getRepeatsWaiting();
    }

    isInitialized () {
        return this._initialized;
    }

    addEvent (event) {
        this.getStatistics().addEvent(event);

        if (Schema.isSchemaMinorGE(event.schema, EVENT_SCHEMA.GUESS, 0, 0) &&
            !StatisticsProcessorUtils.is_guess_event_new_word(event) &&
            this.repeats_waiting >= 1) {
            this._stateManager.decrementRepeatsWaiting();
        }
    }
}
