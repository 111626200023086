var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 296 180",
          width: "296",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M159.67 140.63V50.68c0-6.45 5.23-11.68 11.68-11.68h43.42c6.45 0 11.68 5.23 11.68 11.68v67.67c0 6.45-5.23 11.68-11.68 11.68h-36.05",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M159.76 50.69c0-6.45-5.23-11.68-11.68-11.68h-42.42c-6.45 0-11.68 5.23-11.68 11.68M93.98 118.35c0 6.45 5.23 11.68 11.68 11.68h37.06c4.23 0 8.29 1.7 11.26 4.72l5.78 5.88M72.69 95.05V85.15c0-5.51 2.65-10.68 7.11-13.9l16.96-13.02 28.679-5.7a8.677 8.677 0 01-4.71 10.5l-11.78 5.31M123.169 88.75v10.9c0 6.93-5.62 12.54-12.54 12.54h-20.79c-4.73 0-9.02-1.92-12.12-5.02",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M117.13 81.5l27.61-1.34a5.134 5.134 0 004.89-5.13c0-2.77-2.19-5.04-4.95-5.13l-39.33-1.41",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M73 100a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M210.03 56.4h-33.96a4.97 4.97 0 00-4.97 4.97v3.54a4.97 4.97 0 004.97 4.97h33.96a4.97 4.97 0 004.97-4.97v-3.54c0-2.75-2.22-4.97-4.97-4.97z",
          fill: "#AC6ED1",
          stroke: "#AC6ED1",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M176.079 91.24a4.97 4.97 0 01-4.97-4.97v-3.54a4.97 4.97 0 014.97-4.97h33.96a4.97 4.97 0 014.97 4.97v3.54a4.97 4.97 0 01-4.97 4.97h-24.77M176.079 112.61a4.97 4.97 0 01-4.97-4.97v-3.54a4.97 4.97 0 014.97-4.97h33.96a4.97 4.97 0 014.97 4.97v3.54a4.97 4.97 0 01-4.97 4.97h-23.75",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M177.33 62.84l3.48 3.18 6.24-5.77",
          stroke: "#9DF4F4",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }