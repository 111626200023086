var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M39 14.5c0 2.765-1.264 4.877-3.5 6a6.407 6.407 0 00-4.668-2.5c-.479-3-2.32-5.883-3.832-7.177C28 9 30.305 8 32.5 8c3.67 0 6.5 3 6.5 6.5z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.602 26.44A6.943 6.943 0 013 23.607c0-4.081 3.511-7.392 7.839-7.392.093 0 .18 0 .265.007C12.136 12.081 16.084 9 20.792 9c5.496 0 9.945 4.196 9.945 9.378v.014c3.497.21 6.263 2.946 6.263 6.297 0 .608-.093 1.196-.265 1.75C35.932 29.074 33.36 31 30.308 31H15.203",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }