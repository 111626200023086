
import dust from "dustjs-linkedin";

import "template/account/course.js";
import "template/account/course.js";
import "template/account/course.js";

(function(){dust.register("classrooms/create-classroom",body_0);function body_0(chk,ctx){return chk.write("<div class=\"create-classroom-modal\"><div class=\"titlebar\"><div class=\"title\">").helper("i18n",ctx,{},{"id":"classrooms_create_modal_title"}).write("</div><a class=\"close\">").helper("svg",ctx,{},{"path":"close"}).write("</a></div><div class=\"name\"><div class=\"input classroom-name\"><span class=\"icon\">").helper("svg",ctx,{},{"path":"menu/classrooms"}).write("</span><input type=\"text\" autocomplete=\"off\" maxlength=\"40\" placeholder=\"").helper("i18n",ctx,{},{"id":"classrooms_create_modal_name_placeholder"}).write("\" /></div><p class=\"error hidden\">").helper("i18n",ctx,{},{"id":"course_wizard_publish_name_error"}).write("</p></div><main><div class=\"courses\">").section(ctx.get(["activeSourceCourses"], false),ctx,{"block":body_1},{}).write("</div><div class=\"courses\">").section(ctx.get(["otherSourceCourses"], false),ctx,{"block":body_2},{}).write("</div>").section(ctx.get(["noActiveNoOtherCourses"], false),ctx,{"block":body_3},{}).write("</main><footer><button disabled class=\"create-classroom\">").helper("i18n",ctx,{},{"id":"classrooms_create_modal_action_create"}).write("</button></footer></div>");}function body_1(chk,ctx){return chk.partial("account/course",ctx,{"course":ctx.getPath(true, [])});}function body_2(chk,ctx){return chk.partial("account/course",ctx,{"course":ctx.getPath(true, [])});}function body_3(chk,ctx){return chk.write("<div class=\"courses\">").section(ctx.get(["allAvailableCourses"], false),ctx,{"block":body_4},{}).write("</div>");}function body_4(chk,ctx){return chk.partial("account/course",ctx,{"course":ctx.getPath(true, [])});}return body_0;})();

export default 0;
