var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { "fill-rule": "nonzero", fill: "none" } }, [
        _c("circle", {
          attrs: { cx: "16", cy: "16", r: "16", fill: "#FFCC4D" },
        }),
        _c("path", {
          attrs: {
            fill: "#664500",
            d: "M16 18.667c-3.22 0-5.357-.375-8-.89-.604-.116-1.778 0-1.778 1.779 0 3.555 4.085 8 9.778 8 5.692 0 9.778-4.445 9.778-8 0-1.778-1.174-1.896-1.778-1.778-2.643.514-4.78.889-8 .889z",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#FFF",
            d: "M8 19.556s2.667.888 8 .888 8-.888 8-.888-1.778 3.555-8 3.555c-6.222 0-8-3.555-8-3.555z",
          },
        }),
        _c("ellipse", {
          attrs: {
            cx: "10.667",
            cy: "12",
            fill: "#664500",
            rx: "2.222",
            ry: "3.111",
          },
        }),
        _c("ellipse", {
          attrs: {
            cx: "21.333",
            cy: "12",
            fill: "#664500",
            rx: "2.222",
            ry: "3.111",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }