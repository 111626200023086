<template>
    <span class="saving-indicator">
        <span v-if="saving" class="saving-blob" />
        <span v-if="saving" class="saving" v-html="this.$i18n('saving', { collection: 'lessons_creator_editor_saving_indicator' })" />
        <span v-else class="saved" v-html="this.$i18n('saved', { collection: 'lessons_creator_editor_saving_indicator' })" />
    </span>
</template>

<script>
    import { EventBus } from 'Util/vue-event-bus';

    export default {
        name: 'saving-indicator',
        data() {
            return {
                saving: false
            }
        },
        methods: {
            setSavingState(event) {
                this.saving = event;
            },
            listenEventBus() {
                EventBus.$on('cw:saving', this.setSavingState);
            }
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            EventBus.$off('cw:saving', this.setSavingState);
        }
    }
</script>

<style lang="scss">
    span.saving-indicator {
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        opacity: 0.6;
        span.saved {
            text-decoration: underline;
        }
        span.saving {
            cursor: progress;
        }
        span.saving-blob {
            display: inline-block;
            border-radius: 50%;
            margin-right: .8rem;
            height: 1rem;
            width: 1rem;
            transform: scale(1);
            background: rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            animation: pulse-red 1s infinite;
        }
    }

    @keyframes pulse-red {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
        }
    }
</style>
