var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#FFF",
          d: "M15.3 3.5c-.7.9-2 1.6-3 1.6H12v-.4c0-1.1.6-2.3 1.2-3 .7-1 2.1-1.7 3.2-1.7v.4c0 1.2-.5 2.3-1.1 3.1zm-4.9 18.1c-.5.2-1 .4-1.6.4-1.4 0-2.3-1.3-3.4-2.8C4 17.4 3 14.6 3 11.9c0-4.3 2.8-6.5 5.6-6.5.8 0 1.5.3 2.2.6.5.2 1 .4 1.4.4.4 0 .8-.2 1.3-.4.7-.3 1.6-.6 2.6-.6.6 0 2.9.1 4.4 2.2h-.1c-.4.2-2.4 1.5-2.4 4.1 0 3.3 2.9 4.4 3 4.4v.1c-.1.3-.5 1.7-1.5 3.1-1 1.3-2 2.7-3.5 2.7-.7 0-1.2-.2-1.7-.4s-1-.5-1.9-.5c-.9 0-1.5.2-2 .5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }