<template>
    <div class="card-visual-modal" @click.self="close" v-if="isOpen">
        <div class="card-visual-modal-window">
            <CloseButton @click="close" class="close" />
            <h2 class="title" v-html="this.$i18n('unsplash_change_picture_title')" />
            <div class="content">
                <div class="visuals">
                    <div class="visual" :class="{ selected: image.selected, empty: image.empty }" @click="changeChosenVisual(image)"
                         v-for="image in this.images"
                         v-bind:style="getStyle(image)" />
                </div>
                <v-button :i18n="{ id: 'unsplash_change_picture_feedback_label' }" size="medium" @click.native="noPictureToChoose"  />
            </div>
        </div>
    </div>
</template>

<script>


import CloseButton from "Images/close-24x24.svg";
import { EventBus } from "Util/vue-event-bus";
import VButton from "ViewComponent/v-button.vue";
import UserManager from "Modules/usermanager";
import i18nUtils from "Util/i18n.js";
import { TYPE as TOAST_TYPE } from "../../toaster/constants.js";

export default {
    name: 'card-visual-modal',
    components: {
        VButton,
        CloseButton
    },
    props: {
        question: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isOpen: false,
            images_data: null,
            IMAGES_COUNT_IN_GRID: 9
        }
    },
    computed: {
        images() {
            let _images = this.images_data.slice(0, this.IMAGES_COUNT_IN_GRID);

            // if there are no 9 (IMAGES_COUNT_IN_GRID) images, add empty grid elements
            if (_images.length < this.IMAGES_COUNT_IN_GRID) {
                const elementsNeeded = this.IMAGES_COUNT_IN_GRID - _images.length;
                for (let i = 0; i < elementsNeeded; i++) {
                    _images.push({ empty: true});
                }
            }
            return _images;
        }
    },
    methods: {
        open(images_data) {
            if (images_data && images_data.length > 0) {
                this.images_data = images_data;
                document.body.classList.add('modal-open');
                this.isOpen = true;
            }
        },
        getStyle(image) {
            if (image && image.urls && image.urls.small) {
                return { backgroundImage: 'url(' + image.urls.small + ')' };
            } else {
                return { backgroundColor: '#F5F5F5' }
            }
        },
        close() {
            this.isOpen = false;
            this.images_data = null;
            document.body.classList.remove('modal-open');
        },
        keyDown(event) {
            if (event && event.keyCode === 27) { // close on ESC key
                this.close()
            }
        },
        changeChosenVisual(visual) {
            if (!visual.empty) {
                this.$emit('visual-chosen', visual);
                this.close();
            }
        },
        noPictureToChoose() {
            if (this.question && this.question.uuid) {
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('guess-visuals-gallery', 'no-suitable-image', this.question.uuid);
                const toast = {
                    text: i18nUtils.prop('card_feedback_tooltip_confirmation'),
                    type: TOAST_TYPE.SUCCESS
                };
                EventBus.$emit('toaster-add', toast);
                this.$emit('visual-chosen', { enabled: false });
                this.close();
            }
        },
        listenEventBus() {
            EventBus.$on('guess:card-visual-modal-open', this.open);
            EventBus.$on('guess:card-visual-modal-close', this.close);
        },
        unListenEventBus() {
            EventBus.$off('guess:card-visual-modal-open', this.open);
            EventBus.$off('guess:card-visual-modal-close', this.close);
        }
    },
    created() {
        this.listenEventBus();
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
    },
    beforeDestroy() {
        this.unListenEventBus();
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
    }
}
</script>

<style lang="scss">
@import '~Styles/mixins';
@import "~Styles/colors";

div.card-visual-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(43, 81, 142, 0.80);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    > div.card-visual-modal-window {
        position: relative;
        @include elevated;
        background-color: #fff;
        border-radius: pxToRem(12);
        min-width: pxToRem(500);
        min-height: pxToRem(400);
        display: flex;
        flex-direction: column;
        max-width: 40vw;
        box-sizing: border-box;
        padding: pxToRem(24);

        @include respond-to('small') {
            min-width: 100vw;
            min-height: unset;
            border-radius: unset;
        }

        > svg.close {
            cursor: pointer;
            position: absolute;
            right: pxToRem(24);
            top: pxToRem(24);
            transition: 100ms ease-in-out;
            body[data-interface-language="ar"] & {
                right: unset;
                left: pxToRem(24);
            }

            &:hover {
                transform: scale(1.2);
            }
        }
        > h2.title {
            text-align: left;
            font-size: fontSize(18);
        }

        > div.content {
            margin-top: pxToRem(24);
            > div.visuals {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(3, 1fr);
                grid-gap: 1.5rem;
                @include respond-to('small') {
                    grid-gap: 1rem;
                }
                > div.visual {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 8.2rem;
                    min-width: 8.2rem;
                    min-height: 8.2rem;
                    background-color: $colorBackgroundGray;
                    border-radius: pxToRem(8);
                    justify-self: center;
                    @include respond-to('small') {
                        width: 6rem;
                        min-width: 6rem;
                        min-height: 6rem;
                    }
                    @include respond-to('tiny') {
                        width: 5rem;
                        min-width: 5rem;
                        min-height: 5rem;
                    }
                    &.selected {
                        box-shadow: 0 0 0 pxToRem(8) $colorBackgroundDarker;
                    }
                    &:hover:not(.empty) {
                        cursor: pointer;
                        box-shadow: 0 0 0 pxToRem(8) rgba($colorBackgroundDarker, 0.5);
                    }
                }
            }
            button {
                display: block;
                margin: 1.5rem auto 0;
            }
        }
    }
}
</style>
