var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "add-more_svg__add_more_svg__ic-add-more-paper",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass: "add-more_svg__add_more_svg__ic-add-more-paper__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass:
                "add-more_svg__add_more_svg__ic-add-more-paper__icon",
              attrs: { fill: "#2C3143", "fill-rule": "nonzero" },
            },
            [
              _c(
                "g",
                {
                  staticClass:
                    "add-more_svg__add_more_svg__ic-add-more-paper__icon__group",
                },
                [
                  _c("path", {
                    staticClass:
                      "add-more_svg__add_more_svg__ic-add-more-paper__icon__group__path-19",
                    attrs: {
                      d: "M17 20a1 1 0 01-1.993.117L15 20v-8a1 1 0 011.993-.117L17 12v8z",
                    },
                  }),
                ]
              ),
              _c("path", {
                staticClass:
                  "add-more_svg__add_more_svg__ic-add-more-paper__icon__path",
                attrs: {
                  d: "M19.836 15.253a1 1 0 011.417 1.405l-.089.09-4.5 4a1 1 0 01-1.23.077l-.098-.078-4.5-4a1 1 0 011.23-1.572l.098.078L16 18.66l3.836-3.408z",
                },
              }),
              _c("path", {
                staticClass:
                  "add-more_svg__add_more_svg__ic-add-more-paper__icon__group__path-19",
                attrs: {
                  d: "M20 3a1 1 0 01.117 1.993L20 5H4a1 1 0 01-.117-1.993L4 3h16zm0 4a1 1 0 01.117 1.993L20 9H4a1 1 0 01-.117-1.993L4 7h16zm-10 4a1 1 0 01.117 1.993L10 13H4a1 1 0 01-.117-1.993L4 11h6z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }