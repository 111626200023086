var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M15 6.5V5H9v6.335a2.4 2.4 0 01-.342 1.235l-3.477 5.795C4.22 19.965 5.373 22 7.239 22h9.202c1.826 0 2.983-1.957 2.103-3.557l-3.247-5.903A2.4 2.4 0 0115 11.384V11M9 9h3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 3.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: { d: "M10 2a1 1 0 11-2 0 1 1 0 012 0z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M7.5 5h9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.554 15.5a1 1 0 00-.848.47l-1.25 2a1 1 0 00.848 1.53h5.392a1 1 0 00.848-1.53l-1.25-2a1 1 0 00-.848-.47h-2.892z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }