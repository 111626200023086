
'use strict';

import $ from 'jquery';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';

import SurveyView from '../view/survey.view.js';
import { EventBus } from "../util/vue-event-bus.js";

const _25_CARD_SURVEY_TYPE = '25-card';

export class SurveyController {
    constructor () {
        EventBus.$on('statistics-updated', async () => {
            await this._statisticsUpdated();
        });
    }

    show (type, url) {
        this._renderSurvey(type, url);
    }

    _renderSurvey (type, url) {
        var user = UserManager.instance.getUser();
        let surveyView = new SurveyView({
            data: {
                surveyUrl: `${url}?${$.param({user_uuid: user.UUID, type: type})}`
            },
        });
        surveyView.render();
        return surveyView.show(); // Returning a promise
    }

    _statisticsUpdated () {
        if (UserManager.instance.getUser().getCourse().getStatistics().getData().all_units.total === 25) {
            let survey = UserManager.instance.getUser().getCourse().getSurvey(_25_CARD_SURVEY_TYPE);
            if (survey !== null) {
                return this._renderSurvey(_25_CARD_SURVEY_TYPE, survey);
            }
        }
    }
}

export const survey_controller_factory = new ControllerFactory(SurveyController);
