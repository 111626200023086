var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M0 34a2 2 0 002 2h28a2 2 0 100-4H6a2 2 0 01-2-2V15a2 2 0 10-4 0v19z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36 24.125V31a1 1 0 01-1 1H5a1 1 0 01-1-1v-6.875M26 9V6a1 1 0 00-1-1H15a1 1 0 00-1 1v3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36 20.538V24H4V10a1 1 0 011-1h30a1 1 0 011 1v2.461M29 26v-4M11 26v-4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }