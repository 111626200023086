var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "questionnaire" },
    [
      _c("nav", [
        _c(
          "div",
          { staticClass: "back-button" },
          [
            _c("arrow-icon", {
              staticClass: "navigate-back",
              nativeOn: {
                click: function ($event) {
                  return _vm.navigate_back.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "skip-button" }, [
          _c("div", {
            staticClass: "skip",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(
                  "demographic_registration_question_why_learn_language_skip"
                )
              ),
            },
            on: { click: _vm.skipQuestionnaire },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("question", {
        staticClass: "questions",
        attrs: { question: _vm.questionsAnswers[_vm.questionIndex] },
        on: { "answer-given": _vm.answerReceived },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }