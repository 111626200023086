<template>
    <div class="faq">
        <div class="questions">
            <h2 v-html="this.$i18n('trial_opt_in_faq_title_0_web')" />
            <div class="questions-list">
                <div class="question" v-for="question in questions" @click="toggle(question)" :class="{ open: question.open }">
                    <arrow-icon class="toggle-icon" />
                    <h3 v-html="$i18n(question.title)" />
                    <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                        <ul v-show="question.open">
                            <li v-if="question.text" v-html="$i18n(question.text)" />
                            <template v-else-if="question.collection && question.collection.items">
                                <li v-for="item in question.collection.items" v-html="$i18n(item, { collection: question.collection.id })" />
                            </template>
                        </ul>
                    </transition>
                </div>
            </div>
        </div>
        <div class="more-questions">
            <h2 v-html="this.$i18n('more_questions_title')" />
            <faq-light-icon />
            <a :href="`mailto: ${support_email}`" v-html="this.$i18n('more_questions_faq_text_2', { args: { support_email }})" />
        </div>
    </div>
</template>

<script>
    import FaqLightIcon from 'Images/pay/faq-light.svg';
    import ArrowIcon from 'Images/arrow-3.svg';
    import getConfigValue from 'Util/configuration.js';

    export default {
        name: 'faq',
        components: { FaqLightIcon, ArrowIcon },
        data() {
            return {
                questions: [
                    {
                        open: false,
                        title: 'trial_opt_in_faq_title_5_web',
                        text: 'trial_opt_in_faq_text_5_web'
                    },
                    {
                        open: false,
                        title: 'trial_opt_in_faq_title_6_web',
                        collection: {
                            id: 'trial_opt_in_faq_text_6_web',
                            items: ['a', 'b', 'c']
                        }
                    },
                    {
                        open: false,
                        title: 'trial_opt_in_faq_title_1_web',
                        collection: {
                            id: 'trial_opt_in_faq_text_1_web',
                            items: ['a', 'b']
                        }
                    },
                    {
                        open: false,
                        title: 'trial_opt_in_faq_title_3_web',
                        collection: {
                            id: 'trial_opt_in_faq_text_3_web',
                            items: ['a', 'b', 'c']
                        }
                    },
                    {
                        open: false,
                        title: 'trial_opt_in_faq_title_7_web',
                        text: 'trial_opt_in_faq_text_7_web'
                    }
                ]
            }
        },
        computed: {
            support_email () {
                return getConfigValue('support_email');
            }
        },
        methods: {
            toggle(question) {
                question.open = !question.open;
            },
            enter(el) {
                el.style.height = 'auto';
                const height = getComputedStyle(el).height;
                el.style.height = 0;
                getComputedStyle(el);
                setTimeout(() => {
                    el.style.height = height;
                });
            },
            afterEnter(el) {
                el.style.height = 'auto';
            },
            leave(el) {
                el.style.height = getComputedStyle(el).height;
                getComputedStyle(el);
                setTimeout(() => {
                    el.style.height = 0;
                });
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.faq {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 1rem;
        > div.questions {
            border-radius: .5rem;
            background-color: #fff;
            padding: 2rem;
            min-width: pxToRem(620);
            max-width: pxToRem(620);
            @include respond-to('small') {
                min-width: unset;
                max-width: unset;
                width: 100%;
            }
            > h2 {
                margin-bottom: 1rem;
            }
            > div.questions-list {
                > div.question {
                    position: relative;
                    margin-top: 1rem;
                    user-select: none;
                    padding-right: 2rem;
                    &:first-child {
                        margin-top: 0;
                    }
                    > svg.toggle-icon {
                        position: absolute;
                        top: .25rem;
                        right: 0;
                        transform: rotate(90deg);
                    }
                    > h3 {
                        font-size: fontSize(16);
                        font-weight: $font-bold;
                    }
                    > ul {
                        > li {
                            margin-left: 1rem;
                            margin-top: .5rem;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    &.open {
                        > svg.toggle-icon {
                            transform: rotate(270deg);
                        }
                    }
                }
            }
        }
        > div.more-questions {
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            > svg {
                margin: 1.5rem;
            }
        }

        .expand-enter-active, .expand-leave-active {
            transition: height 250ms ease-in-out;
            overflow: hidden;
        }
    }
</style>
