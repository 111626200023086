var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "48",
          height: "32",
          viewBox: "0 0 48 32",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M0 11.055A3.055 3.055 0 013.055 8H6.5l-.386.515a2.624 2.624 0 00-.084 3.03c.587.88.587 2.03 0 2.91L6 14.5a2.704 2.704 0 000 3 2.725 2.725 0 01-.008 3.012 2.786 2.786 0 00-.046 3.003c.563.902.57 2.058.006 2.96L5 28h35v.945A3.055 3.055 0 0136.945 32H3.055A3.055 3.055 0 010 28.945v-17.89z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5 4V3a1 1 0 00-.832 1.555L5 4zm40 0l.832.555A1 1 0 0045 3v1zm0 24v1a1 1 0 00.832-1.555L45 28zM5 28l-.832-.555A1 1 0 005 29v-1zm9.23-25a1 1 0 100 2V3zM8.463 5a1 1 0 000-2v2zM5 16l-.832-.555a1 1 0 000 1.11L5 16zm0 6l-.832-.555a1 1 0 000 1.11L5 22zm0-12l-.832-.555a1 1 0 000 1.11L5 10zm2 3l.832.555a1 1 0 000-1.11L7 13zm0-6l.832.555a1 1 0 000-1.11L7 7zm0 12l.832.555a1 1 0 000-1.11L7 19zm0 6l.832.555a1 1 0 000-1.11L7 25zm38-9l.832.555a1 1 0 000-1.11L45 16zm0-6l.832.555a1 1 0 000-1.11L45 10zm0 12l.832.555a1 1 0 000-1.11L45 22zm-2 3l-.832-.555a1 1 0 000 1.11L43 25zm0-6l-.832-.555a1 1 0 000 1.11L43 19zm0-6l-.832-.555a1 1 0 000 1.11L43 13zm0-6l-.832-.555a1 1 0 000 1.11L43 7zM14.23 5H45V3H14.23v2zM5 5h3.462V3H5v2zm9.23 22H5v2h9.23v-2zM5.833 16.555l2-3-1.664-1.11-2 3 1.664 1.11zm2-4.11l-2-3-1.664 1.11 2 3 1.664-1.11zm-2-1.89l2-3-1.664-1.11-2 3 1.664 1.11zm2-4.11l-2-3-1.664 1.11 2 3 1.664-1.11zm-2 16.11l2-3-1.664-1.11-2 3 1.664 1.11zm2-4.11l-2-3-1.664 1.11 2 3 1.664-1.11zm-2 10.11l2-3-1.664-1.11-2 3 1.664 1.11zm2-4.11l-2-3-1.664 1.11 2 3 1.664-1.11zm36.336-3l-2 3 1.664 1.11 2-3-1.664-1.11zm-2 4.11l2 3 1.664-1.11-2-3-1.664 1.11zm2-10.11l-2 3 1.664 1.11 2-3-1.664-1.11zm-2 4.11l2 3 1.664-1.11-2-3-1.664 1.11zm2-10.11l-2 3 1.664 1.11 2-3-1.664-1.11zm-2 4.11l2 3 1.664-1.11-2-3-1.664 1.11zm2-10.11l-2 3 1.664 1.11 2-3-1.664-1.11zm-2 4.11l2 3 1.664-1.11-2-3-1.664 1.11zM38.846 27H14.231v2h24.615v-2zM45 27h-6.154v2H45v-2z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14 4v24",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-dasharray": "2.29 4.58",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "24",
          cy: "11",
          r: "3",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "34",
          cy: "21",
          r: "3",
          stroke: "#2C3143",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24 23L34 9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }