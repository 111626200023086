
import getConfigValue from '../../util/configuration.js';

import { SigninPopup } from './popup.js';

const Facebook = {

    showFacebookLogin: async function (emailHint) {
        let redirect_uri = `${window.location.protocol}//${window.location.host}/oauth.html?provider=facebook`;
        let url = `https://www.facebook.com/v7.0/dialog/oauth` +
                  `?client_id=${encodeURIComponent(getConfigValue('facebook-app-id'))}` +
                  `&response_type=code` +
                  `&scope=email` +
                  `&display=popup` +
                  `&redirect_uri=${encodeURIComponent(redirect_uri)}` +
                  `&login_hint=${encodeURIComponent(emailHint)}`;

        return await (new SigninPopup(redirect_uri).open(url));
    }
};

export default Facebook;

