var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9 9l2.246-6.363c.25-.711 1.257-.711 1.508 0L15 9h6.687c.76 0 1.092.96.494 1.43L17 14.5l2.239 6.343c.252.714-.537 1.346-1.179.945L12 18l-6.06 3.788c-.642.4-1.43-.231-1.179-.945L7 14.5l-5.181-4.07C1.22 9.96 1.553 9 2.313 9H9z",
          fill: "#FAAA5A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }