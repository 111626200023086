<template>
    <div class="lesson-intro" :class="{ 'disabled': this.interactionDisabled }">
        <div class="lesson-intro-content">
            <intro-illustration />
            <div class="text">
                <!-- <intro-arrow class="intro-arrow "/> -->
                <h3 v-html="this.$i18n('lessons_creator_editor_intro_title')" />
                <p v-html="this.$i18n('lessons_creator_editor_intro_text')" />
                <v-button @click.native="openTutorial" size="medium" :filled="true" color="purple" :i18n="{ id: 'lesson_creator_editor_see_tutorial_button' }" />
            </div>
            <upload-file v-bind:lesson_uuid="this.lesson_uuid" v-on:text-from-file="textFromFileArrived" v-on:ocr-done="ocrDone" />
        </div>
    </div>
</template>

<script>
    import VButton from 'ViewComponent/v-button.vue';
    import IntroIllustration from 'Images/course-wizard/intro-illustration.svg';
    import IntroArrow from 'Images/course-wizard/intro-arrow.svg';
    import UploadFile from './upload-file.vue';
    import { EventBus } from 'Util/vue-event-bus';

    export default {
        name: 'lesson-intro',
        components: {
            VButton,
            UploadFile,
            IntroIllustration,
            IntroArrow
        },
        props: {
            lesson_uuid: {
                type: String
            },
            interactionDisabled: {
                type: Boolean
            },
        },
        methods: {
            textFromFileArrived(text) {
                this.$emit('add-cards', { seed_text: text, intent: 'text' });
            },
            ocrDone(info) {
                const { file_uuid: seed_file } = info;
                this.$emit('add-cards', { seed_file, intent: 'text' });
            },
            openTutorial() {
                EventBus.$emit('cw:open-tutorial-modal');
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.lesson-intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        &.disabled {
            pointer-events: none;
            opacity: 0.6;
            filter: grayscale(100%);
        }

        > div.lesson-intro-content {
            margin: 2rem;
            text-align: center;
            max-width: pxToRem(600);
            > div.text {
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                > h3 {
                    font-size: fontSize(16);
                    line-height: fontSize(20);
                    margin-bottom: 1rem;
                }
                > button {
                    margin-top: 1rem;
                }
                > svg.intro-arrow {
                    position: absolute;
                    left: -3rem;
                    top: 1.3rem;
                }
            }
        }
    }
</style>
