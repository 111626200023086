<template>
    <div class="toast" v-if="toast" :data-type="toast.type">
        <div class="icon" v-if="toast.type">
            <tick-icon v-if="toast.type === TYPE.SUCCESS" width="20" height="20" />
            <error-icon v-if="toast.type === TYPE.ERROR" width="20" height="20" />
            <alert-icon v-if="toast.type === TYPE.ALERT" width="24" height="24" />
            <incomplete-icon v-if="toast.type === TYPE.INCOMPLETE" width="24" height="24" />
            <mic-icon v-if="toast.type === TYPE.MICROPHONE" width="24" height="24" />
        </div>
        <div class="text">{{ toast.text }}</div>
    </div>
</template>

<script>
    import TickIcon from 'Images/tick.svg';
    import AlertIcon from 'Images/alert-white-24x24.svg';
    import ErrorIcon from 'Images/account/input-error.svg';
    import IncompleteIcon from 'Images/course-wizard/card-status/status-incomplete.svg';
    import MicIcon from 'Images/mic-24x24-dark.svg';
    import { TYPE } from './constants.js';

    export default {
        name: 'toast',
        props: {
            toast: {
                type: Object,
                required: true
            },
        },
        data () {
            return {
                TYPE
            }
        },
        components: {
            TickIcon,
            ErrorIcon,
            AlertIcon,
            IncompleteIcon,
            MicIcon
        }
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    div.toast {
        background-color: $colorBackgroundDarkLighter;
        color: #fff;
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;

        > div.icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: .5rem;
        }
    }
</style>
