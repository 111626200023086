var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "language-not-listed" },
    [
      _vm.eventSent
        ? _c(
            "div",
            { staticClass: "thanks" },
            [
              _c("thank-you-illustration"),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("feedback_doorslam_confirmation")
                  ),
                },
              }),
            ],
            1
          )
        : [
            _vm.showTitle
              ? _c("h1", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("register_language_not_listed_title")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("register_language_not_listed_description")
                ),
              },
            }),
            _vm._v(" "),
            _vm.allLanguages
              ? _c("input-search-dropdown", {
                  attrs: {
                    options: _vm.allLanguages,
                    "pre-selected-id": _vm.selectedTargetLanguageCode,
                    name: "target_language",
                    maxItem: 50,
                    placeholder: _vm.targetLangPlaceholder,
                  },
                  on: { selected: _vm.targetLangSelected },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [_c("language-unknown-icon")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    360095858
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allLanguages
              ? _c("input-search-dropdown", {
                  attrs: {
                    options: _vm.allLanguages,
                    name: "source_language",
                    maxItem: 50,
                    placeholder: _vm.sourceLangPlaceholder,
                  },
                  on: { selected: _vm.sourceLangSelected },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [_c("language-unknown-icon")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    360095858
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-container" },
              [
                _c("account-email-icon"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  ref: "email_input",
                  staticClass: "email",
                  class: { error: !this.emailIsValid },
                  attrs: {
                    type: "email",
                    placeholder: this.$i18n(
                      "register_language_not_listed_email_textbox"
                    ),
                    autocomplete: "email",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !this.emailIsValid
              ? _c("p", {
                  staticClass: "error",
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("register_error_invalid_email")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass: "footnote",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("register_language_not_listed_email_description")
                ),
              },
            }),
            _vm._v(" "),
            _c("v-button", {
              attrs: {
                i18n: { id: "register_language_not_listed_send_btn" },
                filled: true,
                disabled: !_vm.fieldsAreFilled,
                color: "primary",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.sendRequest.apply(null, arguments)
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }