var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M4 15h3M9 4v3M20 9h-3M15 20v-3M3 12h4M12 3v4M21 12h-4M12 21.053V17M4 9h3M15 4v3M20 15h-3M9 20v-3",
          stroke: "#63E1E7",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 9.6a.6.6 0 01.6-.6h4.8a.6.6 0 01.6.6v4.8a.6.6 0 01-.6.6H9.6a.6.6 0 01-.6-.6V9.6z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 8.25C7 7.56 7.56 7 8.25 7h7.5c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25h-7.5C7.56 17 7 16.44 7 15.75v-7.5z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }