var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "faq" }, [
    _c("div", { staticClass: "questions" }, [
      _c("h2", {
        domProps: {
          innerHTML: _vm._s(this.$i18n("trial_opt_in_faq_title_0_web")),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "questions-list" },
        _vm._l(_vm.questions, function (question) {
          return _c(
            "div",
            {
              staticClass: "question",
              class: { open: question.open },
              on: {
                click: function ($event) {
                  return _vm.toggle(question)
                },
              },
            },
            [
              _c("arrow-icon", { staticClass: "toggle-icon" }),
              _vm._v(" "),
              _c("h3", {
                domProps: { innerHTML: _vm._s(_vm.$i18n(question.title)) },
              }),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: { name: "expand" },
                  on: {
                    enter: _vm.enter,
                    "after-enter": _vm.afterEnter,
                    leave: _vm.leave,
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: question.open,
                          expression: "question.open",
                        },
                      ],
                    },
                    [
                      question.text
                        ? _c("li", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$i18n(question.text)),
                            },
                          })
                        : question.collection && question.collection.items
                        ? _vm._l(question.collection.items, function (item) {
                            return _c("li", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$i18n(item, {
                                    collection: question.collection.id,
                                  })
                                ),
                              },
                            })
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "more-questions" },
      [
        _c("h2", {
          domProps: { innerHTML: _vm._s(this.$i18n("more_questions_title")) },
        }),
        _vm._v(" "),
        _c("faq-light-icon"),
        _vm._v(" "),
        _c("a", {
          attrs: { href: `mailto: ${_vm.support_email}` },
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("more_questions_faq_text_2", {
                args: { support_email: _vm.support_email },
              })
            ),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }