<template>
    <div class="success">
        <order-success-icon />
        <div class="message">
            <h2 v-html="this.$i18n('pay_success_page_order')" />
            <p v-if="showReceiptInfo" v-html="this.$i18n('pay_success_page_receipt')" />
        </div>
        <v-button :i18n="{ id: 'pay_success_page_continue_learn' }" route="guess" :filled="true" color="primary" />
    </div>
</template>

<script>
    import OrderSuccessIcon from "Images/pay/illustrations/payment_successful.svg";
    import VButton from "ViewComponent/v-button.vue";
    export default {
        name: 'success',
        props: {
            showReceiptInfo: {
                type: Boolean,
                default: true
            },
        },
        components: {
            VButton,
            OrderSuccessIcon
        }
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.success {
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.message {
            text-align: center;
            margin: 2rem 1rem;
            font-size: fontSize(18);
            > h2 {
                margin-bottom: .5rem;
                font-weight: $font-bold;
            }
        }
    }
</style>
