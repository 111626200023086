<template>
    <div class="today grid-item" v-if="dateData && goalStatus && title && subtitle && message">
        <div class="today-goals">
            <div class="icon">
                <goal-status-icon :size="40" :status="goalStatus" />
            </div>
            <div class="info">
                <div class="title">{{ title }}</div>
                <p class="text">{{ subtitle }}</p>
                <p class="desc">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import i18nUtils from "Util/i18n";
    import moment from "moment";
    import GoalStatusIcon from "ViewComponent/goal-status-icon.vue";
    import { Awards, AWARD_NAME, DYNAMIC_GOAL_RECOMMENDED_SIZE } from "Modules/awards.js";
    import { EventBus } from "Util/vue-event-bus.js";

    export default {
        name: 'today-goals',
        components: {
            GoalStatusIcon
        },
        props: {
            course: {
                type: Object,
                required: true
            },
            day: {
                type: Object,
                default() {
                    return moment().local().startOf('day')
                }
            }
        },
        data() {
            return {
                dateData: null,
                goalStatus: null,
                title: null,
                subtitle: null,
                message: null,
                dynamicGoalSize: Awards.getDynamicGoalSize()
            }
        },
        methods: {
            setDynamicGoalSize(size) {
                this.dynamicGoalSize = size;
            },
            dateGoalAwardsCount () {
                if (this.dateData && this.dateData.awards_objects) {
                    return this.dateData.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE).length;
                } else {
                    return 0;
                }
            },
            combineData () {
                const statistics = (this.course) ? this.course.getStatistics() : null;
                if (!statistics) {
                    return;
                }

                this.dateData = (statistics && this.day) ? statistics.getDateData(this.day) : null;

                let texts = null;
                const isToday = this.day.isSame(moment().startOf('day'));
                const cards = this.dateData.all_units.total;
                const goalAwardsCount = this.dateGoalAwardsCount();

                if (goalAwardsCount === 0) {
                    if (cards === 0) {
                        this.goalStatus = 'none';
                        texts = this.zeroCardsTexts(!isToday, cards, this.dynamicGoalSize);

                    } else {
                        this.goalStatus = 'progress';
                        texts = this.goalStartedTexts(!isToday, cards, this.dynamicGoalSize);
                    }
                } else {
                    const recommendedGoalSizeBegin = DYNAMIC_GOAL_RECOMMENDED_SIZE;
                    const recommendedGoalSizeEnd = recommendedGoalSizeBegin + 9;
                    const streakDay = Awards.getStreakToday();
                    this.goalStatus = 'done';

                    if (cards < recommendedGoalSizeBegin) {
                        texts = this.smallGoalCompletedTexts(!isToday, cards, streakDay);
                    } else if (cards > recommendedGoalSizeEnd) {
                        texts = this.largeGoalCompletedTexts(!isToday, cards, streakDay);
                    } else {
                        texts = this.recommendedGoalCompletedTexts(!isToday, cards, streakDay);
                    }
                }

                if (texts) {
                    this.title = texts.title;
                    this.subtitle = texts.subtitle;
                    this.message = texts.message;
                }
            },
            zeroCardsTexts (isPast, cards, goalSize) {
                let texts = { title: null, subtitle: null, message: null };

                if (isPast) {
                    texts.title = i18nUtils.prop('dynamic_goals_message_zero_past_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_zero_past_subtitle');
                    texts.message = i18nUtils.prop('dynamic_goals_message_zero_past_message');
                } else {
                    texts.title = i18nUtils.prop('dynamic_goals_message_zero_present_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_zero_present_subtitle', { daily_cards_goal: goalSize });
                    texts.message = i18nUtils.prop('dynamic_goals_message_zero_present_message', { cards: cards, daily_cards_goal: goalSize });
                }

                return texts;
            },
            goalStartedTexts (isPast, cards, goalSize) {
                let texts = { title: null, subtitle: null, message: null };

                if (isPast) {
                    texts.title = i18nUtils.prop('dynamic_goals_message_started_past_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_started_past_subtitle');
                    texts.message = i18nUtils.prop('dynamic_goals_message_started_past_message', { cards: cards });
                } else {
                    texts.title = i18nUtils.prop('dynamic_goals_message_started_present_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_started_present_subtitle', { daily_cards_goal: goalSize });
                    texts.message = i18nUtils.prop('dynamic_goals_message_started_present_message', { cards: cards, daily_cards_goal: goalSize });
                }

                return texts;
            },
            smallGoalCompletedTexts (isPast, cards, streakDay) {
                let texts = { title: null, subtitle: null, message: null };

                let _getPresentMessage = function (_streakDay) {
                    switch (_streakDay) {
                        case 1:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day1');
                        case 2:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day2');
                        case 3:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day3');
                        case 4:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day4');
                        case 5:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day5');
                        case 6:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day6');
                        case 7:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_day7');
                        default:
                            return i18nUtils.prop('dynamic_goals_message_small_present_message_no_streak');
                    }
                }

                if (isPast) {
                    texts.title = i18nUtils.prop('dynamic_goals_message_small_past_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_small_past_subtitle', { cards: cards });
                    texts.message = i18nUtils.prop('dynamic_goals_message_small_past_message');
                } else {
                    texts.title = i18nUtils.prop('dynamic_goals_message_small_present_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_small_present_subtitle', { cards: cards });
                    texts.message = _getPresentMessage(streakDay);
                }

                return texts;
            },
            largeGoalCompletedTexts (isPast, cards, streakDay) {
                let texts = { title: null, subtitle: null, message: null };
                let _getPresentMessage = function (_streakDay) {
                    switch (_streakDay) {
                        case 1:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day1');
                        case 2:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day2');
                        case 3:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day3');
                        case 4:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day4');
                        case 5:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day5');
                        case 6:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day6');
                        case 7:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_day7');
                        default:
                            return i18nUtils.prop('dynamic_goals_message_large_present_message_no_streak');
                    }
                }

                if (isPast) {
                    texts.title = i18nUtils.prop('dynamic_goals_message_large_past_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_large_past_subtitle', { cards: cards });
                    texts.message = i18nUtils.prop('dynamic_goals_message_large_past_message');
                } else {
                    texts.title = i18nUtils.prop('dynamic_goals_message_large_present_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_large_present_subtitle', { cards: cards });
                    texts.message = _getPresentMessage(streakDay);
                }

                return texts;
            },
            recommendedGoalCompletedTexts (isPast, cards, streakDay) {
                let texts = { title: null, subtitle: null, message: null };
                let _getPresentMessage = function (_streakDay) {
                    switch (_streakDay) {
                        case 1:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day1');
                        case 2:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day2');
                        case 3:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day3');
                        case 4:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day4');
                        case 5:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day5');
                        case 6:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day6');
                        case 7:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_day7');
                        default:
                            return i18nUtils.prop('dynamic_goals_message_recommended_present_message_no_streak');
                    }
                }

                if (isPast) {
                    texts.title = i18nUtils.prop('dynamic_goals_message_recommended_past_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_recommended_past_subtitle', { cards: cards });
                    texts.message = i18nUtils.prop('dynamic_goals_message_recommended_past_message');
                } else {
                    texts.title = i18nUtils.prop('dynamic_goals_message_recommended_present_title');
                    texts.subtitle = i18nUtils.prop('dynamic_goals_message_recommended_present_subtitle', { cards: cards });
                    texts.message = _getPresentMessage(streakDay);
                }

                return texts;
            },
            listenEventBus() {
                EventBus.$on('dynamic-goal:size-changed', this.setDynamicGoalSize);
            }
        },
        created() {
            if (this.course) {
                this.combineData();
                this.listenEventBus();
            }
        },
        beforeDestroy () {
            EventBus.$off('dynamic-goal:size-changed', this.setDynamicGoalSize);
        },
        watch: {
            course(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.combineData();
                }
            },
            day(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.combineData();
                }
            },
            dynamicGoalSize(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.combineData();
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~Styles/_mixins.scss';

    div.today {
        grid-area: today;
        > h2 {
            margin-bottom: 1rem;
        }
        > div.today-goals {
            display: flex;
            gap: .5rem;
            > div.info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                > div.title {
                    margin-bottom: pxToRem(4);
                    font-weight: $font-bold;
                }
                > p {
                    font-size: fontSize(14);
                    margin-bottom: pxToRem(4);
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
