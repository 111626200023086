
'use strict';

import _ from 'lodash';

export default class BackboneAugmentor {

    static augment (backbone) {
        backbone.View.extend = function(protoProps, staticProps) {
            var parent = this;
            var child;

            // The constructor function for the new subclass is either defined by you
            // (the "constructor" property in your `extend` definition), or defaulted
            // by us to simply call the parent constructor.
            if (protoProps && _.has(protoProps, 'constructor')) {
                child = protoProps.constructor;
            } else {
                child = function(){ return parent.apply(this, arguments); };
            }

            // Wrap the specified prototype functions to call their parents **before** the call
            ['initialize'].forEach(function (protoPropName) {
                if (protoProps && _.has(protoProps, protoPropName)) {
                    var protoProp = protoProps[protoPropName];

                    protoProps[protoPropName] = function () {
                        parent.prototype[protoPropName].apply(this, arguments);
                        return protoProp.apply(this, arguments);
                    };
                } // else {} // If the specified function is not provided then the version from the parent will be
                // taken, which is either already wrapped properly or is the base function
            });

            // Wrap the specified prototype functions to call their parents **after** the call
            // ['remove'].forEach(function (protoPropName) {
            //     if (protoProps && _.has(protoProps, protoPropName)) {
            //         var protoProp = protoProps[protoPropName];
            //
            //         protoProps[protoPropName] = function () {
            //             var self = this;
            //             return Promise.resolve().then(function () {
            //                 return protoProp.apply(self, arguments);
            //             }).then(function () {
            //                 return parent.prototype[protoPropName].apply(self, arguments);
            //             });
            //         };
            //     } // else {} // If the specified function is not provided then the version from the parent will be
            //     // taken, which is either already wrapped properly or is the base function
            // });

            // Add static properties to the constructor function, if supplied.
            _.extend(child, parent, staticProps);

            // Set the prototype chain to inherit from `parent`, without calling
            // `parent`'s constructor function and add the prototype properties.
            child.prototype = _.create(parent.prototype, protoProps);
            child.prototype.constructor = child;

            // Set a convenience property in case the parent's prototype is needed
            // later.
            child.__super__ = parent.prototype;

            return child;
        };
    }
}
