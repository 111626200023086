
'use strict';

import moment from 'moment';

var currentLoggedCorrection = null;

function absoluteChangeRatio (previous, current) {
    var diff = Math.abs(previous - current);

    if (previous > current) {
        return diff / Math.abs(previous);
    } else {
        return diff / Math.abs(current);
    }
}

export function setServerTs (serverTs) {
    /**
     * Uses the server timestamp to set the local time correction
     * */

    var initialTime = moment.utc(Date.now()),
        timeDifference = initialTime.diff(serverTs, 'milliseconds');

    moment.now = () => Date.now() - timeDifference;

    if (currentLoggedCorrection === null ||
        (absoluteChangeRatio(currentLoggedCorrection, timeDifference) > 0.1 && Math.max(Math.abs(timeDifference), Math.abs(currentLoggedCorrection)) > 1000)) {
        currentLoggedCorrection = timeDifference;
        console.info(`DatetimeUtils setServerTs: Time difference: ${timeDifference}ms `+
                     `Uncorrected client time: ${initialTime.toISOString()} ` +
                     `Corrected time: ${moment.utc().toISOString()}`);
    }
}

export function getDurationVars(duration) {
    const vars = {};
    try {
        const durationMoment = moment.duration(duration);

        const years = durationMoment.years();
        const months = durationMoment.months();
        const days = durationMoment.days();
        const hours = durationMoment.hours();
        const minutes = durationMoment.minutes();
        console.debug(`getDurationVars(): ${duration}, years: ${years}, months: ${months}, days: ${days}, hours: ${hours}, minutes: ${minutes}`);
        return {
            years, months, days, hours, minutes
        };
    } catch (error) {
        console.error(error);
    }
    return vars;
}

export function getTimezoneOffset () {
    return (new Date()).getTimezoneOffset() / 60;
}

export function getCurrentFormattedTs() {
    return moment().local().locale('en').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}

export default {
    setServerTs,
    getTimezoneOffset,
    getDurationVars
};
