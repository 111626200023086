<template>
    <div class="variation">
        <variation-icon @click.native="openVariationModal(variation)" :variation="variation" />
        <div class="name" @click="openVariationModal(variation)">{{ variation.name }}</div>
        <div class="stats" v-if="variation.stats">
            <div class="text" v-html="this.$i18n('variation_page_words_count', { args: {
                                       variation_words_total: variation.stats.user_total_words,
                                       variation_units: variation.units }})" />
            <progress-bar :maxWidth="120"
                         :primary="(variation.stats.user_remembered_words / variation.units) * 100"
                         :secondary="(variation.stats.user_total_words / variation.units) * 100" />
        </div>
        <div class="stats loading" v-else-if="variation.status !== 'initial'">
            <loader />
        </div>
        <div class="actions">
            <status-icon v-if="isGameOver || (variation.status === 'subscription_limited' && variation.type !== 'general')" type="locked" tooltip="disabled" />
            <status-icon type="complete" v-if="variation.status === 'complete'" tooltip="complete" />
            <more-dropdown :variation="variation"
                           :variation-toggle-disabled="variationToggleDisabled" />
        </div>
    </div>
</template>

<script>
    import { EventBus } from 'Util/vue-event-bus';
    import Loader from 'ViewComponent/loader.vue';
    import MoreDropdown from './more-dropdown.vue';
    import ProgressBar from './progress-bar.vue';
    import StatusIcon from './status-icon.vue';
    import VariationIcon from 'ViewComponent/variation-icon.vue';

    export default {
        props: {
            variation: {
                type: Object,
                required: true
            },
            variationToggleDisabled: {
                type: Boolean,
                default: false
            },
            isGameOver: {
                type: Boolean
            }
        },
        components: {
            Loader,
            MoreDropdown,
            ProgressBar,
            StatusIcon,
            VariationIcon
        },
        methods: {
            openVariationModal(variation) {
                EventBus.$emit('open-variation-modal', variation);
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";

    div.variation {
        > div.stats {
            > div.progress-bar {
                background-color: #fff;
            }
        }
        &:hover {
            > div.stats {
                > div.progress-bar {
                    background-color: $colorBackgroundLighter;
                }
            }
        }
    }
</style>
