<template>
    <div class="grammar-tag">
        <div class="header">
            <div class="title" v-html="this.$i18n('hub_grammar')" />
            <CloseButton @click="close" class="close" />
        </div>
        <div class="content">
            <card-grammar :parsed-grammar="this.question.parsed_grammar" :arrow="false" :full="true" />
            <div class="desc" v-if="iconDesc">
                {{ iconDesc }}
            </div>
            <div class="explanation" v-if="this.question.parsed_grammar.explanation" v-html="this.question.parsed_grammar.explanation" />
        </div>
    </div>
</template>

<script>
    import CloseButton from 'Images/close-24x24.svg';
    import CardGrammar from "./card-grammar.vue";
    export default {
        name: 'grammar-tag',
        components: {
            CardGrammar,
            CloseButton,
        },
        props: {
            question: {
                type: Object,
                required: true
            },
        },
        computed: {
            iconDesc() {
                if (this.question && this.question.parsed_grammar && this.question.parsed_grammar.icon) {
                    return this.question.parsed_grammar.icon.description;
                }
            }
        },
        methods: {
            close() {
                this.$emit('close');
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";
    div.grammar-tag {
        display: flex;
        height: auto;
        background-color: white;
        border-radius: pxToRem(12);
        flex-direction: column;

        > div.header {
            padding: 1rem;
            border-bottom: 1px solid $colorBackgroundDarker;
            display: flex;
            flex-direction: row;
            align-items: center;

            > div.title {
                background: #EEF0F4;
                border-radius: pxToRem(12);
                padding: .5rem 1rem;

                > tgt {
                    color: $colorTargetPrimary;
                    font-family: $targetLanguageFonts;
                }
            }

            > svg.close {
                cursor: pointer;
                transition: 100ms ease-in-out;
                margin-left: auto;
                body[data-interface-language="ar"] & {
                    margin-left: unset;
                    margin-right: auto;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        > div.content {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            > div.explanation {
                margin-top: 1rem;
                > tgt {
                    color: $colorTargetPrimary;
                    font-family: $targetLanguageFonts;
                }
            }
            > div.desc {
                margin-top: 1rem;
            }
        }
    }
</style>
