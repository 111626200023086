import i18nUtils from '../util/i18n.js';

const ACTION_STYLE = {
    DEFAULT: 'default',
    SECONDARY: 'secondary'
};

const ACTION = {
    OPEN: 'open',
    CLOSE: 'close',
    NEXT: 'next',
    CUSTOM: '_custom'
};

export class BlockedFeaturePopupUpsell {
    static data (variables) {
        return {
            heading: i18nUtils.getRawMessage('blocked_feature_popup_title'),
            illustration_id: 'blocked_feature',
            paragraphs: [
                i18nUtils.getRawMessage('blocked_feature_popup_text_1'),
                i18nUtils.getRawMessage('blocked_feature_popup_text_2'),
            ],
            actions: [
                {
                    title: i18nUtils.getRawMessage('blocked_feature_popup_button_free'),
                    action: ACTION.OPEN,
                    style: ACTION_STYLE.SECONDARY,
                    uri: 'lingvist:hub'
                },
                {
                    title: i18nUtils.getRawMessage('blocked_feature_popup_button_upgrade'),
                    action: ACTION.OPEN,
                    style: ACTION_STYLE.DEFAULT,
                    uri: variables.store_uri
                }
            ]
        };
    }
}

export class HardPaywallBlockedFeaturePopupUpsell {
    static data (variables) {
        if (variables.hasExpiredSubscription) {
            return {
                heading: i18nUtils.getRawMessage('subscription_expired_limit_pop_up_title'),
                illustration_id: 'blocked_feature',
                paragraphs: [
                    i18nUtils.getRawMessage('subscription_expired_limit_pop_up_text')
                ],
                actions: [
                    {
                        title: i18nUtils.getRawMessage('subscription_expired_limit_pop_up_btn'),
                        action: ACTION.OPEN,
                        style: ACTION_STYLE.DEFAULT,
                        uri: variables.store_uri
                    }
                ]
            };
        } else {
            return {
                heading: i18nUtils.getRawMessage('hard_paywall_limit_pop_up_title'),
                illustration_id: 'trial_end',
                paragraphs: [
                    i18nUtils.getRawMessage('hard_paywall_limit_pop_up_text')
                ],
                actions: [
                    {
                        title: i18nUtils.getRawMessage('hard_paywall_limit_pop_up_btn'),
                        action: ACTION.OPEN,
                        style: ACTION_STYLE.DEFAULT,
                        uri: variables.store_uri
                    }
                ]
            };
        }
    }
}

export class HardPaywallTrialEndPopup {
    static data (variables) {
        return {
            heading: i18nUtils.getRawMessage('hard_paywall_notice_title'),
            illustration_id: 'trial_end',
            paragraphs: [
                i18nUtils.getRawMessage('hard_paywall_notice_text')
            ],
            actions: [
                {
                    title: i18nUtils.getRawMessage('upsell_trial_end_action_upgrade'),
                    action: ACTION.OPEN,
                    style: ACTION_STYLE.DEFAULT,
                    uri: 'lingvist:store?catalog=unlimited'
                }
            ]
        };
    }
}

export class HardPaywallSubscriptionEndPopup {
    static data (variables) {
        return {
            heading: i18nUtils.getRawMessage('subscription_expired_pop_up_title'),
            illustration_id: 'blocked_feature',
            paragraphs: [
                i18nUtils.getRawMessage('subscription_expired_pop_up_text')
            ],
            actions: [
                {
                    title: i18nUtils.getRawMessage('subscription_expired_pop_up_btn'),
                    action: ACTION.OPEN,
                    style: ACTION_STYLE.DEFAULT,
                    uri: 'lingvist:store?catalog=unlimited'
                }
            ]
        };
    }
}

export class TrialAvailablePopup {
    static data () {
        return {
            heading: i18nUtils.getRawMessage('trial_available_popup_heading'),
            illustration_id: 'trial_available',
            paragraphs: [
                i18nUtils.getRawMessage('trial_available_popup_p1')
            ],
            actions: [
                {
                    title: i18nUtils.getRawMessage('trial_available_popup_button_start_trial'),
                    action: ACTION.CLOSE,
                    style: ACTION_STYLE.DEFAULT
                }
            ]
        };
    }
}

export class ShortCoursePopup {
    static data (variables) {
        return {
            heading: i18nUtils.prop('shorter_course_popup_title', { course_name: variables.name }),
            illustration_id: 'new_course',
            paragraphs: [
                i18nUtils.prop(1, { course_words_rounded: variables.course_words_rounded }, null, 'shorter_course_popup_body'),
                i18nUtils.prop(2, null, null, 'shorter_course_popup_body')
            ],
            actions: [
                {
                    title: i18nUtils.getRawMessage('shorter_course_popup_btn_continue'),
                    action: ACTION.CUSTOM,
                    style: ACTION_STYLE.DEFAULT
                }
            ]
        };
    }
}

export class ShortCoursePopupHub {
    static data (variables) {
        return {
            heading: i18nUtils.prop('shorter_course_popup2_title', { course_name: variables.name }),
            illustration_id: 'new_course',
            paragraphs: [
                i18nUtils.prop('shorter_course_popup2_body', { course_words_rounded: variables.course_words_rounded })
            ],
            actions: [
                {
                    title: i18nUtils.getRawMessage('shorter_course_popup2_btn_ok'),
                    action: ACTION.CLOSE,
                    style: ACTION_STYLE.DEFAULT
                }
            ]
        };
    }
}
