
import moment from 'moment';
import Raven from 'raven-js';

import { version } from '../version.js';

import getConfigValue from '../util/configuration.js';

import EventEmitter from 'events';

import Client from '../util/client.js';
import { getPersistentStorageProvider } from './persistent.storage.provider.js';
import EventSender from './eventsender.js';
import ApiClient from '@lingvist/api-client-js';

import { UserCourse } from './course/user.course.js';
import { Subscription } from './subscription.js';
import { PayAPICache } from './pay.api.cache.js';
import { UserClassrooms } from './classrooms/user.classrooms.js';
import { UserParameters, NAME as USER_PARAMETER, TYPE as PARAMETER_TYPE } from './user.parameters.js';
import { UserAuthentication } from './user/authentication.js';
import { UserTerms } from './user/terms.js';
import { UserLessons } from './lessons/user.lessons.js';
import * as DatetimeUtils from '../util/datetime.js';
import { setLeanplumUserAttributes } from '../util/leanplum.js';
import i18nUtils from '../util/i18n.js';
import OauthProviderModel from '../model/oauth-provider.model.js';
import ControllerManager from './controller.manager.js';

export const USER_FEATURE = {
    CLASSROOMS: 'classrooms',
    LESSONS: 'course_wizard'
};


export class UserNotInitializedError extends Error {
    constructor (message) {
        super(message);
        this.name = 'UserNotInitializedError';
    }
}

export class OAuthAuthorizationException extends Error {
    constructor (message) {
        super(message);
        this.name = 'OAuthAuthorizationException';
    }
}


export default class User extends EventEmitter.EventEmitter {
    constructor (uuid, authentication_token) {
        super();

        // Instance fields
        this.UUID = uuid; // TODO: De-capitalize UUID -> uuid
        this._registered = null;
        this.profile = {
            email: null,
            name: null,
            marketing_opt_in: null
        };

        this._sync_bookmark = null;
        this._course = null; // Holds reference to the current course
        this._courses = [];
        this._coursesLoaded = false;
        this._initialized = false;
        this._courseLearningProgress = null;

        this._features = [];

        // Sub-modules: Constructors must be passive, initialization, if any, is done in User.initialize().
        this._authentication = new UserAuthentication(this, authentication_token);
        this._pay_api_cache = new PayAPICache(this);
        this._apiClient = this.constructApiClient(authentication_token);
        this._client = new Client(this);
        this._subscription = new Subscription(this);
        this._classrooms = new UserClassrooms(this);
        this._lessons = new UserLessons(this);
        this._storage = getPersistentStorageProvider(this);
        this._terms = new UserTerms(this);
        this._eventSender = new EventSender(this);
        this._parameters = new UserParameters(this._apiClient);
    }

    constructApiClient(authentication_token) {
        const apiClient = ApiClient.getClient({
            token_authentication: authentication_token
        }, getConfigValue('api-url'), {
            clientType: 'web',
            clientVersion: version
        });

        apiClient.on('error-unauthorized', () => {  // HTTP 401
            this.emit('error-unauthorized');
        });

        return apiClient;
    }

    destroy () {
        this.removeAllListeners('error-unauthorized');

        return Promise.resolve().then(() =>  {
            let eventSender = this._eventSender;
            delete this._eventSender;
            return eventSender.destroy();
        }).then(() => {
            // Destroy all course objects
            let courses = this._courses;
            delete this._course;
            delete this._courses;
            return Promise.all(courses.map(c => c.destroy()));
        }).then(() => {
            let client = this._client;
            delete this._client;
            return client.destroy();
        }).then(() => {
            let terms = this._terms;
            delete this._terms;
            return terms.destroy();
        }).then(() => {
            let subscription = this._subscription;
            delete this._subscription;
            return subscription.destroy();
        }).then(() => {
            let classrooms = this._classrooms;
            delete this._classrooms;
            return classrooms.destroy();
        }).then(() => {
            let lessons = this._lessons;
            delete this._lessons;
            return lessons.destroy();
        }).then(() => {
            let parameters = this._parameters;
            delete this._parameters;
            return parameters.destroy();
        }).then(() => {
            let storage = this._storage;
            delete this._storage;
            return storage.destroy();
        }).then(() => {
            let apiClient = this._apiClient;
            delete this._apiClient;
            return apiClient.destroy();
        }).then(() => {
            let authentication = this._authentication;
            delete this._authentication;
            return authentication.destroy();
        }).then(() => {
            let pay_api_cache = this._pay_api_cache;
            delete this._pay_api_cache;
            return pay_api_cache.destroy();
        });
    }

    /**
     * @typedef {object} UserInfo
     *
     * @property uuid {string}
     * @property email {string}
     * @property name {string|null}
     * @property registered {moment}
     */

    get registered () {
        return this._registered.clone();
    }

    /**
     * Returns structured info about the user
     * @return {UserInfo}
     */
    getInfo () {
        return {
            uuid: this.UUID,
            email: this.profile.email,
            name: this.profile.name,
            registered: this.registered
        };
    }

    async sync() {
        console.log('user sync called');
        // TODO: Make sync happen every ~10minutes in addition to just on-demand
        try {
            const client_info = await this._client.getClientInfo();
            const response = await this.getApiClient().r.user.sync.post({
                client: client_info,
                user_state: this._sync_bookmark !== null ? {bookmark: this._sync_bookmark} : null
            }, { minorVersion: 7 });

            // TODO: UserStateManager?
            // TODO: Persist user state locally?

            const {
                meta: { bookmark, clean_state, server_ts },  // Everything other than "meta" is optional!
                user,
                profile,
                authentication,
                terms_and_conditions,
                features,  // Array of strings
                subscription,
                parameters
            } = response;

            this._sync_bookmark = bookmark;
            DatetimeUtils.setServerTs(moment.utc(server_ts));

            if (clean_state) {
                // TODO: Reset local state for all of the fields that could be in the response
                // Probably must be done synchronously so that other modules wouldn't request things in the meantime!!
            }

            let update_promises = [];

            if (user !== undefined) {
                this.UUID = user.uuid;
                this._registered = moment(user.registered_ts);
            }

            if (profile !== undefined) {
                this.profile.email = profile.email;
                this.profile.name = profile.name;
                this.profile.marketing_opt_in = profile.marketing_opt_in;
            }

            if (authentication !== undefined) {
                update_promises.push(this.getAuthentication().update(authentication));
            }

            if (terms_and_conditions !== undefined) {
                update_promises.push(this.getTerms().update(terms_and_conditions));
            }

            if (subscription !== undefined) {
                update_promises.push(this.getSubscription().updateSubscription(subscription));
            }

            if (parameters !== undefined) {
                update_promises.push(this.getParameters().updateParameters(parameters));
            }

            if (features !== undefined) {
                this._features = features;
            }

            await Promise.all(update_promises);

            const current_course_uuid = this.getParameters().getParameter(USER_PARAMETER.CURRENT_COURSE_UUID);

            if (!this.hasCourse() && current_course_uuid !== null) {
                try {
                    return await this.initializeCourses(current_course_uuid);
                } catch (error) {
                    console.log('APP: user sync initializeCourses error', error);
                    if (error === 'no-such-course') {
                        await this.enrolToCourse(current_course_uuid);
                    }
                    return; // not sure if necessary
                }
            } else if (this.hasCourse()) {
                return await this._course.syncCourse();
            } else {
                console.log('APP: has no course');
                return; // not sure if necessary
            }
        } catch (error) {
            console.error('APP: user sync error', error);
            // Handle any errors that occurred during sync
            // Depending on your needs, you might want to rethrow the error, return a default value, etc.
        }
    }


    async initialize () {
        console.info(`Initializing User uuid="${this.UUID}"`);

        // Asynchronously initialize the PayApi Cache
        Promise.resolve()
            .then(() => this._pay_api_cache.initialize())
            .catch(error => {
                Raven.captureException(error, {level: 'error'});
            });

        this._pay_api_cache.on('methods-updated', () => {
            console.log(`Syncing User after payment methods update`);
            this.sync();
        });

        this._initialized = true;
        this._eventSender.syncQueue();
        return await this.sync();
    }

    async initializeCourses (course_uuid) {
        const course = this._course || this._courses.find(c => c.UUID === course_uuid);
        if (course === undefined || !(course instanceof UserCourse)) {
            this._course = new UserCourse(this, {uuid: course_uuid});
        } else {
            this._course = course;
        }
        try {
            await this._course.initialize();
        } catch (error) {
            console.log('APP: initializeCourses throw error', error);
            throw error;
        }

        const source_language = this._course.getInfo().source_language;
        await i18nUtils.setInterfaceLanguage(source_language, false);
        setLeanplumUserAttributes({source_language});

        this._courses.push(this._course);
        await this._loadCourses();
    }

    _loadCourses () {
        if (this._getCoursesPromise === undefined) {
            this._getCoursesPromise = Promise.resolve()
                .then(() => this.getApiClient().r.courses.get())
                .then(response => {
                    response.courses
                        .filter(user_course_info => this._courses.find(c => user_course_info.uuid === c.UUID) === undefined)
                        .map(user_course_info => new UserCourse(this, user_course_info))
                        .forEach(course => this._courses.push(course));
                    this._coursesLoaded = true;

                    delete this._getCoursesPromise;
                    return Promise.resolve(this._courses);
                });
        }

        return this._getCoursesPromise;
    }

    /**
     *
     * @return {UserCourse}
     */
    getCourse () {
        if (this._course == null) {
            throw Error('Course is not set');
        }
        return this._course;
    }

    async setCourse (course_uuid) {
        const courses = await this.getCourses();
        let course;

        if (course_uuid && courses.length > 0) {
            course = courses.find(course => course.UUID === course_uuid);
        }

        if (course === undefined) {
            return Promise.reject(`User doesn't have a course with uuid="${course_uuid}"`);
        }

        await this.getParameters().setParameter(USER_PARAMETER.CURRENT_COURSE_UUID, course_uuid, PARAMETER_TYPE.STRING);
        await course.initialize();

        await i18nUtils.setInterfaceLanguage(course.getInfo().source_language, true);

        setLeanplumUserAttributes({source_language: course.getInfo().source_language});
        this._course = course;
        return course;
    }

    /**
     * Tells whether user has a course - either active or expired
     */

    hasCourse () {
        return (this._course !== null);
    }

    /**
     * Tells whether user has an active Course that is not expired.
     * User can end up in not having an active Course in two ways:
     * - By creating an account without selecting a course
     * @returns {boolean}
     */

    hasActiveCourse () {
        return this._course !== null && !moment().isAfter(this._course.getInfo().expiration_ts);
    }

    removeCourse (courseUUID) {
        var self = this;

        return Promise.resolve().then(() => {
            return self.getCourses();
        }).then(function (courses) {
            var course = courses.find(course => course.UUID === courseUUID);
            if (course.length === 0) {
                return Promise.reject(`Can't remove course: User doesn't have a course with uuid="${courseUUID}"`);
            } else {
                return Promise.resolve().then(() => {
                    return course.unregisterFrom();
                }).then(function (response) {
                    console.info(`Successful courses/unregister call for ${courseUUID} returned "${response}"`);
                    return Promise.resolve(course);
                });
            }
        });
    }

    getCourses () {
        if (this._coursesLoaded) {
            return Promise.resolve(this._courses);
        } else {
            return this._loadCourses();
        }
    }

    getCoursesInfo () {
        var self = this;
        return Promise.resolve().then(() => {
            return self.getCourses();
        }).then(function (courses) {
            return Promise.resolve(courses.map(c => c.getInfo()));
        });
    }

    /**
     * Enrols user to course, regardless of whether the course is locally present,
     * and adds course to local list if required
     * @param course_uuid
     * @return {Promise<void>}
     */
    enrolToCourse (course_uuid) {
        return Promise.resolve()
            .then(() => {
                // TODO: Upgrade to minorVersion >= 5 override
                return this.getApiClient().r.courses.course_uuid(course_uuid).register.post({
                    starting_level: null,  // setting starting_level to null
                }, {minorVersion: 6});
            })
            .then(user_course_info => {
                return Promise.resolve()
                    .then(() => this.getCourses())
                    .then(courses => {
                        let course = courses.find(c => c.UUID === course_uuid);
                        if (course !== undefined) {
                            console.info(`Updated Course(${course_uuid}) for user `);
                            course.update_info(user_course_info);
                        } else {
                            console.info(`Added Course(${course_uuid}) for user `);
                            this._courses.push(new UserCourse(this, user_course_info));
                        }
                        this.getParameters().setParameter(USER_PARAMETER.CURRENT_COURSE_UUID, course_uuid, PARAMETER_TYPE.STRING);
                    });
            })
            .catch(error => {
                // Catches 404: "no-such-course"
                if (error.response && error.response.body) {
                    let e = new Error(`Unable to enroll to Course(${course_uuid}) code="${error.response.body.code}" message="${error.response.body.message}"`);
                    e.name = 'UnableToEnrollError';
                    Raven.captureException(e, {level: 'error'});
                    return Promise.reject({code: error.response.body.code, message: error.response.body.message});
                } else {
                    Raven.captureException(error, {level: 'error'});
                    return Promise.reject({code: 'unknown-error', message: error});
                }
            });
    }

    getVariationInfo (course_uuid, variation_uuid) {
        return Promise.resolve()
            .then(() => this.getApiClient().r.courses.course_uuid(course_uuid).variations.variation_uuid(variation_uuid).get())
            .then(response => {  // 200 - Success response
                return response;
            }).catch(error => {  // 400, 403, 500 - Error responses
                return Promise.resolve({ error: true, code: error.status });
            });
    }

    deleteVariation (course_uuid, variation_uuid) {
        return Promise.resolve()
            .then(() => this.getApiClient().r.courses.course_uuid(course_uuid).variations.variation_uuid(variation_uuid).delete())
            .then(response => {  // 200 - Success response
                return response;
            }).catch(error => {  // 403, 404, 500 - Error responses
                return Promise.resolve({ error: true, code: error.status });
            });
    }

    getCourseLearningProgress (course_uuid, fresh = false) {
        // fetch new progress only after every 5 minutes - it's expensive call
        if (!fresh && this._courseLearningProgress && this._courseLearningProgress.hasOwnProperty('course_uuid') && this._courseLearningProgress.course_uuid === course_uuid && this._courseLearningProgress.hasOwnProperty('moment') && moment.isMoment(this._courseLearningProgress.moment) && this._courseLearningProgress.moment.diff(moment().local(), 'minutes') > -5) {
            return this._courseLearningProgress.progress;
        } else {
            return Promise.resolve()
                .then(() => this.getApiClient().r.courses.course_uuid(course_uuid).learning_progress.get())
                .then(response => {  // 200 - Success response
                    let { progress } = response;
                    this._courseLearningProgress = { moment: moment().local(), progress, course_uuid };
                    return progress;
                }).catch(error => {  // 400, 403, 500 - Error responses
                    return Promise.resolve({ error: true, code: error.status });
                });
        }
    }

    async _oauth2_authorize_request (params) {
        let response = null;
        try {
            response = await this.getApiClient().r.oauth2.authorize.get(params);
        } catch (error) {
            if (error instanceof Response && !error.bodyUsed) {
                let error_response_text = await error.text();
                Raven.captureException(
                    new OAuthAuthorizationException(`Failed oauth authorization with status=${error.status}`),
                    {
                        level: 'error',
                        logger: 'manual',
                        fingerprint: ['User', 'OAuth', 'getAuthCode', error.status],
                        extra: {args: params, response: error_response_text}
                    });
                throw `Failed to authorize - status=${error.status} text=${error_response_text}`;
            } else {
                throw error;
            }
        }

        return response;
    }

    /**
     *  Get temporary authorization code to allow Pay app sign-in without redirect
     */
    async getAuthCode () {
        // Non-200 errors get propagated to caller
        const auth_response_body = await this._oauth2_authorize_request({
            'response_type': 'code',
            'redirect_uri': getConfigValue('pay-link'),
            'scope': 'auth',
            'client_id': getConfigValue('pay-oauth-client-id')
        });

        if (auth_response_body.error) {
            let message = `Failed to authorize with status=200 error=${auth_response_body.error}
                            error_description=${auth_response_body.error_description}`;
            Raven.captureMessage(
                message,
                {
                    level: 'error',
                    logger: 'manual',
                    fingerprint: ['User', 'OAuth', 'getAuthCode', 'status_200_error'],
                    extra: {
                        response: auth_response_body
                    }
                }
            );
            throw message;
        } else {
            return auth_response_body.code;
        }
    }

    async sso_authorize () {
        try {
            ControllerManager.instance.getController('LoaderController').showPriorityLoader('sso');
            const auth_response_body = await this._oauth2_authorize_request(OauthProviderModel.getSsoParams());

            await OauthProviderModel.disableSsoFlow();
            if (auth_response_body.error) {
                console.warn('Sso sign-in failure:', auth_response_body.error);
                window.location.href = auth_response_body.redirect_uri_enriched;
            } else {
                console.log('Sso success');
                window.location.href = auth_response_body.redirect_uri_enriched;
            }
        } catch (error) {
            console.warn('Sso fatal sign-in failure:', error);
        }
    }

    _assertInitialized (module_name) {
        if (!this._initialized) {
            throw new UserNotInitializedError(`Attempting to access "${module_name}" of a non-initialized User object.`);
        }
    }

    getApiClient () {
        this._assertInitialized('PayApiClient');
        return this._apiClient;
    }

    getPayApi () {
        this._assertInitialized('PayApiClient');
        return this._pay_api_cache;
    }

    /**
     * @returns PersistentStorageProvider
     */
    getStorage () {
        this._assertInitialized('Storage');
        return this._storage;
    }

    getEventSender () {
        this._assertInitialized('EventSender');
        return this._eventSender;
    }

    getClient () {
        this._assertInitialized('Client');
        return this._client;
    }

    getSubscription () {
        this._assertInitialized('Subscription');
        return this._subscription;
    }

    getClassrooms () {
        this._assertInitialized('Classrooms');
        return this._classrooms;
    }

    getLessons () {
        this._assertInitialized('Lessons');
        return this._lessons;
    }

    getParameters () {
        this._assertInitialized('Parameters');
        return this._parameters;
    }

    getAuthentication () {
        this._assertInitialized('Authentication');
        return this._authentication;
    }

    getTerms () {
        this._assertInitialized('TermsAndConditions');
        return this._terms;
    }

    startTrial () {
        return Promise.resolve()
            .then( () => this.getApiClient().r.user.trial.post())
            .then( () => this.sync());
    }

    updateRole (role) {
        return Promise.resolve()
            .then(() => this.getApiClient().r.user.role.post({role: role}))
            .then(response => {
                this._features = response.features;
            })
            .then(() => this.sync());
    }

    hasFeature (feature) {
        return this._features.includes(feature);
    }

    async addFeature (feature) {
        if (!this.hasFeature(feature)) {
            this._features.push(feature);
            this.updateFeatures();
        }
        // TODO: Saving features through the api
    }

    async updateFeatures () {
        try {
            await this.getApiClient().r.user.features.post({
                features: this._features
            });
        } catch (error) {
            Raven.captureException(error, {level: 'error'});
        }
    }

    // TODO: setFeature + saveFeatures
}
