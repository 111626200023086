var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "words-progress grid-item" },
    [
      _c("h2", {
        domProps: {
          innerHTML: _vm._s(this.$i18n("hub_section_progress_title")),
        },
      }),
      _vm._v(" "),
      _c(
        "thermometer",
        _vm._g(
          { attrs: { course: this.course, user: this.user, clickable: true } },
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c("collapsible-box", {
        attrs: {
          i18n_title: { id: "widget_stats_total_words_label" },
          "title-meta": _vm.totalWords,
          i18n_text: { id: "knowledge_lab_total_words_description" },
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function () {
              return [_c("IconHubTotalWords")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }