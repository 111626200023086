var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "26",
          height: "26",
          viewBox: "0 0 26 26",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("circle", {
          attrs: { fill: "#FE5C55", cx: "13", cy: "13", r: "13" },
        }),
        _c("path", {
          attrs: {
            stroke: "#FFF",
            "stroke-width": "1.75",
            "stroke-linecap": "round",
            d: "M7.624 7.551L17.91 17.977l.466.472M18.294 7.622L7.69 18.44",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }