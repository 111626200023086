var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M2.6 23h12.8a.6.6 0 00.6-.6V20H5V5H2.6a.6.6 0 00-.6.6v16.8a.6.6 0 00.6.6z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 19v1H5V3h12v3M9 7h4M9 11h3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M18 10l2.5-2.5 2 2L20 12l-2-2z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "clip-rule": "evenodd",
          d: "M13 15l5-5 2 2-2.5 2.5L15 17h-2v-2z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }