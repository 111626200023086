var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "select-target-language" },
    [
      _c("languages", {
        attrs: {
          languages: _vm.target_languages,
          heading: this.$i18n("register_select_course_target_heading"),
        },
      }),
      _vm._v(" "),
      _c("languages", {
        attrs: {
          languages: _vm.source_languages,
          heading: this.$i18n("register_select_course_source_heading"),
          show_language_not_listed: true,
        },
        on: {
          language_not_listed: _vm.language_not_listed,
          language_selected: _vm.language_selected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }