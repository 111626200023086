var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "demographic-question" },
    [
      _vm.question.questionLanguage !== undefined
        ? _c("h2", {
            domProps: {
              innerHTML: _vm._s(
                this.$i18n(_vm.question.questionId, {
                  args: {
                    language_name: {
                      collection: _vm.question.questionLanguage,
                    },
                    target_language: _vm.question.questionLanguage,
                  },
                })
              ),
            },
          })
        : _c("h2", [_vm._v(" " + _vm._s(_vm.$i18n(_vm.question.questionId)))]),
      _vm._v(" "),
      _vm._l(_vm.question.answers, function (answer) {
        return _c(
          "div",
          {
            key: answer.name,
            staticClass: "answer",
            class: { selected: _vm.question.selected },
            on: {
              click: function ($event) {
                return _vm.answerGiven(answer)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "highlight" },
              [
                _c("answer-icon", { attrs: { value: answer.iconId } }),
                _vm._v(" "),
                _c("div", { staticClass: "name-container" }, [
                  _c("span", {
                    staticClass: "name",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$i18n(answer.name, {
                          collection: _vm.question.answerId,
                        })
                      ),
                    },
                  }),
                ]),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }