var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "debug-tabs" },
    [
      _c("header", [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              height: "24",
              viewBox: "0 0 24 24",
              width: "24",
            },
          },
          [
            _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
            _c("path", {
              attrs: {
                d: "M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "debug-tabs-header" },
          _vm._l(_vm.tabs, function (tab, index) {
            return _c(
              "li",
              {
                key: tab.title,
                class: { active: _vm.selectedIndex === index },
                on: {
                  click: function ($event) {
                    return _vm.selectTab(index)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(tab.title) + "\n            "
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }