import _ from 'lodash';
import moment from 'moment';
import Raven from 'raven-js';

import Schema from '../../util/schema.js';

import { CATEGORY as EXERCISE_CATGEGORY } from '../../modules/exercises/constants.js';
import { EVENT_SCHEMA } from '../eventsender.js';

const TIME_BETWEEN_EVENTS_LIMIT = 60;

export const EVENT_TYPE = {
    GUESS: 'guess',
    EXERCISE_COMPLETE: 'exercise_complete',
    AWARD: 'award',
    TAG: 'tag'
};

class HistoryUpdateNotImplementedForEventTypeException extends Error {
    constructor (event_type) {
        super(`History update behavior has not been implemented for given event_type=${event_type}`);
    }
}

export class UnexpectedEventException extends Error {
    constructor (message) {
        super(message);
        this.name = 'UnexpectedEventException';
    }
}

export class StatisticsProcessorUtils {

    static getFreshStatistics () {
        return {
            client_sn: -1,
            client_event_ts: moment(0),
            study_time: 0,
            new_units: {
                correct: 0,
                total: 0
            },
            repeat_units: {
                correct: 0,
                total: 0
            },
            all_units: {
                correct: 0,
                total: 0
            },
            exercises: {
                all: 0,
                by_category: StatisticsProcessorUtils._get_empty_exercise_categories()
            },
            sets: {
                max_correct_rate: 0,
                max_correct_streak: 0,
                count: 0
            },
            flips: {
                positive: 0,
                negative: 0
            },
            kicks: 0,
            fast_tracked_words: 0,
            unique_word_count: 0
        };
    }

    static getFreshHistory () {
        const history = [];
        let day = moment().local().startOf('day');
        _.times(30, () => {
            history.push(this.getFreshHistoryDataPoint(day.format('YYYY-MM-DD')));
            day.subtract(1, 'days');
        });

        history.sort(this.historySorter);

        return history;
    }

    static getFreshHistoryDataPoint (ds) {
        return {
            ts: ds,
            client_sn: 0,  // Locally used field to track update status for each history day
            all_units: {
                total: 0,
                correct: 0
            },
            repeat_units: {
                total: 0,
                correct: 0
            },
            new_units: {
                total: 0,
                correct: 0
            },
            study_time: 0,
            max_correct_streak: 0,
            last_correct_streak: 0,
            awards_objects: [],
            exercises: {
                all: 0,
                by_category: StatisticsProcessorUtils._get_empty_exercise_categories()
            },
            flips: {
                positive: 0,
                negative: 0
            },
            kicks: 0,
            fast_tracked_words: 0,
            unique_word_count: 0
        };
    }

    static _get_empty_exercise_categories() {
        return Object.values(EXERCISE_CATGEGORY).reduce((obj, cat) => {
            obj[cat] = 0;
            return obj;
        }, {});
    }

    static getDataPointForTS (history, ts) {
        /**
         * :param history: <array> of history data points
         * :param ts: <moment> object representing the time the history point should be returned for
         */

        // Comparisons are done with format because moment does weird things with timezones

        let today = ts.format('YYYY-MM-DD');

        let dataPointIndex;

        dataPointIndex = _.findIndex(history, dp => dp.ts === today);

        // If the day is not in the history yet initialize an empty day
        if (dataPointIndex === -1) {
            history = this.addDataPointToHistory(history, today);
            dataPointIndex = _.findIndex(history, dp => dp.ts === today);
        }

        return {
            index: dataPointIndex,
            point: history[dataPointIndex]
        };
    }

    static getUpdatedHistory (eventStatistics, history, timeBetweenEvents) {
        let eventTS = eventStatistics.eventTS;
        let dp = StatisticsProcessorUtils.getDataPointForTS(history, eventTS);
        history = [this.getUpdatedHistoryDataPoint(eventStatistics, dp.point, timeBetweenEvents)];

        return history;
    }

    static historySorter (a, b) {
        if (a.ts < b.ts) {
            return -1;
        }
        if (a.ts > b.ts) {
            return 1;
        }
        return 0;
    }

    static addDataPointToHistory (history, ds) {
        /**
         * :param history: history array
         * :param ds: date stamp in the format 'YYYY-MM-DD'
         */
        history.push(this.getFreshHistoryDataPoint(ds));
        history.sort(this.historySorter);
        return history;
    }

    static getUpdatedHistoryDataPoint (eventStatistics, dataPoint, timeBetweenEvents) {
        let updatedDataPoint = _.clone(dataPoint);

        updatedDataPoint.client_sn = eventStatistics.clientSN;

        switch (eventStatistics.type) {
            case EVENT_TYPE.GUESS: {
                if (eventStatistics.isNew) {
                    updatedDataPoint.new_units = {
                        total: dataPoint.new_units.total + 1,
                        correct: dataPoint.new_units.correct + (eventStatistics.isCorrect ? 1 : 0)
                    };
                } else {
                    updatedDataPoint.repeat_units = {
                        total: dataPoint.repeat_units.total + 1,
                        correct: dataPoint.repeat_units.correct + (eventStatistics.isCorrect ? 1 : 0)
                    };

                    if (eventStatistics.previous_is_correct && !eventStatistics.isCorrect) {
                        updatedDataPoint.flips.negative++;
                    } else if (!eventStatistics.previous_is_correct && eventStatistics.isCorrect) {
                        updatedDataPoint.flips.positive++;
                    }

                    if (eventStatistics.previous_is_correct && eventStatistics.isCorrect) {
                        updatedDataPoint.kicks++;
                    }
                }

                updatedDataPoint.all_units = {
                    total: dataPoint.all_units.total + 1,
                    correct: dataPoint.all_units.correct + (eventStatistics.isCorrect ? 1 : 0)
                };

                if (eventStatistics.isCorrect) {
                    updatedDataPoint.last_correct_streak = dataPoint.last_correct_streak + 1;
                } else {
                    updatedDataPoint.last_correct_streak = 0;
                }

                updatedDataPoint.max_correct_streak = _.max([
                    updatedDataPoint.last_correct_streak,
                    dataPoint.max_correct_streak
                ]);

                updatedDataPoint.study_time = this.bumpTime(timeBetweenEvents, dataPoint.study_time);
                updatedDataPoint.ts = dataPoint.ts;
                break;
            }
            case EVENT_TYPE.EXERCISE_COMPLETE: {
                updatedDataPoint.exercises.all = dataPoint.exercises.all + 1;
                updatedDataPoint.exercises.by_category[eventStatistics.category] =
                    (dataPoint.exercises.by_category[eventStatistics.category] || 0) + 1;
                break;
            }
            default: {
                Raven.captureException(new HistoryUpdateNotImplementedForEventTypeException(eventStatistics.type), {level: 'error'});
            }
        }

        return updatedDataPoint;
    }

    static getUpdatedBadgeProgress (eventStatistics, badgeProgress, timeBetweenEvents) {
        badgeProgress.groups = badgeProgress.groups === undefined ? [] : badgeProgress.groups;

        // The 50-card reset
        // If user has done 50 cards, then remove first five card groups,
        // so that next card will be counted in the 6th group created on previous iteration
        if (_.sumBy(badgeProgress.groups, group => group.total_cards_in_group) >= badgeProgress.cards_per_badge) {
            badgeProgress.groups = _.takeRight(badgeProgress.groups, 1);
        }

        var lastGroup = badgeProgress.groups[badgeProgress.groups.length - 1];

        if (lastGroup !== undefined) {
            if (eventStatistics.isNew) {
                lastGroup.new_cards++;
            } else {
                lastGroup.repeated_cards++;
            }

            if (timeBetweenEvents < TIME_BETWEEN_EVENTS_LIMIT) {
                lastGroup.study_time += timeBetweenEvents;
            }

            lastGroup.total_cards_in_group++;
        }

        if (lastGroup === undefined || lastGroup.total_cards_in_group >= lastGroup.max_cards_in_group) {
            var newLastGroup = {
                group_id: lastGroup !== undefined ? lastGroup.group_id + 1 : 0,
                max_cards_in_group: badgeProgress.cards_per_group,
                new_cards: 0,
                repeated_cards: 0,
                study_time: 0,
                total_cards_in_group: 0
            };
            badgeProgress.groups.push(newLastGroup);
        }

        return badgeProgress;
    }


    static getCleanBadgeProgress (badgeProgress) {
        return {
            cards_per_badge: badgeProgress.cards_per_badge,
            cards_per_group: badgeProgress.cards_per_group,
            groups: [{
                group_id: 0,
                max_cards_in_group: badgeProgress.cards_per_group,
                new_cards: 0,
                repeated_cards: 0,
                study_time: 0,
                total_cards_in_group: 0
            }]};
    }

    static bumpTime (timeBetweenEvents, currentTime) {
        return timeBetweenEvents < TIME_BETWEEN_EVENTS_LIMIT ? currentTime + timeBetweenEvents : currentTime;
    }

    static is_guess_event_new_word (event) {
        if (Schema.isSchemaMinorGE(event.schema, EVENT_SCHEMA.GUESS, 0, 0)) {
            const answerCount = event.data.simple_algorithm_state.answer_count;
            return answerCount !== null && answerCount !== undefined && parseInt(answerCount) === 0;
        } else {
            throw new UnexpectedEventException(`Expected "${EVENT_SCHEMA.GUESS}" got "${event.schema}"!`);
        }
    }
}
