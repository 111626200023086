var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8.185 7.568c-.384.349-.762.72-1.134 1.111-4.133 4.36-6.164 10.13-4.06 12.348 1.005 1.059 2.842 1.128 4.77.53M15.18 17.106c.557-.45 1.107-.943 1.643-1.478 4.246-4.235 6.348-9.4 4.185-11.557-.992-.99-2.884-1.034-4.769-.53",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.087 9.476a21.397 21.397 0 00-1.596-1.693C11.08 3.581 5.238 1.931 2.991 4.071c-1.137 1.084-1.27 2.656-.529 4.769M7.549 15.198c.405.499.842.99 1.31 1.47 4.292 4.403 9.963 6.602 12.149 4.36 1.122-1.152 1.283-3.579.53-5.83",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.89 10.523a1.851 1.851 0 012.577 0c.71.696.71 1.824 0 2.521L12.445 16l-2.91-2.847a1.755 1.755 0 010-2.522 1.85 1.85 0 012.577 0l.333.326.444-.434zm-1.993.193c-.413 0-.678.03-.897.284-.233.269-.402.57-.29 1.013.046.183.227.403.517.34.537-.117.247-.435.5-.728.205-.236.632-.141.632-.546 0-.276-.164-.364-.462-.364z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }