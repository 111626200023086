'use strict';

import Mailcheck from 'mailcheck';

const PASSWORD_MIN_LENGTH = 8;

Mailcheck.defaultDomains.push(
    /* Lingvist users domains*/
    'outlook.jp', 'live.jp', 'hotmail.co.jp', 'excite.co.jp', 'yahoo.com.tw', 'live.jp', 'ukr.net', 'pchome.com.tw',
    'hot.ee', 'bk.ru', 'inbox.ru', '163.com', 'yahoo.com.vn', 'mail.ee', 'i.ua', 'yandex.ua', 'yahoo.es', 'nifty.com',
    'infoseek.jp', 'softbank.ne.jp', '126.com', 'i.softbank.jp', 'ezweb.ne.jp', 'docomo.ne.jp', '163.com',
    'hotmail.com.tw', 'livemail.tw', 'kimo.com', 'ntu.edu.tw', 'protonmail.com', 'seed.net.tw', 'myway.com',
    'msa.hinet.net',

    /* Other global domains */
    "email.com", "games.com" /* AOL */, "gmx.net", "hush.com", "hushmail.com", "icloud.com", "inbox.com",
    "lavabit.com", "love.com" /* AOL */, "outlook.com", "pobox.com", "rocketmail.com" /* Yahoo */,
    "safe-mail.net", "wow.com" /* AOL */, "ygm.com" /* AOL */, "ymail.com" /* Yahoo */, "zoho.com", "fastmail.fm",
    "yandex.com","iname.com",

    /* United States ISP domains */
    "bellsouth.net", "charter.net", "comcast.net", "cox.net", "earthlink.net", "juno.com",

    /* British ISP domains */
    "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
    "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
    "virgin.net", "wanadoo.co.uk", "bt.com",

    /* Domains used in Asia */
    "sina.com", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph",

    /* French ISP domains */
    "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",

    /* German ISP domains */
    "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de" /* T-Mobile */, "web.de", "yahoo.de",

    /* Russian ISP domains */
    "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",

    /* Belgian ISP domains */
    "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",

    /* Argentinian ISP domains */
    "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",

    /* Domains used in Mexico */
    "hotmail.com", "gmail.com", "yahoo.com.mx", "live.com.mx", "yahoo.com", "hotmail.es", "live.com", "hotmail.com.mx", "prodigy.net.mx", "msn.com",

    /* Domains used in Brazil */
    "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
);

Mailcheck.defaultSecondLevelDomains.push(
    'excite', 'ukr', 'pchome', 'hot', 'bk', 'inbox', '163', 'mail', 'i', 'yandex', 'nifty', 'infoseek', 'softbank',
    '126', 'i', 'ezweb', 'docomo', '163', 'livemail', 'kimo', 'ntu', 'protonmail', 'seed', 'myway', 'msa');

Mailcheck.defaultTopLevelDomains.push('ee', 'tw', 'net.tw', 'edu.tw');

export default {
    similarText: function(first, second, percent) {
        // http://kevin.vanzonneveld.net
        // +   original by: Rafał Kukawski (http://blog.kukawski.pl)
        // +   bugfixed by: Chris McMacken
        // +   added percent parameter by: Markus Padourek (taken from http://www.kevinhq.com/2012/06/php-similartext-function-in-javascript_16.html)
        // *     example 1: similarText('Hello World!', 'Hello phpjs!');
        // *     returns 1: 7
        // *     example 2: similarText('Hello World!', null);
        // *     returns 2: 0
        // *     example 3: similarText('Hello World!', null, 1);
        // *     returns 3: 58.33
        // *   bugfixed by: Jarkko Rantavuori based on findings in stackoverflow (http://stackoverflow.com/questions/14136349/how-does-similar-text-work)
        if (first === null || second === null || typeof first === 'undefined' || typeof second === 'undefined') {
            return 0;
        }

        first += '';
        second += '';

        var pos1 = 0,
            pos2 = 0,
            max = 0,
            firstLength = first.length,
            secondLength = second.length,
            p, q, l, sum;

        for (p = 0; p < firstLength; p++) {
            for (q = 0; q < secondLength; q++) {
                for (l = 0;
                     (p + l < firstLength) && (q + l < secondLength) && (first.charAt(p + l) === second.charAt(q + l)); l++){/*pass*/}
                if (l > max) {
                    max = l;
                    pos1 = p;
                    pos2 = q;
                }
            }
        }

        sum = max;

        if (sum) {
            if (pos1 && pos2) {
                sum += this.similarText(first.substr(0, pos1), second.substr(0, pos2));
            }

            if ((pos1 + max < firstLength) && (pos2 + max < secondLength)) {
                sum += this.similarText(first.substr(pos1 + max, firstLength - pos1 - max), second.substr(pos2 + max, secondLength - pos2 - max));
            }
        }

        if (!percent) {
            return sum;
        } else {
            return (sum * 200) / (firstLength + secondLength);
        }
    },

    emailRegExp: new RegExp(/[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+/),

    validateEmail: function (email, errorCallback) {
        if (!email || email.length === 0) {
            if (errorCallback !== undefined) {
                errorCallback('error-empty-email');
            }
            return false;
        }

        if (!this.emailRegExp.test(email)) {
            if (errorCallback !== undefined) {
                errorCallback('error-invalid-email');
            }
            return false;
        }

        return true;
    },

    validateAndSuggestEmail: function (email) {
        var self = this;

        return new Promise(function(resolve, reject) {
            if (email.length === 0) {
                reject('error-empty-email');
            } else if (!self.emailRegExp.test(email)) {
                reject('error-invalid-email');
            } else {
                Mailcheck.run({
                    email: email,
                    suggested: function(suggestion) {
                        resolve(suggestion);
                    },
                    empty: function() {
                        resolve(null);
                    }
                });
            }
        });
    },

    passwordMinLength: PASSWORD_MIN_LENGTH
};
