<template>
    <div class="tutorial-modal" @click.self="close">
        <div class="tutorial-modal-window">
            <CloseButton @click="close" class="close"/>
            <div class="content" v-if="video_id">
                <loader :centered="true" v-if="loading" />
                <h3 v-else v-html="this.$i18n('lesson_creator_editor_video_title')" />
                <vimeo-player ref="player" :video-id='video_id' :options="options" @ready="onReady" :player-width="playerWidth" :player-height="420" :autoplay="true" />
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from 'Util/vue-event-bus';
    import CloseButton from 'Images/close-24x24.svg';
    import VButton from 'ViewComponent/v-button.vue';
    import loader from "ViewComponent/loader.vue";
    import vueVimeoPlayer from 'vue-vimeo-player';
    import Vue from 'vue';

    Vue.use(vueVimeoPlayer);

    export default {
        name: 'tutorial-modal',
        components: {
            VButton,
            CloseButton,
            loader
        },
        props: {
            video_id: {
                type: String,
                default: '554275676'
            },
        },
        data() {
            return {
                loading: true,
                options: {
                    autoplay: true
                }
            }
        },
        methods: {
            close() {
                EventBus.$emit('cw:close-tutorial-modal');
            },
            keyDown(event) {
                if (event && event.keyCode === 27) { // close on ESC key
                    this.close();
                }
            },
            onReady() {
                this.loading = false;
            }
        },
        computed: {
            playerWidth() {
                let windowWidth = window.innerWidth;
                if (windowWidth < 1000) {
                    return Math.round(windowWidth * 0.7);
                } else {
                    return 740;
                }
            }
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
            document.body.classList.add('modal-open');
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.tutorial-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43,81,142,0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.tutorial-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(760);
            min-height: pxToRem(500);
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('medium') {
                min-width: pxToRem(400);
                min-height: pxToRem(200);
            }

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                z-index: 100;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }
                &:hover {
                    transform: scale(1.2);
                }
            }

            > div.content {
                position: relative;
                z-index: 50;
                > h3 {
                    user-select: none;
                    margin-bottom: .5rem;
                    margin-right: 2rem;
                }
            }
        }
    }
</style>
