var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "83",
          height: "102",
          viewBox: "0 0 83 102",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8 22.768v29.369a32 32 0 0010.114 23.345l17.782 16.671C38.743 95 40.331 95.19 42.348 93.3l-7.612 7.136a3.999 3.999 0 01-5.472 0l-19.15-17.953A32 32 0 010 59.137V27a4 4 0 014-4h.402c1.21 0 2.411-.078 3.598-.232z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M72 38v14.137a32 32 0 01-10.114 23.345l-19.15 17.953a4 4 0 01-5.472 0l-19.15-17.953A32 32 0 018 52.137V20a4 4 0 014-4h.402a28 28 0 0019.799-8.201l4.97-4.97a4 4 0 015.657 0l4.971 4.97A28 28 0 0067.598 16H68a4 4 0 014 4v9M75 14l7-7M72 11V7M78 17h4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.207 37.793l-1.5-1.5a1 1 0 00-1.414 0l-10.5 10.5L36 49l11.207-11.207zM27.207 45.793L24 49l11.207 11.207-3.5 3.5a1 1 0 01-1.414 0l-12-12a1 1 0 010-1.414l6-6a1 1 0 011.414 0l1.5 1.5z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42 55l4-4 10-10-6-6-14 14-6-6-6 6 12 12 2-2",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }