var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "status-unfinished_svg__variation-unfinished-paper-16",
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        },
        [
          _c(
            "g",
            {
              staticClass:
                "status-unfinished_svg__variation-unfinished-paper-16__group",
              attrs: { stroke: "#2C3143", "stroke-width": "1.5" },
            },
            [
              _c("path", {
                staticClass:
                  "status-unfinished_svg__variation-unfinished-paper-16__group__combined-shape",
                attrs: {
                  d: "M13.12 3.472c.469.47.469 1.231 0 1.701l-6.076 6.075a1.8 1.8 0 01-.717.44L2.89 12.81l1.123-3.437a1.805 1.805 0 01.44-.715l6.075-6.076a1.202 1.202 0 011.61-.083l.09.082.89.89zm-9.162 8.703l-.431-.432-.211.641.642-.21z",
                },
              }),
              _c("path", {
                staticClass:
                  "status-unfinished_svg__variation-unfinished-paper-16__group__path-16",
                attrs: { d: "M3 13h5.55" },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }