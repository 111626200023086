<template>
    <div class="more-dropdown" @mouseleave="closeDropdownDelayed">
        <more-circle @click="openDropdown" :class="{ transparent }"/>
        <transition name="fade">
            <div class="dropdown" v-if="open" @mouseenter="openDropdown" @mouseleave="closeDropdown">
                <variation-settings :variation="variation"
                                    :variation-toggle-disabled="variationToggleDisabled" />
                <div class="actions">
                    <v-button class="discardLesson" v-if="canBeDiscarded" @click.native="discardLesson" :i18n="{ id: 'course_wizard_save_for_later_discard' }" />
                    <v-button class="editLesson" v-if="isEditable" @click.native="editLesson" :i18n="{ id: 'lessons_list_table_edit' }" :filled="true" color="primary" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import Backbone from 'backbone';
    import MoreCircle from 'Images/more-circle.svg';
    import VariationSettings from './variation-settings.vue'
    import VButton from "ViewComponent/v-button.vue";
    import { EventBus } from 'Util/vue-event-bus';

    export default {
        name: 'more-dropdown',
        components: {
            VButton,
            MoreCircle,
            VariationSettings
        },
        props: {
            variation: {
                type: Object
            },
            variationToggleDisabled: {
                type: Boolean,
                default: false
            },
            transparent: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            isEditable() {
                return (this.variation && this.variation.type === 'lesson' && this.variation.is_editable);
            },
            canBeDiscarded() {
                return (this.variation && this.variation.type === 'lesson' && !this.variation.assigned_ts);
            }
        },
        data() {
            return {
                open: false,
                closeTimeout: null
            };
        },
        methods: {
            openDropdown() {
                clearTimeout(this.closeTimeout);
                this.open = true;
            },
            closeDropdown() {
                this.open = false;
            },
            closeDropdownDelayed() {
                this.closeTimeout = setTimeout(() => {
                    this.closeDropdown();
                }, 250);
            },
            editLesson() {
                Backbone.history.navigate(`course-wizard/edit/${this.variation.uuid}`, { trigger: true });
            },
            discardLesson() {
                EventBus.$emit('discard-lesson', this.variation.uuid);
                this.closeDropdown();
            }
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.more-dropdown {
        width: pxToRem(28);
        height: pxToRem(28);
        position: relative;

        svg {
            circle {
                transition: fill 200ms ease;
            }
        }
        svg.transparent {
            circle {
                fill: transparent;
            }
        }
        &:hover {
            cursor: pointer;
            svg.transparent {
                circle {
                    fill: $colorBackgroundGray;
                }
            }
        }

        div.dropdown {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: 0;
            top: pxToRem(30);
            @include elevated;
            border-radius: pxToRem(12);
            padding: pxToRem(16);
            background-color: #fff;
            z-index: 100;
            min-width: pxToRem(310);
            body[data-interface-language="ar"] & {
                right: unset;
                left: 0;
            }
            @include respond-to('small') {
                min-width: pxToRem(260);
                padding: pxToRem(8);
            }
            &.fade-enter-active, &.fade-leave-active {
                transition: opacity 250ms;
            }
            &.fade-enter, &.fade-leave-to {
                opacity: 0;
            }
            > div.actions {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                > button.editLesson,
                > button.discardLesson {
                    flex: 1;
                    margin: .5rem;
                }
            }
        }
    }
</style>
