
'use strict';

import moment from 'moment';

import _ from 'lodash';

import { ExerciseFactory } from './exercises/exercise.factory.js';
import AsyncDestroyable from './async.destroyable.js';
import Raven from 'raven-js';


/**
 * @typedef {object} ExerciseData
 * @property {string} uuid
 * @property {string} available
 * @property {string|null} completed
 * @property {string} type
 * @property {{v1: string}} paths
 * @property {string} level
 * @property {{source: string, target: string}} title
 * @property {{source: string, target: string}} description
 * @property {{display: {description, intro, summary, title}} speaking
 * @property {{display: object, answer_order: array}} reading
 * @property {{display: object, answer_order: array}} listening
 * @property {{display: object, answer_order: array}} grammar
 */

export class Exercises extends AsyncDestroyable {
    constructor (course, assetLoader) {
        super(['initializeState', 'updateExercises']);
        this._course = course;
        this._assetLoader = assetLoader;
        this._exercises = null;
    }

    _destroyExercises () {
        if (this._exercises !== null) {
            return Promise.all(_.values(this._exercises).map(exercise => exercise.destroy()));
        }
        return Promise.resolve();
    }

    /**
     * Initializes the CourseExercises
     */
    initializeState () {
        console.log(`CourseExercises.initializeState()`);
        let promise = this._destroyExercises();

        this._exercises = {};
        return promise;
    }

    /**
     * Loads exercise data provided by the server and updates the exercises
     * @param {ExerciseData[]} exercises
     */
    updateExercises (exercises) {
        console.debug(`CourseExercises.updateExercises(${this._course.UUID})`, exercises);
        exercises.forEach(exercise_data => {
            try {
                if (this._exercises[exercise_data.uuid] === undefined) {
                    this._exercises[exercise_data.uuid] = ExerciseFactory.getExercise(exercise_data.type, this._course, this._assetLoader);
                }
                this._exercises[exercise_data.uuid].update(exercise_data);
            } catch (error) {
                // Just reporting the error to Sentry, and skipping the exercise, no reason to break the app
                Raven.captureException(error, {level: 'error'});
            }
        });
        return Promise.resolve();
    }

    /**
     * Generates data in a format acceptable be updateExercises
     *
     * @return {ExerciseInfo[]}
     */
    serializeExercises () {
        return _.values(this._exercises).map(exercise => exercise.serialize());
    }

    getExercise (uuid) {
        return this._exercises[uuid];
    }

    /**
     * Returns an array filtered based on the provided options of ExerciseInfo objects
     *   available - By default only available exercises are returned
     *   completed - By default all exercises are returned
     * @param {{completed: boolean|undefined, available: boolean|undefined}} options
     * @return {ExerciseInfo[]}
     */
    getExercisesInfo (options = {}) {
        let now = moment();

        return _.values(this._exercises)
            .map(ex => ex.getInfo())
            .filter(exInfo => {
                if (options.completed !== undefined) {
                    // completed shouldn't be in the future
                    if (options.completed && exInfo.completed === null) {
                        return false;
                    } else if (!options.completed && exInfo.completed !== null) {
                        return false;
                    }
                }

                if (options.available === undefined || options.available === true) {
                    return exInfo.available !== null && exInfo.available <= now;
                }

                return true;
            })
            .sort((info1, info2) => {
                if (info1.level === info2.level) {
                    return info1.order - info2.order;
                } else {
                    return info1.level - info2.level;
                }
            });
    }

    /**
     * Returns the current amount of exercises
     * @return {number}
     */
    getExercisesCount () {
        return Object.keys(this._exercises).length;
    }

    destroy () {
        // Remove all references
        delete this._course;
        delete this._assetLoader;

        return this._destroyExercises();
    }
}
