var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "success" },
    [
      _c("order-success-icon"),
      _vm._v(" "),
      _c("div", { staticClass: "message" }, [
        _c("h2", {
          domProps: { innerHTML: _vm._s(this.$i18n("pay_success_page_order")) },
        }),
        _vm._v(" "),
        _vm.showReceiptInfo
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("pay_success_page_receipt")),
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("v-button", {
        attrs: {
          i18n: { id: "pay_success_page_continue_learn" },
          route: "guess",
          filled: true,
          color: "primary",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }