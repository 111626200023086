var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade-message" } }, [
    !_vm.hidden
      ? _c("div", { staticClass: "locked-message" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "icon-container" },
              [_c("unlock-icon")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "message-action" },
              [
                _c("div", { staticClass: "message" }, [
                  _vm.title ? _c("h4", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
                  _vm._v(" "),
                  _vm.text
                    ? _c("p", { staticClass: "text" }, [
                        _vm._v(" " + _vm._s(_vm.text)),
                      ])
                    : _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("pre_trial_float_message_text")
                          ),
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("v-button", {
                  attrs: {
                    i18n: {
                      id: this.showStartTrial
                        ? "pre_trial_float_message_button"
                        : "pre_trial_float_message_button_2",
                    },
                    route: "subscriptions",
                    size: "medium",
                    color: "primary",
                    filled: true,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.closable
            ? _c(
                "div",
                { staticClass: "right" },
                [
                  _c("div", { staticClass: "separator" }),
                  _vm._v(" "),
                  _c("close-button", {
                    staticClass: "close",
                    on: { click: _vm.close },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }