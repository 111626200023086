
'use strict';

import moment from 'moment';

import AsyncDestroyable from './async.destroyable.js';

export class CourseVariationStats extends AsyncDestroyable {
    constructor (user, course) {
        super(['getAll']);
        this._user = user;
        this._course_variation_stats = null;
        this._course = course;
        this._lastFetch = null;
    }

    destroy () {
        let self = this;

        return Promise.resolve().then(function () {
            delete self._course_variation_stats;
            delete self._course;
            delete self._user;
        });
    }

    getAll () {
        const elapsedDurationInMinutes = this._lastFetch ? moment.duration(moment().diff(this._lastFetch)).asMinutes() : 0;
        const self = this;

        // Fetch fresh stats when there's more than a minute passed from previous fetch
        if (this._course_variation_stats === null || elapsedDurationInMinutes > 1) {
            this._lastFetch = moment();
            return Promise.resolve()
                .then(() => this._user.getApiClient().r.courses.course_uuid(this._course.UUID).variation_stats.get({}, { majorVersion: 2, minorVersion: 0 }))
                .then(response => {  // 200 - Success response
                    self._course_variation_stats = response;
                    return Promise.resolve(self._course_variation_stats);
                }).catch(error => {  // 400, 403, 500 - Error responses
                    return Promise.resolve({ error: true, code: error && error.status });
                });
        } else {
            return Promise.resolve(this._course_variation_stats);
        }

    }
}

export default new CourseVariationStats();
