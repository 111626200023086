
'use strict';

import _ from 'lodash';
import moment from 'moment';

const LOG_LEVELS = {
    'debug': 0,
    'log': 1,
    'info': 2,
    'warn': 3,
    'error': 4
};

export default class Logger {
    constructor (originalConsole, options) {
        var self = this;
        this.originalConsole = originalConsole;
        this.levels = Object.keys(LOG_LEVELS);

        // Set max size as approximately 1MB (Actually it's capped by the number of
        // characters which in unicode can be larger then a byte)
        this.MAX_SIZE = options['log-max-size'] || 5 * 1024 * 1024;
        this.logs = [];
        this.size = 0;
        this.originalConsole = window.console;
        this.originalConsoleLevels = this.levels.reduce((result, level) => {
            result[level] = originalConsole[level];
            return result;
        }, {});

        var replacementConsole = window.console || {};

        // If environment is undefined or anything but local attach the logger.
        // Do not capture logs and re-wrap them when the environment is the local development environment because this
        // masks the original location of the log call.
        if (options.environment !== 'local') {
            this.levels.forEach(function (logLevel) {
                replacementConsole[logLevel] = self.getLoggerReplacement(logLevel);
            });
        }
        window.console = replacementConsole;
    }

    getLoggerReplacement (logLevel) {
        // Returns a replacement for a logging function at a given level
        var self = this;

        return function () {
            var args = Array.prototype.slice.call(arguments);

            // Non-serializable arguments are converted
            args = args.map(argument => {
                try {
                    JSON.stringify(argument);
                    return argument;
                } catch (error) {
                    self.log('error', ['Unable to JSON serialize arguments', args, error]);
                    return `[[Unable to stringify argument. ${error.name}: ${error.message} ]]`;
                }
            });

            var size = JSON.stringify(args).length;

            self.size += size;
            self.logs.push({
                corrected_ts: moment().local().locale('en'), // Set locale as 'en' to send properly formatted strings to the server regardless of the current app locale
                client_ts: (new Date()).toISOString(), // Using native Date deliberately to record ts without correction
                level: logLevel,
                size: size,
                arguments: args
            });

            self.clean();
            self.log(logLevel, args);
        };
    }

    getLogs (minimumLevel, maxSize) {
        var size = 0,
            minimumLevelValue = LOG_LEVELS[minimumLevel];

        return _(this.logs)
            .filter(row => LOG_LEVELS[row.level] >= minimumLevelValue)
            .takeRightWhile(row => {size += row.size; return size < maxSize;})
            .map(row => ({ts: row.corrected_ts.format('YYYY-MM-DDTHH:mm:ss.SSSZ'), level: row.level, line: _.join(row.arguments, ' ')}))
            .value();
    }

    getRawLogs () {
        return this.logs;
    }

    clean () {
        // Remove entries from the beginning of the log until it is smaller then MAX_SIZE if necessary
        if (this.size >= this.MAX_SIZE) {
            while (this.size >= this.MAX_SIZE) {
                var logEntry = this.logs.shift();
                this.size -= logEntry.size;
            }
        }
    }

    log (level, args) {
        // Local logging shortcut function to the long call
        // IE9 doesn't allow calling apply on console functions directly
        // See: https://stackoverflow.com/questions/5472938/does-ie9-support-console-log-and-is-it-a-real-function#answer-5473193
        var levelFunction = this.originalConsoleLevels[level];
        Function.prototype.bind.call(levelFunction, this.originalConsole).apply(this.originalConsole, Array.prototype.slice.call(args));
    }
}
