
'use strict';

import Raven from 'raven-js';
import uuid from 'uuid';
import { getPersistentStorageProvider } from '../modules/persistent.storage.provider.js';
import * as DatetimeUtils from "Util/datetime.js";
import { SUPPORTED_EXPERIMENTS } from "Modules/course.experiments.js";

const UUID_RE = /[a-f0-9\-]{36}/;

export default class Client {
    constructor (user) {
        this._snLocked = false;
        this._delayedSnRequests = [];

        this._user = user;
    }

    destroy () {
        let self = this;

        if (this._snLocked) {
            return new Promise(function(resolve, reject) {
                self._destroyCallback = function () {
                    delete self._user;
                    resolve();
                };
            });
        }

        delete this._user;
        return Promise.resolve();
    }

    getUUID () {
        return Promise.resolve().then(function () {
            return getPersistentStorageProvider().getItemAnonymous('clientUUID');
        }).then(function (UUID) {
            if (UUID === null || !UUID_RE.test(UUID)) {
                UUID = uuid.v4();
                return getPersistentStorageProvider().setItemAnonymous('clientUUID', UUID).then(function () {
                    return Promise.resolve(UUID);
                });
            } else {
                return Promise.resolve(UUID);
            }
        });
    }

    resetUUID () {
        console.log(`Client.resetUUID()`);
        return getPersistentStorageProvider().setItemAnonymous('clientUUID', uuid.v4());
    }

    getUnauthenticatedUserUUID () {
        return Promise.resolve().then(function () {
            return getPersistentStorageProvider().getItemAnonymous('unauthenticatedUserUUID');
        }).then(function (UUID) {
            if (UUID === null || !UUID_RE.test(UUID)) {
                UUID = uuid.v4();
                return getPersistentStorageProvider().setItemAnonymous('unauthenticatedUserUUID', UUID).then(function () {
                    return Promise.resolve(UUID);
                });
            } else {
                return Promise.resolve(UUID);
            }
        });
    }

    async getClientInfo(ts = DatetimeUtils.getCurrentFormattedTs()) {
        return {
            uuid: await this.getUUID(),
            request_ts: ts,
            time_correction: DatetimeUtils.getTimezoneOffset(),
            supported_experiments: SUPPORTED_EXPERIMENTS,
        };
    }

    removeUnauthenticatedUserUUID() {
        return getPersistentStorageProvider().removeItemAnonymous('unauthenticatedUserUUID');
    }

    _setStorageItem(key, value) {
        if (this._user) {
            return this._user.getStorage().setItem(key, value);
        } else {
            return getPersistentStorageProvider(null).setItemAnonymous(key, value);
        }
    }

    _getStorageItem(key, getAnonymous = false) {
        if (this._user && !getAnonymous) {
            return this._user.getStorage().getItem(key);
        } else {
            return getPersistentStorageProvider(null).getItemAnonymous(key);
        }
    }

    _removeStorageItem(key, fromAnonymous = false) {
        if (this._user && !fromAnonymous) {
            return this._user.getStorage().removeItem(key);
        } else {
            return getPersistentStorageProvider(null).removeItemAnonymous(key);
        }
    }


    getSn () {
        // if (this._user == null) {
        //     throw Error('User object is required to get the sn');
        // }

        if (this._snLocked) {
            let _resolve, _reject;

            this._delayedSnRequests.push(() => {
                this.getSn().then(_resolve, _reject);
            });

            return new Promise((resolve, reject) => {
                _resolve = resolve;
                _reject = reject;
            });
        } else {
            this._snLocked = true;

            return new Promise((resolve, reject) => {
                Promise.resolve()
                    .then(() => this.getUUID())
                    .then(client_uuid => {
                        return Promise
                            .all([
                                this._getStorageItem(`${client_uuid}/client_event_sn`),
                                this._getStorageItem(`${client_uuid}/client_event_sn`, true),
                            ])
                            .catch(error => {
                                Raven.captureException(error, { level: 'error' });
                                return Promise.resolve()
                                    .then(() => Promise.all([
                                        this._removeStorageItem(`${client_uuid}/client_event_sn`),
                                        this._removeStorageItem(`${client_uuid}/client_event_sn`, true)
                                    ]))
                                    .then(() => this.resetUUID())
                                    .then(() => Promise.resolve([null, null]));
                            })
                            .then(([client_event_sn, anonymous_client_event_sn]) => {
                                if (client_event_sn == null) {
                                    client_event_sn = (anonymous_client_event_sn != null) ? anonymous_client_event_sn : 0;
                                }
                                client_event_sn++;
                                return this._setStorageItem(`${client_uuid}/client_event_sn`, client_event_sn)
                                    .then(() =>  {
                                        resolve(client_event_sn);
                                        this._snLocked = false;
                                        let delayedSnRequest = this._delayedSnRequests.shift();
                                        if (delayedSnRequest) {
                                            delayedSnRequest();
                                        } else if (this._destroyCallback !== undefined) {
                                            this._destroyCallback();
                                        }
                                    });
                            });
                    })
                    .catch(error => {
                        console.log(`Client.getSn() Just catching the error to see it`, error.name);
                        console.log(`Client.getSn() Just catching the error to see it`, error);
                        return Promise.reject(error);
                    })
                    .catch(reject);
            });
        }
    }
}
