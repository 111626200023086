import AsyncDestroyable from '../async.destroyable.js';

export class Classrooms extends AsyncDestroyable {

    constructor () {
        super(['initialize']);
        this._apiClient = null;
    }

    initialize (apiClient) {
        this._apiClient = apiClient;
    }

    getCourseUuidByClassroomCode (classroomCode) {
        return Promise.resolve()
            .then( () => this._apiClient.r.classrooms.by_code.classroom_code(classroomCode).get())
            .then( response => {
                return Promise.resolve(response.course_uuid);
            });
    }
}

export default new Classrooms();
