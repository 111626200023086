
'use strict';

import { QuestionList } from './question.list.js';

export class NewWordList extends QuestionList {

    constructor (course, assetLoader, Modules) {
        super(course, undefined, assetLoader, Modules);
        this.__name = 'NewWordList';

        this.ASSET_LOADING_PRIORITY = 20;
    }

    _questionFilter (questionData) {
        return !this._Modules.Question.isFastTracking(questionData) && this._Modules.Question.isNew(questionData);
    }
}
