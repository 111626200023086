import getConfigValue from '../../util/configuration.js';
import { GOOGLE_SIGNIN } from '../../util/credentials.js';

export function loadGooglePlatformSdk () {

    const firstModule = document.getElementsByTagName('script')[0];
    const platformSdkModule = document.createElement('script');

    platformSdkModule.src = '//apis.google.com/js/platform.js?onload=googlePlatformLoaded';
    platformSdkModule.async = true;

    firstModule.parentNode.insertBefore(platformSdkModule, firstModule);

    window.googlePlatformLoaded = sdkLoaded;
}

function sdkLoaded () {

    if (window.gapi) {
        window.gapi.load('auth2', () => {
            initializeAuthentication();
        });
    }
}

function initializeAuthentication () {

    if (window.gapi && window.gapi.auth2) {
        window.gapi.auth2.init({
            client_id: getConfigValue('google-app-id'),
            scope: 'openid email profile'
        }).then( googleAuth => {
            console.info('GoogleAuth initialized.');

            googleAuth.isSignedIn.listen( isSignedIn => {

                if (isSignedIn) {

                    const googleUser = googleAuth.currentUser.get();

                    if (googleUser) {

                        const profile = googleUser.getBasicProfile();

                        if (window.FederatedCredential) {

                            const credential = new window.FederatedCredential({
                                id: profile.getEmail(),
                                name: profile.getName(),
                                iconURL: profile.getImageUrl(),
                                provider: GOOGLE_SIGNIN
                            });

                            navigator.credentials.store(credential);
                        }
                    }
                }

            });
        });
    }
}

export async function signInWithGoogle () {
    if (window.gapi && window.gapi.auth2) {
        try {
            // https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
            const { code } = await window.gapi.auth2.getAuthInstance().grantOfflineAccess({
                prompt: 'select_account',
                scope: 'openid email profile'
            });
            return code;
        } catch (error) {
            console.error('GoogleAuth grantOfflineAccess returned with error', error);
            // popup_closed_by_google isn't listed by the API doc, but leaving it here because it was here
            // previously and I don't know why exactly - Karl
            if (error.error === 'popup_closed_by_user' || error.error === 'popup_closed_by_google') {
                throw 'popup-closed';
            } else {
                throw error;
            }
        }
    } else {
        throw new Error('Unable to access window.gapi or window.gapi.auth2');
    }
}
