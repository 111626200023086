var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "7",
          height: "12",
          viewBox: "0 0 7 12",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        staticClass: "arrow-3_svg__arrow",
        attrs: {
          fill: "#2C3143",
          d: "M3.82 6L.245 10.387a1 1 0 101.558 1.253l4.02-4.997a1 1 0 000-1.253L1.803.393A1 1 0 00.245 1.647L3.82 6z",
          "fill-rule": "evenodd",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }