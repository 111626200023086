<template>
    <div class="variation-icon" :title="title">
        <component v-bind:is="icon" />
    </div>
</template>

<script>
    import GeneralVariationIconLarge from 'Images/variations/40x40/general.svg';
    import DefaultVariationIconLarge from 'Images/variations/40x40/default.svg';
    import CourseWizardVariationIconLarge from 'Images/variations/40x40/lesson.svg';
    import LoveVariationIconLarge from 'Images/variations/40x40/love.svg';
    import HolidayInSpainVariationIconLarge from 'Images/variations/40x40/holiday_in_spain.svg';
    import AnimalVariationIconLarge from 'Images/variations/40x40/animal.svg';
    import BeautyVariationIconLarge from 'Images/variations/40x40/beauty.svg';
    import BusinessVariationIconLarge from 'Images/variations/40x40/business.svg';
    import BusinessMailVariationIconLarge from 'Images/variations/40x40/business_mail.svg';
    import ClothesVariationIconLarge from 'Images/variations/40x40/clothes.svg';
    import ColoursVariationIconLarge from 'Images/variations/40x40/colours.svg';
    import CommonErrorsVariationIconLarge from 'Images/variations/40x40/common_errors.svg';
    import DebateVariationIconLarge from 'Images/variations/40x40/debate.svg';
    import EmotionsVariationIconLarge from 'Images/variations/40x40/emotions.svg';
    import EnvironmentVariationIconLarge from 'Images/variations/40x40/environment.svg';
    import FootballVariationIconLarge from 'Images/variations/40x40/football.svg';
    import FFriendsVariationIconLarge from 'Images/variations/40x40/ffriends.svg';
    import FruitsVariationIconLarge from 'Images/variations/40x40/fruits.svg';
    import HealthVariationIconLarge from 'Images/variations/40x40/health.svg';
    import HouseVariationIconLarge from 'Images/variations/40x40/house.svg';
    import HumanBodyVariationIconLarge from 'Images/variations/40x40/human_body.svg';
    import IdiomsVariationIconLarge from 'Images/variations/40x40/idioms.svg';
    import Junior1000VariationIconLarge from 'Images/variations/40x40/junior_1000.svg';
    import LawVariationIconLarge from 'Images/variations/40x40/law.svg';
    import LeisureVariationIconLarge from 'Images/variations/40x40/leisure.svg';
    import LifeAbroadVariationIconLarge from 'Images/variations/40x40/life_abroad.svg';
    import MedicalVariationIconLarge from 'Images/variations/40x40/medical.svg';
    import NutritionVariationIconLarge from 'Images/variations/40x40/nutrition.svg';
    import ProVerbsVariationIconLarge from 'Images/variations/40x40/proverbs.svg';
    import QuotesVariationIconLarge from 'Images/variations/40x40/quotes.svg';
    import SelectivoVariationIconLarge from 'Images/variations/40x40/selectivo.svg';
    import SportsSummerVariationIconLarge from 'Images/variations/40x40/sports_summer.svg';
    import SportsWinterVariationIconLarge from 'Images/variations/40x40/sports_winter.svg';
    import TaxiVariationIconLarge from 'Images/variations/40x40/taxi.svg';
    import TechnologyVariationIconLarge from 'Images/variations/40x40/technology.svg';
    import ToeflVariationIconLarge from 'Images/variations/40x40/toefl.svg';
    import ToeicVariationIconLarge from 'Images/variations/40x40/toeic.svg';
    import TravelVariationIconLarge from 'Images/variations/40x40/travel.svg';
    import WeatherVariationIconLarge from 'Images/variations/40x40/weather.svg';
    import WeekendInParisVariationIconLarge from 'Images/variations/40x40/weekend_in_paris.svg';
    import WizardVariationIconLarge from 'Images/variations/40x40/wizard.svg';
    import NytVariationIconLarge from 'Images/variations/40x40/nyt.svg';
    import CardinalNumbersIconLarge from 'Images/variations/40x40/cardinal_numbers.svg';
    import DemocracyIconLarge from 'Images/variations/40x40/democracy.svg';
    import DemocracyUAIconLarge from 'Images/variations/40x40/democracy_ua.svg';
    import HiraganaIconLarge from 'Images/variations/40x40/hiragana.svg';
    import KanjiIconLarge from 'Images/variations/40x40/kanji.svg';
    import KatakanaIconLarge from 'Images/variations/40x40/katakana.svg';
    import HangeulIconLarge from 'Images/variations/40x40/ko_hangeul.svg';
    import OrdinalNumbersIconLarge from 'Images/variations/40x40/ordinal_numbers.svg';
    import PersonalLifeIconLarge from 'Images/variations/40x40/personal_life.svg';
    import ScienceIconLarge from 'Images/variations/40x40/science.svg';
    import TimeDateIconLarge from 'Images/variations/40x40/time_date.svg';

    import GeneralVariationIconSmall from 'Images/variations/24x24/general.svg';
    import DefaultVariationIconSmall from 'Images/variations/24x24/default.svg';
    import CourseWizardVariationIconSmall from 'Images/variations/24x24/lesson.svg';
    import LoveVariationIconSmall from 'Images/variations/24x24/love.svg';
    import HolidayInSpainVariationIconSmall from 'Images/variations/24x24/holiday_in_spain.svg';
    import AnimalVariationIconSmall from 'Images/variations/24x24/animal.svg';
    import BeautyVariationIconSmall from 'Images/variations/24x24/beauty.svg';
    import BusinessVariationIconSmall from 'Images/variations/24x24/business.svg';
    import BusinessMailVariationIconSmall from 'Images/variations/24x24/business_mail.svg';
    import ClothesVariationIconSmall from 'Images/variations/24x24/clothes.svg';
    import ColoursVariationIconSmall from 'Images/variations/24x24/colours.svg';
    import CommonErrorsVariationIconSmall from 'Images/variations/24x24/common_errors.svg';
    import DebateVariationIconSmall from 'Images/variations/24x24/debate.svg';
    import EmotionsVariationIconSmall from 'Images/variations/24x24/emotions.svg';
    import EnvironmentVariationIconSmall from 'Images/variations/24x24/environment.svg';
    import FootballVariationIconSmall from 'Images/variations/24x24/football.svg';
    import FFriendsVariationIconSmall from 'Images/variations/24x24/ffriends.svg';
    import FruitsVariationIconSmall from 'Images/variations/24x24/fruits.svg';
    import HealthVariationIconSmall from 'Images/variations/24x24/health.svg';
    import HouseVariationIconSmall from 'Images/variations/24x24/house.svg';
    import HumanBodyVariationIconSmall from 'Images/variations/24x24/human_body.svg';
    import IdiomsVariationIconSmall from 'Images/variations/24x24/idioms.svg';
    import Junior1000VariationIconSmall from 'Images/variations/24x24/junior_1000.svg';
    import LawVariationIconSmall from 'Images/variations/24x24/law.svg';
    import LeisureVariationIconSmall from 'Images/variations/24x24/leisure.svg';
    import LifeAbroadVariationIconSmall from 'Images/variations/24x24/life_abroad.svg';
    import MedicalVariationIconSmall from 'Images/variations/24x24/medical.svg';
    import NutritionVariationIconSmall from 'Images/variations/24x24/nutrition.svg';
    import ProVerbsVariationIconSmall from 'Images/variations/24x24/proverbs.svg';
    import QuotesVariationIconSmall from 'Images/variations/24x24/quotes.svg';
    import SelectivoVariationIconSmall from 'Images/variations/24x24/selectivo.svg';
    import SportsSummerVariationIconSmall from 'Images/variations/24x24/sports_summer.svg';
    import SportsWinterVariationIconSmall from 'Images/variations/24x24/sports_winter.svg';
    import TaxiVariationIconSmall from 'Images/variations/24x24/taxi.svg';
    import TechnologyVariationIconSmall from 'Images/variations/24x24/technology.svg';
    import ToeflVariationIconSmall from 'Images/variations/24x24/toefl.svg';
    import ToeicVariationIconSmall from 'Images/variations/24x24/toeic.svg';
    import TravelVariationIconSmall from 'Images/variations/24x24/travel.svg';
    import WeatherVariationIconSmall from 'Images/variations/24x24/weather.svg';
    import WeekendInParisVariationIconSmall from 'Images/variations/24x24/weekend_in_paris.svg';
    import WizardVariationIconSmall from 'Images/variations/24x24/wizard.svg';
    import NytVariationIconSmall from 'Images/variations/24x24/nyt.svg';
    import CardinalNumbersIconSmall from 'Images/variations/24x24/cardinal_numbers.svg';
    import DemocracyIconSmall from 'Images/variations/24x24/democracy.svg';
    import DemocracyUAIconSmall from 'Images/variations/24x24/democracy_ua.svg';
    import HiraganaIconSmall from 'Images/variations/24x24/hiragana.svg';
    import KanjiIconSmall from 'Images/variations/24x24/kanji.svg';
    import KatakanaIconSmall from 'Images/variations/24x24/katakana.svg';
    import HangeulIconSmall from 'Images/variations/24x24/ko_hangul.svg';
    import OrdinalNumbersIconSmall from 'Images/variations/24x24/ordinal_numbers.svg';
    import PersonalLifeIconSmall from 'Images/variations/24x24/personal_life.svg';
    import ScienceIconSmall from 'Images/variations/24x24/science.svg';
    import TimeDateIconSmall from 'Images/variations/24x24/time_date.svg';

    export default {
        name: 'variation-icon',
        props: {
            variation: {
                type: Object,
            },
            variation_icon: {
                type: String
            },
            size: {
                type: String,
                default: 'large'
            },
            title: {
                type: String
            }
        },
        components: {
            GeneralVariationIconLarge,
            DefaultVariationIconLarge,
            CourseWizardVariationIconLarge,
            LoveVariationIconLarge,
            HolidayInSpainVariationIconLarge,
            AnimalVariationIconLarge,
            BeautyVariationIconLarge,
            BusinessVariationIconLarge,
            BusinessMailVariationIconLarge,
            ClothesVariationIconLarge,
            ColoursVariationIconLarge,
            CommonErrorsVariationIconLarge,
            DebateVariationIconLarge,
            EmotionsVariationIconLarge,
            EnvironmentVariationIconLarge,
            FootballVariationIconLarge,
            FFriendsVariationIconLarge,
            FruitsVariationIconLarge,
            HealthVariationIconLarge,
            HouseVariationIconLarge,
            HumanBodyVariationIconLarge,
            IdiomsVariationIconLarge,
            Junior1000VariationIconLarge,
            LawVariationIconLarge,
            LeisureVariationIconLarge,
            LifeAbroadVariationIconLarge,
            MedicalVariationIconLarge,
            NutritionVariationIconLarge,
            ProVerbsVariationIconLarge,
            QuotesVariationIconLarge,
            SelectivoVariationIconLarge,
            SportsSummerVariationIconLarge,
            SportsWinterVariationIconLarge,
            TaxiVariationIconLarge,
            TechnologyVariationIconLarge,
            ToeflVariationIconLarge,
            ToeicVariationIconLarge,
            TravelVariationIconLarge,
            WeatherVariationIconLarge,
            WeekendInParisVariationIconLarge,
            WizardVariationIconLarge,
            NytVariationIconLarge,
            CardinalNumbersIconLarge,
            DemocracyIconLarge,
            DemocracyUAIconLarge,
            HiraganaIconLarge,
            KanjiIconLarge,
            KatakanaIconLarge,
            HangeulIconLarge,
            OrdinalNumbersIconLarge,
            PersonalLifeIconLarge,
            ScienceIconLarge,
            TimeDateIconLarge,
            GeneralVariationIconSmall,
            DefaultVariationIconSmall,
            CourseWizardVariationIconSmall,
            LoveVariationIconSmall,
            HolidayInSpainVariationIconSmall,
            AnimalVariationIconSmall,
            BeautyVariationIconSmall,
            BusinessVariationIconSmall,
            BusinessMailVariationIconSmall,
            ClothesVariationIconSmall,
            ColoursVariationIconSmall,
            CommonErrorsVariationIconSmall,
            DebateVariationIconSmall,
            EmotionsVariationIconSmall,
            EnvironmentVariationIconSmall,
            FootballVariationIconSmall,
            FFriendsVariationIconSmall,
            FruitsVariationIconSmall,
            HealthVariationIconSmall,
            HouseVariationIconSmall,
            HumanBodyVariationIconSmall,
            IdiomsVariationIconSmall,
            Junior1000VariationIconSmall,
            LawVariationIconSmall,
            LeisureVariationIconSmall,
            LifeAbroadVariationIconSmall,
            MedicalVariationIconSmall,
            NutritionVariationIconSmall,
            ProVerbsVariationIconSmall,
            QuotesVariationIconSmall,
            SelectivoVariationIconSmall,
            SportsSummerVariationIconSmall,
            SportsWinterVariationIconSmall,
            TaxiVariationIconSmall,
            TechnologyVariationIconSmall,
            ToeflVariationIconSmall,
            ToeicVariationIconSmall,
            TravelVariationIconSmall,
            WeatherVariationIconSmall,
            WeekendInParisVariationIconSmall,
            WizardVariationIconSmall,
            NytVariationIconSmall,
            CardinalNumbersIconSmall,
            DemocracyIconSmall,
            DemocracyUAIconSmall,
            HiraganaIconSmall,
            KanjiIconSmall,
            KatakanaIconSmall,
            HangeulIconSmall,
            OrdinalNumbersIconSmall,
            PersonalLifeIconSmall,
            ScienceIconSmall,
            TimeDateIconSmall
        },
        computed: {
            icon: function() {
                if (this.variation && this.variation.type === 'general') {
                    return this.size === 'large' ? GeneralVariationIconLarge : GeneralVariationIconSmall;
                } else if (this.variation && this.variation.type === 'lesson') {
                    return this.size === 'large' ? CourseWizardVariationIconLarge : CourseWizardVariationIconSmall;
                } else {
                    let _icon = null;
                    if (this.variation_icon) {
                        _icon = this.variation_icon
                    } else if (this.variation && this.variation.icon) {
                        _icon = this.variation.icon;
                    }

                    console.log('variation icon', _icon)

                    switch (_icon) {
                        case 'general':
                            return this.size === 'large' ? GeneralVariationIconLarge : GeneralVariationIconSmall;
                        case 'travel':
                            return this.size === 'large' ? TravelVariationIconLarge : TravelVariationIconSmall;
                        case 'business':
                            return this.size === 'large' ? BusinessVariationIconLarge : BusinessVariationIconSmall;
                        case 'business_mail':
                            return this.size === 'large' ? BusinessMailVariationIconLarge : BusinessMailVariationIconSmall;
                        case 'life_abroad':
                            return this.size === 'large' ? LifeAbroadVariationIconLarge : LifeAbroadVariationIconSmall;
                        case 'medical':
                            return this.size === 'large' ? MedicalVariationIconLarge : MedicalVariationIconSmall;
                        case 'toefl':
                            return this.size === 'large' ? ToeflVariationIconLarge : ToeflVariationIconSmall;
                        case 'toeic':
                            return this.size === 'large' ? ToeicVariationIconLarge : ToeicVariationIconSmall;
                        case 'football':
                            return this.size === 'large' ? FootballVariationIconLarge : FootballVariationIconSmall;
                        case 'weekend_in_paris':
                            return this.size === 'large' ? WeekendInParisVariationIconLarge : WeekendInParisVariationIconSmall;
                        case 'holiday_in_spain':
                            return this.size === 'large' ? HolidayInSpainVariationIconLarge : HolidayInSpainVariationIconSmall;
                        case 'junior_1000':
                            return this.size === 'large' ? Junior1000VariationIconLarge : Junior1000VariationIconSmall;
                        case 'love':
                            return this.size === 'large' ? LoveVariationIconLarge : LoveVariationIconSmall;
                        case 'animals':
                            return this.size === 'large' ? AnimalVariationIconLarge : AnimalVariationIconSmall;
                        case 'clothes':
                            return this.size === 'large' ? ClothesVariationIconLarge : ClothesVariationIconSmall;
                        case 'colours':
                            return this.size === 'large' ? ColoursVariationIconLarge : ColoursVariationIconSmall;
                        case 'debate':
                            return this.size === 'large' ? DebateVariationIconLarge : DebateVariationIconSmall;
                        case 'emotions':
                            return this.size === 'large' ? EmotionsVariationIconLarge : EmotionsVariationIconSmall;
                        case 'environment':
                            return this.size === 'large' ? EnvironmentVariationIconLarge : EnvironmentVariationIconSmall;
                        case 'fruits':
                            return this.size === 'large' ? FruitsVariationIconLarge : FruitsVariationIconSmall;
                        case 'health':
                            return this.size === 'large' ? HealthVariationIconLarge : HealthVariationIconSmall;
                        case 'house':
                            return this.size === 'large' ? HouseVariationIconLarge : HouseVariationIconSmall;
                        case 'human_body':
                            return this.size === 'large' ? HumanBodyVariationIconLarge : HumanBodyVariationIconSmall;
                        case 'leisure':
                            return this.size === 'large' ? LeisureVariationIconLarge : LeisureVariationIconSmall;
                        case 'nutrition':
                            return this.size === 'large' ? NutritionVariationIconLarge : NutritionVariationIconSmall;
                        case 'taxi':
                            return this.size === 'large' ? TaxiVariationIconLarge : TaxiVariationIconSmall;
                        case 'technology':
                            return this.size === 'large' ? TechnologyVariationIconLarge : TechnologyVariationIconSmall;
                        case 'weather':
                            return this.size === 'large' ? WeatherVariationIconLarge : WeatherVariationIconSmall;
                        case 'lesson':
                            return this.size === 'large' ? CourseWizardVariationIconLarge : CourseWizardVariationIconSmall;
                        case 'beauty':
                            return this.size === 'large' ? BeautyVariationIconLarge : BeautyVariationIconSmall;
                        case 'common_errors':
                            return this.size === 'large' ? CommonErrorsVariationIconLarge : CommonErrorsVariationIconSmall;
                        case 'ffriends':
                            return this.size === 'large' ? FFriendsVariationIconLarge : FFriendsVariationIconSmall;
                        case 'idioms':
                            return this.size === 'large' ? IdiomsVariationIconLarge : IdiomsVariationIconSmall;
                        case 'law':
                            return this.size === 'large' ? LawVariationIconLarge : LawVariationIconSmall;
                        case 'proverbs':
                            return this.size === 'large' ? ProVerbsVariationIconLarge : ProVerbsVariationIconSmall;
                        case 'quotes':
                            return this.size === 'large' ? QuotesVariationIconLarge : QuotesVariationIconSmall;
                        case 'selectivo':
                            return this.size === 'large' ? SelectivoVariationIconLarge : SelectivoVariationIconSmall;
                        case 'sports_summer':
                            return this.size === 'large' ? SportsSummerVariationIconLarge : SportsSummerVariationIconSmall;
                        case 'sports_winter':
                            return this.size === 'large' ? SportsWinterVariationIconLarge : SportsWinterVariationIconSmall;
                        case 'wizard':
                            return this.size === 'large' ? WizardVariationIconLarge : WizardVariationIconSmall;
                        case 'nyt':
                            return this.size === 'large' ? NytVariationIconLarge : NytVariationIconSmall;
                        case 'ordinal_numbers':
                            return this.size === 'large' ? OrdinalNumbersIconLarge : OrdinalNumbersIconSmall;
                        case 'cardinal_numbers':
                            return this.size === 'large' ? CardinalNumbersIconLarge : CardinalNumbersIconSmall;
                        case 'science':
                            return this.size === 'large' ? ScienceIconLarge : ScienceIconSmall;
                        case 'personal_life':
                            return this.size === 'large' ? PersonalLifeIconLarge : PersonalLifeIconSmall;
                        case 'time_date':
                            return this.size === 'large' ? TimeDateIconLarge : TimeDateIconSmall;
                        case 'democracy':
                            return this.size === 'large' ? DemocracyIconLarge : DemocracyIconSmall;
                        case 'democracy_ua':
                            return this.size === 'large' ? DemocracyUAIconLarge : DemocracyUAIconSmall;
                        case 'hiragana':
                            return this.size === 'large' ? HiraganaIconLarge : HiraganaIconSmall;
                        case 'katakana':
                            return this.size === 'large' ? KatakanaIconLarge : KatakanaIconLSmall;
                        case 'kanji':
                            return this.size === 'large' ? KanjiIconLarge : KanjiIconSmall;
                        case 'ko_hangeul':
                            return this.size === 'large' ? HangeulIconLarge : HangeulIconSmall;
                        case 'default':
                        default:
                            return this.size === 'large' ? DefaultVariationIconLarge : DefaultVariationIconSmall;
                    }
                }
            }
        }
    };
</script>
