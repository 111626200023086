var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.variations
    ? _c("div", { staticClass: "active-variations" }, [
        _c("h2", [
          _c("span", {
            domProps: { innerHTML: _vm._s(this.$i18n("hub_active_courses")) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.variations.length)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list" },
          [
            _vm._l(_vm.visibleVariations, function (variation) {
              return [
                _c("active-variation-item", {
                  key: `active_variation_${variation.uuid}`,
                  attrs: {
                    variation: variation,
                    "is-game-over": _vm.isGameOver,
                    "variation-toggle-disabled":
                      (variation.type === "general" &&
                        _vm.disableGeneralSettings) ||
                      !_vm.isSubscriptionActive,
                  },
                }),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.showToggle
          ? _c("div", { staticClass: "toggleWrapper" }, [
              this.allVisible
                ? _c(
                    "div",
                    {
                      staticClass: "toggleButton hide",
                      on: { click: _vm.toggleVisible },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("hub_active_variations_toggle_hide")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("toggle-arrow"),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "toggleButton",
                      on: { click: _vm.toggleVisible },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("hub_active_variations_toggle_show")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("toggle-arrow"),
                    ],
                    1
                  ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }