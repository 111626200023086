var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "secure" }, [
    _c("div", { staticClass: "illustrations with-gradient" }, [
      _c("div", { staticClass: "items" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("illustration-secure"),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("product_payment_highlights_title_1")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("product_payment_highlights_text_1")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("illustration-no-ads"),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("product_payment_highlights_title_2")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("product_payment_highlights_text_2")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("illustration-cancel"),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("product_payment_highlights_title_3")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("product_payment_highlights_text_3")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }