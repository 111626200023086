var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { "fill-rule": "nonzero", fill: "none" } }, [
        _c("path", {
          attrs: {
            fill: "#FFCC4D",
            d: "M32 16c0 8.836-7.164 16-16 16S0 24.836 0 16 7.164 0 16 0s16 7.164 16 16",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#664500",
            d: "M22.653 26.56c-.04-.16-1.038-3.893-6.653-3.893-5.616 0-6.613 3.733-6.653 3.892a.443.443 0 00.21.493.45.45 0 00.534-.069c.017-.017 1.737-1.65 5.909-1.65s5.893 1.633 5.91 1.65a.446.446 0 00.53.071.445.445 0 00.213-.495zm-8.691-11.188c-2.894-2.894-7.543-2.928-7.74-2.928a.89.89 0 000 1.778c.025 0 1.71.02 3.54.655-.527.57-.873 1.453-.873 2.456 0 1.72.995 3.111 2.222 3.111 1.228 0 2.222-1.392 2.222-3.11 0-.155-.017-.303-.033-.451.012 0 .023.006.033.006a.888.888 0 00.628-1.517zm11.816-2.928c-.197 0-4.846.034-7.74 2.928a.888.888 0 00.629 1.517c.011 0 .021-.006.032-.006-.015.148-.032.296-.032.45 0 1.72.994 3.111 2.222 3.111 1.227 0 2.222-1.392 2.222-3.11 0-1.004-.346-1.888-.873-2.457a12.38 12.38 0 013.542-.655.89.89 0 00-.002-1.778z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }