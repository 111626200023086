import Backbone from 'backbone';
import renderTemplate from '../../util/template.renderer.js';

const VerifyEmailView = Backbone.View.extend({

    name: 'verify-email',

    tagName: 'main',
    className: 'verify-email-view',

    events: {
        'click button.verify-email-continue': 'continueLearning'
    },

    initialize: function (options) {
        console.log("Verify email view init");
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function (data) {
        renderTemplate('verify-email', data, this.$el).then( () => {
            this._postRender();
        });
        return this;
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
    },

    continueLearning: function () {
        this.trigger('continueLearning');
    }
});

export default VerifyEmailView;
