'use strict';
import Backbone from 'backbone';
import BackboneAugmentor from './util/backbone.augmentor.js';
import Raven from 'raven-js';
import "@babel/polyfill";
import _ from 'lodash';
import { blockAdBlock } from './util/blockadblock.js';

import getConfigValue from './util/configuration.js';
import version from './version.js';
import Logger from './util/logger.js';
import ExportUtils from './util/export.js';
import { trackLeanplumEvent } from './util/leanplum.js';
import UserManager from './modules/usermanager.js';
import ControllerManager from './modules/controller.manager.js';
import BackwardCompatibility from './backward.compatibility.js';


/*
 * This file is used as a single entry point for running the app both in requirejs and webpack mode.
 * This is required so that we could initialize some things (error capturing and login) before we
 * load any of the actual application code. Since loading the code has side effects and therefore possible
 * exceptions and logs must be captured.
 * */


// Augment backbones extend to have inheritance semantics that automatically call parents' initialize and remove
BackboneAugmentor.augment(Backbone);

// Initialize the UserManager. From this point onward UserManager.instance must be used to access the it.
UserManager.init();

// Initialize the ControllerManager. From this point onward, use ControllerManager.instance.
ControllerManager.init();

// Set up log capture
var logger = new Logger(window.console, {
    'log-max-size': getConfigValue('log-max-size'),
    'environment': getConfigValue('environment')
});
ExportUtils.export('app.logger', logger);

var originalCaptureException = Raven.captureException;
Raven.captureException = function () {
    console.error('Intercepted raven error', arguments[0]);
    originalCaptureException.apply(Raven, arguments);
};

var originalCaptureMessage = Raven.captureMessage;
Raven.captureMessage = function () {
    console.warn('Intercepted raven message', arguments[0]);
    originalCaptureMessage.apply(Raven, arguments);
};

// Set up Raven for Sentry monitoring
var ravenDSN = getConfigValue('web-client-sentry-dsn');
var whitelistUrls = _.map(getConfigValue('web-client-sentry-url-whitelist'), function (x) {return new RegExp(x);});

var localEnvironment = getConfigValue('environment') === 'local';

if (ravenDSN) {
    Raven.config(ravenDSN, {
        release: version.version,
        whitelistUrls: whitelistUrls,
        autoBreadcrumbs: { // Disable breadcrumbs capturing in local mode, because it can disturb logging.
            'xhr': !localEnvironment,      // XMLHttpRequest
            'console': !localEnvironment,  // console logging
            'dom': !localEnvironment,       // DOM interactions, i.e. clicks/typing
            'location': !localEnvironment  // url changes, including pushState/popState
        }
    }).install();


    Backbone.on('userSignedIn',
        /**
         * @param {User} user - initialized User object
         */
        user => {
            Raven.setUserContext({
                id: user.UUID
            });
            if (user.hasActiveCourse()) {
                let course = user.getCourse(),
                    course_info = course.getInfo();

                Raven.setExtraContext({
                    course_uuid: course.UUID,
                    source_language: course_info.source_language,
                    target_language: course_info.target_language
                });
            } else {
                Raven.setExtraContext();
            }
            trackLeanplumEvent('log-in', {logged_in: true});
        });

    Backbone.on('userSignedOut', function () {
        // Empty call to setUserContext removes the user data.
        Raven.setUserContext();
        Raven.setExtraContext();
        trackLeanplumEvent('log-out', {logged_in: false});
    });

    Backbone.on('userCourseChanged',
        /**
         * @param {UserCourse} course
         */
        course => {
            let course_info = course.getInfo();
            Raven.setExtraContext({
                course_uuid: course.UUID,
                source_language: course_info.source_language,
                target_language: course_info.target_language
            });
        });

    Promise.resolve()
        .then(() => blockAdBlock.onDetected())
        .then(() => {
            console.log(`adblock detected`);
            Raven.setExtraContext({
                adblock_detected: true
            });
        });

    Promise.resolve()
        .then(() => blockAdBlock.onNotDetected())
        .then(() => {
            console.log(`adblock not detected`);
            Raven.setExtraContext({
                adblock_detected: true
            });
        });
}

Promise.resolve().then(function () {
    return BackwardCompatibility.applyPatches();
}).then(function () {

    console.info(`Landed on "${window.location.href}"`);
    console.info('Starting application with version:', version.version);

    import(  // jshint ignore: line
        /* webpackMode: "eager" */
        './app.js');  // jshint ignore: line

}).catch(function (error) {
    Raven.captureException(error, { level: 'error' });
});
