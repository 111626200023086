var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal-popup",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-popup-window", style: { width: _vm.base_width } },
        [
          _vm.closable
            ? _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("header", [
            _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } }),
            _vm._v(" "),
            _vm.subtitle
              ? _c("h3", { domProps: { innerHTML: _vm._s(_vm.subtitle) } })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }