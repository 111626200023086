<template>
    <div class="tutor">
        <loader :centered="true" v-if="!grammarTableInfo" />
        <template v-else>
            <div class="header">
                <div class="title" v-html="grammarTableInfo.title" />
                <CloseButton @click="close" class="close" />
            </div>
            <div class="content">
                <div class="sidebar">
                    <div class="item" v-for="section in grammarTableInfo.sections" @click="openSection(section)" :class="{ active: (activeSection && section.id === activeSection.id) }">
                        {{ section.title }}
                    </div>
                </div>
                <div class="body">
                    <template v-if="activeSection">
                        <div class="items">
                            <div class="item target" v-for="item in activeSection.items" :class="{ heading: item.type === 'heading', item: item.type === 'item'}">
                                <p class="heading" v-if="item.type === 'heading'">{{ item.heading }}</p>
                                <template v-else>
                                    <div class="item-row">
                                    <span v-if="item.prefixes" class="prefix">
                                        {{ item.prefixes.join(', ') }}
                                    </span>
                                    <span class="form">
                                        {{ item.forms.join(', ') }}
                                    </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="comment" v-if="activeSection.comment" v-html="activeSection.comment" />
                        <div class="description" v-if="activeSection.description">
                            <h3 v-html="activeSection.description.title" />
                            <p v-html="activeSection.description.text" />
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Loader from "ViewComponent/loader.vue";
    import CloseButton from 'Images/close-24x24.svg';

    export default {
        name: 'tutor',
        components: { Loader, CloseButton },
        props: {
            question: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                grammarTableInfo: null,
                activeSection: null
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            openSection(section) {
                this.activeSection = section;
            },
            async getGrammarTableFromPromise () {
                return Promise.all([
                    this.question.grammarTablePromise
                ]).then(([grammarTable]) => {
                    this.grammarTableInfo = grammarTable;
                });
            }
        },
        async mounted() {
            await this.getGrammarTableFromPromise();
        },
        watch: {
            grammarTableInfo(newValue, oldValue) {
                if (newValue !== oldValue && this.grammarTableInfo.sections && this.grammarTableInfo.sections.length > 0)  {
                    this.openSection(this.grammarTableInfo.sections[0]);
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.tutor {
        display: flex;
        height: auto;
        background-color: white;
        border-radius: pxToRem(12);
        flex-direction: column;
        > div.header {
            padding: 1rem;
            border-bottom: 1px solid $colorBackgroundDarker;
            display: flex;
            flex-direction: row;
            align-items: center;
            > div.title {
                background: #EEF0F4;
                border-radius: pxToRem(12);
                padding: .5rem 1rem;
                > tgt {
                    color: $colorTargetPrimary;
                    font-family: $targetLanguageFonts;
                }
            }
            > svg.close {
                cursor: pointer;
                transition: 100ms ease-in-out;
                margin-left: auto;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
        > div.content {
            display: grid;
            height: 100%;
            grid-template-columns: auto 200px;
            grid-template-rows: auto;
            grid-template-areas:
              "tutor-body tutor-sidebar";
            @include respond-to('small') {
                grid-template-columns: auto 160px;
            }
            > div.body {
                grid-area: tutor-body;
                padding: 1rem;
                overflow-y: auto;
                @include minimalistScrollbar;
                > div.comment {
                    margin-top: 1rem;
                    font-weight: $font-bold;
                    tgt {
                        color: $colorTargetPrimary;
                        font-family: $targetLanguageFonts;
                    }
                }
                > div.items {
                    > div.item {
                        > div.item-row {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            > span.form {
                                flex: 2 0 auto;
                                text-align: left;
                                color: $colorTargetPrimary;
                                margin-left: 1rem;
                            }
                            > span.prefix {
                                flex: 0 1 auto;
                                text-align: right;
                                color: $colorTargetSecondary;
                                min-width: 30%;
                            }
                        }
                    }
                }
                > div.description {
                    margin-top: 2rem;
                    > h3 {
                        margin-bottom: 1rem;
                    }
                    > h3,
                    > p {
                        > tgt {
                            color: $colorTargetPrimary;
                            font-family: $targetLanguageFonts;
                        }
                    }
                }
            }
            > div.sidebar {
                grid-area: tutor-sidebar;
                max-width: pxToRem(200);
                display: flex;
                flex-direction: column;
                background-color: $colorBackgroundGray;
                overflow-y: auto;
                @include minimalistScrollbar;
                > div.item {
                    display: flex;
                    padding: .5rem;
                    justify-content: center;
                    align-items: center;
                    background-color: $colorBackgroundGray;
                    &.active,
                    &:hover {
                        background-color: $colorBackgroundDarker;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>
