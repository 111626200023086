
import ControllerFactory from '../modules/controller.factory.js';

import { parse as parse_qs } from '../util/query-string.js';
import i18nUtils from '../util/i18n.js';

import UserModel from '../model/user.model.js';

import VerifyEmailView from '../view/signin/verify.email.view.js';


export class VerifyEmailController {

    go (appView, router, queryString) {
        let { code } = parse_qs(queryString);

        if (this.verifyEmailView !== undefined) {
            this.verifyEmailView.off();
            this.verifyEmailView.remove();
        }

        this.verifyEmailView = new VerifyEmailView();

        this.verifyEmailView.on('continueLearning', this.continueLearning, this);

        appView.setMainView(this.verifyEmailView, true);

        if (code) {
            return Promise.resolve()
                .then(() => UserModel.verifyEmail(code))
                .then(data =>  Promise.resolve()
                    .then(() => i18nUtils.setInterfaceLanguage(data.interface_language, false))
                    .then(() => this.verifyEmailView.render(data))
                )
                .catch(() => {
                    this.verifyEmailView.render({status: 'error'});
                });
        }
    }

    continueLearning () {
        UserModel.assureSignedIn('verify-email');
    }
}

export const verify_email_controller_factory = new ControllerFactory(VerifyEmailController);
