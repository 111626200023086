var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8.5 7.5h6M8.5 10.5h3",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.5 3.5h.75v-.75h-.75v.75zm-14 16.25a.75.75 0 000 1.5v-1.5zm13.25-4.25a.75.75 0 001.5 0h-1.5zM13 21.25a.75.75 0 000-1.5v1.5zM3.75 15.5a.75.75 0 001.5 0h-1.5zM6.5 4.25h12v-1.5h-12v1.5zm11.25-.75v12h1.5v-12h-1.5zM13 19.75H4.5v1.5H13v-1.5zM5.25 15.5v-10h-1.5v10h1.5zM6.5 2.75A2.75 2.75 0 003.75 5.5h1.5c0-.69.56-1.25 1.25-1.25v-1.5z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.5 15.5h5l-5 5v-5z",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }