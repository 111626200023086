var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: { d: "M22 7a4 4 0 11-8 0 4 4 0 018 0z", fill: "#AC6ED1" },
      }),
      _c("path", {
        attrs: {
          d: "M4 8h13v10c0 1-.643 2.063-1.686 2.52-1.042.457-2.56.353-3.314-.02",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 4v8c0 4.917-1.2 7.865-4 9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }