var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "feedback-modal",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "feedback-modal-window" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c("h2", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("card_feedback_report_problem_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "radio-button-list" },
                  [
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_sentence_quality",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel("report_sentence_quality"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport("report_sentence_quality")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_sentence_translation_issue",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel(
                          "report_sentence_translation_issue"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport(
                            "report_sentence_translation_issue"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_incorrect_grammar",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel(
                          "report_incorrect_grammar"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport("report_incorrect_grammar")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_word_translation_issue",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel(
                          "report_word_translation_issue"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport(
                            "report_word_translation_issue"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_synonym_not_accepted",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel(
                          "report_synonym_not_accepted"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport("report_synonym_not_accepted")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_incorrect_swt_tooltip",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel(
                          "report_incorrect_swt_tooltip"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport(
                            "report_incorrect_swt_tooltip"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("radio-button", {
                      attrs: {
                        report_value: "report_broken_audio",
                        selected_report_value: _vm.selectedReportValue,
                        label: this.getFeedbackLabel("report_broken_audio"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeReport("report_broken_audio")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.cardHasVisuals
                      ? _c("radio-button", {
                          attrs: {
                            report_value: "report_incorrect_picture",
                            selected_report_value: _vm.selectedReportValue,
                            label: this.getFeedbackLabel(
                              "report_incorrect_picture"
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeReport(
                                "report_incorrect_picture"
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLimitFreeInput
                      ? _c("radio-button", {
                          attrs: {
                            report_value: "report_other",
                            selected_report_value: _vm.selectedReportValue,
                            label: this.getFeedbackLabel("report_other"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeReport("report_other")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isLimitFreeInput
                  ? _c("div", { staticClass: "description" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "card_feedback_description_label_compulsory"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userDescription,
                            expression: "userDescription",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.descriptionPlaceholder,
                          maxlength: "2000",
                        },
                        domProps: { value: _vm.userDescription },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.userDescription = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "action-buttons" },
                [
                  _c("v-button", {
                    attrs: {
                      filled: true,
                      color: "primary",
                      disabled: !this.selectedReportValue,
                      i18n: { id: "card_feedback_send_feedback" },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.sendFeedback.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: { i18n: { id: "feedback_modal_cancel" } },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }