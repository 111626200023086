var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("rect", {
        attrs: {
          x: "9",
          y: "8",
          width: "6",
          height: "5",
          rx: "1",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.5 13H14a1 1 0 001-1V5a1 1 0 00-1-1H3a1 1 0 00-1 1v11l3-3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 17h-5a1 1 0 01-1-1V9a1 1 0 011-1h11a1 1 0 011 1v11l-3-3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }