var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "insights" },
    [
      _vm.page === "calendar"
        ? _c("calendar")
        : _vm.page === "wordlist"
        ? _c("wordlist")
        : _vm.page === "vocabulary"
        ? _c("vocabulary")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }