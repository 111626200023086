<template>
    <div class="lesson-meta">
        <div class="meta-block separate box">
            <cards-toggle-menu :lesson="this.lesson" :cards="this.cards" />
        </div>
        <div class="meta-block">
            <div class="text">
                <div class="title" v-html="this.$i18n('course_wizard_course_description_title')" />
                <div class="subtitle" v-html="this.$i18n('course_wizard_course_description_body')" />
            </div>
            <div class="textarea">
                <textarea rows="6" maxlength="2000" v-model.lazy="description" :placeholder="this.$i18n('course_wizard_course_description_screen_body')" data-id="description" />
            </div>
        </div>
    </div>
</template>

<script>
    import CardsToggleMenu from "./cards-toggle-menu.vue";

    export default {
        name: 'lesson-meta',
        components: {
            CardsToggleMenu
        },
        props: {
            course_info: {
                type: Object,
                required: true
            },
            lesson: {
                type: Object,
                required: true
            },
            cards: {
                type: Array,
                default: []
            },
        },
        data() {
            return {
                description: this.lesson.description,
            }
        },
        watch: {
            description(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$emit('update-meta', { field: 'description', value: newValue })
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.lesson-meta {
        min-width: pxToRem(500);
        margin: 0 auto;

        @include respond-to('medium') {
            min-width: unset;
            margin: unset;
            flex: 1;
        }

        > div.meta-block {
            display: flex;
            flex-direction: column;
            &.with-checkbox {
                flex-direction: row;
            }
            padding: pxToRem(16) 0;
            > div.text {
                > div.title {
                    font-weight: $font-bold;
                    font-size: fontSize(18);
                }
            }

            > div.checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: pxToRem(16);
                margin-left: auto;
            }
            &.separate {
                border-bottom: 1px solid $colorBackgroundDarker;
            }

            > div.textarea {
                margin-top: pxToRem(16);
                > textarea {
                    width: 100%;
                    background: $colorBackgroundDarker;
                    color: $colorSourcePrimary;
                    padding: pxToRem(16);

                    @include minimalistScrollbar;

                    &:focus {
                        background: $colorBackgroundDefault;

                    }
                }
            }
            &.box {
                //@include elevated;
                background-color: #fff;
                border-radius: .5rem;
                padding: 1rem;
            }
        }
    }
</style>
