var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M15.628 13.778l-.739-.674.739.674zm-7.256 0l-.739.674.739-.674zM10 18H9v1h1v-1zm4 0v1h1v-1h-1zM8.231 8.788a1 1 0 10-1.893-.643l1.893.643zm1.757-4.43a1 1 0 00.69 1.876l-.69-1.877zM16 10.2a4.294 4.294 0 01-1.11 2.904l1.477 1.348A6.295 6.295 0 0018 10.2h-2zM12 6c2.172 0 4 1.843 4 4.2h2C18 6.813 15.35 4 12 4v2zm-2.89 7.104A4.294 4.294 0 018 10.2H6c0 1.64.618 3.14 1.633 4.252l1.478-1.348zM11 18v-1.796H9V18h2zm3-1h-4v2h4v-2zm-1-.796V18h2v-1.796h-2zM8 10.2c0-.497.082-.972.231-1.412l-1.893-.643A6.379 6.379 0 006 10.2h2zm2.678-3.966A3.82 3.82 0 0112 6V4a5.82 5.82 0 00-2.012.357l.69 1.877zm-3.045 8.218c.168.184.37.386.536.556.177.182.335.351.471.518.294.361.36.57.36.678h2c0-.82-.44-1.488-.808-1.94a9.894 9.894 0 00-.59-.65c-.197-.204-.352-.357-.491-.51l-1.478 1.348zm7.256-1.348c-.139.153-.294.306-.491.51a9.896 9.896 0 00-.59.65c-.367.452-.808 1.12-.808 1.94h2c0-.107.066-.317.36-.678.136-.167.294-.336.471-.518.165-.17.368-.372.536-.556l-1.478-1.348z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10 22h4M2 11h1.5M20.5 11H22M19 18l1 1M5 18l-1 1M19 4l1-1M5 4L4 3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }