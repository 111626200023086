
import $ from 'jquery';
import Backbone from 'backbone';

import renderTemplate from '../../util/template.renderer.js';
import URI from '../../util/uri.js';

import { ConfirmationView, CONFIRM_ACTION } from '../util/confirmation.view.js';
import i18nUtils from '../../util/i18n.js';

import UserManager from '../../modules/usermanager.js';

const SubscriptionTabView = Backbone.View.extend({

    name: 'subscription',

    tagName: 'section',
    className: 'tab-subscription',

    events: {
        'click a.upsell-button[data-action="subscribe"]': 'subscribe',
        'click a.manage-button[data-action="change-subscription"]': 'manageSubscription',
        'click a.manage-button[data-action="change-card"]': 'manageCard',
        'click button.cancel-subscription': 'cancelSubscription'
    },

    initialize: function () {
        console.log('Subscription Tab view init');
    },

    setProps: function (props) {
        this.props = props;
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        return Promise.resolve()
            .then(() => renderTemplate('account/tab-subscription', this.props, this.$el))
            .then(() => this._postRender());
    },

    _postRender: function () {
        return Promise.resolve();
    },

    subscribe: function () {
        UserManager.instance.getUser().getEventSender().sendNavigationEvent('account-post-subscription', 'open', null);
        const subscribeUri = 'lingvist:store?catalog=unlimited';
        new URI(subscribeUri).navigateTo();
    },

    manageSubscription: function () {
        new URI('lingvist:store?page=recurring-products&catalog=unlimited').navigateTo();
    },

    manageCard: function () {
        Backbone.history.navigate('subscriptions/manage', { trigger: true });
    },

    cancelSubscription: function (event) {
        let $target = $(event.currentTarget),
            subscription_uuid = $target.parents('\.service').data('uuid');

        let confirmationView = new ConfirmationView({
            title: i18nUtils.prop('account_subscription_cancel_title'),
            message: i18nUtils.prop('account_subscription_cancel_text'),
            actions: [
                {
                    title: i18nUtils.prop('account_subscription_cancel_dismiss'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('account_subscription_cancel_confirm'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        }, {background: true});

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                this.trigger('cancel-subscription', subscription_uuid);
            }
            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });

        confirmationView.show();
    }
});

export default SubscriptionTabView;
