var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dateData &&
    _vm.goalStatus &&
    _vm.title &&
    _vm.subtitle &&
    _vm.message
    ? _c("div", { staticClass: "today grid-item" }, [
        _c("div", { staticClass: "today-goals" }, [
          _c(
            "div",
            { staticClass: "icon" },
            [
              _c("goal-status-icon", {
                attrs: { size: 40, status: _vm.goalStatus },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.subtitle))]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.message))]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }