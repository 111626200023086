
export const GOOGLE_SIGNIN = 'https://accounts.google.com';

export class CredentialsManager {

    static getCredentials () {

        // Check if browser supports Credentials Management API
        // https://developer.mozilla.org/en-US/docs/Web/API/Credential_Management_API

        if (window.PasswordCredential || window.FederatedCredential) {

            // Check if any regular user/pass or
            // federated (Google/Facebook) credentials are available)

            return Promise.resolve()
                .then( () => {
                    return navigator.credentials.get({
                        password: true,
                        mediation: 'optional'
                    });
                })
                .then( credential => {

                    if ( ! credential) {
                        return Promise.reject('not-available');
                    }

                    switch (credential.type) {

                        case 'password': {

                            const { id, password } = credential;

                            return Promise.resolve({
                                type: 'password',
                                user: id,
                                password
                            });
                        }
                    }
                });
        } else {
            return Promise.reject('not-supported');
        }
    }
}
