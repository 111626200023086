var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 20 20",
          width: "20",
          height: "20",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M10 19a9 9 0 10-6.3-2.573",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3 9a1 1 0 011-1h.697a1 1 0 01.555.168l2.303 1.535a1 1 0 01.445.832v1.132a1 1 0 01-.2.6l-1 1.333c-.576.769-1.8.361-1.8-.6v-1a1 1 0 00-1-1 1 1 0 01-1-1V9zm8-2.414c0-.324.262-.586.586-.586A.586.586 0 0012 5l-.707-.707A1 1 0 0111 3.586V2.78a1 1 0 011.243-.97l.65.162a.99.99 0 01.205.076l3.551 1.775a1 1 0 01.49.544l1.05 2.802a.614.614 0 01-.575.83.614.614 0 00-.614.614v1.224a1 1 0 01-.051.316l-.72 2.162a1 1 0 01-.95.684h-.498a1 1 0 01-.97-.758l-.621-2.485A1 1 0 0012.22 9H12a1 1 0 01-1-1V6.586zM4 7v-.382C4 6.277 4.277 6 4.618 6a.618.618 0 00.553-.342L5.9 4.201a.95.95 0 00-1.377-1.216l-1.254.836a1 1 0 00-.382.481L1.5 8H3a1 1 0 001-1z",
          fill: "#2C3143",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }