var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M11.228 17.176l-2.443 2.44-6.58-6.58A4.12 4.12 0 011 10.12a4.12 4.12 0 016.62-3.274 4.21 4.21 0 00-.42 6.257l4.028 4.073z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.022 20l-6.824-6.897a4.21 4.21 0 010-5.884 4.04 4.04 0 015.784 0l1.04 1.014.996-1.014a4.039 4.039 0 015.784 0 4.21 4.21 0 010 5.884L16.97 17M9.361 10.5A1.094 1.094 0 0110.5 9.368",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }