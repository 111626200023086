var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "card-gap",
      class: {
        wrong: this.wrongAnswer,
        correct: this.isCorrectAnswer,
        remembered: this.isRemembered,
        synonym: this.synonymAnswer,
        onPrevious: this.on_previous,
      },
      style: { minWidth: !_vm.on_previous ? _vm.gapMinWidth : "unset" },
      attrs: {
        "data-word": _vm.word.word,
        id: "card-gap",
        "data-audio-hash": _vm.word.audio_hash,
      },
    },
    [
      _c("autosize-input", {
        ref: "autosizeInput",
        attrs: {
          name: "answer-input",
          className: "answer-input",
          hidden:
            _vm.answerVisible || _vm.isCorrectAnswer || _vm.isEquivalentAnswer,
          disabled: _vm.inputDisabled,
          maxWidth: _vm.gapMaxWidth,
          minWidth: _vm.gapMinWidth ? _vm.gapMinWidth : "112px",
          "extra-width": 16,
        },
        on: {
          submit: _vm.submit,
          blur: _vm.blurCursorPosition,
          focus: () => _vm.$emit("gap-focus"),
        },
        model: {
          value: _vm.userAnswer,
          callback: function ($$v) {
            _vm.userAnswer = $$v
          },
          expression: "userAnswer",
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.isEquivalentAnswer ||
                _vm.answerVisible ||
                _vm.isCorrectAnswer ||
                _vm.hintVisible ||
                (_vm.hintVisible && _vm.synonymAnswer),
              expression:
                "isEquivalentAnswer || answerVisible || isCorrectAnswer || hintVisible || (hintVisible && synonymAnswer)",
            },
          ],
          staticClass: "word-container",
          class: {
            hintIsVisible:
              _vm.hintVisible && !_vm.answerVisible && !_vm.isCorrectAnswer,
          },
          on: {
            click: function ($event) {
              return _vm.hideAnswer(true)
            },
          },
        },
        _vm._l(_vm.hintAsLetters, function (letterObj, index) {
          return _c(
            "span",
            {
              class: [
                letterObj.correctness,
                _vm.isSpaceOrLetter(letterObj.letter),
              ],
              attrs: { index: index },
            },
            [_vm._v(_vm._s(letterObj.letter))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "span",
        { ref: "hiddenWordContainer", staticClass: "hidden-word-container" },
        _vm._l(_vm.wordAsLetters, function (letter) {
          return _c("span", { class: _vm.isSpaceOrLetter(letter) }, [
            _vm._v(_vm._s(letter)),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }