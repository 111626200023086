const KeyCodes = {
    ENTER: 13,
    SPACE: 32,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    PLUS: 43,
    ESC: 27,
    TAB: 9
};

export default KeyCodes;
