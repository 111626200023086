
export class SigninPopup {

    constructor (redirect_uri) {
        this._redirect_uri = redirect_uri;
    }

    clear_handlers () {
        window.removeEventListener('message', this.messageHandler);
        clearInterval(this.windowTimer);
        this.signinWindow = undefined;
    }

    async open (url, width=600, height=500) {
        let left;

        // position login windows roughly in the middle
        if (width > screen.width) {
            width = screen.width;
        }
        left = window.screenX || (screen.width - window.outerWidth) / 2;
        left += window.outerWidth / 2 - width / 2;  // center
        this.signinWindow = window.open(url, '_blank', `location=no, height=${height}, width=${width}, top=150, left=${left}, scrollbars=yes,status=yes`);

        // Listen for OAuth message from the Login window
        return new Promise((resolve, reject) => {
            this.messageHandler = (event) => {
                let oauth_event = null;

                if (typeof event.data.lingvist_oauth_event === 'object' &&
                    typeof event.data.lingvist_oauth_event !== null) {
                    oauth_event = event.data.lingvist_oauth_event;
                    this.clear_handlers();
                    if (oauth_event.error === null) {
                        oauth_event.redirect_uri = this._redirect_uri;
                        resolve(oauth_event);
                    } else {
                        reject(oauth_event.error);
                    }
                }
            };

            window.addEventListener('message', this.messageHandler, false);

            // timer to check if user closed popup window to re-enable buttons
            this.windowTimer = setInterval(() => {
                if (this.signinWindow == null || this.signinWindow.closed) {
                    this.clear_handlers();
                    reject('closed');
                }
            }, 300);
        });
    }
}
