var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-context-translations", class: { open: this.open } },
    [
      _vm.hasContentTranslations
        ? _c(
            "div",
            { staticClass: "toggle-icon", on: { click: _vm.toggleOpen } },
            [_c("arrow-icon")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h3",
        {
          class: { isClickable: _vm.hasContentTranslations },
          attrs: { id: "card-context-translation-title" },
          on: { click: _vm.toggleOpen },
        },
        [
          _vm._l(_vm.translations, function (item, index) {
            return [
              item.translationStart
                ? _c("span", { staticClass: "translation-start" }, [
                    _vm._v(_vm._s(item.translationStart)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "translation" }, [
                _vm._v(_vm._s(item.translation)),
              ]),
              _vm._v(" "),
              item.translationEnd
                ? _c("span", { staticClass: "translation-end" }, [
                    _vm._v(_vm._s(item.translationEnd)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              index + 1 < _vm.translations.length
                ? _c("span", { staticClass: "comma" }, [_vm._v(", ")])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.contextTranslationComments
        ? _c(
            "div",
            { staticClass: "comments" },
            _vm._l(_vm.contextTranslationComments, function (comment) {
              return _c("div", { staticClass: "comment" }, [
                _vm._v("\n            " + _vm._s(comment) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasContentTranslations
        ? _c(
            "transition",
            {
              attrs: { name: "expand" },
              on: {
                enter: _vm.enter,
                "after-enter": _vm.afterEnter,
                leave: _vm.leave,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.open,
                      expression: "open",
                    },
                  ],
                  staticClass: "card-context-translations-content",
                },
                _vm._l(
                  _vm.question.context_translation,
                  function (context_translation) {
                    return _c(
                      "div",
                      {
                        staticClass: "translation-row",
                        attrs: { "data-type": context_translation.type },
                      },
                      [
                        _c("div", {
                          staticClass: "translation",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.context_translation_highlighted(
                                context_translation.translation
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        context_translation.type === "literal"
                          ? _c("div", { staticClass: "context_type" }, [
                              _vm._v(
                                "(" + _vm._s(context_translation.type) + ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }