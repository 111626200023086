var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "authentication-options" },
    [
      _vm._l(_vm.options.primary, function ({ option, label }) {
        return _c(
          "button",
          {
            key: option,
            class: option,
            on: {
              click: function ($event) {
                return _vm.select_option(option)
              },
            },
          },
          [
            _c(_vm.logo(option), { tag: "component", staticClass: "icon" }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(label) } }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.options.secondary.length > 0
        ? _c(
            "button",
            {
              staticClass: "toggle-secondary",
              class: { open: _vm.secondary_options_open },
              on: {
                click: function ($event) {
                  _vm.secondary_options_open = !_vm.secondary_options_open
                },
              },
            },
            [
              _c("arrow-svg"),
              _vm._v(" "),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.more_options_label) },
              }),
              _vm._v(" "),
              _c("arrow-svg"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.secondary_options_open
        ? _vm._l(_vm.options.secondary, function ({ option, label }) {
            return _c(
              "button",
              {
                key: option,
                class: option,
                on: {
                  click: function ($event) {
                    return _vm.select_option(option)
                  },
                },
              },
              [
                _c(_vm.logo(option), { tag: "component", staticClass: "icon" }),
                _vm._v(" "),
                _c("span", { domProps: { innerHTML: _vm._s(label) } }),
              ],
              1
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }