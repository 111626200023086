var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lesson-cards-control",
      class: { disabled: _vm.lessonCardsLimitReached },
    },
    [
      !_vm.lessonCardsLimitReached
        ? _c(
            "div",
            { staticClass: "actions" },
            [
              _c("div", { staticClass: "check-all" }, [
                _vm.lessonHasCards
                  ? _c("input", {
                      staticClass: "check-all custom",
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.allChecked },
                      on: {
                        change: function ($event) {
                          return _vm.checkAllToggle($event)
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.checkedCards && _vm.checkedCards.length > 0
                ? _c("div", { staticClass: "delete" }, [
                    _c(
                      "div",
                      {
                        staticClass: "action",
                        class: { disabled: this.interactionDisabled },
                        on: { click: _vm.deleteCheckedCards },
                      },
                      [
                        _c("remove-icon"),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n(
                                "lessons_creator_editor_word_action_delete_selected"
                              )
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "selected-counter" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n(
                              "lessons_creator_editor_items_selected",
                              {
                                args: {
                                  0: _vm.checkedCards.length,
                                  items: _vm.checkedCards.length,
                                },
                              }
                            )
                          ),
                        },
                      }),
                    ]),
                  ])
                : [
                    _c("div", { staticClass: "word" }, [
                      _c(
                        "div",
                        {
                          staticClass: "action",
                          class: { disabled: this.interactionDisabled },
                          on: { click: _vm.openAddWordDialog },
                        },
                        [
                          _c("add-word-icon"),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n("course_wizard_add_new_word_add_btn")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _c(
                        "div",
                        {
                          staticClass: "action",
                          class: { disabled: this.interactionDisabled },
                          on: { click: _vm.openModal },
                        },
                        [
                          _c("add-text-icon"),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                this.$i18n(
                                  "lessons_creator_editor_add_text_button"
                                )
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.lessonHasCards
                      ? _c("div", { staticClass: "example" }, [
                          _c(
                            "div",
                            {
                              staticClass: "action",
                              class: { disabled: this.interactionDisabled },
                              on: { click: _vm.exampleClicked },
                            },
                            [
                              _c("example-icon"),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    this.$i18n(
                                      "lessons_creator_editor_intro_example_button"
                                    )
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lessonHasCards
                      ? _c("div", { staticClass: "auto" }, [
                          _c(
                            "div",
                            {
                              staticClass: "action",
                              class: { disabled: this.interactionDisabled },
                              on: { click: _vm.expandLesson },
                            },
                            [
                              _c("add-more-icon"),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    this.$i18n(
                                      "lessons_creator_editor_button_expand",
                                      { args: { words_count: "~20" } }
                                    )
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("cards-filter-dropdown", {
                      attrs: {
                        "active-filter": _vm.activeFilter,
                        cards: this.cards,
                        disabled: this.interactionDisabled,
                      },
                    }),
                    _vm._v(" "),
                    _c("cards-control-more-dropdown", {
                      attrs: { lesson: this.lesson, cards: this.cards },
                    }),
                    _vm._v(" "),
                    _vm.lessonHasCards
                      ? _c("expand-button", {
                          attrs: { expanded: _vm.expanded },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.expandCollapse.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lessonCardsLimitReached
        ? _c("div", {
            staticClass: "limit-reached-message",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("lesson-size-exceeded", {
                  collection: "lessons_creator_editor_error",
                })
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.addCardDialogOpen
        ? _c(
            "div",
            {
              staticClass: "add-card-dialog",
              on: {
                mouseleave: _vm.closeAddCardDialogDelayed,
                mouseenter: _vm.clearAddCardDialogClose,
              },
            },
            [
              _c(
                "div",
                { staticClass: "add-card-dialog-container" },
                [
                  _c("div", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_add_words_dialog_title"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "subtitle",
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n(
                          "lessons_creator_editor_add_words_dialog_subtitle"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    ref: "word_input",
                    attrs: { type: "text", placeholder: _vm.manualPlaceholder },
                    on: {
                      keyup: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addWordsToLesson.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.closeAddCardDialog.apply(null, arguments)
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    attrs: {
                      filled: true,
                      size: "medium",
                      i18n: {
                        id: "lessons_creator_editor_add_words_dialog_action",
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.addWordsToLesson.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }