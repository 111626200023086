
'use strict';

import UserManager from './usermanager.js';

import moment from 'moment';

import AsyncDestroyable from './async.destroyable.js';

import googleAnalyticsCommand from '../util/google-analytics.js';
import { NAME, TYPE as PARAMETER_TYPE } from "Modules/user.parameters.js";
import { EventBus } from "Util/vue-event-bus";

export const EXERCISES_AWARD_EXERCISES = 1;
export const SETS_GOAL_IN_WEEK = 7;
export const DYNAMIC_GOAL_RECOMMENDED_SIZE = 50;
export const DYNAMIC_GOAL_RECOMMENDED_MAX_SIZE = 200;

export const AWARD_NAME = {
    CARDS: 'cards_completed',
    NEW_WORDS: 'new_words_done',
    EXERCISES: 'exercises',
    SET_COMPLETE: 'set_complete'
};

export class Awards extends AsyncDestroyable {
    constructor (user, course) {
        super([]);
        this._user = user;
        this._course = course;
        this._awardLastGiven = {};
    }

    destroy () {
        delete this._user;
        delete this._course;
        return Promise.resolve();
    }

    static getExercisesAward (todayStatistics) {
        return todayStatistics.awards_objects.find(award => award.award_name === AWARD_NAME.EXERCISES) || null;
    }

    static getDynamicGoalSize () {
        const userParameters = UserManager.instance.getUser().getParameters();
        const goalSizeFromParams = userParameters.getParameter(NAME.DYNAMIC_GOALS_CARDS_GOAL);
        if (!goalSizeFromParams) {
            return DYNAMIC_GOAL_RECOMMENDED_SIZE;
        } else {
            return goalSizeFromParams;
        }
    }

    static setDynamicGoalSize (card_count) {
        if (card_count && card_count >= 10) {
            const userParameters = UserManager.instance.getUser().getParameters();
            EventBus.$emit('dynamic-goal:size-changed', card_count);
            return userParameters.setParameter(NAME.DYNAMIC_GOALS_CARDS_GOAL, parseInt(card_count), PARAMETER_TYPE.INTEGER);
        }
    }

    static isPossibleCompleteSetsWeekGoal (daysCountWithSetsAward) {
        const daysFromBeggingOfWeek = this.getDaysFromBeginningOfWeek();
        const daysLeftInWeek = 7 - daysFromBeggingOfWeek;
        const studyDaysRequired = Math.max(SETS_GOAL_IN_WEEK - daysCountWithSetsAward, 0);

        return studyDaysRequired === 0 || studyDaysRequired <= daysLeftInWeek;
    }

    static getDaysFromBeginningOfWeek () {
        const today = moment().local().startOf('day');
        const _statistics = UserManager.instance.getUser().getCourse().getStatistics();
        const startOfCurrentWeek = _statistics.getStartOfCurrentWeek();
        return today.diff(startOfCurrentWeek, 'days') + 1; // +1 to include the first day
    }

    static getSetAwardsInfo (awards_objects) {
        let _setAwards = awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE);
        return { setsCompleted: _setAwards.length };
    }

    setCurrentSetCorrectStreak (streak) {
        return Promise.resolve().then(function () {
            return UserManager.instance.getUser().getStorage().getItem('set_longest_correct_streak') || 0;
        }).then(function (set_longest_correct_streak) {
            UserManager.instance.getUser().getStorage().setItem('set_correct_streak', streak);
            if (set_longest_correct_streak < streak) {
                UserManager.instance.getUser().getStorage().setItem('set_longest_correct_streak', streak);
            }
        });
    }

    async resetCurrentSetCorrectStreak () {
        await UserManager.instance.getUser().getStorage().setItem('set_correct_streak', 0);
        await UserManager.instance.getUser().getStorage().setItem('set_longest_correct_streak', 0);
    }

    async getCurrentSetLongestCorrectStreak () {
        return await UserManager.instance.getUser().getStorage().getItem('set_longest_correct_streak') || 0;
    }

    static getCorrectRate (award) {
        let allRepeats = award.correct_repeated_cards + award.wrong_repeated_cards;
        if (allRepeats > 0) {
            return Math.round((award.correct_repeated_cards / allRepeats) * 100);
        } else {
            return 0;
        }
    }

    getAwardObject (course_uuid, award_name, statistics, cards_completed = null) {
        return {
            course_uuid,
            award_name,
            time_elapsed: statistics.study_time,
            cards_completed: (cards_completed) ? cards_completed : statistics.all_units.total,
            new_cards: UserManager.instance.getUser().getCourse().getStatistics().getWordsEncountered(statistics),
            correct_repeated_cards: statistics.repeat_units.correct,
            wrong_repeated_cards: statistics.repeat_units.total - statistics.repeat_units.correct,
            max_correct_streak: 0
        };
    }

    async getSetAwardObject (course_uuid, todayStatistics) {
        const response_max_correct_streak = await this.getCurrentSetLongestCorrectStreak();

        let newAward = {
            course_uuid,
            award_name: AWARD_NAME.SET_COMPLETE,
            time_elapsed: todayStatistics.study_time,
            cards_completed: todayStatistics.all_units.total,
            new_cards: todayStatistics.new_units.total,
            correct_repeated_cards: todayStatistics.repeat_units.correct,
            wrong_repeated_cards: todayStatistics.repeat_units.total - todayStatistics.repeat_units.correct,
            max_correct_streak: (response_max_correct_streak) ? response_max_correct_streak : 0
        };

        let _setAwards = todayStatistics.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE);

        _setAwards.map(award => {
            newAward.time_elapsed = newAward.time_elapsed - award.time_elapsed;
            newAward.cards_completed = newAward.cards_completed - award.cards_completed;
            newAward.new_cards = newAward.new_cards - award.new_cards;
            newAward.correct_repeated_cards = newAward.correct_repeated_cards - award.correct_repeated_cards;
            newAward.wrong_repeated_cards = newAward.wrong_repeated_cards - award.wrong_repeated_cards;
        });

        return newAward;
    }

    shouldGrantSetAward () {
        const todayStatistics = this._course.getStatistics().getTodayData();
        const dynamicGoalSize = this.constructor.getDynamicGoalSize();

        let todaySetAwardsCardsTotal = this.constructor.getTodaySetAwardsTotalCards();
        if (todayStatistics.all_units.total !== 0 && (todayStatistics.all_units.total - todaySetAwardsCardsTotal >= dynamicGoalSize)) {
            const set_cards_completed =
                todayStatistics.awards_objects
                    .filter(award => award.award_name === AWARD_NAME.SET_COMPLETE)
                    .reduce((result, award) => { result += award.cards_completed; return result; }, 0);
            return (set_cards_completed < todayStatistics.all_units.total);
        } else {
            return false;
        }
    }

    static shouldShowDontOverdoNotification (course) {
        if (course) {
            let showOverdoNotification = false;
            const todayStatistics = course.getStatistics().getTodayData();
            const words = todayStatistics.all_units.total;
            const cardsDoneInCurrentSet = this.cardsDoneInActiveSet();

            if (cardsDoneInCurrentSet === 0) {
                const _setAwards = todayStatistics.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE);
                if (_setAwards && _setAwards.length > 0) {
                    const lastAward = [..._setAwards].pop();
                    const lastAwardCards = lastAward.cards_completed;

                    if (words >= DYNAMIC_GOAL_RECOMMENDED_MAX_SIZE && words - lastAwardCards < DYNAMIC_GOAL_RECOMMENDED_MAX_SIZE) {
                        showOverdoNotification = true;
                    }
                }
            }

            return showOverdoNotification;
        } else {
            return false;
        }
    }

    async grantSetComplete () {
        const todayStatistics = this._course.getStatistics().getTodayData();
        const newAward = await this.getSetAwardObject(this._course.UUID, todayStatistics);
        await this._user.getEventSender().sendAwardEventV1(newAward);

        await this.resetCurrentSetCorrectStreak();

        let totalUnits = this._course.getStatistics().getData().all_units.total;
        const dynamicGoalSize = this.constructor.getDynamicGoalSize();

        if (totalUnits === dynamicGoalSize) {
            googleAnalyticsCommand('send', 'event', 'NewUser', 'SetCompleted');
        } else {
            googleAnalyticsCommand('send', 'event', 'User', 'SetCompleted');
        }

        EventBus.$emit('dynamic-goal:set-completed', dynamicGoalSize);


        return newAward;
    }

    notifyStatisticsUpdated (todayStatistics) {
        let now = moment().local();

        this.setCurrentSetCorrectStreak(todayStatistics.last_correct_streak);

        if (todayStatistics.exercises.all >= EXERCISES_AWARD_EXERCISES &&
            Awards.getExercisesAward(todayStatistics) === null &&
            !now.isSame(this._awardLastGiven[AWARD_NAME.EXERCISES] || null, 'day')) {

            this._awardLastGiven[AWARD_NAME.EXERCISES] = now;
            this._user.getEventSender().sendAwardEventV1(this.getAwardObject(this._course.UUID, AWARD_NAME.EXERCISES, todayStatistics));
        }
    }

    static getDailyGoalsAchievedThisWeek (course = null)  {
        let daily_goals_achieved = [];
        if (course || this._course) {
            const weekStatistics = (course) ? course.getStatistics().getHistoryDataFromTo(): this._course.getStatistics().getHistoryDataFromTo();
            weekStatistics.forEach(day => {
                if (day.awards_objects.length > 0) {
                    let _setAwards = day.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE);
                    if (_setAwards.length > 0) {
                        daily_goals_achieved.push({ date: day.ts, awards: _setAwards, weekday_nr: moment(day.ts).isoWeekday() });
                    }
                }
            });
        }

        return daily_goals_achieved;
    }

    // WARNING: This method is just for debugging and testing, shouldn't be used in production
    static getDailyGoalsAchievedThisWeekDebug (debug = 0)  {
        let daily_goals_achieved = [];
        let _date;
        for (let i = 1; i <= debug; i++) {
            if (debug > 0) {
                if (i < debug) {
                    _date = moment().local().startOf('day').subtract(debug - i, 'days');
                } else {
                    _date = moment().local().startOf('day');
                }
                daily_goals_achieved.push({ date: _date, weekday_nr: i });
            }
        }

        return daily_goals_achieved;
    }

    static getStreakToday () {
        const weekStatistics = UserManager.instance.getUser().getCourse().getStatistics().getHistoryDataFromTo();
        let _weekDaysWithSetAward = [];

        weekStatistics.forEach(day => {
            if (day.awards_objects.length > 0) {
                let _setAwards = day.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE);
                _weekDaysWithSetAward.push({ date: day.ts, awards: _setAwards });
            }
        });

        let days = this.getDaysFromBeginningOfWeek();
        if (days === _weekDaysWithSetAward.length) {
            return _weekDaysWithSetAward.length;
        } else {
            return -1;
        }
    }

    static getTodaySetAwardsTotalCards () {
        const todayStatistics = UserManager.instance.getUser().getCourse().getStatistics().getTodayData();
        const _setAwards = todayStatistics.awards_objects.filter(award => award.award_name === AWARD_NAME.SET_COMPLETE);

        let total_cards_completed = 0;
        if (_setAwards && _setAwards.length > 0) {
            _setAwards.forEach(award => {
                total_cards_completed = total_cards_completed + award.cards_completed;
            });
        }

        return total_cards_completed;
    }

    static cardsDoneInActiveSet () {
        const todayStatistics = UserManager.instance.getUser().getCourse().getStatistics().getTodayData();
        const totalCardsToday = todayStatistics.all_units.total;

        let cardsDoneTodayInSets = this.getTodaySetAwardsTotalCards();
        if (cardsDoneTodayInSets > 0) {
            return Math.max(totalCardsToday - cardsDoneTodayInSets, 0);
        } else {
            return totalCardsToday;
        }
    }
}
