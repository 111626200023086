

import { ReadingExercise } from './reading.exercise.js';
import { ListeningExercise } from './listening.exercise.js';
import { SpeakingExercise } from './speaking.exercise.js';
import { ArticleExercise } from './article.exercise.js';
import { TYPE } from './constants.js';

const EXERCISE_TYPE_MAPPING = {
    [TYPE.SPEAKING]: SpeakingExercise,
    [TYPE.READING]: ReadingExercise,
    [TYPE.LISTENING]: ListeningExercise,
    [TYPE.ARTICLE]: ArticleExercise
};

export class UnknownExerciseTypeException extends Error {
    constructor (type) {
        super();
        this.name = 'UnknownExerciseTypeException';
        this.message = `Exercise type="${type}" is not supported!`;
    }
}

export class ExerciseFactory {
    static getExercise (type, course, assetLoader) {
        if (EXERCISE_TYPE_MAPPING[type] === undefined) {
            throw new UnknownExerciseTypeException(type);
        }
        return new EXERCISE_TYPE_MAPPING[type](course, assetLoader);
    }
}
