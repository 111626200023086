import moment from 'moment';
import { BaseExercise } from './base.exercise.js';
import { ArticleExerciseAnswer } from './answer/article.js';

export class ArticleExercise extends BaseExercise {

    constructor (...args) {
        super(...args);
    }

    update (exercise_data) {
        super.update(exercise_data);
    }

    getData () {
        return Promise.resolve()
            .then(() => super.getData())
            .then(data => {
                data.title = this._data.content.title[this._display_info.title];
                data.description = this._data.content.description[this._display_info.description];
                data.article = this._data.content.article;
                data.author = this._data.content.author;
                data.content_partner = this._data.content.content_partner;
                data.link = this._data.content.link;
                data.publish_date = moment(this._data.content.publish_date);
                data.subtitle = this._data.content.subtitle;
                data.summary_title = this._data.content.summary.title[this._display_info.summary];
                data.summary_content = this._data.content.summary.content[this._display_info.summary];

                return Promise.resolve(data);
            });
    }

    getExerciseAnswer () {
        return new ArticleExerciseAnswer(
            this._course.UUID,
            this.getInfo(),
            this._display_info,
            this._getAssetURL()
        );
    }
}
