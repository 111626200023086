var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M39 17c0 4.418-3.64 8-8.129 8A6.92 6.92 0 0126 23l5-5-6-6.5C26.48 9.98 28.563 9 30.871 9 35.361 9 39 12.582 39 17z",
          fill: "#8EA3EE",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.5 25.5l-7.75 7.75a2.475 2.475 0 11-3.5-3.5L11 22",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 22l-4-4L19 6l12 12-12 12-1.723-1.762M14.554 17.346l8.169 8.17",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }