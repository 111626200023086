<template>
    <div class="debug-tabs">
        <header>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z"/></svg>
            <ul class="debug-tabs-header">
                <li v-for="(tab, index) in tabs" :key="tab.title" @click="selectTab(index)" :class="{ active: selectedIndex === index }">
                    {{ tab.title }}
                </li>
            </ul>
        </header>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'debug-tabs',
        data () {
            return {
                selectedIndex: 0,
                tabs: []
            }
        },
        methods: {
            selectTab (i) {
                this.selectedIndex = i
                // loop over all the tabs
                this.tabs.forEach((tab, index) => {
                    tab.isActive = (index === i)
                })
            },
            close() {
                this.$emit('close');
            }
        },
        mounted () {
            this.selectTab(0)
        },
        created () {
            this.tabs = this.$children
        }
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.debug-tabs {
        > header {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            margin-bottom: 1rem;
            ul.debug-tabs-header {
                align-items: center;
                border-bottom: 1px solid #dbdbdb;
                display: flex;
                flex-grow: 1;
                flex-shrink: 0;
                justify-content: flex-start;
                margin-right: 3rem;
                > li {
                    align-items: center;
                    border-bottom: 1px solid #dbdbdb;
                    color: #4a4a4a;
                    display: flex;
                    justify-content: center;
                    margin-bottom: -1px;
                    padding: .5em 1em;
                    vertical-align: top;
                    font-size: fontSize(18);
                    &:hover {
                        cursor: pointer;
                    }
                    &.active {
                        border-bottom-color: $colorPurplePrimary;
                        color: $colorPurplePrimary;
                    }
                }
            }

            @include respond-to('small') {
                gap: .5rem;
                ul.debug-tabs-header {
                    margin-right: unset;
                    flex-wrap: wrap;
                    flex-shrink: unset;
                    border-bottom-color: transparent;
                    > li {
                        font-size: fontSize(14);
                    }
                }
            }
        }

        div.debug-tab-content {
            padding: 1rem;
        }
    }

</style>
