
import Raven from 'raven-js';
import Howler from 'howler';
import { clone } from 'lodash';
import ExportUtils from './export.js';

export class AudioLoadingFailedException extends Error {
    constructor (message) {
        super(message);
        this.name = 'AudioLoadingFailedException';
    }
}

export class AudioNotAvailableException extends Error {
    constructor (message) {
        super(message);
        this.name = 'AudioNotAvailableException';
    }
}

class AudioPlayer {

    constructor () {
        this._audioEnabled = true;

        this._uuid = null;
        this._wordAudio = null;
        this._contextAudio = null;
        this._singleWordAudios = [];

        this._conversationAudios = [];
        this._listenChallengeAudio = null;

        this._playWordAudioRequested = false;
        this._playContextAudioRequested = false;

        this._loadedCallback = () => {};
        this._playStartCallback = () => {};
        this._playEndCallback = () => {};
        this._pausedCallback = () => {};

        this._loadingInProgress = false;

        ExportUtils.export('app.util.audioplayer', this);
    }

    /**
     * Load all audios for current question
     * @param uuid - uuid of the Question's Lexical Unit
     * @param wordPaths - asset url paths for word audio files
     * @param contextPaths - asset url paths for context audio files
     * @param singleWords - array of objects for context sentence words
     * @param autoPlayContext - play context automatically
     */

    loadQuestionAudios (uuid, wordPaths, contextPaths, singleWords, autoPlayContext = false) {

        // console.info(`AudioPlayer: load start LU=${uuid}`);

        this._uuid = uuid;
        this._loadingInProgress = true;

        // Stop context audio playback when user switches card during playback
        // BUG: this broke the .playContext callback and got app stuck..
        // if (this._contextAudio !== null && this._contextAudio.playing()) {
        //     this._contextAudio.stop();
        // }

        this._wordAudio = null;
        this._playWordAudioRequested = false;
        this._contextAudio = null;
        this._playContextAudioRequested = false;
        this._singleWordAudios = [];

        Howler.Howler.unload();

        if (!this.validateAudioPaths(wordPaths) || !this.validateAudioPaths(contextPaths)) {
            console.log('AUDIO: broken audio paths, skip loading');
            return;
        }

        Promise.resolve()
            .then( () => this._loadWordAudio(wordPaths))
            .then( wordAudio => {

                this._wordAudio = wordAudio;

                if (this._playWordAudioRequested) {
                    this.playWord();
                    this._playWordAudioRequested = false;
                }

                return Promise.resolve();

            })
            .catch(error => {
                if (error.name === 'AudioLoadingFailedException') {
                    console.info('AudioPlayer: Word audio loading failed', error.message);
                } else {
                    throw error;
                }
            })
            .then( () => this._loadContextAudio(contextPaths, uuid))
            .then(({ contextAudio, _uuid }) => {
                // console.log("AudioPlayer: _loadContextAudio completed for _uuid %s but current is %s", _uuid, uuid);
                // now check if there has been other audios loaded (racer: avoid overwriting latest request result with one what just took more time #DEV-938)
                if (_uuid === this._uuid && contextAudio) {
                    this._contextAudio = contextAudio;

                    if (this._playContextAudioRequested) {
                        this.playContext();
                        this._playContextAudioRequested = false;
                    }

                    if (this._audioEnabled && autoPlayContext) {
                        this.playContext();
                    }
                }

                return Promise.resolve();

            })
            .catch(error => {
                if (error.name === 'AudioLoadingFailedException') {
                    // console.info('AudioPlayer: Context audio loading failed', error.message);
                } else {
                    throw error;
                }
            })
            .then( () => this._loadSingleWordAudios(singleWords))
            .then( () => {
                // console.info(`AudioPlayer: load end LU=${this._uuid}`);
                this._loadingInProgress = false;
            })
            .catch( error => {
                if (error.name === 'AudioLoadingFailedException') {
                    // console.info('AudioPlayer: Single word audio loading failed');
                } else {
                    throw error;
                }
            })
            .catch(error => {
                this._loadingInProgress = false;
                Raven.captureException(error, { level: 'error' });
            });
    }

    validateAudioPaths(paths) {
        let valid = true;
        paths.forEach(path => {
            valid = !path.includes('null');
        });

        return valid;
    }

    loadConversationAudios (linePaths) {

        var sequence = Promise.resolve();
        this._conversationAudios = [];

        linePaths.forEach(linePath => {
            sequence = sequence.then( () => {
                return this._loadConversationLineAudio(linePath);
            }).then(lineAudio => {
                this._conversationAudios.push(lineAudio);
            });
        });
    }

    loadListenChallengeAudio (audioPath) {
        return new Promise( (resolve, reject) => {
            this._listenChallengeAudio = new Howler.Howl({
                src: audioPath,
                onload: () => {
                    // console.log(`AudioPlayer: listeningAudio loaded for path=${audioPath}`);
                    this._loadedCallback();
                    resolve();
                },
                onloaderror: () => {
                    reject(new AudioLoadingFailedException(audioPath));
                }
            });
        });
    }

    /**
     * Load the word's audio
     * @param paths - paths of the word audio
     * @returns {Promise} - is resolved when
     * @private
     */

    _loadWordAudio (paths) {
        return new Promise ( (resolve, reject) => {
            let wordAudio = new Howler.Howl({
                src: paths,
                onload: () => {
                    // console.info(`AudioPlayer: wordAudio loaded LU=${this._uuid} path=${wordAudio._src}`);
                    resolve(wordAudio);
                },
                onloaderror: () => {
                    reject(new AudioLoadingFailedException(paths[0]));
                }
            });
        });
    }

    /**
     * Load context sentence's audio
     * @param paths - paths of the context sentence audio
     * @param uuid - uuid
     * @returns {Promise} - is resolved when context sentence audio is successfully loaded
     * @private
     */

    _loadContextAudio (paths, uuid) {
        return new Promise( (resolve, reject) => {
            const _uuid = clone(uuid);
            let contextAudio = new Howler.Howl({
                src: paths,
                onload: () => {
                    // console.info(`AudioPlayer: contextAudio loaded for LU=${this._uuid} path=${contextAudio._src}`);
                    resolve({ contextAudio, _uuid });
                },
                onloaderror: () => {
                    reject(new AudioLoadingFailedException(paths[0]));
                }
            });
        });
    }

    /**
     * Load all single word audios for the context sentence
     * @param contextWords - array of word hashes and paths
     * @returns {Promise} - is resolved when all word audios are loaded
     * @private
     */

    _loadSingleWordAudios (contextWords) {

        var sequence = Promise.resolve();

        // Load Single Word audios in sequence
        contextWords.forEach(word => {
            sequence = sequence.then( () => {
                return this._loadSingleWord(word);
            }).then(singleWordAudio => {
                this._singleWordAudios.push(singleWordAudio);
            });
        });

        return sequence;
    }

    /**
     * Load single context sentence's word audio
     * @param word - the word to load, referred by hash
     * @returns {Promise} - is resolved when audio file is successfully loaded
     * @private
     */

    _loadSingleWord (word) {
        return new Promise( (resolve, reject) => {

            let singleWordAudio = new Howler.Howl({
                src: word.urls,
                onload: () => {
                    // console.info(`AudioPlayer: singleWordAudio loaded for LU=${this._uuid} path=${singleWordAudio._src}`);

                    resolve({ hash: word.hash, audio: singleWordAudio });
                },
                onloaderror: () => {
                    reject(new AudioLoadingFailedException(word.urls[0]));
                }
            });
        });
    }

    _loadConversationLineAudio (linePath) {
        return new Promise( (resolve, reject) => {
            const conversationLineAudio = new Howler.Howl({
                src: linePath,
                onload: () => {
                    // console.log(`AudioPlayer: conversationLineAudio loaded for path=${linePath}`);
                    resolve(conversationLineAudio);
                },
                onloaderror: () => {
                    reject(new AudioLoadingFailedException(linePath));
                }
            });
        });
    }

    /**
     * Play word's audio
     * @param ignoreAudioDisabled - override mute when clicking the play button
     * @returns {Promise} - is resolved when playback ends
     */

    playWord (ignoreAudioDisabled = false) {
        return new Promise( (resolve, reject) => {
            if (ignoreAudioDisabled || this._audioEnabled) {

                if (this._wordAudio !== null) {
                    this._playStartCallback();

                    // console.info(`AudioPlayer: playWord LU=${this._uuid}`);

                    this._wordAudio.once('end', () => {
                        this._playEndCallback();
                        resolve();
                    });

                    if (this._wordAudio.playing()) {
                        this._wordAudio.stop();
                    }
                    this._wordAudio.play();
                } else {
                    this._playWordAudioRequested = true;
                }
            } else {
                resolve();
            }
        });
    }

    /**
     * Play context sentence's audio
     * @param ignoreAudioDisabled - override mute when clicking the play button
     * @returns {Promise} - is resolved when playback ends
     */

    playContext (ignoreAudioDisabled = false) {
        let self = this;
        return new Promise( (resolve, reject) => {
            if (ignoreAudioDisabled || self._audioEnabled) {

                if (this._contextAudio !== null) {
                    // console.info(`AudioPlayer: playContext LU=${this._uuid}`);
                    let _playbackTimeout = null;

                    this._playStartCallback();
                    this._contextAudio.once('end', () => {
                        clearTimeout(_playbackTimeout);
                        this._playEndCallback();
                        resolve();
                    });
                    if (this._contextAudio.playing()) {
                        this._contextAudio.stop();
                    }
                    this._contextAudio.play();

                    // added 6s timeout when audio playback gets stuck for some reason
                    _playbackTimeout = setTimeout(() => {
                        this._playEndCallback();
                        resolve();
                    }, 6000);
                } else {
                    this._playContextAudioRequested = true;
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    stopContextAudioPlayback () {
        if (this._contextAudio) {
            this._contextAudio.stop();
            this._playEndCallback();
        }
    }

    stopWordAudioPlayback () {
        if (this._wordAudio) {
            this._wordAudio.stop();
            this._playEndCallback();
        }
    }

    hasContextAudio () {
        return !!this._contextAudio;
    }

    /**
     * Find single word audio by using hash and play it
     * @param hash - id of the audio to play
     * @returns {Promise} - is resolved when playback ends
     */

    playSingleWord (hash) {
        return new Promise( (resolve, reject) => {

            // console.info(`AudioPlayer: playSingleWord hash=${hash}`);

            let singleWordAudio = this._singleWordAudios.find(word => word.hash === hash);

            if (singleWordAudio !== undefined) {
                this._playStartCallback();

                singleWordAudio.audio.once('end', () => {
                    this._playEndCallback();
                    resolve();
                });

                if (singleWordAudio.audio.playing()) {
                    singleWordAudio.audio.stop();
                }

                singleWordAudio.audio.play();
            } else {
                resolve();
            }
        });
    }

    playConversationLine (index) {
        return new Promise( (resolve) => {

            // console.info(`AudioPlayer: playConversationLine index=${index}`);

            const conversationAudio = this._conversationAudios[index];

            if (conversationAudio !== undefined) {
                this._playStartCallback();

                conversationAudio.once('end', () => {
                    this._playEndCallback();
                    resolve();
                });

                if (conversationAudio.playing()) {
                    conversationAudio.stop();
                }

                conversationAudio.play();
            } else {
                resolve();
            }
        });
    }

    playListenChallengeAudio () {
        return new Promise( resolve => {

            // console.info('AudioPlayer: playListenChallengeAudio');

            if (this._listenChallengeAudio !== null) {

                this._listenChallengeAudio.once('end', () => {
                    this._playEndCallback();
                    resolve();
                });

                this._playStartCallback();
                this._listenChallengeAudio.play();
            } else {
                resolve();
            }
        });
    }

    playLearnedWordAudio (audioPath) {

        return Promise.resolve().then( () => {
            return new Promise(resolve => {
                const learnedWordAudio = new Howler.Howl({
                    src: audioPath,
                    onload: () => {
                        // console.log(`AudioPlayer: learnedWordAudio loaded for path=${audioPath}`);
                        resolve(learnedWordAudio);
                    }
                });
            });
        }).then(learnedWordAudio => {

            return new Promise(resolve => {
                learnedWordAudio.once('end', () => {
                    resolve();
                });
                learnedWordAudio.play();
            });
        });
    }

    pauseListenChallengeAudio () {
        this._pausedCallback();
        this._listenChallengeAudio.pause();
    }

    isListenChallengeAudioPlaying () {
        return this._listenChallengeAudio.playing();
    }

    stopChallengeAudioPlayback () {
        this._listenChallengeAudio.stop();
    }

    getListenChallengePosition () {
        return this._listenChallengeAudio.seek();
    }

    getListenChallengeProgress () {
        const audioDuration = this._listenChallengeAudio.duration();
        const currentPosition = this._listenChallengeAudio.seek();

        return (currentPosition / audioDuration * 100);
    }

    /**
     * Switch automatic audio playback on or off
     * @param audioEnabled - true to enable, false to disable
     */

    toggleAudioEnabled (audioEnabled) {
        this._audioEnabled = audioEnabled;
    }

    isAudioEnabled () {
        return this._audioEnabled;
    }

    isLoadingInProgress () {
        return this._loadingInProgress;
    }

    onPlayStart (callback) {
        this._playStartCallback = callback;
    }

    onPlayEnd (callback) {
        this._playEndCallback = callback;
    }

    onPaused (callback) {
        this._pausedCallback = callback;
    }

    onLoaded (callback) {
        this._loadedCallback = callback;
    }
}

export default new AudioPlayer();
