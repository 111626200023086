var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "repeat-progress-modal",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "repeat-progress-modal-window" },
            [
              _c("CloseButton", {
                staticClass: "close",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c("h2", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(
                    this.$i18n("learn_repeat_progress_modal_title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "instructions" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("learn_repeat_progress_modal_instruction_1")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        this.$i18n("learn_repeat_progress_modal_instruction_2")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "levels" }, [
                  _c("div", { staticClass: "level five" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("learn_repeat_progress_high")
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "level four" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("learn_repeat_progress_medium_high")
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "level three" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("learn_repeat_progress_medium")
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "level two" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("learn_repeat_progress_low")
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "level one" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("learn_repeat_progress_new")
                        ),
                      },
                    }),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("span", { staticClass: "stripe" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("span", { staticClass: "stripe one" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe two" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe three" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe four" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe five" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("span", { staticClass: "stripe one" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe two" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe three" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe four" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe five" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("span", { staticClass: "stripe one" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe two" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe three" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe four" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe five" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("span", { staticClass: "stripe one" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe two" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe three" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe four" }),
      _vm._v(" "),
      _c("span", { staticClass: "stripe five" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }