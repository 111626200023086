<template>
    <div class="dynamic-goal-modal">
        <div class="dialog-window">
            <h3 v-html="this.$i18n('dynamic_goals_change_goal_title')" />
            <close-button @click="close" class="close" />
            <transition name="slide-fade">
                <div class="locked-message-container" v-if="lockedMessageVisible">
                    <locked-message :closable="false" />
                </div>
            </transition>
            <div class="cardAmount">
                <div class="amount" v-html="this.$i18n('dynamic_goals_change_goal_cards_count', { args: { daily_cards_goal: goal_size } })" />
                <span class="footnote" v-html="this.$i18n('dynamic_goals_change_goal_count_recommended_label')" :style="{ visibility: goal_size_is_recommended ? 'visible' : 'hidden' }" />
            </div>
            <slider :max_amount="recommended_max_size"
                    :position="sliderPosition"
                    @update-position="sliderUpdate"
                    @start-tracking="sliderStartTracking"
                    @end-tracking="sliderEndTracking" />
            <div class="text">
                <p v-html="this.$i18n('dynamic_goals_change_goal_reminder_text_1')" />
            </div>
            <div class="actions">
                <v-button :i18n="{ id: 'dialog_button_cancel' }" @click.native="close" size="medium" :filled="false" />
                <v-button :i18n="{ id: 'dynamic_goals_change_goal_button_set' }" @click.native="setGoal" size="medium" :filled="true" color="primary" :disabled="!setGoalEnabled" />
            </div>
        </div>
    </div>
</template>

<script>
    import CloseButton from 'Images/close-24x24-whitespace.svg';
    import Slider from './slider.vue';
    import VButton from 'ViewComponent/v-button.vue';
    import { DYNAMIC_GOAL_RECOMMENDED_SIZE, DYNAMIC_GOAL_RECOMMENDED_MAX_SIZE, Awards } from 'Modules/awards.js';
    import i18nUtils from "Util/i18n.js";
    import { TYPE as TOAST_TYPE } from "../../../toaster/constants.js";
    import { EventBus } from "Util/vue-event-bus.js";
    import { PRE_TRIAL_CARD_LIMIT } from "Modules/course/limits.js";
    import {SUBSCRIPTION_STATUS} from "Modules/subscription.js";
    import UserManager from "Modules/usermanager.js";
    import LockedMessage from "ViewComponent/locked-message.vue";

    export default {
        name: 'dynamic-goal-modal',
        components: {
            LockedMessage,
            VButton,
            CloseButton,
            Slider
        },
        data() {
            return {
                sliderInitial: true,
                initialGoalSize: Awards.getDynamicGoalSize(),
                sliderPosition: 0,
                lockedMessageVisible: false
            }
        },
        computed: {
            recommended_max_size() {
                return (DYNAMIC_GOAL_RECOMMENDED_MAX_SIZE - 10) / 10;
            },
            goal_size () {
                return this.goalSizeCalc(this.sliderPosition);
            },
            goal_size_is_recommended () {
                return (this.goal_size === DYNAMIC_GOAL_RECOMMENDED_SIZE);
            },
            setGoalEnabled () {
                const cardsDoneInCurrentSet = Awards.cardsDoneInActiveSet();
                return cardsDoneInCurrentSet < this.goal_size;
            },
            goalSizeCap() {
                const subStatus = UserManager.instance.getUser().getSubscription().getStatus().status;

                if (subStatus === SUBSCRIPTION_STATUS.PRE_TRIAL) {
                    return PRE_TRIAL_CARD_LIMIT;
                } else {
                    return null;
                }
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            goalSizeCalc(sliderPosition) {
                return sliderPosition * 10 + 10;
            },
            setGoal() {
                Awards.setDynamicGoalSize(this.goal_size);
                const toast = {
                    text: i18nUtils.prop('dynamic_goals_goal_changed_toast'),
                    type: TOAST_TYPE.SUCCESS
                };
                EventBus.$emit('toaster-add', toast);
                this.close();
            },
            keyDown(event) {
                if (event && event.code) {
                    switch (event.code.toLowerCase()) {
                        case 'escape':
                            event.preventDefault();
                            event.stopImmediatePropagation();
                            event.stopPropagation();
                            this.close();
                            break;
                    }
                }
            },

            sliderUpdate (amount) {
                if (this.goalSizeCap !== null && this.goalSizeCalc(amount) > this.goalSizeCap) {
                    this.lockedMessageVisible = true;
                    return;
                } else {
                    this.lockedMessageVisible = false;
                }
                this.sliderPosition = amount;
                this.sliderInitial = false;
            },

            sliderStartTracking () {
                this.slider_tracking = true;
            },

            sliderEndTracking () {
                this.slider_tracking = false;
            },
        },
        created() {
            setTimeout(() => {
                this.sliderPosition = (this.initialGoalSize) ? (this.initialGoalSize - 10) / 10 : 0;
            }, "200")
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.keyDown);
        }
    }
</script>

<style lang="scss">
    @import '~Styles/_mixins.scss';
    @import '~Styles/_colors.scss';

    div.dynamic-goal-modal {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 100vh;
        z-index: 99;
        overflow-y: auto;
        @include minimalistScrollbar;
        background-color: rgba(255,255,255,0.8);
        @include respond-to('small') {
            align-items: unset;
            justify-content: unset;
            background-color: white;
        }

        > div.dialog-window {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            z-index: 100;
            width: 33rem;
            //min-height: 38rem;
            gap: 1rem;
            margin: 1rem;
            background-color: white;
            border-radius: pxToRem(8);
            height: max-content;

            &.weeklyGoalView {
                justify-content: center;
            }

            @include elevated;
            @include respond-to('small') {
                margin: 0;
                box-shadow: unset;
                width: auto;
                height: max-content;
                min-height: 100vh;
            }

            > svg.close {
                position: absolute;
                right: 1rem;
                top: 1rem;
                cursor: pointer;
                transition: 100ms ease-in-out;
                z-index: 100;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: 1rem;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }

            > h3 {
                font-size: fontSize(20);
                font-weight: $font-bold;
            }

            > div.cardAmount {
                text-align: center;
                justify-content: center;
                align-items: center;
                > div.amount {
                    font-size: fontSize(26);
                    color: $colorPurplePrimary;
                }
                > div.footnote {
                    font-size: fontSize(12);
                }
            }

            > div.text {
                color: $colorSourceSecondary;
                font-size: fontSize(16);
            }

            > div.actions {
                display: flex;
                flex-direction: row;
                justify-content: end;
                align-items: center;
                gap: 1rem;
            }

            .slide-fade-enter-active {
                transition: all .3s ease;
            }
            .slide-fade-leave-active {
                transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
            }
            .slide-fade-enter, .slide-fade-leave-to {
                transform: translateX(-30px);
                opacity: 0;
            }
        }
    }
</style>
