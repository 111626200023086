<template>
    <div class="featured-in">
    <h2 v-html="this.$i18n('product_highlights_featured_title')" />
        <div class="featured-in-logos">
            <a href="https://www.huffingtonpost.com/entry/how-technology-is-helping-us-learn-new-languages_us_5a0b2a6ae4b06d8966cf32fe" target="_blank"><huffpost-logo /></a>
            <a href="http://www.dw.com/en/7-estonian-startups-to-be-reckoned-with-entrepreneurship-innovation-robotics-starship-estonianmafia/g-41028331" target="_blank"><dw-logo /></a>
            <a href="http://time.com/4032036/best-websites-learn-something-new/" target="_blank"><time-logo /></a>
            <a href="http://www.aljazeera.com/news/2017/09/estonians-eye-digital-future-170929065350530.html" target="_blank"><aljazeera-logo /></a>
            <a href="https://venturebeat.com/2020/06/17/amazon-and-techstars-announce-inaugural-alexa-next-stage-cohort/" target="_blank"><venture-beat-logo /></a>
            <a href="https://www.lifehacker.jp/2017/07/34745.html" target="_blank"><lifehacker-logo /></a>
            <a href="https://blog.aboutamazon.com/devices/alexa-next-stage-startups-pitch-their-businesses" target="_blank"><alexa-logo /></a>
            <a href="https://www.zdnet.com/article/quickly-learn-a-new-language-with-ai-powered-lingvist/" target="_blank"><zdnet-logo /></a>
        </div>
    </div>
</template>

<script>
    import AlexaLogo from "Images/pay/featured-in/alexa-next-stage-logo.svg";
    import AljazeeraLogo from "Images/pay/featured-in/aljazeera-logo.svg";
    import DwLogo from "Images/pay/featured-in/deutche-welle-logo.svg";
    import HuffpostLogo from "Images/pay/featured-in/huffpost-logo.svg";
    import LifehackerLogo from "Images/pay/featured-in/lifehacker-logo.svg";
    import TimeLogo from "Images/pay/featured-in/time-logo.svg";
    import VentureBeatLogo from "Images/pay/featured-in/venturebeat-logo.svg";
    import ZdnetLogo from "Images/pay/featured-in/zdnet-logo.svg";
    export default {
        name: 'featured-in',
        components: {
            AlexaLogo, AljazeeraLogo, DwLogo, HuffpostLogo, LifehackerLogo, TimeLogo, VentureBeatLogo, ZdnetLogo
        }
    }
</script>

<style lang="scss">
    @import "~Styles/mixins";
    div.featured-in {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > h2 {
            margin-bottom: 1rem;
        }
        > div.featured-in-logos {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            > a {
                margin: .5rem 1rem;
                transition: opacity .2s ease;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
</style>
