<template>
    <div class="variation-modal" @click.self="close">
        <div class="variation-modal-window">
            <CloseButton @click="close" class="close" />
            <template v-if="variation && !loading">
                <variation-icon :variation="variation" />
                <h2 class="title">{{ variation.name }}</h2>
                <div class="content">
                    <div class="difficulty-info" v-if="variation.difficulty">
                        <div class="difficulty-label" v-html="this.$i18n(this.difficultyName, { collection: 'difficulty_indicator_label' })" />
                        <difficulty :show-tooltip="false" :difficulty="variation.difficulty" />
                    </div>
                    <template v-if="!isSubscriptionActive && variation.paid">
                        <div class="text">
                            <p v-if="variation.description">{{ variation.description }}</p>
                            <p v-html="this.$i18n('variation_page_upgrade_words_to_learn', { args: { variation_units: variation.units }})" />
                            <p class="message" v-html="this.$i18n((this.gameOver) ? 'variation_page_upgrade_body_1' : 'variation_page_trial_offer_body_1' )"></p>
                        </div>
                        <div class="action-buttons">
                            <v-button route="subscriptions"
                                      icon="unlimited"
                                      @click.native="upgradeClicked"
                                      :filled="true"
                                      color="primary"
                                      :i18n="{ id: (this.showStartTrial) ? 'variation_page_trial_offer_button' : 'variation_page_upgrade_button' }" />
                            <variation-modal-share v-bind:variation="variation" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="text">
                            <p v-if="variation.description">{{ variation.description }}</p>
                        </div>
                        <div class="stats" v-if="variation.stats">
                            <div class="progress-bar-legend">
                                <div class="new-words" >
                                    <div class="dot" />
                                    <div class="text" v-html="this.$i18n('variation_page_count_new_words', { args: {
                                       variation_words_total: variation.stats.user_total_words, variation_units: variation.units }})" />
                                </div>
                                <div class="remembered-words">
                                    <div class="dot" />
                                    <div class="text" v-html="this.$i18n('variation_page_count_words_mastered', { args: {
                                       variation_words_remembered: variation.stats.user_remembered_words }})" />
                                </div>
                            </div>
                            <progress-bar :primary="(variation.stats.user_remembered_words / variation.units) * 100"
                                          :secondary="(variation.stats.user_total_words / variation.units) * 100" />
                        </div>
                        <div class="action-buttons">
                            <v-button v-if="!variationToggleDisabled"
                                      @click.native="enableOnlyThisVariation"
                                      :i18n="{ id: 'variation_page_continue_button_activated' }" />
                            <v-button v-else
                                      route="guess"
                                      @click.native="continueClicked"
                                      :i18n="{ id: 'variation_page_continue_button' }"/>
                            <variation-modal-share v-bind:variation="variation" />
                            <v-button v-if="canBeDiscarded" @click.native="discardLesson"
                                      :filled="true"
                                      icon="trash"
                                      :i18n="{ id: 'overflow_menu_remove_button' }"/>
                        </div>
                        <variation-settings :variation="this.variation"
                                            :variation-toggle-disabled="variationToggleDisabled" />
                    </template>
                    <div class="samples" v-if="variation.sample">
                        <p v-html="this.$i18n(samplesTitle)" />
                        <div class="words">
                            <span class="target" v-for="sample in variation.sample" :title="sample.word_translation"> {{ sample.word }} </span>
                        </div>
                    </div>
                </div>
            </template>
            <Loader v-else />
        </div>
    </div>
</template>

<script>
    import { EventBus } from 'Util/vue-event-bus';
    import Loader from 'ViewComponent/loader.vue';
    import VariationSettings from './variation-settings.vue';
    import CloseButton from 'Images/close-24x24.svg';
    import VButton from 'ViewComponent/v-button.vue';
    import VariationModalShare from './variation-modal-share.vue';
    import ProgressBar from './progress-bar.vue';
    import VariationIcon from 'ViewComponent/variation-icon.vue';
    import UserManager from 'Modules/usermanager';
    import { SUBSCRIPTION_STATUS } from "Modules/subscription";
    import Difficulty from "./difficulty.vue";

    export default {
        name: 'variation-modal',
        props: {
            variation: {
                type: Object
            },
            disableGeneralSettings: {
                type: Boolean,
                default: false
            },
            isSubscriptionActive: {
                type: Boolean
            }
        },
        components: {
            Difficulty,
            VariationSettings,
            Loader,
            VButton,
            CloseButton,
            VariationModalShare,
            ProgressBar,
            VariationIcon
        },
        data: function () {
            return {
                loading: false
            }
        },
        methods: {
            close() {
                this.$emit('close-variation-modal');
            },
            keyDown(event) {
                if (event && event.keyCode === 27) { // close on ESC key
                    this.close()
                }
            },
            enableOnlyThisVariation() {
                EventBus.$emit('enable-variation-only-this', this.variation);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('Variations', 'VariationPageStart', this.variation.uuid);

                setTimeout(() => {
                    this.close()
                }, 500);
            },
            upgradeClicked() {
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('Variations', 'VariationPageUpgrade', this.variation.uuid);
            },
            continueClicked() {
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('Variations', 'VariationPageContinue', this.variation.uuid);
            },
            discardLesson() {
                this.$emit('discard-lesson', this.variation.uuid);
                this.close();
            }
        },
        computed: {
            samplesTitle: function () {
                const { status } = this.variation;
                switch (status) {
                    case 'initial':
                        return 'variation_page_start_body';
                    case 'in_progress':
                    case 'complete':
                        return 'variation_page_continue_body';
                    case 'subscription_limited':
                        return 'variation_page_upgrade_body_2';
                    default:
                        return 'variation_page_start_body';
                }
            },
            variationToggleDisabled: function () {
                return !!(this.variation && this.variation.type === 'general' && this.disableGeneralSettings);
            },
            gameOver() {
                return UserManager.instance.getUser().getCourse().getLimits().isGameOver();
            },
            showStartTrial() {
                let subStatus = UserManager.instance.getUser().getSubscription().getStatus().status;
                return subStatus === SUBSCRIPTION_STATUS.PRE_TRIAL;
            },
            canBeDiscarded() {
                return (this.variation && this.variation.type === 'lesson' && !this.variation.assigned_ts);
            },
            difficultyName() {
                if (this.variation) {
                    switch(this.variation.difficulty) {
                        case 1:
                            return 'beginner'
                        case 2:
                            return 'intermediate'
                        case 3:
                            return 'advanced'
                    }
                }
            }
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
            document.body.classList.add('modal-open');

            if (this.variation) {
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('Variations', 'VariationPage', this.variation.uuid);
            }
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.variation-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43,81,142,0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.variation-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 35vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > div.variation-icon {
                position: absolute;
                left: 1rem;
                top: 1rem;
                body[data-interface-language="ar"] & {
                    left: unset;
                    right: 1rem;
                }
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }
                &:hover {
                    transform: scale(1.2);
                }
            }

            > h2.title {
                text-align: center;
            }

            > div.content {
                margin-top: pxToRem(24);

                > div.difficulty-info {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 1rem;
                    gap: .5rem;
                    > div.difficulty-label {
                        color: $colorSourceSecondary;
                    }
                }

                > div.text {
                    p {
                        margin-top: pxToRem(8);
                        &:first-child {
                            margin-top: 0;
                        }
                        > em {
                            color: $colorTargetPrimary;
                        }
                        &.message {
                            background-color: $colorBackgroundGray;
                            padding: .5rem;
                            border-radius: .5rem;
                        }
                    }
                }

                > div.stats {
                    margin: 1rem 0 1.5rem;
                    > div.progress-bar-legend {
                        margin-bottom: .5rem;
                        > div.new-words,
                        > div.remembered-words {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: .5rem;
                            > div.dot {
                                width: 0.5rem;
                                height: 0.5rem;
                                border-radius: 50%;
                                background-color: $colorSourcePrimary;
                            }
                        }
                        > div.new-words {
                            > div.dot {
                                background-color: rgba($colorCorrect, 0.4);
                            }
                            margin-bottom: .25rem;
                        }
                        > div.remembered-words {
                            > div.dot {
                                background-color: $colorCorrect;
                            }
                        }
                    }
                }

                > div.action-buttons {
                    margin-top: pxToRem(16);
                    display: flex;
                    gap: 1rem;
                }

                > div.variation-settings {
                    margin-top: pxToRem(16);
                    border-top: 1px solid $colorBackgroundDarker;
                    border-bottom: 1px solid $colorBackgroundDarker;
                    padding: pxToRem(16) 0;
                }

                > div.samples {
                    margin-top: pxToRem(16);
                    padding-top: pxToRem(8);
                    > div.words {
                        margin: pxToRem(8) 0;
                        span {
                            margin-right: pxToRem(16);
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                }

            }

            > div.loader {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -2rem;
                margin-top: -1rem;
            }
        }
    }
</style>
