
'use strict';

import superagent from 'superagent';

import { AssetLoader } from '../modules/asset.loader.js';

export const assetLoader = new AssetLoader({
    // Configuration is hardcoded for now
    POOL_SIZE: 5,
    TIMEOUT_MULTIPLIER: 2,
    RANDOM_COMPONENT: true,
    MAX_RETRIES: 5,
    TIMEOUT_BASE: 50
}, superagent);
