<template>
    <div class="bulk-modal" @click.self="close">
        <div class="bulk-modal-window" :class="{ 'disabled': this.interactionDisabled }">
            <CloseButton @click="close" class="close" v-if="!this.interactionDisabled" />
            <h2 class="title" v-html="this.$i18n('lessons_creator_editor_add_text_title')" />
            <p class="sub-title" v-html="this.$i18n('lessons_creator_editor_add_text_microcopy')" />
            <div class="content" v-if="!uploadLoading">
                <textarea ref="seed_text" v-model="seed_text" rows="10" maxlength="4950" minlength="1" :placeholder="textareaPlaceholder" ondragstart="return false" ondrop="return false" />
            </div>

            <upload-file v-bind:lesson_uuid="this.lesson_uuid" v-on:text-from-file="textFromFileArrived" v-on:ocr-done="ocrDone" v-on:loading="uploadFileLoading" />

            <div class="action-buttons" v-if="!uploadLoading">
                <v-button @click.native="addCardsPressed"
                          :filled="true" color="primary"
                          :disabled="this.seed_text.length <= 0"
                          :i18n="{ id: 'lessons_creator_editor_add_text_add_words_button', params: { words: 2 } }" />
                <v-button @click.native="close"
                          :i18n="{ id: 'lessons_creator_editor_add_text_cancel_button' }" />
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from 'Util/vue-event-bus';
    import CloseButton from 'Images/close-24x24.svg';
    import VButton from 'ViewComponent/v-button.vue';
    import i18nUtils from 'Util/i18n';
    import UploadFile from './upload-file.vue';


    export default {
        name: 'bulk-modal',
        components: {
            VButton,
            CloseButton,
            UploadFile
        },
        props: {
            lesson_uuid: {
                type: String
            },
            interactionDisabled: {
                type: Boolean
            },
        },
        data() {
            return {
                seed_text: '',
                uploadLoading: false
            }
        },
        computed: {
            textareaPlaceholder() {
                return i18nUtils.prop('lessons_creator_editor_add_text_hint');
            }
        },
        methods: {
            close(event, force = false) {
                if (!this.interactionDisabled || force) {
                    EventBus.$emit('cw:close-bulk-modal');
                }
            },
            keyDown(event) {
                if (event && event.keyCode === 27) { // close on ESC key
                    this.close();
                }
            },
            addCardsPressed() {
                this.$emit('add-cards', { seed_text: this.seed_text, intent: 'text' });
                this.close();
            },
            setFocus() {
                this.$refs.seed_text.focus();
            },
            textFromFileArrived(text) {
                this.$emit('add-cards', { seed_text: text, intent: 'text' });
                this.close();
            },
            ocrDone(info) {
                const { file_uuid: seed_file } = info;
                this.$emit('add-cards', { seed_file, intent: 'text' });
                this.close(null,true);
            },
            uploadFileLoading(state) {
                this.uploadLoading = state;
            }
        },
        mounted() {
            document.addEventListener('keydown', this.keyDown);
            document.body.classList.add('modal-open');

            this.$nextTick(() => this.setFocus());
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.keyDown);
            document.body.classList.remove('modal-open');
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.bulk-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43,81,142,0.80);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div.bulk-modal-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: pxToRem(500);
            min-height: pxToRem(400);
            display: flex;
            flex-direction: column;
            max-width: 40vw;
            box-sizing: border-box;
            padding: pxToRem(24);

            @include respond-to('small') {
                min-width: 100vw;
                min-height: unset;
                border-radius: unset;
            }

            > svg.close {
                cursor: pointer;
                position: absolute;
                right: pxToRem(24);
                top: pxToRem(24);
                transition: 100ms ease-in-out;
                body[data-interface-language="ar"] & {
                    right: unset;
                    left: pxToRem(24);
                }
                &:hover {
                    transform: scale(1.2);
                }
            }

            > h2.title {
                font-size: fontSize(20);
                font-weight: $font-bold;
                user-select: none;
            }

            > p {
                user-select: none;
            }

            > div.content {
                margin-top: pxToRem(24);
                > textarea {
                    width: 100%;
                    background: $colorInputBox;
                    color: $colorSourcePrimary;
                    padding: pxToRem(16);

                    &:focus {
                        background: $colorInputBoxFocus;
                    }
                }
            }

            > div.upload-file {
                margin-top: pxToRem(16);
            }

            > div.action-buttons {
                margin-top: pxToRem(16);
                display: flex;
                flex-direction: row-reverse;
                > button {
                    margin-right: pxToRem(16);
                    &:first-child {
                        margin-right: 0;
                    }
                }
            }

            &.disabled {
                pointer-events: none;
                filter: grayscale(100%);
                > h2.title,
                > p,
                > div.content,
                > div.upload-file {
                    opacity: 0.6;
                }
            }
        }
    }
</style>
