var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumb" },
    [
      _vm._l(_vm.steps, function (step, index) {
        return [
          _c(
            "div",
            {
              staticClass: "step",
              class: { active: _vm.isStepActive(step) },
              on: { click: _vm.stepClicked },
            },
            [_vm._v("\n            " + _vm._s(step.title) + "\n        ")]
          ),
          _vm._v(" "),
          index < _vm.steps.length - 1 ? _c("chevron-right") : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }