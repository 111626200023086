var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "signin" }, [
    _c("h1", { domProps: { innerHTML: _vm._s(this.$i18n("signin_welcome")) } }),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "user-accounts" },
      _vm._l(
        _vm.user_accounts_,
        function ({ user, course, subscription, auth_provider }) {
          return _c(
            "div",
            {
              key: user.user_uuid,
              staticClass: "account",
              on: {
                click: function ($event) {
                  return _vm.signin_account_selected({ user, auth_provider })
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "highlight" },
                [
                  _c("course-icon", {
                    attrs: { value: course.target_icon_id, size: "large" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _c("h4", { staticClass: "email" }, [
                      _vm._v(_vm._s(user.email)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "course" }, [
                      _vm._v(
                        _vm._s(course.name) + " " + _vm._s(course.name_subtitle)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "subscription" }, [
                      _vm._v(_vm._s(subscription.text)),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          )
        }
      ),
      0
    ),
    _vm._v(" "),
    _c(
      "footer",
      [
        _c("authentication-options", {
          attrs: {
            options: _vm.login_options,
            more_options_label: this.$i18n("signin_more_options"),
          },
          on: { option_selected: _vm.signin_option_selected },
        }),
        _vm._v(" "),
        _vm.register_enabled
          ? _c("button", {
              staticClass: "register outline",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_new_to_lingvist")),
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("register")
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.current_error === "error-authentication"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_error_authentication")),
              },
            })
          : _vm.current_error === "authorization-failed"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("signin_error_authorization_failed")
                ),
              },
            })
          : _vm.current_error === "error-server"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_error_server")),
              },
            })
          : _vm.current_error === "error-network"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_error_network")),
              },
            })
          : _vm.current_error === "duplicate-email"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_error_duplicate_email")),
              },
            })
          : _vm.current_error === "deletion-in-progress"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(
                  this.$i18n("signin_error_deletion_in_progress")
                ),
              },
            })
          : _vm.current_error === "error-unauthorized"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_error_unauthorized")),
              },
            })
          : _vm.current_error === "error-unauthorized"
          ? _c("p", {
              staticClass: "error",
              domProps: {
                innerHTML: _vm._s(this.$i18n("signin_error_server")),
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }