<template>
    <div class="breadcrumb">
        <template v-for="(step, index) in steps">
            <div class="step" @click="stepClicked" :class="{ active: isStepActive(step) }">
                {{ step.title }}
            </div>
            <chevron-right v-if="index < steps.length - 1" />
        </template>
    </div>
</template>

<script>
    import ChevronRight from 'Images/chevron-right-16.svg';
    import i18nUtils from "Util/i18n";

    export default {
        name: 'breadcrumb',
        components: {
            ChevronRight
        },
        props: {
            page: {
                type: String,
                required: true
            },
            completeCardsCount: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                steps: [{
                    title: i18nUtils.prop('lessons_creator_editor_header_breadcrumb_1_start'),
                    page: 'cards',
                    completeCardsRequired: false,
                },{
                    title: i18nUtils.prop('lessons_creator_editor_header_breadcrumb_2_preview'),
                    page: 'cards',
                    completeCardsRequired: true,
                },{
                    title: i18nUtils.prop('lessons_creator_editor_header_breadcrumb_3_publish'),
                    page: 'meta',
                    completeCardsRequired: true
                }]
            }
        },
        methods: {
            stepClicked(event) {

            },
            isStepActive(step) {
                if (step.completeCardsRequired) {
                    return (step.page === this.page && this.completeCardsCount > 0);
                } else {
                    return (step.page === this.page && this.completeCardsCount === 0);
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.breadcrumb {
        padding: .5rem;
        border-radius: pxToRem(8);
        background-color: $colorBackgroundDefault;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        > div.step {
            user-select: none;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: $colorSourceTertiary;
            &.active {
                color: $colorSourcePrimary;
            }
        }
    }
</style>
