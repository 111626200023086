<template>
    <button class="button-dropdown-component" :class="{ disabled }" @mouseleave="closeDropdownDelayed">
        <span class="primary" @click="triggerAction(primary.action)" :class="{ disabled: primary.disabled }" v-html="this.$i18n(primary.i18n.id, { collection: primary.i18n.collection, args: primary.i18n.params })" />
        <span class="dropdown" @click="openDropdown">
            <arrow-up-icon v-if="dropdownOpen" />
            <arrow-down-icon v-else />
        </span>
        <transition name="fade">
            <span class="dropdown-content" v-if="dropdownOpen" @mouseenter="openDropdown" @mouseleave="closeDropdown">
                <span class="dropdown-item" v-for="item in dropdown"
                      @click="triggerAction(item.action)">
                    <component v-if="item.icon" v-bind:is="icon(item.icon)" />
                    <span class="text" v-html="$i18n(item.i18n.id, { collection: item.i18n.collection, args: item.i18n.params })" />
                </span>
            </span>
        </transition>
    </button>
</template>

<script>
    import ArrowDownIcon from 'Images/arrows/arrow-down.svg';
    import ArrowUpIcon from 'Images/arrows/arrow-up.svg';

    // possible icons used in dropdown items <component v-if="item.icon" v-bind:is="icon(item.icon)" />
    import DiscardIcon from 'Images/remove.svg';
    import LessonsIcon from 'Images/read.svg';

    export default {
        name: 'v-button-dropdown',
        props: {
            primary: {
                Type: Object,
                required: true
            },
            dropdown: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ArrowDownIcon,
            ArrowUpIcon
        },
        data() {
            return {
                dropdownOpen: false,
                dropdownCloseTimeout: null
            }
        },
        methods: {
            triggerAction(action) {
                this.$emit('action', action);
            },
            openDropdown() {
                clearTimeout(this.dropdownCloseTimeout);
                this.dropdownOpen = true;
            },
            closeDropdown() {
                this.dropdownOpen = false;
            },
            closeDropdownDelayed() {
                this.dropdownCloseTimeout = setTimeout(() => {
                    this.closeDropdown();
                }, 250);
            },
            icon: function (iconName) {
                switch (iconName) {
                    case 'discard':
                        return DiscardIcon;
                    case 'lessons':
                        return LessonsIcon;
                }
            },
        }
    };
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    button.button-dropdown-component {
        display: flex;
        height: unset;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        padding: 0;
        user-select: none;
        border-radius: unset;
        background-color: transparent;
        border: 0;
        color: #fff;
        &:hover {
            cursor: default;
        }
        > span.dropdown {
            background-color: $colorSourcePrimary;
            border-color: $colorSourcePrimary;
            border-left: 1px solid #fff;
            padding: pxToRem(8) pxToRem(16);
            border-top-right-radius: pxToRem(12);
            border-bottom-right-radius: pxToRem(12);
            &:hover {
                opacity: .8;
                cursor: pointer;
            }
            > svg > path {
                fill: #fff;
            }
        }
        > span.primary {
            background-color: $colorSourcePrimary;
            padding: pxToRem(8) pxToRem(16) pxToRem(8) pxToRem(16);
            border-top-left-radius: pxToRem(12);
            border-bottom-left-radius: pxToRem(12);
            border: 1px solid $colorSourcePrimary;
            &:hover {
                opacity: .8;
                cursor: pointer;
            }
            &.disabled {
                opacity: .8;
                pointer-events: none;
                cursor: default;
            }
        }
        > span.dropdown-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            min-width: pxToRem(220);
            right: 0;
            top: pxToRem(35);
            @include elevated;
            padding: pxToRem(4) 0;
            background-color: #fff;
            z-index: 100;
            color: $colorSourcePrimary;
            text-align: left;
            > span.dropdown-item {
                border-top: pxToRem(1) solid $colorBackgroundGraySecondary;
                padding: pxToRem(8) pxToRem(16);
                display: flex;
                align-items: center;
                > svg {
                    margin-right: pxToRem(8);
                }
                &:first-child {
                    border-top: none;
                }
                &:hover {
                    opacity: .8;
                    cursor: pointer;
                }
            }
        }
        &.disabled {
            color: $colorSourceTertiary;
            border-color: $colorSourceTertiary;
            background-color: transparent;
            pointer-events: none;
            cursor: default;
        }
        &.fade-enter-active, &.fade-leave-active {
            transition: opacity 250ms;
        }
        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }
    }
</style>
