<template>
    <span class="autosize-input" :class="{ 'hidden': this.hidden }">
        <input
            :value="value"
            :width="`${inputWidth}px`"
            :style="styles"
            v-bind="$attrs"
            @input="updateValue"
            class="autosize-input-box"
            :class="className"
            :disabled="this.disabled"
            v-on:keyup.enter.exact="submit"
            v-on:blur="blurIt"
            v-on:focus="() => $emit('focus')"
            type="text"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            ref="inputField"
        >
        <span ref="sizer" :style="sizerStyle">{{ value }}</span>
        <span v-if="placeholder" ref="placeHolderSizer" :style="sizerStyle">{{ placeholder }}</span>
    </span>
</template>

<script>
export default {
    name: 'AutosizeInput',
    props: {
        id: String,
        className: String,
        minWidth: {
            type: String,
            default: '10px',
        },
        maxWidth: {
            type: String,
            default: '300px',
        },
        extraWidth: [String, Number],
        placeholderIsMinWidth: Boolean,
        placeholder: String,
        value: {
            type: String,
            required: true,
        },
        hidden: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            inputWidth: this.minWidth,
            inputId: this.id || `_${Math.random().toString(36).substr(2, 12)}`,
            sizerStyle: {
                position: 'absolute',
                top: 0,
                left: 0,
                visibility: 'hidden',
                height: 0,
                overflow: 'scroll',
                whiteSpace: 'pre',
            },
        }
    },
    watch: {
        value () {
            this.$nextTick(this.updateInputWidth)
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
            }
        },
        styles() {
            return {
                width: `${this.inputWidth}px`,
                minWidth: this.minWidth,
                maxWidth: this.maxWidth
            }
        }
    },
    mounted () {
        this.updateInputWidth()
    },
    methods: {
        updateValue(event) {
            const inputValue = event.target.value;
            this.$emit('input', inputValue);
            this.$emit('change', event);
            this.updateInputWidth();
        },
        focusInput() {
            if (this.$refs.inputField) {
                this.$refs.inputField.focus();
            }
        },
        getSelectionStart() {
            return this.$refs.inputField.selectionStart;
        },
        blurInput() {
            if (this.$refs.inputField) {
                this.$refs.inputField.blur();
            }
        },
        updateInputWidth () {
            if (!this.$refs.sizer || typeof this.$refs.sizer.scrollWidth === 'undefined') {
                return
            }
            let newInputWidth;
            if (this.placeholder && (!this.value || (this.value && this.placeholderIsMinWidth))) {
                newInputWidth = Math.max(this.$refs.sizer.scrollWidth, this.$refs.placeHolderSizer.scrollWidth) + 2;
            } else {
                newInputWidth = this.$refs.sizer.scrollWidth + 2;
            }
            const extraWidth = (this.$attrs.type === 'number' && this.extraWidth === undefined)
                ? 16 : parseInt(this.extraWidth) || 0;
            newInputWidth += extraWidth;
            if (newInputWidth < this.minWidth) {
                newInputWidth = this.minWidth;
            }
            if (newInputWidth !== this.inputWidth) {
                this.inputWidth = newInputWidth;
            }
        },
        submit(e) {
            this.$emit('submit', e);
        },
        blurIt(e) {
            this.$emit('blur', e);
        },
        mounted() {
            this.focusInput();
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '~Styles/mixins';
    @import "~Styles/colors";
    span.autosize-input {
        display: inline;
        > input {
            outline: none;
            &.autosize-input-box {
                box-sizing: content-box;
                border: none;
                background-color: transparent;
            }
            &.answer-input {
                //font-size: 1.8946rem;
                //line-height: 2.5rem;
                //font-family: $targetLanguageFonts;
                font-feature-settings: "lnum" on;
            }
        }
    }
</style>
