<template>
    <div class="support-modal-pay">
        <div class="support-modal-pay-window">
            <header>
                <h2 v-html="this.$i18n('support_float_title')" />
                <CloseButton @click="close" class="close" v-if="closable" />
            </header>
            <main>
                <div class="field" :class="{ focused: this.messageInputFocused }">
                    <textarea name="message" ref="message" rows="4"
                           @focus="messageInputFocused = true"
                           @blur="messageInputFocused = false"
                           v-model="userMessage"
                           :placeholder="messagePlaceholder" />
                </div>
                <div class="actions">
                    <v-button :i18n="{ id: 'dialog_button_cancel' }" @click.native="close" size="medium" />
                    <v-button :i18n="{ id: 'support_float_button_send_message' }" :disabled="!messageIsValid" @click.native="sendMessage" :filled="true" size="medium" />
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import CloseButton from 'Images/close-24x24.svg';
import VButton from "ViewComponent/v-button.vue";
import i18nUtils from "Util/i18n.js";
import AppState from "Modules/app.state.js";
import { TYPE as TOAST_TYPE } from "../../toaster/constants.js";
import { EventBus } from "Util/vue-event-bus.js";
import UserManager from "Modules/usermanager.js";

export default {
    name: 'support-modal-pay',
    props: {
        closable: {
            type: Boolean,
            default: true
        }
    },
    components: {
        VButton,
        CloseButton,
    },
    data() {
        return {
            messageInputFocused: false,
            userMessage: ''
        }
    },
    computed: {
        messagePlaceholder() {
            return i18nUtils.prop('support_placeholder_text_field');
        },
        messageIsValid() {
            return this.userMessage.length >= 2;
        }
    },
    methods: {
        close() {
            if (this.closable) {
                this.$emit('close');
            }
        },
        keyDown(event) {
            if (this.closable && event && event.code === 'Escape') { // close on ESC key
                this.close()
            }
        },
        sendMessage() {
            if (this.messageIsValid) {
                let appState = new AppState();
                UserManager.instance.getUser().getEventSender().sendFeedbackEvent({
                    content: this.userMessage,
                    mood: 'okay',
                    category: 'default'
                }, appState, UserManager.instance.getUser().getSubscription().getStatus());
                this.showSuccessToast('feedback');
                this.close();
            }
        },
        showSuccessToast() {
            const toast = {
                text: i18nUtils.prop('feedback_doorslam_confirmation'),
                type: TOAST_TYPE.SUCCESS,
                time: 4000
            };
            EventBus.$emit('toaster-add', toast);
        },
        focusInput() {
            this.messageInputFocused = true;
            this.$refs.message.focus();
        }
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
        document.body.classList.add('modal-open');
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
    }
};
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.support-modal-pay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        background-color: rgba(44, 49, 67, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div.support-modal-pay-window {
            position: relative;
            @include elevated;
            background-color: #fff;
            border-radius: 0.75rem;
            display: flex;
            flex-direction: column;
            max-height: calc(100% - 2rem);
            min-width: pxToRem(400);
            max-width: pxToRem(600);
            box-sizing: border-box;
            padding-bottom: 1.5rem;
            gap: 1rem;

            > header {
                padding: 1.5rem 1.5rem 0 1.5rem;
                display: flex;
                flex-direction: row;
                > svg.close {
                    cursor: pointer;
                    transition: 100ms ease-in-out;
                    margin-left: auto;
                    body[data-interface-language="ar"] & {
                        margin-left: unset;
                        margin-right: auto;
                    }
                    &:hover {
                        transform: scale(1.2);
                    }
                }

                > h2 {
                    font-size: fontSize(20);
                    line-height: fontSize(24);
                    text-align: left;
                    margin-right: 1rem;
                    body[data-interface-language="ar"] & {
                        text-align: right;
                        margin-left: 1rem;
                        margin-right: unset;
                    }
                }
            }

            > main {
                padding: 0 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                div.field {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    padding: .5rem;
                    border: 2px solid $colorBackgroundDarker;
                    border-radius: .5rem;
                    &.focused {
                        border: 2px solid $colorBackgroundDark;
                        border-radius: .5rem;
                    }
                    > textarea {
                        flex: 1;
                        @include minimalistScrollbar(8px);
                    }
                    > svg.edit-icon {
                        margin-left: auto;
                        cursor: pointer;
                        transition: all 150ms;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
                > div.actions {
                    margin-top: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    gap: 1rem;
                }
            }

        }
    }
</style>
