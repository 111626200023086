
import $ from 'jquery';

export default {

    _headers: {},

    setup: function () {
        var self = this;

        $.ajaxSetup({
            contentType:"application/json",
            cache: false,
            beforeSend: function (xhr) {
                Object.keys(self._headers).forEach(function (headerName) {
                    var headerContent = self._headers[headerName];
                    xhr.setRequestHeader(headerName, headerContent);
                });
            }
        });

        return Promise.resolve();
    },

    setHeader: function (headerName, headerContent) {
        this._headers[headerName] = headerContent;
    },

    removeHeader: function (headerName) {
        delete this._headers[headerName];
    }
};
