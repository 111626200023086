var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "account" },
    [
      this.confirmationModalContent
        ? _c("confirmation-modal", {
            attrs: { content: this.confirmationModalContent },
            on: {
              close: _vm.closeConfirmationModal,
              action: _vm.triggerAction,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.supportModalVisible
        ? _c("support-modal", {
            on: { close: _vm.closeSupportModal, send: _vm.sendFeedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "profile" },
        [
          _c("h1", {
            domProps: { innerHTML: _vm._s(this.$i18n("account_title")) },
          }),
          _vm._v(" "),
          _c("section-name", {
            attrs: { name: this.fullName },
            on: { save: _vm.saveName },
          }),
          _vm._v(" "),
          _c("section-email", {
            attrs: { email: this.email, emailVerified: this.emailVerified },
            on: { save: _vm.saveEmail },
          }),
          _vm._v(" "),
          this.passwordSet
            ? _c("section-password", { on: { save: _vm.savePassword } })
            : [
                _c("section-password-create", {
                  on: { save: _vm.createPassword },
                }),
                _vm._v(" "),
                _c("hr"),
              ],
          _vm._v(" "),
          _c("section-discount", {
            attrs: { "opt-in": this.marketing_opt_in },
            on: { save: _vm.saveOptIn },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("section-action", {
            attrs: {
              title: this.$i18n(
                "account_additional_settings_data_download_lbl"
              ),
              description: this.$i18n(
                "account_additional_settings_data_download_txt"
              ),
              action: "open-download-modal",
            },
            on: { action: _vm.triggerAction },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("section-action", {
            attrs: {
              title: this.$i18n("account_profile_delete_your_account_title"),
              description: this.$i18n(
                "account_profile_delete_your_account_info"
              ),
              action: "open-delete-modal",
            },
            on: { action: _vm.triggerAction },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("section-action", {
            attrs: {
              title: this.$i18n(
                "account_additional_settings_customer_support_lbl"
              ),
              action: "support",
            },
            on: { action: _vm.triggerAction },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("section-action", {
            attrs: {
              title: this.$i18n("account_profile_trustpilot_title"),
              action: "trustpilot",
            },
            on: { action: _vm.triggerAction },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("v-button", {
            staticClass: "signOutButton",
            attrs: {
              i18n: { id: "menu_sign_out" },
              filled: true,
              color: "primary",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.signOut.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("terms", { attrs: { message_key: "account_legal_link" } }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "subscription" },
        [
          _c("subs-main", {
            on: {
              "cancel-subscription-modal": _vm.showCancelSubscriptionConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }