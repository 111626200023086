var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#63E1E7",
          d: "M11.2 17.2l-2.4 2.4L2.2 13c-.8-.8-1.2-1.8-1.2-2.9 0-1 .4-2.1 1.2-2.9C3.7 5.7 6 5.6 7.6 6.8c-.1.2-.3.3-.4.4-1.6 1.6-1.6 4.3 0 5.9l4 4.1z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M14.3 6.5c2-2 5.2-2 7.2 0s2 5.3 0 7.3l-3.8 3.9c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l3.8-3.9c1.2-1.2 1.2-3.2 0-4.5-1.2-1.2-3.2-1.2-4.4 0L14 9.6l-1.8-1.7c-.5-.6-1.3-.9-2-.9-.8 0-1.7.3-2.3.9-1.2 1.2-1.2 3.2 0 4.5l6.8 6.9c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-6.8-6.9c-2-2-2-5.3 0-7.3 1-1 2.4-1.5 3.8-1.5 1.2 0 2.5.5 3.4 1.5l.3.3.3-.3z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#2C3143",
          d: "M8.4 10.5c-.1-1.2 1-2.2 2.2-2.2.6 0 1 .5 1 1s-.5 1-1 1h-.1v.1c0 .6-.4 1-1 1-.6.1-1.1-.3-1.1-.9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }