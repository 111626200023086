
'use strict';

import Vue from 'vue';
import Raven from 'raven-js';
import EventEmitter from 'events';

import ControllerFactory from '../modules/controller.factory.js';
import { parse as parse_qs } from '../util/query-string.js';
import SigninMainView from '../view/signin/main.vue';
import i18nUtils from 'Util/i18n.js';
import Courses from 'Modules/courses.js';
import Backbone from 'backbone';
import {UserAccounts} from 'Modules/user.accounts.js';
import {SUBSCRIPTION_STATUS} from 'Modules/subscription.js';
import {oauth_authorize} from 'Modules/third_party_signin';
import UserModel from 'Model/user.model.js';

const SigninViewComponent = Vue.extend(SigninMainView);

const SIGNIN_OPTION = {
    EMAIL: 'email',
    APPLE: 'apple',
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    WEIBO: 'weibo',
};


export class SigninController extends EventEmitter.EventEmitter {
    constructor () {
        super();

        this._onboarding_controller = null;
        this._view = null;
    }

    initialize (onboarding_controller) {
        this._onboarding_controller = onboarding_controller;
        this._onboarding_controller.on('step-done', step => {
            this.emit(`onboarding-step-done:${step}`);
        });

        Backbone.on('smartLockSignInError', () => {
            if (this._view) {
                this._view.set_error('error-authentication');
            }
        });
    }

    getView () {
        return this._view;
    }

    async _getUserAccounts () {
        const _accounts = await (new UserAccounts()).getUserAccounts();
        _accounts.map(account => {
            let { subscription } = account;
            let subName = '-';
            if (subscription.hasOwnProperty('product_name') && subscription.product_name) {
                subName = i18nUtils.prop(subscription.product_name, null, null, 'subscription_name');
            } else { // fallback for old behaviour if product_name is not present yet (will be added on login)
                switch(subscription.status) {
                    case SUBSCRIPTION_STATUS.INTRO_TRIAL:
                    case SUBSCRIPTION_STATUS.INITIAL_TRIAL:
                    case SUBSCRIPTION_STATUS.ADDITIONAL_TRIAL:
                        subName = i18nUtils.prop('trial', null, null, 'subscription_plan_names');
                        break;
                    case SUBSCRIPTION_STATUS.PAID:
                    case SUBSCRIPTION_STATUS.PAID_INTRO:
                        subName = i18nUtils.prop('paid', null, null, 'subscription_plan_names');
                        break;
                    default:
                        subName = '–';
                }
            }

            if (account.course) {
                const _course = Courses.getCourse(account.course.uuid);
                if (_course) { // Fix so sign-in doesn't die when user has no course.. for some reason
                    const _courseInfo = _course.getInfo();
                    account.course.name = _courseInfo.name;
                    account.course.name_subtitle = _courseInfo.name_subtitle;
                }
            }

            subscription.text = i18nUtils.prop('account_row', { subscription: subName });

            return account;
        });
        return _accounts;
    }

    handle_oauth_error (view, error) {
        Backbone.trigger('hideLoader');
        if (error === 'closed') {
            view.reset_error();
        } else {
            view.set_error(error);
        }
    }

    async oauth_signin (view, provider, email = null) {
        try {
            const data = await oauth_authorize(provider, email);

            let { provider: return_provider, code, user, redirect_uri } = data;
            await UserModel.oauth_signin(return_provider, code, user, redirect_uri);
        } catch (error) {
            this.handle_oauth_error(view, error);
        }
    }

    signin_email (email = null) {
        Backbone.history.navigate(
            `signin-credentials${email ? ('?email=' + email) : '' }`, {trigger: true});
    }

    async go (appView, router, queryString=null) {
        let { email, error_unauthorized } = parse_qs(queryString);
        window.history.replaceState({}, document.title, '/' + location.hash); // Remove search string

        this._view = new SigninViewComponent({
            data: {},
            propsData: {
                user_accounts: await this._getUserAccounts(),
                get_user_accounts: async () => await this._getUserAccounts(),
                register_enabled: Courses.getCoursesByInterfaceLanguage(i18nUtils.currentInterfaceLanguage).length > 0,
                error_unauthorized: !!(error_unauthorized),
            }
        });

        this._view.$on('mounted', () => {
            this.emit('view-initial-rendered');
        });

        this._view.$on('register', () => {
            Backbone.history.navigate('register', {trigger: true});
        });

        this._view.$on('signin_option_selected', option => {
            Backbone.trigger('showLoader', 'signin');
            if (option === SIGNIN_OPTION.EMAIL) {
                this.signin_email(email);
            } else if (Object.values(SIGNIN_OPTION).includes(option)) {
                this.oauth_signin(this._view, option, email);
            } else {
                Backbone.trigger('hideLoader');
                Raven.captureException(new Error(`Signin: Unknown signin option: "${option}"`),
                    {level: 'error'});
            }
        });

        this._view.$on('signin_account_selected', ({user, auth_provider}) => {
            Backbone.trigger('showLoader', 'signin');
            if (auth_provider === 'password') {
                this.signin_email(user.email || email);
            } else if (Object.values(SIGNIN_OPTION).includes(auth_provider)) {
                this.oauth_signin(this._view, auth_provider, user.email || email);
            } else {
                Backbone.trigger('hideLoader');
                if (user && user.email) {
                    this.signin_email(user.email);
                } else {
                    Raven.captureException(new Error(`Signin: Unknown authentication provider on account: "${auth_provider}" without an e-mail`),
                        {level: 'error'});
                }
            }
        });

        appView.setMainView(this._view);

    }

    async continueOAuth (error, data) {
        if (this._view !== null) {
            if (error === null) {
                let { provider, code, user, redirect_uri } = data;

                try {
                    await UserModel.oauth_signin(provider, code, user, redirect_uri);
                } catch (error_status) {
                    this.handle_oauth_error(this._view, error_status);
                }
            } else {
                this.handle_oauth_error(this._view, error);
            }
        } else {
            Raven.captureException(new Error(`Signin: continueOAuth called when view is not initialized`),
                {level: 'error'});
        }
    }
}

export const signin_controller_factory = new ControllerFactory(SigninController);
