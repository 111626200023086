/* global console, Audio, setTimeout, document */

import $ from 'jquery';
import Backbone from 'backbone';
import moment from 'moment';

import UserManager from '../../modules/usermanager.js';
import SpeechReco from '../../modules/speech.reco.js';

import renderTemplate from '../../util/template.renderer.js';
import KeyCodes from '../../util/keycodes.js';
import AudioPlayer from '../../util/audioplayer.js';

const RecorderView = Backbone.View.extend({

    name: 'recorder',
    tagName: 'main',
    className: 'recorder-view',

    events: {
        'click a.skip': 'skipSentence',
        'click a.button.record': 'startRecording',
        'click a.button.play': 'startPlayback'
    },

    initialize: function (options) {
        console.log('Recorder View init');

        const { targetLanguage } = options;

        this.props = {};

        this.state = {
            expectedTextVisible: false,
            transcript: '',
            status: 'initial',
            recordingActive: false,
            evaluatedWords: null,
            answerIsCorrect: null,
        };

        this.beepAudio = new Audio();
        this.beepAudio.src = 'audio/alert.m4a';
        this.beepAudio.load();

        this.answerCorrectAudio = new Audio();
        this.answerCorrectAudio.src = 'audio/correct.m4a';
        this.answerCorrectAudio.load();

        this.speechReco = new SpeechReco(targetLanguage);

        this.speechReco.handleEvent('start', () => {
            this.updateState({ status: 'recording' });
        });

        this.speechReco.handleEvent('result', (transcript, confidence) => {
            this.state.transcript = transcript;
            this.state.confidence = confidence;
            this.evaluateTranscript(transcript, false);
        });

        this.speechReco.handleEvent('finalResult', (transcript, confidence) => {
            console.log(`SpeechRecognition result: "${transcript}" confidence: ${confidence}`);
            this.evaluateTranscript(transcript, true);
        });

        this.speechReco.handleEvent('end', () => {
            this.updateState({ status: 'ready', evaluatedWords: null });
        });

        this.speechReco.handleEvent('error', errorType => {
            this.updateState({ status: 'error', evaluatedWords: null });
        });

        this.keyPressed = event => {

            if (this.state.status === 'ready') {
                switch (event.keyCode) {
                    case KeyCodes.SPACE: {
                        event.stopPropagation();
                        this.startRecording();
                        break;
                    }
                    case KeyCodes.ENTER: {
                        event.stopPropagation();
                        this.startPlayback();
                        break;
                    }
                    case KeyCodes.PLUS: {
                        event.stopPropagation();
                        this.skipSentence();
                        break;
                    }
                }
            }
        };

        this.$transcript = this.$('div.transcript');

        this.$instructionCorrect = this.$('p.correct');

        $(document).on('keypress.speaking', this.keyPressed);

        this.evaluationLastUpdated = moment();
    },

    remove: function () {
        $(document).off('keypress.speaking');
    },

    setProps: function (props) {
        this.props = props;
        this.render();
    },

    updateState: function (stateUpdate) {

        this.state = Object.assign({}, this.state, stateUpdate);
        const { status } = this.state;

        switch (status) {

            case 'initial': {
                this.$el.removeClass('display visible');
                break;
            }

            case 'ready': {
                this.render();
                this.$el.addClass('display');
                this.delay(25).then( () => {
                    this.$el.addClass('visible');
                });
                break;
            }

            case 'recording': {
                break;
            }

            // Update transcript only once per second
            case 'evaluation-updated': {
                if (moment().diff(this.evaluationLastUpdated) > 1000) {
                    this.evaluationUpdateTimestamp = moment();
                    this.render();
                }
                break;
            }

            case 'correct': {
                this.answerCorrectAudio.play();
                this.delay(2000).then( () => {
                    this.trigger('evaluated-correct', this.state.transcript);
                    this.updateState({ status: 'idle' });
                });
                break;
            }

            case 'try-again': {
                break;
            }

            case 'incorrect': {
                this.delay(2000).then( () => {
                    this.trigger('evaluated-incorrect', this.state.transcript);
                    this.updateState({ status: 'try-again' });
                });
                break;
            }

            case 'skip': {
                this.trigger('skipped');
                break;
            }
        }

        this.updateView();
    },

    render: function () {

        const renderData = {
            props: this.props,
            state: this.state
        };

        renderTemplate('challenges/recorder', renderData, this.$el).then(output => {
            this.$el.html(output);
            this.updateView();
        });
    },

    updateView: function () {
        this.$instructions = this.$('div.instruction');
        const $lineExpected = this.$('p.line.expected');
        const $lineEvaluated = this.$('p.line.evaluated-words');

        const $playButton = this.$('a.button.play');

        const $instructionIncorrect = this.$('p.incorrect');

        const $computerSpeakingMessage = this.$('p.computer-speaking');
        const $errorMessage = this.$('p.error');
        const $recordingMessage = this.$('p.recording');

        const { status } = this.state;

        this.$('div.instruction > p').removeClass('visible');

        switch (status) {

            case 'initial': {
                break;
            }

            case 'ready': {
                this.delay(25).then( () => {
                    $lineExpected.addClass('visible');
                    this.$('p.ready').addClass('visible');
                });
                break;
            }

            case 'idle': {
                $lineExpected.removeClass('visible');
                break;
            }

            case 'recording': {
                $playButton.addClass('disabled');
                $recordingMessage.addClass('visible');
                break;
            }

            case 'evaluation-updated': {
                $lineExpected.removeClass('visible');
                $lineEvaluated.addClass('visible');
                $recordingMessage.addClass('visible');
                break;
            }

            case 'correct': {
                $playButton.removeClass('disabled');
                $lineExpected.addClass('visible correct');
                $lineEvaluated.removeClass('visible');
                this.$('p.correct').addClass('visible');
                break;
            }

            case 'incorrect': {
                $playButton.removeClass('disabled');
                break;
            }

            case 'try-again': {
                $lineEvaluated.removeClass('visible');
                $lineExpected.addClass('visible');
                $instructionIncorrect.addClass('visible');
                break;
            }

            case 'computer-speaking': {
                $lineExpected.removeClass('visible');
                $computerSpeakingMessage.addClass('visible');
                break;
            }

            case 'skip': {
                $lineExpected.removeClass('visible');
                break;
            }

            case 'error': {
                $errorMessage.addClass('visible');
                $playButton.removeClass('disabled');
                break;
            }
        }
    },

    startRecording: function () {

        if (this.state.status === 'recording') {
            this.speechReco.stopRecognition();

            this.updateState({ status: 'ready' });

            UserManager.instance.getUser().getEventSender().sendNavigationEvent('conversation', 'stop', 'recording');
        } else {
            this.updateState({ status: 'recording' });

            this.beepAudio.play();
            this.speechReco.startRecognition();

            UserManager.instance.getUser().getEventSender().sendNavigationEvent('conversation', 'start', 'recording');
        }
    },

    startPlayback: function () {
        AudioPlayer.playConversationLine(this.props.lineIndex);
        UserManager.instance.getUser().getEventSender().sendNavigationEvent('conversation', 'start', 'playback');
    },

    skipSentence: function () {
        this.updateState({ status: 'skip' });
    },

    /**
        evaluateTranscript - compare the transcript coming from Speech Recognition API
        against array of parsed words.

        @param transcript - text generated by speech recognition api
    */

    evaluateTranscript (transcript, isFinal) {

        const { lineWords } = this.props;
        const transcriptWords = transcript.split(/[\s']/);

        let answerIsCorrect = true;

        const lineWordsEvaluated = lineWords.map( (lineWord, index) => {

            const transcriptWord = transcriptWords[index];
            const word = lineWord.word.replace('\'', '').toLowerCase();

            if (transcriptWord !== undefined) {
                if (word === transcriptWord.toLowerCase()) {
                    return Object.assign({}, lineWord, { isCorrect: true });
                } else {
                    answerIsCorrect = false;
                    return Object.assign({}, lineWord, { isInCorrect: true, incorrectWord: transcriptWord });
                }
            } else {
                answerIsCorrect = false;
                return lineWord;
            }
        });

        if (isFinal) {
            if (answerIsCorrect) {
                this.updateState({
                    status: 'correct',
                    transcript,
                    evaluatedWords: lineWordsEvaluated
                });
            } else {
                this.updateState({
                    status: 'incorrect',
                    transcript,
                    evaluatedWords: lineWordsEvaluated,
                });
            }
        } else {
            this.updateState({
                status: 'evaluation-updated',
                evaluatedWords: lineWordsEvaluated,
            });
        }
    },

    delay: function (duration) {
        return new Promise(resolve => setTimeout( () => { resolve(); }, duration));
    }
});

export default RecorderView;
