var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "intro" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "scroll-to",
            rawName: "v-scroll-to",
            value: "#illustrations",
            expression: "'#illustrations'",
          },
        ],
        staticClass: "anchor",
      },
      [
        _c("h2", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("intro_offer_see_more_content")),
          },
        }),
        _vm._v(" "),
        _c("arrow-icon", { staticClass: "arrow-down" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "illustrations with-gradient",
        attrs: { id: "illustrations" },
      },
      [
        _c("div", { staticClass: "items" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "illustration" },
                [_c("illustration-level")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_title_2")),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_text_2")),
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "illustration" },
                [_c("illustration-learn-faster")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_title_3")),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_text_3")),
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "illustration" },
                [_c("illustration-build-vocabulary")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_title_4")),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_text_4")),
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "illustration" },
                [_c("illustration-study-what")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_title_5")),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_text_5")),
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "illustration" },
                [_c("illustration-vocabulary4you")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_title_6")),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(this.$i18n("product_highlights_text_6")),
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "track-progress" }, [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "illustration" },
          [_c("illustration-progress")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("h3", {
            domProps: {
              innerHTML: _vm._s(this.$i18n("product_highlights_title_7")),
            },
          }),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(this.$i18n("product_highlights_text_7")),
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "illustrations" }, [
      _c("div", { staticClass: "items" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "item" }, [
            _c(
              "div",
              { staticClass: "illustration" },
              [_c("illustration-learn-everywhere")],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info" }, [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(this.$i18n("product_highlights_title_8")),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(this.$i18n("product_highlights_text_8")),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c(
              "div",
              { staticClass: "illustration" },
              [_c("illustration-science")],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info" }, [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(this.$i18n("product_highlights_title_9")),
                },
              }),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(this.$i18n("product_highlights_text_9")),
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }