import { EventBus } from './vue-event-bus.js';


export default {
    install(Vue, {i18nUtils}) {
        Vue.prototype.$i18n = function (id, { collection, message = null, args = {}, images = {}, link_templates = {}, addDefaultParams = false, removeExtraBreaks = false } = {}) {
            let output;

            if (message !== null) {
                output = i18nUtils.resolveMessage(message, args, images, link_templates, {addDefaultParams});
            } else {
                output = i18nUtils.prop(id, args, images, collection, link_templates, {addDefaultParams});
            }

            if (removeExtraBreaks) {
                output = i18nUtils.removeExtraBreaks(output);
            }

            return output;
        };

        Vue.prototype.$i18nLanguageChanged = 0;

        EventBus.$on('interfaceLanguageChanged', () => {
            Vue.prototype.$i18nLanguageChanged += 1;
        });
    }
};
