
import dust from "dustjs-linkedin";

import "template/content-dialog.js";
import "template/grammar-tips/list.js";
import "template/grammar-tips/content.js";

(function(){dust.register("grammar-tips/main",body_0);var blocks={"classes":body_1,"heading":body_2,"listContent":body_3,"content":body_4,"favorites":body_5};function body_0(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.partial("content-dialog",ctx,{});}function body_1(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.write("grammar-tips");}function body_2(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.helper("i18n",ctx,{},{"id":"grammar_tips_header"});}function body_3(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.partial("grammar-tips/list",ctx,{});}function body_4(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.partial("grammar-tips/content",ctx,{});}function body_5(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.write("<span class=\"favorite").helper("gt",ctx,{"block":body_6},{"key":ctx.get(["favorites"], false),"value":0}).write("\"><input type=\"checkbox\" id=\"show-favorites\" ").exists(ctx.get(["inFavorites"], false),ctx,{"block":body_7},{}).write(" /><label for=\"show-favorites\"></label></span><span class=\"number\">").helper("gt",ctx,{"block":body_8},{"key":ctx.get(["favorites"], false),"value":0}).write("</span>");}function body_6(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.write(" has-favorites");}function body_7(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.write("checked");}function body_8(chk,ctx){ctx=ctx.shiftBlocks(blocks);return chk.reference(ctx.get(["favorites"], false),ctx,"h");}return body_0;})();

export default 0;
