var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "180",
          height: "181",
          viewBox: "0 0 180 181",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M38.5 66l4-8.5H23l4 10-4 9 4 9-4 9 4 10-4 9 4 9-4 9h120l-5-9 1-1.5H38.5l4-9-4-8.5 4-9-4-10.5 4-9-4-9z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.5 47v-1.5a1.5 1.5 0 00-1.393 2.057L38.5 47zm120 0l1.342.67a1.502 1.502 0 00-1.342-2.17V47zm0 74v1.5a1.501 1.501 0 001.311-2.229L158.5 121zm-120 0l-1.37-.609a1.499 1.499 0 001.37 2.109V121zm27-75.5a1.5 1.5 0 000 3v-3zm-17 3a1.5 1.5 0 100-3v3zM38.5 84l-1.37-.61a1.5 1.5 0 00-.023 1.167L38.5 84zm0 19l-1.37-.609c-.173.388-.173.83 0 1.218L38.5 103zm0-37l-1.37-.61a1.5 1.5 0 000 1.22L38.5 66zm4 9l1.37.61a1.5 1.5 0 000-1.22L42.5 75zm0-18l1.37.61a1.5 1.5 0 00.023-1.167L42.5 57zm0 37l1.37.61a1.5 1.5 0 00.023-1.167L42.5 94zm0 18l1.37.609c.173-.388.173-.83 0-1.218L42.5 112zm116-27l1.311.728a1.5 1.5 0 00.031-1.399L158.5 85zm0-19l1.311.728a1.498 1.498 0 000-1.456L158.5 66zm0 37l1.311.728a1.499 1.499 0 000-1.457L158.5 103zm-5 9l-1.311-.729a1.499 1.499 0 000 1.457L153.5 112zm0-18l-1.311-.728a1.498 1.498 0 000 1.456L153.5 94zm0-19l-1.311-.728a1.5 1.5 0 00-.031 1.399L153.5 75zm0-18l-1.342-.67a1.5 1.5 0 00.031 1.398L153.5 57zm-88-8.5h93v-3h-93v3zm-27 0h10v-3h-10v3zm1.37 36.11l4-9-2.74-1.22-4 9 2.74 1.22zm4-10.22l-4-9-2.74 1.22 4 9 2.74-1.22zm-4-7.78l4-9-2.74-1.22-4 9 2.74 1.22zm4.023-10.167l-4-10-2.786 1.114 4 10 2.786-1.114zm-4.022 47.166l4-9-2.742-1.218-4 9 2.742 1.218zm4.022-10.166l-4-10-2.786 1.114 4 10 2.786-1.114zm-4.022 28.166l4-9-2.742-1.218-4 9 2.742 1.218zm4-10.218l-4-9-2.742 1.218 4 9 2.742-1.218zm113.318-9.12l-5 9 2.622 1.457 5-9-2.622-1.457zm-5 10.457l5 9 2.622-1.457-5-9-2.622 1.457zm5-28.456l-5 9 2.622 1.456 5-9-2.622-1.456zm-5 10.456l5 9 2.622-1.457-5-9-2.622 1.457zm5-29.456l-5 9 2.622 1.456 5-9-2.622-1.456zm-5.031 10.399l5 10 2.684-1.342-5-10-2.684 1.342zm5-29.342l-5 10 2.684 1.342 5-10-2.684-1.342zm-4.969 11.4l5 9 2.622-1.457-5-9-2.622 1.456zM158.5 119.5h-120v3h120v-3z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.5 47v74",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-dasharray": "4 8",
        },
      }),
      _c("circle", {
        attrs: { cx: "98.001", cy: "69.5", r: "9", fill: "#AC6ED1" },
      }),
      _c("circle", {
        attrs: { cx: "122", cy: "98.5", r: "9", fill: "#AC6ED1" },
      }),
      _c("circle", {
        attrs: { cx: "122", cy: "98.5", r: "4", fill: "#63E1E7" },
      }),
      _c("circle", {
        attrs: { cx: "98.001", cy: "69.5", r: "4", fill: "#63E1E7" },
      }),
      _c("path", {
        attrs: {
          d: "M92.5 107l35-46",
          stroke: "#2C3143",
          "stroke-width": "2.46",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }