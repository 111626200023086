/* global console, window */

class SpeechReco {

    constructor (targetLanguage = 'en') {

        this.startHandler = () => {};
        this.resultHandler = () => {};
        this.finalResultHandler = () => {};
        this.endHandler = () => {};
        this.errorHandler = () => {};

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;

        if (SpeechRecognition === undefined) {
            console.log('Speech Recognition not supported by browser');
            return;
        }

        this.grammar = `
            #JSGF V1.0;

            grammar com.lingvist.learn;

            public <sentence> = Yes, I will be at the office soon.;
        `;

        this.recognition = new SpeechRecognition();
        this.recognitionList = new SpeechGrammarList();

        this.recognitionList.addFromString(this.grammar, 1);

        this.recognition.grammars = this.recognitionList;
        this.recognition.lang = targetLanguage;
        this.recognition.interimResults = true;
        this.recognition.maxAlternatives = 10;

        // Fires when speech recognition engine starts analyzing audio
        this.recognition.onstart = () => {
            this.startHandler();
        };

        // Fires when a phrase is recognised
        this.recognition.onresult = event => {

            const isFinal = event.results[0].isFinal;
            const transcript = event.results[0][0].transcript;
            const confidence = Math.round(event.results[0][0].confidence * 100);

            if (isFinal) {
                this.finalResultHandler(transcript, confidence);
            } else {
                this.resultHandler(transcript, confidence);
            }
        };

        this.recognition.onaudiostart = () => {

        };

        this.recognition.onspeechend = () => {
            this.endHandler();
        };

        this.recognition.onerror = event => {
            this.errorHandler(event.error);
        };
    }

    handleEvent(event, handler) {

        if (event === 'start') {
            this.startHandler = handler;
        }

        if (event === 'end') {
            this.endHandler = handler;
        }

        if (event === 'result') {
            this.resultHandler = handler;
        }

        if (event === 'finalResult') {
            this.finalResultHandler = handler;
        }

        if (event === 'error') {
            this.errorHandler = handler;
        }
    }

    startRecognition () {
        this.recognition.start();
    }

    stopRecognition () {
        this.recognition.stop();
    }
}

export default SpeechReco;
