var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkout" }, [
    _c("h1", {
      domProps: { innerHTML: _vm._s(this.$i18n("pay_checkout_title")) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "columns" },
      [
        _c("transition", { attrs: { name: "slide-fade" } }, [
          _c(
            "div",
            { staticClass: "column braintree" },
            [
              _c("braintree", {
                attrs: {
                  token: _vm.token,
                  user: _vm.user,
                  enableThreeDSecure: _vm.enableThreeDSecure,
                  amount: _vm.amountToCheckout,
                },
                on: {
                  "set-braintree-instance": _vm.setBraintreeInstance,
                  "payment-option-selected": _vm.setPaymentOptionSelected,
                  "payment-method-requestable": _vm.setPaymentMethodRequestable,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "summary" }, [
            _c("h3", {
              domProps: {
                innerHTML: _vm._s(this.$i18n("pay_order_summary_title")),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "subtitle" },
              [
                _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
                _vm._v(" "),
                _c("v-button", {
                  attrs: {
                    i18n: { id: "payment_button_change" },
                    size: "small",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clearProduct.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "info",
                class: {
                  dots:
                    _vm.trialLengthInDays > 0 ||
                    (_vm.voucherIsForInitialPaymentOnly &&
                      _vm.fullPriceStartsFrom &&
                      !_vm.isSinglePurchase),
                },
              },
              [
                _vm.trialLengthInDays > 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "dot" }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "label",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("payment_trial_start_label")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "right",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("payment_trial_period_value", {
                              args: {
                                product_trial_days: _vm.trialLengthInDays,
                              },
                            })
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "row price",
                    class: { middle: _vm.voucherIsForInitialPaymentOnly },
                  },
                  [
                    _c("div", { staticClass: "dot" }),
                    _vm._v(" "),
                    _vm.trialEnds
                      ? _c("div", {
                          staticClass: "label",
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n("payment_plan_start_date_label", {
                                args: { next_payment_ts: _vm.trialEnds },
                              })
                            ),
                          },
                        })
                      : _c("div", {
                          staticClass: "label",
                          domProps: {
                            innerHTML: _vm._s(
                              this.$i18n("pay_order_summary_total")
                            ),
                          },
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "priceWithPeriod" }, [
                        _vm.product.voucherInfoFormatted &&
                        !_vm.product.voucherInfoFormatted.is_forced_free_trial
                          ? _c("span", { staticClass: "totalPrice" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.product.voucherInfoFormatted
                                    .totalWithCurrency
                                )
                              ),
                            ])
                          : _c("span", { staticClass: "totalPrice" }, [
                              _vm._v(_vm._s(_vm.product.textTotalPrice)),
                            ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "textPeriod" }, [
                          _vm._v("/ " + _vm._s(_vm.product.textPerPeriod)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.product.voucherInfoFormatted &&
                      !_vm.product.voucherInfoFormatted.is_forced_free_trial &&
                      _vm.product.voucherInfoFormatted.discount
                        ? _c("p", { staticClass: "crossed" }, [
                            _vm._v(_vm._s(_vm.product.textTotalPrice)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "vatText",
                        domProps: {
                          innerHTML: _vm._s(this.$i18n("payment_vat_text")),
                        },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.voucherIsForInitialPaymentOnly &&
                _vm.fullPriceStartsFrom &&
                !_vm.isSinglePurchase
                  ? _c("div", { staticClass: "row price secondary" }, [
                      _c("div", { staticClass: "dot" }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "label",
                        domProps: {
                          innerHTML: _vm._s(
                            this.$i18n("payment_plan_start_date_label", {
                              args: {
                                next_payment_ts: _vm.fullPriceStartsFrom,
                              },
                            })
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "priceWithPeriod" }, [
                          _c("span", { staticClass: "totalPrice" }, [
                            _vm._v(_vm._s(_vm.product.textTotalPrice)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "textPeriod" }, [
                            _vm._v("/ " + _vm._s(_vm.product.textPerPeriod)),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          this.product && this.product.voucherInfoFormatted
            ? _c(
                "div",
                { staticClass: "voucherInfo" },
                [
                  _c("voucher-icon-small"),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _c("span", {
                      staticClass: "prefix",
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("payment_order_voucher_title")
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("payment_order_voucher_discount_text", {
                            args: {
                              amount: _vm.product.voucherInfoFormatted.discount,
                            },
                          })
                        ),
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.footnoteTitle || _vm.footnoteText
            ? _c("div", { staticClass: "footnote" }, [
                _vm.footnoteTitle
                  ? _c("h5", [_vm._v(_vm._s(_vm.footnoteTitle))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.footnoteText
                  ? _c("p", [_vm._v(_vm._s(_vm.footnoteText))])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("div", { staticClass: "error" }, [
                _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("div", { staticClass: "tos" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tosChecked,
                      expression: "tosChecked",
                    },
                  ],
                  staticClass: "custom",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.tosChecked)
                      ? _vm._i(_vm.tosChecked, null) > -1
                      : _vm.tosChecked,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.tosChecked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.tosChecked = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.tosChecked = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.tosChecked = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  [
                    _c("terms", {
                      ref: "tos",
                      attrs: { message_key: "payment_terms_and_conditions" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-button", {
                attrs: {
                  text: _vm.checkoutButtonText,
                  filled: true,
                  color: "primary",
                  disabled: !_vm.isValidToCheckout,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.checkout.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }