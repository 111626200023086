var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wordlist-search" },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
          on: { click: _vm.focusInput },
        },
        [
          _c("path", {
            attrs: {
              d: "M17.5732 13C17.8496 12.2181 18 11.3766 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.3117 18 13.9734 17.3576 15.2697 16.2882L21 22",
              stroke: "#2C3143",
              "stroke-width": "2",
              "stroke-linecap": "round",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M7.5 13.5C7.5 13.5 6 12.587 6 10.5C6 8.41301 7.5 7.5 7.5 7.5",
              stroke: "#2C3143",
              "stroke-width": "2.29545",
              "stroke-linecap": "round",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchString,
            expression: "searchString",
          },
        ],
        ref: "searchInput",
        attrs: { type: "text", placeholder: _vm.searchPlaceholder },
        domProps: { value: _vm.searchString },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.searchString = $event.target.value
            },
            _vm.debouncedOnChange,
          ],
        },
      }),
      _vm._v(" "),
      _vm.searchString && _vm.searchString.length > 0
        ? _c("close-icon", {
            staticClass: "close",
            nativeOn: {
              click: function ($event) {
                return _vm.clearSearch.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }