
import $ from 'jquery';
import Backbone from 'backbone';

import renderTemplate from '../../util/template.renderer.js';

import UserManager from '../../modules/usermanager.js';
import googleAnalyticsCommand from '../../util/google-analytics.js';

const ArticleView = Backbone.View.extend({

    name: 'challenge.article',
    tagName: 'main',
    className: 'article-view',

    events: {
        'click a.close': 'closeButtonClicked',
        'click a.back': 'backButtonClicked',
        'click button.article-completed': 'articleCompleted',
        'click a.dialog-button': 'dialogButtonClicked',
        'click section.dialog-correct-container': 'dialogBackgroundClicked',
        'click a.notification-button': 'notificationButtonClicked'
    },

    initialize: function () {

        this.props = {};

        this.state = {
            step: 'initial',
        };

        this.intervalledTimeSpentEvent = setInterval( () => {
            this.trigger('time-spent');
        }, 59 * 1000);
    },

    setProps: function (props) {
        this.props = props;

        const { type, uuid } = this.props;

        UserManager.instance.getUser().getEventSender().sendNavigationEvent(type, uuid, 'open');
    },

    remove: function () {
        clearInterval(this.intervalledTimeSpentEvent);
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {

        const renderData = {
            props: this.props,
            state: this.state
        };

        renderTemplate('challenges/article', renderData, this.$el).then( () => {
            this._postRender();
        });

        const screen = 'Challenges/Article/Exercise';

        googleAnalyticsCommand('send', 'pageview', screen);
        googleAnalyticsCommand('send', 'screenview', { 'screenName': screen });

        return this;
    },

    articleCompleted: function () {
        this.state.step = 'ended';
        this.$actionButtonContainer.removeClass('display');
        this.$correctDialog.addClass('display');

        this.trigger('reading-complete');
        clearInterval(this.intervalledTimeSpentEvent);

        this.delay(25).then( () => {
            this.$correctDialog.addClass('active');
        });
    },

    dialogButtonClicked: function (event) {

        const target = $(event.currentTarget).data('target');

        if (target === 'challenges') {
            this.trigger('exercise-ended');
            Backbone.history.navigate('challenges', { trigger: true });
        } else if (target === 'content-partner') {
            this.trigger('visit-link');
            window.open(this.props.link, '_blank');
        }
    },

    dialogBackgroundClicked: function (event) {

        const $element = $(event.target);

        if ($element.is('section.dialog-correct-container')) {

            this.trigger('exercise-ended');
            this.$correctDialog.removeClass('active');
            this.delay(25).then( () => {
                this.$correctDialog.removeClass('display');
            });
        }
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
        this.$correctDialog = this.$('section.dialog-correct-container');
        this.$actionButtonContainer = this.$('div.action');
    },

    backButtonClicked: function () {
        Backbone.history.navigate('challenges', { trigger: true });
    },

    delay: function (duration) {
        return new Promise(resolve => setTimeout( () => { resolve(); }, duration));
    },
});

export default ArticleView;
