var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M7 24h2V11a2 2 0 012-2h21V6a2 2 0 00-2-2H3a2 2 0 00-2 2v23.959L7 24z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21 29h14a2 2 0 002-2V11a2 2 0 00-2-2H11a2 2 0 00-2 2v24l6-6M17 15l12 9M17 24l12-9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }