var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M12 1l10 9h-4.5a1.5 1.5 0 00-1.5 1.5V13H8v-1.5A1.5 1.5 0 006.5 10H2l10-9z",
          fill: "#037889",
        },
      }),
      _c("path", {
        attrs: { opacity: ".6", d: "M8 15h8v3H8v-3z", fill: "#037889" },
      }),
      _c("path", {
        attrs: {
          opacity: ".3",
          d: "M8 20h8v.5a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 20.5V20z",
          fill: "#037889",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }