var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M10 6.5l1.796 5.528h5.813l-4.703 3.416 1.796 5.528L10 17.556l-4.702 3.416 1.796-5.528-4.702-3.416h5.812L10 6.5zM14 4V3",
          stroke: "#fff",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20 9a1 1 0 100 2V9zm1 2a1 1 0 100-2v2zm-1 0h1V9h-1v2z",
          fill: "#fff",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19 5l1-1",
          stroke: "#fff",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }