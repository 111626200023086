<template>
    <section class="credentials-email">
        <h1 v-html="this.$i18n('register_credentials_selected_course_heading')"></h1>

        <course :course="selected_course"/>

        <h2 v-html="this.$i18n('register_email_header')"></h2>
        <p v-if="this.oauth_input" class="social" v-html="this.$i18n('signin_social_profile_label', { args: { provider: this.oauth_input.provider }})" />
        <p v-html="this.$i18n('signin_social_profile_email_requirement_text')"></p>

        <form class="register" v-on:keypress.enter="submit_credentials" v-on:submit.prevent>
            <div class="input-container">
                <account-email-icon />
                <input
                    class="email"
                    ref="email_input"
                    :class="{'error': email_validation_error, 'suggestion': email_suggestion}"
                    type="email"
                    v-model="email_"
                    @input="check_email"
                    @blur="check_email"
                    :placeholder="this.$i18n('register_email_email_placeholder')"
                    autocomplete="email" />
                <p
                    v-if="email_suggestion"
                    class="email-suggestion"
                    @click="use_email_suggestion"
                    v-html="this.$i18n('register_email_suggestion', {args: {email: `<a>${decodeURIComponent(email_suggestion.full)}</a>`}})"
                ></p>
            </div>
            <div class="email-in-use" v-if="email_in_use">
                <p class="error" v-html="this.$i18n('settings_error_email_in_use')" />
                <p class="error" v-html="this.$i18n('register_error_duplicate_email_account_ask')" />
                <a @click="navigate_to_signin" v-html="this.$i18n('register_error_log_in_link')" class="login-link"/>
            </div>
            <template v-else>
                <p class="error" v-if="email_validation_error === 'error-empty-email'"
                   v-html="this.$i18n('register_error_empty_email')"></p>
                <p class="error" v-else-if="email_validation_error === 'error-invalid-email'"
                   v-html="this.$i18n('register_error_invalid_email')"></p>
            </template>
        </form>

        <footer>
            <button @click="submit_credentials" :disabled="!submit_enabled"
                    v-html="this.$i18n('register_submit')"></button>
            <terms />
        </footer>
    </section>
</template>

<script>

import EvaluationUtils from '../../../util/evaluation.js';

import Course from './course.vue';
import Terms from '../../component/terms.vue';

import AccountEmailIcon from '../../../../img/account/email.svg';
import EmailVerifiedIcon from '../../../../img/email-verified.svg';
import Backbone from "backbone";

export default {
    name: 'register.oauth-email',
    props: {
        email: {
            type: String,
            required: false
        },
        selected_course: {
            type: Object,
            required: true,
        },
        single_available_target_language_course: {
            type: Boolean,
            required: true
        },
        email_in_use: {
            type: Boolean,
            default: false
        },
        oauth_input: {
            type: Object
        },
    },
    components: {
        Course,
        Terms,
        AccountEmailIcon,
        EmailVerifiedIcon,
    },
    data () {
        return {
            email_: this.email || '',
            email_validation_error: null,
            email_suggestion: null,
        };
    },
    mounted () {
        this.$refs.email_input.focus();
    },
    computed: {
        submit_enabled () {
            return this.email_.length > 0 && !this.email_validation_error;
        }
    },
    methods: {
        async check_email () {
            this.email_suggestion = null;
            this.email_validation_error = null;

            try {
                this.email_suggestion = await EvaluationUtils.validateAndSuggestEmail(this.email_);
                this.$emit('email_updated', this.email_);
            } catch (validation_error) {
                this.email_validation_error = validation_error;
            }

            if (this.email_.length === 0) { // clear the email in the parent component as well
                this.$emit('email_updated', this.email_);
            }
        },
        use_email_suggestion (event) {
            if (event.target.nodeName === 'A') {
                this.email_ = event.target.innerText;
                this.email_suggestion = null;
                this.email_validation_error = null;
            }
        },
        submit_credentials () {
            if (this.submit_enabled) {
                this.$emit('verified_submit', this.email_)
            }
        },
        navigate_to_signin() {
            Backbone.history.navigate('signin', {trigger: true});
        }
    }
};
</script>

<style scoped lang="scss">
@import "~Styles/colors";
@import "~Styles/font-weights.scss";
@import "~Styles/email-suggestion.scss";

section.credentials-email {
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
        font-weight: $font-bold;
        font-size: 1.625rem;
        margin-bottom: 2rem;
    }

    > h2 {
        font-weight: $font-bold;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    > p {
        align-self: start;
        &.social {
            color: $colorSourceSecondary;
            margin-bottom: .5rem;
        }
    }

    > form {
        width: 100%;

        > div.input-container {
            margin: 1rem 0;

            > svg {
                position: absolute;
                left: 0.125rem;
                top: calc(50% - 0.75rem);
                width: 1.5rem;
                body[data-interface-language="ar"] & {
                    left: unset;
                    right: 0.125rem;
                }
            }

            > input {
                width: 100%;
                font-size: 1rem;
                font-weight: $font-normal;
                height: 3rem;
                padding-left: 2rem;
                color: $colorSourcePrimary;
                body[data-interface-language="ar"] & {
                    padding-left: unset;
                    padding-right: 2rem;
                }

                border-bottom: 2px solid $colorSourceTertiary;

                &:focus {
                    border-bottom: 2px solid $colorSourcePrimary;
                }

                &.error {
                    border-bottom: 2px solid $colorIncorrect;
                }

                &.suggestion {
                    border-bottom: 2px solid $colorAttention;
                }

                &::placeholder {
                    font-size: 1rem;
                }
            }

            &::v-deep > p.email-suggestion {
                @include email-suggestion;
            }
        }

        > div.email-in-use {
            a.login-link {
                display: inline-block;
                margin-top: .25rem;
                cursor: pointer;
                text-decoration: underline;
                &:hover {
                    opacity: .8;
                }
            }
        }

        p.error {
            font-size: 0.875rem;
            color: $colorIncorrect;
            text-align: left;
        }
    }

    > footer {
        display: flex;
        flex-direction: column;

        flex: 1 1 10rem;
        justify-content: flex-end;

        text-align: center;
    }
}

</style>
