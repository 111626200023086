<template>
    <div class="radio-button" @click="toggle">
        <!-- Checked -->
        <svg v-if="isSelected" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="9" stroke="#B2B8CB" stroke-width="2"/>
            <circle cx="12" cy="12" r="5" fill="#2C3143"/>
        </svg>

        <!-- Unchecked -->
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="9" stroke="#B2B8CB" stroke-width="2"/>
        </svg>

        <p class="label" v-if="label !== null">{{ label }}</p>
    </div>
</template>

<script>
    export default {
        name: 'radio-button',
        props: {
            report_value: {
                type: String,
                required: true,
            },
            selected_report_value: {
                type: String
            },
            label: {
                type: String,
                default: null,
            },
        },
        computed: {
            isSelected() {
                return this.report_value === this.selected_report_value;
            }
        },
        data () {
            return {
                value_: this.value,
            };
        },
        methods: {
            toggle () {
                this.$emit('change', this.report_value);
            },
        },
    };
</script>

<style lang="scss">
    @import "~Styles/mixins";

    div.radio-button {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;
        user-select: none;

        > p.label {
            margin-left: 0.75rem;
        }
    }
</style>
