var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M14 19H6a3 3 0 00-3 3v13l5.87-3H15a3 3 0 003-3v-5",
          stroke: "#E2B4FF",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22 17.818l2.522-1.528A2 2 0 0125.56 16h.882a2 2 0 011.037.29L30 17.818V20h-8v-2.182zM28 11a2 2 0 11-4 0 2 2 0 014 0z",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15 15v6a3 3 0 003 3h10l9 5V8a3 3 0 00-3-3H18a3 3 0 00-3 3v2M7 24h4M7 28h7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }