var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dynamic-goal-modal" }, [
    _c(
      "div",
      { staticClass: "dialog-window" },
      [
        _c("h3", {
          domProps: {
            innerHTML: _vm._s(this.$i18n("dynamic_goals_change_goal_title")),
          },
        }),
        _vm._v(" "),
        _c("close-button", { staticClass: "close", on: { click: _vm.close } }),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide-fade" } }, [
          _vm.lockedMessageVisible
            ? _c(
                "div",
                { staticClass: "locked-message-container" },
                [_c("locked-message", { attrs: { closable: false } })],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cardAmount" }, [
          _c("div", {
            staticClass: "amount",
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("dynamic_goals_change_goal_cards_count", {
                  args: { daily_cards_goal: _vm.goal_size },
                })
              ),
            },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "footnote",
            style: {
              visibility: _vm.goal_size_is_recommended ? "visible" : "hidden",
            },
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("dynamic_goals_change_goal_count_recommended_label")
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c("slider", {
          attrs: {
            max_amount: _vm.recommended_max_size,
            position: _vm.sliderPosition,
          },
          on: {
            "update-position": _vm.sliderUpdate,
            "start-tracking": _vm.sliderStartTracking,
            "end-tracking": _vm.sliderEndTracking,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("dynamic_goals_change_goal_reminder_text_1")
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c("v-button", {
              attrs: {
                i18n: { id: "dialog_button_cancel" },
                size: "medium",
                filled: false,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("v-button", {
              attrs: {
                i18n: { id: "dynamic_goals_change_goal_button_set" },
                size: "medium",
                filled: true,
                color: "primary",
                disabled: !_vm.setGoalEnabled,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setGoal.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }