<template>
        <div class="cookie-consent-container">
            <transition name="slide-in-fade">
                <div class="cookie-consent" v-if="this.isVisible">
                    <svg class="cookie-icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle class="circle-stroked" cx="9" cy="18" r="2" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <circle class="circle-filled" cx="15.5" cy="15.5" r="1.5" fill="#ffffff"/>
                        <circle class="circle-filled" cx="24.5" cy="20.5" r="1.5" fill="#ffffff"/>
                        <path d="M7.69213 26C4.82516 23.6159 3 20.0223 3 16.0026C3 9.38469 7.94695 3.92202 14.3453 3.10938C14.2139 3.51518 14.1429 3.94818 14.1429 4.39776C14.1429 6.32447 15.4474 7.94655 17.2215 8.42927C17.0325 8.90572 16.9286 9.4252 16.9286 9.96894C16.9286 12.2763 18.7994 14.1467 21.1071 14.1467C21.7698 14.1467 22.3964 13.9925 22.9531 13.718C23.6438 15.0734 25.0527 16.0018 26.6786 16.0018C27.5298 16.0018 28.3215 15.7473 28.9819 15.3104C28.9939 15.5396 29 15.7703 29 16.0026C29 23.1809 23.1797 29 16 29C14.9675 29 13.963 28.8797 13 28.6522" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.3284 23.6926C12.4167 24.3535 13.6329 24.776 14.8966 24.9321" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.7435 8.69458C9.82506 9.35543 9.03948 10.1835 8.42791 11.1355" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20.2614 23.9272C20.6069 23.7415 20.9398 23.5334 21.2581 23.3043" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <div class="text">
                        <p>
                            <span v-html="this.$i18n('cookie_acceptance_information')" />
                            <a @click="moreInfo" class="find-out-more" v-html="this.$i18n('cookie_acceptance_findoutmore_button')" />
                        </p>
                    </div>

                    <div class="action">
                        <v-button @click.native="accept" :i18n="{ id: 'cookie_acceptance_accept_button' }" :filled="true" color="white" />
                    </div>
                </div>
            </transition>
            <modal-popup
                v-if="document_open && document_open.hasOwnProperty('content')"
                :title="document_open.title"
                base_width="50rem"
                @close="document_open = null">
                <main class="document" v-html="document_open.content"></main>
            </modal-popup>
        </div>
</template>

<script>
import VButton from "ViewComponent/v-button.vue";
import Cookie from "Util/cookie.js";
import moment from "moment";
import { EventBus } from "Util/vue-event-bus.js";
import Terms from "ViewComponent/terms.vue"
import ModalPopup from "ViewComponent/modal-popup.vue";
import Backbone from "backbone";
import i18nUtils from "Util/i18n.js";
import getConfigValue from "Util/configuration.js";
import UserModel from "Model/user.model.js";

const consentCookieName = 'cookies-accepted';
export default {
    name: 'cookie-consent',
    components: { ModalPopup, Terms, VButton },
    data() {
        return {
            isVisible: false,
            userIsSignedIn: false,
            document_open: null
        }
    },
    methods: {
        showConsent() {
            this.isVisible = true;
        },
        showConsentWithDelay() {
            setTimeout(() => {
                this.showConsent();
            }, 1000)
        },
        hideConsent() {
            this.isVisible = false;
        },
        isConsentCookieSet () {
            return Cookie.getCookie(consentCookieName) === 'true';
        },
        moreInfo() {
            this.show_document();
        },
        onUserLogin() {
            this.userIsSignedIn = true;
            if (!this.isConsentCookieSet()) {
                this.showConsentWithDelay();
            }
        },
        accept() {
            const domainMatch = window.location.host.match(/.*\.(.*\..*)/);
            const parentDomain = domainMatch && domainMatch[1] || window.location.hostname;

            Cookie.setCookie(consentCookieName, 'true', {
                expires: moment.utc().add(1, 'year'),
                domain: parentDomain
            });

            this.hideConsent();
        },
        async show_document () {
            try {
                Backbone.trigger('showLoader');

                let document = 'tos'
                let url;
                let title_key;
                let language = i18nUtils.currentInterfaceLanguage;

                if (document === 'tos') {
                    let tosPaths = getConfigValue('tos-paths');
                    url = `${getConfigValue('assets-base-url')}/${tosPaths[language] || tosPaths.default}`;
                    title_key = 'register_modal_terms_of_service';
                }

                const response = await fetch(url);
                const document_content = await response.text();

                this.document_open = {
                    type: document,
                    title: this.$i18n(title_key),
                    content: document_content,
                };
            } catch (error) {
                console.error('Failed showing document with error:', error)
            } finally {
                Backbone.trigger('hideLoader')
            }
        },
        listenEventBus() {
            EventBus.$on('user:signed-in', this.onUserLogin);
            EventBus.$on('cookie:show-consent', this.showConsent);
        },
        unListenEventBus() {
            EventBus.$off('user:signed-in', this.onUserLogin);
            EventBus.$off('cookie:show-consent', this.showConsent);
        }
    },
    mounted() {
        this.userIsSignedIn = UserModel.isSignedIn();
        if (!this.isConsentCookieSet() && this.userIsSignedIn) {
            this.showConsentWithDelay();
        }
    },
    created() {
        this.listenEventBus();
    },
    beforeDestroy() {
        this.unListenEventBus();
    }
}
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.cookie-consent-container {
        > div.cookie-consent {
            position: relative;
            align-items: center;
            background-color: rgba(44, 49, 67, 0.95);
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 2.5rem;
            width: pxToRem(400);
            margin: 1rem;
            border-radius: 1rem;
            @include elevated;

            @include respond-to('small') {
                margin: 0;
                border-radius: unset;
                box-shadow: none;
                width: 100%;
            }

            > div.text {
                flex: 1;
                color: #fff;
                > p {
                    > a.find-out-more {
                        text-decoration: underline;
                        cursor: pointer;
                        color: #fff;
                    }
                }
            }

            > div.action {
                width: 100%;
                > button {
                    width: 100%;
                }
            }
        }
        .slide-in-fade-enter-active,
        .slide-in-fade-leave-active {
            transition: all 350ms ease;
        }
        .slide-in-fade-enter,
        .slide-in-fade-leave-to {
            transform: translateY(100px);
            opacity: 0;
        }
    }

</style>
