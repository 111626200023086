
import { BaseExercise } from './base.exercise.js';
import { MultipleChoiceExerciseAnswer } from './answer/multiple.choice.js';

export class MultipleChoiceExercise extends BaseExercise {

    constructor (...args) {
        super(...args);
        this._answer_order = null;
    }

    update (exercise_data) {
        super.update(exercise_data);
        this._answer_order = exercise_data[this.type].answer_order;
    }

    getInfo () {
        let info = super.getInfo();

        info.answer_order = this._answer_order.slice(0);

        return info;
    }

    getData () {
        return Promise.resolve()
            .then(() => super.getData())
            .then(data => {
                data.title = this._data.content.title[this._display_info.title];
                data.instructions = this._data.content.instructions[this._display_info.instructions];
                data.script = this._data.content.script;
                data.summary = this._data.content.summary[this._display_info.summary];
                data.prompt = this._data.content.prompt[this._display_info.prompt];
                data.orderedAnswers = data.answer_order.map(answerId => {
                    const orderedAnswer =  this._data.content.answers.find(answer => answer.id === answerId);
                    return {
                        id: orderedAnswer.id,
                        text: orderedAnswer[this._display_info.answers],
                        correct: orderedAnswer.correct
                    };
                });
                return Promise.resolve(data);
            });
    }

    getExerciseAnswer () {
        return new MultipleChoiceExerciseAnswer(
            this._course.UUID,
            this.getInfo(),
            this._display_info,
            this._getAssetURL(),
            this._answer_order
        );
    }
}
