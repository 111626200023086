var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9 13a1 1 0 011-1h4a1 1 0 011 1v8H9v-8z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5 4v4.727L3.75 9.75l-1.666 1.363a.5.5 0 00.317.887H5v9h14v-9h2.6a.5.5 0 00.316-.887l-9.6-7.854a.5.5 0 00-.633 0L9.25 5.25 9 5.455V4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }