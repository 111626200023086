var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M19.723 12.037c0-1 .412-1.977 1.14-2.656l.637-.58-2.032-3.564-.922.32c-.922.291-1.869.161-2.694-.32 0 0-.03 0-.03-.031-.825-.488-1.426-1.26-1.62-2.236L14.014 2H9.986l-.188.97c-.188.939-.795 1.748-1.62 2.236 0 0-.03 0-.03.03-.825.489-1.808.65-2.694.322l-.922-.321L2.5 8.794l.637.58A3.57 3.57 0 014.277 12c0 1.007-.412 1.976-1.14 2.625l-.637.58 2.032 3.558.922-.32c.922-.291 1.869-.161 2.694.32 0 0 .03 0 .03.031.825.488 1.426 1.26 1.62 2.236l.188.97h4.028l.188-.97c.188-.939.795-1.748 1.62-2.236 0 0 .03 0 .03-.03.825-.488 1.808-.65 2.694-.322l.922.321 2.032-3.557-.637-.58c-.698-.612-1.14-1.588-1.14-2.589zM15 12a3 3 0 11-3-3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }