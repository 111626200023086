var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "file-image-32_svg__ic-file-image-paper-32",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c(
            "g",
            {
              staticClass:
                "file-image-32_svg__ic-file-image-paper-32__file-icon",
            },
            [
              _c("path", {
                staticClass:
                  "file-image-32_svg__ic-file-image-paper-32__file-icon__shadow",
                attrs: {
                  fill: "#2C3143",
                  "fill-opacity": ".1",
                  d: "M9.6 31h16.8a1.6 1.6 0 001.6-1.6V10l-6-6H9.6A1.6 1.6 0 008 5.6v23.8A1.6 1.6 0 009.6 31z",
                },
              }),
              _c("path", {
                staticClass:
                  "file-image-32_svg__ic-file-image-paper-32__file-icon__rectangle",
                attrs: {
                  fill: "#FFF",
                  stroke: "#2C3143",
                  "stroke-linejoin": "round",
                  d: "M7.1 28.5h16.8a1.6 1.6 0 001.6-1.6V7.5h0l-6-6H7.1a1.6 1.6 0 00-1.6 1.6v23.8a1.6 1.6 0 001.6 1.6z",
                },
              }),
              _c("path", {
                staticClass:
                  "file-image-32_svg__ic-file-image-paper-32__file-icon__rectangle",
                attrs: {
                  fill: "#E4E9F2",
                  stroke: "#2C3143",
                  "stroke-linejoin": "round",
                  d: "M19.5 7.5h6l-6-6z",
                },
              }),
            ]
          ),
          _c("path", {
            staticClass: "file-image-32_svg__ic-file-image-paper-32__stroke-5",
            attrs: {
              fill: "#FFF",
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M19.5 25.5H8.618a1 1 0 01-.894-1.447L13.5 12.5h0l6 13z",
            },
          }),
          _c("path", {
            staticClass: "file-image-32_svg__ic-file-image-paper-32__stroke-5",
            attrs: {
              fill: "#FFF",
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M14.5 19l-1-1.5-2.466-.067L13.5 12.5l2.122 4.597z",
            },
          }),
          _c("path", {
            staticClass: "file-image-32_svg__ic-file-image-paper-32__stroke-9",
            attrs: {
              fill: "#FFF",
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M19.5 16.5l3.776 7.503a1 1 0 01-.887 1.45L15 25.5h0l4.5-9z",
            },
          }),
          _c("path", {
            staticClass: "file-image-32_svg__ic-file-image-paper-32__stroke-9",
            attrs: {
              fill: "#FFF",
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M19.5 16.5l2.026 4.025-1.026.975-2 1-1.328-1.344z",
            },
          }),
          _c("path", {
            staticClass: "file-image-32_svg__ic-file-image-paper-32__stroke-3",
            attrs: {
              stroke: "#2C3143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M18 12a2 2 0 104 0 2 2 0 00-4 0z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }