var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M18.757 27.917l-4.781 4.776L3.008 21.725A6.866 6.866 0 011 16.866a6.866 6.866 0 0111.649-4.925c-.03.024-.054.048-.078.072a6.848 6.848 0 00-2.014 4.853c0 1.757.675 3.515 2.014 4.86l1.25 1.254 4.936 4.937z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27.31 29.449l7.706-7.78c2.645-2.671 2.645-6.995 0-9.666a6.72 6.72 0 00-9.573 0l-1.65 1.665-1.72-1.665a6.715 6.715 0 00-4.557-2 6.715 6.715 0 00-5.016 2c-2.645 2.67-2.645 6.995 0 9.666L23.694 33M18 14c-3-.5-4.5 3-2.5 5",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }