var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M2 24c1.803 0 1.736-2 3.54-2 1.803 0 1.803 2 3.606 2s1.803-2 3.607-2c1.803 0 1.803 2 3.606 2s1.803-2 3.606-2 1.803 2 3.607 2c1.803 0 1.803-2 3.606-2s1.803 2 3.607 2c1.804 0 1.804-2 3.608-2s1.804 2 3.607 2",
          stroke: "#8EA3EE",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21 30V5M21 13h15c-2.537-4.121-8.297-7-15-7C14.296 6 8.537 8.88 6 13h8M21 6c-1.126 0-3.5 2-5 4M21 6c1.402 0 7 3.5 7 7",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M29 36H12L5 23l6-6 7 13h17l-6 6z",
          fill: "#8EA3EE",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 33h9M17 27h9",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }