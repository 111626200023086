var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 296 180",
          width: "296",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M181.189 108.05h18.29c1.85 0 3.02 2.01 2.07 3.64l-9.12 15.66a2.414 2.414 0 01-4.19 0l-9.12-15.66c-.95-1.63.22-3.64 2.07-3.64z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M210.178 47.68h-39.7c-5.98 0-10.82 4.85-10.82 10.82v41.4c0 5.98 4.85 10.82 10.82 10.82h39.7c5.98 0 10.82-4.85 10.82-10.82V58.5c.01-5.97-4.84-10.82-10.82-10.82z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M174.808 83.18a3.97 3.97 0 100-7.94 3.97 3.97 0 000 7.94zM190.327 83.18a3.97 3.97 0 100-7.94 3.97 3.97 0 000 7.94zM205.847 83.18a3.97 3.97 0 100-7.94 3.97 3.97 0 000 7.94z",
          fill: "#9DF4F4",
        },
      }),
      _c("path", {
        attrs: { d: "M191 36a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M190.328 31.69h-18.9c-23.75 0-43 19.25-43 43v4.18l-11.91 14.02c-3.31 3.89-.54 9.88 4.57 9.88h5.55v14.26c0 6.97 5.65 12.62 12.62 12.62h5.9l-.6 21.76h20.7M105.327 107.94a8.106 8.106 0 000 11.47M95.797 98.41c-8.43 8.43-8.43 22.1 0 30.53M86.267 88.88c-13.69 13.69-13.69 35.9 0 49.59",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }