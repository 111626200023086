
import _ from 'lodash';
import moment from 'moment';

import AsyncDestroyable from '../async.destroyable.js';

import getConfigValue from '../../util/configuration.js';

import { NotImplementedException } from '../../util/modules.js';


export class BaseExercise extends AsyncDestroyable {
    constructor (course, assetLoader) {
        super(['getData']);
        this._data_loaded = false;

        this._course = course;
        this._assetLoader = assetLoader;

        this.uuid = null;
        this.type = null;
        this._paths = null;
        this._info = null;
        this._data = null;
        this._display_info = null;

        this._entries = [];
    }

    /**
     *
     * @param {ExerciseData} exercise_data
     */
    update (exercise_data) {
        this.uuid = exercise_data.uuid;
        this.type = exercise_data.type;
        this._paths = exercise_data.paths;
        this._info = _.clone(exercise_data);
        this._info.completed = this._info.completed === null ? null : moment(this._info.completed);
        this._info.available = this._info.available === null ? null : moment(this._info.available);
        this._display_info = exercise_data[this.type].display;
    }

    setCompleted () {
        this._info.completed = moment();
    }

    serialize () {
        return _.clone(this._info);
    }

    /**
     * @typedef {object} ExerciseInfo
     * @property {string} uuid
     * @property {string} type
     * @property {string} title
     * @property {string} description
     * @property {int} level
     * @property {int} order
     * @property {moment|null} completed
     * @property {moment|null} available
     * @property {array<string>} answer_order
     */

    /**
     * Get basic information about the exercise
     * @return {ExerciseInfo}
     */
    getInfo () {
        let title, description;

        if (this._data !== null) {
            title = this._data.content.title[this._display_info.title];
            description = this._data.content.description[this._display_info.description];
        } else {
            title = this._info.title[this._display_info.title];
            description = this._info.description[this._display_info.description];
        }

        return {
            uuid: this.uuid,

            type: this.type,
            category: this._info.category,
            title: title,
            description: description,
            level: this._info.level,
            order: this._info.order,

            completed: this._info.completed,
            available: this._info.available
        };
    }

    _getAssetURL () {
        return `${getConfigValue('assets-base-url')}/${this._paths.v1}`;
    }

    getData () {
        let promise = Promise.resolve();
        let assetUrl = this._getAssetURL();

        if (!this._data_loaded || this._data_loaded !== assetUrl) {
            promise = promise
                .then(() => this._assetLoader.loadAsset(assetUrl, 0))
                .then(data => {
                    this._data = JSON.parse(data).exercise;
                    this._data_loaded = assetUrl;
                });
        }

        promise = promise.then(() => {
            return Promise.resolve(this.getInfo());
        });

        return promise;
    }

    destroy () {
        this._assetLoader.removeAssetFromLoading(this._getAssetURL());
        delete this._assetLoader;
        delete this._course;

        return Promise.resolve();
    }

    getExerciseAnswer () {
        throw new NotImplementedException(this, 'getExerciseAnswer');
    }
}
