var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.goalsData && this.goalsData.length > 0
    ? _c("div", { staticClass: "week-goals" }, [
        _c(
          "div",
          { staticClass: "week" },
          [
            _vm._l(_vm.goalsData, function (day) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      goal: true,
                      today: day.isToday,
                      missing: day.missing,
                    },
                    attrs: { title: day.dayWord },
                    on: {
                      click: function ($event) {
                        return _vm.dayClicked(day)
                      },
                    },
                  },
                  [
                    _c("goal-status-icon", {
                      attrs: { size: 24, status: day.goalStatus },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "day" }, [
                      _c("span", [_vm._v(_vm._s(day.dayShort))]),
                    ]),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }