
import $ from 'jquery';
import Backbone from 'backbone';

import EventUtils from '../util/event.js';

const OnboardingOverlayView =  Backbone.View.extend({

    events: {},

    _initElement: function () {
        console.log('Initialize onboarding overlay element');
        let $element = $('body > div.onboarding-overlay');
        if ($element.length === 0) {
            $element = $('<div class="onboarding-overlay hidden"></div>');
            $('body').append($element);
        }
        this.setElement($element);
    },

    initialize: function (contentView) {
        this._content = contentView;
    },

    show: function () {
        // Put the view into dom at the latest possible moment
        this._initElement();
        this._content.setElement(this.$el);

        return Promise.resolve()
            .then(() => this._content.render())
            .then(() => new Promise((resolve, reject) => {
                    this._showTimeout = setTimeout(() => {
                        $('main#app-root').addClass('onboarding-overlay-open');
                        this.$el.removeClass('hidden');
                        resolve();
                    }, 16);
                })
            )
            .then(() => this._content.start())
            .then(() => new Promise(resolve => {
                // Only resolve the show when the transition period in overlay.scss is actually complete
                setTimeout(() => resolve(), 500);
            }));
    },

    remove: function () {
        console.log('Remove onboarding overlay');
        clearTimeout(this._showTimeout);

        return Promise.resolve()
            .then(() => new Promise((resolve, reject) => {
                $('main#app-root').removeClass('onboarding-overlay-open');
                this.$el.addClass('fade-out');
                EventUtils.oneTransitionEnd(this.$el, resolve);
            }))
            .then(() => this._content.remove())
            .then(() => {
                this.unbind();
                Backbone.View.prototype.remove.call(this);
            });
    }
});

export default OnboardingOverlayView;
