var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 180 180",
          width: "180",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M46.4 140.81H21.69M46.4 133.13v-20.29a4.59 4.59 0 014.59-4.59h5.68M76.67 128.93v7.29a4.59 4.59 0 01-4.59 4.59H46.4M76.67 89.25V45.64c0-5.32 4.32-9.64 9.64-9.64h70.7v62.61c0 5.32-4.32 9.64-9.64 9.64H96.44",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M88.429 108.24c0 6.24-5.19 11.44-11.43 11.44-.69 0-1.36-.06-2.02-.18-5.42-.95-9.43-5.7-9.43-11.26 0-6.41 5.21-11.43 11.44-11.43 3.2 0 6.06 1.3 8.11 3.38.08.07.15.15.23.24a11.33 11.33 0 013.1 7.81z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M140.949 72.11c0 13.15-10.95 24.13-24.1 24.13-1.45 0-2.88-.13-4.25-.38-11.43-2-19.88-12.03-19.88-23.75 0-13.53 10.98-24.1 24.13-24.1 6.75 0 12.78 2.75 17.1 7.13.18.15.33.33.48.5a23.98 23.98 0 016.52 16.47z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M110.9 63.29h14.77v14.76M108 80.96l17.67-17.67",
          stroke: "#9DF4F4",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M21 145a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }