var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "#2C3143",
          d: "M6 11c0-.6-.4-1-1-1s-1 .4-1 1c0 2.9 1.6 5.5 3.9 6.9.4.2 1.1.1 1.3-.4.3-.5.1-1.1-.3-1.4C7.2 15.1 6 13.2 6 11zM20 11c0-.6-.4-1-1-1s-1 .4-1 1c0 3.3-2.7 6-6 6-.6 0-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1v-3.1c3.9-.5 7-3.8 7-7.9z",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          fill: "#8EA3EE",
          d: "M12 1C9.8 1 8 2.8 8 5v6c0 2.2 1.8 4 4 4s4-1.8 4-4V5c0-2.2-1.8-4-4-4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }