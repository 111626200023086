var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "g",
        {
          staticClass: "remove_svg__ic-remove-paper-24",
          attrs: { fill: "none", "fill-rule": "evenodd" },
        },
        [
          _c("path", {
            staticClass: "remove_svg__ic-remove-paper-24__bg",
            attrs: { d: "M0 0h24v24H0z" },
          }),
          _c(
            "g",
            {
              staticClass: "remove_svg__ic-remove-paper-24__icon",
              attrs: {
                stroke: "#2C3143",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
              },
            },
            [
              _c("path", {
                staticClass: "remove_svg__ic-remove-paper-24__icon__path-20",
                attrs: {
                  d: "M4 9h16V8a2 2 0 00-2-2H6a2 2 0 00-2 2v1h0zm4-3h8V5a2 2 0 00-2-2h-4a2 2 0 00-2 2v1h0z",
                },
              }),
              _c("path", {
                staticClass: "remove_svg__ic-remove-paper-24__icon__path-18",
                attrs: { d: "M5.5 9L7 21h10l1.5-12" },
              }),
              _c("path", {
                staticClass: "remove_svg__ic-remove-paper-24__icon__path-21",
                attrs: { d: "M10 12l.5 6m3.5-6l-.5 6" },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }