

import AsyncDestroyable from '../async.destroyable.js';
import AuthUtils from '../../util/auth.js';

const PROVIDER = {
    PASSWORD: 'password',
    FACEBOOK: 'facebook',
    GOOGLE: 'google',
    APPLE: 'apple',
    WEIBO: 'weibo'
};

export class UserAuthentication extends AsyncDestroyable {
    constructor (user, token) {
        super([]);
        this._user = user;

        this.token = token;
        this.providers = [];
        this.email_verified = false;
    }

    async destroy () {
        delete this._user;
    }

    async update (authentication_info) {
        const {
            token,
            providers,
            email_verified
        } = authentication_info;

        // TODO: This should emit an event about the token change instead of updating it like this
        await AuthUtils.setAuthentication(this._user.UUID, token);
        this._user.getApiClient().setToken('token_authentication', token);

        this.token = token;
        this.providers = providers;
        this.email_verified = email_verified;
    }

    is_password_set () {
        return this.providers.includes(PROVIDER.PASSWORD);
    }
}
