<template lang="html">
    <div v-show="isActive" class="debug-tab-content">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Tab'
        }
    },
    data () {
        return {
            isActive: true
        }
    }
}
</script>
