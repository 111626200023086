var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "56",
          height: "56",
          viewBox: "0 0 56 56",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9.45 38a1.45 1.45 0 11-2.9 0 1.45 1.45 0 012.9 0z",
          fill: "#2C3143",
          stroke: "#2C3143",
          "stroke-width": "1.1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.066 21.065v13.87a3.401 3.401 0 01-3.401 3.402H21.406L41.49 18.19a3.4 3.4 0 011.577 2.875zM34.447 17.66l-20.041 20.1a3.397 3.397 0 01-1.513-2.825v-13.87a3.405 3.405 0 013.405-3.406h18.149z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.5 21.5h9M16.5 25.5h5",
          stroke: "#9DF4F4",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.905 12.09L12.05 43.865M8.273 38.828A22.405 22.405 0 015.5 28C5.5 15.572 15.572 5.5 28 5.5S50.5 15.572 50.5 28 40.428 50.5 28 50.5a22.423 22.423 0 01-15.95-6.632",
          stroke: "#2C3143",
          "stroke-width": "1.5",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.23 37h5.54c.561 0 .916.588.628 1.065l-2.763 4.58a.745.745 0 01-1.27 0l-2.763-4.58c-.288-.477.067-1.065.627-1.065z",
          fill: "#AC6ED1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }