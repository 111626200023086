
import _ from 'lodash';
import $ from 'jquery';
import Raven from 'raven-js';
import Backbone from 'backbone';
import moment from 'moment';

import KeyCodes from '../../util/keycodes.js';
import renderTemplate from '../../util/template.renderer.js';
import CopyLinkView from '../util/copy.link.view.js';

const DEFAULT_OPTIONS = {
    navigation: false,
    illustration_prefix: '',
    dismissible: false,
    dismiss_confirm: false,
    copy_link_enabled: false
};

export const DoorslamView =  Backbone.View.extend({

    events: {
        'click div.arrow[data-navigate="next"]': 'next',
        'click div.arrow[data-navigate="previous"]': 'previous',
        'click button[data-action]': 'action',
        'click a.dismiss': 'dismiss'
    },

    /**
     * @typedef {object} DoorslamData
     * @property {array<{actions: array<{action, style, uri, title}>, heading, illustration_id, paragraphs: array<string>}>} cards
     */

    /**
     *
     * @param {DoorslamData} doorslam - Doorslam data
     * @param {object} context - Doorslam context
     * @param {{navigation:boolean, illustration_prefix:string}} options - Additional view options
     */
    initialize: function (doorslam, context={}, options={}) {
        console.debug(`Initializing DoorslamView with doorslam='${JSON.stringify(doorslam)}'; context='${JSON.stringify(context)}'; options='${JSON.stringify(options)}'`);

        this._doorslam = doorslam;
        this._context = context;
        this._options = _.defaults(options, DEFAULT_OPTIONS);
        this._doorslamRenderedAt = null;
    },

    remove: function () {
        $('main#app-root').removeClass('doorslam-open');
        $(document).unbind('keyup', this.onDocumentKeyUp);
        this.unbind();
        if (this.copyLinkView) {
            this.copyLinkView.remove();
        }
        Backbone.View.prototype.remove.call(this);
    },

    render: function () {
        renderTemplate('upsell/doorslam', {
            doorslam: this._doorslam,
            context: this._context,
            options: this._options
        }, this.$el).then( () => {
            this.mountSubviews();
            $('main#app-root').addClass('doorslam-open');
            console.log(`DoorslamView.rendered`);
            this._doorslamRenderedAt = moment().local();
        });
    },

    mountSubviews: function () {
        if (this._options.copy_link_enabled && this._options.copy_link_url && this._options.variation_uuid) {
            this.copyLinkView = new CopyLinkView({ copy_link_url: this._options.copy_link_url });
            this.copyLinkView.setElement(this.$('.copy-link-container'));
            this.copyLinkView.model.set({ mounted: true, variation_uuid: this._options.variation_uuid });
            this.copyLinkView.on('copy-link', (variation_uuid) => {
                this.trigger('copy-link', variation_uuid);
            });
        }
    },

    /**
     * Starts all the necessary event listeners
     */
    start: function () {
        // Start key listeners for navigation
        this.onDocumentKeyUp = event => {
            let keyCode = event.keyCode;
            let now = moment().local();
            let elapsedSecondsAfterRender = moment.duration(now.diff(this._doorslamRenderedAt)).asSeconds();

            // To remove accidental key-presses
            if (elapsedSecondsAfterRender < 1) {
                return;
            }

            if (this._options.navigation) {
                if (keyCode === KeyCodes.RIGHT_ARROW) {
                    let $current = this.$el.find('section.content-container > article[data-index]:not(.hidden)'),
                        currentIndex = parseInt($current.data('index')),
                        lastIndex = this.$el.find('section.content-container > article[data-index]').length - 1;

                    if (currentIndex < lastIndex) {
                        this._navigate('next');
                    }
                } else if (keyCode === KeyCodes.LEFT_ARROW) {
                    let $current = this.$el.find('section.content-container > article[data-index]:not(.hidden)'),
                        currentIndex = parseInt($current.data('index'));

                    if (currentIndex > 0) {
                        this._navigate('previous');
                    }
                }
            }
            if (keyCode === KeyCodes.ENTER) { // get first active button and trigger it
                let $firstButton = this.$el.find('section.utility-container .slot .button-container:not(.hidden) button[data-action]').first(),
                    _action = $firstButton.data('action'),
                    _uri = $firstButton.data('uri') || null;
                if (_action && _action.length > 0) {
                    this.trigger(_action, _uri);
                }
            }

            if (keyCode === KeyCodes.TAB) {
                let $firstButton = this.$el.find('section.utility-container .slot .button-container:not(.hidden) button[data-action]').first();
                // set focus only once to first button and then forget about it
                if (!$firstButton.data('focused-once')) {
                    $firstButton.focus();
                    $firstButton.data('focused-once', true);
                }
            }

            if (keyCode === KeyCodes.ESC && this._options.dismissible) {
                if (this._options.dismiss_confirm) {
                    this.trigger('dismiss', null);
                } else {
                    this.trigger('close', null);
                }
            }
        };
        $(document).on('keyup', this.onDocumentKeyUp);
    },

    /**
     *
     * @param {string} direction
     * @private
     */
    _navigate: function (direction) {
        let $current = this.$el.find('section.content-container > article[data-index]:not(.hidden)'),
            currentIndex = parseInt($current.data('index')),
            nextIndex = currentIndex + (direction === 'previous' ? -1 : 1),
            lastIndex = this.$el.find('section.content-container > article[data-index]').length - 1;

        if (nextIndex <= lastIndex && nextIndex >= 0) {
            this.animateNavigation($current, nextIndex, currentIndex);
        }
    },

    /**
     * Navigate to specific page of the doorslam
     */
    navigateToIndex: function (pageIndex) {
        const $currentPage = this.$('article[data-index]:not(.hidden)');
        const numPages = this.$('article[data-index]').length;
        const currentIndex = parseInt($currentPage.data('index'));
        if (pageIndex >= 0 && pageIndex < numPages) {
            this.animateNavigation($currentPage, pageIndex, currentIndex);
        }
    },

    animateNavigation: function ($current, nextIndex, currentIndex) {

        var self = this,
            $next,
            $nextButtons,
            $previousButtons;

        let lastIndex = this.$el.find('section.content-container > article[data-index]').length - 1;

        $next = this.$el.find('section.content-container > article[data-index="' + nextIndex + '"]');
        $nextButtons = self.$el.find('div.button-container[data-index="' + nextIndex + '"]');
        $previousButtons = self.$el.find('div.button-container:not([data-index="' + nextIndex + '"])');

        this.$el.find('section.arrows > div.arrow').removeClass('hidden');
        if (nextIndex === 0) {
            this.$el.find('section.arrows > div.arrow[data-navigate="previous"]').addClass('hidden');
        }

        if (nextIndex === lastIndex) {
            this.$el.find('section.arrows > div.arrow[data-navigate="next"]').addClass('hidden');
        }

        $current.css('transform', '');
        $current.css('opacity', '');

        if (nextIndex > currentIndex) {
            $current.removeClass('right left').addClass('left');
            $next.removeClass('right left').addClass('right');
        } else {
            $current.removeClass('right left').addClass('right');
            $next.removeClass('right left').addClass('left');
        }

        $nextButtons.removeClass('hidden');

        setTimeout(function () {
            $current.addClass('hidden');
            $next.removeClass('hidden');
            $previousButtons.addClass('not-shown').addClass('hidden').find('button').prop('disabled', true);
            $nextButtons.removeClass('not-shown').find('button').prop('disabled', false);
            self.$el.find('.background[data-index="' + currentIndex + '"]').addClass('hidden');
            self.$el.find('.background[data-index="' + nextIndex + '"]').removeClass('hidden');
        }, 16);
    },

    next: function() {
        this._navigate('next');
    },

    previous: function() {
        this._navigate('previous');
    },

    action: function (event) {
        let $target = $(event.currentTarget),
            action = $target.data('action'),
            uri = $target.data('uri');

        switch (action) {
            case 'open':
                this.trigger('open', uri);
                break;
            case 'next':
                this._navigate('next');
                break;
            case 'close':
                this.trigger('close', uri);
                break;
            case '_custom': // NB! Only used for doorslams defined in the web client!
                this.trigger(uri);
                break;
            default:
                Raven.captureException(`Unknown doorslam button action triggered "${action}"`, {level: 'error'});
                break;
        }
    },

    dismiss: function () {
        this.trigger('dismiss');
    }
});
