<template>
    <div class="card-status-icon">
        <template v-if="this.showTooltip">
            <span class="tooltip" v-if="tooltip">{{ tooltip }}</span>
            <component v-bind:is="icon"/>
        </template>
        <component v-else v-bind:is="icon" :title="this.status"/>
    </div>
</template>

<script>
import StatusAll from "Images/course-wizard/card-status/status-all.svg";
import StatusComplete from "Images/course-wizard/card-status/status-complete.svg";
import StatusInComplete from "Images/course-wizard/card-status/status-incomplete.svg";
import StatusInCompleteKnown from "Images/course-wizard/card-status/status-incomplete-known.svg";
import StatusInvalid from "Images/course-wizard/card-status/status-invalid.svg";
import StatusKnown from "Images/course-wizard/card-status/status-known.svg";
import i18nUtils from "Util/i18n";


export default {
    name: 'card-status-icon',
    props: {
        status: {
            type: [String],
            required: true
        },
        known: {
            required: true
        },
        showTooltip: {
            type: [Boolean],
            default: false
        }
    },
    computed: {
        icon: function () {
            if (this.known) {
                if (this.status === 'incomplete') {
                    return StatusInCompleteKnown;
                } else {
                    return StatusKnown;
                }
            } else {
                switch (this.status) {
                    case 'complete':
                        return StatusComplete;
                    case 'incomplete':
                        return StatusInComplete;
                    case 'invalid':
                        return StatusInvalid;
                    case 'all':
                        return StatusAll;
                }
            }
        },
        tooltip: function () {
            if (this.known) {
                if (this.status === 'incomplete') {
                    return i18nUtils.prop('lessons_creator_editor_tooltip_already_learned_incomplete');
                } else {
                    return i18nUtils.prop('lessons_creator_editor_tooltip_card_already_learned');
                }
            } else {
                switch (this.status) {
                    case 'complete':
                        return i18nUtils.prop('lessons_creator_editor_tooltip_incomplete_card');
                    case 'incomplete':
                        return i18nUtils.prop('lessons_creator_editor_tooltip_incomplete_card');
                    case 'invalid':
                        return i18nUtils.prop('lessons_creator_editor_tooltip_missing_translation');
                }
            }
        },
    },
}
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.card-status-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        > span.tooltip {
            display: none;
            min-width: pxToRem(130);
            position: absolute;
            bottom: pxToRem(24);
            right: pxToRem(8);
            border-radius: pxToRem(8);
            background-color: $colorBackgroundDarkLighter;
            padding: pxToRem(8);
            text-align: center;
            color: #fff;
        }
        &:hover {
            > span.tooltip {
                display: block;
            }
        }
    }
</style>
