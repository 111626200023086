import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';
import ArticleView from '../view/challenges/article.view.js';
import MultipleChoiceView from '../view/challenges/multiple.choice.view.js';
import {
    ENTRY_TYPE as MULTIPLE_CHOICE_ENTRY_TYPE
} from '../modules/exercises/answer/multiple.choice.js';
import {
    ENTRY_TYPE as ARTICLE_ENTRY_TYPE
} from '../modules/exercises/answer/article.js';
import { TYPE as EXERCISE_TYPE } from '../modules/exercises/constants.js';

export class ReadingController {

    constructor () {
        this.exercise = null;
        this.exerciseAnswer = null;
    }

    go (appView, router, exerciseUuid) {
        this.exercise = UserManager.instance.getUser().getCourse().getExercises().getExercise(exerciseUuid);
        this.exerciseAnswer = this.exercise.getExerciseAnswer();

        return Promise.resolve()
            .then(() => this.exercise.getData())
            .then(exerciseData => {

                if (exerciseData.type === EXERCISE_TYPE.ARTICLE) {

                    const articleView = new ArticleView();
                    articleView.setProps(exerciseData);

                    articleView.on('time-spent', () => {
                        this.exerciseAnswer.addEntry(ARTICLE_ENTRY_TYPE.TIME);
                    });

                    articleView.on('visit-link', () => {
                        this.exerciseAnswer.addEntry(ARTICLE_ENTRY_TYPE.LINK);
                    });

                    articleView.on('reading-complete', () => {
                        this.exerciseAnswer.addEntry(ARTICLE_ENTRY_TYPE.COMPLETE);
                    });

                    articleView.on('exercise-ended', () => {
                        if (this.exerciseAnswer.hasEntries()) {
                            this.exerciseAnswer.complete();
                            if (this.exerciseAnswer.isFinished()) {
                                this.exercise.setCompleted();
                            }
                            UserManager.instance.getUser().getEventSender()
                                .sendArticleExerciseEvent(this.exerciseAnswer);
                        }
                    });

                    appView.setMainView(articleView, true);
                    articleView.render();

                } else if (exerciseData.type === EXERCISE_TYPE.READING) {

                    const multipleChoiceView = new MultipleChoiceView();
                    multipleChoiceView.setProps(exerciseData);

                    multipleChoiceView.on('exercise-answer-incorrect', answer => {
                        this.exerciseAnswer.addEntry(MULTIPLE_CHOICE_ENTRY_TYPE.SELECT, {
                            option: answer.answerId,
                            correct: false
                        });
                    });

                    multipleChoiceView.on('exercise-answer-correct', answer => {
                        this.exerciseAnswer.addEntry(MULTIPLE_CHOICE_ENTRY_TYPE.SELECT, {
                            option: answer.answerId,
                            correct: true
                        });
                    });

                    multipleChoiceView.on('exercise-ended', isComplete => {
                        if (this.exerciseAnswer.hasEntries()) {
                            this.exerciseAnswer.complete(isComplete);
                            if (isComplete) {
                                this.exercise.setCompleted();
                            }
                            UserManager.instance.getUser().getEventSender().sendReadingExerciseEvent(this.exerciseAnswer);
                        }
                    });

                    appView.setMainView(multipleChoiceView, true);
                    multipleChoiceView.render();
                }
            });
    }
}

export const reading_controller_factory = new ControllerFactory(ReadingController);
