<template>
    <div class="upload-file" @dragover="dragover" @dragleave="dragleave" @drop="drop" :class="{ error: typesAnimated }">
        <template v-if="!loading">
            <input type="file" class="file-input" @change="onChange" ref="file" multiple accept=".jpg,.jpeg,.png,.txt,.pdf" />
            <div class="button-container">
                <v-button :i18n="{ id: 'lessons_creator_editor_upload_button' }" @click.native="openExplorer" />
                <span class="help-text" v-html="this.$i18n('lessons_creator_editor_upload_text')"/>
            </div>
            <div class="accepted-types" :class="{ animated: typesAnimated }">
                <FileImageIcon />
                <span class="text">(JPG, PNG)</span>
                <FileTextIcon />
                <span class="text">(TXT, PDF)</span>
            </div>
        </template>
        <div class="process" v-else>
            <loader />
            <p v-if="status === 'uploading'" v-html="this.$i18n('lessons_creator_editor_upload_status_1_uploading')" />
            <p v-else-if="status === 'upload-complete'" v-html="this.$i18n('lessons_creator_editor_upload_status_2_file_uploaded')" />
            <p v-else-if="status === 'processing'" v-html="this.$i18n('lessons_creator_editor_upload_status_3_file_uploaded')" />
            <p v-else-if="status === 'still-processing'" v-html="this.$i18n('lessons_creator_processing_message_text')" />
        </div>
    </div>
</template>

<script>
import VButton from "ViewComponent/v-button.vue";
import UserManager from "Modules/usermanager";
import Loader from "ViewComponent/loader.vue";
import FileImageIcon from 'Images/file-image-32.svg';
import FileTextIcon from 'Images/file-text-32.svg';
import { TYPE as TOAST_TYPE } from "../../toaster/constants.js";
import { EventBus } from "Util/vue-event-bus";
import i18nUtils from 'Util/i18n';

export default {
    name: 'upload-file',
    components: {
        Loader,
        VButton,
        FileImageIcon,
        FileTextIcon,
    },
    props: {
        lesson_uuid: {
            type: String
        },
    },
    data() {
        return {
            status: null,
            loading: false,
            file: null,
            content_type: null,
            file_size: null,
            image_width: null,
            image_height: null,
            typesAnimated: false,
        }
    },
    methods: {
        openExplorer() {
            this.$refs.file.click();
        },
        async onChange() {
            if (this.$refs.file.files && this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files[0];
                if (this.validateFile(this.file)) {
                    this.content_type = this.file.type ? this.file.type : null;

                    if (this.file.size) {
                        this.file_size = (this.file.size / (1024*1024)).toFixed(2);
                    }

                    let reader = new FileReader();
                    const that = this;

                    if (this.content_type === 'text/plain') { // plain text files
                        reader.onload = function () {
                            let text = reader.result;
                            if (text.length > 0) {
                                that.$emit('text-from-file', text)
                                this.loading = false;
                            } else {
                                that.resetFile();
                                that.animateTypes();
                            }
                        }
                        reader.readAsText(this.file);
                    } else if (this.content_type === 'application/pdf'){ // pdf
                        await that.callCreateFileGetText('pdf');
                    } else { // images
                        reader.readAsDataURL(this.file);
                        reader.onload = function (e) {
                            let image = new Image();
                            image.src = e.target.result;

                            image.onload = function () {
                                that.image_height = this.height;
                                that.image_width = this.width;
                                that.callCreateFileGetText('image');
                            };
                        }
                    }
                }
            }
        },
        async callCreateFileGetText(type) {
            if (this.file && this.content_type && this.file_size) {
                this.loading = true;
                EventBus.$emit('cw:disable-interaction');

                let ocrFileInfo;
                if (type === 'image' && this.image_width && this.image_height) {
                    ocrFileInfo = await UserManager.instance.getUser().getLessons().createFileGetFileUuid(this.lesson_uuid, this.file, this.content_type, this.file_size, this.image_width, this.image_height);
                } else {
                    ocrFileInfo = await UserManager.instance.getUser().getLessons().createFileGetFileUuid(this.lesson_uuid, this.file, this.content_type, this.file_size);
                }

                if (ocrFileInfo) {
                    this.$emit('ocr-done', ocrFileInfo)
                    this.loading = false;
                } else {
                    this.animateTypes();
                }
            }
        },
        validateFile(file) {
            const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'text/plain', 'application/pdf'];

            if (validTypes.includes(file.type)) {
                return true;
            } else {
                const toast = {
                    text: i18nUtils.prop('lessons_creator_toast_filetype_copy', { filename: file.name } ),
                    type: TOAST_TYPE.ALERT
                };
                EventBus.$emit('toaster-add', toast);
                this.animateTypes();
            }
        },
        dragover(event) {
            event.preventDefault();
            event.currentTarget.classList.add('drag');
        },
        dragleave(event) {
            event.currentTarget.classList.remove('drag');
        },
        drop(event) {
            event.preventDefault();
            const filesDropped = event.dataTransfer.files;

            if (filesDropped && filesDropped.length > 0 && this.validateFile(filesDropped[0])) {
                this.$refs.file.files = filesDropped;
                this.onChange();
            }

            event.currentTarget.classList.remove('drag');
        },
        animateTypes() {
            this.typesAnimated = true;
            setTimeout(() => {
                this.typesAnimated = false;
            }, 1000);
            return false;
        },
        resetFile() {
            this.$refs.file.value = '';
            this.file = null;
            this.content_type =  null;
            this.file_size=  null;
            this.image_width = null;
            this.image_height = null;
        },
        reset() {
            this.resetFile();
            this.status = null;
            this.loading = false;
            this.typesAnimated = false;
        },
        updateStatus(status){
            switch (status) {
                case 'failed':
                    EventBus.$emit('toaster-add', {
                        text: i18nUtils.prop('lessons_creator_upload_end_message_error_text'),
                        type: TOAST_TYPE.ERROR
                    });
                    this.reset();
                    break;
                case 'completed':
                    EventBus.$emit('toaster-add', {
                        text: i18nUtils.prop('lessons_creator_editor_upload_status_4_adding_words'),
                        type: TOAST_TYPE.SUCCESS
                    });
                    break;
                default:
                    this.status = status;
            }
        },
        listenEventBus() {
            EventBus.$on('cw:ocr-status', this.updateStatus);
        }
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('loading', newValue)
            }
        }
    },
    created() {
        this.listenEventBus();
    },
    beforeDestroy() {

    }
}
</script>

<style lang="scss">
    @import "~Styles/colors";
    @import "~Styles/mixins";

    div.upload-file {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: background-color 250ms ease-out;
        background-color: $colorInputBoxFocus;
        border-radius: pxToRem(12);
        border: 1px dashed $colorMasculine;
        padding: 1.5rem 2rem;
        > input.file-input {
            position: absolute;
            z-index: 10;
            opacity: 0;
            overflow: hidden;
        }
        &.drag {
            background-color: $colorInputBox;
        }
        &.error {
            background-color: lighten($colorAttention, 20%);
        }
        > div.button-container {
            position: relative;
            z-index: 20;
            display: flex;
            flex-direction: row;
            span.help-text {
                user-select: none;
                margin-left: .5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        > div.accepted-types {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: .5rem;
            font-size: fontSize(12);
            color: $colorSourceSecondary;
            z-index: 100;
            svg {
                margin: 0 .25rem;
            }
            &.animated {
                animation: shake-types 1s infinite;
            }
        }

        > div.process {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > div.loader {
                margin: .5rem;
            }
            > p {
                text-align: center;
            }

            > button {
                margin-top: .5rem;
            }
        }
    }

    @keyframes shake-types {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }

</style>
