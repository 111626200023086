var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M9 2.5v3M4 18.5v3M20 18.5v3M10.5 4h-3M5.5 20h-3M21.5 20h-3",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.776 15.906c.143.06.258.173.32.316.347.812 1.543 3.615 1.863 4.463a.64.64 0 01.001.449l-.535 1.448a.64.64 0 01-1.202 0l-1.736-4.693a.64.64 0 00-.378-.379l-4.69-1.736a.64.64 0 010-1.202l1.436-.532a.64.64 0 01.467.009l4.454 1.857z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12 11l-4.105 2.053a.5.5 0 000 .894l3.956 1.979a.5.5 0 01.224.223l1.978 3.957a.5.5 0 00.895 0l1.978-3.957a.499.499 0 01.224-.224L21.1 13.95a.5.5 0 00-.002-.896l-3.948-1.952a.5.5 0 01-.226-.226l-1.978-3.978a.5.5 0 00-.895-.001l-.177.353",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.205 5.206a.315.315 0 01.59 0l.76 2.053c.032.086.1.154.186.186l2.053.76a.315.315 0 010 .59l-2.053.76a.315.315 0 00-.186.186l-.76 2.053a.315.315 0 01-.59 0l-.76-2.053a.315.315 0 00-.186-.186l-2.053-.76a.315.315 0 010-.59l2.053-.76a.315.315 0 00.186-.186l.76-2.053z",
          fill: "#63E1E7",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }