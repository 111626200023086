import Backbone from 'backbone';

import renderTemplate from '../util/template.renderer.js';
import i18nUtils from '../util/i18n.js';


const ShowFileView = Backbone.View.extend({

    name: 'show-file',

    tagName: 'main',
    className: 'show-file-view',

    initialize: function (options) {
        console.log('ShowFileView: initialize');
        this.queryParameters = options.queryParameters;
    },

    render: function (data = {}) {
        data = {
            fileContent: this.queryParameters && this._getFileContent(this.queryParameters.name)
        };

        renderTemplate('show-file', data, this.$el).then( () => {
            this._postRender();
        });
    },

    _getFileContent: function (name) {
        return i18nUtils.getFileContent(name);
    },

    _postRender: function () {
        Backbone.trigger('rendered', this);
    },

    remove: function () {
        Backbone.View.prototype.remove.call(this);
    }
});

export default ShowFileView;
