var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          viewBox: "0 0 180 180",
          width: "180",
          height: "180",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M70.65 110.49c12.985 0 23.51-10.526 23.51-23.51 0-12.984-10.525-23.51-23.51-23.51-12.984 0-23.51 10.526-23.51 23.51 0 12.984 10.526 23.51 23.51 23.51z",
          fill: "#AC6ED1",
        },
      }),
      _c("path", {
        attrs: { d: "M70 37a4 4 0 100-8 4 4 0 000 8z", fill: "#2C3143" },
      }),
      _c("path", {
        attrs: {
          d: "M56.12 87.24c0-8.03 6.51-14.53 14.53-14.53",
          stroke: "#9DF4F4",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M62.58 117.81h16.14M64.72 126.7h11.85M39.87 87.19H32M109.29 87.19h-7.87M70.65 56.41v-7.87M48.89 65.43l-5.57-5.57M92.41 65.43l5.57-5.57M94.06 151.62h24.69v-20.9h5.6c6.97 0 12.62-5.65 12.62-12.62v-14.26h5.55c5.11 0 7.88-5.98 4.57-9.88l-11.91-14.02v-4.18c0-23.75-19.25-43-43-43H70.65",
          stroke: "#2C3143",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }