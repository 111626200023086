var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "40",
          height: "40",
          viewBox: "0 0 40 40",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3 5.313c4.909-1.452 10.582 2.346 12 7.374C10.091 14.14 4.418 10.34 3 5.313zM27.2 30.729a8.8 8.8 0 008.8-8.8c0-1.512-.827-3.09-1.675-4.707-.62-1.185-1.252-2.39-1.578-3.605-.77-2.873-2.483-5.547-5.548-5.547-3.063 0-4.806 2.533-5.547 5.547-.09.368-.207.721-.343 1.063 3.028 1.453 5.37 3.839 5.45 6.782.082 3.04 0 5.328-1.454 9.063.61.133 1.244.204 1.894.204zm5.708-10.148a1 1 0 00-1.816.838c.692 1.501 0 2.985-1.147 3.749a1 1 0 001.11 1.664c1.76-1.173 3.054-3.648 1.853-6.25z",
          fill: "#63E1E7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27 9.5s.691-3.178-.5-5M17.463 13c.49.085.97.201 1.437.347 4.691 1.46 8.1 5.863 8.1 11.069C27 30.813 21.851 36 15.5 36S4 30.813 4 24.416c0-3.747 1.765-7.077 4.504-9.194",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.5 17c-.556.516-1.5 1-2.5 1s-1.944-.484-2.5-1",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }