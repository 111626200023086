import Backbone from 'backbone';
import UserModel from '../model/user.model.js';
import i18nUtils from '../util/i18n.js';
import Vue from 'vue';
import ToasterView from './toaster/toaster.vue';
import DebugView from './debug/debug.vue';
import HomeDrawerView from './home_drawer/main.vue';
import SettingsView from './settings/main.vue';
import CookieConsentView from './component/cookie-consent.vue';
import HeaderView from './header/main.vue';
import { EventBus } from '../util/vue-event-bus.js';

import renderTemplate from '../util/template.renderer.js';

const AppView = Backbone.View.extend({

    el: 'main#app-root',

    events: {
        'click section.app-main': 'dismiss'
    },

    initialize: function () {
        console.log("App view init");
        i18nUtils.setAppView(this);
        UserModel.setAppView(this);

        Backbone.on('reRenderAllViews', this.reRenderAll, this);

        this.listenEventBus();
    },

    render: function () {
        return Promise.resolve()
            .then(() => renderTemplate('app', {}, this.$el))
            .then(() => {
                return this._postRender();
            });
    },

    _postRender: function () {
        this.$appHeaderContainer = this.$('section.app-header');
        this.$appContentContainer = this.$('section.app-content');
        this.$appHomeDrawerContainer = this.$('section.app-home-drawer');
        this.$appCookieConsentContainer = this.$('section.app-cookie-consent');
        this.$appToasterContainer = this.$('section.app-toaster');
        this.$appSettingsContainer = this.$('section.app-settings');
        this.$appDebugContainer = this.$('section.app-debug');

        this.setHomeDrawerView();
        this.setToasterView();
        this.setSettingsView();
        this.setDebugView();
        this.setHeaderView();
        this.setCookieConsentView();
    },

    setHeaderView: function (view) {
        if (this.$appHeaderContainer) {
            const headerView = document.createElement( "div" );
            headerView.classList.add('header-view');
            const HeaderViewComponent = Vue.extend(HeaderView);
            const HeaderViewInstance = new HeaderViewComponent();

            this.$appHeaderContainer.append(headerView);
            HeaderViewInstance.$mount(headerView);
        }
    },

    setMainView: function (view, noRender) {
        if (this._mainView && this._mainView !== view) {
            if (this._mainView instanceof Vue) {
                this._mainView.$destroy();
                if (this._mainView && this._mainView.$el) {
                    this._mainView.$el.remove();
                }
            } else {
                this._mainView.remove();
                this._mainView.unbind();
            }
        }

        this._mainView = view;

        if (this._mainView instanceof Vue) {
            const vueView = document.createElement( "div" );
            vueView.classList.add('vue-view');
            this.$appContentContainer.append(vueView);
            this._mainView.$mount(vueView);
        } else {
            this.$appContentContainer.append(this._mainView.el);
            if (!noRender) {
                this._mainView.render();
            }
        }
    },

    setToasterView: function () {
        if (this.$appToasterContainer) {
            const vueToasterView = document.createElement( "div" );
            vueToasterView.classList.add('vue-toaster-view');
            const ToasterViewComponent = Vue.extend(ToasterView);
            const ToasterViewInstance = new ToasterViewComponent();

            this.$appToasterContainer.append(vueToasterView);
            ToasterViewInstance.$mount(vueToasterView);
        }
    },

    setHomeDrawerView: function () {
        if (this.$appHomeDrawerContainer) {
            const homeDrawerView = document.createElement( "div" );
            homeDrawerView.classList.add('home-drawer-view');
            const HomeDrawerViewComponent = Vue.extend(HomeDrawerView);
            const HomeDrawerViewInstance = new HomeDrawerViewComponent();

            this.$appHomeDrawerContainer.append(homeDrawerView);
            HomeDrawerViewInstance.$mount(homeDrawerView);
        }
    },

    setSettingsView: function () {
        if (this.$appSettingsContainer) {
            const vueSettingsView = document.createElement( "div" );
            vueSettingsView.classList.add('vue-settings-view');
            const SettingsViewComponent = Vue.extend(SettingsView);
            const SettingsViewInstance = new SettingsViewComponent();

            this.$appSettingsContainer.append(vueSettingsView);
            SettingsViewInstance.$mount(vueSettingsView);
        }
    },

    setDebugView: function () {
        if (this.$appDebugContainer) {
            const vueDebugView = document.createElement( "div" );
            vueDebugView.classList.add('vue-debug-view');
            const DebugViewComponent = Vue.extend(DebugView);
            const DebugViewInstance = new DebugViewComponent();

            this.$appDebugContainer.append(vueDebugView);
            DebugViewInstance.$mount(vueDebugView);
        }
    },

    setCookieConsentView: function () {
        if (this.$appCookieConsentContainer) {
            const cookieConsentView = document.createElement( "div" );
            cookieConsentView.classList.add('cookie-consent-view');
            const CookieConsentViewComponent = Vue.extend(CookieConsentView);
            const CookieConsentViewInstance = new CookieConsentViewComponent();

            this.$appCookieConsentContainer.append(cookieConsentView);
            CookieConsentViewInstance.$mount(cookieConsentView);
        }
    },

    reRenderAll: function () {
        console.log('AppView: re rendering all current views.');
        if (this._mainView) {
            if (this._mainView instanceof Vue) {
                this._mainView.$forceUpdate();
            } else {
                this._mainView.render();
            }
        }
    },

    dismiss: function () {
        Backbone.trigger('closeDrawers');
    },

    listenEventBus: function () {
        EventBus.$on('home-drawer-opened', () => {
            this.$el.addClass('home-drawer-open');
        });
        EventBus.$on('home-drawer-closed', () => {
            this.$el.removeClass('home-drawer-open');
        });
    },
});

export default AppView;
