var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lesson-meta" }, [
    _c(
      "div",
      { staticClass: "meta-block separate box" },
      [
        _c("cards-toggle-menu", {
          attrs: { lesson: this.lesson, cards: this.cards },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "meta-block" }, [
      _c("div", { staticClass: "text" }, [
        _c("div", {
          staticClass: "title",
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("course_wizard_course_description_title")
            ),
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "subtitle",
          domProps: {
            innerHTML: _vm._s(
              this.$i18n("course_wizard_course_description_body")
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "textarea" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model.lazy",
              value: _vm.description,
              expression: "description",
              modifiers: { lazy: true },
            },
          ],
          attrs: {
            rows: "6",
            maxlength: "2000",
            placeholder: this.$i18n(
              "course_wizard_course_description_screen_body"
            ),
            "data-id": "description",
          },
          domProps: { value: _vm.description },
          on: {
            change: function ($event) {
              _vm.description = $event.target.value
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }