var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "text" },
    _vm._l(_vm.text, function (word) {
      return _c(
        "span",
        { staticClass: "word", class: { blocked: word.p > _vm.position } },
        [_vm._v(_vm._s(word.w))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }