
export const TYPE = {
    SPEAKING: 'speaking',
    READING: 'reading',
    LISTENING: 'listening',
    ARTICLE: 'article'
};

export const CATEGORY = {
    SPEAKING: 'speaking',
    READING: 'reading',
    LISTENING: 'listening',
    GRAMMAR: 'grammar',
    ARTICLE: 'article'
};

export const SUPPORTED = [
    TYPE.SPEAKING,
    TYPE.READING,
    TYPE.LISTENING,
    TYPE.ARTICLE
];
