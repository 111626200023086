var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "32",
          viewBox: "0 0 32 32",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { "fill-rule": "nonzero", fill: "none" } }, [
        _c("path", {
          attrs: {
            fill: "#FFCC4D",
            d: "M32 16c0 8.836-7.164 16-16 16S0 24.836 0 16 7.164 0 16 0s16 7.164 16 16",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#664500",
            d: "M16 19.421c-2.636 0-4.387-.307-6.55-.728-.495-.094-1.456 0-1.456 1.456 0 2.911 3.345 6.55 8.006 6.55s8.006-3.639 8.006-6.55c0-1.456-.962-1.55-1.456-1.456-2.163.42-3.913.728-6.55.728",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#DD2E44",
            d: "M14.8 2.916a4.147 4.147 0 00-7.897.226A4.147 4.147 0 00.615 7.921c.109.306.253.59.42.851 1.734 3.184 6.745 5.423 9.778 5.45 2.344-1.926 4.841-6.813 4.194-10.38-.034-.309-.1-.62-.207-.926zm2.4 0A4.152 4.152 0 0122.494.383a4.15 4.15 0 012.604 2.76 4.147 4.147 0 013.755-.516 4.152 4.152 0 012.534 5.294 4.24 4.24 0 01-.42.851c-1.735 3.184-6.746 5.423-9.78 5.45-2.344-1.926-4.84-6.813-4.193-10.38.033-.309.1-.62.206-.926z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }