var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "22",
          height: "22",
          viewBox: "0 0 22 22",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M14.5 14.5l-2.76 6.703a.8.8 0 01-1.48 0L7.5 14.5.797 11.74a.8.8 0 010-1.48L7.5 7.5 10.26.796a.8.8 0 011.48 0L14.5 7.5l6.703 2.76a.8.8 0 010 1.48L14.5 14.5z",
          fill: "#6CD5E5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }