var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.selected_card && this.selected_card_contexts
    ? _c(
        "div",
        { staticClass: "lesson-card" },
        [
          _c("close-button", {
            staticClass: "close",
            on: { click: _vm.close },
          }),
          _vm._v(" "),
          _c("h3", {
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("lessons_creator_editor_card_preview_title")
              ),
            },
          }),
          _vm._v(" "),
          _c("card-preview-edit", {
            attrs: {
              selected_card: _vm.selected_card,
              course_info: _vm.course_info,
            },
            on: { close: _vm.close },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "comment" }, [
            _c("textarea", {
              staticClass: "comment",
              attrs: {
                rows: "2",
                maxlength: "100",
                placeholder: this.$i18n("course_wizard_comment_title"),
              },
              domProps: { value: _vm.comment },
              on: {
                change: _vm.updateComment,
                keyup: _vm.updateCommentCounter,
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "comment-length-counter" }, [
              _vm._v(_vm._s(_vm.commentLengthCounter)),
            ]),
          ]),
          _vm._v(" "),
          _c("h3", {
            domProps: {
              innerHTML: _vm._s(
                this.$i18n("lessons_creator_editor_choose_sentence_title")
              ),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { ref: "sentences", staticClass: "sentences" },
            [
              _vm._l(_vm.selected_card_contexts_filtered, function (item) {
                return _c(
                  "div",
                  {
                    class: {
                      sentence: true,
                      selected: _vm.selected_card.context === item.context,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("select-card-context", item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "context target" }, [
                      _vm._v(_vm._s(item.context)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "context-translation" }, [
                      _vm._v(_vm._s(item.context_translation)),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.selected_card_contexts.length > 5
                ? _c("div", { staticClass: "actions" }, [
                    !_vm.showMore
                      ? _c(
                          "div",
                          {
                            staticClass: "show-more",
                            on: { click: _vm.showMoreSentences },
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n("course_wizard_sentence_show_more")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("arrow-down-icon"),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showMore
                      ? _c(
                          "div",
                          {
                            staticClass: "show-less",
                            on: { click: _vm.showLessSentences },
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  this.$i18n("course_wizard_sentence_show_less")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("arrow-up-icon"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }