export default {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: "trước",
    suffixFromNow: "từ bây giờ",
    seconds: "chưa đầy một phút",
    minute: "khoảng một phút",
    minutes: "%d phút",
    hour: "khoảng một giờ",
    hours: "khoảng %d giờ",
    day: "một ngày",
    days: "%d ngày",
    month: "khoảng một tháng",
    months: "%d tháng",
    year: "khoảng một năm",
    years: "%d năm",
    wordSeparator: " ",
    numbers: []
};
