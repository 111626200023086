var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M8 11H4c-.994 0-2 1.006-2 2v8l3.322-2H9c.994 0 2-1.006 2-2v-2",
          stroke: "#E2B4FF",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.5 7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8 10v3c0 .994 1.006 2 2 2h7l5 3V5c0-.994-.606-2-1.6-2H10c-.994 0-2 1.006-2 2v1M13 11h4",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }