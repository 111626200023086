var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          opacity: ".5",
          fill: "#63E1E7",
          d: "M21 15c0-1.1-.9-2-2-2h-6v6c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2v-4zm0-10c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v6h6c1.1 0 2-.9 2-2V5zM11 5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h6V5z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#6CD5E5",
          d: "M11 13H4c-1.7 0-3 1.3-3 3v4c0 1.7 1.3 3 3 3h4c1.7 0 3-1.3 3-3v-7z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }