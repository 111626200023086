<template>
    <div class="toaster">
        <transition-group name="list" tag="div">
            <toast v-for="toast in toasts"
                   :key="toast.id"
                   :toast="toast"
                   @click.native="removeToast(toast)"
            />
        </transition-group>
    </div>
</template>

<script>
    import toast from './toast.vue';
    import { uniqueId } from 'lodash';
    import { EventBus } from 'Util/vue-event-bus';

    export default {
        name: 'toaster',
        data() {
            return {
                toasts: []
            };
        },
        components: {
            toast
        },
        methods: {
            addToast(toast) {
                if (toast && toast.text) {
                    toast.id = uniqueId('toast_');
                    const visibleFor = (toast.hasOwnProperty('time')) ? toast.time : 4000;
                    this.toasts.push(toast);
                    setTimeout(() => {
                        this.removeToast(toast);
                    }, visibleFor);
                }
            },
            removeToast(toast) {
                this.toasts = this.toasts.filter(t => t.id !== toast.id);
            },
            listenEventBus() {
                // NOTE: when adding to the list, make sure to add $off to beforeDestroy() method as well.
                EventBus.$on('toaster-add', this.addToast);
            }
        },
        created() {
            this.listenEventBus();
        },
        beforeDestroy() {
            EventBus.$off('toaster-add', this.addToast);
        }
    };
</script>

<style lang="scss" scoped>
    div.toaster {
        display: flex;
        flex-direction: column;
        user-select: none;
        div.toast {
            margin-bottom: 1rem;
            &.list-enter-active, &.list-leave-active {
                transition: all .5s;
            }
            &.list-enter, &.list-leave-to {
                opacity: 0;
                transform: translateY(2rem);
            }
        }
    }
</style>
