<template>
    <section class="discount">
        <div class="text">
            <h3 v-html="this.$i18n('register_marketing_optin_title')" />
            <p v-html="this.$i18n('register_marketing_optin_subtitle')" />
        </div>
        <toggle-button v-model="optInLocal"
                       @change="save"
                       :key="`optIn:toggle`"
                       :sync="true"
                       :color="{ checked: '#2C3143' }"
                       :width="60"
                       :height="24" />
    </section>
</template>

<script>
    export default {
        name: 'section-discount',
        props: {
            optIn: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                optInLocal: false
            }
        },
        methods: {
            save() {
                this.$emit('save', this.optInLocal);
            },
        },
        watch: {
            optIn(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.optInLocal = newValue;
                }
            }
        },
        beforeMount() {
            this.optInLocal = this.optIn;
        },
    }
</script>

<style lang="scss" scoped>
    @import "~Styles/colors";
    @import "~Styles/mixins";

    section.discount {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 0 .5rem;
        > div.text {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            > h3 {
                font-size: fontSize(16);
                line-height: fontSize(20);
            }
            > p {
                font-size: fontSize(12);
                line-height: fontSize(16);
                color: $colorSourceSecondary;
            }
        }
        > label.vue-js-switch {
            margin-left: auto;
        }
    }
</style>
