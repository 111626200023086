import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'lodash';
import renderTemplate from '../../util/template.renderer.js';

import CreateClassroomModalView from './create.classroom.modal.view.js';
import UserManager from '../../modules/usermanager.js';
import { CONFIRM_ACTION, ConfirmationView } from '../util/confirmation.view.js';
import i18nUtils from "../../util/i18n.js";
import Vue from "vue";
import LockedMessageView from "../component/locked-message.vue";

const ClassroomListView = Backbone.View.extend({

    name: 'classrooms',

    tagName: 'main',
    className: 'classrooms-view',

    events: {
        'click div.name': 'expandLessons',
        'click a.action.view': 'viewClassroom',
        'click a.action.delete': 'deleteClassroom',
        'click a.create-classroom': 'createClassroom'
    },

    initialize: function () {
        console.log('Classrooms view init');
    },

    setProps: function (props) {
        this.props = props;
    },

    render: function () {
        renderTemplate(
            'classrooms/classroom-list',
            this.props,
            this.$el
        ).then( () => {
            this._postRender();
        });
    },

    _postRender: function () {
        this.$lockedMessageContainer = this.$('div.locked-message-container');
        if (this.props.isDisabled) {
            this.$lockedMessageContainer.removeClass('hidden');
            this.setLockedMessageView();
        } else {
            this.$lockedMessageContainer.addClass('hidden');
        }
        Backbone.trigger('rendered', this);
    },

    createClassroom: function () {

        const createClassroomModalView = new CreateClassroomModalView();
        let userCourse = UserManager.instance.getUser().getCourse().getInfo();

        createClassroomModalView.setProps({
            activeSourceCourses: this.markSelectedCourse(this.props.activeSourceCourses, userCourse.uuid),
            otherSourceCourses: this.markSelectedCourse(this.props.otherSourceCourses, userCourse.uuid),
            allAvailableCourses: this.markSelectedCourse(this.props.allAvailableCourses, userCourse.uuid),
            noActiveNoOtherCourses: _.isEmpty(this.props.activeSourceCourses) && _.isEmpty(this.props.otherSourceCourses),
            selectedCourseUuid: (userCourse && userCourse.uuid) ? userCourse.uuid : null
        });

        createClassroomModalView.on('create-classroom', ({ name, course_uuid }) => {
            this.trigger('create-classroom', { name, course_uuid });
        });

        createClassroomModalView.render();
        this.$('div.add-classroom-modal-container').append(createClassroomModalView.el);
        createClassroomModalView.show();
    },

    setLockedMessageView: function() {
        if (this.$lockedMessageContainer) {
            this.$lockedMessageContainer.empty();
            const vueLockedMessageView = document.createElement( "div" );
            vueLockedMessageView.classList.add('vue-locked-message-view');
            const LockedMessageViewComponent = Vue.extend(LockedMessageView);
            const LockedMessageViewInstance = new LockedMessageViewComponent({
                propsData: {
                    closable: false,
                }
            });

            this.$lockedMessageContainer.append(vueLockedMessageView);
            LockedMessageViewInstance.$mount(vueLockedMessageView);
        }
    },

    markSelectedCourse: function(courses, uuid) {
        let _courses = _.cloneDeep(courses);
        return _courses.map(course => {
                if (course.uuid === uuid) {
                    course.selected = true;
                }
                return course;
            });
    },

    expandLessons: function ({ currentTarget }) {

        const classroomUuid = $(currentTarget).parent().data('uuid');

        if (this.props.lessonsEnabled) {
            this.$(`div.classroom-container[data-uuid="${classroomUuid}"]`).toggleClass('expand');
        }
    },

    viewClassroom: function (event) {
        const classroom_uuid = $(event.currentTarget).closest('div.classroom').data('uuid');
        Backbone.history.navigate(`classrooms/${classroom_uuid}`, { trigger: true });
    },

    deleteClassroom: function (event) {
        const $classroom = $(event.currentTarget).closest('div.classroom');
        const classroom_uuid = $classroom.data('uuid');
        const classroom_name = $classroom.data('name');

        const confirmationView = this._showClassroomDeleteConfirmation(classroom_name);

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                this.trigger('delete-classroom', { classroom_uuid });
            }

            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });
    },

    _showClassroomDeleteConfirmation (classroom_name) {

        const confirmationView = new ConfirmationView({
            title: i18nUtils.prop('classrooms_list_delete_confirm_dialog_title'),
            message: i18nUtils.prop('classrooms_list_delete_confirm_dialog_message', { classroom_name }),
            actions: [
                {
                    title: i18nUtils.prop('classrooms_list_delete_confirm_dialog_cancel'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('classrooms_list_delete_confirm_dialog_confirm'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        });

        confirmationView.show();

        return confirmationView;
    },
});


export default ClassroomListView;
