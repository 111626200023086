'use strict';

import SpeechReco from '../modules/speech.reco.js';
import { Awards } from '../modules/awards.js';

import { NAME as PARAMETER_NAME, TYPE as PARAMETER_TYPE } from '../modules/user.parameters.js';

import { CONFIRM_ACTION, ConfirmationView } from '../view/util/confirmation.view.js';

import { COURSE_FEATURE } from '../modules/course/course.js';

/**
 * Controller for Onboarding dialogs and notifications
 */
import EventEmitter from 'events';

import Backbone from 'backbone';
import moment from 'moment';
import _ from 'lodash';
import ControllerFactory from '../modules/controller.factory.js';

import ControllerManager from '../modules/controller.manager.js';

import UserManager from '../modules/usermanager.js';
import UserModel from '../model/user.model.js';

import OnboardingOverlayView from '../view/onboarding.overlay.view.js';

import ResponsivenessUtils from '../util/responsiveness.js';
import i18nUtils from '../util/i18n.js';
import ExportUtils from '../util/export.js';

import URI from '../util/uri.js';
import googleAnalyticsCommand, { tagManagerCommand } from '../util/google-analytics.js';
import { DoorslamView } from '../view/component/doorslam.js';
import {
    ConversationIntroDoorslam,
    CourseEndDoorslam,
    FastTrackingCompleteDoorslam,
    FastTrackingDoorslam,
    LessonIsPublishedDoorslam,
    NoFastTrackingDoorslam,
    VariationEndUnifiedDoorslam,
    WelcomeBackDoorslam,
    PaywallDoorslam
} from '../data/doorslams.js';
import { VARIATION_STATUS } from '../modules/course/user.course.js';
import { EventBus } from "../util/vue-event-bus.js";

export const ONBOARDING_STEP = {
    PLEDGE: 'pledge', // Deprecated-removed
    GUESS_TRANSLATION: 'guess_onboarding.translation',
    GUESS_ANSWER: 'guess_onboarding.answer',
    GUESS_REVEAL: 'guess_onboarding.reveal',
    GUESS_LEARN: 'guess_onboarding.learn',
    GUESS_FIRST_MISTAKE: 'guess_onboarding.first_mistake',
    GUESS_DOUBLE_REVEAL: 'guess_onboarding.double_reveal',
    GUESS_SYNONYM: 'guess_onboarding.synonym',
    WELCOME_BACK: 'welcome-back',
    CLASSROOM_FEATURE: 'classroom_feature',
    SETTINGS_AUTO_ADVANCE: 'settings.auto_advance',
    SETTINGS_STRICT_DIACRITICS: 'settings.strict_diacritics',
    FAST_TRACKING_DONE: 'doorslams.fast_tracking_done',
    GUESS_WORDLIST: 'guess_onboarding.wordlist',
    GUESS_JA_GENERAL: 'guess_onboarding.japanese_general',
    GUESS_JA_KATAKANA: 'guess_onboarding.japanese_katakana',
    GUESS_JA_HIRAGANA: 'guess_onboarding.japanese_hiragana',
    GUESS_KO_GENERAL: 'guess_onboarding.korean_general',
    GUESS_KO_HANGEUL: 'guess_onboarding.korean_hangeul',
    GUESS_RU_GENERAL: 'guess_onboarding.russian_general',
    GUESS_RU_CYRILLIC: 'guess_onboarding.russian_cyrillic',
    GUESS_FORM_SPELLING: 'guess_onboarding.form_spelling',
    GUESS_2ND_CARD: 'guess_onboarding.2nd_card',
    GUESS_3RD_CARD: 'guess_onboarding.3rd_card',
    GUESS_VOICE_INPUT: 'guess_onboarding.voice_input'
};

export class OnboardingController extends EventEmitter.EventEmitter {

    constructor () {
        super();
        this._doorslamQueue = [];
        this._tooltipQueue = [];
        this._postTrialStartQueue = [];

        Backbone.on('userSignedOut', this.onUserSignedOut, this);
        Backbone.on('tip-permanent-translations-switch-toggled', this.showPermanentTranslations, this);

        EventBus.$on('guess:question-ready', () => {
            this.onGuessQuestionReady();
        });

        ExportUtils.export('app.controller.onboarding', this);
    }

    onGuessQuestionReady () {
        this.showOnboardingGuessQuestionReady();
        this.showLessonAssignedNotification();
    }

    getCourseStep(step, courseUuid) {
        return courseUuid ? `${step}-${courseUuid}` : step;
    }

    getStepDone (step, courseUuid = null) {
        let courseStep = this.getCourseStep(step, courseUuid);

        return UserManager.instance.getUser().getParameters().getParameter(PARAMETER_NAME.ONBOARDING_PROGRESS_STEP_DONE(courseStep));
    }

    async setStepDone (step, courseUuid = null) {
        let courseStep = this.getCourseStep(step, courseUuid);

        await UserManager.instance.getUser().getParameters().setParameter(
            PARAMETER_NAME.ONBOARDING_PROGRESS_STEP_DONE(courseStep), true, PARAMETER_TYPE.BOOLEAN);

        this.emit(`step-done`, step);
    }

    async setStepNotDone (step, courseUuid = null) {
        let courseStep = this.getCourseStep(step, courseUuid);

        await UserManager.instance.getUser().getParameters().setParameter(
            PARAMETER_NAME.ONBOARDING_PROGRESS_STEP_DONE(courseStep), false, PARAMETER_TYPE.BOOLEAN);
    }

    getOnboardingTimestamp (name, initial_value) {

        const timestamp = UserManager.instance.getUser().getParameters()
            .getParameter(PARAMETER_NAME.ONBOARDING_PROGRESS_TIMESTAMP(name));

        if (timestamp === null) {
            this.setOnboardingTimestamp(name, initial_value);
            return initial_value;
        } else {
            return timestamp;
        }
    }

    async setOnboardingTimestamp (name, value) {
        await UserManager.instance.getUser().getParameters().setParameter(
            PARAMETER_NAME.ONBOARDING_PROGRESS_TIMESTAMP(name), value, PARAMETER_TYPE.DATETIME);
    }

    setHeaderView (headerView) {
        this._headerView = headerView;
    }

    setGuessView (guessView) {
        this._guessView = guessView;
        this._guessView.on('rendered', this.showOnboardingOnGuessRender, this);
        this._guessView.on('keyboard-dismissed-with-text-input', this.showGuessSwipeLeftToProceed, this);
    }

    removeGuessView () {
        if (this._guessView) {
            this._guessView.off(null, null, this);
            this._guessView = undefined;
        }
    }

    showOnboardingOnGuessRender (guess_card_view) {
        if (!guess_card_view.model.question.on_previous) {
            this.showGuessPreviousCardTooltip();
            this.showGuessEncouragementTooltip();
            this.showWelcomeBack();
            this.showGuess20IntroTooltip();
        }
    }

    showGuessSwipeLeftToProceed () {
        if (ResponsivenessUtils.guessInMobileView() && !this.getStepDone('keyboard-closed-with-input')) {
            this._guessView.renderOnboardingTooltip('keyboard-closed');
            this.setStepDone('keyboard-closed-with-input');
        }
    }

    showGuessCorrectAnswerTooltip (force = false) {
        if ((UserManager.instance.getUser().getCourse().getStatistics().getData().all_units.total === 0 &&
            !this.getStepDone('correct')) || force) {
            if (this._guessView) {
                this._guessView.renderOnboardingTooltip('correct');
                this.setStepDone('correct');
            }
        }
    }

    showGuessFirstMistakeTooltip (force = false) {
        if (!this.getStepDone('first-mistake') || force) {
            if (this._guessView) {
                this._guessView.renderOnboardingTooltip('first-mistake');
                this.setStepDone('first-mistake');
            }
        }
    }

    showGuessAfterMistakeTooltip (force = false) {
        if (!this.getStepDone('after-mistake') || force) {
            if (this._guessView) {
                this._guessView.renderOnboardingTooltip('after-mistake');
                this.setStepDone('after-mistake');
            }
        }
    }

    showGuess20IntroTooltip () {
        if (this.getStepDone(ONBOARDING_STEP.WELCOME_BACK) && !this.getStepDone('guess-20-intro')) {
            if (this._guessView && this.isUserRegisteredBeforeLayout20RollOut()) {
                this._guessView.renderOnboardingTooltip('guess-20-intro');
                this.setStepDone('guess-20-intro');
            }
        }
    }

    // Doorslams

    showWelcomeBack () {
        const user = UserManager.instance.getUser();
        const todayStatistics = user.getCourse().getStatistics().getTodayData();
        const registered = user.registered.local();

        if (! this.getStepDone(ONBOARDING_STEP.WELCOME_BACK) && registered && todayStatistics.ts > registered.format('YYYY-MM-DD')) {
            let doorslam = new DoorslamView(WelcomeBackDoorslam.data(), {language_name: this._getCurrentLanguageName()}),
                overlay = new OnboardingOverlayView(doorslam);

            doorslam.on('close', () => {
                this.setStepDone(ONBOARDING_STEP.WELCOME_BACK);
                overlay.remove();
            });

            overlay.show();
        }
    }

    showConversationIntro () {
        if (!this.getStepDone('conversation-intro')) {
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('conversation', 'start', 'intro');
            googleAnalyticsCommand('send', 'event', 'Conversation', 'intro-start');

            let doorslam = new DoorslamView(ConversationIntroDoorslam.data()),
                overlay = new OnboardingOverlayView(doorslam);

            doorslam.on('check-permissions', () => {
                const speechRecognition = new SpeechReco();

                speechRecognition.handleEvent('start', () => {
                    this.setStepDone('conversation-intro');
                    UserManager.instance.getUser().getEventSender().sendNavigationEvent('conversation', 'done', 'intro');
                    googleAnalyticsCommand('send', 'event', 'ChallengesArea', 'SpeakingPermissionGranted');
                    googleAnalyticsCommand('send', 'event', 'Conversation', 'intro-done');
                    doorslam.next();
                });

                speechRecognition.handleEvent('error', errorType => {
                    if (errorType === 'not-allowed') {
                        doorslam.navigateToIndex(3);
                        googleAnalyticsCommand('send', 'event', 'ChallengesArea', 'SpeakingPermissionDeclined');
                    }
                });

                speechRecognition.startRecognition();
            });

            doorslam.on('close', () => {
                overlay.remove();
            });

            doorslam.on('open', uri => {
                if (uri) {
                    new URI(uri).navigateTo();
                    overlay.remove();
                }
            });

            overlay.show();
        }
    }

    showFastTrackingIntro () {
        // TODO: Actually figure out if things are properly removed in the end..
        let doorslam = new DoorslamView(FastTrackingDoorslam.data(), {
            language_name: this._getCurrentLanguageName()
        }, { dismissible: true }),
            overlay = new OnboardingOverlayView(doorslam);

        doorslam.on('close dismiss', () => {
            overlay.remove();
        });

        overlay.show();
    }

    showNoFastTracking () {
        if (!this.getStepDone('no-fast-tracking-' + UserManager.instance.getUser().getCourse().UUID)) {
            let words_encountered = UserManager.instance.getUser().getCourse().getStatistics().getWordsEncountered();
            const setSize = Awards.getDynamicGoalSize();

            let doorslam = new DoorslamView(NoFastTrackingDoorslam.data(), {
                goal_words_remaining: setSize - words_encountered,
            }, { dismissible: true }),
                overlay = new OnboardingOverlayView(doorslam);

            doorslam.on('dismiss close', () => {
                this.setStepDone('no-fast-tracking-' + UserManager.instance.getUser().getCourse().UUID);
                overlay.remove();
            });

            overlay.show();
        }
    }

    async showFastTrackingComplete (delay = 0) {

        await UserManager.instance.getUser().getCourse().syncCourse();
        const course = await UserManager.instance.getUser().getCourse();
        const courseHasVocabulary = await course.hasFeature(COURSE_FEATURE.VOCABULARY_CURVE);
        setTimeout(() => {
            const courseInfo = UserManager.instance.getUser().getCourse().getInfo();
            let doorslam = new DoorslamView(FastTrackingCompleteDoorslam.data(courseHasVocabulary), {
                    language_name: this._getCurrentLanguageName(),
                    ft_result: i18nUtils.getLocaleFormattedNumber(
                        UserManager.instance.getUser().getCourse().getStatistics().getWordsEncountered()
                    ),
                    course_words: courseInfo.words,
                }, { dismissible: true }),
                overlay = new OnboardingOverlayView(doorslam);

            this.setStepDone(ONBOARDING_STEP.FAST_TRACKING_DONE, UserManager.instance.getUser().getCourse().UUID);

            doorslam.on('dismiss close', uri => {
                Promise.resolve()
                    .then(() => overlay.remove())
                    .then(() => {
                        if (uri) {
                            EventBus.navigationData = { end_of_FT: true };
                            new URI(uri).navigateTo();
                        }
                    });
            });

            overlay.show();
        }, delay);
    }

    async showVariationEndUnified (variation) {

        if (!variation) {
            console.log('ONBOARDING: cant show variation end doorslam, no variation defined');
            return;
        }

        const extend_variation_enabled = await this.isExtendVariationEnabled(variation);

        let doorslam = new DoorslamView(VariationEndUnifiedDoorslam.data({
            copy_link_enabled: true,
            extend_variation_enabled
        }), {
            variation_name: variation.name,
            variation_units: variation.units,
        }, { variation_uuid: variation.uuid, copy_link_enabled: true, copy_link_url: variation.share_url });


        const overlay = new OnboardingOverlayView(doorslam);

        doorslam.on('open_hub', () => {
            this.navigateToAndRemoveOverlay(overlay, 'hub');
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('variation-end.doorslam', 'home', variation.uuid);
        });

        doorslam.on('extend_variation', () => {
            const courseInfo = UserManager.instance.getUser().getCourse().getInfo();

            if (courseInfo && courseInfo.uuid && variation && variation.uuid) {
                Backbone.trigger('showLoader', 'hub');
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('variation-end.doorslam', 'extend', variation.uuid);
                Promise.resolve()
                    .then(() => UserManager.instance.getUser().getLessons().createVariationExtension(courseInfo.uuid, variation.uuid, 45))
                    .then(() => ControllerManager.instance.getController('Guess').reSyncGuess())
                    .then(() => {
                        Backbone.trigger('hideLoader', 'hub');
                        overlay.remove();
                    });
            }
        });

        doorslam.on('copy-link', (variation_uuid) => {
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('variation-end.doorslam', 'share', variation_uuid);
        });

        return overlay.show();
    }

    async showCourseEnd (variations_completed = false) {
        const course = UserManager.instance.getUser().getCourse();
        console.log('---Now showing CourseEnd doorslam from onboarding.js---');
        let doorslam = new DoorslamView(CourseEndDoorslam.data({
                variations_enabled: await course.hasFeature(COURSE_FEATURE.VARIATIONS),
                variations_completed
            })),
            overlay = new OnboardingOverlayView(doorslam);

        doorslam.on('open_hub', () => {
            this.navigateToAndRemoveOverlay(overlay, 'hub');
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('out-of-words', 'home', null);
        });

        return overlay.show();
    }

    async showPaywall () {
        let doorslam = new DoorslamView(PaywallDoorslam.data()),
            overlay = new OnboardingOverlayView(doorslam);

        doorslam.on('open_hub', () => {
            this.navigateToAndRemoveOverlay(overlay, 'hub');
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('paywall-doorslam', 'home', null);
        });

        doorslam.on('open_pay', () => {
            this.navigateToAndRemoveOverlay(overlay, 'subscriptions');
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('paywall-doorslam', 'pay', null);
        });

        return overlay.show();
    }

    showLessonIsPublished (lesson_name, variation_uuid) {
        const doorslam = new DoorslamView(LessonIsPublishedDoorslam.data(), {lesson_name});
        const overlay = new OnboardingOverlayView(doorslam);

        googleAnalyticsCommand('send', 'event', 'CourseWizard', 'LessonIsPublishedDoorslam');
        UserManager.instance.getUser().getEventSender().sendNavigationEvent('CourseWizard', 'LessonIsPublishedDoorslam', null);

        doorslam.on('open', uri => {
            if (uri) {
                googleAnalyticsCommand('send', 'event', 'CourseWizard', 'LessonIsPublishedDoorslamDecline', 'VariationUuid', variation_uuid);
                UserManager.instance.getUser().getEventSender().sendNavigationEvent('CourseWizard', 'LessonIsPublishedDoorslamDecline', variation_uuid);

                Backbone.trigger('showLoader', 'variations');
                Promise.resolve()
                    .then(() => UserManager.instance.getUser().getCourse().setVariation({ variation_uuid, enabled: false }))
                    .then(() => ControllerManager.instance.getController('Guess').reSyncGuess())
                    .then(() => {
                        new URI(uri).navigateTo();
                        Backbone.trigger('hideLoader', 'variations');
                        overlay.remove();
                    });

            }
        });

        doorslam.on('confirm', () => {
            googleAnalyticsCommand('send', 'event', 'CourseWizard', 'LessonIsPublishedDoorslamConfirm', 'VariationUuid', variation_uuid);
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('CourseWizard', 'LessonIsPublishedDoorslamConfirm', variation_uuid);

            Backbone.trigger('showLoader', 'variations');
            Promise.resolve()
                .then(() => UserManager.instance.getUser().getCourse().setVariation({ variation_uuid }))
                .then(() => ControllerManager.instance.getController('Guess').reSyncGuess())
                .then(() => overlay.remove())
                .then(() => {
                    Backbone.trigger('hideLoader', 'variations');
                    Backbone.history.navigate('guess',  { trigger: true });
                });
        });

        doorslam.on('close dismiss', () => {
            googleAnalyticsCommand('send', 'event', 'CourseWizard', 'LessonIsPublishedDoorslamClosed');
            UserManager.instance.getUser().getEventSender().sendNavigationEvent('CourseWizard', 'LessonIsPublishedDoorslamClosed', null);
            overlay.remove();
            Backbone.trigger('hideLoader', 'variations');
        });

        overlay.show();
    }

    // Messages

    showGuessPreviousCardTooltip (force = false) {

        console.log('TODO: showGuessPreviousCardTooltip'); // TODO
        // if ((UserManager.instance.getUser().getCourse().getStatistics().getData().all_units.total >= 15 &&
        //     !this.getStepDone('previous-card') &&
        //     ControllerManager.instance.getController('Guess').getModelCollection().hasPrevious()) || force) {
        //     this._guessView.renderOnboardingTooltip('previous-card');
        //     this.setStepDone('previous-card');
        // }
    }

    showGuessEncouragementTooltip (force = false, tooltipName = null) {
        const correct_cards = UserManager.instance.getUser().getCourse().getStatistics().getData().all_units.correct,
            new_words = UserManager.instance.getUser().getCourse().getStatistics().getWordsEncountered();

        if (new_words === 3 && !this.getStepDone('encouragement')) {
            if (correct_cards <= 1) {
                this._guessView.renderOnboardingTooltip('good-start');
            } else if (correct_cards === 2) {
                this._guessView.renderOnboardingTooltip('great-start');
            } else if (correct_cards ===3) {
                this._guessView.renderOnboardingTooltip('amazing-start');
            }
            this.setStepDone('encouragement');
        } else if (force && tooltipName) {
            this._guessView.renderOnboardingTooltip(tooltipName);
        }
    }

    showPermanentTranslations (switchedOn) {
        if (switchedOn) {
            this._guessView.renderOnboardingTooltip('permanent-translations-enabled');
        }
    }

    showOnboardingGuessQuestionReady () {

        if (this._doorslamQueue.length > 0) {
            const doorslamName = this._doorslamQueue.splice(0, 1)[0];
            console.log('onboarding: doorslamName', doorslamName);

            switch (doorslamName) {
                case 'fast-tracking':
                    this.showFastTrackingIntro();
                    break;
                case 'no-fast-tracking':
                    this.showNoFastTracking();
                    break;
                case 'fast-tracking-completed':
                    this.showFastTrackingComplete();
                    break;
                case 'conversation':
                    this.showConversationIntro();
                    break;
                default:
                    throw Error(`Unknown doorslam name="${doorslamName}"`);
            }
        }
    }

    showLessonAssignedNotification () {
        const userParameters = UserManager.instance.getUser().getParameters();

        const lastAssigned = userParameters.getParameter(PARAMETER_NAME.VARIATIONS_LAST_ASSIGNED);
        const currentVariations = UserManager.instance.getUser().getCourse().getCurrentVariations();
        const assignedVariations = _.sortBy(currentVariations.filter( variation => variation.assigned_ts !== null), ['assigned_ts']);

        if (assignedVariations.length > 0) {
            let { name, units, assigned_ts } = _.last(assignedVariations);

            if (assigned_ts && (lastAssigned === null || moment(assigned_ts).isAfter(lastAssigned))) {

                userParameters.setParameter(
                    PARAMETER_NAME.VARIATIONS_LAST_ASSIGNED,
                    moment.utc(),
                    PARAMETER_TYPE.DATETIME
                );

                const lessonAssignedNotification = ControllerManager.instance.getController('ModalMessages')
                    .show('lesson_assigned', { name, units });

                lessonAssignedNotification.on('close', callback => callback());
            }
        }
    }

    showAutoAdvanceTooltip (force = false) {
        if (!this.getStepDone(ONBOARDING_STEP.SETTINGS_AUTO_ADVANCE) || force) {
            this._guessView.renderOnboardingTooltip('settings-auto-advance');
            this.setStepDone(ONBOARDING_STEP.SETTINGS_AUTO_ADVANCE);
        }
    }

    queueOnboardingDoorslam (doorslam) {
        this._doorslamQueue.push(doorslam);
    }

    queueOnboardingTooltip (tooltip) {
        this._tooltipQueue.push(tooltip);
    }

    queuePostTrialStart (action) {
        this._postTrialStartQueue.push(action);
    }

    trackCardProgress (guessEvent) {
        const user = UserManager.instance.getUser();
        const statistics = user.getCourse().getStatistics().getData();
        const history = user.getCourse().getStatistics().getHistory();

        const ENTHUSED_USER_DAYS = 7;
        const ENTHUSED_USER_LEARN_DAYS = 3;
        const ENTHUSED_USER_CARDS = 25;

        switch (statistics.all_units.total) {
            case 1:
                googleAnalyticsCommand('send', 'event', 'NewUser', 'OneCard');
                break;
            case 12:
                googleAnalyticsCommand('send', 'event', 'NewUser', 'TwelveCards');
                tagManagerCommand({ 'event': 'CardsCount12' });
                break;
            case 50:
                googleAnalyticsCommand('send', 'event', 'NewUser', 'FiftyCards');
                break;
            case 100:
                tagManagerCommand({ 'event': 'CardsCount100' });
                break;
        }

        let daysLearned = 0;
        let cardsDone = 0;

        if (user.registered !== null) {

            let registerDate = moment(user.registered).local();
            let accountAge = moment().diff(registerDate, 'days');

            if (accountAge <= ENTHUSED_USER_DAYS) {

                history.forEach(day => {
                    if (day.all_units.total > 0) {
                        cardsDone += day.all_units.total;
                        daysLearned += 1;
                    }
                });

                if (daysLearned === ENTHUSED_USER_LEARN_DAYS && cardsDone > ENTHUSED_USER_CARDS) {
                    if ( ! this.getStepDone('become-enthused')) {
                        googleAnalyticsCommand('send', 'event', 'NewUser', 'Enthused');
                        tagManagerCommand({ 'event': 'UserIsEnthused' });
                        this.setStepDone('become-enthused');
                    }
                }
            }
        }
    }

    showAccountDeleteConfirmation() {
        const confirmationView = new ConfirmationView({
            title: i18nUtils.prop('account_profile_delete_your_account_confirmation_title'),
            message: i18nUtils.prop('account_profile_delete_your_account_confirmation_text'),
            actions: [
                {
                    title: i18nUtils.prop('account_profile_delete_your_account_confirmation_cancel_button'),
                    action: CONFIRM_ACTION.CANCEL
                },
                {
                    title: i18nUtils.prop('account_profile_delete_your_account_confirmation_send_email'),
                    action: CONFIRM_ACTION.OK,
                    primary: true
                }
            ]
        });

        confirmationView.on('confirm', action => {
            if (action === CONFIRM_ACTION.OK) {
                UserManager.instance.getUser().getEventSender().sendDeletionRequestEvent();
            }
            Promise.resolve()
                .then(() => confirmationView.hide())
                .then(() => {
                    confirmationView.remove();
                });
        });

        confirmationView.show();
    }

    notifyGuessSubmitted (guessEvent) {
        this.trackCardProgress(guessEvent);
    }

    _getCurrentLanguageName () {
        return i18nUtils.prop(UserManager.instance.getUser().getCourse().getInfo().target_language, {}, {}, 'language_name');
    }

    activateVariation (variation_uuid) {
        let variation = UserManager.instance.getUser().getCourse().getVariation(variation_uuid);

        if (!variation) { // variation is not yet available for user via get
            variation = { uuid: variation_uuid };
        }

        if (variation && variation.status !== VARIATION_STATUS.SUBSCRIPTION_LIMITED) {
            Promise.resolve()
                .then(() => UserManager.instance.getUser().getCourse().setVariation({ variation }))
                .then(() => UserManager.instance.getUser().getCourse().syncCourse());
        }
    }

    isUserRegisteredBeforeLayout20RollOut() {
        let registeredBeforeLayout20RollOut = null;
        if (UserModel.isSignedIn()) {
            const registered = UserManager.instance.getUser().registered.local();
            const layout20fullRollOutDate = moment("12-12-2019", "MM-DD-YYYY");
            registeredBeforeLayout20RollOut = registered.isBefore(layout20fullRollOutDate);
        }

        return registeredBeforeLayout20RollOut;
    }

    async isExtendVariationEnabled (variation) {
        const _course = UserManager.instance.getUser().getCourse();
        const courseHasDecksFeature = await _course.hasFeature(COURSE_FEATURE.COURSE_WIZARD);
        return courseHasDecksFeature && variation && variation.can_extend;
    }

    navigateToAndRemoveOverlay (overlay, to = 'hub') {
        Backbone.trigger('showLoader', to);
        Promise.resolve()
            .then(() => overlay.remove())
            .then(() => {
                Backbone.history.navigate(to,  {trigger: true});
            })
            .catch(error => Backbone.trigger('server-error', true, error));
    }
}

export const factory = new ControllerFactory(OnboardingController);
