var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
        },
      },
      _vm.$listeners
    ),
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M0 0h24v24H0z" } }),
        _c("g", { attrs: { fill: "#FFF" } }, [
          _c("path", {
            attrs: {
              d: "M12 2C6.477 2 2 6.477 2 12l.006.343a9.966 9.966 0 001.233 4.482l.026.045-.05.02a9.327 9.327 0 01-.725.222c-1.987.523-1.987 3.344 0 3.868 1.163.306 2.39.464 3.64.464l.474-.008c.472-.015.94-.052 1.402-.112l.274-.041.068.029A9.98 9.98 0 0012 22c5.523 0 10-4.477 10-10S17.523 2 12 2zm0 2a8 8 0 11-3.465 15.213c-.768.15-1.574.231-2.405.231-1.098 0-2.152-.14-3.13-.398 1.223-.322 2.33-.828 3.26-1.473A8 8 0 0112 4z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M9.763 9.763a.999.999 0 11-1.998.002.999.999 0 011.998-.002M16.157 9.763a.999.999 0 11-1.998.002.999.999 0 011.998-.002M14 13.178a1 1 0 111.866.72 4.246 4.246 0 01-3.962 2.727 4.247 4.247 0 01-3.963-2.727 1 1 0 011.818-.826l.048.106a2.247 2.247 0 002.097 1.447c.936 0 1.762-.58 2.096-1.447z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }