<template>
    <div class="star-icon">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.043 2.60083C15.4344 1.79972 16.5656 1.79972 16.957 2.60083L20.4565 9.762C20.6119 10.0801 20.9124 10.3006 21.26 10.3516L29.0851 11.5C29.9604 11.6284 30.31 12.7149 29.6765 13.3384L24.0143 18.9126C23.7628 19.1603 23.648 19.517 23.7074 19.8667L25.044 27.7376C25.1936 28.6181 24.2785 29.2895 23.4955 28.8738L16.4966 25.1577C16.1857 24.9926 15.8143 24.9926 15.5034 25.1577L8.50447 28.8738C7.72151 29.2895 6.80642 28.6181 6.95595 27.7376L8.29263 19.8667C8.35201 19.517 8.23723 19.1603 7.98569 18.9126L2.32346 13.3385C1.69004 12.7149 2.03957 11.6284 2.91494 11.5L10.74 10.3516C11.0876 10.3006 11.3881 10.0801 11.5435 9.762L15.043 2.60083Z" :fill="fillColor" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'star-icon',
    props: {
        filled: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        fillColor() {
            return this.filled ? '#FFD86F' : '#B2B8CB';
        }
    }
}
</script>

<style lang="scss">
    div.star-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
    }
</style>
