
import moment from 'moment';


export class Limit {
    constructor (type, scopes) {
        this.type = type;
        this._scopes = scopes && scopes.map(scope => ({
            start: scope.start_ts && moment(scope.start_ts),
            end: scope.end_ts && moment(scope.end_ts),
            action: scope.action,
            parameters: scope.parameters,
        }));
    }

    serialize () {
        return this._scopes && this._scopes.map(scope => ({
            start_ts: scope.start && scope.start.format(),
            end_ts: scope.end && scope.end.format(),
            action: scope.action,
            parameters: scope.parameters
        }));
    }

    /**
     * @typedef {object} LimitObject
     * @property {moment} start
     * @property {moment} end
     * @property {string|null} action
     * @property {object} parameters
     */

    /**
     *
     * @return {LimitObject|null}
     * @private
     */
    _getActiveLimit () {
        let now = moment();
        return this._scopes.find(scope => {
            return (scope.start === null || scope.start < now) && (scope.end === null || scope.end > now);
        }) || null;
    }

    /**
     * Takes statistics and returns an action to take if necessary or null otherwise
     * @return {string|null}
     */
    getAction () {
        let limit = this._getActiveLimit();
        return limit && limit.action || null;
    }

    /**
     * Takes statistics and returns an action to take if necessary or null otherwise
     * @return {object|null}
     */
    getParameters () {
        let limit = this._getActiveLimit();
        return limit && limit.parameters || null;
    }
}
