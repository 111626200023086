var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M1 9.5l-.447-.894a1 1 0 000 1.788L1 9.5zm22 0l.447.894a1 1 0 000-1.788L23 9.5zM7.447 11.606a1 1 0 10-.894 1.788l.894-1.788zm-4.894-.212a1 1 0 10.894-1.788l-.894 1.788zm9 3.382l-.447.895.447-.895zm0-10.552l-.447-.895.447.895zm-10.106 6.17L12 5.118l-.894-1.789L.553 8.606l.894 1.788zM12 5.118l10.553 5.276.894-1.788-10.553-5.277L12 5.118zm10.553 3.488L12 13.882l.894 1.789 10.553-5.277-.894-1.788zM12 13.882l-4.553-2.276-.894 1.788 4.553 2.277.894-1.789zM3.447 9.606l-2-1-.894 1.788 2 1 .894-1.788zM12 13.882l-.894 1.789a2 2 0 001.788 0L12 13.882zm0-8.764l.894-1.789a2 2 0 00-1.788 0L12 5.118z",
          fill: "#2C3143",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 12.5V17c0 2 3 3 5 3s5-1 5-3v-1M21 10.5V14",
          stroke: "#2C3143",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }